<form #form='ngForm' id="fAntecedentes">
    <div class="container-fluid">
        <div class="row titulos">
            <div class="col-md-2">
                <span>LISTADO</span>
            </div>
            <div class="col">
                <span>PERSONALES</span>
            </div>
            <div class="col">
                <span>FECHA</span>
            </div>
            <div class="col">
                <span>FAMILIARES</span>
            </div>
            <div class="col">
                <span>NOTAS</span>
            </div>
        </div>
        <!-- enfermedades de la piel -->
        <div class="row">
            <div class="col-md-2">
                <p>Enfermedades de la Piel</p>
            </div>
            <div class="col">
                <input name="enfermedadesPielPersonal" [(ngModel)]="antecedentes.enfermedadesPielPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="enfermedadesPielFecha" [(ngModel)]="antecedentes.enfermedadesPielFecha"  type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="enfermedadesPielFamiliares" [(ngModel)]="antecedentes.enfermedadesPielFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="enfermedadesPielNotas" [(ngModel)]="antecedentes.enfermedadesPielNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- desnutrición -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Desnutrición</p>
            </div>
            <div class="col">
                <input name="desnutricionPersonal" [(ngModel)]="antecedentes.desnutricionPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="desnutricionFecha" [(ngModel)]="antecedentes.desnutricionFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="desnutricionFamiliares" [(ngModel)]="antecedentes.desnutricionFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="desnutricionNotas" [(ngModel)]="antecedentes.desnutricionNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- obesidad -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Obesidad</p>
            </div>
            <div class="col">
                <input name="obesidadPersonal" [(ngModel)]="antecedentes.obesidadPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="obesidadFecha" [(ngModel)]="antecedentes.obesidadFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="obesidadFamiliares" [(ngModel)]="antecedentes.obesidadFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="obesidadNotas" [(ngModel)]="antecedentes.obesidadNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- defectos postulares -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Defectos Postulares</p>
            </div>
            <div class="col">
                <input name="defectosPostularesPersonal" [(ngModel)]="antecedentes.defectosPostularesPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="defectosPostularesFecha" [(ngModel)]="antecedentes.defectosPostularesFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="defectosPostularesFamiliares" [(ngModel)]="antecedentes.defectosPostularesFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="defectosPostularesNotas" [(ngModel)]="antecedentes.defectosPostularesNotas"  type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- fracturas -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Fracturas</p>
            </div>
            <div class="col">
                <input name="fracturasPersonal" [(ngModel)]="antecedentes.fracturasPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="fracturasFecha" [(ngModel)]="antecedentes.fracturasFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="fracturasFamiliares" [(ngModel)]="antecedentes.fracturasFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="fracturasNotas" [(ngModel)]="antecedentes.fracturasNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- hospitalizaciones -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Hospitalizaciones</p>
            </div>
            <div class="col">
                <input name="hospitalizacionesPersonal" [(ngModel)]="antecedentes.hospitalizacionesPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="hospitalizacionesFecha" [(ngModel)]="antecedentes.hospitalizacionesFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="hospitalizacionesFamiliares" [(ngModel)]="antecedentes.hospitalizacionesFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="hospitalizacionesNotas" [(ngModel)]="antecedentes.hospitalizacionesNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- transfusiones -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Transfusiones</p>
            </div>
            <div class="col">
                <input name="transfucionesPersonal" [(ngModel)]="antecedentes.transfucionesPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="transfucionesFecha" [(ngModel)]="antecedentes.transfucionesFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="transfucionesFamiliares" [(ngModel)]="antecedentes.transfucionesFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="transfucionesNotas" [(ngModel)]="antecedentes.transfucionesNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- cardiopatias -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Cardiopatias</p>
            </div>
            <div class="col">
                <input name="cardiopatiasPersonal" [(ngModel)]="antecedentes.cardiopatiasPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cardiopatiasFecha" [(ngModel)]="antecedentes.cardiopatiasFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cardiopatiasFamiliares" [(ngModel)]="antecedentes.cardiopatiasFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cardiopatiasNotas" [(ngModel)]="antecedentes.cardiopatiasNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- cirugias -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Cirugías</p>
            </div>
            <div class="col">
                <input name="cirugiasPersonal" [(ngModel)]="antecedentes.cirugiasPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cirugiasFecha" [(ngModel)]="antecedentes.cirugiasFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cirugiasFamiliares" [(ngModel)]="antecedentes.cirugiasFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cirugiasNotas" [(ngModel)]="antecedentes.cirugiasNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- cancer/leucemia -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Cáncer/Leucemia</p>
            </div>
            <div class="col">

                <input name="cancerLeucemiaPersonal" [(ngModel)]="antecedentes.cancerLeucemiaPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cancerLeucemiaFecha" [(ngModel)]="antecedentes.cancerLeucemiaFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cancerLeucemiaFamiliares" [(ngModel)]="antecedentes.cancerLeucemiaFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="cancerLeucemiaNotas" [(ngModel)]="antecedentes.cancerLeucemiaNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- alergias -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>Alergias</p>
            </div>
            <div class="col">
                <input name="alergiasPersonal" [(ngModel)]="antecedentes.alergiasPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="alergiasFecha" [(ngModel)]="antecedentes.alergiasFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="alergiasFamiliares" [(ngModel)]="antecedentes.alergiasFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="alergiasNotas" [(ngModel)]="antecedentes.alergiasNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- vih -->
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
                <p>VIH</p>
            </div>

            <div class="col">
                <input name="vihPersonal" [(ngModel)]="antecedentes.vihPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="vihFecha" [(ngModel)]="antecedentes.vihFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="vihFamiliares" [(ngModel)]="antecedentes.vihFamiliares"  type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="vihNotas" [(ngModel)]="antecedentes.vihNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- tabaquismo -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Tabaquismo</p>
            </div>
            <div class="col">
                <input name="tabaquismoPersonal" [(ngModel)]="antecedentes.tabaquismoPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="tabaquismoFecha" [(ngModel)]="antecedentes.tabaquismoFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="tabaquismoFamiliares" [(ngModel)]="antecedentes.tabaquismoFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="tabaquismoNotas" [(ngModel)]="antecedentes.tabaquismoNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- alcoholismo -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Alcoholismo</p>
            </div>

            <div class="col">
                <input name="alcoholismoPersonal" [(ngModel)]="antecedentes.alcoholismoPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="alcoholismoFecha" [(ngModel)]="antecedentes.alcoholismoFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="alcoholismoFamiliares" [(ngModel)]="antecedentes.alcoholismoFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="alcoholismoNotas" [(ngModel)]="antecedentes.alcoholismoNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- diabetes -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Diabetes</p>
            </div>
            <div class="col">
                <input name="diabetesPersonal" [(ngModel)]="antecedentes.diabetesPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="diabetesFecha" [(ngModel)]="antecedentes.diabetesFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="diabetesFamiliares" [(ngModel)]="antecedentes.diabetesFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="diabetesNotas" [(ngModel)]="antecedentes.diabetesNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- tuberculosis -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">

            <div class="col-md-2">
                <p>Tuberculosis</p>
            </div>

            <div class="col">
                <input name="tuberculosisPersonal" [(ngModel)]="antecedentes.tuberculosisPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="tuberculosisFecha" [(ngModel)]="antecedentes.tuberculosisFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="tuberculosisFamiliares" [(ngModel)]="antecedentes.tuberculosisFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="tuberculosisNotas" [(ngModel)]="antecedentes.tuberculosisNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- deportes -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Deportes</p>
            </div>
            <div class="col">
                <input name="deportesPersonal" [(ngModel)]="antecedentes.deportesPersonal" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="deportesFecha" [(ngModel)]="antecedentes.deportesFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="deportesFamiliares" [(ngModel)]="antecedentes.deportesFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="deportesNotas" [(ngModel)]="antecedentes.deportesNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- enfermedades de los sentidos -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Enfermedades de los Sentidos</p>
            </div>

            <div class="col">
                <input name="enfermedadesDeLosSentidosPersonales" [(ngModel)]="antecedentes.enfermedadesDeLosSentidosPersonales" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="enfermedadesDeLosSentidosFecha" [(ngModel)]="antecedentes.enfermedadesDeLosSentidosFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="enfermedadesSentidosFamiliares" [(ngModel)]="antecedentes.enfermedadesSentidosFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="enfermedadesSentidosNotas" [(ngModel)]="antecedentes.enfermedadesSentidosNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- exposicion laboral -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Exposición Laboral</p>
            </div>
            <div class="col">
                <input name="expoLaboralPersonales" [(ngModel)]="antecedentes.expoLaboralPersonales" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="expoLaboralFecha" [(ngModel)]="antecedentes.expoLaboralFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="expoLaboralFamiliares" [(ngModel)]="antecedentes.expoLaboralFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="expoLaboralNotas" [(ngModel)]="antecedentes.expoLaboralNotas" type="text" placeholder="" class="form-control">
            </div>

        </div>
        <!-- defectos postquirurgicos -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Defectos Postquirúrgicos</p>
            </div>
            <div class="col">
                <input name="postQuirurgicoPersonales" [(ngModel)]="antecedentes.postQuirurgicoPersonales" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="postQuirurgicoFecha" [(ngModel)]="antecedentes.postQuirurgicoFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="postQuirurgicoFamiliares" [(ngModel)]="antecedentes.postQuirurgicoFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="postQuirurgicoNotas" [(ngModel)]="antecedentes.postQuirurgicoNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <!-- otras enfermedades -->
        <div class="row" style="margin-top: 10px;" *ngIf="edad > 9">
            <div class="col-md-2">
                <p>Otras Enfermedades (COVID-19)</p>
            </div>
            <div class="col">
                <input name="otrasEnfPersonales" [(ngModel)]="antecedentes.otrasEnfPersonales" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="otrasEnfFecha" [(ngModel)]="antecedentes.otrasEnfFecha" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="otrasEnfFamiliares" [(ngModel)]="antecedentes.otrasEnfFamiliares" type="text" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input name="otrasEnfNotas" [(ngModel)]="antecedentes.otrasEnfNotas" type="text" placeholder="" class="form-control">
            </div>
        </div>
        <div class="row">
            <!-- *ngIf="paciente.consultas = 1" -->
            <!--TODO: se quito para actualizar expedientes *ngIf="paciente.consultas = 1" -->
            
                <button class="boton" (click)="agregarAntecedentes(form)">Guardar</button>
                <button class="boton2" (click)="actualizarAntecedentes(form)">Actualizar</button>
            
        </div>
    </div>
</form>
