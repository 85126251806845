<div class="container">

    <div class="row">
        <h1 class="col">SURTIMINETO DE ALMACEN</h1>

            <div class="gap-2 col-3 mt-2">
                <button class="btn btn-primary" type="button">IMPRIMIR</button>
            </div>
    </div>
    

    <div class="card p-3 mt-3">
        <div class="row">
            <div class="col">
                <div class="mb-1 row">
                    <label class="col-sm-3">NOMBRE: </label>
                    <div class="col sm-5">
                        <p>ALMACEN-BANCO DE SANGRE</p>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label class="col-sm-3">CATEGORÍA: </label>
                    <div class="col-sm-5">
                        <p>URGENTE</p>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label class="col-sm-3">SOLICITANTE: </label>
                    <div class="col-sm-5">
                        <p>ENFERMERÍA-CENSUR</p>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label class="col-sm-3">DESCRIPCIÓN: </label>
                    <div class="col-sm-9">
                        <p>MATERIAL EXTRA PARA LA EXTRACCION DE SANGRE</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-1 row">
                    <label class="col-sm-3">ID: </label>
                    <div class="col-sm-5">
                        <p>2RKFJNRLF245OUTJ5P4OP45JO</p>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label class="col-sm-3">DEPARTAMENTO: </label>
                    <div class="col-sm-5">
                        <p>FLEBOTOMIA</p>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label class="col-sm-3">ESTADO: </label>
                    <div class="col-sm-5">
                        <p>PENDIENTE-URGENTE</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-5">
        <div class="card-header">
            <div class="row">
                <div class="col">
                    No.
                </div>
                <div class="col-2">
                    PRODUCTO
                </div>
                <div class="col">
                    DESCRIPCIÓN
                </div>
                <div class="col">
                    QR
                </div>
                <div class="col text-center">
                    Check-in
                </div>
                <div class="col text-center">
                    Check-out
                </div>
                <div class="col text-center">
                    DEVOLUCIÓN
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    01
                </div>
                <div class="col-2">
                    CONCENTARDO ERITROCITARIO
                </div>
                <div class="col">
                    SANGRE TOTAL
                </div>
                <div class="col">
                    Aqui va el QR
                </div>
                <div class="col text-center">
                    <input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" aria-label="...">
                </div>
                <div class="col text-center">
                    <input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" aria-label="...">
                </div>
                <div class="col text-center">
                    <input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" aria-label="...">
                </div>
            </div>
        </div>
        <div class="card-footer text-center">
            <div class="col">
                AQUI VA EL PAGINADOR
            </div>
        </div>
    </div>

</div>
