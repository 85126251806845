<section class="container-fluid" style="height: 40em;">
    <h2 class="centerxd" id="centerxd"> Registro de {{ id }}</h2>
    <div class="card" id="card">
        <div class="container">
            <form [formGroup]="forma" (ngSubmit)="guardar()">
                <div class="form-group">
                    <label for="ESTUDIO"> {{id | titlecase}}: </label>
                    <input type="text" min="1" maxlength="100" name="ESTUDIO" id="ESTUDIO" class="form-control" placeholder="Nombre del estudio o servicio" formControlName="ESTUDIO">
                </div>

                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO"> Precio público: </label>
                        <input type="number" ng-maxlength="6" min="1" pattern="\d*" max="900000" name="PRECIO_PUBLICO" id="PRECIO_PUBLICO" class="form-control" placeholder="Precio público" formControlName="PRECIO_PUBLICO">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA"> Precio membresía: </label>
                        <input type="number" min="1" max="900000" name="PRECIO_MEMBRESIA" id="PRECIO_MEMBRESIA" class="form-control" placeholder="Precio membresia" formControlName="PRECIO_MEMBRESIA">
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_URGENCIA ">Precio público urgencia:</label>
                        <input type="number" min="1" max="900000" name="PRECIO_PUBLICO_URGENCIA " id="PRECIO_PUBLICO_URGENCIA " placeholder="Precio publico urgencia" class="form-control" formControlName="PRECIO_PUBLICO_URGENCIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_URGENCIA ">Precio membresía urgencia:</label>
                        <input type="number" min="1" name="PRECIO_MEMBRESIA_URGENCIA " id="PRECIO_MEMBRESIA_URGENCIA " placeholder="Precio membresia urgencia" class="form-control " formControlName="PRECIO_MEMBRESIA_URGENCIA">
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_HOSPITALIZACION ">Precio público hospitalización:</label>
                        <input type="number" min="1" max="900000" name="PRECIO_PUBLICO_HOSPITALIZACION " id="PRECIO_PUBLICO_HOSPITALIZACION " placeholder="Precio público hospitalización" class="form-control " formControlName="PRECIO_PUBLICO_HOSPITALIZACION">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_HOSPITALIZACION ">Precio membresía hospitalización:</label>
                        <input type="number" min="1" max="900000" name="PRECIO_MEMBRESIA_HOSPITALIZACION " id="PRECIO_MEMBRESIA_HOSPITALIZACION " placeholder="Precio membresia hospitalización" class="form-control " formControlName="PRECIO_MEMBRESIA_HOSPITALIZACION">
                    </div>
                </div>

                <div class="form-row" *ngIf="id == 'ultrasonido' || id == 'tomografia' || id == 'resonancia'">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA ">Precio público hospitalización urgencia:</label>
                        <input type="number" min="1" max="900000" name="PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA " id="PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA " placeholder="Precio público hospitalizacion urgencia" class="form-control " formControlName="PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA">Precio membresia hospitalización urgencia:</label>
                        <input type="number" min="1" max="900000" name="PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA " id="PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA " placeholder="Precio membresia hospitalización urgencia" class="form-control " formControlName="PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-block " [disabled]="forma.invalid ">  Enviar </button>
                </div>
            </form>
        </div>
    </div>
</section>