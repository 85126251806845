import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-disponentes-bitacora',
  templateUrl: './disponentes-bitacora.component.html',
  styleUrls: ['./disponentes-bitacora.component.css']
})
export class DisponentesBitacoraComponent implements OnInit {

  public pacientes:any [] = [];

  constructor(private _banco:BancoService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getBitacora();
    this.spinner.show();
  }

  getBitacora(){
    this._banco.getEnfermeria().subscribe((resp:any)=>{
      console.log(resp);
      
      this.pacientes = resp['data'];
      this.spinner.hide();
      this.pacientes.reverse();   
      /* console.log(this.pacientes); */
    })
  }
}
