<div class="container-fluid">
    <h1 class="centerxd">BITÁCORA DE PEDIDOS</h1>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>

                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>GRUPO Y RH</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTATUS</p>
                        </div>
                        <div class="col text_header">
                            <p>PRECESO</p>
                        </div>
                        <div class="col text_header">
                            <p>MOTIVO</p>
                        </div>
                        <div class="col text_header">
                            <p>TIEMPO DE DIFERIDO</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div>
                        <div class="row" id="pointer"  *ngFor="let pedido of hojasSErvcios; let i = index"  >

                            <div class="col-md-1" style="text-align: left;">
                                <p>
                                    {{  i + 1 }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{  pedido.paciente.apellidoPaterno  }} {{ pedido.paciente.apellidoMaterno }} {{ pedido.paciente.nombrePaciente }}
                                </p>
                            </div>
                            <div class="col-md-1" style="text-align: center;">
                                <p>
                                   {{ pedido.paciente.genero }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ pedido.paciente.edad }} AÑOS
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    A+
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    DIFERIDO
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    AUTOEXCLUSIÓN
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    TATUAJES RECIENTES
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    12 MESES
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>