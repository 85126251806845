import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Cie10Service } from 'src/app/services/consultas/cie10.service';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import { USGService } from 'src/app/services/usg/usg.service';
import { XRAYService } from 'src/app/services/Rayos-X/xray.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-hoja-evolucion-historico',
  templateUrl: './hoja-evolucion-historico.component.html',
  styleUrls: ['./hoja-evolucion-historico.component.css']
})
export class HojaEvolucionHistoricoComponent implements OnInit {

  hojasEvoluciones: any;


  constructor(
    private _HistoriaClinicaService: HistoriaClinicaService,
    private _route: ActivatedRoute,
    private _Cie10Service: Cie10Service,
    private _consultaService: ConsultaService,
    private _router: Router,
    private _laboratorio: LaboratorioService,
    private _usgService: USGService,
    private _xrayServices: XRAYService

  ) { }

  public fecha = "";
  public hora = "";
  public id = "";
  public edadMeses25 = "";
  public diagnosticoTxt: string;
  public diagnosticos: [] = [];
  public diagnostico: any[] = [];


  // este json se envia a la db para actualizar la info de la consulta
  public hojaEvolucion = {
    diagnosticos: [],
    diagnosticoActual: "",
    fechaHojaEvolucion: '',
    horaHojaEvolucion: '',
    evolucion: '',
    idConsulta: '',
    idMedico: '',
    idPaciente: '',
    plan: '',
    motivoDeConsulta: '',
  }

  public antecedentesPersonalesNoPatologicos = {
    tabaquismoPorDia: "",
    aniosConsumo: "",
    exFumadorPasivo: "",
    alcoholismoPorDia: "",
    aniosDeconsumoAlcohol: "",
    exAlcoholicoUOcasional: "",
    alergias: "",
    tipoAlergias: "",
    tipoSanguineo: "",
    desconoceTipoSanguineo: "",
    drogadiccionTipo: "",
    aniosConsumoDrogas: "",
    exDrogadicto: "",
    alimentacionAdecuada: "",
    viviendaConServiciosBasicos: "",
    otrosAntecedentesNoPatologicos: "",
    idPaciente: ""
  }

  public tallaInput = [];
  public historialSginos = [];
  public historialGraficas = [];


  public paciente = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    municipio: '',
    edad: 0,
    curp: '',
    idPaciente: '',
    sexo: '',
    registro: '',
    calle: '',
    talla: 0,
    temp: '',
    peso: 0,
    pa: '',
    pt: '',
    pc: '',
    imc: 0,
    fc: '',
    fr: '',
    lpm: '',
    sistolica: '',
    diastolica: '',
    apgar: '',
    sao: '',
    rpm: '',
    pao: '',
    glucosa: '',
    alergias: "",
    idAntecedente: "",
    _id: ""
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% LABORATORIOS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

  public resultado = [{
    idEstudio: {
      ELEMENTOS: [{
        elementos: "",
        referencia: "",
        tipo:""
      }
      ]
    },
    obtenidos: {

    },
    idPaciente: [],
    idPedido: ""
  }]

  public obtenido = [];



  ///%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5 ULTRASONIDOS %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  public ultrasonidos = {
    imageUrl: [],
    idEstudio: {
      ELEMENTOS: [{
        machote: ""
      }],
      ESTUDIO: ""
    },
    diagnostico: "",
    machoteEdit: "",
    fecha: "",
    idPaciente: [],
  }

  public titulo;
  public machoteEditado;
  public diag;
  public observa;
  public fechaAntes;
  public imagenes: []

  public dates: []
  public imgss: [{ imageUrl: [] }]

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% VARIABLES XRAY %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  public xray = {
    imageUrl: [],
    idEstudio: {
      ELEMENTOS: [{
        machote: ""
      }],
      ESTUDIO: ""
    },
    diagnostico: "",
    machoteEdit: "",
    fecha: "",
    idPaciente: [],
  }

  public titulox;
  public machoteEditadox;
  public diagx;
  public observax;
  public fechaAntesx;
  public imagenesx: []

  public datesx: []
  public imgssx: [{ imageUrl: [] }]
  public tipos = [];
  public result=[];
  public obtenido2=[];
  // %%%%%%%%%%%%%%5%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%




  ngOnInit(): void {

    this.fecha = moment().format('L');
    this.hojaEvolucion.fechaHojaEvolucion = moment().format('L');
    this.hojaEvolucion.horaHojaEvolucion = moment().format('LTS');


    // este id es de la consulta
    this.id = this._route.snapshot.paramMap.get('id');


    this.obtenerConsulta();
    
    this.getMedicoData();
    this.hojaEvolucion.idConsulta = this.id;

    this.obtenerIMC();

    


  }

  obtenerIMC() {



    this.paciente.imc = ((this.paciente.peso) / (this.paciente.talla * this.paciente.talla))

    let imcL = this.paciente.imc.toString();
    imcL.split(',', 2);

    let imcLn;
    imcLn = parseFloat(imcL).toFixed(2);

    this.paciente.imc = imcLn;

  }

  verSignosVitalesAnteriores() {


    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {

        
        let graf = data['data'];
        graf.forEach(element => {

          if (element == null) {
            return;

          } else {
            // console.log(element.temp);
            this.lineTemp[0].data.push(element.temp);
            this.lineChartLabels.push(element.fechaIngreso)
          }

        });
      });
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%    regresos laboratorio %%%%%%%%%%%%%%%%%%%%%%
  obtenerResultados() {
    // console.log(this.paciente.idPaciente)
    // this._laboratorio.obtenerLaboratorioEvolucion(this.paciente.idPaciente)
    //   .subscribe((data) => {
    //     this.setEstudio(data['data'])
    //   })

  }
  setEstudio(estudio) {
    this.resultado = estudio
    // console.log(this.resultado);
    
    if (!this.resultado[0]) {
      return;
    }
    // console.log(Object.keys(this.resultado[0].obtenidos));
    for (let index = 0; index < estudio.length; index++) {
      this.res(estudio[index])      
    }

    this.obtenido2=this.obtenido[0];

    for(let item of this.resultado){
      for(let items of item.idEstudio.ELEMENTOS){
          // se hace el push al elemento 0 del arreglo 
        if(this.tipos.length == 0){
            this.tipos.push(items.tipo);  
         }else{
           // busca los elementos dentro del arreglo
    
           const encontrar= this.tipos.find(element => element == items.tipo)
           if(encontrar){
              this.tipos.push("")
              // se hace un push de elementos vacios
           }else{
             // un push del elemento nuevo
             this.tipos.push(items.tipo)
           }
         }   
      }
    }

  }

  res(elements){
    this.result=[]
    let obt={}
    obt= elements.obtenidos[0]
    for (const it in obt) {
      this.result.push(obt[it])
    }
    this.obtenido.push(this.result);
  }



  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%



  // ######################################### REGRESO ULTRASONIDO #############################################

  setPeidoUltra(pedido) {
    this.ultrasonidos = pedido;
    this.dates = pedido;
    this.imgss = pedido


    if (!this.ultrasonidos) {
      return;
    }

    this.diag = this.ultrasonidos[0].diagnostico;
    this.titulo = this.ultrasonidos[0].idEstudio.ESTUDIO;
    this.machoteEditado = this.ultrasonidos[0].machoteEdit;
    this.observa = this.ultrasonidos[0].observaciones;
    this.fechaAntes = this.ultrasonidos[0].fecha;
    this.imagenes = this.ultrasonidos[0].imageUrl;
    // Ilmer guarda el valor del diagnostico
  }
  // hfbdhbhf
  mostarUsg() {
    //  const id= JSON.parse (localStorage.getItem('idPaciente'))._id;

    this._usgService.obtenerUsgHC(this.paciente.idPaciente)
      .subscribe((data: any) => {
        if (data.ok) {
          this.setPeidoUltra(data['data'])

        }

      })


  }

  obtener(i){
    this.obtenido2=this.obtenido[i];
  }

  public idContentB: number = 0;
  selectContentB(index: number) {
    this.idContentB = index;
    this.obtener(index);
  }

  public idContentC: number = 0;
  selectContentC(index: number) {
    this.idContentC = index;
  }

  /*public resultado = [{
    idEstudio: {
      ELEMENTOS: [{
        elementos: "",
        referencia: ""
      }
      ]
    },
    obtenidos: {

    },
    idPaciente: [],
    idPedido: ""
  }]*/

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


  // ############################################################################################################
  //                                                   RAYOS X
  // ############################################################################################################

  setPeidoXray(xray) {
    this.xray = xray;
    this.datesx = xray;
    this.imgssx = xray
    // console.log(this.datesx);
    // console.log(this.imgss);

    if (!this.xray) {
      return;
    }

    //  this.diag = this.ultrasonidos[0].diagnostico;
    //  this.titulo = this.ultrasonidos[0].idEstudio.ESTUDIO;
    //  this.machoteEditado = this.ultrasonidos[0].machoteEdit;
    //  this.observa = this.ultrasonidos[0].observaciones;
    // this.fechaAntes = this.ultrasonidos[0].fecha;
    // this.imagenes= this.ultrasonidos[0].imageUrl;
    // Ilmer guarda el valor del diagnostico
  }

  XrayResultados() {
    this._xrayServices.obtenerXrayRecepcionHC(this.paciente.idPaciente)
      .subscribe((data: any) => {
        if (data.ok) {
          this.setPeidoXray(data['data'])
        }

      })
  }

  public idContentXray: number = 0;

  ContenidoXray(index: number) {
    this.idContentXray = index;
  }

  public idContentEvolucion: number = 0;

  ContentHistoricoEvolucion(index: number) {
    this.idContentEvolucion = index;
  }


  // ###############################################################################################################
  cerrarModalGraficas() {
    this.lineTemp[0].data = [];
    this.lineChartLabels = [];
  }
  graficaTallaL() {
    // console.log(this.paciente.idPaciente);
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let grafTalla = data['data'];

        grafTalla.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.lineChartData[0].data.push(element.talla)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficaPeso() {
    // console.log(this.paciente.idPaciente);
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let grafPeso = data['data'];

        grafPeso.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.linePeso[0].data.push(element.peso)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficaIMC() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let grafIMC = data['data'];

        grafIMC.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.lineIMC[0].data.push(element.imc)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarFC() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graFC = data['data'];

        graFC.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.lineFC[0].data.push(element.fc)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarFR() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graFR = data['data'];

        graFR.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.lineFR[0].data.push(element.fr)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarPC() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graPC = data['data'];

        graPC.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.linePC[0].data.push(element.pc)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarPA() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graPA = data['data'];

        graPA.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.linePA[0].data.push(element.pa)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarPT() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {

        // console.log(data);

        let graPT = data['data'];

        graPT.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.linePT[0].data.push(element.pt)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarSAO() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {

        let graSAO = data['data'];

        graSAO.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.lineSAO[0].data.push(element.SaO)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarGlucosa() {
    // console.log(this.paciente.idPaciente)
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graGluc = data['data'];

        graGluc.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.lineGlucosa[0].data.push(element.glucosa)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarPresion() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graTA = data['data'];

        graTA.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.linePresion[0].data.push(element.sistolica)
            this.linePresion[1].data.push(element.diastolica)
            this.linePresion[2].data.push(140)
            this.linePresion[3].data.push(90)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }
  graficarPAO() {
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente.idPaciente)
      .subscribe((data) => {
        // console.log(data);

        let graPAO = data['data'];

        graPAO.forEach(element => {
          if (element == null) {
            return;
          } else {
            this.linePAO[0].data.push(element.pao)
            this.linePAO[1].data.push(95)
            this.linePAO[2].data.push(85)
            this.lineChartLabels.push(element.fechaIngreso)

          }
        });
      });
  }






  obtenerConsulta() {
    // id de la consulta

    this._HistoriaClinicaService.obtenerConsultaPorElId(this.id)
      .subscribe(
        (data: any) => {
          // console.log(data);
          
          this.hora = data['data'].horaIngreso;
          this.paciente.nombre = data['data']['paciente']['nombrePaciente'];
          this.paciente.apellidoPaterno = data['data']['paciente'].apellidoPaterno;
          this.paciente.apellidoMaterno = data['data']['paciente'].apellidoMaterno;
          this.paciente.edad = data['data']['paciente'].edad;
          this.paciente.curp = data['data']['paciente'].curp;
          this.paciente.idPaciente = data['data']['paciente']['_id'];
          this.paciente.sexo = data['data']['paciente']['genero'];
          this.paciente.registro = data['data']['paciente']['fechaRegistro'];
          this.paciente.municipio = data['data']['paciente']['municipio'];
          this.paciente.calle = data['data']['paciente']['callePaciente'];
          this.paciente.talla = data['data']['talla'];
          this.paciente.temp = data['data']['temp'];
          this.paciente.peso = data['data']['peso'];
          this.paciente.pt = data['data']['pt'];
          this.paciente.pc = data['data']['pc'];
          this.paciente.pa = data['data']['pa'];
          this.paciente.imc = data['data']['imc'];
          this.paciente.fc = data['data']['fc'];
          this.paciente.sistolica = data['data']['sistolica'];
          this.paciente.diastolica = data['data']['diastolica'];
          this.paciente.apgar = data['data']['apgar'];
          this.paciente.sao = data['data']['SaO'];
          this.paciente.pao = data['data']['pao'];
          this.paciente.fr = data['data']['fr'];
          this.paciente.idAntecedente = data['data']['paciente']['antecedente'];
          this.paciente.glucosa = data['data']['glucosa'];
          this.hojaEvolucion.idPaciente = data['data']['paciente']['_id'];
          this.hojaEvolucion = data['data']['idHojaEvolucion']
          // this.soloSignos(data['data']['temp'])


        });
  }

  soloSignos(signos) {
    // console.log(signos);

  }

  getMedicoData() {


    this.hojaEvolucion.idMedico = getDataStorage()._id;

  }

  // este servicio obtiene los diagnosticos del cie10
  obtenerDiagnosticoUno() {

    if( this.diagnosticoTxt.length == 0 ){
      this.diagnosticos = [];
    }

    if( this.diagnosticoTxt.length <= 2 ){
      this.diagnosticos = []
    }

    if (this.diagnosticoTxt.length > 3) {
      this._Cie10Service.getCiePorNombre(this.diagnosticoTxt).subscribe(
        (data: any) => {
          // console.log(data);
          this.diagnosticos = data.data;
        });
    }

  }

  validarDiagnosticos() {
    if (this.hojaEvolucion.diagnosticos.length < 1) {
      return false;
    } else {
      return true;
    }

  }




  agregarDiagnosticoUno(item) {

    this.diagnosticoTxt = "";
    
    let diagnostico = { diagnostico: item }

    this.hojaEvolucion.diagnosticos.push(diagnostico);
    // console.log(this.hojaEvolucion);
    this.diagnosticos = [];
  }

  obtenerHojasDeEvolucion(){
    this._HistoriaClinicaService.verHistorialDeEvoluciones( this.paciente.idPaciente )
    .subscribe( (data) => {
      // console.log( data );
      this.setHistoricoEvolucion( data['data'] )
    } )
  }



  setHistoricoEvolucion(  evoluciones){
    this.hojasEvoluciones = evoluciones;
    // console.log( this.hojasEvoluciones )
    if( !this.hojasEvoluciones ){
      return
    }
  } 


  validateForm(){
    
    if(this.hojaEvolucion.evolucion == "" || this.hojaEvolucion.plan == ""){
        Swal.fire('Ingresa los datos', '', 'error');
        return false;
    }else {
      return true
    }
  }

  public agregarHojaEvolucion() {

    if( this.validateForm() ){

      this._consultaService.agregarHojaEvolucion(this.hojaEvolucion)
      .subscribe(data => {

        if (data['ok']) {

          let body = {
            idHojaEvolucion: data['data']['_id']
          }

          this._consultaService.agregarHojaEvolucionEnConsulta(this.id, body)
            .subscribe((data) => {

              
              if (data['ok']) {

                  Swal.fire('Datos agregados', 'Exito', 'success')
                this._router.navigateByUrl('/receta/medica/' + this.id);
                
              }
            });

        }
      });
      
    }else {
      return false;
    }

  }


  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'Historial Talla' }
  ];
  public linePeso: ChartDataSets[] = [
    { data: [], label: 'Historial Peso' }
  ];
  public lineIMC: ChartDataSets[] = [
    { data: [], label: 'Historial IMC' }
  ];
  public lineFC: ChartDataSets[] = [
    { data: [], label: 'Historial Frecuencia Cardiaca' }
  ];
  public lineFR: ChartDataSets[] = [
    { data: [], label: 'Historial Frecuencia Respiratoria' }
  ];
  public lineTemp: ChartDataSets[] = [
    { data: [], label: 'Historial Temperatura' }
  ];
  public linePC: ChartDataSets[] = [
    { data: [], label: 'Historial Perimetro Cefálico' }
  ];
  public linePA: ChartDataSets[] = [
    { data: [], label: 'Historial Perimetro Abdominal' }
  ];
  public linePT: ChartDataSets[] = [
    { data: [], label: 'Historial Perimetro Torácico' }
  ];
  public lineSAO: ChartDataSets[] = [
    { data: [], label: 'Historial Saturación de Oxígeno' }
  ];
  public lineGlucosa: ChartDataSets[] = [
    { data: [], label: 'Historial Glucosa' }
  ];
  public linePAO: ChartDataSets[] = [
    { data: [], label: 'Historial PaO' },
    { data: [], label: 'Máxima' },
    { data: [], label: 'Mínima' }
  ];
  public linePresion: ChartDataSets[] = [
    { data: [], label: 'Historial Sistólica' },
    { data: [], label: 'Historial Diastólica' },
    { data: [], label: 'Sistólica Máxima' },
    { data: [], label: 'Diastólica Máxima' }
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // blue
      // backgroundColor: 'rgba(50, 87, 231, 0.9)',
      borderColor: 'blue',
      pointBackgroundColor: 'blue',
      pointBorderColor: 'blue',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      // backgroundColor: 'rgba(53, 126, 87, 0.9)',
      borderColor: 'green',
      pointBackgroundColor: 'green',
      pointBorderColor: 'green',
    },
    {
      borderColor: 'red',
      pointBackgroundColor: 'red',
      pointBorderColor: 'red',
    },
    {
      borderColor: 'yellow',
      pointBackgroundColor: 'yellow',
      pointBorderColor: 'yellow'
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  // public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;


  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number): number {
    return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.forEach((x, i) => {
      const num = this.generateNumber(i);
      const data: number[] = x.data as number[];
      data.push(num);
    });
    this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  }

  public changeColor(): void {
    this.lineChartColors[2].borderColor = 'green';
    this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  }

  public changeLabel(): void {
    this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  }

}
