import { Injectable } from '@angular/core';
declare global {
	interface Window {
		OneSignal: any;
	}
}

@Injectable({
  providedIn: 'root'
})
export class OnesignalService {

  constructor() { }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function() {
        resolve(window.OneSignal);
      });
    });
  }

  onInit():void {
    this.onLoad().then((OneSignal)=>{
      OneSignal.init({
        appId: "f1649780-711b-4045-ad71-af4664b89496",
      });
    });
  }
  sendTags(user:any):void {
    this.onLoad().then((OneSignal)=>{
      OneSignal.sendTags({
        user: user,
      });
    });
  }
}
