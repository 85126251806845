<div class="container-fluid">
    <section>
        <app-titulos [title]="'FICHA DE ENFERMERÍA'" [rol]="'enfermeria'"></app-titulos>
        <!-- <app-ficha-enfermeria-disponente [pacienteInfo]="paciente" (newItemEvent)="relig($event)"></app-ficha-enfermeria-disponente> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'enfermeria'" (newItemEvent)="relig($event)"></app-fichas-identificacion>
    </section>
    
        <div class="card" name="card" id="card">
            <form class="container-fluid" #f='ngForm'>
                <div class="row">
                    <div class="col">
                        <label for="talla">TALLA (m)</label>
                        <input type="text" name="talla" required  class="form-control" [(ngModel)]="talla">
                    </div>
                    <div class="col">
                        <label for="peso">PESO (Kg)</label>
                        <input type="text" name="peso" [(ngModel)]="peso" (change)="obtenerIMC()" required class="form-control">
                    </div>
                    <div class="col">
                        <label for="imc">IMC</label>
                        <input type="text" name="imc" required class="form-control" [(ngModel)]="imc" [value]="imc">
                    </div>
                    <div class="col">
                        <label for="">SISTÓLICA</label>
                        <input type="text" name="sistolica" [(ngModel)]="signosVitales.sistolica"  class="form-control">
                    </div>
                    <div class="col">
                        <label for="">DIASTÓLICA</label>
                        <input type="text" name="diastolica" [(ngModel)]="signosVitales.diastolica"  class="form-control">
                    </div>
    
                    <div class="col">
                        <label for="">TEMPERATURA (°C)</label>
                        <input type="text" name="temp" [(ngModel)]="signosVitales.temp" class="form-control">
                    </div>
    
                </div>
    
    
                <div class="row" id="venopuncion">
                    <div class="col-md-4">
                        <label for="">HUELLAS DE VENO-PUNCIÓN</label>
                    </div>
                    <div class="col-md-1">
                        <label for="" id="multiples">MÚLTIPLES</label>
                    </div>
                    <div class="col">
                        <input type="text" name="multiples" [(ngModel)]="signosVitales.multiples" class="form-control">
                    </div>
                    <div class="col-md-1">
                        <label for="" id="recientes">RECIENTES</label>
                    </div>
                    <div class="col">
                        <input type="text" name="recientes" [(ngModel)]="signosVitales.recientes" class="form-control" (blur)="validacionFormSignos()">
                    </div>
                </div>
    
                <!-- <div class="row" id="validacion">
                    <div class="col">
                        <label for=""> ¿PUEDE EL DISPONENTE CONTINUAR CON EL PROCESO DE DONACIÓN? </label>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="SI">
                                <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" value="no" name="NO">
                                <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                       <textarea name="" id="" cols="30" rows="10">

                       </textarea> 
                    </div>
                </div> -->
    
                <div class="center">
                    <button class="principalButton" [disabled]="btnSignosVitales" data-toggle="modal" data-target="#preregistro" (click)="ageragrSignos()">Guardar</button>
                </div>      
            </form>

            <div class="modal fade" id="preregistro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            EL RESULTADO ES {{respuesta.respuestas}}/8
                         </div>
                        <div class="modal-body">
                            <p *ngIf="respuesta.diferir == false" id="sipuede">EL DISPONENTE PUEDE CONTINUAR CON EL PROCESO</p>
                            <!-- <p> ¿PUEDE EL DONADOR CONTINUAR CON EL PROCESO DE DONACIÓN? </p>
                            <div class="form-group" id="diferir">
                                <div class="form-check form-check-inline">
                                    <input type="radio" value="SI" name="diferir" ngModel>
                                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" value="NO" name="diferir" ngModel>
                                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                                </div>
                            </div>-->
                            <p *ngIf="respuesta.diferir == true" id="nopuede">EL DISPONENTE NO PUEDE CONTINUAR CON EL PROCESO</p>
                            <textarea *ngIf="respuesta.diferir == true" name="motivo" id="motivo" cols="30" rows="10" placeholder="COMENTARIOS">{{respuesta.diferido[0].motivo}}</textarea>
                            <div class="center">
                                <button class="principalButton" (click)="enviar(respuesta)" data-dismiss="modal"> ACEPTAR </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</div>

