import { Component, OnInit } from '@angular/core';
import { ServiciosService } from 'src/app/services/admin/servicios.service';

@Component({
  selector: 'app-edita-laboratorios',
  templateUrl: './edita-laboratorios.component.html',
  styleUrls: ['./edita-laboratorios.component.css']
})
export class EditaLaboratoriosComponent implements OnInit {

  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno:"",
    genero:"",
    edad:0,
  }

  constructor(
    private serviciosService: ServiciosService
  ) { }

  ngOnInit(): void {
    this.getEstudios();
  }


  getEstudios(){
    this.serviciosService.getResultadoPorEstudio('61e5c1011d866309789b3e40')
    .subscribe((data:any) => {
      if( data.ok){    
        this.setDataPacienes( data.data.paciente );
      }
    }
    );
  }


  setDataPacienes(data) {
    this.paciente = data;
  }

}
