import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterResultados } from 'src/app/classes/buscarServicos/buscarServicos';
import { ServiciosService } from 'src/app/services/admin/servicios.service';

@Component({
  selector: 'app-bitacora-patologias',
  templateUrl: './bitacora-patologias.component.html',
  styleUrls: ['./bitacora-patologias.component.css']
})
export class BitacoraPatologiasComponent implements OnInit {
  public consultas:any[]=[]
  public totalpaciente:string;
  public pagina = 0;
  public pedidosPato = [];

  constructor(private spinner : NgxSpinnerService,
              private _servicios: ServiciosService) { }

  ngOnInit(): void {
    this.spinner.show()
    this.obtenerCosnultaUltra()
  }

  obtenerCosnultaUltra(){
    this._servicios.getBitacoraLabs('patologia')
    .subscribe((data:any) => {
      if( data.ok ) {
        this.consultas = this.setRegresos( data.data )
        this.spinner.hide();
      }
    })
  }

  busquedaGeneral(event:any){
    if (event.target.value == '') {
      this.consultas = this.pedidosPato;
    } else if (event.target.value.length >= 3){
      this.consultas = filterResultados(this.pedidosPato, event.target.value)
    }
  }

  setRegresos(patologias:any){
    patologias.forEach(element => {
      if (element.idPaciente != null) {
        this.consultas.push(element)
      }
    });
    var found ={};
    var resp = this.consultas.filter(function(element){
      return found.hasOwnProperty(element.idPaciente._id)? false : (found[element.idPaciente._id]=true);
    });
    this.pedidosPato = resp;
    return resp.reverse();
  }

  // setDataUltras(data:any) {
  //   console.log(data);
    
  //   this.consultas = data.reverse();
  // }

}
