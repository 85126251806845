<section class="container-fluid" style="height: 40em;">

    <h1 class="centerxd" id="centerxd">Editar</h1>

    <div class="card" id="card">
        <div class="container">
            <form #ambulanciaForm="ngForm" (ngSumbit)="onSubmit()">
                <div class="form-group">
                    <label for="DESTINO" class="col-form-label">Destino:</label>
                    <input type="text" name="DESTINO" #destino="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.DESTINO">
                </div>
                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_DIA" class="col-form-label">Precio público día:</label>
                        <input type="text" name="PRECIO_PUBLICO_DIA" #pDia="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_PUBLICO_DIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_PUBLICO_REDONDO_DIA" class="col-form-label"> Precio público redondo día:</label>
                        <input type="text" name="PRECIO_PUBLICO_REDONDO_DIA" #pRDia="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_PUBLICO_REDONDO_DIA">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_NOCHE" class="col-form-label">Precio público noche:</label>
                        <input type="text" name="PRECIO_PUBLICO_NOCHE" #pNoche="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_PUBLICO_NOCHE">
                    </div>
                    <div class="col">
                        <label for="PRECIO_PUBLICO_REDONDO_NOCHE" class="col-form-label">Precio público redondo noche:</label>
                        <input type="text" name="PRECIO_PUBLICO_REDONDO_NOCHE" #pRNoche="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_PUBLICO_REDONDO_NOCHE">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_DIA" class="col-form-label">Precio membresia día:</label>
                        <input type="text" name="PRECIO_MEMBRESIA_DIA" #pRNoche="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_MEMBRESIA_DIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_NOCHE" class="col-form-label">Precio membresia noche:</label>
                        <input type="text" name="PRECIO_MEMBRESIA_NOCHE" #pRNoche="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_MEMBRESIA_NOCHE">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_REDONDO_DIA" class="col-form-label">Precio membresia redondo día:</label>
                        <input type="text" name="PRECIO_MEMBRESIA_REDONDO_DIA" #pRNoche="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_MEMBRESIA_REDONDO_DIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_REDONDO_NOCHE" class="col-form-label">Precio membresia redondo noche:</label>
                        <input type="text" name="PRECIO_MEMBRESIA_REDONDO_NOCHE" #pRNoche="ngModel" class="form-control" id="recipient-name" [(ngModel)]="ambulanciaEdit.PRECIO_MEMBRESIA_REDONDO_NOCHE">
                    </div>
                </div>
                <div class="form-row" style="margin: 2rem 0rem 2rem 25rem">
                    <div class="col-12">
                        <button type="button" class="btn btn-secondary" style="border-radius: 30px; width: 20%; margin-right: 1em;" data-dismiss="modal">Cancelar</button>
                        <!-- <button type="button" class="btn btn-primary" (click)="onSubmit()">Aceptar</button> -->
                        <input type="submit" value="Guardar" class="btn btn-primary" style="border-radius: 30px; width: 20%;" (click)="onSubmit()">
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>