<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="titulo"></app-titulos>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>DESCARGAR</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <div *ngFor="let item of consultas | paginate:{id:'listapacientes',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer" [routerLink]="['/resultado-servicios', item._id]">
                            <div class="col-md-1">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.fecha}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.edad}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.genero | generos:true }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.sede}}</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{item.estudios[0].nombreEstudio}}</p>
                            </div>
                        </div>
                        [routerLink]="['/final/obtenidos', item._id]"
                    </div> -->
                        <div class="row pointer puntero" *ngFor="let item of pedidosLab; let i = index">
                            <div class="col-md-1">
                                <p>{{ i + 1 }}</p>
                            </div>
                            <div class="col-md-2">
                                <p>{{ item.fechaPedido | date: 'dd/MM/yyyy':'UTC' }}</p>
                            </div>
                            <div class="col">
                                <p [routerLink]="['/final/obtenidos', item._id]">{{ item.idPaciente.nombrePaciente }} {{ item.idPaciente.apellidoPaterno }} {{ item.idPaciente.apellidoMaterno }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ item.idPaciente.edad }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ item.idPaciente.genero }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.sede }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.idServicio.ESTUDIO }}</p>
                            </div>
                            <div class="col">
                                <a (click)="obtenerVAlores(item)" *ngIf="nombreServicio != 'endoscopia'">
                                    <i class="fa fa-print" id="ver"></i>
                                </a>
                                <a (click)="descargarEndos(item.resultados[0].obtenidos.imgs[0].base,item.resultados[0].obtenidos.imgs[0].name)" *ngIf="nombreServicio == 'endoscopia'">
                                    <i class="fa fa-print" id="ver"></i>
                                </a>
                                <!-- <button (click)="obtenerVAlores(item)">DESCARGAR</button> -->
                            </div>
                        </div>
                </div>
                <!-- <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div> -->
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>