import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AgregarGinecoObstetricosPorVisita } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';

@Component({
  selector: 'app-gineco-por-visita',
  templateUrl: './gineco-por-visita.component.html',
  styleUrls: ['./gineco-por-visita.component.css']
})
export class GinecoPorVisitaComponent implements OnInit {

  @Input() _id='';
  @Input() agregarGinecoObstetricosPorVisita: AgregarGinecoObstetricosPorVisita = {} as AgregarGinecoObstetricosPorVisita;
  
  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarGinecoObstetricos(form: NgForm){
    this.agregarGinecoObstetricosPorVisita = form.value
    this.agregarGinecoObstetricosPorVisita.idPaciente = this._id;
    this._HistoriaClinicaService.agregarGinecoObstetricosPorVisita ( this.agregarGinecoObstetricosPorVisita )
    .subscribe((data:any) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Se guardaron los Antecedentes Gineco Obstetricos Historia por Visita',
        })
      }
    });
  }

  actualizarGinecoPorVisitalt(form: NgForm){
    let id = this.agregarGinecoObstetricosPorVisita._id;
    this.agregarGinecoObstetricosPorVisita = form.value;
    this.agregarGinecoObstetricosPorVisita.idPaciente = this._id;
    this.agregarGinecoObstetricosPorVisita._id = id
    this._HistoriaClinicaService.actualizarGinecoPorVisita(this.agregarGinecoObstetricosPorVisita._id, this.agregarGinecoObstetricosPorVisita)
    .subscribe((data:any) => {
      if(data['ok']){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZARON LOS ANTECEDENTES',
        })
      }
    })
  }

}
