import { Component, OnInit } from '@angular/core';
import { HospitalizacionService } from 'src/app/services/hospitalizacion/hospitalizacion.service';
import { Hospitalizacion } from './hospitalizacion.interface';

@Component({
  selector: 'app-bitacora-hospitalizacion',
  templateUrl: './bitacora-hospitalizacion.component.html',
  styleUrls: ['./bitacora-hospitalizacion.component.css']
})
export class BitacoraHospitalizacionComponent implements OnInit {

  public hospitalizados: Hospitalizacion[];

  constructor(
    private _HospitalizacionService: HospitalizacionService
  ) { }

  ngOnInit(): void {
    this.getBitacoraHospitalizados();
  }

  getBitacoraHospitalizados(){
    this._HospitalizacionService.getBitacoraHospitalizacion()
    .subscribe((data: any ) => {
      this.hospitalizados = data.data;
    })
  }

}