<div class="container">
    <section>
        <app-titulos [title]="'ORDEN DE SERVICIO'" [rol]="'laboratorio'"></app-titulos>
        <!-- <app-ficha-laboratorio-disponente [titulo]="'ORDEN DE SERVICIO'" [pacienteInfo]="paciente"></app-ficha-laboratorio-disponente> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'laboratorio'"></app-fichas-identificacion>
    </section>
</div>


<div class="card cardPer container">
    <form #form='ngForm'>
        <div class="row">
            <h2>SEROLOGÍA</h2>

        </div>
        <div class="row letras">
            <div class="col">
                <h3>elementos</h3>
            </div>
            <div class="col">
                <h3>Resultados Obtenidos</h3>
            </div>
            <div class="col">
                <h3>Método</h3>
            </div>
        </div>

        <div class="row letras" *ngFor="let item of elementos; let i = index" style="margin-top: 15px;">
            <div class="col">
                <h3>{{item.elemento}}</h3>
            </div>
            <div class="col">
                <select class="custom-select pointer" id="inputGroupSelect01" name="resultado{{i}}" class="form-control" [(ngModel)]='resultado[i]'>
                    <option >seleccione una opción...</option>
                    <option value="REACTIVO">REACTIVO</option>
                    <option value="NO REACTIVO" selected>NO REACTIVO</option>
                  </select>
            </div>
            <div class="col">
                <h3>{{item.metodo}}</h3>
            </div>
        </div>

        <ng-container *ngIf="tipo_de_sangreado =='SANGRE TOTAL'">
            <div class="row letras">
                <h3>Concentrado eritrocitario</h3>
            </div>
            <div class="row mt-3 mr-3">
                <div class="col-md-4">
                    <label for="volumen"> Volumen </label>
                    <input [(ngModel)]='seroVolObte[0].volumen' type="text" class="form-control shadow-input-bs" name="volumen" id="volumen" />
                </div>
                <div class="col-md-4">
                    <label for="volumen"> Solucion anticoagulante </label>
                    <input [(ngModel)]='seroVolObte[0].solucion' type="text" min="5" max="15" name="tiempo" class="form-control shadow-input-bs" id="tiempo" />
                </div>
                <div class="col-md-2" class="desechoContainer">
                    <input type="checkbox" class="centerCheck"  (change)="agregarADesechos($event, 'CE')"  name="desechoCE" id="desechoCE">
                    <label for="desechoCE" class="centerLabel">
                        <strong>
                            DESECHAR
                        </strong>       
                    </label>
                </div>
                <div class="col-md-2" *ngIf="motivos.motivo1" >
                    <input type="text" name="motivo1"  class="shadow-input-bs btnMotivos"  [(ngModel)]="sero.productos[0].motivoDesecho"  id="">
                </div>
            </div>
            <br>
            <div class="row letras">
                <h3>Concentrado plaquetario</h3>
            </div>
            <div class="row mt-3 mr-3">
                <div class="col-md-4">
                    <label for="volumen" (click)="agregarADesechos()"> Volumen </label>
                    <input [(ngModel)]='seroVolObte[1].volumen'  type="text" class="form-control shadow-input-bs" name="volumen" id="volumen" />
                </div>
                <div class="col-md-4">
                    <label for="volumen" (click)="agregarADesechos()"> Solucion anticoagulante </label>
                    <input  [(ngModel)]='seroVolObte[1].solucion'  type="text" min="5" max="15" name="tiempo" class="form-control shadow-input-bs" id="tiempo" />
                </div>
                <div class="col-md-2" class="desechoContainer">
                    <input type="checkbox" class="centerCheck" (change)="agregarADesechos($event, 'CP')" name="desechoCP" id="desechoCP">
                    <label for="desechoCP" class="centerLabel"> 
                        <strong>
                            DESECHAR 

                        </strong>
                    </label>
                </div>
                <div class="col-md-2" *ngIf="motivos.motivo2">
                    <input type="text" name="motivo2"  class="shadow-input-bs btnMotivos"  [(ngModel)]="sero.productos[1].motivoDesecho"  id="">
                </div>
            </div>
            <br>
            <div class="row letras">
                <h3>Plasma</h3>
            </div>
            <div class="row mt-3 mr-3">
                <div class="col-md-4">
                    <label for="volumen"> Volumen </label>
                    <input  [(ngModel)]='seroVolObte[2].volumen' type="text" class="form-control shadow-input-bs" name="volumen" id="volumen" />
                </div>
                <div class="col-md-4">
                    <label for="volumen"> Solucion anticoagulante </label>
                    <input  [(ngModel)]='seroVolObte[2].solucion' type="text" min="5" max="15" name="tiempo" class="form-control shadow-input-bs" id="tiempo" />
                </div>
                <div class="col-md-1" class="desechoContainer">
                    <input type="checkbox" class="centerCheck" (change)="agregarADesechos($event, 'PL')" name="desechoPL" id="desechoPL">
                    <label for="desechoPL" class="centerLabel"> <strong>
                        DESECHAR
                    </strong> 
                </label>
                </div>
                <div class="col-md-2" *ngIf="motivos.motivo3">
                    <input type="text" name="motivo2"  class="shadow-input-bs btnMotivos"  [(ngModel)]="sero.productos[2].motivoDesecho"  id="">
                </div>
            </div>
        </ng-container>
    </form>

    <div class="center row mt-5 letras mb-3">
        <div class="col">
            <h3>técnico:  {{quimico.nombre}}  </h3>
        </div>

        <div class="col">
            <h3>químico:  {{quimico.nombre}} </h3>
            <h3>cédula: 123456789</h3>
            <!-- TODO: QUEDA PENDIENTE AGREGAR LA CEDULA DEL QUIMICO -->
        </div>
    </div>

    <div class="center mt-2 mb-3">
        <div class="principalButton" data-toggle="modal" data-target="#serologia" (click)="validarSerologia(form)">GUARDAR</div>

        <!-- <div class="principalButton" data-toggle="modal" (click)="etiquetaLiberado()">etiqueta</div> -->
    </div>

    <div class="modal fade" id="serologia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    EL RESULTADO DEL CUESTIONARIO ES {{respuesta.respuestas}}/7
                </div>
                <div class="modal-body">
                    <p *ngIf="respuesta.diferir == false" id="sipuede">EL DISPONENTE PUEDE CONTINUAR CON EL PROCESO</p>
                    <!-- <p> ¿PUEDE EL DONADOR CONTINUAR CON EL PROCESO DE DONACIÓN? </p>
                    <div class="form-group" id="diferir">
                        <div class="form-check form-check-inline">
                            <input type="radio" value="SI" name="diferir" ngModel>
                            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" value="NO" name="diferir" ngModel>
                            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                        </div>
                    </div>-->
                    <!-- <spinner-perrucho></spinner-perrucho> -->
                    <p *ngIf="respuesta.diferir == true" id="nopuede">EL DISPONENTE NO PUEDE CONTINUAR CON EL PROCESO</p>
                    <textarea *ngIf="respuesta.diferir == true" name="motivo" id="motivo" cols="30" rows="10" placeholder="COMENTARIOS">{{respuesta.diferido[0].motivo}}</textarea>
                    <div class="center">
                        <button class="principalButton" (click)="enviar(respuesta)" data-dismiss="modal"> ACEPTAR </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <section class="display-none" id="sppiner-section">
        <div class="row">
            <div class="sppiner-bg">
                <div class="spinner-stock">
                    <div class="col-md-12">
                        <app-sppiner></app-sppiner>
                    </div>
                </div>

            </div>
        </div>
    </section> -->
</div>