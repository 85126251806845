<div class="content">
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p> ¿HA CONSUMIDO ALCOHOL EN LAS ÚLTIMAS HORAS? </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="alcohol_consumido" formControlName="alcohol_consumido" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="alcohol_consumido" formControlName="alcohol_consumido" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>¿TOMA CON REGULARIDAD ALGÚN MÉDICAMENTO?</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="medicamento_regular" formControlName="medicamento_regular" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="medicamento_regular" formControlName="medicamento_regular" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>CONSUME ETRETINATO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="consume_etretinato" formControlName="consume_etretinato" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="consume_etretinato" formControlName="consume_etretinato" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>TRATAMIENTO DENTAL RECIENTE</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="tratamiento_dental" formControlName="tratamiento_dental" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="tratamiento_dental" formControlName="tratamiento_dental" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>CIRUGÍA MAYOR RECIENTE</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="cirugia_mayor" formControlName="cirugia_mayor" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="cirugia_mayor" formControlName="cirugia_mayor" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>CIRUGÍA MENOR RECIENTE</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="cirugia_menor" formControlName="cirugia_menor" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="cirugia_menor" formControlName="cirugia_menor" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>ALERGIAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="alergias" formControlName="alergias" (focus)="activarLugar('SI')" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <!-- <input type="radio" value="NO" name="alergias" [(ngModel)]='inNop2' (blur)="formularioAntecedentesPer(form)"> -->
                        <input type="radio" value="NO" name="alergias" formControlName="alergias" (focus)="activarLugar('NO')" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="lugar">
            <div class="col">
                <p>A QUE:</p>
            </div>
            <div class="col">
                <input type="text" name="alergias" formControlName="alergias" ngModel (blur)="formularioAntecedentesPer(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>INMUNIZACIONES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="inmunizaciones" formControlName="inmunizaciones" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="inmunizaciones" formControlName="inmunizaciones" (blur)="formularioAntecedentesPer(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>