<!-- Content Wrapper -->

<div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow mr-auto">
            <!--div class="col-2"></div-->
            <div class="col-md-7">
                <a class="nav-link" [routerLink]="['/']">
                    <img src="../../../assets/images/dibujo-1.svg" width="200" height="85">
                </a>
            </div>
            <div class="col-md-3 col-name-user">
                <p><span>BIENVENIDO(A):</span> {{ userName | uppercase }}  </p>
            </div>
            <div class="col-md-2" style="text-align: end;">

                <button type="button" class="boton" (click)="cerrarSesion()">CERRAR SESIÓN </button>
        
            </div>

        </nav>
    </div>
</div>