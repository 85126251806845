
<section>
<div class="row">
    <div class="container-fluid" style="padding-left: 3.5rem;
    padding-right: 3.5rem;">
        <div class="card" id="card">
            <div role="tabpanel" class="row">
                <div class="col-2">
                    <ul class="nav flex-column nav-pills">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#fAntecedentes" aria-controls="#antecedentes" (click)="obtenerAntecedentes()" style="
                        font-size: 15px;font-weight: 600;"> Antecedentes </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#signosVitales" data-toggle="tab" aria-controls="#signosVitales" (click)="verSignosVitalesAnteriores()" style="
                        font-size: 15px;font-weight: 600;"> Signos vitales </a>
                        </li>
                        <li class="nav-item" *ngIf="paciente.edad > 9 && paciente.genero == 'FEMENINO'" (click)="obtenerGinecoPorVisita()">
                            <a class="nav-link" href="#ginecoxVisita" data-toggle="tab" aria-controls="#ginecoxVisita" style="
                        font-size: 15px;font-weight: 600;"> Gineco obstétricos por Visita </a>
                        </li>
                        <li (click)="obtenerMedicinaP()" class="nav-item" *ngIf="paciente.edad > 9">
                            <a class="nav-link" href="#medicinaPreventiva" data-toggle="tab" aria-controls="#medicinaPreventiva" style="
                        font-size: 15px;font-weight: 600;">Medicina preventiva</a>
                        </li>
                        <li class="nav-item" *ngIf="paciente.edad <= 9">
                            <a class="nav-link" href="#fEsquemaVacunacion" (click)="obtenerVacunacionNinos()" data-toggle="tab" aria-controls="#fEsquemaVacunacion" style="
                        font-size: 15px;font-weight: 600;">Esquema de vacunación</a>
                        </li>
                        <li class="nav-item" *ngIf="paciente.edad <= 9" (click)="obtenerNutricionPrros()">
                            <a class="nav-link" href="#nutricion" data-toggle="tab" aria-controls="#nutricion" style="
                        font-size: 15px;font-weight: 600;">Nutrición</a>
                        </li>
                        <li class="nav-item" *ngIf="paciente.edad > 9">

                            <!-- (click)="obtenerHeredoFamiliaresXPaciente()" -->
                            <a class="nav-link" href="#fHeredoFamiliares" (click)="obtenerHeredoFamiliaresXPaciente()" data-toggle="tab" aria-controls="#fHeredoFamiliares" style="
                        font-size: 15px;font-weight: 600;">Antecedentes Heredo Familiares</a>
                        </li>
                        <li class="nav-item" (click)="verAntecedentesNoPatologicos()" *ngIf="paciente.edad > 9">
                            <a class="nav-link" href="#personalNoPatologico" data-toggle="tab" aria-controls="#personalNoPatologico" style="
                        font-size: 15px;font-weight: 600;">Antecedentes personales no patológicos</a>
                        </li>
                        <li class="nav-item" (click)="verAntecedentesPersonalesPatologicos()" *ngIf="paciente.edad > 9">
                            <a class="nav-link" href="#personalPatologico" data-toggle="tab" aria-controls="#personalPatologico" style="
                        font-size: 15px;font-weight: 600;">Antecedentes personales patológicos</a>
                        </li>

                        <li class="nav-item" *ngIf="paciente.edad >= 9 && paciente.genero == 'FEMENINO'" (click)="obtenerGinecoHistoria()">
                            <a class="nav-link" href="#ginecoObs" data-toggle="tab" aria-controls="#ginecoObs" style="
                        font-size: 15px;font-weight: 600;">Antecedentes gineco obstétricos</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#signosVitales" data-toggle="tab" aria-controls="#signosVitales" style="
                        font-size: 15px;font-weight: 600;"> Historial de signos vitales</a>
                        </li>
                        <li class="nav-item" *ngIf="pageTitle === 'hojaevo'">
                            <a href="#interrogacionPorAparatosYsistemas" class="nav-link" data-toggle="tab" aria-controls="#interrogacionPorAparatosYsistemas" style="
                            font-size: 15px;font-weight: 600;">Interrogación por Aparatos y Sistemas</a>
                        </li>
                        <li class="nav-item" *ngIf="pageTitle == 'ddd'">
                            <a class="nav-link" href="#exploracionFisica" data-toggle="tab" aria-controls="#exploracionFisica" style="
                            font-size: 15px;font-weight: 600;">  Exploración fisica </a>
                        </li>
                    </ul>
                </div>
                <!-- Fin Col-2 -->
                <div class="col-10" style=" height: 75rem;">
                    <div class="tab-content">
                        <div role="tabpanel" id="fAntecedentes" class="tab-pane active">
                            <br>
                            <form id="fAntecedentes">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Listado</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Personales</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Fecha</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Familiares</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Notas</h5>
                                        </div>
                                    </div>
                                    <!-- enfermedades de la piel -->
                                    <div class="row">
                                        <div class="col">
                                            <p>Enfermedades de la Piel</p>
                                        </div>
                                        <div class="col">
                                            <input name="enfPielPersonales" [(ngModel)]="antecedentes.enfermedadesPielPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input name="enfPielFecha" [(ngModel)]="antecedentes.enfermedadesPielFecha" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input name="enfPielFamiliares" [(ngModel)]="antecedentes.enfermedadesPielFamiliares" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input name="enfPielNotas" [(ngModel)]="antecedentes.enfermedadesPielNotas" type="text" placeholder="" class="form-control">
                                        </div>

                                    </div>
                                    <!-- desnutrición -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Desnutrición</p>
                                        </div>
                                        <div class="col">
                                            <input name="desnuPesonales" [(ngModel)]="antecedentes.desnutricionPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="desnuFecha" [(ngModel)]="antecedentes.desnutricionFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="desnuFamiliares" [(ngModel)]="antecedentes.desnutricionFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="desnuNotas" [(ngModel)]="antecedentes.desnutricionNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- obesidad -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Obesidad</p>
                                        </div>
                                        <div class="col">
                                            <input name="obesidadPersonal" [(ngModel)]="antecedentes.obesidadPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="obesidadFecha" [(ngModel)]="antecedentes.obesidadFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="obesidadFamiliares" [(ngModel)]="antecedentes.obesidadFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="obesidadNotas" [(ngModel)]="antecedentes.obesidadNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- defectos postulares -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Defectos Postulares</p>
                                        </div>
                                        <div class="col"><input name="defectosPersonales" [(ngModel)]="antecedentes.defectosPostularesPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="defectosFecha" [(ngModel)]="antecedentes.defectosPostularesFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="defectosFamiliares" [(ngModel)]="antecedentes.defectosPostularesFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="defectosNotas" [(ngModel)]="antecedentes.defectosPostularesNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- fracturas -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Fracturas</p>
                                        </div>
                                        <div class="col">
                                            <input name="fracturasPersonales" [(ngModel)]="antecedentes.fracturasPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="fracturasFecha" [(ngModel)]="antecedentes.fracturasFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="fracturasFamiliares" [(ngModel)]="antecedentes.fracturasFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="fracturasNotas" [(ngModel)]="antecedentes.fracturasNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- hospitalizaciones -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Hospitalizaciones</p>
                                        </div>
                                        <div class="col"><input name="hospPersonales" [(ngModel)]="antecedentes.hospitalizacionesPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="hospFecha" [(ngModel)]="antecedentes.hospitalizacionesFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="hospFamiliares" [(ngModel)]="antecedentes.hospitalizacionesFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="hospNotas" [(ngModel)]="antecedentes.hospitalizacionesNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- transfusiones -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Transfusiones</p>
                                        </div>
                                        <div class="col">
                                            <input name="transfusionesPersonales" [(ngModel)]="antecedentes.transfucionesPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="transfusionesFecha" [(ngModel)]="antecedentes.transfucionesFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="transfusionesFamiliares" [(ngModel)]="antecedentes.transfucionesFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="transfusionesNotas" [(ngModel)]="antecedentes.transfucionesNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- cardiopatias -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Cardiopatias</p>
                                        </div>
                                        <div class="col">
                                            <input name="cardiopatiasPersonales" [(ngModel)]="antecedentes.cardiopatiasPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="cardiopatiasFecha" [(ngModel)]="antecedentes.cardiopatiasFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="cardiopatiasFamiliares" [(ngModel)]="antecedentes.cardiopatiasFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="cardiopatiasNotas" [(ngModel)]="antecedentes.cardiopatiasNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- cirugias -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Cirugías</p>
                                        </div>
                                        <div class="col">
                                            <input name="cirugiasPersonales" [(ngModel)]="antecedentes.cirugiasPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="cirugiaFecha" [(ngModel)]="antecedentes.cirugiasFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="cirugiaFamiliares" [(ngModel)]="antecedentes.cirugiasFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="cirugiaNotas" [(ngModel)]="antecedentes.cirugiasNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- cancer/leucemia -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Cáncer/Leucemia</p>
                                        </div>
                                        <div class="col">

                                            <input name="clPersonales" [(ngModel)]="antecedentes.cancerLeucemiaPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="clFecha" [(ngModel)]="antecedentes.cancerLeucemiaFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="clFamiliares" [(ngModel)]="antecedentes.cancerLeucemiaFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="clNotas" [(ngModel)]="antecedentes.cancerLeucemiaNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- alergias -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>Alergias</p>
                                        </div>
                                        <div class="col">

                                            <input name="alergiasPersonales" [(ngModel)]="antecedentes.alergiasPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="alergiasFecha" [(ngModel)]="antecedentes.alergiasFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="alergiasFamiliares" [(ngModel)]="antecedentes.alergiasFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="alergiasNotas" [(ngModel)]="antecedentes.alergiasNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- vih -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col">
                                            <p>VIH</p>
                                        </div>

                                        <div class="col">

                                            <input name="vihPersonales" [(ngModel)]="antecedentes.vihPersonal" type="text" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input name="vihFecha" [(ngModel)]="antecedentes.vihFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="vihFamiliares" [(ngModel)]="antecedentes.vihFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="vihNotas" [(ngModel)]="antecedentes.vihNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- tabaquismo -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Tabaquismo</p>
                                        </div>
                                        <div class="col"><input name="tabaquismoPersonales" [(ngModel)]="antecedentes.tabaquismoPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="tabaquismoFecha" [(ngModel)]="antecedentes.tabaquismoFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="tabaquismoFamiliares" [(ngModel)]="antecedentes.tabaquismoFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="tabaquismoNotas" [(ngModel)]="antecedentes.tabaquismoNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- alcoholismo -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">

                                        <div class="col">
                                            <p>Alcoholismo</p>
                                        </div>

                                        <div class="col"><input name="alcoholismoPersonales" [(ngModel)]="antecedentes.alcoholismoPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="alcoholismoFecha" [(ngModel)]="antecedentes.alcoholismoFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="alcoholismoFamiliares" [(ngModel)]="antecedentes.alcoholismoFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="alcoholismoNotas" [(ngModel)]="antecedentes.alcoholismoNotas" type="text" placeholder="" class="form-control"></div>


                                    </div>
                                    <!-- diabetes -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Diabetes</p>
                                        </div>
                                        <div class="col"><input name="diabetesPersonales" [(ngModel)]="antecedentes.diabetesPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="diabetesFecha" [(ngModel)]="antecedentes.diabetesFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="diabetesFamiliares" [(ngModel)]="antecedentes.diabetesFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="diabetesNotas" [(ngModel)]="antecedentes.diabetesNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- tuberculosis -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">

                                        <div class="col">
                                            <p>Tuberculosis</p>
                                        </div>

                                        <div class="col"><input name="tuberculosisPersonales" [(ngModel)]="antecedentes.tuberculosisPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="tuberculosisFecha" [(ngModel)]="antecedentes.tuberculosisFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="tuberculosisFamiliares" [(ngModel)]="antecedentes.tuberculosisFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="tuberculosisNotas" [(ngModel)]="antecedentes.tuberculosisNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- deportes -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Deportes</p>
                                        </div>
                                        <div class="col"><input name="deportesPersonales" [(ngModel)]="antecedentes.deportesPersonal" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="deportesFecha" [(ngModel)]="antecedentes.deportesFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="deportesFamiliares" [(ngModel)]="antecedentes.deportesFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="deportesNotas" [(ngModel)]="antecedentes.deportesNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- enfermedades de los sentidos -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Enfermedades de los Sentidos</p>
                                        </div>

                                        <div class="col"><input name="Personales" [(ngModel)]="antecedentes.enfermedadesDeLosSentidosPersonales" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="enfermedadesSentidosFecha" [(ngModel)]="antecedentes.enfermedadesDeLosSentidosFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="enfermedadesSentidosFamiliares" [(ngModel)]="antecedentes.enfermedadesSentidosFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="enfermedadesSentidosNotas" [(ngModel)]="antecedentes.enfermedadesSentidosNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- exposicion laboral -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Exposición Laboral</p>
                                        </div>
                                        <div class="col"><input name="expoLaboralPersonales" [(ngModel)]="antecedentes.expoLaboralPersonales" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="expoLaboralFecha" [(ngModel)]="antecedentes.expoLaboralFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="expoLaboralFamiliares" [(ngModel)]="antecedentes.expoLaboralFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="expoLaboralNotas" [(ngModel)]="antecedentes.expoLaboralNotas" type="text" placeholder="" class="form-control"></div>

                                    </div>
                                    <!-- defectos postquirurgicos -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Defectos Postquirúrgicos</p>
                                        </div>
                                        <div class="col"><input name="postQuirurgicoPersonales" [(ngModel)]="antecedentes.postQuirurgicoPersonales" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="postQuirurgicoFecha" [(ngModel)]="antecedentes.postQuirurgicoFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="postQuirurgicoFamiliares" [(ngModel)]="antecedentes.postQuirurgicoFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="postQuirurgicoNotas" [(ngModel)]="antecedentes.postQuirurgicoNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <!-- otras enfermedades -->
                                    <div class="row" style="margin-top: 10px;" *ngIf="paciente.edad > 9">
                                        <div class="col">
                                            <p>Otras Enfermedades (COVID-19)</p>
                                        </div>
                                        <div class="col"><input name="otrasEnfPersonales" [(ngModel)]="antecedentes.otrasEnfPersonales" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="otrasEnfFecha" [(ngModel)]="antecedentes.otrasEnfFecha" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="otrasEnfFamiliares" [(ngModel)]="antecedentes.otrasEnfFamiliares" type="text" placeholder="" class="form-control"></div>
                                        <div class="col"><input name="otrasEnfNotas" [(ngModel)]="antecedentes.otrasEnfNotas" type="text" placeholder="" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <!-- *ngIf="paciente.consultas = 1" -->
                                        <!--TODO: se quito para actualizar expedientes *ngIf="paciente.consultas = 1" -->
                                        <div class="col-md-4" >
                                            <button type="submit" class="secondaryButton" (click)="agregarAntecedentes()">Guardar</button>
                                            <button class="btn btn-success" (click)="actualizarAntecedentes()">Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Fin col-10 -->
                        <div role="tabpanel" class="tab-pane col-md-6" id="ginecoObs" *ngIf="paciente.edad >= 10 && paciente.genero == 'FEMENINO'">
                            <form>
                                <br>
                                <div class="container-fluid" style="margin-right: 40rem;">
                                    <div class="form-check form-check-inline ">
                                        <label for=" " style="margin-right: 8rem;">Dismenorrea (Incapacitante): </label>
                                        <input class="form-check-input" type="radio" name="dismeSi" [(ngModel)]="antecedentesGinecoObstetricosHistoria.dismenorrea" id="dismeSi" value="Si ">
                                        <label class="form-check-label" for="dismeSi">Si</label>
                                        <input class="form-check-input" style="margin-left: 3rem;" type="radio" name="dismeNo" [(ngModel)]="antecedentesGinecoObstetricosHistoria.dismenorrea" id="dismeNo" value="No ">
                                        <label class="form-check-label" for="dismeNo">No</label>

                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <label for=" " style="margin-right: 15rem;">Ciclos Regulares: </label>
                                        <input class="form-check-input" type="radio" name="regularSi" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ciclosRegulares" id="regularSi" value="Si">
                                        <label class="form-check-label" for="regularSi">Si</label>
                                        <input class="form-check-input" style="margin-left: 3rem;" type="radio" name="regularNo" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ciclosRegulares" id="regularNo" value="No">
                                        <label class="form-check-label" for="regularNo">No</label>
                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <label for=" " style="margin-right: 15rem;">IVSA:</label>
                                        <label class="form-check-label" for="ivsa">Años:</label>
                                        <input class="form-check-input resultados" style="margin-left: 0.4rem;" name="ivsa" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ivsaAnios" type="number" id=" ">
                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <label for="ritmo">Ritmo: </label>
                                        <input type="text" style="margin-left: 2.5rem;" name="ritmo" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ritmo" class="form-control resultados" id="ritmo">

                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <label for=" " style="margin-top: 0.5rem;">Hipermenorrea: </label>
                                        <input class="form-check-input " style="margin-left: 14rem;" type="radio" id="hipermenorreaSi " name="hiperSi" [(ngModel)]="antecedentesGinecoObstetricosHistoria.hipermenorrea" value="Si">
                                        <label class="form-check-label " for="hipermenorreaSi ">Si</label>
                                        <input class="form-check-input " style="margin-left: 3rem;" type="radio" id="hipermenorreaNo " name="hiperNo" [(ngModel)]="antecedentesGinecoObstetricosHistoria.hipermenorrea" value="No">
                                        <label class="form-check-label " for="hipermenorreaNo ">No</label>
                                    </div>

                                    <div class="form-check form-check-inline ">
                                        <label for="citologia">Fecha Última citología (PAP): </label>
                                        <input type="date" name="citologia" [(ngModel)]="antecedentesGinecoObstetricosHistoria.fechaUltimaCitologia" class="form-control resultados">

                                    </div>

                                    <div class="form-check form-check-inline ">
                                        <label for="menstruacion">Fecha Última Menstruación: </label>
                                        <input type="date" name="Menstruacion" [(ngModel)]="antecedentesGinecoObstetricosHistoria.fechaUltimaMenstruacion" class="form-control resultados">

                                    </div>
                                    <div class="" >
                                        <button class="btn btn-primary" (click)="agregarGinecoObstetricosHistoria()">Guardar</button>
                                        <button class="btn btn-success" (click)="actualizarGinecoHistoria()">Actualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Fin Gineco Obstetricos Historia -->

                        <!-- Exploracion Fisica -->
                        <div role="tabpanel" class="tab-pane" id="exploracionFisica">
                            <!--h4> Exploración fisica </h4-->
                            <br>
                            <div class="container" style="overflow-x: scroll; height: 19rem;">
                                <!--div class="row mb-2">
                                    <div class="col-md-2">
                                        <p>
                                            PESO <strong>  {{ paciente.peso }} </strong> KG
                                        </p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>
                                            TALLA <strong> {{ paciente.talla }}</strong> MTs.
                                        </p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>
                                            TA mmHG.
                                        </p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>
                                            FC/PULSO <strong>  {{ paciente.rpm }} </strong> x min
                                        </p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>
                                            TEMPERATURA <strong> {{ paciente.temp }}</strong> °C
                                        </p>
                                    </div>
                                </div-->

                                <form action="">
                                    <div class="row">

                                        <div class="col-md-3">
                                            <label for="piel">Piel y Anexo</label>

                                        </div>
                                        <div class="col-md-8">

                                            <input type="text" name="pielyanexo" class="form-control" [(ngModel)]="hojaEvolucion.piel">
                                        </div>
                                        <div class="col-md-3">

                                            <label for="cabezaYCuello">Cabeza y Cuello</label>
                                        </div>

                                        <div class="col-md-8">

                                            <input type="text" name="cabezaYCuello" class="form-control" [(ngModel)]="hojaEvolucion.cabezaCuello">
                                        </div>

                                        <div class="col-md-3">
                                            <label for="torax">Tórax</label>

                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" name="torax" class="form-control" [(ngModel)]="hojaEvolucion.torax">
                                        </div>

                                        <div class="col-md-3">
                                            <label for="abdomen">Abdomen</label>

                                        </div>
                                        <div class="col-md-8">

                                            <input type="text" name="abdomen" class="form-control" [(ngModel)]="hojaEvolucion.abdomen">
                                        </div>

                                        <div class="col-md-3">
                                            <label for="genitales">Genitales</label>

                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" name="genitales" class="form-control" [(ngModel)]="hojaEvolucion.genitales">

                                        </div>

                                        <div class="col-md-3">
                                            <label for="extremidades">Extremidades</label>

                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="extremidades" class="form-control" [(ngModel)]="hojaEvolucion.extremidades">

                                        </div>
                                        <div class="col-md-3">
                                            <label for="sistemaNervioso">Sistema Nervioso</label>

                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" name="sistemaNervioso" class="form-control" [(ngModel)]="hojaEvolucion.sistemaNervioso">

                                        </div>

                                        <button class="btn btn-primary" > Guardar</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <!-- Fin Exploracion Fisica -->

                        <!-- Heredo Familiares -->
                        <div role="tabpanel" class="tab-pane" id="fHeredoFamiliares" *ngIf="paciente.edad > 9">
                            <!-- <button class="btn btn-primary" (click)="obtenerHeredoFamiliaresXPaciente()">Ver Heredo Familiares Anteriores</button> -->
                            <form #fHeredoFamiliares>
                                <br>
                                <div class="container-fluid" style="margin-right: 40rem;">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Diabetes</h5>
                                            <input name="ahDiabetes" [(ngModel)]="antecedentesHeredoFamiliares.diabetes" class="form-control " type="text " id="ahDiabetes">
                                        </div>
                                        <div class="col">
                                            <h5>Cáncer</h5>
                                            <input name="ahCancer" [(ngModel)]="antecedentesHeredoFamiliares.cancer" class="form-control " type="text " id="ahCancer">
                                        </div>
                                        <div class="col">
                                            <h5>Cardiopatias</h5>
                                            <input name="ahCardiopatias" [(ngModel)]="antecedentesHeredoFamiliares.cardiopatias" class="form-control " type="text " id="ahCardiopatias">
                                        </div>
                                        <div class="col">
                                            <h5>Malformaciones</h5>
                                            <input name="ahMalformaciones" [(ngModel)]="antecedentesHeredoFamiliares.malformaciones" class="form-control " type="text " id="ahMalformaciones">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h5>Hipertensión</h5>
                                            <input name="ahHipertension" [(ngModel)]="antecedentesHeredoFamiliares.hipertension" class="form-control " type="text " id="ahHipertension">
                                        </div>
                                        <div class="col">
                                            <h5>Tipo</h5>
                                            <input name="ahHipertensionTipo" [(ngModel)]="antecedentesHeredoFamiliares.hipertensionTipo" class="form-control" type="text " id="ahHipertensionTipo">
                                        </div>
                                        <div class="col">
                                            <h5>Nefropatías</h5>
                                            <input name="ahNefropatias" [(ngModel)]="antecedentesHeredoFamiliares.nefropatias" class="form-control" type="text " id="ahNefropatias">
                                        </div>
                                        <div class="col">
                                            <h5>Tipo</h5>
                                            <input name="ahNefropatiasTipo" [(ngModel)]="antecedentesHeredoFamiliares.nefropatiasTipo" class="form-control" type="text " id="ahNefropatiasTipo">
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col">
                                            <button (click)="agregarAntecedentesHeredoFamimiares()" class="secondaryButton" style="margin-top: 10px;">
                                            Guardar
                                        </button>

                                            <div class="btn btn-success" (click)="actualizarHeredoFamiliares()">Actualizar</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Fin Heredo Familiares -->

                        <!-- Inicio Medicina Preventiva -->
                        <div role="tabpanel" class="tab-pane" id="medicinaPreventiva" *ngIf="paciente.edad > 9 ">
                            <form #fMedicinaPreventiva>
                                <div class="container-fluid">
                                    <br>
                                    <div class="row">
                                        <table class="table table-borderless">
                                            <thead class="thead">
                                                <tr>
                                                    <th>
                                                        <h5>Estudios</h5>
                                                    </th>
                                                    <th>
                                                        <h5>Fecha de Toma</h5>
                                                    </th>
                                                    <th>
                                                        <h5>Fecha de Entrega</h5>
                                                    </th>
                                                    <th>
                                                        <h5>Resultados</h5>
                                                    </th>
                                                    <th>
                                                        <h5>Observaciones</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="paciente.genero == 'masculino' && paciente.edad > 9">
                                                    <td>Detección de cáncer de próstata</td>
                                                    <td> <input name="cancerProstataFechaToma " [(ngModel)]="medicinaPreventiva.cancerProstataFechaToma" [value]="medicinaPreventiva.cancerProstataFechaToma" type="text " placeholder=" " class="form-control ">                                                        </td>
                                                    <td> <input name="cancerEstomagoMujerFechaEntrega " [(ngModel)]="medicinaPreventiva.cancerProstataFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoMujerResultados " [(ngModel)]="medicinaPreventiva.cancerProstataResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoMujerObservaciones " [(ngModel)]="medicinaPreventiva.cancerProstataObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr *ngIf="paciente.genero == 'masculino' && paciente.edad > 9">
                                                    <td>Detección de Leucemia</td>
                                                    <td> <input name="cancerEstomagoMujerFechaToma " [(ngModel)]="medicinaPreventiva.leucemiaFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoMujerFechaEntrega " [(ngModel)]="medicinaPreventiva.leucemiaFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoMujerResultado " [(ngModel)]="medicinaPreventiva.leucemiaResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoMujerObservaciones " [(ngModel)]="medicinaPreventiva.leucemiaObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Detección de cáncer de estómago</td>
                                                    <td> <input name="cancerEstomagoToma " [(ngModel)]="medicinaPreventiva.cancerEstomagoFechaToma" type="text " [value]="medicinaPreventiva.cancerEstomagoFechaToma" class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoEntrega " [(ngModel)]="medicinaPreventiva.cancerEstomagoFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoResultado " [(ngModel)]="medicinaPreventiva.cancerEstomagoResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerEstomagoObservaciones " [(ngModel)]="medicinaPreventiva.cancerEstomagoObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Detección de cáncer de colon</td>
                                                    <td> <input name="cancerColonchaToma " [(ngModel)]="medicinaPreventiva.cancerColonFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerColonchaEntrega " [(ngModel)]="medicinaPreventiva.cancerColonFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerColonResultado " [(ngModel)]="medicinaPreventiva.cancerColonResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerColonObservaciones " [(ngModel)]="medicinaPreventiva.cancerColonObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Detección de cáncer de pulmón</td>
                                                    <td> <input name="cacerPulmonchaToma " [(ngModel)]="medicinaPreventiva.cancerPulmonFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cacerPulmonchaEntrega " [(ngModel)]="medicinaPreventiva.cancerPulmonFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cacerPulmonResultado " [(ngModel)]="medicinaPreventiva.cancerPulmonResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cacerPulmonObservaciones " [(ngModel)]="medicinaPreventiva.cancerPulmonObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr *ngIf="paciente.genero == 'FEMENINO' && paciente.edad > 9">
                                                    <td>Detección de cáncer de mama</td>
                                                    <td> <input name="cancerMamaToma " [(ngModel)]="medicinaPreventiva.cancerMamaFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerMamaEntrega " [(ngModel)]="medicinaPreventiva.cancerMamaFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerMamaResultado " [(ngModel)]="medicinaPreventiva.cancerMamaResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerMamaObservaciones " [(ngModel)]="medicinaPreventiva.cancerMamaObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr *ngIf="paciente.genero == 'FEMENINO' && paciente.edad > 9">
                                                    <td>Detección de cáncer cérvico uterino</td>
                                                    <td> <input name="cancerCUchaToma " [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerCUchaEntrega " [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerCUResultado " [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="cancerCUObservaciones " [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña de detección de agudeza auditiva</td>
                                                    <td> <input name="auditivaFechaToma " [(ngModel)]="medicinaPreventiva.agudezaAuditivaFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="auditivaFechaEntrega " [(ngModel)]="medicinaPreventiva.agudezaAuditivaFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="auditivaResultado " [(ngModel)]="medicinaPreventiva.agudezaAuditivaResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="auditivaObservaciones " [(ngModel)]="medicinaPreventiva.agudezaAuditivaObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña de buena salud bucal</td>
                                                    <td> <input name="bucalFechaToma" [(ngModel)]="medicinaPreventiva.saludBucalFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="bucalFechaEntrega " [(ngModel)]="medicinaPreventiva.saludBucalFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="bucalResultado " [(ngModel)]="medicinaPreventiva.saludBucalResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="bucalObservaciones " [(ngModel)]="medicinaPreventiva.saludBucalObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña contra la desnutrición, sobrepeso y obesidad</td>
                                                    <td> <input name="nutricionFechaToma " [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="nutricionFechaEntrega " [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="nutricionResultado " [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="nutricionObservaciones " [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña de detección de diabetes mellitus y dislipidemia</td>
                                                    <td> <input name="diabetesFechaToma " [(ngModel)]="medicinaPreventiva.diabetesFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="diabetesFechaEntrega " [(ngModel)]="medicinaPreventiva.diabetesFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="diabetesResultado " [(ngModel)]="medicinaPreventiva.diabetesResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="diabetesObservaciones " [(ngModel)]="medicinaPreventiva.diabetesObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña de detección de agudeza visual</td>
                                                    <td> <input name="visualFechaToma " [(ngModel)]="medicinaPreventiva.agudezaVisualFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="visualFechaEntrega " [(ngModel)]="medicinaPreventiva.agudezaVisualFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="visualResultado " [(ngModel)]="medicinaPreventiva.agudezaVisualResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="visualObservaciones " [(ngModel)]="medicinaPreventiva.agudezaVisualObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña de detección de enfermedades respiratorias</td>
                                                    <td> <input name="respiratoriasFechaToma " [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="respiratoriasFechaEntrega " [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="respiratoriasResultado " [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="respiratoriasObservaciones " [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Campaña de detección de trastorno en la marcha</td>
                                                    <td> <input name="marchaFechaToma" [(ngModel)]="medicinaPreventiva.trastornoMarchaFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="marchaFechaEntrega " [(ngModel)]="medicinaPreventiva.trastornoMarchaFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="marchaResultado" [(ngModel)]="medicinaPreventiva.trastornoMarchaResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="marchaObservaciones" [(ngModel)]="medicinaPreventiva.trastornoMarchaObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <td>Otros</td>
                                                    <td> <input name="otrosFechaToma " [(ngModel)]="medicinaPreventiva.otrosFechaToma" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="otrosFechaEntrega " [(ngModel)]="medicinaPreventiva.otrosFechaEntrega" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="otrosResultado " [(ngModel)]="medicinaPreventiva.otrosResultado" type="text " placeholder=" " class="form-control "> </td>
                                                    <td> <input name="otrosObservaciones " [(ngModel)]="medicinaPreventiva.otrosObservaciones" type="text " placeholder=" " class="form-control "> </td>
                                                </tr>
                                                <tr>
                                                    <div class="" >
                                                        <button (click)="agregarMedicinaP()" class="secondaryButton">Guardar</button>
                                                        <button class="btn btn-success" (click)="actualizarMedicinaP()">Actualizar Datos</button>
                                                    </div>
                                                </tr>
                                            </tbody>
                                            <!-- <button (click)="ageragarMedicinaP()">Guardar</button> -->
                                        </table>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <!-- Fin Medicina Preventiva -->
                        <!-- Inicio Nutrición -->
                        <div role="tabpanel" class="tab-pane" id="nutricion" *ngIf="paciente.edad <= 9">
                            <form #fNutricion="ngForm">
                                <br>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Listado</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Previene</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Mes 1</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Mes 2</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Mes 3</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Mes 4</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Mes 5</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Mes 6</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Notas</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Alimentate Sanamente</p>
                                        </div>
                                        <div class="col">
                                            <p>Desnutrición</p>
                                        </div>
                                        <div class="col"><input type="text" name="alimentarSanamenteMesUno" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesUno" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="alimentarSanamenteMesDos" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesDos" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="alimentarSanamenteMesTres" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesTres" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="alimentarSanamenteMesCuatro" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesCuatro" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="alimentarSanamenteMesCinco" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesCinco" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="alimentarSanamenteMesSeis" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesSeis" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="alimentarSanamenteNotas" [(ngModel)]="nutricionNinos.notasMesUno" placeholder="" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Desparacitación Intestinal</p>
                                        </div>
                                        <div class="col">
                                            <p>Parasitosis Intestinales</p>
                                        </div>
                                        <div class="col"><input type="text" name="despIntestinalMesUno" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesUno" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="despIntestinalMesDos" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesDos" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="despIntestinalMesTres" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesTres" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="despIntestinalMesCuatro" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesCuatro" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="despIntestinalMesCinco" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesCinco" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="despIntestinalMesSeis" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesSeis" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="despIntestinalNotas" [(ngModel)]="nutricionNinos.notasMesDos" placeholder="" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Vitamina A</p>
                                        </div>
                                        <div class="col">
                                            <p>Infecciones Respiratorias y Diarreas</p>
                                        </div>
                                        <div class="col"><input type="text" name="vitaminaAMesUno" [(ngModel)]="nutricionNinos.vitaminaANinosMesUno" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="vitaminaAMesDos" [(ngModel)]="nutricionNinos.vitaminaANinosMesDos" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="vitaminaAMesTres" [(ngModel)]="nutricionNinos.vitaminaANinosMesTres" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="vitaminaAMesCuatro" [(ngModel)]="nutricionNinos.vitaminaANinosMesCuatro" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="vitaminaAMesCinco" [(ngModel)]="nutricionNinos.vitaminaANinosMesCinco" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="vitaminaAMesSeis" [(ngModel)]="nutricionNinos.vitaminaANinosMesSeis" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="vitaminaANotas" [(ngModel)]="nutricionNinos.notasMesTres" placeholder="" class="form-control">
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Hierro NCBP</p>
                                        </div>
                                        <div class="col">
                                            <p>Anemia</p>
                                        </div>
                                        <div class="col"><input type="text" name="hierroNcbpMesUno" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesUno" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="hierroNcbpMesDos" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesDos" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="hierroNcbpMesTres" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesTres" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="hierroNcbpMesCuatro" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesCuatro" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="hierroNcbpMesCinco" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesCinco" placeholder="" class="form-control"></div>
                                        <div class="col"><input type="text" name="hierroNcbpMesSeis" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesSeis" placeholder="" class="form-control"></div>
                                        <div class="col"> <input type="text" name="hierroNcbpNotas" [(ngModel)]="nutricionNinos.notasMesCuatro" placeholder="" class="form-control">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Hierro PP</p>
                                        </div>
                                        <div class="col">
                                            <p>Anemia</p>
                                        </div>
                                        <div class="col"><input type="text" name="hierroPpMesUno" [(ngModel)]="nutricionNinos.hierroPPNinosMesUno" placeholder="" class="form-control ">
                                        </div>
                                        <div class="col"><input type="text" name="hierroPpMesDos" [(ngModel)]="nutricionNinos.hierroPPNinosMesDos" placeholder="" class="form-control ">
                                        </div>
                                        <div class="col"><input type="text" name="hierroPpMesTres" [(ngModel)]="nutricionNinos.hierroPPNinosMesTres" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="hierroPpMesCuatro" [(ngModel)]="nutricionNinos.hierroPPNinosMesCuatro" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"> <input type="text" name="hierroPpMesCinco" [(ngModel)]="nutricionNinos.hierroPPNinosMesCinco" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="hierroPpMesSeis" [(ngModel)]="nutricionNinos.hierroPPNinosMesSeis" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="hierroPpNotas" [(ngModel)]="nutricionNinos.notasMesCinco" placeholder="" class="form-control">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Otros</p>
                                        </div>
                                        <div class="col">
                                            <p>Otras Enfermedades</p>
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesUno" [(ngModel)]="nutricionNinos.otrosNinosMesUno" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesDos" [(ngModel)]="nutricionNinos.otrosNinosMesDos" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesTres" [(ngModel)]="nutricionNinos.otrosNinosMesTres" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesCuatro" [(ngModel)]="nutricionNinos.otrosNinosMesCuatro" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesCinco" [(ngModel)]="nutricionNinos.otrosNinosMesCinco" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesCinco" [(ngModel)]="nutricionNinos.otrosNinosMesCinco" placeholder="" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" name="otrosMesNotas" [(ngModel)]="nutricionNinos.notasMesSeis" placeholder="" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="" >
                                    <button class="btn btn-primary" (click)="agregarNutricionN()">Guardar</button>
                                    <button class="btn btn-success" (click)="actualizarNutricionN()">Actualizar</button>
                                </div>
                            </form>
                        </div>
                        <!-- Fin Nutrición -->

                        <!-- Inicio A. P. Patologicos -->
                        <div class="tab-pane" id="personalPatologico" *ngIf="paciente.edad > 9">
                            <form #fPersonalesPatologico>
                                <br>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Enfermedades de la Infancia</h5>
                                            <div class="col"><input type="text" name="enfInfancia" [(ngModel)]="antecedentesPersonalesPatologicos.enfermedadesInfancia" class="form-control"></div>
                                        </div>
                                        <div class="col">
                                            <h5 for=" ">Secuelas</h5>
                                            <input type="text " name="secuelas" [(ngModel)]="antecedentesPersonalesPatologicos.enfermedadesInfanciaSecuelas" class="form-control">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col ">
                                            <h5 for=" ">Hospitalizaciones previas</h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input " type="radio" name="hospPreviSi" [(ngModel)]="antecedentesPersonalesPatologicos.hospitalizacionesPrevias" id="hospPreviSi " value="Si">
                                                <label class="form-check-label " for="hospPreviSi " style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input " type="radio" name="hospPreviNo" [(ngModel)]="antecedentesPersonalesPatologicos.hospitalizacionesPrevias" id="hospPreviNo " value="No">
                                                <label class="form-check-label " for="hospPreviNo " style="margin-right: 1rem;">No</label>
                                            </div>

                                        </div>
                                        <div class="col">
                                            <h5 for=" ">Especificaciones</h5>
                                            <input type="text " name="hospEs" [(ngModel)]="antecedentesPersonalesPatologicos.hospitalizacionesPreviasEsp" class="form-control">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <h5 for=" ">Transfusiones Previas: </h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input" name="transfucionesPreviasAPP" [(ngModel)]="antecedentesPersonalesPatologicos.transfucionesPrevias" type="radio" id="transfucionesSi " value="Si">
                                                <label class="form-check-label " for="transfucionesSi " style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input " name="transfucionesPreviasNoApp" [(ngModel)]="antecedentesPersonalesPatologicos.transfucionesPrevias" type="radio" id="transfucionesNo " value="No">
                                                <label class="form-check-label " for="transfucionesNo " style="margin-right: 1rem;">No</label>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <h5 for=" ">Especificaciones: </h5>
                                            <input type="text " name="transfusionesEs" [(ngModel)]="antecedentesPersonalesPatologicos.transfucionesPreviasEsp" class="form-control ">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <h5 for=" ">Antecedentes Quirurgicos: </h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input " name="anteQuiruSi" type="radio" id="cirugiasSi" [(ngModel)]="antecedentesPersonalesPatologicos.antecedentesQuirurgicos" value="Si">
                                                <label class="form-check-label " for="cirugiasSi " style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input " name="anteQuiruNo" type="radio" id="cirugiasNo" [(ngModel)]="antecedentesPersonalesPatologicos.antecedentesQuirurgicos" value="No">
                                                <label class="form-check-label " for="cirugiasNo " style="margin-right: 1rem;">No</label>
                                            </div>
                                        </div>

                                        <div class="col">
                                            <h5 for=" ">Especificaciones: </h5>
                                            <input type="text " name="quiruEs" [(ngModel)]="antecedentesPersonalesPatologicos.antecedentesQuirurgicosEsp" class="form-control ">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <h5 for="">Fracturas: </h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input" name="fracturasSi" [(ngModel)]="antecedentesPersonalesPatologicos.fractutas" type="radio" id="fracturasSi" value="Si">
                                                <label class="form-check-label " for="fracturasSi " style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input" name="fracturasNo" [(ngModel)]="antecedentesPersonalesPatologicos.fractutas" type="radio" id="fracturasNo" value="No">
                                                <label class="form-check-label " for="fracturasNo " style="margin-right: 1rem;">No</label>
                                            </div>
                                        </div>

                                        <div class="col">
                                            <h5>Especificaciones: </h5>
                                            <input type="text " name="fracturasEs" [(ngModel)]="antecedentesPersonalesPatologicos.fracturasEsp" class="form-control ">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <h5 for=" ">Otras Enfermedades: </h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input " name="otrasEnf" [(ngModel)]="antecedentesPersonalesPatologicos.otrasEnfermedades" type="radio" id="otrasEnfSi " value="Si">
                                                <label class="form-check-label " for="otrasEnfSi " style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input" name="otrasEnf" [(ngModel)]="antecedentesPersonalesPatologicos.otrasEnfermedades" type="radio" id="otrasEnfNo " value="No">
                                                <label class="form-check-label " for="otrasEnfNo " style="margin-right: 1rem;">No</label>
                                            </div>
                                        </div>


                                        <div class="col">
                                            <h5 for=" ">Medicamentos Actuales: </h5>
                                            <input type="text " name="otrasEnfermedadesEsp" [(ngModel)]="antecedentesPersonalesPatologicos.otrasEnfermedadesEsp" class="form-control ">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <h5 for=" ">Medicamentos Actuales: </h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input" name="medicamentosActuales" [(ngModel)]="antecedentesPersonalesPatologicos.medicamentosActuales" type="radio" id="medicamentosActualesSi" value="Si">
                                                <label class="form-check-label" for="medicamentosActualesSi" style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input" type="radio" name="medicamentosActualesNo" [(ngModel)]="antecedentesPersonalesPatologicos.medicamentosActuales" id="medicamentosActualesNo" value="No">
                                                <label class="form-check-label" for="medicamentosActualesNo" style="margin-right: 1rem;">No</label>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <h5 for="medicamentosActualesEsp">Especificaciones: </h5>
                                            <input type="text" name="medicamentosActualesEsp" [(ngModel)]="antecedentesPersonalesPatologicos.medicamentosActualesEsp" class="form-control ">
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <h5 for=" ">Accidentes/Traumatismo: </h5>
                                            <div class="form-check form-check-inline ">
                                                <input class="form-check-input " type="radio" id="traumatismoSi" name="accidentesSi" [(ngModel)]="antecedentesPersonalesPatologicos.accidentes" value="Si">
                                                <label class="form-check-label " for="traumatismoSi " style="margin-right: 1rem;">Si</label>
                                                <input class="form-check-input " type="radio" id="traumatismoNo" name="accidentesNo" [(ngModel)]="antecedentesPersonalesPatologicos.accidentes" value="No">
                                                <label class="form-check-label " for="traumatismoNo " style="margin-right: 1rem;">No</label>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <h5 for=" ">Especificaciones: </h5>
                                            <input type="text " name="accidentesEsp" [(ngModel)]="antecedentesPersonalesPatologicos.accidentesEsp" class="form-control ">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" >
                                            <button class="secondaryButton" (click)="agregarPersonalesPatologicos()">Guardar</button>
                                            <button class="btn btn-success" (click)="actualizarPersonalesPatologicos()">Actualizar</button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- Fin A. P. Patologicos -->
                        <!-- Inico Antecedentes Personales No Patologicos -->
                        <div role="tabpanel" class="tab-pane" id="personalNoPatologico" *ngIf="paciente.edad > 9">
                            <form #fPersonalesNoPat>
                                <div class="container card">
                                    <div class="form-row ">
                                        <div class="col ">
                                            <div class="form-group col">
                                                <h5>Tabaquismo</h5>
                                                <p>¿Cuántos?</p>
                                                <div class="form-check form-check-inline ">
                                                    <input class="form-check-input" name="cuantosFiltros" [(ngModel)]="antecedentesPersonalesNoPatologicos.tabaquismoPorDia" type="number" id="cuantosFiltros">
                                                    <label class="form-check-label" for="cuantosFiltros">Por Día</label>
                                                </div>
                                            </div>
                                            <div class="form-group col">
                                                <div class="form-check form-check-inline ">
                                                    <label class="form-check-label " for="aniosConsumo">Años de Consumo: </label>
                                                    <input name="aniosConsumo" [(ngModel)]="antecedentesPersonalesNoPatologicos.aniosconsumo" class="form-check-input " type="number " id="aniosConsumo">
                                                </div>
                                            </div>
                                            <div class="form-group col">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Exfumador o Tabaquismo pasivo: </label>
                                                        <input type="text" name="exFumador" [(ngModel)]="antecedentesPersonalesNoPatologicos.exFumadorPasivo" class="form-control antecedentes-inputs">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col">
                                                <h5>Alcoholismo</h5>
                                                <p>¿Cuántas Copas?</p>
                                                <div class="form-check form-check-inline ">
                                                    <input class="form-check-input" name="cuantosFiltros" [(ngModel)]="antecedentesPersonalesNoPatologicos.copasPorDia" type="number" id="cuantosFiltros">
                                                    <label class="form-check-label" for="cuantosFiltros">Por Día</label>
                                                </div>
                                                <h5>Cervezas</h5>
                                                <p>¿Cuántas Cervezas?</p>
                                                <div class="form-check form-check-inline ">
                                                    <input class="form-check-input" name="copasDia" [(ngModel)]="antecedentesPersonalesNoPatologicos.alcoholismoPorDia" type="number " id="">
                                                    <label class="form-check-label" for=" ">Por Semana</label>
                                                </div>
                                            </div>
                                            <div class="form-group col">
                                                <div class="form-check form-check-inline ">
                                                    <label class="form-check-label " for="aniosConsumoAlcohol">Años de Consumo: </label>
                                                    <input class="form-check-input" name="aniosConsumoAlcohol" [(ngModel)]="antecedentesPersonalesNoPatologicos.aniosDeconsumoAlcohol" type="number " id="aniosConsumoAlcohol">
                                                </div>
                                            </div> 
                                            <div class="form-group col">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for="alcoholicoOcasional">Alcoholico Y/U ocacional: </label>
                                                        <input type="text" id="alcoholicoOcasional" name="alcoholicoOcasional" [(ngModel)]="antecedentesPersonalesNoPatologicos.exAlcoholicoUOcasional" class="form-control antecedentes-inputs ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col">
                                                <!-- <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <input class="form-check-input" name="cuantosFiltros" [(ngModel)]="antecedentesPersonalesNoPatologicos.tabaquismoPorDia" type="number" id="cuantosFiltros">
                                                        <label class="form-check-label" for="cuantosFiltros">Por Día</label>
                                                    </div>
                                                </div> -->
                                                <div class="form-group col">
                                                    <div class="form-group col-md6 ">

                                                        <label for="alergiasSi" style="margin-top: 2rem;">Alergias: </label>
                                                        <!-- <input type="radio " name="sdds " id="alergiaNoPatSi " value="alergiaNoPatSi"> -->
                                                        <input type="radio" name="alergiasSi" [(ngModel)]="antecedentesPersonalesNoPatologicos.alergias" value="Si">
                                                        <label class="form-check-label " style="margin-right: 2rem;" for="alergiaSi">Si</label>
                                                        <input type="radio" name="alergiasNo" [(ngModel)]="antecedentesPersonalesNoPatologicos.alergias" value="No" id="alergiaNoPatNo ">
                                                        <label class="form-check-label " style="margin-right: 2rem;" for="alergiaNoPatNo ">No</label>
                                                    </div>
                                                </div>
                                                <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for=" " style="margin-right: 4rem;">Tipo: </label>
                                                            <input type="text" style="margin-right:0.5rem;" class="form-control antecedentes-inputs" name="tipoAlergia" [(ngModel)]="antecedentesPersonalesNoPatologicos.tipoAlergias">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for=" ">Alimentación adecuada: </label>
                                                            <input class="form-check-input " type="radio" id="alimentacionSi" [(ngModel)]="antecedentesPersonalesNoPatologicos.alimentacionAdecuada" value="Si" name="AlimentacionSi">
                                                            <label class="form-check-label " for="alimentacionSi">Si</label>
                                                            <input class="form-check-input " type="radio" id="alimentacionNo " name="AlimentacionNo" [(ngModel)]="antecedentesPersonalesNoPatologicos.alimentacionAdecuada" value="No">
                                                            <label class="form-check-label " for="alimentacionNo ">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                
                                               
                                                <!-- <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for="tipoSangre">Tipo Sanguíneo: </label>
                                                            <input type="text" class="form-control antecedentes-inputs" name="tipoSangre" [(ngModel)]="antecedentesPersonalesNoPatologicos.tipoSanguineo">

                                                        </div>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for=" ">Se desconoce: </label>
                                                            <input class="form-check-input" [(ngModel)]="antecedentesPersonalesNoPatologicos.desconoceTipoSanguineo" type="radio" name="desconoceSi" id="desconoceSi " value="Si ">
                                                            <label class="form-check-label" for="desconoceSi ">Si</label>
                                                            <input class="form-check-input" [(ngModel)]="antecedentesPersonalesNoPatologicos.desconoceTipoSanguineo" type="radio" name="desconoceNo" id="desconoceNo " value="No ">
                                                            <label class="form-check-label" for="desconoceNo ">No</label>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for=" ">Vivienda Servicios Básicos: </label>
                                                            <input class="form-check-input" [(ngModel)]="antecedentesPersonalesNoPatologicos.viviendaConServiciosBasicos" type="radio" name=" " id="serviciosBasicosSi " value="Si">
                                                            <label class="form-check-label" for="serviciosBasicosSi ">Si</label>
                                                            <input class="form-check-input" [(ngModel)]="antecedentesPersonalesNoPatologicos.viviendaConServiciosBasicos" type="radio" name=" " id="serviciosBasicosNo " value="No">
                                                            <label class="form-check-label" for="serviciosBasicosNo ">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col ">
                                                <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for="drogadictoTipo">Tipo de Drogas: </label>
                                                            <input type="text" name="drogadictoTipo" id="drogadictoTipo" [(ngModel)]="antecedentesPersonalesNoPatologicos.drogadiccionTipo" class="form-control antecedentes-inputs ">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col">
                                                    <div class="form-check form-check-inline ">
                                                        <label class="form-check-label" for=" ">Años de Consumo: </label>
                                                        <input class="form-check-input" name="aniosDrogoConsumo" [(ngModel)]="antecedentesPersonalesNoPatologicos.aniosConsumoDrogas" type="number " id="aniosDrogoConsumo">

                                                    </div>
                                                </div>
                                                <div class="form-group col">
                                                    <div class="form-group col-md6 ">
                                                        <div class="form-check form-check-inline ">
                                                            <label for=" ">Exdrogadicto: </label>
                                                            <input type="text" name="exDrogo" [(ngModel)]="antecedentesPersonalesNoPatologicos.exDrogadicto" class="form-control antecedentes-inputs ">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <!-- <div class="form-group ">
                                            <label for="inputAddress ">Otros:</label>
                                            <input type="text" name="otrosNoPat" [(ngModel)]="antecedentesPersonalesNoPatologicos.otrosAntecedentesNoPatologicos" class="form-control " id="otros " placeholder=" ">
                                        </div> -->

                                    </div>

                                    <div class="form-group ">
                                        <label for="inputAddress ">Otros:</label>
                                        <input type="text" name="otrosNoPat" [(ngModel)]="antecedentesPersonalesNoPatologicos.otrosAntecedentesNoPatologicos" class="form-control " id="otros " placeholder=" ">
                                    </div>

                                </div>

                                <div class="" >
                                    <button class="btn btn-primary" (click)="agregarPersonalesNoPatologicos()">Guardar</button>
                                    <button class="btn btn-success" (click)="actualizarNoPatologicos()">Actualizar</button>
                                </div>
                            </form>
                        </div>
                        <!-- Fin Antecedentes Personales No Patologicos -->

                        <!-- Inicio Signos Vitales -->
                        <div role="tabpanel" class="tab-pane" id="signosVitales">
                            <form #fSignosVitales>
                                <div class="container-fluid">
                                    <div class="">
                                        <br>
                                        <div class="row">
                                            <div class="col">
                                                <h5>Talla (m)</h5>
                                            </div>

                                            <div class="col">
                                                <h5>Peso (kg)</h5>
                                            </div>
                                            <div class="col">
                                                <h5>IMC (P/T^2)</h5>
                                            </div>
                                            <div class="col" *ngIf="paciente.edad > 9">
                                                <h5>
                                                    TA (Sist/Dias)</h5>
                                            </div>
                                            <div class="col" *ngIf="paciente.edad <= 9">
                                                <h5>
                                                    F.C. (LPM)</h5>
                                            </div>
                                            <div class="col" *ngIf="paciente.edad <= 9">
                                                <h5>
                                                    F.R. (RPM)</h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    Temp °C</h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    P.C. (cm)</h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    P.A. (cm)</h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    P.T. (cm)</h5>
                                            </div>
                                            <div class="col" *ngIf="paciente.edad <= 9">
                                                <h5>
                                                    SaO (SaO%)</h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    PaO
                                                </h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    Glucosa mg/dl
                                                </h5>
                                            </div>
                                            <div class="col">
                                                <h5>
                                                    Fecha</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- TODO: QUEDAN PENDIENTES LOS SIGNOS DE TA, FC, FR -->

                                    <div class="card-body">
                                        <div *ngFor="let signos of historialSginos">
                                            <!-- <pre> {{  signos| json }}</pre> -->
                                            <div class="row">
                                                <div class="col">
                                                    {{ signos.talla }}
                                                </div>

                                                <div class="col">
                                                    {{ signos.peso }}
                                                </div>

                                                <div class="col">
                                                    {{ signos.imc}}
                                                </div>

                                                <div class="col" *ngIf="paciente.edad > 9">
                                                    {{ signos.sistolica}}/{{signos.diastolica}}
                                                </div>
                                                <div class="col" *ngIf="paciente.edad <= 9">
                                                    {{signos.fc}}
                                                </div>
                                                <div class="col" *ngIf="paciente.edad <= 9">
                                                    {{signos.fr}}
                                                </div>
                                                <div class="col" style="
                                                padding-left: 4rem;">
                                                    {{ signos.temp }}
                                                </div>
                                                <div class="col">
                                                    {{ signos.pc }}
                                                </div>
                                                <div class="col">
                                                    {{ signos.pa }}
                                                </div>
                                                <div class="col">
                                                    {{ signos.pt }}
                                                </div>
                                                <div class="col" *ngIf="paciente.edad <= 9">
                                                    {{ signos.SaO }}
                                                </div>
                                                <div class="col">
                                                    {{signos.pao}}
                                                </div>
                                                <div class="col">
                                                    {{signos.glucosa}}
                                                </div>
                                                <div class="col">
                                                    {{ signos.fechaIngreso }}
                                                </div>
                                            </div>

                                        </div>

                                        <!-- <div class="card-body">
                                            <div *ngFor="let signos of historialSginos">

                                                <div class="row  mt-1 ">
                                                    <div class="col-md-1">
                                                        {{ signos.talla }}
                                                    </div>

                                                    <div class="col-md-1">
                                                        {{ signos.peso }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.imc}}
                                                    </div>

                                                    <div class="col-md-1">

                                                    </div>

                                                    <div class="col-md-1">

                                                    </div>
                                                    <div class="col-md-1">

                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.temp }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.pc }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.pa }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.pt }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.SaO }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        {{ signos.fechaIngreso }}
                                                    </div>

                                                </div>

                                            </div>
                                        </div> -->

                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Fin Signos Vitales -->

                        <!-- Inicio Interrogación por aparatos y sistemas -->

                        <div role="tabpanel" class="tab-pane" id="interrogacionPorAparatosYsistemas">
                            <!--h4 class="text-principal"> Interrogacion por aparatos y sistemas </h4-->
                            <br>
                            <div class="container" style="overflow-x: scroll; height: 19rem;">
                                <form action="">
                                    <div class="row">
                                        <div class="col-md-3">

                                            <label for="respiratorio">Respiratorio/Cardiovascular</label>
                                        </div>

                                        <div class="col-md-9">
                                            <input type="text" name="respiraotriosCardiovasculares" [(ngModel)]="hojaEvolucion.respiratorioCardiovascular" class="form-control">

                                        </div>
                                    </div>
                                    <div class="row">


                                        <div class="col-md-3">

                                            <label for="digestivo">Digestivo</label>
                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" class="form-control" name="digestivo" [(ngModel)]="hojaEvolucion.digestivo">

                                        </div>

                                        <div class="col-md-3">
                                            <label for="endocrino"> Endocrino </label>
                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" class="form-control" name="endocrino" id="endocrino" [(ngModel)]="hojaEvolucion.endocrino">
                                        </div>

                                        <div class="col-md-3">

                                            <label for="musculo">Musculo - Esquelético</label>
                                        </div>

                                        <div class="col-md-8">

                                            <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.musculoEsqueletico" name="musculoEsqueletico">
                                        </div>

                                        <div class="col-md-3">
                                            <label for="gitourinario">Gitourinario</label>

                                        </div>

                                        <div class="col-md-8">

                                            <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.genitourinario" name="genitourinario">
                                        </div>
                                        <div class="col-md-3">
                                            <label for="hematopoyetico">Hematopoyético - Linfático</label>

                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.hematopoyeticoLinfatico" name="hematopoyeticoLinfatico">

                                        </div>
                                        <div class="col-md-3">
                                            <label for="piel">Piel y anexo</label>

                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.pielAnexos" name="pielAnexos">

                                        </div>

                                        <div class="col-md-3">
                                            <label for="neurologico">Neurológico y Psiquiátrico</label>

                                        </div>

                                        <div class="col-md-8">
                                            <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.neurologicoPsiquiatricos" name="neurologicoPsiquiatricos">

                                        </div>

                                        <button class="btn btn-primary" (click)="actulizarAparatosSistemas()" >
                                            Guardar
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <!-- Fin Interrogración por aparatos y sistemas -->
                        <div role="tabpanel" class="tab-pane" id="fEsquemaVacunacion">
                            <br>
                            <form #fEsquemaVacunacion="ngForm">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Vacuna</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Previene</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Dosis</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Fecha 1</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Fecha 2</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Fecha 3</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Fecha 4</h5>
                                        </div>
                                        <div class="col">
                                            <h5>Ultima fecha</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p class="text-aling: center">BCG</p>
                                        </div>
                                        <div class="col">
                                            <p class="text-aling: center">Tuberculosis</p>
                                        </div>
                                        <div class="col">
                                            <input type="text" placeholder="" name="tubercul" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoDosis" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input type="text" placeholder="" name="tuberculosisNinoFechaUno" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaUno" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input type="text" placeholder="" name="tuberculosisNinoFechaDos" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaDos" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input type="text" placeholder="" name="tuberculosisNinoFechaTres" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaTres" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input type="text" placeholder="" name="tuberculosisNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaCuatro" class="form-control">
                                        </div>
                                        <div class="col">
                                            <input type="text" placeholder="" name="tuberculosisNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaUltima" class="form-control">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Hepatitis B</p>
                                        </div>
                                        <div class="col">
                                            <p>Hepatitis B</p>
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="hepatitisNinoDosis" [(ngModel)]="esquemaVacuncaion.hepatitisNinoDosis" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaUno" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaUno" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaDos" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaDos" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaTres" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaTres" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaCuatro" class="form-control">
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaUltima" class="form-control">
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Pentavalente</p>
                                        </div>
                                        <div class="col">
                                            <p>Difteria, Tosferina, Tétanos, Poliomielitis e Infecciones por H. Influenzae b</p>
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="pentavalenteNinoDosis" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="pentavalenteNinoFechaUno" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="pentavalenteNinoFechaDos" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="pentavalenteNinoFechaTres" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="pentavalenteNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="pentavalenteNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>DPT</p>
                                        </div>
                                        <div class="col">
                                            <p>Difteria, Tosferina y Tétanos</p>
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="dptNinoDosis" [(ngModel)]="esquemaVacuncaion.dptNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="dptNinoFechaUno" [(ngModel)]="esquemaVacuncaion.dptNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="dptNinoFechaDos" [(ngModel)]="esquemaVacuncaion.dptNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="dptNinoFechaTres" [(ngModel)]="esquemaVacuncaion.dptNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="dptNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.dptNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="dptNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.dptNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Rotavirus</p>
                                        </div>
                                        <div class="col">
                                            <p>Diarrea por Rotavirus</p>
                                        </div>
                                        <div class="col"><input type="text" placeholder="" name="rotavirusNinoDosis" [(ngModel)]="esquemaVacuncaion.rotavirusNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="rotavirusNinoFechaUno" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="rotavirusNinoFechaDos" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="rotavirusNinoFechaTres" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="rotavirusNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="rotavirusNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Neumococo</div>
                                        <div class="col">Infecciones por Neumococo</div>
                                        <div class="col"><input type="text" placeholder="" name="neumococoNinoDosis" [(ngModel)]="esquemaVacuncaion.neumococoNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="neumococoNinoFechaUno" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="neumococoNinoFechaDos" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="neumococoNinoFechaTres" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="neumococoNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="neumococoNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Influenza</div>
                                        <div class="col">Influenza</div>
                                        <div class="col"><input type="text" placeholder="" name="influenzaNinoDosis" [(ngModel)]="esquemaVacuncaion.influenzaNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="influenzaNinoFechaUno" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="influenzaNinoFechaDos" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="influenzaNinoFechaTres" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="influenzaNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="influenzaNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">SRP</div>
                                        <div class="col">Sarampión, Rubéola, Parotiditis</div>
                                        <div class="col"><input type="text" placeholder="" name="sprNinoDosis" [(ngModel)]="esquemaVacuncaion.sprNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sprNinoFechaUno" [(ngModel)]="esquemaVacuncaion.sprNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sprNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sprNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sprNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sprNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sprNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.sprNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sprNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.sprNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Sabin</div>
                                        <div class="col">Poliomielitis</div>
                                        <div class="col"><input type="text" placeholder="" name="sabinNinoDosis" [(ngModel)]="esquemaVacuncaion.sabinNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sabinNinoFechaUno" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sabinNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sabinNinoFechaTres" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sabinNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="sabinNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Otras Vacunas</div>
                                        <div class="col">Otras Enfermedades</div>
                                        <div class="col"><input type="text" placeholder="" name="otrasVacunasNinoDosis" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoDosis" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="otrasVacunasNinoFechaUno" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaUno" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="otrasVacunasNinoFechaDos" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaDos" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="otrasVacunasNinoFechaTres" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaTres" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="otrasVacunasNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaCuatro" class="form-control"></div>
                                        <div class="col"><input type="text" placeholder="" name="otrasVacunasNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaUltima" class="form-control"></div>
                                    </div>
                                    <div class="row">
                                        <h5>Alergias:</h5>
                                        <textarea name="alergia" [(ngModel)]="esquemaVacuncaion.alergia" id="alergia" class="textarea form-control"></textarea>
                                    </div>
                                </div>
                                <!--div class="card center">
                                    <table class="table">

                                        <tbody>
                                            <tr>
                                                <td>BCG</td>
                                                <td>Tuberculosis</td>
                                                <td>
                                                    <input type="text" placeholder="" name="tubercul" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoDosis" class="antecedentes-inputs">
                                                </td>

                                                <td>
                                                    <input type="text" placeholder="" name="tuberculosisNinoFechaUno" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaUno" class="antecedentes-inputs">
                                                </td>

                                                <td>
                                                    <input type="text" placeholder="" name="tuberculosisNinoFechaDos" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaDos" class="antecedentes-inputs">
                                                </td>

                                                <td>
                                                    <input type="text" placeholder="" name="tuberculosisNinoFechaTres" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaTres" class="antecedentes-inputs">
                                                </td>

                                                <td>
                                                    <input type="text" placeholder="" name="tuberculosisNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaCuatro" class="antecedentes-inputs">
                                                </td>

                                                <td>
                                                    <input type="text" placeholder="" name="tuberculosisNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaUltima" class="antecedentes-inputs">
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>Hepatitis B</td>
                                                <td>Hepatitis B</td>
                                                <td><input type="text" placeholder="" name="hepatitisNinoDosis" [(ngModel)]="esquemaVacuncaion.hepatitisNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="hepatitisNinoFechaUno" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="hepatitisNinoFechaDos" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="hepatitisNinoFechaTres" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="hepatitisNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="hepatitisNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>Pentavalente</td>
                                                <td>Difteria, Tosferina, Tétanos, Poliomielitis e Infecciones por H. Influenzae b</td>
                                                <td><input type="text" placeholder="" name="pentavalenteNinoDosis" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="pentavalenteNinoFechaUno" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="pentavalenteNinoFechaDos" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="pentavalenteNinoFechaTres" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="pentavalenteNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="pentavalenteNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>DPT</td>
                                                <td>Difteria, Tosferina y Tétanos</td>
                                                <td><input type="text" placeholder="" name="dptNinoDosis" [(ngModel)]="esquemaVacuncaion.dptNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="dptNinoFechaUno" [(ngModel)]="esquemaVacuncaion.dptNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="dptNinoFechaDos" [(ngModel)]="esquemaVacuncaion.dptNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="dptNinoFechaTres" [(ngModel)]="esquemaVacuncaion.dptNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="dptNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.dptNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="dptNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.dptNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>Rotavirus</td>
                                                <td>Diarrea por Rotavirus</td>
                                                <td><input type="text" placeholder="" name="rotavirusNinoDosis" [(ngModel)]="esquemaVacuncaion.rotavirusNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="rotavirusNinoFechaUno" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="rotavirusNinoFechaDos" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="rotavirusNinoFechaTres" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="rotavirusNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="rotavirusNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>Neumococo</td>
                                                <td>Infecciones por Neumococo</td>
                                                <td><input type="text" placeholder="" name="neumococoNinoDosis" [(ngModel)]="esquemaVacuncaion.neumococoNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="neumococoNinoFechaUno" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="neumococoNinoFechaDos" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="neumococoNinoFechaTres" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="neumococoNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="neumococoNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>Influenza</td>
                                                <td>Influenza</td>
                                                <td><input type="text" placeholder="" name="influenzaNinoDosis" [(ngModel)]="esquemaVacuncaion.influenzaNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="influenzaNinoFechaUno" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="influenzaNinoFechaDos" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="influenzaNinoFechaTres" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="influenzaNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="influenzaNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>SRP</td>
                                                <td>Sarampión, Rubéola, Parotiditis</td>
                                                <td><input type="text" placeholder="" name="sprNinoDosis" [(ngModel)]="esquemaVacuncaion.sprNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sprNinoFechaUno" [(ngModel)]="esquemaVacuncaion.sprNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sprNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sprNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sprNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sprNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sprNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.sprNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sprNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.sprNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>Sabin</td>
                                                <td>Poliomielitis</td>
                                                <td><input type="text" placeholder="" name="sabinNinoDosis" [(ngModel)]="esquemaVacuncaion.sabinNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sabinNinoFechaUno" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sabinNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sabinNinoFechaTres" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sabinNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="sabinNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>SR</td>
                                                <td>Sarampión y Rubéola</td>
                                                <td><input type="text" placeholder="" name="srNinoDosis" [(ngModel)]="esquemaVacuncaion.srNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="srNinoFechaUno" [(ngModel)]="esquemaVacuncaion.srNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="srNinoFechaDos" [(ngModel)]="esquemaVacuncaion.srNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="srNinoFechaTres" [(ngModel)]="esquemaVacuncaion.srNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="srNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.srNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="srNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.srNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                            <tr>
                                                <td>Otras Vacunas</td>
                                                <td>Otras Enfermedades</td>
                                                <td><input type="text" placeholder="" name="otrasVacunasNinoDosis" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoDosis" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="otrasVacunasNinoFechaUno" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaUno" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="otrasVacunasNinoFechaDos" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaDos" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="otrasVacunasNinoFechaTres" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaTres" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="otrasVacunasNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaCuatro" class="antecedentes-inputs"></td>
                                                <td><input type="text" placeholder="" name="otrasVacunasNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaUltima" class="antecedentes-inputs"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div-->
                                <!--div class="">
                                    <h5>Alergias:</h5>
                                    <textarea name="alergia" [(ngModel)]="esquemaVacuncaion.alergia" id="alergia" class="textarea"></textarea>
                                </div-->
                                <div class="" >
                                    <button class="btn btn-primary" (click)="agregarVacunacionNinos()">Guardar</button>
                                    <button class="btn btn-success" (click)="actualizarEsquemaVacunacion()">Actualizar</button>
                                </div>
                            </form>
                        </div>
                        <!-- Fin Esquema de Vacunación -->


                        <div role="tabpanel" class="tab-pane" id="ginecoxVisita">

                            <div class="container ">
                                <form action=" ">
                                    <div class="form-row ">
                                        <div class="col ">
                                            <div class="form-row ">
                                                <div class="form-check form-check-inline ">
                                                    <label for=" " style="margin-right: 4rem;">¿Embarazada Actualmente?</label>
                                                    <input class="form-check-input" type="radio" name="embarazoActualSi" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.embarazoActual" id="eASi" value="Si">
                                                    <label class="form-check-label" style="margin-right: 0.5rem;" for="eAsi">Si</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.embarazoActual" type="radio" name="embNo" id="eASi" value="No">
                                                    <label class="form-check-label" style="margin-right: 2rem;" for="eANo">No</label>
                                                </div>
                                            </div>
                                            <div class="form-row ">

                                                <div class="form-check form-check-inline ">
                                                    <label for=" " style="margin-right: 4rem;">¿Embarazada de Alto Riesgo?</label>
                                                    <input class="form-check-input" type="radio" name="altoRisego" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.embarazoAltoRiesgo" id="alRSi" value="Si">
                                                    <label class="form-check-label" style="margin-right: 2rem;" for="alRSi">Si</label>
                                                </div>
                                                <div class="form-check form-check-inline ">
                                                    <input class="form-check-input" type="radio" name="altoResgoNo" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.embarazoAltoRiesgo" id="alRSi" value="No">
                                                    <label class="form-check-label" style="margin-right: 2rem;" for="alRNo">No</label>
                                                </div>

                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" " style="margin-right: 2rem;">Administración de: </label>
                                                        <input type="text " class="form-control antecedentes-inputs" name="hierro" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.fechaAdmonHierro" [value]="agregarGinecoObstetricosPorVisitaForm.fechaAdmonHierro" placeholder="Hierro ">
                                                        <input type="text " class="form-control" name="aFolico" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.administracionHierroAcidoF" [value]="agregarGinecoObstetricosPorVisitaForm.administracionHierroAcidoF" placeholder="A. Folico">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" " style="margin-right: 4rem;">Fecha: </label>
                                                        <input type="date" name="fechaAdmon" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.fechaAdmonHierroAcidoF" [value]="agregarGinecoObstetricosPorVisitaForm.fechaAdmonHierroAcidoF" class="form-control">
                                                        <input type="date" name="fechaAdmon2" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.fechaAdmonAcido" [value]="agregarGinecoObstetricosPorVisitaForm.fechaAdmonAcido" class="form-control">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row ">

                                                <div class="form-check form-check-inline ">
                                                    <label for=" " style="margin-right: 4rem;">¿Método anticonceptivo después del parto?</label>
                                                    <input class="form-check-input" type="radio" name="metodoASoi" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.metodoAntiConceptivoPostParto" id="metSi" value="Si">
                                                    <label class="form-check-label" for="metSi">Si</label>
                                                </div>
                                                <div class="form-check form-check-inline ">
                                                    <input class="form-check-input" type="radio" name="metodoANo" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.metodoAntiConceptivoPostParto" id="metSi " value="No">
                                                    <label class="form-check-label " for="metNo ">No</label>
                                                </div>

                                            </div>

                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Inicio de la vida sexual activa: </label>
                                                        <input type="text" name="inicioVSA" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.incioVidaSexualActiva" [value]="agregarGinecoObstetricosPorVisitaForm.incioVidaSexualActiva" class="form-control antecedentes-inputs ">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Fecha del último parto: </label>
                                                        <input type="date" name="dateUltimoParto" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.fechaUltimoParto" [value]="agregarGinecoObstetricosPorVisitaForm.fechaUltimoParto" class="form-control ">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Crecimiento de vellos pubicos: </label>
                                                        <input type="text" name="crecimientoBP" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.crecimientoBellosPubicos" [value]="agregarGinecoObstetricosPorVisitaForm.crecimientoBellosPubicos" class="form-control ">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Tipo de método de planif. </label>
                                                        <input type="text" name="planFam" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.tipoMetodoPlanFamiliar" [value]="agregarGinecoObstetricosPorVisitaForm.tipoMetodoPlanFamiliar" class="form-control ">

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col ">
                                            <div class="form-row ">
                                                <div class="form-group col-md-2 ">
                                                    <label for="gesta">Gestas</label>
                                                    <input type="text" name="gestas" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.gestas" [value]="agregarGinecoObstetricosPorVisitaForm.gestas" class="form-control " id="">
                                                </div>
                                                <div class="form-group col-md-2 ">
                                                    <label for="partos">Partos</label>
                                                    <input type="text" name="partos" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.partos" [value]="agregarGinecoObstetricosPorVisitaForm.partos" class="form-control " id="">
                                                </div>
                                                <div class="form-group col-md-2 ">
                                                    <label for="cesareas">Cesáreas</label>
                                                    <input type="text" name="cesareas" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.cesareas" [value]="agregarGinecoObstetricosPorVisitaForm.cesareas" class="form-control " id="">
                                                </div>
                                                <div class="form-group col-md-2 ">
                                                    <label for="abortos">Abortos</label>
                                                    <input type="text" name="abortos" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.abortos" [value]="agregarGinecoObstetricosPorVisitaForm.abortos" class="form-control " id="">
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Último Papanicolao: </label>
                                                        <input type="text" name="papanicolao" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.ultimoPapanicolao" [value]="agregarGinecoObstetricosPorVisitaForm.ultimoPapanicolao" class="form-control antecedentes-inputs ">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Última Colposcopía: </label>
                                                        <input type="text" name="colposcopia" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.ultimaColposcopia" class="form-control antecedentes-inputs ">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Última revisión mamaria: </label>
                                                        <input type="text" name="revisionMamaria" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.ultimaRevisionMamaria" class="form-control antecedentes-inputs ">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Nacidos: </label>
                                                        <input type="text" name="vivos" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.bebesNacidos" class="form-control antecedentes-inputs " placeholder="Vivos ">
                                                        <input type="text" name="muertos" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.bebesMuertos" class="form-control " placeholder="Muertos ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">1era Menstruación: </label>
                                                        <input type="text" name="monstruacion" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.primeraMenstruacion" class="form-control antecedentes-inputs ">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Crecimiento de Pechos: </label>
                                                        <input type="text" name="cPechos" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.crecimientoDePechos" class="form-control antecedentes-inputs ">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Primer Embarazo: </label>
                                                        <input type="text" name="firstEmbarazo" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.primerEmbarazo" class="form-control antecedentes-inputs">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row ">
                                                <div class="form-group col-md6 ">
                                                    <div class="form-check form-check-inline ">
                                                        <label for=" ">Fecha: </label>
                                                        <input type="date" name="fechaas" [(ngModel)]="agregarGinecoObstetricosPorVisitaForm.fechaPrimerEmbarazo" class="form-control ">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md" >
                                                    <button (click)="agregarGinecoObstetricosPorVisita()" class="btn btn-primary">
                                                        Guardar
                                                    </button>

                                                    <button class="btn btn-success" (click)="actualizarGinecoPorVisitalt()">Actualizar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <!-- end container -->

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>