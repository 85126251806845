<div class="container-fluid">
    <div class="card">
        <div class="row">
            <div class="col-md-4" id="separacion">
                <div class="row" id="titulos">
                    <div class="col">
                        <p>AGENDA DOCTORES</p>
                    </div>
                    <div class="col">
                        <p>HOY <span>12 OCTUBRE 2022</span></p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="row" style="overflow: auto; padding: 12px; height: 556px !important;">
                    <ul class="list-group" *ngFor="let item of [1,1,1,1,1,1,1,1]">
                        <div class="list-group-item list-group-item-action d-flex justify-content-start pointer" id="items">
                            <div class="image-parent">
                                <div class="icon-button">
                                    <img src="../../../../assets/images/doctoricono.png" data-badge="100" class="img-fluid" alt="quixote">
                                    <span class="icon-button__badge"></span>
                                </div>
                            </div>
                            <div class="flex-column">
                                <div class="row" id="doctor">
                                    <div class="col">
                                        <p>DOC. DAVID REYES SALAZAR</p>
                                    </div>
                                    <div class="col">
                                        <span class="badge badge-success badge-pill"> VER CITAS DISPONIBLES</span>
                                    </div>
                                </div>
                                <p style="margin: 0px !important;"><small>SERVICIO: ENDOSCOPIA</small></p>
                                <p style="margin: 0px !important;"><small>PACIENTE: VICTOR MANUEL ARAGON CASTELLANOS</small></p>
                                <div class="row">
                                    <div class="col">
                                        <p style="margin: 0px !important;"><small>CITA: 12:00 - 13:00</small></p>
                                    </div>
                                    <div class="col">
                                        <p style="margin: 0px !important;"><small>UNIDAD MEDICA: TLY01</small></p>
                                    </div>
                                </div>
                            </div>      
                        </div>
                        <div class="divider2"></div>
                    </ul>
                </div>
            </div>
            <div class="col">
                <div class="row botonAgenda" style="justify-content: flex-end;">
                    <button >AGENDAR</button>
                </div>
                <div class="row">
                    <div class="col">
                        <select>
                            <option value="">SELECCIONE UN PACIENTE</option>
                        </select>
                    </div>
                    <div class="col">
                        <select>
                            <option value="">SELECCIONA UN SERVICIO</option>
                        </select>
                    </div>
                    <div class="col">
                        <select>
                            <option value="">SELECCION UNA UNIDAD MEDICA</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="card calendario">
                        <app-calendario-personal [role]="role"></app-calendario-personal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <app-calendario-personal></app-calendario-personal> -->