
<div class="container-fluid">
  <section class="row m-0" class="no-print-body">
    <div class="col-md-12 mb-3">
      <app-titulos [title]="'DETALLE DE SERVICIO'" [rol]="'almacen'"> </app-titulos>
    </div>
  </section>

  <div class="row ml-3 no-print-body">
    <div class="col-md-7">
      <div class="form-group">
        <input
          type="text"
          name=""
          placeholder="Buscar..."
          class="form-control rounded-pill pl-4 shadow-input-bs"/>
      </div>
    </div>
    <div class="col-md-3 ">
      <button class="btn btn-block rounded-pill btnPrincipalBS"  (click)="printTable()" >
        IMPRIMIR
      </button>
    </div>
  </div>

  <section class="container-fluid">
    <div class="card" name="card" id="card">
      <div class="row">
        <div class="col margin-info">
          <p><span>NOMBRE: {{servicio.nombreDeServicio}}</span></p>
        </div>
        <div class="col">
          <p><span>ID: {{servicio._id}}</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 margin-info">
          <span>DISPONIBILIDAD:</span>
        </div>
        <div class="col">
          <div class="row" *ngFor="let item of servicio.fechas">
            <div class="col">
              <p>{{ item.start | date:'dd/MM/yyyy':'UTC' }} DE {{item.start | date:'shortTime'}} A {{item.end | date:'shortTime'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="row no-print-body">
  <div
    class="card textos"
    id="card"
  >
    <div class="row mt-4">
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">NOMBRE:</p>
          <div class="col-8 p-0">
            <p>
                {{ service.nombre }}
            </p>
          </div>
        </div>
      </div>
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">ID:</p>
          <div class="col-8 p-0">
            <p>{{  service.idServicio }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">TIPO DE SERVICIO:</p>
          <div class="col-8 p-0">
            <p>{{ service.tipoServicio }}</p>
          </div>
        </div>
      </div>
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">COSTO:</p>
          <div class="col-8 p-0">
            <p>{{service.costo | currency }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">DESCRIPCIÓN:</p>
          <div class="col-8 p-0">
            <p>
              {{ service.descripcion }}
            </p>
          </div>
        </div>
      </div>
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">ESTADO:</p>
          <div class="col-8 p-0">
            <p>
              {{ service.status }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4">
            HORARIO:</p>
          <div class="col-8 p-0">
            <div *ngFor="let hora of service.horario" > 
              <p> {{ hora | uppercase }} </p>

            </div>
          </div>
        </div>
      </div>
      <div class="col ml-4">
        <div class="row m-0">
          <p class="col-md-4"> PROFESIONAL A REALIZAR: </p>
          <div class="col-8 p-0">
            <p>
              {{service.profesionalAREalizar}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

  <div class="row m-0" noprint >
    <div class="col p-0">
      <div class="card" id="tableServicesToPrint" >
        <div class="header__product--detail">
          <div class="row">
            <div class="col-md-1 text_header-services">
              <p>No.</p>
            </div>
            <div class="col-sm text_header-services">
              <p>NOMBRE</p>
            </div>
            <div class="col-md-3 text_header-services">
              <p>DESCRIPCIÓN</p>
            </div>
            <div class="col-sm text_header-services">
              <p>CATEGORÍA</p>
            </div>
            <div class="col-sm-2 text_header-services">
              <p>PROFESIONAL A REALIZAR</p>
            </div>
            <div class="col text_header-services">
              <p>FACTURA</p>
            </div>
            <div class="col text_header-services">
              <p>HORARIO</p>
            </div>
          </div>
        </div>

        <div class="card-body"  style="padding: 1rem 0rem !important">
          <!-- SOLO SON DEMOSTRATIVOS -->
          <div class="row">
            <div class="col-md-1">
              <p class="textos-center img-to-print">01</p>
            </div>
            <div class="col-sm">
              <p class="textos-center">CENTRIFUGADO</p>
            </div>
            <div class="col-md-3">
              <p class="textos-center">
                CENTRIFUGADO DEL CONCENTRADO ELITROCITARIO
              </p>
            </div>
            <div class="col-sm">
              <p class="textos-center">BANCO DE SANGRE</p>
            </div>
            <div class="col-sm-2">
              <p class="textos-center">DR. DAVID REYES</p>
            </div>
            <div class="col">
              <p class="textos-center">123156489132</p>
            </div>
            <div class="col">
              <p class="textos-center">L,M,X,J,V</p>
            </div>
          </div>
        </div>

        <!-- Fin de la tabla de los servicios -->
        <div class="offset-md-4"  class="no-print-body" >
          <pagination-controls
            id="listaproductos"
            maxSize="5"
            directionLinks="true"
            (pageChange)="pagina = $event">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
