<div class="container center">

    <div class="col inputsPediatria">
        <label for="talla">Talla (m): </label>
        <input type="text" placeholder="Talla" name="tallaNina" required [(ngModel)]="paciente.talla">
        <label for="peso">Peso (kg): </label>
        <input type="text" placeholder="Peso" name="pesoNina" required [(ngModel)]="paciente.peso" (blur)="obtenerIMCDos()">
        <label for="imc">IMC: </label>
        <input type="text" placeholder="IMC" name="imcNina" required [(ngModel)]="paciente.imc">
        <label for="pc">P.C. : </label>
        <input type="text" placeholder="P.C." name="pcNina" required [(ngModel)]="paciente.pc" (blur)="recorrerEdadMesesNinaPC()">
        <!-- <button class="btn btn-primary" style="margin: 2rem 0" (click)="updateSV()">Actualizar Signos</button> -->

    </div>
    <div class="row">
        <!-- Grafica Talla Niña -->
        <div class="col-sm-4 muf">
            <div class="flex">
                <div class="flex-item">
                    <h2>Estatura</h2>
                    <div style="display: block;">
                        <canvas baseChart width="300" height="300" [datasets]="lineChartTallaNina" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>

        <!-- Grafica Peso Niña -->
        <div class="col-sm-4">
            <div class="flex">
                <div class="flex-item">
                    <h2>Peso</h2>
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="lineChartPesoNina" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>

        <!-- Grafica IMC Niña -->
        <div class="col-sm-4 muf">

            <div class="flex">
                <div class="flex-item">
                    <h2>Índice de Masa Corporal</h2>
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="lineChartIMCNina" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>

        <!-- Grafica Perimetro Céfalico -->
        <div class="col-sm-4 muf mt-3">

            <div class="flex">
                <div class="flex-item">
                    <h2>Perímetro Cefálico</h2>
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="chartCefalicoNinas" [labels]="cefalicoNinasEjeX" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>

        <!-- Grafica Perimetro Céfalico -->
        <div class="col-sm-4 muf mt-3">

            <div class="flex">
                <div class="flex-item">
                    <h2>Relacion Peso * Talla</h2>
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="chartPesoPorTallaNinas" [labels]="pesoPorTallaNinasEjeX" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins"
                            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>
        <!--<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="col card">
                        <div class="flex">
                            <div class="flex-item ">
                                <div style="display: block;">
                                    <canvas baseChart width="500" height="500" [datasets]="lineChartTallaNina" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                                        (chartClick)="chartClicked($event)"></canvas>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="col card">
                        <div class="flex">
                            <div class="flex-item">
                                <div style="display: block; max-width: 1000px; height: 400px;">
                                    <canvas baseChart width="500" height="500" [datasets]="lineChartPesoNina" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                                        (chartClick)="chartClicked($event)"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="col card">

                        <div class="flex">
                            <div class="flex-item">
                                <div style="display: block; max-width: 1000px; height: 400px;">
                                    <canvas baseChart width="500" height="500" [datasets]="lineChartIMCNina" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                                        (chartClick)="chartClicked($event)"></canvas>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="col card">

                        <div class="flex">
                            <div class="flex-item ">
                                <div style="display: block; max-width: 1000px; height: 400px;">
                                    <canvas baseChart width="500" height="500" [datasets]="chartCefalicoNinas" [labels]="cefalicoNinasEjeX" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                                        (chartClick)="chartClicked($event)"></canvas>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="col card">

                        <div class="flex">
                            <div class="flex-item ">
                                <div style="display: block; max-width: 1000px; height: 400px;">
                                    <canvas baseChart width="500" height="500" [datasets]="chartPesoPorTallaNinas" [labels]="pesoPorTallaNinasEjeX" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins"
                                        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div> -->
        <br>

    </div>
</div>