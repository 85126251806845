import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlebotomiaService } from 'src/app/services/flebotomia.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-documentos-donante-flebotomia',
  templateUrl: './documentos-donante-flebotomia.component.html',
  styleUrls: ['./documentos-donante-flebotomia.component.css']
})
export class DocumentosDonanteFlebotomiaComponent implements OnInit {

  public idUrlBanco = "";
  // public fecha = moment().format('LLL');
  public fecha = moment().format('L');
  public hora = moment().format('LT')
  public horainicio
  public horafinal
  public paciente = {
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
    religion:''
  };
  public btnSend = true;
  public acuerdoValidate = '';
  public  dataDonante = {
    tipo_de_sangreado:"",
    tipo_de_donador:"",
    notas:"",
    idbancosangre:"",
    volumen:"",
    tiempo:"5",
    archivos:[]
  }
  public rechazoData = {
    proceso: "",
    estatus: "",
    motivo: "",
    rechazo: "",
    fechaRechazo: "",
    idBanco: ""
  }
  public actualizacioProceso = {
    idbancosangre: "",
    proceso: ""
  }
  imagenes = [];

  constructor(private _activateRoute: ActivatedRoute,
              private _flebotomia: FlebotomiaService,
              private _router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getIdFlebotomia();
    this.fecha;
    // this.spinner.show()
  }
  
  // obtenemos el para FI
  getIdFlebotomia() {
    this.idUrlBanco = this._activateRoute.snapshot.paramMap.get('id');
    this.getDataFlebotomia()
  }
  //Funcion para obtener los datos de flebotomia de la FI
  getDataFlebotomia () {
    this._flebotomia.getFlebotomiaById( this.idUrlBanco )
    .subscribe( (data:any) => this.setPacienteInfo(data.data[0].paciente))
  }

  setPacienteInfo(data) {
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

  //LOADING
  pintLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
  }

  removeLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
    // console.log('dejo de funcionar');
  }

  // DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[0].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
    })
  }

  onRemove(event) {
    this.imagenes.splice(this.imagenes.indexOf(event), 1)
    this.files.splice(this.files.indexOf(event), 1);
  }

  respDatapacienteEmmit( event){
    this.dataDonante = event;
  }

  validateFieldsflebotomia() { 
    if( this.dataDonante.tipo_de_donador.length == 0 ){
      // alert("Ingresa el tipo de donador");
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Ingresa el tipo de donador',
      })
    }else if(  this.files.length < 3  ){
      // alert("Ingresa todos los documentos");
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Ingresa todos los documentos',
      })
      this.btnSend = true;
    }else {
      this.btnSend = false;  
    }
  }
  
  sendDataFlebomotomia() {
    if(this.acuerdoValidate === 'si') {
      //seteamos el idBanco de sangre 
      this.dataDonante.idbancosangre = this.idUrlBanco;
      this.dataDonante.archivos = this.imagenes
      this.spinner.show();
      // this.dataDonante = this.idUrlBanco;
      //const formData = new FormData();
      this._flebotomia.postFlebotomia(this.dataDonante).subscribe((data:any) =>{
        this.btnSend = true;
        // se hace la carga de los archivos y de los datos al sevidor 
        if(data.ok){
          this.actualizacioProceso.idbancosangre = this.dataDonante.idbancosangre;
          this.actualizacioProceso.proceso = "serologia";
          this._flebotomia.actualizacionCambioDeEstado(this.actualizacioProceso).subscribe((data:any) => {
            if(data.ok) {
              this.etiquetaNoLiberado(this.paciente.curp, this.dataDonante.tipo_de_sangreado)
              Swal.fire({
                icon: 'success',
                title: '',
                text: 'El proceso de donacion termina en serología',
              })
              this.spinner.hide();
              this._router.navigate([ `/dashboard`]);
            }
          });
        }else {
          this.btnSend = false;
        }
      });
      // Se envia la data del donante a la tabla de diferidos
    }else if (this.acuerdoValidate === 'no') {
      // TODO: PONER LA FECHA DE TERMINO DE LA AUTO-EXCLUSUION
      this.rechazoData.idBanco = this.idUrlBanco;
      this.rechazoData.motivo = "Autoexclusión";
      this.rechazoData.proceso = "Flebotomia";
      this.rechazoData.estatus = "diferido";
      this._flebotomia.postDiferirDisponente(this.rechazoData).subscribe( (data:any) => {
        if(data.ok) {
          this.spinner.hide();
          this._router.navigateByUrl('/');
        }
      });
    }
  }

  etiquetaNoLiberado(producto: any, sangre: any) {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [12, 8]
    });
    doc.setFont('helvetica')
    doc.setTextColor(150);
    doc.setFontSize(35);
    doc.text('NO LIBERADO', 3, 7, null, 45);
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text('BANCO DE SANGRE: CENSUR', 1, 1);
    doc.setFontSize(7);
    doc.text('FECHA: ', 7, 1);
    doc.text(this.fecha, 8, 1);
    doc.text('HORA: ', 10, 1);
    doc.text(this.hora, 11, 1);
    doc.text('HORA INICIO: ', 7, 1.5);
    doc.text(this.horainicio, 10, 1.5);
    doc.text('HORA TERMINO: ', 7, 2);
    doc.text(this.horafinal, 10, 2);
    doc.setFontSize(10);
    doc.text('COMPONENTE SANGUINEO: ', 1, 2.8);
    doc.text(sangre, 7, 2.8);
    doc.text('ID: ', 1, 3.5);
    doc.text(producto, 1.8, 3.5);
    doc.text('SOLUCION ANTICOAGULANTE: ', 1, 4.3);
    doc.text('soluc.', 7, 4.3);
    doc.text('VOLUMEN DE EXTRACCIÓN: ', 1, 5.2);
    doc.text(this.dataDonante.volumen + 'ml', 7, 5.2);
    doc.text('TEMPERATURA: ', 1, 6);
    doc.text('35 ºC.', 7, 6);
    doc.save(`ETIQUET_NO_LIBERADO: ${this.paciente.apellidoPaterno}`)
    //doc.save("Etiqueta_NO_Liberado.pdf")

  }

  // import { moduleOrComponent } from '../../../../../assets/images/qr_test';
  etiquetaLiberado(producto: any) {
    let imgEtiqueta = '../../../../../assets/images/qr_test.png'
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [12, 8]
    });
    doc.setFont('helvetica')
    doc.setTextColor(150);
    doc.setFontSize(58);
    doc.text('LIBERADO', 3, 8, null, 43);
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text('BANCO DE SANGRE: CENSUR', 1, 1);
    doc.setFontSize(7);
    doc.text('FECHA: ', 7, 1);
    doc.text('HORA: ', 10, 1);
    doc.text('HORA INICIO: ', 7, 1.5);
    doc.setFontSize(12);
    doc.text('CADUCIDAD: ', 7, 4);
    doc.setFontSize(10);
    doc.text('GRUPO Y RH: ', 7, 4.7);
    doc.text('ID: ', 1, 2.4);
    doc.text(producto, 2.6, 3);
    doc.text('COMPONENTE SANGUINEO: ', 1, 3);
    doc.text('SOLUCION ANTICOAGULANTE: ', 1, 3.5);
    doc.text('VOLUMEN DE EXTRACCIÓN: ', 1, 4);
    doc.text('TEMPERATURA: ', 1, 4.5);
    doc.text('SEROLOGIA: ', 1, 5.4);
    doc.addImage(imgEtiqueta, 'PNG', 7, 5.7, 20, 20)
    doc.setFontSize(5);
    doc.text('VIRUS DE INMUNODEFICIENCIA HUMANA: ', 1, 5.8);
    doc.text('ANTICUERPOS ANTI HEPATITIS B: ', 1, 6);
    doc.text('ANTICUERPOS ANTI HEPATITIS C: ', 1, 6.2);
    doc.text('VDRL: ', 1, 6.4);
    doc.text('CHAGAS: ', 1, 6.6);
    doc.text('BRUCELLAS: ', 1, 6.8);
    doc.text('DENGUE: ', 1, 7);
    doc.text('No transfundirse en presencia de hemólisis o cualquier otro signo de deterioro: ', 1, 7.3);
    doc.text('-No mezclarse con medicamentos o soluciones que no sea solución salina isotónica al 0.9%, y ', 1, 7.5);
    doc.text('-Transfundirse a través de un filtro de 170 – 200 micras o en su caso, un filtro para leucodepletar, y', 1, 7.7);
    doc.text('-Los procedimientos adicionales que se hubiesen efectuado, tales como irradiación, remoción de la capa leucoplaquetaria o filtrado para leucodepleción', 0.1, 7.9);
    doc.save("Etiqueta_Liberado.pdf")
  }

  downloadPdf(pdfUrl: string, pdfName: string ) {
    
  }

}
