import { Component, OnInit, Input } from '@angular/core';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';

@Component({
  selector: 'app-tab-hisroia-clinica',
  templateUrl: './tab-hisroia-clinica.component.html',
  styleUrls: ['./tab-hisroia-clinica.component.css']
})
export class TabHisroiaClinicaComponent implements OnInit {

  @Input() paciente;

  public antecedentes = {
    enfermedadesPielPersonal:"",
    enfermedadesPielFecha:"",
    enfermedadesPielFamiliares:"",
    enfermedadesPielNotas:"",
    desnutricionPersonal:"",
    desnutricionFecha:"",
    desnutricionFamiliares:"",
    desnutricionNotas:"",
    obesidadPersonal:"",
    obesidadFecha:"",
    obesidadFamiliares:"",
    obesidadNotas:"",
    defectosPostularesPersonal:"",
    defectosPostularesFecha:"",
    defectosPostularesFamiliares:"",
    defectosPostularesNotas:"",
    fracturasPersonal: "",
    fracturasFecha: "",
    fracturasFamiliares: "",
    fracturasNotas: "",
    hospitalizacionesPersonal:"",
    hospitalizacionesFecha:"",
    hospitalizacionesFamiliares:"",
    hospitalizacionesNotas:"",
    transfucionesPersonal:"",
    transfucionesFecha:"",
    transfucionesFamiliares:"",
    transfucionesNotas:"",
    cardiopatiasPersonal:"",
    cardiopatiasFecha:"",
    cardiopatiasFamiliares:"",
    cardiopatiasNotas:"",
    cirugiasPersonal:"",
    cirugiasFecha:"",
    cirugiasFamiliares:"",
    cirugiasNotas:"",
    cancerLeucemiaPersonal:"",
    cancerLeucemiaFecha:"",
    cancerLeucemiaFamiliares:"",
    cancerLeucemiaNotas:"",
    alergiasPersonal:"",
    alergiasFecha:"",
    alergiasFamiliares:"",
    alergiasNotas:"",
    vihPersonal:"",
    vihFecha:"",
    vihFamiliares:"",
    vihNotas:"",
    tabaquismoFecha:"",
    tabaquismoFamiliares:"",
    tabaquismoNotas:"",
    tabaquismoPersonal:"",
    diabetesPersonal:"",
    diabetesFecha:"",
    diabetesFamiliares:"",
    diabetesNotas:"",
    tuberculosisPersonal:"",
    tuberculosisFecha:"",
    tuberculosisFamiliares:"",
    tuberculosisNotas:"",
    alcoholismoPersonal:"",
    alcoholismoFecha:"",
    alcoholismoFamiliares:"",
    alcoholismoNotas:"",
    deportesPersonal:"",
    deportesFecha:"",
    deportesFamiliares:"",
    deportesNotas:"",
    idPaciente:"",
    otrasEnfPersonales:"",
    otrasEnfFecha:"",
    otrasEnfFamiliares:"",
    otrasEnfNotas:"",
    enfermedadesDeLosSentidosPersonales:"",
    enfermedadesDeLosSentidosFecha:"",
    enfermedadesSentidosFamiliares:"",
    enfermedadesSentidosNotas:"",
    expoLaboralPersonales:"",
    expoLaboralFecha:"",
    expoLaboralFamiliares:"",
    expoLaboralNotas:"",
    postQuirurgicoPersonales:"",
    postQuirurgicoFecha:"",
    postQuirurgicoFamiliares:"",
    postQuirurgicoNotas:"",
  };

  public antecdentesNoPatologicos ={ 
    alcoholismoPorDia:"",
    alergias:"",
    alimentacionAdecuada:"",
    aniosConsumoDrogas:"",
    aniosDeconsumoAlcohol:"",
    desconoceTipoSanguineo:"",
    drogadiccionTipo:"",
    exAlcoholicoUOcasional:"",
    exDrogadicto:"",
    tabaquismoPorDia:"",
    tipoAlergias:"",
    tipoSanguineo:"",
    viviendaConServiciosBasicos:""
    }


  constructor(
    private _HistoriaClinicaService: HistoriaClinicaService,
  ) { }

  ngOnInit(): void {
    /* console.log( this.paciente ); */
  }

  verAntecedentesNoPatologicos(){

    let body = {  idPaciente: this.paciente.idPaciente  }

    this._HistoriaClinicaService.verAntecendetesNoPatoloogicos( body )
    .subscribe(  (data) => {

        this.antecdentesNoPatologicos.alcoholismoPorDia = data['data'][0]['alcoholismoPorDia'];
        this.antecdentesNoPatologicos.alergias = data['data'][0]['alergias'];
        this.antecdentesNoPatologicos.aniosConsumoDrogas = data['data'][0]['aniosConsumoDrogas'];
        this.antecdentesNoPatologicos.alimentacionAdecuada = data['data'][0]['alimentacionAdecuada'];
        this.antecdentesNoPatologicos.aniosDeconsumoAlcohol = data['data'][0]['aniosDeconsumoAlcohol'];
        this.antecdentesNoPatologicos.desconoceTipoSanguineo = data['data'][0]['desconoceTipoSanguineo'];
        this.antecdentesNoPatologicos.drogadiccionTipo = data['data'][0]['drogadiccionTipo'];
        this.antecdentesNoPatologicos.exAlcoholicoUOcasional = data['data'][0]['exAlcoholicoUOcasional'];
        this.antecdentesNoPatologicos.exDrogadicto = data['data'][0]['exDrogadicto'];
        this.antecdentesNoPatologicos.tabaquismoPorDia = data['data'][0]['tabaquismoPorDia'];
        this.antecdentesNoPatologicos.tipoAlergias = data['data'][0]['tipoAlergias'];
        this.antecdentesNoPatologicos.tipoSanguineo = data['data'][0]['tipoSanguineo'];
        this.antecdentesNoPatologicos.viviendaConServiciosBasicos = data['data'][0]['viviendaConServiciosBasicos'];
        /* console.log(  this.antecdentesNoPatologicos ); */ 
    });
  }

  
  verAntecedentes(){

   this._HistoriaClinicaService.obtenerAntecedentes( this.paciente.idAntecedente )
   .subscribe( (data:Antecedentes) =>  { 

        this.antecedentes.enfermedadesPielPersonal = data['data'][0].enfermedadesPielPersonal
        this.antecedentes.enfermedadesPielFecha = data['data'][0].enfermedadesPielFecha,
        this.antecedentes.enfermedadesPielFamiliares = data['data'][0].enfermedadesPielFamiliares ,
        this.antecedentes.enfermedadesPielNotas = data['data'][0].enfermedadesPielNotas ,
        this.antecedentes.desnutricionPersonal = data['data'][0].desnutricionPersonal,
         this.antecedentes.desnutricionFecha= data['data'][0].desnutricionFecha,
        this.antecedentes.desnutricionFamiliares =data['data'][0] .desnutricionFamilia,
        this.antecedentes.desnutricionNotas = data['data'][0].desnutricionNotas,
        this.antecedentes.obesidadPersonal = data['data'][0].obesidadPersonal,
        this.antecedentes.obesidadFecha = data['data'][0].obesidadFecha,
        this.antecedentes.obesidadFamiliares = data['data'][0].obesidadFamiliares,
        this.antecedentes.obesidadNotas = data['data'][0].obesidadNotas,
        this.antecedentes.defectosPostularesPersonal = data['data'][0].defectosPostularesPersonal,
        this.antecedentes.defectosPostularesFecha = data['data'][0].defectosPostularesFecha,
        this.antecedentes.defectosPostularesFamiliares = data['data'][0].defectosPostularesFamiliares,
        this.antecedentes.defectosPostularesNotas = data['data'][0].defectosPostularesNotas,
        this.antecedentes.fracturasPersonal = data['data'][0].fracturasPersonal,
        this.antecedentes.fracturasFecha = data['data'][0].fracturasFecha,
        this.antecedentes.fracturasFamiliares = data['data'][0].fracturasFamiliares,
        this.antecedentes.fracturasNotas = data['data'][0].fracturasNotas,
        this.antecedentes.hospitalizacionesPersonal = data['data'][0].hospitalizacionesPersonal,
        this.antecedentes.hospitalizacionesFecha = data['data'][0].hospitalizacionesFecha,
        this.antecedentes.hospitalizacionesFamiliares = data['data'][0].hospitalizacionesFamiliares,
        this.antecedentes.hospitalizacionesNotas = data['data'][0].hospitalizacionesNotas,
        this.antecedentes.transfucionesPersonal = data['data'][0].transfucionesPersonal,
        this.antecedentes.transfucionesFecha = data['data'][0].transfucionesFecha,
        this.antecedentes.transfucionesFamiliares= data['data'][0].transfucionesFamiliares,
        this.antecedentes.transfucionesNotas= data['data'][0].transfucionesNotas,
        this.antecedentes.cardiopatiasPersonal= data['data'][0].cardiopatiasPersonal,
        this.antecedentes.cardiopatiasFecha= data['data'][0].cardiopatiasFecha,
        this.antecedentes.cardiopatiasFamiliares= data['data'][0].cardiopatiasFamiliares,
        this.antecedentes.cardiopatiasNotas= data['data'][0].cardiopatiasNotas,
        this.antecedentes.cirugiasPersonal= data['data'][0].cirugiasPersonal,
        this.antecedentes.cirugiasFecha= data['data'][0].cirugiasFecha,
        this.antecedentes.cirugiasFamiliares= data['data'][0].cirugiasFamiliares,
        this.antecedentes.cirugiasNotas= data['data'][0].cirugiasNotas,
        this.antecedentes.cancerLeucemiaPersonal= data['data'][0].cancerLeucemiaPersonal,
        this.antecedentes.cancerLeucemiaFecha= data['data'][0].cancerLeucemiaFecha,
        this.antecedentes.cancerLeucemiaFamiliares= data['data'][0].cancerLeucemiaFamiliares,
        this.antecedentes.cancerLeucemiaNotas= data['data'][0].cancerLeucemiaNotas,
        this.antecedentes.alergiasPersonal= data['data'][0].alergiasPersonal
        this.antecedentes.alergiasFecha= data['data'][0].alergiasFecha
        this.antecedentes.alergiasFamiliares= data['data'][0].alergiasFamiliares
        this.antecedentes.alergiasNotas= data['data'][0].alergiasNotas;
        this.antecedentes.vihPersonal = data['data'][0].vihPersonal;
        this.antecedentes.vihFecha = data['data'][0]['vihFecha'];
        this.antecedentes.vihFamiliares= data['data'][0].vihFamiliares;
        this.antecedentes.vihNotas = data['data'][0].vihNotas;
        this.antecedentes.tabaquismoFecha=data['data'][0].tabaquismoFecha
        this.antecedentes.tabaquismoFamiliares=data['data'][0].tabaquismoFamiliares
        this.antecedentes.tabaquismoNotas=data['data'][0].tabaquismoNotas
        this.antecedentes.tabaquismoPersonal=data['data'][0].tabaquismoPersonal
        this.antecedentes.diabetesPersonal= data['data'][0].diabetesPersonal,
        this.antecedentes.diabetesFecha= data['data'][0].diabetesFecha,
        this.antecedentes.diabetesFamiliares= data['data'][0].diabetesFamiliares,
        this.antecedentes.diabetesNotas= data['data'][0].diabetesNotas,
        this.antecedentes.tuberculosisPersonal=data['data'][0].tuberculosisPersonal
        this.antecedentes.tuberculosisFecha=data['data'][0].tuberculosisFecha
        this.antecedentes.tuberculosisFamiliares=data['data'][0].tuberculosisFamiliares
        this.antecedentes.tuberculosisNotas=data['data'][0].tuberculosisNotas
        this.antecedentes.alcoholismoPersonal=data['data'][0].alcoholismoPersonal
        this.antecedentes.alcoholismoFecha=data['data'][0].alcoholismoFecha
        this.antecedentes.alcoholismoFamiliares=data['data'][0].alcoholismoFamiliares
        this.antecedentes.alcoholismoNotas=data['data'][0].alcoholismoNotas
        this.antecedentes.deportesPersonal=data['data'][0].deportesPersonal
        this.antecedentes.deportesFecha=data['data'][0].deportesFecha
        this.antecedentes.deportesFamiliares=data['data'][0].deportesFamiliares
        this.antecedentes.deportesNotas=data['data'][0].deportesNotas
        this.antecedentes.otrasEnfPersonales=data['data'][0].otrasEnfPersonales
        this.antecedentes.otrasEnfFecha=data['data'][0].otrasEnfFecha
        this.antecedentes.otrasEnfFamiliares=data['data'][0].otrasEnfFamiliares
        this.antecedentes.otrasEnfNotas=data['data'][0].otrasEnfNotas
        this.antecedentes.enfermedadesDeLosSentidosPersonales = data['data'][0].enfermedadesDeLosSentidosPersonales
        this.antecedentes.enfermedadesDeLosSentidosFecha = data['data'][0].enfermedadesDeLosSentidosFecha
        this.antecedentes.enfermedadesSentidosFamiliares = data['data'][0].enfermedadesSentidosFamiliares
        this.antecedentes.enfermedadesSentidosNotas = data['data'][0].enfermedadesSentidosNotas
        this.antecedentes.expoLaboralPersonales= data['data'][0].expoLaboralPersonales
        this.antecedentes.expoLaboralFecha= data['data'][0].expoLaboralFecha
        this.antecedentes.expoLaboralFamiliares=data['data'][0].expoLaboralFamiliares
        this.antecedentes.expoLaboralNotas= data['data'][0].expoLaboralNotas
        this.antecedentes.postQuirurgicoPersonales= data['data'][0].postQuirurgicoPersonales
        this.antecedentes.postQuirurgicoFecha= data['data'][0].postQuirurgicoFecha
        this.antecedentes.postQuirurgicoFamiliares= data['data'][0].postQuirurgicoFamiliares
        this.antecedentes.postQuirurgicoNotas= data['data'][0].postQuirurgicoNotas

  });
  }

}


interface Antecedentes {
  enfermedadesPielPersonal:string;
  enfermedadesPielFecha:string;
  enfermedadesPielFamiliares:string;
  enfermedadesPielNotas:string;
  desnutricionPersonal:string;
  desnutricionFecha:string;
  desnutricionFamiliares:string;
  desnutricionNotas:string;
  obesidadPersonal:string;
  obesidadFecha:string;
  obesidadFamiliares:string;
  obesidadNotas:string;
  defectosPostularesPersonal:string;
  defectosPostularesFecha:string;
  defectosPostularesFamiliares:string;
  defectosPostularesNotas:string;
  fracturasPersonal:string;
  fracturasFecha:string;
  fracturasFamiliares:string;
  fracturasNotas:string;
  hospitalizacionesPersonal:string;
  hospitalizacionesFecha:string;
  hospitalizacionesFamiliares:string;
  hospitalizacionesNotas:string;
  transfucionesPersonal:string;
  transfucionesFecha:string;
  transfucionesFamiliares:string;
  transfucionesNotas:string;
  cardiopatiasPersonal:string;
  cardiopatiasFecha:string;
  cardiopatiasFamiliares:string;
  cardiopatiasNotas:string;
  cirugiasPersonal:string;
  cirugiasFecha:string;
  cirugiasFamiliares:string;
  cirugiasNotas:string;
  cancerLeucemiaPersonal:string;
  cancerLeucemiaFecha:string;
  cancerLeucemiaFamiliares:string;
  cancerLeucemiaNotas:string;
  alergiasPersonal:string;
  alergiasFecha:string;
  alergiasFamiliares:string;
  alergiasNotas:string;
  vihPersonal:string;
  vihFecha:string;
  vihFamiliares:string;
  vihNotas:string;
  tabaquismoFecha:string;
  tabaquismoFamiliares:string;
  tabaquismoNotas:string;
  tabaquismoPersonal:string;
  diabetesPersonal:string;
  diabetesFecha:string;
  diabetesFamiliares:string;
  diabetesNotas:string;
  tuberculosisPersonal:string;
  tuberculosisFecha:string;
  tuberculosisFamiliares:string;
  tuberculosisNotas:string;
  alcoholismoPersonal:string;
  alcoholismoFecha:string;
  alcoholismoFamiliares:string;
  alcoholismoNotas:string;
  deportesPersonal:string;
  deportesFecha:string;
  deportesFamiliares:string;
  deportesNotas:string;
  idPaciente:string;
  otrasEnfPersonales:string;
  otrasEnfFecha:string;
  otrasEnfFamiliares:string;
  otrasEnfNotas:string;
  enfermedadesDeLosSentidosPersonales:string;
  enfermedadesDeLosSentidosFecha:string;
  enfermedadesSentidosFamiliares:string;
  enfermedadesSentidosNotas:string;
  expoLaboralPersonales:string;
  expoLaboralFecha:string;
  expoLaboralFamiliares:string;
  expoLaboralNotas:string;
  postQuirurgicoPersonales:string;
  postQuirurgicoFecha:string;
  postQuirurgicoFamiliares:string;
  postQuirurgicoNotas:string;
}
