<div class="container" id="container">

    <!--Outer Row -->
    <div class="row justify-content-center">

        <div class="col">

            <div class="card o-hidden border-0  shadow-lg my-5" id="card2">
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col">
                            <img src="../../assets/images/CUADROS.svg" id="img-fluid">
                        </div>
                    </div>
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-sm-6 ">
                            <img src="../../../assets/images/dibujo-1.svg" id="logo">
                            <!-- <img src="../../../assets/images/logo3.png" id="logo" style="height: 380px; width: 680px;"> -->
                        </div>
                        <!-- En esta parte va la imagen el perro -->
                        <div class="col-sm-6">
                            <div class="p-5">
                                <div>
                                    <h1 class="h4 mb-3 text-left text-login" id="title">INICIAR SESIÓN</h1>
                                </div>
                                <form ngNativeValidate #f="ngForm" (ngSubmit)="ingresar( f )" class="user">
                                    <div class="form-group">
                                        <h5 class="h2" id="login">USUARIO</h5>
                                        <input ngModel name="nombre" type="text" maxlength="50" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp">
                                    </div>
                                    <div class="form-group">
                                        <h5 class="h2" id="login">CONTRASEÑA</h5>
                                        <input type="password" maxlength="50" autocomplete="off" name="password" ngModel class="form-control form-control-user" id="exampleInputPassword">
                                    </div>
                                    <input type="submit" id="btn-login" value="Ingresar" [disabled]="f.invalid" class="btn btn-primary btn-user btn-block">
                                </form>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col">
                            <img src="../../assets/images/CUADROS.svg" id="img-fluid-2">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div>