import { Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import  jsPDF from  "jspdf" ;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-hoja-reporte',
  templateUrl: './hoja-reporte.component.html',
  styleUrls: ['./hoja-reporte.component.css']
})
export class HojaReporteComponent implements OnInit {

  public nombre :string
  public id: string
  public servicioNameUrl = "";
  public estudio = "";
  public paciente  = {
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:'',
    _id:""
  };
  public quimico;
  public codPedido:""
  public metodo: any;
  public examen: any;
  public valoresRef: any;
  public usuario: any;
  public cedula: any;
  public resultado = {
    toma: '',
    liberacion: '',
    muestra: '',
    obtenidos: [],
    observaciones:'',
    realizo: '',
    libero:'',
    status:""
  }
  venta: any;
  servicio: any;
  public imp = [];
  public imprimir = {
    grupo: '',
    elementos: '',
    obtenidos: '',
    unidad: '',
    valoresReferencia: ''
  }
  private onDestroy$ = new Subject<boolean>();

  constructor(private _routers: Router, 
              private _servicios : ServiciosService,
              public _consultaService: ConsultaService,
              private activatedRoute: ActivatedRoute,  
              private _laboratorio:  LaboratorioService,
              private spinner:NgxSpinnerService) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.servicioNameUrl = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.getValoresReferencia()
    this.usuario= getDataStorage();
    this.quimico = this.usuario.nombre;
  }

  getValoresReferencia(){
    let id = {id : this.id, nameService: this.servicioNameUrl }
    this._laboratorio.hojaReporteEstudios( id )
    .subscribe((data:any) =>{
      if(data.ok){
        this.setData(data.data)
      }
    });
  }

  setData (data:any) {
    this.paciente = data['paciente']
    this.metodo = data['metodo']
    this.nombre = data ['nombre']
    this.examen = data ['tipo_de_examen']
    this.valoresRef = data ['valoresDeReferencia']
    this.venta = data ['venta']
    this.servicio = data ['servicio']
    this.estudio = data ['estudio']
    this._servicios.title$.emit(  this.estudio  )
  }

  tipoMuestra( event ){
    this.resultado.muestra = event
  }

  //recibimos la data del emit 
  getReportEstudies(event){
    this.resultado.obtenidos = event;
    delete this.resultado.liberacion
    delete this.resultado.muestra
    delete this.resultado.toma
    delete this.resultado.observaciones
    delete this.resultado.libero;
    this.requestToSaveGets();
  }

  sendObtenidos( form: any ){
    this.setEstudios(this.valoresRef,form.value);
    this.testLabsPDF()
  }

  enviar(form: any){
    this.spinner.show()
    this.resultado.obtenidos = form.value
    this.resultado.libero = 'Dr.ALBERTO ZAMORA PALMA '
    this.resultado.realizo = this.quimico
    this.requestToSaveGets();
  }

  requestToSaveGets() {
    this.resultado.status = 'FINALIZADO'
    this.spinner.show();
    this._servicios.sentStudiesByServices(this.id ,this.resultado)
    .subscribe((data:any) =>  {
      if(data.ok) { 
        this.spinner.hide();
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'LOS DATOS SE ENVIARON CORRECTAMENTE',
        });
        this._routers.navigate(['/hoja-servicios/',this.servicio,this.venta])
      } 
    })
  }

  ngOnDestroy(){
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }


  setEstudios(data, obtenidosFinalesHTML) {
    let obt :any [] = [];
    this.imprimir = {
      grupo: '',
      elementos: '',
      obtenidos: '',
      unidad: '',
      valoresReferencia: ''
    }
    for (const key in obtenidosFinalesHTML) {
      obt.push(obtenidosFinalesHTML[key])
    }
    let i= 0;
    for (let element of data ) {    
      this.imprimir.grupo = element.grupo;
      this.imprimir.elementos = element.elemento;
      this.imprimir.obtenidos=obt[i];
      this.imprimir.unidad = element.unidad;
      let val = ""
      element.valorDeReferencia.forEach(elem => {
        val = val.concat(elem.valorReferenciaD, `\n`)
      });
      this.imprimir.valoresReferencia = val
      this.imp.push(this.imprimir)
      this.imprimir = {
        grupo: '',
        elementos: '',
        obtenidos: '',
        unidad: '',
        valoresReferencia: ''
      }
      i++
      val = ""
    }
  }

  testLabsPDF(){
    const patient = this.paciente;
    const HORA_TOMA = this.resultado.toma;
    const HORA_LIBERACION =  this.resultado.liberacion;
    const HORA_IMPRESION = '';
    const TIPO_MUESTRA = this.resultado.muestra;
    const REALIZO = 'Dr.ALBERTO ZAMORA PALMA';
    const NOMBRE_ESTUDIO = this.nombre;
    const OBSERVACIONES = this.resultado.observaciones;
    const METODO = this.metodo;

    let imgLabs = '../../../../../assets/images/hero_c.png';
    let fooLabs = '../../../../../assets/images/lineas-bs.png';
    let piensaHero = '../../../../../assets/images/piensa-l.png';
    let firmaDoc = '../../../../../assets/images/firma_doc.png';
    let marca = '../../../../../assets/images/marca_d.png';
    
      const doc:any = new jsPDF({
      unit: "cm",
      format: 'a4',
      });
      let values = [];
      values = this.imp.map((elemento) => Object.values(elemento));
      doc.autoTable({
        margin: {top:6.7 ,bottom: 6},
        theme: 'plain',
        head: [[' ', 'Elementos', 'Resultados', 'Unidades', 'Intervalo Biologico de Referencia']],
        body: values,
        didDrawPage: function(paciente) {
          doc.addImage(marca, 'PNG', 1.5, 12, 17.5, 6);

          doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
          doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
          doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
          doc.addImage(piensaHero, 'PNG', 17, 24.5, 3, 1);
          doc.setTextColor(0, 0, 255);
          doc.setFontSize(8);
          doc.setFont('helvetica')
          // doc.setFontType('bold')
          doc.text('PACIENTE:', 1, 4)
          doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 2.6, 4)
          doc.text('EDAD: ', 1, 4.4);
          //SETEAT EDAD
          doc.text(patient.edad + ' AÑOS', 2.6, 4.4);
          doc.text('GÉNERO: ', 1, 4.8);
          doc.text(patient.genero, 2.6, 4.8);
          doc.text('FECHA DE NACIMIENTO: ', 1, 5.2);
          // Setear Fecha paciente
          const nacimiento = String(patient.fechaNacimiento)
          if (nacimiento == 'undefined' || nacimiento == '') {
            doc.text('-- / --- / ----', 4.5, 5.2);
          }else {
            let fechaNacimiento;
            fechaNacimiento = nacimiento.slice(0, -14)
            doc.text(fechaNacimiento, 4.5, 5.2);
          }
          doc.text('SOLICITANTE: ', 1, 5.6);
          doc.text('A Quién corresponda', 3.1, 5.6);
           //Fecha y horas de muestras
           doc.text('HORA DE TOMA:', 13, 4)
           doc.text(HORA_TOMA, 15.5, 4)
           doc.text('HORA DE LIBERACIÓN:',13, 4.4)
           doc.text(HORA_LIBERACION, 16.3, 4.4)
           /* doc.text('HORA DE IMPRESIÓN:', 13, 4.8)
           doc.text(HORA_IMPRESION, 16.3, 4.8) */
           doc.text('TIPO DE MUESTRA:', 13, 5.2)
           doc.text(TIPO_MUESTRA, 16, 5.2)
           doc.text('REALIZÓ: ', 13, 5.6)
           doc.text(REALIZO.toUpperCase(), 14.4, 5.6)
          // Nombre del estudio
            doc.setTextColor(255, 0, 0)
            doc.setFontSize(12);
            doc.text(NOMBRE_ESTUDIO, 7, 6.1)
            doc.addImage(fooLabs, 'PNG', 1, 6.2, 19.5, 0.5)
            doc.setTextColor(0, 0, 255);
            //Footer
            doc.setFontSize(10);
            doc.setFont('helvetica')
            doc.text('Observaciones:', 1, 24)
            doc.text(OBSERVACIONES, 1, 24.4)
            doc.text('Método:', 4.5, 24.8);
            doc.text(METODO, 6, 24.8)
            doc.setFontSize(8);
            doc.text('*Un diagnóstico clÍnico lo debe realizar un médico certificado y un resultado aislado en ocasiones', 1, 26.4)
            doc.text('es insuficiente para establecerlo.', 1, 26.7)
            doc.setFontSize(10);
            doc.text('Liberó: Dr. Alberto Zamora Palma', 14.5, 26.2);
            doc.text('Cédula: 3279016', 15.5, 26.7)
            // doc.addImage(marca, 'PNG', 4.5, 11.5, 12, 5, 'marca_agua', 'SLOW', 0);
            // values = 0;       
        },
      });
      /* 
      if (SESION != 'laboratorio') {
        doc.save(this.nombre + '_' + this.paciente.nombre + '_' + this.paciente.apellidoPaterno + '_' + this.paciente.apellidoMaterno + '_' + '' +'.pdf');
      }else { */
        
        window.open(doc.output('bloburl', '_blank'));
      /* } */
      
	    
  }

}
