<!-- <section class="container-fluid">
    <div class="container">
        <div class="card-columns">
            <a [routerLink]="servicios.url" *ngFor="let servicios of servicio | paginate:{
                                                                                id:'lstadoservicios',
                                                                                itemsPerPage:6,
                                                                                currentPage:pagina,
                                                                                totalItems: totalservicios}">
                <div class="" id="card">
                    <div class="card-body text-center">
                        <img class="img-fluid cursor" id="img2" [src]="servicios.img" alt="card image">
                        <h2 class="card-title" id="h2">{{servicios.nombre}}</h2>
                    </div>
                </div>
            </a>
        </div>
    </div>
</section> -->
<div id="content">
    <div class="card-columns animate bounceIn">
        <a [routerLink]="servicios.url" *ngFor="let servicios of servicio | paginate:{
                                                                                id:'lstadoservicios',
                                                                                itemsPerPage:6,
                                                                                currentPage:pagina,
                                                                                totalItems: totalservicios}">
            <div class="card" id="card">
                <img class="img-fluid cursor" id="img2" [src]="servicios.img" alt="card image">
                <h2 class="card-title" id="h2">{{servicios.nombre}}</h2>
            </div>
        </a>
    </div>
</div>
<div class="container-fluid aling-self-center paginador">
    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="lstadoservicios" maxSize="6" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
</div>
