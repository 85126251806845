<section class="container-fluid">
    <div class="card" name="card" id="card">
        <div class="row">
            <div class="col margin-info">
                <p><span>NOMBRE: {{dataFiProducto.nombre}}</span></p>
            </div>
            <div class="col">
                <p *ngIf="tipo != 'MATERIAL'"><span>NOMBRE COMERCIAL: {{this.dataFiProducto.nombre_comercial}}</span></p>
                <p *ngIf="tipo == 'MATERIAL'"><span>FABRICANTE: {{this.dataFiProducto.laboratorio}}</span></p>
            </div>
            <div class="col-md-3">
                <p><span>ID: {{ dataFiProducto.idProducto }}</span></p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>DESCRIPCION:</span>{{this.dataFiProducto.descripcion}}</p>
            </div>
            <div class="col" *ngIf="tipo != 'MATERIAL'">
                <p><span>TIPO DE SERVICIO:</span>{{ dataFiProducto.tipo_producto }}</p>
            </div>
            <div class="col-md-3">
                <p><span>ESTADO:</span>{{this.dataFiProducto.estado}}</p>
            </div>
        </div>
        <div class="row center">
            <div class="col">
                <span>PROVEEDORES</span>
            </div>
        </div>
        <div class="row" *ngFor="let proveedorUnique of dataFiProducto.proveedor">
            <div class="col" style="margin-left: 40px;" >
                <p><span>PROVEEDOR:</span>{{ this.proveedorUnique.proveedor }}</p>
            </div>
            <div class="col">
                <p><span>COSTO:</span>${{ this.proveedorUnique.costo }}</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <hr class="col-11" style="border-top: 2.5px solid rgba(0, 0, 0, 0.7)" />
          </div>
      
          <div class="d-flex justify-content-around pl-5 pr-5 ml-5 mr-5 mb-4">
            <div class="col">
              <div class="d-flex justify-content-center">
                <div>
                  <p class="d-flex justify-content-center align-items-center m-0">
                    <i class="fas fa-boxes fa-4x"></i>
                  </p>
                </div>
                <div class="justify-content-center d-flex flex-column pl-3 pr-3">
                  <p class="mgb d-flex justify-content-center m-0">
                    <span>STOCK</span>
                  </p>
                  <p class="d-flex justify-content-center mb-0">
                    <span>{{stock}} UNIDADES</span>
                  </p>
                </div>
                </div>
            </div>
      
            <div class="col">
              <div class="d-flex justify-content-center">
                <div>
                  <p class="d-flex justify-content-center align-items-center m-0">
                      <i class="fas fa-arrow-up fa-4x"></i>
                  </p>
                </div>
                <div class="justify-content-center d-flex flex-column pl-3 pr-3">
                  <p class="mgb d-flex justify-content-center m-0">
                    <span>VENTA</span>
                  </p>
                  <p class="d-flex justify-content-center mb-0">
                    <span>{{venta}} UNIDADES</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-3">
              <div class="d-flex justify-content-center">
                <div>
                  <p class="d-flex justify-content-center align-items-center m-0">
                    <i class="far fa-hospital fa-4x"></i>
                  </p>
                </div>
                <div class="justify-content-center d-flex flex-column pl-3 pr-3">
                  <p class="mgb d-flex justify-content-center m-0">
                    <span>OTROS HOSPITALES</span>
                  </p>
                  <p class="d-flex justify-content-center mb-0">
                    <span>{{otrosHospitales}} UNIDADES</span>
                  </p>
                </div>
              </div>
            </div> -->
            <div class="col">
              <div class="d-flex justify-content-center">
                <div>
                  <p class="d-flex justify-content-center align-items-center m-0">
                    <i class="far fa-arrow-alt-circle-down fa-4x"></i>
                  </p>
                </div>
                <div class="justify-content-center d-flex flex-column pl-2 pr-2">
                  <p class="mgb d-flex justify-content-center m-0">
                    <span>CANTIDAD MINIMA</span>
                  </p>
                  <p class="d-flex justify-content-center mb-0">
                    <span>{{this.dataFiProducto.cantidadMin}} UNIDADES</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
    </div>
</section>










<!-- <div class="row">

    <div class="col-md-10 offset-md-1">
        <div class="card">
            <div class="row">

                <div class="col-md-5">
                    <p class="text-fi">
                        NOMBRE: 
                        {{dataFiProducto.nombre}}
                    </p>
                </div>
                <div class="col-md-5">
                    <p class="text-fi">
                        ID: 
                        {{ dataFiProducto.idProducto }}
                    </p>
                </div>
                <div class="col-md-5">
                    <p class="text-fi">
                        TIPODE SERVICIO:
                        {{ dataFiProducto.tipo_producto }}
                    </p>
                </div>
                <div class="col-md-5 text-proveedor">
                    <p class="text-fi"  *ngFor="let proveedorUnique of dataFiProducto.proveedor" >
                        PRECIO:  {{ this.proveedorUnique.proveedor }} - {{ this.proveedorUnique.costo }}
                    </p>
                </div>
                <div class="col-md-5">
                    <p class="text-fi">
                        DESCRIPCION:
                        {{this.dataFiProducto.descripcion}}
                    </p>
                </div>
                <div class="col-md-5">
                    <p class="text-fi">
                        ESTADO: 
                        {{this.dataFiProducto.estado}}
                    </p>
                </div>
                <div class="col-md-5">
                    <p class="text-fi">
                        OBSERVACIOENS:
                    </p>
                </div>

            </div>
        </div>
    </div>
</div> -->