<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'BITÁCORA DE RESULTADOS DE ULTRASONIDO'"></app-titulos>
        </div>
    </div>
   
    <div class="row">
        <div class="col-md-8">
            <form>
                <div class="form-group">
                    <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral($event)">
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <!-- <div class="col text_header">
                            <p>FECHA</p>
                        </div> -->
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTUDIO</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of consultas | paginate:{id:'listapacientes',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer puntero" [routerLink]="['/bitacora/pedidos/paciente/', item.idPaciente._id, 'ultrasonido']">
                            <div class="col-md-1">
                                <p> {{ i +1 }} </p>
                            </div>
                            <!-- <div class="col">
                                <p> {{item.fechaPedido | date : 'dd/MM/yyyy': 'UTC' }} </p>
                            </div> -->
                            <div class="col">
                                <p> {{item.idPaciente.nombrePaciente |uppercase}} {{item.idPaciente.apellidoPaterno|uppercase}} {{item.idPaciente.apellidoMaterno|uppercase}} </p>
                            </div>
                            <div class="col">
                                <p> {{item.idPaciente.edad}} </p>
                            </div>
                            <div class="col">
                                <p> {{item.idPaciente.genero | generos:true }} </p>
                            </div>
                            <div class="col">
                                <p> {{item.sede}} </p>
                            </div>
                            <div class="col">
                                <p> {{item.idServicio.ESTUDIO}} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>


