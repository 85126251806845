<div role="tabpanel">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#antecedentes" (click)="verAntecedentes()">  Antecedentes </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#antecedentesNoPatologicos" (click)="verAntecedentesNoPatologicos()">  Antecedentes no patologicos </a>
        </li>
    </ul>
</div>
<div class="tab-content">
    <div role="tabpanel" class="tab-pane" id="antecedentes">
        <form action="">
            <div class="row">

                <div class="col-md-3">
                    <label for="enfPielFecha">  Enfermedades de la piel personales </label>
                    <input name="enfPielFecha" [(ngModel)]="antecedentes.enfermedadesPielPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>
                <div class="col-md-3">
                    <label for=""> Enfermedades de la piel familiares </label>
                    <input name="enfermedadesPielFamiliares" [(ngModel)]="antecedentes.enfermedadesPielFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="enfermedadesPielFecha">  Enfermedades de la piel fecha </label>
                    <input name="enfermedadesPielFecha" [(ngModel)]="antecedentes.enfermedadesPielFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="enfermedadesPielNotas">  Notas </label>
                    <input name="enfermedadesPielNotas" [(ngModel)]="antecedentes.enfermedadesPielNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="desnutricionPersonal">  Desnutrición personal </label>
                    <input name="desnutricionPersonal" [(ngModel)]="antecedentes.desnutricionPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="desnutricionFamiliares">  Desnutrición  familiares </label>
                    <input name="desnutricionFamiliares" [(ngModel)]="antecedentes.desnutricionFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="desnutricionFecha"> Desnutricion fecha </label>
                    <input name="desnutricionFecha" [(ngModel)]="antecedentes.desnutricionFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>
                <div class="col-md-3">
                    <label for="desnutricionNotas">
                        Desnutrición notas
                    </label>
                    <input name="desnutricionNotas" [(ngModel)]="antecedentes.desnutricionNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="obesidadPersonal"> Obesidad personal </label>
                    <input name="obesidadPersonal" [(ngModel)]="antecedentes.obesidadPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="obesidadFamiliares">  Obecidad familiares</label>
                    <input name="obesidadFamiliares" [(ngModel)]="antecedentes.obesidadFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="obesidadFecha">  Obecidad fecha </label>
                    <input name="enfPielFecha" [(ngModel)]="antecedentes.obesidadFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="obesidadNotas"> Obesidad notas </label>
                    <input name="obesidadNotas" [(ngModel)]="antecedentes.obesidadNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="defectosPostularesPersonal"> Defectos postulares personales </label>
                    <input name="defectosPostularesPersonal" [(ngModel)]="antecedentes.defectosPostularesPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="defectosPostularesFamiliares"> Defectos postulares  familiares </label>
                    <input name="defectosPostularesFamiliares" [(ngModel)]="antecedentes.defectosPostularesFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="defectosPostularesFecha"> Defectos postulares fecha  </label>
                    <input name="defectosPostularesFecha" [(ngModel)]="antecedentes.defectosPostularesFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="defectosPostularesNotas"> Defectos postulares notas </label>
                    <input name="defectosPostularesNotas" [(ngModel)]="antecedentes.defectosPostularesNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="fracturasPersonal"> Fracturas personales </label>
                    <input name="fracturasPersonal" [(ngModel)]="antecedentes.fracturasPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="fracturasFamiliares">  Fracturas familiares </label>
                    <input name="fracturasFamiliares" [(ngModel)]="antecedentes.fracturasFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="fracturasFecha">  Fracturas fecha </label>
                    <input name="fracturasFecha" [(ngModel)]="antecedentes.fracturasFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="fracturasNotas">  Fracturas notas </label>
                    <input name="fracturasNotas" [(ngModel)]="antecedentes.fracturasNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="hospitalizacionesPersonal">  Hospitalizaciones personales </label>
                    <input name="hospitalizacionesPersonal" [(ngModel)]="antecedentes.hospitalizacionesPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="hospitalizacionesFamiliares">  Hospitalizaciones fecha </label>
                    <input name="hospitalizacionesFamiliares" [(ngModel)]="antecedentes.hospitalizacionesFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="hospitalizacionesFecha">  Hospitalizaciones fecha </label>
                    <input name="hospitalizacionesFecha" [(ngModel)]="antecedentes.hospitalizacionesFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>



                <div class="col-md-3">
                    <label for="hospitalizacionesNotas">  Hospitalizaciones notas </label>
                    <input name="hospitalizacionesNotas" [(ngModel)]="antecedentes.hospitalizacionesNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="transfucionesPersonal">  Transfuciones personal </label>
                    <input name="transfucionesPersonal" [(ngModel)]="antecedentes.transfucionesPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="transfucionesFecha">  Transfuciones fecha </label>
                    <input name="transfucionesFecha" [(ngModel)]="antecedentes.transfucionesFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="transfucionesFamiliares">  Transfuciones familiares </label>
                    <input name="transfucionesFamiliares" [(ngModel)]="antecedentes.transfucionesFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="transfucionesNotas">  Transfuciones notas </label>
                    <input name="transfucionesNotas" [(ngModel)]="antecedentes.transfucionesNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cardiopatiasPersonal">  Cardiopatias personales </label>
                    <input name="cardiopatiasPersonal" [(ngModel)]="antecedentes.cardiopatiasPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cardiopatiasFamiliares">  Cardiopatias familiares </label>
                    <input name="cardiopatiasFamiliares" [(ngModel)]="antecedentes.cardiopatiasFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cardiopatiasFecha">  Cardiopatias fechas </label>
                    <input name="cardiopatiasFecha" [(ngModel)]="antecedentes.cardiopatiasFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cardiopatiasNotas">  Cardiopatias notas </label>
                    <input name="cardiopatiasNotas" [(ngModel)]="antecedentes.cardiopatiasNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cirugiasPersonal">  Cirugias personale </label>
                    <input name="cirugiasPersonal" [(ngModel)]="antecedentes.cirugiasPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cirugiasFamiliares">  Cirugias familiares </label>
                    <input name="cirugiasFamiliares" [(ngModel)]="antecedentes.cirugiasFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cirugiasFecha">  Cirugias fecha </label>
                    <input name="cirugiasFecha" [(ngModel)]="antecedentes.cirugiasFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="cirugiasNotas">  Cirugias notas </label>
                    <input name="cirugiasNotas" [(ngModel)]="antecedentes.cirugiasNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cancerLeucemiaPersonal">  Leucemia personales </label>
                    <input name="cancerLeucemiaPersonal" [(ngModel)]="antecedentes.cancerLeucemiaPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cancerLeucemiaFamiliares">  Leucemia familiares </label>
                    <input name="cancerLeucemiaFamiliares" [(ngModel)]="antecedentes.cancerLeucemiaFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="cancerLeucemiaFecha">  Leucemia fecha </label>
                    <input name="cancerLeucemiaFecha" [(ngModel)]="antecedentes.cancerLeucemiaFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="cancerLeucemiaNotas">  Leucemia notas </label>
                    <input name="cancerLeucemiaNotas" [(ngModel)]="antecedentes.cancerLeucemiaNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alergiasPersonal">  Alegías personales </label>
                    <input name="alergiasPersonal" [(ngModel)]="antecedentes.alergiasPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alergiasFamiliares">  Alegías familiares </label>
                    <input name="alergiasFamiliares" [(ngModel)]="antecedentes.alergiasFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alergiasFecha">  Alegías personales </label>
                    <input name="alergiasFecha" [(ngModel)]="antecedentes.alergiasFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alergiasNotas">  Alegías notas </label>
                    <input name="alergiasNotas" [(ngModel)]="antecedentes.alergiasNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="vihPersonal">  V.I.H personales </label>
                    <input name="vihPersonal" [(ngModel)]="antecedentes.vihPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="vihFamiliares">  V.I.H familiares </label>
                    <input name="vihFamiliares" [(ngModel)]="antecedentes.vihFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="vihFecha">  V.I.H  fecha </label>
                    <input name="vihFecha" [(ngModel)]="antecedentes.vihFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="vihNotas">  V.I.H  notas </label>
                    <input name="vihNotas" [(ngModel)]="antecedentes.vihNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tabaquismoPersonal">  Tabaquismo personales </label>
                    <input name="tabaquismoPersonal" [(ngModel)]="antecedentes.tabaquismoPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tabaquismoFecha">  Tabaquismo fecha </label>
                    <input name="tabaquismoFecha" [(ngModel)]="antecedentes.tabaquismoFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tabaquismoFamiliares">  Tabaquismo familiares </label>
                    <input name="tabaquismoFamiliares" [(ngModel)]="antecedentes.tabaquismoFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tabaquismoNotas">  Tabaquismo notas </label>
                    <input name="tabaquismoNotas" [(ngModel)]="antecedentes.tabaquismoNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="diabetesPersonal">  Diabetes personal </label>
                    <input name="diabetesPersonal" [(ngModel)]="antecedentes.diabetesPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="diabetesFamiliares">  Diabetes familiares </label>
                    <input name="diabetesFamiliares" [(ngModel)]="antecedentes.diabetesFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="diabetesFecha">  Diabetes fecha </label>
                    <input name="diabetesFecha" [(ngModel)]="antecedentes.diabetesFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="diabetesNotas">  Diabetes notas </label>
                    <input name="diabetesNotas" [(ngModel)]="antecedentes.diabetesNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tuberculosisPersonal">  Tuberculosis personales </label>
                    <input name="tuberculosisPersonal" [(ngModel)]="antecedentes.tuberculosisPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tuberculosisFamiliares">  Tuberculosis familiares  </label>
                    <input name="tuberculosisFamiliares" [(ngModel)]="antecedentes.tuberculosisFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="tuberculosisFecha">  Tuberculo fecha </label>
                    <input name="tuberculosisFecha" [(ngModel)]="antecedentes.tuberculosisFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="tuberculosisNotas">  Tuberculo notas </label>
                    <input name="tuberculosisNotas" [(ngModel)]="antecedentes.tuberculosisNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="alcoholismoPersonal">  Alcoholismo personal </label>
                    <input name="alcoholismoPersonal" [(ngModel)]="antecedentes.alcoholismoPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alcoholismoFamiliares">  Alcoholismo personal </label>
                    <input name="alcoholismoFamiliares" [(ngModel)]="antecedentes.alcoholismoFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alcoholismoFecha">  Alcoholismo personal </label>
                    <input name="alcoholismoFecha" [(ngModel)]="antecedentes.alcoholismoFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="alcoholismoNotas">  Alcoholismo personal </label>
                    <input name="alcoholismoNotas" [(ngModel)]="antecedentes.alcoholismoNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="deportesPersonal">  Deportes personal </label>
                    <input name="deportesPersonal" [(ngModel)]="antecedentes.deportesPersonal" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="deportesFamiliares">  Deportes familiares </label>
                    <input name="deportesFamiliares" [(ngModel)]="antecedentes.deportesFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="deportesFecha">  Deportes fecha </label>
                    <input name="deportesFecha" [(ngModel)]="antecedentes.deportesFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="deportesNotas">  Deportes notas </label>
                    <input name="deportesNotas" [(ngModel)]="antecedentes.deportesNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="otrasEnfPersonales">  Deportes notas </label>
                    <input name="otrasEnfPersonales" [(ngModel)]="antecedentes.otrasEnfPersonales" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="otrasEnfFecha">  Deportes notas </label>
                    <input name="otrasEnfFecha" [(ngModel)]="antecedentes.otrasEnfFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="otrasEnfFamiliares">  Deportes notas </label>
                    <input name="otrasEnfFamiliares" [(ngModel)]="antecedentes.otrasEnfFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="otrasEnfNotas">  Deportes notas </label>
                    <input name="otrasEnfNotas" [(ngModel)]="antecedentes.otrasEnfNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="enfermedadesDeLosSentidosPersonales">  Enfermedades de los sentidos </label>
                    <input name="enfermedadesDeLosSentidosPersonales" [(ngModel)]="antecedentes.enfermedadesDeLosSentidosPersonales" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="enfermedadesSentidosFamiliares">  Enfermedades de los sentidos Familiares </label>
                    <input name="enfermedadesSentidosFamiliares" [(ngModel)]="antecedentes.enfermedadesSentidosFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="enfermedadesDeLosSentidosFecha">  Enfermedades de los sentidos fecha </label>
                    <input name="enfermedadesDeLosSentidosFecha" [(ngModel)]="antecedentes.enfermedadesDeLosSentidosFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="enfermedadesSentidosNotas">  Enfermedades de los senidos notas </label>
                    <input name="enfermedadesSentidosNotas" [(ngModel)]="antecedentes.enfermedadesSentidosNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="expoLaboralPersonales">  Laborales  personales </label>
                    <input name="expoLaboralPersonales" [(ngModel)]="antecedentes.expoLaboralPersonales" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="expoLaboralFamiliares">  Laborales  familiares </label>
                    <input name="expoLaboralFamiliares" [(ngModel)]="antecedentes.expoLaboralFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="expoLaboralFecha">  Laborales  fecha  </label>
                    <input name="expoLaboralFecha" [(ngModel)]="antecedentes.expoLaboralFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="expoLaboralNotas">  Laborales notas </label>
                    <input name="expoLaboralNotas" [(ngModel)]="antecedentes.expoLaboralNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>

                <div class="col-md-3">
                    <label for="postQuirurgicoPersonales">  Antecedentes postquirurgicos personales </label>
                    <input name="postQuirurgicoPersonales" [(ngModel)]="antecedentes.postQuirurgicoPersonales" type="text" placeholder="" class="antecedentes-inputs">
                </div>


                <div class="col-md-3">
                    <label for="postQuirurgicoFamiliares">  Antecedentes postquirurgicos familiares </label>
                    <input name="postQuirurgicoFamiliares" [(ngModel)]="antecedentes.postQuirurgicoFamiliares" type="text" placeholder="" class="antecedentes-inputs">
                </div>
                <div class="col-md-3">
                    <label for="postQuirurgicoFecha">  Antecedentes postquirurgicos  fecha </label>
                    <input name="postQuirurgicoFecha" [(ngModel)]="antecedentes.postQuirurgicoFecha" type="text" placeholder="" class="antecedentes-inputs">
                </div>
                <div class="col-md-3">
                    <label for="postQuirurgicoNotas">  Antecedentes postquirurgicos notas </label>
                    <input name="postQuirurgicoNotas" [(ngModel)]="antecedentes.postQuirurgicoNotas" type="text" placeholder="" class="antecedentes-inputs">
                </div>
            </div>
        </form>
    </div>

    <!-- ANTECEDENTES PERSONALES NO PATOLOGICOS   -->
    <div role="tabpanel" class="tab-pane" id="antecedentesNoPatologicos">
        <h2> No patologicos </h2>
        <form>
            <div class="row">
                <div class="col-md-3">
                    <label for="alcoholismoPorDia"> Alcoholismo por día  </label>
                    <input type="text" name="alcoholismoPorDia" id="alcoholismoPorDia" [(ngModel)]="antecdentesNoPatologicos.alcoholismoPorDia">
                </div>
                <div class="col-md-3">
                    <label for="alergias"> Alergías  </label>
                    <input type="text" name="alergias" id="alergias" [(ngModel)]="antecdentesNoPatologicos.alergias">
                </div>
                <div class="col-md-3">
                    <label for="aniosConsumoDrogas"> Años de consumo de drogas  </label>
                    <input type="text" name="aniosConsumoDrogas" id="aniosConsumoDrogas" [(ngModel)]="antecdentesNoPatologicos.aniosConsumoDrogas">
                </div>
                <div class="col-md-3">
                    <label for="alimentacionAdecuada"> Alimentación adecuada  </label>
                    <input type="text" name="alimentacionAdecuada" id="alimentacionAdecuada" [(ngModel)]="antecdentesNoPatologicos.alimentacionAdecuada">
                </div>

                <div class="col-md-3">
                    <label for="aniosDeconsumoAlcohol"> Alimentación adecuada  </label>
                    <input type="text" name="aniosDeconsumoAlcohol" id="aniosDeconsumoAlcohol" [(ngModel)]="antecdentesNoPatologicos.aniosDeconsumoAlcohol">
                </div>

                <div class="col-md-3">
                    <label for="desconoceTipoSanguineo"> Alimentación adecuada  </label>
                    <input type="text" name="desconoceTipoSanguineo" id="desconoceTipoSanguineo" [(ngModel)]="antecdentesNoPatologicos.desconoceTipoSanguineo">
                </div>

                <div class="col-md-3">
                    <label for="drogadiccionTipo"> Alimentación adecuada  </label>
                    <input type="text" name="drogadiccionTipo" id="drogadiccionTipo" [(ngModel)]="antecdentesNoPatologicos.drogadiccionTipo">
                </div>

                <div class="col-md-3">
                    <label for="exAlcoholicoUOcasional"> Alimentación adecuada  </label>
                    <input type="text" name="exAlcoholicoUOcasional" id="exAlcoholicoUOcasional" [(ngModel)]="antecdentesNoPatologicos.exAlcoholicoUOcasional">
                </div>

                <div class="col-md-3">
                    <label for="exDrogadicto"> Alimentación adecuada  </label>
                    <input type="text" name="exDrogadicto" id="exDrogadicto" [(ngModel)]="antecdentesNoPatologicos.exDrogadicto">
                </div>

                <div class="col-md-3">
                    <label for="tabaquismoPorDia"> Alimentación adecuada  </label>
                    <input type="text" name="tabaquismoPorDia" id="tabaquismoPorDia" [(ngModel)]="antecdentesNoPatologicos.tabaquismoPorDia">
                </div>

                <div class="col-md-3">
                    <label for="tipoAlergias"> Alimentación adecuada  </label>
                    <input type="text" name="tipoAlergias" id="tipoAlergias" [(ngModel)]="antecdentesNoPatologicos.tipoAlergias">
                </div>

                <div class="col-md-3">
                    <label for="tipoSanguineo"> Alimentación adecuada  </label>
                    <input type="text" name="tipoSanguineo" id="tipoSanguineo" [(ngModel)]="antecdentesNoPatologicos.tipoSanguineo">
                </div>

                <div class="col-md-3">
                    <label for="viviendaConServiciosBasicos"> Alimentación adecuada  </label>
                    <input type="text" name="viviendaConServiciosBasicos" id="viviendaConServiciosBasicos" [(ngModel)]="antecdentesNoPatologicos.viviendaConServiciosBasicos">
                </div>

            </div>
        </form>
    </div>
</div>