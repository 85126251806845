import { Component, OnInit } from '@angular/core';
import { getCarritoStorage  } from '../../../functions/pacienteIntegrados'
@Component({
  selector: 'app-estudios-por-aprobar',
  templateUrl: './estudios-por-aprobar.component.html',
  styleUrls: ['./estudios-por-aprobar.component.css']
})
export class EstudiosPorAprobarComponent implements OnInit {

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  constructor() { }

  ngOnInit(): void {
    
  }

}
