
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { RecetasService } from 'src/app/services/recetas/recetas.service';
import { eliminarStorage, getCarritoStorage } from '../../../functions/pacienteIntegrados';
import { gaurdarCotizacion } from '../../../functions/storage.funcion';
import CarritoMembresia from 'src/app/classes/carritoMembresia/carritoMembresia.class';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import Carrito from 'src/app/classes/carrito/carrito.class';
import jsPDF from 'jspdf';
import Tickets from 'src/app/classes/tickets/ticket.class';

@Component({
  selector: 'app-ver-estudios',
  templateUrl: './ver-estudios.component.html',
  styleUrls: ['./ver-estudios.component.css']
})
export class VerEstudiosComponent implements OnInit {


  public id: string;

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public AgendaServ: any = []

  public paciente = {

    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    telefono: 0,
    consultas: 0,
    _id: "",
    fechaRegistro: Date,
    genero: "",
    estadoPaciente: "",
    callePaciente: "",
    paisPaciente: "",
    cpPaciente: "",
    contactoEmergencia1Nombre: "",
    contactoEmergencia1Edad: "",
    correo: "",
    cp: "",
    edad: "",
    paquetes: [],
    membresiaActiva: false
  }

  public recetMedica = {
    universidad: '',
    medicamentos: [],
    estudios: [],
    otrasIndicaciones: "",
    idPaciente: "",
    idConsulta: "",
    fechaReceta: '',
    horareceta: '',
    medicoQueAtendio: '',
    idMedico: '',
    cedula: '',
    especialidad: '',
    cedulaEsp: '',
    firma: '',
    origen: '',
    horaEgreso: '',
    prioridad: 'Programado',
    folio: ''
  }

  public peso = ''
  public talla = ''
  public temp = ''

  public recetaPDF = {
    height: 3.7,
    width: 1.5,
    whithEspacio: 3.4
  }

  public estudios: any[] = [];

  public totalSinMembresia = 0;
  public totalConMembresia = 0;
  public totalCarritoMembresia: Number;

  constructor(
    private _recetaService: RecetasService,
    private _route: ActivatedRoute,
    private _routers: Router
  ) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');

    this.obtenerReceta();
  }



  obtenerCarrito() {


    let carritoSinMembresia = new Carrito();
    this.carrito = carritoSinMembresia.obtenerSotorageCarrito();

    // console.log( this.carrito );
    this.totalSinMembresia = this.carrito.totalSin;
    this.totalCarritoMembresia = this.carrito.totalCon;

  }

  imprimirCotizacion(carrito) {
    this.generarTicket(carrito);
  }

  generarTicket(carrito) {
    const tickets = new Tickets();
    tickets.imprimirCotizacion(carrito);
    eliminarStorage();
    this.obtenerCarrito();
  }


  obtenerReceta() {
    this._recetaService.verRecetaConConsulta(this.id)
      .subscribe(data => {
        this.setDataReceta(data['data'])
        this.paciente.nombrePaciente = data['data']['idPaciente'].nombrePaciente;
        this.paciente.apellidoPaterno = data['data']['idPaciente'].apellidoPaterno;
        this.paciente.apellidoMaterno = data['data']['idPaciente'].apellidoMaterno;
        this.paciente.cp = data['data']['idPaciente'].cpPaciente;
        this.paciente.estadoPaciente = data['data']['idPaciente'].estadoPaciente;
        if (this.paciente.estadoPaciente == undefined) {
          this.paciente.estadoPaciente = ''
        }
        this.paciente.paisPaciente = data['data']['idPaciente'].paisPaciente;
        this.paciente.callePaciente = data['data']['idPaciente'].callePaciente;
        if (this.paciente.callePaciente == undefined) {
          this.paciente.callePaciente = ''
        }
        this.paciente.cpPaciente = data['data']['idPaciente'].cpPaciente;
        this.paciente.edad = data['data']['idPaciente'].edad;
        this.paciente.correo = data['data']['idPaciente'].correoPaciente;
        this.paciente.curp = data['data']['idPaciente'].curp;
        this.paciente._id = data['data']['idPaciente']._id;
        this.paciente.paquetes = data['data']['idPaciente'].paquetes;
        this.paciente.contactoEmergencia1Nombre = data['data']['idPaciente'].contactoEmergencia1Nombre;
        this.paciente.contactoEmergencia1Edad = data['data']['idPaciente'].contactoEmergencia1Edad;
        this.paciente.genero = data['data']['idPaciente'].genero;
        this.paciente.fechaRegistro = data['data']['idPaciente'].fechaRegistro;
        this.estudios = data['data']['estudios'];
        // console.log( this.paciente );
        /* this.setMembresia( data['data']['idPaciente']['membresiaActiva'] );
        this.setLocalStoragePaciente( data['data']['idPaciente'] ); */
        this.guardarLocalStorage();

      });
  }

  setDataReceta(receta: any) {
    this.recetMedica.cedula = receta.idMedico.cedulaProfesional
    this.recetMedica.cedulaEsp = receta.idMedico.cedulaEsp
    this.recetMedica.especialidad = receta.idMedico.Especialidad
    this.recetMedica.estudios = receta.estudios
    this.recetMedica.fechaReceta = receta.fechaReceta
    this.recetMedica.medicamentos = receta.medicamentos
    this.recetMedica.medicoQueAtendio = receta.idMedico.medico
    this.recetMedica.otrasIndicaciones = receta.otrasIndicaciones
    this.recetMedica.universidad = receta.idMedico.universidad
    this.recetMedica.folio = receta.folio
    if (this.recetMedica.cedula == undefined) {
      this.recetMedica.cedula = ""
    }
    if (this.recetMedica.cedulaEsp == undefined) {
      this.recetMedica.cedulaEsp = ""
    }
    if (this.recetMedica.folio == undefined) {
      this.recetMedica.folio = ""
    }
  }

  setMembresia(status) {
    this.paciente.membresiaActiva = status;
    this.obtenerCarrito();
  }

  guardarLocalStorage() {

    if (localStorage.getItem('paciente')) {

      localStorage.removeItem('paciente');
    }
    if (this.paciente.paquetes.length >= 1 || this.paciente.membresiaActiva == true) {
      this.paciente.membresiaActiva = true;
    }

    localStorage.setItem('paciente', JSON.stringify(this.paciente));
    /* console.log(this.paciente); */

  }

  setLocalStoragePaciente(paciente) {
    /* this.paciente = paciente
    this.guardarLocalStorage(this.paciente); */
    /* localStorage.setItem('idPaciente', JSON.stringify(paciente)) */
    /* const pacienteStorage = new PacienteStorage();
    const result  =  pacienteStorage.setPacienteStorage( idPaciente ); */
    // if( result  ){
    //   console.log("paciente agregado");
    // }
  }
  SelectAgenda(item) {
    var element = <HTMLInputElement>document.getElementById(item._id);
    if (element.checked === true) {
      this.AgendaServ.push(item)
    } else if (element.checked === false) {
      if (item._id!) {
        if (this.AgendaServ.length != 0) {
          this.AgendaServ.forEach((item2, index) => {
            if (item2._id === item._id) {
              this.AgendaServ.splice(index, 1)
            } else {
              return
            }

          });

        } else if (this.AgendaServ.length == 0) {
          return

        }


      }

    }


  }
  pagaragenda() {
    this.Agendar()
    this._routers.navigate(['/pago/servicios'])

  }
  Agendar() {

    if (this.AgendaServ.length != 0) {
      let evento: any = []
      evento = {
        paciente: JSON.parse(localStorage.getItem("paciente")),
        agen: JSON.parse(localStorage.getItem("usuario")),
        servicios: this.AgendaServ
      }
      localStorage.setItem("ServicioAgenda", JSON.stringify(evento))
      Swal
        .fire({
          title: "¿Desea Agendar las citas?",
          text: "Se redirigirá",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: "Actualizar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            this._routers.navigate(['/agenda'])

          } else {
            console.log("no")
          }
        });
    }
  }

  alertcomparasion(ev, precioPublicoOtros, precioMembresia, item2: any) {
    let precioSinTrim = precioPublicoOtros.replace('$', ''),
      precioSinComaPublico = precioSinTrim.replace(',', ''),
      precioMemTrim = precioMembresia.replace('$', ''),
      precioMemComaMembresia = precioMemTrim.replace(',', '')

    Swal.fire({
      icon: 'success',
      title: 'CON MEMBRESIA THE AHORRAS',
      text: `$ ${precioSinComaPublico - precioMemComaMembresia}.00`,
    })

    this.agregarCarrito(ev, item2);
  }


  //carrito

  agregarCarrito(event, item: any) {


    let carrito = new Carrito();
    this.carrito = carrito.agregarItem(event, item);
    this.obtenerCarrito()
  }

  eliminar(id) {

    let carrito = new Carrito();
    carrito.eliminar(id);

    this.obtenerCarrito();

    // this.obtenerCarritoStorage();

  }

  imprimirFrente() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    /*HOJA POR EL FRENTE*/

    let imgSocios = '../../assets/images/logoHeader.png';
    var marca = '../../assets/images/favicon 2.png';
    var piePagina = '../../assets/images/piePaginaTlaya.png'
    let linea2 = '../../assets/images/cuadritos.png';
    let universidad = this.recetMedica.universidad
    let hora_impresion = hoy.toLocaleDateString()
    const doc: any = new jsPDF({
      unit: "cm",
      format: 'a4',
    });
    doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
    doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
    doc.addImage(marca, 'PNG', 1, 6, 19, 25);
    doc.setFontSize(12);
    doc.setFont('helvetica')
    doc.text('RECETA MÉDICA', 8, 3)
    doc.text('FOLIO: ' + this.recetMedica.folio, 16, 3)
    doc.text('FECHA DE CONSULTA:', 12.5, 4)
    doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 4)
    doc.setFontSize(10);
    doc.text('NOMBRE:', 1, 5)
    doc.text(`${this.paciente.nombrePaciente.toLocaleUpperCase()} ${this.paciente.apellidoPaterno.toLocaleUpperCase()} ${this.paciente.apellidoMaterno.toLocaleUpperCase()}`, 3.2, 5)
    doc.text('GÉNERO: ', 1, 5.7);
    doc.text(`${this.paciente.genero.toLocaleUpperCase()}`, 3.2, 5.7);
    doc.text('EDAD: ', 7, 5.7);
    doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 5.7);
    doc.text('DIRECCIÓN:', 1, 6.5)
    doc.text(`${this.paciente.callePaciente.toLocaleUpperCase()} ${this.paciente.estadoPaciente.toLocaleUpperCase()}`, 3.7, 6.5)
    doc.text('MEDICAMENTOS:', 1, 8)
    let a = 8.8;
    let b = 9.7;

    this.recetMedica.medicamentos.forEach((medicamento: any, index) => {
      this.recetaPDF.height += 1;
      doc.text(1, a, (this.recetaPDF.width, this.recetaPDF.height, (index + 1) + '.-' + `${medicamento.medicamento.toLocaleUpperCase()}`),{ maxWidth: 18 });
      a = a + 1.8;
      this.recetaPDF.height += 1;
      doc.text(2, b,'-'+ (this.recetaPDF.width, this.recetaPDF.height, `${medicamento.indicaciones.toUpperCase()}`),{ maxWidth: 18 });
      b = b + 1.8;
      if (b > 24) {
        doc.text("MEDICO: " + this.recetMedica.medicoQueAtendio, 7, 24.5);
        doc.text("ESPECIALIDAD: " + this.recetMedica.especialidad.toLocaleUpperCase(), 7, 25);
        doc.text("CÉDULA: " + this.recetMedica.cedula + "/" + this.recetMedica.cedulaEsp, 7, 25.5);
        /* doc.text("UNIVERSIDAD: ", 8,26); */
        doc.addImage(universidad, 'PNG', 16.5, 23, 3, 3);
        b = 9
        doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
        doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
        doc.text("Tel. (735) 357 7564", 1, 28.7);
        doc.text("atencion.horizonte@gmail.com", 1, 29);
        doc.setFontSize(12);
        doc.addPage();
        doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
        doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
        doc.addImage(marca, 'PNG', 1, 6, 19, 25);
        doc.setFontSize(12);
        doc.setFont('helvetica')
        doc.text('RECETA MÉDICA', 8, 3)
        doc.text('FOLIO: ' + this.recetMedica.folio, 16, 3)
        doc.text('FECHA DE CONSULTA:', 12.5, 4)
        doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 4)
        doc.setFontSize(10);
        doc.text('NOMBRE:', 1, 5)
        doc.text(`${this.paciente.nombrePaciente.toLocaleUpperCase()} ${this.paciente.apellidoPaterno.toLocaleUpperCase()} ${this.paciente.apellidoMaterno.toLocaleUpperCase()}`, 3.2, 5)
        doc.text('GÉNERO: ', 1, 5.7);
        doc.text(`${this.paciente.genero.toLocaleUpperCase()}`, 3.2, 5.7);
        doc.text('EDAD: ', 7, 5.7);
        doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 5.7);
        doc.text('DIRECCIÓN:', 1, 6.5)
        doc.text(`${this.paciente.callePaciente.toLocaleUpperCase()} ${this.paciente.estadoPaciente.toLocaleUpperCase()}`, 3.7, 6.5)
      }
    });

    if (b > 21) {
      b = 9
      doc.text("MEDICO: " + this.recetMedica.medicoQueAtendio, 7, 24.5);
      doc.text("ESPECIALIDAD: " + this.recetMedica.especialidad.toLocaleUpperCase(), 7, 25);
      doc.text("CÉDULA: " + this.recetMedica.cedula + "/" + this.recetMedica.cedulaEsp, 7, 25.5);
      /* doc.text("UNIVERSIDAD: ", 8,26); */
      doc.addImage(universidad, 'PNG', 16.5, 23, 3, 3);
      doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
      doc.setFontSize(8);
      doc.setTextColor(0, 0, 0);
      doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
      doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
      doc.text("Tel. (735) 357 7564", 1, 28.7);
      doc.text("atencion.horizonte@gmail.com", 1, 29);
      doc.setFontSize(12);
      doc.addPage();
      doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
      doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
      doc.addImage(marca, 'PNG', 1, 6, 19, 25);
      doc.setFontSize(12);
      doc.setFont('helvetica')
      doc.text('RECETA MÉDICA', 8, 3)
      doc.text('FOLIO: ' + this.recetMedica.folio, 16, 3)
      doc.text('FECHA DE CONSULTA:', 12.5, 4)
      doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 4)
      doc.setFontSize(10);
      doc.text('NOMBRE:', 1, 5)
      doc.text(`${this.paciente.nombrePaciente.toLocaleUpperCase()} ${this.paciente.apellidoPaterno.toLocaleUpperCase()} ${this.paciente.apellidoMaterno.toLocaleUpperCase()}`, 3.2, 5)
      doc.text('GÉNERO: ', 1, 5.7);
      doc.text(`${this.paciente.genero.toLocaleUpperCase()}`, 3.2, 5.7);
      doc.text('EDAD: ', 7, 5.7);
      doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 5.7);
      doc.text('DIRECCIÓN:', 1, 6.5)
      doc.text(`${this.paciente.callePaciente.toLocaleUpperCase()} ${this.paciente.estadoPaciente.toLocaleUpperCase()}`, 3.7, 6.5)
    }

    doc.text('OTRAS INDICACIONES:', 1, b)
    b = b + .5

    // doc.text(`${this.recetMedica.otrasIndicaciones.toLocaleUpperCase()}`, 1, b, { maxWidth: 19, align: 'justify' })
    let lines = doc.setFont('helvetica')
    .setFontSize(10)
    .splitTextToSize(this.recetMedica.otrasIndicaciones.toLocaleUpperCase(), 19)
    
    lines.forEach(element => {
      doc.text(element, 1, b,{ align: 'justify' })
      b = b +.5
      if (b > 24) {
        doc.text("MEDICO: " + this.recetMedica.medicoQueAtendio, 7, 24.5);

        doc.text("ESPECIALIDAD: " + this.recetMedica.especialidad.toLocaleUpperCase(), 7, 25);

        doc.text("CÉDULA: " + this.recetMedica.cedula + "/" + this.recetMedica.cedulaEsp, 7, 25.5);
        /* doc.text("UNIVERSIDAD: ", 8,26); */
        doc.addImage(universidad, 'PNG', 16.5, 23, 3, 3);
        b = 8
        doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
        doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
        doc.text("Tel. (735) 357 7564", 1, 28.7);
        doc.text("atencion.horizonte@gmail.com", 1, 29);
        doc.setFontSize(12);
        doc.addPage();
        doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
        doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
        doc.addImage(marca, 'PNG', 1, 6, 19, 25);
        doc.setFontSize(12);
        doc.setFont('helvetica')
        doc.text('RECETA MÉDICA', 8, 3)
        doc.text('FOLIO: ' + this.recetMedica.folio, 16, 3)
        doc.text('FECHA DE CONSULTA:', 12.5, 4)
        doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 4)
        doc.setFontSize(10);
        doc.text('NOMBRE:', 1, 5)
        doc.text(`${this.paciente.nombrePaciente.toLocaleUpperCase()} ${this.paciente.apellidoPaterno.toLocaleUpperCase()} ${this.paciente.apellidoMaterno.toLocaleUpperCase()}`, 3.2, 5)
        doc.text('GÉNERO: ', 1, 5.7);
        doc.text(`${this.paciente.genero.toLocaleUpperCase()}`, 3.2, 5.7);
        doc.text('EDAD: ', 7, 5.7);
        doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 5.7);
        doc.text('DIRECCIÓN:', 1, 6.5)
        doc.text(`${this.paciente.callePaciente.toLocaleUpperCase()} ${this.paciente.estadoPaciente.toLocaleUpperCase()}`, 3.7, 6.5)
      }
    });
    
    doc.text("MEDICO: " + this.recetMedica.medicoQueAtendio, 7, 24.5);

    doc.text("ESPECIALIDAD: " + this.recetMedica.especialidad.toLocaleUpperCase(), 7, 25);

    doc.text("CÉDULA: " + this.recetMedica.cedula + "/" + this.recetMedica.cedulaEsp, 7, 25.5);
    /* doc.text("UNIVERSIDAD: ", 8,26); */
    doc.addImage(universidad, 'PNG', 16.5, 23, 3, 3);
    
    
    b = b + 1
    if (this.recetMedica.estudios.length != 0) {
      doc.text('ESTUDIOS:', 1, b)
      if (b > 21) {
        b = 8
        doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
        doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
        doc.text("Tel. (735) 357 7564", 1, 28.7);
        doc.text("atencion.horizonte@gmail.com", 1, 29);
        doc.setFontSize(12);
        doc.addPage();
        doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
        doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
        doc.addImage(marca, 'PNG', 1, 6, 19, 25);
        doc.setFontSize(12);
        doc.setFont('helvetica')
        doc.text('RECETA MÉDICA', 8, 3)
        doc.text('FOLIO: ' + this.recetMedica.folio, 16, 3)
        doc.text('FECHA DE CONSULTA:', 12.5, 4)
        doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 4)
        doc.setFontSize(10);
        doc.text('NOMBRE:', 1, 5)
        doc.text(`${this.paciente.nombrePaciente.toLocaleUpperCase()} ${this.paciente.apellidoPaterno.toLocaleUpperCase()} ${this.paciente.apellidoMaterno.toLocaleUpperCase()}`, 3.2, 5)
        doc.text('GÉNERO: ', 1, 5.7);
        doc.text(`${this.paciente.genero.toLocaleUpperCase()}`, 3.2, 5.7);
        doc.text('EDAD: ', 7, 5.7);
        doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 5.7);
        doc.text('DIRECCIÓN:', 1, 6.5)
        doc.text(`${this.paciente.callePaciente.toLocaleUpperCase()} ${this.paciente.estadoPaciente.toLocaleUpperCase()}`, 3.7, 6.5)
      }
    }
    // let c = 20.5
    this.recetMedica.estudios.forEach((estudio, i) => {
      doc.text(`${estudio.ESTUDIO}`, 1, (b + 0.5));
      b = b + .5;
      if (b > 23) {
        b = 8
        doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
        doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
        doc.text("Tel. (735) 357 7564", 1, 28.7);
        doc.text("atencion.horizonte@gmail.com", 1, 29);
        doc.setFontSize(12);
        doc.addPage();
        doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
        doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
        doc.addImage(marca, 'PNG', 1, 6, 19, 25);
        doc.setFontSize(12);
        doc.setFont('helvetica')
        doc.text('RECETA MÉDICA', 8, 3)
        doc.text('FOLIO: ' + this.recetMedica.folio, 16, 3)
        doc.text('FECHA DE CONSULTA:', 12.5, 4)
        doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 4)
        doc.setFontSize(10);
        doc.text('NOMBRE:', 1, 5)
        doc.text(`${this.paciente.nombrePaciente.toLocaleUpperCase()} ${this.paciente.apellidoPaterno.toLocaleUpperCase()} ${this.paciente.apellidoMaterno.toLocaleUpperCase()}`, 3.2, 5)
        doc.text('GÉNERO: ', 1, 5.7);
        doc.text(`${this.paciente.genero.toLocaleUpperCase()}`, 3.2, 5.7);
        doc.text('EDAD: ', 7, 5.7);
        doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 5.7);
        doc.text('DIRECCIÓN:', 1, 6.5)
        doc.text(`${this.paciente.callePaciente.toLocaleUpperCase()} ${this.paciente.estadoPaciente.toLocaleUpperCase()}`, 3.7, 6.5)
      }
    })
    doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
    doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
    doc.text("Tel. (735) 357 7564", 1, 28.7);
    doc.text("atencion.horizonte@gmail.com", 1, 29);
    //doc.output('dataurlnewwindow');
    /* doc.save(this.paciente.nombrePaciente + " " + this.paciente.apellidoPaterno); */
    window.open(doc.output('bloburl', '_blank'));

  }

  saltoDePagina(b,piePagina,imgSocios,linea2,marca){
    const doc: any = new jsPDF({
      unit: "cm",
      format: 'a4',
    });
    if (b > 21) {
      b = 8
      doc.addImage(piePagina, 'PNG', 1, 26.5, 19, 1.4);
      doc.setFontSize(8);
      doc.setTextColor(0, 0, 0);
      doc.text("Horario: Lunes a Domingo 24 horas", 1, 28.1);
      doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1, 28.4);
      doc.text("Tel. (735) 357 7564", 1, 28.7);
      doc.text("atencion.horizonte@gmail.com", 1, 29);
      doc.setFontSize(12);
      doc.addPage();
      doc.addImage(imgSocios, 'PNG', 1, 0.5, 6, 2);
      doc.addImage(linea2, 'PNG', 17, 0.5, 2, 2);
      doc.addImage(marca, 'PNG', 1, 6, 19, 25);
      doc.setFontSize(16);
      doc.setFont('helvetica')
      doc.text('RECETA MÉDICA', 8, 4)
      doc.text('FOLIO: ' + this.recetMedica.folio, 16, 4)
      doc.setFontSize(12);
      doc.text('FECHA DE CONSULTA:', 12.5, 5)
      doc.text(`${this.recetMedica.fechaReceta}`, 17.5, 5)
      doc.text('NOMBRE:', 1, 6)
      doc.text(`${this.paciente.nombrePaciente} ${this.paciente.apellidoPaterno} ${this.paciente.apellidoMaterno}`, 3.2, 6)
      doc.text('GÉNERO: ', 1, 6.7);
      doc.text(`${this.paciente.genero}`, 3.2, 6.7);
      doc.text('EDAD: ', 7, 6.7);
      doc.text(`${this.paciente.edad}` + ' AÑOS', 8.5, 6.7);
      doc.text('DIRECCIÓN:', 1, 7.5)
      doc.text(`${this.paciente.callePaciente} ${this.paciente.estadoPaciente}`, 3.7, 7.5)
    }
  }

}
