import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaquetesService } from '../../../../services/paquetes/paquetes.service';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Dates from 'src/app/classes/dates/date.class';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { CEDE } from 'src/app/classes/cedes/cedes.class';
import Tickets from 'src/app/classes/tickets/ticket.class';
import { eliminarStorage } from 'src/app/functions/pacienteIntegrados';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import { PagoServiciosService } from 'src/app/services/pagos/pago-servicios.service';

@Component({
  selector: 'app-medico-laboral',
  templateUrl: './medico-laboral.component.html',
  styleUrls: ['./medico-laboral.component.css']
})
export class MedicoLaboralComponent implements OnInit {

  @Input() id: String;
  consultas:any = { tipo: '', consulta: '', fecha: '', medico: '', firma: '' }
  concepto:any[] = []
  medicos:any[] = []
  paquete:any[] = []
  citas:any[] = []
  examenes:any[] = []
  public egoCount=0;
  public pacienteInfo={
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    id:"",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    _id:""
  };

  public infoVenta = {  

    paciente:"",
    nombrePaciente:"",
    vendedor:"",
    fecha:"",
    hora:"",
    estudios:[],
    efectivo:false,
    doctorQueSolicito:"",
    transferencia: false,
    tarjetCredito:false,
    tarjetaDebito:false,
    montoEfectivo:0,
    montoTarjteCredito:0,
    montoTarjetaDebito:0,
    montoTranferencia: 0,
    sede:"",
    totalCompra:0,
    prioridad:"",
    compraConMembresia:true,
    status:'Pagado'
  }

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };
    // public paqueteExamenesInto = [];
    public fechaConsulta = moment().format('l');
    public horaIngreso = moment().format('hh:mm');
    public btnAgregaConsultas = false;
  fecha: string;
  public diasRestantes:Number;
  public fechaRegistro: string;
  public nombrePaquete='';
  constructor(public _router: ActivatedRoute, public _paquete:PaquetesService, public _route:Router, private _pagoServicios: PagoServiciosService) { }

  ngOnInit(): void {
    this.obtenerMedicos();
    this.obtenerPaquete();
    this.consultas.firma = JSON.parse(localStorage.getItem('usuario')).nombre;
  }

  obtenerPaquete(){
    this._paquete.obtenerPaquete(this.id)
    .subscribe(  (data:any) =>  {
      this.pacienteInfo = data['paquetes']['paciente'];
      this.paquete.push(data['paquetes']);
      this.citas = this.paquete[0].visitas
      this.examenes = this.paquete[0].examenesLab;
      this.fechaRegistro = this.paquete[0].fecha;
      this.nombrePaquete = this.paquete[0].paquete.nombrePaquete;
      this.verCosnultashechas();
      this.getDiasRestantes();
    });
  }

  getDiasRestantes(){

    const dias = new Dates()
    this.diasRestantes  =  dias.getDateRest( this.fechaRegistro ); 
    /* console.log( this.diasRestantes ); */ 

    if( this.diasRestantes <= 0 || this.diasRestantes == NaN ){
      
      let estado = {
        membresiaActiva: false
      }
      this._paquete.actualizarEstadoMembresia( this.pacienteInfo._id, estado )
      .subscribe( data => {});

    }
  }

  obtenerMedicos(){
    this._paquete.getMedicos()
    .subscribe( (data) => {
      this.medicos = data['medicos']
    });
  }

  verCosnultashechas(){
    this.egoCount=0;
    this.citas.forEach(element => {
      // console.log( element );
      if( element.consulta == "Consulta de medicina general sin costo durante un año de Lunes a Domingo las 24 Horas" ){
        // console.log("Se lo lee");
        this.egoCount += 1;
        let cardMG = document.querySelector('#cardMG');
        let badgeMG = document.querySelector('#badgeMG');
        this.addClassesCss( cardMG, badgeMG);
        //this.hospitalizacion = element ;
      } 
    });
    // termian el codigo que valida las consultas 
    this.verEstudios();
  }

  irAUnServicio(  servicio ){
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    this._route.navigate([ `/serviciosInt/${servicio}`]);
  }

  verEstudios(){
    // badgeQuimica
   //   // quimicaSanguineaCard
    this.examenes.forEach( (examen:consultas) => {
      if( examen.consulta === "Estudios básicos" ){
        let cardBasicos = document.querySelector('#BasicosObsCard');
        let badgeBasicos = document.querySelector('#badegeBasicos');
        this.addClassesCss( cardBasicos, badgeBasicos);
      }
      if( examen.consulta === "Estudios especiales"  ){
        let cardEspeciales = document.querySelector('#EspecialesCard');
        let badgeEspeciales = document.querySelector("#badgeEspeciales");
        this.addClassesCss( cardEspeciales, badgeEspeciales );
      }
      if(examen.consulta === "Realización de estudios"){
        let cardRE = document.querySelector('#RECard');
        let badgeRE = document.querySelector("#badegeRE");
        this.addClassesCss( cardRE, badgeRE );
      }
      if(examen.consulta === "Interpretación de estudios "){
        let cardIE = document.querySelector('#IECard');
        let badgeIE = document.querySelector('#badgeIE');
        this.addClassesCss( cardIE, badgeIE);
      }
      if( examen.consulta === "Realización de placas"){
        let cardRP = document.querySelector("#RPCard");
        let badgeRP = document.querySelector("#badgeRP");
        this.addClassesCss(cardRP, badgeRP)
      }
      if( examen.consulta === "Interpretación de placas"){
        let cardIP = document.querySelector("#IPCard");
        let badgeIP = document.querySelector("#badgeIP");
        this.addClassesCss(cardIP, badgeIP)
      }
    });
   //   //  termina el codigo que valida los examenes 
  }

  showMessage(examen: string){
    this.examenes.forEach(  (estudio:consultas, index) => {
      // console.log( estudio.consulta  === examen  );
      // console.log( estudio  );
        if( estudio.consulta ===  examen  ){
          // console.log(estudio.consulta, index);
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Tipo:  ${this.examenes[index].consulta}\n  Fecha: ${this.examenes[index].fecha} \n Hora: ${this.examenes[index].hora}\n   Médico ${this.examenes[index].medico}\n  Atendió: ${this.examenes[index].firma}`,
          })
        }else{
          this.citas.forEach((cita:consultas, index)=>{
            if(cita.consulta === examen){
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Tipo:  ${this.citas[index].consulta}\n  Fecha: ${this.citas[index].fecha} \n Hora: ${this.citas[index].hora}\n   Médico ${this.citas[index].medico}\n  Atendió: ${this.citas[index].firma}`,
          })
            }
          })
        }
    });
  }

  showMessageConsulta(consulta:string){    
    this.citas.forEach((cita:consultas, index)=>{
      if(cita.consulta == consulta){
                  Swal.fire({
            icon: 'success',
            title: '',
            text: `Tipo:  ${this.citas[index].consulta}\n  Fecha: ${this.citas[index].fecha} \n Hora: ${this.citas[index].hora}\n   Médico ${this.citas[index].medico}\n  Atendió: ${this.citas[index].firma}`,
          })
      }
    })
  }

  addClassesCss( card: Element, badge:Element ){
    card.classList.add('border-primary');
    if( badge.id == "badgeMG"  ){
      badge.innerHTML = this.egoCount.toString(); 
    }
    badge.classList.add('badge-primary');
  }

  // Nueva programacion de insercion
  seleccion($event, value){
    switch (value) {
      case 'visitas':
        this.concepto=[]
        for(let item of this.paquete ){
          for(let item2 of item.paquete.CitasIncluidas){
            this.concepto.push(item2)
          }
          /* console.log(this.concepto) */
        }  
        this.consultas.consulta = ''
      break;

      case 'laboratorio':
        this._route.navigateByUrl("/serviciosInt/laboratorio");
      break;

      case 'ultrasonido':
        this._route.navigateByUrl("/serviciosInt/ultrasonido");
      break;

      case 'xray':
        this._route.navigateByUrl("/serviciosInt/xray");
      break;

      default:
        break;
    }
  }

  agregarConsulta(){
    this.consultas.fecha = this.fechaConsulta;
    this.consultas.hora = this.horaIngreso;
    /* console.log(this.consultas) */
    this.consultas.firma = JSON.parse(localStorage.getItem('usuario')).nombre;
    
    if(this.consultas.tipo == '' || this.consultas.consulta == '' || this.consultas.medico == '' || this.consultas.firma == ''){
                Swal.fire({
            icon: 'error',
            title: '',
            text: 'POR FAVOR; INGRESA LOS DATOS CORRECTOS',
          })
    }else{
      if(this.consultas.tipo == 'laboratorio' || this.consultas.tipo == 'ultrasonido'|| this.consultas.tipo == 'rayosx'){
        this.consultas.tipo='examenesLab'
      }
      this._paquete.agregarConsulta(this.consultas,this.consultas.tipo,this.id).subscribe(
        (data)=>{
          
          this.mostrarConsultas();
          this.setDatesVenta(this.consultas.medico);          
          this._pagoServicios.agregarPedido( this.infoVenta )
          .subscribe( (data) => {
            // console.log( data );
            if(  data['ok'] ){
              
              this.generarTicket(data['folio']);
                // se crea la tabla de las ventas 
            Swal.fire({
            icon: 'success',
            title: 'Consulta Agregada',
            text: 'Puede ver las visitas en la tabla',
          })
                // ESTA INFORMCAIÓN SE ENVIA A LOS DIFERENTES SERVICIOS 
                // seteamos las fechas 
                  eliminarStorage();
                  
                  
                  const eliminarPaciente = new PacienteStorage();
                  eliminarPaciente.removePacienteStorage(); 
                  this._route.navigateByUrl('consulta');  
              }
          });
        })
      this.consultas = { tipo: '', consulta: '', fecha: '', medico: '', firma:'' }
    }
  }
  mostrarConsultas(){
    this.obtenerPaquete();
  }

  generarTicket(folio){

    const tickets = new Tickets();
    tickets.printTicketSinMembresia( this.pacienteInfo, this.carrito ,  this.infoVenta, folio );
  
  }

  setDatesVenta(medico){
    const item = {
      name:this.nombrePaquete,
      nombreEstudio: "CONSULTA DE MEDICO GENERAL",
      precioCon:0,
      precioSin:0,
      _id:'5fd3ebca08ccbb0017712f0d'
    }
    const dates = new Dates();
    //this.infoVenta.totalCompra = this.carrito.totalSin;
    this.fecha = moment().format('l');    
    this.infoVenta.hora = moment().format('LT');
    this.infoVenta.vendedor = getDataStorage()._id;
    this.infoVenta.paciente = this.pacienteInfo.id;
    this.infoVenta.sede = CEDE;
    this.infoVenta.prioridad = "Programado"
    this.infoVenta.fecha = dates.getDate();
    this.infoVenta.doctorQueSolicito = medico;
    this.carrito.items.push(item);
    this.infoVenta.estudios= this.carrito.items
    this.infoVenta.totalCompra = 0;
        
  }

}

class consultas  {

  tipo:  string;
  consulta:  string;
  fecha:  string;
  firma:  string;
  hora:  string;
  medico:  string;

}
