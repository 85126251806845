import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nota-egreso',
  templateUrl: './nota-egreso.component.html',
  styleUrls: ['./nota-egreso.component.css']
})
export class NotaEgresoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
