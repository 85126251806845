import { Component, OnInit } from '@angular/core';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ventas-canceladas',
  templateUrl: './ventas-canceladas.component.html',
  styleUrls: ['./ventas-canceladas.component.css']
})
export class VentasCanceladasComponent implements OnInit {

  public  ventas = [];
  public token = "";
  public filterName =""
  public filterPacientes = [];
  public totaltickets:string;
  public pagina = 0;

  constructor(private _serviciosService: ServiciosService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.obtenerVentas();
    this.obtenerToken();
  }

  obtenerToken() {
    this.token = localStorage.getItem('token');
  }

  obtenerVentas() {
    this._serviciosService.listaTickets()
    .subscribe((data:any) => {
      if(data.ok){
        this.removeLoadingScreen()
        this.setTickets( data.data )
        this.totaltickets = data['data'].results
      }
    })
  }

  setTickets(data:any){
    data.forEach(element => {
      if(element.paciente != null){
        this.filterPacientes.push(element)
      }
    });
    /* this.filterPacientes = data; */
    this.ventas = data;
    this.spinner.hide();
   // this.concatNames();
  }
  
  pintLoadingScreen() {
    // funcion que pinta el loading en la pantalla
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
  }

  removeLoadingScreen() {
    // se quita la clase que pinta el loading en la pantalla, la clase esta en el css
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
  }


  cancelTicket(id: any, cancelTicket: any){

    if( cancelTicket.value.length == "" || cancelTicket.value == "undefined" ){

      Swal.fire('Ingresa el motivo', '', 'error');

    }else {

      const motivo = {
        motivo: cancelTicket.value
      }
  
      
      this.pintLoadingScreen();
  
      this._serviciosService.cancelTickets( id , this.token, motivo)
      .subscribe( (data:any) => {
        if(data.ok) {
          this.filterName = "",
          this.obtenerVentas();
          Swal.fire('SE CANCELO EL TICKET', '', 'success');        
        }
  
      });
    
      
    }

  }


  filterByName(){

    this.filterPacientes = this.ventas;
    const filterPacientesConst = [];

      if(this.filterName.length == 0){

        this.filterPacientes = this.ventas;
      
      }else {

        this.filterPacientes.forEach( venta2 => {

          if( venta2.folio == this.filterName ) {
          filterPacientesConst.push( venta2);
        }
     
      });
      
      this.filterPacientes = filterPacientesConst;
      }


  }

}
