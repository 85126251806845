import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-jefa-enfermeria',
  templateUrl: './jefa-enfermeria.component.html',
  styleUrls: ['./jefa-enfermeria.component.css']
})
export class JefaEnfermeriaComponent implements OnInit {

  public consultas = [];
  public txtSearch = "";
  public totalConsultas:string;
  public pagina = 0;

  constructor(private spinner: NgxSpinnerService,
    private _ConsultasService: ConsultaService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.obtenerConsultas();
  }

  obtenerConsultas(){
    this._ConsultasService.obtenerConsultas()
    .subscribe((data:any) =>{
      this.setConsultas(data['data'])
      this.totalConsultas = data['data'].results
    });
  }

  setConsultas(consultas:any){
    consultas.forEach(element => {
      if(element.paciente != null){
        element.fechaIngreso = this.cortarCadena(element.fechaIngreso)
        this.consultas.push(element)
      }
    });
    this.consultas = this.ordenarFechas(this.consultas)
    this.spinner.hide();
  }

  ordenarFechas(arreglo:any){
    arreglo.sort(function(a, b) { 
      a = a.fechaIngreso; 
      b = b.fechaIngreso; 
      return a>b ? -1 : a<b ? 1 : 0; 
    });
    return arreglo
  }

  cortarCadena(fecha:string){
    let regreso;
    let valor = fecha.split("/")
    let dia = valor[0]
    let mes = valor[1]
    let anio = valor[2]
    if(dia.length == 1){
      let val ="0"
      dia = val.concat(dia)
    }
    if(mes.length == 1){
      let val ="0"
      mes = val.concat(mes)
    }
    regreso = anio.concat("-"+mes+"-"+dia)
    let regresoFecha = new Date(regreso)
    return regresoFecha;
  }

  filterChangeByName(){


    const consultasFilter =[];

    if(this.txtSearch.length >= 3){

      this.consultas.forEach( (consultas) =>{
        if (consultas.paciente.nombrePaciente.includes(this.txtSearch.toUpperCase())) {
          consultasFilter.push( consultas );
        }

      });
      
      this.consultas = consultasFilter;

    } else{
      this.obtenerConsultas();
    }
  }

}
