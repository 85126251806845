<div class="container">
    <h1 class="title-header">LISTADO DE SEGUIMIENTO</h1>

    <div class="row mt-3">
        <div class="col-sm-5">
            <input class="form-control form-control-sm" type="text" placeholder="BUSCAR">
        </div>
        
        <div class="col-sm-4">
            <select class="form-control form-control-sm">
                <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                <option value="1">PRODUCTO</option>
                <option value="2">SERVICIOS</option>
                <option value="3">MATERIALES</option>
              </select>
        </div>

        <div class="col">
            <div class="d-grid gap-2 col-4 mx-auto justify-end">
                <button class="btn btn-primary btn-sm" type="button">NUEVO</button>                
              </div>
        </div>
    </div>

    <div class="card mt-5">
        <div class="card-header">
            <div class="row">
                <div class="col-1 text-header">
                    No.
                </div>
                <div class="col-2 text-header">
                    NOMBRE
                </div>
                <div class="col text-header">
                    DESCRIPCIÓN
                </div>
                <div class="col text-header">
                    SOLICITANTE
                </div>
                <div class="col text-header">
                    CATEGORIA
                </div>
                <div class="col text-header">
                    PRODUCTOS
                </div>
                <div class="col text-header">
                    ESTADO
                </div>
                <div class="col text-header">
                    ACCIONES
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row" *ngFor="let seguimiento of seguimientos; let i = index">
                <div class="col-1 card-text">
                    <p>
                        {{ i + 1 }}
                    </p>
                </div>
                <div class="col-2 card-text">
                    <p>
                        {{ seguimiento.nombre }}
                    </p>
                </div>
                <div class="col card-text">
                    <p>
                        {{ seguimiento.descripcion }}
                    </p>
                </div>
                <div class="col card-text">
                    <p>
                        {{ seguimiento.solicitante }}
                    </p>
                </div>
                <div class="col card-text">
                    <p>
                        {{ seguimiento.categoria }}
                    </p>
                </div>
                <div class="col card-text">
                    <p>
                        {{ seguimiento.productos }}
                    </p>
                </div>
                <div class="col card-text">
                    <p>
                        {{ seguimiento.estado }}
                    </p>
                </div>
                <div class="col card-text">

                    <div class="row">
                        <div class="col">
                            <i class="fa fa-edit"></i>
                        </div>
                        <div class="col">
                            <i class="fa fa-trash-alt" (click)="borrarSeguimiento()"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="text-center">
                AQUI VA EL PAGINADOR
            </div>
        </div>
    </div>
</div>