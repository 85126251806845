<div class="content">
    <form #form='ngForm'>
        <p> TIEMPO DE PRESENTACIÓN REACCIÓN ADVERSA </p>
        <div class="form-group" id="diferir">
            <div class="form-check form-check-inline">
                <input type="radio" value="TEMPORAL" name="tiempodepresentacion" ngModel (change)="formularioTiempoPresentacion($event.target.value)">
                <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TEMPORAL</label>
            </div>
            <div class="form-check form-check-inline">
                <input type="radio" value="INMEDIATA" name="tiempodepresentacion" ngModel (change)="formularioTiempoPresentacion($event.target.value)">
                <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">INMEDIATA</label>
            </div>
            <div class="form-check form-check-inline">
                <input type="radio" value="TARDIA" name="tiempodepresentacion" ngModel (change)="formularioTiempoPresentacion($event.target.value)">
                <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">TARDIA</label>
            </div>
        </div>
        <!-- <div>
            <input type="radio" value="SI" name="medicamento_acitretina" ngModel >
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TEMPORAL </label>
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_tamoxifeno" ngModel >
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> INMEDIATA </label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_tamoxifeno" ngModel >
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TARDIA </label> 
        </div>   -->   
        
        <div>
            <p>TRATAMIENTO</p>
            <textarea name="tratamiento" id="tratamiento" cols="30" rows="10" class="form-control" ngModel (blur)="tratamientos($event.target.value)"></textarea>
        </div>  
    </form>
</div>
