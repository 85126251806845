import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
@Component({
  selector: 'app-bitacora-diferidos',
  templateUrl: './bitacora-diferidos.component.html',
  styleUrls: ['./bitacora-diferidos.component.css']
})
export class BitacoraDiferidosComponent implements OnInit {
  public diferidos = []
  constructor(private _banco:BancoService) { }

  ngOnInit(): void {
    this.getDiferidos()
  }

  getDiferidos(){
    this._banco.getDiferidos().subscribe((resp:any)=>{
      console.log(resp);
      
      this.diferidos = resp.data
    })
  }
}
