<section>
    <header>
        <app-titulos [title]="'ENTREGA DE RESULTADOS'"></app-titulos>
    </header>
    <!-- <h2 class="centerxd">HOJA DE REPORTE</h2> -->
</section>

<section class="row mb-1">
    <div class="col-md-12">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
    </div>
</section>

<section *ngIf="servicioValid === 'LABORATORIO'" >
    <div class="row">
        <div class="col-md-4 offset-md-8 mt-3">
            <div class="center forced-jump noprint">
                <button type="button" class="btn-censur hide-when-printing buttonSVY" (click)="testLabsPDF()">
                    DESCARGAR
                </button>
            </div>
        </div>
    </div>
    <div class="card cardPer card-paddin-title container">
        <div class="row">
            <div class="title-card">
                <h2 class="mt-3 font-color">{{nombre}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="title-card">
                    <h6 class="mt-1 font-color">{{examen}}</h6>
                </div>
            </div>
        </div>
        <div class="row letras">
            <div class="col">
                <h6>Toma</h6>
                <!-- <input class="form-control" name="toma" type="datetime-local" [(ngModel)]="resultado.toma"> -->
                <p>{{ tomaxD | date:'M/d/yy, h:mm a':'UTC' }}</p>
            </div>
            <div class="col">
                <h6>Liberación</h6>
                <!-- <input class="form-control" name="liberacion" type="datetime-local" [(ngModel)]="resultado.liberacion"> -->
                <p>{{ liberacionxD | date:'M/d/yy, h:mm a':'UTC' }}</p>
            </div>
            <div class="col">
                <h6>Tipo de muestra</h6>
                <p>{{muestraxD}}</p>
            </div>
        </div>
        <div class="row letras title-card mt-3 mb-3 mayus">
            <div class="col-3">
                <h6>grupos </h6>
            </div>
            <div class="col-2">
                <h6>elementos </h6>
            </div>
            <div class="col-2">
                <h6>Resultados </h6>
            </div>
            <div class="col-2">
                <h6>Unidades </h6>
            </div>
            <div class="col-3">
                <h6>Referencia</h6>
            </div>
        </div>
        <form #f='ngForm'>
            <div *ngFor="let estudio of valoresRef; let i = index">
                <!-- container de todos los rows -->
                <div class="container">
                    <div class="row letras mt-3 mb-3">
                        <div class="col-3">
                            <h6 style="overflow-wrap:break-word">{{ estudio.grupo }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 style="overflow-wrap:break-word">{{ estudio.elemento }}</h6>
                        </div>
                        <div class="col-2">
                            <!-- <input class="form-control" [name]="estudio.elemento" required ngModel type="text" /> -->
                            <h6 style="overflow-wrap:break-word">{{obtenidosFinalesHTML[i]}}</h6>
                        </div>
                        <div class="col-2">
                            <h6>{{ estudio.unidad }}</h6>
                        </div>
                        <div class="col-3">
                            <div class="row" *ngFor="let valoresRef of estudio.valorDeReferencia">
                                <div class="col">
                                    <h6> {{ valoresRef.valorReferenciaD }} </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row letras">
            <div class="col">
                <h6>OBSERVACIONES</h6>
                <p>{{observacionesxD}}</p>
            </div>
        </div>
        <div class="row letras mt-2">
            <h3>{{metodo}}</h3>
            <span style="color: #000000">*Un diagnóstico clÍnico lo debe realizar un médico certificado y un resultado aislado en ocasiones es insuficiente para establecerlo</span>
        </div>
        <div class="center row mt-5 letras">
            <div class="col">
                <h3> Liberación: Dr.ALBERTO ZAMORA PALMA </h3>
                <h3>cédula: 3279016 </h3>
            </div>
            <div class="col">
                <h3>Realizo: {{ realizoxD }} </h3>
            </div>
        </div>
    </div>
</section>

<section class="container-fluid card" *ngIf="servicioValid == 'ULTRASONIDO'" >
    <article class="mt-3 " >
        <header class="row" >
            <div class="col-md-4 offset-md-8">
                <button class="btn-censur"  (click)="btnPrintPdf()"  >
                    DESCARGAR PDF
                </button>
            </div>
        </header>
        <div class="col-md-12">
            <header>
                <h2 class="center title-principal-bs mt-4 mb-4" > {{ titleUsg }} </h2>
            </header>
        </div>
        <article class="container-fluid" >
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        DIAGNÓSTICO:
                    </h5>
                    <p> {{ diagnosticoUsg }} </p>  
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        INTERPRETACIÓN:
                    </h5>
                    <p style="text-align: justify;"> {{ interpretacion }} </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        OBSERVACIONES:
                    </h5>
                    <p> {{ observacionesUSG }} </p>
                </div>
            </div>
            <figure class="row">
                <div class="col-md-12">
                    <img id="imagenesUltra" *ngFor="let img of imgUsg" [src]="img.base" class="img-responsive img-width pointer" [alt]="img.name">
                </div>
            </figure>
        </article>
    </article>
</section>

<section class="container-fluid card" *ngIf="servicioValid == 'ENDOSCOPIA'" >
    <article class="mt-3">
        <div class="col-md-12">
            <header>
                <h2 class="center title-principal-bs mt-4 mb-4" > {{ titleUsg }} </h2>
            </header>
        </div>
        <article class="container-fluid" >
            <div *ngFor="let img of imgUsg">
                <pdf-viewer [src]="img.base"[zoom]="1"></pdf-viewer>
                <header class="row" style="margin-bottom: 15px;">
                    <div class="col-md-4 offset-md-4">
                        <button class="btn-censur"  (click)="descargarEndos(img.base,img.name)">
                            DESCARGAR PDF
                        </button>
                    </div>
                </header>
            </div>
        </article>
    </article>
</section>

<section class="container-fluid card" *ngIf="servicioValid == 'PATOLOGIA'" >
    <article class="mt-3 " >
        <header class="row" >
            <div class="col-md-4 offset-md-8">
                <button class="btn-censur"  (click)="btnPrintPdf()"  >
                    DESCARGAR PDF
                </button>
            </div>
        </header>
        <div class="col-md-12">
            <header>
                <h2 class="center title-principal-bs mt-4 mb-4" > {{ titleUsg }} </h2>
            </header>
        </div>
        <article class="container-fluid" >
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        DIAGNÓSTICO:
                    </h5>
                    <p> {{ diagnosticoUsg }} </p>  
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        INTERPRETACIÓN:
                    </h5>
                    <p> {{ interpretacion }} </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        OBSERVACIONES:
                    </h5>
                    <p> {{ observacionesUSG }} </p>
                </div>
            </div>
            <figure class="row">
                <div class="col-md-12">
                    <img id="imagenesUltra" *ngFor="let img of imgUsg" [src]="img.base" class="img-responsive img-width pointer" [alt]="img.name">
                </div>
            </figure>
        </article>
    </article>
</section>

<section class="container-fluid card" *ngIf="servicioValid == 'XRAY'" >
    <article class="mt-3 " >
        <header class="row" >
            <div class="col-md-4 offset-md-8">
                <button class="btn-censur"  (click)="pdfRayosX()"  >
                    DESCARGAR PDF
                </button>
            </div>
        </header>
        <div class="col-md-12">
            <header>
                <h2 class="center title-principal-bs mt-4 mb-4" > {{ titleUsg }} </h2>
            </header>
        </div>
        <article class="container-fluid" >
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        DIAGNÓSTICO:
                    </h5>
                    <p> {{ diagnosticoUsg }} </p>  
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        INTERPRETACIÓN:
                    </h5>
                    <p> {{ interpretacion }} </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="bold" > 
                        OBSERVACIONES:
                    </h5>
                    <p> {{ observacionesUSG }} </p>
                </div>
            </div>
            <figure class="row">
                <div class="col-md-12">
                    <img id="imagenesUltra" *ngFor="let img of imgUsg" [src]="img.base" class="img-responsive img-width pointer" [alt]="img.name">
                </div>
            </figure>
        </article>
    </article>
</section>