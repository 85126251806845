<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <form action="" class="card cardPer card-paddin-title" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-md-12 center">
                        <h5 class="mt-4">
                            PDF
                        </h5>
                        <div class="card">
                            <ngx-dropzone name="fileUploadTosend" (change)="onSelect($event)">
                                <ngx-dropzone-label>
                                    <!-- <span class="material-icons">
                                        add
                                    </span> -->
                                    INSERTA LOS DOCUMENTOS AQUÍ
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview [file]="f" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                    <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                </div>
                <div class="row" style="display: flex;justify-content: center;">
                    <div>
                        <button class="boton_contra2"  (click)="sendResultsData()" >
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
