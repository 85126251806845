import { Component, OnInit } from '@angular/core';
import { PaquetesService } from '../../../services/paquetes/paquetes.service';

@Component({
  selector: 'app-ver-paquetes',
  templateUrl: './ver-paquetes.component.html',
  styleUrls: ['./ver-paquetes.component.css']
})
export class VerPaquetesComponent implements OnInit {

  paquetes:[];

  constructor(
    private _paqueteService: PaquetesService
  ) { }

  ngOnInit(): void {
    this.getPaquetes();
  }

  getPaquetes(){
    
    this._paqueteService.getPaquetes()
    .subscribe(  (data:any) =>  {
      /* console.log( data ); */
      this.paquetes = data['paquetes'];
      // console.log(this.paquetes);   
    }  );  
  }

}
