
<article class="container-fluid">
    <header>
        <div>
            <app-titulos [title]="'BITÁCORA DE SALIDAS'" [rol]="'pacientes'"></app-titulos>
        </div>
    </header>
    <section>
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-heder header">
                        <div class="row">
                            <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                                <p>No.</p>
                            </div>
    
                            <div class="col-md-2 text_header">
                                <p>NOMBRE</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p> LOTE UNITARIO </p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p>MOTIVO</p>
                            </div>
                            <div class="col-md-3 text_header">
                                <p>PROCESO EN EL QUE SE DESECHÓ</p>
                            </div>
                            <div class="col-md-1 text_header">
                                <p>  MOTIVO </p>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="salidas === []" > 
                        <p> No hay salidas </p>    
                    </div>

                    <div *ngIf="salidas !== []">
                        <div class="card-body"  >
                            <div  *ngFor="let item of salidas; let i = index" >
                                <div class="row row-hover" id="pointer">
                                    <div class="col-md-1" style="text-align: left;">
                                        <p>
                                            {{ i + 1 }}
                                        </p>
                                    </div>
                                    <div class="col-md-2" style="text-align: center;">
                                        <p>
                                            {{ item.productosFI.nombre | uppercase }} 
                                        </p>
                                    </div>
                                    <div class="col-md-2" style="text-align: left;">
                                        <p>
                                            {{ item.lote_unitario | uppercase}}
                                        </p>
                                    </div>
                                    <div class="col-md-2" style="text-align: center;">
                                        <p>
                                            {{item.motivoDesecho}}
                                        </p>
                                    </div>
                                    <div class="col-3" style="text-align: center;">
                                        <p>
                                            {{item.procesoDesecha | uppercase}}
                                        </p>
                                    </div>
                                    <div class="col-md-2" >
                                        <i class="fa fa-trash pointer colorRed" (click)="changeStatus(item._id)" ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
        <br>
        <p style="color: white"> Cargando... </p>
    </ngx-spinner>
</article>
<!-- [routerLink]="['/hoja/servicios/laboratorio/disponentes/', item._id]" -->