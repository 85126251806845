<div class="row alan">
   <div class="container-fluid">
      <app-titulos [title]="'LISTADO DE SEDES'" ></app-titulos>
   </div>
   <div class="col-md-6">
      <input type="text" placeholder="Buscar" required class="form-control" id="alineacion">
   </div>
   <div class="col-md-2"></div>
   <div class="col-md-2">
      <a class="principalButton" type="submit" [routerLink]="['/agregar/sede/bs']">AGREGAR SEDE</a>
   </div>
</div>
<br>
<div class="row alan">
   <div class="  col-md-3" id="alineacion"
   *ngFor="let sedes of sedes | paginate:{id:'_id',
                                                                               itemsPerPage: 6,
                                                                               currentPage:pagina,
                                                                               totalItems:totalAmbulancia}">
      <a [routerLink]="['/detalle/sede/', sedes._id]">
         <img
            src="https://elsouvenir.com/wp-content/uploads/2019/07/Las-iglesias-mas-hermosas-de-Puebla.-Foto-Pedro-Lastra-560x600.jpg"
            class="rounded">
         <h5>{{ sedes.clinica}}</h5>
      </a>
   </div>
</div>
<div class="offset-md-4">
   <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="_id" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
</div>
<br>
<br>