import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-formulario-edit-service',
  templateUrl: './formulario-edit-service.component.html',
  styleUrls: ['./formulario-edit-service.component.css']
})
export class FormularioEditServiceComponent implements OnInit {

  public serviceEdit = {
      _id: "",
      ESTUDIO: "",
      PRECIO_MEMBRESIA:"",
      PRECIO_MEMBRESIA_URGENCIA: "",
      PRECIO_MEMBRESIA_HOSPITALIZACION: "",
      PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA: "",
      PRECIO_PUBLICO: "",
      PRECIO_PUBLICO_URGENCIA: "",
      PRECIO_PUBLICO_HOSPITALIZACION: "",
      PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA: ""
  };
  public nombre:string;
  public status: string;
  public is_edit: boolean;
  public id: string;
  public page_title: string;
  public url: string;

  constructor(private _route:ActivatedRoute, private _service:IntegradosService, private _router:Router) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    /* console.log(this.id); */
    this.getService();
  }

  onSubmit() {
    /* console.log(this.serviceEdit); */
    this._service.updateServicio(this.id, this.serviceEdit).subscribe(
      res => {
        if(res.ok){
          this.status = 'ok';
          this.serviceEdit = res.data;
          //console.log("Editado Correctamente!", "Puedes ver los cambios en el Servicio de Ambulancia!", "success")
          Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL SERVICIO SE HA EDITADO CORRECTAMENTE',
          })
          this._router.navigateByUrl('/serviciosInt/'+res.data.name);
        }else{
          Swal.fire({
          icon: 'error',
          title: '',
          text: 'EL SERVICIO NO SE EDITADO CORRECTAMENTE',
          })
          this.status = 'error';
        }
      },
      err => {
        /* console.log(err); */
          Swal.fire({
          icon: 'error',
          title: '',
          text: 'EL SERVICIO NO SE EDITADO CORRECTAMENTE',
          })
        this.status = 'error';
      }
    )
  }

  cancelar(){
    this._router.navigateByUrl('/serviciosInt/'+this.nombre);
  }

  getService(){
    this._route.params.subscribe(params => {
      var id = params['id'];
      this._service.getServicioById(id).subscribe(
        (res:any) => {
          if(res.data){
            this.serviceEdit = res.data;
            this.nombre=res.data.name;
            /* console.log(this.serviceEdit); */
          }else{
            // this._router.navigate(['/ambulancia']);
          }
        },
        err => { 
          console.log(err);
          // this._router.navigate(['/ambulancia']);  
        }
      )
    });
  }

}
