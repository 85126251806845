<section>
    <div class="row">
        <div class="col-8">
            <!-- <h1 class="centerxd"> HISTORIA CLÍNICA </h1> -->
            <app-titulos [title]="'HISTORIA CLÍNICA'" [rol]="'doctor'"></app-titulos>
        </div>
    </div>
</section>
<!-- <section>
    <div class="card" name="card" id="card">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <p>Nombre: {{paciente.nombre | uppercase}} {{paciente.apellidoPaterno | uppercase}} {{paciente.apellidoMaterno | uppercase}} </p>
                </div>
                <div class="col-md-3">
                    <p> CURP: {{ paciente.curp | uppercase }} </p>
                </div>
                <div class="col-md-2">
                    <p>Edad: {{paciente.edad}} </p>
                </div>
                <div class="col-md-3">
                    <p>Sexo: {{paciente.genero | uppercase}} </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <p>Registro: {{paciente.registro}} </p>
                </div>
                <div class="col-md-3">
                    <p>Dirección: {{paciente.calle | uppercase}} {{ paciente.municipio | uppercase}}</p>
                </div>
                <div class="col-md-2">
                    <p> {{ fecha }} {{ hora }} </p>
                </div>
                <div class="col-md-3">
                    <p> ID: {{ paciente.idPaciente }} </p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<ficha-info-user [pacienteInfo]="paciente2" [title]="false"></ficha-info-user>

<section style="margin: 2%;">
    <div class="card estudios" id="card">
        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-cambio" data-toggle="modal" data-target="#exampleModal"> Historia clínica </button>
            <button type="button" class="btn btn-cambio" data-toggle="modal" (click)="obtenerResultados('laboratorio'); modalLab.show() "> Laboratorio </button>
            <button type="button" class="btn btn-cambio" data-toggle="modal" (click)="obtenerResultados('xray'); modalRayosX.show() "> Rayos x </button>
            <button type="button" class="btn btn-cambio" data-toggle="modal" (click)="obtenerResultados('endoscopia'); modalEndos.show()"> Endoscopias </button>
            <button type="button" class="btn btn-cambio" data-toggle="modal" (click)="obtenerResultados('patologia');modalPato.show()"> Patologias </button>
            <button type="button" class="btn btn-cambio" data-toggle="modal" (click)="obtenerResultados('ultrasonido');modalUsg.show()"> Ultrasonidos </button>
            <button type="button" class="btn btn-cambio" (click)="modalResonancia.show()"> Resonancias </button>
            <button type="button" class="btn btn-cambio" (click)="obtenerOtrosEstudios('LABORATORIO');modalOtros.show()"> Otros Estudios </button>
        </div>
    </div>
</section>

<!-- COMPONENTE DE LAS GRAFICAS BROW -->
<div class="">
    <app-graficas-pediatria [paciente]="paciente" *ngIf="paciente.genero == 'FEMENINO' && paciente.edad <=9">
    </app-graficas-pediatria>
</div>

<div class="">
    <app-graficas-ninos [paciente]="paciente" *ngIf="paciente.genero == 'MASCULINO' && paciente.edad <=9"></app-graficas-ninos>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5> Historia clínica </h5>
            </div>
            <div class="modal-body">
                <!-- <app-historia-clinica [paciente]="paciente"></app-historia-clinica> -->
                <app-historia-clinica-horizonte [paciente]="paciente2"></app-historia-clinica-horizonte>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
            </div>
        </div>
    </div>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-2">
            <div class="card signos" id="card">
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>Talla: <span>{{paciente.talla}} m</span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#graficaTalla" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>Peso: <span>{{paciente.peso}} Kg</span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#graficaPeso" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>IMC: <span> {{paciente.imc}}  Kg/m<sup>2</sup> </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <!--  -->
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-8">
                        <p>Sistolica: <span> {{paciente.sistolica}}  <sup></sup> </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-8">
                        <p>Diastolica: <span> {{paciente.diastolica}}  <sup></sup> </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>

                <!--  -->
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>FC: <span> {{paciente.fc}} LPM </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>FR: <span> {{paciente.fr}} RPM </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>TEMP: <span> {{paciente.temp}} °C </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>P.C.: <span> {{paciente.pc}} cm </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>P.A.: <span> {{paciente.pa}} cm </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p>P.T.: <span> {{paciente.pt}} cm </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p *ngIf="edad > 8">APGAR: <span> {{paciente.apgar}} </span></p>
                    </div>
                    <div class="col-4">
                        <button *ngIf="edad > 8" type="button" class="botonSignos" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-8">
                        <p>SaO: <span> {{paciente.sao}} % </span></p>
                    </div>
                    <div class="col-4">
                        <button type="button" class="botonSignos" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-8">
                        <p>SpO<sub>2</sub>: <span>{{paciente.pao}}</span>%SpO<sub>2</sub></p>
                    </div>
                    <div class="col-4">
                        <button type="button" (click)="graficarPAO()" class="botonSignos" data-toggle="modal" data-target="#graficaPao"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-8">
                        <p> Alergias:<span> {{paciente.alergias}} </span></p>
                    </div>
                    <div class="col-4"></div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="container-fluid" style="width: 125%;  height: 100%;"> 
                <div class="card" id="card" style="width: 100%; height: 100%;">
                    <div class="col-12">
                        <div role="tabpanel">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" aria-controls="#motivoConsulta" href="#motivoConsulta">Motivo de la consulta</a>
                                </li>
    
                                <li class="nav-item">
                                    <a class="nav-link" href="#evolucionDelPaciente" data-toggle="tab" aria-controls="#evolucionDelPaciente">Evolución del paciente</a>
                                </li>
    
                                <li class="nav-item">
                                    <a class="nav-link" href="#interrogacionPorAparatosYsistemasH" data-toggle="tab" aria-controls="#interrogacionPorAparatosYsistemasH">  Interrogación por aparatos y sistemas  </a>
                                </li>
    
                                <li class="nav-item">
                                    <a class="nav-link" href="#exploracionFisicaH" data-toggle="tab" aria-controls="#exploracionFisicaH">  Exploración fisica </a>
                                </li>
    
                                <li class="nav-item">
                                    <a class="nav-link" href="#diagnostico" data-toggle="tab" aria-controls="#diagnostico">  Diagnóstico </a>
                                </li>
    
                                <li class="nav-item">
                                    <a class="nav-link" href="#plan" data-toggle="tab" aria-controls="#plan">  Plan </a>
                                </li>
    
                            </ul>

                            <div class="tab-content">
                                <!-- Motivo de la consulta -->
                                <div role="tabpanel" class="tab-pane active" id="motivoConsulta">
                                    <br>
                                    <!--h4> Motivo de la consulta </h4-->
                                    <div class="container">
                                        <form>
                                            <div class="row">
                                                <textarea class="form-control text-upper" name="motivoDeConsulta" id="motivoDeConsulta" [(ngModel)]="hojaEvolucion.motivoDeConsulta" cols="130" rows="12"></textarea>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <!-- Evolucion de la consulta  -->
                                <div role="tabpanel" class="tab-pane" id="evolucionDelPaciente">
                                    <!--h4> Evolucion de la consulta </h4-->
                                    <br>
                                    <div class="container">
                                        <form>
                                            <div class="row">
                                                <textarea class="form-control text-upper" name="evolucionDelPadecimiento" [(ngModel)]="hojaEvolucion.evolucionDelPadecimiento" id="evolucionDelPadecimiento" cols="130" rows="12"></textarea>
                                            </div>
                                        </form>
                                    </div>
                                </div>
    
    
    
    
                                <!-- INTEROGACION  POR APARATOS Y SISTEMAS -->
    
                                <div role="tabpanel" class="tab-pane" id="interrogacionPorAparatosYsistemasH">
                                    <!--h4 class="text-principal"> Interrogacion por aparatos y sistemas </h4-->
    
                                    <div class="container" style="overflow-x: scroll; height: 19rem;">
                                        <form action="">
                                            <div class="row">
                                                <div class="col-md-3">
    
                                                    <label for="respiratorio">Respiratorio/Cardiovascular</label>
                                                </div>
    
                                                <div class="col-md-9">
                                                    <input type="text" name="respiraotriosCardiovasculares" [(ngModel)]="hojaEvolucion.respiratorioCardiovascular" class="form-control">
    
                                                </div>
                                            </div>
                                            <div class="row">
    
    
                                                <div class="col-md-3">
    
                                                    <label for="digestivo">Digestivo</label>
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" class="form-control" name="digestivo" [(ngModel)]="hojaEvolucion.digestivo">
    
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="endocrino"> Endocrino </label>
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" class="form-control" name="endocrino" id="endocrino" [(ngModel)]="hojaEvolucion.endocrino">
                                                </div>
    
                                                <div class="col-md-3">
    
                                                    <label for="musculo">Músculo - Esquelético</label>
                                                </div>
    
                                                <div class="col-md-8">
    
                                                    <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.musculoEsqueletico" name="musculoEsqueletico">
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="gitourinario">Gitourinario</label>
    
                                                </div>
    
                                                <div class="col-md-8">
    
                                                    <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.genitourinario" name="genitourinario">
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="hematopoyetico">Hematopoyético - Linfático</label>
    
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.hematopoyeticoLinfatico" name="hematopoyeticoLinfatico">
    
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="piel">Piel y anexo</label>
    
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.pielAnexos" name="pielAnexos">
    
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="neurologico">Neurológico y Psiquiátrico</label>
    
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.neurologicoPsiquiatricos" name="neurologicoPsiquiatricos">
    
                                                </div>
                                            </div>
    
                                        </form>
                                    </div>
                                </div>
                                <!-- Exploracion fisica  -->
                                <div role="tabpanel" class="tab-pane" id="exploracionFisicaH">
                                    <!--h4> Exploración fisica </h4-->
                                    <div class="container" style="overflow-x: scroll; height: 19rem;">
    
    
                                        <form action="">
                                            <div class="row">
    
                                                <div class="col-md-3">
                                                    <label for="piel">Piel y Anexo</label>
    
                                                </div>
                                                <div class="col-md-8">
    
                                                    <input type="text" name="pielyanexo" class="form-control" [(ngModel)]="hojaEvolucion.piel">
                                                </div>
                                                <div class="col-md-3">
    
                                                    <label for="cabezaYCuello">Cabeza y Cuello</label>
                                                </div>
    
                                                <div class="col-md-8">
    
                                                    <input type="text" name="cabezaYCuello" class="form-control" [(ngModel)]="hojaEvolucion.cabezaCuello">
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="torax">Tórax</label>
    
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" name="torax" class="form-control" [(ngModel)]="hojaEvolucion.torax">
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="abdomen">Abdomen</label>
    
                                                </div>
                                                <div class="col-md-8">
    
                                                    <input type="text" name="abdomen" class="form-control" [(ngModel)]="hojaEvolucion.abdomen">
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="genitales">Genitales</label>
    
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" name="genitales" class="form-control" [(ngModel)]="hojaEvolucion.genitales">
    
                                                </div>
    
                                                <div class="col-md-3">
                                                    <label for="extremidades">Extremidades</label>
    
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="text" name="extremidades" class="form-control" [(ngModel)]="hojaEvolucion.extremidades">
    
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sistemaNervioso">Sistema Nervioso</label>
    
                                                </div>
    
                                                <div class="col-md-8">
                                                    <input type="text" name="sistemaNervioso" class="form-control" [(ngModel)]="hojaEvolucion.sistemaNervioso">
    
                                                </div>
    
                                            </div>
                                        </form>
                                    </div>
    
                                </div>
    
                                <!-- DIAGNOSTICOS -->
    
                                <div role="tabpanel" class="tab-pane" id="diagnostico">
                                    <div class="container-fluid" style="height: 100%;">
                                        <div class="row">
                                            <h3 class="title-principal-bs mt-1" > Dianostico </h3>
                                            
                                            <div class="col-md-12">
                                                <input type="text" class="input-censur"  placeholder="APENDICITIS"  name="buscarDiagnostico" (keyup)="obtenerDiagnosticoUno()" id="buscarDiagnostico" [(ngModel)]="buscarDiagnostico" autocomplete="off">
                                            
                                                <div style="overflow-y: scroll; height:200px;" *ngIf="diagnosticos.length > 0">
                                                    <table  class="table table-hover" >
                                                        <tr class="pointer" *ngFor="let item of diagnosticos">
                                                            <td (click)="agregarDiagnosticoUno( item.NOMBRE  )"> {{ item.NOMBRE }} </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <!-- 
                                                Dexys Mindnight Runners
                                             -->
                                            <div class="col-md-12">
                                                <div class="card card-shadow" style="margin-top: 10px;" *ngIf="hojaEvolucion.diagnosticos.length > 0">

                                                    <!-- 
                                                        Listado de los diagnosticos selccionados
                                                     -->
                                                    <ol  class="row"  *ngFor="let item of hojaEvolucion.diagnosticos; let i = index">
                                                        <li class="col-md-7" >
                                                            {{ item.diagnostico }} 
                                                        </li>
                                                        <div class="col-md-2">

                                                            <!-- 
                                                                Delete de los diagnosticos 
                                                             -->
                                                            <i class="fa fa-times pointer" (click)="deleteItem(i)" ></i>

                                                        </div>
                                                    </ol>
                                                </div>
                                            </div>
    
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- 
                                                    se REQUIERE DE RECERTA 
                                                 -->
                                                <h5  class="centar" style="margin-top: 10px;">
                                                    RECETA:
                                                </h5>
                                                <div class="col-md-4 offset-md-4">
                                                    <label for="si" class="resaltar"> Si </label>
                                                    <input type="radio" class="primerReceta" value="si" [(ngModel)]="necistaReceta" name="receta" id="receta">
                                                    <label for="no" class="resaltar"> No </label>
                                                    <input type="radio" class="primerReceta" value="no" [(ngModel)]="necistaReceta" name="receta" id="receta">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
    
                                <!-- PLAN -->
    
                                <div role="tabpanel" class="tab-pane" id="plan">
                                    <!--h4> Plan</h4-->
                                    <br>
                                    <div class="container">
                                        <div class="row">
                                            <textarea name="plan" class="form-control text-upper" [(ngModel)]="hojaEvolucion.plan" id="plan" cols="125" rows="6"></textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-4 center">
                                            <button class="principalButton colorWhite" (click)="agregarHistoriaClinica()"> Guardar  </button>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-modal #modalEndos class="modal-demo" [maximizable]="true" [backdrop]="false">
    <ng-container class="app-modal-header"> ENDOSCOPIAS </ng-container>
    <ng-container class="app-modal-body" style="overflow: auto;">
        <div class="card" id="card">
            <!-- <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let item of endos ; index as i  ; let first = first">
                        <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentC(i)"> 
                            {{item.fechaPedido | date : 'dd/MM/yyyy':'UTC'}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content" *ngFor="let ss of endos ; index as i;">
                    <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentC}">
                        <pdf-viewer [src]="ss.resultados[0].obtenidos.imgs[0].base"[zoom]="1" style="width: 100px; height: 100px"></pdf-viewer>
                        <header class="row" style="margin-bottom: 15px;">
                            <div class="col-md-6 offset-md-4">
                                <button class="btn-censur" (click)="descargarEndos(ss.resultados[0].obtenidos.imgs[0].base)">
                                    DESCARGAR PDF
                                </button>
                            </div>
                        </header>
                    </div>
                </div>
            </div> -->
        </div>
    </ng-container>
    <ng-container class="app-modal-footer">
        <div class="text-right">
            <button type="button" class="btn-censur"  (click)="modalEndos.hide()">Close
            </button>
        </div>
    </ng-container>
</app-modal>

<app-modal #modalTomografia class="modal-demo" [maximizable]="true" [backdrop]="false">
    <ng-container class="app-modal-header"> Tomografia </ng-container>
    <ng-container class="app-modal-body">

    </ng-container>
    <ng-container class="app-modal-footer">
        <div class="text-right">
            <button type="button" class="btn-censur"  (click)="modalTomografia.hide()">Close
            </button>
        </div>
    </ng-container>
</app-modal>

<app-modal #modalResonancia class="modal-demo" [maximizable]="true" [backdrop]="false">
    <ng-container class="app-modal-header"> Resonancia </ng-container>
    <ng-container class="app-modal-body">

    </ng-container>
    <ng-container class="app-modal-footer">
        <div class="text-right">
            <button type="button" class="btn-censur"  (click)="modalResonancia.hide()">Close
            </button>
        </div>

    </ng-container>
</app-modal>

<app-modal #modalOtros class="modal-demo" [maximizable]="true" [backdrop]="false">
    <ng-container class="app-modal-header"> OTROS ESTUDIOS </ng-container>
    <ng-container class="app-modal-body" style="overflow: auto;">
        <div class="card" id="card">
            <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let item of listaOtrosServ ; index as i  ; let first = first">
                        <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentC(i);obtenerOtrosEstudios(item.nombre);"> 
                            {{item.nombre}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content" *ngFor="let ss of listaOtrosServ ; index as i;">
                    <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentC}">
                        <div role="tabpanel">
                            <ul class="nav nav-tabs">
                                <li class="nav-item" *ngFor="let item of otrosEstudios ; index as i  ; let first = first">
                                    <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentB(i)"> 
                                        {{item.fechaEstudios | date : 'dd/MM/yyyy':'UTC'}}
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" *ngFor="let otroEstudio of otrosEstudios ; index as i;">
                                <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentB}">
                                    <article class="mt-3 " >
                                        <article class="container-fluid" >
                                            <figure class="row">
                                                <div class="col-md-12">
                                                    <img id="imagenesUltra" *ngFor="let img of otroEstudio.imageUrl" [src]="img.base" class="img-responsive img-width pointer" [alt]="img.name">
                                                </div>
                                            </figure>
                                        </article>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container class="app-modal-footer">
        <div class="text-right">
            <button type="button" class="btn-censur"  (click)="modalOtros.hide()">Close
            </button>
        </div>

    </ng-container>
</app-modal>

<!-- fin de los regresos  -->
<app-modal #modalLab class="modal-demo" [maximizable]="true" [backdrop]="false">
    <ng-container class="app-modal-header"> Laboratorios </ng-container>
    <ng-container class="app-modal-body">
        <div class="card" id="card" style="overflow: scroll-y;">
            <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let titles of resultado ; index as i ; let first = first">
                        <a class="nav-link active fade show" data-toggle="tab" href="#/{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentB(i)">
                            {{titles.fechaPedido | date : 'dd/MM/yyyy':'UTC'}}
                        </a>
                    </li>
                </ul>

                <div class="tab-content" *ngFor="let item of resultado; index as i;">
                    <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentB}">
                        <app-labs-regresos [id]="item._id"></app-labs-regresos>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container class="app-modal-footer">
        <div class="row">
            <div class="col-md-6 offset-md-4">  
                <button type="button" class="btn-censur" style="float: right;" (click)="modalLab.hide()">Close</button>
            </div>
        </div>
    </ng-container>
</app-modal>



<app-modal #modalUsg class="modal-demo" [backdrop]="false" [maximizable]="true">
    <ng-container class="app-modal-header"> Ultrasonido </ng-container>
    <ng-container class="app-modal-body">
        <div class="card" id="card" >
            <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let item of resultado ; index as i  ; let first = first">
                        <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentC(i)"> 
                            {{item.fechaPedido | date : 'dd/MM/yyyy':'UTC'}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content" *ngFor="let ss of resultado ; index as i;">
                    <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentC}">
                        <app-usg-regresos [id]="ss._id"></app-usg-regresos>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="row">
            <div class="col-md-6 offset-md-4">   
                <button type="button" class="btn-censur" (click)="modalUsg.hide()">
                    Cerrar
                </button>
            </div>
        </div>
    </ng-container>
</app-modal>

<app-modal #modalRayosX class="modal-demo" [backdrop]="false" [maximizable]="true">
    <ng-container class="app-modal-header"> Rayos x </ng-container>
    <ng-container class="app-modal-body">
        <div class="card" id="card" >
            <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let item of resultado ; index as i  ; let first = first">
                        <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentC(i)"> 
                            {{item.fechaPedido | date : 'dd/MM/yyyy':'UTC'}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content" *ngFor="let ss of resultado ; index as i;">
                    <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentC}">
                        <app-rayosx-regresos [id]="ss._id"></app-rayosx-regresos>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="row">
            <div class="col-md-6 offset-md-4">   
                <button type="button" class="btn-censur" (click)="modalUsg.hide()">
                    Cerrar
                </button>
            </div>
        </div>
    </ng-container>
</app-modal>

<app-modal #modalPato class="modal-demo" [backdrop]="false" [maximizable]="true">
    <ng-container class="app-modal-header"> Patologias </ng-container>
    <ng-container class="app-modal-body">
        <div class="card" id="card" >
            <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let item of resultado ; index as i  ; let first = first">
                        <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentC(i)"> 
                            {{item.fechaPedido | date : 'dd/MM/yyyy':'UTC'}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content" *ngFor="let ss of resultado ; index as i;">
                    <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentC}">
                        <app-usg-regresos [id]="ss._id"></app-usg-regresos>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="row">
            <div class="col-md-6 offset-md-4">   
                <button type="button" class="btn-censur" (click)="modalUsg.hide()">
                    Cerrar
                </button>
            </div>
        </div>
    </ng-container>
</app-modal>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>