<!-- SE ANEXO EL HISTORIAL DE CONSULTAS A BITACORA DE ENFERMERÍA -->

<div class="container-fluid">
    <!-- <h1 class="centerxd"> BITACORA DE ENFERMERÍA CONSULTA EXTERNA</h1> -->
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="'BITACORA DE ENFERMERÍA CONSULTA EXTERNA'"></app-titulos>
        </div>
        <div class="col">
            <button class="boton" [routerLink]="['/enfermeria/bitacora']"> HISTORIAL DE CONSULTAS </button>
            <button class="principalButton" (click)="imprimirBitacora()">IMPRIMIR BITACORA</button>
            <!-- <a class="boton" [routerLink]="['/enfermeria/bitacora']"> HISTORIAL DE CONSULTAS </a> -->
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col text_header">
                            <p>Curp</p>
                        </div>
                        <div class="col text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col text_header">
                            <p>Servicio médico</p>
                        </div>
                        <div class="col text_header">
                            <p>Médico</p>
                        </div>
                        <div class="col text_header">
                            <p>Hora de cita</p>
                        </div>
                        <div class="col text_header">
                            <p>Número de expediente</p>
                        </div>
                    </div>
                </div>
                <div class="card-body mayus">
                    <div *ngFor="let listaEsperaP of listaEspera | paginate:{id:'listapacientes',
                    itemsPerPage: 5,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer sombra" [routerLink]="['/ficha-enfermeria-01', listaEsperaP._id]" >
                            <div class="col-md-1" style="text-align: center;">
                                <p>{{i+1}}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ listaEsperaP.paciente.nombrePaciente }} {{ listaEsperaP.paciente.apellidoPaterno }} {{ listaEsperaP.paciente.apellidoMaterno }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{listaEsperaP.paciente.curp}}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{listaEsperaP.paciente.edad}}</p>
                            </div>
                            <div class="col bold" style="text-align: center;">
                                <p>
                                    <strong>

                                        {{listaEsperaP.tipoDeServicio}}
                                    </strong>
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{listaEsperaP.doctorAPasar}}</p>
                            </div>
      
                            <div class="col" style="text-align: center;">
                                <p>{{listaEsperaP.horaIngreso}}</p>
                            </div>

                            <div class="col" style="text-align: center;">
                                <p *ngIf="listaEsperaP.paciente.numeroExpediente!=undefined">
                                    {{ listaEsperaP.paciente.numeroExpediente }} <br>
                                </p>
                                <p *ngIf="listaEsperaP.paciente.numeroExpediente==undefined">
                                   Sin Expediente <br>
                                </p>
                            </div>
      

                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="center">
        <section class="sec">
        
    </section> -->
    <!-- <div class="btn">

        <button class="btn btn-primary mayus" (click)="imprimirBitacora()">Imprimir Bitácora</button>

    </div> -->

</div>