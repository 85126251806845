import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-documentos-receptor',
  templateUrl: './documentos-receptor.component.html',
  styleUrls: ['./documentos-receptor.component.css']
})
export class DocumentosReceptorComponent implements OnInit {

  public document ={
    lastModified: 1623721377330,
    name: "",
    size: 167511,
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    webkitRelativePath: ""
  }
  public paciente = {
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    _id:"",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    receptor:"",
  }

  public receptor ={
    idbancosangre: ''
  }
  public imagenes:any[]=[]
  public documentosReceptor = [];
  public id = '';

  constructor(private _bancoSangre: BancoService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.obtenerReceptor();
    this.getPedidoReceptor();
  }

  obtenerReceptor(){
    this.paciente = JSON.parse(localStorage.getItem('receptor'));
  }

  getPedidoReceptor(){
    this.spinner.show();
    this.receptor.idbancosangre = localStorage.getItem('idCensur');
    this._bancoSangre.getPedidoReceptor(this.receptor).subscribe((resp:any) =>{
      this.id = resp.data.receptor._id;
      this.setPedido(resp.data.receptor.documentosreceptor);
    })
  }
  
  setPedido(pedido){
    pedido.forEach(element => {
      this.documentosReceptor.push(element);
    });
    this.spinner.hide();
  }

  actualizar(){
    this._bancoSangre.actualizarDocumentosReceptor(this.id,this.imagenes).subscribe((resp:any)=>{
      console.log(resp);
      
    })
  }

  showPdf(name, data) {
    const linkSource = data;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  // DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[i].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }
  
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.imagenes.splice(this.imagenes.indexOf(event),1)
  }

}
