import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';
@Injectable({
  providedIn: 'root'
})
export class PacientesService {

  public url = URL;
 

  constructor(private _http: HttpClient) {
  }

  getPaquetePaciente(paciente){
    let uri = this.url+'/ver/paquetes/paciente/'+ paciente;
    //console.log(paciente);
    return this._http.get(uri);
  }

  getPacientes( intervalo: number  ) {

    const uri = this.url+'/paciente/'+ intervalo;
    return this._http.get( uri )
  }
  getUltimoExpediente(){
    const uri = this.url+'/ultimo/expediente/pacientes'
    return this._http.get( uri )
  }


  // este servicio crea un nuevo paciente en la DB
  setPacientes( data: any , sede: string ){

    const uri = `${this.url}/paciente/${sede}`;
    return this._http.post( uri, data  );
  }

  //obtener la direccion por el codigo postal

  

  setCodigoPostal( code: any  ){
    const uri = `${this.url}/codigo/postal`;
    return this._http.post( uri, code  );
  }

  // este servicio obtiene pacientes por nombre

  getPacientePorNombre( nombre: String ) {


    // let uri = URLDEV + '/paciente/'+nombre;
    const uri = `${this.url}/pacientes/nombre/${nombre}`;

    // return this._http.get(uri)
    // .pipe(  map( (data) => {
    //     return data;
    // }))

    return this._http.get( uri );

  }



  // este servicio agrega un paquete al usuario
  addPaquete( paciente:any){


    /* console.log( paciente ); */
    
    // recibe por parametro el ID del usuario, el paquete y los nuevos valores a actualizar
  const id = paciente._id;

  // let uri = URLDEV+'/agregarPaquete/'+id;
  const uri =  `${this.url}/actualizar/paciente/${id}`;


// se obteiene la data para actulizar en el paquete


   return this._http.put( uri, paciente );

  }
  // fin de la funcion



  getPacienteBtID(id: string) {

  const uri = `${this.url}/pacientes/${id}`;

   return this._http.get( uri )
  }

  // actualizamos la informacion  del paciente

  updatePaceinte(body, id){

    /* console.log( body ); */

    const url = `${this.url}/actualizar/paciente/${id}`;

   return this._http.put( url , body);  
  }

  getPacientesAll(){


    
    const url = `${this.url}/pacientes`;

   return this._http.get( url);  

  }


  actualizarFiPacientes(id, body){
    /* console.log(id, body); */
    const url = `${ URL }/actualizar/paciente/${id}`;
    return this._http.put( url, body);
  }
  
  pacientesRepetidos(body:any){
    const url = `${this.url}/paciente/repetidos/list`;
   return this._http.post( url, body);  
  }

  buscarPacientesPorNombreBackend(body: any){
    const url = `${this.url}/pacientes/nombre`;
    return this._http.post( url, body);  
  }

  obtenerAllPacintes(){
    let uri = this.url+'/pacientes/repetidos/tabla';
    //console.log(paciente);
    return this._http.get(uri);
  }

}
