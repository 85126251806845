import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-stepper-socio',
  templateUrl: './stepper-socio.component.html',
  styleUrls: ['./stepper-socio.component.css']
})
export class StepperSocioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  enviar(form: NgForm){
    /* console.log(form); */
    
  }
}
