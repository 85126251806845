import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder,  Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';;
@Component({
  selector: 'app-registro-materiales',
  templateUrl: './registro-materiales.component.html',
  styleUrls: ['./registro-materiales.component.css']
})
export class RegistroMaterialesComponent implements OnInit {

  public newFormMateriales : any;
  public btnValidateSend = false;
  public countMateriales = 0;
  public newIdMaterial = "";
  public proveedoresCensur = [];

  constructor(
    private _fb: FormBuilder,
    private _almacenService: AlmacenService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.counAllMateriales();
    this.obtenerProveedoresCensur();
    this.createAForm();
  }


  get valoresProveedoresFB () {
    return this.newFormMateriales.get('proveedor') as FormArray;
  }

  addAProveedor() {
   // agregamos la propiedad del form Array
    const proveedoresValue = this._fb.group({
      proveedor: '',
      costo: 0
    });
   
    this.valoresProveedoresFB.push( proveedoresValue );
  }

  createAForm() {
    // creamos el formulario de forma dinamica
    this.newFormMateriales = this._fb.group({
      nombre: ['', { validators: [ Validators.required ]  } ],
      idProducto: [''],
      // nombre_comercial: ['', { validators: [ Validators.required]}],
      descripcion: [''],
      proveedor: this._fb.array([]), 
      laboratorio: [''],
      productoMatriales:['MATERIAL'],
      cantidadMin: ['', { validators: [Validators.required] }],
      // costo: [ 0, { validators:  [ Validators.required ]  }]
    });

    this.addAProveedor();
  }


  obtenerProveedoresCensur() {
    this._almacenService.obetenerProveedores()
      .subscribe( (data:any) => this.setProveedores(data.data));
  }

  setProveedores(data) {
    this.proveedoresCensur = data;
  }
  

  validateForm() {
    if( this.newFormMateriales.status == 'VALID') {
      this.btnValidateSend = false;
    }else {
      alert('Completa los campos');
    }
  }

  counAllMateriales() {

    const token = localStorage.getItem('token')

    this._almacenService.getcountAllMaterial(token)
      .subscribe((data:any) => {
        
        if(data.ok) {

          this.setCount( data.data.count )
        }else {
          alert( data.message );
        }

        });

  }
  
  setCount(count: number) {
    this.countMateriales = count;

  }

  createAnId() {
    const { nombre, tipo_producto, nombre_comercial, proveedor, laboratorio } = this.newFormMateriales.value;
    const nombreJson  = nombre.slice(0,3).toUpperCase();
    //const tipo_productoJson  = tipo_producto.slice(0,3).toUpperCase();
    // const nombre_comercialJson  = nombre_comercial.slice(0,3).toUpperCase();
    const proveedorJson  = proveedor[0].proveedor.slice(0,3).toUpperCase();
    const laboratorioJson  = laboratorio.slice(0,3).toUpperCase();
    // se elimino el tipo de producto ya que se cambio este form, para el registro de los productos de farmacia
    // this.newIdMaterial = `${nombreJson}/${nombre_comercialJson}/${proveedorJson}/${laboratorioJson}/${this.countMateriales}/${new Date().getHours()}/${new Date().getMinutes()}`;
    this.newIdMaterial = `${nombreJson}/${proveedorJson}/${laboratorioJson}/${this.countMateriales}/${new Date().getHours()}/${new Date().getMinutes()}`;
  }

  agregarNuevoProveedor(event){
    this.proveedoresCensur.push(event);
  }

  removeValores( i: number ){
    if( this.valoresProveedoresFB.length  > 1 ) {
      this.valoresProveedoresFB['controls'].forEach((element, index) => {
        if (index == i) {
          this.valoresProveedoresFB['controls'].splice(index, 1)
          this.valoresProveedoresFB.value.splice(index, 1)
        }
      });
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'SE REQUIERE AGREGAR AL MENOS UN PROVEEDOR',
      });
    }
  }

  sendANewMaterial() {
    this.btnValidateSend = true;
    this.createAnId();
    this.newFormMateriales.value.idProducto = this.newIdMaterial;
    if(this.newFormMateriales.status != 'INVALID'){
      this._almacenService.addANewMaterial( this.newFormMateriales.value )
      .subscribe((data:any) => {
        if(data.ok) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE REGISTRO EXITOSAMENTE EL PRODUCTO',
          });
            // esperamos 2s para que redireccione al dash
          setTimeout(() => {
            this._router.navigateByUrl('/lista/materiales');
          }, 2000)
        }
      })
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'ES NECESARIO COMPLETAR TODOS LOS CAMPOS',
      });
    }
  }

}

