import { Component, OnInit } from '@angular/core';
import { ModuloService } from 'src/app/services/asignarModulo/modulo.service';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';;

@Component({
  selector: 'app-asignar-modulo',
  templateUrl: './asignar-modulo.component.html',
  styleUrls: ['./asignar-modulo.component.css']
})
export class AsignarModuloComponent implements OnInit {

  public usuarios = [];
  public modulos = [];
  modulosForm: FormGroup

  createFormModule(){
    return new FormGroup({
      id_user: new FormControl('', [Validators.required]),
      id_modulo: new FormControl('', [Validators.required])
    })
  }

  constructor( private _asignarModulo: ModuloService ) { 
    this.modulosForm = this.createFormModule();
  }

  ngOnInit(): void {
    this.obtenerUsuarios();
    this.obtenerModulos();
  }

  obtenerUsuarios(){
    this._asignarModulo.getUsers()
    .subscribe((data:any) => {
      if( data.ok){
        this.usuarios = data.personal;

      }
    })
  }

  obtenerModulos(){
    this._asignarModulo.getModulos().subscribe((data:any) => {
      this.modulos = data.data;
    })
  }

  asignarModuloUser(){
    if(this.modulosForm.valid){
      /* console.log(this.modulosForm.value); */
      this._asignarModulo.addModulo(this.modulosForm.value).subscribe(( data:any ) => {
        if(data.ok) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE ASIGNO EXITOSAMENTE EL MÓDULO',
          });
          /* console.log(data); */
        }
      })
    }else{
      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'SELECCIONA LAS OPCIONES',
      });
    }
  }

  get id_user(){ return this.modulosForm.get('id_user') }
  get id_modulo(){ return this.modulosForm.get('id_modulo') }

}
