<form  [formGroup]="newFormValor"  >
<div class="container-fluid">
    <h1 class="centerxd">NUEVO ESTUDIO DE LABORATORIO</h1>
    

    <div class="card" name="card" id="card">

        <div class="row" id="principal">
            <div class="col margin-info">
                <p> <span>  NOMBRE DEL ESTUDIO</span> </p>
                <input type="text"  formControlName="nombreEstudio" />
            </div>
            <div class="col">
                <p> <span> TIPO DE EXAMEN</span> </p>
                <input type="text"  formControlName="tipoExamen"  />
            </div>
        </div>

        <div  formArrayName="valores"  *ngFor="let valor of valoresDeReferencias.controls; let j = index">
           
            <div [formGroupName]="j" class="row">

                <div class="col">
                    <p> <span>GRUPO </span> </p>
                    <input type="text"  formControlName="grupo"  /> 
                </div>

                <div class="col" style="margin-left: 40px;">
                    <p> <span>ELEMENTO</span> </p>
                    <input type="text" aria-describedby="basic-addon1"  formControlName="elemento" />
                </div>

                <div  formArrayName="valorDeReferencia"  *ngFor="let valor of valor['controls']['valorDeReferencia']['controls']; let i = index" >
                    <div [formGroupName]="i" class="row">
                        <div class="col">
                            <p><span>VALOR DE REFERENCIA</span></p>
        
                            <div class="input-group mb-3">
                                <input type="text" class="form-control"  formControlName="valorReferenciaD" />
                                <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1"  (click)="agregarValoresReferenciaD(j)"  > + </span>
                            </div>
                        </div>
                    </div>
                        
                    </div>
                
                <div class="col">
                    <p><span>METODO</span> </p>
                    <input type="text" formControlName="metodo" />
                </div>

           
                <div class="col buttons-content">
                    <button type="button" class="btn btn-success btn-rounded"   (click)="addValoreDeReferencia()" > + </button>
                    <button type="button" class="btn btn-danger btn-left btn-rounded" (click)="removeValores( i )" > - </button>
                </div>

            </div>
        </div>
    </div>
    
    <div class="card" name="card" id="card">
        <p class="cent"><span>OBSERVACIONES</span></p>
        <textarea name="" id="" cols="30" rows="10"  formControlName="observaciones"   ></textarea>
    </div>
    
    <div class="boton">
        <button class="principalButton"  [disabled]="btnAgregar" (click)="sendDataPost()"  >AGREGAR</button>
    </div>
</div>

</form>