<!-- <h1 class="centerxd" *ngIf="showAmbulanacia2">LISTADO DE TRASLADO</h1> -->
<div id="titulo">
    <app-titulos [title]="'LISTADO DE TRASLADO'" [rol]="'traslados'" *ngIf="showAmbulanacia2"></app-titulos>
</div>

<div class="container-fluid">
    <div class="container-fluid">
        <div class="row" *ngIf="showAmbulanacia2">
            <div class="col-md-8">
                <div class="form-group">
                    <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio">
                </div>
            </div>

            <!-- <div class="col-2">
                <button type=" button " class="btn btn-primary separar" id="boton_contra1" [routerLink]="['/hoja-fram']" routerLinkActive="router-link-active">COTIZAR</button>
            </div> -->

            <div class="col-2" *ngIf="roleUser === 'Admin' ">
                <button type="button" class="btn btn-secondary principalButton" id="boton_contra2" [routerLink]="['/formulario', servicios]" routerLinkActive="router-link-active">NUEVO</button>
            </div>

        </div>

        <div class="row" *ngIf="showTableAmbulanacia">
            <div class="col-md-9" *ngIf="showTableAmbulanacia">
                <div class="card" style="border-radius: 30px;">
                    <div class="card-heder header">
                        <div class="row">
                            <div class="col-md-2 text_header">
                                <p>Destino</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado de día</p>
                                <p *ngIf="membresia">Traslado membresía de día </p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado redondo de día</p>
                                <p *ngIf="membresia">Traslado membresía redondo de día</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado de noche</p>
                                <p *ngIf="membresia">Traslado membresía de noche</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado redondo de noche</p>
                                <p *ngIf="membresia">Traslado membresía redondo de noche</p>
                            </div>
                            <div class="col-md-2 text_header" *ngIf="roleUser == 'Admin'">
                                Acciones
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card-body" style="background-color: #ffffff; border-radius: 0px 0px 30px 30px;">
                    <div *ngFor="let ambulanciaSiService of ambulanciaSI | paginate:{id:'listaAmbulancia',
                                 itemsPerPage: 5 ,
                                 currentPage:pagina,
                                 totalItems:totalAmbulancia} ">
                        <div class="row">
                            <div class="col-md-2">
                                <label style="margin-right: 50px; margin-top: 14px;">{{ ambulanciaSiService.DESTINO }}</label>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_DIA, ambulanciaSiService.PRECIO_MEMBRESIA_DIA, ambulanciaSiService )" class="cursor  btn btn-light precioPublico" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                            background-color: white;
                                            border: none;" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_DIA |currency }}
                                        <!-- <p   *ngIf="membresia != true"> 
                                            {{ ambulanciaSiService.PRECIO_PUBLICO_DIA }}
                                        </p> 
                                        <p   *ngIf="membresia"> 
                                            {{ ambulanciaSiService.PRECIO_MEMBRESIA_DIA }}
                                        </p>  -->
                                </div>
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_DIA, ambulanciaSiService.PRECIO_MEMBRESIA_DIA, ambulanciaSiService )" class="cursor  btn btn-light precioPublico" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                            background-color: white;
                                            border: none;" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_DIA|currency }}
                                        <!-- <p   *ngIf="membresia != true"> 
                                            {{ ambulanciaSiService.PRECIO_PUBLICO_DIA }}
                                        </p> 
                                        <p   *ngIf="membresia"> 
                                            {{ ambulanciaSiService.PRECIO_MEMBRESIA_DIA }}
                                        </p>  -->
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_REDONDO_DIA, ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_DIA, ambulanciaSiService)" class="cursor btn btn-light precioRedondoDia" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                    background-color: white;
                                    border: none;" *ngIf="membresia != true">
                                    {{ ambulanciaSiService.PRECIO_PUBLICO_REDONDO_DIA|currency }}
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_REDONDO_DIA }}
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_DIA }}
                                    </h6> -->

                                </div>
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_REDONDO_DIA, ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_DIA, ambulanciaSiService)" class="cursor btn btn-light precioRedondoDia" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                    background-color: white;
                                    border: none;" *ngIf="membresia">
                                    {{ ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_DIA|currency }}
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_REDONDO_DIA }}
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_DIA }}
                                    </h6> -->

                                </div>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_NOCHE, ambulanciaSiService.PRECIO_MEMBRESIA_NOCHE, ambulanciaSiService  )" class="cursor btn btn-light precioNoche" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color: white;
                                              border: none;" *ngIf="membresia != true">
                                    {{ ambulanciaSiService.PRECIO_PUBLICO_NOCHE|currency }}
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_NOCHE }}
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_NOCHE }}
                                    </h6> -->

                                </div>
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_NOCHE, ambulanciaSiService.PRECIO_MEMBRESIA_NOCHE, ambulanciaSiService  )" class="cursor btn btn-light precioNoche" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color: white;
                                              border: none;" *ngIf="membresia">
                                    {{ ambulanciaSiService.PRECIO_MEMBRESIA_NOCHE|currency }}
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_NOCHE }}
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_NOCHE }}
                                    </h6> -->

                                </div>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_REDONDO_NOCHE, ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_NOCHE, ambulanciaSiService )" class="cursor btn btn-light precioRedondoNoche" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color: white;
                                              border: none;" *ngIf="membresia != true">
                                    {{ ambulanciaSiService.PRECIO_PUBLICO_REDONDO_NOCHE|currency }}
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_REDONDO_NOCHE }}
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_NOCHE }}
                                    </h6> -->

                                </div>
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_REDONDO_NOCHE, ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_NOCHE, ambulanciaSiService )" class="cursor btn btn-light precioRedondoNoche" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color: white;
                                              border: none;" *ngIf="membresia">
                                    {{ ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_NOCHE|currency }}
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        {{ ambulanciaSiService.PRECIO_PUBLICO_REDONDO_NOCHE }}
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        {{ ambulanciaSiService.PRECIO_MEMBRESIA_REDONDO_NOCHE }}
                                    </h6> -->

                                </div>
                            </div>

                            <div class="col-md-2" *ngIf="roleUser == 'Admin'">

                                <label class="btn btn-outline-success" (click)="editarAmbulancia(  ambulanciaSiService._id )" routerLinkActive="router-link-active">
                                    <i class="fas fa-edit"></i>
                                </label>

                                <label class="btn btn-outline-danger" style="margin-left: 1rem;" (click)="delete(ambulanciaSiService._id)">
                                    <i class="fas fa-trash-alt"></i>
                                </label>

                            </div>
                        </div>
                    </div>
                    <div class="offset-md-3">
                        <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card" style="margin: 10px; height: 33em; border-radius: 24px;" *ngIf="showTableAmbulanacia">
                    <div class="card-title" style="border-bottom-style: solid;">
                        <h4 style="text-align: center;">COTIZACIÓN</h4>
                    </div>

                    <div class="card-body" style="overflow: auto;">
                        <div class="row" *ngFor="let pedido of carrito.items; let i=index">
                            <div class="col-md-8">
                                <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia != true">
                                    {{ pedido.nombreEstudio }} : {{ pedido.precioSin }}
                                </p>
                                <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia">
                                    {{ pedido.nombreEstudio }} : {{ pedido.precioCon }}
                                </p>
                            </div>

                            <div class="col-md-2">
                                <button type="button" (click)="eliminar(i)" class="btn btn-link" style="color: red;">
                                    <!-- <i class="fas fa-trash-alt"></i> -->
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer" style="border-top-style: solid;">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 15px; font-weight: 800;" *ngIf="membresia != true">Total: &#36; {{carrito.totalSin}}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 15px; font-weight: 800;">Total membresía: &#36; {{ carrito.totalCon}}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro">Ahorro: &#36; {{carrito.totalSin - carrito.totalCon}}</h5>
                            </div>
                        </div>

                        <button type="button" *ngIf="btnPago" class="btn btn-success btn-block btn-tarjeta" (click)="onClick()">
                                <i class="fas fa-hand-holding-usd"></i>
                                Pagar
                        </button>

                        <button *ngIf="btnImpPago" type="button" class="btn btn-success btn-block btn-tarjeta" (click)="imprimirCotizacion(carrito)">
                            <i class="fas fa-hand-holding-usd"></i>
                                Imprimir cotización
                        </button>

                        <button class="btn btn-dark btn-tarjeta btn-block" [routerLink]="['/paciente']" routerLinkActive="router-link-active">
                                <i class="fas fa-user-plus"></i>
                                Nuevo
                        </button>

                        <button class="btn btn-block btn-info  btn-tarjeta" *ngIf="!membresia">
                                <i class="fas fa-share-alt"></i>
                                Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <app-tabla-service *ngIf="showOtrosService" [serviceSi]="serviceSi" [membresia]="membresia" [RoleUser]="roleUser" [totalAmbulancia]="totalAmbulancia" [servicios]="servicios"></app-tabla-service>
        <!-- <ambulancia-con [membresia]="membresia" *ngIf="membresia" [showTableAmbulanacia]="showTableAmbulanacia"></ambulancia-con> -->

        <!-- ESTA ES LA TABLA DE LOS DEMAS SERVICIOS  -->
        <!-- TERMINA LA TABLA DE LOS DEMAS SERVICIOS  -->
        <!-- <app-endoscopia *ngIf="showOtrosService" [showVista]="false" [serviceSi]="serviceSi"></app-endoscopia> -->

    </div>
</div>