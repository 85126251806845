<div class="container-fluid">
    <!-- <h1 class="centerxd" *ngIf="showVista">LISTADO DE ENDOSCOPÍA</h1> -->
    <app-titulos [title]="'LISTADO DE AGEnDA'" [rol]="'agenda'" *ngIf="showVista"></app-titulos>
    <div class="container-fluid">
        <div class="row" *ngIf="showVista">
            <div class="col-md-8">
                <form>
                    <div class="form-group">
                        <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio">
                        <!-- <button class="btn btn-primary" type="submit">
                            Buscar
                        </button> -->
                    </div>
                </form>
            </div>

            <div class="col-2">
                <button type="button" class="btn btn-secondary principalButton" id="boton_contra2" [routerLink]="['/formulario', servicios]" routerLinkActive="router-link-active" *ngIf="RoleUser == 'Admin' ">NUEVO</button>
            </div>
            <!-- <d
                iv class="col-2">
                <button type=" button " class="btn btn-primary separar" id="boton_contra1" [routerLink]="['/hoja-fram']" routerLinkActive="router-link-active">COTIZAR</button>
            </div> -->
            <app-tabla-service *ngIf="showTableAmbulanacia" [serviceSi]="serviceSi" [membresia]="membresia" [RoleUser]="RoleUser" [totalAmbulancia]="totalAmbulancia" [servicios]="servicios"></app-tabla-service>
            <!-- TERMINA LA TABLA DE LOS DEMAS SERVICIOS  -->
            <app-ambulancia *ngIf="showAmbulanacia" [ambulanciaSI]="ambulanciaSI" [showAmbulanacia2]="false"></app-ambulancia>
        </div>
    </div>
</div>



    <!-- <div class="col-md-3" *ngIf="showTableAmbulanacia">
                <div class="card" style="margin: 10px; height: 33em; border-radius: 24px;">

                    <div class="card-title" style="border-bottom-style: solid;">
                        <h4 style="text-align: center;">COTIZACIÓN</h4>
                    </div>

                    <div class="card-body" style="overflow: auto;">
                        <div class="row" *ngFor="let pedido of carrito.items; let i=index">
                            <div class="col-md-8">
                                <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia != true">
                                    {{ pedido.nombreEstudio }} : {{ pedido.precioSin}}
                                </p>
                                <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia">
                                    {{ pedido.nombreEstudio }} : {{ pedido.precioCon}}
                                </p>
                            </div>

                            <div class="col-md-2">
                                <button type="button" (click)="eliminar(i)" class="btn btn-link" style="color: red;">
                                    
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer" style="border-top-style: solid;">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 15px; font-weight: 800;" *ngIf="membresia != true">Total: &#36; {{carrito.totalSin}}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 15px; font-weight: 800;">Total membrecia: &#36; {{ carrito.totalCon}}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro"> Ahorro: &#36; {{carrito.totalSin - carrito.totalCon}}</h5>
                            </div>
                        </div>

                        <button type="button" class="btn btn-success btn-block btn-tarjeta" [routerLink]="['/pago/servicios']">
                            <i class="fas fa-hand-holding-usd"></i>
                                Pagar
                        </button>

                        <button class="btn btn-dark btn-tarjeta btn-block" [routerLink]="['/paciente']" routerLinkActive="router-link-active">
                            <i class="fas fa-user-plus"></i>
                                Nuevo
                        </button>

                        <button class="btn btn-block btn-info  btn-tarjeta">
                            <i class="fas fa-share-alt"></i>
                                Enviar
                        </button>
                    </div>
                </div>
            </div> -->


    <!-- <endoscopia-con *ngIf="paciente" [membresia]="membresia" [servicio]="servicios" [preciosMembresia]="serviceSi"></endoscopia-con> -->