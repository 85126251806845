import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { HttpClientModule } from "@angular/common/http"; //AQUI ESTA EL HTTPCLIENTMODULE

import { PacientesComponent } from './pacientes/pacientes/pacientes.component'
// import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from './page/page.component';
import { ConsutaGralComponent } from './consultas/consuta-gral/consuta-gral.component';

import {CdkAccordionModule} from '@angular/cdk/accordion';
import { PAGES_ROUTES } from './page.routes';
import { NgxPaginationModule } from 'ngx-pagination';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { HojaDiariaEnfGralComponent } from './enfermeria/hoja-diaria-enf-gral/hoja-diaria-enf-gral.component';
import { FichaEnfermeriaComponent } from './consultas/ficha-enfermeria/ficha-enfermeria.component';
import { HevolucionCGComponent } from './consultas/hevolucion-cg/hevolucion-cg.component';
import { RecetaComponent } from './consultas/receta/receta/receta.component';
import { ServiciosDashComponent } from './servicios/servicios-dash/servicios-dash.component';
import { ServiciosComponent } from './servicios/servicios/servicios.component';
import { FormularioComponent } from './servicios/formulario/formulario.component';
import { FormularioEditAmbComponent } from './servicios/formulario/formulario-edit-amb/formulario-edit-amb.component';
import { FormularioEditServiceComponent } from './servicios/formulario/formulario-edit-service/formulario-edit-service.component';
import { BitacoraConsultaGralComponent } from './consultas/doctores/bitacora-consulta-gral/bitacora-consulta-gral.component';
import { RegistroComponent } from './pacientes/registro/registro.component';
import { HojaEvolucionComponent } from './consultas/hojaEvolucion/hoja-evolucion/hoja-evolucion.component';
import { ConsultasComponent } from './consultas/bitacora/consultas/consultas.component';
import { FolioComponent } from './farmacia/folio/folio.component';
import { PagoComponent } from './servicios/pago/pago.component';
import { EstudiosPorAprobarComponent } from './consultas/estudios-por-aprobar/estudios-por-aprobar.component';
import { VerEstudiosComponent } from './consultas/ver-estudios/ver-estudios.component';
//  paquetes 
import { VerPaquetesComponent } from './paquetes/ver-paquetes/ver-paquetes.component';
import { PaqueteIDComponent } from '../pages/paquetes/paquete-id/paquete-id.component';
import { SolicitudComponent } from './paquetes/solicitud/solicitud.component';
import { VerPacienteComponent } from './pacientes/ver-paciente/ver-paciente.component';
import { PaquetePacienteComponent } from './pacientes/paquete-paciente/paquete-paciente.component';
import { ListadoMedicamentosComponent } from './farmacia/listado-medicamentos/listado-medicamentos.component';

//laboratorio
import { RegresosComponent } from './servicios/laboratorio/regresos/regresos.component';
import { CloroTinacoCisternaComponent } from './mantenimiento/cloro-tinaco-cisterna/cloro-tinaco-cisterna.component';
import { AnalisisBacteorologicoComponent } from './mantenimiento/analisis-bacteorologico/analisis-bacteorologico.component';
import { HojaServiciosComponent } from './servicios/laboratorio/hoja-servicios/hoja-servicios.component';
import { HojaReporteComponent } from './servicios/laboratorio/hoja-reporte/hoja-reporte.component';
import { NewStudioComponent } from './servicios/laboratorio/new-studio/new-studio.component';
import { GenerosPipe } from '../pipes/generos.pipe';
import { HistoricoEstudiosComponent } from './servicios/laboratorio/historico-estudios/historico-estudios.component';
// import { HistoricoEstudiosComponent } from './servicios/laboratorio/historico-estudios/historico-estudios.component';
import { ServicioComponent } from './admin/servicio/servicio.component';
import { ServicioPorUsuarioComponent } from './admin/servicio-por-usuario/servicio-por-usuario.component';
import { UtilidadServicioComponent } from './admin/utilidad-servicio/utilidad-servicio.component';
import { ServiciosPorClienteComponent } from './admin/servicios-clientes/servicios-por-cliente/servicios-por-cliente.component';
import { ExpedienteClinicoComponent } from './admin/expediente-clinico/expediente-clinico.component';
import { HojaConsumoAlDiaComponent } from './admin/hoja-consumo-al-dia/hoja-consumo-al-dia.component';
import { ComprasPorUtilidadComponent } from './admin/compras-por-utilidad/compras-por-utilidad.component';
import { AgregarServicioPorUtilidadComponent } from './admin/agregar-servicio-por-utilidad/agregar-servicio-por-utilidad.component';
import { ComponentsModule } from '../components/components.module';
import { BitacoraultraComponent } from './servicios/ultrasonido/bitacoraultra/bitacoraultra.component';
import { HojaUltrasonidoComponent } from './servicios/ultrasonido/hoja-ultrasonido/hoja-ultrasonido.component';
import { FormularioElementosComponent } from './servicios/ultrasonido/formulario-elementos/formulario-elementos.component';
import { HistoricoUltrasonidoComponent } from './servicios/ultrasonido/historico-ultrasonido/historico-ultrasonido.component';
import { DashboardResultadosComponent } from './servicios/recepcion-regresos/dashboard-resultados/dashboard-resultados.component';
import { EntregaDashComponent } from './recepcion/entrega-dash/entrega-dash.component';
import { RegresosLabComponent } from './servicios/laboratorio/regresos-lab/regresos-lab.component';
import { ResultadosFinalesComponent } from './servicios/recepcion-regresos/resultados-finales/resultados-finales.component';
import { SocioCanalComponent } from './admin/socio-canal/socio-canal.component';
import { DashComponent } from './admin/socio-canal/dashboard/dash/dash.component';
import { SedeComponent } from './admin/socio-canal/sede/sede.component';
import { FormularioCreadorComponent } from '../components/servicios/creador-servicios/formulario-creador/formulario-creador.component';
import { EntregaUltrasonidoComponent } from './servicios/recepcion-regresos/entrega-ultrasonido/entrega-ultrasonido.component';
import { ResultadosUltrasonidoComponent } from './servicios/recepcion-regresos/resultados-ultrasonido/resultados-ultrasonido.component';
import { ReporteUltrasonidoComponent } from './servicios/ultrasonido/reporteultra/reporte-ultrasonido/reporte-ultrasonido.component';
import { EditarServicioPorUtilidadComponent } from './admin/editar-servicio-por-utilidad/editar-servicio-por-utilidad.component';
import { BitacoraUltrasonidosComponent } from './servicios/recepcion-regresos/bitacora-ultrasonidos/bitacora-ultrasonidos.component';
import { BitacoraComponent } from './enfermeria/bitacora/bitacora.component';
import { ListadoDeSedesComponent } from './sedes/listado-de-sedes/listado-de-sedes.component';
import { DetailServiciosSedeComponent } from './sedes/detail-servicios-sede/detail-servicios-sede.component';
import { DashResultadosComponent } from './servicios/recepcion-regresos/dash-resultados/dash-resultados.component';
import { ServiciosRecepcionComponent } from './servicios/recepcion-regresos/servicios-recepcion/servicios-recepcion.component';

////////////////////////////////////////////////////////////////////////////////////////
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PedidosSedesSociosComponent } from './admin/pedidos-sedes-socios/pedidos-sedes-socios.component';
import { DetallePedidosSedesComponent } from './admin/detalle-pedidos-sedes/detalle-pedidos-sedes.component';
import { BitacoraRayosxComponent } from './servicios/recepcion-regresos/bitacora-rayosx/bitacora-rayosx.component';
import { EntregaRayosxComponent } from './servicios/recepcion-regresos/entrega-rayosx/entrega-rayosx.component';
import { ResultadosRayosxComponent } from './servicios/recepcion-regresos/resultados-rayosx/resultados-rayosx.component';
import { BitacoraHistoricoComponent } from './consultas/doctores/bitacora-historico/bitacora-historico.component';
import { ModalModule } from 'ng-modal-lib';
import { HojaEvolucionHistoricoComponent } from './consultas/hoja-evolucion-historico/hoja-evolucion-historico.component';
import { ReceptoresComponent } from './BancoSangre/rolRecepcion/receptores/receptores.component';
import { HojaServicioReceptorComponent } from './BancoSangre/rolRecepcion/receptores/hoja-servicio-receptor/hoja-servicio-receptor.component';
import { DocumentosReceptorComponent } from './BancoSangre/rolRecepcion/receptores/documentos-receptor/documentos-receptor.component';
import { EntregaProductosComponent } from './BancoSangre/rolRecepcion/receptores/entrega-productos/entrega-productos.component';
import { OrdenCompraComponent } from './BancoSangre/rolRecepcion/receptores/orden-compra/orden-compra.component';
import { TiparCruzarComponent } from './BancoSangre/rolLaboratorio/receptores/tipar-cruzar/tipar-cruzar/tipar-cruzar.component';
import { BitacoraDisponentesComponent } from './BancoSangre/rolRecepcion/disponentes/bitacora-disponentes/bitacora-disponentes.component';
import { RegistroDisponentesComponent } from './BancoSangre/rolRecepcion/disponentes/registro-disponentes/registro-disponentes.component';
import { VerDisponenteComponent } from './BancoSangre/rolRecepcion/disponentes/ver-disponente/ver-disponente.component';
import { AutoexclusionComponent } from './BancoSangre/rolRecepcion/disponentes/autoexclusion/autoexclusion.component';
import { BitacoraDiferidosComponent } from './BancoSangre/rolRecepcion/bitacora-diferidos/bitacora-diferidos.component';
import { BitacoraAlmacenComponent } from './BancoSangre/rolRecepcion/bitacora-almacen/bitacora-almacen.component';
import { DisponentesBitacoraComponent } from './BancoSangre/rolEnfermeria/disponentes-bitacora/disponentes-bitacora.component';
import { FichaDisponenteComponent } from './BancoSangre/rolEnfermeria/ficha-disponente/ficha-disponente.component';
import { BitacoraFlebotomiaComponent } from './BancoSangre/rolFlebotomia/bitacora-flebotomia/bitacora-flebotomia.component';
import { DocumentosDonanteFlebotomiaComponent } from './BancoSangre/rolFlebotomia/documentos-donante-flebotomia/documentos-donante-flebotomia.component';
import { BitacoraConsultaDisponentesComponent } from './BancoSangre/rolDoctor/bitacora-consulta-disponentes/bitacora-consulta-disponentes.component';
import { HistoriaClinicaComponent } from './BancoSangre/rolDoctor/historia-clinica/historia-clinica.component';
import { HojaEvolucionDisponenteComponent } from './BancoSangre/rolDoctor/hoja-evolucion-disponente/hoja-evolucion-disponente.component';
import { ReaccionesDerivadasComponent } from './BancoSangre/rolFlebotomia/reacciones-derivadas/reacciones-derivadas.component';
import { HojaServiciosLabDisponenteComponent } from './BancoSangre/rolLaboratorio/Disponente/hoja-servicios-lab-disponente/hoja-servicios-lab-disponente.component';
import { SerologiaLabDisponenteComponent } from './BancoSangre/rolLaboratorio/Disponente/serologia-lab-disponente/serologia-lab-disponente.component';
import { HistoricoEstudiosDisponenteComponent } from './BancoSangre/rolLaboratorio/Disponente/historico-estudios-disponente/historico-estudios-disponente.component';
import { NuevoEstudioLabComponent } from './BancoSangre/rolLaboratorio/Disponente/nuevo-estudio-lab/nuevo-estudio-lab.component';
import { AgregarSedeComponent } from './BancoSangre/rolJefeBS/Agregar_Sede/agregar-sede/agregar-sede.component';
import { FISedeComponent } from './BancoSangre/rolJefeBS/Ficha_Identificacion_Sede/fisede/fisede.component';
import { BitacoraNoAptosODiferidosComponent } from './BancoSangre/rolJefeBS/Bitacora_No_Aptos_Diferidos/bitacora-no-aptos-odiferidos/bitacora-no-aptos-odiferidos.component';
import { HojaServicioReceptorBSComponent } from './BancoSangre/rolJefeBS/Hoja_Servicio_Receptor/hoja-servicio-receptor/hoja-servicio-receptor.component';
import { ListaProductosAlmacenComponent } from './Almacen/Productos/listaDeProductos/lista-productos-almacen/lista-productos-almacen.component';
import { RegistroProductosAlmacenComponent } from './Almacen/Productos/registroProductos/registro-productos-almacen/registro-productos-almacen.component';
import { DetalleProductosAlmacenComponent } from './Almacen/Productos/detalle-productos-almacen/detalle-productos-almacen.component';
import { EditarProductosAlmacenComponent } from './Almacen/Productos/editarProductos/editar-productos-almacen/editar-productos-almacen.component';
import { BitacoraPedidosComponent } from './BancoSangre/rolLaboratorio/Disponentes/bitacora-pedidos/bitacora-pedidos.component';
import { BitacoraLaboratorioComponent } from './BancoSangre/rolLaboratorio/Disponente/bitacora-laboratorio/bitacora-laboratorio.component';
import { EditarComponent } from './servicios/laboratorio/editar/editar.component';
import { BuscarPacientePipe } from '../pipes/paciente/buscar-paciente.pipe';

import { ListaServiciosComponent } from './Almacen/Servicios/listaServicios/lista-servicios/lista-servicios.component';
import { RegistroServiciosComponent } from './Almacen/Servicios/registroServicios/registro-servicios/registro-servicios.component';
import { DetalleServiciosComponent } from './Almacen/Servicios/detalleServicios/detalle-servicios/detalle-servicios.component';
import { EditarServiciosComponent } from './Almacen/Servicios/editarServicios/editar-servicios/editar-servicios.component';
import { ListaMaterialesComponent } from './Almacen/Materiales/listaMateriales/lista-materiales/lista-materiales.component';
import { RegistroMaterialesComponent } from './Almacen/Materiales/registroMateriales/registro-materiales/registro-materiales.component';
import { DetalleMaterialesComponent } from './Almacen/Materiales/detalleMateriales/detalle-materiales/detalle-materiales.component';
import { EditarMaterialesComponent } from './Almacen/Materiales/editarMateriales/editar-materiales/editar-materiales.component';
import { ListadoSeguimientosAlmacenComponent } from './Almacen/Seguimientos/listado-seguimientos-almacen/listado-seguimientos-almacen.component';
import { CrearSeguimientosAlmacenComponent } from './Almacen/Seguimientos/crear-seguimientos-almacen/crear-seguimientos-almacen.component';
import { EditarSeguimientosAlmacenComponent } from './Almacen/Seguimientos/editar-seguimientos-almacen/editar-seguimientos-almacen.component';
import { DetalleSeguimientosAlmacenComponent } from './Almacen/Seguimientos/detalle-seguimientos-almacen/detalle-seguimientos-almacen.component';
import { DetalleProveedorComponent } from './Almacen/Proveedor/detalle-proveedor/detalle-proveedor.component';
import { ActualizarProveedorComponent } from './Almacen/Proveedor/actualizar-proveedor/actualizar-proveedor.component';
import { StockProductosAlamacenBSComponent } from './Almacen/Productos/stock-productos-alamacen-bs/stock-productos-alamacen-bs.component';
import { DetalleSedeComponent } from './BancoSangre/rolJefeBS/detalle-sede/detalle-sede.component';
import { HistoricoDisponenteBSComponent } from './BancoSangre/rolRecepcion/historico-disponente-bs/historico-disponente-bs.component';
import { SedesbsComponent } from './BancoSangre/rolJefeBS/sedesbs/sedesbs.component';
import { EntregaProductosSanguineosComponent } from './BancoSangre/rolRecepcion/entrega-productos-sanguineos/entrega-productos-sanguineos.component';
import { OrdenreceptoresComponent } from './BancoSangre/rolJefeBS/ordenreceptores/ordenreceptores.component';
import { AsignarModuloComponent } from './asignar-modulo/asignar-modulo.component';
import { ListadoProveedorComponent } from './Almacen/Proveedor/listado-proveedor/listado-proveedor.component';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { RegistroProveedoresBsComponent } from './Almacen/Proveedor/registro-proveedores-bs/registro-proveedores-bs.component';
import { EmpleadoComponent } from './empleado/empleado.component';
import { CatalogoProductosComponent } from './Almacen/Seguimientos/catalogo-productos/catalogo-productos.component';
import { SurtimientoAlmacenComponent } from './Almacen/Seguimientos/surtimiento-almacen/surtimiento-almacen.component';
import { PasswordComponent } from './password/password.component';
import { JefaEnfermeriaComponent } from './enfermeria/jefa-enfermeria/jefa-enfermeria.component';
import { ActualizarConsultasEnfermeriaComponent } from './enfermeria/actualizar-consultas-enfermeria/actualizar-consultas-enfermeria.component';
import { CarritoAlmacenComponent } from './Almacen/carrito-almacen/carrito-almacen.component';
import { PagosAlmacenComponent } from './Almacen/pagos/pagos-almacen/pagos-almacen.component';
import { PagoFinalComponent } from './Almacen/pagos/pago-final/pago-final.component';
import { EndoscopiasComponent } from './endoscopias/bitacora/endoscopias.component';
import { ReporteEndoscopiasComponent } from './endoscopias/reporte/reporte-endoscopias/reporte-endoscopias.component';
import { ServicioEndoscopiasComponent } from './endoscopias/servicio-endoscopias/servicio-endoscopias.component';
import { BitacoraPatologiaComponent } from './Almacen/Patologia/bitacora-patologia/bitacora-patologia.component';
import { HojaDeReportePatologiaComponent } from './Almacen/Patologia/hoja-de-reporte-patologia/hoja-de-reporte-patologia.component';
import { HojaDeServiciosPatologiaComponent } from './Almacen/Patologia/hoja-de-servicios-patologia/hoja-de-servicios-patologia.component';
import { BitacoraPacientesComponent } from './Almacen/General-pacientes/bitacora-pacientes/bitacora-pacientes.component';
import { NuevoEstudioComponent } from './servicios/Almacen/Patologia/nuevosEstudios/nuevo-estudio/nuevo-estudio.component';
import { HojaReportePatologiaComponent } from './Almacen/Patologia/hojaReporte/hoja-reporte-patologia/hoja-reporte-patologia.component';
import { EntregaPatologiaComponent } from './recpecion-regresos/entrega-patologia/entrega-patologia/entrega-patologia.component';
import { ActualizarFiPacienteComponent } from './Almacen/General-pacientes/actualizar-fi-paciente/actualizar-fi-paciente.component';
import { VentasCanceladasComponent } from './recepcion/ventas/ventas-canceladas/ventas-canceladas.component';
import { RegistroSedeComponent } from './pacientes/registro-sede/registro-sede.component';
import { RectasBitacoraComponent } from './consultas/doctores/recetas/rectas-bitacora/rectas-bitacora.component';
import { RectasDetalleComponent } from './consultas/doctores/recetas/rectas-detalle/rectas-detalle.component';
import { RegistrarPatologiaComponent } from './Almacen/Patologia/registrar-patologia/registrar-patologia.component';
import { RegistroEndoscopiasComponent } from './endoscopias/registro-endoscopias/registro-endoscopias.component';
import { ComisionSociosComponent } from './socios/comision-socios/comision-socios.component';
import { UtilidadesSedesComponent } from './utilidades/utilidades-sedes/utilidades-sedes.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { EditaLaboratoriosComponent } from './laboratorio/edita-laboratorios/edita-laboratorios.component';
import { PacienteEstudiosLabComponent } from './servicios/laboratorio/paciente-estudios-lab/paciente-estudios-lab.component';
import { BitacoraEndoscopiaComponent } from './servicios/recepcion-regresos/bitacora-endoscopia/bitacora-endoscopia.component';
import { BitacoraPatologiasComponent } from './servicios/recepcion-regresos/bitacora-patologias/bitacora-patologias.component';
import { OtrosEstudiosComponent } from './servicios/recepcion-regresos/otros-estudios/otros-estudios.component';
import { NotaIngresoComponent } from './Hospitalizacion/nota-ingreso/nota-ingreso.component';
import { NotaEgresoComponent } from './Hospitalizacion/nota-egreso/nota-egreso.component';
import { HojaIndicacionesComponent } from './Hospitalizacion/hoja-indicaciones/hoja-indicaciones.component';
import { BitacoraHospitalizacionComponent } from './Hospitalizacion/bitacora-hospitalizacion/bitacora-hospitalizacion.component';
import { HabitacionesPacientesComponent } from './Hospitalizacion/habitaciones-pacientes/habitaciones-pacientes.component';
import { VerHospitalizadoComponent } from './Hospitalizacion/ver-hospitalizado/ver-hospitalizado.component';

import { AgendaComponent } from './agenda/agenda.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import  dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
// IMPORTS DE CALENDAR 
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';
import { DocumentosReceptorFirmadosComponent } from './BancoSangre/rolRecepcion/documentos-receptor-firmados/documentos-receptor-firmados.component';
import { BitacoraSalidasComponent } from './BancoSangre/Almacen/salidas/bitacora-salidas/bitacora-salidas.component';
import { CargarOtrosEstudiosComponent } from './servicios/recepcion-regresos/otros-estudios/cargar-otros-estudios/cargar-otros-estudios.component';
import { EstudiosIdComponent } from './pacientes/estudios-id/estudios-id.component';
import { AgendaPersonalComponent } from './agenda/agenda-personal/agenda-personal.component';
import { AgendaHorizonteComponent } from './agenda/agenda-horizonte/agenda-horizonte.component';

import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';


FullCalendarModule.registerPlugins([
  listPlugin,
  timeGridPlugin,
  interactionPlugin,
  dayGridPlugin
])

//import localeEs from '@angular/common/locales/es-MX';
//import { registerLocaleData } from '@angular/common';
//registerLocaleData(localeEs, 'es-MX') 

@NgModule({
  declarations: [
    AgendaComponent,
    GenerosPipe,
    BuscarPacientePipe,    
    PageComponent,
    PacientesComponent,
    ConsutaGralComponent,
    HojaDiariaEnfGralComponent,
    FichaEnfermeriaComponent,
    HevolucionCGComponent,
    RecetaComponent,
    ServiciosDashComponent,
    ServiciosComponent,
    FormularioComponent,
    FormularioEditAmbComponent,
    FormularioEditServiceComponent,
    //RecetaComponent,
    BitacoraConsultaGralComponent,
    RegistroComponent,
    HojaEvolucionComponent,
    ConsultasComponent,
    FolioComponent,
    PagoComponent,
    EstudiosPorAprobarComponent,

    ResultadosRayosxComponent,
    EntregaRayosxComponent,
    BitacoraHistoricoComponent,
    VerEstudiosComponent,
    VerPaquetesComponent,
    PaqueteIDComponent,
    SolicitudComponent,
    VerPacienteComponent,
    PaquetePacienteComponent,
    ListadoMedicamentosComponent,
    RegresosComponent,
    CloroTinacoCisternaComponent,
    AnalisisBacteorologicoComponent,
    HojaServiciosComponent,
    HojaReporteComponent,
    NewStudioComponent,
    HistoricoEstudiosComponent,
    AnalisisBacteorologicoComponent,
    ServicioComponent,
    ServicioPorUsuarioComponent,
    ServiciosPorClienteComponent,
    ExpedienteClinicoComponent,
    HojaConsumoAlDiaComponent,
    ComprasPorUtilidadComponent,
    AgregarServicioPorUtilidadComponent,
    UtilidadServicioComponent,
    HistoricoUltrasonidoComponent,
    BitacoraultraComponent,
    HojaUltrasonidoComponent,
    FormularioElementosComponent,
    DashboardResultadosComponent,
    EntregaDashComponent,
    RegresosLabComponent,
    ResultadosFinalesComponent,
    SocioCanalComponent,
    DashComponent,
    SedeComponent,
    FormularioCreadorComponent,
    EntregaUltrasonidoComponent,
    ResultadosUltrasonidoComponent,
    ReporteUltrasonidoComponent,
    EditarServicioPorUtilidadComponent,
    BitacoraUltrasonidosComponent,
    BitacoraComponent,
    ListadoDeSedesComponent,
    DetailServiciosSedeComponent,
    DashResultadosComponent,
    ServiciosRecepcionComponent,
    PedidosSedesSociosComponent,
    DetallePedidosSedesComponent,
    HojaEvolucionHistoricoComponent,
    ReceptoresComponent,
    HojaServicioReceptorComponent,
    DocumentosReceptorComponent,
    EntregaProductosComponent,
    OrdenCompraComponent,
    TiparCruzarComponent,
    BitacoraDisponentesComponent,
    RegistroDisponentesComponent,
    VerDisponenteComponent,
    AutoexclusionComponent,
    BitacoraDiferidosComponent,
    BitacoraAlmacenComponent,
    DisponentesBitacoraComponent,
    FichaDisponenteComponent,
    BitacoraFlebotomiaComponent,
    DocumentosDonanteFlebotomiaComponent,
    BitacoraConsultaDisponentesComponent,
    HistoriaClinicaComponent,
    HojaEvolucionDisponenteComponent,
    ReaccionesDerivadasComponent,
    HojaServiciosLabDisponenteComponent,
    SerologiaLabDisponenteComponent,
    HistoricoEstudiosDisponenteComponent,
    NuevoEstudioLabComponent,
    AgregarSedeComponent,
    FISedeComponent,
    BitacoraNoAptosODiferidosComponent,
    HojaServicioReceptorBSComponent,
    ListaProductosAlmacenComponent,
    BitacoraLaboratorioComponent,
    RegistroProductosAlmacenComponent,
    DetalleProductosAlmacenComponent,
    EditarProductosAlmacenComponent,
    BitacoraPedidosComponent,
    ListaServiciosComponent,
    RegistroServiciosComponent,
    DetalleServiciosComponent,
    EditarServiciosComponent,
    ListaMaterialesComponent,
    RegistroMaterialesComponent,
    DetalleMaterialesComponent,
    EditarMaterialesComponent,
    ListadoSeguimientosAlmacenComponent,
    CrearSeguimientosAlmacenComponent,
    EditarSeguimientosAlmacenComponent,
    DetalleSeguimientosAlmacenComponent,
    DetalleProveedorComponent,
    ActualizarProveedorComponent,
    StockProductosAlamacenBSComponent,
    DetalleSedeComponent,
    HistoricoDisponenteBSComponent,
    EntregaProductosSanguineosComponent,
    SedesbsComponent,
    OrdenreceptoresComponent,
    ListadoProveedorComponent,
    AsignarModuloComponent,
    RegistroProveedoresBsComponent,
    EmpleadoComponent,
    CatalogoProductosComponent,
    SurtimientoAlmacenComponent,

    EditarComponent,

    PasswordComponent,

    JefaEnfermeriaComponent,

    ActualizarConsultasEnfermeriaComponent,
      CarritoAlmacenComponent,
      PagosAlmacenComponent,
      PagoFinalComponent,
      EndoscopiasComponent,
      ReporteEndoscopiasComponent,
      ServicioEndoscopiasComponent,
      BitacoraPatologiaComponent,
      HojaDeReportePatologiaComponent,
      HojaDeServiciosPatologiaComponent,
      BitacoraPacientesComponent,

      NuevoEstudioComponent,
      HojaReportePatologiaComponent,
      EntregaPatologiaComponent,

      ActualizarFiPacienteComponent,
      NuevoEstudioComponent,
      HojaReportePatologiaComponent,
      EntregaPatologiaComponent,
      VentasCanceladasComponent,
      RegistroSedeComponent,
      RectasBitacoraComponent,
      RectasDetalleComponent,
      RegistrarPatologiaComponent,
      RegistroEndoscopiasComponent,
      ComisionSociosComponent,
      UtilidadesSedesComponent,
      EditaLaboratoriosComponent,
      PacienteEstudiosLabComponent,
      BitacoraEndoscopiaComponent,
      BitacoraPatologiasComponent,
      BitacoraRayosxComponent,
      OtrosEstudiosComponent,
      NotaIngresoComponent,
      NotaEgresoComponent,
      HojaIndicacionesComponent,
      BitacoraHospitalizacionComponent,
      HabitacionesPacientesComponent,
      VerHospitalizadoComponent,
      DocumentosReceptorFirmadosComponent,
      BitacoraSalidasComponent,
      CargarOtrosEstudiosComponent,
      EstudiosIdComponent,
      AgendaPersonalComponent,
      AgendaHorizonteComponent,

  ],

  exports: [
    MatCardModule,
    PageComponent,
    ReactiveFormsModule,
    MatStepperModule,
    CdkStepperModule,
    NgStepperModule,
  ],
  imports: [
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    CdkAccordionModule,
    FullCalendarModule,
    BrowserModule,
    ComponentsModule,
    FormsModule,
    RouterModule,
    ChartsModule, 
    PAGES_ROUTES,
    ReactiveFormsModule,
    NgxPaginationModule,
    PdfViewerModule,
    CommonModule,
    NgxDropzoneModule,
    ModalModule,
    //AQUI IMPORTE EL CLIENT MODULE
    HttpClientModule,
    MatStepperModule,
    CdkStepperModule,
    NgStepperModule,
    NgxSpinnerModule,
    NgxMultipleDatesModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  providers:[]
})

export class PageModule { 
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon(
      'github',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/github.svg')
    );
  }
}
