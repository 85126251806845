<section class="container-fluid" style="height: 40em;">

    <h1 class="centerxd" id="centerxd">Editar</h1>

    <div class="card" id="card">
        <div class="container">
            <form #servicioForm="ngForm" (ngSumbit)="onSubmit()">
                <div class="form-group">
                    <label for="ESTUDIO" class="col-form-label">Estudio:</label>
                    <input type="text" name="ESTUDIO" #estudio="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.ESTUDIO" [value]="serviceEdit.ESTUDIO" required>
                </div>

                <div class="form-row">
                    <div class="col">
                        <label class="col-form-label" *ngIf="nombre == 'patologia' || nombre == 'xray' || nombre == 'ultrasonido'"> Precio público día</label>
                        <label for="PRECIO_PUBLICO" class="col-form-label" *ngIf="nombre == 'endoscopia' || nombre == 'tomografia' || nombre == 'resonancia'">Precio público:</label>
                        <input type="text" name="PRECIO_PUBLICO" #pPub="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_PUBLICO">
                    </div>
                    <div class="col">
                        <label class="col-form-label" *ngIf="nombre == 'patologia' || nombre == 'xray' || nombre == 'ultrasonido'"> Precio membresia día</label>
                        <label for="PRECIO_MEMBRESIA" class="col-form-label" *ngIf="nombre == 'endoscopia' || nombre == 'tomografia' || nombre == 'resonancia'"> Precio membresia:</label>
                        <input type="text" name="PRECIO_MEMBRESIA" #pMem="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_URGENCIA" class="col-form-label">Precio público urgencia:</label>
                        <input type="text" name="PRECIO_PUBLICO_URGENCIA" #pPUrg="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_URGENCIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_URGENCIA" class="col-form-label">Precio membresia urgencia:</label>
                        <input type="text" name="PRECIO_MEMBRESIA_URGENCIA" #pUMem="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_URGENCIA">
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_HOSPITALIZACION" class="col-form-label">Precio público hospitalización:</label>
                        <input type="text" name="PRECIO_PUBLICO_HOSPITALIZACION" #pPHosp="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_HOSPITALIZACION">
                    </div>
                    <div class="col">
                        <label for="PRECIO_MEMBRESIA_HOSPITALIZACION" class="col-form-label">Precio membresia hospitalización:</label>
                        <input type="text" name="PRECIO_MEMBRESIA_HOSPITALIZACION" #pMHosp="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_HOSPITALIZACION">
                    </div>
                </div>

                <div class="form-row" *ngIf="nombre == 'ultrasonido' || nombre == 'tomografia' || nombre == 'resonancia'">
                    <div class="col">
                        <label for="PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA" class="col-form-label">Precio público hospitalización urgencia:</label>
                        <input type="text" name="PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA" #pPHUrg="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA">
                    </div>
                    <div class="col">
                        <label for="PRECIO_HOSPITALIZACION_URGENCIA_MEMBRESIA" class="col-form-label">Precio membresia hospitalización urgencia:</label>
                        <input type="text" name="PRECIO_HOSPITALIZACION_URGENCIA_MEMBRESIA" #pHUMem="ngModel" class="form-control" id="recipient-name" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA">
                    </div>
                </div>

                <div class="form-row" style="margin: 2rem 0rem 2rem 25rem">
                    <div class="col-12">
                        <button type="button" class="btn btn-secondary" style="border-radius: 30px; width: 20%; margin-right: 1em;" data-dismiss="modal" (click)="cancelar()">Cancelar</button>
                        <!-- <button type="button" class="btn btn-primary" (click)="onSubmit()">Aceptar</button> -->
                        <input type="submit" value="Guardar" class="btn btn-primary" style="border-radius: 30px; width: 20%;" (click)="onSubmit()">
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>