<section>
    <app-fichas-identificacion [data]="paciente" [rol]="'recepcion'"></app-fichas-identificacion>
    <!-- <app-ficha-info-censur [pacienteInfo]="paciente"></app-ficha-info-censur> -->
</section>

<div class="row">
    <div class="col-md-12 col-lg-12">
        <div class="card">
            <div class="card-heder header">
                <div class="row">
                    <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                        <p>No.</p>
                    </div>
                    <div class="col text_header">
                        <p>Fecha</p>
                    </div>
                    <div class="col text_header">
                        <p>Tiempo de espera</p>
                    </div>
                    <div class="col text_header">
                        <p>Tipo de donador</p>
                    </div>
                    <div class="col text_header">
                        <p>Producto</p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row" *ngFor="let item of donaciones;let i = index">
                    <div class="col-md-1" style="text-align: left;">
                        <p>
                            {{i+1}}
                        </p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>
                            {{item.fecha | date : 'dd/MM/yyyy':'UTC'}}
                        </p>
                    </div>
                    <div class="col-md" style="text-align: center;">
                        <p>
                            {{item.tiempo_rechazo}}
                        </p>
                    </div>
                    <div class="col-md" style="text-align: center;">
                        <p>
                            {{item.flebotomia.tipo_de_donador}}
                        </p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>
                            {{item.flebotomia.tipo_de_sangreado}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>