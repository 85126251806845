import { Routes, RouterModule } from '@angular/router';
// components
import { PageComponent } from './page/page.component';
import { PacientesComponent } from './pacientes/pacientes/pacientes.component';
import { ConsutaGralComponent } from './consultas/consuta-gral/consuta-gral.component';
import { RegistroComponent } from './pacientes/registro/registro.component';
import { HojaDiariaEnfGralComponent } from './enfermeria/hoja-diaria-enf-gral/hoja-diaria-enf-gral.component';
import { FichaEnfermeriaComponent } from './consultas/ficha-enfermeria/ficha-enfermeria.component';
import { HevolucionCGComponent } from './consultas/hevolucion-cg/hevolucion-cg.component';
import { RecetaComponent } from './consultas/receta/receta/receta.component';
import { ServiciosDashComponent } from './servicios/servicios-dash/servicios-dash.component';
import { ServiciosComponent } from './servicios/servicios/servicios.component';
import { FormularioComponent } from './servicios/formulario/formulario.component';
import { FormularioEditAmbComponent } from './servicios/formulario/formulario-edit-amb/formulario-edit-amb.component';
import { FormularioEditServiceComponent } from './servicios/formulario/formulario-edit-service/formulario-edit-service.component';
import { ListadoMedicamentosComponent } from './farmacia/listado-medicamentos/listado-medicamentos.component'
import { ServicioComponent } from './admin/servicio/servicio.component';
import { UtilidadServicioComponent } from './admin/utilidad-servicio/utilidad-servicio.component';
import { SocioCanalComponent } from './admin/socio-canal/socio-canal.component';

//  LABORATORIO REGRESOS

import { RegresosComponent } from './servicios/laboratorio/regresos/regresos.component';
import { HojaServiciosComponent } from './servicios/laboratorio/hoja-servicios/hoja-servicios.component';
import { NewStudioComponent } from './servicios/laboratorio/new-studio/new-studio.component';
import { HojaReporteComponent } from './servicios/laboratorio/hoja-reporte/hoja-reporte.component';
import { HistoricoEstudiosComponent } from './servicios/laboratorio/historico-estudios/historico-estudios.component';


// ##################################  ULTRSONIDO REGRESOS  ###################################

import { BitacoraultraComponent } from './servicios/ultrasonido/bitacoraultra/bitacoraultra.component';
import { HojaUltrasonidoComponent } from './servicios/ultrasonido/hoja-ultrasonido/hoja-ultrasonido.component';
import { FormularioElementosComponent } from './servicios/ultrasonido/formulario-elementos/formulario-elementos.component';
import { HistoricoUltrasonidoComponent } from './servicios/ultrasonido/historico-ultrasonido/historico-ultrasonido.component';
import { ReporteUltrasonidoComponent } from './servicios/ultrasonido/reporteultra/reporte-ultrasonido/reporte-ultrasonido.component';

import { EntregaUltrasonidoComponent } from './servicios/recepcion-regresos/entrega-ultrasonido/entrega-ultrasonido.component';
import { ResultadosUltrasonidoComponent } from './servicios/recepcion-regresos/resultados-ultrasonido/resultados-ultrasonido.component';
import { BitacoraUltrasonidosComponent } from './servicios/recepcion-regresos/bitacora-ultrasonidos/bitacora-ultrasonidos.component';


//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% RECEPCION REGRESOS RESULTAODS DE ESTUDIOS %%%%%%%%%%%%%%%%%%%%%%%%%%%%5
import { DashboardResultadosComponent } from './servicios/recepcion-regresos/dashboard-resultados/dashboard-resultados.component';
import { ResultadosFinalesComponent } from './servicios/recepcion-regresos/resultados-finales/resultados-finales.component';
import { DashResultadosComponent } from './servicios/recepcion-regresos/dash-resultados/dash-resultados.component';
import { ServiciosRecepcionComponent } from './servicios/recepcion-regresos/servicios-recepcion/servicios-recepcion.component';

import { BitacoraRayosxComponent } from './servicios/recepcion-regresos/bitacora-rayosx/bitacora-rayosx.component';
import { EntregaRayosxComponent } from './servicios/recepcion-regresos/entrega-rayosx/entrega-rayosx.component';
import { ResultadosRayosxComponent } from './servicios/recepcion-regresos/resultados-rayosx/resultados-rayosx.component';
import { PacienteEstudiosLabComponent } from './servicios/laboratorio/paciente-estudios-lab/paciente-estudios-lab.component';

// COMPONENT DEL CALENDARIO
import { AgendaComponent } from './agenda/agenda.component';


// MANTENIMIENTO
import { CloroTinacoCisternaComponent } from './mantenimiento/cloro-tinaco-cisterna/cloro-tinaco-cisterna.component';
import { AnalisisBacteorologicoComponent } from './mantenimiento/analisis-bacteorologico/analisis-bacteorologico.component';



// GUARDS

import { RecpecionRolGuard } from '../gurads/recepcion/recpecion-rol.guard'
import { BitacoraConsultaGralComponent } from './consultas/doctores/bitacora-consulta-gral/bitacora-consulta-gral.component';
import { EnfermeriaGuard } from '../gurads/enfermeria/enfermeria.guard';
import { HojaEvolucionComponent } from './consultas/hojaEvolucion/hoja-evolucion/hoja-evolucion.component';
import { ConsultasComponent } from './consultas/bitacora/consultas/consultas.component';
import { FolioComponent } from './farmacia/folio/folio.component';


import { MedicosGuard } from '../gurads/medicos/medicos.guard'
import { FarmaciaRolGuard } from '../gurads/farmacia/farmacia-rol.guard';
import { PagoComponent } from './servicios/pago/pago.component';
import { VerPacienteComponent } from './pacientes/ver-paciente/ver-paciente.component';
import { EstudiosPorAprobarComponent } from './consultas/estudios-por-aprobar/estudios-por-aprobar.component';
import { VerEstudiosComponent } from './consultas/ver-estudios/ver-estudios.component';
import { VerPaquetesComponent } from './paquetes/ver-paquetes/ver-paquetes.component';
import { PaqueteIDComponent } from './paquetes/paquete-id/paquete-id.component';
import { SolicitudComponent } from './paquetes/solicitud/solicitud.component';
import { PaquetePacienteComponent } from './pacientes/paquete-paciente/paquete-paciente.component';
import { ServicioPorUsuarioComponent } from './admin/servicio-por-usuario/servicio-por-usuario.component';
import { ServiciosPorClienteComponent } from './admin/servicios-clientes/servicios-por-cliente/servicios-por-cliente.component';
import { AgregarServicioPorUtilidadComponent } from './admin/agregar-servicio-por-utilidad/agregar-servicio-por-utilidad.component';
import { ComprasPorUtilidadComponent } from './admin/compras-por-utilidad/compras-por-utilidad.component';
import { ExpedienteClinicoComponent } from './admin/expediente-clinico/expediente-clinico.component';
import { HojaConsumoAlDiaComponent } from './admin/hoja-consumo-al-dia/hoja-consumo-al-dia.component';
import { EntregaDashComponent } from './recepcion/entrega-dash/entrega-dash.component';
import { RegresosLabComponent } from './servicios/laboratorio/regresos-lab/regresos-lab.component';
import { DashComponent } from './admin/socio-canal/dashboard/dash/dash.component';
import { SedeComponent } from './admin/socio-canal/sede/sede.component';
import { FormularioCreadorComponent } from '../components/servicios/creador-servicios/formulario-creador/formulario-creador.component'
import { EditarServicioPorUtilidadComponent } from './admin/editar-servicio-por-utilidad/editar-servicio-por-utilidad.component';
import { BitacoraComponent } from './enfermeria/bitacora/bitacora.component';
import { DetailServiciosSedeComponent } from './sedes/detail-servicios-sede/detail-servicios-sede.component';
import { PedidosSociosComponent } from './admin/pedidosSocios/pedidos-socios/pedidos-socios.component';
import { ListadoDeSedesComponent } from './sedes/listado-de-sedes/listado-de-sedes.component';
import { PedidosSedesSociosComponent } from './admin/pedidos-sedes-socios/pedidos-sedes-socios.component';
import { DetallePedidosSedesComponent } from './admin/detalle-pedidos-sedes/detalle-pedidos-sedes.component';
import { BitacoraHistoricoComponent  } from './consultas/doctores/bitacora-historico/bitacora-historico.component';
import { PagosPaquetesComponent } from '../components/paquetes/pagos-paquetes/pagos-paquetes.component';
import { HojaEvolucionHistoricoComponent } from './consultas/hoja-evolucion-historico/hoja-evolucion-historico.component';

//Banco de sangre receptores
import { ReceptoresComponent } from './BancoSangre/rolRecepcion/receptores/receptores.component';
import { HojaServicioReceptorComponent } from './BancoSangre/rolRecepcion/receptores/hoja-servicio-receptor/hoja-servicio-receptor.component';
import { DocumentosReceptorComponent } from './BancoSangre/rolRecepcion/receptores/documentos-receptor/documentos-receptor.component';
import { EntregaProductosComponent } from './BancoSangre/rolRecepcion/receptores/entrega-productos/entrega-productos.component';
import { OrdenCompraComponent } from './BancoSangre/rolRecepcion/receptores/orden-compra/orden-compra.component';
import { TiparCruzarComponent } from './BancoSangre/rolLaboratorio/receptores/tipar-cruzar/tipar-cruzar/tipar-cruzar.component';
//Banco de sangre disponentes recepcion
import { BitacoraDisponentesComponent } from './BancoSangre/rolRecepcion/disponentes/bitacora-disponentes/bitacora-disponentes.component';
import { RegistroDisponentesComponent } from './BancoSangre/rolRecepcion/disponentes/registro-disponentes/registro-disponentes.component';
import { VerDisponenteComponent } from './BancoSangre/rolRecepcion/disponentes/ver-disponente/ver-disponente.component';
import { AutoexclusionComponent } from './BancoSangre/rolRecepcion/disponentes/autoexclusion/autoexclusion.component';
//bitacora almacen y diferidos
import { BitacoraAlmacenComponent } from './BancoSangre/rolRecepcion/bitacora-almacen/bitacora-almacen.component';
import { BitacoraDiferidosComponent } from './BancoSangre/rolRecepcion/bitacora-diferidos/bitacora-diferidos.component';
//Banco de sangre disponentes enfermeria
import { DisponentesBitacoraComponent } from './BancoSangre/rolEnfermeria/disponentes-bitacora/disponentes-bitacora.component';
import { FichaDisponenteComponent } from './BancoSangre/rolEnfermeria/ficha-disponente/ficha-disponente.component'
//Banco de sangre disponentes flebotomia
import { BitacoraFlebotomiaComponent } from './BancoSangre/rolFlebotomia/bitacora-flebotomia/bitacora-flebotomia.component';
import { DocumentosDonanteFlebotomiaComponent } from './BancoSangre/rolFlebotomia/documentos-donante-flebotomia/documentos-donante-flebotomia.component';
import { ReaccionesDerivadasComponent } from './BancoSangre/rolFlebotomia/reacciones-derivadas/reacciones-derivadas.component'
//Banco de sangre disponentes doctor
import { BitacoraConsultaDisponentesComponent } from './BancoSangre/rolDoctor/bitacora-consulta-disponentes/bitacora-consulta-disponentes.component';
import { HistoriaClinicaComponent } from './BancoSangre/rolDoctor/historia-clinica/historia-clinica.component';
import { HojaEvolucionDisponenteComponent } from './BancoSangre/rolDoctor/hoja-evolucion-disponente/hoja-evolucion-disponente.component';

//Laboratorios Disponentes

import { HojaServiciosLabsComponent } from './BancoSangre/rolLaboratorio/Disponente/hoja-servicios/hoja-servicios.component';
import { HojaServiciosLabDisponenteComponent } from './BancoSangre/rolLaboratorio/Disponente/hoja-servicios-lab-disponente/hoja-servicios-lab-disponente.component';
import { BiometriaLabDisponenteComponent } from '../components/biometria-lab-disponente/biometria-lab-disponente.component';
import { SerologiaLabDisponenteComponent } from './BancoSangre/rolLaboratorio/Disponente/serologia-lab-disponente/serologia-lab-disponente.component';
import { HistoricoEstudiosDisponenteComponent } from './BancoSangre/rolLaboratorio/Disponente/historico-estudios-disponente/historico-estudios-disponente.component'
import { NuevoEstudioLabComponent } from './BancoSangre/rolLaboratorio/Disponente/nuevo-estudio-lab/nuevo-estudio-lab.component';

import { AgregarSedeComponent } from './BancoSangre/rolJefeBS/Agregar_Sede/agregar-sede/agregar-sede.component';
import { BitacoraNoAptosODiferidosComponent } from './BancoSangre/rolJefeBS/Bitacora_No_Aptos_Diferidos/bitacora-no-aptos-odiferidos/bitacora-no-aptos-odiferidos.component';
import { FISedeComponent } from './BancoSangre/rolJefeBS/Ficha_Identificacion_Sede/fisede/fisede.component';
import { HojaServicioReceptorBSComponent } from './BancoSangre/rolJefeBS/Hoja_Servicio_Receptor/hoja-servicio-receptor/hoja-servicio-receptor.component';
import { ListaProductosAlmacenComponent } from './Almacen/Productos/listaDeProductos/lista-productos-almacen/lista-productos-almacen.component';

import { RegistroProductosAlmacenComponent } from './Almacen/Productos/registroProductos/registro-productos-almacen/registro-productos-almacen.component';
import { DetalleProductosAlmacenComponent } from './Almacen/Productos/detalle-productos-almacen/detalle-productos-almacen.component';
import { EditarProductosAlmacenComponent } from './Almacen/Productos/editarProductos/editar-productos-almacen/editar-productos-almacen.component';
import { BitacoraPedidosComponent } from './BancoSangre/rolLaboratorio/Disponentes/bitacora-pedidos/bitacora-pedidos.component';
import { BitacoraLaboratorioComponent } from './BancoSangre/rolLaboratorio/Disponente/bitacora-laboratorio/bitacora-laboratorio.component';
import { ListadoSeguimientosAlmacenComponent } from './Almacen/Seguimientos/listado-seguimientos-almacen/listado-seguimientos-almacen.component';
import { CrearSeguimientosAlmacenComponent } from './Almacen/Seguimientos/crear-seguimientos-almacen/crear-seguimientos-almacen.component';
import { DetalleSeguimientosAlmacenComponent } from './Almacen/Seguimientos/detalle-seguimientos-almacen/detalle-seguimientos-almacen.component';
import { EditarSeguimientosAlmacenComponent } from './Almacen/Seguimientos/editar-seguimientos-almacen/editar-seguimientos-almacen.component';
import { DetalleProveedorComponent } from './Almacen/Proveedor/detalle-proveedor/detalle-proveedor.component';
//import { RegistroProveedorComponent } from './Almacen/Proveedor/registro-proveedor/registro-proveedor.component';
import { ActualizarProveedorComponent } from './Almacen/Proveedor/actualizar-proveedor/actualizar-proveedor.component';
import { StockProductosAlamacenBSComponent } from './Almacen/Productos/stock-productos-alamacen-bs/stock-productos-alamacen-bs.component';
import { HistoricoDisponenteBSComponent } from './BancoSangre/rolRecepcion/historico-disponente-bs/historico-disponente-bs.component';
import { DetalleSedeComponent } from './BancoSangre/rolJefeBS/detalle-sede/detalle-sede.component';
import { SedesbsComponent } from './BancoSangre/rolJefeBS/sedesbs/sedesbs.component';
import { EntregaProductosSanguineosComponent } from './BancoSangre/rolRecepcion/entrega-productos-sanguineos/entrega-productos-sanguineos.component';
import { OrdenreceptoresComponent } from './BancoSangre/rolJefeBS/ordenreceptores/ordenreceptores.component';
import { ListadoProveedorComponent } from './Almacen/Proveedor/listado-proveedor/listado-proveedor.component';
import { AsignarModuloComponent } from './asignar-modulo/asignar-modulo.component';
import { RegistroServiciosComponent } from './Almacen/Servicios/registroServicios/registro-servicios/registro-servicios.component';
import { DetalleServiciosComponent } from './Almacen/Servicios/detalleServicios/detalle-servicios/detalle-servicios.component';
import  { EditarComponent } from './servicios/laboratorio/editar/editar.component';
//IMPORTACION DEL DE STEPPER PARA PRUEBAS
//import { StepperComponent } from "../components/registro/stepper/stepper.component";
import { DetalleMaterialesComponent } from './Almacen/Materiales/detalleMateriales/detalle-materiales/detalle-materiales.component';
import { ListaMaterialesComponent } from './Almacen/Materiales/listaMateriales/lista-materiales/lista-materiales.component';
import { RegistroMaterialesComponent } from './Almacen/Materiales/registroMateriales/registro-materiales/registro-materiales.component';
import { RegistroProveedoresBsComponent } from './Almacen/Proveedor/registro-proveedores-bs/registro-proveedores-bs.component';
import { EmpleadoComponent } from './empleado/empleado.component';
import { ListaServiciosComponent } from './Almacen/Servicios/listaServicios/lista-servicios/lista-servicios.component';
import { EditarServiciosComponent } from './Almacen/Servicios/editarServicios/editar-servicios/editar-servicios.component'; 
import { EditarMaterialesComponent } from './Almacen/Materiales/editarMateriales/editar-materiales/editar-materiales.component';
import { PasswordComponent } from './password/password.component';
import { JefaEnfermeriaComponent } from "./enfermeria/jefa-enfermeria/jefa-enfermeria.component";
import { ActualizarConsultasEnfermeriaComponent } from './enfermeria/actualizar-consultas-enfermeria/actualizar-consultas-enfermeria.component';
import { CarritoAlmacenComponent } from './Almacen/carrito-almacen/carrito-almacen.component';
import { PagoFinalComponent } from './Almacen/pagos/pago-final/pago-final.component';
import { EndoscopiasComponent } from './endoscopias/bitacora/endoscopias.component';
import { ReporteEndoscopiasComponent } from './endoscopias/reporte/reporte-endoscopias/reporte-endoscopias.component';
import { ServicioEndoscopiasComponent } from './endoscopias/servicio-endoscopias/servicio-endoscopias.component';

//SECCION DE PATOLOGIA
import { BitacoraPatologiaComponent } from './Almacen/Patologia/bitacora-patologia/bitacora-patologia.component';
import { HojaDeServiciosPatologiaComponent } from './Almacen/Patologia/hoja-de-servicios-patologia/hoja-de-servicios-patologia.component';
import { HojaReportePatologiaComponent } from './Almacen/Patologia/hojaReporte/hoja-reporte-patologia/hoja-reporte-patologia.component';
import { EntregaPatologiaComponent } from './recpecion-regresos/entrega-patologia/entrega-patologia/entrega-patologia.component';

import { BitacoraPacientesComponent } from './Almacen/General-pacientes/bitacora-pacientes/bitacora-pacientes.component';
import { ActualizarFiPacienteComponent } from './Almacen/General-pacientes/actualizar-fi-paciente/actualizar-fi-paciente.component';
import { VentasCanceladasComponent } from './recepcion/ventas/ventas-canceladas/ventas-canceladas.component';
import { RegistroSedeComponent } from './pacientes/registro-sede/registro-sede.component';
import { RectasBitacoraComponent } from './consultas/doctores/recetas/rectas-bitacora/rectas-bitacora.component';
import { RectasDetalleComponent } from './consultas/doctores/recetas/rectas-detalle/rectas-detalle.component';
import { RegistrarPatologiaComponent } from './Almacen/Patologia/registrar-patologia/registrar-patologia.component';
import { RegistroEndoscopiasComponent } from './endoscopias/registro-endoscopias/registro-endoscopias.component';
import { ComisionSociosComponent } from './socios/comision-socios/comision-socios.component';
import { UtilidadesSedesComponent } from './utilidades/utilidades-sedes/utilidades-sedes.component';
import { EditaLaboratoriosComponent } from './laboratorio/edita-laboratorios/edita-laboratorios.component';

//! seccion de FARMACIA
import { FarmaciaComponent } from './farmacia/farmacia/farmacia.component';
import { OtrosEstudiosComponent } from './servicios/recepcion-regresos/otros-estudios/otros-estudios.component';

// hospitalizacion
import { BitacoraHospitalizacionComponent } from './Hospitalizacion/bitacora-hospitalizacion/bitacora-hospitalizacion.component';
import { NotaIngresoComponent } from './Hospitalizacion/nota-ingreso/nota-ingreso.component';
import { HojaIndicacionesComponent } from './Hospitalizacion/hoja-indicaciones/hoja-indicaciones.component';
import { NotaEgresoComponent } from './Hospitalizacion/nota-egreso/nota-egreso.component';
import { HabitacionesPacientesComponent } from './Hospitalizacion/habitaciones-pacientes/habitaciones-pacientes.component';
import { VerHospitalizadoComponent } from './Hospitalizacion/ver-hospitalizado/ver-hospitalizado.component';
import { DocumentosReceptorFirmadosComponent } from './BancoSangre/rolRecepcion/documentos-receptor-firmados/documentos-receptor-firmados.component';
import { BitacoraSalidasComponent } from './BancoSangre/Almacen/salidas/bitacora-salidas/bitacora-salidas.component';
import { CargarOtrosEstudiosComponent } from './servicios/recepcion-regresos/otros-estudios/cargar-otros-estudios/cargar-otros-estudios.component';
import { EstudiosIdComponent } from './pacientes/estudios-id/estudios-id.component';
import { AgendaPersonalComponent } from './agenda/agenda-personal/agenda-personal.component';
import { AgendaHorizonteComponent } from './agenda/agenda-horizonte/agenda-horizonte.component';


//! seccion de ALMACEN
// , canActivate: [ValidarRecepcionGuard]
const pagesRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [],
    children: [

      // aca se iran agregando las rutas para el dashboard 
      { path: 'agenda', component: AgendaComponent },
      { path: 'agenda/agendar', component: AgendaComponent },
      { path: 'agenda/personal', component: AgendaPersonalComponent },
      { path: 'agenda/horizonte', component: AgendaHorizonteComponent },

      // rutas para el proceso de consulta
      // pacientes
      //{ path: 'Stepper', component: StepperComponent },

      { path: 'paciente', component: PacientesComponent },
      { path: 'paciente/:id', component: VerPacienteComponent },
      { path: 'registro/pacientes', component: RegistroComponent },
      { path: 'registro/paciente/sede', component:RegistroSedeComponent },
      { path: 'estudio/por/paciente/:id', component: EstudiosIdComponent },
      // consultas
      { path: 'ver/estudios/pendientes/por/aprobar', component: EstudiosPorAprobarComponent, canActivate: [RecpecionRolGuard] },
      { path: 'consulta', component: ConsutaGralComponent, canActivate: [RecpecionRolGuard] },
      { path: 'consulta/:id', component: ConsutaGralComponent },
      { path: 'hoja/diaria/enfermeria', component: HojaDiariaEnfGralComponent, canActivate: [EnfermeriaGuard] },
      { path: 'ficha-enfermeria-01/:id', component: FichaEnfermeriaComponent },
      { path: 'receta/medica/:id', component: RecetaComponent },
      { path: 'consultas/general', component: ConsultasComponent },
      { path: 'estudios/pedidos/:id', component: VerEstudiosComponent },

      // esta es la hisotria clinica
      { path: 'historia/clinica/:id', component: HevolucionCGComponent, canActivate: [MedicosGuard] },
      { path: 'hoja/evolucion/:id', component: HojaEvolucionComponent, canActivate: [MedicosGuard] },
      { path: 'receta/medica/:id', component: RecetaComponent, canActivate: [MedicosGuard] },
      { path: 'bitacora/medicina/general', component: BitacoraConsultaGralComponent, canActivate: [MedicosGuard] },
      { path: 'historico/consulta/general', component: BitacoraHistoricoComponent },
      { path: 'historico/hoja/evolucion/:id', component: HojaEvolucionHistoricoComponent },
      { path : 'historico/recetas', component: RectasBitacoraComponent },
      { path : 'detalle/receta/:id', component: RectasDetalleComponent },
      // servicios 
      { path: 'serviciosInt', component: ServiciosDashComponent },
      { path: 'serviciosInt/:servicio', component: ServiciosComponent },
      // pago de los servicios 
      { path: 'pago/servicios', component: PagoComponent, canActivate: [RecpecionRolGuard] },
      { path: 'pago/servicios/:agenda', component: PagoComponent , canActivate: [RecpecionRolGuard]},

      // pago de los servicios con usuario registrado
      { path: 'pago/servicios/:id', component: PagoComponent, canActivate: [RecpecionRolGuard] },

      { path: 'formulario/:servicio', component: FormularioComponent },
      { path: 'formularioeditar/:id', component: FormularioEditAmbComponent },
      { path: 'formularioeditarservice/:id', component: FormularioEditServiceComponent },
      // RUTAS DE LAS PAGINAS QUE DONFORMAN  FARMACIA
      
      { path: 'agregar/medicamento', component: FolioComponent },
      { path: 'farmacia/listado/medicamento', component: ListadoMedicamentosComponent },      // paquetes
      
      // TERMINAN LAS RUTAS DE FARMACIA

      //RUTA DE LOS PAQUETES DE LOS USUARIOS
      { path: 'consultar/paquetes', component: VerPaquetesComponent },
      { path: 'paquete/:id', component: PaqueteIDComponent },
      { path: 'solicitar/paquete/:id', component: SolicitudComponent },
      { path: 'paquete-paciente/:id', component: PaquetePacienteComponent },
      { path: 'pago-paquete',component: PagosPaquetesComponent},


      //BITACORA DE ENFERMERÍA 
      { path: 'enfermeria/bitacora', component: BitacoraComponent },
      { path: 'jefa/enfermeria', component: JefaEnfermeriaComponent },
      { path: 'actualizar/consulta/enfermeria', component: ActualizarConsultasEnfermeriaComponent },

      //RUTA ADMON  
      { path: 'utilidad', component: ServicioComponent },
      { path: 'utilidades/servicio', component: UtilidadServicioComponent },
      { path: 'servicios/por/usuario', component: ServicioPorUsuarioComponent },
      { path: 'servicios/por/cliente', component: ServiciosPorClienteComponent },

      { path: 'agregar/servicios/por/utlidad', component: AgregarServicioPorUtilidadComponent },
      { path: 'compras/por/utilidad', component: ComprasPorUtilidadComponent },
      { path: 'expediente/clinico', component: ExpedienteClinicoComponent },
      { path: 'hoja/consumo/por/usuario/:id', component: HojaConsumoAlDiaComponent },
      { path: 'dash/:id/:servicio', component: SocioCanalComponent },
      { path: 'dash/:id', component: DashComponent },
      { path: 'sedes/servicio', component: SedeComponent },
      { path: 'editar/utilidad/:servicio/:id', component: EditarServicioPorUtilidadComponent },
      //LABORATRIO REGRESOS RUTAS

      { path: 'bitacora/pedidos/:services', component: RegresosComponent },
      { path: 'bitacora/pedidos/paciente/:id/:servicio', component: PacienteEstudiosLabComponent },
      // #################### ULTRASONIDO ##############

      // { path: 'bitacora/ultrasonido', component: BitacoraultraComponent },
      // { path: 'hoja-ultrasonido/:id', component: HojaUltrasonidoComponent },
      // { path: 'registro/elementos/ultrasonido', component: FormularioElementosComponent },
      { path: 'historico/ultrasonido/estudios', component: HistoricoUltrasonidoComponent },

      // { path: 'bitacora/recepcion/ultrasonidos', component: BitacoraUltrasonidosComponent },
      // { path: 'lista/entrega/ultrasonidos/:id', component: EntregaUltrasonidoComponent },
      // { path: 'resultados/finales/ultrasonido/:id', component: ResultadosUltrasonidoComponent },
      // // %%%%%%%%%%%%%%%%%%%%%% recepcion regresos estudios %%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      // // { path: 'resultado-servicios/:id', component: DashboardResultadosComponent },
      { path: 'registro/elementos/ultrasonido/:id', component: FormularioElementosComponent },
      // { path: 'historico/ultrasonido/estudios', component: HistoricoUltrasonidoComponent },
      // { path: 'hoja/reporte/ultrasonido/:id', component: ReporteUltrasonidoComponent },
      
      // // %%%%%%%%%%%%%%%%%%%%%% recepcion regresos estudios %%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
      // { path: 'resultado-servicios/:id', component: DashboardResultadosComponent },
      { path: 'hoja-servicios/:nombreServicio/:id', component: HojaServiciosComponent },
      { path: 'nuevo/estudio/laboratorio/:id', component: NewStudioComponent },
      { path: 'hoja-reporte/:servicio/:id', component: HojaReporteComponent },
      { path: 'historico-estudio', component: HistoricoEstudiosComponent },
      // { path: 'bitacora/laboratorios', component: RegresosComponent },
      // { path: 'entrega/resultados', component: RegresosLabComponent },
      { path: 'final/obtenidos/:id', component: ResultadosFinalesComponent },

      {path:'recepcion/lista/entrega/rayosx/:id',component: EntregaRayosxComponent},
      {path:'resultado/servicio/rayosx/:id',component:ResultadosRayosxComponent},
      { path:"editar/laboratorios/:id", component:EditarComponent },

      // Otros estudios
      { path: 'cargar/otros/estudios/:id', component: OtrosEstudiosComponent },
      { path:'agregar/otros/estudios/:id', component:CargarOtrosEstudiosComponent },

      //////Formulario creador de servicios
      { path: 'reporte/ultrasonido/todo/:id', component: FormularioCreadorComponent },
      // MANTENIMIENTO RUTAS

      // pedidos de otras sedes

      { path:'ver/pedidos/sedes', component: PedidosSociosComponent },

      // entrega de resultados
      { path:'entrega/resultados/dash', component: EntregaDashComponent },
      
      /// modulos card de resultados recepcion 
      {path:'dashboard/recepcion/resultados',component:DashResultadosComponent},
      {path:'dashboard/recepcion/resultados/:servicio',component:ServiciosRecepcionComponent},

      // sedes 

      { path: 'listado/sedes/', component: ListadoDeSedesComponent },
      { path: 'servicios/sedes/:id', component: DetailServiciosSedeComponent }, 
      { path: 'servicios/pedidos/otras/sedes', component: PedidosSedesSociosComponent  },
      {  path: 'pedidos/detalle/:id', component: DetallePedidosSedesComponent  },

      //Banco de sangre receptores
      {  path: 'receptores', component: ReceptoresComponent  },
      { path: 'hojaservicios/receptor/:id', component: HojaServicioReceptorComponent },
      { path: 'documentos/receptor', component: DocumentosReceptorComponent },
      { path: 'productos/receptor', component: EntregaProductosComponent },
      { path: 'orden/productos', component: OrdenCompraComponent },
      { path: 'tiparcruza', component: TiparCruzarComponent },

      //Banco de sangre disponentes recepcion
      { path: 'bitacora/disponentes', component: BitacoraDisponentesComponent },
      { path: 'registro/disponentes', component: RegistroDisponentesComponent },
      { path: 'ver/disponente/:id', component: VerDisponenteComponent },
      { path: 'autoexclusion/:id', component: AutoexclusionComponent },
      //Entrega de productos Sanguineos
      { path: 'entrega/productos/sanguineos', component: EntregaProductosSanguineosComponent },

      //Banco de sangre disponentes enfermeria
      { path: 'disponentes/bitacora', component: DisponentesBitacoraComponent },
      { path: 'ficha/disponente/:id', component: FichaDisponenteComponent },

      //Banco de sangre disponentes flebotomia
      { path: 'bitacora/flebotomia', component: BitacoraFlebotomiaComponent },
      { path: 'documentos/flebotomia/:id', component: DocumentosDonanteFlebotomiaComponent },
      { path: 'reacciones/adversas', component: ReaccionesDerivadasComponent },
      //bitacora diferidos y almacen
      { path: 'bitacora/almacen', component: BitacoraAlmacenComponent },
      { path: 'bitacora/diferidos', component: BitacoraDiferidosComponent },
      //bitacora de consulta de diponentes doctor
      { path: 'bitacora/consulta/disponentes', component: BitacoraConsultaDisponentesComponent },
      { path: 'historia/clinica/doctor/:id', component: HistoriaClinicaComponent },
      { path: 'hoja/evolucion/disponente/:id', component: HojaEvolucionDisponenteComponent },

      // entrega de resultados
      { path: 'entrega/resultados/dash', component: EntregaDashComponent },

      // Laboratorios Disponentes
      { path: 'bitacora/laboratorios/disponentes', component: BitacoraLaboratorioComponent},
      { path: 'hoja/servicios/laboratorio/disponentes/:id', component: HojaServiciosLabDisponenteComponent},
      { path: 'grupo/rh/biometria/hematica/completa/:id', component: BiometriaLabDisponenteComponent},
      
      { path: 'serologia/laboratorio/disponente/:id', component: SerologiaLabDisponenteComponent},
      
      { path: 'historico/estudios/disponente', component: HistoricoEstudiosDisponenteComponent},
      // nuevo estudio de laboratorio de censur
      { path: 'nuevo/estudios/lab', component: NuevoEstudioLabComponent},

      // RUTAS JEFE BANCO SANGRE
      { path: 'agregar/sede/bs', component: AgregarSedeComponent },
      
      // Componente Detalle Sede Eladio
      { path: 'detalle/sede/:id', component: DetalleSedeComponent },
      
      { path: 'bitacora/no-aptos', component: BitacoraNoAptosODiferidosComponent },

      // Bitacora de estudios que va ligada a la hoja de servicios
      { path: 'bitacora/hoja/de/servicios', component: BitacoraPedidosComponent },
      { path: 'ficha/identificacion/sede', component: FISedeComponent },
      { path: 'hoja/servicio/receptor', component: HojaServicioReceptorBSComponent },
      { path: 'historico/disponente/bs/:id', component: HistoricoDisponenteBSComponent},


      // Endoscopias
      { path: 'bitacora/endoscopias', component: EndoscopiasComponent },
      { path: 'reporte/endoscopias', component: ReporteEndoscopiasComponent },
      { path: 'servicio/endoscopias', component: ServicioEndoscopiasComponent },
      { path: 'registrar/endoscopias', component: RegistroEndoscopiasComponent },

      
      
      // AQUÍ COMIENZA "ALMACÉN"
      
      // productos almacen BS
      { path: 'lista/productos/almacen', component: ListaProductosAlmacenComponent },
      { path: 'agregar/productos/almacen', component: RegistroProductosAlmacenComponent },
      { path: 'detalle/producto/almacen/:id', component: DetalleProductosAlmacenComponent },
      { path: 'editar/producto/almacen/:id', component: EditarProductosAlmacenComponent },
      { path: 'agregar/stock/productos/almacen/:id', component: StockProductosAlamacenBSComponent },

      // Seguimientos Almacén
        { path:'lista/seguimiento/almacen', component: ListadoSeguimientosAlmacenComponent },
        { path: 'crear/seguimiento/almacen', component: CrearSeguimientosAlmacenComponent },
        { path: 'detalle/seguimiento/almacen', component: DetalleSeguimientosAlmacenComponent },
        { path: 'editar/seguimiento/almacen', component: EditarSeguimientosAlmacenComponent },
        { path: 'bitacora/salidas/almacen', component: BitacoraSalidasComponent  },

       //Almacen materiales
       { path: 'detalle/materiales/:id', component: DetalleMaterialesComponent},
       { path: 'lista/materiales', component: ListaMaterialesComponent },
       { path: 'registro/materiales', component: RegistroMaterialesComponent },
       { path: 'editar/materiales/:id', component: EditarMaterialesComponent },    
       
       //Almacen servicios
       { path: 'detalle/servicio/almacen/:id' , component: DetalleServiciosComponent},
       { path: 'lista/servicios', component: ListaServiciosComponent }, 
       { path: 'editar/servicio/almacen/:id', component: EditarServiciosComponent }, 
       // Rutas de proveedores
      {  path: 'detalle/proveedor', component: DetalleProveedorComponent },
      {  path: 'registro/proveedor/banco/sangre', component: RegistroProveedoresBsComponent },
      {  path: 'actualizar/proveedor/:id', component: ActualizarProveedorComponent }, 
      {  path: 'listado/proveedor', component: ListadoProveedorComponent},

      // Rutas de servicos de banco de SANGRE
      { path: 'agregar/servicios', component: RegistroServiciosComponent }, 
      

      //listado de cedes de banco de sangre
      {  path: 'listado/sedes/banco/sangre', component: SedesbsComponent },
      { path: 'orden/servicio/receptores', component: OrdenreceptoresComponent},

      // carrito
      { path: 'carrito/almacen', component: CarritoAlmacenComponent },
      // pagos en almacen
      { path: 'pago/almacen' , component: PagoFinalComponent},




      


      /// modulos card de resultados recepcion 
      { path: 'dashboard/recepcion/resultados', component: DashResultadosComponent },
      { path: 'dashboard/recepcion/resultados/:servicio', component: ServiciosRecepcionComponent },

      // Ruta para asignar modulo a los usuarios
      { path: 'asignar/modulo', component: AsignarModuloComponent},

      // Ruta para agregar un empleado nuevo del hospital
      { path: 'registrar/empleado', component: EmpleadoComponent },

      // Ruta para actualizar contraseña 
      { path: 'actualizar/password', component: PasswordComponent },

      //Ruta para la seccion de patologia
      { path: 'bitacora/patologia', component: BitacoraPatologiaComponent },
      { path: 'hoja/servicio/patologia/:id', component: HojaDeServiciosPatologiaComponent },
      { path: 'hoja/reporte/patologia/:id', component: HojaReportePatologiaComponent },
      // { path: 'hoja/servicios/nuevos/:id', component: HojaDeServiciosPatologiaComponent  },
      { path: 'registrar/patologia', component: RegistrarPatologiaComponent },
      {  path: 'regresos/patologia/:id', component: EntregaPatologiaComponent  },

      // Ruta para la seccion de General Pacientes en Alamcen
      { path: 'bitacora/pacientes/general', component: BitacoraPacientesComponent },
      { path: 'actualizar/fi/pacientes/:id', component: ActualizarFiPacienteComponent },
      { path: 'cancelar/tickets' , component: VentasCanceladasComponent },
      { path: 'utilidad/socios/recepcion', component: ComisionSociosComponent },
      { path: 'utilidades/por/sede', component: UtilidadesSedesComponent },

      /**
       * Validar estudios de laboratorio
       */
      { path: 'validar/estudios/laboratorio', component: EditaLaboratoriosComponent },
      { path: 'agregar/docuemtos/recpetor/firmados', component: DocumentosReceptorFirmadosComponent },

      /** 
       *  *ROUTER DE FARMACIA
       */ 
       { path : 'farmacia/venta', component: FarmaciaComponent },



       // hospitalizacion
       { path: 'bitacora/hospitalizacion', component: BitacoraHospitalizacionComponent },
       { path:'nota/de/ingreso/:id', component: NotaIngresoComponent },
       { path:'hoja/indicaciones/:id', component:HojaIndicacionesComponent },
       { path:'nota/de/egreso', component: NotaEgresoComponent },
       { path:'habitaciones/pacientes', component: HabitacionesPacientesComponent },
       { path:'hospitalizado', component: VerHospitalizadoComponent },
      //  termina hospitalizacion

      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

    ]

  } 
];
export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
