import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Module } from '../../interfaces/module.interfaces';
import { map } from 'rxjs/operators';
import { URL } from 'src/app/config/conf';


@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  public url: string;

  constructor( private _http: HttpClient ) {

    this.url = URL;

  }


  getModules( id: string ) {

  
    const url = this.url + '/modulos/'+id;


  return  this._http.post( url, id )
  }


}
