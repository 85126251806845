import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buscarPaciente'
})
export class BuscarPacientePipe implements PipeTransform {

  transform(value: any, args: any): any {
    let buscarPaciente:any[]=[];

    if(args.length == 0){
      buscarPaciente = value
    }else{
      for ( const paciente of value) {
        let nombreCompleto = paciente.nombrePaciente.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "").concat(" "+ paciente.apellidoPaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "")+" "+paciente.apellidoMaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
        if(nombreCompleto.toUpperCase().includes(args.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "").toUpperCase())){
          buscarPaciente.push(paciente);
        }
      }
    }
    return buscarPaciente;
  }


}
