<div class="container-fluid">
    <!-- <h1 class="title-principal-bs">LISTADO DE PROVEEDORES</h1> -->
    <app-titulos [title]="'LISTADO DE PROVEEDORES'"></app-titulos>
    <div class="row">
        <div class="col-9">
            <input type="text" class="form-control shadow-input-bs" id="buscarProveedor" placeholder="BUSCAR">
        </div>
        <div class="col">
            <button type="button" class="boton" [routerLink]="['/registro/proveedor/banco/sangre']" >  NUEVO </button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col text_header">
                            <p>DIRECCIÓN</p>
                        </div>
                        <div class="col text_header">
                            <p>CORREO</p>
                        </div>
                        <div class="col text_header">
                            <p>TELÉFONO</p>
                        </div>
                        <div class="col text_header">
                            <p>DISTRIBUCIÓN</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>ACCIONES</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let proveedor of proveedores | paginate:{id:'listapacientes',
                    itemsPerPage: 4,
                    currentPage:pagina,
                    totalItems:totalproveedor} ; let i = index">
                        <div class="row">
                            <div class="col-md-1">
                                <p>{{ i + 1 }}</p>
                            </div>
                            <div class="col">
                                <p>{{ proveedor.nombreEmpresa }}</p>
                            </div>
                            <div class="col">
                                <p>{{ proveedor.domicilio }}</p>
                            </div>
                            <div class="col">
                                <p>{{ proveedor.correo }}</p>
                            </div>
                            <div class="col">
                                <p>{{ proveedor.contacto }} </p>
                            </div>
                            <div class="col">
                                <p>{{ proveedor.diasDistribucion }}</p>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col pointer">
                                        <i class="fa fa-edit" [routerLink]="['/actualizar/proveedor', proveedor._id]" ></i>
                                    </div>
                                    <div class="col pointer">
                                        <i class="fa fa-trash-alt" (click)="borrarProveedor(proveedor._id)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
