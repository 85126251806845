import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';;

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  changePasswordForm: FormGroup;

  createFormModule(){
    return new FormGroup({
      nuevaPassword: new FormControl('', 
        [
          Validators.required, 
          Validators.minLength(8),
          Validators.pattern(/\d/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
        ]
      ),
      confirmarPassword: new FormControl('', 
        [
          Validators.required,
        ]
      ),
    })
  }

  constructor(
    private _loginService: LoginService,
    private _router: Router
  ) {  
    this.changePasswordForm = this.createFormModule();
  }

  ngOnInit(): void {
  }

  confirmarPasswords(){
    var password = this.changePasswordForm.value.nuevaPassword;
    var confirmarPassword = this.changePasswordForm.value.confirmarPassword;

    if(this.changePasswordForm.valid){
      if(password == confirmarPassword){
      
        const idLocalStorage = getDataStorage()._id;
        

        this._loginService.updatePassword(idLocalStorage, this.changePasswordForm.value)
        .subscribe((data: any) => {
          // console.log(data)
          if(data.ok) {
          
            Swal.fire('Exito!', 'Se actualizo la contraseña', 'success');
            this._router.navigateByUrl('/');
          }else {
            Swal.fire('Error', data.message, 'error');
          }
        });

      }else{
        Swal.fire({ icon: 'warning', title: '', text: 'LAS CONTRASEÑAS DEBEN SER IGUALES'});
      }
    }else{
      Swal.fire({ icon: 'warning', title: '', text: 'ESCRIBE LA CONTRASEÑA PARA CONTINUAR'});
    }
  }

  get nuevaPassword(){ return this.changePasswordForm.get('nuevaPassword') }
  get confirmarPassword(){ return this.changePasswordForm.get('confirmarPassword') }

}
