<!-- ESTAS CARDS SON LAS DE LOS MODULOS    -->
<!-- <div class="center" *ngIf="usuario.nombre == 'Liliana De Aragon'">
    <h1><span>F</span><span>E</span><span>L</span><span>I</span><span>Z</span><span> </span><span>C</span><span>U</span><span>M</span><span>P</span><span>L</span><span>E</span><span>A</span><span>Ñ</span><span>O</span><span>S</span></h1>
</div> -->
<div id="content">
    
    <div class="card-columns animate bounceIn">
        <a [routerLink]="module.modules.route" *ngFor="let module of modules | paginate:{id:'listaAmbulancia',
                                                                               itemsPerPage: 8 ,
                                                                               currentPage:pagina,
                                                                               totalItems:totalAmbulancia}">
            <div class="card" id="card">
                <img [src]="module.modules.icon" id="img2" [routerLink]="module.modules.route">
                <h2 id="h2">{{ module.modules.nameModule | uppercase}}</h2>
            </div>
        </a>
    </div>
</div>
<div class="offset-md-4">
    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
</div>