import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-productos-stock',
  templateUrl: './productos-stock.component.html',
  styleUrls: ['./productos-stock.component.css']
})
export class ProductosStockComponent implements OnInit {

  public productosStock = this._fb.group({
    costoReal       : [ '' , [ Validators.required] ],
    precioVenta     : [ '' , [ Validators.required] ],
    lote            : [ '' , [ Validators.required] ],
    cantidad        : [ '' , [ Validators.required] ],
    proveedor       : [ '' , [ Validators.required] ],
    fecha_caducidad : [ '' , [ Validators.required] ],
    factura         : [ '' , [ Validators.required] ],
  });

  @Input() proveedores = [];
  @Output() proveedor = new EventEmitter<any>();

  constructor(private _fb: FormBuilder) { }

  ngOnInit(): void {
  }

  enviarDatos(form:NgForm){
    this.proveedor.emit(form.form);
  }

}
