<!-- SE DIERON ESTILOS A LA TARJETA, PARA AGREGAR UN NUEVO MEDICAMENTO -->

<div>
    <h1 class="cent">AGREGAR NUEVO MEDICAMENTO</h1>
</div>
<form
#medicamentosNgForm='ngForm'>
<div class="card">
    <div class="row">
        <div style="margin-top: 3%;" class="col-md-3">
            <label for="nombreComercial" style="color: #001672;" class="name">Nombre Comercial:</label>
            <input type="text " style="margin: 2 2 2 2; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1);" class="nc"   ngModel id="validationCustom01"  name="nombreComercial"  required>
        </div>

        <div style="margin-top: 3%; " class=" col-md-3 ">
            <label for="sustanciaActiva " style="color: #001672; " class="noms ">Nombre De La Sal ó Sustancia Activa:</label>
            <input type="text " style=" margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1); " class="nsc "  ngModel id="validationCustom01"  name="nombreDeSalOsustanciaActiva"  required>
        </div>

        <div style="margin-top: 3%; " class="col-md-3 ">
            <label for="presentacio " style="color: #001672; " class="pre ">Presentación:</label>
            <input type="text " style=" margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1); " class="prese "  ngModel id="validationCustom01"  name="presentacio"  required>
        </div>

        <div style="margin-top: 3%; " class="col-md-3 ">
            <label for="contenido " style="color: #001672; " class="con ">Contenido del frasco:</label>
            <input type="text " style=" margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1); " class="cont "  ngModel id="validationCustom01"  name="contenidoFrasco"  required>
        </div>

        <div style="margin-top: 5%; " class="col-md-3 ">
            <label for="viaAdmon " style="color: #001672; " class="via ">Vía de Administración:</label>
            <input type="text " style=" margin-top:4%; margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1);" class="viad "  ngModel id="validationCustom01"  name="viaDeAdministracion"  required>
        </div>

        <div style="margin-top: 5%; " class="col-md-2 ">
            <label for="lab " style="color: #001672; " class="lab ">Laboratorio:</label>
            <input type="text " style="margin-top:4%;  margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1);" class="labo "  ngModel id="validationCustom01"  name="laboratorio"  required>
        </div>

        <div style="margin-top: 5%; " class="col-md-2 ">
            <label for="lab " style="color: #001672; " class="fecha ">Fecha de Caducidad:</label>
            <input type="text " style="margin-top:4%; margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1); " class="fech "  ngModel id="validationCustom01"  name="fechaCaducidad"  required>

        </div>

        <div style="margin-top: 5%; " class="col-md-2 ">
            <label for="lote " style="color: #001672; " class="lote "> Lote: </label>
            <input  required style=" margin-top:4%; margin: 2rem 2 2rem 0; box-shadow: 10px 10px 20px -8px rgba(196, 192, 196, 1);"  ngModel id="validationCustom01" name="lote"  class="lot " type="Folio ">
        </div>

    </div>
    <div class="row mt-4">
        <div class="col-md-4 offset-md-4 mb-4">
            <button  class="btn-censur" (click)="enviar(medicamentosNgForm) "> GUARDAR </button>
        </div>
    </div>
</div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>