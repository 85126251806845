<form #fPersonalesNoPat #form='ngForm'>
    <div class="container-fluid">
        <div class="form-row ">
            <div class="col ">
                <div class="form-group col">
                    <span>TABAQUISMO</span>
                    <p>¿Cuántos?</p>
                    <div class="form-check form-check-inline estilo">
                        <input class="form-check-input" name="tabaquismoPorDia" [(ngModel)]="antecedentesPersonalesNoPatologicos.tabaquismoPorDia" type="number" id="cuantosFiltros">
                        <label class="form-check-label" for="cuantosFiltros">Por Día</label>
                    </div>
                </div>
                <div class="form-group col">
                    <div class="form-check form-check-inline estilo">
                        <label class="form-check-label " for="aniosConsumo">Años de Consumo: </label>
                        <input name="aniosconsumo" [(ngModel)]="antecedentesPersonalesNoPatologicos.aniosconsumo" class="form-check-input " type="number " id="aniosConsumo">
                    </div>
                </div>
                <div class="form-group col">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline estilo">
                            <label for=" ">Exfumador o Tabaquismo pasivo: </label>
                            <input type="text" name="exFumadorPasivo" [(ngModel)]="antecedentesPersonalesNoPatologicos.exFumadorPasivo" class="form-control antecedentes-inputs">
                        </div>
                    </div>
                </div>
                <div class="form-group col">
                    <span>ALCOHOLISMO</span>
                    <p>¿Cuántas Copas?</p>
                    <div class="form-check form-check-inline estilo">
                        <input class="form-check-input" name="copasPorDia" [(ngModel)]="antecedentesPersonalesNoPatologicos.copasPorDia" type="number" id="cuantosFiltros">
                        <label class="form-check-label" for="cuantosFiltros">Por Día</label>
                    </div>
                    <p style="margin-top: 20px;">Cervezas</p>
                    <p>¿Cuántas Cervezas?</p>
                    <div class="form-check form-check-inline estilo">
                        <input class="form-check-input" name="alcoholismoPorDia" [(ngModel)]="antecedentesPersonalesNoPatologicos.alcoholismoPorDia" type="number " id="">
                        <label class="form-check-label" for=" ">Por Semana</label>
                    </div>
                </div>
                <div class="form-group col">
                    <div class="form-check form-check-inline estilo">
                        <label class="form-check-label " for="aniosConsumoAlcohol">Años de Consumo: </label>
                        <input class="form-check-input" name="aniosDeconsumoAlcohol" [(ngModel)]="antecedentesPersonalesNoPatologicos.aniosDeconsumoAlcohol" type="number " id="aniosConsumoAlcohol">
                    </div>
                </div> 
                <div class="form-group col">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline estilo">
                            <label for="alcoholicoOcasional">Alcoholico Y/U ocacional: </label>
                            <input type="text" id="alcoholicoOcasional" name="exAlcoholicoUOcasional" [(ngModel)]="antecedentesPersonalesNoPatologicos.exAlcoholicoUOcasional" class="form-control antecedentes-inputs ">
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group col"> -->
                    <!-- <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <input class="form-check-input" name="cuantosFiltros" [(ngModel)]="antecedentesPersonalesNoPatologicos.tabaquismoPorDia" type="number" id="cuantosFiltros">
                            <label class="form-check-label" for="cuantosFiltros">Por Día</label>
                        </div>
                    </div> -->
                    <div class="form-group col">
                        <div class="form-group col-md6 ">

                            <label for="alergiasSi" style="margin-top: .5rem;">Alergias: </label>
                            <!-- <input type="radio " name="sdds " id="alergiaNoPatSi " value="alergiaNoPatSi"> -->
                            <input type="radio" name="alergias" style="margin-left: 1rem;" [(ngModel)]="antecedentesPersonalesNoPatologicos.alergias" value="Si">
                            <label class="form-check-label " style="margin-right: 2rem;" for="alergiaSi">Si</label>
                            <input type="radio" name="alergias" style="margin-left: .5rem;" [(ngModel)]="antecedentesPersonalesNoPatologicos.alergias" value="No" id="alergiaNoPatNo ">
                            <label class="form-check-label " style="margin-right: 2rem;" for="alergiaNoPatNo ">No</label>
                        </div>
                    </div>
                    <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline estilo">
                                <label for=" " style="margin-right: 4rem;">Tipo: </label>
                                <input type="text" style="margin-right:0.5rem;" class="form-control antecedentes-inputs" name="tipoAlergias" [(ngModel)]="antecedentesPersonalesNoPatologicos.tipoAlergias">

                            </div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <label for=" " style="margin-top: .5rem;">Alimentación adecuada: </label>
                                <input class="form-check-input " style="margin-left: 1rem;" type="radio" id="alimentacionSi" [(ngModel)]="antecedentesPersonalesNoPatologicos.alimentacionAdecuada" value="Si" name="alimentacionAdecuada">
                                <label class="form-check-label " for="alimentacionSi">Si</label>
                                <input class="form-check-input " style="margin-left: .5rem;" type="radio" id="alimentacionNo " name="alimentacionAdecuada" [(ngModel)]="antecedentesPersonalesNoPatologicos.alimentacionAdecuada" value="No">
                                <label class="form-check-label " for="alimentacionNo ">No</label>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
                <!-- <div class="col-md-6"> -->
                    
                   
                    <!-- <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <label for="tipoSangre">Tipo Sanguíneo: </label>
                                <input type="text" class="form-control antecedentes-inputs" name="tipoSangre" [(ngModel)]="antecedentesPersonalesNoPatologicos.tipoSanguineo">

                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <label for=" ">Se desconoce: </label>
                                <input class="form-check-input" [(ngModel)]="antecedentesPersonalesNoPatologicos.desconoceTipoSanguineo" type="radio" name="desconoceSi" id="desconoceSi " value="Si ">
                                <label class="form-check-label" for="desconoceSi ">Si</label>
                                <input class="form-check-input" [(ngModel)]="antecedentesPersonalesNoPatologicos.desconoceTipoSanguineo" type="radio" name="desconoceNo" id="desconoceNo " value="No ">
                                <label class="form-check-label" for="desconoceNo ">No</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <label for=" " style="margin-top: .5rem;">Vivienda Servicios Básicos: </label>
                                <input class="form-check-input" style="margin-left: 1rem;" [(ngModel)]="antecedentesPersonalesNoPatologicos.viviendaConServiciosBasicos" type="radio" name="viviendaConServiciosBasicos" id="serviciosBasicosSi " value="Si">
                                <label class="form-check-label" for="serviciosBasicosSi ">Si</label>
                                <input class="form-check-input" style="margin-left: .5rem;" [(ngModel)]="antecedentesPersonalesNoPatologicos.viviendaConServiciosBasicos" type="radio" name="viviendaConServiciosBasicos" id="serviciosBasicosNo " value="No">
                                <label class="form-check-label" for="serviciosBasicosNo ">No</label>
                            </div>
                        </div>
                    </div>

                <!-- </div> -->
                <!-- <div class="col "> -->
                    <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline estilo">
                                <label for="drogadictoTipo">Tipo de Drogas: </label>
                                <input type="text" name="drogadiccionTipo" id="drogadictoTipo" [(ngModel)]="antecedentesPersonalesNoPatologicos.drogadiccionTipo" class="form-control antecedentes-inputs ">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <div class="form-check form-check-inline estilo">
                            <label class="form-check-label" for=" ">Años de Consumo: </label>
                            <input class="form-check-input" name="aniosConsumoDrogas" [(ngModel)]="antecedentesPersonalesNoPatologicos.aniosConsumoDrogas" type="number" id="aniosDrogoConsumo">

                        </div>
                    </div>
                    <div class="form-group col">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline estilo">
                                <label for=" ">Exdrogadicto: </label>
                                <input type="text" name="exDrogadicto" [(ngModel)]="antecedentesPersonalesNoPatologicos.exDrogadicto" class="form-control antecedentes-inputs ">
                            </div>
                        </div>
                    </div>

                <!-- </div> -->
            </div>
        </div>

        <div class="form-group estilo">
            <label for="inputAddress " >Otros:</label>
            <input type="text" name="otrosAntecedentesNoPatologicos" [(ngModel)]="antecedentesPersonalesNoPatologicos.otrosAntecedentesNoPatologicos" class="form-control " id="otros " placeholder=" ">
        </div>

    </div>

    <div class="" >
        <button class="boton" (click)="agregarPersonalesNoPatologicos(form)">Guardar</button>
        <button class="boton2" (click)="actualizarNoPatologicos(form)">Actualizar</button>
    </div>
</form>
