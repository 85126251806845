import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegradosService } from '../../../services/servicios/integrados.service';
import { SocioService } from '../../../services/socio/socio.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 
@Component({
  selector: 'app-stepper-socio-editar-ambulancia',
  templateUrl: './stepper-socio-editar-ambulancia.component.html',
  styleUrls: ['./stepper-socio-editar-ambulancia.component.css']
})
export class StepperSocioEditarAmbulanciaComponent implements OnInit {

  public id: string;
  public serviceEdit = {
    DESTINO: "",
    PRECIO_MEMBRESIA_DIA: "",
    PRECIO_MEMBRESIA_NOCHE: "",
    PRECIO_MEMBRESIA_REDONDO_DIA: "",
    PRECIO_MEMBRESIA_REDONDO_NOCHE: "",
    PRECIO_PUBLICO_DIA: "",
    PRECIO_PUBLICO_NOCHE: "",
    PRECIO_PUBLICO_REDONDO_DIA: "",
    PRECIO_PUBLICO_REDONDO_NOCHE: "",
    name: "",
    _id: ""
  };
  public utilidad = {
    rangoUtilidad: "",
    preciosRangoA:[
      {
        precio_publico_diaA: "",
        precio_publico_redondo_diaA: "",
        precio_publico_nocheA: "",
        precio_publico_redondo_nocheA: "",
        precio_membresia_diaA: "",
        precio_membresia_redondo_diaA: "",
        precio_membresia_nocheA: "",
        precio_membresia_redondo_nocheA: "",
      }
    ],
    preciosRangoB:[
      {
        precio_publico_diaB: "",
        precio_publico_redondo_diaB: "",
        precio_publico_nocheB: "",
        precio_publico_redondo_nocheB: "",
        precio_membresia_diaB: "",
        precio_membresia_redondo_diaB: "",
        precio_membresia_nocheB: "",
        precio_membresia_redondo_nocheB: "",
      }
    ],
    preciosRangoC:[
      {
        precio_publico_diaC: "",
        precio_publico_redondo_diaC: "",
        precio_publico_nocheC: "",
        precio_publico_redondo_nocheC: "",
        precio_membresia_diaC: "",
        precio_membresia_redondo_diaC: "",
        precio_membresia_nocheC: "",
        precio_membresia_redondo_nocheC: "",
      }
    ],
    name: "",
    idServicio: "",
    idSede: "",
    _id:""
  };
  public nombre:string;
  public sede:string;
  public editar= false;

  constructor(private _router: Router,private _route:ActivatedRoute, private _service:IntegradosService, private _socio:SocioService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id'); 
    this.getAmbulancia(); 
    this.getSede();
    this.obtenerUtilidadServ();  
  }

  obtenerUtilidadServ(){
    this._socio.obtenerUtilidadAmbulancia(this.id,this.sede).subscribe((resp:any) => {
      if(resp.ok){
        /* console.log(resp); */
        if(resp.data.length > 0){
          this.setUtilidadServicio(resp.data[0]);
          this.editar=true;
        }else{
          this.utilidad=this.utilidad;
        }
      }
    })
  }

  setUtilidadServicio(utilidad){
    this.utilidad=utilidad
  }

  getSede(){
    this.sede= localStorage.getItem('sede')
  }

  enviar(form: NgForm){
    if(this.editar){
      //console.log("entro");
      this._socio.updateUtilidadAmbulancia(this.utilidad._id,this.utilidad).subscribe((resp:any) => {
        if(resp.ok){
           Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA UTILIDAD SE MODIFICO CORRECTAMENTE',
            })
          this._router.navigateByUrl('dash/'+this.sede+'/'+this.serviceEdit.name);
        }
      })
    }else{
      this.setUtilidad(form.controls);
      this._socio.utilidadAmbulancia(this.utilidad).subscribe((resp:any) =>{
        if(resp.ok){
          this.aler();
          this._router.navigateByUrl('dash/'+this.sede+'/'+this.serviceEdit.name);
        }
      });    
    }
  }

  aler(){
     Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA UTILIDAD SE CREO CORRECTAMENTE',
            })
  }

  setUtilidad(form){
    this.utilidad.rangoUtilidad= form.rangoUtilidad.value;
    /* this.utilidad.precio_publico_dia=form.utilidadprecioPublicoDia2.value;
    this.utilidad.precio_publico_redondo_dia=form.utilidadprecioPublicoRedondoDia2.value;
    this.utilidad.precio_publico_noche=form.utilidadprecioPublicoNoche2.value;
    this.utilidad.precio_publico_redondo_noche=form.utilidadprecioPublicoRedondoNoche2.value;
    this.utilidad.precio_membresia_dia=form.utilidadprecioMembresiaDia2.value;
    this.utilidad.precio_membresia_redondo_dia=form.utilidadprecioMembresiaRedondoDia2.value;
    this.utilidad.precio_membresia_noche=form.utilidadprecioMembresiaNoche2.value;
    this.utilidad.precio_membresia_redondo_noche=form.utilidadprecioMembresiaRedondoNoche2.value; */
    this.utilidad.idServicio=form.idServicio.value;
    this.utilidad.idSede=form.sede.value;
    this.utilidad.name=form.Servicio.value;
    //console.log(this.utilidad);
        
  }

  getAmbulancia(){
    this._route.params.subscribe(params => {
      var id = params['id'];
      this._service.getAmbulanciaById(id).subscribe((res:any) => {
          /* console.log( res['data'] ); */
          if(res.data){
            this.serviceEdit = res.data;
            this.nombre=res.data.name;
            //console.log(this.serviceEdit);
          }else{
            // this._router.navigate(['/ambulancia']);
          }
        },
        err => { 
          console.log(err);
          // this._router.navigate(['/ambulancia']);  
        }
      )
    });
  }
}
