import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-detalle-sede',
  templateUrl: './detalle-sede.component.html',
  styleUrls: ['./detalle-sede.component.css']
})
export class DetalleSedeComponent implements OnInit {
  public idsede;
  public sede = {
    imagencli:[],
    clinica: "",
    razon: "",
    rfc:"",
    contacto:"",
    genero:"",
    fecha:"",
    telefono:"",
    contrasena:"",
    correo:"",
    responsable_sa:"",
    calle:"",
    no_exterior:"",
    postal:"",
    pais:"",
    estado:"",
    municipio:"",
    ref_1:"",
    ref_2:"",  
    razSoc:"",
    rfc_cem:"",
    postal_em:"",
    estado_em:"",
    municipio_em:"",
    calle_em:"",
    exterior_em:"",
    correo_em:"",
    archivos:[],
    codsede: ""

  }
  public sedeven ={
    pedido:      [],
    vendedor:    "",
    fecha:      "",
    estudios:   [],
    sede_banco:  "",
    totalCompra:"",
    status:     ""

  }
  public img =[];
  
  constructor(public _BancoService: BancoService,private _router: Router, private _ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.idsede = this._ActivatedRoute.snapshot.paramMap.get('id');
    this.getSede ();
    var currentColor = localStorage.getItem('usuario');  
    this.getVenSede();
  }


 
  getSede(){
    this._BancoService.getSedesById(  this.idsede )
    .subscribe( (resp:any) => {
    this.sede = resp.data;
    this.img = resp.data.imagencli;
    });
    
  }
  getVenSede(){
    this._BancoService.getVenSede(this.idsede)
    .subscribe( (resp:any) => {
     // this.sedeven = resp.data;

    console.log(resp)  
   
    });
    
  }
  

}
