import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-listado-seguimientos-almacen',
  templateUrl: './listado-seguimientos-almacen.component.html',
  styleUrls: ['./listado-seguimientos-almacen.component.css']
})
export class ListadoSeguimientosAlmacenComponent implements OnInit {

  public seguimientos = [];

  constructor(
    private _AlmacenService: AlmacenService
  ) { }

  ngOnInit(): void {
    this.obtenerSeguimiento();
  }

  obtenerSeguimiento(){
    this._AlmacenService.obtenerSeguimentos().subscribe(( data:any ) => {
      this.seguimientos = data.data;
    })
  }


  borrarSeguimiento(){
    Swal.fire({
      title: '¿SEGURO QUE DESEAS ELIMINAR EL SEGUIMIENTO?',
      text: "NO SE PODRA REVERTIR LA ACCIÓN",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#13B42D',
      cancelButtonColor: '#FF0000',
      cancelButtonText: 'NO CANCELAR',
      confirmButtonText: 'SI, ACEPTAR'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'SE ELIMINO EL SEGUIMIENTO EXITOSAMENTE',
          '',
          'success'
        )
      }
    })
  }

}
