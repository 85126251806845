<!--FORMULARIO, AGRAGR NUEVO EN LA PARTE DE AGREGAR UN NUEVO PACIENTE, SE QUITARON LAS FALTAS DE ORTOGRAFÍA EN ESTE FORM -->

<!-- 06/03/21 SE REALIZARON LOS CAMBIOS CORRESPONDIENTES COMO: TODAS LAS LETRAS A MAYUSCULAS
Y AGREGAR CONYUGUE EN PARENTESCO -->
<div class="card" style="border-radius: 30px; margin-left: 25px">
    <form #form="ngForm">
        <mat-horizontal-stepper linear #stepper style="background: none">
            <mat-step label="DATOS PERSONALES">
                <h3>PERSONALES</h3>
                <div class="row">
                    <div class="col-md-3">
                      <mat-label> NOMBRES (S) </mat-label>
                    </div>
                    <div class="col-md-3">
                      <input
                        matInput
                        maxlength="50"
                        type="text"
                        required
                        name="nombrePaciente"
                        ngModel
                        id="nombre"
                        class="form-control mayusculas"
                      />
                    </div>
          
                    <div class="col-md-3">
                      <label for="apellidoPaterno"> APELLIDO PATERNO </label>
                    </div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        maxlength="70"
                        required
                        ngModel
                        name="apellidoPaterno"
                        id="apellidoPaterno"
                        class="form-control mayusculas"
                      />
                    </div>
          
                    <div class="col-md-3">
                      <label for="apellidoMaterno"> APELLIDO MATERNO </label>
                    </div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        maxlength="70"
                        required
                        ngModel
                        name="apellidoMaterno"
                        id="apellidoMaterno"
                        class="form-control mayusculas"
                      />
                    </div>
          
                    <div class="col-md-3">
                      <label for="fechaNacimiento"> FECHA DE NACIMIENTO </label>
                    </div>
                    <div class="col-md-3">
                      <input
                        type="date"
                        #edadForm
                        (blur)="generarEdad(edadForm)"
                        ngModel
                        name="fechaNacimientoPaciente"
                        id="fechaNacimientoPaciente"
                        class="form-control mayusculas"
                      />
                    </div>
          
                    <div class="col-md-3">
                      <label for="edad"> EDAD</label>
                    </div>
                    <div class="col-md-3">
                      <input
                        type="number"
                        min="0"
                        max="150"
                        ngModel
                        name="edad"
                        id="edad"
                        class="form-control"
                      />
                    </div>
          
                    <div class="col-md-3">
                      <label for="genero"> GÉNERO </label>
                    </div>
                    <div class="col-md-3">
                      <select
                        name="genero"
                        required
                        ngModel
                        id="genero"
                        class="form-control"
                      >
                        <option value="FEMENINO">FEMENINO</option>
                        <option value="MASCULINO">MASCULINO</option>
                      </select>
                    </div>


                    <div class="col-md-3">
                      <label for="sede"> SEDE </label>
                    </div>
                    <div class="col-md-5">
                      <select name="sede" ngModel id="sede" class="form-control">
                        <option [value]="item.nomenclatura" *ngFor="let item of sedes">{{item.nomenclatura}} - {{ item.encargado}}</option>
                      </select>
                    </div>
                              
                </div>
                <div class="center">
                    <button
                      mat-button
                      matStepperNext
                      class="principalButton"
                      (click)="enviar(form)"
                      [disabled]="btnActivar"
                    >
                      Agregar
                    </button>
                  </div>
            </mat-step>
        </mat-horizontal-stepper>
    </form>
    <spinner-perrucho></spinner-perrucho>
</div>
