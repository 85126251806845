<div class="container-fluid">

    
    
    <section class="row">
        <div class="col-md-7">
            
            <h1 class="centerxd">BITÁCORA DE ULTRASONIDO</h1>
        </div>
        <div class="col-md-3 offset-md-2">
            <button class="btn-censur" style="color: aliceblue;" (click)="imp()">IMPRIMIR</button>

        </div>

    </section>

</div>


<div class="center">
    <section style="padding-left: 3.5rem; padding-right: 3.5rem;">
        <div class="card" name="card" id="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col text_header">
                        <p> # </p>
                    </div>
                    <div class="col text_header">
                        <p>Fecha</p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre</p>
                    </div>
                    <div class="col text_header">
                        <p>Edad</p>
                    </div>
                    <div class="col text_header">
                        <p>Sexo</p>
                    </div>
                    <div class="col text_header">
                        <p>Sede</p>
                    </div>
                    <div class="col text_header">
                        <p>Estudio</p>
                    </div>
                    <div class="col text_header">
                        <p>Estado</p>
                    </div>

                </div>
            </div>

            <div class="card-body">
                <div *ngFor="let item of consultas | paginate:{id:'listaAmbulancia',
                    itemsPerPage: 3 ,
                    currentPage:pagina,
                    totalItems:totalAmbulancia} ; let i = index">
                    <div class="row" [routerLink]="['/hoja-ultrasonido', item._id]">

                        <div class="col color">
                            <p> {{ i +1 }} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.fechaDePedidoDeLosExamenes | date: "yyyy-MM-dd":"UTC"  }} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.idPaciente.nombrePaciente | uppercase}} {{item.idPaciente.apellidoPaterno | uppercase}} {{item.idPaciente.apellidoMaterno | uppercase}} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.idPaciente.edad}} </p>
                        </div>


                        <div class="col color">
                            <p> {{item.idPaciente.genero | generos:true }} </p>
                        </div>



                        <div class="col color">
                            <p> {{item.sede}} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.estudios[0].nombreEstudio}} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.prioridad}} </p>
                        </div>
                    </div>


                </div>
            </div>
            <div class="offset-md-1">
                <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </section>
</div>