import { Component, OnInit } from '@angular/core';
import { RecetasService } from 'src/app/services/recetas/recetas.service';
import { filterNameByPaciente  } from 'src/app/classes/helpers/filterNamePaciente';
@Component({
  selector: 'app-rectas-bitacora',
  templateUrl: './rectas-bitacora.component.html',
  styleUrls: ['./rectas-bitacora.component.css']
})
export class RectasBitacoraComponent implements OnInit {

  public filterNombre = "";
  public idUser = "";
  public recetas = [];
  public filterPacientesAux=[];
  constructor(
    private _rectasService:RecetasService
  ) { }

  ngOnInit(): void {

    this.obetenerIdDr();
    this.obtenerRecetasBitacora();
  }

  obetenerIdDr() {
    
   const dataUser = JSON.parse(localStorage.getItem('usuario'));
  //  console.log(dataUser);
    this.idUser = dataUser._id;
  }

  obtenerRecetasBitacora() {
    this._rectasService.verRecetasDR( this.idUser )
    .subscribe((data:any) => {
      // console.log(data)
      if(data.ok) {
        this.setRecetas(data.data);
      }
    })

  }

  setRecetas(data:any) {
    this.recetas = [];
    this.recetas = data;
    this.filterPacientesAux = data;
  }

  filterPacienteByName() {
    
    this.recetas = this.filterPacientesAux;

    if ( this.filterNombre.length > 3 ) {
      
      this.recetas = filterNameByPaciente( this.recetas, this.filterNombre ) 

    }else {
      this.recetas = this.filterPacientesAux;
    }

  }

}
