import { Component, OnInit } from '@angular/core';
import { MedicamentosService } from 'src/app/services/farmacia/medicamentos.service';
import{ ActivatedRoute, Router} from '@angular/router';
import { subscribeOn } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, NgForm } from '@angular/forms';



@Component({
  selector: 'app-folio',
  templateUrl: './folio.component.html',
  styleUrls: ['./folio.component.css']
})
export class FolioComponent implements OnInit {

  public medicamento = {
    nombreComercial:"",
    nombreDeSalOsustanciaActiva:"",
    presentacio:"",
    contenidoFrasco:"",
    viaDeAdministracion:"",
    lote:"",
    fechaCaducidad:"",
    laboratorio:"",
  }


  constructor( 
    public _agregarmedicamentoservice:MedicamentosService,
    public _router:Router,
    private spinner: NgxSpinnerService
    ){

   }

  ngOnInit(): void {
  }

  

  enviar(medicamentosNgForm: NgForm){
  this.spinner.show()
  // console.log(medicamentosNgForm);
  if(medicamentosNgForm.status == 'INVALID'){
    Swal.fire({
      icon: 'error',
      title: 'DEBES COMPLETAR TODOS LOS CAMPOS',
      
    })
    this.spinner.hide()

  }else {
  this._agregarmedicamentoservice.agragarmedicamentos(medicamentosNgForm.form.value).subscribe (
    (data:any) => {
      if(data.ok){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'MEDICAMENTO GUARDADO',
            })
        this._router.navigateByUrl('/dashboard');
        this.spinner.hide()
      }
    }
  )
  }
}

    
}

