import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HistoriaClinicaService } from '../../services/historiaClinica/historia-clinica.service';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 

@Component({
  selector: 'graficas-ninos',
  templateUrl: './graficas-ninos.component.html',
  styleUrls: ['./graficas-ninos.component.css']
})
export class GraficasNinosComponent implements OnInit {

  @Input() idConsulta : string;
  @Input()  paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

  public edad = 0;
  public talla = 0;
  public peso = 0;
  public imc = 0;
  public pc = 0;

  public signosVitales = {
    edad: 0,
    talla: 0,
    peso: 0,
    imc: 0
  }
  
  constructor(
    private _HistoriaClinicaService: HistoriaClinicaService,
    private _WsLoginService: WsLoginService

  ) { }

  ngOnInit(): void {
  }

  setPeso(){
    this.signosVitales.peso = this.peso;
    this.signosVitales.talla = this.talla;
    this.signosVitales.imc = this.imc;
  }
  
  
obtenerIMC(){

  this.imc = ((this.peso)/(this.talla * this.talla));
  let imcL = this.imc.toString();

  imcL.split(',', 2);

  let imcLn;
  imcLn = parseFloat(imcL).toFixed(2);

  this.imc = imcLn;
  
  this.recorrerEdadNinoPeso();
  this.recorrerEdadNinoTalla();
  this.recorrerEdadNinoIMC();
  this.recorrerAlturaCentimetrosNinos();
}

  ageragrSignos(){

    this.setPeso();
  
    this._HistoriaClinicaService.agregarSignosVitales( this.idConsulta,  this.signosVitales )
    .subscribe(  (data:any) => {
        /* console.log(data); */
      if(  data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS SIGNOS VITALES SE GUARDARON CORRECTAMENTE',
            })
          /* alert('Signos vitales guardados'); */
          // this.cambioDeEstadoCosnulta();
          this._WsLoginService.notificarDoctor(data);
      }
  
     });
  
  }

  recorrerEdadNinoTalla(){
    let edadEscala = this.paciente.edad;

    for(let i=0; i < edadEscala; i++){
      this.lineChartEstatura[2].data.push(0);


      if(this.lineChartEstatura[2].data.length == edadEscala){
        this.lineChartEstatura[2].data.push( this.talla);
      }
    }
  }

  recorrerEdadNinoPeso(){
    let edadEscala = this.paciente.edad;

    for(let i=0; i < edadEscala; i++){
      this.lineChartData[2].data.push(0);


      if(this.lineChartData[2].data.length == edadEscala){
        this.lineChartData[2].data.push( this.peso);
      }
    }
  }

  recorrerEdadNinoIMC(){
    let edadEscala = this.paciente.edad;

    for(let i=0; i < edadEscala; i++){
      this.lineChartIMC[2].data.push(0);


      if(this.lineChartIMC[2].data.length == edadEscala){
        this.lineChartIMC[2].data.push( this.imc);
      }
    }
  }

  recorrerEdadMesesNinoPC(){
    let edadMeses = this.paciente.edad * 12;
    // let transform = parseFloat(this.pc.toString())
    // transform = this.pc;
    // console.log(edadMeses);
    
    for(let i=0; i < edadMeses; i++){
      this.chartCefalicoNinos[2].data.push(0);


      if(this.chartCefalicoNinos[2].data.length == edadMeses){

        this.chartCefalicoNinos[2].data.push( this.pc );
        // console.log(this.chartCefalicoNinas[2]);
        
      }
    }
  }

  recorrerAlturaCentimetrosNinos(){
    let alturaCentimetros  = this.talla * 100;
    let pesoAltura = this.peso;
    
   
    
    
    for(let i=73; i < alturaCentimetros; i+=2){
      
      
      this.chartPesoPorTallaNinos[2].data.push(0); 
      
      // this.chartPesoPorTallaNinas[2].data.push(0);
      /* console.log('Peso', pesoAltura);
      console.log('Altura', alturaCentimetros); */
      // console.log('Tamaño del Array', this.chartPesoPorTallaNinas[2].data.length);
      // console.log(this.chartPesoPorTallaNinas[2].data.length == this.peso);
      
      
      var tArray = this.chartPesoPorTallaNinos[2].data.length;
       
      }
      if(this.chartPesoPorTallaNinos[2].data.length == tArray){
  
        this.chartPesoPorTallaNinos[2].data.push( pesoAltura );
        // console.log(this.chartCefalicoNinas[2]);
        
      
      }

  }

  public chartPesoPorTallaNinos: ChartDataSets[] = [
    {data: [0, 0, 8.83, 9.23, 9.63, 10.03, 10.42, 10.83, 11.23, 11.64, 12.06, 12.49, 12.93, 13.38, 13.84, 14.32, 14.81, 15.33, 15.87, 16.43, 17.02, 17.65, 18.32, 19.03, 19.79], label: 'Mínimo'},
    {data: [0, 0, 12.03, 12.51, 12.99, 13.49, 14.01, 14.56, 15.14, 15.76, 16.42, 17.13, 17.90, 18.72, 19.59, 20.51, 21.47, 22.47, 23.51, 24.56, 25.61, 26.66, 27.67, 28.62, 29.49], label: 'Máximo'},
    {data: [], label: 'Acual'}
  ];
  public pesoPorTallaNinasEjeX: Label[] = ['73', '75', '77', '79', '81', '83', '85', '87', '89', '91', '93', '95', '97', '99', '101', '103', '105', '107', '109', '111', '113', '115', '117', '119', '121', '123', '125'];


  
  public lineChartData: ChartDataSets[] = [
    { data: [10.673, 11.633, 13.291, 14.990, 16.500, 17.900, 19.900, 22.076, 24.380, 26.562, 29.472, 32.600, 36.712, 41.149, 45.459, 49.866, 51.69, 53.148, 54.200], label: 'Peso Mínimo' },
    { data: [15.668, 18.045, 20.739, 24.461, 28.271, 32.149, 36.983, 39.666, 46.566, 48.863, 56.260, 63.308, 69.705, 76.420, 82.735, 88.504, 93.779, 97.245, 99.185, 102.300], label: 'Peso Máximo' },
    { data:[ ], label: 'Peso Actual'},

  ];

  // Grafica de estatura :D
  public lineChartEstatura: ChartDataSets [] = [
    { data: [.80, .84, .88, .96, 1.02 , 1.08, 1.15, 1.20, 1.26, 1.30, 1.33, 1.36, 1.43, 1.45, 1.50, 1.57, 1.58, 1.60, 1.62, 1.63, 1.66], label: 'Estatura Mínima'},
    { data: [.93, 1.03, 1.12, 1.20, 1.28, 1.33, 1.36, 1.44, 1.50, 1.54, 1.57, 1.64, 1.70, 1.73, 1.76, 1.80, 1.83, 1.85, 1.88, 1.89, 1.90], label: 'Estatura Máxima'},
    { data: [ ], label: 'Estatura Actual'}
  ];

  // Grafica de IMC
  public lineChartIMC: ChartDataSets [] = [
    { data: [14.5, 14.09, 13.81, 13.64, 13.54, 13.52, 13.57, 13.78, 13.96, 14.28, 14.68, 15.10, 15.66, 16.16, 16.77, 17.28, 17.85, 18.30, 19.21], label: 'IMC Mínimo'},
    { data: [19.66, 18.69, 18.23, 18.44, 19.10, 20.08, 21.23, 22.37, 23.72, 24.92, 26.03, 27.02, 27.88, 28.63, 29.30, 29.88, 30.61, 31.20, 32.10], label: "IMC Máximo"},
    { data: [ ], label: "IMC Actual"}

  ];

  public chartCefalicoNinos: ChartDataSets[] = [
    { data: [35.48, 36.98, 38.13, 39.04, 39.79, 40.43, 40.97, 41.44, 41.86, 42.22, 42.55, 42.84, 43.10, 43.34, 43.55, 43.75, 43.93, 44.10, 44.25, 44.39, 44.51, 44.63, 44.74, 44.84, 44.94, 45.02, 45.10, 45.18, 45.24, 45.31, 45.37, 45.39, 45.42, 45.43, 45.44, 45.45, 45.50], label: 'P.C. Mínimo'},
    { data: [38.12, 40.97, 42.22, 43.20, 44.00, 44.68, 45.26, 45.77, 46.23, 46.64, 47.01, 47.34, 47.65, 47.94, 48.20, 48.45, 48.68, 48.90, 49.10, 49.30, 49.48, 49.65, 49.81, 49.97, 50.12, 50.26, 50.39, 50.52, 50.65, 50.77, 50.88, 50.99, 51.01, 51.02, 51.03, 51.10, 51.20], label: 'P.C. Máximo'},
    { data: [], label: 'P.C. Actual'}
  ];

  public cefalicoNinosEjeX: Label[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'];


  public lineChartLabels: Label[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'];

  // Eje de las X
  public lineChartEjeX: Label[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']


  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(0,0,2555,0.2)',
          },
          ticks: {
            fontColor: 'black',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // Rosa Bajito
      // backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: ' #0f5be7',
      // pointBackgroundColor: '#ea3af0',
      // pointBorderColor: '#ea3af0',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      // backgroundColor: 'rgba(0,0,255,1)',
      borderColor: ' #3a72db',
      // pointBackgroundColor: ' #f40bfc',
      // pointBorderColor: ' #f40bfc',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      // backgroundColor: 'rgba(0,255,0,0.6)',
      borderColor: ' #0e3275',
      // pointBackgroundColor: 'rgb(241, 29, 171)',
      // pointBorderColor: 'green',
      // pointHoverBackgroundColor: 'green',
      // pointHoverBorderColor: 'rgba(0,255,0,0.6)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  @ViewChild (BaseChartDirective, { static: true }) chart: BaseChartDirective;

  //Graficas Funciones
  //Inicio Funciones Grafica
public randomize(): void {
  for (let i = 0; i < this.lineChartData.length; i++) {
    for (let j = 0; j < this.lineChartData[i].data.length; j++) {
      this.lineChartData[i].data[j] = this.generateNumber(i);
    }
  }
  this.chart.update();
}

private generateNumber(i: number) {
  return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
}

// events
public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  // console.log(event, active);
}

public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  // console.log(event, active);
}

public hideOne() {
  const isHidden = this.chart.isDatasetHidden(1);
  this.chart.hideDataset(1, !isHidden);
}

public pushOne() {
  this.lineChartData.forEach((x, i) => {
    const num = this.generateNumber(i);
    const data: number[] = x.data as number[];
    data.push(num);
  });
  this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
}

public changeColor() {
  this.lineChartColors[2].borderColor = 'green';
  this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
}

public changeLabel() {
  this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  // this.chart.update();
}
//fin graficas funciones


}
