<app-ficha-info-hopitalizado></app-ficha-info-hopitalizado>
<div class="container-fluid">
    <app-titulos [title]="'ESTADO DE CUENTA DEL PACIENTE HOSPITALIZADO'"></app-titulos>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card-hospi">
                <div class="card-heder-hospi">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>No.</p>
                        </div>
                        <div class="col text_header">
                            <p>Fecha</p>
                        </div>
                        <div class="col text_header">
                            <p>Concepto(Seguimiento)</p>
                        </div>
                        <div class="col text_header">
                            <p>Monto</p>
                        </div>
                        <div class="col text_header">
                            <p>Anticipo</p>
                        </div>
                        <div class="col text_header">
                            <p>Adeudo</p>
                        </div>
                    </div>
                </div>
                <div class="card-body mayus">
                    <div *ngFor="let item of [1,1,1]">
                        <div class="row sombra">
                            <div class="col-md-1" style="text-align: center;">
                                <p>1</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>12/12/2022</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>Cirugia laparoscopica</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>$25,000 MX</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>$5,000 MX</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>$20,000 MX</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button>agregar al carrito</button>
                </div>
                <!-- <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div> -->
            </div>
        </div>
    </div>    
</div>