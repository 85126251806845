
<div class="container-fluid">
    
    <section class="row">
        <div class="col-md-12">
            <h1 class="title-principal-bs">  Productos </h1>

        </div>

    </section>
    
    <section  class="row" >
        <div class="col-md-7 txtSearch">
            <input type="text" name="txtSearch" id="txtSearch" class="input-censur"  (keyup)="filterProductByName()"  [(ngModel)]="txtSearchFilter"  autofocus="autofocus" />
        </div>
    </section>

    <div class="row bitacora">
        <div class="col-md-9 col-lg-9">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            No
                        </div>
                        <div class="col-md-3 text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p> NOMBRE COMERCIAL </p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>PROVEEDOR</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p> PRECIO </p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p> CANTIDAD </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                     <div *ngFor="let producto of products; let i = index">
                        <div class="row pointer">

                        <!-- [routerLink]="['/ver/disponente', paciente._id]" -->
                           
                            <div class="col-1">
                                {{  i +1 }}
                            </div>
                             <div class="col-3">
                                <p  (click)="addToCart( producto )" >
                                    {{ producto.nombre  }} 
                                </p> 
                            </div>
                            <div class="col-2">
                                <p>
                                    {{ producto.nombre_comercial }}
                                </p>
                            </div>
                            <div class="col-2">
                                {{ producto.proveedor[0].proveedor }}
                            </div>
                            <div class="col-2">
                                <p  class="text-center">
                                    <!-- {{ producto }} -->
                                    {{ producto.precio }}
                                </p>
                            </div>
                            <div class="col-md-2">
                                <p class="text-center">  
                                    {{ producto.existencia }}
                                </p>
                                <!-- [routerLink]="['/ver/disponente', producto._id]" -->
                            </div>
                        </div> 
                    </div>

                </div>
                <div class="offset-md-4">
                    <!-- <pagination-controls id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls> -->
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="row">
                
                <div class="col-md-12">
                    
                    <div class="card card-carrito">
                        <div class="row">

                            <div class="col-md-12">
                                <h3 class="text-center mt-4">
                                    Carrito
                                </h3>
                            </div>

                        </div>
                        <hr>

                        <div class="row carrito-products"  *ngFor="let item of carrito.items; let i = index"  >
                            <div class="col-1">
                                <p>
                                    {{i+1}}
                                </p>
                            </div>
            
                            <div class="col-6">
                                <p>
                                    {{item.producto}}
                                </p>
                            </div>
            
                            <div class="col-3" id="pointer" (click)="agregarCarrito(item)">
                                <p>
                                    {{item.precio |currency}}
                                </p>
                            </div>
                            <div class="col">
                                <i class="fas fa-trash-alt colorRed pointer"  (click)="eliminar(i)"  ></i>
                            </div>
                        </div>

                        <div class="row footer-carrito">
                            <hr>
                            <div class="col-md-12">

                                <p class="text-center" >
                                    Total: {{ carrito.total | currency }}
                                </p>
                                <button  [disabled]="btnValidate" [routerLink]="['/pago/almacen']"  class="btn-censur btn-pago">
                                    PAGAR
                                </button>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
