<section class="row m-0">
  <div class="col-md-9 p-0">
    <h1 class="title" id="centerxd" style="padding-left: 10rem">
      DETALLE DEL PRODUCTO
    </h1>
  </div>
  <div class="col-md-auto p-0">
    <div
      class="btn rounded-pill"
      style="background-color: #00acd8; color: #fff; padding: 0.375rem 5rem"
    >
      IMPRIMIR
    </div>
  </div>
</section>
<div class="row m-0" style="padding-left: 9rem">
  <div class="col-md-7 mt-4 mb-4">
    <div class="form-group">
      <input
        type="text"
        name=""
        placeholder="Buscar..."
        class="form-control rounded-pill pl-4"
        style="box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.375) !important"
      />
    </div>
  </div>
</div>

<section class="container-fluid">
  <div
    class="card textos"
    name="card"
    id="card"
    style="border-radius: 2.5rem !important"
  >
    <div class="row mt-4">
      <div class="col ml-4">
        <p>
          <span> NOMBRE: </span
          ><span class="ml-5">{{ productInfo.nombre }}</span>
        </p>
      </div>
      <div class="col ml-4">
        <p>
          <span> ID:</span><span class="ml-5">{{ productInfo._id }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col ml-4">
        <p>
          <span> TIPO DE PRODUCTO:</span
          ><span class="ml-5">{{ productInfo.tipo_producto }}</span>
        </p>
      </div>
      <div class="col ml-4">
        <p>
          <span> PRECIO:</span
          ><span class="ml-5">$ {{ productoInfoPrecio }} MX</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col ml-4">
        <p>
          <span> PROVEEDOR:</span
          ><span class="ml-5">{{
            productInfo.proveedor[0].proveedor ||
              productInfo.proveedor[0] ||
              "Ninguno"
          }}</span>
        </p>
      </div>
      <div class="col ml-4">
        <p>
          <span> FACTURA DE ORIGEN:</span><span class="ml-5">FACTURA XYZ</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col ml-4">
        <p>
          <span> DESCRIPCIÓN: </span>
          <span class="ml-5">{{ productInfo.descripcion }}</span>
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <hr class="col-11" style="border-top: 2.5px solid rgba(0, 0, 0, 0.7)" />
    </div>

    <div class="row pl-5 pr-5 ml-5 mr-5 mb-4">
      <div class="col-3">
        <div class="row">
          <div>
            <p class="d-flex justify-content-center align-items-center m-0">
              <i class="fas fa-boxes fa-4x"></i>
            </p>
          </div>
          <div class="justify-content-center d-flex flex-column pl-3 pr-3">
            <p class="mgb d-flex justify-content-center m-0">
              <span>STOCK</span>
            </p>
            <p class="d-flex justify-content-center mb-0">
              <span>20 UNIDADES</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div>
            <p class="d-flex justify-content-center align-items-center m-0">
              <i class="fas fa-cart-arrow-down fa-4x"></i>
            </p>
          </div>
          <div class="justify-content-center d-flex flex-column pl-3 pr-3">
            <p class="mgb d-flex justify-content-center m-0">
              <span>VENTAS</span>
            </p>
            <p class="d-flex justify-content-center mb-0">
              <span>120 UNIDADES</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div>
            <p class="d-flex justify-content-center align-items-center m-0">
              <i class="far fa-hospital fa-4x"></i>
            </p>
          </div>
          <div class="justify-content-center d-flex flex-column pl-3 pr-3">
            <p class="mgb d-flex justify-content-center m-0">
              <span>OTROS HOSPITALES</span>
            </p>
            <p class="d-flex justify-content-center mb-0">
              <span>35 UNIDADES</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div>
            <p class="d-flex justify-content-center align-items-center m-0">
              <i class="far fa-caret-square-down fa-4x"></i>
            </p>
          </div>
          <div class="justify-content-center d-flex flex-column pl-3 pr-3">
            <p class="mgb d-flex justify-content-center m-0">
              <span>CANTIDAD MINIMA</span>
            </p>
            <p class="d-flex justify-content-center mb-0">
              <span>10 UNIDADES</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="row m-0">
  <div class="col p-0">
    <div class="card" style="border: none">
      <div class="header__product--detail">
        <div class="row">
          <div class="col-1 text_header pl-0">
            <p>No.</p>
          </div>
          <div class="col-1 text_header pl-0">
            <p>ID</p>
          </div>
          <div class="col-1 text_header pl-0">
            <p>LOTE</p>
          </div>
          <div class="col-2 text_header pl-0">
            <p>LOTE UNITARIO</p>
          </div>
          <div class="col-2 text_header pl-0">
            <p>LUGAR DE ORIGEN</p>
          </div>
          <div class="col-2 text_header pl-0">
            <p>PROVEEDOR</p>
          </div>
          <div class="col-1 text_header pl-0">
            <p>FACTURA</p>
          </div>
          <div class="col-1 text_header pl-0">
            <p>DESTINO</p>
          </div>
          <div class="col-1 text_header pl-0">
            <p>STATUS</p>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 1rem 0rem !important">
        <!-- <div *ngFor="let item of pacientes; let i=index">
                    <div class="row" id="pointer" [routerLink]="['/historia/clinica/doctor', item._id]">
                        <div class="col-md-1" style="text-align: left;">
                            <p>
                                {{i + 1}}
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                {{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }}
                            </p>
                        </div>
                        <div class="col-md-3" style="text-align: center;">
                            <p>
                                {{item.paciente.curp | uppercase}}
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                {{item.paciente.edad}}
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                {{item.paciente.genero | uppercase}}
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                {{item.paciente.lugardeorigen | uppercase}}
                            </p>
                        </div>
                    </div>
                </div> -->

        <!-- SOLO SON DEMOSTRATIVOS -->

        <div
          class="row"
          *ngFor="let producto of listProductVentas; let i = index"
        >
          <div class="col-1 pl-0">
            <p class="textos-center">{{ i + 1 }}</p>
          </div>
          <div class="col-1 pl-0">
            <p class="textos-center" style="word-wrap: break-word">
              {{ producto._id }}
            </p>
          </div>
          <div class="col-1 pl-0">
            <p class="textos-center">{{ producto.lote }}</p>
          </div>
          <div class="col-2 pl-0">
            <p class="textos-center">{{ producto.lote_unitario }}</p>
          </div>
          <div class="col-2 pl-0">
            <p class="textos-center">OTROS HOSPITALES</p>
          </div>
          <div class="col-2 pl-0">
            <p class="textos-center">{{ producto.proveedor }}</p>
          </div>
          <div class="col-1 pl-0">
            <p class="textos-center">{{ producto.factura }}</p>
          </div>
          <div class="col-1 pl-0">
            <p class="textos-center">USUARIO FINAL</p>
          </div>
          <div class="col-1 pl-0">
            <p class="textos-center">{{ producto.status }}</p>
          </div>
        </div>
      </div>
      <div class="offset-md-4">
        <!-- <pagination-controls
          id="listaproductos"
          maxSize="5"
          directionLinks="true"
          (pageChange)="pagina = $event"
        ></pagination-controls> -->
      </div>
    </div>
  </div>
</div>
