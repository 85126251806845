import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';

@Component({
  selector: 'app-select-medicos',
  templateUrl: './select-medicos.component.html',
  styleUrls: ['./select-medicos.component.css']
})
export class SelectMedicosComponent implements OnInit {

  @Output() MedicoSelected : EventEmitter<string> = new EventEmitter();

  public medicos = [];

  constructor(
    private _medicosService: PaquetesService
  ) { }

  ngOnInit(): void {
    this.obtenerMedicos();
  }



  obtenerMedicos() {
    this._medicosService.getMedicos()
    .subscribe((data:any) => {
      if(data.ok) {
        this.setMedicos(data.data);
      }
      
    })
  }
  
  setMedicos(data) {
    this.medicos = data;
  }

  changeMedico( medico ){
    this.MedicoSelected.emit( medico );
  }

}
