//libs
import * as moment from 'moment';

var initValues = {
    laboratorio : 0, endoscopia : 0, ultrasonido : 0, rayosX : 0, patologia:0,
    countLab :0 , countEndos :0, countUsg:0, countRayosX :0, countPatologia:0,
    totalPatologia: 0,
    promedioPatologia : 0

}

//json de los pedidos de los labs
var valueSell  = { 
    idServicio:"",
    paciente:'',
    nombreServicio:"",
    sede:"",
    hora:"",
    doctorSolicito:"",
    idVenta:"",
   };

export const filterNameByPaciente = (pacientes = [], name) => {

    //recibe el array de los pacientes
    // recibe el parametro de busqueda

    const pacientesFilter = [];

    pacientes.forEach( (pacientes:any) => {
        
        var  nombrePaciente = ""
        var   apellidoPaterno = ""
        var    apellidoMaterno = ""

        if( pacientes.idPaciente == undefined ) {

            //validacion para los populates de la data
            nombrePaciente = pacientes.nombrePaciente
            apellidoPaterno = pacientes.apellidoPaterno
            apellidoMaterno  = pacientes.apellidoMaterno

        }else {

            // si la data del paciente no trae la data dentro del populate 
             nombrePaciente = pacientes.idPaciente.nombrePaciente
             apellidoPaterno = pacientes.idPaciente.apellidoPaterno
             apellidoMaterno  = pacientes.idPaciente.apellidoMaterno
        }

        nombrePaciente.trim() 
        apellidoPaterno.trim()
        // ponemos el trim de los nombres

        nombrePaciente.toUpperCase(); 
        apellidoPaterno.toUpperCase();

        // concatenamos los apellidos  al nombre
        nombrePaciente += " "+apellidoPaterno         
        nombrePaciente += " " +apellidoMaterno;


        if(nombrePaciente.includes(name.toUpperCase())){
            pacientesFilter.push( pacientes );
            //agregamos al array los pacientes
        }

    });

    //retorna los pacientes encontrados en el array
    return pacientesFilter;
}


export function buscarPaciente( pacientes:any , busqueda:any ) {
    const pacientesFilter = [];
    pacientes.forEach(element => {
        let nombreCompleto = element.nombrePaciente.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "").concat(" "+ element.apellidoPaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "")+" "+element.apellidoMaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
        if(nombreCompleto.toUpperCase().includes(busqueda.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "").toUpperCase())){
            pacientesFilter.push(element);
        }
    });
    return pacientesFilter;
}

export const calcDeparmentsPorcentaje = ( element:any )=> {
    let porcentaje = 0
        if(element.name == 'laboratorio' ) {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'xray') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'patologia') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'ultrasonido') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'consulta') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'endoscopia') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'resonancia') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'tomografia') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'resonancia') {
            porcentaje = sumPorcentajes(element)
        }else if(element.name == 'otros') {
            porcentaje = sumPorcentajes(element)
        }
    return porcentaje;
} 

const sumPorcentajes = ( element:any ) =>{
    let porcentaje = 0;
    for (const key in element) {
        let nombre = key
        if(nombre.includes(element.rangoUtilidad)){
            for (const keey in element[key][0]) {
                porcentaje = element[key][0][keey]
            }
        }
    }
    return porcentaje;
}

// OBTENEMOS LA SEDE DEL PACIENTE
export const getSede = () =>{
    // OBTENEMOS LA SEDE DEL PACIENTE
    const {sede} =  JSON.parse( localStorage.getItem('paciente'))
    return sede;
}


// OBTENEMOS EL NOMBRE DEL LA SESION EL USUARIO
export const getSessionName  = () =>{
    // OBTENEMOS UNICAMENTE EL NOMBRE DEL USUARIO
    const {nombre} =  JSON.parse( localStorage.getItem('usuario'));
    return nombre;
}


// esta funcion nos valida que los metodos de pago no sea mayores al anticipo
export const validateMontos = (montos, anticipo) => {

    const { 
        montoEfectivo,
        montoTarjetaDebito,
        montoTarjteCredito,
        montoTranferencia 
    } = montos;
    // enviamos la infor venta y hacemos el destructuring
 
    if(  montoEfectivo !=anticipo ){
        // comparamos los metodos de pago con el anticipo

        return false;

    }else if( montoTarjetaDebito !=anticipo ){
        return false;
    
    }else if( montoTarjteCredito !=anticipo) {
        return false;

    } else if( montoTranferencia  !=anticipo) {
        return false;

    }
    return true;
}


export const validateAnticipoLess = ( montos, anticipo ) =>  {
    
    const metodoPago = {
        metodo:"",
        valid: true
    }

    const { 
        montoEfectivo,
        montoTarjetaDebito,
        montoTarjteCredito,
        montoTranferencia,
        efectivo,
        transferencia,
        tarjetCredito,
        tarjetaDebito

    } = montos;
    // enviamos la infor venta y hacemos el destructuring

    
    if( efectivo == "true" ) {
        if(  montoEfectivo  >=   anticipo ){
        
            // comparamos los metodos de pago con el anticipo
            
            metodoPago.metodo = "Efectivo"
            return metodoPago 
        
        }
    }else if( tarjetCredito == 'true' ){

        if(  montoTarjteCredito  >=   anticipo ){
        
            // comparamos los metodos de pago con el anticipo
            
            metodoPago.metodo = "TarjetaCredito"
            return metodoPago 
        
        }
    }else if( tarjetaDebito == 'true' ) {

        if(  montoTarjetaDebito  >=   anticipo ){
        
            // comparamos los metodos de pago con el anticipo
            
            metodoPago.metodo = "TarjetaDebito"
            return metodoPago 
        
        }

    }else if( transferencia == 'true' ) { 

        if(  montoTranferencia  >=   anticipo ){
        
            // comparamos los metodos de pago con el anticipo
            
            metodoPago.metodo = "transferencia"
            return metodoPago 
        
        }
    }
    return false;
};



// setea en el local storage el nombre del medico
export const setNameMedicoSelected = ( nameMedico = ""  )  => {

    localStorage.setItem('medicoSolicito', nameMedico);
}

//funcion que se encarga de stear los datos de los pedidos 
export const setDataPedido = ( carrito , idPaciente = "", idVenta = "" ) => {
    
    var arrEstudios  = [];

    carrito.estudios.forEach((element:any, index) => {
        setValuesFromSells('nombreServicio', "");
        setValuesFromSells('idServicio',  "" );
        //resetamos loa variables
        // setetamos las variables
        setValuesFromSells('nombreServicio', element.name)
        setValuesFromSells('idServicio',  element.idEstudio );
        setValuesFromSells('paciente', idPaciente );
        setValuesFromSells('idVenta', idVenta );
        valueSell.sede = getSede();
        valueSell.hora = moment().format('LT');
        arrEstudios.push( valueSell );
    });
    
    return arrEstudios;
}

const setValuesFromSells = (key = "", value) => {
    valueSell[key] = value;
}

// funcion que calcula las fechas de nacimiento de acuerdo al curp
export const calcAge =  ( curp = "" ) => {
    //en el curp es anio/mes/dia
  const agesCutted  =  curp.slice( 4, -8 );  
  
  let year = agesCutted.slice(0,2 )
  let month = agesCutted.slice(2,4 )
  let day = agesCutted.slice(4,6 )
   year  = "19" + year;
   const newDate =  year+"-"+month+"-"+day;
   return newDate
};
