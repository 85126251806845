<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'HOJA DE SERVICIO'"></app-titulos>
        </div>
    </div>
    <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col text_header">
                            <p>No</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTADO</p>
                        </div>
                    </div>
                </div>
                <!--[routerLink]="['/hoja-reporte',item.nombreServicio ,item._id]"  -->
                <div class="card-body">
                    <div *ngFor="let item of estudios| paginate:{id:'listapacientes',
                    itemsPerPage: 4,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index"
                    (click)="navegar(item)">
                        <div class="row pointer" >
                            <div class="col">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idServicio.ESTUDIO}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.status}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>