import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hoja-de-servicios-patologia',
  templateUrl: './hoja-de-servicios-patologia.component.html',
  styleUrls: ['./hoja-de-servicios-patologia.component.css']
})
export class HojaDeServiciosPatologiaComponent implements OnInit {


  public pedido = {
    estudios:[],
    idPaciente: {
      nombrePaciente:"",
      apellidoPaterno:"",
      apellidoMaterno:"",
      edad:0,
      genero:"",
      telefono:"",
      fechaNacimientoPaciente:"",
      _id:""
    },
    prioridad: "",
    sede:"",
    _id:""
  }
  public id ="";
  
  constructor(
    private _patologiaService: PatologiaService,
    private _route: ActivatedRoute
    ) { }
    
    ngOnInit(): void {
      this.id = this._route.snapshot.paramMap.get('id');
      this.obtenerPatologia();
  }

  obtenerPatologia() {
    this._patologiaService.verPedidoPorId( this.id )
    .subscribe((data:any) => {
      
      if(data.ok) {
        this.pedido = data.data;
      }else {

        Swal.fire('error', 'El pedido no es correcto', 'error');
      }
   
    })}
 
    setLocalStorageData(){

      localStorage.setItem('idPaciente', this.pedido.idPaciente._id);
      localStorage.setItem('idPedido', this.pedido._id);
    }
    
}
