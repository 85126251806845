<section class="row">

    <div class="col-md-12" id="titulo">
        <!-- <h1 class="centerxd" id="centerxd">Revisión de la orden de compra</h1> -->
        <app-titulos [title]="'Revisión de la orden de compra'" [rol]="'pago'"></app-titulos>
    </div>
    
    
</section>

<section class="row" id="container">
    
    <div class="col">
        <div class="card card-info-user" id="card">
            <div class="row" id="metodos">
                <div class="col-md-12 text-center mt-2 title"> Método de pago</div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <input type="radio" id="efectivo" name="efectivo" value="true" [(ngModel)]="infoVenta.efectivo"   >
                    <label for="efectivo">Pago en efectivo</label>

                    <div *ngIf="infoVenta.efectivo">
                        <input type="number" min="0" class="input-censur input-pago-almacen" placeholder="Ingresa el monto" name="montoEfectivo" id="montoEfectivo" [(ngModel)]="infoVenta.montoEfectivo" (blur)="restTotal()">
                    </div>
                
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input type="radio" id="transferencia" name="transferencia" value="true" [(ngModel)]="infoVenta.transferencia">
                    <label for="transferencia">Transferencia bancaria</label>

                    <div *ngIf="infoVenta.transferencia">

                        <input type="number" min="0" class="input-censur input-pago-almacen" placeholder="Ingresa el monto" name="montoTranferencia" id="montoTranferencia" [(ngModel)]="infoVenta.montoTranferencia" (blur)="restTotal()">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input type="radio"  id="tarjetaDeCredito" name="tarjetaDeCredito" value="true" [(ngModel)]="infoVenta.tarjetaCredito">

                    <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                    <br>
                    <div *ngIf="infoVenta.tarjetaCredito">

                        <input type="number" min="0" class="input-censur input-pago-almacen" placeholder="Ingresa el monto" name="montoTarjetaCredito" id="montoTarjteCredito" [(ngModel)]="infoVenta.montoTarjetaCredito" (blur)="restTotal()">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input type="radio"  id="tarjetaDeDebito" name="tarjetaDeDebito" value="true" [(ngModel)]="infoVenta.tarjetaDebito">

                    <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                    <br>
                    <div *ngIf="infoVenta.tarjetaDebito">

                        <input type="number" min="0" class="input-censur input-pago-almacen" placeholder="Ingresa el monto" name="montoTarjetaDebito" id="montoTarjetaDebito" [(ngModel)]="infoVenta.montoTarjetaDebito" (blur)="restTotal()">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col" id="boton">
                    <button class="btn-censur" (click)="addToPay()"  [disabled]="btnDisabled" > 
                        ORDENAR
                    </button>
                </div>
            </div>
    </div>

    </div>
    <div class="col">

        <div class="card" id="card">
            <div class="" id="cardheader">
                <div class="row">
                    <div class="col-md-12 title text-center"> Producto</div>
                </div>
            </div>
            <hr>

            <div class="row" *ngFor="let item of carrito.items" >
                <div class="col-6">
                    {{ item.producto }}
                </div>
                            
                <div class="col-4">
                    <p class="mt-2">
                        Cantidad  
                        {{  item.cantidad }}
                    </p>
                </div>
                
                <div class="col-2" >
                    {{  item.precio | currency }}
                </div>
                            
            </div>

            <div class="" id="cardfooter">
                <div class="row total-pago">

                    <div class="col-md-12">
                        <h4 class="text-center title-principal-bs" >
                            Total:  {{ totalRest | currency }}

                        </h4>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <spinner-perrucho></spinner-perrucho>
</section>