<div class="container-fluid">
  <p class="estilos" *ngFor="let sedes of sede; let i = index" >{{sedes.clinica}}</p>
</div>
<body>
  

 <div class="row" *ngFor="let sedes of sede; let i = index"  >
    <div class="colu" >
      <div class="card mb-2">
        <picture *ngFor="let img of this.sede.imagencli; let i = index"
                            class="col-2 me-2 mt-2 position-relative">
                            
                            <img [src]=img class="w-100 imagenes rounded border border-4 border-white"
                                alt={{this.sede.clinica}} style="max-height: 150px">
                        </picture>
              <br>
              <h5 >Nombre:{{sedes.clinica}}</h5>
              <h5 >Razon Social:{{sedes.razon}}</h5>
              <h5 >RFC:{{sedes.rfc}}</h5>
              <h5>CONTACTO: {{sedes.contacto}}</h5>
              <h5 >DIRECCION: {{sedes.dirreccion}}</h5>
              <h5 >{{sedes.calle +' '+sedes.no_exterior+' '+sedes.postal+' '+sedes.municipio+' '+sedes.estado}}</h5>
      </div>
    </div>

    <div class="col col-lg-9 col-md-12" >
          <div class="largo"> 
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <div class="card">
                  <div class="card-heder header">
                    <div class="row text-white">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>
        
                          <div class="col-md-1 text_header">
                            <p>ID</p>
                          </div>
                          <div class="col-md-3 text_header">
                            <p>PRODUCTO A SOLICITAR</p>
                           </div>
                          <div class="col-md-2 text_header">
                            <p>PRODUCTOS ENTREGADOS</p>
                          </div>
                          <div class="col-md-2 text_header ">
                            <p>METODOS </p>
                          </div>
                          <div class="col-md-2 text_header">
                            <p>ESTADOS</p>
                          </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row" id="pointer">
                          <div class="col-md-1" style="text-align: left;">
                              <p class="tipografia">01</p>
                          </div>
                          <div class="col-md-2" style="text-align: center;">
                              <p class="tipografia">PEPRMS2034903</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">CONCENTRADO ELECTROCITARIO</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">SANGRE TOTAL</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">SOLICITUD</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">FINALIZADO</p>
                          </div>
                        
                       </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row" id="pointer">
                          <div class="col-md-1" style="text-align: left;">
                              <p class="tipografia">01</p>
                          </div>
                          <div class="col-md-2" style="text-align: center;">
                              <p class="tipografia">PEPRMS2034903</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">CONCENTRADO ELECTROCITARIO</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">12 donadores</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">INTERCAMBIO</p>
                          </div>
                          <div class="col" style="text-align: center;">
                              <p class="tipografia">FINALIZADO</p>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
     </div>
  </div>
</body>
  





