<form [formGroup]="rowEstudioForm">
    <div class="container-fluid">
        <h1 class="centerxd">EDITAR ESTUDIO DE LABORATORIO</h1>
        <div class="card" name="card" id="card">
            <div class="row" id="principal">
                <div class="col margin-info">
                    <p> <span>  NOMBRE DEL ESTUDIO</span> </p>
                    <p> <span>{{elementos}}</span> </p>
                </div>
                <div class="col">
                    <p> <span> TIPO DE EXAMEN</span> </p>
                    <input type="text" formControlName="tipo_de_examen" />
                </div>
            </div>

            <section formArrayName="valoresDeReferencia">
                <div *ngFor="let valor of rowValores().controls; let i = index">
                    <div [formGroupName]=i class="row">
                        <div class="col">
                            <p> <span>GRUPO </span> </p>
                            <input type="text" formControlName="grupo" />
                        </div>

                        <div class="col" style="margin-left: 20px;">
                            <p> <span>ELEMENTO</span> </p>
                            <input type="text" aria-describedby="basic-addon1" formControlName="elemento" />
                        </div>
                        <div class="col" style="margin-left: 20px;">
                            <p> <span>UNIDADES</span> </p>
                            <input type="text" aria-describedby="basic-addon1" formControlName="unidad" />
                        </div>
                        <!-- <button type="button" class="btn btn-success"   (click)="addRowReferenciasValore( i )" > Agregar referencias </button> -->
                        <div class="col-md-3">
                            <p><span>VALOR DE REFERENCIA</span></p>
                            <section formArrayName="valorDeReferencia">
                                <div *ngFor="let valor of rowValoresReferencia(i).controls; let j = index">
                                    <div [formGroupName]=j class="col">
                                        <div class="input-group mb-2">
                                            <input type="text" class="form-control" formControlName="valorReferenciaD" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1" (click)="addRowReferenciasValore(i)"> + </span>
                                            </div>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1" (click)="dropRowReferenciasValore(i,j)"> - </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div class="col buttons-content">
                            <button type="button" class="btn btn-success" (click)="addValores( i+1 )"> Agregar </button>
                            <button type="button" class="btn btn-danger btn-left" (click)="dropRowEstudioValore( i )"> Quitar </button>
                        </div>
                    </div>
                </div>
            </section>

        </div>

        <div class="card" name="card" id="card">
            <div class="col">
                <p><span>METODO</span> </p>
                <textarea name="" id="" cols="5" rows="10" formControlName="metodo"></textarea>
            </div>
            <div class="col">
                <p class="cent"><span>OBSERVACIONES</span></p>
                <textarea name="" id="" cols="10" rows="10" formControlName="observaciones"></textarea>
            </div>

        </div>

        <div class="boton">
            <button class="principalButton" (click)="sendDataPost()">ACTUALIZAR</button>
        </div>

    </div>
</form>