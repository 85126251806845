<div class="container-fluid">
    <app-titulos [title]="'DETALLE DEL MATERIAL'" ></app-titulos>
    <!-- ! FICHA DE IDENTIFICACIÓN DE LOS P/M/S   -->
    <app-ficha-indentificaciosproductos-almacen [dataFiProducto]="dataFi" [tipo]="'MATERIAL'" [stock]="materiales.length" ></app-ficha-indentificaciosproductos-almacen>
    <section class="mt-4 noprint" style="margin-bottom: 20px;">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="card">
                    <app-productos-stock [proveedores] = "proveedores" (proveedor)="obtenerForm($event)"></app-productos-stock>
                </div>
            </div>
        </div>
    </section>
    <br>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>
                        <div class="col-md-3 text_header">
                            <p>ID</p>
                        </div>
                        <div class="col text_header">
                            <p>LOTE</p>
                        </div>
                        <div class="col text_header">
                            <p>LOTE UNITARIO</p>
                        </div>
                        <!-- <div class="col text_header">
                            <p>LUGAR DE ORIGEN </p>
                        </div> -->
                        <div class="col text_header">
                            <p>PROVEDOR </p>
                        </div>
                        <div class="col text_header">
                            <p>FACTURA </p>
                        </div>
                        <div class="col text_header">
                            <p>QR</p>
                        </div>
                        <div class="col text_header">
                            <p>STATUS</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div  *ngIf="materiales.length === 0"> 
                        <div class="row">
                            <div class="col-md-12">
                                <p class="text-center">
                                    No hay materiales en Stock
                                </p>
                            </div>
                        </div>
                    </div>
                    <div  *ngFor="let material of materiales| paginate:{id:'listamateriales',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalMateriales}; let i = index">
                        <div class="row puntero">
                            <div class="col-md-1" style="text-align: center;">
                                <p>
                                    {{ i +1 }}
                                </p>
                                <span class="puntero"></span>
                            </div>
                            <div class="col-md-3" style="text-align: center;">
                                <p>
                                    {{ material._id }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ material.lote }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{material.lote_unitario}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ material.proveedor }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ material.factura }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <img class="img-responsive" [src]="material.codeQr_img"  #imgElement/>
                            </div>
                            <div class="col" style="text-align: center;">
                                <div class="row">
                                    <div class="col">
                                        <p class="text-header-black puntero" [ngClass]="{'colorRedDownloaded':  material.downloaded== true }" (click)="imprimirEtiquetaIndividual(imgElement, material._id )">
                                            <i class="fas fa-download" style="font-size:36px"></i>  
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listamateriales" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <section class="row mt-4 noprint">
        <div class="offset-md-4 col-md-2">
            <button class="btn btn-product btn-block color-bg-blue" [disabled]="btnValidate" (click)="registrarMaterialesEnStock()"> Agregar </button>
        </div>
        <div class="col-md-2">
            <button  class="btn btn-product btn-block color-bg-blue" (click)="printImgQr()">Descargar</button>
        </div>
    </section>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>
