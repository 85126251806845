<div class="container">
    <section>
        <app-titulos [title]="'ORDEN DE SERVICIO'" [rol]="'laboratorio'"></app-titulos>
        <!-- <app-ficha-laboratorio-disponente  [titulo]="'ORDEN DE SERVICIO'" [pacienteInfo]="paciente"></app-ficha-laboratorio-disponente> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'laboratorio'"></app-fichas-identificacion>
    </section>
</div>

<div class="card cardPer card-paddin-title container">
    <div class="row mb-3">
        <h2 class="font-color p-2"> Grupo y RH</h2>

    </div>
    <div class="row">
        <div class="col">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <h4>GRUPO</h4>
                </div>
                <select class="custom-select" id="inputGroupSelect01" name="grupo" [(ngModel)]="sendaDataPostGRH.grupo">
                  <option value="" selected>Elige Uno...</option>
                  <option [value]="grupo.grupo + '' + grupo.elemento"   *ngFor="let grupo of dataGrupoYRh" > {{ grupo.grupo }} {{ grupo.elemento }} </option>
                </select>
            </div>
        </div>
        <!-- <div class="col">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <h4>FACTOR RH</h4>
                </div>
                <select class="custom-select" name="rh" [(ngModel)]="sendaDataPost.rh" id="inputGroupSelect01">
                  <option value="" selected>Elige Uno...</option>

                  <option value="1"> + </option>
                  <option value="2"> - </option>

                </select>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="title-card">

            <h2 class="mt-3 font-color">BIOMETRÍA HEMÁTICA COMPLETA</h2>
        </div>

    </div>

    <div class="row letras mt-3 mb-3">
        <div class="col">
            <h3>elementos</h3>
        </div>
        <div class="col">
            <h3>Resultados Obtenidos</h3>
        </div>
        <div class="col">
            <h3>valores de referencia</h3>
        </div>
    </div>

    <form #f='ngForm'>


        <div *ngFor="let estudio of valoresEstudios.valoresDeReferencia; let i = index">
            <!-- container de todos los rows -->
            <div class="row letras">
                <div class="col-md-4">
                    <h3>{{ estudio.elemento }}</h3>
                </div>
                <div class="col-md-4">
                    <input class="res input-shadow" [name]="estudio.elemento+i" required ngModel type="text" />
                </div>

                <div *ngFor="let valoresRef of estudio.valorDeReferencia">

                    <h3> {{ valoresRef.valorReferenciaD }} </h3>

                </div>

            </div>
        </div>
        <!-- fin del container de todos los rows -->
    </form>

    <!--     <div class="row letras mt-5">
        <h3 class="mr-4">¿puede el donador continuar con el proceso de donación?</h3>
        <div>
            <label for="" class="mr-1">SÍ</label>
            <input class="mr-3" type="radio"  id="aptoForm"  name="apto"  value="si" [(ngModel)]="sendaDataPost.apto" >
        </div>

        <div>
            <label for="" class="mr-1">NO</label>
            <input type="radio"   id="aptoForm"   value="no"  name="apto"  [(ngModel)]="sendaDataPost.apto" >
        </div>
    </div> 

    <div class="row letras mb-3">
        <textarea class="txtarea" name="descripcionTextArea" id="" cols="200" rows="3"   [(ngModel)]="sendaDataPost.descripcionTextArea"  ></textarea>

    </div> -->


    <div class="row letras mt-2">
        <h3>MÉTODOS: IMPLEMEDANCIA ELÉCTRICA TIPO V(BIOMETRIA HEMATICA COMPLETA) AGLUTINACIÓN EN TUBO (GRUPO Y RH)</h3>

        <span style="color: #000000">*Un diagnóstico clÍnico lo debe realizar un médico certificado y un resultado aislado en ocasiones es insuficiente para establecerlo</span>
    </div>

    <div class="center mt-4">
        <button class="btn-censur" (click)="ngSubmitForm( f )">
            GUARDAR
        </button>
    </div>

    <div class="center row mt-5 letras">
        <div class="col">
            <h3> Técnico: {{ sendaDataPost.tecnico }} </h3>
        </div>

        <div class="col">
            <h3>químico: pedro páramo vídal</h3>
            <h3>cédula: {{ sendaDataPost.cedula }} </h3>
        </div>
    </div>

    <section class="display-none" id="sppiner-section">
        <div class="row">
            <div class="sppiner-bg">
                <div class="spinner-stock">
                    <div class="col-md-12">
                        <app-sppiner></app-sppiner>
                    </div>
                </div>

            </div>
        </div>
    </section>

</div>