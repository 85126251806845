<!-- <app-calendario-personal></app-calendario-personal> -->
<div class="container-fluid">
    <app-titulos [title]="'AGENDA PERSONAL'" [rol]="'pacientes'"></app-titulos>

    <div class="row">
        <div class="col-md-3">
            <div class="card medico">
                <div class="perfil">
                    <img [src]="usuario.imagenUni" alt="" style="height: 150px; width: 150px;">
                    <div class="row datos">
                        <div class="col">
                            <div class="row">
                                <p>NOMBRE:</p>
                            </div>
                            <div class="row">
                                <p>ESPECIALIDAD:</p>
                            </div>
                            <div class="row">
                                <p>CEDULA PROFESIONAL:</p>
                            </div>
                            <div class="row">
                                <p>CEDULA ESPECIALIDAD:</p>
                            </div>
                            <div class="row">
                                <p>UNIDAD MEDICA:</p>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <p>{{usuario.nombre | uppercase}}</p>
                            </div>
                            <div class="row">
                                <p>{{usuario.especialidad | uppercase}}</p>
                            </div>
                            <div class="row">
                                <p>{{usuario.cedulaProfesional}}</p>
                            </div>
                            <div class="row">
                                <p>{{usuario.cedulaEspecialidad}}</p>
                            </div>
                            <div class="row">
                                <p>{{usuario.unidadMedica}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="card calendario">
                <app-calendario-personal [id]="id" [events]="events" [role]="'timeGridWeek'"></app-calendario-personal>
            </div>            
        </div>
    </div>
</div>
