<form action="" enctype="multipart/form-data">
    <div class="container">
        <h1 class="centerxd">
            REPORTE ULTRASONIDO
        </h1>
        <div class="col-md">
            <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
        </div>
        <!--div class="card" id="card" name="card"-->
        <div class="row center">
            <div class="container">

                <div class="card">
                    <div class="form-group row">
                        <h4 class="centerxd" style="margin-left: 1rem;">
                            {{ultrasonidos.ESTUDIO}}
                        </h4>
                    </div>
                    <h5 class="col mt-4" style="margin-left: 1rem;">
                        INTERPRETACION
                    </h5>
                    <div>
                        <textarea [(ngModel)]="holiwis" class="txtarea form-control textUSG container" name="macho"
                            id="campo" cols="120" rows="10" autocapitalize="characters" autocomplete="on">
                            {{holiwis}}
                        </textarea>
                    </div>
                </div>
                <br>
                <div class="card">
                    <h5 class="col mt-4" style="margin-left: 1rem;">
                        DIAGNOSTICO
                    </h5>
                    <div>
                        <textarea [(ngModel)]="diagnostico" class="txtarea form-control textUSG container" name="diag"
                            id="camo" cols="120" rows="2" autocapitalize="characters" autocomplete="on"></textarea>
                    </div>
                    <!--/div-->
                    <br>
                    <!--div class="card"-->
                    <h3 class="mt-4 col">
                        IMAGENES
                    </h3>

                    <div class="card">
                        <ngx-dropzone name="fileUploadTosend" (change)="onSelect($event)">
                            <ngx-dropzone-label>
                                <!-- <span class="material-icons">
                                    add
                                </span> -->
                                INSERTA LOS DOCUMENTOS AQUÍ
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview [file]="f" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                    </div>
                   
                    <!--/div-->

                    <br>
                    <!--div class="card"-->
                    <h5 class="col mt-4" style="margin-left: 1rem;">
                        OBSERVACIÓNES:
                    </h5>
                    <div>
                        <textarea [(ngModel)]="obs" class="txtarea form-control textUSG container" name="diag" id="camo"
                            cols="120" rows="2" autocapitalize="characters" autocomplete="on"></textarea>
                    </div>

                    
                    <div class="row">
                        <div class="col-md-4 offset-md-4">
                            <button class="btnPrincipalBS mt-3" (click)="enviarReporteUsg()">
                                Guardar
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <br>
        </div>
        <!--/div-->
    </div>
</form>

<section class="display-none" id="sppiner-section" >
    <div class="row">
        <div class="sppiner-bg">
            <div class="spinner-stock">
                <div class="col-md-12">
                    <app-sppiner></app-sppiner>
                </div>
            </div>
    
        </div>
    </div>

</section>