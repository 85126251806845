
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {  getDataStorage  } from '../../../functions/storage.funcion'
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import {  ConsultaService }  from '../../../services/consultas/consulta/consulta.service';
import { Cie10Service } from 'src/app/services/consultas/cie10.service';

import * as moment from 'moment';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiciosService } from 'src/app/services/admin/servicios.service';



@Component({
  selector: 'app-hevolucion-cg',
  templateUrl: './hevolucion-cg.component.html',
  styleUrls: ['./hevolucion-cg.component.css']
})
export class HevolucionCGComponent implements OnInit {

  public fecha: string;
  public hora: string;
  public id: string;
  public buscarDiagnostico: string;
  public diagnosticos: [] = [];

  // se le puso hoja de evilucion al objeto pero se llama historia clinca por aparatos y sistemas
  public antecedentesPersonalesNoPatologicos = {
    tabaquismoPorDia: "",
    aniosConsumo: "",
    exFumadorPasivo: "",
    alcoholismoPorDia: "",
    aniosDeconsumoAlcohol: "",
    exAlcoholicoUOcasional: "",
    alergias: "",
    tipoAlergias: "",
    tipoSanguineo: "",
    desconoceTipoSanguineo: "",
    drogadiccionTipo: "",
    aniosConsumoDrogas: "",
    exDrogadicto: "",
    alimentacionAdecuada: "",
    viviendaConServiciosBasicos: "",
    otrosAntecedentesNoPatologicos: "",
    idPaciente: ""
    }
    
  public pageTitle: 'ddd';

  public hojaEvolucion = {
    motivoDeConsulta:'',
    evolucionDelPadecimiento:'',
    medicoTrante:'',
    diagnosticos:[],
    plan:'',
    respiratorioCardiovascular:"",
    digestivo:"",
    endocrino:"",
    musculoEsqueletico:"",
    genitourinario:"",
    hematopoyeticoLinfatico:"",
    pielAnexos:"",
    neurologicoPsiquiatricos:"",
    piel:'',
    cabezaCuello:"",
    torax:"",
    abdomen:"",
    genitales:"",
    extremidades:"",
    sistemaNervioso:"",
    status:""
  }
  public edad:number=0;

  public necistaReceta = "";

  // este json nos ayuda en la representacion de los datos
  public paciente = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    edad: 0,
    registro: '',
    genero: '',
    consultas:0,
    medico: '',
    calle:"",
    curp:"",
    idPaciente:"",
    municipio:"",
    talla: '',
    peso: '',
    imc: '',
    fc: '',
    ta:"",
    fr: '',
    lpm:'',
    sistolica: '',
    diastolica: '',
    temp: '',
    glucosa: '',
    pc: '',
    pa: '',
    pt: '',
    apgar: 0  ,
    sao: '',
    rpm:'',
    alergias: '',
    pao: ''

  }

  public paciente2 = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    sede:""
  }

  public tallatl;
  public tallaPrueba = [];

  public endos:any[] = [];
  public otrosEstudios:any[] = [];
  public listaOtrosServ:any[]=[
    {
      nombre:'LABORATORIO'
    },{
      nombre:'ULTRASONIDO'
    },{
      nombre:'RAYOSX'
    },{
      nombre:'ENDOSCOPIA'
    },{
      nombre:'TOMOGRAFIA'
    },{
      nombre:'OTROS ESTUDIOS'
    },{
      nombre:'NOTAS Y RESUMENES'
    },{
      nombre:'RECETAS'
    }
  ];
  public obtenidosLab= [];
  public resultado = [{
    idServicio: {
      ESTUDIO:"",
      ELEMENTOS: [{
        elementos: "",
        referencia: "",
        tipo:""
      }
      ]
    },
    obtenidos:[],
    idPaciente: [],
  }]

  public obtenido = [];

  constructor(
    private _route: ActivatedRoute,
    private _HistoriaClinicaService: HistoriaClinicaService,
    public _consultaService: ConsultaService,
    private router: Router,
    public _Cie10Service: Cie10Service,
    private spinner: NgxSpinnerService,
    private _servicios: ServiciosService,) { }

  ngOnInit(): void {
    this.fecha = moment().format('L');

    // OBTENEMOS LA INFORMCION DEL ROL
      this.hojaEvolucion.medicoTrante = getDataStorage().nombre;
    // Obtener Id del Paciente
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerConsulta();
    // this.setEdad();
  }

  alertaGraficas(){
    Swal.fire({
              icon: 'error',
              title: '',
              text: 'NO SE ENCONTRO EL HISTORICO DE LOS SIGNOS VITALES DEL PACIENTE',
            })
  }

  obtenerConsulta(){
    this._HistoriaClinicaService.obtenerConsultaPorElId( this.id  )
    .subscribe(
      (data:any) => {
        this.setPaciente(data['data']['paciente'])
        this.paciente.consultas = data['data']['paciente']['consultas'];
        this.hora = data['data'].horaIngreso;
        this.paciente.nombre = data['data']['paciente']['nombrePaciente'];
        this.paciente.apellidoPaterno = data['data']['paciente'].apellidoPaterno;
        this.paciente.apellidoMaterno = data['data']['paciente'].apellidoMaterno;
        this.paciente.edad = data['data']['paciente'].edad;
        this.paciente.curp = data['data']['paciente'].curp;
        this.paciente.idPaciente = data['data'].paciente._id;
        this.paciente.diastolica = data['data'].diastolica;
        this.paciente.sistolica = data['data'].sistolica;
        this.paciente.genero = data['data'].paciente.genero;
        this.paciente.registro = data['data']['paciente']['fechaRegistro'];
        this.paciente.calle = data['data']['paciente']['callePaciente'];
        this.paciente.talla = data['data']['talla'];
        this.paciente.temp = data['data']['temp'];
        this.paciente.peso = data['data']['peso'];
        this.paciente.pt = data['data']['pt'];
        this.paciente.pc = data['data']['pc'];
        this.paciente.imc = data['data']['imc'];
        this.paciente.lpm = data['data']['lpm'];
        this.paciente.pa = data['data']['pa'];
        this.paciente.apgar = data['data']['apgar'];
        this.paciente.sao = data['data']['SaO'];
        /* console.log(this.paciente.sao); */
        this.paciente.pao = data['data']['pao']
        
        this.paciente.rpm =data['data']['rpm'];
        this.paciente.glucosa =data['data']['glucosa'];
        this.paciente.fc = data['data'].fc
        this.paciente.fr = data['data'].fr
        /* console.log(this.paciente); */
        
      })
  }

  setPaciente(data){
    for (const key in this.paciente2) {
      if (data[key] == undefined) {
        this.paciente2[key] = ''
      }else{
        this.paciente2[key] = data[key]
      }
    }
  }

   //Inicio Funciones Grafica
  //  public randomize(): void {
  //   for (let i = 0; i < this.lineChartData.length; i++) {
  //     for (let j = 0; j < this.lineChartData[i].data.length; j++) {
  //       this.lineChartData[i].data[j] = this.generateNumber(i);
  //     }
  //   }
  //   this.chart.update();
  // }

  // private generateNumber(i: number) {
  //   return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  // }

  // // events
  // public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  //   // console.log(event, active);
  // }

  // public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  //   // console.log(event, active);
  // }

  // public hideOne() {
  //   const isHidden = this.chart.isDatasetHidden(1);
  //   this.chart.hideDataset(1, !isHidden);
  // }

  // public pushOne() {
  //   this.lineChartData.forEach((x, i) => {
  //     const num = this.generateNumber(i);
  //     const data: number[] = x.data as number[];
  //     data.push(num);
  //   });
  //   this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  // }

  // public changeColor() {
  //   this.lineChartColors[2].borderColor = 'green';
  //   this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  // }

  // public changeLabel() {
  //   this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  //   // this.chart.update();
  // }

  // funciones de validaciones

  validarMotivoConsulta(){
    if(this.hojaEvolucion.motivoDeConsulta.length < 6 || this.hojaEvolucion.motivoDeConsulta == ''){
      return  false;
    }else {
      return true;
    }
  }

  validarEvolucionDelPaciente(){
    if(  this.hojaEvolucion.evolucionDelPadecimiento == ""  || this.hojaEvolucion.evolucionDelPadecimiento.length < 6 ){
      return false;
    }else {
      return true;
    }
  }


  validarPlan(){
    if(   this.hojaEvolucion.plan == "" || this.hojaEvolucion.plan.length < 5 ){
      return false;    
    }else {
      return true;
    }
  }

  // validamos la hoja en general
  validarHistoriaClinica(){
    
    if(  this.validarMotivoConsulta() ){
     
    }else {
      Swal.fire({
              icon: 'warning',
              title: '',
              text: 'EL MOTIVO DE LA CONSULTA ES REQUERIDO',
            })
      /* alert('Llena el motivo de la consulta'); */
      return false;
    }
    
    if( this.validarEvolucionDelPaciente() ){
        
    }else{
      Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA HOJA DE EVOLUCION ES REQUERIDA',
            })
      /* alert('Llena la evolucion del paciete'); */
      return false;

    }

    if(  this.validarPlan() ){

    }else{
      Swal.fire({
              icon: 'warning',
              title: '',
              text: 'EL PLAN DE TRATAMIENTO ES REQUERIDO',
            })
      /* alert('Completa el plan'); */
      return false;
    }

      return true;
  }

  agregarDiagnosticoUno( item  ){
    let diagnostico  = { diagnostico: item}
    
    this.hojaEvolucion.diagnosticos.push( diagnostico );
    this.diagnosticos = [];
    this.buscarDiagnostico = "";
  }

  obtenerDiagnosticoUno(){

    if(this.buscarDiagnostico.length >= 3) {
      this._Cie10Service.getCiePorNombre(this.buscarDiagnostico).subscribe(
        (data:any) => {
            this.diagnosticos = data.data;
        });
    }

  }

// validaciones
  validacion(texto: string){
    if( texto.length == 0  ){
      return true;
    }else {
      return false;
    }

  }

  alertValidacionicompleta(message){
    Swal.fire({
              icon: 'warning',
              title: '',
              text: message,
            })

    return false;
  }

  //delete de los diagnosticos de la receta
  deleteItem(i:number){
    // console.log(i);
    this.hojaEvolucion.diagnosticos.splice( i, 1 );
  }  

  validarAparatosYsistemas(){


    if( this.validacion(    this.hojaEvolucion.respiratorioCardiovascular)  ){
      Swal.fire({
              icon: 'warning',
              title: '',
              text: 'LOS RESPIRATORIO CARDIO VASCULAR ES REQUERIDO',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion(  this.hojaEvolucion.digestivo )  ){
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'LO DIGESTIVO ES REQUERIDO',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if(  this.validacion( this.hojaEvolucion.endocrino )) {
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'EL ENDOCRINO ES REQUERIDO',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion( this.hojaEvolucion.musculoEsqueletico )  ){
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'LOS MUSCULOS ESQUELETICOS SON REQUERIDOS',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion( this.hojaEvolucion.hematopoyeticoLinfatico) ) {
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'EL HEMATOPOYETICOLINFATICO ES REQUERIDO',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if( this.validacion( this.hojaEvolucion.piel ) ){
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'LA PIEL ES REQUERIDO',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion( this.necistaReceta )  ){
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'LOS DATOS SON REQUERIDOS',
            })
      /* alert('Completa los campos'); */
      return false;
    }else if( this.validacion( this.hojaEvolucion.pielAnexos ) ){
     return this.alertValidacionicompleta('completa el campo piel y anexos');
    }else if(this.validacion( this.hojaEvolucion.cabezaCuello ) ){
      return this.alertValidacionicompleta('completa el campo exploracion cabeza y cuello');
    }else if(this.validacion(  this.hojaEvolucion.torax) ){
      return this.alertValidacionicompleta('completa el campo torax');
    }else if( this.validacion(this.hojaEvolucion.abdomen) )  { 
      return this.alertValidacionicompleta( 'completa el campo Abdomen' )
    }else if( this.validacion(this.hojaEvolucion.genitales) )  { 
      return this.alertValidacionicompleta( 'completa el campo Genitales' );
    }else if( this.validacion(this.hojaEvolucion.extremidades) )  { 
      return this.alertValidacionicompleta( 'completa el campo Extremidades' )
    }else if( this.validacion(this.hojaEvolucion.sistemaNervioso) )  { 
      return this.alertValidacionicompleta( 'completa el campo Sistema nervioso' );
    }

    return true;

  }

  // agregamos la hoja de evlucion a la consulta
  agregarHistoriaClinica(){



    if(   this.validarHistoriaClinica() && this.validarAparatosYsistemas() == true ){
      try {
        this._HistoriaClinicaService.agregarHistoriaCLinicaPaciente(Object.assign(this.hojaEvolucion,{idPaciente:this.paciente2._id}))
        .subscribe((data)=>{
          let idHistoriaclinica = data['data']['_id']
          if (idHistoriaclinica !== undefined) {
            this.necistaReceta =='si' ? this.hojaEvolucion.status = "Receta" : this.hojaEvolucion.status = "NoReceta"
            
            this._HistoriaClinicaService.agregarHojaEvolucion( this.id, (Object.assign(this.hojaEvolucion, {idHistoriaClinica: idHistoriaclinica})))
            .subscribe( (data) => {
              if(data['ok']){ 
                let idHistoriaClinica = { idHistoriaClinica: idHistoriaclinica }
                
                this._HistoriaClinicaService.actualizarHistoriaclinicaPorAparatos(  this.paciente.idPaciente, idHistoriaClinica )
                .subscribe( (data) => {
                  if(data['ok']){
                    this.necistaReceta == 'si' ?  this.router.navigate(['receta','medica', this.id ]) : this.router.navigate(['bitacora/medicina/general'])
                  
                    Swal.fire({
                      icon: 'success',
                      title: '',
                      text: 'LOS DATOS SE AGREGARON CORRECTAMENTE',
                    })
      
                    
                  }
                });
              }
          });
            
          } else {
            
          }
        })
      } catch (error) {
        
      }

    }

    return;
    
  }

  obtenerResultados(estudio:any) {
    this.spinner.show();
      this._servicios.obtenerRegresosHistoriaClinica( this.paciente.idPaciente, estudio)
      .subscribe((data:any) => {
        if(data.ok)this.setEstudio(data['data'],estudio);
      })
  }

  obtenerOtrosEstudios(servicio:any){
    this.spinner.show();
    this.otrosEstudios=[];
    this._servicios.obtenerOtrosEstudiosPaciente(this.paciente.idPaciente, servicio).subscribe((resp:any)=>{
      if(resp.ok){
        this.otrosEstudios = resp['data']
        this.spinner.hide()
      }
    })
  }

  setEstudio(estudio = [],estudioPedido) {
    // console.log(estudio);
    if(estudioPedido == 'endoscopia'){
      this.endos = estudio
      this.spinner.hide();
    }else{
      this.obtenidosLab = estudio;
      this.resultado = estudio
      // console.log(this.resultado);
      this.spinner.hide();
      if (!this.resultado[0]) {
        return;
      }
    }
  }

  public idContentB: number = 0;
  selectContentB(index: number) {
    this.idContentB = index;
    
  }

  public idContentC: number = 0;
  selectContentC(index: number) {
    this.idContentC = index;
  }

}
