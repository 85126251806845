import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-antecedentes',
  templateUrl: './antecedentes.component.html',
  styleUrls: ['./antecedentes.component.css']
})
export class AntecedentesComponent implements OnInit {

  @Output() antecedentes = new EventEmitter<string>();
  formulario: FormGroup;
  public lugar = false;
  public enfermedad = '';

  constructor() { }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      enfermos_hepatitis: new FormControl('NO'),
      vih_deteccion: new FormControl('NO'),
      hepatitisb_deteccion: new FormControl('NO'),
      hepatitisc_deteccion: new FormControl('NO'),
      enfermedad_de_transmision: new FormControl('NO'),
      odinofagia: new FormControl('NO'),
    });
    
    this.antecedentes.emit(this.formulario.value);
  }

  activarLugar(tipo){
    if(tipo == 'SI'){
      this.lugar = true;
      this.enfermedad = ""
    }else{
      this.lugar = false;
      this.enfermedad = "NO"
    }
  }

  formularioAntecedentes(form: NgForm){
    if(this.enfermedad == "SI"){
      form.value.enfermedad_de_transmision = this.enfermedad;
    }
    this.antecedentes.emit(form.form.value);
  }

}
