import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reaccion-adversa',
  templateUrl: './reaccion-adversa.component.html',
  styleUrls: ['./reaccion-adversa.component.css']
})
export class ReaccionAdversaComponent implements OnInit {

  public tratami = [];
  @Output() tiempodepresentacion = new EventEmitter();
  @Output() tratamiento = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

  formularioTiempoPresentacion(event){
    this.tratami.push(event);
    this.tiempodepresentacion.emit(this.tratami);
  }

  tratamientos(event){
    this.tratamiento.emit(event)
  }

}
