import { Component, OnDestroy, OnInit } from '@angular/core';
import { PacientesService  } from '../../../services/pacientes/pacientes.service';
import { filterNameByPaciente, buscarPaciente } from '../../../classes/helpers/filterNamePaciente'
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {  Subject } from 'rxjs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.css']
})
export class PacientesComponent implements OnInit, OnDestroy {

  public pacientes2:any[] = [];
  public pacientes =  [];
  public totalpaciente:string;
  public pagina = 0;
  public  filtropacientes = '';
  public filterPost = '';
  public rol='';
  public nombre = '';
  private unsubcribe$ = new Subject<void>();
  public arrayBusqueda:any[] = []

  constructor(public _pacienteService: PacientesService,
              public _spinnerService: SpinnerService,
              private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.obtenerUsuario()
    this.obtenerPacientes();
    //this.obtenerPacientesTabla();
    this.spinner.show();
  }

  obtenerUsuario(){
    this.rol = JSON.parse(localStorage.getItem('usuario')).role;
    this.nombre = JSON.parse(localStorage.getItem('usuario')).nombre
  }

  obtenerPacientes() {

    this.pacientes2 = [];

    this._pacienteService.getPacientesAll()
    .subscribe( (data: any) => {
      if(data.ok){
        this.arrayBusqueda = data['data']
        //this.pacientes2 = data.data.reverse();
        // this.encontrarRepetidos(this.pacientes2)
        this.pacientes = data.data;
        this.pacientes2 = data.data;
        this.totalpaciente = data.data.results;
        this.spinner.hide();
      }

      if( data['message']   === 'No hay pacientes' ) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'NO SE ENCUENTRA NINGUN PACIENTE',
            })
        return;
      }
      /* this._spinnerService.show(); */

    });
  }

  // encontrar pacientes repetidos
  obtenerPacientesTabla(){
    this._pacienteService.obtenerAllPacintes().subscribe((resp:any)=>{
      console.log(resp);
      
      this.encontrarRepetidos(resp['data']);
    })
  }

  encontrarRepetidos(pacientes){
    // const busqueda = pacientes.reduce((acc, persona) => {
    //   acc[persona.nombrePaciente.trim().concat(" "+persona.apellidoPaterno.trim()+" "+persona.apellidoMaterno.trim())] = ++acc[persona.nombrePaciente.trim().concat(" "+persona.apellidoPaterno.trim()+" "+persona.apellidoMaterno.trim())] || 0;
    //   return acc;
    // }, {});
    
    // const duplicados = pacientes.filter( (persona) => {
    //   return busqueda[persona.nombrePaciente.trim().concat(" "+persona.apellidoPaterno.trim()+" "+persona.apellidoMaterno.trim())];
    // });

    // duplicados.sort(function (a, b) {
    //   if (a.nombrePaciente > b.nombrePaciente) {
    //     return 1;
    //   }
    //   if (a.nombrePaciente < b.nombrePaciente) {
    //     return -1;
    //   }
    //   return 0;
    // });
    // this.imprimirPDF(duplicados)
  }

  imprimirPDF(duplicados:any){
    let contador =1
    const data : any =[]
    const doc : any = new jsPDF({ orientation: 'landscape'});
    doc.setTextColor(100);
    doc.setFont('courier')
    duplicados.forEach((element:any) => {
      var x =[
        contador,
        element._id,
        element.nombrePaciente.trim().concat(" " + element.apellidoPaterno.trim()+" "+ element.apellidoMaterno.trim()),
    ]
    contador = contador + 1;
      data.push(x)
    });
    doc.autoTable({
      theme:'grid',
       styles: { halign: 'center',font :'courier',fontSize:18 , fillColor:[44, 62, 80]},
      columnStyles: {0:{  halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     1:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     2:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     3:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     4:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     5:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     6:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     7:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] }},
      head:[["#","ID","NOMBRE"]],

      body :data,
      startY:30
    });

    

     doc.output('dataurlnewwindow')
  } 


  typeSearchPatients(){

    if(this.filtropacientes.length >= 3){
      this._pacienteService.buscarPacientesPorNombreBackend( {nombre: this.filtropacientes} )
      .subscribe( (data: any) => this.pacientes2 = data.data);
    }else{
      this.pacientes2 = this.pacientes;
    }
  }

  focusSearchPatients(){
    const input: HTMLInputElement = document.querySelector('#busquedaPaciente');
    input.select();
  }

  paginaAnterior(){
    this.pagina = this.pagina - 8;
    this._pacienteService.getPacientes( this.pagina).subscribe( (data: any) => {
      this.pacientes2 = data.users;
    })
  }

  siguentePagina(){
    this.pagina += 8;
    this._pacienteService.getPacientes( this.pagina).subscribe( (data: any) => {
      this.pacientes2= data.users;
    })
  }

  ngOnDestroy(){
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }
}
