import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import CarritoCensur  from '../../../classes/carrito-censur/censur-carrito';


@Component({
  selector: 'app-carrito-almacen',
  templateUrl: './carrito-almacen.component.html',
  styleUrls: ['./carrito-almacen.component.css']
})
export class CarritoAlmacenComponent implements OnInit {

  public products = [];
  public txtSearchFilter = "";

  public carrito = {
    total: 0,
    items: []
  };

  public btnValidate = true;

  constructor(
    private _almacenService: AlmacenService
  ) {


   }

  ngOnInit(): void {
    this.getAllroducts();
    this.getSessionCensur();
    this.obtenerCarritoStorage();
  }

  getSessionCensur(){
    const carritoStorage = new CarritoCensur();
    this.carrito = carritoStorage.obtenerSotorageCarrito();
   
    if( this.carrito.items.length >= 1 ) {
      this.btnValidate = false;
    }
  }


  getAllroducts() {
    this._almacenService.obtenerProductosAlmacenBitacora()
    .subscribe( (data:any) => this.setProduct(data.data));
  }



  setProduct( products: any ){
    this.products = products;   
  }


  addToCart( producto ){
    const carrito = new CarritoCensur();
    carrito.agregarItemAlmace(producto);
    this.getSessionCensur();

  }
  
  obtenerCarritoStorage(){
    let carrito = new CarritoCensur();
    this.carrito = carrito.obtenerSotorageCarrito();
  }

  eliminar(index){
    const carritoCensur = new CarritoCensur();
    carritoCensur.eliminar(  index );
    this.obtenerCarritoStorage();
  }


  filterProductByName() {
    
    const productsFilter  = [];

    if( this.txtSearchFilter.length >= 3 ) {

      this.products.forEach( element => {

        if( element.nombre.includes( this.txtSearchFilter.toUpperCase() ) ) {
          productsFilter.push( element );
        }
        this.products = productsFilter;

      });
    }else {
      this.getAllroducts();
    }

  }

}
