<section class="container-fluid">
    <div class="card" name="card" id="card">
        <br>
        <div class="row">
            <div class="col margin-info">
                <p> <span>  Nombre Completo: {{ data.nombrePaciente | uppercase  }} {{ data.apellidoPaterno | uppercase  }} {{ data.apellidoMaterno | uppercase  }} </span> </p>
            </div>
            <div class="col">
                <p> <span> Edad: {{ data.edad }} </span> </p>
            </div>
            <div class="col">
                <p> <span> Género: {{data.genero | uppercase  }} </span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Domicilio:</span>{{ data.callePaciente | uppercase }} {{data.estadoPaciente | uppercase }} {{ data.paisPaciente | uppercase }} </p>
            </div>
            <div class="col">
                <p> <span>Fecha de Nacimiento:</span>{{data.fechaNacimientoPaciente | date:'dd/MM/yyyy':'UTC'}} </p>
            </div>
            <div class="col">
                <p> <span>Lugar de Nacimiento:</span> {{ data.estadoPaciente | uppercase }} {{ data.paisPaciente | uppercase }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Teléfono:</span> {{ data.telefono }} </p>
            </div>
            <div class="col">
                <p> <span>ID:</span> {{ data._id }} </p>
            </div>
            <div class="col" *ngIf="data.receptor == 'RECEPTOR'">
                <p> <span> Tipo: {{data.receptor}} </span> </p>
            </div>
            <div class="col" *ngIf="data.disponente == 'DISPONENTE'">
                <p> <span> Tipo: {{data.disponente}} </span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;" *ngIf="data.tipoDeSangre != ''">
                <p style="font-size: 26px !important"> <span>Tipo de Sangre:</span> {{data.tipoDeSangre}}</p>
            </div>
            <div class="col" *ngIf="rol == 'laboratorio' || rol == 'doctor'">
                <p> <span> Religión: </span> {{data.religion}}</p>
            </div>
        </div>
        <div class="row" id="religion" *ngIf="rol == 'enfermeria'">
            <div class="col-md-1">
                <span>Religión: </span>
            </div>
            <div class="col">
                <select name="religion" class="form-control" [(ngModel)]="dato.religion" (change)="capturar()" style="max-width: 25em;">
                <option value="Tipo" selected>Seleccionar una opción</option>
                <option value="judios">Judío</option>
                <option value="catolicos">Católico</option>
                <option value="evangelicos">Evangélico</option>
                <option value="menonitas">Menonita</option>
                <option value="pentecostales">Pentecostés</option>
                <option value="bautista">Bautista</option>
                <option value="metodistas">Metodista</option>
                <option value="adventistas">Adventista</option>
                <option value="budistas">Budista</option>
                <option value="krishnas">Krishna</option>
                <option value="presbiterianos">Presbiteriano</option>
                <option value="testigos_de_jehova">Testigo de Jehová</option>
                <option value="islamicos">Islámico</option>
                <option value="otraReligion">Otra</option>
                 </select>
                <br>
            </div>
        </div>

        <div class="row" style="margin-left: 30px;" *ngIf="rol == 'flebotomia'">
            <div class="col-md-4">
                <div class="form-group row">
                    <label class="col col-form-label"><span>TIPO DE PRODUCTO:</span></label>
                    <div class="form-group" style="margin-left: 0px; margin-top: 5px;">
                        <div class="form-check form-check-inline">
                            <input type="radio" value="SANGRE TOTAL" name="tipoProducto" id="tipoProducto"  [(ngModel)]="dataDonante.tipo_de_sangreado" >
                            <label class="form-check-label" for="tipoProducto" style="margin-left: 5px; margin-top: 1px;"> SANGRE TOTAL</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" value="PLAQUETAS" name="tipoProducto" id="tipoProductoPlaquetas" [(ngModel)]="dataDonante.tipo_de_sangreado" >
                            <label class="form-check-label" for="tipoProductoPlaquetas" style="margin-left: 5px; margin-top: 1px;">PLAQUETAS</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-3">
                        <p><span>Tipo de donador:</span></p>
                    </div>
                    <div class="col">

                         <select name="voluntartio" [(ngModel)]="dataDonante.tipo_de_donador"  id=""  style="width: 90%; border: none; border-radius: 10px; padding:3px; margin-top: 5px; box-shadow: 7px 12px 20px 8px rgb(196 192 196);"  (blur)="emmitDataDocumentos()" >
                            <option value="" > Selecciona el tipo de donador </option> 
                            <option value="Autologo"> Autologo </option>
                            <option value="Repetición"> Repetición </option>
                            <option value="Designado"> Designado </option>
                            <option value="Dirigido"> Dirigido </option>
                            <option value="Regular"> Regular </option>
                            <option value="Altruista"> Altruista </option>
                            <option value="Familiar"> Familiar </option>
                         </select>

                    </div>
                </div>  
            </div>
        </div>
    </div>
</section>