import { Component, OnInit } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute} from '@angular/router';
import { gaurdarCotizacion } from '../../../../functions/storage.funcion';
import { getCarritoStorage } from '../../../../functions/pacienteIntegrados';
import {  getrolefunction  } from '../../../../functions/storage.funcion';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import Carrito  from '../../../../classes/carrito/carrito.class';

@Component({
  selector: 'app-lab-ulta-tomo-res',
  templateUrl: './lab-ulta-tomo-res.component.html',
  styleUrls: ['./lab-ulta-tomo-res.component.css']
})
export class LabUltaTomoResComponent implements OnInit {

  
  // data de los servicios
  public titulo;
  public serviceSi: any [] = [];
  public ambulanciaSI: any [] = [];
  public totalAmbulancia: string;
  public termino: string;
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showAmbulanacia = false;
  public searching = false;
  public servicios:string;
  public roleuser = "";
  public membresia: boolean;
  public mostrar=true;
  public paciente :any;

  public todosLosServicios = {
    ambulancia: [],
    endoscopia: [],
    laboratorios: [],
    message: '',
    ok: false,
    otrosServicio: [],
    patologia: [],
    rayosX: [],
    tomografia: [],
    ultrasonido: []
  };

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public buscar = {
    estudio:''
  };

  constructor(private _service: IntegradosService, private _router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let title='LISTADO DE '
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.titulo = title.concat(this.servicios)
    this.obtenerCarrito();
    this.obtenerMembresia();
    this.getRoleUser();
  }

  obtenerCarrito () {
    const carrito = new Carrito();
    this.carrito = carrito.obtenerSotorageCarrito();
  }

  obtenerMembresia(){
    const usuarioMembresia = new PacienteStorage();
    this.paciente = usuarioMembresia.verPacienteConMembresia();
    if( this.paciente == null || this.paciente.membresiaHabilitada == false ) {
      this.verDatos();
    }else{ 
      this.membresia = usuarioMembresia.verMembresiaPaciente(); 
      this.verDatos();  
    }
  }

  getRoleUser(){
    this.roleuser = getrolefunction();
  }

  busquedaGeneral(){
    this.pagina = 1;
    if(this.buscar.estudio.length >3){
      this._service.getAllDepartments(this.buscar)
      .subscribe((res:any) => {
        // if(res.data[0]!=0){
        //   this.ambulanciaSI = res.data[0];
        //   this.showAmbulanacia= true;
        //   this.showTableAmbulanacia = false;
        // }else 
        if(res.data[1] != 0){
          this.serviceSi = res.data[1];
          this.searching = true;
        }            
      })
    }
    if(this.buscar.estudio == ''){
      this.searching = false;
      this.mostrar=true;
      this.showAmbulanacia= false;
      this.showTableAmbulanacia = true;
      this.obtenerCarrito();
      this.verDatos();
    }
  }
  
  agregarCarrito( event, item: any ) {
    const carrito = new Carrito();
    this.carrito =  carrito.agregarItem( event, item );
  }

  eliminar( item ) {
    const carrito = new Carrito();
    carrito.eliminar( item );
    this.obtenerCarrito();
  }

  verDatos(){
    this._service.getObtener(this.servicios).subscribe(
      (res: any) => {
          this.serviceSi = res.data;
          this.totalAmbulancia = res.data.results;
      },
      err => {
        /* console.log(<any>err); */
      }
    );   
  }

  alertcomparasion( ev, precioPublico, precioMembresia, item2:any ){
    let precioSinTrim  =  precioPublico.replace('$', '');
    let precioSinComaPublico = precioSinTrim.replace(',', '');
    let precioMemTrim  =  precioMembresia.replace('$', '');
    let precioMemComaMembresia = precioMemTrim.replace(',', '');
     Swal.fire({
              icon: 'success',
              title: 'CON MEMBRESIA AHORRAS',
              text: `${precioSinComaPublico - precioMemComaMembresia}`,
            })
  }

  showAlert(){
    Swal.fire({title: "¿Estas seguro de contratar a este destino?",
    text: "El servicio de ambulancia solo será requerido para dicho destino, no puede haber cambios",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
                     Swal.fire({
              icon: 'success',
              title: '',
              text: 'INGRESA LOS DATOS PARA EL TRASLADO',
            })
            this._router.navigateByUrl('/hoja-fram');
      }else if (result.dismiss === Swal.DismissReason.cancel) {
        this._router.navigateByUrl('serviciosInt/'+this.servicios);
      }

    })
  }

  editarServicio(id){

    Swal.fire({title: "¿Estas seguro que vas a editar el servicio",
    text: "Una vez que se haya editado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._router.navigateByUrl('formularioeditarservice/' +id)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'NO SE EDITO NADA',
            })
            this._router.navigateByUrl('serviciosInt/'+this.servicios);
      }   
    })
  }
  
  delete(id) {
            Swal.fire({title: "¿Estas seguro de eliminar el servicio?",
    text: "Una vez que se haya eliminado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._service.deleteService(id).subscribe((resp:any) => {
          if(resp.ok){
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'EL SERVICIO SE ELIMINO CORRECTAMENTE',
            })
            this.verDatos();
            this._router.navigateByUrl('serviciosInt/'+this.servicios);
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'EL SERVICIO NO SE ELIMINO',
            })
        } 
    })
}
}