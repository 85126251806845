import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import  CarritoCensur  from '../../../../../classes/carrito-censur/censur-carrito';

@Component({
  selector: 'app-entrega-productos',
  templateUrl: './entrega-productos.component.html',
  styleUrls: ['./entrega-productos.component.css']
})
export class EntregaProductosComponent implements OnInit {

  public  filtropacientes = '';
  censur = '61d87b35bd8da09c6b8c71eb'
  public productos=[
    {
      producto:"CONCENTRADOS ERITROCITARIOS",
      precio:2400,
      cantidad:0,
      idProducto:"5fd12c0caf8c87001705597d" 
    },
    {
      producto:"CONCENTRADOS PLAQUETARIOS",
      precio:2300,
      cantidad:0,
      idProducto:"5fd12c0caf8c87001705597a"
    },
    {
      producto:"PLASMA",
      precio:2300,
      cantidad:0,
      idProducto:"5fd12c0caf8c87001705597D"
    },
    {
      producto:"PLAQUETAS",
      precio:2300,
      cantidad:0,
      idProducto:"5fd12c0caf8c87001705597C"
    },
    {
      producto:"Crioprecipitado",
      precio:2300,
      cantidad:0,
      idProducto:"5fd12c0caf8c87001705597B"
    }
  ];
  public carrito = {
    total: 0,
    items: []
  };

  public receptor ={
    idbancosangre: ''
  }

  constructor(private _router: Router,
              private _bancoSangre: BancoService) { 
                this.obtenerCarritoStorage();
              }

  ngOnInit(): void{
    this.getProductosAlmacen();
    this.getPedidoReceptor();
    
    /* this.obtenerCarritoStorage(); */
  }

  typeSearchPatients(){}

  getPedidoReceptor(){
    this.receptor.idbancosangre = localStorage.getItem('idCensur');
    this._bancoSangre.getPedidoReceptorPagos(this.receptor).subscribe((resp:any) =>{
      this.setPedido(resp.data);
    })
  }
  getProductosAlmacen(){
    this._bancoSangre.getProductosAlmacen().subscribe((resp:any) =>{
      console.log(resp.data)
    })
  }

  setPedido(pedido){
    let total = 0
    for (let x = 0; x < pedido.length; x++) {
         const element = pedido[x].precio
         total = total + Number(element)
    }
    if(this.obtenerCarritoStorage() == undefined){
      let carrito = new CarritoCensur();
      carrito.agregarProductos(pedido, total);
    }
    this.obtenerCarritoStorage()
  }

  //Obtener carrito de storage
  obtenerCarritoStorage(){
    let carrito = new CarritoCensur();
    this.carrito = carrito.obtenerSotorageCarrito();
  }

/*   eliminarCarrito(){
    localStorage.removeItem('censur-carrito')
  } */

  //Agregar item al carrito
  agregarCarrito(item: any) {
    let carrito = new CarritoCensur();
    this.carrito = carrito.agregarItem(item);
    this.validarCarrito();
  }

  //Eliminar item del carrito
  eliminar(index){
    let carrito = new CarritoCensur();
    carrito.eliminar( index );
    this.obtenerCarritoStorage();
    this.validarCarrito();
  }

  //Validacion de carrito vacio
  validarCarrito(){
    this.obtenerCarritoStorage();
    if(this.carrito.items.length == 0){
      return true;
    }else{
      return false;
    }
  } 

}
