<div class="container-fluid">
    <section>
        <app-titulos [title]="'HOJA DE SERVICIO'" [rol]="'laboratorio'"></app-titulos>
        <!-- <app-ficha-laboratorio-disponente  [titulo]="'HOJA DE SERVICIO'" [pacienteInfo]="paciente"></app-ficha-laboratorio-disponente> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'laboratorio'"></app-fichas-identificacion>
    </section>



    <div class="row" id="tabla2">
        <div class="col-md-12 col-lg-12">

            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-2 text_header" style="text-align: center;">
                            <p>No</p>
                        </div>
                        <div class="col-5 text_header">
                            <p>NOMBRE DEL ESTUDIO</p>
                        </div>
                        <div class="col-5 text_header">
                            <p>ESTADO</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let item of servicios; let i = index">
                        <div class="row" id="pointer">
                            <!-- *ngFor="let servicioslab of servicio; let i = index" [routerLink]="servicio.url" -->
                            <div class="col-2" style="text-align: center;">
                                <p>
                                    {{ i + 1 }}
                                </p>
                            </div>
                            <div *ngIf="item.nombre === 'BIOMETRIA HEMATICA COMPLETA'" class="col-5" style="text-align: center;" [routerLink]="['/grupo/rh/biometria/hematica/completa/',idCensur]">
                                <p>
                                    {{ item.nombre }}
                                </p>
                            </div>
                            <div *ngIf="item.nombre === 'SEROLOGIA'" class="col-5" style="text-align: center;" [routerLink]="['/serologia/laboratorio/disponente/',idCensur]">
                                <p>
                                    {{ item.nombre }}
                                </p>
                            </div>
                            <div *ngIf="item.nombre === 'GRUPO Y RH'" class="col-5" style="text-align: center;">
                                <p>
                                    {{ item.nombre }}
                                </p>
                            </div>
                            <div *ngIf="item.proceso === undefined" class="col-5" style="text-align: center;">
                                <p>
                                    EN PROCESO
                                </p>
                            </div>
                            <div *ngIf="item.proceso !== 'undefined'" class="col-5" style="text-align: center;">
                                <p>
                                    {{ item.proceso }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modal -->

            </div>
        </div>
    </div>
</div>