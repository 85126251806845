import { Component, OnInit } from '@angular/core';
import  CarritoCensur  from '../../../classes/carrito-censur/censur-carrito';
import Dates from 'src/app/classes/dates/date.class';
import Tickets from 'src/app/classes/tickets/ticket.class';
/* import { CEDE } from 'src/app/classes/cedes/cedes.class';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import Tickets from 'src/app/classes/tickets/ticket.class'; */
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { Router } from '@angular/router';
import { generarPdfComponentes } from 'src/app/functions/generarEnvioComponentes';
import { NgxSpinnerService } from 'ngx-spinner';
import { fileToBase64 } from 'src/app/functions/generalFunctions';

@Component({
  selector: 'app-pagos-censur',
  templateUrl: './pagos-censur.component.html',
  styleUrls: ['./pagos-censur.component.css']
})
export class PagosCensurComponent implements OnInit {
 
  public pedidoCensur = "";
  public carrito = {
    total: 0,
    items: []
  };

  public paciente = {
    nombrePaciente:'',
    apellidoPaterno:'',
    apellidoMaterno: '',
    edad:0, 
    genero:'',
    direccion:{
      callePaciente:""
    },
    id:'',
    membresiaActiva:false,
    _id:"",
  }

  public documentos = [];
  public venta = {
    montoEfectivo:0,
    montoTranseferencia:0,
    montoCredito:0,
    montoDebito:0,
    quienRecibe:'',
    idPaciente:'',
    total:0,
    fechaDeCreacion:'',
    horaDeCreacion: '',
    sede:'',
    vendedor:'',
    idBanco:'',
    Efectivo:false,
    Transferencia:false,
    TarjetaCredito:false,
    TarjetaDebito:false,
    product:[]
  }

  public fecha = "";
  public boton = false;
  public montoTotal = 0;
  public IVaDEl16 = 0;
  public idCensur = '';

  constructor(private _banco:BancoService,
              private _router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.obtenerCarritoStorage();
    this.obtenerReceptor();
    this.pedidoCensur = localStorage.getItem('pedidoCensur');
    this.idCensur = localStorage.getItem('idCensur');
  }

  obtenerCarritoStorage(){
    const storageCarrito = new CarritoCensur();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
    this.montoTotal = this.carrito.total;
  }

  obtenerReceptor(){
    this.paciente = JSON.parse(localStorage.getItem('receptor'))
  }

  habilitar(metodo:String, event){
    if (metodo == 'efectivo') {
      this.venta.Efectivo = true;
      if (event.target.value == 'on') {
        event.target.value = 'off'
      } else {
        event.target.value = 'on'
        event.target.checked = false;
        this.carrito.total = this.carrito.total + this.venta.montoEfectivo
        this.venta.Efectivo = false;
        this.venta.montoEfectivo =0;
      }
    }else if( metodo == 'tranfer' ){
      this.venta.Transferencia = true;
      if (event.target.value == 'on') {
        event.target.value = 'off'
      } else {
        event.target.value = 'on'
        event.target.checked = false;
        this.carrito.total = this.carrito.total + this.venta.montoTranseferencia
        this.venta.Transferencia = false;
        this.venta.montoTranseferencia =0;
      }
    } else if(metodo == 'credito'){
      this.venta.TarjetaCredito = true;
      if (event.target.value == 'on') {
        event.target.value = 'off'
        const iva = .028;
        let totalIva = (( this.carrito.total * iva ));
        const totalConIva =  this.carrito.total + totalIva;
        this.carrito.total =  parseFloat(totalConIva.toFixed(2));
      } else {
        event.target.value = 'on'
        event.target.checked = false;
        const iva = .028 + 1;
        let totalIva = (( this.carrito.total / iva ));
        this.carrito.total =  totalIva
        this.carrito.total = this.carrito.total + this.venta.montoCredito
        this.venta.TarjetaCredito = false;
        this.venta.montoCredito =0;
      }
    }else{
      this.venta.TarjetaDebito = true;
      if (event.target.value == 'on') {
        event.target.value = 'off'
        const iva = .018;
        let totalIva = (( this.carrito.total * iva ));
        const totalConIva =  this.carrito.total + totalIva;
        this.carrito.total =  parseFloat(totalConIva.toFixed(2));
      } else {
        event.target.value = 'on'
        event.target.checked = false;
        const iva = .018 + 1;
        let totalIva = (( this.carrito.total / iva ));
        this.carrito.total =  totalIva
        this.carrito.total = this.carrito.total + this.venta.montoDebito
        this.venta.TarjetaDebito = false;
        this.venta.montoDebito =0;
      }
    }
  }

  calculoEfectivo(){
    this.carrito.total = this.carrito.total - this.venta.montoEfectivo;
  }

  calculoTransferencia(){
    this.carrito.total = this.carrito.total - this.venta.montoTranseferencia;
  }

  calculoCredito(){
    this.carrito.total = this.carrito.total - this.venta.montoCredito;
  }

  calculoDebito(){
    this.carrito.total = this.carrito.total - this.venta.montoDebito;
  }

  enviar(){
    //todo : vic si hay algun problema descoemte estas lineas
     this.spinner.show();
     this.generarDocumentos();
    if (this.carrito.total == 0) {
      this.boton = true;
      this.setVenta();
      this._banco.agregarCarrito(this.venta).subscribe((resp:any)=>{
        if(resp.ok){
          this._banco.cambioEstadoCensur( this.idCensur )
          .subscribe( (data: any) => {
            if( data.ok ){
              this.generarTicket(resp['folio']);
              Swal.fire({
                icon: 'success',
                title: '',
                text: 'VENTA GENERADA EXITOSAMENTE',
              });
              this._router.navigateByUrl('/agregar/docuemtos/recpetor/firmados')
              //* hice el redirect al nuevo componente
            }
          });
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'Aun no puedes hacer el pago',
      })
    }
  }

  generarDocumentos(){
    let objeto = {
      nombreDonante:'',
      numeroProgresivoDonante:'',
      hemocomponente:'',
      grupoRh:'',
      fechaExtraccion:'',
      fechaCaducidad:'',
      volumenUnidad:'',
      hbHto:'',
      fechaSalida:'',
      horaSalida:'',
      unidadReceptora:'',
      domicilioUnidadReceptora:'',
      nombrePreparoUnidades:'',
      nombreQuienEnviaUnidades:'',
    }
    for (let index = 0; index < this.carrito.items.length; index++) {
      if(this.carrito.items[index].producto != 'Prueba tipar y cruzar' && this.carrito.items[index].producto != 'Grupo sanguineo y factor RH (anti D)'){
        this._banco.getPacienteDisponente(this.carrito.items[index].idDonante).subscribe((resp:any)=>{
          objeto.hemocomponente = this.carrito.items[index].producto
          objeto.fechaExtraccion = resp['data'].flebotomia.fecha
          objeto.nombreDonante = resp['data'].paciente.nombrePaciente.concat(' '+resp['data'].paciente.apellidoPaterno+' '+resp['data'].paciente.apellidoMaterno);
          objeto.numeroProgresivoDonante = resp['data']._id
          let encontrado = resp['data'].almacen.find(elemen => elemen.productosFI == this.carrito.items[index].idProducto);
          objeto.fechaCaducidad = encontrado.fecha_caducidad
          objeto.grupoRh = resp['data'].laboratorio[2].obtenidos[0]
          objeto.volumenUnidad = resp['data'].flebotomia.volumen
          objeto.hbHto = resp['data'].laboratorio[1].obtenidos[0].hematocrito8.concat(" / "+resp['data'].laboratorio[1].obtenidos[0].hemoglobina9)
          generarPdfComponentes(objeto);
         objeto = {
            nombreDonante:'',
            numeroProgresivoDonante:'',
            hemocomponente:'',
            grupoRh:'',
            fechaExtraccion:'',
            fechaCaducidad:'',
            volumenUnidad:'',
            hbHto:'',
            fechaSalida:'',
            horaSalida:'',
            unidadReceptora:'',
            domicilioUnidadReceptora:'',
            nombrePreparoUnidades:'',
            nombreQuienEnviaUnidades:'',
          }
        })
      }
    }
    this.spinner.hide();
  }

  setVenta(){
    const dates = new Dates(); 
    this.venta.fechaDeCreacion = dates.getDate();   
    this.venta.horaDeCreacion = moment().format('LT');
    this.venta.sede = JSON.parse(localStorage.getItem('cede'));
    this.venta.vendedor = JSON.parse(localStorage.getItem('usuario')).nombre;
    this.venta.total = this.montoTotal
    this.venta.idPaciente = this.paciente._id;
    this.venta.idBanco = localStorage.getItem('idCensur');
    this.venta.product = this.carrito.items
  }

  generarTicket(folio){
    const tickets = new Tickets();
    tickets.printTicketCansur( this.paciente, this.carrito ,  this.venta, folio);
  }

}
