import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-reacciones-derivadas',
  templateUrl: './reacciones-derivadas.component.html',
  styleUrls: ['./reacciones-derivadas.component.css']
})
export class ReaccionesDerivadasComponent implements OnInit {

  public idCensur;
  public paciente={
    apellidoMaterno: "",
    apellidoPaterno: "",
    edad: 0,
    nombrePaciente: "",
    _id: "",
    disponente: "",
    fechaNacimientoPaciente: "",
    estadoPaciente: "",
    callePaciente: "",
    telefono: 0,
  }
  public data={
    iniciodereaccion:'',
    tipodonante:'',
    presentacionreacciones:'',
    antecedentesreacciones:'',
    reaccionvasovagalleve:[],
    reaccionvasovagalmoderado:[],
    reaccionvasovagalsevera:[],
    reaccionvasovagalleveotros:'',
    reaccionvasovagalmoderadootros:'',
    reaccionvasovagalseveraotros:'',
    reaccionvenopuncionleve:[],
    reaccionvenopuncionmoderado:[],
    reaccionvenopuncionsevera:[],
    reaccionvenopuncionleveotros:'',
    reaccionvenopuncionmoderadootros:'',
    reaccionvenopuncionseveraotros:'',
    reacciontoxicidadleve:[],
    reacciontoxicidadmoderado:[],
    reacciontoxicidadsevera:[],
    reacciontoxicidadleveotros:'',
    reacciontoxicidadmoderadootros:'',
    reacciontoxicidadseveraotros:'',
    tiempodepresentacion:[],
    resultadosLabCalcio:'',
    resultadosLabMagnesio:'',
    tratamiento:'',
    paciente:''
  }
  
  constructor(private _banco: BancoService,
              private activatedRoute:ActivatedRoute){}

  ngOnInit(): void {
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    /* this.getDisponente(this.idCensur); */
  }

  reaccionesVasovagalleve(event){
    this.data.reaccionvasovagalleve = event
  }

  reaccionesVasovagalmoderada(event){
    this.data.reaccionvasovagalmoderado = event
  }

  reaccionesVasovagalsevera(event){
    this.data.reaccionvasovagalsevera = event
  }

  reaccionesVenopuncionlleve(event){
    this.data.reaccionvenopuncionleve = event
  }

  reaccionesVenopuncionmoderada(event){
    this.data.reaccionvenopuncionmoderado = event
  }

  reaccionesVenopuncionsevera(event){
    this.data.reaccionvenopuncionsevera = event
  }

  reaccionesToxicidadlleve(event){
    this.data.reacciontoxicidadleve = event
  }

  reaccionesToxicidadmoderada(event){
    this.data.reacciontoxicidadmoderado = event
  }

  reaccionesToxicidadsevera(event){
    this.data.reacciontoxicidadsevera = event
  }

  tiempodepresentacion(event){
    this.data.tiempodepresentacion = event
  }

  tratamiento(event){
    this.data.tratamiento = event
  }

  otrosSintomasLeveVeno(event){
    this.data.reaccionvenopuncionleveotros = event
  }

  otroModeradoVeno(event){
    this.data.reaccionvenopuncionmoderadootros = event
  }

  otroSeveroVeno(event){
    this.data.reaccionvenopuncionseveraotros = event
  }

  otrosSintomasLeveVaso(event){
    this.data.reaccionvasovagalleveotros = event
  }

  otrosSintomasModeradaVaso(event){
    this.data.reaccionvasovagalmoderadootros = event
  }

  otrosSintomasSeveraVaso(event){
    this.data.reaccionvasovagalseveraotros = event
  }

  LabCalcio(event){
    this.data.resultadosLabCalcio = event
  }

  LabMagnesio(event){
    this.data.resultadosLabMagnesio = event
  }

  otrosSintomasModeradaToxi(event){
    this.data.reacciontoxicidadmoderadootros=event
  }

  otrosSintomasSeveraToxi(event){
    this.data.reacciontoxicidadseveraotros = event
  }

  getDisponente(id){
    this._banco.getDisponente(id).subscribe((resp:any)=>{
      this.paciente = resp.data.paciente
    })
  }

  enviar(){

    /* this._banco.reaccionesDerivadas(this.data).subscribe((resp:any)=>{
      console.log(resp);
      
    }) */
  }
}
