import { Component, OnInit } from '@angular/core';
import { BancoService } from '../../../../services/bancodeSangre/banco.service';
import { preRegistro} from '../../../../functions/bancoSangre'
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { buscarReceptor } from 'src/app/classes/helpers/filtroNameReceptores';

@Component({
  selector: 'app-receptores',
  templateUrl: './receptores.component.html',
  styleUrls: ['./receptores.component.css']
})
export class ReceptoresComponent implements OnInit {
  public  filtropacientes = '';
  public pedidos=[];
  public fecha;

  public resultado:any;
 
  constructor(private _banco: BancoService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getOrdenes();
  }

  typeSearchPatients(){
    if(this.filtropacientes.length != 0){
      if(this.filtropacientes.length > 3){
        this.pedidos = buscarReceptor(this.pedidos, this.filtropacientes);
      }
    }else{
      this.getOrdenes();
    }
  }

  getOrdenes(){
    this.pedidos = [];
    this.spinner.show();
    this._banco.getOrdenPedido().subscribe((resp:any) =>{
      this.setOrdenes(resp.data)
    })
  }

  setOrdenes(resp){
    resp.forEach(element => {
      if(element.pedido != undefined){
        if(element.paciente != null){
          this.pedidos.push(element);
          this.spinner.hide();
        }
      }      
    });
    this.pedidos.reverse();
    this.spinner.hide();
  }

  /* validaciones(){
    this.fecha = moment().format('l'); 
    let regreso = historiaClinica(this.fecha, this.form);
    console.log(regreso);
    
  } */
}
