<div class="container">
    <h1 class="mayus titless">Entrega de Productos Sanguineos</h1>


    <section>
        <app-fichas-identificacion [data]="paciente" [rol]="'recepcion'"></app-fichas-identificacion>

    </section>

    <h2 class="mayus">Recepción de productos</h2>

    <section class="card">
        <div class="textoImprimir" style="margin-top: 1rem; margin-left: 3rem;">
            <p class="mayus textoImprimir">yo _______________________________ recibo productos hematicos para el</p>
            <p class="mayus textoImprimir">paciente _________________________. he recibido informacion al respecto</p>
            <p class="mayus textoImprimir">y firmo al calce.</p>
            <br>
            <p class="mayus textoImprimir">Firma: ________________________.</p>
        </div>
    </section>

    <div class="card">
        <p class="mayus" style="margin-top: 1rem; margin-left: 3rem; color: #000000;">productos sanguineos sin pruebas cruzadas por la solicitud de urgencia</p>
    </div>
    <button type="button" class="btn-label" (click)="etiquetaPorUrgencia()">Imprimir Etiqueta</button>
    <div class="centerxd btn btn-primary mayus" (click)="imprimirDeclaratoria()">imprimir</div>



</div>