<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">

            <app-titulos [title]="'HOJA DE REPORTE DE PATOLOGIA'" [rol]="'patologia'"> </app-titulos>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <!-- <ficha-info-user [pacienteInfo]="" title="true" [rol]="'patologia'"></ficha-info-user> -->

        </div>
    </div>

    <div class="row">
        <div class="card pb-4">
            <div class="container-fluid">
                <div class="row">
                    
                    <div class="col-md-12">
                        <h2 class="regresosTitle" > {{ estudio.ESTUDIO }} </h2>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="center mb-2" >
                            INTERPRETACIÓN
                        </h3>
                        <textarea name="interpretacion"  [(ngModel)]="obtenidosPatologia.interpretacion"  id="" cols="30" rows="10"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="card mt-4">
            <div class="container-fluid">
                <div class="row">
                
                    <div class="col-md-12 pt-3">
                        <h4 class="center mb-2" >
                            DIAGNOSTICO
                        </h4>
                        <textarea name="diagnostico"  [(ngModel)]="obtenidosPatologia.diagnostico" id="" cols="30" rows="2"></textarea>
                    </div>
                
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <h4 class="center mt-3" >
                            OBSERVACIONES
                        </h4>
                        <textarea name="observaciones"  [(ngModel)]="obtenidosPatologia.observaciones"  id="" cols="30" rows="2"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-12 offset-md-4 mb-3">
                    <button class="btnPrincipalBS mt-3" (click)="enviarReporte()" >
                        Guardar
                    </button>
                </div>
            </div>
            
        </div>
    </div>
</div>


<section class="display-none" id="sppiner-section" >
    <div class="row">
        <div class="sppiner-bg">
            <div class="spinner-stock">
                <div class="col-md-12">
                    <app-sppiner></app-sppiner>
                </div>
            </div>
    
        </div>
    </div>

</section>