import { Component, OnInit } from '@angular/core';
import { FlebotomiaService } from 'src/app/services/flebotomia.service';
@Component({
  selector: 'app-bitacora-flebotomia',
  templateUrl: './bitacora-flebotomia.component.html',
  styleUrls: ['./bitacora-flebotomia.component.css']
})
export class BitacoraFlebotomiaComponent implements OnInit {

  public pacientes :any[] = [];

  constructor(
    private _flebotomiaServices: FlebotomiaService,
  ) { }

  ngOnInit(): void {
    this.getPacientesFlebotomia();
  }


  getPacientesFlebotomia(){
    this._flebotomiaServices.getPacientesBitacora()
    .subscribe( (data: any) => {
      this.pacientes = data.data;
    })
  }

}
