<h1 class="centerxd"> REACCIONES DERIVADAS DE LA DONACIÓN </h1>

    <div class="card" name="card" id="card">
        <div class="row" id="principal">
            <div class="col margin-info">
                <p> <span>  Nombre Completo: {{ pacienteInfo.nombrePaciente | uppercase  }} {{ pacienteInfo.apellidoPaterno | uppercase  }} {{ pacienteInfo.apellidoMaterno | uppercase  }}</span> </p>
            </div>
            <div class="col">
                <p> <span> Edad: {{ pacienteInfo.edad }}</span> </p>
            </div>
            <div class="col">
                <p> <span> Género: {{pacienteInfo.genero | uppercase  }}</span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p>Domicilio: {{ pacienteInfo.callePaciente | uppercase }} {{pacienteInfo.estadoPaciente | uppercase }} {{ pacienteInfo.paisPaciente | uppercase }}</p>
            </div>
            <div class="col">
                <p>Fecha de Nacimiento: {{pacienteInfo.fechaNacimientoPaciente}}</p>
            </div>
            <div class="col">
                <p>Lugar de Nacimiento: {{ pacienteInfo.estadoPaciente | uppercase }} {{ pacienteInfo.paisPaciente | uppercase }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p>Teléfono: {{ pacienteInfo.telefono }}</p>
            </div>
            <div class="col">
                <p> <span> Tipo: {{pacienteInfo.disponente}}</span> </p>
            </div>
            <!-- <div class="col" *ngIf="pacienteInfo.disponente == 'DISPONENTE'">
                <p> <span> Tipo: </span> </p>
            </div> -->
            <div class="col">
                <p> ID: {{ pacienteInfo._id }}</p>
            </div>
        </div>

        <div class="row" id="religion">
            <div class="col">
                <p>Religión: {{pacienteInfo.religion}}</p>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-5" style="margin-left: 40px;">
                <div class="form-group row">
                    <label class="col-5 col-form-label"><span>Tipo de sangrado:</span></label>
                    <div class="form-group" style="margin-left: -10px; margin-top: 5px;">
                        <div class="form-check form-check-inline">
                            <input type="radio" value="SANGRE TOTAL" name="SI" [(ngModel)]="riesgo.valores" (change)="enviarDatos()">
                            <label class="form-check-label" style="margin-left: 5px; margin-top: 1px;"> SANGRE TOTAL</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" value="AFERESIS" name="NO" [(ngModel)]="riesgo.valores" (change)="enviarDatos()">
                            <label class="form-check-label" style="margin-left: 5px; margin-top: 1px;">AFERESIS</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-6">
                        <p style="margin-top: 5px;">Contenido obtenido por aferesis: </p>
                    </div>
                    <div class="col">
                        <input type="text" name="contenidoporaferesis" id="contenidoporaferesis" [(ngModel)]="contenido" (change)="enviarDatosCont()">
                    </div>
                </div>
            </div>
        </div> -->

        