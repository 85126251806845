import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compras-por-utilidad',
  templateUrl: './compras-por-utilidad.component.html',
  styleUrls: ['./compras-por-utilidad.component.css']
})
export class ComprasPorUtilidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
