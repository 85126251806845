import { Component, OnInit , Input} from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import {  getDataStorage  } from '../../../functions/storage.funcion'
import Swal from 'sweetalert2/dist/sweetalert2.js';
 


@Component({
  selector: 'app-historia-clinica',
  templateUrl: './historia-clinica.component.html',
  styleUrls: ['./historia-clinica.component.css']
})
export class HistoriaClinicaComponent implements OnInit {

  @Input() paciente  = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:0,
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    religion:'',
    tipoDeSangre:'',
    idPaciente:''
  };

  public pageTitle = "hojaEvo";

  public historialSginos :any[];


  public antecedentesHeredoFamiliares = {
    diabetes: "",
    cancer: "",
    cardiopatias: "",
    malformaciones: "",
    hipertension: "",
    hipertensionTipo: "",
    nefropatias: "",
    nefropatiasTipo: "",
    idPaciente: "",
    _id: ""
  }

  public esquemaVacuncaion = {
    tuberculosisNinoDosis:"",
    tuberculosisNinoFechaUno:"",
    tuberculosisNinoFechaDos:"",
    tuberculosisNinoFechaTres:"",
    tuberculosisNinoFechaCuatro:"",
    tuberculosisNinoFechaUltima:"",
    hepatitisNinoDosis:"",
    hepatitisNinoFechaUno:"",
    hepatitisNinoFechaDos:"",
    hepatitisNinoFechaTres:"",
    hepatitisNinoFechaCuatro:"",
    hepatitisNinoFechaUltima:"",
    pentavalenteNinoDosis:"",
    pentavalenteNinoFechaUno:"",
    pentavalenteNinoFechaDos:"",
    pentavalenteNinoFechaTres:"",
    pentavalenteNinoFechaCuatro:"",
    pentavalenteNinoFechaUltima:"",
    dptNinoDosis:"",
    dptNinoFechaUno:"",
    dptNinoFechaDos:"",
    dptNinoFechaTres:"",
    dptNinoFechaCuatro:"",
    dptNinoFechaUltima:"",
    rotavirusNinoDosis:"",
    rotavirusNinoFechaUno:"",
    rotavirusNinoFechaDos:"",
    rotavirusNinoFechaTres:"",
    rotavirusNinoFechaCuatro:"",
    rotavirusNinoFechaUltima:"",
    neumococoNinoDosis:"",
    neumococoNinoFechaUno:"",
    neumococoNinoFechaDos:"",
    neumococoNinoFechaTres:"",
    neumococoNinoFechaCuatro:"",
    neumococoNinoFechaUltima:"",
    influenzaNinoDosis:"",
    influenzaNinoFechaUno:"",
    influenzaNinoFechaDos:"",
    influenzaNinoFechaTres:"",
    influenzaNinoFechaCuatro:"",
    influenzaNinoFechaUltima:"",
    sprNinoDosis:"",
    sprNinoFechaUno:"",
    sprNinoFechaDos:"",
    sprNinoFechaTres:"",
    sprNinoFechaCuatro:"",
    sprNinoFechaUltima:"",
    sabinNinoDosis:"",
    sabinNinoFechaUno:"",
    sabinNinoFechaDos:"",
    sabinNinoFechaTres:"",
    sabinNinoFechaCuatro:"",
    sabinNinoFechaUltima:"",
    srNinoDosis:"",
    srNinoFechaUno:"",
    srNinoFechaDos:"",
    srNinoFechaTres:"",
    srNinoFechaCuatro:"",
    srNinoFechaUltima:"",
    otrasVacunasNinoDosis:"",
    otrasVacunasNinoFechaUno:"",
    otrasVacunasNinoFechaDos:"",
    otrasVacunasNinoFechaTres:"",
    otrasVacunasNinoFechaCuatro:"",
    otrasVacunasNinoFechaUltima:"",
    alergia:"",
    idPaciente: "",
    _id: ""
  }

  public hojaEvolucion = {
    motivoDeConsulta:'',
    evolucionDelPadecimiento:'',
    medicoTrante:'',
    diagnostico:[],
    plan:'',
    respiratorioCardiovascular:"",
    digestivo:"",
    endocrino:"",
    musculoEsqueletico:"",
    genitourinario:"",
    hematopoyeticoLinfatico:"",
    pielAnexos:"",
    neurologicoPsiquiatricos:"",
    piel:'',
    cabezaCuello:"",
    torax:"",
    abdomen:"",
    genitales:"",
    extremidades:"",
    sistemaNervioso:"",
    idPaciente: ""
  }



  public antecedentesPersonalesNoPatologicos = {
    tabaquismoPorDia: 0,
    copasPorDia:"",
    aniosconsumo: "",
    exFumadorPasivo: "",
    alcoholismoPorDia: "",
    aniosDeconsumoAlcohol: "",
    exAlcoholicoUOcasional: "",
    alergias: "",
    tipoAlergias: "",
    tipoSanguineo: "",
    desconoceTipoSanguineo: "",
    drogadiccionTipo: "",
    aniosConsumoDrogas: "",
    exDrogadicto: "",
    alimentacionAdecuada: "",
    viviendaConServiciosBasicos: "",
    otrosAntecedentesNoPatologicos: "",
    idPaciente: "",
    _id: ""
    }


    
    public antecedentesPersonalesPatologicos = {
      enfermedadesInfancia: "",
      enfermedadesInfanciaSecuelas: "",
      hospitalizacionesPrevias: "",
      hospitalizacionesPreviasEsp: "",
      antecedentesQuirurgicos: "",
      antecedentesQuirurgicosEsp: "",
      transfucionesPrevias: "",
      transfucionesPreviasEsp: "",
      fractutas: "",
      fracturasEsp: "",
      otrasEnfermedades: "",
      otrasEnfermedadesEsp: "",
      medicamentosActuales: "",
      medicamentosActualesEsp: "",
      accidentes: "",
      accidentesEsp: "",
      idPaciente: "",
      _id: ""
    }


  public medicinaPreventiva = {
    cancerMamaFechaToma:"",
    cancerMamaFechaEntrega:"",
    cancerMamaResultado:"",
    cancerEstomagoFechaToma:"",
    cancerEstomagoFechaEntrega:"",
    cancerEstomagoResultado:"",
    cancerEstomagoObservaciones:"",
    cancerColonFechaToma:"",
    cancerColonFechaEntrega:"",
    cancerColonResultado:"",
    cancerColonObservaciones:"",
    cancerPulmonFechaToma:"",
    cancerPulmonFechaEntrega:"",
    cancerPulmonResultado:"",
    cancerPulmonObservaciones:"",
    cancerProstataFechaToma:"",
    cancerProstataFechaEntrega:"",
    cancerProstataResultado:"",
    cancerProstataObservaciones:"",
    leucemiaFechaToma:"",
    leucemiaFechaEntrega: "",
    leucemiaResultado: "",
    leucemiaObservaciones: "",
    cancerCervicoUterinoFechaEntrega:"",
    cancerCervicoUterinoResultado:"",
    cancerCervicoUterinoObservaciones:"",
    agudezaAuditivaFechaToma:"",
    agudezaAuditivaFechaEntrega:"",
    agudezaAuditivaResultado:"",
    agudezaAuditivaObservaciones:"",
    saludBucalFechaToma:"",
    saludBucalFechaEntrega:"",
    saludBucalResultado:"",
    saludBucalObservaciones:"",
    desnutricionSobrePesoObesidadFechaToma:"",
    desnutricionSobrePesoObesidadFechaEntrega:"",
    desnutricionSobrePesoObesidadResultado:"",
    desnutricionSobrePesoObesidadObservaciones:"",
    diabetesFechaToma:"",
    diabetesFechaEntrega:"",
    diabetesResultado:"",
    diabetesObservaciones:"",
    agudezaVisualFechaToma:"",
    agudezaVisualFechaEntrega:"",
    agudezaVisualResultado:"",
    agudezaVisualObservaciones:"",
    enfermedadesRespiratoriasFechaToma:"",
    enfermedadesRespiratoriasFechaEntrega:"",
    enfermedadesRespiratoriasResultado:"",
    enfermedadesRespiratoriasObservaciones:"",
    trastornoMarchaFechaToma:"",
    trastornoMarchaFechaEntrega:"",
    trastornoMarchaResultado:"",
    trastornoMarchaObservaciones:"",
    otrosFechaToma:"",
    otrosFechaEntrega:"",
    otrosResultado:"",
    otrosObservaciones:"",
    cancerCUFechaEntrega:"",
    cancerCUResultado:"",
    cancerCUObservaciones:"",
    cancerMamaToma:"",
    cancerMamaEntrega:"",
    cancerMamaResultados:"",
    cancerMamaObservaciones:"",
    cancerCervicoUterinoFechaToma:"",
    idPaciente: "",
    _id: ""
  }

  public agregarGinecoObstetricosPorVisitaForm = {
    embarazoActual: "",
    embarazoAltoRiesgo: "",
    administracionHierroAcidoF: "",
    fechaAdmonHierroAcidoF: "",
    fechaAdmonHierro: "",
    fechaAdmonAcido: "",
    metodoAntiConceptivoPostParto: "",
    incioVidaSexualActiva: "",
    fechaUltimoParto: "",
    crecimientoBellosPubicos: "",
    tipoMetodoPlanFamiliar: "",
    gestas: "",
    partos: "",
    cesareas: "",
    abortos: "",
    ultimoPapanicolao: "",
    ultimaColposcopia: "",
    ultimaRevisionMamaria: "",
    bebesNacidos: "",
    bebesMuertos:"",
    primeraMenstruacion: "",
    crecimientoDePechos: "",
    primerEmbarazo: "",
    fechaPrimerEmbarazo: "",
    idPaciente: "",
    _id: ""
  }

  public antecedentesGinecoObstetricosHistoria = {
    menarcaAniosEdad: "",
    fechaUltimaMenstruacion: "",
    dismenorrea: "",
    ciclosRegulares: "",
    polimenorrea: "",
    ivsaAnios: "",
    fechaUltimaCitologia: "",
    ritmo: "",
    hipermenorrea: "",
    numParejasSexuales: "",
    resultado: "",
    gestas:"",
    partos:"",
    abortos:"",
    cesareas:"",
    idPaciente: "",
    _id: ""
  }


  public antecedentes = {
    enfermedadesPielPersonal: '',
    enfermedadesPielFecha: '',
    enfermedadesPielFamiliares: '',
    enfermedadesPielNotas: '',
    desnutricionPersonal: '',
    desnutricionFecha: '',
    desnutricionFamiliares: '',
    desnutricionNotas: '',
    obesidadPersonal: '',
    obesidadFecha: '',
    obesidadFamiliares: '',
    obesidadNotas: '',
    defectosPostularesPersonal: '',
    defectosPostularesFecha: '',
    defectosPostularesFamiliares: '',
    defectosPostularesNotas: '',
    fracturasPersonal: '',
    fracturasFecha: '',
    fracturasFamiliares: '',
    fracturasNotas: '',
    hospitalizacionesPersonal: '',
    hospitalizacionesFecha: '',
    hospitalizacionesFamiliares: '',
    hospitalizacionesNotas: '',
    transfucionesPersonal: '',
    transfucionesFecha: '',
    transfucionesFamiliares: '',
    transfucionesNotas: '',
    cardiopatiasPersonal: '',
    cardiopatiasFecha: '',
    cardiopatiasFamiliares: '',
    cardiopatiasNotas: '',
    cirugiasPersonal: '',
    cirugiasFecha: '',
    cirugiasFamiliares: '',
    cirugiasNotas: '',
    cancerLeucemiaPersonal: '',
    cancerLeucemiaFecha: '',
    cancerLeucemiaFamiliares: '',
    cancerLeucemiaNotas: '',
    alergiasPersonal: '',
    alergiasFecha: '',
    alergiasFamiliares: '',
    alergiasNotas: '',
    vihPersonal: '',
    vihFecha: '',
    vihFamiliares: '',
    vihNotas: '',
    tabaquismoFecha:"",
    tabaquismoFamiliares:"",
    tabaquismoNotas:"",
    tabaquismoPersonal:"",
    diabetesPersonal: "",
    diabetesFecha: "",
    diabetesFamiliares: "",
    diabetesNotas: "",
    tuberculosisPersonal:"",
    tuberculosisFecha:"",
    tuberculosisFamiliares:"",
    tuberculosisNotas:"",
    alcoholismoPersonal:"",
    alcoholismoFecha:"",
    alcoholismoFamiliares:"",
    alcoholismoNotas:"",
    deportesPersonal:"",
    deportesFecha:"",
    deportesFamiliares:"",
    deportesNotas:"",
    idPaciente: "",
    otrasEnfPersonales:"",
    otrasEnfFecha:"",
    otrasEnfFamiliares:"",
    otrasEnfNotas:"",
    enfermedadesDeLosSentidosPersonales :"",
    enfermedadesDeLosSentidosFecha :"",
    enfermedadesSentidosFamiliares :"",
    enfermedadesSentidosNotas :"",
    expoLaboralPersonales:"",
    expoLaboralFecha:"",
    expoLaboralFamiliares:"",
    expoLaboralNotas:"",
    postQuirurgicoPersonales:"",
    postQuirurgicoFecha:"",
    postQuirurgicoFamiliares:"",
    postQuirurgicoNotas:"",
    _id: ""
  }

  public nutricionNinos = {
    alimentarSanamenteNinosMesUno: "",
    alimentarSanamenteNinosMesDos: "",
    alimentarSanamenteNinosMesTres: "",
    alimentarSanamenteNinosMesCuatro: "",
    alimentarSanamenteNinosMesCinco: "",
    alimentarSanamenteNinosMesSeis: "",
    desparacitacionIntestinalNinosMesUno: "",
    desparacitacionIntestinalNinosMesDos: "",
    desparacitacionIntestinalNinosMesTres: "",
    desparacitacionIntestinalNinosMesCuatro: "",
    desparacitacionIntestinalNinosMesCinco: "",
    desparacitacionIntestinalNinosMesSeis: "",
    vitaminaANinosMesUno: "",
    vitaminaANinosMesDos: "",
    vitaminaANinosMesTres: "",
    vitaminaANinosMesCuatro: "",
    vitaminaANinosMesCinco: "",
    vitaminaANinosMesSeis: "",
    hierroNCBPNinosMesUno: "",
    hierroNCBPNinosMesDos: "",
    hierroNCBPNinosMesTres: "",
    hierroNCBPNinosMesCuatro: "",
    hierroNCBPNinosMesCinco: "",
    hierroNCBPNinosMesSeis: "",
    hierroPPNinosMesUno: "",
    hierroPPNinosMesDos: "",
    hierroPPNinosMesTres: "",
    hierroPPNinosMesCuatro: "",
    hierroPPNinosMesCinco: "",
    hierroPPNinosMesSeis: "",
    otrosNinosMesUno: "",
    otrosNinosMesDos: "",
    otrosNinosMesTres: "",
    otrosNinosMesCuatro: "",
    otrosNinosMesCinco: "",
    otrosNinosMesSeis: "",
    notasMesUno: "",
    notasMesDos: "",
    notasMesTres: "",
    notasMesCuatro: "",
    notasMesCinco: "",
    notasMesSeis: "",
    idPaciente: "",
    _id: ""
  }

  constructor(
    private _HistoriaClinicaService: HistoriaClinicaService,
    public _consultaService: ConsultaService,
    public _WsLoginService: WsLoginService
  ) { }

  ngOnInit(): void {
    /* console.log( this.paciente); */
    // console.log();
    
    this.hojaEvolucion.medicoTrante = getDataStorage().nombre;

  }

  // Transformar consultas a numeros
  


  obtenerAntecedentes(){
    
    /* console.log(this.paciente); */
    
    this.antecedentes.idPaciente= this.paciente.idPaciente;
    
    this._HistoriaClinicaService.obtenerAntecedentes( this.antecedentes.idPaciente  )
    .subscribe((data:any) => {
        /* console.log(data); */
        if(data['data'].length == 0) {

          return;
        }
        
        if(  data['data'].length >= 1 ){
        this.antecedentes = data['data'][0];
        /* console.log( this.antecedentes ); */
    
      }



    })
  
  }  

  agregarGinecoObstetricosHistoria(){
    this.antecedentesGinecoObstetricosHistoria.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarGinecoObstetricosHC( this.antecedentesGinecoObstetricosHistoria )
    .subscribe(  (data:any) =>{
        
        if (data['ok']) {
           Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS ANTECEDENTES GINECO OBSTETRICOS SE GUARDARON CORRECTAMENTE',
            })
          /* alert('Se guardaron los Antecedentes Gineco Obstetricos Historia C.'); */
        }
    });
}



  obtenerGinecoHistoria(){
    this._HistoriaClinicaService.obtenerGinecoHistoria(this.paciente.idPaciente)
    .subscribe((data:any) => {
      if(data['data'].length == 0){
        return
      }else if (data['data'].length >= 1 ){
        this.antecedentesGinecoObstetricosHistoria = data['data'][0];
        /* console.log(this.antecedentesGinecoObstetricosHistoria); */
        
      }
    })
  }


  agregarGinecoObstetricosPorVisita(){
    this.agregarGinecoObstetricosPorVisitaForm.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarGinecoObstetricosPorVisita ( this.agregarGinecoObstetricosPorVisitaForm )

    .subscribe((data:any) => {
      /* console.log(data); */
      

      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'Se guardaron los Antecedentes Gineco Obstetricos Historia por Visita',
            })
        /* alert('Se guardaron los Antecedentes Gineco Obstetricos Historia por Visita'); */
      }
    } );
  }

  // Esquema de Vacunación
  agregarVacunacionNinos() {
    this.esquemaVacuncaion.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarEsquemaVacunacion( this.esquemaVacuncaion )
    .subscribe((data:any) => {
      /* console.log(data); */

      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDARDO EL ESQUEMA DE VACUNACION',
            })
        /* alert('Se Guardó el esquema de Vacunación') */
      }
      
    });
  }

  obtenerVacunacionNinos() {
    this.esquemaVacuncaion.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.obtenerEV(this.esquemaVacuncaion.idPaciente)
    .subscribe((data:any) => {

      if(data['data'].length == 0) {
        // this.esquemaVacuncaion = data['data'][0];  
        return;
      }
      if(  data['data'].length >= 1 ){
        this.esquemaVacuncaion = data['data'][0];
        /* console.log( this.esquemaVacuncaion ); */
    
      }
        // this.esquemaVacuncaion = data['data'][0];  
    
      // console.log(data);

     
    })
  }



  // Fin Vacunacion

  agregarNutricionN() {
    this.nutricionNinos.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarNutricion(this.nutricionNinos)
    .subscribe((data:any) => {
      /* console.log(data); */
      
      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDO NUTRICION',
            })
        /* alert('Se guardó Nutrición'); */
      }
    })
  }

  obtenerNutricionPrros() {
    this.nutricionNinos.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.obtenerNutricion(this.nutricionNinos.idPaciente)
    .subscribe((data:any) => {
      // console.log(data);
      if(data['data'].length == 0) {
        this.nutricionNinos = data['data'][0];  
        return;
      }else if (data['data'].length >= 1) {
        
        this.nutricionNinos = data['data'][0]
      } 

      
    })
  }

  obtenerGinecoPorVisita() {
    this._HistoriaClinicaService.obtenerGinecoPorVisita(this.paciente.idPaciente)
    .subscribe((data:any) => {

      /* console.log(data); */
      
      if ( data['data'].length == 0 ) {
        return;
      }else if( data['data'].length >= 1) {

        this.agregarGinecoObstetricosPorVisitaForm.embarazoActual = data['data'][0]['embarazoActual'];
        this.agregarGinecoObstetricosPorVisitaForm.embarazoAltoRiesgo = data['data'][0]['embarazoAltoRiesgo'];
        this.agregarGinecoObstetricosPorVisitaForm.administracionHierroAcidoF = data['data'][0]['administracionHierroAcidoF'];
        this.agregarGinecoObstetricosPorVisitaForm.fechaAdmonHierroAcidoF = data['data'][0]['fechaAdmonHierroAcidoF'];
        this.agregarGinecoObstetricosPorVisitaForm.fechaAdmonHierro = data['data'][0]['fechaAdmonHierro'];
        this.agregarGinecoObstetricosPorVisitaForm.fechaAdmonAcido = data['data'][0]['fechaAdmonAcido'];
        this.agregarGinecoObstetricosPorVisitaForm.metodoAntiConceptivoPostParto = data['data'][0]['metodoAntiConceptivoPostParto'];
        this.agregarGinecoObstetricosPorVisitaForm.incioVidaSexualActiva = data['data'][0]['incioVidaSexualActiva'];
        this.agregarGinecoObstetricosPorVisitaForm.fechaUltimoParto = data['data'][0]['fechaUltimoParto'];
        this.agregarGinecoObstetricosPorVisitaForm.crecimientoBellosPubicos = data['data'][0]['crecimientoBellosPubicos'];
        this.agregarGinecoObstetricosPorVisitaForm.tipoMetodoPlanFamiliar = data['data'][0]['tipoMetodoPlanFamiliar'];
        this.agregarGinecoObstetricosPorVisitaForm.gestas = data['data'][0]['gestas'];
        this.agregarGinecoObstetricosPorVisitaForm.partos = data['data'][0]['partos'];
        this.agregarGinecoObstetricosPorVisitaForm.cesareas = data['data'][0]['cesareas'];
        this.agregarGinecoObstetricosPorVisitaForm.abortos = data['data'][0]['abortos'];
        this.agregarGinecoObstetricosPorVisitaForm.ultimoPapanicolao = data['data'][0]['ultimoPapanicolao'];
        this.agregarGinecoObstetricosPorVisitaForm.ultimaColposcopia = data['data'][0]['ultimaColposcopia'];
        this.agregarGinecoObstetricosPorVisitaForm.ultimaRevisionMamaria = data['data'][0]['ultimaRevisionMamaria'];
        this.agregarGinecoObstetricosPorVisitaForm.bebesNacidos = data['data'][0]['bebesNacidos'];
        this.agregarGinecoObstetricosPorVisitaForm.bebesMuertos = data['data'][0]['bebesMuertos'];
        this.agregarGinecoObstetricosPorVisitaForm.primeraMenstruacion = data['data'][0]['primeraMenstruacion'];
        this.agregarGinecoObstetricosPorVisitaForm.crecimientoDePechos = data['data'][0]['crecimientoDePechos'];
        this.agregarGinecoObstetricosPorVisitaForm.primerEmbarazo = data['data'][0]['primerEmbarazo'];
        this.agregarGinecoObstetricosPorVisitaForm.fechaPrimerEmbarazo = data['data'][0]['fechaPrimerEmbarazo'];

      }
    })
  }



agregarAntecedentes(){


  this.antecedentes.idPaciente = this.paciente.idPaciente;

  this._HistoriaClinicaService.agregarHistoriaClinica(  this.antecedentes  )
  .subscribe(  (data:any) => { 
      if(data['ok']){
        /* console.log(data); */
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE AGREGARON LOS ANTECEDENTES',
            })
        /* alert('Antecedentes agregados'); */
      }
  });
}

actualizarAntecedentes(){
  this.antecedentes.idPaciente = this.paciente.idPaciente;

  this._HistoriaClinicaService.actualizarAntecedentesPaciente(this.antecedentes.idPaciente, this.antecedentes)
  .subscribe((data:any) => {
    if(data['ok']){ Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZARON LOS ANTECEDENTES',
            })
      /* alert('Se actualizaron los Antecedentes') */

      /* console.log(data); */
      
    }
  })
}



obtenerMedicinaP(){
 
    this._HistoriaClinicaService.obtenerMedicinaPreventiva( this.paciente.idPaciente)
    .subscribe((data:any) => {

      /* console.log(data); */

      if (data['data'].length == 0) {
        return;
      }else if (data['data'].length >= 1) {
        this.medicinaPreventiva = data['data'][0]
      }
      

    })
  }




  agregarMedicinaP(){
    this.medicinaPreventiva.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarMedicinaPreventiva( this.medicinaPreventiva )
    .subscribe( (data:any )=> {
          /* console.log(data); */
          
        if( data['ok'] ){
           Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDADO LA MEDICINA PREVENTIVA',
            })
          
        }
    });


  }

  actualizarMedicinaP(){
    this._HistoriaClinicaService.actualizarMedicinaPreventivaEnElPaciente(this.medicinaPreventiva._id, this.medicinaPreventiva)
    .subscribe((data:any) => {
      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZO LA MEDICINA PREVENTIVA',
            })
        /* alert('Se Actualizó la Médicina Preventiva') */
      }
    })
  }
  ////////////////////////////////////////////////
  /////////// REVISAR UN ERROR REFERENTE A LOS HEADERS, ERROR 5000
  ////////// SOLO IMPLEMENTÉ UNA ALERTA XDDDDDDDDDDDDDDDD

  agregarInterrogacionAparatos() {
    this.hojaEvolucion.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarAparatosYsistemas( this.hojaEvolucion )
    .subscribe( ( data:any ) => {
      
      if( data['ok'] ) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDO LA INTERROGACION POR APARATOS Y SISTEMAS',
            })
        /* alert('Se guardó la interrogación por aparatos y sistemas'); */
      }
    } )
  }

  actulizarAparatosSistemas(){
    this.hojaEvolucion.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.actualizarAparatosYsistemas( this.hojaEvolucion.idPaciente, this.hojaEvolucion)
    .subscribe( ( data:any ) => {
      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZO LA INTERROGACION POR APARATOS Y SISTEMAS',
            })
        /* alert('Se actualizó la interrogación por aparatos y sistemas') */
      }
    } );
  }

  agregarPersonalesPatologicos(){

    this.antecedentesPersonalesPatologicos.idPaciente = this.paciente.idPaciente;

    /* console.log( this.antecedentesPersonalesPatologicos ); */
    
    this._HistoriaClinicaService.agregarPersonalesPatologicos( this.antecedentesPersonalesPatologicos )
    .subscribe( ( data:any ) => {
      if(data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDO LA HISTORIA CLINICA',
            })
        /* alert('Se guardo la historia clinica'); */
      }
    });
  }

  agregarPersonalesNoPatologicos(){
    this.antecedentesPersonalesNoPatologicos.idPaciente = this.paciente.idPaciente;


    /* console.log( this.antecedentesPersonalesNoPatologicos ); */

    this._HistoriaClinicaService.agregarPersonalesNoPatologicos( this.antecedentesPersonalesNoPatologicos )
    .subscribe( ( data: any ) => {
        if( data['ok'] ){
           Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE AGREGARON LOS ANTECEDENTES PERSONALES NO PATOLOGICOS',
            })
          /* alert('Se agregaron los antecedentes personales no patologicos'); */
        }
    })
  }




  verAntecedentesNoPatologicos(){
    this._HistoriaClinicaService.verAntecendetesNoPatoloogicos( this.paciente.idPaciente  )
    .subscribe(  (data) => {
            /* console.log(data); */

            if(data['data'].length == 0 ) {

            } else if(data['data'].length >= 1) {
              this.antecedentesPersonalesNoPatologicos.tabaquismoPorDia = data['data'][0]['tabaquismoPorDia']
              this.antecedentesPersonalesNoPatologicos.aniosconsumo = data['data'][0]['aniosconsumo']
              this.antecedentesPersonalesNoPatologicos.exFumadorPasivo = data['data'][0]['exFumadorPasivo']
              this.antecedentesPersonalesNoPatologicos.alcoholismoPorDia = data['data'][0]['alcoholismoPorDia']
              this.antecedentesPersonalesNoPatologicos.aniosDeconsumoAlcohol = data['data'][0]['aniosDeconsumoAlcohol']
              this.antecedentesPersonalesNoPatologicos.exAlcoholicoUOcasional = data['data'][0]['exAlcoholicoUOcasional']
              this.antecedentesPersonalesNoPatologicos.alergias = data['data'][0]['alergias']
              this.antecedentesPersonalesNoPatologicos.tipoAlergias = data['data'][0]['tipoAlergias']
              this.antecedentesPersonalesNoPatologicos.tipoSanguineo = data['data'][0]['tipoSanguineo']
              this.antecedentesPersonalesNoPatologicos.desconoceTipoSanguineo = data['data'][0]['desconoceTipoSanguineo']
              this.antecedentesPersonalesNoPatologicos.drogadiccionTipo = data['data'][0]['drogadiccionTipo']
              this.antecedentesPersonalesNoPatologicos.aniosConsumoDrogas = data['data'][0]['aniosConsumoDrogas']
              this.antecedentesPersonalesNoPatologicos.exDrogadicto = data['data'][0]['exDrogadicto']
              this.antecedentesPersonalesNoPatologicos.alimentacionAdecuada = data['data'][0]['alimentacionAdecuada']
              this.antecedentesPersonalesNoPatologicos.viviendaConServiciosBasicos = data['data'][0]['viviendaConServiciosBasicos']
              this.antecedentesPersonalesNoPatologicos.otrosAntecedentesNoPatologicos = data['data'][0]['otrosAntecedentesNoPatologicos']
              this.antecedentesPersonalesNoPatologicos._id = data['data'][0]['_id'];  

            }
          });
  }

  actualizarNoPatologicos(){
    this._HistoriaClinicaService.actualizarPersonalesNoPatologicosEnElPaciente(this.antecedentesPersonalesNoPatologicos._id, this.antecedentesPersonalesNoPatologicos)
    .subscribe( ( data:any ) => {
      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZARON LOS ANTECEDENTES PERSONALES NO PATOLOGICOS',
            })
        /* alert('Se actualizó Antecedentes Personales No Patologicos') */
      }
    } );
  }

  obtenerHeredoFamiliaresXPaciente(){
  this._HistoriaClinicaService.obtenerHeredoFamiliares(this.paciente.idPaciente)
  .subscribe((data:any) => {

    /* console.log( data ); */

    if (data['data'].length == 0) {
      
      return;
    }else if(data['data'].length >= 1){

      this.antecedentesHeredoFamiliares = data['data'][0];
    }
    
  })
}


  ///////////////////////////////Agregar Antecedentes Heredo Familiares
  agregarAntecedentesHeredoFamimiares(){
      this.antecedentesHeredoFamiliares.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.agregarHeredoFamiliares( this.antecedentesHeredoFamiliares )
    .subscribe((data:any) => {
      if(data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE AGREGARON LOS ANTECEDENTES',
            })
        /* alert('Antecedentes agregados'); */
      }
    })
  }

  actualizarHeredoFamiliares(){
    this._HistoriaClinicaService.actualizarHeredoFamiliaresEnElPaciente(this.antecedentesHeredoFamiliares._id, this.antecedentesHeredoFamiliares)
    .subscribe((data:any) => {
      if(data['ok']) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZARON LOS ANTECEDENTES HEREDO FAMILIARES',
            })
        /* alert('Se actualizaron los antecedentes Heredo Familiares')
        console.log(data); */
        
      }
    });
  }

  verSignosVitalesAnteriores(){

    /* console.log( this.paciente.idPaciente ); */
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente( this.paciente.idPaciente )
    .subscribe( (data) =>   {
      /* console.log(data); */  
      this.historialSginos = data['data'] 
      /* console.log(this.historialSginos); */
      
    });
  }


  verAntecedentesPersonalesPatologicos(){
    this.antecedentesPersonalesPatologicos.idPaciente = this.paciente.idPaciente;

    this._HistoriaClinicaService.obtenerAntecedentePersonalePatologicos( this.paciente.idPaciente)
    .subscribe( (data) => { 

      if(data['data'].length == 0) {

      }else if(data['data'].length >= 1){
       this.antecedentesPersonalesPatologicos.enfermedadesInfancia = data['data'][0]['enfermedadesInfancia'];
       this.antecedentesPersonalesPatologicos.enfermedadesInfanciaSecuelas = data['data'][0]['enfermedadesInfanciaSecuelas'];
       this.antecedentesPersonalesPatologicos.hospitalizacionesPrevias = data['data'][0]['hospitalizacionesPrevias'];
       this.antecedentesPersonalesPatologicos.hospitalizacionesPreviasEsp = data['data'][0]['hospitalizacionesPreviasEsp'];
       this.antecedentesPersonalesPatologicos.antecedentesQuirurgicos = data['data'][0]['antecedentesQuirurgicos'];
       this.antecedentesPersonalesPatologicos.antecedentesQuirurgicosEsp = data['data'][0]['antecedentesQuirurgicosEsp'];
       this.antecedentesPersonalesPatologicos.transfucionesPrevias = data['data'][0]['transfucionesPrevias'];
       this.antecedentesPersonalesPatologicos.transfucionesPreviasEsp = data['data'][0]['transfucionesPreviasEsp'];
       this.antecedentesPersonalesPatologicos.fractutas = data['data'][0]['fractutas'];
       this.antecedentesPersonalesPatologicos.fracturasEsp = data['data'][0]['fracturasEsp'];
       this.antecedentesPersonalesPatologicos.otrasEnfermedades = data['data'][0]['otrasEnfermedades'];
       this.antecedentesPersonalesPatologicos.otrasEnfermedadesEsp = data['data'][0]['otrasEnfermedadesEsp'];
       this.antecedentesPersonalesPatologicos.medicamentosActuales = data['data'][0]['medicamentosActuales'];
       this.antecedentesPersonalesPatologicos.medicamentosActualesEsp = data['data'][0]['medicamentosActualesEsp'];
       this.antecedentesPersonalesPatologicos.accidentes = data['data'][0]['accidentes'];
       this.antecedentesPersonalesPatologicos.accidentesEsp = data['data'][0]['accidentesEsp'];
      }
      });
  } 

  actualizarPersonalesPatologicos(){
    this._HistoriaClinicaService.actualizarPersonalesPatologicos(this.antecedentesPersonalesPatologicos._id, this.antecedentesPersonalesPatologicos)
    .subscribe((data:any) => {
      if(data['ok']) {
        /* console.log('Se actualizaron los Antecedentes Personales Patológicos'); */
        
      }
    })
  }

  actualizarGinecoPorVisitalt(){
    this._HistoriaClinicaService.actualizarGinecoPorVisita(this.agregarGinecoObstetricosPorVisitaForm._id, this.agregarGinecoObstetricosPorVisitaForm)
    .subscribe((data:any) => {
      
      if(data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZARON LOS ANTECEDENTES',
            })
        /* alert('Se actualizaron los Antecedentes') */
      }
    })
  }

  actualizarGinecoHistoria(){
    this._HistoriaClinicaService.actualizarGinecoH(this.antecedentesGinecoObstetricosHistoria._id, this.antecedentesGinecoObstetricosHistoria)
    .subscribe((data:any) => {
      if(data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZARON LOS ANTECEDENTES',
            })
        /* alert('Se actualizaron los Antecedentes') */
      }
    })
  }

  actualizarEsquemaVacunacion(){
    this._HistoriaClinicaService.actualizarEV(this.esquemaVacuncaion._id, this.esquemaVacuncaion)
    .subscribe((data:any) => {
      if(data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZO EL ESQUEMA DE VACUNACION',
            })
        /* alert('Se actualizó el Esquema de Vacunación'); */
      }
    })
  }

  actualizarNutricionN(){
    this._HistoriaClinicaService.actualizarNutricion(this.nutricionNinos._id, this.nutricionNinos)
    .subscribe((data:any) => {
      if(data['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZO NUTRICION',
            })
        /* alert('Se Actualizó Nutrición'); */
        
      }
    })
  }


}
