<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="'PROCESOS DE SERVICIOS'"></app-titulos>
        </div>
        <div class="col">
            <h1 class="fecha" id="fecha"> {{ today }} </h1>
        </div>
    </div>

    <div class="row">
        <div class="card">
            <div class="card-heder header mayus">
                <div class="row">
                    <div class="col text_header">
                        <p>SERVICIOS</p>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="col pointer">
                    <p>Utilidades por servicios</p>
                </div>
                <div class="col pointer">
                    <p>Compras con utilidades</p>
                </div>
                <div class="col pointer" [routerLink]="['/utilidades/servicio']">
                    <p>Orden de servicio</p>
                </div>
                <div class="col pointer">
                    <p>Orden de servico por unidad</p>
                </div>
                
                <div class="col pointer" [routerLink]="['/sedes/servicio']">
                    <p>Socios de canal</p>
                </div>

                <div class="col pointer" [routerLink]="['/utilidades/por/sede']">
                    <p>
                        Dividendos por sede    
                    </p>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <section class="container-fluid">
    <div class="row">
        <div class="col">
            <h1 class="centerxd" id="centerxd">PROCESOS DE SERVICIOS</h1>
        </div>
        <div class="col">
            <h1 class="fecha" id="fecha"> {{ today }} </h1>
        </div>
    </div>
</section>

<br>

<section class="container-fluid">
    <div class="row">
        <div class="card">
            <div class="card-header header">
                <div class="row">
                    <div class="col-md-12 text_header">
                        <p class="text-center">Servicios </p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div>

                    <div class="col ">
                        <p class="font text-center" style="color: #001672;"> Utilidades por servicios</p>
                    </div>
                    <hr>
                    <div class="col">
                        <p class="font text-center" style="color: #001672;">Compras con utilidades</p>
                    </div>
                    <hr>
                    <div class="col" [routerLink]="['/utilidades/servicio']" routerLinkActive="router-link-active">
                        <p class="font text-center" style="color: #001672;">Orden de servicio</p>
                    </div>
                    <hr>
                    <div class="col">
                        <p class="font text-center" style="color: #001672;">Orden de servico por unidad</p>
                    </div>
                    <hr>
                    <div class="col" [routerLink]="['/sedes/servicio']">
                        <p class="font text-center" style="color: #001672;">Socios de canal</p>
                    </div>
                    <hr>
                </div>
            </div>

        </div>
    </div>

</section> -->