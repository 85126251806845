<!-- <h1 class="centerxd">HISTORICO DE ESTUDIOS DE ULTRASONIDO</h1> -->
<div class="container-fluid">
    <app-titulos [title]="'HISTORICO DE ESTUDIOS DE ULTRASONIDO'"></app-titulos>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>No.</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL ESTUDIO</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>ACCIONES</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let ultra of usg | paginate:{id:'listapacientes',
                                                              itemsPerPage: 6,
                                                              currentPage:pagina,
                                                              totalItems:totalUlta}; let i = index">
                        <div class="row puntero">
                            <div class="col-md-1" style="text-align: center;">
                                <p>{{i + 1}}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ultra.ESTUDIO}}</p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <div class="col">
                                    <i class="fas fa-edit" id="ver"></i>   
                                    <!-- <button type="button" class="btn btn-success" [routerLink]="['/registro/elementos/ultrasonido', ultra._id]" >editar</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>

<!-- <div class="card" style="border-radius: 30px;">
    <div class="row">
        <div class="col-md-12">


            <table class="table table-hover colors izq" id="bitacora">

                <thead class="center" style="background-color: #03318C;">

                    <tr style="color: white;">

                        <th scope="col"> No.</th>
                        <th scope="col"> NOMBRE DEL ESTUDIO </th>
                        <th scope="col"> ACCIONES</th>
                    </tr>




                </thead>

                <tbody class="center" style="background-color: white;color: black;">

                    <tr *ngFor="let ultra of usg; let i = index">



                        <td> {{i + 1}}</td>
                        <td> {{ultra.ESTUDIO}}</td>
                        <td>
                            <div class="col">
                                <button type="button" class="btn btn-success" [routerLink]="['/registro/elementos/ultrasonido', ultra._id]" >editar</button>
                                
                            </div>

                            

                        </td>



                    </tr>
                </tbody>

            </table>
        </div>


    </div>

</div>

<br> -->