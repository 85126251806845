<div class="container-fluid">
    <app-titulos [title]="'registro de materiales'| uppercase"></app-titulos>

    <div class="card cardPer container">

        <h2 class="title-secondary">DATOS DEL MATERIAL</h2>

        <form [formGroup]="newFormMateriales" >
            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> NOMBRE:</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" name="nombre" class="form-control shadow-input-bs" formControlName="nombre" />
                </div>
    
    
                <div class="col-md-2">
                    <label class="mayus" for="idProducto"> ID: </label>
                </div>
                <div class="col-md-4">
                    <input type="text" [value]="newIdMaterial" disabled  name="idProducto" id="idProducto" class="form-control shadow-input-bs" formControlName="idProducto" />
                </div>
    
            </div>
                
            <div class="row mt-4">
                <!-- <div class="col-md-2">
                    <label class="mayus">tipo de producto:</label>
                </div>
    
                <div class="col-md-4">
    
                    <select class="custom-select mayus selects" name="tipo_producto" id="inputGroupSelect01" formControlName="tipo_producto">
                        <option selected>selecciona un producto...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                </div> -->
                <!-- <div class="col-md-2">
                    <label class="mayus">NOMBRE COMERCIAL</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control shadow-input-bs" name="nombre_comercial" id="nombre_comercial" formControlName="nombre_comercial" />
                </div> -->
                <!-- <div class="col-md-2">
                    <label class="mayus">COSTO</label>
               </div>
                <div class="col-md-4">
                    <div class="input-group">
                    <input type="text" class="form-control costoInput"  formControlName="costo" name="costo" />

                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroupPrepend3" style="border-bottom-right-radius: 10px;
                        border-top-right-radius: 10px;">MX</span>
                    </div>
                    </div>
                </div> -->
            </div>
            
            <div formArrayName="proveedor" *ngFor="let valor of valoresProveedoresFB.controls; let j = index" style="margin-top: 15px;">
                <div  [formGroupName]="j">
                    <div class="row mb-3">
                        <div class="col-md-2">
                            <label>PROVEEDOR:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="custom-select mayus"  id="inputGroupSelect01"  formControlName="proveedor" >
                                <option [value]="proveedor.razonSocial"   *ngFor="let proveedor of proveedoresCensur" > {{ proveedor.nombreEmpresa }} </option>
                                <!-- <option value="Vic"></option> -->
                              </select>
                        </div>
                        <div class="col-md-2">
                            <label>COSTO: </label>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                            <input type="text" class="form-control costoInput"  formControlName="costo" name="costo" />
        
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3" style="border-bottom-right-radius: 10px;
                                border-top-right-radius: 10px;">MX</span>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <i class="fa fa-trash-alt colorRed pointer" (click)="removeValores(j)"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div formArrayName="proveedor"  *ngFor="let valor of valoresProveedoresFB.controls; let j = index" >
        
                <div [formGroupName]="j" >

                    <div class="row mt-4">
                        <div class="col-md-2">
                            <label class="mayus">PROVEEDOR</label>
                        </div>
                        <div class="col-md-4">
                            <select class="custom-select selects mayus" id="inputGroupSelect01" name="" formControlName="proveedor" >
                                <option [value]="proveedor.razonSocial" *ngFor="let proveedor of proveedoresCensur">  {{ proveedor.razonSocial }}</option>
                              </select>
                              <br>
                              <br>
                                <a class="proveedor" type="button" data-toggle="modal" data-target=".bd-example-modal-lg">Agregar NUEVO Proveedor</a>
                              <br>
                              <br>
                        </div>
                       
                    </div>
                </div>
            </div> -->

            <!-- ! Modal de los Proveedores -->

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Agregar Proveedor</h5> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <app-registro-proveedores-bs (agregar)="agregarNuevoProveedor($event)"></app-registro-proveedores-bs>
                  </div>
                </div>
            </div>

            <!-- ! Fin Modal Proveedores -->
    
            <div class="row mb-3">
                <div class="col-md-2">
                    <label class="mayus">FABRICANTE</label>
                </div>
                <div class="col-md-4">
                    <input type="text"    class="form-control shadow-input-bs" name="" id="laboratorio" formControlName="laboratorio" />
                </div>
                <div class="col-md-2">
                    <label>CANTIDAD MINIMA:</label>
                </div>
                <div class="col-md-4">
                    <input type="number" min="0" class="input" name="cantidadMin" id="" formControlName="cantidadMin">   
                </div>
            </div>
    
            <div class="row ml-1 mb-4">
                <div class="col-md-3">
                    <label class="proveedor" (click)="addAProveedor()" >agregar  proveedor</label>
                </div>
                <div class="col-md-3">
                    <a class="proveedor" type="button" data-toggle="modal" data-target=".bd-example-modal-lg">Agregar NUEVO Proveedor</a>
                </div>
            </div>
    
            <div class="row ml-1">
                <div class="col-md-12">
                    <label class="mayus">descripción:</label>
    
                </div>
                <div class="col-md-12">
                    
                    <textarea class="mb-3 .selects txtarea" name="descripcion" id="" cols="2" rows="3" formControlName="descripcion" ></textarea>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-2 offset-md-5">
                    <button  class="btn btnPrincipalBS"  [disabled]="btnValidateSend" (click)="sendANewMaterial()" >
                        REGISTRAR
                    </button>
                </div>
            </div>
        </form>
        
        <!-- Fin del contaienr -->
    </div>
  
</div>
