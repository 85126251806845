import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';



@Component({
  selector: 'app-formulario-edit-amb',
  templateUrl: './formulario-edit-amb.component.html',
  styleUrls: ['./formulario-edit-amb.component.css']
})
export class FormularioEditAmbComponent implements OnInit {

  public ambulanciaEdit = {
      // _id: "",
      DESTINO: "",
      PRECIO_MEMBRESIA_DIA:"",
      PRECIO_MEMBRESIA_NOCHE: "",
      PRECIO_MEMBRESIA_REDONDO_DIA: "",
      PRECIO_MEMBRESIA_REDONDO_NOCHE: "",
      PRECIO_PUBLICO_DIA: "",
      PRECIO_PUBLICO_NOCHE: "",
      PRECIO_PUBLICO_REDONDO_DIA: "",
      PRECIO_PUBLICO_REDONDO_NOCHE: ""
  };

  public status: string;
  public is_edit: boolean;
  public id: string;
  public page_title: string;
  public url: string;

  constructor(private _router:Router ,private _route:ActivatedRoute, private _service:IntegradosService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
  
    this.getAmbulancia();
  }

  onSubmit() {

    this._service.updateAmbulancia(this.id, this.ambulanciaEdit).subscribe(
      res => {
        /* console.log(  res ); */
        if(res.ok){
          this.status = 'ok';
          this.ambulanciaEdit.DESTINO = res['data'].DESTINO;
          this.ambulanciaEdit.PRECIO_PUBLICO_DIA = res['data'].PRECIO_PUBLICO_DIA;
          this.ambulanciaEdit.PRECIO_PUBLICO_REDONDO_DIA = res['data']['PRECIO_PUBLICO_REDONDO_DIA'];
          this.ambulanciaEdit.PRECIO_PUBLICO_REDONDO_NOCHE = res['data']['PRECIO_PUBLICO_REDONDO_NOCHE'];

          this.ambulanciaEdit.PRECIO_MEMBRESIA_DIA = res['data']['PRECIO_MEMBRESIA_DIA'];
          this.ambulanciaEdit.PRECIO_MEMBRESIA_NOCHE = res['data']['PRECIO_MEMBRESIA_NOCHE'];
          this.ambulanciaEdit.PRECIO_MEMBRESIA_REDONDO_DIA = res['data']['PRECIO_MEMBRESIA_REDONDO_DIA'];
          this.ambulanciaEdit.PRECIO_MEMBRESIA_REDONDO_NOCHE = res['data']['PRECIO_MEMBRESIA_REDONDO_NOCHE'];
          Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL PRECIO SE HA EDITADO CORRECTAMENTE',
          })
          this._router.navigateByUrl('/serviciosInt/ambulancia');
        }else{
          this.status = 'error';
        }
      },
      err => {
        /* console.log(err); */
        this.status = 'error';
        
      }
    )
  }

  
  getAmbulancia(){
    this._route.params.subscribe(params => {
      var id = params['id'];
      this._service.getAmbulanciaById(id).subscribe(
        (res:any) => {
          /* console.log( res['data'] ); */
          if(res['data']){
            this.ambulanciaEdit = res['data'];
          }else{
            // this._router.navigate(['/ambulancia']);
          }
        },
        err => { 
          console.log(err);
          // this._router.navigate(['/ambulancia']);  
        }
      )
    });
  }
}
