<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-lg-6 col-xm-12 col-sm-12" *ngFor="let paquete of paquetes">
            <div class="container-fluid">
                <div class="card mb-3 ml-4 " id="card">
                    <div class="row">
                        <div class="col-md-4">
                            <img [src]="paquete.icon" class="card-img pointer">
                        </div>
                        <div class="col-md-8 mt-3">
                            <div class="card-body">
                                <h4> {{ paquete.nombrePaquete }} </h4>
                                <a class="btn btn-outline btn-block btn-lg principalButton" [routerLink]="['/paquete', paquete._id]"> Ver más... </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
