import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editar-servicio-por-utilidad',
  templateUrl: './editar-servicio-por-utilidad.component.html',
  styleUrls: ['./editar-servicio-por-utilidad.component.css']
})
export class EditarServicioPorUtilidadComponent implements OnInit {

  public servicios;
  
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');  
    /* console.log(this.servicios); */
      
  }

}
