import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bitacora-no-aptos-odiferidos',
  templateUrl: './bitacora-no-aptos-odiferidos.component.html',
  styleUrls: ['./bitacora-no-aptos-odiferidos.component.css']
})
export class BitacoraNoAptosODiferidosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
