import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-hoja-evolucion-disponente',
  templateUrl: './hoja-evolucion-disponente.component.html',
  styleUrls: ['./hoja-evolucion-disponente.component.css']
})
export class HojaEvolucionDisponenteComponent implements OnInit {

  public idCensur;
  public paciente={
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
    religion:''
  }
  public fisica={};
  public diagnostico={};
  public exclucion = '';
  public fecha_final:Date;
  public tiempo_exclusion = '';
  public motivo_exclusion = '';
  public labs = [];

  constructor(private activatedRoute:ActivatedRoute,
              private _banco: BancoService,
              private _router: Router) { }

  ngOnInit(): void {
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDisponente(this.idCensur);
  }

  getDisponente(id){
    this._banco.getDisponente(id).subscribe((resp:any)=>{
      /* this.paciente = resp.data.paciente */
      this.setPaciente(resp.data.paciente);
    })
  }

   //LOADING
   pintLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
    /* console.log("toy funcionando weeeee"); */
    
  }

  removeLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
    /* console.log('dejo de funcionar'); */
    
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

  editLab(event){
    /* console.log(event); */
  }

  exploracionFisica(event){
    this.fisica = event;
  }

  diagnostic(event){
    this.diagnostico=event;
    this.exclucion = event.candidato_donacion
    if(event.tiempo_exclusion != undefined){
      this.tiempo_exclusion = event.tiempo_exclusion
    }
    if(event.fecha_exclusion != undefined){
      this.fecha_final = event.fecha_exclusion
    }
    if(event.motivo_exclusion != undefined){
      this.motivo_exclusion = event.motivo_exclusion
    }
  }

  enviar(){
    this.pintLoadingScreen();
    let process = {
      idbancosangre:this.idCensur,
      proceso:'FLEBOTOMIA'
    }
    let id = {
      idbancosangre:this.idCensur
    }
    let hojaEvo = Object.assign(id, 
                                this.fisica, 
                                this.diagnostico);
    if(this.exclucion == 'SI'){
      this._banco.hojaEvolucion(hojaEvo).subscribe((resp:any)=>{
        if(resp.ok){
          this._banco.cambiarProceso(process).subscribe((resp:any)=>{
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'EL DISPONENTE PASO A FLEBOTOMIA',
            })
            this.removeLoadingScreen();
            this._router.navigate([ `/dashboard`]);
          })
        }
      })
    }else{
      let fecha = new Date(this.fecha_final);
        let dif ={
            idbanco:this.idCensur,
            proceso:'DOCTOR', 
            estatus:'DIFERIDO',
            motivo:this.motivo_exclusion,
            rechazo:this.tiempo_exclusion,
            fechaRechazo:fecha
        }
        this._banco.diferirDisponente(dif).subscribe((resp:any)=>{
          /* console.log(resp); */
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'EL DISPONENTE SE DIFERIO CORRECTAMENTE',
          })
          this.removeLoadingScreen();
          this._router.navigate([ `/dashboard`]);
        })
    }
  }

}
