<form action="" enctype="multipart/form-data">
    <div class="conteiner">
        <section>
            <app-titulos [title]="'ADJUNTAR DOCUMENTOS DEL DONANTE'" [rol]="'flebotomia'"></app-titulos>
            <!-- <app-ficha-info-flebotomia [pacienteFI]="paciente"  (dataDonanteEmit)="respDatapacienteEmmit($event)" ></app-ficha-info-flebotomia> -->
            <app-fichas-identificacion [data]="paciente" [rol]="'flebotomia'" (dataDonanteEmit)="respDatapacienteEmmit($event)"></app-fichas-identificacion>
        </section>

        <div class="row" id="pointer">
            <div class="col">
                <p>
                    <a style="text-decoration: none;" href="../../../../../assets/documentos/censur.pdf" download="DOCUMENTOS DE DONACION">Documentos de donacion.pdf</a>
                </p>
            </div>

            <!--             <button type="button" (click)="etiquetaNoLiberado('jdjdjdjdjdjdjdjdjdjd','sangre total')" class="btn btn-primary">Etiqueta Liberado</button> -->

            <div class="card">
                <ngx-dropzone name="fileUploadTosend" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <!-- <span class="material-icons">
                            add
                        </span> -->
                        INSERTA LOS DOCUMENTOS AQUÍ
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview [file]="f" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
            <div id="pregunta">
                <div class="row mt-3 mr-3">
                    <div class="col-md-2 offset-md-2">
                        <label for="volumen"> Volumen </label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control shadow-input-bs" [(ngModel)]="dataDonante.volumen" name="volumen" id="volumen" />
                    </div>
                    <div class="col-md-2">
                        <label for="volumen"> Tiempo </label>
                    </div>
                    <div class="col-md-3">
                        <input type="number" min="5" max="15" name="tiempo" class="form-control shadow-input-bs" [(ngModel)]="dataDonante.tiempo" id="tiempo" />
                    </div>
                </div>
                <div class="row mt-3 mr-3">
                    <div class="col-md-2 offset-md-2">
                        <label for="volumen"> HORA INICIO </label>
                    </div>
                    <div class="col-md-3">
                        <input type="time" class="form-control shadow-input-bs" name="volumen" id="volumen" [(ngModel)]="horainicio" />
                    </div>
                    <div class="col-md-2">
                        <label for="volumen"> HORA TERMINO </label>
                    </div>
                    <div class="col-md-3">
                        <input type="time" min="5" max="15" name="tiempo" class="form-control shadow-input-bs" id="tiempo" [(ngModel)]="horafinal" />
                    </div>
                </div>

                <div class="row" style="margin-top: 35px;">
                    <div class="col-md-6">
                        <span> He firmado mi consentimiento informado. </span>
                    </div>

                    <div class="col-md-3" style="padding: 0px !important;">
                        <div class="form-group row">
                            <div class="form-group">

                                <div class="form-check form-check-inline">
                                    <input type="radio" value="si" id="acuerdoSi" name="acuerdo" [(ngModel)]="acuerdoValidate" (blur)="validateFieldsflebotomia()">
                                    <label class="form-check-label" for="acuerdoSi" style="margin-left: 5px; margin-top: 1px;"> SI</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input type="radio" value="no" id="acuerdoNo" name="acuerdo" [(ngModel)]="acuerdoValidate" (blur)="validateFieldsflebotomia()">
                                    <label class="form-check-label" for="acuerdoSi" style="margin-left: 5px; margin-top: 1px;"> No </label>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="card" id="card">
                        <textarea name="notas" id="" cols="30" rows="10" [(ngModel)]="dataDonante.notas"></textarea>
                    </div>
                </div>

                <button class="btn btn-primary center" [disabled]="btnSend" (click)="sendDataFlebomotomia()">
                Guardar
            </button>
            </div>

        </div>
    </div>
</form>

<section class="display-none" id="sppiner-section">
    <div class="row">
        <div class="sppiner-bg">
            <div class="spinner-stock">
                <div class="col-md-12">
                    <app-sppiner></app-sppiner>
                </div>
            </div>

        </div>
    </div>
</section>


<!-- Spinner -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>