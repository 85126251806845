<div class="container">

    <app-titulos
        [title]="'BITÁCORA DE GENERAL DE PACIENTES'"
        [rol]="'general'"
    ></app-titulos>

    <div class="row my-3">
            <div class="col-8 my-2">
                <input type="text" class="form-control shadow-input-bs" placeholder="BUSCAR" name="filterPost" #searchPatient (keyup)="typeSearchPatient( searchPatient )" >
            </div>
            <div class="col my-2 offset-md-2">
                <button class="btnPrincipalBS " type="submit">NUEVO</button>
            </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col">
                        No.
                </div>
                <div class="col">
                        FECHA
                </div>
                <div class="col">
                        NOMBRE
                </div>
                <div class="col">
                        CONSULTAS
                </div>
                <div class="col">
                        ESTATUS
                </div>
                <div class="col">
                        SEDE
                </div>
                <div class="col">
                        ESTUDIO
                </div>
            </div>
        </div>
        <div class="card-body">
                <div class="row" *ngFor="let paciente of pacientes; let i = index">
                <div class="col">
                    <p>
                        {{ i + 1 }}
                    </p>
                </div>
                <div class="col">
                    <p>
                        {{ paciente.fechaRegistro   | date }}
                    </p>
                </div>
                <div class="col">
                    <p [routerLink]="['/actualizar/fi/pacientes', paciente._id]" >
                        {{ paciente.nombrePaciente }}
                        {{ paciente.apeldoPaterno }}
                        {{ paciente.apeldoMaterno }}
                    </p>
                </div>
                <div class="col">
                    <p>
                        {{ paciente.consultas }}
                    </p>
                </div>
                <div class="col">
                    <p>
                        {{ paciente.status }}
                    </p>
                </div>
                <div class="col">
                    <p>
                        {{ paciente.sede }}
                    </p>
                </div>

           
            </div>
        </div>
        <div class="offset-md-4">
            <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>
    </div>
</div>