<div class="card">
    <mat-horizontal-stepper linear #stepper>
        <form #form="ngForm">
            <mat-step label="DATOS GENERALES">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">NOMBRE (S)</label>
                            <input type="text" class="form-control" name="nombrePaciente" id="nombrePaciente" required [(ngModel)]="paciente.nombrePaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">APELLIDO PATERNO</label>
                            <input type="text" class="form-control" name="apellidoPaterno" id="validationCustom01" required [(ngModel)]="paciente.apellidoPaterno">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">APELLIDO MATERNO</label>
                            <input type="text" class="form-control" name="apellidoMaterno" id="validationCustom01" [(ngModel)]="paciente.apellidoMaterno">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">FECHA DE NACIMIENTO</label>
                            <input type="date" class="form-control" name="fechaNacimientoPaciente" id="fechaNacimientoPaciente" id="validationCustom01" [(ngModel)]="paciente.fechaNacimientoPaciente" (blur)="edad($event.target.value)">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="nombre">EDAD</label>
                            <div class="input-group">
                               <input type="text" class="form-control" name="edad" id="validationCustom01" [(ngModel)]="paciente.edad">
                               <div class="input-group-prepend">
                                 <span class="input-group-text" id="inputGroupPrepend3">AÑOS</span>
                               </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">GÉNERO</label>
                            <select name="genero" required id="genero" class="form-control custom-select" [(ngModel)]="paciente.genero">
                                <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                <option value="FEMENINO">  FEMENINO </option>
                                <option value="MASCULINO"> MASCULINO </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">LUGAR DE ORIGEN</label>
                            <input type="text" class="form-control" name="lugarOrigen" id="lugarOrigen" [(ngModel)]="paciente.paisNacimiento">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">ESTADO DE ORIGEN</label>
                            <input type="text" class="form-control" name="entidadNacimiento" id="entidadNacimiento" [(ngModel)]="paciente.entidadNacimiento">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">CURP</label>
                            <input type="text" class="form-control" name="curp" id="validationCustom01" [(ngModel)]="paciente.curp">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">TELÉFONO</label>
                            <input type="number" class="form-control" name="telefono" id="validationCustom01" [(ngModel)]="paciente.telefono">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">ESTADO CIVIL</label>
                            <input type="text" class="form-control" name="estadoCivilPaciente" id="estadoCivilPaciente" [(ngModel)]="paciente.estadoCivilPaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">NÚMERO DE EXPEDIENTE</label>
                            <input type="number" class="form-control" id="validationCustom01" name="numeroExpediente" [(ngModel)]="paciente.numeroExpediente">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 p-2">
                            <label for="apellido 2">CORREO</label>
                            <input type="text" class="form-control" id="validationCustom01" name="correoPaciente" [(ngModel)]="paciente.correoPaciente">
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="DIRECCIÓN PERSONAL">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">CALLE</label>
                            <input type="text" class="form-control" id="validationCustom01" name="callePaciente" [(ngModel)]="paciente.callePaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">NÚMERO INTERIOR</label>
                            <input type="text" class="form-control" id="numeroIntPaciente" name="numeroIntPaciente" [(ngModel)]="paciente.numeroIntPaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">NÚMERO EXTERIOR</label>
                            <input type="text" class="form-control" id="numeroPaciente" name="numeroPaciente" [(ngModel)]="paciente.numeroPaciente">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">PAÍS</label>
                            <input type="text" class="form-control" id="paisPaciente" name="paisPaciente" [(ngModel)]="paciente.paisPaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">CÓDIGO POSTAL</label>
                            <input type="text" class="form-control" id="cpPaciente" name="cpPaciente" [(ngModel)]="paciente.cpPaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">ESTADO</label>
                            <input type="text" class="form-control" id="estadoPaciente" name="estadoPaciente" [(ngModel)]="paciente.estadoPaciente">                      
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">MUNICIPIO</label>
                            <input type="text" class="form-control" id="municipioPaciente" name="municipioPaciente" [(ngModel)]="paciente.municipioPaciente">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="nombre">CIUDAD</label>
                            <input type="text" class="form-control" id="colonia" name="colonia" [(ngModel)]="paciente.colonia">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">REFERENCIA</label>
                            <input type="text" class="form-control" id="referenciaPaciente" name="referenciaPaciente" [(ngModel)]="paciente.referenciaPaciente">
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="DATOS FISCALES">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">RAZÓN SOCIAL</label>
                            <input type="text" class="form-control" id="razonSocial1" name="razonSocial1" [(ngModel)]="paciente.razonSocial1">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">RFC</label>
                            <input type="text" class="form-control" id="razonSocial1RFC" name="razonSocial1RFC" [(ngModel)]="paciente.razonSocial1RFC">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">CORREO</label>
                            <input type="text" class="form-control" id="correoRazonSocial1" name="correoRazonSocial1" [(ngModel)]="paciente.correoRazonSocial1">
                        </div>
                    </div>
                    <div class="row">
                        <ng-container>
                            <div class="col-md-4 p-2">
                                <label for="nombre">CALLE</label>
                                <input type="text" class="form-control" id="razonSocial1Calle" name="razonSocial1Calle" [(ngModel)]="paciente.razonSocial1Calle">
                            </div>
                            <div class="col-md-4 p-2">
                                <label for="apellido 1">NÚMERO EXTERIOR</label>
                                <input type="text" class="form-control" id="razonSocial1NumExt" name="razonSocial1NumExt" [(ngModel)]="paciente.razonSocial1NumExt">
                            </div>
                            <div class="col-md-4 p-2">
                                <label for="apellido 2">NÚMERO INTERIOR</label>
                                <input type="text" class="form-control" id="razonSocial1NumInt" name="razonSocial1NumInt" [(ngModel)]="paciente.razonSocial1NumInt">
                            </div>
                        </ng-container>
                    </div>
                    <div class="row">
                        <ng-container>
                            <div class="col-md-4 p-2">
                                <label for="apellido 2">PAÍS</label>
                                <input type="text" class="form-control" id="razonSocialPais" name="razonSocialPais" [(ngModel)]="paciente.razonSocialPais">
                            </div>
                            <div class="col-md-4 p-2">
                                <label for="nombre">CÓDIGO POSTAL</label>
                                <input type="text" class="form-control" id="cpRazonSocial" name="cpRazonSocial" [(ngModel)]="paciente.cpRazonSocial">
                            </div>
                            <div class="col-md-4 p-2">
                                <label for="apellido 1">ESTADO</label>
                                <input type="text" class="form-control" id="razonSocial1Estado" name="razonSocial1Estado" [(ngModel)]="paciente.razonSocial1Estado">
                            </div>
                        </ng-container>
                    </div>
                    <div class="row">
                        <ng-container>
                            <div class="col-md-4 p-2">
                                <label for="apellido 2">MUNICIPIO</label>
                                <input type="text" class="form-control" id="razonSocial1Municipio" name="razonSocial1Municipio" [(ngModel)]="paciente.razonSocial1Municipio">
                            </div>
                            <div class="col-md-4 p-2">
                                <label for="nombre">CIUDAD</label>
                                <input type="text" class="form-control" id="razonSocial1colonia" name="razonSocial1colonia" [(ngModel)]="paciente.razonSocial1colonia">
                            </div>
                        </ng-container>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">TELÉFONO</label>
                            <input type="text" class="form-control" id="razonsocial1Telefono" name="razonsocial1Telefono" [(ngModel)]="paciente.razonsocial1Telefono">
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="CONTACTO EMERGENCIA">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">NOMBRE (S)</label>
                            <input type="text" class="form-control" id="validationCustom01" name="contactoEmergencia1Nombre" [(ngModel)]="paciente.contactoEmergencia1Nombre">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">APELLIDO PATERNO</label>
                            <input type="text" class="form-control" id="validationCustom01" name="contactoEmergencia1ApellidoPaterno" [(ngModel)]="paciente.contactoEmergencia1ApellidoPaterno">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">APELLIDO MATERNO</label>
                            <input type="text" class="form-control" id="validationCustom01" name="contactoEmergencia1ApellidoMaterno" [(ngModel)]="paciente.contactoEmergencia1ApellidoMaterno">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="nombre">EDAD</label>
                            <input type="text" class="form-control" id="validationCustom01" name="contactoEmergencia1Edad" [(ngModel)]="paciente.contactoEmergencia1Edad">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">TELÉFONO</label>
                            <input type="text" class="form-control" id="validationCustom01" name="contactoEmergencia1Telefono" [(ngModel)]="paciente.contactoEmergencia1Telefono">
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 2">PARENTESCO</label>
                            <select name="contactoEmergencia1Parentesco" class="form-control custom-select" id="contactoEmergencia1Parentesco" [(ngModel)]="paciente.contactoEmergencia1Parentesco">
                                <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                <option  value="PADRE">  PADRES   </option>
                                <option value="ABUELOS">   ABUELOS </option>
                                <option value="HIJOS"> HIJOS </option>
                                <option value="HERMANOS">  HERMANOS </option>
                                <option value="PRIMOS">   PRIMOS </option>
                                <option value="CONYUGUE">  CÓNYUGUE </option>
                                <option value="OTROS">  OTROS </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">GÉNERO</label>
                            <select name="contactoEmergencia1Genero" required  id="contactoEmergencia1Genero" class="form-control custom-select" [(ngModel)]="paciente.contactoEmergencia1Genero">
                                <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                <option value="FEMENINO">  FEMENINO </option>
                                <option value="MASCULINO"> MASCULINO </option>
                            </select>
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">ESTADO CIVIL</label>
                            <input type="text" class="form-control" id="validationCustom01" name="contactoEmergencia1EstadoCivil" [(ngModel)]="paciente.contactoEmergencia1EstadoCivil">
                            <!-- <select name="contactoEmergencia1EstadoCivil" required id="contactoEmergencia1EstadoCivil" class="form-control custom-select" [(ngModel)]="paciente.contactoEmergencia1EstadoCivil">
                                <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                <input type="text" class="form-control" id="validationCustom01">
                            </select> -->
                        </div>
                        <div class="col-md-4 p-2">
                            <label for="apellido 1">CORREO</label>
                            <input type="text" class="form-control" id="contactoEmergencia1Correo" name="contactoEmergencia1Correo" [(ngModel)]="paciente.contactoEmergencia1Correo">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col p-2">
                            <div class="center">
                                <button mat-button matStepperNext class="principalButton" (click)="actualizarPaciente(form)"> Actualizar </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
        </form>
    </mat-horizontal-stepper>
 </div>
