<div class="row">

    <h1 style="margin-top: 5px;" class="centerxd" id="centerxd">LISTADO DE SOCIOS DE CANAL</h1>

    <div class="col-md-8">
        <div class="form-group">
            <input style="margin-top: 1%;" type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador">
        </div>
    </div>
</div>

<br>

<div style="margin-left: 10px;" class="row">
    <div class="col-md-3" *ngFor="let item of sede">
        <div style="border-radius: 1.5rem;" class="card">

            <div class="">
                <div [routerLink]="[ '/dash', item._id] ">

                    <p class="mt-3 font-bold center" style="color: #001672; ">{{item.encargado | uppercase}}</p>

                </div>
            </div>

        </div>

    </div>
</div>










<!-- <div class="center ">
    <section>
        <div class="card " style="border-radius: 20px; ">
            <div class="card-header header ">
                <div class="row ">
                    <div class="col-md-10 text_header ">
                        <p>Sedes</p>
                    </div>
                </div>
            </div>


            <br>
        </div>
    </section>
</div> -->