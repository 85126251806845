<div class="container-fluid">
  <app-titulos
    [title]="'BITÁCORA DE RESULTADOS DE ENDOSCOPIAS'"
    [rol]="'endoscopias'"
  ></app-titulos>

  <div class="row">
    <div class="col-md-9">
      <div class="form-group">
        <input
          type="text"
          name="filtroendoscopias"
          placeholder="Buscar"
          [(ngModel)]="filtroendoscopias"
          class="form-control"
          id="filtroendoscopias"
        />
      </div>
    </div>
    <div class="col-md-2">
      <a type="button" class="btn btn-secondary boton_contra2" 
        >NUEVO</a
      >
    </div>
  </div>

  <div class="row card">

    <div class="col-md-12 col-lg-12">
        <div class="card-heder header">
          <div class="row">
            
            <div class="col text_header">
              <p>#</p>
            
            </div>

            <div class="col text_header">
              <p>FECHA</p>
            </div>
            <div class="col text_header">
              <p>NOMBRE</p>
            </div>
            <div class="col text_header">
              <p>EDAD</p>
            </div>
            <div class="col text_header">
              <p>SEXO</p>
            </div>
            <div class="col text_header">
              <p>SEDE</p>
            </div>
            <div class="col text_header">
              <p>ESTUDIO</p>
            </div>
          </div>
  

        <!-- <div class="offset-md-4">
        <pagination-controls class="my-pagination"></pagination-controls> 
        </div>
         -->
         
      </div>
    </div>
  </div>

  <!-- <spinner-perrucho></spinner-perrucho> -->
</div>
