<section class="row">
    <div class="col-md-9">
        <h1 class="title" id="centerxd">SOCIO DE CANAL</h1>
    </div>
    <div class="col-md-3">
        <p class="fecha center" id="fecha">{{ fecha | date: 'shortDate'}}</p>

    </div>
</section>

<section class="">

    <div class="card" name="card" id="card">
        <br>
        <div class="row">
            <div class="col margin-info">
                <p> <span>  Nombre: Socio {{sede.localizacion}}</span> </p>
            </div>
            <div class="col">
                <p> <span> Dirección:</span> </p>
            </div>
            <div class="col">
                <p> <span> Responsable: {{sede.encargado}}</span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p>Rango:</p>
            </div>
            <div class="col">
                <p>Teléfono: </p>
            </div>
        </div>
    </div>
</section>