import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import  Swal from 'sweetalert2'
@Component({
  selector: 'app-editar-productos-almacen',
  templateUrl: './editar-productos-almacen.component.html',
  styleUrls: ['./editar-productos-almacen.component.css']
})
export class EditarProductosAlmacenComponent implements OnInit {

  public btnValidacion=false;
  public idProd ="";
  public proveedoresCensur = [];
  public newFormProductos: any;
  public medicamento = false;

  constructor(private formBuilder: FormBuilder,
              private _almacenService: AlmacenService,
              private activatedRoute: ActivatedRoute, 
              private _router: Router,) { }

  ngOnInit(){
    this.idProd = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerProveedoresCensur();
    this.ProductoPorId();
    this.creacionDeFormulario();
  }

  creacionDeFormulario() {
    // creacion del formualrio dinamico
    this.newFormProductos = this.formBuilder.group({
      nombre: ['', { validators: [Validators.required] }],
      tipo_producto: [''],
      nombre_comercial: ['', { validators: [Validators.required] }],
      idProducto: [ '', { disabled: true }],
      descripcion: [''],
      proveedor: this.formBuilder.array([]),
      laboratorio: [''],
      cantidadMin: ['', { validators: [Validators.required] }],
      medicamento: [''],
      presentacion:[''],
      contenidoFrasco:[''],
      viaAdministracion:[''],
    });
    // hacemos el push de los forms reactivos
  }

  agregarProveedor(data) {
    // metodo para crear el una nueva fila de controles
    const valoresProveedor = this.formBuilder.group({
      proveedor: data.proveedor,
      costo: data.costo
    });
    
    this.valoresProveedor.push( valoresProveedor );
    // console.log("Agregar ejecutado")
  }

  get valoresProveedor () {
    // obtenemos los controles que estan dentro del form Array
    return this.newFormProductos.get('proveedor') as FormArray;
  }

  obtenerProveedoresCensur() {
    this._almacenService.obetenerProveedores()
      .subscribe( (data:any) => this.setProveedores(data.data));
  }

  setProveedores(data) {
    this.proveedoresCensur = data;
  }

  ProductoPorId(){
   this._almacenService.getproductosAlmacenId( this.idProd)
   .subscribe((data:any) =>{ 
    //  console.log(data);
     if (data.ok){
       this.setProductoid(data.data);
     }
   })
  }
  
  setProductoid(data:any){
    data.proveedor.forEach(element => {
      this.agregarProveedor(element);
    });
    this.newFormProductos.patchValue({
      idProducto: data.idProducto,
      nombre: data.nombre,
      tipo_producto: data.tipo_producto,
      nombre_comercial: data.nombre_comercial,
      descripcion: data.descripcion,
      laboratorio: data.laboratorio,
    });
    this.medicamento = data.medicamento;
    if(data.medicamento){
      this.newFormProductos.patchValue({
        cantidadMin: data.cantidadMin,
        medicamento: data.medicamento,
        presentacion:data.presentacion,
        contenidoFrasco:data.contenidoFrasco,
        viaAdministracion:data.viaAdministracion,
      });
    }
    this.newFormProductos.get('idProducto').disable();
  }

  removeValores( i: number ){
    if( this.valoresProveedor.length  > 1 ) {
      this.valoresProveedor['controls'].forEach((element, index) => {
        if (index == i) {
          this.valoresProveedor['controls'].splice(index, 1)
          this.valoresProveedor.value.splice(index, 1)
        }
      });
    }
  }

  agregarNuevoProveedor(event){
    this.proveedoresCensur.push(event);
  }
 
  actualizarProducto(){
    this.btnValidacion = true;
    this._almacenService.CambiarProducto( this.idProd, this.newFormProductos.value)
    .subscribe( (res:any) => { 
      if(res.ok) {
        Swal.fire('Datos actualizados', '', 'success');
        setTimeout(() => {
          this._router.navigateByUrl('/lista/productos/almacen');
        }, 2000);
      }else {
        Swal.fire('Rvisa el internet', 'Intenta de nuevo', 'error');
      }
    })
  }
}
