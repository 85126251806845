<form [formGroup]="newFormValor">
    <div class="container-fluid">
        <h1 class="centerxd">NUEVO ESTUDIO DE LABORATORIO</h1>


        <div class="card" name="card" id="card">

            <div class="row" id="principal">
                <div class="col margin-info">
                    <p> <span>  NOMBRE DEL ESTUDIO</span> </p>
                    <p> <span>{{elementos}}</span> </p>
                </div>
                <div class="col">
                    <p> <span> TIPO DE EXAMEN</span> </p>
                    <input type="text" formControlName="tipoExamen" />
                </div>
            </div>

            <div formArrayName="valores" *ngFor="let valor of valoresDeReferencias.controls; let j = index">

                <div [formGroupName]="j" class="row">

                    <div class="col">
                        <p> <span>GRUPO </span> </p>
                        <input type="text" formControlName="grupo" />
                    </div>

                    <div class="col" style="margin-left: 20px;">
                        <p> <span>ELEMENTO</span> </p>
                        <input type="text" aria-describedby="basic-addon1" formControlName="elemento" />
                    </div>

                    <div class="col" style="margin-left: 20px;">
                        <p> <span>UNIDADES</span> </p>
                        <input type="text" aria-describedby="basic-addon1" formControlName="unidad" />
                    </div>
                    <div class="col-md-3">
                        <p><span>VALOR DE REFERENCIA</span></p>
                        <div formArrayName="valorDeReferencia" *ngFor="let valor of valor['controls']['valorDeReferencia']['controls']; let i = index">
                            <div [formGroupName]="i" class="col">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" formControlName="valorReferenciaD" />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1" (click)="agregarValoresReferenciaD(j)"> + </span>
                                    </div>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1" (click)="quitarValoresReferenciaD(j,i)"> - </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col buttons-content">
                        <button type="button" class="btn btn-success" (click)="addValoreDeReferencia()"> Agregar </button>
                        <button type="button" class="btn btn-danger btn-left" (click)="removeValores( j )"> Quitar </button>
                    </div>

                </div>
            </div>
        </div>

        <div class="card" name="card" id="card">
            <div class="col">
                <p><span>METODO</span> </p>
                <textarea name="" id="" cols="5" rows="10" formControlName="metodo"></textarea>
            </div>
            <div class="col">
                <p class="cent"><span>OBSERVACIONES</span></p>
                <textarea name="" id="" cols="10" rows="10" formControlName="observaciones"></textarea>
            </div>

        </div>

        <div class="boton">
            <button class="principalButton" [disabled]="btnAgregar" (click)="sendDataPost()">AGREGAR</button>
        </div>
    </div>

</form>

















<!-- <form autocomplete="off" [formGroup]="forma">


    <div class="card">

        <div class="form-group row">
            <label class="col col-form-label">NOMBRE DEL ESTUDIO:    {{elementos.ESTUDIO}}</label>
        </div>

    </div>
    <br>
    <br>

    <div class="card">
        <div class="form-group row">
            <label class="col-md-3 col-form-label">ELEMENTOS</label>
            <label class="col-md-3 col-form-label">VALORES DE REFERENCIA</label>
            <label class="col-md-3 col-form-label">TIPO DE EXAMEN (GRUPOS)</label>
        </div>


        <div formArrayName="grupos" *ngFor="let entrada of grupos.controls ; let i =index">
            <div [formGroupName]="i" class="form-group row">

                <div class="col-3">
                    <input class="form-control" formControlName="elementos" type="text" name="nombre">
                </div>

                <div class="col-3" formArrayName="referencia" *ngFor="let item of entrada.controls['referencia'].controls; let j=index">
                    <div [formGroupName]="j" class="form-group row input-group m-3">
                        <input class="form-control" formControlName="referencia" type="text" [name]="j" aria-describedby="basic-addon2">
                        <span (click)="agregarRefer(i)" class="input-group-text" id="basic-addon2">+</span>
                        <button (click)="agregarRefer(i)">agregar</button>
                    </div>

                </div>

                <div class="col-3">
                    <input class="form-control" formControlName="tipo" type="text" name="tipoeamen">
                </div>




                <div class="col">
                    <button type="button" class="btn btn-success" (click)="agregarCampo()">+</button>
                </div>

                <div class="col">
                    <button type="button" class="btn btn-danger" (click)="borrarCampo(i)">-</button>

                </div>


            </div>

        </div>

        <div class="form-group row">
            <label class="col-5 col-form-label">&nbsp;</label>
            <div class="input-group col-md-2">
                <button (click)="enviarSave()" class="btn btn-outline-primary btn-block">
                    ENVIAR
                  </button>
            </div>
        </div>


    </div>




</form> -->