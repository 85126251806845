<div class="container-fluid">
  <app-titulos [title]="'EDITAR SERVICIO'"></app-titulos>
  <div class="card">
    <form>
      <div class="row">
        <div class="col">
            <div class="container-fluid">
                <div class="title-card">
                    <div class="mt-3 font-color">DATOS DEL SERVICIO</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="nombre">SERVICIO</label>
                        <select class="form-control custom-select">
                            <option value="" selected> SELECCIONA UNA SERVICIO </option>
                            <option value="ambulancia"> AMBULANCIA</option>
                            <option value="consulta"> CONSULTA </option>
                            <option value="endoscopia"> ENDOSCOPIA</option>
                            <option value="laboratorio"> LABORATORIO</option>
                            <option value="xray">RAYOS-X</option>
                            <option value="resonancia"> RESONANCIA</option>
                            <option value="tomografias"> TOMOGRAFIA</option>
                            <option value="ultrasonido"> ULTRASONIDO</option>
                            <option value="otros"> SERVICIOS EN GENERAL </option> 
                            <option value="patologia"> PATOLOGIA </option> 
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label for="id">ID</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="nombre">SERVICIOS</label>
                        
                    </div>
                    <div class="col-md-6">
                        <label for="nombre">PRECIO DE VENTA </label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">$</span>
                            </div>
                            <input type="text" class="form-control">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3" style="border-bottom-right-radius: 10px;
                                    border-top-right-radius: 10px;">MX</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="nombre">PROFESIONAL MEDICO RESPONSABLE  </label>
                        
                    </div>
                    <div class="col-md-6">
                        <label for="nombre">SEDE </label>
                        
                    </div>
                </div>
                
                <div class="row mt-3">
                    <div class="col-md-12">
                        <label for="nombre">DESCRIPCIÓN </label>
                        <textarea type="text" class="form-control"></textarea>
                        <div class="invalid-feedback" >
                            EL nombre es requerido.
                        </div>
                    </div> 
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <label for="nombre">PARTICIPANTES </label>
                        <textarea type="text" class="form-control"></textarea>
                        <div class="invalid-feedback" >
                            EL nombre es requerido.
                        </div>
                    </div> 
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 mb-3">
                        <label for="nombre">PRODUCTOS, MATERIALES, MEDICAMENTOS Y OTROS SERVICIOS </label>
                        <input type="text" class="form-control" placeholder="BUSCADOR DE PRODUCTOS Y MATERIALES" data-bs-toggle="dropdown" >
                        <div class="invalid-feedback" >
                            EL nombre es requerido.
                        </div>
                        <div class="card-select mt-4" style="overflow-y: scroll;height: 87px;">
                            <!-- <div class="row" *ngFor="let producto of productsAndServiceDB" >
                                <div class="col-md-12">
                                    <p class="text-product text-center pointer"  (click)="addANewMaterial(producto)" > {{producto.nombre}} - {{producto.nombre_comercial}}  </p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="row mt-3">
                    <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-2">NO</div>
                            <div class="col-8">ARTICULOS</div>
                        </div>
                        <div class="row mt-3" *ngFor="let item of productsAndServiceJson; index as i">
                            <div class="col-2 text-center">
                                {{i}}
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control" name="nombrePaciente" id="validationCustom01" (blur)="addACantidad($event.target.value, i, item._id)">
                            </div>
                            <div class="col-8">
                                {{item?.nombre}}
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-3 offset-4">
                        <button type="submit" class="btn btnPrincipalBS">   
                            Registar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </form>
  </div>
</div>






<!-- <div class="container-fluid">
  <p class="estilos">EDITAR EL SERVICIO</p>
  <div class="card cardPer container">
    <h2 class="title-secondary">DATOS DEL SERVICIO</h2>
    <form>
      <div class="row mt-2">
        <div class="col-md-2">
            <label class="mayus" for="nombre"> NOMBRE:</label>
        </div>
        <div class="col-md-4">
            <input type="text" id="nombre" name="nombre"  placeholder="CENTRIFUGADO"  class="form-control shadow-input-bs" formControlName="nombre" />
        </div>


        <div class="col-md-2">
            <label class="mayus" for="idProducto"> ID: </label>
        </div>
        <div class="col-md-4">
            <input type="text"  disabled  name="idProducto" placeholder="2EK3j34R14U34U" id="idProducto" class="form-control shadow-input-bs" formControlName="idProducto" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2">
            <label class="mayus">TIPO DE SERVICIO</label>
        </div>

        <div class="col-md-4">
            <select class="form-control shadow-input-bs" required>
                    <option>CONCENTRADO ELITROCITARIO</option>
                    <option>Hemoderivados</option>
                    <option>Servicios</option>
                    <option>Materiales</option>
            </select>            
        </div>
        <div class="col-md-2">
          <label class="mayus">COSTO</label>
        </div>
        <div class="col-md-4">
          <input type="number" class="form-control shadow-input-bs"  formControlName="costo" name="" />
          <div class="input-group-append">
              <span class="input-group-text">MX</span>
         </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2">
           <label class="mayus">CATEGORIA</label>
        </div>
        <div class="col-md-4">
          <input type="text" id="nombre" name="nombre"  placeholder="CONCENTRADO ELITROCITARIO CENTRIFUGADO"  class="form-control shadow-input-bs" formControlName="nombre" />
        </div>
        <div class="col-md-2">
           <label class="mayus">PROFECIONAL A REALIZAR</label>
        </div>
        <div class="col-md-4">
           <input type="text" id="nombre" name="nombre"  placeholder="CONCENTRADO ELITROCITARIO CENTRIFUGADO"  class="form-control shadow-input-bs" formControlName="nombre" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2">
          <label class="mayus">FABRICANTE/LAB.</label>
       </div>
       <div class="col-md-4">
         <input type="text" id="nombre" name="nombre"  placeholder="GENOMMA LAB."  class="form-control shadow-input-bs" formControlName="nombre" />
       </div>
      </div>
      <div class="row mt-12">
            <div class="col-md-2">
             <label class="mayus">HORARIOS</label>
            </div>
           <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
              <label class="form-check-label" for="inlineCheckbox1">TODOS LOS DIAS</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
              <label class="form-check-label" for="inlineCheckbox1">LUNES</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
              <label class="form-check-label" for="inlineCheckbox1">MARTES</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
              <label class="form-check-label" for="inlineCheckbox1">MIERCOLES</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
              <label class="form-check-label" for="inlineCheckbox1">JUEVES</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">VIERNES</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">SABADO</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">DOMINGO</label>
              </div>
            </div>
      </div>
      <h2 class="title-secondary">DESCRIPCION DEL SERVICIO</h2>
      <div class="row mt-4">
        <div class="col-md-2">
          <label class="mayus">DESCRIPCION</label>
         </div>
         <div class="col-md-12">                   
          <textarea class="mb-3" name="descripcion" id="" cols="2" rows="3" formControlName="descripcion"></textarea>
         </div>
      </div>
     
      <div class="row mt-4">
        <div class="col-md-2">
          <label class="mayus">PARTICIPANTES</label>
         </div>
         <div class="col-md-12">                   
          <textarea class="mb-3" name="descripcion" id="" cols="2" rows="3" formControlName="descripcion"></textarea>
         </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <label class="mayus">PRODUCTOS O MATERIALES</label>
         </div>
      </div>
      <div class="col-md-6">
        <input type="text" id="nombre" name="nombre"  placeholder="BUSCAR"  class="form-control shadow-input-bs" formControlName="nombre" />
     </div>
     <br>
     <div class="col-md-12">                   
      <textarea class="mb-3" name="descripcion" id="" cols="2" rows="3" formControlName="descripcion"></textarea>
     </div>
    
     <div class="row">
      <div class="col-md-2 offset-md-5">
        <button  class="btn btnPrincipalBS"  >
          ACTUALIZAR
      </button>

      </div>
     </div>
      



    </form>
  </div>
</div> -->