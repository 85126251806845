import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntegradosService, Scv } from '../../../../../services/servicios/integrados.service';
import { Router,ActivatedRoute } from '@angular/router';
import { SedesService } from '../../../../../services/sedes/sedes.service';


@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit {

  public serv;
  public pagina : number = 0;
  public totalservicios:number;
  public sedeInfo = {
    encargado: "",
    localizacion: "",
    nomenclatura: "",
    rfc: "",
    _id: ""
  };
  
  servicio:Scv[]=[];

  constructor( private _serviciosService: IntegradosService, private activatedRoute: ActivatedRoute, private _sede: SedesService) { }

  ngOnInit(): void {
    this.serv = this.activatedRoute.snapshot.paramMap.get('id');
    this.servicio= this._serviciosService.getServicios();
    /* console.log(this.servicio); */
    this.totalservicios = this.servicio.length;
    this.pagina = 0;
    this.guardarId();
    this.obtenerInfSede();
  }

  guardarId(){
    localStorage.setItem('sede', this.serv)
  }

  obtenerInfSede(){
    this._sede.getSede(this.serv).subscribe((resp:any) => {
      if(resp.ok){
        this.sede(resp['data']);
      }
    });
  }

  sede(resp){
    this.sedeInfo=resp;
    /* console.log(this.sedeInfo); */
  }

}
