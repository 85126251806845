import { Component, Input, OnInit } from '@angular/core';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { Antecedentes, 
         AgregarGinecoObstetricosPorVisita, 
         MedicinaPreventivas, 
         EsquemaVacun , 
         nutricion, 
         antecedentesHF,
         antecedentesPNP,
         antecedentesPP,
         antecedentesGO} from '../../../interfaces/historia-clinica';

@Component({
  selector: 'app-historia-clinica-horizonte',
  templateUrl: './historia-clinica-horizonte.component.html',
  styleUrls: ['./historia-clinica-horizonte.component.css']
})
export class HistoriaClinicaHorizonteComponent implements OnInit {

  @Input() paciente={
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:0,
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    religion:'',
    tipoDeSangre:''
  };
  public pageTitle = "hojaEvo";
  public antecedentes:Antecedentes = {} as Antecedentes;
  public historialSginos :any[];
  public agregarGinecoObstetricosPorVisita: AgregarGinecoObstetricosPorVisita = {} as AgregarGinecoObstetricosPorVisita;
  public medicinaPreventiva: MedicinaPreventivas = {} as MedicinaPreventivas;
  public esquemaVacuncaion: EsquemaVacun = {} as EsquemaVacun;
  public nutricionNinos:nutricion = {} as nutricion;
  public antecedentesHeredoFamiliares:antecedentesHF = {} as antecedentesHF;
  public antecedentesPersonalesNoPatologicos:antecedentesPNP = {} as antecedentesPNP;
  public antecedentesPersonalesPatologicos:antecedentesPP = {} as antecedentesPP;
  public antecedentesGinecoObstetricosHistoria:antecedentesGO = {} as antecedentesGO;

  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerAntecedentes(this.paciente._id);
    }, 1000);
  }

  obtenerAntecedentes(id){
    this.antecedentes.idPaciente= id;
    this._HistoriaClinicaService.obtenerAntecedentes( this.antecedentes.idPaciente).subscribe((resp:any) => {
        if(resp['data'].length == 0) {
          return;
        }
        if(resp['data'].length >= 1 ){
          this.antecedentes = resp['data'][0];
        }
    })
  }

  verSignosVitalesAnteriores(){
    this._HistoriaClinicaService.obtenerHistroialSignosVitalesPaciente(this.paciente._id)
    .subscribe( (resp:any) =>   {
      this.historialSginos = resp['data'];
    });
  }

  obtenerGinecoPorVisitas() {
    this._HistoriaClinicaService.obtenerGinecoPorVisita(this.paciente._id)
    .subscribe((resp:any) => {
      if ( resp['data'].length == 0 ) {
        return;
      }else if( resp['data'].length >= 1) {
        this.agregarGinecoObstetricosPorVisita = resp['data'][0];
      }
    })
  }

  obtenerMedicinaP(){
    this._HistoriaClinicaService.obtenerMedicinaPreventiva(this.paciente._id)
    .subscribe((resp:any) => {
      if (resp['data'].length == 0) {
        return;
      }else if (resp['data'].length >= 1) {
        this.medicinaPreventiva = resp['data'][0];
      }
    })
  }

  obtenerVacunacionNinos() {
    this.esquemaVacuncaion.idPaciente = this.paciente._id;
    this._HistoriaClinicaService.obtenerEV(this.esquemaVacuncaion.idPaciente)
    .subscribe((resp:any) => {
      if(resp['data'].length == 0) {
        return;
      }
      if(resp['data'].length >= 1 ){
        this.esquemaVacuncaion = resp['data'][0];
      }
    })
  }

  obtenerNutricion() {
    this.nutricionNinos.idPaciente=this.paciente._id;
    this._HistoriaClinicaService.obtenerNutricion(this.nutricionNinos.idPaciente)
    .subscribe((resp:any) => {
      if(resp['data'].length == 0) {
        this.nutricionNinos = resp['data'][0];  
        return;
      }else if (resp['data'].length >= 1) {
        this.nutricionNinos = resp['data'][0]
      }       
    })
  }

  obtenerHeredoFamiliaresXPaciente(){
    this._HistoriaClinicaService.obtenerHeredoFamiliares(this.paciente._id)
    .subscribe((resp:any) => {
      if (resp['data'].length == 0) {
        return;
      }else if(resp['data'].length >= 1){
        this.antecedentesHeredoFamiliares = resp['data'][0];
      }
    })
  }

  verAntecedentesNoPatologicos(){
    this._HistoriaClinicaService.verAntecendetesNoPatoloogicos(this.paciente._id)
    .subscribe((resp) => {
      if(resp['data'].length == 0 ) {
        return;
      } else if(resp['data'].length >= 1) {
        this.antecedentesPersonalesNoPatologicos = resp['data'][0]
      }
    });
  }

  verAntecedentesPersonalesPatologicos(){
    this.antecedentesPersonalesPatologicos.idPaciente = this.paciente._id;
    this._HistoriaClinicaService.obtenerAntecedentePersonalePatologicos(this.paciente._id)
    .subscribe((resp) => { 
      if(resp['data'].length == 0) {
        return;
      }else if(resp['data'].length >= 1){
       this.antecedentesPersonalesPatologicos = resp['data'][0];
      }
    });
  } 

  obtenerGinecoHistoria(){
    this._HistoriaClinicaService.obtenerGinecoHistoria(this.paciente._id)
    .subscribe((resp:any) => {
      if(resp['data'].length == 0){
        return
      }else if (resp['data'].length >= 1 ){
        this.antecedentesGinecoObstetricosHistoria = resp['data'][0];
      }
    })
  }
}
