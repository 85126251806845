import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agenda-horizonte',
  templateUrl: './agenda-horizonte.component.html',
  styleUrls: ['./agenda-horizonte.component.css']
})
export class AgendaHorizonteComponent implements OnInit {

  public role = '';

  constructor() { }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('usuario')).role
  }

}
