import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class PagoServiciosService {

  public url = URL;

  constructor( private _http: HttpClient  ) { }

  public agregarPedido(venta){
    
    const uri  =  `${this.url}/venta/servicios`;
    return this._http.post( uri, venta );

  }

  //  sin registro del paciente 
  public guardarVentaSinRegistro( venta ){
    const url = `${this.url}/venta/sin/registro`;
    return this._http.post( url, venta );
  }

  pedidosLaboratorio( pedido  ){
    const url = `${this.url}/crear/pedido`;
    return this._http.post( url, pedido  );
  }

  postPedidosUltra(pedido){
    const url = `${this.url}/nuevo/pedido/ultrasonido`;
    return this._http.post( url, pedido );
  }

  postPedidosXray(pedido){
    const url = `${this.url}/nuevo/pedido/rayosX`;
    return this._http.post( url, pedido );
  }

}
