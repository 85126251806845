<div class="container-fluid">
    <h1 class="title-principal-bs">DETALLE DE PROVEEDOR</h1>

    <div class="card m-3">
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">NOMBRE: </label>
                            <div class="col-sm-8">
                                <p> PHARMACUR </p>
                            </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">REPRESENTANTE: </label>
                            <div class="col-sm-8">
                                <p> RAUL TORRES </p>
                            </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">CONTACTO: </label>
                            <div class="col-sm-8">
                                <p> 5515975345 </p>
                            </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">DESCRIPCIÓN: </label>
                            <div class="col-sm-8">
                                <p>ESTE PROOVEEDOR VIENE CADA 15 DIAS</p>
                            </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">RAZON SOCIAL: </label>
                            <div class="col-sm-8">
                                <p> PHARMACUR S.A DE C.V </p>
                            </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">DOMICILIO: </label>
                        <div class="col-sm-8">
                            <p> AV. TOLEDO #233, CUAUTLA MORELOS </p>
                        </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">CORREO: </label>
                        <div class="col-sm-8">
                            <p> pharmacur@gmail.com</p>
                        </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-4 col-form-label">DIAS DE DISTRIBUCION: </label>
                        <div class="col-sm-8">
                            <p>15 dias</p>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <h1 class="title-principal-bs">DOCUMENTO DEL PROVEEDOR</h1>

    <div class="card m-3">
        <div>
            <p>Aqui va la seccion de los documentos</p>
        </div>
    </div>
</div>
