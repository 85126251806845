<section class="container-fluid">
    <!-- <h1 class="centerxd">
        SOLICITUD DE SERVICIOS DE CONSULTA EXTERNA
    </h1> -->
    <app-titulos [title]="'SOLICITUD DE SERVICIOS DE CONSULTA EXTERNA'" [rol]="'doctor'"></app-titulos>
</section>

<ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

<div class="row">
    <button class="btn btn-secondary boton_contra2" (click)="imprimirFrente()">IMPRIMIR RECETA MEDICA</button>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header header">
                    <div class="row">

                        <div class="col-md-4 text_header">
                            <p>ESTUDIO</p>
                        </div>

                        <div *ngIf="!paciente.membresiaActiva" class="col-md-4 text_header">
                            <p>PRECIO PÚBLICO</p>
                        </div>

                        <div *ngIf="paciente.membresiaActiva" class="col-md-4 text_header">
                            <p>PRECIO MEMBRESÍA</p>
                        </div>
                        <div class="col-md-2 text_header"></div>
                    </div>

                </div>
                <div class="card-body">

                    <div class="card-body" style="background-color: #ffffff;">
                        <div *ngFor="let item of estudios">
                            <div class="row">
                                <div class="col">
                                    <label style="margin-right: 50px; margin-top: 18px;">
                                        {{ item.ESTUDIO}}
                                    </label>
                                </div>

                                <div class="col">
                                    <button *ngIf="!paciente.membresiaActiva"
                                        (click)="alertcomparasion( $event, item.PRECIO_PUBLICO, item.PRECIO_MEMBRESIA, item )"
                                        class="cursor  btn btn-light precioPublicoOtros" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                                     background-color: white;
                                                     border: none;">
                                        {{ item.PRECIO_PUBLICO |currency }}


                                    </button>
                                    <button *ngIf="paciente.membresiaActiva"
                                        (click)="alertcomparasion( $event, item.PRECIO_PUBLICO, item.PRECIO_MEMBRESIA, item )"
                                        class="cursor  btn btn-light precioPublicoOtros" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                                     background-color: white;
                                                     border: none;">
                                        {{ item.PRECIO_MEMBRESIA |currency }}

                                    </button>
                                </div>
                                <div class="col">

                                    <div class="form-check" style="margin-right: 50px; margin-top: 18px; left: 117px;">
                                        <input class="form-check-input" (click)="SelectAgenda(item)" type="checkbox"
                                            value="false" id="{{item._id}}">
                                        <label class="form-check-label" for="{{item._id}}">
                                            Agendar
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">

            <div class="card" style="margin: 10px; height: 33em; border-radius: 24px;">
                <div class="card-title" style="border-bottom-style: solid;">
                    <h4 style="text-align: center;">COTIZACIÓN</h4>
                </div>

                <div class="card-body" style="overflow: auto;">
                    <div class="row" *ngFor="let pedido of carrito.items; let i = index">
                        <div class="col-md-8">
                            <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;"
                                *ngIf="!paciente.membresiaActiva">
                                {{ pedido.nombreEstudio }} : {{ pedido.precioSin }} {{ pedido.precioNoche }}
                            </p>
                            <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;"
                                *ngIf="paciente.membresiaActiva">
                                {{ pedido.nombreEstudio }} : {{ pedido.precioCon }}
                            </p>
                        </div>

                        <div class="col-md-2">
                            <button type="button" (click)="eliminar( i )" class="btn btn-link" style="color: red;">
                                <!-- <i class="fas fa-trash-alt"></i> -->
                                Eliminar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="card-footer" style="border-top-style: solid;">
                    <div class="row" *ngIf="!paciente.membresiaActiva">
                        <div class="col-md-10">
                            <h5 style="font-size: 15px; font-weight: 800;">Total: &#36; {{carrito.totalSin}}</h5>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-10">
                            <h5 style="font-size: 15px; font-weight: 800;">Total membresía: &#36; {{ carrito.totalCon}}
                            </h5>
                        </div>
                    </div>

                    <div class="row" *ngIf="!paciente.membresiaActiva">
                        <div class="col-md-10">
                            <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro">Ahorro: &#36;
                                {{carrito.totalSin - carrito.totalCon}}</h5>
                        </div>
                    </div>

                    <div class="row" *ngIf="paciente.membresiaActiva">
                        <div class="col-md-10">
                            <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro">Te ahorraste: &#36;
                                {{carrito.totalSin - carrito.totalCon}}</h5>
                        </div>
                    </div>

                    <button type="button" class="btn btn-success btn-block btn-tarjeta"
                        (click)="pagaragenda()">
                        <i class="fas fa-hand-holding-usd"></i>
                        Pagar
                    </button>
                    <button *ngIf="AgendaServ.length != 0" type="button" class="btn btn-success btn-block btn-tarjeta"
                        (click)="Agendar()">
                        <i class="bi-calendar-check-fill"></i>
                        Agendar
                    </button>
                    <button type="button" class="btn btn-warning btn-block btn-tarjeta"
                        (click)="imprimirCotizacion(carrito)">
                        <i class="fas fa-hand-holding-usd"></i>
                        Imprimir cotización
                    </button>

                    <!-- <button class="btn btn-dark btn-tarjeta btn-block" [routerLink]="['/paciente']" routerLinkActive="router-link-active">
                            <i class="fas fa-user-plus"></i>
                            Nuevo
                    </button> -->

                    <!-- <button class="btn btn-block btn-info  btn-tarjeta">
                 <i class="fas fa-share-alt"></i>
                 Enviar
            </button> -->
                </div>
            </div>

        </div>
    </div>
</section>