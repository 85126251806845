<section class="row">
    <div class="col-md-9">
        <h1 class="title" *ngIf="title" id="centerxd">Ficha de identificación</h1>
    </div>
    <div class="col-md-3">
        <p class="fecha center" id="fecha"></p>

    </div>
</section>

<section class="container-fluid">

    <div class="card" name="card" id="card">
        <br>
        <div class="row">
            <div class="col margin-info">
                <p> <span>  Nombre Completo: {{ pacienteInfo.nombrePaciente | uppercase  }} {{ pacienteInfo.apellidoPaterno | uppercase  }} {{ pacienteInfo.apellidoMaterno | uppercase  }} </span> </p>
            </div>
            <div class="col">
                <p> <span> Edad: {{ pacienteInfo.edad }} </span> </p>
            </div>
            <div class="col">
                <p> <span> Género: {{pacienteInfo.genero | uppercase  }} </span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Domicilio:</span> {{ pacienteInfo.callePaciente | uppercase }} {{pacienteInfo.estadoPaciente | uppercase }} {{ pacienteInfo.paisPaciente | uppercase }} </p>
            </div>
            <div class="col">
                <p> <span>Fecha de Nacimiento:</span> {{pacienteInfo.fechaNacimientoPaciente | date:'dd/MM/yyyy':'UTC'}} </p>
            </div>
            <div class="col">
                <p> <span>Lugar de Nacimiento:</span> {{ pacienteInfo.estadoPaciente | uppercase }} {{ pacienteInfo.paisPaciente | uppercase }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Teléfono:</span> {{ pacienteInfo.telefono }} </p>
            </div>
            <div class="col">
                <p> <span>CURP:</span> {{ pacienteInfo.curp }} </p>
            </div>
            <div class="col">
                <p> <span>ID:</span> {{ pacienteInfo._id }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Numero de Expediente:</span> {{ pacienteInfo.numeroExpediente }} </p>
            </div>
            <div class="col">
                <p> <span>Sede:</span> {{ pacienteInfo.sede }} </p>
            </div>
            <div class="col">
                
            </div>
        </div>
        <div class="row center" *ngIf="rol != 'enfermeria' && rol != 'CONTABILIDAD'">
            <div class="col">
                <span>CONTACTO DE EMERGENCIA</span>
            </div>
        </div>
        <div class="row" *ngIf="rol != 'enfermeria' && rol != 'CONTABILIDAD'">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Nombre:</span> {{ pacienteInfo.contactoEmergencia1Nombre }} </p>
            </div>
            <div class="col">
                <p> <span>Edad:</span> {{ pacienteInfo.contactoEmergencia1Edad }} </p>
            </div>
            <div class="col">
                <p> <span>Telefono:</span> {{ pacienteInfo.contactoEmergencia1Telefono}} </p>
            </div>
        </div>

        <div class="row center" *ngIf="rol == 'enfermeria'">
            <div class="col" style="margin-bottom: 15px;">
                <span>ENFERMERÍA</span>
            </div>
        </div>
        <div class="row" *ngIf="rol == 'enfermeria'" style="margin-bottom: 10px;">
            <div class="col" style="margin-left: 40px;">
                <div class="row">
                    <div class="col-md-5">
                        <p><span>TIPO DE SANGRE:</span></p>
                    </div>
                    <div class="col">
                        <select name="tipoDeSangre" class="form-control" [(ngModel)]="pacienteInfo.tipoDeSangre" (change)="actualizarSangre($event.target.value)">
                            <option value="Tipo" selected>Seleccionar una opción</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            <option value="desconoce">Desconocido</option>
                            <!-- Cambiar edades, Hombres de 10 a 99 años
                                Hombrees de 10 a 99 años, agregando parametros especiales -->
                        </select>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-3">
                        <span>RELIGIÓN:</span>
                    </div>
                    <div class="col">
                        <select name="religion" class="form-control" [(ngModel)]="pacienteInfo.religion" (change)="relig($event.target.value)">
                            <option value="Tipo" selected>Seleccionar una opción</option>
                            <option value="JUDIO">Judío</option>
                            <option value="CATOLICO">Católico</option>
                            <option value="EVANGELICO">Evangélico</option>
                            <option value="MENONITAS">Menonita</option>
                            <option value="PENTECOSTALES">Pentecostés</option>
                            <option value="BAUTISTA">Bautista</option>
                            <option value="METODISTAS">Metodista</option>
                            <option value="ADVENTISTAS">Adventista</option>
                            <option value="BUDISTAS">Budista</option>
                            <option value="KRESHNAS">Krishna</option>
                            <option value="PRESBITERIANOS">Presbiteriano</option>
                            <option value="TESTIGOS DE JEOVA">Testigo de Jehová</option>
                            <option value="ISLAMICOS">Islámico</option>
                            <option value="OTRA RELIGION">Otra</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col">
                <div *ngIf="pacienteInfo.edad <= 9" style="margin-right: 15px;">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">¿Cuenta con esquema de Vacunación?</label>
                        <input type="radio" id="" name="ev" class="form-check-input-lg" [(ngModel)]="infPaciente.esquemaVacunacion" value="si">
                        <label class="form-check-label" for="si">Si</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" id="" name="ev" class="form-check-input-lg" [(ngModel)]="infPaciente.esquemaVacunacion" value="no">
                        <label class="form-check-label" for="no">No</label>
                    </div>
                    <div>
                        <select style="max-width: 25em; margin-top: 4px;" name="esquemaVacunacionStatus" id="esquemaVacunacionStatus" class="form-control" *ngIf="infPaciente.esquemaVacunacion == 'si'">
                            <option value="" selected>Seleccionar una opción</option>
                            <option value="completo">Completo</option>
                            <option value="incompleto">Incompleto</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row center" *ngIf="rol == 'CONTABILIDAD'">
            <div class="col" style="margin-bottom: 15px;">
                <span>FACTURACIÓN</span>
            </div>
        </div>
        <div class="row" *ngIf="rol == 'CONTABILIDAD'" style="margin-bottom: 10px;">
            <div class="col">
                <div class="row">
                    <div class="col margin-info">
                        <p> <span>  Razon social: {{ pacienteInfo.razonSocial1 | uppercase  }}</span> </p>
                    </div>
                    <div class="col">
                        <p> <span> RFC: {{ pacienteInfo.razonSocial1RFC }} </span> </p>
                    </div>
                    <div class="col">
                        <p> <span> Codigo Postal: {{pacienteInfo.cpRazonSocial | uppercase  }} </span> </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col margin-info">
                        <p> <span>  Correo: {{ pacienteInfo.correoRazonSocial1}}</span> </p>
                    </div>
                    <!-- <div class="col">
                        <p> <span> Factura: {{ pacienteInfo.edad }} </span> </p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>