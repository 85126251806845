import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';

@Component({
  selector: 'app-entrega-patologia',
  templateUrl: './entrega-patologia.component.html',
  styleUrls: ['./entrega-patologia.component.css']
})
export class EntregaPatologiaComponent implements OnInit {

  public pedido = {
    estudios:[
      {nombreEstudio:""}
    ],
    idObtenido:{
      interpretacion:"",
      observaciones:"",
      diagnostico:""
    },
    idPaciente:{
      nombrePaciente: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimientoPaciente: "",
      genero: "",
      edad: 0,
      curp: "",
      telefono:0,
      religion:""
    }
  };
  public id = "";

  constructor(
    private patologiaService: PatologiaService,
    private _ActivatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.id = this._ActivatedRoute.snapshot.paramMap.get('id');
    this.obtenerPatologiaPorId();

  }

  obtenerPatologiaPorId() {
    this.patologiaService.obtenidosPatologiaID(this.id)
    .subscribe((data:any) => {

      if(data.ok){
        this.setDataPedido(data.data);
      }

    });
  }

  setDataPedido(data) {
    this.pedido = data;
  }

  
  imprimirRegresos(){

    const imgs = document.querySelectorAll('.img-fluid');
    imgs.forEach((element) => {
      element.classList.remove('hide');
      element.classList.add('block');
    });

    window.print();
    
    imgs.forEach((elements) => {
      elements.classList.remove('block');
      elements.classList.add('hide');
    })
  
  }


}
