<div class="content">
    <form #form='ngForm' [formGroup]="contacto">
        <div class="row">
            <div class="col">
                <p> PIEL </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="piel" id="piel" formControlName="piel" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> MUCOSAS </p>
            </div>
            <div class="col">
                <div class="col test">
                    <select name="mucosas" id="mucosas" formControlName="mucosas" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> TÓRAX </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="torax" id="torax" formControlName="torax" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> ABDOMEN </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="abdomen" id="abdomen" formControlName="abdomen" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> TAMAÑO DE HIGADO (HEPATOMEGALIA) </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="higado" id="higado" formControlName="higado" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> TAMAÑO DE BAZO </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="bazo" id="bazo" formControlName="bazo" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <p> OBSERVACIONES </p>
                <textarea name="observaciones_explofisi" formControlName="observaciones_explofisi" id="observaciones_explofisi" cols="30" rows="10" class="form-control"  (blur)="formularioExploracion(form)"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> GANGLIOS (ADENOMEGALIA) </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="ganglios" id="ganglios" formControlName="ganglios" class="form-control" (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <p> OBSERVACIONES </p>
                <textarea name="observaciones_diagnostico" formControlName="observaciones_diagnostico" id="observaciones_diagnostico" cols="30" rows="10" class="form-control" (blur)="formularioExploracion(form)"></textarea>
            </div>
        </div>
    </form>
</div>