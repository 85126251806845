import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-paquetes',
  templateUrl: './paquetes.component.html',
  styleUrls: ['./paquetes.component.css']
})
export class PaquetesComponent implements OnInit {

  @Input() paquetes:any;

  constructor() { }

  ngOnInit(): void {
  }

}
