<form #fMedicinaPreventiva #form='ngForm' id="fMedicinaPreventiva">
    <div class="container-fluid">
        <div class="row titulos">
            <div class="col-md-3">
                <span>ESTUDIOS</span>
            </div>
            <div class="col">
                <span>FECHA DE TOMA</span>
            </div>
            <div class="col">
                <span>FECHA DE ENTREGA</span>
            </div>
            <div class="col">
                <span>RESULTADOS</span>
            </div>
            <div class="col">
                <span>OBSERVACIONES</span>
            </div>
        </div>
        
        <div class="row" *ngIf="genero == 'masculino' && edad > 9">
            <div class="col-md-3">
                <p>Detección de cáncer de próstata</p>
            </div>
            <div class="col">
                <input name="cancerProstataFechaToma" [(ngModel)]="medicinaPreventiva.cancerProstataFechaToma" [value]="medicinaPreventiva.cancerProstataFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerProstataFechaEntrega" [(ngModel)]="medicinaPreventiva.cancerProstataFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerProstataResultado" [(ngModel)]="medicinaPreventiva.cancerProstataResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerProstataObservaciones" [(ngModel)]="medicinaPreventiva.cancerProstataObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;" *ngIf="genero == 'masculino' && edad > 9">
            <div class="col-md-3">
                <p>Detección de Leucemia</p>
            </div>
            <div class="col">
                <input name="leucemiaFechaToma" [(ngModel)]="medicinaPreventiva.leucemiaFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="leucemiaFechaEntrega" [(ngModel)]="medicinaPreventiva.leucemiaFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="leucemiaResultado" [(ngModel)]="medicinaPreventiva.leucemiaResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="leucemiaObservaciones" [(ngModel)]="medicinaPreventiva.leucemiaObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Detección de cáncer de estómago</p>
            </div>
            <div class="col">
                <input name="cancerEstomagoFechaToma" [(ngModel)]="medicinaPreventiva.cancerEstomagoFechaToma" type="text " [value]="medicinaPreventiva.cancerEstomagoFechaToma" class="form-control ">
            </div>
            <div class="col">
                <input name="cancerEstomagoFechaEntrega" [(ngModel)]="medicinaPreventiva.cancerEstomagoFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerEstomagoResultado" [(ngModel)]="medicinaPreventiva.cancerEstomagoResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerEstomagoObservaciones" [(ngModel)]="medicinaPreventiva.cancerEstomagoObservaciones" type="text " placeholder=" " class="form-control ">
            </div>

        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Detección de cáncer de colon</p>
            </div>
            <div class="col">
                <input name="cancerColonFechaToma" [(ngModel)]="medicinaPreventiva.cancerColonFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerColonFechaEntrega" [(ngModel)]="medicinaPreventiva.cancerColonFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerColonResultado" [(ngModel)]="medicinaPreventiva.cancerColonResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerColonObservaciones" [(ngModel)]="medicinaPreventiva.cancerColonObservaciones" type="text " placeholder=" " class="form-control ">
            </div>

        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Detección de cáncer de pulmón</p>
            </div>
            <div class="col">
                <input name="cancerPulmonFechaToma" [(ngModel)]="medicinaPreventiva.cancerPulmonFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerPulmonFechaEntrega" [(ngModel)]="medicinaPreventiva.cancerPulmonFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerPulmonResultado" [(ngModel)]="medicinaPreventiva.cancerPulmonResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerPulmonObservaciones" [(ngModel)]="medicinaPreventiva.cancerPulmonObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;" *ngIf="genero == 'FEMENINO' && edad > 9">
            <div class="col-md-3">
                <p>Detección de cáncer de mama</p>
            </div>
            <div class="col">
                <input name="cancerMamaFechaToma" [(ngModel)]="medicinaPreventiva.cancerMamaFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerMamaFechaEntrega" [(ngModel)]="medicinaPreventiva.cancerMamaFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerMamaResultado" [(ngModel)]="medicinaPreventiva.cancerMamaResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerMamaObservaciones" [(ngModel)]="medicinaPreventiva.cancerMamaObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
       
        <div class="row" style="margin-top: 10px;" *ngIf="genero == 'FEMENINO' && edad > 9">
            <div class="col-md-3">
                <p>Detección de cáncer cérvico uterino</p>
            </div>
            <div class="col">
                <input name="cancerCervicoUterinoFechaToma" [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerCervicoUterinoFechaEntrega" [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerCervicoUterinoResultado" [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="cancerCervicoUterinoObservaciones" [(ngModel)]="medicinaPreventiva.cancerCervicoUterinoObservaciones" type="text " placeholder=" " class="form-control ">
            </div>

        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña de detección de agudeza auditiva</p>
            </div>
            <div class="col">
                <input name="agudezaAuditivaFechaToma" [(ngModel)]="medicinaPreventiva.agudezaAuditivaFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="agudezaAuditivaFechaEntrega" [(ngModel)]="medicinaPreventiva.agudezaAuditivaFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="agudezaAuditivaResultado" [(ngModel)]="medicinaPreventiva.agudezaAuditivaResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="agudezaAuditivaObservaciones" [(ngModel)]="medicinaPreventiva.agudezaAuditivaObservaciones" type="text " placeholder=" " class="form-control ">
            </div>

        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña de buena salud bucal</p>
            </div>
            <div class="col">
                <input name="saludBucalFechaToma" [(ngModel)]="medicinaPreventiva.saludBucalFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="saludBucalFechaEntrega" [(ngModel)]="medicinaPreventiva.saludBucalFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="saludBucalResultado" [(ngModel)]="medicinaPreventiva.saludBucalResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="saludBucalObservaciones" [(ngModel)]="medicinaPreventiva.saludBucalObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña contra la desnutrición, sobrepeso y obesidad</p>
            </div>
            <div class="col">
                <input name="desnutricionSobrePesoObesidadFechaToma" [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="desnutricionSobrePesoObesidadFechaEntrega" [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="desnutricionSobrePesoObesidadResultado" [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="desnutricionSobrePesoObesidadObservaciones" [(ngModel)]="medicinaPreventiva.desnutricionSobrePesoObesidadObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña de detección de diabetes mellitus y dislipidemia</p>
            </div>
            <div class="col">
                <input name="diabetesFechaToma" [(ngModel)]="medicinaPreventiva.diabetesFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="diabetesFechaEntrega" [(ngModel)]="medicinaPreventiva.diabetesFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="diabetesResultado" [(ngModel)]="medicinaPreventiva.diabetesResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="diabetesObservaciones" [(ngModel)]="medicinaPreventiva.diabetesObservaciones" type="text " placeholder=" " class="form-control ">
            </div>

        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña de detección de agudeza visual</p>
            </div>

            <div class="col">
                <input name="agudezaVisualFechaToma" [(ngModel)]="medicinaPreventiva.agudezaVisualFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="agudezaVisualFechaEntrega" [(ngModel)]="medicinaPreventiva.agudezaVisualFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="agudezaVisualResultado" [(ngModel)]="medicinaPreventiva.agudezaVisualResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="agudezaVisualObservaciones" [(ngModel)]="medicinaPreventiva.agudezaVisualObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña de detección de enfermedades respiratorias</p>
            </div>
            <div class="col">
                <input name="enfermedadesRespiratoriasFechaToma" [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="enfermedadesRespiratoriasFechaEntrega" [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="enfermedadesRespiratoriasResultado" [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="enfermedadesRespiratoriasObservaciones" [(ngModel)]="medicinaPreventiva.enfermedadesRespiratoriasObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
       
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Campaña de detección de trastorno en la marcha</p>
            </div>
            <div class="col">
                <input name="trastornoMarchaFechaToma" [(ngModel)]="medicinaPreventiva.trastornoMarchaFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="trastornoMarchaFechaEntrega" [(ngModel)]="medicinaPreventiva.trastornoMarchaFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="trastornoMarchaResultado" [(ngModel)]="medicinaPreventiva.trastornoMarchaResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="trastornoMarchaObservaciones" [(ngModel)]="medicinaPreventiva.trastornoMarchaObservaciones" type="text " placeholder=" " class="form-control ">
            </div>
        </div>
        
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <p>Otros</p>
            </div>
            <div class="col">
                <input name="otrosFechaToma" [(ngModel)]="medicinaPreventiva.otrosFechaToma" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="otrosFechaEntrega" [(ngModel)]="medicinaPreventiva.otrosFechaEntrega" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="otrosResultado" [(ngModel)]="medicinaPreventiva.otrosResultado" type="text " placeholder=" " class="form-control ">
            </div>
            <div class="col">
                <input name="otrosObservaciones" [(ngModel)]="medicinaPreventiva.otrosObservaciones" type="text " placeholder=" " class="form-control ">
            </div>

        </div>
        <div class="row">
            <!-- *ngIf="paciente.consultas = 1" -->
            <!--TODO: se quito para actualizar expedientes *ngIf="paciente.consultas = 1" -->
            <button class="boton" (click)="agregarMedicinaP(form)">Guardar</button>
            <button class="boton2" (click)="actualizarMedicinaP(form)">Actualizar</button>
        </div>
    </div>
</form>
