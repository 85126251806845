import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { serologia_disp } from '../../../../../functions/bancoSangre';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import jsPDF from 'jspdf'; 
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
@Component({
  selector: 'app-serologia-lab-disponente',
  templateUrl: './serologia-lab-disponente.component.html',
  styleUrls: ['./serologia-lab-disponente.component.css']
})
export class SerologiaLabDisponenteComponent implements OnInit {

    public idBanco = "";
    
    public paciente={
      nombrePaciente: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      curp: '',
      edad: '',
      genero: '',
      _id:'',
      callePaciente: '',
      fechaNacimientoPaciente:'',
      estadoPaciente: '',
      paisPaciente: '',
      telefono: '',
      receptor: '',
      tipoDeSangre: '',
      disponente: '',
      religion:''
    } 

    public motivos = {
      motivo1: false,
      motivo2: false,
      motivo3: false,
    }

    public fecha = moment().format('L');
    public horatl = moment().format('LTS'); 
    resultadoCulero: FormGroup;
        
    ngNop = 'NO REACTIVO';
    ngSip = 'REACTIVO';
    resultado0 = 'NO REACTIVO';
    resultado1 = 'NO REACTIVO';
    resultado2 = 'NO REACTIVO';
    resultado3 = 'NO REACTIVO';
    resultado4 = 'NO REACTIVO';
    resultado5 = 'NO REACTIVO';
    resultado6 = 'NO REACTIVO';

    resultado: string[] = ['NO REACTIVO', 'NO REACTIVO', 'NO REACTIVO', 'NO REACTIVO','NO REACTIVO','NO REACTIVO','NO REACTIVO'];

    public quimico = {
      nombre:"",
    }
    public metodos=[];
    public elementos=[]; 
    public hemoderi = [];
    public sero = {
      idlaboratorio:'',
      metodo:[],
      quimico:'',
      tecnico:'',
      cedula:'',
      proceso:'',
      motivo:'',
      rechazo:'',
      tiempoDiferir: new Date(),
      obtenidos:[],
      productos:[],
      idbancodesangre:''
    }
    public donaciones=0;
    public donacionesPaciente=0;
    public tipo_de_sangreado= '';
    public respuesta={
      diferido: [{
        fechafinal: "",
        fechainicio: "",
        motivo: "",
        tiempoDiferir: "",
      }],
      diferir: true,
      proceso: "",
      respuestas: 0
    }
    public seroVolObte = [
      {
        volumen: '',
        solucion:  '' 
      },
      {
        volumen: '',
        solucion:  '' 
      },
      {
        volumen: '',
        solucion:  '' 
      },
  ]
  constructor(
    private _route: ActivatedRoute, 
    private route: Router, 
    private _banco: BancoService,
    // private formBuilder: FormBuilder,
    private _spinnerService:SpinnerService
  ) { }

  ngOnInit(): void {
    // 
    this.idBanco = this._route.snapshot.paramMap.get('id');
    this.getFichadeIdentificacion(this.idBanco)
    this.dataSerologia();
    this._spinnerService.show();
    this.getstorageNombre();

    this.sero.productos = [
      {
            productosFI: '61d881b0f71b853ce08b6b7e',
            fecha_caducidad:  this.fechas_caducidad(35),
            lote:  '1',
            proveedor: '615e6f5c47e774260875cbbe',
            factura: '0001',
           // lote_unitario : clave.concat('/','CE') ,
            costoReal: '0',
            precioVenta:'3306',
            seDesecha:"",
            motivoDesecho:"",
            procesoDesecha:"",
            nomenclatura:"CE"
      },
      {
            productosFI: '61611ac88598bf035496e751',
            fecha_caducidad:  this.fechas_caducidad(720),
            lote:  '1',
            proveedor: '615e6f5c47e774260875cbbe',
            factura: '0001',
           // lote_unitario : clave.concat('/','CP') ,
            costoReal: '0',
            precioVenta:'2784',
            seDesecha:"",
            motivoDesecho:"",
            procesoDesecha:"",
            nomenclatura:"CP"
      },
      {
            productosFI: '61d8830af71b853ce08b6b80',
            fecha_caducidad:  this.fechas_caducidad(1080),
            lote:  '1',
            proveedor: '615e6f5c47e774260875cbbe',
            factura: '0001',
           // lote_unitario : clave.concat('/','PL') ,
            costoReal: '0',
            precioVenta:'2350',
            seDesecha:"",
            motivoDesecho:"",
            procesoDesecha:"",
            nomenclatura:"PL"
      }
    ];

    // Form Culero :v
  //   this.resultadoCulero = this.formBuilder.group({
  //     resultado0: ['NO REACTIVO'],
  //     resultado1: ['NO REACTIVO'],
  //     resultado2: ['NO REACTIVO'],
  //     resultado3: ['NO REACTIVO'],
  //     resultado4: ['NO REACTIVO'],
  //     resultado5: ['NO REACTIVO'],
  //     resultado6: ['NO REACTIVO'],
  // });


  }


  getstorageNombre() {
   this.quimico.nombre = JSON.parse(localStorage.getItem('usuario') ).nombre;
  //  console.log( this.quimico )
  }

  getFichadeIdentificacion ( id ){
    this._banco.getHojaDeServicioLab(id).subscribe((resp:any)=>{
      /* console.log(resp);  */
      /* this.paciente = resp.data.paciente */
      this.tipo_de_sangreado = resp.data.flebotomia.tipo_de_sangreado
      this.setPaciente(resp.data.paciente);
      this.getDonaciones(resp.data.paciente._id);
    })
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

  dataSerologia(){
    this._banco.getEstudiosbyIdlLab('61383eeb7a528c514406d4ef').subscribe((resp:any)=>{
      this.setSerologia(resp['data']);
    })
  }

  setSerologia(resp){
    this.elementos = resp.valoresDeReferencia
    this.elementos.forEach(element => {
      this.metodos.push(element.metodo)
    })
  }

  getDonaciones(id){
    let idPaciente = {
      idPaciente:id
    }
    this._banco.numerodeDonadores().subscribe((resp:any)=>{
      this.setNumeroDonadores(resp['data']);
    })
    this._banco.numeroDonacionesPaciente(idPaciente).subscribe((resp:any)=>{
      this.setNumeroDonacionesPaciente(resp['data'])
    })
  }

  setNumeroDonadores(numero){
    this.donaciones = numero
  }
  setNumeroDonacionesPaciente(numero){
    this.donacionesPaciente=numero
  }

  validarSerologia(form: NgForm){        // lote_unitario : ,
    this._spinnerService.show();
    let fecha;
    fecha = moment().format('l'); 
    let regreso = serologia_disp(fecha, form.value);
    this.respuesta = regreso;
    
  }

  //loading
  pintLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
    /* console.log("toy funcionando weeeee"); */
    
  }

  removeLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
    /* console.log('dejo de funcionar'); */
    
  }

  enviar(resp){
    // this.pintLoadingScreen()
    if(resp.diferir){
      let fecha = new Date(resp.diferido[0].fechafinal);
      let dif ={
          idbanco:this.idBanco,
          proceso:resp.proceso, 
          estatus:'DIFERIDO',
          motivo:resp.diferido[0].motivo,
          rechazo:resp.diferido[0].tiempoDiferir,
          fechaRechazo:fecha
      }
      this._banco.diferirDisponente(dif).subscribe((resp:any)=>{
       /* console.log(resp); */
      //  this.removeLoadingScreen()
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL DISPONENTE SE DIFERIO CORRECTAMENTE',
        })
        this.route.navigate([ `/dashboard`]);
      })
    }else{
      this.setSerologiaData(resp.sero,resp);
    }
  }

  setSerologiaData(obtenido,resp){
    let fechaget = new Date(this.fechas_caducidad(90))
    let clave = ''
    //TODO:PREGUNTAR CUANTO TIEMPO SE DIFIERE DESPUES DE UNA DONACION
    let id = {
      idbancosangre: this.idBanco,
      proceso: 'FINALIZADO',
      fecha_termino:fechaget,
      estatus:'DIFERIDO',
      motivo:'DONACION REALIZADA',
      tiempo_rechazo:'3 MESES'
    }
    this.sero.idbancodesangre = this.idBanco
    this.sero.idlaboratorio='61383eeb7a528c514406d4ef'
    this.sero.cedula='xxxxxxxxxxxx'
    this.sero.metodo=this.metodos
    this.sero.quimico='xxxxxxxxxxxx'
    this.sero.tecnico='xxxxxxxxxxxx'
    this.sero.proceso='FINALIZADO'
    this.sero.motivo ='Finalizo un proceso de donacion',
    this.sero.rechazo='3 meses',
    this.sero.tiempoDiferir = fechaget,
    this.sero.obtenidos.push(obtenido)
    clave = this.paciente.curp.concat('/',this.donaciones.toString(),'/',this.donacionesPaciente.toString())
    this.sero.productos[0].lote_unitario =clave.concat('/','CE');   
    this.sero.productos[1].lote_unitario =clave.concat('/','CP');
    this.sero.productos[2].lote_unitario =clave.concat('/','PL');    
    if (this.tipo_de_sangreado == 'SANGRE TOTAL') {
      this.hemoderi = ['CONCENTRADO ERITROCITARIO','CONCENTRADO PLAQUETARIO','PLASMA']
    } else {
      this.hemoderi = ['PLAQUETOFERESIS']
          this.sero.productos = [
      {
            productosFI: '61d882c8f71b853ce08b6b7f',
            fecha_caducidad:  this.fechas_caducidad(35),
            lote:  '1',
            proveedor: '615e6f5c47e774260875cbbe',
            factura: '0001',
            lote_unitario : clave.concat('/','PT') ,
            costoReal: '0',
            precioVenta:'300'
      },
    ];
    }

    // this.sero.productos.forEach(element => {
    //   this.etiquetaLiberado(element.lote_unitario);
    // });
     this._banco.datosSerologia(this.sero).subscribe((resp:any)=>{
      this.sero.productos.forEach((element,index) => {
      this.etiquetaLiberado(element.lote_unitario,resp.data[index], element.fecha_caducidad, this.hemoderi[index], this.seroVolObte[index]);
      });


      this._banco.cambiarProceso(id).subscribe((resp:any)=>{
        this.removeLoadingScreen();
        
       })
       Swal.fire({
        icon: 'success',
        title: '',
        text: 'RECUERDA MANTENER LOS PRODUCTOS EN REFRIGERACIÓN',
      })
      this.route.navigate([ `/dashboard`]);
    })     
  }

  fechas_caducidad( duracion ){
    const fecha = new Date()
    const agregarTiempo = duracion * 86400
    
    const fecha_final = fecha.setSeconds(agregarTiempo)
    
    /* console.log(piv_fecha) */
    return fecha_final
  }

  public agregarADesechos(event:any, texto: string){
    
    this.sero.productos.forEach((element: any) => {
      if(element.nomenclatura ==  texto ){
        if(element.seDesecha  === 'SI'){
          element.seDesecha ="NO",          
          element.motivoDesecho ="",
          element.procesoDesecha= ""
          this.motivos.motivo1=false;
          this.motivos.motivo2=false;
          this.motivos.motivo3=false;
        }else {
          
          if( texto == "CE" ){
            this.motivos.motivo1 = true;
          }

          if( texto == "CP" ){
            this.motivos.motivo2 = true;
          }

          if( texto == "PL" ){
            this.motivos.motivo3 = true;
          }
          element.seDesecha ="SI",
          element.procesoDesecha= "SEROLOGIA"
        }
      }
      
    });
  }

  etiquetaLiberado(id:any, qr: any, fecha_caducidad:any, hemo:any, vol:any) {
     // ruta img '../../../../../assets/images/qr_test.png'
     //let imgEtiqueta = '../../../../../assets/images/qr_test_2.png'
     const piv_fecha = new Date(fecha_caducidad).toLocaleDateString('en-US')
     let imgEtiqueta = qr
     // Aquí tenemos que cachar el código qr que genera el back
     const doc = new jsPDF({
       orientation: "landscape",
       unit: "cm",
       format: [12, 8]
     });
    //  this.paciente.tipoDeSangre = "AB+"
    doc.setFont('helvetica')
     doc.setTextColor(150);
     doc.setFontSize(58);
     doc.addImage(imgEtiqueta, 'PNG', 8, 3, 4.2, 4.2)
     doc.text('LIBERADO', 2, 8, null, 43);
     doc.setFontSize(8);
     doc.setTextColor(0, 0, 0);
     doc.text('BANCO DE SANGRE', 0.6, 0.5);
     doc.text('CENSUR', 0.6, 1);
     doc.setFontSize(5);
     doc.text('FECHA: ', 6.7, 0.5);
     doc.text(this.fecha, 7.8, 0.5)
     doc.text('HORA: ', 9.2, 0.5);
     doc.text(this.horatl, 10, 0.5)
     doc.text('HORA INICIO: ', 7, 1);
     doc.text('CADUCIDAD: ', 7, 1.5);
     doc.text(piv_fecha, 8.5, 1.5);
     doc.setFontSize(14);
     doc.text('GRUPO Y RH: ', 7, 2.5);
     doc.text(this.paciente.tipoDeSangre, 8, 3);
     doc.setFontSize(10);
     doc.text('ID: ', 0.6, 1.5);
     doc.text(id, 0.6, 2);
     doc.text('COMPONENTE SANGUÍNEO: ', 0.6, 2.5);
     doc.text(hemo, 0.6, 3);
     doc.text('SOLUCIÓN: ', 0.6, 3.5);
     doc.text(vol.solucion +' ml', 5, 3.5);
     doc.text('VOLUMEN: ', 0.6, 4);
     doc.text(vol.volumen + ' ml', 5, 4);
     doc.text('TEMPERATURA: ', 0.6, 4.5);
     doc.text('-10 ºC ', 5, 4.5);
     doc.text('SEROLOGÍA: ', 0.6, 5);
     
     doc.setFontSize(5);
     // AQUÍ EL CHINE ME DEBE ENSEÑAR COMO PASAR LOS PARAMETROS
     // CHINE COCHINE XDDDDD
     doc.text('VIRUS DE INMUNODEFICIENCIA HUMANA: ', 0.6, 5.4);
     doc.text('NO REACTIVO ', 4.8, 5.4);
     doc.text('ANTICUERPOS ANTI HEPATITIS B: ', 0.6, 5.6);
     doc.text('NO REACTIVO ', 4.8, 5.6);
     doc.text('ANTICUERPOS ANTI HEPATITIS C: ', 0.6, 5.8);
     doc.text('NO REACTIVO ', 4.8, 5.8);
     doc.text('VDRL: ', 0.6, 6);
     doc.text('NO REACTIVO ', 4.8, 6);
     doc.text('CHAGAS: ', 0.6, 6.2);
     doc.text('NO REACTIVO ', 4.8, 6.2);
     doc.text('BRUCELLAS: ', 0.6, 6.4);
     doc.text('NO REACTIVO ', 4.8, 6.4);
     doc.text('DENGUE: ', 0.6, 6.6);
     doc.text('NO REACTIVO ', 4.8, 6.6);
     doc.setFontSize(5);
     doc.text('No transfundirse en presencia de hemólisis o cualquier otro signo de deterioro: ', 0.6, 6.8);
     doc.text('-No mezclarse con medicamentos o soluciones que no sea solución salina isotónica al 0.9%, y ', 0.6, 7);
     doc.text('-Transfundirse a través de un filtro de 070 – 200 micras o en su caso, un filtro para leucodepletar, y', 0.6, 7.2);
     doc.text('-Los procedimientos adicionales que se hubiesen efectuado, tales como irradiación', 0.6, 7.4);
     doc.text(' remoción de la capa leucoplaquetaria o filtrado para leucodepleción', 0.6, 7.6);
     doc.save("Etiqueta_Liberado_" + this.paciente.nombrePaciente + '.pdf');
  }

}
