import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';;

@Component({
  selector: 'app-crear-seguimientos-almacen',
  templateUrl: './crear-seguimientos-almacen.component.html',
  styleUrls: ['./crear-seguimientos-almacen.component.css']
})
export class CrearSeguimientosAlmacenComponent implements OnInit {

  public btnValidacion = true;

  public seguimientoData ={
   departamento:'',
   nombre:'',
   categoria:'Generado',
   solicitante:'',
   depaASoli:'',
   id:'',
   servicios:'',
   estado:'',
   descripcion:''
  }
  constructor(
    private _AlmacenService: AlmacenService,
    private _router: Router 
  ) { }

  ngOnInit(): void {
  }

  validarForm(){
    if (this.seguimientoData.nombre.length == 0) {

      alert('Ingresa el nombre del Seguimiento');

    }else if(this.seguimientoData.departamento.length == 0) {

      alert('Ingresa el nombre del Seguimiento');

    }else if(this.seguimientoData.depaASoli.length == 0) {

      alert('Ingresa el nombre del Seguimiento');

    }else if(this.seguimientoData.servicios.length == 0) {

      alert('Ingresa el nombre del Seguimiento');

    }else if(this.seguimientoData.estado.length == 0) {

      alert('Ingresa el nombre del Seguimiento');

    }else if (this.seguimientoData.descripcion.length == 0) {

      alert('Ingresa el nombre del Seguimiento');

    }
    this.btnValidacion = false;
  }
   
  registrarSeguimiento(){
  
    this._AlmacenService.crearSeguimiento(
      this.seguimientoData).subscribe(( data:any ) => {
      Swal.fire({
        title: 'EL SEGUIMIENTO SE CREO EXISTOSAMENTE',
        icon: 'success'
        }) 
      });
  }      
}
