<section class="row">
    <h1 class="centerxd">REVISIÓN DE ORDEN DE COMPRA</h1>
</section>

<section class="row" id="container">
    <div class="col-md-6">
        <div class="card" id="card">
            <div class="card-header" id="cardheader">
                <div class="row">
                    <div class="col">Datos del paciente</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3"> Nombre: </div>
                    <div class="col">{{paciente.nombrePaciente}} {{paciente.apellidoPaterno}} {{paciente.apellidoMaterno}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3"> Edad: </div>
                    <div class="col"> {{paciente.edad}} </div>
                </div>
                <div class="row">
                    <div class="col-md-3"> Sexo: </div>
                    <div class="col">{{paciente.genero}}</div>
                </div>

                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <label for="solicito">Quien recibe</label>
                    </div>
                    <div class="col">
                        <input type="text" [(ngModel)]="venta.quienRecibe" name="solicito" id="solicito" class="form-control">
                    </div>
                </div>
                <div class="row" id="metodos">
                    <div class="col-md-12">Método de pago</div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" id="efectivo" name="efectivo" ng-model="checked" (click)="habilitar('efectivo', $event)">
                        <label for="efectivo">Pago en efectivo</label>
                        <div *ngIf="venta.Efectivo">
                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" [(ngModel)]="venta.montoEfectivo" name="montoEfectivo" id="montoEfectivo" (change)="calculoEfectivo()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" id="transferencia" name="transferencia" ng-model="checked" (click)="habilitar('tranfer', $event)">
                        <label for="transferencia">Transferencia bancaria</label>
                        <div *ngIf="venta.Transferencia">
                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" [(ngModel)]="venta.montoTranseferencia" name="montoTranferencia" id="montoTranferencia" (change)="calculoTransferencia()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" id="tarjetaDeCredito" name="tarjetaDeCredito" ng-model="checked" (click)="habilitar('credito', $event)">
                        <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                        <br>
                        <div *ngIf="venta.TarjetaCredito">
                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" [(ngModel)]="venta.montoCredito" name="montoTarjetaCredito" id="montoTarjetaCredito" (change)="calculoCredito()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" id="tarjetaDeDebito" name="tarjetaDeDebito" ng-model="checked" (click)="habilitar('debito', $event)">
                        <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                        <br>
                        <div *ngIf="venta.TarjetaDebito">
                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" [(ngModel)]="venta.montoDebito" name="montoTarjetaDebito" id="montoTarjetaDebito" (change)="calculoDebito()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col" id="boton">
                <button class="btn" (click)="enviar()" [disabled]="boton"> 
                    ORDENAR
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card" id="card1">
            <div class="card-header" id="cardheader">
                <div class="row">
                    <div class="col"> No.</div>
                    <div class="col-md-3"> Estudios</div>
                    <div class="col-md-2"> Precio</div>
                    <div class="col-md-5">ID</div>
                </div>
            </div>

            <div class="card-body" style="overflow: auto;">
                <div class="container">
                    <div *ngFor="let item of carrito.items; let i = index">
                        <div class="row">
                            <div class="col">
                                {{i +1}}
                            </div>
                            <div class="col-md-3">
                                {{item.producto}}
                            </div>
                            <div class="col-md-2">
                                $ {{item.precio}}
                            </div>
                            <div class="col-md-5">
                                {{item.idProducto}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" id="cardfooter">
                <div class="row">
                    <div class="col">TOTAL</div>
                    <div class="col white">$ {{carrito.total}}</div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>