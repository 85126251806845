<div class="container">
  <h1>DETALLE DEL SEGUIMIENTO</h1>
<form (ngSubmit)="guardar( f )" #f="ngForm">
  <div class="card p-3 mt-3">
      <div class="row">
          <div class="col">
              <div class="mb-1 row">
                  <label class="col-sm-3">NOMBRE: </label>
                  <div class="col sm-5">
                      <p>ALMACEN-BANCO DE SANGRE</p>
                  </div>
              </div>
              <div class="mb-1 row">
                  <label class="col-sm-3">CATEGORIA: </label>
                  <div class="col-sm-5">
                      <p>URGENTE</p>
                  </div>
              </div>
              <div class="mb-1 row">
                  <label class="col-sm-3">SOLICITANTE: </label>
                  <div class="col-sm-5">
                      <p>ENFERMERÍA-CENSUR</p>
                  </div>
              </div>
              <div class="mb-1 row">
                  <label class="col-sm-3">DESCRIPCION: </label>
                  <div class="col-sm-9">
                      <p>MATERIAL EXTRA PARA LA EXTRACCION DE SANGRE</p>
                  </div>
              </div>
          </div>
          <div class="col">
              <div class="mb-1 row">
                  <label class="col-sm-3">ID: </label>
                  <div class="col-sm-5">
                      <p>2RKFJNRLF245OUTJ5P4OP45JO</p>
                  </div>
              </div>
              <div class="mb-1 row">
                  <label class="col-sm-3">DEPARTAMENTO: </label>
                  <div class="col-sm-5">
                      <p>FLEBOTOMIA</p>
                  </div>
              </div>
              
              <div class="mb-1 row">
                  <label class="col-sm-3">ESTADO: </label>
                  <div class="col-sm-7">
                      <select class="form-select" name="estadoDetalle" [class.is-invalid]="detadoDetalle.invalid && detadoDetalle.touched" ngModel required #detadoDetalle="ngModel">
                          <option hidden selected>SELECCIONA UN ESTADO</option>
                          <option >One</option>
                        </select>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="card mt-5">
      <div class="card-header">
          <div class="row">
              <div class="col">
                  No.
              </div>
              <div class="col">
                  ID
              </div>
              <div class="col">
                  NOMBRE
              </div>
              <div class="col">
                  LOTE
              </div>
              <div class="col">
                  LOTE UNITARIO
              </div>
              <div class="col">L
                  LUGAR DE ORIGEN
              </div>
              <div class="col">
                  ESTADOS
              </div>
          </div>
      </div>
      <div class="card-body">
          <div class="row">
              <div class="col">
                  01
              </div>
              <div class="col">
                  I2390239302
              </div>
              <div class="col">
                  500
              </div>
              <div class="col">
                  1203
              </div>
              <div class="col">
                  OTROS HOSPITALES
              </div>
              <div class="col">
                  Pfizer
              </div>
              <div class="col">
                  ACTIVO
              </div>
          </div>
      </div>
      <div class="card-footer text-center">
          <div class="col">
              AQUI VA EL PAGINADOR
          </div>
      </div>
  </div>

  <div class="my-4">
      <div class="d-grid gap-2 col-2 mx-auto">
          <button type="submit" class="btn btn-primary">ACTUALIZAR</button>
        </div>
  </div>
</form>
</div>