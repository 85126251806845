import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuevo-estudio',
  templateUrl: './nuevo-estudio.component.html',
  styleUrls: ['./nuevo-estudio.component.css']
})
export class NuevoEstudioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
