import { Component, OnInit } from '@angular/core';
const confetti = require('canvas-confetti');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public id = '';
  public activar = false;
  constructor() { }

  ngOnInit(): void {
    this.id = JSON.parse(localStorage.getItem('usuario'))._id;
    if(this.id == '6171e5f323ee433e40a26af6'){
      this.activar = true
    }
    this.eliminarCarrito();
    this.eliminarSede();
    this.borrarLocal();
  }

  eliminarCarrito(){
    localStorage.removeItem('carrito');
  }

  eliminarSede(){
    localStorage.removeItem('sede');
  }

  borrarLocal(){
    localStorage.removeItem('sedePaciente')
    localStorage.removeItem('carrito')
    localStorage.removeItem('censur-carrito')
    localStorage.removeItem('idPedidoSede')
    localStorage.removeItem('idPedidoXray')
    localStorage.removeItem('idPedidoUltra')
    localStorage.removeItem('pedidoUsg')
    localStorage.removeItem('ultrasonido')
    localStorage.removeItem('fechaUsg')
    localStorage.removeItem('fechaXray')
    localStorage.removeItem('xray')
    localStorage.removeItem('paciente');
    localStorage.removeItem('nombreEstidio');
    localStorage.removeItem('idPedido');
    localStorage.removeItem('estudio')
    localStorage.removeItem('idPaciente')
    localStorage.removeItem('elementoss')
    localStorage.removeItem('idCensur')
    localStorage.removeItem('receptor')
    localStorage.removeItem('idUltra')
  }
  
}
