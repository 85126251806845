import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class SedesService {

  private url = URL;
  constructor(private _http: HttpClient) { }

  getSedes(){
    const url = this.url + "/ver/sedes";
    return this._http.get( url );
  }

  postSedes( body ){
    const url = this.url + "/crear/sede";
    return this._http.post( url, body );
  }

  getSede(id){
    const url = this.url + "/ver/sede/";
    return this._http.get(url + id);
  }

  getPorcentajeBySede( nomenclatura, servicio ) {
    const url = this.url + "/ver/utilidad/" +nomenclatura +"/"+servicio;
    return this._http.get(url);
  }

}
