<div class="container-fluid">
    <section id="ingreso">
        <div class="row">
            <div class="col-md-8">
                <app-titulos [title]="'NOTA DE EGRESO'" [rol]="'doctor'"></app-titulos>
            </div>
            <div class="col">
                <p>FECHA:11/JUNIO/2022</p>
                <p>HORA:10:00:00</p>
            </div>
        </div>
    </section>
    
    <ficha-info-user  [rol]="'doctor'"></ficha-info-user>

    <div class="row">
        <div class="col">
            <div class="card" id="historia">
                <div role="tabpanel" class="row">
                    <div class="col-4" id="indicadoress">
                        <ul class="nav flex-column nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#egreso" aria-controls="#egreso"> 
                                    DIAGNOSTICO
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#motivos" data-toggle="tab" aria-controls="#motivos"> 
                                    MOTIVOS
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#plan" data-toggle="tab" aria-controls="#plan">
                                    PLAN
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-8">
                        <div class="tab-content">
                            <div role="tabpanel" class="tab-pane active" id="egreso">
                                <h3 class="title-principal-bs mt-1" > Diagnostico de Ingreso </h3>
                                <div class="col-md-12">
                                    <input type="text" class="input-censur"  placeholder="APENDICITIS"  name="buscarDiagnostico" id="buscarDiagnostico">
                                </div>
                                <h3 class="title-principal-bs mt-1" > Fecha de Ingreso </h3>
                                <div class="col-md-12">
                                    <input type="text" class="input-censur"  placeholder="10/06/2022"  name="buscarDiagnostico" id="buscarDiagnostico">
                                </div>
                                <h3 class="title-principal-bs mt-1" > Diagnostico de Egreso </h3>
                                <div class="col-md-12">
                                    <input type="text" class="input-censur"  placeholder="APENDICITIS"  name="buscarDiagnostico" id="buscarDiagnostico">
                                    <div style="overflow-y: scroll; height:200px;">
                                        <table class="table table-hover">
                                            <tr>
                                                <td> DONANTE DE SANGRE </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <h3 class="title-principal-bs mt-1" > Fecha de Egreso </h3>
                                <div class="col-md-12">
                                    <input type="text" class="input-censur"  placeholder="10/06/2022"  name="buscarDiagnostico" id="buscarDiagnostico">
                                </div>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="motivos">
                                <textarea name="" id="" cols="30" rows="10"></textarea>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="plan">
                                <textarea name="" id="" cols="30" rows="10"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>