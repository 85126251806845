<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'RECETA'"></app-titulos>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ficha-info-user [pacienteInfo]="receta.idPaciente"></ficha-info-user>
        </div>
    </div>

    <div class="card card-medicamentos card-shadow">
        <div class="row">
            <div class="col-md-12 mb-4">
                <h3 class="title-principal-bs">
                    MÉDICAMENTOS
                </h3>
            </div>
        </div>
    
        <div *ngIf="receta.medicamentos.length >= 1" >
            <div class="row" *ngFor="let medicamento of receta.medicamentos; let i = index" >

                <div class="col-md-1">
                    {{ i +1 }}
                </div>
                <div class="col-md-6">
                    <p class="" >
                        {{ medicamento.medicamento}}
                    </p>
                </div>
    
                <div class="col-md-5">
                    <p>
                        {{ medicamento.indicaciones}}
                    </p>
                </div>    
            </div>
        </div>
        <div *ngIf="receta.medicamentos.length == 0">
            <p class="center" >
                No hay médicamentos solicitados
            </p>
        </div>
        
    </div>
</div>