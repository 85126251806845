<div class="container-fluid">
    <h1>HOJA DE SERVICIOS</h1>
    <section>
        <!-- <app-ficha-info-censur [pacienteInfo]="paciente"></app-ficha-info-censur> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'laboratorio'"></app-fichas-identificacion>
    </section>

    <div class="card">
        <h2>TIPAR Y CRUZAR</h2>
        <div class="card-heder">
            <div class="row">
                <div class="col">
                    <p>Producto</p>
                </div>
                <div class="col">
                    <p>Grupo y RH</p>
                </div>
                <div class="col">
                    <p>Donador</p>
                </div>
                <div class="col">
                    <p>Resultados obtenidos</p>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form #form='ngForm'>
                <div *ngFor="let item of product; let i = index">
                    <div class="row productos">
                        <div class="col">
                            {{item.producto}}
                        </div>
                        <div class="col">
                            <select [name]="item.idPaciente+'_'+i" required ngModel [id]="item.idPaciente+'_'+i" class="form-control" (change)="disponentes($event.target.value,item.idProducto,i)">
                                <option value="" selected>Seleccionar una opción</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                            </select>
                        </div> 
                        <div class="col">
                            <ng-container *ngIf="donadoresCompatibles[i]?.resultado != 'compatible'">
                                <select [name]="item.idProducto+'_'+i" ngModel required [id]="item.idProducto+'_'+i" class="form-control" (change)="donante($event.target.value)">
                                    <option value="" selected>Seleccionar una opción</option>
                                    <ng-container *ngIf="item.idProducto+'_'+i == selected ">
                                        <option [value]="item.idDonante" *ngFor="let item of donantes; let j = index" >{{item.lote_interno}} {{item.nombreCompleto}}</option> 
                                    </ng-container>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="donadoresCompatibles[i]?.resultado == 'compatible' && donadoresCompatibles[i]?.row == item.idProducto+'_'+i "  >
                                <select required class="form-control" disabled>
                                    <option [value]="" selected>{{donadoresCompatibles[i]?.strDonante}}</option>
                                </select>
                            </ng-container>
                        </div>
                        <div class="col">
                            <select [name]="item.idPedido+'_'+i" required ngModel [id]="item.idPedido+'_'+i" class="form-control" (change)="resultados(item.idPaciente+'_'+i,item.idProducto+'_'+i,item.idPedido+'_'+i,$event.target.value)">
                                <option value="" selected>Seleccionar una opción</option>
                                <option value="compatible">COMPATIBLE</option>
                                <option value="nocompatible">NO COMPATIBLE</option>
                            </select> 
                        </div>
                    </div>
                </div>
            </form>
            <div class="row letras mt-2">
                <h3>MÉTODOS: IMPLEMEDANCIA ELÉCTRICA TIPO V</h3>
        
                <span style="color: #000000">*Un diagnóstico clÍnico lo debe realizar un médico certificado y un resultado aislado en ocasiones es insuficiente para establecerlo</span>
            </div>
            <div class="center">
                <button class="principalButton" (click)="guardar()"> Agregar </button>
            </div>
            <div class="center row mt-5 letras">
                <div class="col">
                    <h3> Técnico: Pedro Paramo Saliz </h3>
                </div>
                <div class="col">
                    <h3>químico: pedro páramo vídal</h3>
                    <h3>cédula: 02903849 </h3>
                </div>
            </div>
        </div>
    </div>
</div>