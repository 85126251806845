<section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">LISTADO DE SERVICIOS POR CLIENTE</h1>
    </div>


</section>

<br>

<section class="row">
    <div class="col-md-8">
        <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
    </div>

</section>


<br>


<div class="center">
    <section>
        <div class="card" style="border-radius: 20px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col-1 text_header">
                        <p>Servicio</p>
                    </div>
                    <div class="col text_header">
                        <p>Precio</p>
                    </div>
                    <div class="col text_header">
                        <p>Fecha</p>
                    </div>
                    <div class="col text_header">
                        <p>Ticket</p>
                    </div>
                    <div class="col text_header">
                        <p>Factura</p>
                    </div>
                </div>
            </div>


            <div class="card-body">
                <div>

                    <div class="col-md-2">
                        <p style="color: #001672;"> Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </section>
</div>