import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';

@Component({
  selector: 'app-endoscopias',
  templateUrl: './endoscopias.component.html',
  styleUrls: ['./endoscopias.component.css']
})
export class EndoscopiasComponent implements OnInit {
  
  public endoscopias = [];
  public pagina = 0;
  public filtroendoscopias = '';

  constructor(
    private EndoscopiasService: AlmacenService
  ) { }

  ngOnInit(): void {
  }

  obtenerEndoscopia(){
    // this.EndoscopiasService.obtenerEndoscopia().
    // subscribe(( data: any ) => {
    //   this.endoscopias = data['data'].results;
    // })
  }
}
