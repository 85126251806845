<div class="container-md">
    <app-titulos
        [title]="'REGISTRAR PATOLOGÍA'"
        [rol]="'patologia'"
    ></app-titulos>
    
    <div class="card">
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ESTUDIOS: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" name="estudios" [(ngModel)]="patologiaData.estudios" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">PRIORIDAD: </label>
                    <div class="col-sm-7">
                        <select class="form-control" [(ngModel)]="patologiaData.prioridad" name="prioridad">
                            <option selected>SELECCION UNA PRIORIDAD </option> 
                            <option value="1"> URGENTE </option>
                            <option value="2"> EN ESPERA </option>
                            <option value="3"> DESPACHADO </option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">STATUS: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="status" name='status'[(ngModel)]="patologiaData.status" >
                    </div>
                </div>

                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">MEDICO QUE SOLICITO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="status" name='status'[(ngModel)]="patologiaData.status" >
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ID PACIENTE: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="idPaciente" readonly name="idpaciente" [(ngModel)]="patologiaData.idPaciente">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">SEDE: </label>
                    <div class="col-sm-7">
                        <select class="form-control" [(ngModel)]="patologiaData.sede" name="sede" >
                            <option selected >SELECCIONA UNA OPCION</option>
                            <option value="1"> TLYC01 </option>
                            <option value="2"> CUAUTLA </option>
                            <option value="3"> CUERNAVACA </option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">FECHA DE EXAMENES: </label>
                    <div class="col-sm-7">
                        <input type="date" class="form-control" id="fechaExamenes" [(ngModel)]="patologiaData.fechaDePedidoDeLosExamenes"  >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ID OBTENIDO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="idPaciente" readonly name="idpaciente" [(ngModel)]="patologiaData.idObtenido">
                    </div>
                </div>
            </div>
        </div>
    
        <div class="d-grid gap-2 col-2 mx-auto mt-5">
            <button type="submit" class="btn btn-primary" [disabled]="btnValidacion" (click)="guardarPatologia()" >REGISTRAR</button>
        </div>
    </div>
</div>
    
