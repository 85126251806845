<div class="container">
    <!-- <h1> CUESTIONARIO DE AUTOEXCLUSIÓN </h1> -->
    <h1> CUESTIONARIO DE PRE-REGISTRO </h1>
    <form #form='ngForm'>
        <div class="card">
            <h3> ESTIMADO DONADOR(A): CON EL FIN DE AGILIZAR SU DONACIÓN, GARANTIZAR SU SEGURIDAD Y LA DE SU PACIENTE, RESPONDA EL SIGUIENTE CUESTIONARIO: <span> ES IMPORTANTE QUE CONTESTE TODAS LAS PREGUNTAS </span>
            </h3>

            <p>¿EN ESTE MOMENTO TRAE IDENTIFICACIÓN OFICIAL VIGENTE CON FOTOGRAFÍA?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntacero" [(ngModel)]='preguntacero'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntacero" [(ngModel)]='preguntacero'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 1.- ¿TIENE 6 HORAS SIN CONSUMIR ALIMENTOS?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntauno" [(ngModel)]='preguntauno'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntauno" [(ngModel)]='preguntauno'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 2.- ¿PESA MÁS DE 50 KILOS?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntados" [(ngModel)]='preguntados'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntados" [(ngModel)]='preguntados'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 3.- ¿ALGUNA VEZ TE DIAGNOSTICARON CON HEPATITIS DESPUES DE LOS 9 AÑOS?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntatres" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntatres" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 4.- ¿HA ESTADO ENFERMO EN LOS ÚLTIMOS 5 DÍAS?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntacuatro" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntacuatro" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 5.- ¿HA TENIDO FIEBRE EN LOS ÚLTIMOS 5 DÍAS?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntacinco" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntacinco" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 6.- ¿TE HAS REALIZADO UN TATUAJE EN LOS ÚLTIMOS 6 MESES?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntaseis" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntaseis" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 7.- ¿TU DENTISTA TE HA REALIZADO UNA ENDODONCÍA EN LOS ÚLTIMOS 6 MESES?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntasiete" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntasiete" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 8.- ¿LE HAN HECHO PERFORACIONES EN ALGUNA PARTE DEL CUERPO O ACUPUNTURA?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntaocho" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntaocho" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 9.- EN LOS ULTIMOS 14 DÍAS ¿HA TENIDO GRIPE, TOS SECA, CATARRO, TOS CON FLEMAS O FIEBRE, DIARREA, INFECCIONES DENTALES?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntanueve" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntanueve" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 10.- EN LOS ÚLTIMOS DÍAS ¿HA TENIDO O HA ESTADO CON PERSONAS CONFIRMADOS O SOSPECHOSOS COVID-19?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntadiez" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntadiez" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 11.- EN LOS ÚLTIMOS 5 DÍAS ¿HA TOMADO MEDICAMENTO PARA LA TOS, FIEBRE, ASPIRINAS, ANTIBIÓTICOS ENTRE OTROS?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntaonce" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntaonce" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <p> 12.- ¿EN LOS ÚLTIMOS 6 MESES LE HAN REALIZADO UNA CIRUGÍA?</p>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="SI" name="preguntadoce" ngModel>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" value="NO" name="preguntadoce" [(ngModel)]='ngNop'>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                </div>
            </div>

            <div class="center">
                <button class="principalButton" data-toggle="modal" data-target="#preregistro" (click)="autoexclusion(form)"> Agregar </button>
            </div>
        </div>
    </form>


    <div class="modal fade" id="preregistro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    EL RESULTADO DEL CUESTIONARIO ES {{respuesta.respuestas}}/12
                </div>
                <div class="modal-body">
                    <p *ngIf="respuesta.diferir == false" id="sipuede">EL DISPONENTE PUEDE CONTINUAR CON EL PROCESO</p>
                    <!-- <p> ¿PUEDE EL DONADOR CONTINUAR CON EL PROCESO DE DONACIÓN? </p>
                    <div class="form-group" id="diferir">
                        <div class="form-check form-check-inline">
                            <input type="radio" value="SI" name="diferir" ngModel>
                            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" value="NO" name="diferir" ngModel>
                            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                        </div>
                    </div>-->
                    <p *ngIf="respuesta.diferir == true" id="nopuede">EL DISPONENTE NO PUEDE CONTINUAR CON EL PROCESO</p>
                    <textarea *ngIf="respuesta.diferir == true" name="motivo" id="motivo" cols="30" rows="10" placeholder="COMENTARIOS">{{respuesta.diferido[0].motivo}}</textarea>
                    <div class="center">
                        <button class="principalButton" (click)="enviar(respuesta)" data-dismiss="modal"> ACEPTAR </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="display-none" id="sppiner-section">
        <div class="row">
            <div class="sppiner-bg">
                <div class="spinner-stock">
                    <div class="col-md-12">
                        <app-sppiner></app-sppiner>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>