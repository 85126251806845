import { Component, OnInit } from '@angular/core';
import PacienteStorage from '../../../classes/pacientes/pacientesStorage.class';
@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {


  /* public paciente = {

    nombrePaciente:'',
    apellidoPaterno:'',
    apellidoMaterno: '',
    edad:0,
    genero:'',
    direccion:{
      callePaciente:""
    },
    idPaciente:'',
    membresiaHabilitada:false
  }
 */


  

  constructor(  ) { }

  ngOnInit(): void {
    /* this.obtenerPacienteStorage(); */

  }


  /* obtenerPacienteStorage(){

    const pacienteStorage = new PacienteStorage();
    this.paciente = pacienteStorage.verPacienteConMembresia();
    console.log( this.paciente );
  } */
}
interface items {

  nombreEstudio : string
  precioCon: string
  precioSin: string
  idEstudio: string
  name: string
}
interface paciente {
  nombrePaciente: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  edad: number;
  genero: string;
  callePaciente: string;
  _id:string

}


// ESTE CODIGO SE USO PARA LA COTIZACION DE LA MEMBRESÍA 

//  else if(  item.nombreEstudio === this.membresiaData.nombreEstudio && item.idEstudio  === id  ){
      //     // this.ahorro  = this.ahorro + this.membresiaData.precioCon;
      //     let precioCon = parseFloat( this.membresiaData.precioCon  );

      //     this.ahorro = this.ahorro + precioCon;
      //  }