import * as io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
}) 
export class WsLoginService {

  public status:boolean = false;
  private socket;
  private _idUser;
  public audio = new Audio('../../../../assets/sound/chat/mensajes_iphone.mp3');
  private _idUser2: any;
  roleUser: any;
  room: any;
  public nombre:any;

  constructor()  {

    this.socket = io( URL );


  }

  checkStatus( usuarios ){

    this.socket.emit('connect', (usuarios)=> {
      this.status = true;
    });

  }


  login( usuario ) {
 
    this._idUser = usuario._id;
    this.roleUser = usuario.role;
    this.nombre = usuario.nombre;
    this.socket.emit('usuarioConectado', {Id: this._idUser, role: this.roleUser, nombre: this.nombre});


    // esta linea nos ayuda con las consultas generales
    // this.enviarConsultas();


    this.socket.on('event', (data) => {
      // console.log( data );
    })

  } 

  mostarUsuario(){
     return Observable.create((observer) => {
      this.socket.on('usuarioEnLinea', (data) => {
        observer.next( data);
        })
      })
    }

    notificarDoctor(data){

      this.socket.emit('enfermeria2Doctor', { paciente: data })
    }
    // prueba de consultas generales
    enviarConsultas(idConsulta){

      this.socket.emit('consultaGeneral',  { consulta: idConsulta }  );

    }


    public escucharBitacoraDoctor(){
      return Observable.create((observer) => {
        this.socket.on('recibirPaciente', (data) => {
          observer.next( data);
        })
      });
    }

    public escucharConsulta(){

      return Observable.create(
        (observer) => {
          this.socket.on('consultaNueva', (resp) => {
            // console.log( resp);
            observer.next( resp );
            });
        });
    }


    public escucharMensajes(){

      this.socket.on('MsgLaboratorio', response => {

      });

      return Observable.create(
        (observer) => {
          this.socket.on('mensajeLaboratorio', (resp) => {
            // console.log( resp );
            observer.next(  resp);
          })
        }
        )

      }

      // este observable escucha a todos los usuarios conectados

      escucahrUsuaurtioConectados(){


        return  Observable.create(

          (observer) => {

            this.socket.on('usuarioEnLinea', (resp) => {

              // console.log( resp );

                observer.next( resp );


            })
          })

      }


    notificacionAudio(){

      this.audio.load();
      this.audio.play();

    }


    escucharMensajesLab(){

      return Observable.create(
        (observer) => {
          this.socket.emit('mensajeLab', (resp) => {



            if( !resp.payload.horaEnvio  ){
              this.notificacionAudio();

            }


            /* console.log("Mensaje lab" ,resp ); */
            observer.next( resp );
          })
        }
        )
    }


    desconectarUsuario( role ){
      this.socket.emit('cerrarSesion', { role });
      this.socket.emit('disconnect', function () {
      })
    }

    adios(){
    this.socket.emit('disconnect', function () {
      
    })

  }

    escucharUsuarioDesconectado(){

      return Observable.create(
        (observer) => {

          this.socket.on('personaEliminada', (resp:any) => {
            observer.next( resp.userDisconect );
          })
        }
        )
      }



      escucahrNuevoMensajes() {
        return Observable.create(
          (observable) => {
            this.socket.on('crearMensaje', (data) => {
              observable.next( data  );
            })
          }
        )
      }



      enviarMensaje(data){
        /* console.log(this._idUser2); */
        
     var chat = this.roleUser + "_" + this._idUser2;
     
    //this.socket.emit('entrarChatPrivado',{mensaje:data, room:chat, role1:this.roleUser, role2:this._idUser2 });

    this.socket.emit('enviarMensajePrivado', {  mensaje:data, room:this.room, role1:this.nombre, role2:this._idUser2, idUser: this._idUser2 })


  }


  // enviarMensajePrivado(data) {

  //   var chat = this._idUser + "_" + this._idUser2;
  //   this.socket.emit('entrarChatPrivado',{
  //   mensaje:data, room:chat, role1:this._idUser, role2:this._idUser2 });


  // }

  regresarUsuaurios( user  ){
    this._idUser2 = user;
    var _arrayP = [];
    _arrayP.push(this.nombre)
    _arrayP.push(this._idUser2)
    this.room = _arrayP.sort().join('_');
    //var chat = this.roleUser + "_" + this._idUser2;
    this.socket.emit('regresarId', { user  } );
    this.socket.emit('entrarChatPrivado',{room:this.room, role1:this.nombre, role2:this._idUser2 });
  }
}
