import { Component, OnInit } from '@angular/core';

import { ServiciosAlmacenService } from 'src/app/services/servicios-almacen/servicios-almacen.service';

@Component({
  selector: 'app-lista-servicios',
  templateUrl: './lista-servicios.component.html',
  styleUrls: ['./lista-servicios.component.css']
})
export class ListaServiciosComponent implements OnInit {
  
  constructor( private servicioAlmace: ServiciosAlmacenService ) { }
  
  public servicios = [];
  public aux = [];
  public pagina = 0
  public totalservices:string
  ngOnInit(): void {
    // this.obtenerServicios();
    this.serviciosDepartamentos();
  }

  serviciosDepartamentos(){
    this.servicioAlmace.obtenerServiciosDepartamento().subscribe(resp=>{
      console.log(resp);
      // this.servicios = resp['data']
      // this.totalservices = resp['data'].results
    })
  }

  obtenerServicios(){
    this.servicioAlmace.obtenerServicios().subscribe((data:any) =>{ 
      this.servicios = data.data
      this.totalservices = data.data.results
    });
  }

  filterServicesByName( searchNameService ) {
    const listaFilter = [];
    if( searchNameService.value.length > 3) {
      const { value } = searchNameService;
      value.trim();
      this.servicios.forEach( service  => (service.nombre === value.toUpperCase() ? listaFilter.push(service) : null ));
      this.servicios = listaFilter;
    } else {
      this.obtenerServicios();
    }
  }
}
