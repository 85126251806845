<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE DISPONENTES'" [rol]="'pacientes'"></app-titulos>
    <!-- <h1 class="centerxd">LISTADO DE DISPONENTES</h1> -->
    <div class="row">
        <div class="col-md-9">
            <input type="text" name="filterPost" placeholder="Buscar Disponente" [(ngModel)]="filtropacientes" class="form-control" id="busquedaPaciente" (keyup)="typeSearchPatients()">
        </div>
        <div class="col-md-3">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/registro/disponentes']">AGREGAR</a>
        </div>
    </div>

    <div class="row bitacora">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-4 text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Género</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Teléfono</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Servicios</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let paciente of pacientes2 | paginate:{id:'listapacientes',
                    itemsPerPage: 5,
                    currentPage:pagina,
                    totalItems:totalpaciente}">
                        <div class="row pointer puntero" [routerLink]="['/ver/disponente', paciente._id]">
                            <div class="col-md-4">
                                <p>
                                    {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }}
                                </p> 
                                <span class="puntero"></span>
                            </div>
                            <div class="col-md-2">
                                <p>
                                    {{paciente.genero | uppercase}}
                                </p>
                            </div>
                            <div class="col-md-2">
                                <p>
                                    {{paciente.edad}}
                                </p>
                            </div>
                            <div class="col-md-2">
                                <p>
                                    {{paciente.telefono}}
                                </p>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <a [routerLink]="['/ver/disponente', paciente._id]">
                                                <i class="fa fa-eye eyeS" id="ver"></i>
                                            </a>
                                            <!-- <a [routerLink]="['/ver/disponente', paciente._id]">Ver...</a> <br> -->
                                        </p>
                                    </div>
                                    <div class="col" *ngIf="rol == 'recepcion-censur'">
                                        <div class="col">
                                            <i class="fas fa-edit" id="ver" [routerLink]="['/actualizar/fi/pacientes/', paciente._id]"></i>   
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>