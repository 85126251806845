import { Component, OnInit } from '@angular/core';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterNameByPaciente } from 'src/app/classes/helpers/filterNamePaciente';

@Component({
  selector: 'app-bitacora-disponentes',
  templateUrl: './bitacora-disponentes.component.html',
  styleUrls: ['./bitacora-disponentes.component.css']
})
export class BitacoraDisponentesComponent implements OnInit {

  public pacientes2:any[] = [];
  public totalpaciente:string;
  public pagina = 0;
  public  filtropacientes = '';
  public filterPost = '';
  public pacientes =  [];
  private unsubcribe$ = new Subject<void>();
  public rol = '';

  constructor(private _pacienteService: PacientesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.obtenerPacientes();
    this.focusSearchPatients();
    this.ObtenerRole();
  }

  ObtenerRole(){
    this.rol = JSON.parse(localStorage.getItem('usuario')).role;
  }

  obtenerPacientes() {
    this.spinner.show();
    this.pacientes2 = [];
    this._pacienteService.getPacientesAll()
    .subscribe( (data: any) => {
      if( data['message']   === 'No hay pacientes' ) {
        Swal.fire({
              icon: 'warning',
              title: '',
              text: 'AUN NO HAY PACIENTES',
            })
        return;
      }
      this.pacientes = data.data;
       this.pacientes2 = data.data;
       this.totalpaciente = data.data.results;
      //  this.pacientes2.reverse()
       this.spinner.hide();
    });
  }

  typeSearchPatients(){
    if(this.filtropacientes.length >= 3){
      this._pacienteService.buscarPacientesPorNombreBackend( {nombre: this.filtropacientes} )
      .subscribe( (data: any) => this.pacientes2 = data.data);
    }else{
      this.pacientes2 = this.pacientes;
    }
    // if(this.filtropacientes.length != 0){
    //   if(this.filtropacientes.length > 3){
    //     this.pacientes2 = filterNameByPaciente(this.pacientes2, this.filtropacientes);
    //   }
    // }else{
    //   this.obtenerPacientes();
    // }
  }

  focusSearchPatients(){
    const input: HTMLInputElement = document.querySelector('#busquedaPaciente');
    input.select();
  }


  paginaAnterior(){
    this.pagina = this.pagina - 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      this.pacientes2 = data.users;
    })
  }


  siguentePagina(){
    this.pagina += 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe((data: any) => {
      this.pacientes2= data.users;
    })
  }

  ngOnDestroy(){
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }
}
