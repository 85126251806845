import { Component, Input, OnInit } from '@angular/core';
import { calcAge } from 'src/app/classes/helpers/filterNamePaciente';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import  jsPDF from  "jspdf" ;
import * as moment from 'moment';

@Component({
  selector: 'app-labs-regresos',
  templateUrl: './labs-regresos.component.html',
  styleUrls: ['./labs-regresos.component.css']
})
export class LabsRegresosComponent implements OnInit {

  @Input() id = '';
  public paciente = {
    apellidoMaterno: '',
    apellidoPaterno: '',
    callePaciente: '',
    consultas:'' ,
    contactoEmergencia1ApellidoMaterno: '',
    contactoEmergencia1ApellidoPaterno: '',
    contactoEmergencia1Curp: "",
    contactoEmergencia1Edad: "",
    contactoEmergencia1Genero: "",
    contactoEmergencia1Nombre: "",
    contactoEmergencia1Telefono: "",
    contactoEmergencia2ApellidoMaterno: "",
    contactoEmergencia2ApellidoPaterno: "",
    contactoEmergencia2Curp: "",
    contactoEmergencia2Edad: "",
    contactoEmergencia2Genero: "",
    contactoEmergencia2Nombre: "",
    contactoEmergencia2Telefono: "",
    correoPaciente: "",
    correoRazonSocial2: "",
    cpPaciente: '',
    cpRazonSocial: "",
    cpRazonSocial2: "",
    curp: "",
    edad: '',
    estadoPaciente: "",
    familia: [],
    fechaNacimientoPaciente: "",
    fechaRegistro: "",
    genero: "",
    membresiaActiva: '',
    nombrePaciente: "",
    nomenclaturaRegistro: "",
    paisNacimiento: "",
    paisPaciente: "",
    paquetes: [],
    paquetesPacientes: [],
    razonSocial1: "",
    razonSocial1Calle: "",
    razonSocial1Estado: "",
    razonSocial1Municipio: "",
    razonSocial2: "",
    razonSocial2Calle: "",
    razonSocial2Estado: "",
    razonSocial2Municipio: "",
    razoncocial1RFC: "",
    razoncocial2RFC: "",
    status: "",
    telefono: '',
    _id: ''
  }
  public metodo: any;
  public nombre: any;
  public examen: any;
  public valoresRef: any;
  public venta: any;
  public servicio: any;
  public resultados: any;
  public imgQrCode: any;
  public servicioValid = "";
  public curp = "";
  public solicitio2: any;
  public fechaPedidoLabs = "";
  public tomaxD : any;
  public liberacionxD : any;
  public muestraxD : any;
  public observacionesxD? : any;
  public realizoxD : any;
  public obtenidosFinalesHTML:any[] = [];
  public pedidoEstudio :any [] = [];
  public horaTomaPdf : any;
  public fechaToma = '';
  public horaLiberar : any;
  public fechaLiberacion = '';
  public imprimir = {
    grupo: '',
    elementos: '',
    obtenidos: '',
    unidad: '',
    valoresReferencia: ''
  }
  public noSe = [];
  public horaImpresion = moment().format('LT');

  constructor(private _serviciosService: ServiciosService, 
              private _paciente: PacientesService,) { }

  ngOnInit(): void {
    this.obtenerResultadoPorEstudio();
  }

  obtenerResultadoPorEstudio(){
    if(this.id != undefined){
      this._serviciosService.getResultadoPorEstudio(this.id)
      .subscribe((data) => {
        this.paciente = data['data']['paciente']
        this.metodo = data['data']['metodo']
        this.nombre = data['data']['nombre']
        this.examen = data ['data']['tipo_de_examen']
        this.valoresRef = data ['data']['valoresDeReferencia']
        this.venta = data ['data']['venta']
        this.servicio = data['data']['servicio']
        this.imgQrCode = data['data']['imgQrCode']
        this.servicioValid = data['data']['nombreEstudio']
        this.curp = data['data']['paciente']['curp']
        this.solicitio2 = data['data']['solicito'];
        this.fechaPedidoLabs = data['data']['fechaPedido'];
        // console.log( this.servicioValid )
        // comparamos el nombre del servicio 
        if( this.servicioValid == 'LABORATORIO' ) {
          this.resultados = data['data']['resultados'][0].obtenidos
          this.tomaxD = data['data']['resultados'][0].toma
          this.liberacionxD = data['data']['resultados'][0].liberacion
          this.muestraxD = data['data']['resultados'][0].muestra
          this.observacionesxD = data['data']['resultados'][0].observaciones
          this.realizoxD = data['data']['resultados'][0].realizo
          //  si es de laboratorio
          for (const key in this.resultados) {
            this.obtenidosFinalesHTML.push(this.resultados[key])
          }
          this.pedidoEstudio = data['data']
          this.setEstudios(this.valoresRef, this.obtenidosFinalesHTML);
          this.horaTomaPdf = this.tomaxD.slice(11, 17);
          this.fechaToma = this.tomaxD.slice(0, 10);
          // console.log(horafea);
          // this.horaTomaPdf = horafea;
          this.horaLiberar = this.liberacionxD.slice(11, 17)
          this.fechaLiberacion = this.liberacionxD.slice(0, 10)
        }
        if( this.paciente.fechaNacimientoPaciente == null  ){
          const updateNewDate = calcAge( this.curp );
          const data1 = { 
            fechaNacimientoPaciente: updateNewDate
          }
          this._paciente.actualizarFiPacientes( this.paciente._id, data1 )
          .subscribe( data => {})
        }
      })

    }
  }

  setEstudios(data, obtenidosFinalesHTML) {
    
    let i= 0;
    for (let element of data ) {    
      this.imprimir.grupo = element.grupo;
      this.imprimir.elementos = element.elemento;
      
      
       this.imprimir.obtenidos= obtenidosFinalesHTML[i];

      this.imprimir.unidad = element.unidad;
      let val = ""
      element.valorDeReferencia.forEach(elem => {
        val = val.concat(elem.valorReferenciaD, '\n')
        // console.log(elem);
      });
      this.imprimir.valoresReferencia = val

      this.noSe.push(this.imprimir)
      this.imprimir = {
        grupo: '',
        elementos: '',
        obtenidos: '',
        unidad: '',
        valoresReferencia: ''
      }
      i++
      val = ""
    }
    // this.noSe.forEach((element:any)=>{
    //   let array = element.valoresReferencia.split('\n')
    //   if(array.length == 4){
    //     let valor1;
    //     let array3 = element.valoresReferencia.split('\n')
    //     array3.forEach(ele => {
    //       if(ele != ''){
    //         if(ele.includes('Óptimo ')){
    //           valor1 = ele.slice(8).trim();
    //           if(element.obtenidos < parseFloat(valor1)){
    //             element.valoresReferencia = ele
    //           }
    //         }else if(ele.includes('Riesgo ')){
    //           if(ele.includes('Alto Riesgo ') || ele.includes('Riesgo Alto ')  ){
    //             if(ele.slice(12).includes('>')){
    //               if(element.obtenidos > parseFloat(ele.slice(13).trim())){
    //                 element.valoresReferencia = ele
    //               }
    //             }else{
    //               let x = ele.slice(12).split(' ') 
    //               let valorMin = x[0]
    //               let valorMax = x[2]
    //               if(element.obtenidos > parseFloat(valorMin) && element.obtenidos < parseFloat(valorMax)){
    //                 element.valoresReferencia = ele
    //               }
    //             }
    //           }else{            
    //             let x = ele.slice(7).split(' ');
    //             let valorMin = x[0]
    //             let valorMax = x[2]
    //             if(element.obtenidos > parseFloat(valorMin) && element.obtenidos < parseFloat(valorMax)){
    //               element.valoresReferencia = ele
    //             }
    //           }
    //         }
    //       }
    //     });
    //   }else{
    //     let array2 = element.valoresReferencia.split('\n')
    //     if(this.paciente.genero == 'MASCULINO'){
    //       array2.forEach((elemen) => {
    //         let val = 'hombres'
    //         let cadena = elemen.toLowerCase()
    //         if(cadena.includes(val)){
    //           element.valoresReferencia = elemen.toUpperCase();
    //         }else{
    //           if(!cadena.includes('mujeres')){
    //             if(cadena != ''){
    //               let val = cadena.split('-')
    //               if(element.obtendios > parseFloat(val[0]) && element.obtendios < parseFloat(val[1])){
    //                 element.obtenidos = element.obtenidos.concat('*')
    //               }
    //             }
    //           }
    //         }
    //       });
    //     }else if(this.paciente.genero == 'FEMENINO'){
    //       array.forEach(elemen => {
    //         let val = 'mujeres'
    //         let cadena = elemen.toLowerCase()
    //         if(cadena.includes(val)){
    //           element.valoresReferencia = elemen.toUpperCase();            
    //         }else{
    //           if(!cadena.includes('hombres')){
    //             if(cadena != ''){
    //               let val = cadena.split('-')
    //               if(element.obtendios > parseFloat(val[0]) && element.obtendios < parseFloat(val[1])){
    //                 element.obtenidos = element.obtenidos.concat('*')
    //               }
    //             }
    //           }
    //         }
    //       });
    //     }
    //   }
    // })

    // this.noSe.forEach((element)=>{
    //   if(element.valoresReferencia.includes('HOMBRES')){
    //     let valor = element.valoresReferencia.slice(7);
    //     let array = valor.split('-')
    //     let valor1 = array[0];
    //     let valor2 = array[1];
    //     if(element.obtenidos > parseFloat(valor1) || element.obtenidos < parseFloat(valor2)){
    //       element.obtenidos = element.obtenidos.concat('*')
    //     }
    //   }else if(element.valoresReferencia.includes('MUJERES')){
    //     let valor = element.valoresReferencia.slice(8);
    //     let array = valor.split('-')
    //     if(array.length >= 2){
    //       let valor1 = array[0];
    //       let valor2 = array[1];
    //       if(element.obtenidos < parseFloat(valor1) || element.obtenidos > parseFloat(valor2)){
    //         element.obtenidos = element.obtenidos.concat('*')
    //       }
    //     }else{
    //       let array = valor.split(' ')
    //       let valor1 = array[0];
    //       let valor2 = array[2];
    //       if(element.obtenidos < parseFloat(valor1) || element.obtenidos > parseFloat(valor2)){
    //         element.obtenidos = element.obtenidos.concat('*')
    //       }
    //     }
    //   }else{
    //     if(element.valoresReferencia.includes('Riesgo') || element.valoresReferencia.includes('Óptimo')){
    //       if(element.valoresReferencia.includes('Alto Riesgo ') || element.valoresReferencia.includes('Riesgo Alto ')){
    //         if(element.valoresReferencia.slice(12).includes('>')){
    //           if(element.obtenidos > parseFloat(element.valoresReferencia.slice(13).trim())){
    //             element.obtenidos = element.obtenidos.concat('*')
    //           }
    //         }else{
    //           let x = element.valoresReferencia.slice(12).split(' ')
    //           let valorMin = x[0]
    //           let valorMax = x[2]
    //           if(element.obtenidos > parseFloat(valorMin) || element.obtenidos < parseFloat(valorMax)){
    //             element.obtenidos = element.obtenidos.concat('*')
    //           }
    //         }
    //       }else{
    //         let x = element.valoresReferencia.slice(7).split(' ');
    //         let valorMin = x[0]
    //         let valorMax = x[2]
    //         if(element.obtenidos > parseFloat(valorMin) || element.obtenidos < parseFloat(valorMax)){
    //           element.obtenidos = element.obtenidos.concat('*')
    //         }
    //       }
    //     }else{
    //       let array = element.valoresReferencia.split('-')
    //       if(array.length == 1){
    //         let y = array[0].split(' ');
    //         if(y.length == 1){
    //           let valor1 = y[0];
    //           if(element.obtenidos > parseFloat(valor1)){
    //             element.obtenidos = element.obtenidos.concat('*')
    //           }
    //         }else{
    //           let z = array[0].split(' ');
    //           if(z.length == 3){
    //             let valorMin = z[0]
    //             let valorMax = z[2]
    //             if(element.obtenidos < parseFloat(valorMin) || element.obtenidos > parseFloat(valorMax)){
    //               element.obtenidos = element.obtenidos.concat('*')
    //             }
    //           }else{
    //             let valorMin = z[0].slice(0,-2)
    //             let valorMax = z[1]
    //             if(element.obtenidos < parseFloat(valorMin) || element.obtenidos > parseFloat(valorMax)){
    //               element.obtenidos = element.obtenidos.concat('*')
    //             }
    //           }
    //         }
    //       }else{
    //         let valor1 = parseFloat(array[0].trim());
    //         let valor2 = parseFloat(array[1].trim());
    //         if(element.obtenidos < valor1 || element.obtenidos > valor2){
    //           element.obtenidos = element.obtenidos.concat('*')
    //         }
            
    //       }
    //     }
    //   }
    // })


    /********
     *   : Se realizó estudio ultrasonográfico de abdomen superior con un equipo de imagen digital  con tiempo real utilizando transductor convexo abdominal multifrecuencia , estando el paciente en decúbito dorsal y decúbito lateral izquierdo.
	El hígado de forma, tamaño y situación normal, con diámetro longitudinal del lóbulo derecho de   cm, de bordes lisos y regulares, el parénquima es de ecos de intensidad media homogéneos. Su vascularidad  y vías biliares internas normales. 

	La vesícula biliar se encuentra en la foseta cística de bordes regulares y lisos, sin edema de la pared, siendo su espesor de   cm, con diámetros de cm  en longitudinal,  anteroposterior y transversal, con un volumen de   ml, con presencia en su interior de bilis líquida

	El conducto colédoco es uniforme, sin datos de dilatación siendo su diámetro de   cm.
  La vena porta en su rama principal es uniforme, con un diámetro de   cm, sin datos de hipertensión (valor normal hasta 1.30 cm., valores mayores son predictivos de hipertensión portal).  
  Los  trayectos vasculares de vena cava inferior y aorta se encuentran normales.
    El páncreas es de bordes lisos y regulares, el parénquima es de ecos de intensidad media homogéneos. Mide     cm en cabeza, cuerpo y cola respectivamente.

    El riñón derecho es de forma, tamaño y situación normal de bordes lisos y regulares, conserva normal la relación entre la corteza y la médula y el seno renal es ecogénico central, sin datos de ectasia, litos o reflujo aparentes. Mide   cm en sus diámetros longitudinal, anteroposterior y transversal respectivamente. 

    Los trayectos de colon muestran cantidad y distribución normal del gas, sin haber colecciones de líquido en las correderas parietocólicas. .

  IDX: 
  1.	
  No se presentó ningún incidente  ni accidente durante la consulta.
  FERNANDO MEJÍA RUÍZ
  CED.PROF. 10156497
      */
  }

  testLabsPDF(){

    const SESION =  JSON.parse(localStorage.getItem('usuario')).role;
    const patient = this.paciente;
    const HORA_TOMA = this.fechaToma.concat(', ' + this.horaTomaPdf);
    const HORA_LIBERACION =  this.fechaLiberacion.concat(', ' + this.horaLiberar);
    const HORA_IMPRESION = this.horaImpresion;
    const TIPO_MUESTRA = this.muestraxD;
    const REALIZO = this.realizoxD;
    const NOMBRE_ESTUDIO = this.nombre;
    const OBSERVACIONES = this.observacionesxD;
    const METODO = this.metodo;
    const DRSOLICITA = this.solicitio2
    const FECHAPEDIDOLABS = this.fechaPedidoLabs;
    const fechaPedidoCut = FECHAPEDIDOLABS.split('T')[0];

  
    

    let imgLabs = '../../../../../assets/images/hero_c.png';
    let fooLabs = '../../../../../assets/images/lineas-bs.png';
    let piensaHero = '../../../../../assets/images/piensa-l.png';
    let firmaDoc = '../../../../../assets/images/firma_doc.png';
    let marca = '../../../../../assets/images/marca_d.png';
    let qrCode = this.imgQrCode;
    
      const doc:any = new jsPDF({
      unit: "cm",
      format: 'a4',
      });

      let values: any;
      values = this.noSe.map((elemento) => Object.values(elemento));
      doc.autoTable({
        margin: {top:6.7 ,bottom: 6},
        theme: 'plain',
        head: [[' ', 'Elementos', 'Resultados', 'Unidades', 'Intervalo Biologico de Referencia']],
        body: values,
        didDrawCell: (values) => {
          if(values.section == 'body' && values.column.index == 0 ){
              // console.log(values);
              if (values.cell.height > 1.2) {
                    // console.log("entró");
                    values.cell.height = 1.4
                    values.row.height = 1.4
              }else if(values.cell.height < 1.2) {
                    values.cell.height = 0.77
                    values.row.height =0.77
              }
          }
        },
        didDrawPage: function(paciente) {
          doc.addImage(marca, 'PNG', 1.5, 12, 17.5, 7);
          if (qrCode == null || qrCode == undefined || qrCode == '' ) {
            // return
            doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
            doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
            doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
            doc.addImage(piensaHero, 'PNG', 17, 24.5, 3, 1);
          } else {
            doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
            doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
            doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
            doc.addImage(piensaHero, 'PNG', 17, 25, 3, 1);
            doc.addImage(qrCode, 'PNG', 16, 1.3, 2.5, 2.5);
          }
          doc.addImage(imgLabs, 'PNG', 1, 1.5, 4, 2);
          doc.addImage(fooLabs, 'PNG', 1, 26.8, 19.5, 0.4);
          doc.addImage(firmaDoc, 'PNG', 15, 23.5, 3, 4);
          doc.addImage(piensaHero, 'PNG', 17, 25, 3, 1);
          doc.setTextColor(0, 0, 255);
          doc.setFontSize(8);
          doc.setFont('helvetica')
          // doc.setFontType('bold')
          doc.text('PACIENTE:', 1, 4)
          doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 2.6, 4)
          doc.text('EDAD: ', 1, 4.4);
          //SETEAT EDAD
          doc.text(patient.edad + ' AÑOS', 2.6, 4.4);
          doc.text('GÉNERO: ', 1, 4.8);
          doc.text(patient.genero, 2.6, 4.8);
          doc.text('FECHA DE NACIMIENTO: ', 1, 5.2);
          // Setear Fecha paciente
          const nacimiento = String(patient.fechaNacimientoPaciente)
          if (nacimiento == 'undefined' || nacimiento == '') {
            doc.text('-- / --- / ----', 4.5, 5.2);
          }else {
            let fechaNacimiento;
            fechaNacimiento = nacimiento.slice(0, -14)
            doc.text(fechaNacimiento, 4.5, 5.2);
          }
          doc.text('SOLICITANTE: ', 1, 5.6);
          if (DRSOLICITA == undefined || DRSOLICITA == null || DRSOLICITA == '') {
            doc.text('A quien corresponda', 3.1, 5.6);
          }else{
            doc.text(DRSOLICITA, 3.1, 5.6);
          }
          doc.text('FECHA PEDIDO: ', 1, 6)
          doc.text(fechaPedidoCut, 3.3, 6);

           //Fecha y horas de muestras
           doc.text('FECHA Y HORA DE TOMA:', 12.7, 4)
           doc.text(HORA_TOMA, 16.4, 4)
           doc.text('FECHA Y HORA DE LIBERACIÓN:',12.7, 4.4)
           doc.text(HORA_LIBERACION, 17.3, 4.4)
           doc.text('HORA DE IMPRESIÓN:', 12.7, 4.8)
           doc.text(HORA_IMPRESION, 15.8, 4.8)
           doc.text('TIPO DE MUESTRA:', 12.7, 5.2)
           doc.text(TIPO_MUESTRA, 15.5, 5.2)
           doc.text('REALIZÓ: ', 12.7, 5.6)
           if (REALIZO == undefined || REALIZO == null || REALIZO == '') {
            doc.text('---- ---- ----', 14.1, 5.6)
           }else{

             doc.text('TPQC.'+ REALIZO.toUpperCase(), 14.1, 5.6)
           }
          // Nombre del estudio
            doc.setTextColor(255, 0, 0)
            doc.setFontSize(12);
            /* doc.setFontType(undefined, 'bold') */
            doc.text(NOMBRE_ESTUDIO, 7, 6.1)
            doc.addImage(fooLabs, 'PNG', 1, 6.2, 19.5, 0.5)
            doc.setTextColor(0, 0, 255);
            //Footer
            doc.setFontSize(10);
            doc.setFont('helvetica')
            doc.text('Observaciones:', 1, 24)
            if (OBSERVACIONES == undefined || OBSERVACIONES == null  || OBSERVACIONES == '' ) {
              
            }else {
              doc.setFontSize(8);
              doc.text(OBSERVACIONES, 1, 24.4)
            }
            doc.setFontSize(10);
            doc.text('Método:', 4.5, 25.4);
            doc.setFontSize(8);
            doc.text(METODO, 6, 25.4)
            doc.setFontSize(8);
            doc.text('*NOTA: Apreciado paciente, gracias por su confianza, le recordamos que el resultado que le estamos', 1, 26.4)
            doc.text('entregando, solamente puede ser evaluado e interpretado por su médico tratante.', 1, 26.7)
            doc.setFontSize(10);
            doc.text('Liberó: Dr. Alberto Zamora Palma', 14.5, 26.2);
            doc.text('Cédula: 3279016', 15.5, 26.7);
        }       
      });
      
      // if (SESION != 'laboratorio') {
        // doc.save(this.nombre + '_' + this.paciente.nombrePaciente + '_' + this.paciente.apellidoPaterno + '_' + this.paciente.apellidoMaterno + '_' + this.horaImpresion +'.pdf');
      // }else {

        window.open(doc.output('bloburl', '_blank'));
      // }    
  }

}
