<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="'BITACORA DE CONSULTA EXTERNA'"></app-titulos>
        </div>
        <div class="col">
            <button class="boton" [routerLink]="['/historico/consulta/general']"> HISTORIAL DE CONSULTAS </button>
            <button class="principalButton" (click)="imprimirBitacora()"> IMPRIMIR </button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>HORA</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL PACIENTE</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>DIANOSTICO</p>
                        </div>
                        <div class="col text_header">
                            <p>CURP</p>
                        </div>
                        <div class="col text_header">
                            <p>ENFERMERÍA</p>
                        </div>
                        
                        <div class="col text_header">
                            <p>OBSERVACIONES</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of consultas| paginate:{id:'listapacientes',
                    itemsPerPage: 5,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer sombra" (click)="cambiarEstado(item._id, item.paciente.consultas)">
                            <div class="col-md-1">
                                <p>{{ i +1}}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.fechaIngreso }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.horaIngreso }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ item.paciente.edad }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.paciente.genero | uppercase }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.diagnosticoActual | uppercase }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.paciente.curp | uppercase }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.enfermeraAtendio | uppercase }}</p>
                            </div>
                            <!-- <div class="col">
                                <p>{{ item.doctorAPasar | uppercase }}</p>
                            </div> -->
                            <div class="col">
                                <p>{{ item.motivoIngreso | uppercase }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>