import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-hoja-servicios-lab-disponente',
  templateUrl: './hoja-servicios-lab-disponente.component.html',
  styleUrls: ['./hoja-servicios-lab-disponente.component.css']
})
export class HojaServiciosLabDisponenteComponent implements OnInit {

   public paciente={
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
    religion:''
  }
  public idCensur;
  public idprocesos = {
    idbancodesangre: ''
  }
  public servicio = [];
  public servicios=[];
  public procesos = [];

  constructor( private activatedRoute:ActivatedRoute,
              private _banco:BancoService,
              private _router: Router) { }

  ngOnInit(): void {
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    this.getHojaDeServicios(this.idCensur);
  }

getProcesoServicios( id ){
      this.idprocesos.idbancodesangre = id
      this._banco.getProcesosLab(this.idprocesos).subscribe((resp:any)=>{
        const procesos = resp.data
        this.setear_arreglo_proceso(procesos)
      })
}


  getHojaDeServicios( id ) {
    this._banco.getHojaDeServicioLab(id).subscribe((resp:any)=>{
      this.idprocesos.idbancodesangre = this.idCensur

      this.paciente = resp.data.paciente
      this.servicio = resp.data.laboratorio[0].pedido
      //this.postOrdenLaboratorios(this.idCensur)
      this.getProcesoServicios(this.idCensur)
      /* this.generarArreglo(this.servicio) */
      /* console.log("servicios :", this.servicio) */ 
      /* console.log(this.paciente) */
    })
  }

  postOrdenLaboratorios( id ){
    this.idprocesos.idbancodesangre = id
    this._banco.getLaboratoriosOrden(this.idprocesos).subscribe((resp:any)=>{
        /* console.log("consola memo",resp.data[0].pedido) */
        const procesos = resp.data[0].pedido
        this.servicio = procesos
         /* this.generarArreglo(this.servicio) */
        /* console.log( "consola de jun",procesos) */
      })
  }

  setear_arreglo_proceso (data){
    //console.log(data)
    data.forEach(element => {
      this.procesos.push(element.proceso)
    });
    /* console.log(this.procesos) */
    this.generarArreglo(this.servicio, this.procesos)
  }

  generarArreglo(arreglos , procesos){
    
    /* console.log(procesos)
    console.log(arreglos); */
    let bh = {
      nombre:arreglos[0].bhc[0],
      id:arreglos[0].bhc[1],
      proceso:procesos[2]
    };
    let serologia = {
      nombre:arreglos[0].sero[0],
      id:arreglos[0].sero[1],
      proceso:procesos[0]
    }
    let rh = {
      nombre:arreglos[0].grupo[0],
      id:arreglos[0].grupo[1],
      proceso:procesos[2]
    }

    this.servicios.push(rh)
    this.servicios.push(bh)
    this.servicios.push(serologia)
    
     //console.log(this.servicios); 
    
  }

}
