import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditableService } from 'src/app/services/editable/editable.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class EditarComponent implements OnInit {

  public id;
  item:any[]=[];
  constructor(private _route: ActivatedRoute,
              private _consumoIntegrado: IntegradosService,
              private _editable: EditableService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerIdEstudio(this.id);
  }

  obtenerIdEstudio(id){
    this._consumoIntegrado.getServicioById(id).subscribe((resp:any)=>{
      /* console.log( resp ) */
      if(resp.ok){
        localStorage.setItem('elementoss', JSON.stringify(resp.data));
        this.set(resp.data.ELEMENTOS);
        
      }
    })
  }

  set(resp){
  
    this._editable.setArreglo(resp);
  //  console.log( elementos);
   const elementos = this._editable.obtenerArreglo();
    this.item=resp
  }
}
