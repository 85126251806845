import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XRAYService } from 'src/app/services/Rayos-X/xray.service';

@Component({
  selector: 'app-resultados-rayosx',
  templateUrl: './resultados-rayosx.component.html',
  styleUrls: ['./resultados-rayosx.component.css']
})
export class ResultadosRayosxComponent implements OnInit {

  public paciente ={
    apellidoMaterno: '',
  apellidoPaterno: '',
callePaciente: '',
consultas:'' ,
contactoEmergencia1ApellidoMaterno: '',
contactoEmergencia1ApellidoPaterno: '',
contactoEmergencia1Curp: "",
contactoEmergencia1Edad: "",
contactoEmergencia1Genero: "",
contactoEmergencia1Nombre: "",
contactoEmergencia1Telefono: "",
contactoEmergencia2ApellidoMaterno: "",
contactoEmergencia2ApellidoPaterno: "",
contactoEmergencia2Curp: "",
contactoEmergencia2Edad: "",
contactoEmergencia2Genero: "",
contactoEmergencia2Nombre: "",
contactoEmergencia2Telefono: "",
correoPaciente: "",
correoRazonSocial2: "",
cpPaciente: '',
cpRazonSocial: "",
cpRazonSocial2: "",
curp: "",
edad: '',
estadoPaciente: "",
familia: [],
fechaNacimientoPaciente: "",
fechaRegistro: "",
genero: "",
membresiaActiva: '',
nombrePaciente: "",
nomenclaturaRegistro: "",
paisNacimiento: "",
paisPaciente: "",
paquetes: [],
paquetesPacientes: [],
razonSocial1: "",
razonSocial1Calle: "",
razonSocial1Estado: "",
razonSocial1Municipio: "",
razonSocial2: "",
razonSocial2Calle: "",
razonSocial2Estado: "",
razonSocial2Municipio: "",
razoncocial1RFC: "",
razoncocial2RFC: "",
status: "",
telefono: '',
_id: ''
  }

  public id;
public xray={
  imageUrl:[],
  usuario:"",
  idEstudio:{
    ELEMENTOS:[{
      machote:""
   }],
   ESTUDIO:""
  },
    diagnostico:"",
    machoteEdit:"",
  idPaciente:[],
  observaciones:""
}

public titulo;
public machoteEditado;
public diagnostico;
public diag;
public observa;
public imagenes=[];
public userXRAY
public searchEstudio
public xrayos :[]
  constructor( private _route: ActivatedRoute,
              private _xrayServices : XRAYService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.paciente=JSON.parse(localStorage.getItem('idPaciente'))
    this.obtenerXRAY();
    this.searchEstudio = JSON.parse(localStorage.getItem('idPedidoXray'))
  }


  setPeidoXRAY(pedido){


    // console.log( pedido )
    // this.xray =pedido;
    // this.xrayos =pedido

    this.xray = pedido.find(element => element.idPedido == this.searchEstudio)
    
    // if(!this.xray){
    //   return;
    // }

    this.imagenes = this.xray.imageUrl
    
  
    this.diag = this.xray.diagnostico;
    this.titulo = this.xray.idEstudio.ESTUDIO;
    this.machoteEditado = this.xray.machoteEdit;
    this.observa = this.xray.observaciones;
    

    this.userXRAY = this.xray.usuario;
    // for( let i in pedido ){

    //   this.diag = this.xray[i].diagnostico;
    //   this.titulo = this.xray[i].idEstudio.ESTUDIO;
    //   this.machoteEditado = this.xray[i].machoteEdit;
    //   this.observa = this.xray[i].observaciones;
      
    //   this.imagenes= this.xray[i].imageUrl;
    //   this.userXRAY = this.xray[i].usuario;
  
    // }    
    // console.log( this.imagenes  );

    }
      obtenerXRAY(){
       this._xrayServices.obtenerXrayRecepcionHC (this.paciente._id)
       .subscribe((data) =>  {
        /* console.log(data);  */
        this.setPeidoXRAY(data ['data'])})
       
      }



      USGpdf() {
        let showElement = document.querySelectorAll(".hide-display");
        showElement.forEach(elemento =>{
          elemento.classList.remove("hide-display");
        });
        window.print();
        window.location.reload();
      }
}
