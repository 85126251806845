<div class="container-fluid">
    <h1 class="title-principal-bs">JEFA DE ENFERMERÍA</h1>

    <div class="row my-3">
        <div class="col-7">
            <input type="text" 
            class="form-control shadow-input-bs" 
            placeholder="BUSCAR"
            name="txtSearch"
            [(ngModel)]="txtSearch"
            (keyup)="filterChangeByName()"
            autofocus="autofocus">
        </div>
        <div class="col">
            <div class="col-3 mx-auto">
                <button class="btn btn-primary" type="submit">BUSCAR</button>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-1">
                    <p>
                        No.
                    </p>
                </div>
                <div class="col-4">
                    <p>
                        NOMBRE DEL PACIENTE
                    </p>
                </div>
                <div class="col">
                    <p>
                        ENFERMERA ATENDIÓ
                    </p>
                </div>
                <div class="col">
                    <p>
                        DOCTOR
                    </p>
                </div>
                <div class="col-2">
                    <p>
                        FECHA
                    </p>
                </div>
            </div>
        </div>
        <div class="card-body">
                <div class="row"  *ngFor="let consulta of consultas| paginate:{id:'listapacientes',
                itemsPerPage: 7,
                currentPage:pagina,
                totalItems:totalConsultas}; let i = index;">
                    <div class="col-1">
                        <p>
                            {{ i + 1 }}
                        </p>
                    </div>
                    <div class="col-4">
                        <p>
                            {{ consulta.paciente.nombrePaciente }}
                            {{ consulta.paciente.apellidoPaterno }}
                            {{ consulta.paciente.apellidoMaterno }}
                        </p>
                    </div>
                    <div class="col">
                        <p>
                            {{consulta.enfermeraAtendio | uppercase}}
                        </p>
                    </div>
                    <div class="col">
                        <p>
                            {{ consulta.doctorAPasar | uppercase}}
                        </p>
                    </div>
                    <div class="col-2">
                        <p>
                            {{ consulta.fechaIngreso | date:'dd/MM/yyyy':'UTC' }}
                        </p>
                    </div>
                </div>
            </div>
        <div class="card-footer text-center">
            <div>
                <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>

