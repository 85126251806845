<!-- SE CREO EL LISTADO DE PACIENTE -->
<div class="container-fluid">
    
    <div class="row mt-2 mb-2">
        <div class="col-12">
            <app-titulos [title]="'LISTADO DE RECETAS'"></app-titulos>

        </div>

    </div>

    <div class="row">
        <div class="col-md-9">

            <input [(ngModel)]="filterNombre" name="filterNombre" id="busquedaPaciente" autofocus="autofocus"  (keyup)="filterPacienteByName()"  class="form-control input-censur mb-2" type="text" placeholder="Buscar Paciente...">

        </div>
        <div class="col">
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col-md-3 text_header">
                            <p>PACIENTE</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>
                                GÉNERO
                            </p>
                        </div>
                        <div class="col-md-4 text_header">
                            <p>
                                FECHA
                            </p>
                        </div>                        
                    </div>
                </div>

                
                <div class="card-body">
                    <div *ngFor="let item of recetas ; let i = index" >
                        <div class="row pointer" [routerLink]="['/detalle/receta', item._id]">
                            
                            <div class="col-md-1 text_header text-black">
                                <p> {{ i +1 }} </p>
                            </div>
                        
                            <div class="col-md-3 text_header text-black">
                                <p>{{ item.idPaciente.nombrePaciente | uppercase }} {{ item.idPaciente.apellidoPaterno | uppercase }} {{ item.idPaciente.apellidoMaterno | uppercase }}</p>
                            </div>
                            
                            <div class="col-md-2 text_header text-black">
                                <p>{{ item.idPaciente.edad }}</p>
                            </div>
                            
                            <div class="col-md-2 text_header text-black">
                                {{ item.idPaciente.genero | uppercase }}
                            </div>
                            
                            <div class="col-md-4 text_header text-black">
                                <p> {{ item.fechaReceta }} </p>
                            </div>

                        </div>
                    </div>
                </div>

            <div class="offset-md-4">
                    <!-- <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls> -->
                </div>

           
                
            </div>
        </div>
    </div>
</div>