import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { signosVitales} from '../../../../functions/bancoSangre'
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';;
import jsPDF from 'jspdf';

@Component({
  selector: 'app-ficha-disponente',
  templateUrl: './ficha-disponente.component.html',
  styleUrls: ['./ficha-disponente.component.css']
})
export class FichaDisponenteComponent implements OnInit {

  public idCensur;
  public paciente={
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
  }

  public religion;
  public imc: number = 0;
  public btnSignosVitales = false;
  public talla: number =0;
  public peso: number=0;

  public respuesta={
    diferido: [{
      fechafinal: "",
      fechainicio: "",
      motivo: "",
      tiempoDiferir: "",
    }],
    diferir: true,
    proceso: "",
    respuestas: 0
  }

  public signosVitales = {
    talla:  0,
    peso:  0 ,
    imc: 0,
    temp: "",
    sistolica: "",
    diastolica: "",
    multiples:"",
    recientes: ""
  }
  public fecha;
  public obtenidos = [];
  constructor(private _banco:BancoService,
              private activatedRoute:ActivatedRoute,
              private _router:Router) { }

  ngOnInit(): void {
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDisponente(this.idCensur);
  }

  getDisponente(id){
    console.log(id);
    
    this._banco.getDisponente(id).subscribe((resp:any)=>{
      console.log(resp);
      
      this.paciente = resp.data.paciente
      this.setPaciente(resp.data.paciente);
    })
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

  relig(event){
    this.religion = event
    let rel = {
      idpaciente:this.paciente._id,
      religion:event
    }
    this._banco.actualizarReligion(rel).subscribe((resp:any)=>{
      /* console.log(resp); */
    })
  }

  setPeso(){
    this.signosVitales.peso = this.peso;
    this.signosVitales.talla = this.talla;
    this.signosVitales.imc = this.imc;
  }

  obtenerIMC(){

    this.imc = ((this.peso)/(this.talla * this.talla));
    let imcL = this.imc.toString();
  
    imcL.split(',', 2);
  
    let imcLn;
    imcLn = parseFloat(imcL).toFixed(2);
  
    this.imc = imcLn;
   
  }

  validarSignos( validar: number  ){

    if( validar <= 0 ){
      return true;
    }
  }

  validarStringsForm(validar: string){

    if(  validar == "" || validar == undefined ){
      return true;
    }
  }

  messageAlert(message){
     Swal.fire({
              icon: 'error',
              title: '',
              text: message,
            })


    /* alert(message); */
  }

  validacionFormSignos(){
    
    if( this.validarSignos( this.talla ) ){
      this.messageAlert('Completa la talla');
      return false;
    }else if(this.peso == 0){
      this.messageAlert('Completa el peso');
      return false;
    }else if( this.validarStringsForm( this.signosVitales.temp )  ){
      this.messageAlert('Completa la tempetura')
      return false;
    }
    this.btnSignosVitales =  false;
    return true;
  }

  ageragrSignos(){
    this.fecha = moment().format('l'); 
    this.setPeso();
    let regreso =  signosVitales(this.fecha, this.signosVitales) 
    this.respuesta = regreso;
  }

  enviar(resp){
    /* console.log(resp); */
    
    // console.log(resp);
    
    if(resp.diferir){
        let fecha = new Date(resp.diferido[0].fechafinal);
        let dif ={
            idbanco:this.idCensur,
            proceso:resp.proceso, 
            estatus:'DIFERIDO',
            motivo:resp.diferido[0].motivo,
            rechazo:resp.diferido[0].tiempoDiferir,
            fechaRechazo:fecha
        }
        // console.log(dif);
        this._banco.diferirDisponente(dif).subscribe((resp:any)=>{
          /* console.log(resp); */
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'EL DISPONENTE SE DIFERIO CORRECTAMENTE',
          })
          this._router.navigate([ `/dashboard`]);
        })
    }else{
      let id = {
        idbancosangre:this.idCensur,
        proceso:'doctor'
      }
      let signos = {
        idbancosangre:this.idCensur,
        talla:resp.signos.talla,
        peso:resp.signos.peso,
        imc:resp.signos.imc,
        temp:resp.signos.temp,
        sistolica:resp.signos.sistolica,
        diastolica:resp.signos.diastolica,
        multiples:resp.signos.multiples,
        recientes:resp.signos.recientes
      }
      let pedidoLab={
        idbancodesangre:this.idCensur,
        pedido:{ 
              grupo:['GRUPO Y RH', '61326f058173002364db7c0f'], 
              bhc:['BIOMETRIA HEMATICA COMPLETA', '61291e62f1f91457f8a98ce7'],
              sero:['SEROLOGIA','61383eeb7a528c514406d4ef']
              },
        obtenidos: this.obtenidos,
        metodo: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
        quimico: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
        tecnico: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
        cedula: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
      }

      /* console.log(id);
      console.log(signos);
      console.log(pedidoLab); */
      
      this._banco.agregarSignos(signos).subscribe((resp:any)=>{
        
      })

      this._banco.pedirLaboratoriosDisp(pedidoLab).subscribe((resp:any)=>{
        if(resp.ok){
          this.printLabel('BIOMETRIA HEMATICA COMPLETA Y  GRUPO Y RH')
          this.printLabel('SEROLOGIA')
          // AGREGAR LA ETIQUETA  
        }
      })
      
      this._banco.cambiarProceso(id).subscribe((resp:any)=>{
        /* this.printLabel();
        //  console.log(resp);*/
         Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL DISPONENTE PASO A DOCTOR',
        })
        this._router.navigate([ `/dashboard`]); 
      })

      
      //colocar el /ordenar/laboratorios idbancodesangre y pedido(['Grupo y rh', 'Biometria hematica', 'Serologia'])
    }
  
  }


  printLabel(producto: any){
    //Hacemos uso de la libreria de jspdf
    const doc: any = new jsPDF('l', 'cm', [3,10]);
    //obtenemos la fecha 
    const dateOfDonation = new Date();
    //seteamos el size de la fuente
    doc.setFontSize(8);
    // layout de las etiquetas
    doc.text( 0.2 , 1,`NOMBRE: ${this.paciente.apellidoPaterno.toUpperCase()} ${this.paciente.apellidoMaterno.toUpperCase()} ${this.paciente.nombrePaciente.toUpperCase()}          FECHA: ${dateOfDonation.getDate()}/ ${dateOfDonation.getMonth()+1} / ${dateOfDonation.getFullYear()}   `);
    doc.text(`HORA: ${dateOfDonation.getHours()}: ${dateOfDonation.getMinutes()}`, 7, 2.1);
    doc.text( 0.2 , 1.5,`EDAD: ${this.paciente.edad}`);
    doc.text( 0.2 , 2.1,`ID: ${this.paciente._id} `);
    doc.text(`PRODUCTO: ${producto}`, 0.2, 2.5)
    // guardamos en PDF
    doc.save(`ETIQUETA: ${this.paciente.apellidoPaterno} ${this.paciente.apellidoMaterno} ${this.paciente.nombrePaciente}`);

  }

}
