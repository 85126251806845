<div class="container-fluid">
    <!-- <p class="title-principal-bs">LISTADO DE MATERIALES</p> -->
    <app-titulos [title]="'LISTADO DE MATERIALES'"></app-titulos>

    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Ejemplo: PARACETAMOL TABLETAS" class="form-control" id="busquedaPaciente" (keyup)="filterChangeByName($event)">
            </div>
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/registro/materiales']">NUEVO</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" >
                            <p >No.</p>
                        </div>
                        <div class="col text_header">
                            <p>Nombre</p>
                        </div>
                        <!-- <div class="col text_header">
                            <p>Nombre Comercial</p>
                        </div> -->
                        <div class="col text_header">
                            <p>Descripción</p>
                        </div>
                        <!-- <div class="col text_header">
                            <p>Lote</p>
                        </div> -->
                        <div class="col text_header">
                            <p>Fabricante</p>
                        </div>
                        <div class="col text_header">
                            <p>Proveedor</p>
                        </div>
                        <div class="col text_header">
                            <p>Acciones</p>
                        </div>
                    </div>
                </div>
    
                <div class="card-body">
                    <div class="row puntero pointer" *ngFor="let servicio of servicios | paginate: {
                                                              id:'listapacientes',
                                                              itemsPerPage: 5,
                                                              currentPage:pagina,
                                                              totalItems:totalMateriales
                                                              }; let i = index">
                        <div class="col-md-1" >
                            <p>
                                {{ i + 1 }}
                            </p>
                        </div>
                        <div class="col" [routerLink]="['/detalle/materiales', servicio._id]" style="text-align: center;">
                            <p>
                                {{ servicio.nombre }}
                            </p>
                        </div>
                        <!-- <div class="col" style="text-align: center;">
                            <p>
                                {{ servicio.nombre_comercial }}
                            </p>
                        </div> -->
                        <div class="col-md-2" style="text-align: center;">
                            <p>
                                {{ servicio.descripcion }}
                            </p>
                        </div>
                        <!-- <div class="col" style="text-align: center;">
                            <p>
                                No hay descripcion
                            </p>
                        </div> -->
                        <div class="col" style="text-align: center;">
                            <p>
                                {{ servicio.laboratorio }}
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p *ngIf="servicio.proveedor.length != 0">
                                {{ servicio.proveedor[0].proveedor }}
                            </p>
                            <p *ngIf="servicio.proveedor.length == 0">
                                No hay proveedor
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                <!-- <i class="fa fa-trash-alt colorRed pointer estilosxd"
                                    (click)="disabledService(servicio._id)"
                                    style="margin-right: 1rem;">
                                </i> -->
                                <i class="fa fa-edit colorGreenEditIcon ml-1 pointer size estilosxd"
                                    [routerLink]="['/editar/materiales/', servicio._id]">
                                </i>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>         
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>