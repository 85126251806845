<div class="container-fluid">
    <h1>PRODUCTOS</h1>
    <div class="row"> 
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar" [(ngModel)]="filtropacientes" class="form-control" id="busquedaPaciente" (keyup)="typeSearchPatients()">
            </div>
        </div>
        <div class="col-md-3">
            <div class="btn btn-primary center" id="nuevo">
                NUEVO
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header header">
                    <div class="row">
                        <div class="col text_header">
                            <p>No.</p>
                        </div>
                        <div class="col text_header">
                            <p>PRODUCTOS</p>
                        </div>
                        <div class="col text_header">
                            <p>PRECIO</p>
                        </div>
                        <div class="col text_header">
                            ACCIONES
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let item of productos; let i = index">
                        <div class="row">
                            <div class="col">
                                <p>
                                    {{i+1}}
                                </p>
                            </div>
            
                            <div class="col">
                                <p>
                                    {{item.producto}}
                                </p>
                            </div>
            
                            <div class="col" id="pointer" (click)="agregarCarrito(item)">
                                <p>
                                    {{item.precio |currency}}
                                </p>
                            </div>

                            <div class="col">
                                <label class="btn btn-outline-success">
                                    <i class="fas fa-edit"></i>
                                </label>

                                <label class="btn btn-outline-danger">
                                    <i class="fas fa-trash-alt"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card" id="cotizacion">
                <div class="card-title header">
                    <h4>ORDEN DE COMPRA</h4>
                </div>
                <div class="card-body" style="overflow-y: auto;">
                    <div class="row" *ngFor="let item of carrito.items; let i = index">
                        <div class="col-md-2">
                            <p>
                                {{i+1}}
                            </p>
                        </div>
                        <div class="col-md-7">
                            <p>
                                {{item.producto}}
                            </p>
                        </div>
                        <div class="col-md-3">
                            <p>
                                ${{item.precio}}
                            </p>
                        </div>
                    </div>
                    <!-- <div *ngIf="validarCarrito()">
                        <p style="text-align: center;">No cuentas con productos en el carrito</p>
                    </div> -->
                </div>

                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-12">
                            <h5>TOTAL A PAGAR: {{carrito.total | currency}}</h5>
                        </div>
                    </div>
                    <button type="button" class="btn btn-block btn-tarjeta" [routerLink]="['/orden/productos']">
                        <p>PAGAR</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
