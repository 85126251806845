import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {  FichaInfoReaccionesDerivadas } from '../../../app/classes/ficha-info-paciente';

@Component({
  selector: 'app-ficha-reacciones-derivadas',
  templateUrl: './ficha-reacciones-derivadas.component.html',
  styleUrls: ['./ficha-reacciones-derivadas.component.css']
})
export class FichaReaccionesDerivadasComponent implements OnInit {

  @Input() pacienteInfo: FichaInfoReaccionesDerivadas;
  @Output() usuarioSeleccionado = new EventEmitter();
  @Output() contenid = new EventEmitter();
  public riesgo={
    valores:""
  }

  public contenido;

  constructor() { }

  ngOnInit(): void {
  }

  enviarDatos(){
    this.usuarioSeleccionado.emit(this.riesgo);
  }

  enviarDatosCont(){
    this.contenid.emit(this.contenido);
  }

}
