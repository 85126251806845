import { Component, OnInit } from '@angular/core';
// import * as console from 'console';
import CarritoCensur  from '../../../../classes/carrito-censur/censur-carrito';

@Component({
  selector: 'app-pago-final',
  templateUrl: './pago-final.component.html',
  styleUrls: ['./pago-final.component.css']
})
export class PagoFinalComponent implements OnInit {

  public totalRest = 0;

  public carrito =  {
    items: [],
    total: 0
  }

  public btnDisabled = true;

  public infoVenta = {
    montoEfectivo:0,
    montoTranferencia:0,
    montoTarjetaDebito: 0,
    montoTarjetaCredito: 0,
    efectivo: "",
    transferencia: "",
    tarjetaDebito: "",
    tarjetaCredito: ""
  }

  constructor() { }

  ngOnInit(): void {
    this.getAllProducts();
    this.totalRest = this.carrito.total;
  }

  getAllProducts() {
    const carrito = new CarritoCensur();
   this.carrito = carrito.obtenerSotorageCarrito();
  //  console.log(this.totalRest );
  }

  restTotal() {

      this.totalRest = this.carrito. total;

      if( this.infoVenta.efectivo == 'true'  ) {
        
        this.totalRest = this.totalRest - this.infoVenta.montoEfectivo;
        // console.log( this.totalRest )
      }
       
      if(  this.infoVenta.tarjetaCredito == 'true' ) {
      
        this.totalRest = this.totalRest - this.infoVenta.montoTarjetaCredito;
      
      }
      
      if( this.infoVenta.tarjetaDebito == 'true' ) {
      
        this.totalRest = this.totalRest - this.infoVenta.montoTarjetaDebito;
      
      } 
      
      if(  this.infoVenta.transferencia == "true" ) {

        this.totalRest = this.totalRest - this.infoVenta.montoTranferencia;
      } 

      if( this.totalRest == 0 ){
        this.btnDisabled = false;
      }

  }


  addToPay(){
    console.log("pago");
    //TODO: enviar al pago
  }

}
