<div class="card" id="historia">
    <div role="tabpanel" class="row">
        <div class="col-4" id="indicadores">
            <ul class="nav flex-column nav-pills">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#antecedentes" aria-controls="#antecedentes"> 
                        ANTECEDENTES
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="verSignosVitalesAnteriores()" href="#signosVitales" data-toggle="tab" aria-controls="#signosVitales"> 
                        SIGNOS VITALES
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad >= 13 && paciente.genero == 'FEMENINO'">
                    <a class="nav-link" (click)="obtenerGinecoPorVisitas()" href="#ginecoPorVisita" data-toggle="tab" aria-controls="#ginecoPorVisita"> 
                        GINECO OBSTÉTRICOS POR VISITA 
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad > 9">
                    <a class="nav-link" (click)="obtenerMedicinaP()" href="#medicinaPreventiva" data-toggle="tab" aria-controls="#medicinaPreventiva">
                        MEDICINA PREVENTIVA
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad <= 9">
                    <a class="nav-link" (click)="obtenerVacunacionNinos()" href="#esquemaVacunacion" data-toggle="tab" aria-controls="#esquemaVacunacion">
                        ESQUEMA DE VACUNACIÓN
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad <= 9">
                    <a class="nav-link" (click)="obtenerNutricion()" href="#nutricion" data-toggle="tab" aria-controls="#nutricion">
                        NUTRICIÓN
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad > 9">
                    <a class="nav-link" (click)="obtenerHeredoFamiliaresXPaciente()" href="#antecedentesHF" data-toggle="tab" aria-controls="#antecedentesHF">
                        ANTECEDENTES HEREDO FAMILIARES
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad >= 13">
                    <a class="nav-link" (click)="verAntecedentesNoPatologicos()" href="#antecedentesPNP" data-toggle="tab" aria-controls="#antecedentesPNP">
                        ANTECEDENTES PERSONALES NO PATOLÓGICOS
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad >= 9">
                    <a class="nav-link" (click)="verAntecedentesPersonalesPatologicos()" href="#antecedentesPP" data-toggle="tab" aria-controls="#antecedentesPP">
                        ANTECEDENTES PERSONALES PATOLÓGICOS
                    </a>
                </li>
                <li class="nav-item" *ngIf="paciente.edad >= 13 && paciente.genero == 'FEMENINO'">
                    <a class="nav-link" (click)="obtenerGinecoHistoria()" href="#antecedentesGineco" data-toggle="tab" aria-controls="#antecedentesGineco">
                        ANTECEDENTES GINECO OBSTÉTRICOS
                    </a>
                </li>
                <li class="nav-item" *ngIf="pageTitle === 'hojaevo'">
                    <a class="nav-link" href="#interrogacionPAYS" data-toggle="tab" aria-controls="#interrogacionPAYS">
                        INTERROGACIÓN POR APARATOS Y SISTEMAS
                    </a>
                </li>
                <li class="nav-item" *ngIf="pageTitle == 'ddd'">
                    <a class="nav-link" href="#exploracionFisica" data-toggle="tab" aria-controls="#exploracionFisica">
                        EXPLORACIÓN FISICA
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-8">
            <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="antecedentes">
                    <app-antecedentes-horizonte [_id]="paciente._id" [edad]="paciente.edad" [antecedentes]="antecedentes"></app-antecedentes-horizonte>
                </div>
                <div role="tabpanel" class="tab-pane" id="signosVitales">
                    <app-signos-vitales-horizonte [_id]="paciente._id" [historialSginos]="historialSginos"></app-signos-vitales-horizonte>
                </div>
                <div role="tabpanel" class="tab-pane" id="ginecoPorVisita">
                    <app-gineco-por-visita [_id]="paciente._id" [agregarGinecoObstetricosPorVisita]="agregarGinecoObstetricosPorVisita"></app-gineco-por-visita>
                </div>
                <div role="tabpanel" class="tab-pane" id="medicinaPreventiva">
                    <app-medicina-prev [_id]="paciente._id" [genero]="paciente.genero" [edad]="paciente.edad" [medicinaPreventiva]="medicinaPreventiva"></app-medicina-prev>
                </div>
                <div role="tabpanel" class="tab-pane" id="esquemaVacunacion">
                    <app-esquema-vacunacion [_id]="paciente._id" [esquemaVacuncaion]="esquemaVacuncaion"></app-esquema-vacunacion>
                </div>
                <div role="tabpanel" class="tab-pane" id="nutricion">
                    <app-nutricion [_id]="paciente._id" [nutricionNinos]="nutricionNinos"></app-nutricion>
                </div>
                <div role="tabpanel" class="tab-pane" id="antecedentesHF">
                    <app-antecedentes-hf [_id]="paciente._id" [antecedentesHeredoFamiliares]="antecedentesHeredoFamiliares"></app-antecedentes-hf>
                </div>
                <div role="tabpanel" class="tab-pane" id="antecedentesPNP">
                    <app-antecedentes-pnp [_id]="paciente._id" [antecedentesPersonalesNoPatologicos]="antecedentesPersonalesNoPatologicos"></app-antecedentes-pnp>
                </div>
                <div role="tabpanel" class="tab-pane" id="antecedentesPP">
                    <app-antecedentes-pp [_id]="paciente._id" [antecedentesPersonalesPatologicos]="antecedentesPersonalesPatologicos"></app-antecedentes-pp>
                </div>
                <div role="tabpanel" class="tab-pane" id="antecedentesGineco">
                    <app-antecedentes-gineco-obs [_id]="paciente._id" [antecedentesGinecoObstetricosHistoria]="antecedentesGinecoObstetricosHistoria"></app-antecedentes-gineco-obs>
                </div>
                <div role="tabpanel" class="tab-pane" id="interrogacionPAYS">
                    <app-interrogacion-pays></app-interrogacion-pays>
                </div>
                <div role="tabpanel" class="tab-pane" id="exploracionFisica">
                    <p>activado12</p>
                </div>
            </div>
        </div>
    </div>
</div>
