import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaquetesService } from '../../../../services/paquetes/paquetes.service';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Dates  from '../../../../classes/dates/date.class';
import Tickets from 'src/app/classes/tickets/ticket.class';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import { eliminarStorage } from 'src/app/functions/pacienteIntegrados';
import { PagoServiciosService } from '../../../../services/pagos/pago-servicios.service';
import { CEDE } from 'src/app/classes/cedes/cedes.class';

@Component({
  selector: 'app-membresia',
  templateUrl: './membresia.component.html',
  styleUrls: ['./membresia.component.css']
})
export class MembresiaComponent implements OnInit {

  @Input() id: String;
  consultas:any = { tipo: '', consulta: '', fecha: '', medico: '', firma: '' }
  medicos:any[] = []
  paquete:any[] = []
  concepto:any[] = []
  citas:any[] = []

  public pacienteInfo={
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    // id:"",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    membresiaActiva:false,
    _id:""
  };

  public infoVenta = {  

    paciente:"",
    nombrePaciente:"",
    vendedor:"",
    fecha:"",
    hora:"",
    estudios:[],
    efectivo:false,
    doctorQueSolicito:"",
    transferencia: false,
    tarjetCredito:false,
    tarjetaDebito:false,
    montoEfectivo:0,
    montoTarjteCredito:0,
    montoTarjetaDebito:0,
    montoTranferencia: 0,
    sede:"",
    totalCompra:0,
    prioridad:"",
    compraConMembresia:true,
    status:'Pagado'
  }

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };
  fecha: string;
  /* examenes:any[] = []
  public egoCount = 0;
  public general = {fecha:"", hora:"" , consulta:"", medico: "", firma:"" };
  public nutricion = {fecha:"", hora:"" , consulta:"", medico: "", firma:"" }; */
    // public paqueteExamenesInto = [];

    // formatos de fechas 
  public fechaConsulta = moment().format('l');
  public horaIngreso = moment().format('hh:mm');

  public fechaRegistro: string;
  public btnAgregaConsultas = false;
  public diasRestantes:Number;
  public nombrePaquete='';
  medicoSelected: any;


  constructor(public _router: ActivatedRoute, public _paquete:PaquetesService, public _route:Router, private _pagoServicios:PagoServiciosService) { }

  ngOnInit(): void {
    this.obtenerMedicos();
    this.obtenerPaquete();
    this.consultas.firma = JSON.parse(localStorage.getItem('usuario')).nombre;
  }

  obtenerPaquete(){
    this._paquete.obtenerPaquete(this.id)
    .subscribe(  (data:any) =>  {
      localStorage.removeItem('paciente');
      localStorage.setItem('paciente', JSON.stringify(data['paquetes']['paciente']))
      this.pacienteInfo = data['paquetes']['paciente'];
      this.paquete.push(data['paquetes'])
      this.citas = this.paquete[0].visitas
      this.fechaRegistro = this.paquete[0].fecha;
      this.nombrePaquete = this.paquete[0].paquete.nombrePaquete;
      this.getDiasRestantes();
    });
  }

  responseMedico( event  ) {
    this.medicoSelected = event;
   }
   
  getDiasRestantes(){
    const dias = new Dates()
    this.diasRestantes  =  dias.getDateRest( this.fechaRegistro ); 
    if( this.diasRestantes <= 0 || this.diasRestantes == NaN ){
      let estado = {
        membresiaActiva: false
      }
      this._paquete.actualizarEstadoMembresia( this.pacienteInfo._id, estado )
      .subscribe( (data:any) =>{
        localStorage.removeItem('paciente')
        localStorage.setItem('paciente', JSON.stringify(data.data))
      });
    }
  }

  obtenerMedicos(){
    this._paquete.getMedicos()
    .subscribe( (data) => this.medicos = data['medicos']);
  }

  irAUnServicio(  servicio ){
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    this._route.navigate([ `/serviciosInt/${servicio}`]);
  }

  //insercion
  seleccion($event, value){
    switch (value) {
      case 'visitas':
        this.concepto=[]
        for(let item of this.paquete ){
          for(let item2 of item.paquete.CitasIncluidas){
            if(item2 == 'Consulta  de medicina general sin costo de lunes a domingo las 24 Horas'){
              this.concepto.push(item2)
            }
          }
        }  
        this.consultas.consulta = ''
      break;

      case 'laboratorio':
        this._route.navigateByUrl("/serviciosInt/laboratorio");
      break;

      case 'ultrasonido':
        this._route.navigateByUrl("/serviciosInt/ultrasonido");
      break;

      case 'xray':
        this._route.navigateByUrl("/serviciosInt/xray");
      break;

      /* case 'examenesLab':
        this.concepto=[]
        for(let item of this.paquete ){
          for(let item2 of item.paquete.examenesLaboratorio){
            this.concepto.push(item2)
          }
          console.log(this.concepto)
        }  
        this.consultas.consulta = ''
      break; */

      default:
        break;
    }
  }


  agregarConsulta(){
    if(this.consultas.tipo == '' || this.consultas.consulta == '' || this.consultas.medico == '' || this.consultas.firma == ''){
         Swal.fire({
              icon: 'error',
              title: '',
              text: 'ingresa los datos correctos',
            })
    }else{
      this.consultas.fecha = new Date()
      /* console.log(this.consultas) */
      this.consultas.hora = this.horaIngreso;
      this._paquete.agregarConsulta(this.consultas,this.consultas.tipo,this.id).subscribe(
        (data)=>{
        Swal.fire({
          icon: 'success',
          title: 'CONSULTA AGREGADA',
          text: 'Puede ver las visitas en la tabla',
        })
          this.mostrarConsultas();
          this.setDatesVenta(this.consultas.medico);          
          this._pagoServicios.agregarPedido( this.infoVenta )
          .subscribe( (data) => {
            // console.log( data );
            if(  data['ok'] ){
              
              this.generarTicket(data['folio']);
                // se crea la tabla de las ventas 
              Swal.fire({
                icon: 'success',
                title: 'CONSULTA AGREGADA',
                text: 'Puede ver las visitas en la tabla',
              })
                // ESTA INFORMCAIÓN SE ENVIA A LOS DIFERENTES SERVICIOS 
                // seteamos las fechas 
                  eliminarStorage();
                  
                  
                  const eliminarPaciente = new PacienteStorage();
                  eliminarPaciente.removePacienteStorage();  
                  this._route.navigateByUrl('consulta');  
              }
          });
        })
      
      this.consultas = { tipo: '', consulta: '', fecha: '', medico: '', firma:'' }
    }
  }

  generarTicket(folio){

    const tickets = new Tickets();
    tickets.printTicketSinMembresia( this.pacienteInfo, this.carrito ,  this.infoVenta, folio );
  
  }

  setDatesVenta(medico){
    const item = {
      name:this.nombrePaquete,
      nombreEstudio: "CONSULTA DE MEDICO GENERAL",
      precioCon:0,
      precioSin:0,
      _id:'5fd3ebca08ccbb0017712f0d'
    }
    const dates = new Dates();
    //this.infoVenta.totalCompra = this.carrito.totalSin;
    this.fecha = moment().format('l');    
    this.infoVenta.hora = moment().format('LT');
    this.infoVenta.vendedor = getDataStorage()._id;
    this.infoVenta.paciente = this.pacienteInfo._id;
    this.infoVenta.sede = CEDE;
    this.infoVenta.prioridad = "Programado"
    this.infoVenta.fecha = dates.getDate();
    this.infoVenta.doctorQueSolicito = medico;
    this.carrito.items.push(item);
    this.infoVenta.estudios= this.carrito.items
    this.infoVenta.totalCompra = 0;
        
  }

  mostrarConsultas(){
    this.obtenerPaquete();
  }

  mostrarDatos(consulta, medico, servicio){
    if(servicio == '1'){
              Swal.fire({
          icon: 'success',
          title: 'CITAS INCLUIDAS',
          text: `Tipo de consulta:\n${consulta}\nMedico: ${medico}`,
        })
    }
    if(servicio == '2'){        
      Swal.fire({
          icon: 'success',
          title: 'EXAMENES DE LABORATORIO',
          text: `Tipo de laboratorio:\n${consulta}\nMedico: ${medico}`,
        })
        }
  }
}

class consultas  {

  tipo:  string;
  consulta:  string;
  fecha:  string;
  firma:  string;
  hora:  string;
  medico:  string;

}
