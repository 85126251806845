import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {  FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';



@Component({
  selector: 'app-formulario-servicios',
  templateUrl: './formulario-servicios.component.html',
  styleUrls: ['./formulario-servicios.component.css']
})
export class FormularioServiciosComponent implements OnInit {

  forma: FormGroup;
  public id: string;

  constructor(private _fb: FormBuilder, private _router:Router, private _route:ActivatedRoute, private _integradosService:IntegradosService) { }

  ngOnInit(){
    this.id = this._route.snapshot.paramMap.get('servicio');

    this.forma = this._fb.group({
      ESTUDIO:['',[Validators.required, Validators.minLength(3) ]],
      PRECIO_PUBLICO:['', [ Validators.min(0)]],
      PRECIO_MEMBRESIA:['', [Validators.min(0)]],
      PRECIO_PUBLICO_URGENCIA:['',[Validators.min(0)]],
      PRECIO_MEMBRESIA_URGENCIA:['',[Validators.min(0)]],
      PRECIO_PUBLICO_HOSPITALIZACION:['', [Validators.min(0)]],
      PRECIO_MEMBRESIA_HOSPITALIZACION:['', [Validators.min(0)]],
      // PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA:['', [Validators.min(0)]],
      // PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA:['', [Validators.min(0)]],
      servicio: this.id
    });
  }

  guardar(){
    let patologia = document.querySelector('#patologia');
    let publico = document.querySelector('#publico');
    let membresia = document.querySelector("#membresia");

    if( this.forma.invalid ){
        if( this.forma.value.PATOLOGIA === ""  || this.forma.value.PATOLOGIA ){
          patologia.classList.add('is-invalid');
          return;
        }    
        if(  this.forma.value.PUBLICO <=0   ){
          publico.classList.add('is-invalid');
          return;
        }
        if( this.forma.value.MEMBRESIA <=0 ){}
          membresia.classList.add('is-invalid');
          return;
    }
    this._integradosService.setANewPatologia( this.forma.value).subscribe( (data:any) => {
      if(data.ok){
        // console.log(this.forma);
        // console.log("Se creo el servicio", "Servicio creado", "success");
        this._router.navigateByUrl('serviciosInt/'+this.id);
      }
    })
  }

}