<form #form='ngForm'>
    <mat-horizontal-stepper linear #stepper>
        <mat-step label="Datos generales">
            <div class="row">
                <div class="col-md-2">
                    <label for="nombreServicio"> Nombre del servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" maxlength="13" name="nombreServicio" [(ngModel)]="serviceEdit.ESTUDIO" id="nombreServicio" class="form-control">
                </div>

                <div class="col-md-2 offset-1">
                    <label for="idServicio"> ID del servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="idServicio" [(ngModel)]="serviceEdit._id" id="idServicio" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="sede">  Sede </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="sede" [(ngModel)]="sede" id="sede" class="form-control">
                </div>

                <div class="col-md-2 offset-1">
                    <label for="idServicio"> Servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="Servicio" [(ngModel)]="serviceEdit.name" id="Servicio" class="form-control">
                </div>
            </div>
        </mat-step>
        <mat-step label="Rango de utilidad">
            <div class="row">    
                <div class="col-md-2">
                    <label for="rangoUtilidad"> Rango de utilidad </label>
                </div>
                <div class="col-md-3">
                    <select name="rangoUtilidad" [(ngModel)]="utilidad.rangoUtilidad" id="rangoUtilidad" class="form-control">
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                    </select>
                </div>
            </div>
            
            <div class="row">    
                <div class="col-md-2">
                    <label for="precioPublico"> Precio publico </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" maxlength="13" name="precioPublico" [(ngModel)]="serviceEdit.PRECIO_PUBLICO" id="precioPublico" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioMembresia"> Precio membresia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioMembresia" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA" id="precioMembresia" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioUrgencia">  Precio urgencia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioUrgencia" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_URGENCIA" id="precioUrgencia" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioUrgenciaMembresia"> Precio urgencia membresia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioUrgenciaMembresia" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_URGENCIA" id="precioUrgenciaMembresia" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioHospitalizacion">  Precio hospitalización </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioHospitalizacion" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_HOSPITALIZACION" id="precioHospitalizacion" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioHopitalizacionMem"> Precio hospitalización membresia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioHopitalizacionMem" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_HOSPITALIZACION" id="precioHopitalizacionMem" class="form-control">
                </div>
                <!-- terminan los datos fiscales  -->
            </div>
        </mat-step>

        <mat-step label="Precio por utilidad">
            <div class="row">
                <div class="col" style="font-weight: bolder; text-align: center;">
                    <p>RANGO A</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <p id="utilidad1">Utilidad</p>
                </div>
                <div class="col-md-8">
                    <p id="utilidad2">Utilidad</p>
                </div>
            </div> 
            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublicoA"> Precio público </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" maxlength="13" name="utilidadprecioPublicoA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO" id="utilidadprecioPublicoA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" maxlength="13" name="utilidadprecioPublicoA2" [(ngModel)]="utilidad.preciosRangoA[0].porcentajePrecioPublicoA" id="utilidadprecioPublicoA2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaA"> Precio membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioMembresiaA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA" id="utilidadprecioMembresiaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaA2" [(ngModel)]="utilidad.preciosRangoA[0].porcentajePrecioMembresiaA" id="utilidadprecioMembresiaA2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioUrgenciaA">  Precio urgencia </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioUrgenciaA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_URGENCIA" id="utilidadprecioUrgenciaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioUrgenciaA2" [(ngModel)]="utilidad.preciosRangoA[0].porcentajePrecioPublicoUrgenciaA" id="utilidadprecioUrgenciaA2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioUrgenciaMembresiaA"> Precio urgencia membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioUrgenciaMembresiaA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_URGENCIA" id="utilidadprecioUrgenciaMembresiaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioUrgenciaMembresiaA2" [(ngModel)]="utilidad.preciosRangoA[0].porcentajePrecioMembresiaUrgenciaA" id="utilidadprecioUrgenciaMembresiaA2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioHospitalizacionA">  Precio hospitalización </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioHospitalizacionA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_HOSPITALIZACION" id="utilidadprecioHospitalizacionA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioHospitalizacionA2" [(ngModel)]="utilidad.preciosRangoA[0].porcentajePrecioPublicoHospitalizacionA" id="utilidadprecioHospitalizacionA2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioHopitalizacionMemA"> Precio hospitalización membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioHopitalizacionMemA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_HOSPITALIZACION" id="utilidadprecioHopitalizacionMemA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioHopitalizacionMemA2" [(ngModel)]="utilidad.preciosRangoA[0].porcentajePrecioMembresiaHospitalizacionA" id="utilidadprecioHopitalizacionMemA2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>

            <div class="row">
                <div class="col" style="font-weight: bolder; text-align: center;">
                    <p>RANGO B</p>
                </div>
            </div>

            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublicoB"> Precio público </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" maxlength="13" name="utilidadprecioPublicoB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO" id="utilidadprecioPublicoB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" maxlength="13" name="utilidadprecioPublicoB2" [(ngModel)]="utilidad.preciosRangoB[0].porcentajePrecioPublicoB" id="utilidadprecioPublicoB2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaB"> Precio membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioMembresiaB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA" id="utilidadprecioMembresiaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaB2" [(ngModel)]="utilidad.preciosRangoB[0].porcentajePrecioMembresiaB" id="utilidadprecioMembresiaB2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioUrgenciaB">  Precio urgencia </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioUrgenciaB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_URGENCIA" id="utilidadprecioUrgenciaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioUrgenciaB2" [(ngModel)]="utilidad.preciosRangoB[0].porcentajePrecioPublicoUrgenciaB" id="utilidadprecioUrgenciaB2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioUrgenciaMembresiaB"> Precio urgencia membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioUrgenciaMembresiaB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_URGENCIA" id="utilidadprecioUrgenciaMembresiaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioUrgenciaMembresiaB2" [(ngModel)]="utilidad.preciosRangoB[0].porcentajePrecioMembresiaUrgenciaB" id="utilidadprecioUrgenciaMembresiaB2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioHospitalizacionB">  Precio hospitalización </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioHospitalizacionB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_HOSPITALIZACION" id="utilidadprecioHospitalizacionB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioHospitalizacionB2" [(ngModel)]="utilidad.preciosRangoB[0].porcentajePrecioPublicoHospitalizacionB" id="utilidadprecioHospitalizacionB2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioHopitalizacionMemB"> Precio hospitalización membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioHopitalizacionMemB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_HOSPITALIZACION" id="utilidadprecioHopitalizacionMemB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioHopitalizacionMemB2" [(ngModel)]="utilidad.preciosRangoB[0].porcentajePrecioMembresiaHospitalizacionB" id="utilidadprecioHopitalizacionMemB2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>

            <div class="row">
                <div class="col" style="font-weight: bolder; text-align: center;">
                    <p>RANGO C</p>
                </div>
            </div>

            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublicoC"> Precio público </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" maxlength="13" name="utilidadprecioPublicoC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO" id="utilidadprecioPublicoC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" maxlength="13" name="utilidadprecioPublicoC2" [(ngModel)]="utilidad.preciosRangoC[0].porcentajePrecioPublicoC" id="utilidadprecioPublicoC2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaC"> Precio membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioMembresiaC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA" id="utilidadprecioMembresiaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaC2" [(ngModel)]="utilidad.preciosRangoC[0].porcentajePrecioMembresiaC" id="utilidadprecioMembresiaC2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioUrgenciaC">  Precio urgencia </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioUrgenciaC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_URGENCIA" id="utilidadprecioUrgenciaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioUrgenciaC2" [(ngModel)]="utilidad.preciosRangoC[0].porcentajePrecioPublicoUrgenciaC" id="utilidadprecioUrgenciaC2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioUrgenciaMembresiaC"> Precio urgencia membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioUrgenciaMembresiaC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_URGENCIA" id="utilidadprecioUrgenciaMembresiaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioUrgenciaMembresiaC2" [(ngModel)]="utilidad.preciosRangoC[0].porcentajePrecioMembresiaUrgenciaC" id="utilidadprecioUrgenciaMembresiaC2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioHospitalizacionC">  Precio hospitalización </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioHospitalizacionC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_HOSPITALIZACION" id="utilidadprecioHospitalizacionC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioHospitalizacionC2" [(ngModel)]="utilidad.preciosRangoC[0].porcentajePrecioPublicoHospitalizacionC" id="utilidadprecioHospitalizacionC2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioHopitalizacionMemC"> Precio hospitalización membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" [disabled]="true" min="0" name="utilidadprecioHopitalizacionMemC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_HOSPITALIZACION" id="utilidadprecioHopitalizacionMemC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioHopitalizacionMemC2" [(ngModel)]="utilidad.preciosRangoC[0].porcentajePrecioMembresiaHospitalizacionC" id="utilidadprecioHopitalizacionMemC2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>
            <div class="center" *ngIf="editar == false">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
            </div> 
            <div class="center" *ngIf="editar == true">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   EDITAR </button>
            </div>  
        </mat-step>

        <!-- <mat-step label="Agregar">
            <div class="center">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
            </div>
        </mat-step> -->
    </mat-horizontal-stepper>
</form>
