<div class="row">
    <app-titulos [title]="'Bitacora de patologias'" > </app-titulos>
</div>

<div class="card">

        <div class="card-header">
            <div class="row">

                <div class="col text_header text-center">
                    <p>#</p>
                </div>
                
                <div class="col text_header text-center">
                    <p>NOMBRE DEL PACIENTE</p>
                </div>
                <div class="col text_header text-center">
                    <p>EDAD</p>
                </div>
                <div class="col text_header text-center">
                    <p>GÉNERO</p>
                </div>

                <div class="col text_header text-center">
                    <p>
                        STATUS
                    </p>
                </div>

            </div>
        </div>

        <div class="row" *ngFor="let item of patologias;let i = index" >

            <div class="col">
                <p class="colorBlack text-center">
                    {{ i +1 }}
                </p>
            </div>

            <div class="col">
                <p class="text-center"  [routerLink]="['/regresos/patologia', item._id]"  >
                    {{ item.idPaciente.nombrePaciente  }}
                    {{ item.idPaciente.apellidoPaterno  }}
                    {{ item.idPaciente.apellidoMaterno  }}
                </p>
            </div>

            <div class="col">
                <p class="text-center">
                    {{ item.idPaciente.edad  }}
                </p>
            </div>


            <div class="col">
                <p class="text-center">
                    {{ item.idPaciente.genero  }}
                </p>
            </div>

            <div class="col">
                <p class="text-center">
                    {{ item.status  }}
                </p>
            </div>


        </div>
</div>
