import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { URL } from 'src/app/config/conf';



@Injectable()
export class IntegradosService {


    public url: string;
    // public ambulancia: [];
    public slash: string;

    private resultadosdash:DRF [] =[
      {
        nombre:"LABORATORIO",
        img:"../../../assets/icons/serviciosIconos/chemistry.svg",
        url:"laboratorio"
      },
      {
        nombre:"ULTRASONIDO",
        img:"../../../assets/icons/serviciosIconos/womb.svg",
        url:"ultrasonido"
      },
      {
        nombre:"RAYOS-X",
        img:"../../../assets/icons/serviciosIconos/skeleton.svg",
        url:"xray"
      },
      {
        nombre:"PATOLOGÍA",
        img:"../../../assets/icons/serviciosIconos/microscope.svg",
        url:"patologia"
      },
      {
        nombre:"ENDOSCOPÍA",
        img:"../../../assets/icons/serviciosIconos/endoscopy.svg",
        url:"endoscopia"
      }
    ]

    private servicios:Scv[]=[
        {
          nombre:"AMBULANCIA",
          img:"../../../assets/icons/serviciosIconos/car.svg",
          url:"ambulancia"
        },
        
        {
          nombre:"CONSULTA",
          img:"../../../assets/icons/serviciosIconos/doctor.svg",
          url:"consulta"
        },
        {
          nombre:"ENDOSCOPÍA",
          img:"../../../assets/icons/serviciosIconos/endoscopy.svg",
          url:"endoscopia"
        },
        // {
        //   nombre:"HOSPITALIZACIÓN",
        //   img:"../../../assets/icons/serviciosIconos/hospital-bed.svg",
        //   url:"hospitalizacion"
        // },
        {
          nombre:"PATOLOGÍA",
          img:"../../../assets/icons/serviciosIconos/microscope.svg",
          url:"patologia"
        },
        {
          nombre:"RAYOS X",
          img:"../../../assets/icons/serviciosIconos/skeleton.svg",
          url:"xray"
        },
        {
          nombre:"LABORATORIO",
          img:"../../../assets/icons/serviciosIconos/chemistry.svg",
          url:"laboratorio"
        },
        {
          nombre:"QUIROFANO",
          img:"../../../assets/icons/serviciosIconos/surgery-room.svg",
          url:"quirofano"
        },
        {
          nombre:"RESONANCIA",
          img:"../../../assets/icons/serviciosIconos/mri.svg",
          url:"resonancia"
        },
        {
          nombre:"ULTRASONIDO",
          img:"../../../assets/icons/serviciosIconos/womb.svg",
          url:"ultrasonido"
        },
        {
          nombre:"TOMOGRAFIA",
          img:"../../../assets/icons/serviciosIconos/x-ray.svg",
          url:"tomografia"
        },
        {
          nombre:"SERVICIOS EN GENERAL",
          img:"../../../assets/icons/serviciosIconos/options.svg",
          url:"otros"
        }
    ];

      constructor(private _http:HttpClient){

        this.url = URL;
      }


      getDashServicio(){
        return this.resultadosdash;
      }

      getServicios(){
        return this.servicios;
      }

      getServicio(idx: string){
        return this.servicios[idx];
      }
      
      getObtener(slash){
    
        return this._http.get(this.url +'/ver/categoria/servicios/'+slash);
      }

      getDestino(slash:string): Observable<any>{
        return this._http.get(this.url +'/'+slash)
      }

      getAmbulanciaById(ambulanciaId): Observable<any> {
        return this._http.get(this.url+'/ambulancia/'+ambulanciaId);
      }

      updateAmbulancia(id, ambulancia): Observable<any> {
        const params = JSON.stringify(ambulancia);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url+'/ambulancia/'+id, params, {headers: headers})
      }

      getServicioById(id): Observable<any> {
        return this._http.get(this.url+'/servicio/uno/'+id);
      }

      updateServicio(id, servicio): Observable<any> {
        const params = JSON.stringify(servicio);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url+'/editar/servicio/'+id, params, {headers: headers})
      }

      create(ambulancia,slash:string): Observable<any> {
        const params = JSON.stringify(ambulancia);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url+'/'+slash, params, {headers: headers});
      }

      delete(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.delete(this.url+'/ambulancia/'+id, {headers: headers});
      }

      deleteService(id): Observable<any> {
        /* let headers = new HttpHeaders().set('Content-Type', 'application/json'); */
        return this._http.delete(this.url+'/servicio/'+id);
      }
       
      setANewPatologia(body){  
        return this._http.post( this.url +'/nuevo/servicio/', body );
      }

      getAllDepartments(buscar) {
        //console.log(buscar)
        return this._http.post(this.url+'/buscar/servicio', buscar); 
      }

      getPreciosAmbulanciaMembresia(){

        const url =   `${this.url}/obetener/ambulancia/membresia`;
        return this._http.get( url );   
      }


      getPreciosMembresiaOtros( servicio ){
        const url = `${this.url}/obtener/servicios/membresia/${servicio}`
       return this._http.get(  url  );
      }

      getAllServicesWithMembreship( servicio  ){
        const url = `${this.url}/obtener/servicios/membresia`;
        return this._http.post(  url, servicio );
      }

      actualizarElemtos(  id, body  ){
        const url = `${this.url}/actualizar/elementos/${id}`;
        return this._http.put(  url, body );
      }

      buscarServicio(id){
        const url = `${this.url}/servicio/uno/${id}`;
        return this._http.get(  url );
      }

      getServiciossoloMembresia(  servicio){
        const url = `${this.url}/ver/precios/membresia/receta`;
        
        return this._http.post( url, servicio );
      }

      getItemSede(body){
        const url = `${this.url}/ver/utilidades/item/sedes`;
        
        return this._http.post( url, body );
      }

      getIdSedeNom(nom){
        const url = `${this.url}/ver/nome/sede/${nom}`;
        return this._http.get(  url );
      }

      getItemSedeAmbulancia(body){
        const url = `${this.url}/ver/utilidades/ambulancia/item/sede`;
        return this._http.post( url, body );
      }
      agregarOtrosEstudios(body){
        const url = `${this.url}/agregar/otros/estudios`;
        return this._http.post( url, body );
      }
      obtenerOtrosEstudiosPaciente(id){
        const url = `${this.url}/otros/estudios/paciente/${id}`;
        return this._http.get(  url );
      }
}

export interface Scv{
    nombre: string;
    img: string;
    url: string;
}

export interface DRF{
  nombre: string;
  img: string;
  url: string;
}


export class Ambulancia {
  constructor(
    public DESTINO: String,
    public PRECIO_MEMBRESIA_DIA: String,
    public PRECIO_MEMBRESIA_NOCHE: String,
    public PRECIO_MEMBRESIA_REDONDO_DIA: String,
    public PRECIO_MEMBRESIA_REDONDO_NOCHE: String,
    public PRECIO_PUBLICO_DIA: String,
    public PRECIO_PUBLICO_NOCHE: String,
    public PRECIO_PUBLICO_REDONDO_DIA: String,
    public PRECIO_PUBLICO_REDONDO_NOCHE: String,
  ){}
}

