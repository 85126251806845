import  {  RouterModule, Routes } from '@angular/router';
import { PageComponent } from './pages/page/page.component';
import {  LoginComponent } from './login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
// import { IslogedGuard } from './gurads/login/isloged.guard';

const appRoutes: Routes = [
  { 
    path: '',
    // canActivate: [IslogedGuard],
    component: PageComponent,
    children: [
        { path: 'dashboard', component: DashboardComponent },
        { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
        
    ]
  },
{ path: 'login', component:LoginComponent  },
{ path: '**', component: LoginComponent },
// { path: 'agenda', component: AgendaComponent },


  

// Alguna ruta que no definida muestra el componente login
];


export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' });
