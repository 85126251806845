import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';
@Injectable({
  providedIn: 'root'
})
export class LaboratorioService {

 
  public url  = URL;
  

  constructor(public _http: HttpClient) {
   }

agregarEstudio(  seveEstudio){
    let url = `${this.url}/nuevo/estudio`;
    return this._http.post(url, seveEstudio);
}

editarEstudio( seveEstudio ){
  let url = `${this.url}/editar/estudios/laboratorio/censur`;
  return this._http.post(url, seveEstudio);
}

editablesEstudio(id,seveEstudio ){
  let url = `${this.url}/actualizar/valores/referencia/censur/${id}`;
  return this._http.put(url,seveEstudio)
}
hojaReporteEstudios(body ){
  let url = `${this.url}/obtener/valores/referencia/censur`;
  return this._http.post(url, body)
}







verNombreLaboratorio(nombre){
    
    let url =`${this.url}/ver/estudios`;
    return this._http.post(url, nombre);
  }

  enviarObtenidosLab(enviarObtenido){
    let url = `${this.url}/agregar/regresos/lab`;
    return this._http.post(url,enviarObtenido)
  }

  obtenerLaboratorioEvolucion(id, idPedido){
    let url = `${this.url}/ver/estudio/laboratorio/${id}/${idPedido}`;
    return this._http.get(url)
  }

  //Obtener solo los estudios con estatus Finalizado
  obtenerEstudiosImprimir( idPedido){
    let url = `${this.url}/laboratorios/imprimir/${idPedido}`;
    return this._http.get(url)
  }


  actualizarEstado(id,idEstudio,body){
    let url = `${this.url}/actualizar/pedido/${id}/${idEstudio}`;
    return this._http.put(url,body)
  }

  actualizarPrioridad(id, body){
    let url = `${this.url}/actualizar/prioridad/pedido/${id}`;
    return this._http.put(url,body)
  }

  // esta ruta agrega un nuevo laboratorio a los de censur
  addANewEstudioCensur(body: any) {

    const url = `${this.url}/nuevo/estudio/banco`;
    return this._http.post(url, body);
  }

  getEstudioById( id: string) {
    const url = `${this.url}/obtener/estudio/banco/${id}`;
    return this._http.get(url);
  }

  postBiometriaBanco(body: any) {

    const url = `${this.url}/analisis/estudios`;
    return this._http.post(url, body);
    
  }


  getFichaIdentificacionBiometria( id: string ){
    
    const url = `${this.url}/obtener/banco/banco/${id}`;
    return this._http.get(url);

  }


  obtenerResultadosLaboratorios(id: string) {

    const url = `${this.url}/ver/laboratorios/hoja/evolucion/${id}`;
    return this._http.get(url);
  }

}