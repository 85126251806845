import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PedidosSedesService } from 'src/app/services/pedidosSedes/pedidos-sedes.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pedidos-sedes-socios',
  templateUrl: './pedidos-sedes-socios.component.html',
  styleUrls: ['./pedidos-sedes-socios.component.css']
})
export class PedidosSedesSociosComponent implements OnInit {

  public pedidos:any []=[];
  public totalpaciente:string;
  public pagina = 0;

  constructor( private spinner: NgxSpinnerService,
    private pedidosSedesService: PedidosSedesService,
    private _router: Router) { }

  ngOnInit(): void {
    this.spinner.show();
    this.obetenerPedidos();
    this.borrarLocal();
  }

  borrarLocal(){
    localStorage.removeItem('paciente')
    localStorage.removeItem('sedePaciente')
    localStorage.removeItem('carrito')
    localStorage.removeItem('idPedidoSede')
  }


  obetenerPedidos(){
    this.pedidosSedesService.obeterPedidos()
    .subscribe( data => {
        this.setPedidos( data['data'] )
    });
  }

  setPedidos(pedidos){
    pedidos.forEach(element => {
      if (element.paciente != null) {
        this.pedidos.push(element);
      }
    });
    this.pedidos.reverse();
    this.totalpaciente = pedidos.results;
    this.spinner.hide();
  }

  detalle(pedido,id){
    const est={
      status: 'Pagado'
    }
    if(pedido.status == 'Pagado'){
      /* console.log(pedido); */
      /* this.pedidosSedesService.actualizarEstado(id, est).subscribe((data:any)=>{
        if(data.ok){
          this.obetenerPedidos();
        }
      }) */
    }else{
      this._router.navigateByUrl('/pedidos/detalle/'+id);
    }
  }
}
