import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { calcAge } from 'src/app/classes/helpers/filterNamePaciente';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';

@Component({
  selector: 'app-rayosx-regresos',
  templateUrl: './rayosx-regresos.component.html',
  styleUrls: ['./rayosx-regresos.component.css']
})
export class RayosxRegresosComponent implements OnInit {
  @Input() id = '';
  public paciente = {
    apellidoMaterno: '',
    apellidoPaterno: '',
    callePaciente: '',
    consultas:'' ,
    contactoEmergencia1ApellidoMaterno: '',
    contactoEmergencia1ApellidoPaterno: '',
    contactoEmergencia1Curp: "",
    contactoEmergencia1Edad: "",
    contactoEmergencia1Genero: "",
    contactoEmergencia1Nombre: "",
    contactoEmergencia1Telefono: "",
    contactoEmergencia2ApellidoMaterno: "",
    contactoEmergencia2ApellidoPaterno: "",
    contactoEmergencia2Curp: "",
    contactoEmergencia2Edad: "",
    contactoEmergencia2Genero: "",
    contactoEmergencia2Nombre: "",
    contactoEmergencia2Telefono: "",
    correoPaciente: "",
    correoRazonSocial2: "",
    cpPaciente: '',
    cpRazonSocial: "",
    cpRazonSocial2: "",
    curp: "",
    edad: '',
    estadoPaciente: "",
    familia: [],
    fechaNacimientoPaciente: "",
    fechaRegistro: "",
    genero: "",
    membresiaActiva: '',
    nombrePaciente: "",
    nomenclaturaRegistro: "",
    paisNacimiento: "",
    paisPaciente: "",
    paquetes: [],
    paquetesPacientes: [],
    razonSocial1: "",
    razonSocial1Calle: "",
    razonSocial1Estado: "",
    razonSocial1Municipio: "",
    razonSocial2: "",
    razonSocial2Calle: "",
    razonSocial2Estado: "",
    razonSocial2Municipio: "",
    razoncocial1RFC: "",
    razoncocial2RFC: "",
    status: "",
    telefono: '',
    _id: ''
  }
  public metodo: any;
  public nombre: any;
  public examen: any;
  public valoresRef: any;
  public venta: any;
  public servicio: any;
  public resultados: any;
  public imgQrCode: any;
  public servicioValid = "";
  public curp = "";
  public solicitio2: any;
  public fechaPedidoLabs = "";
  public observacionesUSG = "";
  public imgUsg = [];
  public diagnosticoUsg = "";
  public interpretacion = "";
  public titleUsg = "";
  public solicitio ="";
  public fechaPedido = "";
  public realizoxD : any;
  public cedulaUSG = "";
  public especialidadUSG = "";

  constructor(private _serviciosService: ServiciosService, 
              private _paciente: PacientesService,) { }

  ngOnInit(): void {
    this.obtenerResultadoPorEstudio();
  }

  obtenerResultadoPorEstudio(){
    if(this.id != undefined){
      this._serviciosService.getResultadoPorEstudio(this.id)
      .subscribe((data) => {
        this.paciente = data['data']['paciente']
        this.metodo = data['data']['metodo']
        this.nombre = data['data']['nombre']
        this.examen = data ['data']['tipo_de_examen']
        this.valoresRef = data ['data']['valoresDeReferencia']
        this.venta = data ['data']['venta']
        this.servicio = data['data']['servicio']
        this.imgQrCode = data['data']['imgQrCode']
        this.servicioValid = data['data']['nombreEstudio']
        this.curp = data['data']['paciente']['curp']
        this.solicitio2 = data['data']['solicito'];
        this.fechaPedidoLabs = data['data']['fechaPedido'];
        // console.log( this.servicioValid )
        // comparamos el nombre del servicio 
        if( this.servicioValid != 'LABORATORIO' ) {
          // console.log("Entra");
          this.observacionesUSG = data['data']['resultados'][0].obtenidos.observaciones
          this.imgUsg = data['data']['resultados'][0].obtenidos.imgs
          this.diagnosticoUsg = data['data']['resultados'][0].obtenidos.diagnostico
          this.interpretacion = data['data']['resultados'][0].obtenidos.interpretacion
          this.titleUsg = data['data']['servicio']['ESTUDIO'];
          this.solicitio = data['data']['solicito'];
          this.fechaPedido = data['data']['fechaPedido'];
          this.realizoxD = data['data']['resultados'][0]['obtenidos']['realizo']
          this.cedulaUSG = data['data']['resultados'][0]['obtenidos']['cedula']
          this.especialidadUSG = data['data']['resultados'][0]['obtenidos']['especilidad']
          // console.log( this.titleUsg)
        }
        if( this.paciente.fechaNacimientoPaciente == null  ){
          const updateNewDate = calcAge( this.curp );
          const data1 = { 
            fechaNacimientoPaciente: updateNewDate
          }
          this._paciente.actualizarFiPacientes( this.paciente._id, data1 )
          .subscribe( data => {})
        }
      })
    }
  }

  btnPrintPdf() {
    let imgHeader = '../../../../../assets/images/logoHeader.png';
    var marca = '../../../../../assets/images/favicon 2.png';
    var CUADROS = '../../../../../assets/images/cuadritos.png';
    var piePagina = '../../../../../assets/images/piePaginaTlaya.png'
    const patient = this.paciente;
    const NOMBRE_ESTUDIO = this.titleUsg;
    const OBSERVACIONES =this.observacionesUSG
    const IMGS =this.imgUsg
    const DIAGNOSTICOS =this.diagnosticoUsg
    const INTERPRETACION =this.interpretacion;
    const SOLICITO = this.solicitio
    const qRCode = this.imgQrCode
    const FECHAPEDIDO = this.fechaPedido;
    const fechaSplited = FECHAPEDIDO.split('T')[0];
    const realizo = this.realizoxD
    const cedula = this.cedulaUSG
    const especialidad = this.especialidadUSG
    
    let firmaDoc = '../../../../../assets/images/firma_doc.png';
    
    const doc:any = new jsPDF( {
      unit: "cm",
      format: 'a4',
    });
    
    doc.addImage(imgHeader, 'PNG', 1, 1, 6, 2);
    doc.addImage(CUADROS, 'PNG', 17, 1, 2, 2);
    doc.addImage(qRCode, 'PNG', 10, 1, 2.5, 2.5);
    doc.addImage(marca, 'PNG', 1, 6, 19, 25);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);
    doc.setFont('helvetica')
    // Ficha de identificacion
    doc.text('PACIENTE:', 1, 4)
    doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 3, 4)
    doc.text('EDAD: ', 10.5, 4);
    //SETEAT EDAD
    doc.text(patient.edad + ' AÑOS', 11.6, 4);
    doc.text('GÉNERO: ', 13.5, 4);
    doc.text(patient.genero, 15.5, 4);
    doc.text('FECHA DE NACIMIENTO: ', 1, 4.5 );
    // Setear Fecha paciente
    const nacimiento = String(patient.fechaNacimientoPaciente)
    if (nacimiento == 'undefined' || nacimiento == '') {
      doc.text('-- / --- / ----', 5.5, 4.5);
    }else {
      let fechaNacimiento;
      fechaNacimiento = nacimiento.slice(0, -14)
      doc.text(fechaNacimiento, 5.5, 4.5);
    }
    doc.text('FECHA EN QUE SE REALIZÓ: ', 1, 5 );
    doc.text( fechaSplited , 6.2, 5 );
    //  doc.text('REALIZÓ: ',  9, 4.5)
    doc.text('SOLICITÓ: ',  13.5, 4.5);
    doc.text( SOLICITO , 15.5, 4.5);
    //  doc.text(REALIZO, 9.5, 27)
    doc.text( realizo, 15, 26.6 )
    doc.text( cedula, 15, 26.9 )
    doc.setFontSize(8)
    // doc.text( "Dra. C. Perez Toral", 1, 28.2 )
    // doc.text( "CMU-CU266", 1, 28.5)
    doc.addImage(piePagina, 1, 27, 18, 1)
    doc.setFontSize(14);
    if(  NOMBRE_ESTUDIO.includes('(')){
      const newCharacter = NOMBRE_ESTUDIO.split('(');
      newCharacter[1] = "("+ newCharacter[1];
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
      doc.text(newCharacter[0], 6, 6)
      doc.text(newCharacter[1], 3, 6.5);
    }else if(NOMBRE_ESTUDIO.length>= 35 ){ 
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
      doc.text(NOMBRE_ESTUDIO, 3.5, 6)
    }else{
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
      doc.text(NOMBRE_ESTUDIO, 1, 6)
    }
    
    doc.text( "INTERPRETACIÓN:", 1, 7 )
    doc.setFontSize(10.8)
    doc.text( INTERPRETACION, 1.2, 8, { maxWidth: 18, align: 'left' } )
    doc.setFontSize(14)
    doc.text("DIAGNOSTICO:", 1, 20 )
    doc.setFontSize(10.8)
    doc.text(DIAGNOSTICOS, 1.2, 20.5, { maxWidth: 18, align: 'left' })
    doc.setFontSize(14)
    doc.text('OBSERVACIONES:', 1, 25)
    doc.setFontSize(10.8)
    doc.text(OBSERVACIONES, 1.2, 25.5, { maxWidth: 18, align: 'left' })
    
    doc.setFontSize(8);
    doc.setTextColor(0,0,0);
    doc.text("Horario: Lunes a Domingo 24 horas", 1,28.1);
    doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1,28.4);
    doc.text("Tel. (735) 357 7564", 1,28.7);
    doc.text("atencion.horizonte@gmail.com", 1,29);
    var countX =doc.internal.pageSize.getHeight()/2 , countY = doc.internal.pageSize.getWidth()/2;;
    //! if de las imagenes 
    if( IMGS.length > 0 ) {
      IMGS.forEach(element => {
        doc.addPage();
        doc.addImage(imgHeader, 'PNG', 1.5, 1, 6, 2);
        doc.addImage(CUADROS, 'PNG', 18, 1, 2, 2);
        doc.addImage(marca, 'PNG', 1, 6, 19, 25);
        doc.addImage(qRCode, 'PNG', 10, 1, 2.5, 2.5);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.setFont('helvetica')
        doc.text('PACIENTE:', 1, 4)
        doc.text(patient.nombrePaciente + ' ' + patient.apellidoPaterno + ' ' + patient.apellidoMaterno, 3, 4)
        doc.text('EDAD: ', 10.6,4);
        //SETEAT EDAD
        doc.text(patient.edad + ' AÑOS', 11.6, 4);
        doc.text('GÉNERO: ', 13.5, 4);
        doc.text(patient.genero, 15.5, 4);
        doc.text('FECHA DE NACIMIENTO: ', 1, 4.5);
        doc.text('FECHA EN QUE SE REALIZÓ: ', 1, 5 );
        doc.text( fechaSplited , 6.2, 5 );
        doc.text( realizo, 15, 26.6 )
        doc.text( cedula, 15, 26.9 )
        doc.text('SOLICITÓ: ',  13.5, 4.5);
        doc.text( SOLICITO , 15.5, 4.5);
        doc.addImage(piePagina, 1, 27, 18, 1)
        // doc.text(this.especialidadUSG, 10, 28.5)
        // Setear Fecha paciente
        const nacimiento = String(patient.fechaNacimientoPaciente)
        if (nacimiento == 'undefined' || nacimiento == '') {
          doc.text('-- / --- / ----', 5.5, 4.5);
        }else {
          let fechaNacimiento;
          fechaNacimiento = nacimiento.slice(0, -14)
          doc.text(fechaNacimiento, 5.5, 4.5);
        }
        doc.setFontSize(14);

        if(  NOMBRE_ESTUDIO.includes('(')){
          const newCharacter = NOMBRE_ESTUDIO.split('(');
          newCharacter[1] = "("+ newCharacter[1];
          doc.text(newCharacter[0], 6, 6);
          doc.text(newCharacter[1], 3, 6.5);
          doc.text(newCharacter[0], 6, 6)
          doc.text(newCharacter[1], 3, 6.5);
          doc.text(newCharacter[0], 6, 6)
          doc.text(newCharacter[1], 3, 6.5);
          doc.text(newCharacter[0], 6, 6)
          doc.text(newCharacter[1], 3, 6.5);
        } else if(NOMBRE_ESTUDIO.length>= 35 ){ 
          doc.text(NOMBRE_ESTUDIO, 3.5, 6)
          doc.text(NOMBRE_ESTUDIO, 3.5, 6)
          doc.text(NOMBRE_ESTUDIO, 3.5, 6)
          doc.text(NOMBRE_ESTUDIO, 3.5, 6)
          doc.text(NOMBRE_ESTUDIO, 3.5, 6)
        }else{
          doc.text(NOMBRE_ESTUDIO, 1, 6)
          doc.text(NOMBRE_ESTUDIO, 1, 6)
          doc.text(NOMBRE_ESTUDIO, 1, 6)
          doc.text(NOMBRE_ESTUDIO, 1, 6)
          doc.text(NOMBRE_ESTUDIO, 1, 6)
        }
      
        doc.text("IMAGENES: ", 1, 7);
        doc.addImage(element.base,  1.6, 7.5, 17, 17);
      });
    }

    doc.setFontSize(8);
    doc.setTextColor(0,0,0);
    doc.text("Horario: Lunes a Domingo 24 horas", 1,28.1);
    doc.text("Fancisco I. Madero #27 Barrio del Rosario Tlayacapan, Mor.", 1,28.4);
    doc.text("Tel. (735) 357 7564", 1,28.7);
    doc.text("atencion.horizonte@gmail.com", 1,29);

    window.open(doc.output('bloburl', '_blank'));
  }
}
