import { Component, OnInit } from '@angular/core';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { SedesService } from 'src/app/services/sedes/sedes.service';


import  Dates  from '../../../classes/dates/date.class';

@Component({
  selector: 'app-comision-socios',
  templateUrl: './comision-socios.component.html',
  styleUrls: ['./comision-socios.component.css']
})
export class ComisionSociosComponent implements OnInit {

  public today = "";
  public sedes = [];
  public estudios = [];
  public totales = 0;

  public busqueda = {
    sede:"",
    fecha:""
}

  constructor(
    private _servicios: ServiciosService,
    private _sedes: SedesService

  ) { }

  ngOnInit(): void {
    this.initDate();
    this.getSedesFecha();
    this.getSedesSelect();
  }


  getSedesFecha() {

    this._servicios.getSellsForDate( this.busqueda)
    .subscribe( (data:any) => {
    
      if(data.ok) {
        this.setEstudios(data.data);
        this.calcTotal();
      } 
    });
  }

  getSellsForDate() {
    
    this.estudios = []

    this._servicios.getSellsForDate(this.busqueda)
    .subscribe((data:any) => {

      if(data.ok) {
        this.setEstudios( data.data );
        this.calcTotal();
      }

    });
  }

  setEstudios( data: any) {

    this.estudios = data;
    /* console.log( this.estudios ) */
  }

  calcTotal () {
  
    this.estudios.forEach((element: any) => {
      this.totales += element.ganancia
    });
  
  }

  initDate( ){
    const date = new Dates();
    this.today = date.getDate();
    this.busqueda.fecha = this.today;

  }

  setSedes( data:any){
    this.sedes = data;
  }

  getSedesSelect(){
    this._sedes.getSedes()
    .subscribe( data => {this.setSedes( data['data'])});
  }


  setSedesToSearch( f ){
    this.busqueda.sede = f;
    this.getSellsForDate();
  }


}
