import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-bitacora-almacen',
  templateUrl: './bitacora-almacen.component.html',
  styleUrls: ['./bitacora-almacen.component.css']
})
export class BitacoraAlmacenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
