<div class="content">
    <p>EN LOS ÚLTIMOS 5 DÍAS ¿HA TOMADO MEDICAMENTOS?</p>
    <form #form='ngForm'>
        <div>
            <input type="radio" value="SI" name="medicamento_acitretina" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ACITETRINA</label>
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_tamoxifeno" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TAMOXIFENO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_dutasterida" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DUTASTERIDA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_finasterida" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> FINASTERIDA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_isotretinoina" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ISOTRETINOINA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_tertraciclina" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TERTRACICLINA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_tretinoina" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TRETINOINA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_talidoida" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TALIDOMIA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_acido_acetil_salicilico" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ACIDO ACETIL SALICILICO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_clopidogrel" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CLOPIDOGREL</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_diflunisal" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DIFLUNISAL</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_fenilbutazona" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> FENILBUTAZONA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_meloxicam" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> MELOXICAM</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_nabumetona" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> NABUMETONA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_naproxeno" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> NAPROXENO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_sulindaco" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SULINDACO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_tenoxicam" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TENOXICAM</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="ACECLOFENACO" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ACECLOFENACO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_acetamicin" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ACETAMICIN</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_acido_mefenamico" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ACIDO MEFENÁMICO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_diclofenaco" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DICLOFENACO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_dexibuprofen" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DEXIBUPROFEN</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_flubiprofeno" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> FLUBIPROFENO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_ibuprofeno" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> IBUPROFENO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_indometacina" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> INDOMETACINA</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_ketoprofeno" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> KETOPROFENO</label> 
        </div>
        <div>
            <input type="radio" value="SI" name="medicamento_ketorolaco" ngModel (blur)="formularioMedicamentos(form)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> KETOROLACO</label> 
        </div>
    </form>
</div>
