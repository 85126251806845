import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-riesgo-de-caida',
  templateUrl: './riesgo-de-caida.component.html',
  styleUrls: ['./riesgo-de-caida.component.css']
})
export class RiesgoDeCaidaComponent implements OnInit {

  @Output() totalRiesgoCaida = new EventEmitter<number>();

  constructor() { }

  public riesgo={
    valores:""
  }
  public rango={
    uno:"",
    dos:"",
    tres:"",
    cuatro:"",
    cinco:"",
    seis:"",
    siete:"",
    ocho:"",
    nueve:"",
    diez:"",
    once:"",
    doce:""
  }

  public suma=0;

  public evaluacion = false

  ngOnInit(): void {
  }

  habilitarRiesgo(){
    if (this.riesgo.valores=="si"){
          this.evaluacion=true;
    }
  }

  addRiesgoCaida(){
    this.totalRiesgoCaida.emit( this.suma );
  }

  monstrarRangoRiesgo(){

    if(this.rango.uno=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.dos=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.tres=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.cuatro=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.cinco=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.seis=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.siete=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.ocho=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.nueve=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.diez=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.once=="si"){
      this.suma= this.suma +1;
    }
    if(this.rango.doce=="si"){
      this.suma= this.suma +1;
    }

if(this.suma==0){ 
   Swal.fire({
              icon: 'success',
              title: '',
              text: 'NO EXISTE RIESGO DE CAIDA',
            })

            this.addRiesgoCaida();

            this.limpiarRadios();

    this.evaluacion= false;
    //this.riesgo.valores=""
    this.suma=0

          }
if(this.suma==1 || this.suma==2){
   Swal.fire({
              icon: 'warning',
              title: '',
              text: 'POSIBLE RIESGO DE CAIDA, SE ENCUENTRA EN UN RANGO DE 1 Y 2',
            })
        this.addRiesgoCaida();
    this.limpiarRadios()
    this.evaluacion= false;
    //this.riesgo.valores=""
    this.suma=0
    
}
if(this.suma >2){
   Swal.fire({
              icon: 'error',
              title: '',
              text: 'RIESGO DE CAIDA CON RANGO MAYOR A 2',
            })
         this.addRiesgoCaida();
         this.limpiarRadios()
    this.evaluacion= false;
    //this.riesgo.valores=""
 this.suma=0
                  
}
    
 
} // fin metodo

   limpiarRadios(){
     this.rango.uno="",
     this.rango.dos="",
     this.rango.tres="",
     this.rango.cuatro="",
     this.rango.cinco="",
     this.rango.seis="",
     this.rango.siete="",
     this.rango.ocho="",
     this.rango.nueve="",
     this.rango.diez="",
     this.rango.once="",
     this.rango.doce=""
   }


}
