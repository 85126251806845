import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class USGService {

    
    public  url = URL;

    constructor(  private _http:HttpClient    ) {
        /* console.log('Los Ultrasonidos Están a punto de ser cargados :D'); */
        
    }


    enviarImagenes( body  ){
        const url = `${this.url}/agregar/regresos/usg`;
        return this._http.post( url, body )
    }

    // getUltrasonidos(){
    //     return this.ultrasonido;
    // }

    getUltrsonidoUSG(){
        const url = `${this.url}/ver/pedidos/ultrasonido`;
        return this._http.get( url);
    }

    verUltrsonidoUSG (id){
        const url = `${this.url}/obtener/ultrasonido/pedido/${id}`;
        return this._http.get( url);
    }

    postPedidosUltra(pedido){
        const url = `${this.url}/nuevo/pedido/ultrasonido`;
        return this._http.post( url, pedido );

    }

    enviarUsgRegreso(img){
        let url = `${this.url}/agregar/regresos/usg`;
        return this._http.post(url,img)
    }
    
      obtenerUsgRecepcionHC(idEstudio){
        //   console.log(id)
        const url = `${this.url}/ver/estudio/regreso/ultrasonido/${idEstudio}`;
        return this._http.get(url);
      }

      obtenerUsgHC(idPaciente){
        //   console.log(id)

        const url = `${this.url}/ver/estudio/regreso/ultrasonido/hc/${idPaciente}`;
        return this._http.get(url);
      }

      obtenerPedidoUsg(idPaciente: any , idEstudio:any  ) {
          const url = `${this.url}/ver/usg/regresos/${idPaciente}/${idEstudio}`;
          return this._http.get(url);
      }

      uploadImageUsg( body:FormData ) {

          const url = `https://api.cloudinary.com/v1_1/juanpatron1630/image/upload`;
          return this._http.post( url, body );
      }
      
}