<div class="contaier-fluid">
    <app-titulos [title]="'REGISTRO DE SERVICIOS'"></app-titulos>
    <div class="card cardPer card-paddin-title container">
        <form [formGroup]="newFormValor">
            <div class="row">
                <div class="col">
                    <div class="container-fluid">
                        <div class="title-card">
                            <div class="mt-3 font-color">DATOS DEL SERVICIO</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-3">
                                <label for="id">NOMBRE DEL SERVICIO</label>
                                <input type="text" class="form-control" formControlName="nombre">
                            </div>
                            <div class="col-md-2">
                                <label for="id">COLOR</label>
                                <input type="color" class="form-control" formControlName="color">
                            </div>
                            <div class="col-md-7">
                                <label for="nombre">DISPONIBILIDAD</label>
                                <div formArrayName="horario" *ngFor="let valor of valoresDeReferencias.controls; let j = index">
                                    <div [formGroupName]="j" class="row">
                                        <div class="col-md-12">
                                            <div class="input-group mb-3">
                                                <div>
                                                    <input type="date" class="form-control" formControlName="dia">
                                                </div>
                                                <div>
                                                    <input type="time" class="form-control" formControlName="horaInicio">
                                                </div>
                                                <div>
                                                    <input type="time" class="form-control" formControlName="horafin">
                                                </div>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1" (click)="addValoreDeReferencia()"> + </span>
                                                </div>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text  color-input text-color-input pointer" id="basic-addon1" (click)="removeValores( j )"> - </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col buttons-content">
                                            <label for="nombre">AGREGAR SEDE</label>
                                            <button type="button" class="btn btn-success" (click)="addValoreDeReferencia()"> Agregar </button>
                                            <button type="button" class="btn btn-danger btn-left" (click)="removeValores( j )"> Quitar </button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-3 offset-4">
                                <button type="submit" class="btn btnPrincipalBS" (click)="onSubmit()">   
                                    Registar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>       
    </div>
</div>


<!-- <div class="contaier-fluid">
    <app-titulos [title]="'REGISTRO DE SERVICIOS'"></app-titulos>
    <div class="card cardPer card-paddin-title container">
        <form [formGroup]="newFormProductos">
            <div class="row">
                <div class="col">
                    <div class="container-fluid">
                        <div class="title-card">
                            <div class="mt-3 font-color">DATOS DEL SERVICIO</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="nombre">SERVICIO</label>
                                <select class="form-control custom-select" formControlName="tipoServicio" (change)="getServicios()">
                                    <option value="" selected> SELECCIONA UNA SERVICIO </option>
                                    <option value="ambulancia"> AMBULANCIA</option>
                                    <option value="consulta"> CONSULTA </option>
                                    <option value="endoscopia"> ENDOSCOPIA</option>
                                    <option value="laboratorio"> LABORATORIO</option>
                                    <option value="xray">RAYOS-X</option>
                                    <option value="resonancia"> RESONANCIA</option>
                                    <option value="tomografias"> TOMOGRAFIA</option>
                                    <option value="ultrasonido"> ULTRASONIDO</option>
                                    <option value="otros"> SERVICIOS EN GENERAL </option> 
                                    <option value="patologia"> PATOLOGIA </option> 
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="id">ID</label>
                                <input type="text" class="form-control" formControlName="idServicio">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="nombre">SERVICIOS</label>
                                <mat-select class="form-control custom-select" #select multiple formControlName="servicio">
                                    <mat-option *ngFor="let item of servicios" [value]="item.ESTUDIO">
                                        {{item.ESTUDIO}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col-md-6">
                                <label for="nombre">PRECIO DE VENTA </label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3">$</span>
                                    </div>
                                    <input type="text" class="form-control" formControlName="costo">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3" style="border-bottom-right-radius: 10px;
                                            border-top-right-radius: 10px;">MX</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="nombre">PROFESIONAL MEDICO RESPONSABLE  </label>
                                <select class="form-control custom-select" formControlName="profesionalAREalizar">
                                    <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                    <option [value]="item.NOMBRE" *ngFor="let item of doctores" >{{item.NOMBRE}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="nombre">SEDE </label>
                                <mat-select class="form-control custom-select" #select2 multiple formControlName="sede">
                                    <mat-option *ngFor="let item of sedes" [value]="item._id">
                                        {{ item.encargado }} | {{ item.nomenclatura }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div formArrayName="sede" *ngFor="let valor of valoresSedes.controls; let j = index" >
                            <div  [formGroupName]="j">
                                <div class="row mb-3">
                                    <div class="col-md-2">
                                        <label for="nombre">SEDE </label>
                                    </div>
                                    <div class="col-md-4">
                                        <select class="custom-select mayus"  id="inputGroupSelect01"  formControlName="sede" >
                                            <option [value]="item._id"   *ngFor="let item of sedes" > {{ item.encargado }} | {{ item.nomenclatura }} </option>
                                          </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="nombre">DISPONIBILIDAD </label>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <ngx-multiple-dates [matDatepicker]="picker" placeholder="FECHAS DISPONIBLES" >
                                            </ngx-multiple-dates>
                                            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker type="datetime" #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-1">
                                        <i class="fa fa-trash-alt colorRed pointer"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="nombre">DESCRIPCIÓN </label>
                                <textarea type="text" class="form-control" formControlName="descripcion"></textarea>
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                            </div> 
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="nombre">PARTICIPANTES </label>
                                <textarea type="text" class="form-control" formControlName="participante"></textarea>
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                            </div> 
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 mb-3">
                                <label for="nombre">PRODUCTOS, MATERIALES, MEDICAMENTOS Y OTROS SERVICIOS </label>
                                <input type="text" class="form-control" (keyup)="buscarProductosYMateriales($event)" placeholder="BUSCADOR DE PRODUCTOS Y MATERIALES" data-bs-toggle="dropdown" >
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                                <div class="card-select mt-4" style="overflow-y: scroll;height: 87px;">
                                    <div class="row" *ngFor="let producto of productsAndServiceDB" >
                                        <div class="col-md-12">
                                            <p class="text-product text-center pointer"  (click)="addANewMaterial(producto)" > {{producto.nombre}} - {{producto.nombre_comercial}}  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-2">NO</div>
                                    <div class="col-8">ARTICULOS</div>
                                </div>
                                <div class="row mt-3" *ngFor="let item of productsAndServiceJson; index as i">
                                    <div class="col-2 text-center">
                                        {{i}}
                                    </div>
                                    <div class="col-2">
                                        <input type="text" class="form-control" name="nombrePaciente" id="validationCustom01" (blur)="addACantidad($event.target.value, i, item._id)">
                                    </div>
                                    <div class="col-8">
                                        {{item?.nombre}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 offset-4">
                                <button type="submit" class="btn btnPrincipalBS" (click)="onSubmit()">   
                                    Registar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>       
    </div>
</div> -->
<!-- <div class="contaier-fluid">
    <app-titulos [title]="'REGISTRO DE SERVICIOS'"></app-titulos>
    <div class="card cardPer card-paddin-title container">
        <form [formGroup]="newFormProductos">
            <div class="row">
                <div class="col">
                    <div class="container-fluid">
                        <div class="title-card">
                            <div class="mt-3 font-color">DATOS DEL SERVICIO</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="nombre">NOMBRE </label>
                                <input type="text" class="form-control" formControlName="nombre">
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="id">ID</label>
                                <input type="text" class="form-control" formControlName="idServicio">
                                <div class="invalid-feedback">
                                    EL Apellido paterno es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="nombre">SERVICIOS </label>
                                <select class="form-control custom-select" formControlName="tipoServicio">
                                    <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                    <option value="AMBULANCIA">AMBULANCIA</option>
                                    <option value="CONSULTA">CONSULTA</option>
                                    <option value="ENDOSCOPIA">ENDOSCOPIA</option>
                                    <option value="PATOLOGIA">PATOLOGIA</option>
                                    <option value="RAYOS-X">RAYOS-X</option>
                                    <option value="LABORATORIO">LABORATORIO</option>
                                    <option value="QUIROFANO">QUIROFANO</option>
                                    <option value="RESONANCIA">RESONANCIA</option>
                                    <option value="ULTRASONIDO">ULTRASONIDO</option>
                                    <option value="TOMOGRAFIA">TOMOGRAFIA</option>
                                    <option value="SERVICIOS EN GENERAL">SERVICIOS EN GENERAL</option>
                                    <option value="OTROS">OTROS</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="nombre">PRECIO DE VENTA </label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3">$</span>
                                    </div>
                                    <input type="text" class="form-control" formControlName="costo">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3" style="border-bottom-right-radius: 10px;
                                            border-top-right-radius: 10px;">MX</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <!-- <div class="col-md-6">
                                <label for="nombre">CATEGORIA </label>
                                <select  class="form-control custom-select" formControlName="categoria">
                                    <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                    <option value="URGENTE">URGENTE</option>
                                    <option value="PROGRAMADO">PROGRAMADO</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="nombre">PROFESIONAL MEDICO RESPONSABLE  </label>
                                <select class="form-control custom-select" formControlName="profesionalAREalizar">
                                    <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                    <option [value]="item.NOMBRE" *ngFor="let item of doctores" >{{item.NOMBRE}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="nombre">SEDE </label>
                                <select  class="form-control custom-select" formControlName="sede">
                                    <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                    <option value="URGENTE">URGENTE</option>
                                    <option value="PROGRAMADO">PROGRAMADO</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label for="nombre">HORARIO </label>
                            <div class="input-group">
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="Todos los dias" (change)="addSchedule('Todos los dias')" name="Todos los dias">
                                    <label class="form-check-label" for="inlineCheckbox1">TODOS LOS DIAS</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="lunes" (change)="addSchedule('lunes')" name="lunes">
                                    <label class="form-check-label" for="inlineCheckbox1">LUNES</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1"  value="martes" (change)="addSchedule('martes')" name="martes">
                                    <label class="form-check-label" for="inlineCheckbox1">MARTES</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1"  value="miercoles" (change)="addSchedule('miercoles')" name="miercoles"value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">MIERCOLES</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10x;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="jueves"  (change)="addSchedule('jueves')" name="jueves">
                                    <label class="form-check-label" for="inlineCheckbox1">JUEVES</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="viernes" (change)="addSchedule('viernes')" name="viernes">
                                    <label class="form-check-label" for="inlineCheckbox1">VIERNES</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="sabado" (change)="addSchedule('sabado')" name="sabado" >
                                    <label class="form-check-label" for="inlineCheckbox1">SABADO</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="domingo" (change)="addSchedule('domingo')" name="domingo">
                                    <label class="form-check-label" for="inlineCheckbox1">DOMINGO</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="nombre">DESCRIPCIÓN </label>
                                <textarea type="text" class="form-control" formControlName="descripcion"></textarea>
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                            </div> 
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="nombre">PARTICIPANTES </label>
                                <textarea type="text" class="form-control" formControlName="participante"></textarea>
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                            </div> 
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="nombre">Color </label>
                                <mat-form-field>
                                    <ngx-multiple-dates [matDatepicker]="picker" placeholder="Excluded Dates" name="excludedDates" formControlName="color">
                                    </ngx-multiple-dates>
                                    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <!-- <input type="color" formControlName="color" >
                            </div> 
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 mb-3">
                                <label for="nombre">PRODUCTOS, MATERIALES, MEDICAMENTOS Y OTROS SERVICIOS </label>
                                <input type="text" class="form-control" (keyup)="buscarProductosYMateriales($event)" placeholder="BUSCADOR DE PRODUCTOS Y MATERIALES" data-bs-toggle="dropdown" >
                                <div class="invalid-feedback" >
                                    EL nombre es requerido.
                                </div>
                                <div class="card-select mt-4" style="overflow-y: scroll;height: 87px;">
                                    <div class="row" *ngFor="let producto of productsAndServiceDB" >
                                        <div class="col-md-12">
                                            <p class="text-product text-center pointer"  (click)="addANewMaterial(producto)" > {{producto.nombre}} - {{producto.nombre_comercial}}  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-2">NO</div>
                                    <div class="col-8">ARTICULOS</div>
                                </div>
                                <div class="row mt-3" *ngFor="let item of productsAndServiceJson; index as i">
                                    <div class="col-2 text-center">
                                        {{i}}
                                    </div>
                                    <div class="col-2">
                                        <input type="text" class="form-control" name="nombrePaciente" id="validationCustom01" (blur)="addACantidad($event.target.value, i, item._id)">
                                    </div>
                                    <div class="col-8">
                                        {{item?.nombre}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 offset-4">
                                <button type="submit" class="btn btnPrincipalBS" (click)="onSubmit()">   
                                    Registar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>       
    </div>
</div> -->
<!-- <div class="container-fluid max-width">
    <div class="card">
        <div class="row">
            <div class="col-md-12">
        
                <form #form='ngForm' (ngSubmit)="onSubmit(form)">
                    <mat-horizontal-stepper linear #stepper >
                        <mat-step label="DATOS DEL SERVICIO">
                            
                            <h3 class="title-secondary" > DATOS DEL SERVICIO </h3>
                            
                            <div class="row mt-4">
                                <div class="col-md-3 mt-4">
                                    <label> NOMBRE: </label>
                                </div>
                                <div class="col-md-3 mt-4">
                                    <input matInput maxlength="50" type="text" required name="nombre" ngModel id="nombre" class="form-control input-censur">
                                </div>
        
                                <div class="col-md-3 mt-4">
                                    <label> ID:  </label>
                                </div>
        
                                <div class="col-md-3 mt-4">
                                    <input matInput maxlength="50" type="text" disabled name="idServicio" ngModel id="nombre" class="form-control input-censur">
                                </div>
        
                                <div class="col-md-3 mt-4">
                                    <label>    TIPO DE SERVICIO:  </label>
                                </div>
                            
                                <div class="col-md-3 mt-4">
                                    <input matInput maxlength="50" type="text" required name="tipoServicio" ngModel id="nombre" class="form-control input-censur">
                                </div>        
                                
                                <div class="col-md-3 mt-4">
                                    <label>    COSTO:  </label>
                                </div>
                            
                                <div class="col-md-3 mt-3">
                                    <div class="input-group">
        
                                        <input matInput maxlength="50" min="1" type="number" required name="costo" ngModel id="nombre" class="form-control input-censur">
                                    </div>
                                </div>        
        
                                
                                <div class="col-md-3 mt-3">
                                    <label>    CATEGORIA:  </label>
                                </div>
                            
                                <div class="col-md-3 mt-3">
                                    <select  matInput   required name="categoria" ngModel id="categoria" class="form-control input-censur" >
                                        <option value="urgente"> URGENTE </option>
                                        <option value="programado"> PROGRAMADO </option>
                                    </select>

                                </div>      
        
                                <div class="col-md-3 mt-3">
                                    <label>    PERSONAL A REALIZAR:  </label>
                                </div>
                            
                                <div class="col-md-3 mt-4">
                                    <input matInput  type="text" required name="profesionalARealizar" ngModel id="categoria" class="form-control input-censur">
                                </div>      
        
                            </div>
        
                            <div class="row mt-5">

                                <div class="col-md-1">
                                    <p> HORARIOS </p>
                                </div>
                                <div class="col-md-2">
                                    <input type="checkbox" id="todosLosDias" class="mr-1"  value="todosLosDias" (change)="addSchedule('todosLosDias')" name="todosLosDias" />
                                    <label for="todosLosDias"> TODOS LOS DÍAS </label>
                                </div>
                                <div class="col-md-1">
                                    <input type="checkbox" id="lunes" class="mr-1"  value="lunes" (change)="addSchedule('lunes')" name="lunes" />
                                    <label for="lunes"> LUNES </label>
                                </div>
                                <div class="col-md-1">
                                    <input type="checkbox" id="martes" class="mr-1"  value="martes" (change)="addSchedule('martes')" name="martes" />
                                    <label for="martes"> MARTES </label>
                                </div>
                                <div class="col-md-2">
                                    <input type="checkbox" id="miercoles" class="mr-1"  value="miercoles" (change)="addSchedule('miercoles')" name="miercoles" />
                                    <label for="miercoles"> MIERCOLES </label>
                                </div>
                                <div class="col-md-1">
                                    <input type="checkbox" id="jueves" class="mr-1"  value="jueves" (change)="addSchedule('jueves')" name="jueves" />
                                    <label for="jueves"> JUEVES </label>
                                </div>
                                <div class="col-md-1">
                                    <input type="checkbox" id="viernes" class="mr-1"  value="viernes" (change)="addSchedule('viernes')" name="viernes" />
                                    <label for="viernes"> VIERNES </label>
                                </div>
                                <div class="col-md-1">
                                    <input type="checkbox" id="sabado" class="mr-1"  value="sabado" (change)="addSchedule('sabado')" name="sabado" />
                                    <label for="sabado"> SABADO </label>
                                </div>
                                <div class="col-md-1">
                                    <input type="checkbox" id="domingo" class="mr-1"  value="domingo" (change)="addSchedule('domingo')" name="domingo" />
                                    <label for="domingo"> DOMINGO </label>
                                </div>
                            
                            </div>
        
                        </mat-step>

                        <mat-step label="DESCRIPCIÓN DEL SERVICIO">

                            <h3 class="title-secondary"> DESCRIPCIÒN DEL SERVICIO</h3>
        
                            <div class="row">
        
        
                                <div class="col-md-12 mt-2">
                                    <label>    DESCRIPCIÓN:  </label>
                                </div>
                            
                                <div class="col-md-12 mt-2">
                                    <textarea name="descripcion" ngModel id="descripcion" cols="170" rows="1"></textarea>
                                </div>
                                
                                
                                <div class="col-md-12 mt-2">
                                    <label>    PARTICIPANTES:  </label>
                                </div>
                            
                                <div class="col-md-12 mt-2">
                                    <textarea name="participantes"  required ngModel id="participantes" cols="170" rows="1"></textarea>
                                </div>
                            
                                <div class="col-md-7 mt-2 mb-2">
                                    <label>    PRODUCTOS O MATERIALES:  </label>

                                    <input type="text" id="" name="txtSearchInput"  [(ngModel)]="txtSearchInput"  placeholder="Buscar..." (keyup)="buscarProductosYMateriales()" class="form-control input-censur"/>
                                    
                                    <div class="card-select">
                                        <div class="row" *ngFor="let producto of productsAndServiceDB" >
                                            <div class="col-md-12">
                                                <p class="text-product text-center pointer"  (click)="addANewMaterial(producto.nombre)" > {{producto.nombre}}  </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-md-5">
                                    <div class="row mt-2" *ngFor="let productoOServicio of productsAndServiceJson; let i = index" >
                                        <div class="col-md-5">
                                            {{ productoOServicio  }}
                                        </div>
                                        <div class="col-md-5">
                                            <input type="number" #fcantidad name="cantidad" (blur)="addACantidad(fcantidad, productoOServicio)" ngModel /> 
                                        </div>
                                        <div class="col-md-2">
                                            <i class="fa fa-times colorRed pointer" (click)="deleteProductFromJson(i)"></i>
                                        </div>
                                    </div>

                                </div>
        
                            </div>
        
                            <div class="row">
                                <div class="col-md-3 offset-4">
                                    <button type="submit" class="btn btnPrincipalBS"> 
        
                                        Registar
                                    </button>
                                </div>
                            </div>
                            
                        </mat-step>
                    </mat-horizontal-stepper>
                </form>
            </div>
            </div>
        
        </div>
</div> -->
