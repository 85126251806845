import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bitacora-salidas',
  templateUrl: './bitacora-salidas.component.html',
  styleUrls: ['./bitacora-salidas.component.css']
})
export class BitacoraSalidasComponent implements OnInit {

  public salidas = {
    codeQr_img: "",
    costoReal: 0,
    factura: "",
    fecha_caducidad: "",
    lote: "",
    lote_unitario: "",
    motivoDesecho: "",
    precioVenta: 0,
    procesoDesecha: "",
    productosFI: "",
    proveedor: "",
    seDesecha: "",
    status: ""
  };
  constructor(
    private _bancosService: BancoService,
    private _spinner: NgxSpinnerService,
    private _alemacenService: AlmacenService
  ) { }

  ngOnInit(): void {
    this._spinner.show();
    this.getSAlidasAlmacen();
  }

  getSAlidasAlmacen(){
    this._bancosService.getSalidasAlmacen()
    .subscribe( (data:any) => {
      if(data.ok){
        this._spinner.hide();
        this.salidas = data.data;
      }
    },
    (error) => {
      this._spinner.hide();
      Swal.fire('Error', 'Hubo un error intenta mas tarde', 'error');
    }
    )
  }


  changeStatus( id: string ){
  
    const changeStatus = {  status: 'desechado'.toLocaleUpperCase()  }
    this._alemacenService.actualizarProductosStock( id,  changeStatus  )
    .subscribe( (data:any) => {
      if(data.ok){
        this.getSAlidasAlmacen();
      }
    
    }, (error) => {
      this._spinner.hide();
      Swal.fire('Error',error.message , 'error');
    })

  }

}
