import { Component, OnInit } from '@angular/core';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-bitacora-pedidos',
  templateUrl: './bitacora-pedidos.component.html',
  styleUrls: ['./bitacora-pedidos.component.css']
})
export class BitacoraPedidosComponent implements OnInit {

  public hojasSErvcios = [];

  constructor(
    public _banco:BancoService
  ) { }


  ngOnInit(): void {
    this.getDataBitacora();
  }

  // fuincion que hace la peticion de los datos a la api
  getDataBitacora() {
    this._banco.bitacoraLaboratorio()
    .subscribe( (data: any) =>  this.setDatabitacora(data.data));
  }

  setDatabitacora(data) {
    this.hojasSErvcios = data;
  }

}
