import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-indicadores-geograficos',
  templateUrl: './indicadores-geograficos.component.html',
  styleUrls: ['./indicadores-geograficos.component.css']
})
export class IndicadoresGeograficosComponent implements OnInit {

  @Output() indicadores = new EventEmitter<string>();
  public lugar = false;
  public fecha = false;
  public nacionalidad = "";
  public viajes="";
  formulario: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.formulario = new FormGroup({
      nacionalidad: new FormControl('SI'),
      viajes: new FormControl('NO'),
   });
   this.indicadores.emit(this.formulario.value);
  }

  activarLugar(tipo){
    if(tipo == 'SI'){
      this.lugar = false;
      this.nacionalidad = "SI"
    }else{
      this.lugar = true;
      this.nacionalidad = ""
    }
  }

  activarFecha(tipo){
    if(tipo == 'SI'){
      this.fecha = true;
      this.viajes = ""
    }else{
      this.fecha = false;
      this.viajes = "NO"
    }
  }

  formularioIndicadores(form: NgForm){
    /* console.log(form); */
    
    if(this.nacionalidad == "SI"){
      form.value.nacionalidad = this.nacionalidad;
    }
    if(this.viajes == "SI"){
      form.value.viajes = this.nacionalidad;
    }
    this.indicadores.emit(form.form.value);
  }

}
