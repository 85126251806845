import { Component, OnInit } from '@angular/core';
import Dates from 'src/app/classes/dates/date.class';

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html',
  styleUrls: ['./servicio.component.css']
})
export class ServicioComponent implements OnInit {
  
  public today = ""

  constructor() { }

  ngOnInit(): void {
    this.obtenerFecha();
  }

  obtenerFecha(){
    const datesToday = new Dates();
    this.today = datesToday.getDate();
  }

}
