<div class="">
    <!-- Content Wrapper

  <app-side-bar (notificarViaChat)="estadoChat($event )" [nombreUsuario]="usuario.nombre" class="orden"></app-side-bar>
-->
    <div class="">

        <!-- [nombreUsuario]="usuario.nombre" -->
        <section >
            <app-nav-bar></app-nav-bar>
        </section>
        <section>
            <app-nav-bar-horizonte></app-nav-bar-horizonte>
        </section>
        <section class="" >
            <router-outlet></router-outlet>
            <br>
        </section>
        <section >
            <footer class="noprint">
                <h6 style="text-align: center!important; padding: 2rem; display: flex; position: fixed; bottom: -2rem;  margin-left: -1em;">Powered by INITBOX&copy;</h6>
                <!-- <h6 style="text-align: center; padding: 2rem; display:flex">Desarrollo por Juan Patrón, Guillermo Urzúa, Nelson Barrera, Ulises Suarez, Ilmers Gonzalez, Victor Aragon, Katia Amaro y Samoel Andres</h6> -->
            </footer>
        </section>
        <!-- <section>
                <div id="boton_chat" class="noprint">
                    <button type="button" class="btn btn-circle btn-xl" (click)="abrirVentanaChat($event)" data-toggle="modal" data-target="#ChatModal" data-backdrop="false"> 
                        <img src="../../../assets/icons/comment.svg" width="45" height="45" />
                    </button>
                </div>
        </section> -->
        <div>
            <a class="btn-cht pointer" (click)="abrirVentanaChat($event)" data-toggle="modal" data-target="#ChatModal" data-backdrop="false">
                <img src="../../../assets/icons/comment.svg" class="center" width="45" height="45" />
            </a>
        </div>
        <!-- En esta seccio ira el dashboars y sus compoentes  -->


        <div class="modal" id="ChatModal" tabindex="-1" aria-labelledby="ChatModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content" id="ChatModal">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Chat</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                    </div>
                    <div class="modal-body" style="padding: 0px;">
                        <app-chat (cerrarChat)="cerrarVentanaChat($event)"></app-chat>
                    </div>
                </div>
            </div>
        </div>
        <!-- Inicia la seccion del chat -->

        <!--app-search-bar></app-search-bar -->
        <!--footer class="fbg-dark text-dark" style="background-color: white; margin-top: 0em !important;">
        </footer>  -->

    </div>
</div>