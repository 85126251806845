import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';;


@Component({
  selector: 'app-detalle-seguimientos-almacen',
  templateUrl: './detalle-seguimientos-almacen.component.html',
  styleUrls: ['./detalle-seguimientos-almacen.component.css']
})
export class DetalleSeguimientosAlmacenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
guardar( forma:NgForm){

}
}
