<div class="col-md-12">
        <select name="servicio" class="input-censur" id="" #servicio (change)="irAUnServicio( servicio.value  )">
            <option   value="" selected> Selecciona un servicio  </option>
            <option   value="ambulancia"> Ambulancia</option>
            <option   value="consulta"  > Consulta general </option>
            <option   value="consultaEspecialista">  Consulta con especialista </option>
            <option   value="endoscopia"> Endoscopía</option>

            <option   value="laboratorio"> Laboratorio</option>

            <option   value="xray">     Rayos X </option>
            <option   value="resonancia"> Resonancia</option>
            <option   value="tomografias"> Tomografía</option>

            <option   value="ultrasonido"> Ultrasonido</option>

            <option   value="otros"> Servicios generales </option> 
            <option   value="patologia"> Patología </option> 
        </select>
    
</div>
