import { Component, OnInit } from '@angular/core';
import { Personal } from 'src/app/interfaces/usuario.interface';
import {  ModulesService } from '../../services/modules/modules.service';
import { Router } from '@angular/router';

/* import { randomInRange } from '../../functions/storage.funcion'; */
// require('canvas-confetti');

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  public pagina = 0;
  public totalAmbulancia: string;
  public usuario: Personal;
  public modules:any[]=[];

  constructor(public router: Router,
              public modulesService: ModulesService){}

  ngOnInit(): void {
    this.getRoleLocalStorage();
    
  }

  getRoleLocalStorage(){
    // SETEA LOS DATOS DEL USURAIO EN EL LOCALSTORAGE
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    if( this.usuario == null || this.usuario == undefined){
      return;
    }else{
      this.getModuleByRole();
    }
    /* if(this.usuario.nombre == 'Liliana De Aragon'){
      this.getFuncion();
    } */
  }

  getModuleByRole(){
    this.modulesService.getModules( this.usuario._id ).subscribe( (data:any)  => {
      //  OBTENEMOS TODOS LOS MODULOS DEL USUARIO
      this.modules = data.data;
      this.totalAmbulancia = data.data.results;
    })
  }

  /* getFuncion(){
    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { particleCount:100,startVelocity: 20, spread: 360, ticks: 60, zIndex: 0, scalar:0,resize: true,
      useWorker: true };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function() {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti.create()(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
      confetti.create()(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
    }, 250);
  } */
  
}
