import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reaccion-toxicidad',
  templateUrl: './reaccion-toxicidad.component.html',
  styleUrls: ['./reaccion-toxicidad.component.css']
})
export class ReaccionToxicidadComponent implements OnInit {

  public reaccionTL = []
  public reaccionTM = []
  public reaccionTS = []
  public value='';
  public value2 = '';
  public value3 = '';
  @Output() reaccionesToxicidadlleve = new EventEmitter();
  @Output() reaccionesToxicidadmoderada = new EventEmitter();
  @Output() reaccionesToxicidadsevera = new EventEmitter();
  
  @Output() otrosSintomasLeve = new EventEmitter();
  @Output() otrosSintomasModerada = new EventEmitter();
  @Output() otrosSintomasSevera = new EventEmitter();
  @Output() LabCalcio = new EventEmitter();
  @Output() LabMagnesio = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ToxicidadLeve(event){
    this.reaccionTL.push(event);
    this.reaccionesToxicidadlleve.emit(this.reaccionTL)
  }

  ToxicidadModerada(event){
    this.reaccionTM.push(event);
    this.reaccionesToxicidadmoderada.emit(this.reaccionTM)
  }

  ToxicidadSevera(event){
    this.reaccionTS.push(event);
    this.reaccionesToxicidadsevera.emit(this.reaccionTS)
  }

  LaboCalcio(form: NgForm){
    this.LabCalcio.emit(form.form.value.calcio)
  }

  LaboMagnesio(form: NgForm){
    this.LabMagnesio.emit(form.form.value.magnesio)
  }

  otroModerado(form:NgForm){
    this.value2 = form.form.value.otroModerado
    this.otrosSintomasModerada.emit(this.value2)
  }

  otroSevero(form:NgForm){
    this.value3 = form.form.value.otrosSintomasSevera
    this.otrosSintomasSevera.emit(this.value3)
  }

}
