import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';

 
@Injectable({
  providedIn: 'root'
})
export class PaquetesService {

  private url = URL;

  constructor( private _http: HttpClient  ) { }

  getPaquetes(){
    let uri = `${this.url}/paquetes`;
    return this._http.get( uri );

  }

  getPaqueById( id ){

    const uri = `${this.url}/paquete/${id}`;
      return this._http.get( uri );
  }

  getPaquetesSolicitud(){
    const uri =  `${this.url}/paquetes`;
    return  this._http.get(uri);

  }


  // esta consulta genera la tabla que nos dice quien vendio el paquete y a que paciente asi como la info de la compra

  setPaquete( paqueteUsuario  ) {

    // esta funcion inserta en la tabla relacional los datos necesarios

    const uri = `${this.url}/nuevo/paquete/usuario`;

   return this._http.post( uri , paqueteUsuario );

  }

  actualizarEstadoMembresia(id, body){
    const url = `${this.url}/cambiar/estado/membresia/${id}`;
    return this._http.put( url, body );
  }

  finalizarEstadoMembresia(id, body){
    const url = `${this.url}/finalizar/paquete/${id}`;
    return this._http.put( url, body );
  }
  agregarPaquete(paquete, id){
    
    const url = `${this.url}/agregar/paquetes/${id}`;
    return this._http.post( url, paquete );
  }

  //Obtener el paquete seleccionado del paciente

  obtenerPaquete(id){
    return this._http.get(`${this.url}/paquete/paciente/${id}`);
  }

  obtenerFolio(){
    return this._http.get(`${this.url}/contar/paquetes/folios`);
  }
  
  cambiarMembresiaPaque(id, membresia){
    return this._http.put(`${this.url}/actualizar/estado/paquete/${id}`, membresia)
  }
  _obtenerPagos(id){
    return this._http.get(`${this.url}/pagar/maternidad/${id}`);
  }
  getMedicos(){
    return this._http.get(this.url+'/medicos');
  }

  agregarConsulta(body:any,tipo,id){
    const ur = (`${this.url}/paciente/paquete/${tipo}/${id}`);

    return this._http.post(ur,body);
  }

  verPaquetesDePaciente( id ){
    const url = `${this.url}/ver/paquetes/paciente/${id}`;
    return this._http.get( url );
  }


  actualizarAdelanto(id,body){
    const url = `${this.url}/actualizar/acumulado/pago/${id}`;
    return this._http.put( url ,body);
  }

}
