import { Component, OnInit } from '@angular/core';
import { BancoService  } from '../../../services/bancodeSangre/banco.service';

@Component({
  selector: 'app-listado-de-sedes',
  templateUrl: './listado-de-sedes.component.html',
  styleUrls: ['./listado-de-sedes.component.css']
})
export class ListadoDeSedesComponent implements OnInit {
  public pacientes2:any[] = [];


  constructor(public _BancoService: BancoService) { }

  ngOnInit(): void {
  }

}
