<div class="container-fluid">
    <section id="ingreso">
        <div class="row">
            <div class="col-md-8">
                <app-titulos [title]="'HOJA DE INDICACIONES'" [rol]="'doctor'"></app-titulos>
            </div>
            <div class="col">
                <p>FECHA: {{ hospitalizados.fechaIngreso }} </p>
                <p>HORA:  {{ hospitalizados.horaIngreso }}</p>
            </div>
        </div>
    </section>
    
    <app-ficha-info-hopitalizado [rol]="'doctor'"></app-ficha-info-hopitalizado>
    <ficha-info-user  [rol]="'doctor'"  [pacienteInfo]="hospitalizados.idPaciente" ></ficha-info-user>

    <div class="row">
        <div class="col-xs-2">
            <div class="card signos" id="card">
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Talla: <span> {{ hospitalizados.idConsulta.talla }} </span> m</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Peso: <span>  {{ hospitalizados.idConsulta.peso }} </span> Kg</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>IMC: <span> {{ hospitalizados.idConsulta.imc }} </span> Kg/m<sup>2</sup> </p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Sistolica: <span> </span> </p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Diastolica: <span> </span> </p>
                    </div>
                </div> -->
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad < 9">
                    <div class="col-6">
                        <p>FR: <span> {{paciente.fr}} </span> RPM</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <9">
                    <div class="col-6">
                        <p>FC: <span> {{paciente.fc}} </span> LPM</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div> -->
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>TEMP: <span> {{ hospitalizados.idConsulta.temp }} </span> °C</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.C.: <span> {{ hospitalizados.idConsulta.pc }} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.A.: <span> {{ hospitalizados.idConsulta.pa }} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.T.: <span> {{ hospitalizados.idConsulta.pt }} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p *ngIf="paciente.edad < 8">APGAR: <span> {{paciente.apgar}} </span></p>
                    </div>
                </div> -->
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p>SaO: <span> {{paciente.sao}} </span> %</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div> -->
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>SpO<sub>2</sub>: <span>{{paciente.pao}}</span>%SpO<sub>2</sub></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div> -->
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Glucosa: <span> {{ hospitalizados.idConsulta.glucosa }} </span> mg/dl</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p> Alergias:<span>   </span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" id="historia">
                <div role="tabpanel" class="row">
                    <div class="col-4" id="indicadoress">
                        <ul class="nav flex-column nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#dieta" aria-controls="#dieta"> 
                                    DIETA
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#soluciones" data-toggle="tab" aria-controls="#soluciones"> 
                                     SOLUCIONES PARENTERALES Y ANEXOS
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#medicamentos" data-toggle="tab" aria-controls="#medicamentos">
                                    MEDICAMENTOS
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#enfermeria" data-toggle="tab" aria-controls="#enfermeria">
                                    ENFERMERIA
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#sondasycatereres" data-toggle="tab" aria-controls="#sondasycatereres">
                                    SONDAS Y CATETERES
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#hemotransfusiones" data-toggle="tab" aria-controls="#hemotransfusiones">
                                    HEMOTRANSFUSIONES
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#laboratorios" data-toggle="tab" aria-controls="#laboratorios">
                                    LABORATORIO Y GABINETE
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#interconsultas" data-toggle="tab" aria-controls="#interconsultas">
                                    INTERCONSULTAS
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#traslado" data-toggle="tab" aria-controls="#traslado">
                                    TRASLADO
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#egreso" data-toggle="tab" aria-controls="#egreso">
                                    EGRESO
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#generales" data-toggle="tab" aria-controls="#generales">
                                    GENERALES
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-8">
                        <div class="tab-content">
                            <div role="tabpanel" class="tab-pane active" id="dieta">
                                <select name="dieta" #dieta  (change)="addItem( dieta.value, 'dieta' )"  id="">
                                    <option value="">SELECCIONE UNA DIETA</option>
                                        <option  value="DIETA BLANDA"> DIETA BLANDA </option>
                                        <option  value="DIETA SOLIDA"> DIETA SOLIDA </option>
                                </select>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="soluciones">
                                <input type="text" placeholder="Buscar solucion">
                                <div class="row">
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">TIEMPO</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">ANEXO</option>
                                        </select>
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="medicamentos">
                                <input type="text" placeholder="Buscar medicamento">
                                <div class="row">
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">TEMPORALIDAD</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">INFUSION</option>
                                        </select>
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="enfermeria">
                               <textarea name="" id="" cols="30" rows="10" ></textarea>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="sondasycatereres">
                                <input type="text" placeholder="Buscar sonda y cateteres">
                                <div class="row">
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">TEMPORALIDAD</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">ANEXO</option>
                                        </select>
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="hemotransfusiones">
                                <input type="text" placeholder="Buscar hemotransfusiones">
                                <div class="row">
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">TEMPORALIDAD</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">INFUSION</option>
                                        </select>
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="laboratorios">
                                <input type="text" placeholder="Buscar servicios" #txtServicio (keyup)="searchServicio( txtServicio.value )"  >
                                <div class="row">
                                    <div class="col">
                                        <input type="date" name="" id="">
                                    </div>
                                    <div class="col">
                                        <input type="time" name="" id="">
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="interconsultas">
                                <div class="row">
                                    <div class="col">
                                        <select name="" id="">
                                            <option value="">SELECCIONE UN SERVICIO</option>
                                        </select>
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="traslado">
                                <select name="" id="">
                                    <option value="">TIPO DE TRASLADO</option>
                                </select>
                                <div class="row">
                                    <div class="col">
                                        <input type="date" name="" id="">
                                    </div>
                                    <div class="col">
                                        <input type="time" name="" id="">
                                    </div>
                                </div>
                                <button class="principalButton" data-toggle="modal" data-target="#preregistro" > AGREGAR </button>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="egreso">
                               <input type="date" name="" id="">
                            </div>
                            <div role="tabpanel" class="tab-pane" id="generales">
                               <textarea name="" id="" cols="30" rows="10"></textarea>
                               <div class="container-button-save">
                                   
                                   <button  (click)="createHojaIndicaciones()"  class="principalButton" > GUARDAR  </button>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>