<div class="container">
    <h1>DOCUMENTOS DEL RECEPTOR</h1>
    <section>
        <app-fichas-identificacion [data]="paciente" [rol]="'recepcion'"></app-fichas-identificacion>
        <!-- <app-ficha-info-censur [pacienteInfo]="paciente"></app-ficha-info-censur> -->
    </section>

    <div class="row" id="pointer" style="margin-bottom: 15px;">
        <div class="col" *ngFor="let item of documentosReceptor">
            <h5 (click)="showPdf(item.name,item.base)">{{item.name}}</h5>
<!--             <a href="https://censur.s3.amazonaws.com/{{item}}" download="{{item}}">{{item}}</a> -->
            <!-- <h5>Consentimiento_informado_de_transfusion.pdf</h5> -->
        </div>
        <!-- <div class="col">
            <h5>Solicitud_de_transfusion.pdf</h5>
        </div> -->
    </div>

    <div class="card">
        <ngx-dropzone (change)="onSelect($event)">
            <ngx-dropzone-label>
                AGREGAR ARCHIVOS
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">cd
                <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>
    </div>
    <div class="col text-center">
        <button class="boton" (click)="actualizar()">Actualizar</button>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>