import { Component, OnInit } from '@angular/core';
import { SedesService } from '../../../../services/sedes/sedes.service';

@Component({
  selector: 'app-sede',
  templateUrl: './sede.component.html',
  styleUrls: ['./sede.component.css']
})
export class SedeComponent implements OnInit {

  public sede= [];

  constructor(public sedes:SedesService) { }

  ngOnInit(): void {
    this.obtenerSedes();    
  }

  obtenerSedes(){
    this.sedes.getSedes().subscribe(res => {
      /* this.sede=res['data']  */
      res['data'].forEach((element:any) =>{
        if (element.nomenclatura != "TLYC01" ) {
            this.sede.push(element)
        } else {
          
        }
      })                 
    });
  }

}
