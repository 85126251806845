<div class="container center">

    <div class="col inputsPediatria">
        <label for="talla">Talla (m): </label>
        <input type="text" placeholder="Talla" name="tallaNina" required [(ngModel)]="talla">
        <label for="peso">Peso (kg): </label>
        <input type="text" placeholder="Peso" name="pesoNina" required [(ngModel)]="peso" (change)="obtenerIMC()">
        <label for="imc">IMC: </label>
        <input type="text" placeholder="IMC" name="imcNina" required [(ngModel)]="imc" [value]="imc">
        <label for="pc">P.C. : </label>
        <input type="text" placeholder="P.C." name="pcNino" required [(ngModel)]="pc" (blur)="recorrerEdadMesesNinoPC()">
        <!-- <button class="btn btn-primary">Actualizar Signos</button> -->
    </div>
    <div class="row">
        <!-- Grafica Talla Niña -->
        <div class="col-sm-4 muf">
            <div class="flex">
                <div class="flex-item">
                    <div style="display: block;">
                        <canvas baseChart width="300" height="300" [datasets]="lineChartEstatura" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>

        <!-- Grafica Peso Niña -->
        <div class="col-sm-4">
            <div class="flex">
                <div class="flex-item">
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>

        <!-- Grafica IMC Niña -->
        <div class="col-sm-4 muf">

            <div class="flex">
                <div class="flex-item">
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="lineChartIMC" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>

        <!-- Grafica Perimetro Céfalico -->
        <div class="col-sm-4 muf">

            <div class="flex">
                <div class="flex-item">
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="chartCefalicoNinos" [labels]="cefalicoNinosEjeX" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>

        <!-- Grafica Peso * Talla -->
        <div class="col-sm-4 muf">

            <div class="flex">
                <div class="flex-item">
                    <div style="display: block;">
                        <canvas baseChart width="400" height="400" [datasets]="chartPesoPorTallaNinos" [labels]="pesoPorTallaNinasEjeX" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins"
                            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>

            </div>
        </div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src="..." alt="First slide">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="..." alt="Second slide">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="..." alt="Third slide">
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>


    </div>
</div>