<section class="row">
    <div class="col-md-12" id="titulo">
        <!-- <h1 class="centerxd" id="centerxd">Revisión de la orden de compra</h1> -->
        <app-titulos [title]="'Revisión de la orden de compra'" [rol]="'pago'"></app-titulos>
    </div>
    <!-- <div class="col">
        <h1 class="fecha" id="fecha">{{fecha}}</h1>
    </div> -->
</section>

<section class="row" id="container">
    <div class="col">
        <div class="card" id="card">
            <div class="card-header" id="cardheader">
                <div class="row">
                    <div class="col">Datos del paciente</div>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-2"> Nombre: </div>
                    <div class="col"> {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }}</div>
                </div>
                <div class="row">
                    <div class="col-md-2"> Edad: </div>
                    <div class="col">{{ paciente.edad }}</div>
                </div>
                <div class="row">
                    <div class="col-md-2"> Sexo: </div>
                    <div class="col">{{ paciente.genero | uppercase }}</div>
                </div>

                <div class="row">
                    <div class="col">
                       <div class="form-group">
                            <label for="solicito">Doctor que solicito:</label>
                            <input type="text" name="solicito" id="solicito" [(ngModel)]="infoVenta.doctorQueSolicito" class="form-control" placeholder="Doctor que solicito...">
                        </div> 
                     
                    </div>
                </div>
                <div class="row" id="metodos">
                    <div class="col-md-12">Método de pago</div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <input type="radio" id="efectivo" name="efectivo" value="true" [(ngModel)]="infoVenta.efectivo">
                        <label for="efectivo">Pago en efectivo</label>

                        <div *ngIf="infoVenta.efectivo">
                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoEfectivo" id="montoEfectivo" [(ngModel)]="infoVenta.montoEfectivo" (blur)="calcularNuevoTotalEfectivo()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" id="transferencia" name="transferencia" value="true" [(ngModel)]="infoVenta.transferencia">
                        <label for="transferencia">Transferencia bancaria</label>

                        <div *ngIf="infoVenta.transferencia">

                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTranferencia" id="montoTranferencia" [(ngModel)]="infoVenta.montoTranferencia" (blur)="calcularMontoTransferencia()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" (change)="agregarIva()" id="tarjetaDeCredito" name="tarjetaDeCredito" value="true" [(ngModel)]="infoVenta.tarjetCredito">

                        <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                        <br>
                        <div *ngIf="infoVenta.tarjetCredito">

                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjteCredito" id="montoTarjteCredito" [(ngModel)]="infoVenta.montoTarjteCredito" (blur)="calcularMontoTarjetaCredito()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" (change)="agregarIva()" id="tarjetaDeDebito" name="tarjetaDeDebito" value="true" [(ngModel)]="infoVenta.tarjetaDebito">

                        <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                        <br>
                        <div *ngIf="infoVenta.tarjetaDebito">

                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjetaDebito" id="montoTarjetaDebito" [(ngModel)]="infoVenta.montoTarjetaDebito" (blur)="calcuarMontoTarjetaDebito()">
                        </div>
                    </div>
                </div>
                <div class="row" id="metodos">
                    <div class="col-md-12">Facturación</div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <input type="radio" id="facturacion" name="facturacion" value="true" [(ngModel)]="infoVenta.facturacion">
                        <label for="facturacion">Requiere factura</label>

                        <div *ngIf="infoVenta.facturacion">
                            <select name="catalogo" id="" class="form-control custom-select" (blur)="factura($event.target.value)">
                                <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                <option value="Gastos en general">G03 Gastos en general</option>
                                <option value="Honorarios médicos, dentales y gastos hospitalarios.">D01 Honorarios médicos, dentales y gastos hospitalarios.</option>
                                <option value="Por definir">P01 Por definir</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col" id="boton">
                        <button class="boton" (click)="enviarPedido()" [disabled]="btnValidacion"> 
                            ORDENAR
                        </button>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="col">

        <div class="card" id="card">
            <div class="card-header" id="cardheader">
                <div class="row">
                    <div class="col-md-6"> Producto</div>
                    <div class="col center" *ngIf="!paciente.membresiaActiva"> Precio público </div>
                    <div class="col center" *ngIf="paciente.membresiaActiva"> Precio membresía</div>
                    <div class="col"></div>
                </div>
            </div>

            <div class="card-body" style="overflow: auto;">
                <div *ngFor="let item of carrito.items; let i = index">
                    <div class="row">
                        <div class="col-md-6">
                            {{ item.nombreEstudio }}
                        </div>
                        <div class="col center" *ngIf="!paciente.membresiaActiva">
                            {{ item.precioSin }}
                        </div>
                        <div class="col center" *ngIf="paciente.membresiaActiva">
                            {{ item.precioCon }}
                        </div>
                        <div class="col-md-2">
                            <label class="btn btn-outline-danger" (click)="eliminar(i)">
                                <i class="fas fa-trash-alt"></i>
                            </label>
                        </div>
                    </div>
                    <hr>

                </div>
            </div>
            <div class="card-footer" id="cardfooter">
                <div class="row">
                    <div class="col">Total membresía: </div>
                    <div class="col white"> {{ totalCarritoMembresia | currency }}</div>
                </div>

                <div class="row" *ngIf="!paciente.membresiaActiva">
                    <div class="col totalApagar">Total publico: </div>
                    <div class="col center totalApagar"> {{ totalSinMembresia | currency }}</div>
                </div>
                <div class="row" *ngIf="!paciente.membresiaActiva">
                    <div class="col">Ahorro: </div>
                    <div class="col center"> {{ carrito.totalSin - carrito.totalCon | currency }}</div>
                </div>

            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
        <br>
        <p style="color: white"> Cargando... </p>
    </ngx-spinner>
    <!-- <spinner-perrucho></spinner-perrucho> -->
</section>