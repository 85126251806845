import { Component, Input, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { nutricion } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-nutricion',
  templateUrl: './nutricion.component.html',
  styleUrls: ['./nutricion.component.css']
})
export class NutricionComponent implements OnInit {

  @Input() _id;
  @Input() nutricionNinos:nutricion = {} as nutricion;
  
  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarNutricionN(form: NgForm) {
    this.nutricionNinos = form.value
    this.nutricionNinos.idPaciente = this._id;
    this._HistoriaClinicaService.agregarNutricion(this.nutricionNinos)
    .subscribe((data:any) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE GUARDO NUTRICION',
        })
      }
    })
  }

  actualizarNutricionN(form: NgForm){
    let id = this.nutricionNinos._id;
    this.nutricionNinos = form.value;
    this.nutricionNinos._id = id;
    this.nutricionNinos.idPaciente = this._id;
    this._HistoriaClinicaService.actualizarNutricion(this.nutricionNinos._id, this.nutricionNinos)
    .subscribe((data:any) => {
      if(data['ok']){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZO NUTRICION',
        })
      }
    })
  }

}
