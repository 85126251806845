import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-registro-endoscopias',
  templateUrl: './registro-endoscopias.component.html',
  styleUrls: ['./registro-endoscopias.component.css']
})
export class RegistroEndoscopiasComponent implements OnInit {

  constructor(
    private _AlmacenService: AlmacenService
  ) { }

  public btnValidacion = true;
  endoscopiaData={
    idPaciente:"",
    idObtenido:"",
    estudios:"",
    sede:"",
    medicoQueSolicito:"",
    fechaDePedidoDeLosExamenes:"",
    prioridad:"",
    status:"",
    video:""
    }

  ngOnInit(): void {
    this.guardarEndoscopia();
  }

  validarForm(){
    if (this.endoscopiaData.estudios.length == 0) {

      alert('Ingresa el tipo de estudio de la endoscopia');

    }else if(this.endoscopiaData.sede.length == 0) {

      alert('Ingresa la sede de la endoscopia');

    }else if(this.endoscopiaData.medicoQueSolicito.length == 0) {

      alert('Ingresa el nombre del medico de la endoscopia');

    }else if(this.endoscopiaData.fechaDePedidoDeLosExamenes.length == 0) {

      alert('Ingresa la fecha de los examenes');

    }else if(this.endoscopiaData.prioridad.length == 0) {

      alert('Ingresa la prioridad de la endoscopia');

    }else if (this.endoscopiaData.status.length == 0) {

      alert('Ingresa el status de la endoscopia');

    } else if (this.endoscopiaData.video.length == 0) {

      alert('Ingresa el video de la endoscopia');

    }
    this.btnValidacion = false;
  }


  guardarEndoscopia(){
    this._AlmacenService.postearEndoscopia(this.endoscopiaData).
    subscribe((data:any) => {
      Swal.fire({
        title: 'LA ENDOSCOPIA SE CREO EXISTOSAMENTE',
        icon: 'success'
        }) 
    })
  }
}
