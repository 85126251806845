<div class="container-fluid">
  <app-titulos [title]="'HOJA DE REPORTE'" [rol]="'endoscopias'"></app-titulos>
  <section class="card" name="card" id="card">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Nombre Completo:</p>
            <p>KKKKKKKKKKKKKKKKKKKKKKKKK</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Edad:</p>
            &nbsp;
            <p>25</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Género:</p>
            &nbsp;
            {{ genero }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Domicilio:</p>
            &nbsp;
            <p>Emiliano Zapata #4</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Fecha de Nacimiento:</p>
            &nbsp;
            <p>08/04/1976</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Lugar de Nacimiento:</p>
            &nbsp;
            <p>Cuautla</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Teléfono:</p>
            &nbsp;
            <p>7356248569</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">CURP:</p>
            &nbsp;
            <p>JUIK879912JNKMLO76</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">ID:</p>
            &nbsp;
            <p>1</p>
          </div>
        </div>
      </div>
      <div class="row center">
        <div class="col" style="font-style: italic; font-weight: lighter">
          <h4>Contacto de emergencia</h4>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Nombre:</p>
            &nbsp;
            <p>Manute</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Edad:</p>
            &nbsp;
            <p>76</p>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <p class="subtitle-blue">Telefono:</p>
            &nbsp;
            <p>7356214589</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="card" name="card" id="card">
    <div class="container">
      <h2 class="center subtitle-blue">REPORTE DE ENDOSCOPIA</h2>
      <h3 class="center subtitle-blue">
        ENDOSCOPIA DE ABDOMEN (INCLUYE ABDOMEN COMPLETO)
      </h3>
      <h4 class="subtitle-blue">INTERPRETACIÓN</h4>
      <p class="justify">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, recusandae
        officiis! Magni tempore labore nesciunt dolorum veniam, nobis ab, esse
        autem harum minus sequi assumenda provident mollitia. Saepe id mollitia,
        velit voluptas, quo delectus quaerat amet libero obcaecati, iste
        voluptates nulla? Architecto, expedita officia deleniti dignissimos sit
        alias beatae. Iusto, vel recusandae rem possimus beatae minima sapiente,
        accusamus cumque assumenda facilis ab suscipit nemo! Earum quidem
        voluptatem quis vel asperiores!
      </p>
    </div>
  </section>
</div>
