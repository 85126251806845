import { Component, Input, OnInit } from '@angular/core';
import { LocationServiceService } from 'src/app/services/otrosService/location-services.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import {  MatStepper  } from '@angular/material/stepper';
import {  Router } from '@angular/router';
import { splitAtColon } from '@angular/compiler/src/util';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SedesService } from '../../services/sedes/sedes.service';

@Component({
  selector: 'app-registro-sedes',
  templateUrl: './registro-sedes.component.html',
  styleUrls: ['./registro-sedes.component.css']
})
export class RegistroSedesComponent implements OnInit {

  public validateBtn = false;
  public edadPaciente: any;
  public btnActivar = false;
  public sedes:any [] = []

  constructor(public _locationService: LocationServiceService,
              public _pacienteService: PacientesService,
              private _router: Router,
              private _spinnerService:SpinnerService,
              private _sedes:SedesService) { }

  ngOnInit(): void {
    this.edadPaciente = document.getElementById("edad");
    this.edadPaciente = 0;
    this.verSedes();
  }

  verSedes(){
    this._sedes.getSedes().subscribe((resp:any) =>{
      if(resp.ok){
        resp.data.forEach(element => {
          this.sedes.push(element)
        });
      }
    })
  }

  validarFecha(fechaForm: HTMLInputElement){
    let fechaElegida = fechaForm.value;
    let fechaAhora = new Date();
    if(Date.parse(fechaElegida.toString()) > Date.parse(fechaAhora.toString())){
      fechaForm.value = fechaAhora.toString();
    }
  }

  generarEdad(edadForm: HTMLInputElement ) {
    this.edadPaciente = document.querySelector('#edad');
    let fecha = edadForm.value;
    let splitFecha = fecha.split('-');
    var fechaActual = new Date();
    var anio = fechaActual.getFullYear();
    let edadNormal = anio - parseFloat( splitFecha[0]  );
    let edades = edadNormal.toString();
    this.edadPaciente.value = edades;
    this.validarFecha(edadForm);
  }

  validarString(nombre: string){
    if( nombre == ""  || nombre.length <3 ){
        return false;
    }
    return true;
  }
  validarSexo( sexo: string ){
    if(  sexo.length < 4 || sexo == "" ){
      return false;
    }else {
      return true;
    }
  }

  validarEdad(edad:any){
    //obtenemos el objectInputHTML y lo guardamos en una variable y comparamos su valor.
    let edadPac = edad;
    if( edadPac.value <= 0 || edadPac.value > 110  ){
      return false;
    }else{
      this.edadPaciente.value = edadPac.value;
      return true;
    }
  }
  validarFechaNacimiento(date:Date){
    let fechaAhora = new Date();
    if(Date.parse(date.toString()) > Date.parse(fechaAhora.toString())){
      return false
    }else{
      return true;
    }
  }

  message(msj){
    Swal.fire({
              icon: 'warning',
              title: '',
              text: msj,
            })
    /* alert(msj); */
  }

  validaciones(form){
    //Validaciones del paciente
    if( this.validarString( form.nombrePaciente ) ){

    }else {
      this.message('Completa el nombre del paciente')
      return false;
    }

    if( this.validarString( form.apellidoPaterno ) ){

    }else {
      this.message('Completa el apellido paterno del paciente')
      return false;
    }

    if( this.validarString( form.apellidoMaterno ) ){

    }else {
      this.message('Completa el apellido materno del paciente')
      return false;
    }

    if( this.validarFechaNacimiento( form.fechaNacimiento || "" ) ){
    
    }else {
      this.message('Ingresa una fecha valida de nacimiento del paciente')
      return false;
    }
      //enviamos el valor del campo de Edad
    if( this.validarEdad( document.querySelector('#edad') || 0 ) ){

    }else {
      this.message('Ingrea una edad valida')
      return false;
    }

    if ( this.validarSexo( form.genero ) ){

    }else {
      this.message('Ingresa el sexo del paciente');
      return false;
    }
    return true;
  }

  enviar( form: NgForm  ){
    let resultado =this.validaciones( form.value );
    form.value.edad = this.edadPaciente.value || 0
    if(  resultado ){
      let sedePaciente={
        nomenclaturaRegistro:form.value.sede
      }
      this.validateBtn = true;
      let values = Object.assign(form.value,sedePaciente)
      this._pacienteService.setPacientes(   values , form.value.sede  )
      .subscribe((data) => {
        if(  data['ok'] ){
          this._spinnerService.hide();
          Swal.fire({
              icon: 'success',
              title: '',
              text: 'PACIENTE REGISTRADO',
            })
          this._router.navigateByUrl('/paciente')
        }
      });
    }else {
      return;
    }
  }

}
