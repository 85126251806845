import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {URL } from '../../config/conf'

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    public _http: HttpClient
  ) { }


  envioEmail( cotizacion ){

    let url = `${URL}/cotizacion`;
    // let url = `http://localhost:3200/cotizacion`;

    return this._http.post(url, cotizacion);
  }

}
