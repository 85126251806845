<div class="container-fluid">
    <h1 class="centerxd">BITÁCORA DE NO APTOS O DIFERIDOS</h1>
    <!-- <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar"  class="form-control" id="busquedaPaciente" >
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>

                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>GENERO</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>GRUPO Y RH</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTATUS</p>
                        </div>
                        <div class="col text_header">
                            <p>PRECESO</p>
                        </div>
                        <div class="col text_header">
                            <p>MOTIVO</p>
                        </div>
                        <div class="col text_header">
                            <p>TIEMPO</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let item of diferidos; let i=index">
                        <div class="row">
                            <div class="col-md-1" style="text-align: left;">
                                <p>
                                    {{i + 1 }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ item.nombreCompleto}}
                                </p>
                            </div>
                            <div class="col-md-1" style="text-align: center;">
                                <p>
                                    {{item.genero}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.edad}} AÑOS
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.tipoDeSangre}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.estatus}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.proceso}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.motivo}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.fecha_diferido}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>