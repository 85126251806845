<div class="container-fluid">
    <section id="ingreso">
        <div class="row">
            <div class="col-md-8">
                <app-titulos [title]="'NOTA DE INGRESO'" [rol]="'doctor'"></app-titulos>
            </div>
            <div class="col">
                <p> {{hospitalizados.fechaIngreso}} </p>
                <p> Hora ingreso: <strong> {{hospitalizados.horaIngreso}} </strong> </p>
            </div>
        </div>
    </section>
    
    <ficha-info-user  [rol]="'doctor'"  [pacienteInfo]="hospitalizados.idPaciente" ></ficha-info-user>

    <div class="row">
        <div class="col-xs-2">
            <div class="card signos" id="card">
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Talla: <span> {{ hospitalizados.idConsulta.talla }} </span> m</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Peso: <span> {{ hospitalizados.idConsulta.peso }} </span> Kg</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>IMC: <span>  {{ hospitalizados.idConsulta.imc }} </span> Kg/m<sup>2</sup> </p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Sistolica: <span> </span> </p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Diastolica: <span> </span> </p>
                    </div>
                </div> -->
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad < 9">
                    <div class="col-6">
                        <p>FR: <span> {{paciente.fr}} </span> RPM</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <9">
                    <div class="col-6">
                        <p>FC: <span> {{paciente.fc}} </span> LPM</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div> -->
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>TEMP: <span> {{ hospitalizados.idConsulta.temp }} </span> °C</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.C.: <span> {{ hospitalizados.idConsulta.pc }}  </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.A.: <span> {{ hospitalizados.idConsulta.pa }} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.T.: <span> {{ hospitalizados.idConsulta.pt }}  </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p *ngIf="paciente.edad < 8">APGAR: <span> {{paciente.apgar}} </span></p>
                    </div>
                </div> -->
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p>SaO: <span> {{paciente.sao}} </span> %</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div> -->
                <!-- <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>SpO<sub>2</sub>: <span>{{paciente.pao}}</span>%SpO<sub>2</sub></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div> -->
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Glucosa: <span> {{ hospitalizados.idConsulta.glucosa }} </span> mg/dl</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso">
                            <i class="fas fa-chart-line" id="grafica"></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p> Alergias:<span>     </span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" id="historia">
                <div role="tabpanel" class="row">
                    <div class="col-3" id="indicadoress">
                        <ul class="nav flex-column nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#indicadoresGeograficos" aria-controls="#indicadoresGeograficos"> 
                                    DIAGNOSTICO DE INGRESO
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#antecedentes" data-toggle="tab" aria-controls="#antecedentes"> 
                                     INTERROGATORIO Y EXPLORACION
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#medicamentos" data-toggle="tab" aria-controls="#medicamentos">
                                    PLAN
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-9">
                        <div class="tab-content">
                            <div role="tabpanel" class="tab-pane active" id="indicadoresGeograficos">
                                <input type="text" id="diagnosticoIngreso" name="diagnosticoIngreso" [(ngModel)]="txtCie10"  (keydown)="getcie10PorNombre()" placeholder="CIE10">
                                <div class="container-list">
                                    <ul class="list" >
                                        <li class="list-item pointer" (click)="addNewItem(dianostico.NOMBRE)" *ngFor="let dianostico of cie10; let i = index" >  {{ dianostico.NOMBRE }} </li>
                                    </ul>
                                </div>
                                <div class="container-list-dx">
                                    <ul >
                                        <div class="list-dx" *ngFor="let dx of hospitalizados.diagnosticoInicial; let i = index">
                                            <li class="list-dx-diagnostico" >  {{ dx }} </li>  
                                            <p class="pointer colorRed"  (click)="deleteItem(i)"  >Eliminar</p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="antecedentes">
                                <textarea name="" id="" cols="30" rows="10"></textarea>
                            </div>
                            <div role="tabpanel" class="tab-pane" id="medicamentos">
                                <textarea name="plan" id="plan" cols="30" rows="10"></textarea>
                                <div class="boton">
                                    <!-- 
                                        [routerLink]="['/hoja/indicaciones']"
                                     -->
                                    <button class="principalButton" data-toggle="modal" data-target="#preregistro" (click)="updateNotaIngreso()" > GUARDAR </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>