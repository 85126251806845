import { gaurdarCotizacion, getDataCarrito } from 'src/app/functions/storage.funcion'; 
import { getCarritoStorage } from '../../../app/functions/pacienteIntegrados';
import { CarritoNuevo } from '../../interfaces/carrito-horizonte-interface'

export default class CarritoHorizonte{

    public carrito:CarritoNuevo = {} as CarritoNuevo;
          
    constructor() {
        this.obtenerSotorageCarrito();
    }
    
    public obtenerSotorageCarrito(){
        this.carrito = getCarritoStorage();  
        if ( this.carrito == null ){
          this.carrito = {
              totalSin: 0,
              totalCon: 0,
              items: []
          };
        }
        return this.carrito;
    }

    public agregarItem(membresia, precioSin, precioCon, item){
  
        const estudio = {
            nombreEstudio: item.DESTINO,
            precioSin:precioSin,
            precioCon:precioCon,
            name: item.name,
            idEstudio: item._id
        }

        if(item.name != 'ambulancia'){
            estudio.nombreEstudio = item.ESTUDIO;
        }

        this.sumarTotal( estudio.precioSin, estudio.precioCon );
        this.carrito.items.push( estudio );
        let carritoString = JSON.stringify( this.carrito );
        gaurdarCotizacion( carritoString );
        this.carrito = getDataCarrito();
        return this.carrito;
    }

    public  sumarTotal(  precioSin, precioCon  ){
        // se le quitan los caracteres $ y , al precio con membresia
        let precioConMembresia  = precioCon.replace('$', '');
        let precioConSinComa  = precioConMembresia.replace(',', '');
        let precioConMembresiaNumber = parseFloat( precioConSinComa );
        // se le quitan los caracteres $ y , al precio sin membresia
        let costoSin = precioSin.replace('$', '');
        let costoSinComa = costoSin.replace(',', '');
        let costoSinNumber = parseFloat( costoSinComa ); 
        this.carrito.totalSin = this.carrito.totalSin + costoSinNumber;
        this.carrito.totalCon = this.carrito.totalCon + precioConMembresiaNumber;
    }

    public restarTotal( precioSin, precioCon  ) {
        let precioSinTrim  =  precioSin.replace('$', '');
        let precioSinComa = precioSinTrim.replace(',', '');
            // aca le quito la coma si es que trae
        let precioSinMembresiaNumber = parseFloat( precioSinComa );
        let precioConTirm = precioCon.replace('$', '');
        let precioConMembresiaSinComa = precioConTirm.replace(',', '');
              // aca le quito la coma si es que la trae
        let precioConMembresiaNumber = parseFloat( precioConMembresiaSinComa );
        this.carrito.totalCon = this.carrito.totalCon - precioConMembresiaNumber;
        this.carrito.totalSin = this.carrito.totalSin - precioSinMembresiaNumber;
      }

    public  eliminar( id ) {
        this.carrito.items.forEach(  (item, index) => {
            // Agregar algun otro caso que se pueda dar
            if (index  === id ) {
              this.carrito.items.splice( index, 1 )
              this.restarTotal( item.precioSin, item.precioCon );
            }
        });
        let  carritoString = JSON.stringify( this.carrito  );
        gaurdarCotizacion(  carritoString );
    }
}