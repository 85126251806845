export function preRegistro ( fecha, form ){
    let arreglo = [];
    let regreso ={
        tiempoDiferir:'',
        motivo:'',
        fechafinal:'',
        fechainicio:''
    }
    let contadorsi = 0;
    for (const key in form) {
        switch(key){
            case 'preguntauno':
                if(form[key] == 'SI'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(1,fecha, '1 DÍA', 'CONSUMIR ALIMENTOS'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntados':
                if(form[key] == 'SI'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(7,fecha, '1 SEMANA', 'PESA MENOS DE 50 KILOS'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntatres':
                if(form[key] == 'NO'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(30,fecha, '1 MES', 'LE DIAGNOSTICARON HEPATITIS'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntacuatro':
                if(form[key] == 'NO'){
                    contadorsi += 1 
                }else{
                    arreglo.push(llenarRegreso(60,fecha, '2 MESES', 'SE ENFERMO EN LOS ÚLTIMOS 5 DÍAS'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntacinco':
                if(form[key] == 'NO'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(90,fecha, '3 MESES', 'PADECIÓ FIEBRE EN LOS ÚLTIMOS 5 DÍAS'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntaseis':
                if(form[key] == 'NO'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(180,fecha, '6 MESES', 'SE REALIZO UN TATUAJE EN LOS ÚLTIMOS 6 MESES'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntasiete':
                if(form[key] == 'NO'){
                    contadorsi += 1 
                }else{
                    arreglo.push(llenarRegreso(240,fecha, '8 MESES', 'SE REALIZO UNA ENDODONCÍA EN LOS ÚLTIMOS 6 MESES'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntaocho':
                if(form[key] == 'NO'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(300,fecha, '10 MESES', 'SE REALIZO UNA PERFORACION EN SU CUERPO Y ACUPUNTURA'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntanueve':
                if(form[key] == 'NO'){
                    contadorsi += 1 
                }else{
                    arreglo.push(llenarRegreso(365,fecha, '1 AÑO', 'EN LOS ULTIMOS 14 DÍAS SE CONTAJIO DE GRIPE, TOS SECA, CATARRO, TOS CON FLEMAS O FIEBRE, DIARREA, INFECCIONES DENTALES'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntadiez':
                if(form[key] == 'NO'){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(1,fecha, '1 DÍA', 'ESTUVO CON PERSONAS CONFIRMADAS O SOSPECHOSOS COVID-19'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'preguntaonce':
                if(form[key] == 'NO'){
                    contadorsi += 1
                }else{
                    contadorsi += 1
                    // arreglo.push(llenarRegreso(4,fecha, '4 DIAS', 'EN LOS ÚLTIMOS 5 DÍAS TOMÓ MEDICAMENTO PARA LA TOS, FIEBRE, ASPIRINAS, ANTIBIÓTICOS ENTRE OTROS'))
                    // regreso ={
                    //     tiempoDiferir:'',
                    //     motivo:'',
                    //     fechafinal:'',
                    //     fechainicio:''
                    // }
                }
                break;
            case 'preguntadoce':
                if(form[key] == 'NO'){
                    contadorsi += 1 
                }else{
                    arreglo.push(llenarRegreso(180,fecha, '6 MESES', 'EN LOS ULTIMOS 6 MESES SE REALIZO UNA CIRUGÍA'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;     
        }    
    }
    if(contadorsi < 12){
        let regreso = {
            respuestas: contadorsi,
            diferido: [],
            diferir: true,
            proceso: 'PRE-REGISTRO',
            cuestionario: form
        }
        regreso.diferido.push(fechaMayor(arreglo))
        return regreso;
    }else{
        let regreso = {
            respuestas: contadorsi,
            diferido: [{
                fechafinal: "",
                fechainicio: "",
                motivo: "NO DIFERIR",
                tiempoDiferir: ""
            }],
            diferir: false,
            proceso: '',
            cuestionario: form
        }
        return regreso;
    }
}

export function signosVitales(fecha, form){
    let arreglo = [];
    let regreso ={
        tiempoDiferir:'',
        motivo:'',
        fechafinal:'',
        fechainicio:''
    }
    let contadorsi = 0;
    for (const key in form) {
        switch(key){
            case 'talla':
                contadorsi += 1
                break;
            case 'peso':
                if(form[key] > 45){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(5,fecha, '5 DIAS', 'PESA MENOS DE 50 KILOS'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'imc':
                if(form[key] > 19){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(2,fecha, '2 DIAS', 'IMC ES MENOR A 19'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'sistolica':
                if(form[key] < 180){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(8,fecha, '8 DIAS', 'SISTOLICA MAYOR A 180 mm/Hg'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'diastolica':
                if(form[key] < 100){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(7,fecha, '7 DIAS', 'DIASTOLICA MAYOR A 100 mm/Hg'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'temp':
                if(form[key] < 38){
                    contadorsi += 1
                }else{
                    arreglo.push(llenarRegreso(14,fecha, '2 SEMANAS', 'TEMPERATURA MAYOR A 38°C'))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'multiples':
                contadorsi += 1
                break;
            case 'recientes':
                contadorsi += 1
                break;
        }
    }

    if(contadorsi < 8){
        let regreso = {
            respuestas: contadorsi,
            diferido: [],
            diferir: true,
            proceso: 'ENFERMERIA',
            signos:form
        }
        regreso.diferido.push(fechaMayor(arreglo))
        return regreso;
    }else{
        let regreso = {
            respuestas: contadorsi,
            diferido: [{
                fechafinal: "",
                fechainicio: "",
                motivo: "",
                tiempoDiferir: ""
            }],
            diferir: false,
            proceso: '',
            signos:form
        }
        return regreso;
    }
}

////////////////////validaciones de historia clinica por componente////////////////////////

//////////////////validacion de indicadores geograficos////////////////////////////////////
export function indicadoesGeo(fecha, form){
    let comparacion = historia(fecha);
    /* console.log(comparacion); */
    let arreglo = [];
    let regreso ={
        tiempoDiferir:'',
        motivo:'',
        fechafinal:'',
        fechainicio:''
    }
    let regres = {
        dias:0,
        fechaInicio:'', 
        tiempoDiferir:'', 
        motivo:''
    }
    let contadorsi = 0;
    for (const key in form) { 
        regres = iterar(key, comparacion)
        switch (key) {
            case 'nacionalidad':
                if(form[key] == 'SI'){
                    contadorsi += 1 
                }else{
                    arreglo.push(llenarRegreso(regres.dias,regres.fechaInicio, regres.tiempoDiferir, regres.motivo))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
            case 'viajes':
                if(form[key] == 'NO'){
                    contadorsi += 1 
                }else{
                    arreglo.push(llenarRegreso(regres.dias,regres.fechaInicio, regres.tiempoDiferir, regres.motivo))
                    regreso ={
                        tiempoDiferir:'',
                        motivo:'',
                        fechafinal:'',
                        fechainicio:''
                    }
                }
                break;
        }
    }
    return diferir(contadorsi,2,arreglo)
}

//////////////////fin de validacion de indicadores geograficos////////////////////////////////////

//////////////////validacion de antecedentes////////////////////////////////////

export function antecedentesDisp(fecha, form){
    return historiaClinica(fecha, form,3);
}

//////////////////fin de validacion de antecedentes////////////////////////////////////

//////////////////validacion de antecedentes personales////////////////////////////////////

export function antecedentesPersonales(fecha, form){
    return historiaClinica(fecha, form,6);
}

//////////////////fin de validacion de antecedentes personales/////////////////////////////

//////////////////validacion de antecedentes personales////////////////////////////////////

export function antecedentesPatologicos(fecha, form){
    return historiaClinica(fecha, form,10);
}

//////////////////fin de validacion de antecedentes personales/////////////////////////////

//////////////////validacion de antecedentes gineco////////////////////////////////////

export function antecedentesGineco(fecha, form){
    return historiaClinica(fecha, form,10);
}

//////////////////fin de validacion de antecedentes gineco/////////////////////////////

//////////////////validacion de practicas de riesgo////////////////////////////////////

export function practicasdeRiesgo(fecha, form){
    return historiaClinica(fecha, form,8);
}

//////////////////fin de validacion de practicas de riesgo/////////////////////////////

//////////////////validacion de ultimo año////////////////////////////////////

export function ultimoAnio(fecha, form){
    return historiaClinica(fecha, form,20);
}

//////////////////fin de validacion de ultimo año/////////////////////////////

//////////////////validacion de medicamentos////////////////////////////////////

export function medicamentos(fecha, form){
    return historiaClinica(fecha, form,27);
}

//////////////////fin de validacion de medicamentos/////////////////////////////


function historiaClinica(fecha, form, preguntas){
   let comparacion = historia(fecha);
   let arreglo = [];
   let regreso ={
    tiempoDiferir:'',
    motivo:'',
    fechafinal:'',
    fechainicio:''
   }
   let regres = {
    dias:0,
    fechaInicio:'', 
    tiempoDiferir:'', 
    motivo:''
    }
    let contadorsi = 0;
    for (const key in form) { 
      regres = iterar(key, comparacion)  
        if(form[key] == 'NO'){
            contadorsi += 1 
        }else{
            arreglo.push(llenarRegreso(regres.dias,regres.fechaInicio, regres.tiempoDiferir, regres.motivo))
            regreso ={
                tiempoDiferir:'',
                motivo:'',
                fechafinal:'',
                fechainicio:''
            }
        }
    }
    

    if(contadorsi < preguntas){
        let regreso = {
            respuestas: contadorsi,
            diferido: [],
            diferir: true,
            proceso: 'DOCTOR'
        }
        regreso.diferido.push(fechaMayor(arreglo))
        return regreso;
    }else{
        let regreso = {
            respuestas: contadorsi,
            diferido: [],
            diferir: false,
            proceso: ''
        }
        return regreso;
    } 
}

//////////////////////VALIDACION SEROLOGIA//////////////////////////////////////

export function serologia_disp(fecha, form){
    let comparacion = serologia(fecha);
    let arreglo = [];
    let regreso ={
     tiempoDiferir:'',
     motivo:'',
     fechafinal:'',
     fechainicio:''
    }
    let regres = {
     dias:0,
     fechaInicio:'', 
     tiempoDiferir:'', 
     motivo:''
     }
     let contadorsi = 0;
     for (const key in form) { 
       regres = iterar(key, comparacion)  
         if(form[key] == 'NO REACTIVO'){
             contadorsi += 1 
         }else{
             arreglo.push(llenarRegreso(regres.dias,regres.fechaInicio, regres.tiempoDiferir, regres.motivo))
             regreso ={
                 tiempoDiferir:'',
                 motivo:'',
                 fechafinal:'',
                 fechainicio:''
             }
         }
     }
    
     if(contadorsi < 7){
         let regreso = {
             respuestas: contadorsi,
             diferido: [],
             diferir: true,
             proceso: 'SEROLOGIA'
         }
         regreso.diferido.push(fechaMayor(arreglo))
         return regreso;
     }else{
         let regreso = {
             respuestas: contadorsi,
             diferido: [],
             diferir: false,
             proceso: '',
             sero: form
         }
         return regreso;
     } 
 }


function iterar(keys, comparacion){
    let regres = {
        dias:0,
        fechaInicio:'', 
        tiempoDiferir:'', 
        motivo:''
    }
    
    for (const key in comparacion) {
        if(keys == key){
            regres = comparacion[key]
        }
    }    
    return regres
}

function llenarRegreso(dias,fechaInicio, tiempoDiferir, motivo){
    let fecha3: Date = new Date(); 
    let regreso ={
        tiempoDiferir:'',
        motivo:'',
        fechafinal:'',
        fechainicio:''
    }
    let fechafinal = sumarDias(fecha3, dias).toLocaleDateString("en-US");
    regreso.fechainicio = fechaInicio
    regreso.fechafinal = fechafinal
    regreso.tiempoDiferir = tiempoDiferir
    regreso.motivo = motivo
    return regreso;
}

function sumarDias(fecha, dias){
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
}

export function fechaMayor(arreglo){
    let fecha = arreglo[0].fechafinal;
    let contador = 0;
    let item:any;
    for (let index = 0; index < arreglo.length; index++) {
        if( new Date(fecha) < new Date(arreglo[index].fechafinal)){
            fecha = arreglo[index].fechafinal
        }else{
            fecha = fecha
        }
    }
    arreglo.forEach(element => {
        if(new Date(element.fechafinal).getTime() == new Date(fecha).getTime()){
            item = element
        }
    });
    return item;      
}


//////////////funciones de historia clinica fechas//////////////////////////////

function contarDias(fechaInicio,fechaFinal){
    var day_as_milliseconds = 86400000;
    var diff_in_millisenconds = fechaFinal - fechaInicio;
    var diff_in_days = diff_in_millisenconds / day_as_milliseconds;

    /* console.log( diff_in_days ); */
}

function diferir(contadorsi, preguntas, arreglo){
    if(contadorsi < preguntas){
        let regreso = {
            respuestas: contadorsi,
            diferido: [],
            diferir: true,
            proceso: 'DOCTOR'
        }
        regreso.diferido.push(fechaMayor(arreglo))
        return regreso;
    }else{
        let regreso = {
            respuestas: contadorsi,
            diferido: [],
            diferir: false,
            proceso: ''
        }
        return regreso;
    }
}


function serologia (fecha){
    const serologia = {
        resultado0: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'VIRUS DE INMUNODEFICIENCIA ADQUIRIDA'
        },
        resultado1: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'ANTI CUERPOS ANTI HEPATITIS B'
        },
        resultado2: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'ANTI CUERPOS ANTI HEPATITIS C'
        },
        resultado3: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'VDRL'
        },
        resultado4: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'CHAGAS'
        },
        resultado5: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'BRUCELLAS'
        },
        resultado6: {
            dias:3000,
            fechaInicio:fecha, 
            tiempoDiferir:'100 AÑOS', 
            motivo:'DENGUE'
        },
    }
    return serologia;
}

function historia(fecha){
    const historia = {
        nacionalidad:{
            dias:1,
            fechaInicio:fecha, 
            tiempoDiferir:'1 DÍA', 
            motivo:'NACIONALIDAD'
        },
        viajes:{
            dias:1,
            fechaInicio:fecha, 
            tiempoDiferir:'1 DÍA', 
            motivo:'VIAJE'
        },
        /* enfermos_hepatitis:{
            dias:1,
            fechaInicio:fecha, 
            tiempoDiferir:'1 DÍA', 
            motivo:'HEPATITIS'
        }, */
        vih_deteccion:{
            dias:36500,
            fechaInicio:fecha, 
            tiempoDiferir:'PERMANENTE', 
            motivo:'VIH'
        },
        hepatitisb_deteccion:{
            dias:3650,
            fechaInicio:fecha, 
            tiempoDiferir:'10 AÑOS', 
            motivo:'HEPATITIS B'
        },
        hepatitisc_deteccion:{
            dias:36500,
            fechaInicio:fecha, 
            tiempoDiferir:'PERMANENTE', 
            motivo:'HEPATITIS C'
        },
        /* enfermedad_de_transmision:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'ENFERMEDAD DE TRANSMISION'
        },
        odinofagia:{
            dias:6,
            fechaInicio:fecha, 
            tiempoDiferir:'6 DIAS', 
            motivo:'ODINOFAGIA'
        }, */
        alcohol_consumido:{
            dias:1,
            fechaInicio:fecha, 
            tiempoDiferir:'1 DIA', 
            motivo:'CONSUMO DE ALCOHOL'
        },
        /* medicamento_regular:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO REGULAR'
        }, */
        consume_etretinato:{
            dias:36500,
            fechaInicio:fecha, 
            tiempoDiferir:'PERMANENTE', 
            motivo:'CONSUME ETRETINATO'
        },
        tratamiento_dental:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'TRATAMIENTO DENTAL'
        },
        cirugia_mayor:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'CIRUGIA MAYOR'
        },
        cirugia_menor:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'CIRUGIA MENOR'
        },
        /* alergias:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'ALERGIAS'
        }, */
        inmunizaciones:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'INMUNIZACION'
        },
        cardiopatias:{
            dias:730,
            fechaInicio:fecha, 
            tiempoDiferir:'2 AÑOS', 
            motivo:'CARDIOPATIAS'
        },
        /* efermedades_renales:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'ENFERMEDADES RENALES'
        }, */
        coagulopatias:{
            dias:36500,
            fechaInicio:fecha, 
            tiempoDiferir:'PERMANENTE', 
            motivo:'COAGULOPATIAS'
        },
        cancer:{
            dias:1825,
            fechaInicio:fecha, 
            tiempoDiferir:'5 AÑOS', 
            motivo:'CANCER'
        },
        /* neoplasia_hematologica:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'NEOPLASIA HEMATOLOGICA'
        }, */
        /* anemia:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'ANEMIA'
        }, */
        infecciones_bacteo:{
            dias:90,
            fechaInicio:fecha, 
            tiempoDiferir:'3 MESES', 
            motivo:'INFECCIONES BACTEO'
        },
        /* lepra:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'LEPRA'
        }, */
        /* paludismo:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'PALUDISMO'
        }, */
        brucelosis:{
            dias:1095,
            fechaInicio:fecha, 
            tiempoDiferir:'3 AÑOS', 
            motivo:'BRUCELOSIS'
        },
        diabetes:{
            dias:36500,
            fechaInicio:fecha, 
            tiempoDiferir:'PERMANENTE', 
            motivo:'DIABETES'
        },
        hipertension_arte:{
            dias:7,
            fechaInicio:fecha, 
            tiempoDiferir:'1 SEMANA', 
            motivo:'HIPERTENSION ARTERIAL'
        },
        tuberculosis:{
            dias:1095,
            fechaInicio:fecha, 
            tiempoDiferir:'3 AÑOS', 
            motivo:'TUBERCULOSIS'
        },
        epilepsia:{
            dias:36500,
            fechaInicio:fecha, 
            tiempoDiferir:'PERMANENTE', 
            motivo:'EPILEPSIA'
        },
        hepatitis:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'HEPATITIS'
        },
        /* icteria:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'ICTERIA'
        }, */
        /* transtormental:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'TRANSTORMENTAL'
        }, */
        toxoplasmosis:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'TOXOPLASMOSIS'
        },
        transplantes:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'TRANSPLANTES'
        },
        /* covid:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'COVID'
        }, */
        /* otros_patologicos:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'OTROS PATOLOGICOS'
        }, */
        ultima_relacion:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'ULTIMA RELACION'
        },
        gestas:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'GESTAS'
        },
        partos:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'PARTOS'
        },
        cesareas:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'CESAREAS'
        },
        abortos:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'ABORTOS'
        },
        ultimo_parto:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'ULTIMO PARTO'
        },
        ultimo_aborto:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'ULTIMO ABORTO'
        },
        isoinmunizacioes:{
            dias:14,
            fechaInicio:fecha, 
            tiempoDiferir:'2 SEMANAS', 
            motivo:'ISOINMUNIZACIONES'
        },
        globulina:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'GLOBULINA'
        },
        transfusiones_prev:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'TRANSFUSIONES PREVENTIVAS'
        },
        /* pago_por_donar_sangre:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'PAGO POR DONAR SANGRE'
        }, */
        uso_drogas:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'1 DIAS', 
            motivo:'USO DE DROGAS'
        },
        /* heterosecual_promiscuo:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'HETEROSECUAL PROMISCUO'
        }, */
        /* homoxesual:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'HOMOSEXUAL'
        }, */
        /* bisexual:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'BISEXUAL'
        }, */
        pago_por_relaciones:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'PAGO POR RELACIONES'
        },
        contacto_con_hemofilicos:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'CONTACTO CON HEMOFILICOS'
        },
        instituciones_pentales:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'INSTITUCIONES PENTALES'
        },
        acupunturas:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'ACUPUNTURA'
        },
        tatuajes:{
            dias:365,
            fechaInicio:fecha, 
            tiempoDiferir:'1 AÑO', 
            motivo:'TATUAJES'
        },
        enfermedades_itc:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MESES', 
            motivo:'ENFERMEDADES ITC'
        },
        parejas_sexuales:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'PAREJAS SEXUALES'
        },
        /* ultimo_año_de_parejas:{
            dias:60,
            fechaInicio:fecha, 
            tiempoDiferir:'2 MESES', 
            motivo:'ULTIMO AÑO DE PAREJAS'
        }, */
        /* ultimos_cinco_años_de_parejas:{
            dias:60,
            fechaInicio:fecha, 
            tiempoDiferir:'2 MESES', 
            motivo:'ULTIMOS CINCO AÑOS DE PAREJAS'
        }, */
        tos_disnea:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'TOS DISNEA'
        },
        perdida_de_peso:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'PERDIDA DE PESO'
        },
        diarrea_cronica:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'DIARREA CRONICA'
        },
        diaforesis_cronica:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'DIAFRORESIS CRONICA'
        },
        diaforesis_profusa:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'DIAFORESIS PROFUSA'
        },
        astenia:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'ASTENIA'
        },
        adenomegalias:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'ADENOMEGALIAS'
        },
        herpes_mucocutaneo:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'HERPES MUCOCUTANEO'
        },
        fiebre_continua:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'FIEBRE CONTINUA'
        },
        odinofagia_diez_dias:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'ODINOFAGIA DIEZ DIAS'
        },
        sindrome_diarreico:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'SINDROME DIARREICO'
        },
        isotretinoina_ultimo_mes:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'ISOTRETINOINA ULTIMO MES'
        },
        covid_ultimo_mes:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'COVID ULTIMO MES'
        },
        medicamentos_ultima_semana:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'MEDICAMENTOS ULTIMA SEMANA'
        },
        infecciones_agudas_ultima_semana:{
            dias:30,
            fechaInicio:fecha, 
            tiempoDiferir:'1 MES', 
            motivo:'INFECCIONES AGUDAS ULTIMA SEMANA'
        },
        fiebre_ultimos_dos_dias:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'FIEBRE ULTIMOS DOS DIAS'
        },
        ejercicio_intenso_ultimos_dos_dias:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'EJERCICIO INTENSO ULTIMOS DOS DIAS'
        },
        ayuno_ultimos_dos_dias:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'AYUNO ULTIMOS DOS DIAS'
        },
        vigilia_ultimos_dos_dias:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'VIGILIA ULTIMOS DOS DIAS'
        },
        ingesta_de_alcohol_dos_dias:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'INGESTA DE ALCOHOL DOS DIAS'
        },
        medicamento_acitretina:{
            dias:1095,
            fechaInicio:fecha, 
            tiempoDiferir:'3 AÑOS', 
            motivo:'MEDICAMENTO ACITRETINA'
        },
        medicamento_tamoxifeno:{
            dias:540,
            fechaInicio:fecha, 
            tiempoDiferir:'18 MESES', 
            motivo:'MEDICAMENTO TAMOXIFENO'
        },
        medicamento_dutasterida:{
            dias:180,
            fechaInicio:fecha, 
            tiempoDiferir:'6 MESES', 
            motivo:'MEDICAMENTO DUTASTERIDA'
        },
        medicamento_finasterida:{
            dias:28,
            fechaInicio:fecha, 
            tiempoDiferir:'28 DIAS', 
            motivo:'MEDICAMENTO FINASTERIDA'
        },
        medicamento_isotretinoina:{
            dias:28,
            fechaInicio:fecha, 
            tiempoDiferir:'28 DIAS', 
            motivo:'MEDICAMENTO ISOTRETINOINA'
        },
        medicamento_tertraciclina:{
            dias:28,
            fechaInicio:fecha, 
            tiempoDiferir:'28 DIAS', 
            motivo:'MEDICAMENTO TERTRACICLINA'
        },
        medicamento_tretinoina:{
            dias:28,
            fechaInicio:fecha, 
            tiempoDiferir:'28 DIAS', 
            motivo:'MEDICAMENTO TRETINOINA'
        },
        medicamento_talidoida:{
            dias:28,
            fechaInicio:fecha, 
            tiempoDiferir:'28 DIAS', 
            motivo:'MEDICAMENTO TALIDOIDA'
        },
        medicamento_acido_acetil_salicilico:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO ACIDO ACETIL SALICILICO'
        },
        medicamento_clopidogrel:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO CLOPIDROGREL'
        },
        medicamento_diflunisal:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO DIFLUNISAL'
        },
        medicamento_fenilbutazona:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO FENILBUTAZONA'
        },
        medicamento_meloxicam:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO MELOXICAM'
        },
        medicamento_nabumetona:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO NABUMETONA'
        },
        medicamento_naproxeno:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO NAPROXENO'
        },
        medicamento_sulindaco:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO SULINDACO'
        },
        medicamento_tenoxicam:{
            dias:5,
            fechaInicio:fecha, 
            tiempoDiferir:'5 DIAS', 
            motivo:'MEDICAMENTO TENOXICAM'
        },
        medicamento_aceclofenaco:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO ACECLOFENACO'
        },
        medicamento_acetamicin:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO ACETAMICIN'
        },
        medicamento_acido_mefenamico:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO ACIDO MEFENAMICO'
        },
        medicamento_diclofenaco:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO DICLOFENACO'
        },
        medicamento_dexibuprofen:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO DEXIBUPROFEN'
        },
        medicamento_flubiprofeno:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO FLUBIPROFENO'
        },
        medicamento_ibuprofeno:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO IBUPROFENO'
        },
        medicamento_indometacina:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO INDIMETACINA'
        },
        medicamento_ketoprofeno:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO KETOPROFENO'
        },
        medicamento_ketorolaco:{
            dias:2,
            fechaInicio:fecha, 
            tiempoDiferir:'2 DIAS', 
            motivo:'MEDICAMENTO KETOROLACO'
        }, 
    }
    return historia;
}


/* fechafinal = sumarDias(fecha3, 1).toLocaleDateString("en-US");
                    regreso.fechainicio = fecha
                    regreso.fechafinal = fechafinal
                    regreso.tiempoDiferir = '1 DÍA'
                    regreso.motivo = 'CONSUMIR ALIMENTOS'
                    arreglo.push(regreso) */