import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historico-estudios-disponente',
  templateUrl: './historico-estudios-disponente.component.html',
  styleUrls: ['./historico-estudios-disponente.component.css']
})
export class HistoricoEstudiosDisponenteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
