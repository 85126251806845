<div class="container-fluid">
    <h1>HOJA DE SERVICIOS RECEPTOR</h1>
    <section>
        <app-fichas-identificacion [data]="paciente"></app-fichas-identificacion>
        <!-- <app-ficha-info-censur [pacienteInfo]="paciente"></app-ficha-info-censur> -->
    </section>

    <div class="row" id="tabla1">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <h2>DETALLE DEL PEDIDO</h2>
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>

                        <div class="col text_header">
                            <p>CANTIDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>PRODUCTO</p>
                        </div>
                        <div class="col text_header">
                            <p>PRECIO UNITARIO</p>
                        </div>
                        <div class="col text_header">
                            <p>PRECIO ACUMULADO</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let item of pedido.estudios; let i = index">
                        <div class="row">
                            <div class="col-md-1" style="text-align: left;">
                                <p>
                                    {{i + 1}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.cantidad}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.producto}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.precio | currency}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{item.cantidad * item.precio | currency}}
                                </p>
                            </div>
                        </div>

                    </div>
                    <p style="text-align: right;">TOTAL: {{pedido.totalCompra | currency}}</p>
                </div>                
            </div>
        </div>
    </div>

    <div class="row" id="tabla2">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col text_header" style="text-align: center;">
                            <p>No.</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTADO</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let servicio of servicios; let i = index">
                        <div class="row" id="pointer" [routerLink]="servicio.url_doc" >
                            <div class="col" style="text-align: center;">
                                <p>
                                    1
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{servicio.documentos_medicos}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                   {{servicio.estado_doc}}
                                </p>
                            </div>
                        </div>
                        <div class="row" id="pointer" [routerLink]="servicio.url_tipar" >
                            <div class="col" style="text-align: center;">
                                <p>
                                    2
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{servicio.tipar_cruzar}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                   {{servicio.estado_tipar}}
                                </p>
                            </div>
                        </div>
                        <div class="row" id="pointer" [routerLink]="servicio.url_historico" >
                            <div class="col" style="text-align: center;">
                                <p>
                                    3
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{servicio.consultar_historico}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                   {{servicio.estado_historico}}
                                </p>
                            </div>
                        </div>
                        <div class="row" id="pointer" [routerLink]="servicio.url_productos" >
                            <div class="col" style="text-align: center;">
                                <p>
                                    4
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{servicio.entregar_productos}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                   {{servicio.estado_productos}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>