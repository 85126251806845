import { paciente } from './../../../../../interfaces/historia-clinica';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { etiquetaDesecho } from 'src/app/functions/etiquetasBanco';

@Component({
  selector: 'app-bitacora-laboratorio',
  templateUrl: './bitacora-laboratorio.component.html',
  styleUrls: ['./bitacora-laboratorio.component.css']
})
export class BitacoraLaboratorioComponent implements OnInit {

  public bitacoraPacientes:any[] = [];

  constructor(public _banco:BancoService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bitacora();
    this.spinner.show();
    ///bitacora/laboratorio implementar este metodo
  }

  bitacora(){
    this._banco.bitacoraLaboratorio()
    .subscribe((resp:any)=> {
      if(resp.ok){
        this.setDataBitacora(resp.data);
      }
    })
  }

  setDataBitacora(data) {
    data.forEach(element => {
      if(element.paciente != null){
        this.bitacoraPacientes.push(element)
      }
    });
    this.bitacoraPacientes.reverse();
    this.spinner.hide();
    /* console.log( this.bitacoraPacientes ); */
  }

}
