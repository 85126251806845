import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import Dates from 'src/app/classes/dates/date.class';
import { getSessionName } from 'src/app/classes/helpers/filterNamePaciente';
import { printTicketSocios } from 'src/app/classes/tickets/ticket.class';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { SedesService } from 'src/app/services/sedes/sedes.service';

@Component({
  selector: 'app-utilidades-sedes',
  templateUrl: './utilidades-sedes.component.html',
  styleUrls: ['./utilidades-sedes.component.css']
})
export class UtilidadesSedesComponent implements OnInit {

  public today ="";
  public sedes = [];
  public ventas = [];
  public session = "";
  public socioAsociado = "";
  public sedesFilter = [];

  public busqueda = {
    sede:"TLYC01",
    fecha1:"",
    fecha2:""
  }

  public btnPrint= true;

  public totalGanancia = 0;
  public totalCompra = 0;


  constructor(
    private _sede: SedesService,
    private _servicios: ServiciosService
  ) { }

  ngOnInit(): void {
    const date = new Dates();
    this.today = date.getDate();
    this.getSedes();
    this.busqueda.fecha2 = this.today
   
  }

  busquedaFecha1(date1: any){

    this.busqueda.fecha1 = date1.value
  }

  busquedaFecha2(date2: any){
    this.busqueda.fecha2 = date2.value
  }

  getSedes() {
    this._sede.getSedes()
    .subscribe((data:any) => {
      
      if(  data.ok) {
        this.setSedes(data.data)
      }
    })
  }

  setSedes( data = [] ) {
    this.sedes = data
    //retornamos los socios pero con el filtro de que no salga el DR Reyes 
    this.sedesFilter = this.sedes.filter( (element) => element.encargado != "David Reyes");
    // console.log( this.sedesFilter )
  }

  getSells(sede: any){
 

    this._servicios.getSellsforTwoDates( this.busqueda )
    .subscribe((data:any) =>{

      if(data.ok) {
        this.setDataVentas( data.data );
        this.getNameMedicoAsosciado( sede );
      }
    }
  
    )
  }

  getNameMedicoAsosciado( sede ){


    this.sedes.forEach((element) => {
      
      if( element.nomenclatura == sede  ){
        this.socioAsociado = element.encargado
      }

    })   
  }

  printTicket() {

    printTicketSocios( this.ventas, this.totalGanancia , this.today, this.socioAsociado);
  }

  setDataVentas( data:any) {
    this.ventas = data; 
    this.calcTotales();
    this.validatePrint()
  }

  calcTotales() {
    this.ventas.forEach((element) => {
      
      
      if(element.ganancia === undefined  ) {
        this.totalGanancia += 0
      }else {        
        this.totalGanancia += element.ganancia
      }
      this.totalCompra += element.totalCompra
    });
  }


  validatePrint() {
    
    if( this.ventas.length > 0  ) {
      this.btnPrint = false;
    }
  }
}
