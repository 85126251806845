<div class="row">
    <div class="col-md-12 col-lg-12">
        <div class="card">
            <form class="container-fluid" #f='ngForm'>
                <div class="row">
                    <div class="col">
                        <label for="talla">Talla m</label>
                        <input type="number" name="talla" min="0" required placeholder="Talla M" [(ngModel)]="talla" class="form-control">
                    </div>
                    <div class="col">
                        <label for="peso">Peso Kg</label>
                        <input type="number" name="peso" min="0" required placeholder="Peso" [(ngModel)]="peso" (change)="obtenerIMC()" class="form-control">
                    </div>
                    <div class="col">
                        <label for="imc">IMC</label>
                        <input type="number" name="imc" min="0" required placeholder="IMC" [(ngModel)]="imc" [value]="imc" class="form-control">
                        <!-- Crear en base P/T ^ 2, debe ser por defecto -->
                    </div>
                    <div class="col" *ngIf="paciente.edad > 9">
                        <label for="">Sistólica</label>
                        <input type="number" name="sist" min="0" [(ngModel)]="signosVitales.sistolica" placeholder="---" class="form-control">
                    </div>
                    <div class="col" *ngIf="paciente.edad > 9">
                        <label for="">Diastólica</label>
                        <input type="number" name="diastolica" min="0" [(ngModel)]="signosVitales.diastolica" placeholder="---" class="form-control">
                    </div>
                    <div class="col">
                        <label for="">LPM</label>
                        <input type="number" name="fc" min="0" [(ngModel)]="signosVitales.fc" placeholder="FC" class="form-control">
                    </div>
                    <div class="col">
                        <label for="">RPM</label>
                        <input type="number" name="fr" min="0" [(ngModel)]="signosVitales.fr" placeholder="FR" class="form-control">
                    </div>
                    <div class="col">
                        <label for="">°C</label>
                        <input type="number" name="temp" min="0" [(ngModel)]="signosVitales.temp" placeholder="TEMP" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="">PC cm</label>
                        <input type="number" name="pc" min="0" [(ngModel)]="signosVitales.pc" required placeholder="P.C." class="form-control">
                    </div>
                    <div class="col">
                        <label for="">PA cm</label>
                        <input type="number" name="pa" min="0" [(ngModel)]="signosVitales.pa" placeholder="P.A." class="form-control">
                    </div>
                    <div class="col">
                        <label for="">PT cm</label>
                        <input type="number" name="pt" min="0" [(ngModel)]="signosVitales.pt" placeholder="P.T." class="form-control">
                    </div>
    
                    <div class="col" *ngIf="paciente.edad <= 9">
                        <label for="">APGAR</label>
                        <input type="number" name="apgar" min="0" [(ngModel)]="signosVitales.apgar" placeholder="0 - 10" class="form-control">
                    </div>
    
                    <div class="col" *ngIf="paciente.edad <= 9 ">
                        <label for="">SaO %</label>
                        <input type="number" name="saoNino" min="0" [(ngModel)]="signosVitales.SaO" placeholder="SaO" class="form-control">
                    </div>
    
                    <div class="col" *ngIf="paciente.edad > 9">
                        <label for="">Oximetría</label>
                        <input type="number" name="pao" min="0" [(ngModel)]="signosVitales.pao" placeholder="PaO2" class="form-control">
                    </div>
    
                    <div class="col">
                        <label for="glucosa">  Glucosa:   </label>
                        <span class="glucoWarning">Colocar valor 0 en caso de no tomar la muestra de Glucosa</span>
                        <input type="number" name="glucosa" min="0" [(ngModel)]="signosVitales.glucosa" placeholder="Glucosa" class="form-control" (blur)="validacionFormSignos()">
                    </div>
                </div>
    
                <br>
    
                <div class="row">
                    <div class="col">
                        <app-riesgo-de-caida (totalRiesgoCaida)="caida($event)"></app-riesgo-de-caida>
                    </div>
                    <div class="col">
                        <label for="">  Diagnóstico previo </label>
                        <input class="form-control" name="diagnosticoActual" type="text" [(ngModel)]="signosVitales.diagnosticoActual" placeholder="Diagnóstico previo">
                    </div>
                </div>
                <div class="row">
                    <div class="col center">
                        <button [disabled]="btnSignosVitales" class="principalButton" (click)="ageragrSignos()">Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
