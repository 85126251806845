<div class="container-md">
    <!-- <h1 class="title-principal-bs">REGISTRO DE PROVEEDOR</h1> -->
    <app-titulos [title]="'REGISTRO DE PROVEEDOR'"></app-titulos>
    <div class="card">
        <form [formGroup]="formProveedor">
            <div class="row">
                <div class="col">
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">NOMBRE DE LA EMPRESA: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" tabindex="1" id="nombreEmpresa" name="nombreEmpresa" formControlName="nombreEmpresa">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">RAZÓN SOCIAL: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" tabindex="3" id="razonSocial" name="razonSocial" formControlName="razonSocial">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">CONTACTO: </label>
                        <div class="col-sm-7">
                            <input type="number" class="form-control" tabindex="5" id="contacto" min="0" maxlength="10" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" name="contacto" formControlName="contacto">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">RFC: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" tabindex="7" id="rfcEmpresa" maxlength="13"  name="rfcEmpresa" formControlName="rfcEmpresa" (blur)="generarIdProv()">
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">ID: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" id="IDProveedor" disabled="disabled" name="idProveedor" formControlName="idProveedor" >
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">DOMICILIO: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" tabindex="4" id="domicilio" name="domicilio" formControlName="domicilio">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">CORREO: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" tabindex="6" id="correo"  name="correo" formControlName="correo">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label class="col-sm-5 col-form-label">DÍAS DE DISTRIBUCIÓN: </label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" tabindex="8" id="diasDistribucion" name="diasDistribucion" formControlName="diasDistribucion">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">DESCRIPCIÓN</label>
                    <textarea class="form-control" id="descripcion" rows="3" tabindex="9" name="descripcion" formControlName="descripcion"></textarea>
                </div>
                <div class="d-grid gap-2 col-2 mx-auto mt-5">
                    <button type="submit" [disabled]="btnRegistrar" class="btn btn-primary" (click)="agregarProveedor()">REGISTRAR</button>
                </div>
            </div>
        </form>
    </div>
</div>
    