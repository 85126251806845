import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-agregar-servicio-por-utilidad',
  templateUrl: './agregar-servicio-por-utilidad.component.html',
  styleUrls: ['./agregar-servicio-por-utilidad.component.css']
})
export class AgregarServicioPorUtilidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  enviar(form: NgForm){

  }

}
