import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-proveedor',
  templateUrl: './detalle-proveedor.component.html',
  styleUrls: ['./detalle-proveedor.component.css']
})
export class DetalleProveedorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
