<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="'FICHA DE ENFERMERÍA'"></app-titulos>
        </div>
        <div class="col">
            <h1 class="fecha" id="fecha">{{ fecha}}</h1>
        </div>
    </div>
    <ficha-info-user [pacienteInfo]="paciente" [rol]="'enfermeria'"></ficha-info-user>
    
    <app-signos-vitales [paciente]="paciente" [idConsulta]="id"></app-signos-vitales>

    <app-historia-clinica-horizonte [paciente]="paciente"></app-historia-clinica-horizonte>
</div>




<!-- <app-historia-clinica [paciente]="paciente"></app-historia-clinica> -->
<!-- <div class="boton">
    <button class="principalButton" data-toggle="modal" data-target="#preregistro" > Agregar </button>
</div> -->
<!-- <app-ficha-identificacion [paciente]="paciente" [idConsulta]="id"></app-ficha-identificacion> -->
