<div class="container-fluid">
    <div id="card">
        <div class="row">
            <div class="col-md-6">
                <p> <span> NOMBRE COMPLETO: </span> VICTOR MANUEL ARAGON CASTELLANOS </p>
            </div>
            <div class="col">
                <p> <span>  EDAD: </span> 26 AÑOS</p>
            </div>
            <div class="col-md-3">
                <p> <span>  GENERO: </span> MASCULINO</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p> <span>  DOMICILIO: </span> DEFENSA DEL AGUA #46 COL. CENTRO </p>
            </div>
            <div class="col">
                <p> <span>  ID: </span> ASDGAGDJHAGSHDGAHGSHG12</p>
            </div>
            <div class="col-md-3">
                <p> <span>  TELEFONO: </span> 7351478845</p>
            </div>
        </div>
        <div class="row center" *ngIf="rol != 'Doctor'">
            <div class="col">
                <span>CONTACTO DE EMERGENCIA</span>
            </div>
        </div>
        <div class="row" *ngIf="rol != 'Doctor'">
            <div class="col-md-6">
                <p> <span>  NOMBRE COMPLETO: </span> ITZEL ARAGON CASTELLANOS</p>
            </div>
            <div class="col">
                <p> <span>  EDAD: </span> 26 AÑOS</p>
            </div>
            <div class="col-md-3">
                <p> <span>  TELEFONO: </span> 7352862023</p>
            </div>
        </div>
        <div class="row center" *ngIf="rol != 'Doctor'">
            <div class="col">
                <span>INFORMACION MEDICA</span>
            </div>
        </div>
        <div class="row" *ngIf="rol != 'Doctor'">
            <div class="col-md-6">
                <p> <span>  MEDICO TRATANTE: </span> DAVID REYES SALAZAR</p>
            </div>
            <div class="col">
                <p> <span>  HABITACION: </span> 02</p>
            </div>
            <div class="col-md-3">
                <p> <span>  REFERENCIA: </span> CENTRO MEDICO HORIZONTE</p>
            </div>
        </div>
        <div class="row" *ngIf="rol != 'Doctor'">
            <div class="col">
                <p> <span>  DIAGNOSTICO: </span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque expedita autem exercitationem nisi pariatur hic accusamus fugit officia blanditiis facilis doloribus a accusantium nostrum omnis nulla eum, praesentium, modi ullam!</p>
            </div>
        </div>
    </div>
</div>