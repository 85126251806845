import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { USGService } from '../../../../services/usg/usg.service';
@Component({
  selector: 'app-hoja-ultrasonido',
  templateUrl: './hoja-ultrasonido.component.html',
  styleUrls: ['./hoja-ultrasonido.component.css']
})
export class HojaUltrasonidoComponent implements OnInit {

/// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%% VARIABLES %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%55
  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

  public estudios={
    estudios: [{}],
    fechaDePedidoDeLosExamenes: "",
    idPaciente: "",
    prioridad: "",
    sede: "",
    _id :""
  }

  public pagina = 0;

  public id: string
/// %%%%%%%%%%%%%%%%%%%%%%%% CONSTRUCTOR %%%%%%%%%%%%%%%%%%%%%%%%%%  
  constructor(private _consultaService: ConsultaService,private activatedRoute: ActivatedRoute,
              private _usgService: USGService,
    ) { }


  // %%%%%%%%%%%%%%%%%%%%  ONINIT %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    /* console.log(this.id); */
    
    this.obtenerUSG();
  }

  ///%%%%%%%%%%%%%%%%%%%%%%55 local storage %%%%%%%%%%%%%%%%5

  grabarestudioUltra()
  {
    localStorage.setItem('ultrasonido',JSON.stringify(this.id));
    localStorage.setItem('fechaUsg',JSON.stringify(this.estudios.fechaDePedidoDeLosExamenes));
  }

  grabaridPaciente(){
    localStorage.setItem('idPaciente',JSON.stringify(this.estudios.idPaciente));
    localStorage.setItem('idPedidoUltra',JSON.stringify(this.estudios._id));
  }

// %%%%%%%%%%%%%%%%%%%%%%%%% METODOS %%%%%%%%%%%%%%%%%%%%
  datosPaciente(paciente ){
    this.paciente=paciente
    /* console.log(this.paciente) */
  }

  obtenerUSG(){
    this._usgService.verUltrsonidoUSG(this.id)
    .subscribe((data) => {
      
      this.estudios = data['data']
      // console.log(this.estudios);
      
      this.datosPaciente(data['data']['idPaciente'])

       this.grabaridPaciente()
    })  

  }
}