import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-exploracion-fisica',
  templateUrl: './exploracion-fisica.component.html',
  styleUrls: ['./exploracion-fisica.component.css']
})
export class ExploracionFisicaComponent implements OnInit {

  @Output() exploracionFisica = new EventEmitter<string>();
  contacto: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.contacto = new FormGroup({
      piel: new FormControl('SIN DATOS PATOLOGICOS'),
      mucosas: new FormControl('SIN DATOS PATOLOGICOS'),
      torax: new FormControl('SIN DATOS PATOLOGICOS'),
      abdomen: new FormControl('SIN DATOS PATOLOGICOS'),
      higado: new FormControl('SIN DATOS PATOLOGICOS'),
      bazo: new FormControl('SIN DATOS PATOLOGICOS'),
      observaciones_explofisi: new FormControl(),
      ganglios: new FormControl('SIN DATOS PATOLOGICOS'),
      observaciones_diagnostico: new FormControl()
   });
   this.exploracionFisica.emit(this.contacto.value);
  }

  formularioExploracion(form: NgForm){
    this.exploracionFisica.emit(form.form.value);
  }
}
