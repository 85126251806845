<form autocomplete="off" [formGroup]="forma">
    
    <div class="container-fluid">
        <h1 h1 class="centerxd">{{elementos.ESTUDIO}}</h1>
    </div>

    <!--<div class="card">
        <div class="form-group row center">
            <label class="col col-form-label">NOMBRE DEL ESTUDIO: {{elementos.ESTUDIO}}   </label>
        </div>
    </div>
    <br>
    <br>-->

    <div class="card container-fluid">


        <div class="form-group row center">
            <label class="col-md-3 col-form-label">MACHOTE</label>

        </div>


        <div formArrayName="grupos" *ngFor="let entrada of grupos.controls ; let i =index">
            <div [formGroupName]="i" class="form-group row">

                <div class="col-md">
                    <!-- <input class="form-control" formControlName="elementos" type="text" name="nombre"> -->
                    <textarea style="border-radius: 15px; border-color: #e3e3e3; margin-left: 1rem; text-transform: uppercase;" formControlName="machote" name="machote" id="" cols="110" rows="15">   </textarea>
                </div>

            </div>

            <div class="input-group mb-3">
                <!-- <div class="input-group mb-3" style="display: block"> -->
                <button type="button" class="btn btn-success" (click)="agregarCampo()" style="margin-right: 2rem;">+</button>
                <!-- </div> -->


                <button type="button" class="btn btn-danger" (click)="borrarCampo(i)">-</button>

            </div>

        </div>

        <div class="form-group row">
            <label class="col-5 col-form-label">&nbsp;</label>
            <div class="input-group col-md-2">
                <button class="btn btn-outline-primary btn-block" (click)="enviarUSG()">
                    ENVIAR
                  </button>
            </div>
        </div>


    </div>




</form>