import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { pacienteCompleto } from './../../interfaces/pacientes.interface';
import Swal from 'sweetalert2';
import { AgendaService } from '../../services/agenda/agenda.service';

@Component({
  selector: 'app-actualizar-paciente',
  templateUrl: './actualizar-paciente.component.html',
  styleUrls: ['./actualizar-paciente.component.css']
})
export class ActualizarPacienteComponent implements OnInit {
  public id;
  public agendaAct:any = []
  public paciente:pacienteCompleto = {} as pacienteCompleto;

  constructor(private _pacienteService: PacientesService,
              private _router: Router,
              private activatedRoute: ActivatedRoute,
              private _AgendaService: AgendaService) { }
              

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerPacientesPorId();
    this.GetAgendaID()
  }

  edad(event){
    var fecha = new Date(event)
    this.paciente.fechaNacimientoPaciente = fecha.toISOString().slice(0,10)
    var hoy = new Date()
    var edad = (hoy.getTime() - fecha.getTime())
    var edadfinal = Number((edad / (31536000000 )))
    this.paciente.edad = Math.floor(edadfinal)
  }

  obtenerPacientesPorId() {
    this._pacienteService.getPacienteBtID(this.id).subscribe((resp:any)=>{
      if (resp.ok) {
        this.setDatosPaciente(resp['paciente']);
        this.paciente = resp['paciente'];
      }
    })
  }

  setDatosPaciente(datos:any){
    this.paciente = datos;
    this.paciente.fechaNacimientoPaciente = this.paciente.fechaNacimientoPaciente.split('T')[0]
  }

  GetAgendaID(){
    JSON.parse(localStorage.getItem('ActualizarUsuarioID'))
    if(JSON.parse(localStorage.getItem('ActualizarUsuarioID'))!){
      this._AgendaService.getAgendaId(JSON.parse(localStorage.getItem('ActualizarUsuarioID'))._id).subscribe((resp:any)=>{
        if(resp.ok) {
          this.agendaAct = resp['data']
        }
      })
    }
  }

  actualizarPaciente(form:NgForm){
    let avento ={
      Paciente: this.id
    }
    let nombreCompleto = (form.form.value.nombrePaciente.concat(' '+ form.form.value.apellidoPaterno+' '+form.form.value.apellidoMaterno)).trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    if(JSON.parse(localStorage.getItem('ActualizarUsuarioID'))!){
      Swal.fire({
        title: "¿Seguro que desea Sustituir al Paciente?",
        text:   "Puedes elegir aun",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: "SI",
        cancelButtonText: "CANCELAR",
      }).then(resultado => {
        if (resultado.value) {
         resultado.value
        } else {
         localStorage.removeItem('ActualizarUsuarioID')
        }
      });
    }
    if(JSON.parse(localStorage.getItem('ActualizarUsuarioID'))!){
      this.agendaAct.filter(element => {
        this._AgendaService.UpdateCitaID(element._id,avento).subscribe((resp:any)=>{
          if(resp.ok) {
            Swal.fire('Agenda Actualizada', '', 'success');
            localStorage.removeItem('ActualizarUsuarioID');
          }
        })
      });
    }
    this._pacienteService.actualizarFiPacientes( this.id,Object.assign(form.form.value, {nombreCompletoPaciente:nombreCompleto})).subscribe((resp:any)=>{
      if(resp.ok) {
        Swal.fire('Datos actualizados', '', 'success');
      }else{
        Swal.fire('Revisa el internet', 'Intenta de nuevo', 'error');
      }
    })
    return  this._router.navigateByUrl('/paciente');
  }

}
