<div class="container">

    <h1 class="title-header">EDITAR SEGUIMIENTO</h1>

    <div class="card p-4 mt-4">
        <label class="title-secondary mb-2">DATOS DEL SEGUIMIENTO</label>
        <form (ngSubmit)="guardar( f )" #f="ngForm">
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5">DEPARTAMENTO</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="departamento" [class.is-invalid]="departamento.invalid && departamento.touched" ngModel required #departamento="ngModel">
                            <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                            <option >One</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">NOMBRE</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" name="nombreSeg" [class.is-invalid]="nombreSeg.invalid && nombreSeg.touched" ngModel required #nombreSeg="ngModel">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">CATEGORIA</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="categoria" [class.is-invalid]="categoria.invalid && categoria.touched" ngModel required #categoria="ngModel">
                            <option value="Pendiente">PENDIENTE</option>
                            <option value="Generado" selected>GENERADO</option>
                            <option value="Finalizado">FINALIZADO</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">SOLICITANTE</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" disabled>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5">DEPARTAMENTO A SOLICITAR</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="depaSolitud" [class.is-invalid]="depaSolitud.invalid && depaSolitud.touched" ngModel required #depaSolitud="ngModel">
                            <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                            <option >One</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">ID</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" disabled="disabled" name="idProveedor" ngModel >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">SERVICIOS</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="servicios" [class.is-invalid]="servicios.invalid && servicios.touched" ngModel required #servicios="ngModel">
                            <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                            <option >One</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">ESTADO</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="estadoSegui" [class.is-invalid]="estadoSegui.invalid && estadoSegui.touched" ngModel required #estadoSegui="ngModel">
                            <option hidden selected>SELECCIONA UN ESTADO</option>
                            <option>One</option>
                          </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <label class="col sm-3">DESCRIPCIÓN</label>
            <textarea class="form-control" name="descripcion"[class.is-invalid]="descrip.invalid && descrip.touched" ngModel required #descrip="ngModel" rows="3"></textarea>

            <div class="container mt-3">
                <div class="d-grid gap-2 col-2 mx-auto">
                    <button type="submit" [disabled]="" class="btn btn-primary">AGREGAR</button>
                  </div>
            </div>
        </div>
    </form>
    </div>

</div>
