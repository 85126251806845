import { Component, OnInit } from '@angular/core';
import { ProveedorService } from '../servicio-proveedor/proveedor.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';;

@Component({
  selector: 'app-listado-proveedor',
  templateUrl: './listado-proveedor.component.html',
  styleUrls: ['./listado-proveedor.component.css']
})
export class ListadoProveedorComponent implements OnInit {

  public proveedores = [];
  public pagina = 0;
  public totalproveedor:string;

  constructor( private ProveedorService: ProveedorService) { }

  ngOnInit(): void { 
    this.obtenerProveedores();
  }

  obtenerProveedores(){
    this.ProveedorService.getProveedor().subscribe((data:any) => {
      this.proveedores = data.data;
      this.totalproveedor = data['data'].results;
    })
  }

  borrarProveedor(id){
    Swal.fire({
      title: '¿SEGURO QUE DESEAS ELIMINAR EL PROVEEDOR?',
      text: "NO SE PODRA REVERTIR LA ACCIÓN",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#13B42D',
      cancelButtonColor: '#FF0000',
      cancelButtonText: 'NO CANCELAR',
      confirmButtonText: 'SI, ACEPTAR'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProveedorService.eliminarProveedor(id).subscribe((resp:any)=>{
          this.proveedores = resp.data;
          this.totalproveedor = resp['data'].results;
        });
        Swal.fire(
          'SE ELIMINO EL PROVEEDOR EXITOSAMENTE',
          '',
          'success'
        )
      }
    })
  }
}
