<div class="container-fluid">
    <div class="row mb-3">
      <div class="col-md-9">
        <app-titulos [title]="'DIVIDENDOS POR SEDE'" [rol]="'pacientes'"></app-titulos>
      </div>  

      <div class="col-md-3 mt-2 ">

          <button class="btn-censur"  [disabled]="btnPrint"  (click)="printTicket()">  IMPRIMIR </button>
      </div>

    </div>

    <div class="row  mb-3">
        <div class="col-md-4">
            <input type="date" #fecha1 (change)="busquedaFecha1(fecha1)"  [max]="today" name="fecha1" class="form-control input-censur" id="">
        </div>
        <div class="col-md-4">
            <input type="date" #fecha2 (change)="busquedaFecha2(fecha2)" [value]="today" [max]="today" name="fecha2" class="form-control input-censur" id="">
        </div>
        <div class="col-md-4">
            <select name="" id="" [(ngModel)]="busqueda.sede" #sede class="input-censur select-input" (change)="getSells( sede.value )">
                <option value=""> Selecciona una sede </option>
                <option [value]="sede.nomenclatura" *ngFor="let sede of sedesFilter"> {{ sede.encargado }} -  {{ sede.nomenclatura }} </option>
                
                
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>USUARIO</p>
                        </div>
                        <div class="col text_header">
                            <p>TOTAL DE LA COMPRA</p>
                        </div>
                        <div class="col text_header">
                            <p> GANANCIA DEL SOCIO</p>
                        </div>
                        
                        <div class="col text_header">
                            FOLIO
                        </div>

                        <div class="col text_header">
                            PORCENTAJE PROMEDIO
                        </div>




                    </div>
                </div>
                <div class="card-body">
                    <div >
                        <div class="row pointer" [routerLink]="['/hoja/consumo/por/usuario']"  *ngFor="let item of ventas; let i = index" >
                            
                            <div class="col-md-1">                            
                                    <p> {{ i +1 }} </p>
                            </div>

                            <div class="col text-center">
                              <p> {{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }} </p>
                            </div>
                            
 
                            <div class="col text-center">
                                <p> {{ item.totalCompra | currency }} </p>
                                   
                            </div>



                            <div class="col text-center">
                                <p>
                                    {{  item.ganancia  | currency }}
                                </p>
                            </div>
                            
                            <div class="col text-center">
                                <p> {{ item.folio }} </p>
                            </div>

                            <div class="col text-center">
                                <p> PORCENTAJE </p>
                            </div>

                            
                        </div>
                    </div>
                </div>
                
                <div class="card-body">

                    <div class="row">
                        <hr>
                        <div class="col-md-6">
                            <p class="text-center"  >
                                 <strong class="strong-text" > TOTAL SOCIO: {{ totalGanancia  | currency }} </strong>  
                            </p>

                        </div>
                        <div class="col-md-6 text-center">                            
                            <p class="text-center" >
                                TOTAL DE LA COMPRA: {{ totalCompra | currency }}
                            </p>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
        
    </div>
</div>