import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-servicios',
  templateUrl: './select-servicios.component.html',
  styleUrls: ['./select-servicios.component.css']
})
export class SelectServiciosComponent implements OnInit {

  constructor(
    public _router: Router
  ) { }

  ngOnInit(): void {
  }
  irAUnServicio(  servicio ){
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    this._router.navigate([ `/serviciosInt/${servicio}`]);
  }

}
