import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-header-receta',
  templateUrl: './header-receta.component.html',
  styleUrls: ['./header-receta.component.css']
})
export class HeaderRecetaComponent implements OnInit {

  @Input() paciente  = {
    // _id:'',
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    _id:'',
    registro:''
  };

  public fecha: string;
  public hora: string;

  constructor() { }

  ngOnInit(): void {
    this.fecha = moment().format('L');
    this.hora = moment().format('LT');
    // console.log( this.paciente );
  }

}
