<div class="content">
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p>¿ES DE ORIGEN MEXICANO?</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" value="SI" name="nacionalidad" formControlName="nacionalidad" id="nacionalidad" (focus)="activarLugar('SI')" (blur)="formularioIndicadores(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" id="nacionalidad" name="nacionalidad" formControlName="nacionalidad"  (focus)="activarLugar('NO')" (blur)="formularioIndicadores(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="lugar">
            <div class="col">
                <p>ESPECIFIQUE LUGAR:</p>
            </div>
            <div class="col">
                <input type="text" name="nacionalidad"  formControlName="nacionalidad"  ngModel (blur)="formularioIndicadores(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>VIAJE AL EXTRANJERO EN LOS ÚLTIMOS 6 MESES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="viajes" formControlName="viajes" (focus)="activarFecha('SI')" (blur)="formularioIndicadores(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="viajes" formControlName="viajes" (focus)="activarFecha('NO')" (blur)="formularioIndicadores(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="fecha">
            <div class="col">
                <p>ESPECIFIQUE LUGAR Y FECHA:</p>
            </div>
            <div class="col">
                <input type="text" name="viajes" formControlName="viajes" ngModel (blur)="formularioIndicadores(form)">
            </div>
        </div>
    </form>
</div>