import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegradosService } from '../../../services/servicios/integrados.service';
import { SocioService } from '../../../services/socio/socio.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 
@Component({
  selector: 'app-stepper-socio-editar',
  templateUrl: './stepper-socio-editar.component.html',
  styleUrls: ['./stepper-socio-editar.component.css']
})
export class StepperSocioEditarComponent implements OnInit {

  public id: string;
  public serviceEdit = {
    _id: "",
    ESTUDIO: "",
    PRECIO_MEMBRESIA:"",
    PRECIO_MEMBRESIA_URGENCIA: "",
    PRECIO_MEMBRESIA_HOSPITALIZACION: "",
    PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA: "",
    PRECIO_PUBLICO: "",
    PRECIO_PUBLICO_URGENCIA: "",
    PRECIO_PUBLICO_HOSPITALIZACION: "",
    PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA: "",
    name:""
  };
  public utilidad = {
        rangoUtilidad:"",
        preciosRangoA:[
          {
            porcentajePrecioPublicoA:"",
            porcentajePrecioMembresiaA:"",
            porcentajePrecioPublicoUrgenciaA:"",
            porcentajePrecioMembresiaUrgenciaA:"",
            porcentajePrecioPublicoHospitalizacionA:"",
            porcentajePrecioMembresiaHospitalizacionA:"",
          }
        ]
        ,
        preciosRangoB:[
          {
            porcentajePrecioPublicoB:"",
            porcentajePrecioMembresiaB:"",
            porcentajePrecioPublicoUrgenciaB:"",
            porcentajePrecioMembresiaUrgenciaB:"",
            porcentajePrecioPublicoHospitalizacionB:"",
            porcentajePrecioMembresiaHospitalizacionB:"",
          }
        ]
        ,
        preciosRangoC:[
          {
            porcentajePrecioPublicoC:"",
            porcentajePrecioMembresiaC:"",
            porcentajePrecioPublicoUrgenciaC:"",
            porcentajePrecioMembresiaUrgenciaC:"",
            porcentajePrecioPublicoHospitalizacionC:"",
            porcentajePrecioMembresiaHospitalizacionC:"",
          }
        ]
        ,
        idServicio: "",
        idSede: "",
        name:"",
        _id:""
  };
  public nombre:string;
  public sede:string;
  public editar= false;

  constructor(private _router: Router,private _route:ActivatedRoute, private _service:IntegradosService, private _socio:SocioService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id'); 
    this.nombre=this._route.snapshot.paramMap.get('servicio');
    this.getService(); 
    this.getSede();
    this.obtenerUtilidadServ();  
    
    
  }

  obtenerUtilidadServ(){
    this._socio.obtenerUtilidadServ(this.id,this.sede).subscribe((resp:any) => {
      if(resp.ok){
        /* console.log(resp); */
        if(resp.data.length > 0){
          /* this.utilidad= resp.data[0]; */
          this.setUtilidadServicio(resp.data[0]);
          this.editar=true;
        }else{
          /* console.log("entro"); */
          
          this.utilidad=this.utilidad;
          /* console.log(this.utilidad); */
          
        }
      }
    })
  }

  setUtilidadServicio(utilidad){
    this.utilidad=utilidad
    /* console.log(this.utilidad); */
  }

  getSede(){
    this.sede= localStorage.getItem('sede')
  }

  enviar(form: NgForm){
    //console.log(form.controls);
    if(this.editar){
      /* console.log("entro"); */
      this._socio.updateUtilidad(this.utilidad._id,this.utilidad).subscribe((resp:any) => {
        if(resp.ok){
           Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA UTILIDAD SE A MODIFICADO CORRECTAMENTE',
            })
          this._router.navigateByUrl('dash/'+this.sede+'/'+this.nombre);
        }
      })
    }else{
      this.setUtilidad(form.controls);
      this._socio.utilidad(this.utilidad).subscribe((resp:any) =>{
        if(resp.ok){
          this.aler();
          this._router.navigateByUrl('dash/'+this.sede+'/'+this.nombre);
        }
      });     
    }
  }

  aler(){
     Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA UTILIDAD SE CREO CORRECTAMENTE',
            })
  }

  setUtilidad(form){
    this.utilidad.rangoUtilidad= form.rangoUtilidad.value;

    this.utilidad.idServicio=form.idServicio.value;
    this.utilidad.idSede=form.sede.value;
    this.utilidad.name=form.Servicio.value;
  }

  getService(){
    this._route.params.subscribe(params => {
      var id = params['id'];
      this._service.getServicioById(id).subscribe(
        (res:any) => {
          if(res.data){
            this.serviceEdit = res.data;
            this.nombre=res.data.name;
            //console.log(this.serviceEdit);
          }else{
            // this._router.navigate(['/ambulancia']);
          }
        },
        err => { 
          console.log(err);
          // this._router.navigate(['/ambulancia']);  
        }
      )
    });
  }

}
