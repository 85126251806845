import { Component, OnInit } from '@angular/core';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import {  ConsultaService } from '../../../../services/consultas/consulta/consulta.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import autoTable, { Column } from 'jspdf-autotable';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bitacora-consulta-gral',
  templateUrl: './bitacora-consulta-gral.component.html',
  styleUrls: ['./bitacora-consulta-gral.component.css']
})
export class BitacoraConsultaGralComponent implements OnInit {

  public consultas:any[] =[];
  public fecha:String;

  public nombreDoctror="";
  public totalpaciente:string;
  public pagina = 0;
  public imprimir = {
    indice: 0,
    fecha: '',
    hora:  '',
    nombre: '',
    edad: '',
    sexo: '',
    diagnostico: '',
    curp:'',
    enfermeria:'',
    observaciones:''
  }
  public noSe = [];

  constructor(private _consultaService: ConsultaService,
              private spinner: NgxSpinnerService,
              private _wsLognService: WsLoginService,
              private _router:Router) {}

  ngOnInit(): void {
    this.spinner.show();
    this.fecha = moment().format('L');
    this.obtenerConsultasMedico();
    this._wsLognService.escucharBitacoraDoctor().subscribe(arg => {
      if(arg){
        this.obtenerConsultasMedico();
      }
    });
  }


  obtenerNombreDoctor(){
    this.nombreDoctror = JSON.parse( localStorage.getItem('usuario') ).nombre
  }


  obtenerConsultasMedico(){
    this.obtenerNombreDoctor();
    this._consultaService.verConsultasMedico(  this.nombreDoctror ).subscribe( (data) => {
      // console.log(data);
      /* this.consultas = data['data'].reverse(); */
      this.setConsultas(data['data']);
      this.totalpaciente = data['data'].results;
      this.spinner.hide();
    })
  }

  setConsultas(consultas:any){
    consultas.forEach(element => {
      if(element.paciente != null){
        this.consultas.push(element)
      }
    });
    this.setPaciente(this.consultas);
    this.consultas.reverse();
  }

  cambiarEstado(id: string, consulta) {
    
     let estado = {
      status:'Medico'
    }

    this._consultaService.cambiarEstadoConsulta(id, estado)
    .subscribe(data => {}); 

    if(consulta === 1 || consulta == 0){
      this._router.navigateByUrl('/historia/clinica/'+id);
    }else if(consulta > 1){
      this._router.navigateByUrl('/hoja/evolucion/'+id);
    }
  }

  setPaciente(data) {
    let i= 0;
    for (let element of data) {    
      this.imprimir.indice= i + 1;
      this.imprimir.fecha= element.fechaIngreso,
      this.imprimir.hora= element.horaIngreso,
      this.imprimir.nombre= element.paciente.nombrePaciente,
      this.imprimir.edad= element.paciente.edad,
      this.imprimir.sexo= element.paciente.genero,
      this.imprimir.diagnostico= element.diagnosticoActual,
      this.imprimir.curp= element.paciente.curp,
      this.imprimir.enfermeria= element.enfermeraAtendio,
      this.imprimir.observaciones= element.motivoIngreso
      //console.log(this.imprimir.indice);
      
      this.noSe.push(this.imprimir)
      this.imprimir = {
        indice: 0 ,
        fecha: '',
        hora:  '',
        nombre: '',
        edad: '',
        sexo: '',
        diagnostico: '',
        curp:'',
        enfermeria:'',
        observaciones:''
      }
      i++
    }
  }

  imprimirBitacora(){
    // 'p', 'mm', 'a4'
   /*  const doc:any = new jsPDF('l', 'cm', 'legal');
    doc.autoTable({orientation:'p', html: '#bitacora'});
    doc.save( `Bitácora medicina general${this.fecha}.pdf`); */
    let values: any;
    values = this.noSe.map((elemento) => Object.values(elemento));
    const doc:any = new jsPDF();
    doc.autoTable({
      head: [['#', 'Fecha', 'Hora', 'Nombre', 'Edad', 'Sexo', 'Diagnostico', 'Curp', 'Enfermeria', 'Observaciones' ]],
      body: values
    })
    doc.save('Bitácora_De_Consulta_Externa_'+this.fecha+'_.pdf')
  }
}
