import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LocationServiceService } from 'src/app/services/otrosService/location-services.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import {  MatStepper  } from '@angular/material/stepper';
import {  Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { fnCalculaCURP } from 'src/app/functions/curpyrfc';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {
  
  @ViewChild('registroForm') registroNgForm: NgForm;
  
  public asentamiento = [];
  public municipios = [];
  public estadocp = "";
  public codigoPostal = "";
  public zipCode = [];
  public validar = false;

  //nuevas variabless

  registroForm: FormGroup;
  edadNgForm = 0;
  fechaNacimientoNgForm:any 
  datosfiscales = 'no'

  constructor(public _locationService: LocationServiceService,
              public _pacienteService: PacientesService,
              private _formBuilder: FormBuilder,
              private _router: Router,
              private spinner: NgxSpinnerService) { }
  
  ngOnInit(): void {
    this.registroForm = this._formBuilder.group({
      nombrePaciente              : ['', [Validators.required]],
      apellidoPaterno             : ['', [Validators.required]],
      apellidoMaterno             : [''],
      fechaNacimientoPaciente     : [''],
      edad                        : ['0', [Validators.required]], 
      genero                      : ['', [Validators.required]],
      lugarOrigen                 : [''], 
      entidadNacimiento           : [''],
      curp                        : [''],
      telefono                    : [''],
      estadoCivilPaciente          : [''],
      numeroExpediente            : [''],
      correoPaciente              : [''],
      callePaciente               : [''],
      numeroIntPaciente           : [''],
      numeroPaciente              : [''],
      paisActual                  : ['MEXICO'],
      cpPaciente                  : [''],   
      estadoPaciente              : [''],
      coloniaPaciente             : [''],
      municipioPaciente           : [''],        
      localidadPaciente           : [''],
      referenciaPaciente         : [''],
      razonSocial1RFC             : [''],
      cpRazonSocial               : [''],
      razonSocial1                : [''],
      razonSocial1Municipio       : [''],
      razonSocial1Estado          : [''],
      razonSocial1Calle           : [''],
      razonsocial1Telefono        : [''],
      razonSocial1colonia         : [''],
      correoRazonSocial1          : [''],
      razonSocial1NumInt          : [''],
      razonSocial1NumExt          : [''],
      razonSocialPais             : [''],
      contactoEmergencia1Nombre   : [''],
      contactoEmergencia1ApellidoPaterno: [''],
      contactoEmergencia1ApellidoMaterno: [''],
      contactoEmergencia1Edad     : [''],
      contactoEmergencia1Telefono : [''],
      contactoEmergencia1EstadoCivil: [''],
      contactoEmergencia1Genero   : [''],
      contactoEmergencia1Correo   : [''],
      contactoEmergencia1Parentesco: [''],
      disponente                  : ['DISPONENTE'],
      sede                        : ['TLYC01'],
      personalRegistro            : ['']
    })
    this.spinner.show();
    this.expediente()
  }

  edad(event){
    var fecha = new Date(event)
    this.fechaNacimientoNgForm = fecha.toISOString().slice(0,10)
    var hoy = new Date()
    var edad = (hoy.getTime() - fecha.getTime())
    var edadfinal = Number((edad / (31536000000 )))
    this.edadNgForm = Math.floor(edadfinal)
    this.registroForm.patchValue({
      edad: this.edadNgForm,
    });
  }
  
  expediente(){
    this._pacienteService.getUltimoExpediente()
    .subscribe((resp:any) => { 
      let x =parseInt(resp.data) + 1;
      this.registroForm.patchValue({
        numeroExpediente: x,
      });
      resp.data
    })
  }

  genero(event){
    var genero = event
    this.registroForm.patchValue({
      genero: genero,
    });
  }

  estado(event){
    var estado = event
    this.registroForm.patchValue({
      entidadNacimiento: estado,
    })
    this.curp()
    //console.log(this.registroForm.value);
  }

  curp(){
    var nombre = this.registroForm.get('nombrePaciente').value
    var ape1 =  this.registroForm.get('apellidoPaterno').value
    var ape2 = this.registroForm.get('apellidoMaterno').value
    var genero = this.registroForm.get('genero').value
    var fecha = this.fechaNacimientoNgForm
    var estado = this.registroForm.get('entidadNacimiento').value
    var curp = fnCalculaCURP( nombre.toUpperCase(), ape1.toUpperCase(), ape2.toUpperCase(),fecha ,genero.toUpperCase(), estado );
    this.registroForm.patchValue({
      curp: curp,
    })
  }

  estadoCivil(event){
    var estadoCivil = event
    this.registroForm.patchValue({
      estadoCivilPaciente: estadoCivil,
    })
  }

  pais(event){
    var pais = event
    this.registroForm.patchValue({
      paisActual: pais,
    })
  }

  cogidoPostal(event,event2){
    this.estadocp = event2
    this.codigoPostal = event
    this.municipios = []
    this.asentamiento = []
    this.estadocp = ''
    if (event2 == false) {
      let data = {
        estado: '',
        codigoPostal: event
      }
      this._pacienteService.setCodigoPostal(data)
      .subscribe((resp:any) => { 
        this.estadocp = resp.data[0].cpEstado
        this.registroForm.patchValue({
          estadoPaciente: this.estadocp,
        })
        var result = resp.data.reduce(function (r, a) {
          r[a.cpMunicipio] = r[a.cpMunicipio] || [];
          r[a.cpMunicipio].push(a); 
          return r;
        },Object.create(null))
        for (const key in result) {
          if (result[key] != undefined) {
            this.municipios.push(key)
          }
        }
        resp.data.forEach(element => {
          this.asentamiento.push(element.cpAsentamiento)
        });
      })
    }
    if (event == false) {
      this.municipios = []
      this.asentamiento = []
      let data = {
        estado: event2,
        codigoPostal: ''
      }
      this._pacienteService.setCodigoPostal(data)
      .subscribe((resp:any) => { 
        this.registroForm.patchValue({
          estadoPaciente: this.estadocp,
        })
        this.zipCode = resp.data
        var result = resp.data.reduce(function (r, a) {
          r[a.cpMunicipio] = r[a.cpMunicipio] || [];
          r[a.cpMunicipio].push(a); 
          return r;
        },Object.create(null))
        for (const key in result) {
          if (result[key] != undefined) {
            this.municipios.push(key)
          }
        }
      })
    }
  }

  municipio(event){
    this.registroForm.patchValue({
      municipioPaciente: event,
    })
  }
  
  municipio2(event){
    this.zipCode.forEach(element => {
      if (element.cpMunicipio == event) {
        this.asentamiento.push(element.cpAsentamiento)
      }
    });
    this.registroForm.patchValue({
      coloniaPaciente: event,
    })
  }

  ciudad(event){
    this.registroForm.patchValue({
      coloniaPaciente: event,
    })
  }

  ciudad2(event){
    this.zipCode.forEach(element => {
      if (element.cpAsentamiento == event) {
        this.registroForm.patchValue({
          cpPaciente: element.codigoPostal
        })
      }
    });
    this.registroForm.patchValue({
      coloniaPaciente: event,
    })
  }

  domicilioFiscal(event){
    this.datosfiscales = event
  }

  generoContacto(event){
    this.registroForm.patchValue({
      contactoEmergencia1Genero: event,
    })
  }

  estadoCivilContacto(event){
    this.registroForm.patchValue({
      contactoEmergencia1EstadoCivil: event,
    })
  }

  parentesco(event){
    this.registroForm.patchValue({
      contactoEmergencia1Parentesco: event,
    })
  }

  nuevoDisponente(){
    this.validar = false;
    this.registroForm.patchValue({
      personalRegistro : JSON.parse(localStorage.getItem('usuario')).nombre
    })
    if(this.registroForm.status == 'INVALID'){
      Swal.fire({
        icon: 'error',
        title: 'COMPLETA LOS CAMPOS REQUERIDOS:',
        html: '<li style="text-align: left;">NOMBRE DEL PACIENTE</li> <li style="text-align: left;">APELLIDO PATERNO</li> <li style="text-align: left;">GÉNERO</li> <li style="text-align: left;">EDAD</li>',
      })
    }else{
      this.registroForm.value.nombrePaciente = this.registroForm.value.nombrePaciente.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
      this.registroForm.value.apellidoPaterno = this.registroForm.value.apellidoPaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
      this.registroForm.value.apellidoMaterno = this.registroForm.value.apellidoMaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
      let nombreCompleto = (this.registroForm.value.nombrePaciente.concat(' ',this.registroForm.value.apellidoPaterno,' ',this.registroForm.value.apellidoMaterno)).normalize('NFD').replace(/[\u0300-\u036f]/g,"");
      this._pacienteService.setPacientes(Object.assign(this.registroForm.value,{nombreCompletoPaciente:nombreCompleto}) ,'TLYC01').subscribe((data) => {
        if(  data['ok'] ){
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'PACIENTE REGISTRADO',
          })
          this._router.navigateByUrl('/paciente')
        }
      });
    }
  }
}

