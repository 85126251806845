import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MedicinaPreventivas } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';

@Component({
  selector: 'app-medicina-prev',
  templateUrl: './medicina-prev.component.html',
  styleUrls: ['./medicina-prev.component.css']
})
export class MedicinaPrevComponent implements OnInit {

  @Input() _id='';
  @Input() genero='';
  @Input() edad=0;
  @Input() medicinaPreventiva: MedicinaPreventivas = {} as MedicinaPreventivas;
  
  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarMedicinaP(form: NgForm){
    this.medicinaPreventiva = form.value
    this.medicinaPreventiva.idPaciente = this._id;
    this._HistoriaClinicaService.agregarMedicinaPreventiva( this.medicinaPreventiva )
    .subscribe( (data:any )=> { 
      if( data['ok'] ){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE GUARDADO LA MEDICINA PREVENTIVA',
        })
      }
    });
  }

  actualizarMedicinaP(form: NgForm){
    let id = this.medicinaPreventiva._id;
    this.medicinaPreventiva = form.value;
    this.medicinaPreventiva.idPaciente = this._id;
    this.medicinaPreventiva._id = id;
    this._HistoriaClinicaService.actualizarMedicinaPreventivaEnElPaciente(this.medicinaPreventiva._id, this.medicinaPreventiva)
    .subscribe((data:any) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZO LA MEDICINA PREVENTIVA',
        })
      }
    })
  }

}
