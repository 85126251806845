import { Component, OnInit } from '@angular/core';
import { IntegradosService,DRF } from 'src/app/services/servicios/integrados.service';

@Component({
  selector: 'app-dash-resultados',
  templateUrl: './dash-resultados.component.html',
  styleUrls: ['./dash-resultados.component.css']
})
export class DashResultadosComponent implements OnInit {

  public pagina : number = 0;
  public totalservicios:number;
  servicio:DRF[]=[];

  constructor(private _serviciosService: IntegradosService) { }

  ngOnInit(): void {
    this.servicio= this._serviciosService.getDashServicio();
    this.totalservicios = this.servicio.length;
    this.pagina = 0;
  }

}
