import {
  Component,
  ViewChild,
  TemplateRef,
  forwardRef,
  OnInit,
} from "@angular/core";
import {
  Calendar,
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventInput,
  FullCalendarComponent,
  CalendarApi,
} from "@fullcalendar/angular";
import { ModuloService } from 'src/app/services/asignarModulo/modulo.service';

//import momentPlugin from '@fullcalendar/moment'
import * as Moment from "moment"; /// calendario
import { extendMoment } from "moment-range";
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  NgForm,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import esLocale from "@fullcalendar/core/locales/es";
import { filterNameByPaciente } from "../../classes/helpers/filterNamePaciente";
import { PacientesService } from "../../services/pacientes/pacientes.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { PaquetesService } from "src/app/services/paquetes/paquetes.service";

import { AgendaService } from "../../services/agenda/agenda.service";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

declare let $: any;
/**git commint -m "cambios diseño"
git add .
git push
 */
@Component({
  selector: "app-agenda",
  templateUrl: "./agenda.component.html",
  styleUrls: ["./agenda.component.css"],
})
export class AgendaComponent implements OnInit {
  @ViewChild("modalContent", { static: true }) modalContent?: TemplateRef<any>;
  @ViewChild("modalContent1", { static: true })
  modalContent1?: TemplateRef<any>;
  @ViewChild("Calendar") calendarComponent?: FullCalendarComponent;

  // array para los sub servicios
  modalReference: NgbModal;
  addEventForm: FormGroup;
  addEventForm2: FormGroup;
  addEventForm3: FormGroup;
  addEventForm4: FormGroup;
  // CONTROL DEL ARRAY D SERVICIO EXTRA
  ServicosExtra: FormGroup;
  public Subservice = []; // array para los sub servicios
  public Subservice2 = [];
  public Subservice3 = []; // array para los sub servicios
  //// INPUTS  DE LAS SEMANAS
  public lunes: any = [];
  public martes: any = [];
  public miercoles: any = [];
  public jueves: any = [];
  public viernes: any = [];
  public sabado: any = [];
  public domingo: any = [];
  // array para los sub servicios
  ser: boolean = false;
  calendarApi: CalendarApi;
  calendarPlugins = [
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
    bootstrapPlugin,
  ];
  calendarOptions: CalendarOptions;
  //opcionSeleccionado: string = '0'; HP
  fechaNacimientoNgForm: any;
  edadNgForm = 0;
  public evento = []; // array para guardar el input 2 de agendar citas
  public eventos: any = []; // array para guardar el input 2 de agendar citas
  //Arrays de las consultas de las citas
  public agendaCitas: any = [];
  public agendaServicios: any = [];
  public nomDoctor: String;
  public terminaregistro: Boolean;
  public Registro: Boolean;
  events: EventInput[] = [];
  // currentEvents: EventApi[] = [];
  ///nombres de los titulos
  public nameser = ""; // variable de
  public namedoc = ""; // variable de
  public namesede = "";
  public namesubser = "";
  public datepro = "";
  public textcolor = "";
  /// lista de arrays consultas
  public serviceSublist = [];
  /// variable de colores del servicio
  public colores = "";
  // variables de la fecha y hora del formulario
  public subser2 = false; // sub servicios del select de consultas
  public subser3 = false; // sub servicios del select del formulario
  public subser4 = false; // sub servicios del select del formulario
  public subser5 = false; // sub servicios del select del formulario
  public subser6 = true; // sub servicios del select del formulario
  public AllHours: Boolean = false; // sub servicios del select del formulario
  public fecsemEnt: any = []; // fecha de inicio del dia
  public fecsemSal: any = []; // fecha de inicio del dia
  public InterHour = "00:30:00"; // fecha de inicio del dia
  public InterHours = 30; // fecha de inicio del dia
  public DiaActual = ""; // fecha de inicio del dia
  public servi = [];
  initialized = false;
  public pacientes2: any[] = [];
  public pacientes: any[] = [];
  // public serviciosConsultas = [];
  public medicos = [];
  public serv = [];
  public AllPacientes = [];
  public AllDatesPacientes = [];
  public PACIENTES_SERVICES = [];
  public DoctoresHoy = [];
  public DoctoresHoyHours = [];
  public filtropacientes = "";
  public total: any;
  public longText = `The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog
  from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was
  originally bred for hunting.`;
  public users: any = {
    nombre: "",
    sede: "",
    role: "",
  };
  public agenda: any = {
    _id: "",
    idPaciente: {
      sede: "",
    },
    idPersonal: "",

    agendo: {
      sede: "",
    },
    status: "",
    color: "",
    idServicio: {
      ESTUDIO: "",
      PRECIO_MEMBRESIA: "",
      PRECIO_MEMBRESIA_HOSPITALIZACION: "",
      PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA: "",
      PRECIO_MEMBRESIA_URGENCIA: "",
      PRECIO_PUBLICO: "",
      PRECIO_PUBLICO_HOSPITALIZACION: "",
      PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA: "",
      PRECIO_PUBLICO_URGENCIA: "",
      name: "",
    },
    motivo: "",
    title: "",
    start: "",
    end: "",
    fechaInicio: "",
    fechaFin: "",
  };

  /// array de las consultas de servicios
  public agenServi: any = {
    _id: "",
    agendo: "",
    color: "",
    titulo: "",
    Servicio: "",
    horaInicio: "",
    horaFin: "",
    fechaInicio: "",
    fechaFin: "",
    sede: "",
    idPersonal: "",
  };
  public cita: any = {
    paciente: {
      _id: "",
      nombrePaciente: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      genero: "",
      fechaNacimientoPaciente: "",
      telefono: "",
      edad: "",
      curp: "",
      sede: "",
    },
    idServicio: {
      ESTUDIO: "",
      name: "",
    },
    motivo: "",
    status: "",
  };
  public idDate: string = "";
  public servicio = {
    _id: "",
    ObjectId: "",
    ESTUDIO: "",
    PRECIO_PUBLICO: "",
    PRECIO_PUBLICO_URGENCIA: "",
    PRECIO_PUBLICO_HOSPITALIZACION: "",
    PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA: "",
    PRECIO_MEMBRESIA: "",
    PRECIO_MEMBRESIA_URGENCIA: "",
    PRECIO_MEMBRESIA_HOSPITALIZACION: "",
    PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA: "",
    name: "",
  };
  public service = [];
  public DatesPac: any = [];
  public Servicepac: any = [];
  //public ServicePag: any = [];
  public allserver: Boolean = false;
  public DatesPaySelect: Boolean = false;
  public DatesPaySelectUpdate: Boolean = false;
  public pagina = 0;
  public paginas2 = 0;

  public nomser = [];
  public nommser = [];
  public locals;
  public doctor = {
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    genero: "",
    fechaNacimientoPaciente: "",
    telefono: "",
  };
  public paginas: number = 0;
  public paginas1: number = 0;
  public totalService: string;

  public viewcalen = "dayGridMonth";
  constructor(
    private _servicePaq: PaquetesService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private _pacienteService: PacientesService,
    private _AgendaService: AgendaService,
    private spinner: NgxSpinnerService,
    private _router: Router,
    private _moduleservicio: ModuloService
  ) {
    this.addEventForm = this.formBuilder.group({
      nombrePaciente: new FormControl([], [Validators.required]),
      edad: new FormControl(["0"], [Validators.required]),
      genero: new FormControl([], [Validators.required]),
      telefono: new FormControl([], [Validators.required]),
      apellidoPaterno: new FormControl([""], [Validators.required]),
      apellidoMaterno: new FormControl([""], [Validators.required]),
      fechaNacimientoPaciente: new FormControl([""]),
      paisNacimiento: new FormControl([""]),
      entidadNacimiento: new FormControl([""]),
      curp: new FormControl([""]),
      estadoCivilPaciente: new FormControl([""]),
      numeroExpediente: new FormControl([""]),
      correoPaciente: new FormControl([""]),
      colonia: new FormControl([""]),
      cpPaciente: new FormControl([""]),
      estadoPaciente: new FormControl([""]),
      paisPaciente: new FormControl([""]),
      localidad: new FormControl([""]),
      callePaciente: new FormControl([""]),
      numeroIntPaciente: new FormControl([""]),
      numeroPaciente: new FormControl([""]),
      referenciaPaciente: new FormControl([""]),
      referenciaPaciente2: new FormControl([""]),
      razoncocial1RFC: new FormControl([""]),
      cpRazonSocial: new FormControl([""]),
      razonSocial1: new FormControl([""]),
      razonSocial1Municipio: new FormControl([""]),
      razonSocial1Estado: new FormControl([""]),
      razonSocial1Calle: new FormControl([""]),
      razonsocial1Telefono: new FormControl([""]),
      razonSocial1colonia: new FormControl([""]),
      correoRazonSocial1: new FormControl([""]),
      razonSocial1NumInt: new FormControl([""]),
      razonSocial1NumExt: new FormControl([""]),
      razonSocialPais: new FormControl([""]),
      contactoEmergencia1Nombre: new FormControl([""]),
      contactoEmergencia1ApellidoPaterno: new FormControl([""]),
      contactoEmergencia1ApellidoMaterno: new FormControl([""]),
      contactoEmergencia1Edad: new FormControl([""]),
      contactoEmergencia1Telefono: new FormControl([""]),
      contactoEmergencia1EstadoCivil: new FormControl([""]),
      contactoEmergencia1Genero: new FormControl([""]),
      contactoEmergencia1Correo: new FormControl([""]),
      contactoEmergencia1Parentesco: new FormControl([""]),
      disponente: new FormControl(["DISPONENTE"]),
      NuevoUser: new FormControl(false),
      User: new FormControl(false),
    });
    this.addEventForm2 = this.formBuilder.group({
      Paciente: new FormControl([], [Validators.required]),
      Doctor: new FormControl([], [Validators.required]),
      agen: new FormControl([], [Validators.required]),
      stat: new FormControl([], [Validators.required]),
      color: new FormControl([], [Validators.required]),
      Servicios: new FormControl([], [Validators.required]),
      Subservicios: new FormControl([]),
      FechaEnt: new FormControl([], [Validators.required]),
      FechaSal: new FormControl([], [Validators.required]),
      HoraEnt: new FormControl([], [Validators.required]),
      HoraSal: new FormControl([], [Validators.required]),
      Motivo: new FormControl([], [Validators.required]),
      Descripcion: new FormControl([], [Validators.required]),
      Reservacion: new FormControl([], [Validators.required]),
      sedecon: new FormControl([], [Validators.required]),
      addservice2: new FormControl(false),
      addservice3: new FormControl(false),
      id: new FormControl([]),
      servicioExt: this.formBuilder.array([]),
    });

    this.addEventForm3 = this.formBuilder.group({
      consultafec: new FormControl([]),
      consultaserv: new FormControl([]),
      Subserv: new FormControl([]),
      consultasede: new FormControl([]),
      addservice: new FormControl(false),
      consultName: new FormControl([]),
      consultdoctor: new FormControl([]),
    });
    this.addEventForm4 = this.formBuilder.group({
      Doctor: new FormControl([]),
      agendoser: new FormControl([]),
      colorser: new FormControl([]),
      tituloser: new FormControl([]),
      servicioser: new FormControl([]),
      fechainicioser: new FormControl([]),
      fechafinser: new FormControl([]),
      horainicioser: new FormControl([]),
      horafinser: new FormControl([]),
      sedeser: new FormControl([]),
      lunes: new FormControl(false),
      martes: new FormControl(false),
      miercoles: new FormControl(false),
      jueves: new FormControl(false),
      viernes: new FormControl(false),
      sabado: new FormControl(false),
      domingo: new FormControl(false),
    });
    this.ServicosExtra = this.formBuilder.group({
      Paciente: new FormControl([]), /// posible error le borre las comillas
      Doctor: new FormControl([]),
      agen: new FormControl([]),
      stat: new FormControl([]),
      color: new FormControl([]),
      Servicios: new FormControl([]),
      Subservicios: new FormControl([]),
      FechaEnt: new FormControl([]),
      FechaSal: new FormControl([]),
      HoraEnt: new FormControl([]),
      HoraSal: new FormControl([]),
      Motivo: new FormControl([]),
      Descripcion: new FormControl([]),
      Reservacion: new FormControl([]),
      sedecon: new FormControl([]),
      id: new FormControl([]),
      filsirver: new FormControl([]),
    });
  }
  ngOnInit(): void {
    this.spinner.show();
    this.users = JSON.parse(localStorage.getItem("usuario"));
    this.terminaregistro = false;
    this.Registro = false;
    this.SelectSedeDefault();
    this.obtenerPacientes();
    this.obtenerUsuarios()
    forwardRef(() => Calendar);
    this.GetService()
    this.nomDoctor = "";
    let type = this.ReturnSedeid();
    this.GetAgenda(type)
    this.GetServiceAgenda(type)
    this.GetAgendaCons();
    this.DiaActual = this.ObtenerFecha();
    //console.log(this.ServicePag);
    //console.log(this.DatesPac);
  }


  
  GetAgendaConDoctor() {
    this.namedoc = this.addEventForm3.controls["consultdoctor"].value;
    this.allserver = true;
    console.log("-----------------------------------------------");
    console.log(this.addEventForm3.controls["consultdoctor"].value);
    
    if (this.addEventForm3.controls["consultdoctor"].value != "") {
      this.calendarComponent.getApi().changeView("timeGridWeek");
      this.addEventForm3.patchValue({
        consultaserv: "personal",
      });
      this.events = [];
      this.agendaCitas = [];
      this.agendaServicios = [];
      this.agendaCitas = this.ConsultForDoctor(this.addEventForm3.controls["consultdoctor"].value);
      this.agendaServicios = this.ConsultServiceForDoctor(this.addEventForm3.controls["consultdoctor"].value);
      this.ViewCitas(this.agendaCitas);
      this.ViewServicios("", "background", this.agendaServicios);
      this.spinner.hide();
      this.Subservice2 = [];                          ///"61ba38936722ff03e6aa68e8"
      if (this.addEventForm3.controls["consultdoctor"].value == "624a44335875b84e40c3ed80") {
        this.Subservice3 = this.ConsultServiciosDavid();
        return (this.Subservice2 = this.ConsultServiciosDavid());
      } else {
        this.Subservice3 = this.ConsultServiciosDoctores();
        return (this.Subservice2 = this.ConsultServiciosDoctores());
      }
    } else {
      console.log("holi sin");
    }
  }

  GetAgendaCons() {
    if (JSON.parse(localStorage.getItem("ServicioAgenda")) != null) {
      this.DatesPac = JSON.parse(localStorage.getItem("ServicioAgenda"));
      this.GetAgendaConsultas();
    } else {
      this.DatesPac = [];
    }
  }
  GetAgendaConsultas() {
    this.Servicepac = Array.from(
      new Set(this.DatesPac.servicios.map((s) => s.name))
    ).map((name) => {
      return {
        name: name,
        servicios: this.DatesPac.servicios.filter((s) => s.name === name),
      };
    });
    //console.log(this.Servicepac)
  }
  AllHour() {
    //console.log(this.AllHours);

    if (this.AllHours == false) {
      this.fecsemEnt = "00:10:00";
      this.fecsemSal = "23:59:00";
      this.cargarcalendario();
      return (this.AllHours = true);
    }
    if (this.AllHours == true) {
      this.GetAgendaCon("");
      return (this.AllHours = false);
    }
  }
  // AsigHorarioUpdate(event) {
  //   if (event.idServicio.name == "consulta") {
  //     if (event.idServicio._id != "5fd3ebca08ccbb0017712f0d") {
  //       event.idServicio.name = "Consulta Especialista";
  //     }
  //     if (event.idServicio._id == "5fd3ebca08ccbb0017712f0d") {
  //       event.idServicio.name = "5fd3ebca08ccbb0017712f0d";
  //     }
  //   }
  //   if (
  //     this.addEventForm3.controls["consultaserv"].value !=
  //     "Consulta Especialista"
  //   ) {
  //     if (
  //       event.idServicio.name !=
  //       this.addEventForm3.controls["consultaserv"].value
  //     ) {
  //       this.GetAgendaCon("");
  //     }
  //   } else if (
  //     this.addEventForm3.controls["consultaserv"].value ==
  //     "Consulta Especialista"
  //   ) {
  //     if (
  //       event.idServicio._id != this.addEventForm3.controls["Subserv"].value
  //     ) {
  //       this.GetAgendaCon("");
  //     }
  //   }

  //   this.DatesPaySelectUpdate = true;
  //   if (
  //     event.idServicio.name ==
  //       this.addEventForm3.controls["consultaserv"].value &&
  //     event.idServicio._id != "5fd3ebca08ccbb0017712f0d"
  //   ) {
  //     if (
  //       this.addEventForm3.controls["consultaserv"].value !=
  //       "Consulta Especialista"
  //     ) {
  //       if (
  //         this.addEventForm3.controls["consultaserv"].value ==
  //         event.idServicio.name
  //       ) {
  //         return this.SelectForm2(event);
  //       }
  //     }
  //     if (
  //       this.addEventForm3.controls["consultaserv"].value ==
  //       "Consulta Especialista"
  //     ) {
  //       if (
  //         this.addEventForm3.controls["Subserv"].value == event.idServicio._id
  //       ) {
  //         this.SelectForm2(event);
  //         return this.addEventForm3.patchValue({
  //           Subserv: event.idServicio._id,
  //         });
  //       }
  //       if (
  //         this.addEventForm3.controls["Subserv"].value != event.idServicio._id
  //       ) {
  //         this.addEventForm3.patchValue({ Subserv: event.idServicio._id });
  //         this.GetAgendaCon("");
  //         return this.SelectForm2(event);
  //       }
  //     }
  //   }
  //   if (event._id == "5fd3ebca08ccbb0017712f0d") {
  //     if (
  //       this.addEventForm3.controls["consultaserv"].value ==
  //       event.idServicio._id
  //     ) {
  //       return this.SelectForm2(event);
  //     }

  //     if (
  //       this.addEventForm3.controls["consultaserv"].value !=
  //       event.idServicio._id
  //     ) {
  //       this.addEventForm3.patchValue({ consultaserv: event.idServicio._id });

  //       this.SelectForm2(event);
  //       this.GetAgendaCon("");
  //     }
  //   } else {
  //     this.addEventForm3.patchValue({ consultaserv: event.idServicio.name });
  //     if (
  //       this.addEventForm3.controls["consultaserv"].value ==
  //       "Consulta Especialista"
  //     ) {
  //       this.addEventForm3.patchValue({ Subserv: event.idServicio._id });
  //     }
  //     this.GetAgendaCon("");
  //     return this.SelectForm2(event);
  //   }
  // }
  AsigHorario(event) {
    this.DatesPaySelect = true;
    
    if (
      event.name == this.addEventForm3.controls["consultaserv"].value &&
      event._id != "5fd3ebca08ccbb0017712f0d"
    ) {
      if (
        this.addEventForm3.controls["consultaserv"].value !=
        "Consulta Especialista"
      ) {
        if (this.addEventForm3.controls["consultaserv"].value == event.name) {
          return this.SelectForm(event);
        }
      }
      if (
        this.addEventForm3.controls["consultaserv"].value ==
        "Consulta Especialista"
      ) {
        if (this.addEventForm3.controls["Subserv"].value == event._id) {
          this.SelectForm(event);
          return this.addEventForm3.patchValue({ Subserv: event._id });
        }
        if (this.addEventForm3.controls["Subserv"].value != event._id) {
          this.addEventForm3.patchValue({ Subserv: event._id });
          this.GetAgendaFalse()

          return this.SelectForm(event);
        }
      }
    } else if (event._id == "5fd3ebca08ccbb0017712f0d") {
      if (this.addEventForm3.controls["consultaserv"].value == event._id) {
        return this.SelectForm(event);
      }

      if (this.addEventForm3.controls["consultaserv"].value != event._id) {
        //console.log("son giaulkeeeeees 2")
        this.addEventForm3.patchValue({ consultaserv: event._id });

        this.SelectForm(event);
        this.GetAgendaFalse()

      }
    } else {
      this.addEventForm3.patchValue({ consultaserv: event.name });
      if (
        this.addEventForm3.controls["consultaserv"].value ==
        "Consulta Especialista"
      ) {
        //console.log("entra 1401")
        this.addEventForm3.patchValue({ Subserv: event._id });
      }
      this.GetAgendaFalse()
      return this.SelectForm(event);
    }
  }
  GetAgendaFalse(){
    if(this.allserver != true){
      this.GetAgendaCon("");
      
    }
  }
  SelectForm2(event: any) {
    let idgenda = JSON.parse(localStorage.getItem("usuario"))._id;

    //console.log(event)

    this.addEventForm2.patchValue({
      stat: "PENDIENTE",
      agen: idgenda,
      Reservacion: "Presencial",
      Paciente: event.idPaciente._id,
      id: event._id,
    });

    this.addEventForm.patchValue({
      User: true,
      nombrePaciente: event.idPaciente.nombrePaciente,
      edad: event.idPaciente.edad,
      genero: event.idPaciente.genero,
      telefono: event.idPaciente.telefono,
      apellidoPaterno: event.idPaciente.apellidoMaterno,
      apellidoMaterno: event.idPaciente.apellidoPaterno,
    });

    this.addEventForm2.patchValue({ Servicios: event.idServicio._id });
    //console.log(this.addEventForm2)
  }
  SelectForm(event: any) {
    let idgenda = JSON.parse(localStorage.getItem("ServicioAgenda")).agen._id;

    //console.log(idgenda)

    this.addEventForm2.patchValue({
      stat: "PENDIENTE",
      agen: idgenda,
      Reservacion: "Presencial",
    });
    //console.log(this.addEventForm2)

    this.addEventForm2.patchValue({
      Paciente: JSON.parse(localStorage.getItem("ServicioAgenda")).paciente._id,
    });
    this.addEventForm.patchValue({
      User: true,
      nombrePaciente: JSON.parse(localStorage.getItem("ServicioAgenda"))
        .paciente.nombrePaciente,
      edad: JSON.parse(localStorage.getItem("ServicioAgenda")).paciente.edad,
      genero: JSON.parse(localStorage.getItem("ServicioAgenda")).paciente
        .genero,
      telefono: JSON.parse(localStorage.getItem("ServicioAgenda")).paciente
        .telefono,
      apellidoPaterno: JSON.parse(localStorage.getItem("ServicioAgenda"))
        .paciente.apellidoMaterno,
      apellidoMaterno: JSON.parse(localStorage.getItem("ServicioAgenda"))
        .paciente.apellidoPaterno,
    });

    this.addEventForm2.patchValue({ Servicios: event._id });
    //console.log(this.addEventForm2)
  }
  NewUser() {
    if (this.addEventForm.controls["NuevoUser"].value != false) {
      this.addEventForm2.patchValue({ Paciente: ["new pasciente"] });
      this.addEventForm.reset();
      this.addEventForm.patchValue({ NuevoUser: true, User: true });
    } else if (this.addEventForm.controls["NuevoUser"].value != true) {
      this.addEventForm.reset();
      this.addEventForm2.patchValue({ Paciente: [] });
      this.addEventForm.patchValue({ NuevoUser: false, User: false });
    }
    //console.log(this.addEventForm.value);

    this.CambiarActivosInput();
  }
  InputServicios() {
    this.lunes = this.addEventForm4.controls["lunes"].value;
    this.martes = this.addEventForm4.controls["martes"].value;
    this.miercoles = this.addEventForm4.controls["miercoles"].value;
    this.jueves = this.addEventForm4.controls["jueves"].value;
    this.viernes = this.addEventForm4.controls["viernes"].value;
    this.sabado = this.addEventForm4.controls["sabado"].value;
    this.domingo = this.addEventForm4.controls["domingo"].value;
  }

  SelectDays() {
    this.InputServicios();
    return this.selectDays();
  }
  selectDays() {
    let result = [];
    let resultdays = [];
    if (this.domingo === true) {
      result = this.getYears(0);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }
    if (this.lunes === true) {
      result = this.getYears(1);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }
    if (this.martes === true) {
      result = this.getYears(2);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }
    if (this.miercoles === true) {
      result = this.getYears(3);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }
    if (this.jueves === true) {
      result = this.getYears(4);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }
    if (this.viernes === true) {
      result = this.getYears(5);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }
    if (this.sabado === true) {
      result = this.getYears(6);
      result.forEach((element) => {
        resultdays.push(element);
      });
    }

    //   this.HorarioDoctores(resultdays)
    return resultdays;
  }

  /*HorarioDoctores(SelectDay: any) {
    //console.log("CULOOOOOOOOOOOOOOOOOOOOOOOOOOOOS")
    let arrayname: any = []

    let agendaDoc = Array.from(new Set(this.agendaServicios.map(service => {
      if (this.nameser.toLocaleUpperCase() == service.Servicio) {
        if (service.idPersonal._id == service.idPersonal._id) {
          return service.idPersonal.NOMBRE
        }
        return
      }
      return
    }
    )))
      .map((resultname: string) => {
        let namess = resultname
        let arrayname: any = []
        let arrayse = Array.from(new Set(SelectDay.map(service => {
          resultname = this.selectFecha(service)
          //console.log(resultname)
          let seul = new Date(resultname)
          //console.log(seul);

          if (resultname == resultname) {
            //console.log(resultname)

            return resultname
          }
        }
        )))
          .map((resultname: string) => {
            this.agendaServicios.filter((service) => {
              if (this.nameser.toLocaleUpperCase() == service.Servicio) {
                if (resultname == resultname) {
                  let isu = this.selectFecha2(resultname)
                  let arrayresult = this.getYears(isu)

                }
                return
              }
              return
            }
            )
            return {
              diasemana: resultname,
              names: arrayname,
            }
          });
        //console.log(arrayse)

        /*this.agendaServicios.filter((service) => {
          if (resultname == service.idPersonal.NOMBRE) {
            if (this.nameser.toLocaleUpperCase() == service.Servicio) {
              SelectDay.forEach(element => {
                if (service.fechaInicio == element) {
                  arrayname.push(service)





                }
              })
              return
            }
            return
          }
          return
        }
        )*/
  /*  return {
      diasemana: namess,
      names: arrayse,
    }
  });
//console.log(agendaDoc)
}*/
  selectFecha(fechaIncio: any) {
    let days = [
      "Lunes" + "-" + "2022-06-06",
      "Martes" + "-" + "2022-07-06",
      "Miércoles" + "-" + "2022-08-06",
      "Jueves" + "-" + "2022-09-06",
      "Viernes" + "-" + "2022-10-06",
      "Sábado" + "-" + "2022-11-06",
      "Domingo" + "" + "2022-12-06",
    ];
    let d = new Date(fechaIncio); //Miércoles
    return days[d.getUTCDay() - 1];
  }
  selectFecha2(fechaIncio: any) {
    let d = new Date(fechaIncio); //Miércoles
    return [d.getUTCDay() - 1];
  }
  getYears(day: any) {
    var d = new Date(),
      month = d.getFullYear(),
      mondays = [];
    d.setDate(1);
    // Get the first Monday in the month
    while (d.getDay() !== day) {
      d.setDate(d.getDate() + 1);
    }
    // Get all the other Mondays in the month
    while (d.getFullYear() === month) {
      mondays.push(moment(new Date(d.getTime())).format("YYYY-MM-DD"));
      d.setDate(d.getDate() + 7);
    }
    return mondays;
  }

  get servicioExtArr() {
    return this.addEventForm2.get("servicioExt") as FormArray;
  }
  borrarServicioExtra(i: number, paciente: any) {
    //console.log(paciente)
    //console.log(this.ServicePag)

    if (localStorage.getItem("ServicioAgenda") != null) {
      //console.log("entra borrar ")
      if (
        paciente.value.Paciente ===
        JSON.parse(localStorage.getItem("ServicioAgenda")).paciente._id
      ) {
        JSON.parse(localStorage.getItem("ServicioAgenda")).servicios.forEach(
          (element) => {
            if (element._id === paciente.value.Servicios) {
              this.Servicepac.forEach((element1) => {
                if (paciente.value.filsirver == element1.name) {
                  element1.servicios.push(element);

                  this.Resetform();
                  this.addEventForm.reset();
                  if (this.servicioExtArr.value == 0) {
                    this.addEventForm2.patchValue({ addservice2: false });
                  } else {
                    this.addEventForm2.patchValue({ addservice2: true });
                  }
                  this.addEventForm.patchValue({ User: false, NewUser: false });
                }
              });
            }
          }
        );
      }
    }
    //console.log(this.DatesPaySelectUpdate)
    //console.log(this.ServicePag.length)

    // if (this.ServicePag.length != 0) {
    //   this.agenda.forEach((servicio) => {
    //     if (paciente.value.id == servicio._id) {
    //       /// ya se teiene el servicio

    //       this.ServicePag.forEach((element4) => {
    //         if (element4.id == servicio.idPaciente._id) {
    //           element4.servicio.forEach((element5) => {
    //             //console.log(element4);
    //             //console.log(element5);
    //             //console.log(paciente.value.filsirver);

    //             if (paciente.value.filsirver == element5.name) {
    //               //console.log("holi");
    //               element5.servicios.push(servicio);
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    // }

    this.servicioExtArr.removeAt(i);
  }

  selectName() {
    let nameser = this.addEventForm2.controls["Servicios"].value;
    let services = [];
    services = this.ServInd(nameser);
    var namesers = "";
    services.forEach((elemen: any) => {
      namesers = elemen.ESTUDIO;
      let service2 = {
        id: elemen.id,
        ESTUDIO: namesers,
      };
    });
    this.ServicosExtra.patchValue({
      Subservicios: namesers,
    });
  }

  ocultAddser() {
    if (this.addEventForm2.controls["addservice2"].value != false) {
      //console.log("ENTRA EN != true ADD2")
      this.addEventForm2.patchValue({ addservice2: true });
    } else if (this.addEventForm2.controls["addservice2"].value != true) {
      //console.log("ENTRA EN != false ADD2")
      this.servicioExtArr.reset();
      this.servicioExtArr.clear();
      this.addEventForm2.patchValue({ addservice2: false });
    }
  }
  ocultAddser2() {
    if (this.addEventForm2.controls["addservice3"].value != false) {
      //console.log("ENTRA EN != FALSE ADD3")
      this.addEventForm2.patchValue({ addservice3: true });
    } else if (this.addEventForm2.controls["addservice3"].value != true) {
      //console.log("ENTRA EN != true ADD3")
      this.addEventForm2.patchValue({ addservice3: false });
      this.servicioExtArr.reset();
      this.servicioExtArr.clear();
    }
  }
  agregarServicioExtra() {
    let Paciente = this.addEventForm2.controls["Paciente"].value,
      Doctor = this.addEventForm2.controls["Doctor"].value,
      agen = this.addEventForm2.controls["agen"].value,
      stat = "PENDIENTE",
      color = this.addEventForm2.controls["color"].value,
      FechaEnt = this.addEventForm2.controls["FechaEnt"].value,
      FechaSal = this.addEventForm2.controls["FechaSal"].value,
      HoraEnt = this.addEventForm2.controls["HoraEnt"].value,
      HoraSal = this.addEventForm2.controls["HoraSal"].value,
      Reservacion = this.addEventForm2.controls["Reservacion"].value,
      id = this.addEventForm2.controls["id"].value,
      sedecon = this.addEventForm3.controls["consultasede"].value,
      Servicios = this.addEventForm2.controls["Servicios"].value,
      Subservicios = this.addEventForm2.controls["Subservicios"].value,
      consultaserv = this.addEventForm3.controls["consultaserv"].value;

    //console.log(Subservicios)
    //console.log(Servicios)

    let consultValidateArray = [];
    let consultValidate = [];

    consultValidateArray = this.ConsultValideDateAndHoursSedeArray(
      consultaserv,
      sedecon,
      FechaEnt,
      HoraEnt
    );
    //console.log(consultValidateArray)

    if (Servicios.length != "" && Subservicios.length != 0) {
      //console.log("ENTRAN LOS DOS PERO SUBSERVICIO SE GUARDA")
      this.ServicosExtra.patchValue({
        Servicios: Servicios,
        Subservicios: Subservicios,
      });
    } else if (Servicios.length != "" && Subservicios.length == 0) {
      //console.log("ENTRAN SERVICIO Y SE GUARDA")
      this.ServicosExtra.patchValue({ Servicios: Servicios });
      this.ServInd(Servicios).forEach((element) => {
        if (this.addEventForm2.controls["Servicios"].value == element._id)
          this.ServicosExtra.patchValue({ Subservicios: element.ESTUDIO });
      });
    }
    if (consultaserv != 0) {
      //console.log("ENTRA EN ENDOSCOPIA")
      if (consultValidateArray.length != 0 && this.nameser != "laboratorio") {
        //console.log("MUESTRA EL VALOR DEL TIEMPO")// 1401 p
        //console.log(this.InterHours)// 1401 p

        HoraEnt = moment(HoraEnt, "HH:mm")
          .add(this.InterHours, "minutes")
          .format("HH:mm");
        HoraSal = moment(HoraEnt, "HH:mm")
          .add(this.InterHours, "minutes")
          .format("HH:mm");
        //console.log(HoraEnt);
        //console.log(HoraSal);
        //IMPRIMIRMOS EL RESULTADO
        this.addEventForm2.patchValue({
          HoraEnt: HoraEnt,
          HoraSal: HoraSal,
        });

        consultValidate = this.ConsultValideDateAndHoursSede(
          consultaserv,
          sedecon,
          FechaEnt,
          HoraEnt
        );
        //console.log(consultValidate)
        //console.log("consultValidate eeeeeeeeeeeeeeeeeeeeeeeeeee")
        //console.log(this.nameser)
        if (consultValidate.length == 0 || this.nameser != "laboratorio") {
          return this.SaveServerExtra(
            Paciente,
            Doctor,
            HoraEnt,
            HoraSal,
            agen,
            stat,
            color,
            FechaEnt,
            FechaSal,
            Reservacion,
            sedecon,
            consultaserv,
            Servicios,
            id
          );
        } else {
          return Swal.fire({
            icon: "error",
            title: "",
            text: "HAY UNA CITA AGENDADA EN ESE HORARIO",
          });
        }
      } else {
        return this.SaveServerExtra(
          Paciente,
          Doctor,
          HoraEnt,
          HoraSal,
          agen,
          stat,
          color,
          FechaEnt,
          FechaSal,
          Reservacion,
          sedecon,
          consultaserv,
          Servicios,
          id
        );
      }
    }
    return this.SaveServerExtra(
      Paciente,
      Doctor,
      HoraEnt,
      HoraSal,
      agen,
      stat,
      color,
      FechaEnt,
      FechaSal,
      Reservacion,
      sedecon,
      consultaserv,
      Servicios,
      id
    );
  }
  SaveServerExtra(
    Paciente: any,
    Doctor: any,
    HoraEnt: any,
    HoraSal: any,
    agen: any,
    stat: any,
    color: any,
    FechaEnt: any,
    FechaSal: any,
    Reservacion: any,
    sedecon: any,
    consultaserv: any,
    servicio: any,
    id: any
  ) {
    this.QuitarCitaArray(Paciente, servicio);

    //console.log("id")
    //console.log(id)
    if (id != null) {
      this.ServicosExtra.patchValue({
        id: id,
      });
    }

    this.ServicosExtra.patchValue({
      Paciente: Paciente,
      Doctor: Doctor,
      HoraEnt: HoraEnt,
      HoraSal: HoraSal,
      agen: agen,
      stat: stat,
      color: color,
      FechaEnt: FechaEnt,
      FechaSal: FechaSal,
      Reservacion: Reservacion,
      sedecon: sedecon,
      filsirver: consultaserv,
    });
    this.servicioExtArr.push(this.formBuilder.group(this.ServicosExtra.value));
    this.ServicosExtra.reset();
    this.addEventForm2.patchValue({ Subservicios: [] });
    //console.log(this.servicioExtArr)
    //console.log(this.servicioExtArr.value)
    this.ServicosExtra.patchValue({ id: [] });
  }
  QuitarCitaArray(Paciente: String, servicio: String) {
    if (
      localStorage.getItem("ServicioAgenda") != null &&
      this.DatesPaySelect == true
    ) {
      if (
        Paciente ===
        JSON.parse(localStorage.getItem("ServicioAgenda")).paciente._id
      ) {
        this.Servicepac.forEach((element) => {
          element.servicios.filter((element2, index) => {
            if (element2._id == servicio) {
              //console.log(element2)
              //console.log(index)

              element.servicios.splice(index, 1);
            }
          });
        });
      }
    }
    // if (this.ServicePag.length != 0 && this.DatesPaySelectUpdate == true) {
    //   this.ServicePag.forEach((element1) => {
    //     console.log(this.ServicePag);

    //     element1.servicio.forEach((element2) => {
    //       element2.servicios.filter((element3, index) => {
    //         //console.log(element3)

    //         if (Paciente === element3.idPaciente._id) {
    //           //console.log("elemen 111144400001t")
    //           //console.log(element3)
    //           //console.log(index)
    //           if (element3.idServicio._id == servicio) {
    //             //console.log(element3)
    //             //console.log(index)

    //             element2.servicios.splice(index, 1);
    //           }
    //         }
    //       });
    //     });
    //   });
    // }
  }
  AddInput() {
    this.servicioExtArr.value.forEach((item, index) => {
      if (item.Descripcion == null && item.Motivo == null) {
        item.Motivo = this.addEventForm2.controls["Motivo"].value;
        return (item.Descripcion =
          this.addEventForm2.controls["Descripcion"].value);
      }
    });
    //console.log(this.servicioExtArr.value)
  }
  AddInputPaciente() {
    this.servicioExtArr.value.forEach((item, index) => {
      if (item.Paciente == null && item.Paciente == null) {
        return (item.Paciente = this.addEventForm2.controls["Paciente"].value);
      }
    });
    //console.log(this.servicioExtArr.value)
  }
  GetService() {
    //this.spinner.show();
    this._AgendaService.getServicios().subscribe((resp: any) => {
      this.service = resp["data"];

      this.servi = Array.from(
        new Set(
          this.service.map((s) => {
            if (s.name == "consulta") {
              s.name = "Consulta Especialista";
            }
            return s.name;
          })
        )
      ).map((name) => {
        return {
          name: name,
          names: this.service.filter((s) => s.name === name),
        };
      });
      console.log(this.servi)
      //this.servi.splice(8  , 1)  
      // this.spinner.hide();
    });
  }
  ServiceDoctor(agenServi: any) {
    //console.log("AGENDA DOCTORES");
    var hoy = moment(new Date()).format("YYYY-MM-DD");
    agenServi.forEach((element) => {
      if (hoy == element.fechaInicio) {
        this.DoctoresHoy.push(element);
      }
    });
    //console.log(this.DoctoresHoy);
  }
  UpdateServiceDoctor() {
    //console.log("AGENDA DOCTORES 2");

    var hora = moment(new Date(), "HH:mm:ss").format("HH:mm");

    let c = this.formateaMomento(hora);

    //console.log("HORA ACTUAL");

    //console.log(c);

    this.DoctoresHoy.filter((element) => {
      let b = this.formateaMomento(element.horaFin);
      //console.log(b);

      // c <= b
      if (c <= b) {
        this.DoctoresHoyHours.push(element);
        return this.DoctoresHoyHours.sort(function (a, b) {
          // A va primero que B
          // nocheeeeee quitar && a.horafin >! '00:00'
          if (a.horaFin < b.horaFin) return -1;
          // B va primero que A
          else if (a.horaFin > b.horaFin) return 1;
          // A y B son iguales
          else return 0;
        });
      } else {
        return;
      }
    });
    //console.log(this.DoctoresHoyHours);
    //this.Paginator(this.DoctoresHoyHours, null,null)

    //console.log(this.Paginator(this.DoctoresHoyHours, 1, 5))
    //console.log(this.Paginator(this.DoctoresHoyHours, 2, 5))
    //console.log(this.Paginator(this.DoctoresHoyHours, 3, 5))
  }

  Paginator(items, page, per_page) {
    var page = page || 1,
      per_page = per_page || 5,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(items.length / per_page);
    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }

  GetServiceAgenda(type: any) {
    //console.log(type)
    this._AgendaService.getAgendaSevicios().subscribe((resp: any) => {
      /// array para mostrar la agenda
      //console.log(this.agenServi)
      this.agenServi = resp["data"];

      this.ServiceDoctor(this.agenServi);

      this.UpdateServiceDoctor();

      if (type != "") {
        //this.spinner.show();

        let agendaSer = this.agenServi.filter(
          (servicio) => servicio.sede == type
        );
        this.ViewServicios("", "block", agendaSer);
        this.spinner.hide();

        //console.log(agendaSer)
        // this.spinner.hide();

        return this.cargarcalendario();
      } else {
        //this.spinner.show();
        //console.log("permisos VIP 1.1")
        //console.log(this.agenServi)
        this.ViewServicios("sede", "block", this.agenServi);
        this.spinner.hide();

        //this.spinner.hide();
        return this.cargarcalendario();
      }
    });
  }

  GetAgenda(type: any) {
    this._AgendaService.getAgenda().subscribe((resp: any) => {
      this.agenda = resp["data"].filter(
        (element) => element.idServicio != null
      );

      // this.ServicePag = Array.from(
      //   new Set(
      //     this.agenda.map((servicio) => {
      //       if (
      //         servicio.sede == null &&
      //         servicio.horaInicio == null &&
      //         servicio.horaFin == null &&
      //         servicio.fechaInicio == null
      //       ) {
      //         if (
      //           servicio.fechaFin == null &&
      //           servicio.sede == null &&
      //           servicio.motivo == null &&
      //           servicio.descrpicion == null
      //         ) {
      //           if (servicio.color == null && servicio.idServicio != null) {
      //             return servicio.idPaciente._id;
      //           }
      //         }
      //       }
      //     })
      //   )
      // ).map((paciente: any) => {
      //   return {
      //     name: paciente,
      //     servicios: this.agenda.filter((servicio) => {
      //       if (
      //         servicio.idPaciente._id == paciente &&
      //         servicio.sede == null &&
      //         servicio.horaInicio == null &&
      //         servicio.horaFin == null &&
      //         servicio.fechaInicio == null &&
      //         servicio.fechaFin == null &&
      //         servicio.sede == null &&
      //         servicio.motivo == null &&
      //         servicio.descrpicion == null &&
      //         servicio.color == null
      //       ) {
      //         // console.log(servicio)
      //         return servicio;
      //       }
      //       return;
      //     }),
      //   };
      // });

      // this.ServicePag.forEach((element) => {
      //   if (element.name!) {
      //     this.agenda.filter((pac) => {
      //       if (pac.idPaciente._id == element.name) {
      //         //console.log(element.name._id);
      //         element.name =
      //           pac.idPaciente.nombrePaciente +
      //           " " +
      //           pac.idPaciente.apellidoPaterno;
      //         element.id = pac.idPaciente._id;
      //         element.servicio = Array.from(
      //           new Set(
      //             element.servicios.map((servicio) => {
      //               if (servicio.idServicio!) {
      //                 return servicio.idServicio.name;
      //               }
      //             })
      //           )
      //         ).map((_id: any) => {
      //           const str = Math.random().toString(36).substring(2);
      //           return {
      //             name: _id,
      //             names: _id + "" + str,
      //             servicios: element.servicios.filter((servicio) => {
      //               // console.log(servicio.idServicio.name)
      //               if (servicio.idServicio!) {
      //                 if (_id == servicio.idServicio.name) {
      //                   return servicio;
      //                 }
      //               }
      //             }),
      //           };
      //         });
      //         delete element.servicios;

      //         return element;
      //       }
      //     });
      //   }
      // });
      //this.ServicePag.splice(0, 1);
      //console.log(this.ServicePag)
      let agendaAll: any = [];
      if (type != "") {
        agendaAll = this.agenda.filter((servicio) => {
          if (servicio.status != "CANCELADA") {
            if (
              servicio.sede == type ||
              (servicio.sede != null &&
                servicio.horaInicio != null &&
                servicio.horaFin != null &&
                servicio.fechaInicio != null &&
                servicio.fechaFin != null &&
                servicio.sede != null &&
                servicio.motivo != null &&
                servicio.descrpicion != null &&
                servicio.color != null)
            ) {
              return servicio;
            }
          }
        });
      } else {
        // console.log("permisos VIP")
        agendaAll = this.agenda.filter((servicio) => {
          if (servicio.status != "CANCELADA") {
            if (
              servicio.horaInicio != null &&
              servicio.horaFin != null &&
              servicio.fechaInicio != null &&
              servicio.fechaFin != null &&
              servicio.sede != null &&
              servicio.motivo != null &&
              servicio.descrpicion != null &&
              servicio.color != null
            ) {
              return servicio;
            }
          }
        });
      }
      console.log(agendaAll);
      this.calendarComponent.getApi().changeView("dayGridMonth");
      return this.ViewCitas(agendaAll);
    });
  }
  mostrarServicioExtra(servicioExt: any) {
    for (const servicio of servicioExt) {
      this.servicioExtArr.push(
        this.formBuilder.group(servicio, [Validators.required])
      );
    }
  }
  Cambiarcolortexto(color: any) {
    // this.nameser.fontcolor(color)
    return $(".titulocam").css("color", color);
    //return document.getElementById("nameser").style.color = color;
  }
  agregarEvento(data: any) {
    if (data.start != undefined) {
      let event00 = [...this.events, data];

      this.events = Array.from(new Set(event00.map((s) => s.id))).map((id) => {
        return {
          ...event00.find((s) => s.id === id),
        };
      });
    }
  }
  calendarVisible = true;
  cargarcalendario() {
    this.calendarOptions = {
      titleFormat: {
        // will produce something like "Tuesday, September 18, 2018"
        month: "long",
        year: "numeric",
      },
      locale: esLocale,
      plugins: this.calendarPlugins,
      themeSystem: "bootstrap",
      events: this.events,
      headerToolbar: {
        left: "prev,today,next",
        center: "title",
        right: "dayGridMonth,timeGridDay,timeGridWeek",
      },
      initialView: "timeGridWeek",
      weekends: true,
      navLinks: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      select: this.handleDateSelect.bind(this),
      // dayCellContent: this.handleDayRender.bind(this), // bind is important!
      slotDuration: this.InterHour,
      slotLabelInterval: this.InterHours,
      slotMinTime: this.fecsemEnt,
      slotMaxTime: this.fecsemSal,
      expandRows: true,
      nowIndicator: true,
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "localeEs",
      },
      dayHeaderFormat: {
        weekday: "long",
        day: "numeric",
      },
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "localeEs",
      },
      fixedWeekCount: false,
      showNonCurrentDates: false,
      eventDrop: this.Movactualizad.bind(this),
      eventResize: this.Movactualizad.bind(this),
      firstDay: 0,
      eventClick: this.handleEventClick.bind(this),
    };
    console.log(this.events);
    
  }
  /*handleDayRender(arg)
  {
     let customHtml = "CMHT";
     let mystring = arg.dayNumberText;
     return mystring+' '+customHtml;
  }*/
  handleEventClick(e: EventClickArg) {
    this.idDate = "";
    this.idDate = e.event._def.publicId.toString();
    this.agenda.find((item) => {
      if (item._id == this.idDate) {
        //1401 pas
        item.idPaciente._id; // 1401
        this.cita.paciente._id = item.idPaciente._id;
        this.cita.paciente.nombrePaciente = item.idPaciente.nombrePaciente;
        this.cita.paciente.apellidoPaterno = item.idPaciente.apellidoPaterno;
        this.cita.paciente.apellidoMaterno = item.idPaciente.apellidoMaterno;
        this.cita.paciente.telefono = item.idPaciente.telefono;
        this.cita.paciente.genero = item.idPaciente.genero;
        this.cita.paciente.edad = item.idPaciente.edad;
        this.cita.paciente.curp = item.idPaciente.curp;
        this.cita.idServicio.ESTUDIO = item.idServicio.ESTUDIO;
        this.cita.idServicio.name = item.idServicio.name;
        this.cita.motivo = item.motivo;
        this.cita.status = item.status;
        this.cita.paciente.sede = item.sede;
      }
    });
    //console.log(this.cita.paciente.curp)
    this.agenda.filter((item) => {
      if (item.idPaciente._id == this.cita.paciente._id) {
        //console.log("item2")
        //console.log(item)
        if (this.cita.paciente.curp == undefined) {
          return (this.terminaregistro = false);
        } else if (this.cita.paciente.curp.length == 0) {
          return (this.terminaregistro = false);
        } else if (this.cita.paciente.curp.length != 0) {
          return (this.terminaregistro = true);
        }
      }
    });
    //console.log(this.cita.paciente._id)
    return this.modal.open(this.modalContent1, {
      size: <any>"gl",
      windowClass: "ModalAgen",
    });
  }
  registro(paciente: any) {
     this.hideModal();
    this._router.navigateByUrl("actualizar/fi/pacientes/" + paciente);
  }
  CancelarDate(event) {
    //console.log(event);

    let evento = {
      status: "CANCELADA",
    };
    Swal.fire({
      title: "¿Desea cancelar esta cita?",
      text: "Paciente:",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "CANCELAR",
      cancelButtonText: "NO CANCELAR",
    }).then((resultado) => {
      if (resultado.value) {
        this.agendaCitas.forEach((item, index) => {
          if (item._id == event) {
            this.agendaCitas.splice(index, 1);
          }
        });
        this.agenda.forEach((item, index) => {
          if (item._id == event) {
            this.agenda.splice(index, 1);
            //console.log(this.agenda)
          }
        });
        this._AgendaService
          .CancelarCita(event, evento)
          .subscribe((resp: any) => {
            if (resp.ok) {
              Swal.fire({
                icon: "success",
                title: "",
                text: "CITA CANCELADA CORRECTAMENTE",
              });
              this.calendarComponent.getApi().getEventById(event).remove();
            }
            this.hideModal();
            return this.spinner.hide();
          });
      } else {
        //console.log("no")
      }
    });
  }

  Movactualizad(info) {
    //console.log(info)
    //console.log(info.event)//1401 pp
    //console.log(info.event._def)
    this.idDate = "";
    this.idDate = info.event._def.publicId;

    let evento = {
      HoraEnt: moment(info.event.start, "HH:mm:ss").format("HH:mm"),
      HoraSal: moment(info.event.end, "HH:mm:ss").format("HH:mm"),
      FechaEnt: moment(info.event.startStr).format("YYYY-MM-DD"),
      FechaSal: moment(info.event.endStr).format("YYYY-MM-DD"),
    };
    Swal.fire({
      title: "ACTUALIZAR HORARIO?",
      text: "Cita:" + info.event._def.title,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        this.agendaCitas.forEach((item, index) => {
          if (item._id == this.idDate) {
            this.agendaCitas.splice(index, 1);
          }
        });
        this.agenda.forEach((item, index) => {
          if (item._id == this.idDate) {
            this.agenda.splice(index, 1);
            //console.log(this.agenda)
          }
        });
        this._AgendaService
          .actualizarCita(this.idDate, evento)
          .subscribe((resp: any) => {
            resp.data.forEach((element) => {
              this.events = [];

              this.agenda.push(element);
              this.agendaCitas.push(element);
            });
            if (resp.ok) {
              Swal.fire({
                icon: "success",
                title: "",
                text: "CITA AGREGADA CORRECTAMENTE",
              });
            }
            this.ViewServicios("sede", "background", this.agendaServicios);
            this.ViewCitas(this.agendaCitas);
            return this.spinner.hide();
          });
      } else {
        this.ViewServicios("sede", "background", this.agendaServicios);
        this.ViewCitas(this.agendaCitas);
        return this.spinner.hide();
      }
    });
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }
  // obtenerMedicos() {
  //   this._servicePaq.getMedicos().subscribe((data: any) => {
  //     if (data.ok) {
  //       this.setMedicos(data.data);
  //     }
  //   });
  // }
  obtenerUsuarios(){
    this._moduleservicio.getUsers()
    .subscribe((data:any) => {
        
    this.medicos = data.personal.filter(element => element.status == 'Activo' );
      console.log(this.medicos);
      
 
    })
  }
  holi(){
    console.log(this.addEventForm);
    console.log(this.addEventForm2);
    console.log(this.addEventForm3);
    console.log(this.addEventForm4);
    
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }
  ViewCitas(agendaCitas: any) {
    console.log('........',agendaCitas);
    let result = [];
    if (
      this.addEventForm3.controls["consultaserv"].value == "" &&
      this.addEventForm3.controls["Subserv"].value.length == 0
    ) {
      //console.log("etra aqui 1")
      return this.ShowViewDates(agendaCitas);
    } else if (
      this.addEventForm3.controls["consultaserv"].value != "" &&
      this.addEventForm3.controls["Subserv"].value.length == 0
    ) {
      //console.log("etra aqui 2")
      //console.log(this.addEventForm3.controls['consultaserv'].value)
      if (
        this.addEventForm3.controls["consultaserv"].value ==
        "5fd3ebca08ccbb0017712f0d"
      ) {
        result = agendaCitas.filter(
          (agenda) =>
            agenda.idServicio._id ==
            this.addEventForm3.controls["consultaserv"].value
        );
      } else {
        result = agendaCitas.filter(
          (agenda) =>
            agenda.idServicio.name ==
            this.addEventForm3.controls["consultaserv"].value
        );
      }
      return this.ShowViewDates(result);
    } else if (
      this.addEventForm3.controls["consultaserv"].value != "" &&
      this.addEventForm3.controls["Subserv"].value.length != 0
    ) {
      result = agendaCitas.filter(
        (agenda) =>
          agenda.idServicio._id == this.addEventForm3.controls["Subserv"].value
      );
      return this.ShowViewDates(result);
    }
  }

  ShowViewDates(result: any) {
    console.log('---------',result);
    
    let titlenew: string = "";

    if (result.length != 0) {
      result.forEach((elemen: any) => {
        if (JSON.parse(localStorage.getItem("usuario")).role == "Recepcion") {
          titlenew =
            elemen.idPaciente.nombrePaciente +
            " " +
            elemen.idPaciente.apellidoPaterno +
            ": " +
            elemen.idServicio.ESTUDIO;
        } else {
          titlenew =
            elemen.idServicio.ESTUDIO +
            ": " +
            elemen.idPaciente.nombrePaciente +
            " " +
            elemen.idPaciente.apellidoPaterno;
        }

        let timefinal = this.OutDate(elemen.fechaInicio, elemen.horaInicio),
          timefinal2 = this.OutDate2(elemen.fechaFin, elemen.horaFin),
          eventos = {
            id: elemen._id,
            title: titlenew,
            start: timefinal,
            end: timefinal2,
            color: elemen.color,
          };

        this.agregarEvento(eventos);
      });
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    return this.cargarcalendario();
  }
  ViewServicios(sede: any, search: any, agendaServicios: any) {
    //console.log(search)
    //console.log(agendaServicios)
    let sedename: any = [];
    agendaServicios.forEach((elemen: any) => {
      if (sede != "") {
        sedename = elemen.sede + ": " + elemen.titulo;
      } else {
        sedename = elemen.titulo;
      }

      let timefinal = new Date(elemen.fechaInicio + "T" + elemen.horaInicio),
        timefinal2 = new Date(elemen.fechaFin + "T" + elemen.horaFin),
        eventos = {
          start: timefinal,
          end: timefinal2,
          display: search,
          id: elemen._id,
          title: sedename,
          editable: false,
          color: elemen.color,
        };
      return this.agregarEvento(eventos);
    });
    this.cargarcalendario();
  }
  TypeConsulta(
    cambiocolor: any,
    namesubser: any,
    nameser: any,
    namesede: any,
    datepro: any
  ) {
    if (nameser === "5fd3ebca08ccbb0017712f0d") {
      this.CleanSubSer();
      this.validarColor(cambiocolor, "", nameser);
      this.SelecServicios(namesubser);
      this.subser3 = false;

      return this.ConsultasGeneral(nameser, namesede, datepro);
    }
    if (nameser === "Consulta Especialista") {
      //console.log("AQUI VALE VERGAAAAAAAAAAAAAAAAAAAAA");

      this.subser3 = true;

      this.SelecServicios(namesubser);
      this.validarColor(cambiocolor, namesubser, "");

      return this.ConsultasGeneral(namesubser, namesede, datepro);
    }
  } // P

  GetAgendaCon(event) {
    //variables de los arrays donde se mostraran los eventos
    this.events = [];
    this.allserver = false;
    this.addEventForm3.patchValue({ consultdoctor: [] });
    // SI HAY CONSULTAS POR FECHA
    this.datepro = this.addEventForm3.controls["consultafec"].value;
    this.nameser = this.addEventForm3.controls["consultaserv"].value;
    this.namesede = this.addEventForm3.controls["consultasede"].value;
    this.namesubser = this.addEventForm3.controls["Subserv"].value;
    this.ChangeName();
    let namesede = this.addEventForm3.controls["consultasede"].value;
    let nameser = this.addEventForm3.controls["consultaserv"].value;
    let namesubser = this.addEventForm3.controls["Subserv"].value;
    let datepro = this.addEventForm3.controls["consultafec"].value;
    this.colores = "";
    let cambiocolor = "agendarcita";

    this.users = JSON.parse(localStorage.getItem("usuario"));

    this.addEventForm2.patchValue({
      agen: this.users._id,
      stat: "PENDIENTE",
    });
    //console.log(this.addEventForm3.controls['consultaserv'].value);

    if (datepro.length != 0 && nameser.length != 0 && namesede.length != 0) {
      this.events = []; // 1401 two
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios

      this.calendarComponent.getApi().changeView("timeGridDay", datepro);
      this.subser2 = true;
      this.Subservice2 = this.ConsultName(nameser);

      this.Subservice2.splice(0, 1);
      if (nameser === "5fd3ebca08ccbb0017712f0d") {
        //1401
        //console.log("entra  x date")

        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);
        this.SelecServicios(namesubser);
        this.subser3 = false;

        return this.ConsultasGeneral(nameser, namesede, datepro);
      }
      if (nameser === "Consulta Especialista") {
        this.subser3 = true;

        this.SelecServicios(namesubser);
        this.validarColor(cambiocolor, namesubser, "");

        return this.ConsultasGeneral(namesubser, namesede, datepro);
      } else {
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);
        this.subser3 = false;
        this.agendaCitas = this.ConsultConIndDateSede(
          nameser,
          datepro,
          namesede
        );
        this.agendaServicios = this.ConsultServIndDateSede(
          nameser.toLocaleUpperCase(),
          datepro,
          namesede
        );
        this.SelecServicios(nameser);
        this.ViewServicios("", "background", this.agendaServicios);
        this.ViewCitas(this.agendaCitas);
        return this.spinner.hide();
      }
    }
    if (datepro.length != 0 && namesede.length != 0) {
      this.events = []; // 1401 two
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios
      this.calendarComponent.getApi().changeView("timeGridDay", datepro);
      this.agendaCitas = this.ConsultConDateSede(datepro, namesede);
      //console.log(agenda4)
      this.agendaServicios = this.ConsultDateSede(datepro, namesede);
      this.ViewServicios("", "background", this.agendaServicios);
      this.ViewCitas(this.agendaCitas);
      return this.spinner.hide();
    }
    if (nameser.length != 0 && namesede.length != 0) {
      // TIPO DE VISTA QUE SE MOSTRARÁ
      this.calendarComponent.getApi().changeView("timeGridWeek");
      this.events = [];
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios
      this.subser2 = true;
      if (nameser === "5fd3ebca08ccbb0017712f0d") {
        this.subser3 = false;
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);

        //let sub = this.addEventForm3.controls['Subserv'].value
        //console.log(sub)
        //console.log("AQUI ANDAAAAAAAA");
        //console.log(this.nameser);
        //console.log(this.Subservice2);

        this.Subservice2 = this.ConsultEstudio();

        //console.log(this.Subservice2);

        this.SelecServicios(nameser);

        return this.ConsultasGeneral(nameser, namesede, "");
      }
      if (nameser === "Consulta Especialista") {
        //console.log("VERGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA2")

        this.subser3 = true;
        //console.log("entra  x consulta")
        //console.log(namesubser)
        //console.log(nameser)
        // 1401 hep
        this.Subservice2 = this.ConsultName(nameser);
        //console.log(this.Subservice2)
        //console.log(namesede)

        this.Subservice2.splice(0, 1);
        this.SelecServicios(namesubser);
        this.validarColor(cambiocolor, namesubser, "");

        return this.ConsultasGeneral(namesubser, namesede, "");
      } else {
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);
        this.Subservice2 = this.ConsultName(nameser);
        this.Subservice2.splice(0, 1);
        this.subser3 = false; // 1401 HELP
        this.SelecServicios(nameser);
        this.agendaCitas = this.ConsultConServSede(nameser, namesede);
        this.agendaServicios = this.ConsultServSede(
          nameser.toLocaleUpperCase(),
          namesede
        );
        this.ViewServicios("", "background", this.agendaServicios);

        this.ViewCitas(this.agendaCitas);
        return this.spinner.hide();
      }
    }
    if (nameser.length != 0 && datepro.length != 0) {
      this.fecsemEnt = "00:10:00";
      this.fecsemSal = "23:59:00";
      this.events = [];
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios
      this.calendarComponent.getApi().changeView("timeGridDay", datepro);
      this.subser2 = true;
      this.Subservice2 = this.ConsultName(nameser);

      this.Subservice2.splice(0, 1);
      if (nameser === "5fd3ebca08ccbb0017712f0d") {
        //1401
        //console.log("entra  x date")
        this.SelecServicios(nameser);
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);

        this.subser3 = false;

        return this.ConsultasGeneral(nameser, "", datepro);
      }
      if (nameser === "Consulta Especialista") {
        this.Subservice2 = this.ConsultName(nameser);
        this.Subservice2.splice(0, 1);
        this.SelecServicios(namesubser);
        this.validarColor(cambiocolor, namesubser, "");

        return this.ConsultasGeneral(namesubser, "", datepro);
      } else {
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);
        this.subser3 = false;
        this.agendaCitas = this.ConsultConIndDate(nameser, datepro);
        this.agendaServicios = this.ConsultServIndDate(
          nameser.toLocaleUpperCase(),
          datepro
        );
        this.SelecServicios(nameser);
        this.ViewServicios("", "background", this.agendaServicios);
        this.ViewCitas(this.agendaCitas);
        return this.spinner.hide();
      }
    }
    if (nameser.length != 0) {
      this.fecsemEnt = "00:10:00";
      this.fecsemSal = "23:59:00";
      this.events = [];
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios
      this.subser2 = true;
      this.Subservice2 = this.ConsultName(nameser);

      this.Subservice2.splice(0, 1);
      if (nameser === "5fd3ebca08ccbb0017712f0d") {
        this.subser3 = false;
        this.SelecServicios(nameser);
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);
        return this.ConsultasGeneral(nameser, "", "");
      }
      if (nameser === "Consulta Especialista") {
        this.subser3 = true;
        this.Subservice2 = this.ConsultName(nameser);
        this.Subservice2.splice(0, 1);
        this.SelecServicios(namesubser);
        this.validarColor(cambiocolor, namesubser, "");
        return this.ConsultasGeneral(namesubser, "", "");
      } else {
        this.CleanSubSer();
        this.validarColor(cambiocolor, "", nameser);
        this.subser3 = false;
        this.subser2 = true;
        this.agendaCitas = this.ConsultServInd(nameser);
        this.SelecServicios(nameser);
        this.agendaServicios = this.ConsultNameService(
          nameser.toLocaleUpperCase()
        );
        this.SelecServicios(nameser);
        this.ViewServicios("sede", "block", this.agendaServicios);

        this.ViewCitas(this.agendaCitas);
        return this.spinner.hide();
      }
    }
    if (datepro.length != 0) {
      this.fecsemEnt = "00:10:00";
      this.fecsemSal = "23:59:00";
      this.events = [];
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios
      this.subser3 = false;
      this.calendarComponent.getApi().changeView("timeGridDay", datepro);

      this.CleanSubSer();
      this.agendaServicios = this.ConsultDate(datepro);
      this.agendaCitas = this.ConsultCitaDate(datepro);
      this.ViewServicios("sede", "background", this.agendaServicios);
      this.ViewCitas(this.agendaCitas);
      return this.spinner.hide();
    }
    if (namesede.length != 0) {
      this.calendarComponent.getApi().changeView("dayGridMonth"); /// 1401 w

      this.events = [];
      this.agendaCitas = []; //limpiar los arrays de las citas
      this.agendaServicios = []; //limpiar los arrays de los servicios
      this.subser3 = false;
      this.CleanSubSer();
      this.agendaServicios = this.ConsultNameSede(namesede);
      this.agendaCitas = this.ConsultCitasSede(namesede);
      this.ViewServicios("", "block", this.agendaServicios);

      this.ViewCitas(this.agendaCitas);
      if (this.agendaServicios.length === 0) {
        Swal.fire({
          icon: "error",
          title: "",
          text: "NO HAY SERVICIO EN LA SEDE: " + namesede,
        });
      }
      return this.spinner.hide();
    }
    if (datepro.length == 0 && nameser.length == 0 && namesede.length == 0) {
      //  this.fecsemEnt = '00:10:00'
      //  this.fecsemSal = '23:59:00'
      this.GetAgenda("");
      this.GetService();
      this.GetServiceAgenda("");
      this.subser3 = false;
      this.CleanSubSer();
      this.limpiarnombres();
      return this.spinner.hide();
    }
  }
  CleanSubSer() {
    this.addEventForm3.patchValue({
      Subserv: [],
    });
  }
  validarColor(tipo: any, namesubser: any, nameser: any) {
    if (namesubser.length === 0) {
      this.changeser(tipo, nameser);
    } else if (namesubser.length != 0) {
      this.changeser(tipo, namesubser);
    }
  }

  SelecServicios(nameser: any) {
    this.addEventForm2.patchValue({
      Servicios: nameser,
    });

    this.addEventForm4.patchValue({
      servicioser: nameser,
    });
  }
  ObtenerFecha() {
    var meses = new Array(
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    );
    // var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
    var f = new Date();
    return f.getDate() + " de " + meses[f.getMonth()] + " " + f.getFullYear();
    /**diasSemana[f.getDay()] + */
  }
  ConsultasGeneral(idser: any, sede: any, date: any) {
    var idser = idser;
    var sede = sede;
    var date = date;
    //console.log(idser)
    //console.log(sede)
    //console.log(date)

    this.agendaCitas = [];
    this.agendaServicios = [];

    this.addEventForm4.patchValue({
      servicioser: idser,
    });
    this.addEventForm2.patchValue({
      Servicios: idser,
    });
    this.selectName();
    this.ServicosExtra.patchValue({
      Servicios: idser,
    });
    // console.log(this.nameser) // 1401 aqui me qudé con las consultas individuales por selects

    if (this.nameser != "CONSULTA DE MEDICINA GENERAL" && idser != "") {
      //console.log("consulta 1401") // 1401 aqui me qudé con las consultas individuales por selects1
      this.agendaServicios = this.VefificadorTipoConsultas(
        idser.toLocaleUpperCase(),
        sede,
        date
      );
      //console.log(this.agendaServicios)
    }
    if (this.nameser === "CONSULTA DE MEDICINA GENERAL") {
      this.agendaServicios = this.ConsultNameServiceSede(
        idser.toLocaleUpperCase(),
        sede
      );
      //console.log(this.agendaServicios)
    }

    this.ViewServicios("", "background", this.agendaServicios);

    this.cargarcalendario();

    if (idser != "" && sede != "" && date != "") {
      this.agendaCitas = this.ConsultIdSedeDate(idser, sede, date);
      //console.log(agenServi3)
      this.ViewCitas(this.agendaCitas);
      return this.spinner.hide();
    }
    if (idser != "" && date != "") {
      this.agendaCitas = this.ConsultIdDate(idser, date);
      this.ViewCitas(this.agendaCitas);
      return this.spinner.hide();
    }
    if (idser != "" && sede != "") {
      //console.log(idser) // 1401 aqui me qudé con las consultas individuales por selects1
      //console.log("1401") // 1401 aqui me qudé con las consultas individuales por selects1

      this.agendaCitas = this.ConsultIdSede(idser, sede);
      //console.log(this.agendaCitas)
      this.ViewCitas(this.agendaCitas);
      return this.spinner.hide();
    }
    if (idser != "") {
      this.agendaCitas = this.ConsultId(idser);
      this.ViewCitas(this.agendaCitas);
      return this.spinner.hide();
    }
  }

  VefificadorTipoConsultas(idser: any, sede: any, date: any) {
    if (idser != "" && sede != "" && date != "") {
      return this.ConsultServIndDateSede(idser, date, sede);
    } else if (idser != "" && sede != "") {
      //console.log("idser")

      //console.log(idser)
      return this.ConsultNameServiceSede(idser, sede);
    } else if (idser != "") {
      return this.ConsultNameService(idser);
    }
  }

  limpiarnombres() {
    this.datepro = "";
    this.nameser = "";
    this.namesede = "";
  }
  ChangeName() {
    if (this.nameser === "5fd3ebca08ccbb0017712f0d") {
      this.nameser = "CONSULTA DE MEDICINA GENERAL";
    } else if (this.nameser === "Consulta Especialista") {
      //console.log("CUAL CONSULTA")
      this.servi.forEach((element) => {
        if (element.name == this.nameser) {
          //console.log(this.namesubser);
          element.names.filter((element1) => {
            if (element1._id == this.namesubser) {
              //console.log(element1)
              this.nameser = element1.ESTUDIO;
            }
          });
        }
      });
    }
    if (this.namesede === "TLYC01") {
      this.namesede = "CENTRO MEDICO HORIZONTE TLAYACAPAN";
      this.fecsemEnt = "07:00:00";
      this.fecsemSal = "22:00:00";
    }
    if (this.namesede === "CTLA01") {
      this.namesede = "UNIDAD MEDICA CUAUTLA";
      this.fecsemEnt = "08:00:00";
      this.fecsemSal = "16:00:00";
    }
  }
  ReturnSedeid() {
    this.users = JSON.parse(localStorage.getItem("usuario"));
    return this.users.sede;
  }
  SelectSedeDefault() {
    this.namesede = this.ReturnSedeid();
    //console.log(this.namesede)
    this.addEventForm3.patchValue({
      consultasede: this.namesede,
    });
    this.ChangeName();
  }

  OutDate(a: string, b: boolean) {
    if (a != null && b != null) {
      let aa = a.toString();
      let bb = b.toString();
      let timefinal = aa + "T" + bb + ":00";

      return timefinal;
    }
  }

  OutDate2(c: string, d: boolean) {
    if (c != null && d != null) {
      let cc = c.toString();
      let dd = d.toString();

      let timefinal2 = cc + "T" + dd + ":00";

      return timefinal2;
    }
  }
  ConsultServiciosDoctores() {
    return this.service
      .filter((servicio) => {
        return servicio;
      })
      .sort();
  }
  ConsultServiciosDavid() {
    return this.service
      .filter((servicio) => {
        if (
          servicio.name == "endoscopia" ||
          servicio._id == "5fd3ebca08ccbb0017712f0d" ||
          servicio._id == "5fdd2a14a71eca0017a8e92d" ||
          servicio._id == "5fdd2aa8a71eca0017a8e931" ||
          servicio._id == "5fdd2aa8a71eca0017a8e93"
        ) {
          return servicio;
        }
      })
      .sort();
  }
  // solo consulta de los sub servicios por nombres EJEM ENDOSCOPIA: SDASDASDASD
  ConsultName(name: any) {
    return this.service.filter((servicio) => {
      return servicio.name == name;
    });
  }
  ConsultEstudio() {
    //console.log(this.service);
    return this.service.filter(
      (servicio) => servicio.name == "Consulta Especialista"
    );
  }
  ConsultNameService(name: any) {
    let service = [];
    return (service = this.agenServi.filter(
      (servicio) => servicio.Servicio == name
    ));
  }

  ConsultNameServiceSede(name: any, sede: any) {
    sede;
    name;
    let service = [];
    //console.log(name)
    //console.log(sede)
    //console.log(this.agenServi)
    service = this.agenServi.filter(
      (servicio) => servicio.Servicio == name && servicio.sede == sede
    );
    //console.log(service)
    //this.spinner.hide();
    return service;
  }

  ConsultId(id: any) {
    //console.log(this.agenda2)
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio! &&
          servicio.idServicio._id == id &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultIdSede(id: any, sede: any) {
    //console.log(id)
    //console.log(sede)
    //console.log(this.agenda);

    id;
    sede;
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio! &&
          servicio.idServicio._id == id &&
          servicio.sede == sede &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultIdDate(id: any, date: any) {
    //console.log(this.agenda)
    id;
    date;
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio! &&
          servicio.idServicio._id == id &&
          servicio.fechaInicio == date &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }

  ConsultIdSedeDate(id: any, sede: any, date: any) {
    //console.log(this.agenda)
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio! &&
          servicio.idServicio._id == id &&
          servicio.sede == sede &&
          servicio.fechaInicio == date &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultValideDateAndHoursSedeArray(
    servicios: any,
    sede: any,
    date: any,
    horaInicio: any
  ) {
    //console.log(this.servicioExtArr)
    return this.servicioExtArr.value.filter(
      (servicio) =>
        servicio.filsirver == servicios &&
        servicio.sedecon == sede &&
        servicio.FechaEnt == date &&
        servicio.HoraEnt == horaInicio
    );
  }
  ConsultValideDateAndHoursSede(
    name: any,
    sede: any,
    date: any,
    horaInicio: any
  ) {
    //console.log(name);
    //console.log(sede);
    //console.log(date);
    //console.log(horaInicio);

    //console.log(this.agenda)
    return this.agenda.filter((servicio) => {
      if (servicio.idServicio.name == name && servicio.fechaInicio == date) {
        if (servicio.sede == sede && servicio.horaInicio == horaInicio) {
          if (servicio.status == "PENDIENTE" || servicio.status == "PAGADO") {
            return servicio;
          }
        }
      }
    });
  }
  ConsultNameSede(name: any) {
    return this.agenServi.filter((servicio) => servicio.sede == name);
  }
  ConsultCitasSede(name: any) {
    //console.log(name)
    return this.agenda.filter(
      (servicio) =>
        (servicio.sede == name && servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultServInd(name: any) {
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio.name == name && servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ServInd(id: any) {
    //console.log(id);
    return this.service.filter((servicio) => servicio._id == id);
  }

  ConsultGeneralInd(name: any) {
    let service = [];
    return (service = this.agenda.filter(
      (servicio) =>
        (servicio.idServicio.ESTUDIO == name &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    ));
  }

  ConsultConDateSede(date: any, sede: any) {
    return this.agenda.filter(
      (servicio) =>
        (servicio.fechaInicio == date &&
          servicio.sede == sede &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultDateSede(name: any, sede: any) {
    return this.agenServi.filter(
      (servicio) => servicio.fechaInicio == name && servicio.sede == sede
    );
  }

  ConsultConServSede(name: any, sede: any) {
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio.name == name &&
          servicio.sede == sede &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultServSede(name: any, sede: any) {
    return this.agenServi.filter(
      (servicio) => servicio.Servicio == name && servicio.sede == sede
    );
  }
  ConsultConIndDate(name: any, date: any) {
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio.name == name &&
          servicio.fechaInicio == date &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultConIndDateSede(name: any, date: any, sede: any) {
    //console.log(this.agenda)
    return this.agenda.filter(
      (servicio) =>
        (servicio.idServicio.name == name &&
          servicio.fechaInicio == date &&
          servicio.sede == sede &&
          servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }

  ConsultServIndDate(name: any, date: any) {
    return this.agenServi.filter(
      (servicio) => servicio.Servicio == name && servicio.fechaInicio == date
    );
  }
  ConsultServIndDateSede(name: any, date: any, sede: any) {
    return this.agenServi.filter(
      (servicio) =>
        servicio.Servicio == name &&
        servicio.fechaInicio == date &&
        servicio.sede == sede
    );
  }
  /// BUSCADOR DE LOS SERVICIOS POR FECHA
  ConsultDate(date: any) {
    let dates = [];
    return (dates = this.agenServi.filter(
      (servicio) => servicio.fechaInicio == date
    ));
  }
  // BUSCAR CITAS POR FECHAS
  ConsultCitaDate(date: any) {
    return this.agenda.filter(
      (servicio) =>
        (servicio.fechaInicio == date && servicio.status == "PENDIENTE") ||
        servicio.status == "PAGADO"
    );
  }
  ConsultForDoctor(id: any) {
    return this.agenda.filter((servicio) => {
      if (servicio.idPersonal!) {
        if (
          (servicio.idPersonal.NOMBRE == id &&
            servicio.status == "PENDIENTE") ||
          servicio.status == "PAGADO"
        ) {
          return servicio;
        }
      }
    });
  }
  ConsultServiceForDoctor(id: any) {
    console.log(id);

    return this.agenServi.filter((servicio) => {
      if (servicio.idPersonal!) {
        if (servicio.idPersonal.NOMBRE == id) {
          console.log(servicio);

          return servicio;
        }
      }
    });
  }
  hoursDefault() {
    this.InterHour = "00:30:00";
    this.InterHours = 30;
  }
  changeser(addevent: any, servicio: any) {
    var nomser = servicio;

    if (nomser === "endoscopia") {
      const colores = "#52BE80";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "laboratorio") {
      this.InterHour = "00:10:00";
      this.InterHours = 10;

      const colores = "#DC0035";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "tomografia") {
      this.hoursDefault();
      const colores = "#DC3500";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "patologia") {
      this.hoursDefault();
      const colores = "#DCAB00";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "xray") {
      this.hoursDefault();
      const colores = "#F4D03F";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "ultrasonido") {
      const colores = "#5DADE2";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "servicios") {
      const colores = "#DC00DC";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "otros") {
      const colores = "#04A4DB";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "Consulta Especialista") {
      const colores = "#1400DC";
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fd3ebca08ccbb0017712f0d") {
      /// CONSULTAS GENERALES
      const colores = "#E67E22";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2a7fa71eca0017a8e930") {
      /// CONSULTAS NUTRICION
      const colores = "#FF90E6";
      this.InterHour = "00:40:00";
      this.InterHours = 40;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2a14a71eca0017a8e92d") {
      /// CONSULTAS CIRUGIA  GENERAL
      const colores = "#9600CA";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2aa8a71eca0017a8e931") {
      /// CONSULTAS GASTROENTEROLOGIA
      const colores = "#154360";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2a46a71eca0017a8e92e") {
      /// CONSULTAS UROLOGIA
      const colores = "#9600CA";
      this.InterHour = "00:20:00";
      this.InterHours = 20;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2a65a71eca0017a8e92f") {
      /// CONSULTAS TRAUMATOLOGIA
      const colores = "#E3DAC9";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd29e5a71eca0017a8e92c") {
      /// CONSULTAS MEDICINA INTERNA
      const colores = "#979A9A";
      this.InterHour = "00:40:00";
      this.InterHours = 40;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2b23a71eca0017a8e935") {
      /// CONSULTAS CIRUGIA PLASTICA
      const colores = "#9600CA";
      this.InterHour = "00:40:00";
      this.InterHours = 40;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2ac5a71eca0017a8e932") {
      /// CONSULTAS HEMATOLOGIA
      const colores = "#9600CA";
      this.InterHour = "03:00:00";
      this.InterHours = 180;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2ae1a71eca0017a8e933") {
      /// CONSULTAS ONCOLOGIA
      const colores = "#9600CA";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2b08a71eca0017a8e934") {
      /// CONSULTAS NEFROLOGIA
      const colores = "#9600CA";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2b83a71eca0017a8e938") {
      /// CONSULTAS OTORINOLARINGOLO
      const colores = "#9600CA";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2b6ba71eca0017a8e937") {
      /// CONSULTAS HEMATOLOGIA PEDIATRIA
      const colores = "#9600CA";
      this.hoursDefault();
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2b41a71eca0017a8e936") {
      /// CONSULTAS GINECOLOGIA
      const colores = "#884EA0";
      this.InterHour = "00:40:00";
      this.InterHours = 40;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "5fdd2bfba71eca0017a8e939") {
      /// CONSULTAS PEDIATRIA
      const colores = "#FFD425";
      this.InterHour = "00:40:00";
      this.InterHours = 40;
      return this.Selectcolores(addevent, colores);
    } else if (nomser === "undefined") {
      return Swal.fire({
        icon: "error",
        title: "",
        text: "NO HAY SERVICIO" + nomser,
      });
    }
  }
  Selectcolores(addevent: any, colores: any) {
    //console.log(addevent)
    if (addevent == "agendarcita") {
      //console.log('ya entró en citas')
      this.Cambiarcolortexto(colores);
      //console.log(colores)

      return this.addEventForm2.patchValue({
        color: colores,
      });
    }
    if (addevent == "agendarservicio") {
      //1401
      //console.log('ya entró en servicios')
      this.Cambiarcolortexto(colores);
      //console.log(colores)
      return this.addEventForm4.patchValue({
        colorser: colores,
      });
    }
  }

  generadorHorario(horaApertura, horaCierre) {
    let a = this.formateaMomento(horaApertura);
    let c = this.formateaMomento(horaCierre);

    //console.log('horario: de', a, 'a', c);

    return function (hora) {
      const h = this.formateaMomento(hora);

      //abierto a las 00:00
      if (a > c) {
        //console.log(h)

        return h >= a || h <= c;
      }
      //console.log(h)
      return h >= a && h <= c;
    };
  }

  formateaMomento(momento) {
    const regexp = /\d\d:\d\d(:\d\d)?/;
    if (regexp.test(momento)) {
      const units = momento.split(":");
      return +units[0] * 3600 + +units[1] * 60 + (+units[2] || 0);
    }
    return null;
  }
  handleDateSelect(selectInfo: DateSelectArg) {
    this.Registro = false;
    var formattedhours = moment(selectInfo.start, "HH:mm:ss").format("HH:mm");
    var formattedhours2 = moment(selectInfo.end, "HH:mm:ss").format("HH:mm");
    var formatteddate = moment(selectInfo.startStr).format("YYYY-MM-DD");
    var formatteddate2 = moment(selectInfo.endStr).format("YYYY-MM-DD");

    let newdate1 = this.formateaMomento(formattedhours);
    let newdate2 = this.formateaMomento(formattedhours2);

    this.agendaServicios.filter((element) => {
      let a = this.formateaMomento(element.horaInicio);
      let c = this.formateaMomento(element.horaFin);
      if (formatteddate == element.fechaInicio) {
        //console.log(element.length)

        if (newdate1 >= a || newdate2 <= c) {
          //console.log(element.length)
          if (newdate1 >= a && newdate2 <= c) {
            //console.log(element)
            //console.log(element.length)

            this.addEventForm2.patchValue({ Doctor: element.idPersonal._id });
            this.nomDoctor = element.idPersonal.NOMBRE;

            $("#nomDoctor").find(
              'option:contains("' + element.idPersonal.NOMBRE + '")'
            );

          }
        }
      }
    });

    let Addservic = this.addEventForm3.controls["addservice"].value;

    if (Addservic !== false) {
      ////console.log(this.Addservic)
      this.modal.open(this.modalContent, { size: <any>"gl" });

      this.addEventForm4.patchValue({
        horainicioser: formattedhours,
        horafinser: formattedhours2,
        fechainicioser: formatteddate,

        fechafinser: formatteddate2,
      });
    } else {
      let sede = this.addEventForm3.controls["consultasede"].value;
      var consultserv = this.addEventForm3.controls["consultaserv"].value;
      this.addEventForm2.patchValue({
        sedecon: sede,
      });
      //console.log(consultserv)
      if (consultserv == "") {
        Swal.fire({
          icon: "error",
          title: "",
          text: "tienes que Seleccionar un servicio antes de agregar ",
        });
      }
      if (sede == "") {
        Swal.fire({
          icon: "error",
          title: "",
          text: "tienes que Seleccionar una sede antes de agregar",
        });
      }
      if (consultserv == "" && sede == "") {
        Swal.fire({
          icon: "error",
          title: "",
          text: "tienes que Seleccionar un servicio y sede antes de agregar",
        });
      }
      if (consultserv != "" && sede != "") {
        this.modal.open(this.modalContent, {
          size: <any>"gl",
          windowClass: "ModalAgen",
        });
      }
      //console.log("1401 -------------------------------------------");

      //console.log(this.addEventForm2.controls['Paciente'].value);
      this.CambiarActivosInput();

      this.addEventForm2.patchValue({
        FechaEnt: formatteddate,
        FechaSal: formatteddate2,
        HoraEnt: formattedhours,
        HoraSal: formattedhours2,
      });
      if (this.DatesPaySelect == true) {
        this.addEventForm2.patchValue({
          addservice2: true,
        });
        this.ocultAddser();
      }
      if (this.DatesPaySelectUpdate == true) {
        this.addEventForm2.patchValue({
          addservice2: true,
        });
        this.ocultAddser();
      }
    }
  }
  CambiarActivosInput() {
    if (this.addEventForm2.controls["Paciente"].value.length == 0) {
      this.addEventForm2.controls["Doctor"].disable();
      this.addEventForm2.controls["Motivo"].disable();
      this.addEventForm2.controls["Servicios"].disable();
      this.addEventForm2.controls["Descripcion"].disable();
      this.addEventForm2.controls["Reservacion"].disable();
    } else {
      this.addEventForm2.controls["Doctor"].enable();
      this.addEventForm2.controls["Motivo"].enable();
      this.addEventForm2.controls["Servicios"].enable();
      this.addEventForm2.controls["Descripcion"].enable();
      this.addEventForm2.controls["Reservacion"].enable();
    }
  }
  getRange = (start: Date, end: Date) => {
    let startDateInteger = start.getHours();
    let endDateInteger = end.getHours();
    let hours = [];
    for (let i = startDateInteger; i < endDateInteger + 1; i++) {
      let tempDate = new Date(start);
      tempDate.setHours(i);
      hours.push(tempDate);
    }
    //console.log(hours)
    return hours;
  };

  OpenModals() {
    let ser = this.addEventForm3.controls["consultaserv"].value;
    let sede = this.addEventForm3.controls["consultasede"].value;
    //console.log(ser)
    if (this.addEventForm3.controls["addservice"].value === true) {
      if (ser.length == 0 && sede.length == 0) {
        //console.log("aqui entra")
        this.addEventForm3.patchValue({ addservice: false });

        return Swal.fire({
          icon: "error",
          title: "",
          text: "SELECCIONE SERVICIO & SEDE",
        });
      } else if (ser.length == 0) {
        this.addEventForm3.patchValue({ addservice: false });

        return Swal.fire({
          icon: "error",
          title: "",
          text: "SELECCIONE SERVICIO",
        });
      } else if (sede.length == 0) {
        this.addEventForm3.patchValue({ addservice: false });

        return Swal.fire({
          icon: "error",
          title: "",
          text: "SELECCIONE SEDE",
        });
      } else if (ser.length != 0 && sede.length != 0) {
        this.addEventForm3.patchValue({ addservice: true });

        return this.modal.open(this.modalContent, { size: <any>"gl" });
      }
    } else if (this.addEventForm3.controls["addservice"].value == false) {
      this.addEventForm3.patchValue({ addservice: false });

      this.addEventForm4.patchValue({
        fechainicioser: [],
        fechafinser: [],
        horainicioser: [],
        horafinser: [],
      });
    }
  }

  onSubmitSer() {
    let array: any[] = [];
    var tipo = "servicios";
    this.IdUserUse(tipo);
    let sede = this.addEventForm3.controls["consultasede"].value;
    let consultaserv = this.addEventForm3.controls["consultaserv"].value;
    let Subserv = this.addEventForm3.controls["Subserv"].value;
    let agendarservicio = "agendarservicio";
    //console.log(consultaserv)
    //console.log(Subserv)
    //console.log(this.addEventForm3.controls['consultaserv'].value)

    //console.log(this.addEventForm3.controls['Subserv'].value)
    //console.log("VLIENDO VERGAAAAAAAAAAAAAAAAAAA")
    //console.log(consultaserv.length)
    //console.log(Subserv.length)

    if (consultaserv.length != 0 && Subserv.length != 0) {
      //console.log("entro aqui 1")
      //console.log(Subserv)
      //console.log(this.nameser)

      this.validarColor(agendarservicio, "", Subserv);
      this.addEventForm4.patchValue({
        servicioser: Subserv.toLocaleUpperCase(),
        tituloser: this.nameser,
        sedeser: sede,
      });
    } else if (consultaserv.length != 0 && Subserv.length == 0) {
      //console.log("entro aqui 2")
      //console.log(Subserv)
      //console.log(consultaserv)

      this.validarColor(agendarservicio, "", consultaserv);
      this.addEventForm4.patchValue({
        servicioser: consultaserv,
        tituloser: consultaserv,
        sedeser: sede,
      });
    }

    if (
      this.addEventForm4.controls["fechainicioser"].value.length != 0 &&
      this.addEventForm4.controls["fechafinser"].value.length != 0
    ) {
      if (this.addEventForm4.invalid) {
        return Swal.fire({
          icon: "error",
          title: "",
          text: "TERMINA LOS CAMPOS",
        });
      }

      if (this.addEventForm4.valid) {
        //this.spinner.show();

        this.addEventForm4.patchValue({ tituloser: this.nameser });

        //console.log(this.addEventForm4.value)
        this._AgendaService
          .agregarServicio(this.addEventForm4.value)
          .subscribe((resp: any) => {
            resp.data.forEach((element) => {
              this.agendaServicios.push(element);
              this.agenServi.push(element);
            });
            this.ViewServicios("", "background", this.agendaServicios);
            this.cargarcalendario();

            if (resp.ok) {
              Swal.fire({
                icon: "success",
                title: "",
                text: "SERVICIO AGREGADO CORRECTAMENTE",
              });
              this.addEventForm2.patchValue({ addservice2: false });
            }
          });
        this.hideModal();

        return this.spinner.hide();
      }
    } else {
      let result = this.SelectDays();
      //console.log(result)

      result.forEach((element) => {
        //console.log(element)

        let evento = {
          agendoser: this.addEventForm4.controls["agendoser"].value,
          colorser: this.addEventForm4.controls["colorser"].value,
          tituloser: this.addEventForm4.controls["tituloser"].value,
          servicioser: this.addEventForm4.controls["servicioser"].value,
          horainicioser: this.addEventForm4.controls["horainicioser"].value,
          horafinser: this.addEventForm4.controls["horafinser"].value,
          Doctor: this.addEventForm4.controls["Doctor"].value,
          fechainicioser: element,
          fechafinser: element,
          sedeser: this.addEventForm4.controls["sedeser"].value,
        };
        //console.log(this.agendaServicios)
        //console.log(this.agenServi)
        this._AgendaService.agregarServicio(evento).subscribe((resp: any) => {
          resp.data.forEach((element) => {
            this.agendaServicios.push(element);
            this.agenServi.push(element);
          });
          this.ViewServicios("", "background", this.agendaServicios);
          this.cargarcalendario();

          // this.spinner.hide()
          if (resp.ok) {
            Swal.fire({
              icon: "success",
              title: "",
              text: "SERVICIOS AGREGADOS CORRECTAMENTE",
            });
          }
        });
        // this.spinner.hide();
      });
      this.addEventForm2.patchValue({ addservice2: false });
      this.hideModal();
      return this.spinner.hide();
    }
  }
  IdUserUse(tipo: any) {
    this.users = JSON.parse(localStorage.getItem("usuario"));
    //console.log(this.users)
    if (tipo === "servicios") {
      this.addEventForm4.patchValue({
        agendoser: this.users._id,
      });
    }
  }
  updateForm() {
    this.AddInput();
    //console.log(this.servicioExtArr.value)
    if (this.servicioExtArr.length != 0) {
      this.servicioExtArr.value.forEach((body) => {
        //console.log(body.id);
        if (body.id!) {
          this.FormularioUpdate(body);
        } else {
          this.FormularioGuardar(body);
        }
      });
    } else {
      this._AgendaService
        .UpdateAgendar(
          this.addEventForm2.controls["id"].value,
          this.addEventForm2.value
        )
        .subscribe((resp: any) => {
          this.QuitarCitaArray(
            this.addEventForm2.controls["Paciente"].value,
            this.addEventForm2.controls["Servicios"].value
          );
          this.agenda.forEach((servicio, index) => {
            if (this.addEventForm2.controls["id"].value == servicio._id) {
              //console.log("servicio 1")
              //console.log(servicio)
              this.agenda.splice(index, 1);
            }
          });
          this.agendaCitas.forEach((servicio, index) => {
            if (this.addEventForm2.controls["id"].value == servicio._id) {
              //console.log("servicio 1")
              //console.log(servicio)
              this.agendaCitas.splice(index, 1);
            }
          });
          resp.data.forEach((element) => {
            this.agendaCitas.push(element);
            this.agenda.push(element);
          });

          return this.respGuardar(resp);
        });
    }
  }
  respGuardar(resp: any) {
    this.ViewCitas(this.agendaCitas);
    this.hideModal();

    if (resp.ok) {
      Swal.fire({
        icon: "success",
        title: "",
        text: "CITA AGREGADA CORRECTAMENTE",
      });
      this.servicioExtArr.reset();
      //this.addEventForm.reset()
      this.Resetform();
      this.addEventForm.reset();
      this.addEventForm.patchValue({ User: false, NewUser: false });
      this.servicioExtArr.clear();
    }
    return this.spinner.hide();
  }
  onSubmit() {
    this.AddInput();
    //console.log(this.servicioExtArr.length );
    //console.log();

    if (
      this.addEventForm.controls["NuevoUser"].value === true &&
      this.servicioExtArr.length === 0
    ) {
      //console.log("ENTRA EN PACIENTE NUEVO PERO SOLO UNA CITA")
      return this.SavePacForm("save");
    }
    if (
      this.servicioExtArr.length != 0 &&
      this.addEventForm.controls["NuevoUser"].value === false
    ) {
      //console.log("ENTRA SIN PACIENTE NUEVO PERO CON ARRAYS DE CITAS")
      return this.SaveDates();
    }
    if (
      this.servicioExtArr.length != 0 &&
      this.addEventForm.controls["NuevoUser"].value === true
    ) {
      //console.log("ENTRA CON PACIENTE NUEVO PERO CON ARRAYS DE CITAS")
      return this.SavePacForm("saves");
    }
    if (
      this.servicioExtArr.length == 0 &&
      this.addEventForm.controls["NuevoUser"].value == false
    ) {
      //console.log("ENTRA SOLO CON CITAS")
      return this.Saveform();
    }
  }
  SavePacForm(save: any) {
    //console.log(save);
    //console.log(this.addEventForm);
    //console.log(this.addEventForm3.controls['consultasede'].value);

    -this._pacienteService
      .setPacientes(
        this.addEventForm.value,
        this.addEventForm3.controls["consultasede"].value
      )
      .subscribe((resp) => {
        console.log(resp["data"]);
        //console.log(this.PACIENTES_SERVICES)
        this.PACIENTES_SERVICES.push(resp["data"]);
        //console.log(this.addEventForm2.value)
        this.addEventForm2.patchValue({ Paciente: resp["data"]._id });
        this.AddInputPaciente();
        //console.log("se agregó")
        //console.log(this.addEventForm2.value)
        if (resp["ok"]) {
          Swal.fire({
            icon: "success",
            title: "",
            text: "DISPONENTE REGISTRADO",
          });
          if (save === "save") {
            //console.log("save")
            return this.Saveform();
          } else if (save === "saves") {
            //console.log("saves")
            return this.SaveDates();
          }
        }
      });
  }
  Saveform() {
    if (this.addEventForm2.valid) {
      //console.log(this.addEventForm2.value)
      this._AgendaService
        .agregarCita(this.addEventForm2.value)
        .subscribe((resp: any) => {
          console.log(resp["data"]);
          let pers_obj: any = {};
          resp.data.forEach((element) => {
            this.agendaCitas.push(element);
            this.agenda.push(element);
            pers_obj = element.idPaciente;
          });
          if (localStorage.getItem("ServicioAgenda") != null) {
            this.Servicepac.forEach((element1) => {
              //console.log(element1)
            });
          }
          this.QuitarCitaArray(
            this.addEventForm2.controls["Paciente"].value,
            this.addEventForm2.controls["Servicios"].value
          );
          this.ViewCitas(this.agendaCitas);
          this.spinner.hide();
          if (resp.ok) {
            //console.log(resp);
            //console.log("DEBE DE ELIMINAR 11111111111");
            //console.log(this.Servicepac);

            this.DeleteLocalDates();
            if (!pers_obj.curp || pers_obj.curp == null) {
              Swal.fire({
                title: "CITA AGREGADA CORRECTAMENTE",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: `TERMINAR REGISTRO`,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.registro(pers_obj._id);
                }
              });
            } else {
              Swal.fire({
                title: "CITA AGREGADA CORRECTAMENTE",
                icon: "success",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: `PAGAR AHORA`,
                denyButtonText: `PAGAR TODO`,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.PaytoService(pers_obj, "Hoy");
                } else if (result.isDenied) {
                  this.PaytoService(pers_obj, "Todo");
                }
              });
            }

            this.servicioExtArr.reset();
            this.Resetform();
            this.addEventForm.reset();
            this.addEventForm.patchValue({ User: false, NewUser: false });
            return this.servicioExtArr.clear();
          }
        });
      this.hideModal();
    } else {
      Swal.fire({
        icon: "error",
        title: "COMPLETA LOS CAMPOS",
        text: "Antes de enviar revisa los campos",
      });
    }
  }

  DeleteLocalDates() {
    if (localStorage.getItem("ServicioAgenda") != null) {
      if (this.Servicepac != 0) {
        //console.log(this.DatesPac.agen)
        //console.log(this.DatesPac.servicios)
        //console.log(this.Servicepac)
        let RestLocal: any = {};
        let array: any = [];
        this.Servicepac.forEach((element2) => {
          element2.servicios.forEach((element) => {
            //console.log(element)
            array.push(element);
          });
          RestLocal = {
            agen: this.DatesPac.agen,
            paciente: this.DatesPac.paciente,
            servicios: array,
          };
          //console.log(array)
          //console.log(RestLocal)
          localStorage.setItem("ServicioAgenda", JSON.stringify(RestLocal));
          //console.log(JSON.parse(localStorage.getItem("ServicioAgenda")).servicios.length)

          if (
            JSON.parse(localStorage.getItem("ServicioAgenda")).servicios
              .length == 0
          ) {
            //console.log(JSON.parse(localStorage.getItem("ServicioAgenda")).servicios.length)

            localStorage.removeItem("ServicioAgenda");
            this.GetAgendaCons();
          } else {
            return;
          }
        });
      }
    }
  }
  SaveDates() {
    this.servicioExtArr.value.forEach((body) => {
      this.FormularioGuardar(body);
    });
  }
  FormularioGuardar(body: any) {
    this._AgendaService.agregarCita(body).subscribe((resp: any) => {
      let datas = JSON.parse(JSON.stringify(resp.data));
      this.eventos = datas;
      console.log(resp["data"]);

      resp.data.forEach((element) => {
        //console.log(element.idServicio._id)
        //console.log(element)
        this.agendaCitas.push(element);
        this.agenda.push(element);
      });

      this.DeleteLocalDates();
      //console.log(this.agenda)
      this.ViewCitas(this.eventos);

      this.hideModal();
      if (resp.ok) {
        Swal.fire({
          icon: "success",
          title: "",
          text: "CITAS REGISTRADAS CORRECTAMENTE",
        });
        this.servicioExtArr.reset();
        this.servicioExtArr.clear();
        this.addEventForm.reset();
        this.addEventForm.patchValue({ User: false, NewUser: false });
        this.Resetform();
        this.addEventForm4.reset();
        return this.spinner.hide();
      }
    });
  }
  FormularioUpdate(body: any) {
    this._AgendaService.UpdateAgendar(body.id, body).subscribe((resp: any) => {
      this.agenda.forEach((servicio, index) => {
        if (body.id == servicio._id) {
          //console.log("servicio 1")
          //console.log(servicio)
          this.agenda.splice(index, 1);
        }
      });
      this.agendaCitas.forEach((servicio, index) => {
        if (body.id == servicio._id) {
          //console.log("servicio 1")
          //console.log(servicio)
          this.agendaCitas.splice(index, 1);
        }
      });
      resp.data.forEach((element) => {
        this.agendaCitas.push(element);
        this.agenda.push(element);
      });

      //console.log(this.agendaCitas)
      //console.log("this.agendaCitas 1")
      this.ViewCitas(this.agendaCitas);
      this.hideModal();

      return this.respGuardar(resp);
    });
  }
  Resetform() {
    this.addEventForm2.patchValue({
      Paciente: [],
      Doctor: [],
      Subservicios: [],
      FechaEnt: [],
      FechaSal: [],
      HoraEnt: [],
      HoraSal: [],
      Motivo: [],
      Descripcion: [],
      Reservacion: [],
      addservice2: false,
      addservice3: false,
    });
  }
  hideModal() {
    this.modal.dismissAll();
  }
  hideForm() {
    $("#myModal").modal("hide");
    $("#myModalEdit").modal("hide");
  }

  obtenerPacientes() {
    this._pacienteService.getPacientesAll().subscribe((data: any) => {
      if (data.ok) {
        this.PACIENTES_SERVICES = data["data"];
        this.PACIENTES_SERVICES.forEach((element) => {
          for (element = 0; element < 10; element++) {
            return element;
          }
        });
      }
    });
  }
  SelectPac(item) {
    //console.log(item)
    this.cita.paciente._id = item["_id"];
    //console.log(this.cita.paciente._id)
    this.cita.paciente.genero = item["genero"];
    //console.log();

    this.cita.paciente.telefono = item["telefono"];
    ////console.log(this.paciente.telefono)
    this.addEventForm.patchValue({
      nombrePaciente: [item["nombrePaciente"]],
      apellidoPaterno: [item["apellidoPaterno"]],
      apellidoMaterno: [item["apellidoMaterno"]],
      genero: [this.cita.paciente.genero],
      telefono: [this.cita.paciente.telefono],
    });

    this.addEventForm2.patchValue({
      Paciente: this.cita.paciente._id,
    });
    if (item["fechaNacimientoPaciente"] != undefined) {
      this.cita.paciente.fechaNacimientoPaciente =
        item["fechaNacimientoPaciente"];
      var fecha = new Date(this.cita.paciente.fechaNacimientoPaciente);
      this.fechaNacimientoNgForm = fecha.toISOString().slice(0, 10);
      var hoy = new Date();
      var edad = hoy.getTime() - fecha.getTime();
      var edadfinal = Number(edad / 31536000000);
      this.edadNgForm = Math.floor(edadfinal);
      this.addEventForm.patchValue({
        edad: this.edadNgForm,
      });
    } else {
      this.addEventForm.patchValue({
        edad: 0,
      });
    }
    this.CambiarActivosInput();

    this.addEventForm.patchValue({ User: true });

    this.AllPacientes = [];
  }
  buscarPaciente(nombre: string) {
    if (nombre.length != 0) {
      if (nombre.length > 3) {
        const nameFilter = filterNameByPaciente(
          this.PACIENTES_SERVICES,
          nombre
        );
        console.log(nameFilter);

        this.AllPacientes = nameFilter;
        nombre = "";
      }
    } else {
      this.AllPacientes = [];

      this.addEventForm.reset();
      this.addEventForm.patchValue({ User: false });
    }
  }
  filterDatesByPaciente(pacientes: any, name: any) {
    const pacientesDatesFilter = [];

    pacientes.forEach((pacientes: any) => {
      var nombrePaciente = "";
      var apellidoPaterno = "";
      var apellidoMaterno = "";

      if (pacientes.idPaciente == undefined) {
        //validacion para los populates de la data
        nombrePaciente = pacientes.nombrePaciente;
        apellidoPaterno = pacientes.apellidoPaterno;
        apellidoMaterno = pacientes.apellidoMaterno;
      } else {
        nombrePaciente = pacientes.idPaciente.nombrePaciente;
        apellidoPaterno = pacientes.idPaciente.apellidoPaterno;
        apellidoMaterno = pacientes.idPaciente.apellidoMaterno;
      }

      nombrePaciente += " " + apellidoPaterno;
      nombrePaciente += " " + apellidoMaterno;
      nombrePaciente.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      if (nombrePaciente.includes(name.toUpperCase())) {
        pacientesDatesFilter.push(pacientes);
      }
    });
    return pacientesDatesFilter;
  }
  filterbyServer(server: any, name: any) {
    const ServerFilter = [];
    server.forEach((server: any) => {
      var nombreserver = "";
      nombreserver = server.ESTUDIO.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();
      ////console.log(nombreserver)

      if (nombreserver.includes(name.toUpperCase())) {
        ServerFilter.push(server);
      }
    });
    return ServerFilter;
  }

  SelectSer(item) {
    this.servicio._id = item["_id"];
    this.servicio.ESTUDIO = item["ESTUDIO"];
    this.addEventForm2.patchValue({ Servicios: [] });

    this.addEventForm2.patchValue({
      Servicios: [this.servicio._id],
      Subservicios: [this.servicio.ESTUDIO],
    });
    this.Subservice3 = [];
  }
  buscarPacienteCitas(nombre: string) {
    if (nombre.length != 0) {
      if (nombre.length > 3) {
        this.events = [];
        this.addEventForm3.patchValue({
          consultaserv: [],
        });
        const nameFilter = this.filterDatesByPaciente(this.agenda, nombre);

        //console.log("nameFilter")
        //console.log(nameFilter)
        this.AllDatesPacientes = nameFilter;
        nombre = "";
        this.ViewCitas(this.AllDatesPacientes);
        return this.spinner.hide();
      }
    } else {
      this.AllDatesPacientes = [];
      // this.GetAgenda('')
      // this.GetServiceAgenda('')
    }
  }
  focusSearchPatients() {
    const input: HTMLInputElement = document.querySelector("#busquedaPaciente");
    input.select();
  }
  OcultNameType() {
    //console.log(this.addEventForm3.value)
    if (this.subser4 === false) {
      this.subser4 = true;
      this.subser3 = false;
      document.getElementById("nameser").style.display = "none";
      return this.calendarComponent.getApi().changeView("dayGridMonth");
    } else if (this.subser4 === true) {
      this.subser4 = false;
      //console.log(this.addEventForm3.value)
      document.getElementById("nameser").style.display = "block";

      this.addEventForm3.patchValue({ consultName: [] });

      return (this.AllDatesPacientes = []);
    }
  }
  PaytoService(event: any, modo: any) {
    //console.log(modo);

    localStorage.removeItem("carrito");
    console.log(event);
    this.guardarLocalStorage(event);
    this.hideModal();
    //console.log();
    if (modo == "Hoy") {
      this._router.navigateByUrl("pago/servicios/agenda");
    } else if (modo == "Todo") {
      this._router.navigateByUrl("pago/servicios/agendaall");
    }
  }
  guardarLocalStorage(paciente: any) {
    if (localStorage.getItem("paciente")) {
      localStorage.removeItem("paciente");

      if (!localStorage.getItem("paciente"))
        localStorage.setItem("paciente", JSON.stringify(paciente));
    } else if (!localStorage.getItem("paciente")) {
      localStorage.setItem("paciente", JSON.stringify(paciente));
    }
  }
}


