import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';

@Component({
  selector: 'app-hoja-reporte-endoscopias',
  templateUrl: './hoja-reporte-endoscopias.component.html',
  styleUrls: ['./hoja-reporte-endoscopias.component.css']
})
export class HojaReporteEndoscopiasComponent implements OnInit {

  @Input() nameEstudie = "";
  @Output() dataDonanteEmit = new EventEmitter();

  public dataReportEstudies = {
    interpretacion: "",
    diagnostico:"",
    observaciones: "",
    realizo:"",
    cedula:"",
    especilidad:"",
    imgs:[]
  }

  public nombre :String
  public id = '';
  public imagenes = [];

  constructor(private _routeservi : IntegradosService,
              private _servicios : ServiciosService) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('idUltra');
    this._servicios.title$.subscribe( ((title: String) => {
      this.nombre = title;
    })) 
    /* this.obtenerEndos(); */
  }

  obtenerEndos(){
    this._routeservi.getServicioById(this.id)
    .subscribe((data:any) => {
      this.dataReportEstudies.interpretacion = data['data'].ELEMENTOS[0].machote
    })
  }

  //Archivos del DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[0].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }

  onRemove(event) {
    this.imagenes.splice(this.imagenes.indexOf(event), 1)
    this.files.splice(this.files.indexOf(event), 1);
  }

  sendResultsData () {
    // seteo de los datos de los resultados 
    this.dataReportEstudies.imgs = this.imagenes
    if(JSON.parse(localStorage.getItem('usuario')).medico != null || JSON.parse(localStorage.getItem('usuario')).nombre != undefined){
      this.dataReportEstudies.realizo = JSON.parse(localStorage.getItem('usuario')).medico
    }else{
      this.dataReportEstudies.realizo = JSON.parse(localStorage.getItem('usuario')).nombre  
    }
    this.dataReportEstudies.cedula = JSON.parse(localStorage.getItem('usuario')).cedulaProfesional
    this.dataReportEstudies.especilidad = JSON.parse(localStorage.getItem('usuario')).cedulaEsp
    //emit de los datos
    this.dataDonanteEmit.emit( this.dataReportEstudies);
    localStorage.removeItem('idUltra')
  }

}
