import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import Carrito from 'src/app/classes/carrito/carrito.class';
import { CEDE } from 'src/app/classes/cedes/cedes.class';
import Dates from 'src/app/classes/dates/date.class';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import Tickets from 'src/app/classes/tickets/ticket.class';
import { ActivatedRoute } from '@angular/router';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { PagoServiciosService } from 'src/app/services/pagos/pago-servicios.service';
import { USGService } from 'src/app/services/usg/usg.service';
import { XRAYService } from 'src/app/services/Rayos-X/xray.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import CarritoHorizonte from 'src/app/classes/carrito-horizonte/horizonte-carrito';

// import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';
import { PedidosSedesService } from 'src/app/services/pedidosSedes/pedidos-sedes.service';
import { SedesService } from 'src/app/services/sedes/sedes.service';
import { calcDeparmentsPorcentaje, setDataPedido } from 'src/app/classes/helpers/filterNamePaciente';
import { NgxSpinnerService } from 'ngx-spinner';
import { eliminarStorage } from 'src/app/functions/pacienteIntegrados';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { AgendaService } from 'src/app/services/agenda/agenda.service';
import { element } from 'protractor';

var valor1;
@Component({
  selector: 'pago-servicios-con',
  templateUrl: './pago-servicios-con.component.html',
  styleUrls: ['./pago-servicios-con.component.css']
})
export class PagoServiciosConComponent implements OnInit {

  public fecha = "";
  public porcentajesPrecios = [];

  public paciente = {
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    edad: 0,
    genero: '',
    sede: "",
    direccion: {
      callePaciente: ""
    },
    membresiaActiva: false,
    _id: "",
  }
  public IVaDEl16 = 0;
  public positionYPDF = 100;

  public infoVenta = {
    paciente: "",
    nombrePaciente: "",
    vendedor: "",
    fecha: "",
    hora: "",
    estudios: [],
    efectivo: false,
    doctorQueSolicito: "",
    transferencia: false,
    tarjetCredito: false,
    tarjetaDebito: false,
    facturacion: false,
    montoEfectivo: 0,
    montoTarjteCredito: 0,
    montoTarjetaDebito: 0,
    montoTranferencia: 0,
    sede: "",
    totalCompra: 0,
    prioridad: "",
    compraConMembresia: false,
    status: 'Pagado',
    ganancia: 0,
    gananciaPromedio: 0,
    idVenta: "",
    factura: '',
    folio: 0

  }

  public btnValidacion = true;
  public totalSinMembresia = 0;
  public totalConMembresia = 0;
  public porcentajeServicio = 0;
  public medicos = [];
  public agenda: any = [];
  public agendaPag: any = [];
  public agendaBuy: any = [];
  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public porcentajeSocio = 0;
  public totalConIva = 0;
  public totalCarritoMembresia = 0;
  constructor(private _pagoServicios: PagoServiciosService,
              private _router: Router,
              private _pedidosSedes: PedidosSedesService,
              private _sedes: SedesService,
              private spinner: NgxSpinnerService,
              private _servicios: ServiciosService,
              private _AgendaService: AgendaService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.GetAgenda();
    setTimeout(() => {
      this.obtenerPacienteStorage();
      this.obtenerCarrito();
      this.obtenerTotalCarrito();
      if (this.paciente.sede != 'TLYC01') {
        this.getPorcentajeServicio();
      } else {
        this.infoVenta.estudios = this.carrito.items;
      }
    }, 1000);
  }

  obtenerTotalCarrito() {
    this.totalSinMembresia = this.carrito.totalSin;
    this.totalCarritoMembresia = this.carrito.totalCon;
  }

  setDatesVenta() {
    const dates = new Dates();
    //this.infoVenta.totalCompra = this.carrito.totalSin;
    this.fecha = moment().format('l');
    this.infoVenta.hora = moment().format('LT');
    this.infoVenta.vendedor = getDataStorage()._id;
    this.infoVenta.paciente = this.paciente._id;
    /* if(this.infoVenta.paciente == undefined){
      this.infoVenta.paciente = this.paciente.id;
    } */
    this.infoVenta.sede = CEDE;
    this.infoVenta.prioridad = "Programado"
    this.infoVenta.fecha = dates.getDate();
    /* this.infoVenta.estudios = this.carrito.items; */
    if (this.paciente.membresiaActiva) {
      this.infoVenta.totalCompra = this.carrito.totalCon;
    } else {
      this.infoVenta.totalCompra = this.carrito.totalSin;
    }
  }

  setDatesPedidos() {
    //creamos un nuevo json para todos los pedidos que se hagan conforme a la venta
    this.carrito.items.forEach((element) => {
      let jsonToPedidos = { ... this.infoVenta }
      //  console.log(element);
      Object.assign(jsonToPedidos, { idPaciente: this.infoVenta.paciente });
      //comparamos que si la sede es diferenre de tlayacapan retorne otra respuesta 
      Object.assign(jsonToPedidos, { idServicio: element.idEstudio });
      Object.assign(jsonToPedidos, { nombreServicio: element.name });
      Object.assign(jsonToPedidos, { fechaPedido: this.infoVenta.fecha });
      //eliminamos los datos que estan de mas
      delete jsonToPedidos.estudios
      delete jsonToPedidos.montoEfectivo
      delete jsonToPedidos.montoTarjetaDebito
      delete jsonToPedidos.montoTarjteCredito
      delete jsonToPedidos.montoTranferencia
      delete jsonToPedidos.efectivo
      delete jsonToPedidos.tarjetCredito
      delete jsonToPedidos.tarjetaDebito
      delete jsonToPedidos.transferencia
      delete jsonToPedidos.compraConMembresia
      delete jsonToPedidos.nombrePaciente
      delete jsonToPedidos.status
      //peticion que se lanza para los nuevo estudios
      this.requestPedidos(jsonToPedidos);
    });
    //pedios de los estudios 
  }

  requestPedidos(jsonPedidios) {
    //peticion para los pedidos de los estudios 
    //peticion para crear los nuevo pedidos
    this._servicios.postANewPedidoNew(jsonPedidios).subscribe((data: any) => {
      if (data.ok) console.log(data)
    })
  }

  obtenerPacienteStorage() {
    const pacienteStorage = new PacienteStorage();
    this.paciente = pacienteStorage.verPacienteConMembresia();
  }

  obtenerCarrito() {
    let carritoSinMembresia = new Carrito();
    this.carrito = carritoSinMembresia.obtenerSotorageCarrito();
    this.totalSinMembresia = this.carrito.totalSin;
    this.totalCarritoMembresia = this.carrito.totalCon;
    // console.log( this.carrito );
    if (this.carrito.items[0]!) {
      if (this.carrito.items[0].name == "PAQUETE DE CONTROL PRENATAL" || this.carrito.items[0].name == 'PAQUETE NEONATAL (DE 0 A 12 MESES)') {
        this.infoVenta.doctorQueSolicito = localStorage.getItem('medicoSolicito');
      }
    }
  }

  validarBoton(valor) {
    if (valor.toFixed(2) == 0) {
      this.btnValidacion = false;
    }
  }

  factura(event) {
    this.infoVenta.factura = event
  }

  eliminarCarritoSinMembresia(item) {
    // console.log(item );
    let carritoMembresia = new Carrito();
    carritoMembresia.eliminar(item);
    this.obtenerCarrito();
  }

  calcularNuevoTotalEfectivo() {
    if (this.paciente.membresiaActiva) {
      if (this.carrito.totalCon < this.infoVenta.montoEfectivo) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalCarritoMembresia = this.carrito.totalCon - this.infoVenta.montoEfectivo;
        /* this.infoVenta.compraConMembresia = false; */
        this.validarBoton(this.totalCarritoMembresia);
      }
    } else {
      if (this.carrito.totalSin < this.infoVenta.montoEfectivo) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalSinMembresia = this.carrito.totalSin - this.infoVenta.montoEfectivo;
        /* this.infoVenta.compraConMembresia = false;  */
        this.validarBoton(this.totalSinMembresia);
      }
    }
  }

  calcuarMontoTarjetaDebito() {
    if (this.paciente.membresiaActiva) {
      if (this.infoVenta.montoTarjetaDebito > this.totalCarritoMembresia) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoTarjetaDebito;
        this.validarBoton(this.totalCarritoMembresia);
      }
    } else {
      if (this.infoVenta.montoTarjetaDebito > this.totalSinMembresia) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalSinMembresia = this.totalSinMembresia - this.infoVenta.montoTarjetaDebito;
        this.validarBoton(this.totalSinMembresia);
      }
    }
  }

  calcularMontoTarjetaCredito() {
    if (this.paciente.membresiaActiva) {
      if (this.infoVenta.montoTarjteCredito > this.totalCarritoMembresia) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoTarjteCredito;
        this.validarBoton(this.totalCarritoMembresia);
      }
    } else {
      if (this.infoVenta.montoTarjteCredito > this.totalSinMembresia) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalSinMembresia = this.totalSinMembresia - this.infoVenta.montoTarjteCredito;
        this.validarBoton(this.totalSinMembresia);
      }
    }
  }

  calcularMontoTransferencia() {
    if (this.paciente.membresiaActiva) {
      if (this.infoVenta.montoTranferencia > this.totalCarritoMembresia) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoTranferencia;
        this.validarBoton(this.totalCarritoMembresia);
      }
    } else {
      if (this.infoVenta.montoTranferencia > this.totalSinMembresia) {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'El MONTO ES MAYOR',
        })
      } else {
        this.totalSinMembresia = this.totalSinMembresia - this.infoVenta.montoTranferencia;
        this.validarBoton(this.totalSinMembresia);
      }
    }
  }

  // esta funcion imprime el ticket del paciente, se le pasa por parametro elfolio del ticket
  // TODO:se cambio la forma de llenar el ticket por la data que regresa al guardarlo en la base
  generarTicket(paciente, carrito, infoVenta,  folio) {
    const tickets = new Tickets();
    tickets.generarTicket(paciente, carrito, infoVenta, folio);
    // tickets.printTicketSinMembresia(this.paciente, this.carrito, this.infoVenta, folio);
  }

  agregarIva() {
    // agregamos la comision
    if (this.paciente.membresiaActiva) {
      let iva = 0.0;
      if (this.infoVenta.tarjetCredito) {
        iva = .036;
      }
      if (this.infoVenta.tarjetaDebito) {
        iva = .036;
      }
      let totalIva = ((this.totalCarritoMembresia * iva));
      this.totalConIva = this.totalCarritoMembresia + totalIva;
      this.totalCarritoMembresia = parseFloat(this.totalConIva.toFixed(2));
      /* this.totalCarritoMembresia =  Math.round(this.totalConIva); */
    } else {
      let iva = 0.0;
      if (this.infoVenta.tarjetCredito) {
        iva = .036;
      } else if (this.infoVenta.tarjetaDebito) {
        iva = .036;
      }
      let totalIva = ((this.totalSinMembresia * iva));
      this.totalConIva = this.totalSinMembresia + totalIva;
      this.totalSinMembresia = parseFloat(this.totalConIva.toFixed(2));
      /* this.totalSinMembresia =  Math.round(this.totalConIva); */
    }
  }

  sumPorcentajes(porcentaje: any) {
    this.porcentajeServicio += porcentaje;
    return this.porcentajeServicio
  }

  GetAgenda(){
    if(JSON.parse(localStorage.getItem('paciente'))!){
      this._AgendaService.getAgendaIdPaciente(JSON.parse(localStorage.getItem('paciente'))._id )
      .subscribe((resp: any) => {
        resp['data'].forEach((element) => {
          if (element.status == "PENDIENTE") {
            this.agenda.push(element)
            this.ObtenerPorFecha(element)
          } 
        });
      })
    }
  }

  ObtenerPorFecha(element: any) {
    let date = this.ObtenerFecha()
    let carrito = new CarritoHorizonte();
    let servicios = this.activatedRoute.snapshot.paramMap.get('agenda');
    if (servicios == "agenda") {
      if (date == element.fechaInicio) {
        if (element._id == element._id) {
          this.agenda = []
          this.agenda.push(element)
          return carrito.agregarItem(undefined, element.idServicio.PRECIO_PUBLICO, element.idServicio.PRECIO_MEMBRESIA, element.idServicio);
        }
      }
    } else if (servicios == "agendaall") {
      if (element.idServicio._id == element.idServicio._id) {
        return carrito.agregarItem(undefined, element.idServicio.PRECIO_PUBLICO, element.idServicio.PRECIO_MEMBRESIA, element.idServicio);
      }
    } 
  }

  ObtenerFecha() {
    var date = moment(new Date()).format("YYYY-MM-DD")
    return date
  }

  enviar() {
    let even: any = {}
    if(this.agenda.length != 0 && JSON.parse(localStorage.getItem('carrito')).items.length != 0){
      this.agenda.sort((a, b) => a.idServicio._id > b.idServicio._id).filter((element) => {
        JSON.parse(localStorage.getItem('carrito')).items.sort((a, b) => a.idEstudio > b.idEstudio).find(carrito => {
          if (carrito.idEstudio == element.idServicio._id) {
            this.agendaPag.push(element)
          }
        });
      });
      // JSON.parse(localStorage.getItem('carrito')).items.forEach(element => {
      //   this.agendaBuy.push(element)
      // });

     // let array:any[] = []
      
      // for (let index = 0; index < this.agendaBuy.length; index++) {
      //   let valor = this.agenda.find((element)=> this.agendaBuy[index].idEstudio == element.idServicio._id )
      //   if(valor == undefined){
      //     array.push(this.agendaBuy[index])
      //   }
               
      // }
      // array.forEach(element => {
      //   even = {
      //     Paciente: JSON.parse(localStorage.getItem('paciente'))._id,
      //     Doctor: null,
      //     agen: JSON.parse(localStorage.getItem('usuario'))._id,
      //     stat: 'PAGADO',
      //     color: null,
      //     Servicios: element.idEstudio,
      //     FechaEnt: null,
      //     FechaSal: null,
      //     HoraEnt: null,
      //     HoraSal: null,
      //     Motivo: null,
      //     Descripcion: null,
      //     Reservacion: null,
      //     sedecon: null
      //   }
      //    this._AgendaService.agregarCita(even).subscribe((resp: any) => { console.log(resp) })
      // });
      this.agendaPag.forEach((element, index) => {
        let pagado ={
          status: 'PAGADO'
        }
        this._AgendaService.CancelarCita(element._id, pagado).subscribe((resp: any) => {
          console.log(resp);
          
        })
      });

    }
    // else if(this.agenda.length == 0 && JSON.parse(localStorage.getItem('carrito')).items.length != 0){
    //   JSON.parse(localStorage.getItem('carrito')).items.filter(element => {

    //     even = {
    //       Paciente: JSON.parse(localStorage.getItem('paciente'))._id,
    //       Doctor: null,
    //       agen: JSON.parse(localStorage.getItem('usuario'))._id,
    //       stat: 'PAGADO',
    //       color: null,
    //       Servicios: element.idEstudio,
    //       FechaEnt: null,
    //       FechaSal: null,
    //       HoraEnt: null,
    //       HoraSal: null,
    //       Motivo: null,
    //       Descripcion: null,
    //       Reservacion: null,
    //       sedecon: null
    //     }
    //      this._AgendaService.agregarCita(even).subscribe((resp: any) => { console.log(resp) })

    //   });

    // }
  }
 
  enviarPedido() {
    // console.log(this.infoVenta);
    this.spinner.show();
    this.btnValidacion = true;
    this.setDatesVenta();
    this.infoVenta.sede = this.paciente.sede;
    this.infoVenta.ganancia = (this.infoVenta.totalCompra * this.porcentajeSocio) / 100;
    this.infoVenta.compraConMembresia = this.paciente.membresiaActiva;
    /* this.obtenerPorcentajeSocio(this.infoVenta.sede); */
    //! TODO: quitar el blur del boton de pago
    //! TODO: agregar los nombres de los Drs de la 
    this._pagoServicios.agregarPedido(this.infoVenta).subscribe((data: any) => {
      if (data['ok']) {
        if (this.infoVenta.sede != 'TLYC01') {
          let gan = {
            ganancias: this.infoVenta.ganancia
          }
          let info = Object.assign(this.infoVenta, gan)
          this._pedidosSedes.ventas(info).subscribe((resp: any) => { console.log() });
        }
        if (data.data._id) {
          this.infoVenta.idVenta = data.data._id;
          this.setDatesPedidos();
        }
        //data['data'].paciente
        this.generarTicket(this.paciente, data['data'].estudios, data['data'] ,data['data']['folio']);
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'VENTA GENERADA EXITOSAMENTE',
        })
        this.enviar()
        eliminarStorage();
        let validarConsultas = this.verificarConsulta()
        if (validarConsultas >= 1) {
          this.spinner.hide();
          this._router.navigateByUrl('/consulta');
        } else {
          const eliminarPaciente = new PacienteStorage();
          eliminarPaciente.removePacienteStorage();
          this.spinner.hide();
          this._router.navigateByUrl('/');
        }
      }
    });
  }

  verificarConsulta() {
    let contadorConsultas = 0;
    this.infoVenta.estudios.forEach(element => {
      if (element.name == 'consulta') {
        contadorConsultas += 1
      }
    })
    return contadorConsultas;
  }

  async getPorcentajeServicio() {
    this.carrito.items.forEach((element: any, index) => {
      // console.log(element)
      this._sedes.getPorcentajeBySede(this.paciente.sede, element.name)
        .subscribe((resp) => {
          this.setDatos(resp['data'], index)
        })
    });
  }

  setDatos(datos: any, index) {
    this.infoVenta.estudios.push(datos)
    this.porcentajeSocio = calcDeparmentsPorcentaje(datos)
    this.infoVenta.gananciaPromedio += this.sumPorcentajes(calcDeparmentsPorcentaje(datos));
    if (index == this.carrito.items.length - 1) {
      let valor = this.infoVenta.gananciaPromedio / this.carrito.items.length;
      this.infoVenta.gananciaPromedio = valor;
    }
  }

  eliminar(i) {
    const eliminarItem = new Carrito;
    eliminarItem.eliminar(i);
    this.obtenerCarrito();
    this.obtenerTotalCarrito();
  }

  calcularIva() {
    this.IVaDEl16 = ((this.carrito.totalSin * 16) / 100);
    return this.IVaDEl16;
  }

  onClick() {
    this.btnValidacion = true;
  }

}

interface items {
  nombreEstudio: string
  precioCon: string
  precioSin: string
  idEstudio: string
  name: string
  estado: string
}