<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE SERVICIOS'"></app-titulos>

    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" class="form-control"  autofocus="autofocus"  id="busquedaServicio" #searchNameService   (keyup)="filterServicesByName( searchNameService )" placeholder="BUSCAR">
            </div>
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/agregar', 'servicios']">NUEVO</a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">

        </div>
    </div>

    <div class="card">
        <div class="card-header header">
            <div class="row">
                <div class="col-1 text_header">
                    No.
                </div>
                <div class="col text_header">
                    Nombre
                </div>
                <div class="col text_header">
                    
               </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row puntero pointer" *ngFor="let servicio of servicios |  paginate:{id:'listapacientes',
            itemsPerPage: 7,
            currentPage:pagina,
            totalItems:totalservices} ; let i = index"  [routerLink]="['/detalle/servicio/almacen', servicio._id]" >
                <div class="col-1" style="text-align: center;">
                    <p>
                        {{ i + 1 }}
                    </p>
                </div>
                <div class="col" style="text-align: center;" >
                    <p>
                        {{ servicio.nombre }}
                    </p>
                </div>
                <div class="col" style="text-align: center">
                    <p>
                      <i
                        class="fa fa-trash-alt colorRed icons"
                        (click)="disabledProdcut(servicio._id)"
                      >
                      </i>
                      <i
                        class="far fa-edit colorGreenEditIcon icons"
                        [routerLink]="['/editar/servicio/almacen/', servicio._id]"
                      ></i>
                    </p>
                </div>
            </div>
            <div class="offset-md-4">
                <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div> 
    </div>
</div>

<!-- <div class="container-fluid">
    <app-titulos [title]="'LISTADO DE SERVICIOS'"></app-titulos>

    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" class="form-control"  autofocus="autofocus"  id="busquedaServicio" #searchNameService   (keyup)="filterServicesByName( searchNameService )" placeholder="BUSCAR">
            </div>
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/agregar', 'servicios']">NUEVO</a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">

        </div>
    </div>

    <div class="card">
        <div class="card-header header">
            <div class="row">
                <div class="col-1 text_header">
                    No.
                </div>
                <div class="col text_header">
                    Nombre
                </div>
                <div class="col text_header">
                     Descripción
                </div>
                <div class="col text_header">
                     Categoría
                </div>
                <div class="col text_header">
                     Quien realiza
                </div>
                <div class="col text_header">
                     Factura
                </div>
                <div class="col text_header">
                     Horario
                </div>
                <div class="col text_header">
                    
               </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row puntero pointer" *ngFor="let servicio of servicios |  paginate:{id:'listapacientes',
            itemsPerPage: 7,
            currentPage:pagina,
            totalItems:totalservices} ; let i = index"  [routerLink]="['/detalle/servicio/almacen', servicio._id]" >
                <div class="col-1" style="text-align: center;">
                    <p>
                        {{ i + 1 }}
                    </p>
                </div>
                <div class="col" style="text-align: center;" >
                    <p>
                        {{ (servicio.nombre.length>8)? (servicio.nombre | slice:0:8)+'..':(servicio.nombre)}}
                    </p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>
                      {{ (servicio.descripcion.length>8)? (servicio.descripcion | slice:0:8)+'..':(servicio.descripcion) }}
                    </p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>
                        {{ servicio.categoria }}
                    </p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>
                        {{ servicio.profesionalAREalizar  }}
                    </p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>
                        {{ servicio.costo }}
                    </p>
                </div>
                <div class="col" style="text-align: center;">
                    <p>
                        {{ servicio.horario }}
                    </p>
                </div>
                <div class="col" style="text-align: center">
                    <p>
                      <i
                        class="fa fa-trash-alt colorRed icons"
                        (click)="disabledProdcut(servicio._id)"
                      >
                      </i>
                      <i
                        class="far fa-edit colorGreenEditIcon icons"
                        [routerLink]="['/editar/servicio/almacen/', servicio._id]"
                      ></i>
                    </p>
                  </div>
            </div>
            <div class="offset-md-4">
                <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div> 
    </div>
</div> -->