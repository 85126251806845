import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { URL } from 'src/app/config/conf';
@Injectable({
  providedIn: 'root'
})
export class MedicamentosService {

  constructor( private _http: HttpClient ) { }

  public url = URL;

  agragarmedicamentos (info){

    let url =  this.url + "/agregar/medicamento";
  
    return this._http.post(url, info);
    
   }
  
   obtenermedicamento () {
  
    let url =  this.url + "/medicamentos/doctor";
  
    return this._http.get(url)
  
   }

   obtenerMedicamentoPorNombre(  nombre ){
    let url =  this.url +  `/medicamento/${nombre}`;
    return this._http.get(  url );
   }
  
   eliminarMedicamento( id ){
     let url =  this.url + "/eliminar/medicamento/" + id;
     return this._http.delete(url)
   }

   validarMedicamento(nombre){
    let url =  this.url + "/medicamento/" + nombre
    return this._http.get(url)
   
   }

}
