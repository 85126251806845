<!-- <section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">Ficha de Enfermería</h1>
    </div>
    <div class="col">
        <h1 class="fecha" id="fecha">{{ fecha | date}}</h1>
    </div>
</section> -->

<!-- <section class="row upper">
    <div class="card" name="card" id="card">

        <br>

        <div class="row">
            <div class="col margin-info">
                <p> <span class="title-header">  Nombre Completo: {{ paciente.nombre }} {{ paciente.apellidoPaterno }} {{ paciente.apellidoMaterno }} </span> </p>
            </div>
            <div class="col">
                <p> <span class="title-header"> Edad: {{ paciente.edad }} </span> </p>
            </div>
            <div class="col">
                <p> <span class="title-header"> Género: {{paciente.genero | uppercase }} </span> </p>
            </div>
        </div>

        <div class="row">
            <div class="col margin-info">
                <p>Domicilio: {{ paciente.callePaciente }} {{paciente.estadoPaciente }} {{ paciente.paisPaciente }} </p>
            </div>
            <div class="col">
                <p>Fecha de Nacimiento: {{paciente.fechaNacimiento}} </p>
            </div>
            <div class="col">
                <p>Lugar de Nacimiento: {{ paciente.paisPaciente }} </p>
            </div>
        </div>

        <div class="row">
            <div class="col margin-info">
                <p>Teléfono: {{ paciente.telefono }} </p>
            </div>
            <div class="col">
                <p> ID: {{ paciente.idPaciente }} </p>
            </div>
        </div>

        <div class="row">
            <div class="col margin-info">
                <label> Tipo de Sangre: </label>

                <select name="tSangre" class="form-control" style="max-width: 25em;">
                  <option value="Tipo" selected>Seleccionar una opción</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="desconoce">Desconocido</option>
                  Cambiar edades, Hombres de 10 a 99 años
                      Hombrees de 10 a 99 años, agregando parametros especiales
              </select>

                <br>

            </div>


            <div class="col">
                <label>Religión: </label>

                <select name="religion" class="form-control" style="max-width: 25em;">
                <option value="Tipo" selected>Seleccionar una opción</option>
                <option value="judios">Judío</option>
                <option value="catolicos">Católico</option>
                <option value="evangelicos">Evangélico</option>
                <option value="menonitas">Menonita</option>
                <option value="pentecostales">Pentecostés</option>
                <option value="bautista">Bautista</option>
                <option value="metodistas">Metodista</option>
                <option value="adventistas">Adventista</option>
                <option value="budistas">Budista</option>
                <option value="krishnas">Krishna</option>
                <option value="presbiterianos">Presbiteriano</option>
                <option value="testigos_de_jehova">Testigo de Jehová</option>
                <option value="islamicos">Islámico</option>
                <option value="otraReligion">Otra</option>
                 </select>
                <br>
            </div>

            <div class="col" *ngIf="paciente.edad <= 9">
                <div class="form-check form-check-inline">
                    <label class="form-check-label">¿Cuenta con esquema de Vacunación?</label>
                    <input type="radio" id="" name="ev" class="form-check-input-lg" [(ngModel)]="infPaciente.esquemaVacunacion" value="si">
                    <label class="form-check-label" for="si">Si</label>
                </div>
                <div class="form-check form-check-inline">
                    <input type="radio" id="" name="ev" class="form-check-input-lg" [(ngModel)]="infPaciente.esquemaVacunacion" value="no">
                    <label class="form-check-label" for="no">No</label>
                </div>

                <div>

                    <select style="max-width: 25em; margin-top: 4px;" name="esquemaVacunacionStatus" id="esquemaVacunacionStatus" class="form-control" *ngIf="infPaciente.esquemaVacunacion == 'si'">
                        <option value="" selected>Seleccionar una opción</option>
                        <option value="completo">Completo</option>
                        <option value="incompleto">Incompleto</option>
                    </select>

                </div>

            </div>
        </div>

    </div>
</section> -->




<section class="row">
    <div class="card" name="card" id="card">
        <form class="container-fluid" #f='ngForm'>

            <div class="row">
                <br>

                <div class="col">
                    <label for="talla">Talla m</label>
                    <input type="text" name="talla" required placeholder="Talla M" [(ngModel)]="talla" class="form-control">
                </div>
                <div class="col">
                    <label for="peso">Peso Kg</label>
                    <input type="text" name="peso" required placeholder="Peso" [(ngModel)]="peso" (change)="obtenerIMC()" class="form-control">
                </div>
                <div class="col">
                    <label for="imc">IMC</label>
                    <input type="text" name="imc" required placeholder="IMC" [(ngModel)]="imc" [value]="imc" class="form-control">
                    <!-- Crear en base P/T ^ 2, debe ser por defecto -->
                </div>
                <div class="col" *ngIf="paciente.edad > 9">
                    <label for="">Sistólica</label>
                    <input type="text" name="sist" [(ngModel)]="signosVitales.sistolica" placeholder="---" class="form-control">
                </div>
                <div class="col" *ngIf="paciente.edad > 9">
                    <label for="">Diastólica</label>
                    <input type="text" name="diastolica" [(ngModel)]="signosVitales.diastolica" placeholder="---" class="form-control">
                </div>

                <div class="col" *ngIf="paciente.edad <= 9">
                    <label for="">LPM</label>
                    <input type="text" name="fc" [(ngModel)]="signosVitales.fc" placeholder="FC" class="form-control">
                </div>
                <div class="col" *ngIf="paciente.edad <= 9">
                    <label for="">RPM</label>
                    <input type="text" name="fr" [(ngModel)]="signosVitales.fr" placeholder="FR" class="form-control">
                </div>
                <div class="col">
                    <label for="">°C</label>
                    <input type="text" name="temp" [(ngModel)]="signosVitales.temp" placeholder="TEMP" class="form-control">
                </div>

            </div>


            <div class="row">
                <div class="col">
                    <label for="">PC cm</label>
                    <input type="text" name="pc" [(ngModel)]="signosVitales.pc" required placeholder="P.C." class="form-control">

                </div>
                <div class="col">
                    <label for="">PA cm</label>
                    <input type="text" name="pa" [(ngModel)]="signosVitales.pa" placeholder="P.A." class="form-control">
                </div>
                <div class="col">
                    <label for="">PT cm</label>
                    <input type="text" name="pt" [(ngModel)]="signosVitales.pt" placeholder="P.T." class="form-control">
                </div>

                <div class="col" *ngIf="paciente.edad <= 9">
                    <label for="">APGAR</label>
                    <input type="text" name="apgar" [(ngModel)]="signosVitales.apgar" placeholder="0 - 10" class="form-control">
                </div>

                <div class="col" *ngIf="paciente.edad <= 9 ">
                    <label for="">SaO %</label>
                    <input type="text" name="saoNino" [(ngModel)]="signosVitales.SaO" placeholder="SaO" class="form-control">
                </div>

                <div class="col" *ngIf="paciente.edad > 9">
                    <label for="">Oximetría</label>
                    <input type="text" name="pao" [(ngModel)]="signosVitales.pao" placeholder="PaO2" class="form-control">
                </div>

                <div class="col">
                    <label for="glucosa">  Glucosa:   </label>
                    <span class="glucoWarning">Colocar valor 0 en caso de no tomar la muestra de Glucosa</span>
                    <input type="text" name="glucosa" [(ngModel)]="signosVitales.glucosa" placeholder="Glucosa" class="form-control" (blur)="validacionFormSignos()">
                </div>
            </div>

            <br>

            <div class="row">

                <!-- <div class="col">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">Riesgo de caida:</label>
                        <input type="radio" name="riesgoCaidaSi" value="Si" [(ngModel)]="signosVitales.riesgoCaida" class="form-check-input-lg">
                        <label class="form-check-label" for="riesgoDeCaida"> Si </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" name="riesgoCaidaNo" value="No" [(ngModel)]="signosVitales.riesgoCaida" class="form-check-input-lg">
                        <label class="form-check-label" for="riesgoDeCaidaNo">No</label>
                    </div>
                </div> -->

                <div class="col">
                    <app-riesgo-de-caida (totalRiesgoCaida)="caida($event)"></app-riesgo-de-caida>
                </div>

                <div class="col">
                    <label for="">  Diagnóstico previo </label>
                    <input class="form-control" name="diagnosticoActual" type="text" [(ngModel)]="signosVitales.diagnosticoActual" placeholder="Diagnóstico previo">
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <button [disabled]="btnSignosVitales" class="secondaryButton" (click)="ageragrSignos()">Guardar</button>
                </div>

            </div>

            <br>

        </form>

    </div>

</section>