import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { IntegradosService } from '../../../services/servicios/integrados.service';
import { SocioService } from '../../../services/socio/socio.service';

@Component({
  selector: 'app-socio-canal',
  templateUrl: './socio-canal.component.html',
  styleUrls: ['./socio-canal.component.css']
})
export class SocioCanalComponent implements OnInit {

  public idservicios:string;
  public servicio:string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.idservicios = this.activatedRoute.snapshot.paramMap.get('id');
    this.servicio=this.activatedRoute.snapshot.paramMap.get('servicio')
  }

}
