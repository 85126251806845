<form #form='ngForm'>
    <mat-horizontal-stepper linear #stepper>
        <mat-step label="Datos generales">
            <div class="row">
                <div class="col-md-2">
                    <label for="nombreServicio"> Nombre del servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" maxlength="13" name="nombreServicio" [(ngModel)]="serviceEdit.DESTINO" id="nombreServicio" class="form-control">
                </div>

                <div class="col-md-2 offset-1">
                    <label for="idServicio"> ID del servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="idServicio" [(ngModel)]="serviceEdit._id" id="idServicio" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="sede">  Sede </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="sede" [(ngModel)]="sede" id="sede" class="form-control">
                </div>

                <div class="col-md-2 offset-1">
                    <label for="idServicio"> Servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="Servicio" [(ngModel)]="serviceEdit.name" id="Servicio" class="form-control">
                </div>
            </div>
        </mat-step>
        <mat-step label="Rango de utilidad">
            <div class="row">    
                <div class="col-md-2">
                    <label for="rangoUtilidad"> Rango de utilidad </label>
                </div>
                <div class="col-md-3">
                    <select name="rangoUtilidad" [(ngModel)]="utilidad.rangoUtilidad" id="rangoUtilidad" class="form-control">
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                    </select>
                </div>
            </div>
            
            <div class="row">    
                <div class="col-md-2">
                    <label for="precioPublicoDia"> PRECIO PUBLICO DIA </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" maxlength="13" name="precioPublicoDia" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_DIA" id="precioPublicoDia" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioMembresiaDia"> PRECIO MEMBRESIA DIA </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioMembresiaDia" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_DIA" id="precioMembresiaDia" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioPublicoNoche">  PRECIO PUBLICO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioPublicoNoche" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_NOCHE" id="precioPublicoNoche" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioMembresiaNoche"> PRECIO MEMBRESIA NOCHE </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioMembresiaNoche" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_NOCHE" id="precioMembresiaNoche" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioPublicoRedondoDia">  PRECIO PUBLICO REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioPublicoRedondoDia" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_DIA" id="precioPublicoRedondoDia" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioMembresiaRedondoDia"> PRECIO MEMBRESIA REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioMembresiaRedondoDia" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_DIA" id="precioMembresiaRedondoDia" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioPublicoRedondoNoche"> PRECIO PUBLICO REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioPublicoRedondoNoche" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_NOCHE" id="precioPublicoRedondoNoche" class="form-control">
                </div>

                <div class="col-md-2 offset-1">
                    <label for="precioMembresiaRedondoNoche"> PRECIO MEMBRESIA REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <input type="text" [disabled]="true" name="precioMembresiaRedondoNoche" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_NOCHE" id="precioMembresiaRedondoNoche" class="form-control">
                </div>
                <!-- terminan los datos fiscales  -->
            </div>
        </mat-step>

        <mat-step label="Precio por utilidad">
            <div class="row">
                <div class="col" style="font-weight: bolder; text-align: center;">
                    <p>RANGO A</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <p id="utilidad1">Utilidad</p>
                </div>
                <div class="col-md-8">
                    <p id="utilidad2">Utilidad</p>
                </div>
            </div> 
            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublicoDiaA"> PRECIO PUBLICO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" maxlength="13" name="utilidadprecioPublicoDiaA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_DIA" id="utilidadprecioPublicoDiaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" maxlength="13" name="utilidadprecioPublicoDiaA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_publico_diaA" id="utilidadprecioPublicoDiaA2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaDiaA"> PRECIO MEMBRESIA DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaDiaA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_DIA" id="utilidadprecioMembresiaDiaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaDiaA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_membresia_diaA" id="utilidadprecioMembresiaDiaA2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoNocheA">  PRECIO PUBLICO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoNocheA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_NOCHE" id="utilidadprecioPublicoNocheA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoNocheA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_publico_nocheA" id="utilidadprecioPublicoNocheA2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaNocheA"> PRECIO MEMBRESIA NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaNocheA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_NOCHE" id="utilidadprecioMembresiaNocheA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaNocheA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_membresia_nocheA" id="utilidadprecioMembresiaNocheA2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoRedondoDiaA">  PRECIO PUBLICO REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoRedondoDiaA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_DIA" id="utilidadprecioPublicoRedondoDiaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoRedondoDiaA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_publico_redondo_diaA" id="utilidadprecioPublicoRedondoDiaA2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaRedondoDiaA"> PRECIO MEMBRESIA REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaRedondoDiaA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_DIA" id="utilidadprecioMembresiaRedondoDiaA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaRedondoDiaA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_membresia_redondo_diaA" id="utilidadprecioMembresiaRedondoDiaA2" class="form-control">
                        </div>
                    </div>     
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoRedondoNocheA">  PRECIO PUBLICO REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoRedondoNocheA" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_NOCHE" id="utilidadprecioPublicoRedondoNocheA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoRedondoNocheA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_publico_redondo_nocheA" id="utilidadprecioPublicoRedondoNocheA2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaRedondoNocheA"> PRECIO MEMBRESIA REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaRedondoNocheA" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_NOCHE" id="utilidadprecioMembresiaRedondoNocheA" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaRedondoNocheA2" [(ngModel)]="utilidad.preciosRangoA[0].precio_membresia_redondo_nocheA" id="utilidadprecioMembresiaRedondoNocheA2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>

            <div class="row">
                <div class="col" style="font-weight: bolder; text-align: center;">
                    <p>RANGO B</p>
                </div>
            </div>

            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublicoDiaB"> PRECIO PUBLICO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" maxlength="13" name="utilidadprecioPublicoDiaB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_DIA" id="utilidadprecioPublicoDiaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" maxlength="13" name="utilidadprecioPublicoDiaB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_publico_diaB" id="utilidadprecioPublicoDiaB2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaDiaB"> PRECIO MEMBRESIA DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaDiaB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_DIA" id="utilidadprecioMembresiaDiaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaDiaB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_membresia_diaB" id="utilidadprecioMembresiaDiaB2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoNocheB">  PRECIO PUBLICO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoNocheB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_NOCHE" id="utilidadprecioPublicoNocheB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoNocheB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_publico_nocheB" id="utilidadprecioPublicoNocheB2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaNocheB"> PRECIO MEMBRESIA NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaNocheB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_NOCHE" id="utilidadprecioMembresiaNocheB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaNocheB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_membresia_nocheB" id="utilidadprecioMembresiaNocheB2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoRedondoDiaB">  PRECIO PUBLICO REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoRedondoDiaB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_DIA" id="utilidadprecioPublicoRedondoDiaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoRedondoDiaB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_publico_redondo_diaB" id="utilidadprecioPublicoRedondoDiaB2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaRedondoDiaB"> PRECIO MEMBRESIA REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaRedondoDiaB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_DIA" id="utilidadprecioMembresiaRedondoDiaB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaRedondoDiaB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_membresia_redondo_diaB" id="utilidadprecioMembresiaRedondoDiaB2" class="form-control">
                        </div>
                    </div>     
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoRedondoNocheB">  PRECIO PUBLICO REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoRedondoNocheB" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_NOCHE" id="utilidadprecioPublicoRedondoNocheB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoRedondoNocheB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_publico_redondo_nocheB" id="utilidadprecioPublicoRedondoNocheB2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaRedondoNocheB"> PRECIO MEMBRESIA REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaRedondoNocheB" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_NOCHE" id="utilidadprecioMembresiaRedondoNocheB" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaRedondoNocheB2" [(ngModel)]="utilidad.preciosRangoB[0].precio_membresia_redondo_nocheB" id="utilidadprecioMembresiaRedondoNocheB2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>

            <div class="row">
                <div class="col" style="font-weight: bolder; text-align: center;">
                    <p>RANGO C</p>
                </div>
            </div>

            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublicoDiaC"> PRECIO PUBLICO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" maxlength="13" name="utilidadprecioPublicoDiaC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_DIA" id="utilidadprecioPublicoDiaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" maxlength="13" name="utilidadprecioPublicoDiaC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_publico_diaC" id="utilidadprecioPublicoDiaC2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaDiaC"> PRECIO MEMBRESIA DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaDiaC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_DIA" id="utilidadprecioMembresiaDiaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaDiaC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_membresia_diaC" id="utilidadprecioMembresiaDiaC2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoNocheC">  PRECIO PUBLICO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoNocheC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_NOCHE" id="utilidadprecioPublicoNocheC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoNocheC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_publico_nocheC" id="utilidadprecioPublicoNocheC2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaNocheC"> PRECIO MEMBRESIA NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaNocheC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_NOCHE" id="utilidadprecioMembresiaNocheC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaNocheC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_membresia_nocheC" id="utilidadprecioMembresiaNocheC2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoRedondoDiaC">  PRECIO PUBLICO REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoRedondoDiaC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_DIA" id="utilidadprecioPublicoRedondoDiaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoRedondoDiaC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_publico_redondo_diaC" id="utilidadprecioPublicoRedondoDiaC2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaRedondoDiaC"> PRECIO MEMBRESIA REDONDO DIA </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaRedondoDiaC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_DIA" id="utilidadprecioMembresiaRedondoDiaC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaRedondoDiaC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_membresia_redondo_diaC" id="utilidadprecioMembresiaRedondoDiaC2" class="form-control">
                        </div>
                    </div>     
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioPublicoRedondoNocheC">  PRECIO PUBLICO REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioPublicoRedondoNocheC" [(ngModel)]="serviceEdit.PRECIO_PUBLICO_REDONDO_NOCHE" id="utilidadprecioPublicoRedondoNocheC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioPublicoRedondoNocheC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_publico_redondo_nocheC" id="utilidadprecioPublicoRedondoNocheC2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresiaRedondoNocheC"> PRECIO MEMBRESIA REDONDO NOCHE </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" [disabled]="true" min="0" name="utilidadprecioMembresiaRedondoNocheC" [(ngModel)]="serviceEdit.PRECIO_MEMBRESIA_REDONDO_NOCHE" id="utilidadprecioMembresiaRedondoNocheC" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="number" min="0" name="utilidadprecioMembresiaRedondoNocheC2" [(ngModel)]="utilidad.preciosRangoC[0].precio_membresia_redondo_nocheC" id="utilidadprecioMembresiaRedondoNocheC2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>
            <div class="center" *ngIf="editar == false">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
            </div> 
            <div class="center" *ngIf="editar == true">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   EDITAR </button>
            </div>  
        </mat-step>

        <!-- <mat-step label="Agregar">
            <div class="center">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
            </div>
        </mat-step> -->
    </mat-horizontal-stepper>
</form>
