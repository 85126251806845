import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { LoginService } from 'src/app/services/login.service';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() nombreUsuario: string;

  public usuario = {
    role: ""
  };
  public userName = ""

  constructor(
    public _loginService: LoginService,
    private wsLoginService: WsLoginService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getSessionStorageUser();
  }

  getSessionStorageUser( ) {

    this.userName = getDataStorage().nombre;
    // console.log(this.userName)
  }

  cerrarSesion(){


    this._loginService.logout();
    this.wsLoginService.adios();
    window.location.reload();

  }

}
