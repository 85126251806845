<section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">SERVICIOS POR USUARIO</h1>
    </div>
    <div class="col">
        <h1 class="fecha" id="fecha">11/01/2021</h1>
    </div>

</section>

<br>

<section class="row">
    <div class="col-md">
        <h3 style="color:#001672;">SERVICIOS</h3>

    </div>

    <div class="col-md">

        <select name="Selecciona un servicio " id="servicio " style="color:#001672 ;" class="Derecha"> <Option> Selecciona un servicio</Option></select>
    </div>

    <div class="col-md">
        <button class="btn-primary" style="color:white; ">AGREGAR</button>
    </div>


    <div class="col-md">
        <button class="btn" style="color: aliceblue;">EDITAR</button>

    </div>
</section>


<br>


<div class="center">
    <section>
        <div class="card" style="border-radius: 20px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col-1 text_header">
                        <p>No</p>
                    </div>
                    <div class="col text_header">
                        <p>Concepto</p>
                    </div>
                    <div class="col text_header">
                        <p>Precio Público</p>
                    </div>
                    <div class="col text_header">
                        <p>Utilidad</p>
                    </div>
                    <div class="col text_header">
                        <p>Monto</p>
                    </div>
                    <div class="col text_header">
                        <p>Precio Membresía</p>
                    </div>
                    <div class="col text_header">
                        <p>Utilidad</p>
                    </div>
                    <div class="col text_header">
                        <p>Monto</p>
                    </div>
                </div>
            </div>


            <div class="card-body">
                <div>

                    <div class="col-md-2">
                        <p style="color: #001672;"> Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Loading..</p>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </section>
</div>