import { Component, OnInit, Input } from '@angular/core';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
import  CarritoHorizonte  from 'src/app/classes/carrito-horizonte/horizonte-carrito';
import { cita, 
         laboratorios, 
         numeroVisitas, 
         validarConsultas,
         generarSemanaDosPagos,
         acumulados,
         restante,
         agregarConsultaCarrito,
         restarConsultas} from '../../../../functions/paquetes';
import { getCarritoStorage } from '../../../../functions/pacienteIntegrados';
import { Paciente } from '../../../../interfaces/pacientes.interface';
import { venta, carrito } from 'src/app/interfaces/paquetes'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { PagoServiciosService } from 'src/app/services/pagos/pago-servicios.service';
import Tickets from 'src/app/classes/tickets/ticket.class';
import { NgxSpinnerService } from 'ngx-spinner';
import Dates from 'src/app/classes/dates/date.class';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vida-plena',
  templateUrl: './vida-plena.component.html',
  styleUrls: ['./vida-plena.component.css']
})
export class VidaPlenaComponent implements OnInit {
  
  @Input() id: String;
  public pacienteInfo:Paciente = {} as Paciente;
  public carrito:carrito = {} as carrito;
  public acumulado = 0;
  public costoTotalPaquete=0;
  public anticipo = 0;
  public selectSemanaGest=0
  public selectSemanaPago=0
  public paquete:any[] = [];
  public consulta:any[]=[];
  public citas:any[] = [];
  public citasIncluidas:any[] = [];
  public laboratoriosIncluidas:any[] = [];
  public rayosx:any[] = [];
  public tomografias:any[] = [];
  public hospitalizacion:any[] = []
  public nombrePaquete='';
  public medicos = [];
  public tabularpagos:any[]=[];
  public sem = [];
  public semanaGestacion=[];
  public semanapago=[];
  public pagoSemana;
  public deposito;
  public checked = {
    incluye: false,
    noincluye: false
  }
  public tipoPago={
    tipo:""
  }
  public sumaAcumulado=[];
  public totalpagos=0;
  public totalSemanas;
  public catidadSemanal=0;
  public infoVenta:venta = {} as venta;
  public comisionCredito;
  public comisionDebito;
  
  constructor(public _paquete:PaquetesService,
              private _pagoServicios: PagoServiciosService,
              private spinner: NgxSpinnerService,
              private _router:Router) {}

  ngOnInit(): void {
    this.obtenerPaquete();
    this.obtenerMedicos();
  }

  obtenerMedicos() {
    this._paquete.getMedicos()
    .subscribe((data:any) => {
      if(data.ok) {
        this.setMedicos(data.data);
      }
    })
  }
  
  setMedicos(data) {
    data.forEach(element => {
      this.medicos.push(element.NOMBRE)
    });
  }

  obtenerPaquete(){
    this.obtenerCarrito()
    this._paquete.obtenerPaquete(this.id).subscribe(  (data:any) =>  {
      this.pacienteInfo = data['paquetes']['paciente']
      this.paquete.push(data['paquetes']);
      this.citasIncluidas = numeroVisitas(data['paquetes']['paquete'].consultas,data['paquetes'].consultas)
      this.laboratoriosIncluidas = numeroVisitas(data['paquetes']['paquete'].laboratorio,data['paquetes'].laboratorio)
      this.rayosx = numeroVisitas(data['paquetes']['paquete'].rayosX,data['paquetes'].rayosX)
      this.tomografias = numeroVisitas(data['paquetes']['paquete'].tomografia,data['paquetes'].tomografia)
      this.tabularpagos = data['paquetes'].pagos;
      this.anticipo = data['paquetes'].pagos[0].pago
      this.sumaAcumulado = acumulados(this.tabularpagos)
      this.costoTotalPaquete = data['paquetes']['paquete'].costoTotal
      this.totalpagos = restante(this.sumaAcumulado,this.costoTotalPaquete);
      this.nombrePaquete = data['paquetes']['paquete'].nombrePaquete;
      this.obtenerSemanasPago();
    });
  }

  showMessageLab(examen: any){
    if(examen.numeroVisitas > 0){
      Swal.fire({
        title: '',
        html: `Tipo:  ${examen.consulta}\n  Fecha: ${examen.fecha} \n Hora: ${examen.hora}\n   Médico ${examen.medico}\n  Atendió: ${examen.firma}`,
      })
    }
  }

  showMessageConsulta(consulta: any){
    if(consulta.numeroVisitas > 0){
      Swal.fire({
        title: '',
        html: `Tipo:  ${consulta.consulta}\n  Fecha: ${consulta.fecha} \n Hora: ${consulta.hora}\n   Médico ${consulta.medico}\n  Atendió: ${consulta.firma}`,
      })
    }
  }

  agregarCarrito(name:string, item:any,numeroVisitas){
    let element;
    let validacion = validarConsultas(item,numeroVisitas);
    if(name == 'consultas'){
      element = cita(item)
      if(element != undefined){
        if(validacion){
          this.generarConsulta('consultas', element);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'SE HAN TERMINADO LAS CONSULTAS DE TU PAQUETE',
          })
        }
      }
    }else if(name == 'laboratorio'){
      element = laboratorios(item)
      if(element != undefined){
        if(validacion){
          this.generarConsulta('laboratorio', element);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'SE HAN TERMINADO LAS LABORATORIOS DE TU PAQUETE',
          })
        }
      }
    }else if(name == 'rayosX'){
      element = laboratorios(item)
      if(element != undefined){
        if(validacion){
          this.generarConsulta('rayosX', element);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'SE HAN TERMINADO LOS RAYOS X DE TU PAQUETE',
          })
        }
      }
    }else if(name == 'tomografia'){
      element = laboratorios(item)
      if(element != undefined){
        if(validacion){
          this.generarConsulta('tomografia', element);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'SE HAN TERMINADO LAS TOMOGRAFIAS DE TU PAQUETE',
          })
        }
      }
    }
  }

  obtenerCarrito (){
    this.carrito = getCarritoStorage();
    if ( this.carrito == null ){
        this.carrito = {
          totalSin: 0,
          totalCon: 0,
          items: []
        };
    }
  }

  eliminar(index,item){
    let carrito = new CarritoHorizonte();
    carrito.eliminar( index );
    this.obtenerCarrito();
    if (item.precioCon && item.precioCon) {
      let encontrar = this.consulta.findIndex(element => element.consulta == item.nombreEstudio)
      if (encontrar => 0) {
        this.consulta.forEach((element, index) => {
          if(index === encontrar){
            if (element.tipo == 'consultas') {
              this.citasIncluidas = restarConsultas(this.citasIncluidas,element)
            } else if(element.tipo == 'laboratorio'){
              this.laboratoriosIncluidas = restarConsultas(this.laboratoriosIncluidas,element)
            }else if(element.tipo == 'rayosX'){
              this.rayosx = restarConsultas(this.rayosx,element)
            }else if(element.tipo == 'tomografia'){
              this.tomografias = restarConsultas(this.tomografias,element)
            }
            this.consulta.splice( index, 1 )
          }
        })
      }
    }
  }

  obtenerSemanasPago(){
    let total = this.costoTotalPaquete-this.anticipo
    if(this.tabularpagos.length == 1){
      let array = generarSemanaDosPagos(total,this.selectSemanaPago)
      this.catidadSemanal = array[0]
      this.semanapago = array[1]
      this.pagoSemana = array[0]
      this.totalSemanas = array[2]
      this.selectSemanaPago = 1
    }else{
      let array = generarSemanaDosPagos(total,this.selectSemanaPago)
      this.catidadSemanal = array[0]
      this.semanapago = array[1]
      this.pagoSemana = array[0]
      this.totalSemanas = array[2]
      this.selectSemanaPago = 2
    }
  }

  async generarConsulta(tipoConsulta:string, item){
    const { value:medico } = await Swal.fire({
      title: 'DOCTORES',
      input: 'select',
      inputOptions: this.medicos,
      inputPlaceholder: 'Selecciona un doctor',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value != '') {
            resolve('');
          } 
        })
      }
    })
    if (medico) {
      if(tipoConsulta == 'consultas'){
        this.agregarConsulta(tipoConsulta, item, this.medicos.find((element:any, index)=> index == medico ))
      }else if(tipoConsulta == 'laboratorio'){
        this.agregarConsulta(tipoConsulta, item, this.medicos.find((element:any, index)=> index == medico ))
      }else if(tipoConsulta == 'rayosX'){
        this.agregarConsulta(tipoConsulta, item, this.medicos.find((element:any, index)=> index == medico ))
      }else if(tipoConsulta == 'tomografia'){
        this.agregarConsulta(tipoConsulta, item, this.medicos.find((element:any, index)=> index == medico ))
      }
    }
  }

  agregarConsulta(tipoConsulta:string, item, doctor:any){
    let consultas= {
      nombre:item.nombre,
      tipo: tipoConsulta, 
      consulta: item.ESTUDIO, 
      fecha: moment().format('l'), 
      hora:moment().format('hh:mm'), 
      medico:doctor, 
      firma: JSON.parse(localStorage.getItem('usuario')).nombre
    }
    this.consulta.push(consultas) 
    if (tipoConsulta == 'consultas') {
      agregarConsultaCarrito(this.citasIncluidas,consultas)
    } else if (tipoConsulta == 'laboratorio'){
      agregarConsultaCarrito(this.laboratoriosIncluidas,consultas)
    } if (tipoConsulta == 'rayosX') {
      agregarConsultaCarrito(this.rayosx,consultas)
    }if (tipoConsulta == 'tomografia') {
      agregarConsultaCarrito(this.tomografias,consultas)
    }
    let carrito = new CarritoHorizonte();
    carrito.agregarItem(this.pacienteInfo.membresiaActiva ,item.precioSin, item.precioCon ,item );
    this.obtenerCarrito();
  }

  actualizarConsulta(consultas:any){
    this._paquete.agregarConsulta(consultas,'servicios',this.id).subscribe((resp:any)=>{ 
      this.citasIncluidas = numeroVisitas(resp['data']['paquete'].consultas,resp['data'].consultas)
      this.laboratoriosIncluidas = numeroVisitas(resp['data']['paquete'].laboratorio,resp['data'].laboratorio)
      this.rayosx = numeroVisitas(resp['data']['paquete'].rayosX,resp['data'].rayosX)
      this.tomografias = numeroVisitas(resp['data']['paquete'].tomografia,resp['data'].tomografia)
    });
  }

  agregarPago(){
    let data = {
      acumulado: this.acumulado,
      gestacion: 0,
      pago: this.pagoSemana,
      semana: this.selectSemanaPago,
    }
    if(data.semana > this.totalSemanas){
      Swal.fire({
        icon: 'error',
        title: 'EXCEDE A LA LOS PAGOS PERMITIDOS',
      })
    }else{
      let index = this.sumaAcumulado.length
      let restante = this.costoTotalPaquete - this.sumaAcumulado[index-1]
      if(data.pago > restante){
        Swal.fire({
          icon: 'error',
          title: 'EXCEDE A LA CANTIDAD RESTANTE',
        })
      }else{
        this.generarPago(data);  
      }
    }
  }

  generarPago(data){
    this.spinner.show();
    this._paquete.agregarConsulta(data,'pagos',this.id).subscribe((resp:any)=>{
      this.generarVenta(data); 
      this.deposito="";
      this.tipoPago.tipo=""
      this.tabularpagos = resp['data'].pagos;
      this.sumaAcumulado = acumulados(this.tabularpagos)
      this.totalpagos = restante(this.sumaAcumulado,this.costoTotalPaquete);
      this.obtenerSemanasPago();
    }); 
  }

  generarVenta(data){
    const item = {
      name:this.nombrePaquete,
      nombreEstudio: "PAGO SEMANA "+data.semana,
      precioCon:data.pago,
      precioSin:data.pago,
    }
    this.carrito={
      totalSin: data.pago,
      totalCon: data.pago,
      items: []
    };
    this.carrito.items.push(item)
    const dates = new Dates();
    this.infoVenta.paciente = this.pacienteInfo._id,
    this.infoVenta.nombrePaciente=this.pacienteInfo.nombrePaciente,
    this.infoVenta.vendedor=JSON.parse(localStorage.getItem('usuario'))._id,
    this.infoVenta.hora= moment().format('LT'),
    this.infoVenta.estudios =this.carrito.items,
    /* this.infoVenta.montoEfectivo = data.pago, */
    this.infoVenta.sede=this.pacienteInfo.sede,
    this.infoVenta.totalCompra=data.pago,
    this.infoVenta.status ='Pagado',
    this.infoVenta.fecha = dates.getDate(),
    this.infoVenta.compraConMembresia = this.pacienteInfo.membresiaActiva
    if( this.infoVenta.efectivo ){
      this.infoVenta.montoEfectivo = data.pago;
    }else if( this.infoVenta.tarjetCredito ) {
      this.infoVenta.montoTarjteCredito = data.pago;
    }else if( this.infoVenta.tarjetaDebito ) {
      this.infoVenta.montoTarjetaDebito = data.pago;
    }else if( this.infoVenta.transferencia ) {
      this.infoVenta.montoTranferencia = data.pago;
    }
    this._pagoServicios.agregarPedido(this.infoVenta).subscribe((resp:any)=>{
      if(resp.ok){
        this.generarTicket(resp['folio']);
        this.carrito={
          totalSin: 0,
          totalCon: 0,
          items: []
        };
        this.infoVenta = {} as venta;
        this.spinner.hide();
        this.limpiarModal();
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL PAGO SE REALIZO CORRECTAMENTE',
        })
      }
    })
  }

  generarTicket(folio){
    const tickets = new Tickets();
    tickets.printTicketPagosPaquete( this.pacienteInfo, this.carrito ,this.comisionDebito,this.comisionCredito,  this.infoVenta, folio );
  }

  redireccion(){
    this.actualizarConsulta(this.consulta);
    this._router.navigateByUrl('/pago/servicios');
  }

  limpiarModal(){
    this.deposito = ''
    this.checked = {
      incluye: false,
      noincluye: false
    }
    this.tipoPago={
      tipo:""
    }
    this.comisionCredito = ''
    this.comisionDebito = ''
    this.infoVenta = {} as venta;
    this.obtenerSemanasPago();
  }

  efectivo(event){
    this.infoVenta.efectivo = event.target.checked
    if (this.infoVenta.efectivo == false) {
      this.infoVenta.montoEfectivo = 0;
    }
  }

  transfer(event){
    this.infoVenta.transferencia = event.target.checked
    if (this.infoVenta.transferencia == false) {
      this.infoVenta.montoTranferencia = 0;
    }
  }

  credito(event){
    this.infoVenta.tarjetCredito = event.target.checked
    if (this.infoVenta.tarjetCredito == false) {
      this.infoVenta.montoTarjteCredito = 0;
      this.comisionCredito='';
    }
  }

  debito(event){
    this.infoVenta.tarjetaDebito = event.target.checked
    if (this.infoVenta.tarjetaDebito == false) {
      this.infoVenta.montoTarjetaDebito = 0;
      this.comisionDebito='';
    }
  }

  agregarEfectivo(event){
    this.infoVenta.montoEfectivo = parseInt(event.target.value)
  }

  agregarTransfer(event){
    this.infoVenta.montoTranferencia = parseInt(event.target.value)
  }

  agregarIvaCredito(event){
    this.infoVenta.montoTarjteCredito = parseInt(event.target.value)
    this.comisionCredito = this.agregarIva(this.infoVenta.montoTarjteCredito);
  }

  agregarIvaDebito(event){
    this.infoVenta.montoTarjetaDebito = parseInt(event.target.value)
    this.comisionDebito = this.agregarIva(this.infoVenta.montoTarjetaDebito);
  }

  agregarIva(pago:any){
    let iva = 0.0;
    if( this.infoVenta.tarjetCredito  ){
      iva = .028;
    }
    if( this.infoVenta.tarjetaDebito ){
      iva = .018;
    }
    let totalIva = (( pago * iva ));
    return totalIva.toFixed(2);
  }

}
