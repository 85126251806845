<section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">EDITAR SERVICIO POR UTILIDAD</h1>
    </div>


</section>


<div class="card">
    <app-stepper-socio-editar *ngIf="servicios != 'ambulancia'"></app-stepper-socio-editar>
    <app-stepper-socio-editar-ambulancia *ngIf="servicios == 'ambulancia'"></app-stepper-socio-editar-ambulancia>
</div>
