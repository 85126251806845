<div class="container">

    <h1 class="title-header">CREAR SEGUIMIENTO</h1>

    <div class="card p-4 mt-4">
        <label class="title-secondary mb-2">DATOS DEL SEGUIMIENTO</label>
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5">DEPARTAMENTO</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="departamento" [(ngModel)]="seguimientoData.departamento">
                            <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                            <option value="Banco Sangre" >Banco Sangre</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">NOMBRE</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" name="nombreSeg" [(ngModel)]="seguimientoData.nombre">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">CATEGORIA</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="categoria"  disabled="disabled" [(ngModel)]="seguimientoData.categoria">
                            <option value="Pendiente">PENDIENTE</option>
                            <option value="Generado" selected>GENERADO</option>
                            <option value="Finalizado">FINALIZADO</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">SOLICITANTE</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" disabled [(ngModel)]="seguimientoData.solicitante">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5">DEPARTAMENTO A SOLICITAR</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="depaSolitud" [(ngModel)]="seguimientoData.depaASoli">
                            <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                            <option value="Cuidado Intensivo"> Cuidados Intensivos </option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">ID</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" disabled="disabled" name="idProveedor" [(ngModel)]="seguimientoData.id">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">SERVICIOS</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="servicios" [(ngModel)]="seguimientoData.servicios">
                            <option hidden selected>SELECCIONA UNA OPCIÓN</option>
                            <option value="Cirugia" > Cirugías</option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5">ESTADO</label>
                    <div class="col-sm-7">
                        <select class="form-control" name="estadoSegui" [(ngModel)]="seguimientoData.estado">
                            <option hidden selected>SELECCIONA UN ESTADO</option>
                            <option value="Urgente">Urgente</option>
                          </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <label class="col sm-3">DESCRIPCIÓN</label>
            <textarea class="form-control" name="descripcion" rows="3" (blur)="validarForm()" [(ngModel)]="seguimientoData.descripcion"></textarea>

            <div class="container mt-3">
                <div class="d-grid gap-2 col-2 mx-auto">
                    <button type="submit" [disabled]="btnValidacion" (click)="registrarSeguimiento()" class="btn btn-primary">AGREGAR</button>
                  </div>
            </div>
        </div>
    </div>

</div>
