import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
@Component({
  selector: 'app-ver-paciente',
  templateUrl: './ver-paciente.component.html',
  styleUrls: ['./ver-paciente.component.css']
})
export class VerPacienteComponent implements OnInit {
  
  // public fechaHoy =  moment().format('dddd Do MMM YYYY: hh:mm');
  public id = "";
  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    sede:""
  }
  public fecha;
  public paquetes: any [] = [];

  constructor(private _pacienteService: PacientesService,
              private _route: ActivatedRoute,
              public _router: Router,
              public _paquete:PaquetesService
              ){}
  
  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerPaciente();
  }


  obtenerPaciente(){
    this._pacienteService.getPacienteBtID(  this.id )
    .subscribe( (resp:any) => {
      this.setPaciente(resp.paciente)
    });
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
    let fechaR;
    fechaR = this.paciente.fechaRegistro
    this.fecha=fechaR.split('T')[0]
    this.guardarLocalStorage();
    this.paquete(this.id);
    /* this.buscarRepetidos(this.paciente.nombrePaciente,this.paciente.apellidoPaterno,this.paciente.apellidoMaterno); */
  }

  buscarRepetidos(nombres:any,apellidoPaterno:any,apellidoMaterno:any){
    let val = {
      nombre:nombres,
      paterno:apellidoPaterno,
      materno:apellidoMaterno
    }
    this._pacienteService.pacientesRepetidos(val).subscribe((resp:any)=>{
      console.log(resp);
      
    })
  }


  guardarLocalStorage(){
    if(localStorage.getItem('paciente') ){
     /* if(this.paciente.paquetes.length >= 1 || this.paciente.membresiaActiva == true ){
      this.paciente.membresiaActiva = true;
    } */
    localStorage.setItem('paciente', JSON.stringify(this.paciente));
      localStorage.removeItem('paciente');
    }
 }

  setRouteService(servicio){
    this._router.navigate([ `/serviciosInt/${servicio}`]);
  }

  paquete(id){
    this._pacienteService.getPaquetePaciente(id).subscribe((data: any) => {
      this.paquetes = data['data'];
      this.cancelarMembresia(this.paquetes);
      if(this.paquetes.length == 0){
        let estado = {
          membresiaActiva: false
        }
        this._paquete.actualizarEstadoMembresia( this.paciente._id, estado )
        .subscribe( (data:any) =>{
          localStorage.removeItem('paciente')
          localStorage.setItem('paciente', JSON.stringify(data.data))
        });
      }else{
        let estado = {
          membresiaActiva: true
        }
        this._paquete.actualizarEstadoMembresia( this.paciente._id, estado )
        .subscribe( (data:any) =>{
          localStorage.removeItem('paciente')
          localStorage.setItem('paciente', JSON.stringify(data.data))
        });
      }
    },err => {
      console.log(<any>err);
    });  
  }

  cancelarMembresia(paquetes:any){
    paquetes.forEach(element => {
      if(element.paquete.nombrePaquete != 'PAQUETE BABY & MOM' 
        && element.paquete.nombrePaquete != 'PAQUETE DE CONTROL PRENATAL HOSPITALARIO'
        && element.paquete.nombrePaquete != 'PAQUETE DE CONTROL PRENATAL'
        && element.paquete.nombrePaquete != 'PAQUETE DE CONTROL PRENATAL DE ALTO RIESGO'){  
        this.validarPaquete(element.fecha, element._id);
      }
    });
  }

  validarPaquete(fechaContratacion:any, id){
    let fechaTermino= new Date(fechaContratacion);
    let fechaActual = new Date();
    fechaTermino.setFullYear(fechaTermino.getFullYear()+1);
    if(fechaActual > fechaTermino){
      this.finalizarPaquete(id);
    }
  }

  finalizarPaquete(id){
    const body = {
      membresiaActiva: false
    }
    this._paquete.finalizarEstadoMembresia( id, body  )
    .subscribe( (data:any) => {
      this.paquete(data['data']._id);
    });
  }
}
