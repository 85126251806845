<form autocomplete="off" #form="ngForm">

    <div class="form-group row">
        <label class="col-3 col-form-label">RIESGO DE CAÍDA</label>

        <div class="form-group">
            <div class="form-check form-check-inline">
                <input type="radio" value="si" name="SI" [(ngModel)]="riesgo.valores" (change)="habilitarRiesgo()">
                <label class="form-check-label"> SI</label>
            </div>
            <div class="form-check form-check-inline">
                <input type="radio" value="no" name="NO" [(ngModel)]="riesgo.valores">
                <label class="form-check-label">NO</label>
            </div>
        </div>
    </div>


    <div *ngIf="evaluacion" class="card">
        <h5 class="text-center">EVALUACIÓN</h5>

        <div class="form-group row">
            <label class="col-3 col-form-label">TRANQUILIZANTES/SEDANTES</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="uno" id="r1" [(ngModel)]="rango.uno">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">DIURETICOS</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="dos" id="r2" [(ngModel)]="rango.dos">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">HIPOTENSORES</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="tres" id="r3" [(ngModel)]="rango.tres">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">ANTIPARKINSONIANOS</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="cuatro" id="r4" [(ngModel)]="rango.cuatro">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">ANTIDEPRESIVOS</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="cinco" id="r5" [(ngModel)]="rango.cinco">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">OTROS MEDICAMENTOS</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="seis" id="r6" [(ngModel)]="rango.seis">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">CONFUSO</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="siete" id="r7" [(ngModel)]="rango.siete">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">SEGURA CON AYUDA</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="ocho" id="r8" [(ngModel)]="rango.ocho">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">INSEGURA CON /SIN AYUDA</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="nueve" id="r9" [(ngModel)]="rango.nueve">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">ALTERACIONES VISUALES</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="diez" id="r10" [(ngModel)]="rango.diez">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">ALTERACIONES AUDITIVAS</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="once" id="r11" [(ngModel)]="rango.once">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 col-form-label">EXTREMIDADES</label>
            <div class="form-group">
                <div class="form-check form-check-inline">
                    <input type="radio" value="si" name="doce" id="r12" [(ngModel)]="rango.doce">
                </div>
            </div>
        </div>


        <div class="form-group row">
            <label class="col-5 col-form-label">&nbsp;</label>
            <div class="input-group col-md-12">
                <button (click)="monstrarRangoRiesgo()" type="button" class="btn btn-outline-info btn-block">
                Guardar
              </button>
            </div>
        </div>


    </div>


</form>