import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { antecedentesHF } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';

@Component({
  selector: 'app-antecedentes-hf',
  templateUrl: './antecedentes-hf.component.html',
  styleUrls: ['./antecedentes-hf.component.css']
})
export class AntecedentesHFComponent implements OnInit {

  @Input() _id='';
  @Input() antecedentesHeredoFamiliares:antecedentesHF = {} as antecedentesHF;

  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarAntecedentesHeredoFamimiares(form: NgForm){
    this.antecedentesHeredoFamiliares=form.value
    this.antecedentesHeredoFamiliares.idPaciente = this._id;
    this._HistoriaClinicaService.agregarHeredoFamiliares( this.antecedentesHeredoFamiliares )
    .subscribe((data:any) => {
      if(data['ok']){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE AGREGARON LOS ANTECEDENTES',
        })
      }
    })
  }

  actualizarHeredoFamiliares(form: NgForm){
    let id = this.antecedentesHeredoFamiliares._id
    this.antecedentesHeredoFamiliares = form.value;
    this.antecedentesHeredoFamiliares.idPaciente = this._id;
    this.antecedentesHeredoFamiliares._id = id;
    this._HistoriaClinicaService.actualizarHeredoFamiliaresEnElPaciente(this.antecedentesHeredoFamiliares._id, this.antecedentesHeredoFamiliares)
    .subscribe((data:any) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZARON LOS ANTECEDENTES HEREDO FAMILIARES',
        })
      }
    });
  }
}
