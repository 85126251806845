<div class="container-fluid">
  <p class="estilosxd">ORDEN DE SERVICIOS DE RECEPTORES</p>
  <div class="row">
    <div class="col-md-6">
        <input type="text" placeholder="Buscar"  required class="form-control" id="alineacion">
    </div>
   </div>
  <div class="row">
    <div class="col-md-12 col-lg-12">
        <div class="card">
            <div class="card-heder header">
                <div class="row">
                    <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                        <p>No.</p>
                    </div>

                    <div class="col-md-3 text_header">
                        <p>NOMBRE</p>
                    </div>
                    <div class="col text_header">
                        <p>id</p>
                    </div>
                    <div class="col text_header">
                        <p>EDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>GENERO</p>
                    </div>
                    <div class="col text_header">
                        <p>LUGAR DE ORIGEN</p>
                    </div>
                    
                </div>
            </div>

            <div class="card-body">
                <div>
                    <div class="row" id="pointer">
                        <div class="col-md-1" style="text-align: left;">
                            <p>
                                1
                            </p>
                        </div>
                        <div class="col-md-3" style="text-align: center;">
                            <p>
                                VICTOR MANUEL ARAGON CASTELLANOS
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                PEPRMS2034903MJRLS/01/04/CE
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                48 AÑOS
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                MASCULINO
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                PAQUETE MATERNO
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="row" id="pointer">
                        <div class="col-md-1" style="text-align: left;">
                            <p>
                                02
                            </p>
                        </div>
                        <div class="col-md-3" style="text-align: center;">
                            <p>
                               PEDRO PARAMO URZUA
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                PEPRMS2034903MJRLS/01/04/CE
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                48 AÑOS
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                MASCULINO
                            </p>
                        </div>
                        <div class="col" style="text-align: center;">
                            <p>
                                OTRO HOSPITAL
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>


        </div>
    </div>
 </div>

</div>