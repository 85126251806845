import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import {  getDataStorage  } from '../../../functions/storage.funcion';
/* import swal from 'sweetalert/dist/sweetalert.min.js'; */

@Component({
  selector: 'app-ficha-enfermeria',
  templateUrl: './ficha-enfermeria.component.html',
  styleUrls: ['./ficha-enfermeria.component.css']
})


export class FichaEnfermeriaComponent implements OnInit {


  public id: string;
  public fecha: string;
  public paciente  = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    religion:'',
    tipoDeSangre:''
  };
    

  constructor(private _HistoriaClinicaService: HistoriaClinicaService,
              private _route: ActivatedRoute,
              public _consultaService: ConsultaService,
              public _WsLoginService: WsLoginService){}

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.fecha = moment().format('l');
    this.obtenerConsultaPorId();
  }

  obtenerConsultaPorId(){
    this._HistoriaClinicaService.obtenerConsultaPorElId( this.id )
    .subscribe((resp:any) => { 
      /* this.paciente.nombre = data['data']['paciente']['nombrePaciente'];
      this.paciente.apellidoMaterno = data['data']['paciente']['apellidoMaterno'];
      this.paciente.apellidoPaterno = data['data']['paciente']['apellidoPaterno'];
      this.paciente.genero = data['data']['paciente']['genero'];
      this.paciente.edad = data['data']['paciente']['edad'];
      this.paciente.fechaNacimiento = data['data']['paciente']['fechaNacimientoPaciente'];
      this.paciente.paisPaciente = data['data']['paciente']['paisPaciente'];
      // this.paciente.estadoPaciente = data['data']['paciente']['estadoPaciente'];
      this.paciente.callePaciente = data['data']['paciente']['callePaciente'];
      this.paciente.curp = data['data']['paciente']['curp'];
      this.paciente.telefono = data['data']['paciente']['telefono'];
      this.paciente.cpPaciente = data['data']['paciente']['cpPaciente'];
      this.paciente.idPaciente = data['data']['paciente']['_id'];
      this.paciente.idMedicinaPreventiva = data['data']['paciente']['medicinaPreventiva'];
      this.paciente.idAntecedentesHeredoFam = data['data']['paciente']['antecedentesHeredoFamiliares'];
      this.paciente.consultas = data['data']['paciente']['consultas']; */
      this.setPaciente(resp['data'].paciente)
    });
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  } 
}

