import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';


@Component({
  selector: 'app-entrega-productos-sanguineos',
  templateUrl: './entrega-productos-sanguineos.component.html',
  styleUrls: ['./entrega-productos-sanguineos.component.css']
})
export class EntregaProductosSanguineosComponent implements OnInit {

  constructor() { }

  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    direccion: {
      estadoPaciente:"",
      callePaciente:"",
      paisPaciente:"",
      cp:""
    },
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    correo: "",
    cp:"",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    receptor: "",
    tipoDeSangre: "",
    disponente: "",
  }
  public fecha = moment().format('LLL');


  ngOnInit(): void {
    /* console.log(this.fecha); */
    
  }

  etiquetaPorUrgencia() {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [8, 1.2]
    });

    doc.setFontSize(8);
    doc.setTextColor(255, 0, 0);
    doc.text("PRODUCTOS SANGUINEOS SIN PRUEBAS CRUZADAS", .1, .5);
    doc.text("POR LA SOLICITUD DE URGENCIA", .1, 1)
    doc.save("etiqueta_por_urgencia.pdf")
  }

  imprimirDeclaratoria() {

    const doc = new jsPDF();

    doc.setFontSize(20);
    doc.setTextColor(100);
    doc.text('Entrega de Productos Sanguineos ', 15, 25);

    doc.setFontSize(12)
    doc.setTextColor(0, 0, 255)
    doc.text('Nombre Completo: ', 15, 35);
    doc.text('Edad: ', 105, 35);
    doc.text('Género: ', 145, 35);
    doc.text('Domicilio: ', 15, 45);
    doc.text('Fecha Nacimiento: ', 105, 45);
    doc.text('Lugar Nacimiento: ', 15, 55);
    doc.text('Teléfono: ', 105, 55);
    doc.text('ID: ', 155, 55);

    doc.setTextColor(0,0,0);
    doc.text("YO______________________________ RECIBO PRODUCTOS HEMATICOS PARA EL", 25, 80)
    doc.text("PACIENTE _________________________. HE RECIBIDO INFORMACION AL RESPECTO", 25, 100);
    doc.text("Y FIRMO AL CALCE.", 25, 120);
    doc.text("FIRMA: ________________________.", 25, 140);

    doc.text(this.fecha, 25, 285)

    doc.save("declaratoria_responsiva.pdf");
  }

}
