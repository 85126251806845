import { Component, Input, OnInit } from '@angular/core';
import { antecedentesGO } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-antecedentes-gineco-obs',
  templateUrl: './antecedentes-gineco-obs.component.html',
  styleUrls: ['./antecedentes-gineco-obs.component.css']
})
export class AntecedentesGinecoObsComponent implements OnInit {

  @Input() _id='';
  @Input() antecedentesGinecoObstetricosHistoria:antecedentesGO = {} as antecedentesGO;

  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarGinecoObstetricosHistoria(form: NgForm){
    this.antecedentesGinecoObstetricosHistoria = form.value;
    this.antecedentesGinecoObstetricosHistoria.idPaciente = this._id;    
    this._HistoriaClinicaService.agregarGinecoObstetricosHC( this.antecedentesGinecoObstetricosHistoria )
    .subscribe((resp:any) =>{
        if (resp['ok']) {
           Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS ANTECEDENTES GINECO OBSTETRICOS SE GUARDARON CORRECTAMENTE',
          })
        }
    });
  }

  actualizarGinecoHistoria(form: NgForm){
    let id = this.antecedentesGinecoObstetricosHistoria._id
    this.antecedentesGinecoObstetricosHistoria = form.value;
    this.antecedentesGinecoObstetricosHistoria.idPaciente = this._id; 
    this.antecedentesGinecoObstetricosHistoria._id = id;
    this._HistoriaClinicaService.actualizarGinecoH(this.antecedentesGinecoObstetricosHistoria._id, this.antecedentesGinecoObstetricosHistoria)
    .subscribe((resp:any) => {
      if(resp['ok']){
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE ACTUALIZARON LOS ANTECEDENTES',
        })
      }
    })
  }

}
