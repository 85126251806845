import { Component, OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-parejas-sexuales',
  templateUrl: './parejas-sexuales.component.html',
  styleUrls: ['./parejas-sexuales.component.css']
})
export class ParejasSexualesComponent implements OnInit {

  @Output() parejasSexuales = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  formularioParejas(form){
    this.parejasSexuales.emit(form.form.value);
  }

}
