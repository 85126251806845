import { Component, OnInit } from '@angular/core';
import { AgendaService } from 'src/app/services/agenda/agenda.service';
import { PersonalInterface } from '../../../interfaces/personal.interface';

@Component({
  selector: 'app-agenda-personal',
  templateUrl: './agenda-personal.component.html',
  styleUrls: ['./agenda-personal.component.css']
})
export class AgendaPersonalComponent implements OnInit {

  public usuario:PersonalInterface;
  public id = '';
  public citas:any [] = [];
  public events = [];

  constructor(private _agenda:AgendaService) {}

  ngOnInit(): void {
    const {medico, Especialidad, cedulaProfesional, cedulaEsp, sede, universidad, _id} = JSON.parse(localStorage.getItem('usuario'));
    this.id = _id;
    this.usuario = new PersonalInterface(medico,Especialidad,cedulaProfesional,cedulaEsp,sede,universidad);
    this.agendaPersonal();
  }

  agendaPersonal(){
    this._agenda.obtenerAgendaPersonal(this.id).subscribe(resp=>{
      resp['data'].forEach((elemen: any) => {
        let data = {
          a: elemen.fechaInicio,
          b: elemen.horaInicio,
          c: elemen.fechaFin,
          d: elemen.horaFin
        }
        let { inicio, fin } = this.OutDate(data),
          eventos = {
            id: elemen._id,
            title: elemen.idServicio.ESTUDIO.concat(': '+elemen.idPaciente.nombreCompletoPaciente),
            start: inicio,
            end: fin,
            color: elemen.color,
          };
          this.events.push(eventos)
        });
        console.log(this.events);
        
      // this.calendarOptions.events = this.events
    })
  }

  OutDate(data:any) {
    const { a, b, c, d } = data
    const valores = {
      inicio:'',
      fin:''
    }
    if (a != null && b != null) {
      valores.inicio = a.toString() + "T" + b.toString() + ":00";
      valores.fin = c.toString() + "T" + d.toString() + ":00";
      return valores;
    }
  }

}
