import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { PacientesService  } from '../../../../services/pacientes/pacientes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-bitacora-historico',
  templateUrl: './bitacora-historico.component.html',
  styleUrls: ['./bitacora-historico.component.css']
})
export class BitacoraHistoricoComponent implements OnInit {
  public consultas:any [] = [];
  public totalAmbulancia: string;
  public noSe=[];
  public pagina = 0;
  public totalpaciente:string;
  public imprimir = {
    indice: 0,
    fecha: '',
    nombre:  '',
    genero: '',
    edad: '',
    medico: '',
  }
  public listaEspera = [];
  public getPacienteSotageX = this.listaEspera;
  public filterPost = '';
  public sede =  "";
  public idMedico ="";

  //json de para obtener los datos de la bitacora

  public dataJsonHistory = {
    medicoTrante:"",
    sede:""
  }
  constructor(private _consultasService: ConsultaService,
              private spinner: NgxSpinnerService,) {}

  ngOnInit(): void {
    this.spinner.show();
    this.obtenerSede();
    this.obtenerConsultas();
  }

  obtenerSede () {
    this.sede = JSON.parse( localStorage.getItem('cede') );
    this.dataJsonHistory.sede= JSON.parse( localStorage.getItem('cede') )
    this.dataJsonHistory.medicoTrante = JSON.parse(localStorage.getItem('usuario')).nombre
    this.idMedico = JSON.parse(localStorage.getItem('usuario'))._id
  }

  obtenerConsultas(){
    this._consultasService.obtenerBitacoraConsultas(this.idMedico, this.dataJsonHistory)
    .subscribe( (data:any) => {
      if(data.ok) {
        this.setconsultas( data['data']);
        this.totalpaciente = data['data'].results;
      }
    });
  }

  setconsultas( consultas ){
    consultas.forEach(element => {
      if(element.paciente != null){
        this.consultas.push(element)
      }
    });
    this.consultas.reverse();
    /* this.consultas = consultas.reverse(); */
    this.setImp(this.consultas);
    this.spinner.hide();
  }

  setImp(cons){    
    let i= 0;
    for (let element of cons) {   
      this.imprimir.indice= i + 1;
      this.imprimir.fecha = element.fechaIngreso;
      this.imprimir.nombre = element.paciente.nombrePaciente;
      this.imprimir.genero = element.paciente.genero;
      this.imprimir.edad = element.paciente.edad;
      this.imprimir.medico = element.medicoTrante;
      
      this.noSe.push(this.imprimir)
      this.imprimir = {
        indice: 0 ,
        fecha: '',
        nombre:  '',
        genero: '',
        edad: '',
        medico: '',
      }
      i++
    }    
  }

  imp(){
    let values: any;
    values = this.noSe.map((elemento) => Object.values(elemento));
    const doc:any = new jsPDF();
    doc.autoTable({
      head: [['#', 'Fecha', 'Nombre', 'Género', 'Edad', 'Médico Tratante']],
      body: values
    })
    doc.save('Historico_De_Pacientes.pdf')
  }
}
