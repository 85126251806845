import { Component, Input} from '@angular/core';
import {  FichaInfo } from '../../../../app/classes/ficha-info-paciente';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';


@Component({
  selector: 'ficha-info-user',
  templateUrl: './ficha-info-user.component.html',
  styleUrls: ['./ficha-info-user.component.css']
})
export class FichaInfoUserComponent{

  @Input() pacienteInfo: FichaInfo;
  @Input() title: true;
  @Input() rol='';
  public id = ""
  public fecha:Date;
  public infPaciente = {
    esquemaVacunacion:""
  }

  constructor(public _paquete:PaquetesService,
              private _banco:BancoService){}

  relig(event){ 
    let rel = {
      idpaciente:this.pacienteInfo._id,
      religion:event
    }
    this._banco.actualizarReligion(rel).subscribe((resp:any)=>{
    })
  }

  actualizarSangre(event){
    let rel = {
      idpaciente:this.pacienteInfo._id,
      tipoDeSangre:event
    }
    this._banco.actualizarTipoSangre(rel).subscribe((resp:any)=>{
    })
  }

}
