import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { filterResultados } from 'src/app/classes/buscarServicos/buscarServicos'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-regresos-lab',
  templateUrl: './regresos-lab.component.html',
  styleUrls: ['./regresos-lab.component.css']
})
export class RegresosLabComponent implements OnInit {

  public totalpaciente:string;
  public pagina = 0;
  public consultas:any=[];
  noSe: any;
  public pedidosLab = [];
  public pedidosAux = [];
  private subcription: Subscription;
  private subcription2$ : Subscription;
  public buscar = '';

  constructor(public _consultaService: ConsultaService,
              public _consultaPaciente: PacientesService,
              private spinner: NgxSpinnerService,
              private _serviciosService: ServiciosService) {}

  ngOnInit(): void {
    /* this.obtenerCosnultaLab(); */
    this.getBitacoraLabs();
  }
/*
  ngOnDestroy(): void {
    this.subcription.unsubscribe();
   // this.subcription2$.unsubscribe();
  }
  */
  /* obtenerCosnultaLab(){
    this._consultaService.verListaLaboratorio().subscribe( (data) =>   {
        this.consultas = data['data'].reverse();
        this.totalpaciente = data['data'].results;
        this.spinner.hide();
    });
  } */

  getBitacoraLabs() {
    this.spinner.show()
    // this.subcription.add(
    this._serviciosService.getBitacoraLabs('LABORATORIO')
      .subscribe(
        (data) => {
          this.pedidosLab = this.setRegresos(data['data'])
          this.totalpaciente = data['data'].results
          this.pedidosAux = data['data'];
          this.spinner.hide();
        }
      )
    // )
  }

  setRegresos(laboratorios:any){
    laboratorios.forEach(element => {
      if (element.idPaciente != null) {
        this.pedidosLab.push(element)
      }
    });
    var found ={};
    var resp = this.pedidosLab.filter(function(element){
      return found.hasOwnProperty(element.idPaciente._id)? false : (found[element.idPaciente._id]=true);
    });
    return resp.reverse();
  }

  busquedaGeneral(event:any){
    if (event.target.value == '') {

      this.pedidosLab = this.pedidosAux;

    } else if (event.target.value.length >= 3){
      // this.subcription.add(
      
      this._serviciosService.obterPedidosFinalizadosPorNombrePaciente( {servicio: 'laboratorio', nombre: event.target.value} )
        .subscribe( (data:any) => {
          if(data.ok) this.pedidosLab = data.data
        })
      // )
      // this.pedidosLab = filterResultados(this.pedidosLab, event.target.value)
    }
  }

  buscarEstudio(){
    this._serviciosService.obterPedidosFinalizadosPorNombrePaciente( {servicio: 'laboratorio', nombre: this.buscar} )
        .subscribe( (data:any) => {
          if(data.ok) this.pedidosLab = data.data
        })
  }

  imp(){
    let values: any;
    values = this.noSe.map((elemento) => Object.values(elemento));
    const doc:any = new jsPDF();
    doc.text(12, 9, "BITÁCORA DE RAYOS X");
    doc.autoTable({
      head: [['#', 'Fecha', 'Nombre', 'Edad', 'Sexo', 'Sede', 'Estudio']],
      body: values
    })
    doc.save('Bitácora_De_Rayos_X.pdf')
  }
 
}
