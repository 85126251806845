import { Component, OnInit } from '@angular/core';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';


@Component({
  selector: 'app-bitacora-patologia',
  templateUrl: './bitacora-patologia.component.html',
  styleUrls: ['./bitacora-patologia.component.css']
})
export class BitacoraPatologiaComponent implements OnInit {

  public patologia = [];
  public filtroPatologia = '';
  public filterPost = '';
  public patologias = [];

  public id = "";

  constructor(
    private _patologiaService: PatologiaService,

  ) { }

  ngOnInit(): void {
    // this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerPatologias();
  }


  obtenerPatologias() {
    this._patologiaService.verPedidosPatologia('PEDIDO')
    .subscribe((data:any) => this.setPatologias(data.data));
  }

  setPatologias(data) {
    this.patologias = data;
  }

  typeSearchPathology(){
    
    if (this.filtroPatologia.length >3 ) {
            
    }
  }

}
