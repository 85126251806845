<div class="">
    <div class="row">
        <div class="title-card">
            <h2 class="mt-3 font-color" >BIOMETRÍA HEMÁTICA COMPLETA</h2>
        </div>
    </div>

    <div class="row letras mt-3 mb-3">
        <div class="col-md-4">
            <h3>elementos</h3>
        </div>
        <div class="col">
            <h3>Resultados Obtenidos</h3>
        </div>
        <div class="col">
            <h3>valores de referencia</h3>
        </div>
    </div>

    <!-- <form #f='ngForm' [formGroup]="cont"> -->
        <div  *ngFor="let estudio of valoresEstudios.valoresDeReferencia; let i = index" >
            <!-- container de todos los rows -->
            <div class="row letras">
                <div class="col-md-4">
                    <h3>{{  estudio.elemento }}</h3>
                </div>
                <div class="col-md-4">
                    <h3 style="text-align: center;">{{  estudio.obtenido }}</h3>
                    <!-- <input class="res input-shadow" 
                           style="width: inherit !important;" 
                           [value]="estudio.obtenido"  
                           [name]="estudio.elemento+i" 
                           [formControlName]="estudio.elemento+i" 
                           [(ngModel)]="estudio.obtenido"  
                           type="text" 
                           (blur)="ngSubmitForm(f)"/> -->
                </div>
    
                <div class="col-md-4" *ngFor="let valoresRef of estudio.valorDeReferencia" >
                    <h3>{{ valoresRef.valorReferenciaD}}</h3>  
                </div>
            </div>
        </div>
    <!-- </form> -->
   

    <!-- <div class="center mt-4">
        <button class="btn-censur"  (click)="ngSubmitForm( f )"  >
            GUARDAR
        </button>
    </div> -->

    <!-- <div class="boton">
        <button class="principalButton" (click)="ngSubmitForm(f)"> EDITAR </button>
    </div> -->

</div>



<!-- <div class="content">
    <form #form='ngForm'>
        <div class="row">
            <div class="col">
                <p> MUCOSAS </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="mucosas" id="mucosas" class="form-control" ngModel (blur)="formularioExploracion(form)">
                        <option value="DATOS PATOLOGICOS" > Datos Patológicos </option>
                        <option value="SIN DATOS PATOLOGICOS">  Sin Datos Patológicos  </option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</div> -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>