<section class="container">
    <div class="row">
        <div class="col-8">
            <!-- <h1 class="centerxd">AGREGAR CONSULTAS GENERALES</h1> -->
            <app-titulos [title]="'AGREGAR CONSULTAS GENERALES'" [rol]="'consulta'" ></app-titulos>
        </div>

        <div class="col-2">
            <div class="btnNuevo">
                <button type="button" class="btn btn-secondary boton_contra2" data-toggle="modal" data-target="#exampleModal">
                    NUEVA CITA</button>
            </div>
        </div>
        <div class="col-md-2">
            <button class="secondaryButton" [routerLink]="['/consultas', 'general']"> VER CONSULTAS </button>
        </div>
    </div>
</section>


<section class="container">
    <div class="row center" style="justify-content: center;">
        <div class="col-md-3">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">Consultorio</h5>
                    <h2 class="card-title">1</h2>
                    <p class="card-text">Medicina general</p>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">Consultorio</h5>
                    <h2 class="card-title">2</h2>
                    <p class="card-text">Cirugía plástica</p>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card consultorioxd">
                <div class="card-body">
                    <h5 class="card-text">Consultorio</h5>
                    <h2 class="card-title">3</h2>
                    <!-- <h5 class="card-text">Ficha: </h5> -->
                    <p class="card-text">Pediatria</p>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card consultorioxd">
                <div class="card-body">
                    <h5 class="card-text">Consultorio</h5>
                    <h2 class="card-title">4</h2>
                    <!-- <h5 class="card-text">Ficha: </h5> -->
                    <p class="card-text">Ginecología</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="cardxd">
    sala de espera
</div>

<section class="container">
    <div class="row center" style="justify-content: center;">
        <div class="col-md-3">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <!-- <h5 class="card-text">Ingreso:</h5>
                    <h3 class="card-title">Lucia Mendez </h3> -->
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <h5 class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioUno'">{{ listaEsperaP.paciente.nombrePaciente }} {{ listaEsperaP.paciente.apellidoMaterno }} {{ listaEsperaP.paciente.apellidoPaterno }} </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <!-- <h5 class="card-text">Ingreso:</h5>
                    <h3 class="card-title">Lucia Mendez </h3> -->
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <p class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioDos'">{{ listaEsperaP.paciente.nombrePaciente }}  {{ listaEsperaP.paciente.apellidoPaterno }}  {{ listaEsperaP.paciente.apellidoMaterno }} </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <!-- <h5 class="card-text">Ingreso:</h5>
                    <h3 class="card-title">Lucia Mendez </h3> -->
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <h5 class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioTres'">{{ listaEsperaP.paciente.nombrePaciente }}  {{ listaEsperaP.paciente.apellidoPaterno }}  {{ listaEsperaP.paciente.apellidoMaterno }} </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <!-- <h5 class="card-text">Ingreso:</h5>
                    <h3 class="card-title">Lucia Mendez </h3> -->
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <h5 class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioCuatro'">{{ listaEsperaP.paciente.nombrePaciente }}  {{ listaEsperaP.paciente.apellidoPaterno }}  {{ listaEsperaP.paciente.apellidoMaterno }} </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="modal fade nombrePendejoParaEcontrarEsteDivDeMierdaxD" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <section class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="modal-title cardxd" id="exampleModalLabel">NUEVA CITA</h5>
                            </div>
                        </div>
                    </section>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <input #nombre (keyup)="buscarPaciente(nombre.value)" type="text" name="busquedaPaciente" class="form-control input-search" id="busquedaPaciente" placeholder="Buscar paciente...">
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <section class="modal-header">
                        <div class="row">
                            <div class="col-md-12 pacietes-select">
                                <table class="table-search">
                                    <tr *ngFor="let item of AllPacientes">
                                        <td class="puntero" (click)="seleccionarPaciente( item )"> 
                                                <strong>
                                                    {{ item.nombrePaciente }} {{ item.apellidoPaterno }} {{ item.apellidoMaterno }}
                                                </strong>
                                            {{  item.curp }} </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </section>
                    <form>
                        <div class="form-group">
                            <label for="nombrePacienteIdentificado" class="col-form-label">Paciente:   </label> {{ paciente.nombrePaciente }} {{ paciente.apellidoPaterno }} {{ paciente.apellidoMaterno }}
                            <br>
                            <div class="containerFI">
                                <label for="curp"> CURP: {{ paciente.curp }}  </label>

                            </div>

                        </div>
                        <div class="form-group">
                            <label for="consultorio" class="col-form-label">Consultorio:</label>
                            <select class="selectStyle form-control" [(ngModel)]="paciente.consultorio" name="consultorio" id="consultorio">
                                <option value=""  selected>Seleccione Consultorio</option>
                                <option value="consultorioUno">Consultorio 1</option>
                                <option value="consultorioDos">Consultorio 2</option>
                                <option value="consultorioTres">Consultorio 3</option>
                                <option value="consultorioCuatro">Consultorio 4</option>
                                <option value="urgencias "> Urgencias </option>
                                <option value="quirofano"> Quirofano </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="servicio" class="col-form-label">Servicio:</label>
                            <select class="selectStyle form-control" [(ngModel)]="paciente.servicio" name="servicio" id="servicio">
                                <option selected value="">Seleccione Servicio</option>                            
                                <option selected [value]="consulta.name"  *ngFor="let consulta of serviciosConsultasSelect" > {{ consulta.name  }} </option>
                                
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="doctorAPasar"> Médico </label>
                            <select name="doctorAPasar" class="selectStyle form-control" [(ngModel)]="paciente.doctorAPasar" name="doctorAPasar" id="doctorAPasar">
                                <option value="" selected >  Selecciona un médico </option>
                                <option value="Alejandro Vargas" > Alejandro Vargas </option>
                                <option value="Angeles Jazmín Pedraza Banda"> Angeles Jazmín Pedraza Banda </option>
                                <option value="Arturo Enríquez"> Arturo Enríquez </option>
                                <!-- <option value="Carmen Pérez">  Carmen Pérez Toral</option> -->
                                <option value="David Reyes">  David Reyes </option>
                                <option value="Erick Alarcón"> Erick Alarcón </option>
                                <option value="Felipe Maciel"> Felipe Maciel </option>
                                <option value="Jesús Olguin"> Jesus Olguin Perez </option>
                                <option value="Raul Ortiz"> Ramón Arteaga </option>
                                <option value="Rafael Gomez"> Rafael Gomez </option>
                                <option value="Raul Ortiz"> Raul Ortiz </option>
                                <!-- <option value="Karina Jiménez"> Karina Jiménez </option>
                                <option value="Laura Morales"> Laura Morales </option> -->
                                <!-- <option value="Ricardo"> Ricardo </option> -->
                                <option value="Mario Rosique"> Mario Rosique Martinez </option>
                                <!-- <option value="Marcos Flores Guapo"> Marcos Flores Guapo </option> -->
                                <option value="Víctor Guerrero"> Víctor Guerrero </option>
                                <option value="Yuri Segura"> Yuri Segura </option>
                                <option value="Beatriz López de la Cruz">Beatriz López de la Cruz</option>
                                <option value="Mario Javier Martínez Rosique">Mario Javier Martínez Rosique</option>
                                <option value="Karina Jimenez Valdepeña">Karina Jimenez Valdepeña</option>
                                <option value="ALEXANDRA MORALES SOSA">Alexandra Morales Sosa</option>
                                <option value="MARCO ANTONIO HERNÁNDEZ JAHÉN">Marco Antonio Hernández Jahén</option>
                                <option value="JESUS JHOVANNY PEREZ MONTESINOS">JESUS JHOVANNY PEREZ MONTESINOS</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="motivoIngreso" class="col-form-label">Motivo de ingreso: </label>
                            <input type="text" class="form-control" id="motivoIngreso" [(ngModel)]="paciente.motivoIngreso" name="motivoIngreso">
                        </div>

                        <div class="row dupla center">
                            <div class="col-md">
                                <label for="">Fecha  de ingreso {{ fechaIngreso }}   </label>
                                <label for="hora">Hora: {{ horaIngreso}}  </label>
                            </div>

                        </div>


                        <div class="form-group">
                            <label for="message-text" class="col-form-label">Nota:</label>
                            <textarea class="form-control" id="message-text" name="notaRecepcion" [(ngModel)]="paciente.notaRecepcion"></textarea>
                        </div>
                        
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="boton2" data-dismiss="modal">Cerrar</button>
                    <button type="button" class="boton" (click)="enviar()" data-dismiss="modal">   Enviar </button>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>