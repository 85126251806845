import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';

@Component({
  selector: 'app-dashboard-resultados',
  templateUrl: './dashboard-resultados.component.html',
  styleUrls: ['./dashboard-resultados.component.css']
})
export class DashboardResultadosComponent implements OnInit {


  public id: string
  public pedido  = {
    estadoPedido: "",
    estudios: [{}], 
    _id: "", 
    idPaciente:"", 
    fecha: "", 
    hora: "",
  };

  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

  public totalpaciente:string;
  public pagina = 0;

  constructor( public _consultaService: ConsultaService,private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerPedidoLab()
  }


  grabarLocalStorage(){
    localStorage.setItem('estudio',JSON.stringify( this.id));
    
  }

  grabaridPaciente(){
    localStorage.setItem('idPedido',this.pedido._id.trim())
    localStorage.setItem('idPaciente',JSON.stringify(this.pedido.idPaciente))
  }



  obtenerPedidoLab(){
    this._consultaService.verPedidosLaboratorio(this.id)
    .subscribe( (data) =>   {
      
      this.pedido = data['data']
      this.datosPaciente(data ['data']['idPaciente'])
      this.totalpaciente=data['data'].results;
      // localStorage.getItem( JSON.stringify( this.grabaridPaciente) )
      this.grabaridPaciente()
      
    });
  }
 
  datosPaciente(paciente ){
 this.paciente=paciente
 /* console.log(this.paciente) */
  }

  verEstudio(nombre:string)  {
    localStorage.setItem('nombreEstidio', nombre );

  }

}
