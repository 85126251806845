import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterResultados } from 'src/app/classes/buscarServicos/buscarServicos';
import { ServiciosService } from 'src/app/services/admin/servicios.service';

@Component({
  selector: 'app-bitacora-endoscopia',
  templateUrl: './bitacora-endoscopia.component.html',
  styleUrls: ['./bitacora-endoscopia.component.css']
})
export class BitacoraEndoscopiaComponent implements OnInit {

  public consultas:any=[]
  public totalpaciente:string;
  public pagina = 0;
  public pedidosEndos= [];
  public pedidosEndoscopias = [];

  constructor(private spinner : NgxSpinnerService,
              private _servicios: ServiciosService) { }

  ngOnInit(): void {
    this.spinner.show()
    this.obtenerCosnultaEndos()
  }

  obtenerCosnultaEndos(){
    this._servicios.getBitacoraLabs('endoscopia')
    .subscribe((data:any) => {
      if( data.ok ) {
        this.consultas = this.setRegresos( data.data )
        this.spinner.hide();
      }
    })
  }

  busquedaGeneral(event:any){
    if (event.target.value == '') {
      this.consultas = this.pedidosEndoscopias;
    } else if (event.target.value.length >= 3){
      this.consultas = filterResultados(this.pedidosEndoscopias, event.target.value)
    }
  }

  setRegresos(endosco:any){
    endosco.forEach(element => {
      if (element.idPaciente != null) {
        this.consultas.push(element)
      }
    });
    var found ={};
    var resp = this.consultas.filter(function(element){
      return found.hasOwnProperty(element.idPaciente._id)? false : (found[element.idPaciente._id]=true);
    });
    this.pedidosEndoscopias = resp;
    return resp.reverse();
  }

// setDataUltras(data:any) {
//   console.log(data);
  
//   this.consultas = data.reverse();
// }

}
