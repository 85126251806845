import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute} from '@angular/router';
/* import { gaurdarCotizacion } from '../../../../functions/storage.funcion'; */
import { getDataStorage  } from '../../../../functions/storage.funcion';
import  Carrito  from '../../../../classes/carrito/carrito.class';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
import { eliminarStorage, getCarritoStorage } from 'src/app/functions/pacienteIntegrados';
import { Subject } from 'rxjs';
import Tickets from 'src/app/classes/tickets/ticket.class';

@Component({
  selector: 'app-ambulancia',
  templateUrl: './ambulancia.component.html',
  styleUrls: ['./ambulancia.component.css']
})
export class AmbulanciaComponent implements OnInit, OnDestroy {

  // data de los servicios
  @Input() ambulanciaSI: any [] = [];
  @Input() showAmbulanacia2=true;

  public totalAmbulancia: string;
  public termino: string;
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showOtrosService= false;
  public servicios:string;
  public serviceSi: any [] = [];
  public membresia = false;
  public preciosMembresia=[];
  public roleUser = "";
  public searching = false;

  public btnPago = true;
  public btnImpPago = false;

  private unsubscribe$ = new Subject<void>();

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  }; 

  public buscar = {
    estudio:''
  }
  public sedePaciente = '';
  public dataSedePaciente = {
    idSede:'', 
    name:'', 
    idItem:''
  }
  public idPedidoSede;

  constructor(private _service: IntegradosService, 
              private _router: Router, 
              private activatedRoute: ActivatedRoute
              ){}

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.obtenerRoleUser();
    // this.verDatos();
    this.obtenerPacienteYMembresia();
    this.obtenerCarritoStorage();
    this.obtenerSedePaciente();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  obtenerSedePaciente(){
    this.sedePaciente= localStorage.getItem('sedePaciente');
    if(this.sedePaciente == ''){
      this.sedePaciente = ''
    }
  }


  obtenerCarritoStorage(){
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  obtenerRoleUser(){
    this.roleUser = getDataStorage().role;
    /* console.log( this.roleUser ) */
  }


  obtenerPacienteYMembresia(){
    // se obtienen los precios con membresia 
    let usuario = JSON.parse( localStorage.getItem('paciente'));
    if( usuario == null ){
      this.btnPago = false;
      this.btnImpPago = true;
      this.verDatos();      
    }else {
        this.btnImpPago = false;
        this.membresia = usuario.membresiaActiva;
        this.verDatos(); 
        this.btnPago = true;
    } 
      /* console.log( this.membresia ); */ 
  }

  busquedaGeneral(){
    if(this.buscar.estudio.length > 3){
      this._service.getAllDepartments(this.buscar)
      .subscribe((res:any) => {
        //console.log( res );
        if(res.data[0]!=0){
          this.ambulanciaSI = res.data[0];
        }else{
          this.showTableAmbulanacia = false;
          this.showOtrosService= true;
          this.serviceSi = res.data[1];           
          this.searching = true; 
        }            
      });
    }else if(this.buscar.estudio == ''){
      this.searching = false;
      this.showOtrosService= false;
      this.showTableAmbulanacia = true;
      this.obtenerCarritoStorage();
      this.verDatos();
    }
  }

  imprimirCotizacion(carrito){
    this.generarTicket(carrito);
  }

  generarTicket(carrito){
    const tickets = new Tickets();
    tickets.imprimirCotizacion(carrito);
    eliminarStorage();
    this.obtenerCarritoStorage();
  }


  agregarCarrito( event, item: any ) {
    /* console.log(event); */
    let carrito = new Carrito();
    this.carrito = carrito.agregarItem(  event, item );
    this.validarCarrito();
  }

  eliminar(index){
    //console.log('Pedido', pedido);
    let carrito = new Carrito();
    carrito.eliminar( index );
    this.obtenerCarritoStorage();
    this.validarCarrito();
  }
  // le sumamos los precios al carrito con membresia 
  verDatos(){
      this._service.getDestino(this.servicios).subscribe(
        (res: any) => {
            this.setDatos( res['data']  );
            this.totalAmbulancia = res['data'].results;
        },
      err => {
          /* console.log(<any>err); */
      });   
  }

  setDatos( datos  ){
    this.ambulanciaSI = datos;            
    /* this.totalAmbulancia = datos; */
    /* console.log(this.ambulanciaSI); */
  }

  onClick(){
    this.idPedidoSede = localStorage.getItem('idPedidoSede');
    if(this.idPedidoSede == null){
      this._router.navigateByUrl('/pago/servicios');
    }else{
      this._router.navigateByUrl('/pedidos/detalle/'+this.idPedidoSede);
    }
  }

  alertcomparasion( ev, precioPublico, precioMembresia, item2:any ){
    let precioSinTrim  =  precioPublico.replace('$', '');
    let precioSinComaPublico = precioSinTrim.replace(',', '');
    let precioMemTrim  =  precioMembresia.replace('$', '');
    let precioMemComaMembresia = precioMemTrim.replace(',', '');
    if(this.sedePaciente === null){
      this.agregarCarrito(ev,item2);
    }else{
      this._service.getIdSedeNom(this.sedePaciente).subscribe((data:any)=>{
        if(data.ok){
          this.dataSedePaciente.idSede = data['data'][0]._id;
          this.dataSedePaciente.name = this.servicios;
          this.dataSedePaciente.idItem = item2._id;
          this._service.getItemSedeAmbulancia(this.dataSedePaciente).subscribe((data:any)=>{
            if(data.ok){  
              if(data['data'].length == 0){
                this.agregarCarrito(ev,item2); 
              }else{
                  let uti= '';
                  switch(data['data'][0].rangoUtilidad){
                    case 'A':
                      if(this.membresia){
                        uti=data['data'][0].preciosRangoA[0].porcentajePrecioPublicoA
                      }else{
                        uti=data['data'][0].preciosRangoA[0].porcentajePrecioMembresiaA
                      }
                    break;
                    case 'B':
                      if(this.membresia){
                        uti=data['data'][0].preciosRangoB[0].porcentajePrecioPublicoB
                      }else{
                        uti=data['data'][0].preciosRangoB[0].porcentajePrecioMembresiaB
                      }
                    break;
                    case 'C':
                      if(this.membresia){
                        uti=data['data'][0].preciosRangoC[0].porcentajePrecioPublicoC
                      }else{
                        uti=data['data'][0].preciosRangoC[0].porcentajePrecioMembresiaC
                      }
                    break;
                  }
                this.agregarCarritoSede(ev,data['data'][0].idServicio, uti, data['data'][0].rangoUtilidad)              
              }
              
            }
          })
          
        }
      })
    }
     Swal.fire({
              icon: 'success',
              title: 'CON MEMBRESIA AHORRAS',
              text: `$ ${precioSinComaPublico - precioMemComaMembresia}.00`,
            })
  }

  agregarCarritoSede( event, item: any, uti, rango ) {
    // console.log(event);
    let carrito = new Carrito();
    this.carrito = carrito.agregarItemUtilidad(  event, item,uti,rango );
    this.validarCarrito();
  }

  validarCarrito(){
    this.obtenerCarritoStorage();
    if(this.carrito.items.length == 0){
      return true;
    }else{
      return false;
    }
  }

  showAlert(){
    Swal.fire({title: "¿Estas seguro de contratar a este destino?",
    text: "El servicio de ambulancia solo será requerido para dicho destino, no puede haber cambios",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
                     Swal.fire({
              icon: 'success',
              title: '',
              text: 'INGRESA LOS DATOS PARA EL TRASLADO',
            })
            this._router.navigateByUrl('/hoja-fram');
      }else if (result.dismiss === Swal.DismissReason.cancel) {
        this._router.navigateByUrl('serviciosInt/'+this.servicios);
      }

    })

  }

  editarAmbulancia( id ){
    Swal.fire({title: "¿Estas seguro que vas a editar el servicio",
    text: "Una vez que se haya editado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._router.navigateByUrl('formularioeditarservice/' +id)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'NO SE EDITO NADA',
            })
            this._router.navigateByUrl('serviciosInt/'+this.servicios);
      }   
    })
  }

  delete(id) {
    Swal.fire({title: "¿Estas seguro de eliminar el servicio?",
    text: "Una vez que se haya eliminado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._service.deleteService(id).subscribe((resp:any) => {
          if(resp.ok){
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'EL SERVICIO SE ELIMINO CORRECTAMENTE',
            })
            this.verDatos();
            this._router.navigateByUrl('serviciosInt/'+this.servicios);
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'EL SERVICIO NO SE ELIMINO',
            })
        } 
    })
  }

}
