<form autocomplete="off" #form="ngForm">

    <h1 class="text-center">CENTRO MEDICO HORIZONTE</h1>
    <h1 class="text-center">DEPARTAMENTO DE MANTENIMIENTO</h1>
    <h5 class="text-center">LAVADO Y CLORACION DE CISTERNA Y TINACOS</h5>
    <hr>
    <br>
    <br>

    <div class="form-group row">
        <label class=" col-form-label">MES:</label>
        <div class="col-3">
            <input class="form-control" type="text" name="mes" placeholder="MES">
        </div>
        <label class=" col-form-label">AÑO:</label>
        <div class="col-2">
            <input class="form-control" type="text" name="AÑO" placeholder="AÑO">
        </div>




    </div>
    <div class="form-group row">
        <label class=" col-form-label">SEMANA EPIDEMIOLOGICA DEL :</label>
        <div class="col-2">
            <input class="form-control" type="text" name="SEMANA" placeholder="">
        </div>
        <label class=" col-form-label">AL:</label>
        <div class="col-2">
            <input class="form-control" type="text" name="A" placeholder="">

        </div>


    </div>

    <br>
    <!--************************ TABLA *********************-->
    <table class="table">
        <thead class="thead-dark">
            <tr>

                <th scope="col">ACTIVIDAD REALIZADA</th>
                <th scope="col">FECHA</th>
                <th scope="col">CISTERNA</th>
                <th scope="col">TINACO 1</th>
                <th scope="col">TINACO 2</th>
                <th scope="col">TINACO 3</th>
                <th scope="col">TINACO 4</th>
                <th scope="col">TINACO 5</th>
                <th scope="col">TINACO 6</th>
                <th scope="col">TINACO 7</th>
                <th scope="col">NOMBRE Y FIRMA DE QUIEN REALIZA ACTIVIDAD</th>

            </tr>
        </thead>

        <tbody>
            <tr>
                <th scope="row"> VACIAMIENTO</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VC" [(ngModel)]="radios.r1">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT1" [(ngModel)]="radios.r2" [disabled]="habilitaV1 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT2" [(ngModel)]="radios.r3" [disabled]="habilitaV2 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT3" [(ngModel)]="radios.r4" [disabled]="habilitaV3 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT4" [(ngModel)]="radios.r5" [disabled]="habilitaV4 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT5" [(ngModel)]="radios.r6" [disabled]="habilitaV5 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT6" [(ngModel)]="radios.r7" [disabled]="habilitaV6 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="VT7" [(ngModel)]="radios.r8" [disabled]="habilitaV7 == false">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaV7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button> -->
                <!-- </td> -->

            </tr>
            <tr>
                <th scope="row"> DESASOLVE DE ARENA</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AC" [disabled]="habilitaAC == false" [(ngModel)]="radios.r9">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaAC == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT1" [disabled]="habilitaA1 == false" [(ngModel)]="radios.r10">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT2" [disabled]="habilitaA2 == false" [(ngModel)]="radios.r11">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT3" [disabled]="habilitaA3 == false" [(ngModel)]="radios.r12">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT4" [disabled]="habilitaA4 == false" [(ngModel)]="radios.r13">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT5" [disabled]="habilitaA5 == false" [(ngModel)]="radios.r14">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT6" [disabled]="habilitaA6 == false" [(ngModel)]="radios.r15">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="AT7" [disabled]="habilitaA7 == false" [(ngModel)]="radios.r16">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaA7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button> -->
                <!-- </td> -->

            </tr>
            <tr>
                <th scope="row"> LAVADO CON AGUA Y JABON</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LC" [disabled]="habilitaLC == false" [(ngModel)]="radios.r17">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLC == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT1" [disabled]="habilitaL1 == false" [(ngModel)]="radios.r18">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT2" [disabled]="habilitaL2 == false" [(ngModel)]="radios.r19">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT3" [disabled]="habilitaL3 == false" [(ngModel)]="radios.r20">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT4" [disabled]="habilitaL4 == false" [(ngModel)]="radios.r21">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT5" [disabled]="habilitaL5 == false" [(ngModel)]="radios.r22">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT6" [disabled]="habilitaL6 == false" [(ngModel)]="radios.r23">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LT7" [disabled]="habilitaL7 == false" [(ngModel)]="radios.r24">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaL7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button> -->
                <!-- </td> -->

            </tr>
            <tr>
                <th scope="row"> DESINFECCION CON SOLUCION CLORADA A 0.5%</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DC" [disabled]="habilitaDC == false" [(ngModel)]="radios.r25">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaDC == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT1" [disabled]="habilitaD1 == false" [(ngModel)]="radios.r26">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT2" [disabled]="habilitaD2 == false" [(ngModel)]="radios.r27">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT3" [disabled]="habilitaD3 == false" [(ngModel)]="radios.r28">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT4" [disabled]="habilitaD4 == false" [(ngModel)]="radios.r29">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT5" [disabled]="habilitaD5 == false" [(ngModel)]="radios.r30">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT6" [disabled]="habilitaD6 == false" [(ngModel)]="radios.r31">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="DT7" [disabled]="habilitaD7 == false" [(ngModel)]="radios.r32">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaD7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button> -->
                <!-- </td> -->
            </tr>
            <tr>
                <th scope="row"> LLENADO (REGISTRAR VOLUMEN EN LITROS)</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLC" [disabled]="habilitaLLC == false" [(ngModel)]="radios.r33">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLLC == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT1" [disabled]="habilitaLL1 == false" [(ngModel)]="radios.r34">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT2" [disabled]="habilitaLL2 == false" [(ngModel)]="radios.r35">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT3" [disabled]="habilitaLL3 == false" [(ngModel)]="radios.r36">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT4" [disabled]="habilitaLL4 == false" [(ngModel)]="radios.r37">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT5" [disabled]="habilitaLL5 == false" [(ngModel)]="radios.r38">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT6" [disabled]="habilitaLL6 == false" [(ngModel)]="radios.r39">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="LLT7" [disabled]="habilitaLL7 == false" [(ngModel)]="radios.r40">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaLL7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button> -->
                <!-- </td> -->
            </tr>

            <tr>
                <th scope="row"> CLORACION DEL AGUA (REGISTRAR VOLUMEN DE CLORO UTILIZADO)</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CC" [disabled]="habilitaCC == false" [(ngModel)]="radios.r41">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaCC == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT1" [disabled]="habilitaC1 == false" [(ngModel)]="radios.r42">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT2" [disabled]="habilitaC2 == false" [(ngModel)]="radios.r43">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT3" [disabled]="habilitaC3 == false" [(ngModel)]="radios.r44">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT4" [disabled]="habilitaC4 == false" [(ngModel)]="radios.r45">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT5" [disabled]="habilitaC5 == false" [(ngModel)]="radios.r46">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT6" [disabled]="habilitaC6 == false" [(ngModel)]="radios.r47">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="CT7" [disabled]="habilitaC7 == false" [(ngModel)]="radios.r48">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaC7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button> -->
                <!-- </td> -->
            </tr>
            <tr>
                <th scope="row"> MEDICION DE CLORO RESIDUAL</th>
                <td> </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MC" [disabled]="habilitaMC == false" [(ngModel)]="radios.r49">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaMC == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT1" [disabled]="habilitaM1 == false" [(ngModel)]="radios.r50">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM1 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT2" [disabled]="habilitaM2 == false" [(ngModel)]="radios.r51">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM2 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT3" [disabled]="habilitaM3 == false" [(ngModel)]="radios.r52">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM3 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT4" [disabled]="habilitaM4 == false" [(ngModel)]="radios.r53">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM4 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT5" [disabled]="habilitaM5 == false" [(ngModel)]="radios.r54">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM5 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT6" [disabled]="habilitaM6 == false" [(ngModel)]="radios.r55">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM6 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td>
                    <div class="form-group row">
                        <label class="col-5 col-form-label">SI</label>
                        <div class="form-group">
                            <div class="form-check form-check-inline">
                                <input type="radio" value="si" name="MT7" [disabled]="habilitaM7 == false" [(ngModel)]="radios.r56">
                            </div>
                        </div>
                    </div>
                    <div class="md-form">
                        <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput [disabled]="habilitaM7 == false" (change)="cambioRadio()"></textarea>
                        <label for="form7">DESCRIPCION</label>
                    </div>
                </td>
                <td> </td>
                <!-- <td>  <button  type="button" class="btn btn-outline-info btn-block">
                    SUBIR FOTOGRAFIA
                  </button>
             </td> -->
            </tr>


        </tbody>
        <br>

    </table>


    <h5>CISTERNA DE 18,000 LITROS:</h5>
    <h6>Si la concentración del hipoclorito de sodio es al 6%, se añadiran 180 ml de esta solución a una cisterna con 18,000 litros de agua, para garantizar una concentración de cloro residual de 0.5 mg/l.</h6>
    <h5>TINACO DE 1,100 LITROS:</h5>
    <h6>Si la concentración del hipoclorito de sodio es al 6%, se añadiran 110 ml de esta solución a un tinaco con 1,100 litros de agua, para garantizar una concentración de cloro residual de 0.5 mg/l.</h6>
    <h5>TINACO DE 750 LITROS:</h5>
    <h6>Si la concentración del hipoclorito de sodio es al 6%, se añadiran 75 ml de esta solución a un tinaco con 750 litros de agua, para garantizar una concentración de cloro residual de 0.5 mg/l.</h6>
    <br>

    <!-- <div class="col-10">
    <div class="col-2">
        <label class="col-5 col-form-label">&nbsp;</label>
        <img class="card-img-top"  src="../../../assets/subir.ico">
        <button  type="button" class="btn btn-outline-info btn-block">
          SUBIR FOTOGRAFIA
        </button>
   
        </div>

    </div> -->

    <!-- Botones -->
    <div class="form-group row">
        <label class="col-5 col-form-label">&nbsp;</label>
        <div class="input-group col-md-2">
            <button type="button" class="btn btn-outline-primary btn-block" (click)="enviar()" [disabled]="save == false">
        GUARDAR
      </button>
        </div>
    </div>
    <br>
</form>