<!-- SE CREO EL LISTADO DE PACIENTE -->
<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE PACIENTES'"></app-titulos>

    <div class="row">
        <div class="col-md-9">
            <input [(ngModel)]="filterNombre" name="filterNombre" id="busquedaPaciente" class="form-control" type="text" placeholder="Buscar Paciente...">
        </div>
        <div class="col">
            <button class="boton" (click)="imp()"> IMPRIMIR </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>HORA</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL PACIENTE</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>GÉNERO</p>
                        </div>
                        <div class="col text_header">
                            <p>MÉDICO TRATANTE</p>
                        </div>
                        <div class="col text_header">
                            <p>ENFERMERA</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of consultas | paginate:{id:'listapacientes',
                    itemsPerPage: 5,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer" [routerLink]="['/ficha-enfermeria-01', item._id]">
                            <div class="col-md-1">
                                <p> {{ i +1 }} </p>
                            </div>
                            <div class="col">
                                <p> {{ item.fechaIngreso }} </p>
                            </div>
                            <div class="col">
                                <p> {{ item.horaIngreso }} </p>
                            </div>
                            <div class="col">
                                <p>{{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.paciente.edad }}</p>
                            </div>
                            <div class="col">
                                {{ item.paciente.genero | uppercase }}
                            </div>
                            <div class="col">
                                <p> {{ item.doctorAPasar }} </p>
                            </div>
                            <div class="col">
                                <p> {{ item.enfermeraAtendio }} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>






<!-- <div class="container-fluid">
    <h1 h1 class="centerxd"> LISTADO DE PACIENTES</h1>
</div>

<section class="row">
    <div class="col-md">
        <input [(ngModel)]="filterNombre" name="filterNombre" style="margin-left: 2rem;" class="form-control" type="text" placeholder="Buscar Paciente...">
    </div>

    <div class="col-md offset-md-5">
        <button class="btn" style="color: aliceblue; " (click)="imp()">IMPRIMIR</button>
    </div>
</section>

<div class="center">
    <section>
        <div class="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col text_header">
                        <p> # </p>
                    </div>
                    <div class="col text_header">
                        <p>Fecha</p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre</p>
                    </div>
                    <div class="col text_header">
                        <p>Género</p>
                    </div>
                    <div class="col text_header">
                        <p>Edad</p>
                    </div>
                    <div class="col text_header">
                        <p>Médico tratante</p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row clicksss" *ngFor="let item of consultas | buscarPaciente:filterNombre | paginate:{id:'listaAmbulancia',
                    itemsPerPage: 8,
                    currentPage:pagina,
                    totalItems:totalAmbulancia} ; let i = index">

                    <div class="col color">
                        <p> {{ i +1 }} </p>
                    </div>

                    <div class="col color">
                        <p> {{ item.fechaIngreso }} </p>
                    </div>

                    <div class="col color">
                        <p [routerLink]="['/ficha-enfermeria-01', item._id]"> {{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }} </p>
                    </div>

                    <div class="col color">
                        <p> {{ item.paciente.genero | uppercase }} </p>
                    </div>

                    <div class="col color">
                        <p> {{ item.paciente.edad }} </p>
                    </div>

                    <div class="col color">
                        <p> {{ item.medicoTrante }} </p>
                    </div>
                </div>
            </div>
            <div class="offset-md-1">
                <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </section>
</div> -->