import { Component, OnInit , Output, EventEmitter, ViewChild, ViewChildren, ElementRef, AfterViewChecked, QueryList} from '@angular/core';
import { getDataStorage } from '../../../functions/storage.funcion';
import { WsLoginService  } from '../../../services/sockets/chat/ws-login.service'
import * as moment from 'moment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit  {

  @Output() public cerrarChat = new EventEmitter<any>();
  @ViewChild('myscrollcontainer',{static: false}) myScrollContainer: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
 // @Input() public roleNotificacion   : string ;

  private scrollContenedor: any;
  private isNearBottom = true;
  public mesages = [];
  public nickname = '';
  public horaEnvio = moment().format('h:mm:ss');
  public usuario = {
    RFC: '',
    curp: '',
    fechaREgistro: '',
    img: "",
    nombre: "",
    password:"" ,
    role: "",
    sede:"",
    turno:"" ,
    __v: 0,
    _id: ''
  }
  public payload = {
    message: '',
    horaEnvio: this.horaEnvio
  };

  message = '';

  public usuarioConectados = [];


  constructor(
    private wsloginService: WsLoginService
    ) { }

    ngOnInit(): void {
      this.usuario = getDataStorage();
      this.obtenerMensajes();
      

      
      // this.wsloginService.escucharMensajesLab()
      // .subscribe( (message) => {
      //   console.log(  message);
        
      //   this.mesages.push( message.payload );
        
      // });
      this.wsloginService.mostarUsuario().subscribe((args)=>{
      })

      this.wsloginService.adios();
      // escucahmos el mensaje de los usuarios
    this.wsloginService.escucahrUsuaurtioConectados()
    .subscribe( (arg) => {
      //console.log(arg);
     // console.log(this.usuario);
      
      this.usuarioConectados = arg.filter(data => 
        data. idUser !== this.usuario._id
      )
      /* for (const item of arg) {
        if(item.nombre != this.usuario.nombre){
          this.usuarioConectados.push(item)
        }else if(item.nombre == this.usuario.nombre){
          this.usuarioConectados.pop();
        }
      } */
      /* this.usuarioConectados = arg  */
    });


    
    
    // escuchamos si algun usuario se desconecta

    this.wsloginService.escucharUsuarioDesconectado()
    
    .subscribe( (arg) =>  {
      this.usuarioConectados.forEach(  (user:any, index) => {
        
        if(  user.usuario.nombre === arg.user.nombre ){
          
          
          this.usuarioConectados.splice(index, 1);
        }
        
        
      })
      
      // console.log(this.usuarioConectados);
      
       });
      }
      
      private onItemElementsChanged(): void{
        if (this.isNearBottom) {
          this.scrollToBottom();
        }
      }
 

      obtenerMensajes() {

        this.wsloginService.escucahrNuevoMensajes()
          .subscribe(data => {
            
              this.mesages = data;
              this.scrollDiv();
          } ) ;
          
      }
      
      // enviarMensaje() {
        //   this.wsloginService.enviarMensajePrivado( this.mesages );

        // }
        
        

      enviarData( ): void{

      this.obtenerMensajes();
      this.wsloginService.enviarMensaje(this.message);
      this.message = '';
      }

      scrollToBottom(): void {
        this.scrollContenedor.scroll({
          top: this.scrollContenedor.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });                
      }

      private isUserNearBottom(): boolean {
        const threshold = 150;
        const position = this.scrollContenedor.scrollTop + this.scrollContenedor.offsetHeight;
        const height = this.scrollContenedor.scrollHeight;
        return position > height - threshold;
      }
    
      scrolled(event: any): void {
        
      }


  cerrarVentanachat(){


      this.cerrarChat.emit({ estado: true  });

  }


  
  verUsaurio(  user, event ){

    this.nickname = user;
    this.obtenerMensajes()
    this.wsloginService.regresarUsuaurios(  user );
  } 

  scrollDiv(){
    let chatBox = document.querySelector('.container-fluid');
    document.getElementById("tudiv").scrollTop = chatBox.scrollHeight;
  }


}
