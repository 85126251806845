<h1 class="centerxd">LISTADO DE SERVICIOS SOCIO DE CANAL</h1>

<div class="col-md-8">
    <div class="form-group">
        <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador">
    </div>
</div>


<div class="container-fluid">


    <div class="row">
        <div class="col-md-9">
            <div class="card" style="border-radius: 30px;">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>No.</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Paciente</p>

                        </div>
                        <div class="col-md-2 text_header">
                            <p>SEDE</p>

                        </div>
                        <div class="col-md-2 text_header">
                            <p>Fecha</p>

                        </div>
                        <div class="col-md-1 text_header">
                            <p>Servicio</p>

                        </div>
                        <div class="col-md-2 text_header">
                            Anticipo
                        </div>

                        <div class="col-md-1 text_header">
                            Total
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="card-body col-md-9" style="background-color: #ffffff;">



            <div class="col-md-2">
                <p>01</p>
            </div>

            <div class="col-md-2">
                <div>

                    <h6>Julieta Vega Cruz</h6>

                </div>
            </div>

            <div class="col-md-2">
                <div>

                    <h6>Amayuca</h6>

                </div>
            </div>

            <div class="col-md-2">
                <div>

                    <h6> 02/03/2021</h6>

                </div>
            </div>

            <div class="col-md-2">
                <div>

                    <h6>Laboratotio</h6>

                </div>
            </div>

            <div class="col-md-2">
                <div>

                    <h6> $240.00 </h6>

                </div>
            </div>

            <div class="col-md-2">
                <div>

                    <h6> Total</h6>

                </div>
            </div>

        </div>

        <div class="col-md-3">
            <div class="card" style="margin: 10px; height: 35em; border-radius: 24px;">
                <div class="card-title" style="border-bottom-style: solid;">
                    <h4 class="cot" style="text-align: center;">COTIZACIÓN</h4>
                </div>

                <div class="card-body" style="overflow: auto;">


                    <div class="row">
                        <div style="margin-top:55%;" class="col-md-10">
                            <h5 class="precio" style="font-size: 1.2rem; font-weight: 800;">Precio Total: </h5>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-10">
                            <h5 class="anti" style="font-size: 1.2rem; font-weight: 800;">Anticipo: </h5>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-10">
                            <h5 class="fe" style="font-size: 1.2rem; font-weight: 800;">Fecha: </h5>
                        </div>
                    </div>

                    <button style="margin-top:15%;" type="button" class="btn btn-block btn-info  btn-tarjeta">
                        
                       IMPRIMIR
                </button>

                    <button style="margin-top:20px;" class="btn btn-block btn-info  btn-tarjeta">
                       
                        ENVIAR
                </button>

                    <button style="margin-top:20px;" class="btn btn-block btn-info  btn-tarjeta">
                        
                        AGENDAR
                
            </button>
                </div>
            </div>
        </div>

    </div>
</div>