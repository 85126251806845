<section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">EXPEDIENTE CLÍNICO</h1>
    </div>
</section>

<br>

<section>
    <div class="card" name="card" id="card">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <p style="color: #001672;">Nombre: Juan Peréz Ramos </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">Dirección: Ignacio Allende #10 Cuautla, Mor </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">Género: Hombre</p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;"> Teléfono: 7351284593 </p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;"> Religión: Cristiana</p>
                </div>
                <div class="col-md-2">
                    <p style="color: #001672;">ID: wh2395nksdjfno </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">Fecha de nacimiento: 12/04/1990 </p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;">Lugar de naciemiento: Guerrero, México </p>
                </div>
                <div class="col-md-2">
                    <p style="color: #001672;">Tipo de sangre: O+</p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;">Edad: 20 años</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="card" name="card" id="card">
    <div class="container">
        <div class="row">
            <div clas=col>
                <p style="color: #001672;">Laboratorios </p>
            </div>

            <div class=col>
                <p style="color: #001672;"> Rayos-X </p>
            </div>

            <div class=col>
                <p style="color: #001672;">Patología </p>
            </div>

            <div class=col>
                <p style="color: #001672;">Ambulancia</p>
            </div>
            <div class=col>
                <p style="color: #001672;">Ultrasonido</p>
            </div>
            <div class=col>
                <p style="color: #001672;"> Resonancias</p>
            </div>
            <div class=col>
                <p style="color: #001672;"> Cirugías</p>
            </div>
            <div>
                <p style="color: #001672;">Tomografías</p>
            </div>
            <div class=col>
                <p style="color: #001672;"> Endoscopía</p>
            </div>
            <div class=col>
                <p style="color: #001672;"> Hospitalización</p>
            </div>
        </div>
    </div>
</div>
<br>

<section class="row">
    <div class="col">
        <h1 class="cen" id="centerxd">Historia Clinica</h1>
    </div>
</section>

<br>

<div class="center">
    <section>
        <div class="card" style="border-radius: 75px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col-1 text_header">
                        <p>Signos vitales</p>
                    </div>
                    <div class="col text_header">
                        <p>Talla</p>
                    </div>
                    <div class="col text_header">
                        <p>Peso</p>
                    </div>
                    <div class="col text_header">
                        <p>IMC</p>
                    </div>
                    <div class="col text_header">
                        <p>TA</p>
                    </div>
                    <div class="col text_header">
                        <p>Temp</p>
                    </div>
                    <div class="col text_header">
                        <p>P.C</p>
                    </div>
                    <div class="col text_header">
                        <p>P.A</p>
                    </div>
                    <div class="col text_header">
                        <p>P.T</p>
                    </div>
                    <div class="col text_header">
                        <p>PaO</p>
                    </div>
                    <div class="col text_header">
                        <p>Glucosa</p>
                    </div>
                    <div class="col text_header">
                        <p>Fecha</p>
                    </div>
                </div>
            </div>


            <div class="card-body">
                <div>

                    <div class="col-md-2">
                        <p style="color: #001672;"> Antecedentes</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Medicina preventica</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Antecedentes Heredo Familiares</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Antecedentes no patologícos</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Antecedentes patologícos</p>
                    </div>
                    <div class="col-md-2">
                        <p style="color: #001672;">Antecedentes ginecologícos</p>
                    </div>

                </div>
            </div>
            <br>
        </div>
    </section>
</div>