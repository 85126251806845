import { Component, OnInit } from '@angular/core';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { ActivatedRoute } from '@angular/router';
import { USGService } from 'src/app/services/usg/usg.service';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'app-resultados-ultrasonido',
  templateUrl: './resultados-ultrasonido.component.html',
  styleUrls: ['./resultados-ultrasonido.component.css']
})
export class ResultadosUltrasonidoComponent implements OnInit {


  public paciente = {
    apellidoMaterno: '',
    apellidoPaterno: '',
    callePaciente: '',
    consultas: '',
    contactoEmergencia1ApellidoMaterno: '',
    contactoEmergencia1ApellidoPaterno: '',
    contactoEmergencia1Curp: "",
    contactoEmergencia1Edad: "",
    contactoEmergencia1Genero: "",
    contactoEmergencia1Nombre: "",
    contactoEmergencia1Telefono: "",
    contactoEmergencia2ApellidoMaterno: "",
    contactoEmergencia2ApellidoPaterno: "",
    contactoEmergencia2Curp: "",
    contactoEmergencia2Edad: "",
    contactoEmergencia2Genero: "",
    contactoEmergencia2Nombre: "",
    contactoEmergencia2Telefono: "",
    correoPaciente: "",
    correoRazonSocial2: "",
    cpPaciente: '',
    cpRazonSocial: "",
    cpRazonSocial2: "",
    curp: "",
    edad: '',
    estadoPaciente: "",
    familia: [],
    fechaNacimientoPaciente: "",
    fechaRegistro: "",
    genero: "",
    membresiaActiva: '',
    nombrePaciente: "",
    nomenclaturaRegistro: "",
    paisNacimiento: "",
    paisPaciente: "",
    paquetes: [],
    paquetesPacientes: [],
    razonSocial1: "",
    razonSocial1Calle: "",
    razonSocial1Estado: "",
    razonSocial1Municipio: "",
    razonSocial2: "",
    razonSocial2Calle: "",
    razonSocial2Estado: "",
    razonSocial2Municipio: "",
    razoncocial1RFC: "",
    razoncocial2RFC: "",
    status: "",
    telefono: '',
    _id: ''
  }

  public id;
  public ultrasonidos = {
    imageUrl: [],
    usuario: "",
    idEstudio: {
      ELEMENTOS: [{
        machote: ""
      }],
      ESTUDIO: ""
    },
    diagnostico: "",
    machoteEdit: "",
    idPaciente: [],
    _id:"",
    idPedido:"",
    observaciones:""
  }

  public titulo;
  public machoteEditado;
  public diagnostico;
  public diag;
  public observa;
  public imagenes= [];
  public p:[{_id:""}];
  public userUSG
 public searchEstudio;

  constructor(private _usgService: USGService, private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.paciente = JSON.parse(localStorage.getItem('idPaciente'))
    this.searchEstudio = JSON.parse(localStorage.getItem('idPedidoUltra'))
    this.obtenerUsg();
  }



  setPeidoUltra(pedido) {
    
  
    this.imagenes = pedido[0].imageUrl
    
  
    this.diag = pedido[0].diagnostico;
    this.titulo = pedido[0].idEstudio.ESTUDIO;
    this.machoteEditado = pedido[0].machoteEdit;
    this.observa = pedido[0].observaciones;
    

    this.userUSG = pedido[0].usuario;

    /* console.log(  this.imagenes ); */
  //   // Ilmer guarda el valor del diagnostico
  }
 
  
  
  obtenerUsg() {

    this._usgService.obtenerPedidoUsg(this.paciente._id, this.searchEstudio)
      .subscribe((data) => this.setPeidoUltra(data['data']));
;

  }

  USGpdf() {
    let showElement = document.querySelectorAll(".hide-display");
    showElement.forEach((elemento) => {
      // console.log(elemento);
      elemento.classList.remove("hide-display");
    });
    window.print();
    window.location.reload();
  }
}
