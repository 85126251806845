import { Component, OnInit } from '@angular/core';
import {  ConsultaService } from '../../../services/consultas/consulta/consulta.service';
import {   WsLoginService } from '../../../services/sockets/chat/ws-login.service'

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Router } from '@angular/router';


@Component({
  selector: 'app-hoja-diaria-enf-gral',
  templateUrl: './hoja-diaria-enf-gral.component.html',
  styleUrls: ['./hoja-diaria-enf-gral.component.css']
})
export class HojaDiariaEnfGralComponent implements OnInit {

  public listaEspera = [];
  public getPacienteSotageX = this.listaEspera;
  public fechatl;

  public imprimir = {
    indice: 0,
    nombre: '',
    curp:  '',
    edad: '',
    servMed: '',
    medico: '',
    horaCita: '',
  }
  public totalpaciente:string;
  public pagina = 0;
  public noSe = [];



  constructor(
    public _consultasService: ConsultaService,
    private _router: Router,
    public _WsloginService: WsLoginService
  ) { }

  ngOnInit(): void {

    this.obtenerConsultas();

    this._WsloginService.escucharConsulta()
      .subscribe(arg => { 
        if(arg != ""){
          this.obtenerConsultas();
        }  
      });
    
    
  }

  obtenerConsultas(){
    this._consultasService.verConsultasPendientesEnfermeria()
    .subscribe( (data) => {
      let pacientes = [];
      let sede = JSON.parse(localStorage.getItem('cede'))
      data['data'].forEach(element => {
        if(element.sede == sede){
          pacientes.push(element)
        }
      });
      this.listaEspera = pacientes.reverse();
      this.totalpaciente = data['data'].results;
      this.setPaciente(pacientes);
    });
  }

  setPaciente(data) {
    let i= 0;
    for (let element of data) {    
      this.imprimir.indice= i + 1;
      this.imprimir.nombre = element.paciente.nombrePaciente;
      this.imprimir.curp = element.paciente.curp;
      this.imprimir.edad = element.paciente.edad;
      this.imprimir.servMed = element.tipoDeServicio;
      this.imprimir.medico = element.doctorAPasar;
      this.imprimir.horaCita = element.horaIngreso;
      //console.log(this.imprimir.indice);
      
      this.noSe.push(this.imprimir)
      this.imprimir = {
        indice: 0 ,
        nombre: '',
        curp:  '',
        edad: '',
        servMed: '',
        medico: '',
        horaCita: '',
      }
      i++
    }
    
  }

  imprimirBitacora(){

    let values: any;
    values = this.noSe.map((elemento) => Object.values(elemento));    
    
    const doc:any = new jsPDF();

    doc.autoTable({
      head: [['#', 'Nombre', 'Curp', 'Edad', 'Servicio Médico', 'Médico', 'Hora de Cita' ]],
      body: values
    })

    doc.save('Bitácora_Hoja_Diaria_Enf_Gral_'+this.fechatl+'_.pdf')
  }


}
