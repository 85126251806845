<section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">BITÁCORA DE PEDIDOS</h1>
    </div>
</section>

<br>

<section class="row">
    <div class="col-md-8">
        <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
    </div>

    <div class="col-md ">
        <button class="principalButton " style="color: aliceblue; ">AGREGAR</button>
    </div>

</section>

<br>

<section>
    <div class="center">
        <div class="card" style="border-radius: 20px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col-1 text_header">
                        <p>No.</p>
                    </div>
                    <div class="col-2 text_header">
                        <p>Paciente</p>
                    </div>
                    <div class="col-1 text_header">
                        <p>SEDE</p>
                    </div>
                    <div class="col-2 text_header">
                        <p>Fecha</p>
                    </div>
                    <div class="col-2 text_header">
                        <p>Servicio</p>
                    </div>
                    <div class="col-2 text_header">
                        <p>Anticipo</p>
                    </div>
                    <div class="col-1 text_header">
                        <p>Total</p>
                    </div>
                    <div class="col-1 text_header">
                        <p></p>
                    </div>
                </div>
            </div>


            <div class="card-body">
                <div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                    <div class="col-md-1">
                        <p style="color: #001672;">01</p>
                    </div>
                </div>
            </div>

        </div>

        <br>

    </div>

</section>