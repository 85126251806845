import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { URL } from '../../../app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  public url = URL;
 
  title$ = new EventEmitter<String>();

  constructor( private _http: HttpClient ) { }


  public getSells( bodyFecha ){

    // console.log( bodyFecha )
    const url = this.url + "/ver/ventas/servicios"
    return this._http.post( url , bodyFecha);

  }

  public getSellsForDate( body){
    const url = this.url + "/ver/ventas/fecha/sede";
    return this._http.post( url, body );
  }

  public getSellsDetailByUser( idPaciente, ){
    const url = this.url +  `/ventas/por/usuario/${idPaciente}`;
    // console.log( url );
    return this._http.get( url ); 
  }
  
  public listaTickets(){ 
    const url = this.url +  `/ver/tickets/corte`;
    return this._http.get( url);
  }

  public cancelTickets( id:any, token: any ,motivo: any  ) {

    const url = this.url +  `/actualizar/estado/ticket/${id}/${token}`;
    return this._http.post( url, motivo );
  
  }

  public getSellsforTwoDates( body) { 
    const url = this.url +  `/ver/ventas/por/fecha/sedes`;
    return this._http.post( url, body );  
  }

  public postANewPedidoNew( body ) { 
    const url = this.url + "/nuevo/pedido/estudios";
    return this._http.post( url, body);
  }

  public getPedidosByServices( service  ){
    const url = this.url + "/ver/bitacora/estudios/pedidos/" + service;
    return this._http.get( url);
  }

  public getEstudiesByServices( service  ){
    const url = this.url + "/hoja/servicio/pedido/estudios";
    return this._http.post( url, service);
  }

  public getStudiesByServices( service  ){
    const url = this.url + "/hoja/servicio/pedido/estudios/";
    return this._http.post( url, service);
  }

  public sentStudiesByServices( id, service  ){
    
    const url = `${this.url}/actualizar/pedidios/${id}`;
    return this._http.put( url, service);
  }
  
  public getBitacoraLabs ( service ) {
    /* console.log( service ) */
    const url = `${this.url}/ver/hoja/reporte/resultados/` + service;
    return this._http.get(url);

  }

  public getResultadoPorEstudio(id) {
    const url = `${this.url}/hoja/reporte/resultados/${id}`;
    return this._http.get(url)
  }

  public obtenerRegresosHistoriaClinica( id: string, servicio: string ) {
      // TODO: TERMINAR EL SERVICIO DE OBRTENER LOS VALORES POR LA CONSULTA
      const url = `${this.url}/obtener/resultaos/por/paciente/servicio/${id}/${servicio}`;
      return this._http.get(url);
    };
  

    public obtenerRegresosHistoriaClinicaOptimizado( id: string, servicio: string ) {
      const url = `${this.url}/obtener/resultaos/por/paciente/servicio/optimizado/${id}/${servicio}`;
      return this._http.get(url);
    };
  

  public obtenerOtrosEstudiosPaciente(id:any, servicio:any){
    const url = `${this.url}/obtener/otros/estudios/${id}/${servicio}`;
    return this._http.get(url);
  }

  public obterPedidosFinalizadosPorNombrePaciente (body:any) { 
    const url =   `${this.url}/ver/hoja/reporte/resultados/por/nombre/paciente`;
    return this._http.post( url, body );
  }
  //servicio que se encarga de obtener los pedidos por el NOMBRE DEL paciente 

  public obtenerPedidoPorIdPaciente(id:any){
    const url = `${this.url}/ver/hoja/reporte/resultados/por/nombre/paciente/${id}`;
    return this._http.get(url);
  }

}

