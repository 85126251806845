<div class="container-fluid">

    <!-- <app-titulos
    [title]="'HOJA DE REPORTE DE PATOLOGIA'"
    [rol]="'patologia'"
    >
</app-titulos> -->

    <ficha-info-user 
    [pacienteInfo]="pedido.idPaciente"
    title="false"
    [rol]="'patologia'"
    >
</ficha-info-user>

    <div class="card my-3">
        <div class="header center">
            <div class="row">
                <div class="col text_header">
                    #
                </div>
                <div class="col text_header">
                    NOMBRE DEL ESTUDIO
                </div>
                <div class="col text_header">
                    ESTADO
                </div>
                <div class="col text_header">
                    SEDE
                </div>
            </div>
        </div>
        <div class="card-body center">
                <div class="row"  *ngFor="let item of pedido.estudios; let i = index" >
                

                <div class="col">
                    <p>
                        {{ i +1 }}
                    </p>
                </div>
                <div class="col"  >
                    <p class="pointer" (click)="setLocalStorageData()" [routerLink]="['/hoja/reporte/patologia/', item.idEstudio ]" >
                        {{ item.nombreEstudio }}
                    </p>
                </div>
                <div class="col">
                    <p>
                        {{ pedido.prioridad}}
                    </p>
                </div>
                <div class="col">
                    <p>
                        {{ pedido.sede }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
