import { Component, OnInit } from '@angular/core';
import { EmpleadoService } from 'src/app/services/registrarEmpleado/empleado.service';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';;

@Component({
  selector: 'app-empleado',
  templateUrl: './empleado.component.html',
  styleUrls: ['./empleado.component.css']
})
export class EmpleadoComponent implements OnInit {

  public sedes = [];
  
  empleadoForm: FormGroup;

  rfcExpression = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

  curpExpression = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;


  createFormModule(){
    return new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      rfc: new FormControl('', []),
      role: new FormControl('', []),
      sedeId: new FormControl('', []),
      turno: new FormControl('', []),
      curp: new FormControl('', []),
      // sede: new FormControl('', [Validators.required]),
    })
  }

  constructor(private _registrarEmpleado: EmpleadoService, private _BancoService: BancoService) { 
    this.empleadoForm = this.createFormModule();
  }

  ngOnInit(): void {
    this.obtenerSedes();
  }

  obtenerSedes(){
    this._BancoService.getSedes().subscribe((data:any) => {
      console.log(data);
      
      this.sedes = data.data;
    });
  }

  registrarEmpleado(){

    const TOKEN = localStorage.getItem("token");
    this.empleadoForm.value.token = TOKEN;
    if(this.empleadoForm.valid){
      console.log(this.empleadoForm.value);
      
      this._registrarEmpleado.agregarEmpleado(this.empleadoForm.value).subscribe(( data:any ) => {
        if(data.ok) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'EMPLEADO REGISTRADO CORRECTAMENTE',
          });
          /* console.log(data); */
        }
      });
    }else{
      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'COMPLETA EL REGISTRO',
      });
    }
  }

  get nombre(){ return this.empleadoForm.get('nombre') }
  get password(){ return this.empleadoForm.get('password') }
  get rfc(){ return this.empleadoForm.get('rfc') }
  get role(){ return this.empleadoForm.get('role') }
  get turno(){ return this.empleadoForm.get('turno') }
  get curp(){ return this.empleadoForm.get('curp') }
  // get sede(){ return this.empleadoForm.get('sede') }

  //TODO AGREGAR LA VALIDACION PARA EL RFC Y EL curp
  // TODO EL TOKEN LO ENVIA VACIO

}
