<article class="mt-3 " >
    <header class="row" >
        <div class="col-md-4 offset-md-8">
            <button class="btn-censur"  (click)="btnPrintPdf()"  >
                DESCARGAR PDF
            </button>
        </div>
    </header>
    <div class="col-md-12">
        <header>
            <h2 class="center title-principal-bs mt-4 mb-4" > {{ titleUsg }} </h2>
        </header>
    </div>
    <article class="container-fluid" >
        <div class="row">
            <div class="col-md-12">
                <h5 class="bold" > 
                    DIAGNÓSTICO:
                </h5>
                <p> {{ diagnosticoUsg }} </p>  
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="bold" > 
                    INTERPRETACIÓN:
                </h5>
                <p> {{ interpretacion }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="bold" > 
                    OBSERVACIONES:
                </h5>
                <p> {{ observacionesUSG }} </p>
            </div>
        </div>
        <figure class="row">
            <div class="col-md-12">
                <img id="imagenesUltra" *ngFor="let img of imgUsg" [src]="img.base" class="img-responsive img-width pointer" [alt]="img.name">
            </div>
        </figure>
    </article>
</article>
