<form #fEsquem #form='ngForm'>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <span>VACUNA</span>
            </div>
            <div class="col">
                <span>PREVIENE</span>
            </div>
            <div class="col">
                <span>DOSIS</span>
            </div>
            <div class="col">
                <span>FECHA 1</span>
            </div>
            <div class="col">
                <span>FECHA 2</span>
            </div>
            <div class="col">
                <span>FECHA 3</span>
            </div>
            <div class="col">
                <span>FECHA 4</span>
            </div>
            <div class="col">
                <span>ULTIMA FECHA</span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>BCG</p>
            </div>
            <div class="col">
                <p>Tuberculosis</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="tuberculosisNinoDosis" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="tuberculosisNinoFechaUno" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="tuberculosisNinoFechaDos" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="tuberculosisNinoFechaTres" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="tuberculosisNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="tuberculosisNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.tuberculosisNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Hepatitis B</p>
            </div>
            <div class="col">
                <p>Hepatitis B</p>
            </div>
            <div class="col"><input type="text" placeholder="" name="hepatitisNinoDosis" [(ngModel)]="esquemaVacuncaion.hepatitisNinoDosis" class="form-control">
            </div>
            <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaUno" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaUno" class="form-control">
            </div>
            <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaDos" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaDos" class="form-control">
            </div>
            <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaTres" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaTres" class="form-control">
            </div>
            <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaCuatro" class="form-control">
            </div>
            <div class="col"><input type="text" placeholder="" name="hepatitisNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.hepatitisNinoFechaUltima" class="form-control">
            </div>

        </div>
        <div class="row">
            <div class="col">
                <p>Pentavalente</p>
            </div>
            <div class="col">
                <p>Difteria, Tosferina, Tétanos, Poliomielitis e Infecciones por H. Influenzae b</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="pentavalenteNinoDosis" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="pentavalenteNinoFechaUno" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="pentavalenteNinoFechaDos" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="pentavalenteNinoFechaTres" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="pentavalenteNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="pentavalenteNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.pentavalenteNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>DPT</p>
            </div>
            <div class="col">
                <p>Difteria, Tosferina y Tétanos</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="dptNinoDosis" [(ngModel)]="esquemaVacuncaion.dptNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="dptNinoFechaUno" [(ngModel)]="esquemaVacuncaion.dptNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="dptNinoFechaDos" [(ngModel)]="esquemaVacuncaion.dptNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="dptNinoFechaTres" [(ngModel)]="esquemaVacuncaion.dptNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="dptNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.dptNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="dptNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.dptNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Rotavirus</p>
            </div>
            <div class="col">
                <p>Diarrea por Rotavirus</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="rotavirusNinoDosis" [(ngModel)]="esquemaVacuncaion.rotavirusNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="rotavirusNinoFechaUno" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="rotavirusNinoFechaDos" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="rotavirusNinoFechaTres" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="rotavirusNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="rotavirusNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.rotavirusNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Neumococo</p>
            </div>
            <div class="col">
                <p>Infecciones por Neumococo</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="neumococoNinoDosis" [(ngModel)]="esquemaVacuncaion.neumococoNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="neumococoNinoFechaUno" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="neumococoNinoFechaDos" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="neumococoNinoFechaTres" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="neumococoNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="neumococoNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.neumococoNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Influenza</p>
            </div>
            <div class="col">
                <p>Influenza</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="influenzaNinoDosis" [(ngModel)]="esquemaVacuncaion.influenzaNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="influenzaNinoFechaUno" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="influenzaNinoFechaDos" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="influenzaNinoFechaTres" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="influenzaNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="influenzaNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.influenzaNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>SRP</p>
            </div>
            <div class="col">
                <p>Sarampión, Rubéola, Parotiditis</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sprNinoDosis" [(ngModel)]="esquemaVacuncaion.sprNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sprNinoFechaUno" [(ngModel)]="esquemaVacuncaion.sprNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sprNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sprNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sprNinoFechaTres" [(ngModel)]="esquemaVacuncaion.sprNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sprNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.sprNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sprNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.sprNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Sabin</p>
            </div>
            <div class="col">
                <p>Poliomielitis</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sabinNinoDosis" [(ngModel)]="esquemaVacuncaion.sabinNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sabinNinoFechaUno" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sabinNinoFechaDos" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sabinNinoFechaTres" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sabinNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="sabinNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.sabinNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Otras Vacunas</p>
            </div>
            <div class="col">
                <p>Otras Enfermedades</p>
            </div>
            <div class="col">
                <input type="text" placeholder="" name="otrasVacunasNinoDosis" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoDosis" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="otrasVacunasNinoFechaUno" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaUno" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="otrasVacunasNinoFechaDos" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaDos" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="otrasVacunasNinoFechaTres" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaTres" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="otrasVacunasNinoFechaCuatro" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaCuatro" class="form-control">
            </div>
            <div class="col">
                <input type="text" placeholder="" name="otrasVacunasNinoFechaUltima" [(ngModel)]="esquemaVacuncaion.otrasVacunasNinoFechaUltima" class="form-control">
            </div>
        </div>
        <div class="row">
            <span>ALERGIAS</span>
            <textarea name="alergia" [(ngModel)]="esquemaVacuncaion.alergia" id="alergia" class="textarea form-control"></textarea>
        </div>
    </div>
    <div class="" >
        <button class="boton" (click)="agregarVacunacionNinos(form)">Guardar</button>
        <button class="boton2" (click)="actualizarEsquemaVacunacion(form)">Actualizar</button>
    </div>
</form>
