import { Component, EventEmitter, Input, Output} from '@angular/core';
import {  FichaInfoCensur } from '../../../../classes/ficha-info-paciente';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-fichas-identificacion',
  templateUrl: './fichas-identificacion.component.html',
  styleUrls: ['./fichas-identificacion.component.css']
})
export class FichasIdentificacionComponent{

  @Input() data:FichaInfoCensur;
  @Input() rol='';
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() dataDonanteEmit = new EventEmitter();
  public tabHeader = [];
  public tabBody = [];
  public dato ={
    religion:'Tipo'
  }
  
  dataDonante = {
    tipo_de_sangreado:"",
    tipo_de_donador:"",
    idbancosangre:"",
    notas:""
  }

  capturar(){
    this.newItemEvent.emit(this.dato.religion);
  }

  emmitDataDocumentos() {
    if( this.dataDonante.tipo_de_sangreado.length == 0 ){
        alert("Ingresa si es de repetición");
    }else if( this.dataDonante.tipo_de_donador.length == 0 ){
      // console.log( this.dataDonante );
      // alert("Ingresa el tipo de donador");
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Ingresa el tipo de donador',
      })
    }
    else{
      this.dataDonanteEmit.emit( this.dataDonante );
    }
  }

}
