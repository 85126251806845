import { Component, OnInit } from '@angular/core';
import { IntegradosService } from '../../../services/servicios/integrados.service';
import { Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

   // data de los servicios
   public servicios:string;

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
  }
   
  constructor(private activatedRoute: ActivatedRoute) {}
}
