<div class="content">
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p> TRANSFUSIONES PREVIAS </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="transfusiones_prev" formControlName="transfusiones_prev" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="transfusiones_prev" formControlName="transfusiones_prev" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>¿LE HAN PAGADO POR DONAR SANGRE?</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="pago_por_donar_sangre" formControlName="pago_por_donar_sangre" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="pago_por_donar_sangre" formControlName="pago_por_donar_sangre" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>USO DE DROGAS INTRAVENOSAS/INHALADAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="uso_drogas" formControlName="uso_drogas" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="uso_drogas" formControlName="uso_drogas" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>HETEROSEXUAL PROMISCUO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="heterosecual_promiscuo" formControlName="heterosecual_promiscuo" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="heterosecual_promiscuo" formControlName="heterosecual_promiscuo" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>HOMOSEXUAL</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="homoxesual" formControlName="homoxesual" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="homoxesual" formControlName="homoxesual" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>BISEXUAL</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="bisexual" formControlName="bisexual" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="bisexual" formControlName="bisexual" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>¿HA PAGADO O RECIBIDO DINERO POR TENER RELACIONES SEXUALES EN LOS ÚLTIMOS DÍAS?</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="pago_por_relaciones" formControlName="pago_por_relaciones" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="pago_por_relaciones" formControlName="pago_por_relaciones" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>CONTACTO SEXUAL CON HEMOFILICOS/HEPATITIS/DESCONOCIDOS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="contacto_con_hemofilicos" formControlName="contacto_con_hemofilicos" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="contacto_con_hemofilicos" formControlName="contacto_con_hemofilicos" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>INTERNAMIENTO EN INSTITUCIONES PENALES O MENTALES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="instituciones_pentales" formControlName="instituciones_pentales" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="instituciones_pentales" formControlName="instituciones_pentales" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ACUPUNTURA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="acupunturas" formControlName="acupunturas" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="acupunturas" formControlName="acupunturas" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>TATUAJES/PERFORACIONES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="tatuajes" formControlName="tatuajes" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="tatuajes" formControlName="tatuajes" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ENFERMEDADES DE TRANSMISIÓN SEXUAL</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="enfermedades_itc" formControlName="enfermedades_itc" (focus)="activarLugar('SI')" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="enfermedades_itc" formControlName="enfermedades_itc" (focus)="activarLugar('NO')" (blur)="formularioRiesgo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="lugar">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="enfermedades_itc" formControlName="enfermedades_itc" ngModel (blur)="formularioRiesgo(form)">
            </div>
        </div>
    </form>
</div>