import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { MatStepper } from '@angular/material/stepper';
import { LocationServiceService } from 'src/app/services/otrosService/location-services.service';
import Swal from 'sweetalert2';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { filterNameByPaciente } from '../../../../classes/helpers/filterNamePaciente'

@Component({
  selector: 'app-actualizar-fi-paciente',
  templateUrl: './actualizar-fi-paciente.component.html',
  styleUrls: ['./actualizar-fi-paciente.component.css']
})
export class ActualizarFiPacienteComponent implements OnInit {
  public AllPacientes = [];
  public PACIENTES_SERVICES = [];

  public estados: [];
  public paises:[];
  public municipios:[];
  public estado: string;
  public pais: string;
  public btnValidacion= true;
  public idPaciente = "";

  public pacienteData = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno:"",
    curp:"",
    lugarOrigen:"",
    fechaNacimientoPaciente:"",
    edad:"",
    telefono:"",
    estadoCivil:"",
    genero:"",
    correoPaciente:"",
    numeroExpediente:"",
    callePaciente:"",
    numeroPaciente:"",
    codigoPostal:"",
    paisNacimiento:"",
    paisActual:"",
    estado:"",
    municipioPaciente:"",
    estadoPaciente:"",
    municipio:"",
    referencia1:"",
    referencia2:"",
    razonSocial:"",
    razonSocial1RFC:"",
    cpRazonSocial: "",
    estadoRazonSocial: "",
    municipioRazonSocial:"",
    calle1RazonSocial1:"",
    correoRazonSocial:"",
    calle2RazonSocial1:"",
    razonSocial2:"",
    razonSocial2RFC:"",
    cpRazonSocial2: "",
    razonSocial2Estado:"",
    municipioRazonSocial2:"",
    calle1RazonSocial2:"",
    correoRazonSocial2:"",
    calle2RazonSocial2:"",
    contactoEmergenciaNombre:"",
    apellidoPaternoContactoDeEmergencia:"",
    apellidoMaternoContactoDeEmergencia:"",
    contactoEmergencia1Parentesco:"",
    edadContactoDeemergencia:"",
    telefonoContactoEmergencia:"",
    estadoCivilContactoDeEmergencia:"",
    generoContactoDeEmergencia:"",
    contactoEmergenciaNombre2:"",
    contactoDeEmergencia2apellidoPaterno:"",
    contactoDeEmergencia2ApellidoMaterno:"",
    contactoEmergencia2Parentesco:"",
    edadContactoDeemergencia2:"",
    contactoEmergenciaTelefono:"",
    contactoDeEmergenciaEstadoCivil:"",
    contactoDeEmergencia2Genero:"",
    status:""
  }
  public fecha;

  constructor(  
    private _pacienteService: PacientesService,
    private activatedRoute: ActivatedRoute, 
    private _router: Router
    ) { }

  ngOnInit(): void {
    this.idPaciente = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerPacientes()
  }
  buscarPaciente(nombre: string) {
    if (nombre.length != 0) {
      if (nombre.length > 3) {
        const nameFilter = filterNameByPaciente(this.PACIENTES_SERVICES, nombre);
        this.AllPacientes = nameFilter; 
        nombre = "";
      }
    } else {
      this.AllPacientes = [];


    }
  }
  SelectPac(item) {
    this.AllPacientes = [];
    Swal
    .fire({
      title: "¿Desea cambiar de Usuario Actual:" +this.pacienteData.nombrePaciente +" "+ this.pacienteData.apellidoPaterno +"?",
      text:   "Cambiar por::" +item.nombrePaciente +" "+ item.apellidoPaterno,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: "SI",
      cancelButtonText: "CANCELAR",
    })
    .then(resultado => {
      
      if (resultado.value) {
        let dataID = {
          _id: this.idPaciente
        }
        localStorage.setItem('ActualizarUsuarioID',  JSON.stringify(dataID));
        this._router.navigateByUrl('actualizar/fi/pacientes/'+item._id);
        setTimeout(() => {
        window.location.reload();
        }, 250);
      } else {
        
      }
    });

  }
  obtenerPacientes() {
    this._pacienteService.getPacientesAll()
      .subscribe((data: any) => {
        if (data.ok) {
          this.PACIENTES_SERVICES = data['data'];
          this.PACIENTES_SERVICES.forEach(element => {
            for (element = 0; element < 10; element++) {
              return element;
            }
          });
        }
      });
  }
  obtenerPacientesPorId() {

    this._pacienteService.getPacienteBtID( this.idPaciente ).
    subscribe(( data: any ) => {
      if (data.ok) {
        this.setPacinteId(data.paciente);
      }
    })

  }

  setPacinteId(data: any) {
    this.pacienteData = data;
  }

  actualizarPaciente(){
    
    /* console.log(this.pacienteData) */
    /* this._pacienteService.actualizarFiPacientes( this.idPaciente, this.pacienteData )
    .subscribe( (res: any) => {

      if(res.ok) {
        Swal.fire('Datos actualizados', '', 'success');
        setTimeout(() => {
            
          this._router.navigateByUrl('/dashboard');
        }, 2000);
        
      }else {
        Swal.fire('Revisa el internet', 'Intenta de nuevo', 'error');
      }
    }) */

  }
}
