<section class="container-fluid">
    <div class="card">
        <br>
        <div class="container">
            <div class="row">
                <div class="col">
                    <p> <span> Nombre:  {{paciente.nombrePaciente}} {{paciente.apellidoPaterno | uppercase}} {{paciente.apellidoMaterno  | uppercase }}</span> </p>
                </div>

                <div class="col">
                    <p> <span> Edad: {{paciente.edad}}  </span> </p>
                </div>

                <div class="col">
                    <p> <span> Sexo: {{paciente.genero | uppercase}} </span> </p>
                </div>

            </div>
            <div class="row">
                <div class="col">
                    <p> CURP: {{ paciente.curp }} </p>
                </div>
                <div class="col">
                    <p>Fecha: {{fecha}} {{hora}} </p>
                </div>
                <div class="col">
                    <p>Registro: {{paciente.registro}} </p>
                </div>
            </div>


            <div class="row">

                <div class="col">
                    <p>Dirección: {{paciente.callePaciente}} {{ paciente.estadoPaciente }} </p>
                </div>

                <div class="col">
                    <p> ID: {{paciente._id }} </p>
                </div>
                <div class="col"></div>
            </div>
        </div>
    </div>
</section>