<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE PACIENTES'" [rol]="'pacientes'"></app-titulos>

    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Ejemplo: CHUCHITA PEREZ" [(ngModel)]="filtropacientes"  (keyup)="typeSearchPatients()"  class="form-control" id="busquedaPaciente">
            </div>
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/registro', 'pacientes']">NUEVO</a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-4 text_header">
                            <p>Nombre</p>
                        </div>

                        <div class="col text_header">
                            <p>Género</p>
                        </div>
                        <div class="col text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col text_header">
                            <p>Teléfono</p>
                        </div>
                        <div class="col text_header">
                            <p>Expediente</p>
                        </div>
                        <div class="col text_header">
                            <p>Servicios</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let paciente of pacientes2 | paginate:{id:'listapacientes',
                                                              itemsPerPage: 7,
                                                              currentPage:pagina,
                                                              totalItems:totalpaciente}">
                        <div class="row puntero" [routerLink]="['/paciente', paciente._id]">
                            <div class="col-md-4" style="text-align: center;">
                                <p>
                                    {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }}

                                </p>
                                <span class="puntero"></span>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{paciente.genero | uppercase}}
                                    <br></p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ paciente.edad }}<br>
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ paciente.telefono }} <br>
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p *ngIf="paciente.numeroExpediente!=undefined">
                                    {{ paciente.numeroExpediente }} <br>
                                </p>
                                <p *ngIf="paciente.numeroExpediente==undefined">
                                    Sin Expediente <br>
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <a [routerLink]="['/paciente', paciente._id]">
                                                <i class="fa fa-eye eyeS puntero" id="ver"></i>
                                            </a>
                                        </p>
                                    </div>
                                    <div class="col" *ngIf="rol == 'Admin' || nombre == 'Liliana Diaz'">
                                        <i class="fas fa-edit" id="ver" [routerLink]="['/actualizar/fi/pacientes/', paciente._id]"></i>   
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="pacientes2 == []">
                        <p>
                            Aún no hay pacientes registrados
                        </p>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>

    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
        <br>
        <p style="color: white"> Cargando... </p>
    </ngx-spinner>
</div>