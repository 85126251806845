import { ArrayType } from '@angular/compiler';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reaccion-vasovagal',
  templateUrl: './reaccion-vasovagal.component.html',
  styleUrls: ['./reaccion-vasovagal.component.css']
})
export class ReaccionVasovagalComponent implements OnInit {
  
  public reaccionesVL = [];
  public reaccionesVM = [];
  public reaccionesVS = [];
  public value='';
  public value2 = '';
  public value3 = '';
  @Output() reaccionesVasovagalleve = new EventEmitter();
  @Output() reaccionesVasovagalmoderada = new EventEmitter();
  @Output() reaccionesVasovagalsevera = new EventEmitter();

  @Output() otrosSintomasLeve = new EventEmitter();
  @Output() otrosSintomasModerada = new EventEmitter();
  @Output() otrosSintomasSevera = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  vasovagalLeve(event){
    this.reaccionesVL.push(event);
    this.reaccionesVasovagalleve.emit(this.reaccionesVL)
  }

  vasovagalModerada(event){
    this.reaccionesVM.push(event);
    this.reaccionesVasovagalmoderada.emit(this.reaccionesVM)
  }

  vasovagalSevera(event){
    this.reaccionesVS.push(event);
    this.reaccionesVasovagalsevera.emit(this.reaccionesVS)
  }

  otroleve(form: NgForm){
    this.value = form.form.value.otrosSintomasLeves
    this.otrosSintomasLeve.emit(this.value)
  }

  otroModerado(form:NgForm){
    this.value2 = form.form.value.otrosSintomasModerados
    this.otrosSintomasModerada.emit(this.value2)
  }

  otroSevero(form:NgForm){
    this.value3 = form.form.value.otrosSintomasSevero
    this.otrosSintomasSevera.emit(this.value3)
  }

}
