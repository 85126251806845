import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'graficas-sv',
  templateUrl: './graficas-sv.component.html',
  styleUrls: ['./graficas-sv.component.css']
})
export class GraficasSvComponent implements OnInit {
  
  @Input()  historialSginos = [];
  @Input() paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };
  // public tallas = [];
  public id = '';
  
  constructor( 
    private _HistoriaClinicaService: HistoriaClinicaService,
    private _route: ActivatedRoute,
  ) { }
  
  ngOnInit(): void {
    // this.verSignosParaGraficar();
    // this.id = this._route.snapshot.paramMap.get('id');
    /* console.log( this.historialSginos ); */
  }

  // Obtener los signos Vitales Anteriores
 




  public lineChartData: ChartDataSets[] = [
    // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    { data: [0.96, 1.0, 1.12], label: 'Historial Talla', yAxisID: 'y-axis-1' }
  ];
  public linePeso: ChartDataSets[] = [
    {data: [15.20, 22.5, 23.1]}
  ];
  public lineChartLabels: Label[] = ['23/12/20', '29/12/20', '31/12/20'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
     { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  // public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;


  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number): number {
    return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    /* console.log(event, active); */
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    /* console.log(event, active); */
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.forEach((x, i) => {
      const num = this.generateNumber(i);
      const data: number[] = x.data as number[];
      data.push(num);
    });
    this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  }

  public changeColor(): void {
    this.lineChartColors[2].borderColor = 'green';
    this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  }

  public changeLabel(): void {
    this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  }
}

