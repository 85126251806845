import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { utilidades } from '../../../interfaces/utilidades';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { SedesService } from 'src/app/services/sedes/sedes.service';
import  Dates  from '../../../classes/dates/date.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-utilidad-servicio',
  templateUrl: './utilidad-servicio.component.html',
  styleUrls: ['./utilidad-servicio.component.css']
})

export class UtilidadServicioComponent implements OnInit {

  public pagina = 0;
  public totalpaciente:string;
  public data:any[];
  public sedes:any[];
  public totales:number = 0;
  public charatecter$:Subscription;
  public busqueda = {
        sede:"TLYC01",
        fecha:""
  }
  public today ="";
  public metodo = [];
  
  constructor(private _servicios: ServiciosService) {}

  ngOnInit(): void {
    const date = new Dates();
    this.today = date.getDate();
    this.busqueda.fecha = this.today;
    this.buscarPorFecha();
  }

  getVentas(){
    this.charatecter$ =  this._servicios.getSells( this.busqueda )
    .subscribe( (data: utilidades) => {
      this.setData(data['data'])
      this.totalpaciente = data['data'].results;
    });
  }

  setData( ventas ){
    this.data = ventas;
    this.calcularTotales();
  }

  busquedaFecha(fecha: NgForm){
    this.busqueda.fecha = fecha.value;
    this.buscarPorFecha();
  }

  buscarPorFecha(){
    this._servicios.getSellsForDate( this.busqueda )
    .subscribe( data => {
      this.totalpaciente = data['data'].results;
      this.data = data['data'];
      this.calcularTotales();
    });
  }

  calcularTotales(){
    this.totales = 0;
    this.data.forEach( (elemento:any) => {
      if( elemento.status === 'CANCELADO'  ){
        this.totales = this.totales + 0
      }else{
        if(this.busqueda.sede !== 'TLYC01'){
          if(elemento.ganancia === undefined){
            this.totales = this.totales + 0;
          }else {
            this.totales = this.totales + elemento.ganancia;
          }
        }else {
          this.totales = this.totales + elemento.totalCompra;
        }
        if (elemento.efectivo) {
          this.metodo.push('EFECTIVO')
        } else if(elemento.tarjetCredito){
          this.metodo.push('TARJETA DE CREDITO')
        }else if(elemento.tarjetaDebito){
          this.metodo.push('TARJETA DE DEBITO')
        }else if(elemento.transferencia){
          this.metodo.push('TRANFERENCIA')
        }else{
          this.metodo.push('SIN METODO')
        }
      }
    });
  } 

}
