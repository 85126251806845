<section class="container-fluid" >
    <article class="row" >
        <header class="col-md-12">
            <app-titulos [title]="'VALIDACION DE RESULTADOS'"></app-titulos>
        </header>
    </article>
    <article>
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
    </article>

    <article class="row">
        <form class="col-md-12 card">
            
        </form>
    </article>
</section>