import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hoja-de-reporte-patologia',
  templateUrl: './hoja-de-reporte-patologia.component.html',
  styleUrls: ['./hoja-de-reporte-patologia.component.css']
})
export class HojaDeReportePatologiaComponent implements OnInit {

  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:''
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
