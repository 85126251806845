<div class="container">
    <div class="col-md-12">
        <h5 style="margin-top: 15px;">
            Método de pago
        </h5>
    </div>
    <div class="col-md-12">
        <div class="row mt-3">
            <div class="col">
                <input type="checkbox" [(ngModel)]="metodos.efectivo" (change)="efectivo()">
                <label>Pago en efectivo</label>
            </div>
        </div>
        <div class="row">    
            <input type="number" class="cantidad" *ngIf="metodos.efectivo" (keyup)="agregarEfectivo($event)">
        </div>
        <div class="row">
            <div class="col">
                <input type="checkbox" [(ngModel)]="metodos.transferencia" (change)="transfer()">
                <label>Transferencia bancaria</label>
            </div>
        </div>
        <div class="row">    
            <input type="number" class="cantidad" *ngIf="metodos.transferencia" (keyup)="agregarTransfer($event)">
        </div>
        <div class="row">
            <div class="col">
                <input type="checkbox" [(ngModel)]="metodos.tarjetCredito" (change)="credito()">
                <label>Tarjeta de Crédito {{metodos.comisionCredito}}</label>
            </div>
        </div>
        <div class="row">    
            <input type="number" class="cantidad" *ngIf="metodos.tarjetCredito" (keyup)="agregarIvaCredito($event)">
        </div>
        <div class="row">
            <div class="col">
                <input type="checkbox" [(ngModel)]="metodos.tarjetaDebito" (change)="debito()">
                <label>Tarjeta de Débito {{metodos.comisionDebito}}</label>
            </div>
        </div>
        <div class="row">    
            <input type="number" class="cantidad" *ngIf="metodos.tarjetaDebito" (keyup)="agregarIvaDebito($event)">
        </div>
    </div>
</div>
