import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-antecedentes-personales',
  templateUrl: './antecedentes-personales.component.html',
  styleUrls: ['./antecedentes-personales.component.css']
})
export class AntecedentesPersonalesComponent implements OnInit {

  @Output() antecedentesPersonales = new EventEmitter<string>();
  formulario: FormGroup;
  public lugar = false;
  public alergia = '';

  constructor() { }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      alcohol_consumido: new FormControl('NO'),
      medicamento_regular: new FormControl('NO'),
      consume_etretinato: new FormControl('NO'),
      tratamiento_dental: new FormControl('NO'),
      cirugia_mayor: new FormControl('NO'),
      cirugia_menor: new FormControl('NO'),
      alergias: new FormControl('NO'),
      inmunizaciones: new FormControl('NO'),
    });
    this.antecedentesPersonales.emit(this.formulario.value);
  }

  activarLugar(tipo){
    if(tipo == 'SI'){
      this.lugar = true;
      this.alergia = ""
    }else{
      this.lugar = false;
      this.alergia = "NO"
    }
  }


  formularioAntecedentesPer(form: NgForm){
    if(this.alergia == "SI"){
      form.value.alergias = this.alergia;
    }
    this.antecedentesPersonales.emit(form.form.value);
  }

}
