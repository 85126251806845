<app-titulos [title]="'BITACORA DE HOSPITALIZACIÓN'"></app-titulos>
<div class="form-group">
    <input type="text" name="filterPost" placeholder="Ejemplo: CHUCHITA PEREZ" class="form-control" id="busquedaPaciente">
</div>
<div id="content">
    <div class="card-columns animate bounceIn">
        <a *ngFor="let item of [1,1,1,1,1,1,1,2]">
            <div class="card" id="card">
                <div class="circulo"></div>
                <span>VICTOR MANUEL ARAGON CASTELLANOS</span>
                <h2 id="h2">Cama: 1 </h2>
                <div class="card-footer">
                    <button [routerLink]="['/hospitalizado']">DETALLES</button>
                </div>
            </div>
        </a>
    </div>
</div>
<!-- <div class="offset-md-4">
    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
</div> -->
