import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators} from '@angular/forms';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';import { Router } from '@angular/router';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { eventsFechas } from 'src/app/functions/validar';

@Component({
  selector: 'app-registro-servicios',
  templateUrl: './registro-servicios.component.html',
  styleUrls: ['./registro-servicios.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistroServiciosComponent implements OnInit {

  public schedules = [];
  public counterAllService  =  0;
  public txtSearchInput = "";
  public productsAndServiceDB = [];
  public productsAndServiceJson = [];
  public doctores = [];
  public sedes = [];
  public servicios:any[]=[];
  public data = {
    nombre: '',
    idServicio: '',
    tipoServicio: '',
    costo: 0,
    categoria: '',
    profesionalAREalizar: '',
    horario: [],
    descripcion: '',
    participante: '',
    color:'',
    productosOMateriales: []
  }
  public model: Date[];
  public newFormValor:any;
  public newFormProductos:any;

  constructor(private formBuilder: FormBuilder,
              private _almacenService: AlmacenService,
              private _DoctoresService: PaquetesService,
              private _service: IntegradosService,
              private _BancoService: BancoService,
              private _router: Router,) { }

  ngOnInit(): void {
    this.listadoDoctores();
    this.obtenerSedes();
    this.creacioDeForm();
  }

  creacioDeForm() {
    this.newFormValor =  this.formBuilder.group({ 
      nombre: ['', {validators: [Validators.required]}],
      color: ['', {validators: [Validators.required]}],
      horario: this.formBuilder.array([]),
    });
    this.addValoreDeReferencia();
  }

  addValoreDeReferencia() {
    const valoresInput =  this.formBuilder.group({
      dia: '',
      horaInicio:'',
      horafin:'',
    })
    this.valoresDeReferencias.push( valoresInput );
  }

  get valoresDeReferencias() {
    return this.newFormValor.get('horario') as FormArray;
  }

  removeValores( i: number ){
    if( this.valoresDeReferencias.length  > 1 ) {
      this.valoresDeReferencias['controls'].forEach((element, index) => {
        if (index == i) {
          this.valoresDeReferencias['controls'].splice(index, 1)
        }
      });
    }
  }

  listadoDoctores(){
    this._DoctoresService.getMedicos().subscribe((data:any)=>{
      this.doctores = data.data
    })
  }

  obtenerSedes(){
    this._BancoService.getSedes().subscribe((data:any) => {
      this.sedes = data.data;
    });
  }

  // funcion que se encarga de traer los servicios
  getServicios(){
    const servicio = this.newFormProductos.value.tipoServicio;
    this._service.getObtener(servicio).subscribe((res:any) => {
      this.servicios = res.data;
    },(err) => {
        /* console.log(<any>err); */
    });  
  }

  // buscamos en la DB los productos y servicio en la DB
  buscarProductosYMateriales(event:any){
    this.productsAndServiceDB = [];
    const searchTxtJson = {
      nombre: event.target.value
    }
    if( event.target.value.length >= 3 ) {
      this._almacenService.obtenerLosProductosYMateriales(  searchTxtJson )
      .subscribe((data:any) => {
        this.setProductsAndService(data.data)
      });
    }else {
      this.productsAndServiceDB = [];
    } 
  }

  //funcion que se encarga de agregar y validar que no haya producto y materiales repetidos en el json
  addANewMaterial(value:any) {
    /* console.log(value); */
    let ProductosYServicios = {
      _id: value._id,
      nombre: value.nombre,
      cantidad: 0,
    }
    // Si es la primera vez que se agrega un producto o material que se agregue al array
    if(this.productsAndServiceJson.length == 0){
      this.productsAndServiceJson.push(ProductosYServicios)
    }else{
      let encontrado;
      encontrado = this.productsAndServiceJson.findIndex(elelemt => elelemt._id == value._id);
      if (encontrado >= 0) {
        alert("No lo puedes agregar otra vez");
      } else {
        this.productsAndServiceJson.push(ProductosYServicios);
      }
    }
    this.productsAndServiceDB = [];
    this.txtSearchInput = "";
  }

  //setea los productos y materiales con los que cuenta el servicio
  setProductsAndService(data:any) {
    this.productsAndServiceDB = data;
  }
 
  //Elimina del array el elemento que se pinta en el html
  deleteProductFromJson( index:number) {
    this.productsAndServiceJson.splice(index, 1);
  }

  // funcion que agrega al json la cantidad
  addACantidad(cantidad, index, id ){
    let ProductoEncontrado = this.productsAndServiceJson.findIndex(element => element._id == id)
    this.productsAndServiceJson[index].cantidad = cantidad
  }

  // hacemos el post de los servicios
  onSubmit(){
    this.data.horario = this.schedules
    this.data.productosOMateriales = this.productsAndServiceJson
    const valores = {
      nombre:this.newFormValor.value.nombre,
      horario:{
        nombreDeServicio:this.newFormValor.value.nombre,
        fechas:eventsFechas(this.newFormValor.value)
      }
    }
    this._almacenService.crearServicioDepartamento(valores).subscribe(resp=>{
      Swal.fire({
        icon: 'success',
        title: '',
        text: 'SE REGISTRO EXITOSAMENTE EL SERVICIO',
      });
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error' );
    });
    //    if(!this.data) {      
    //     Swal.fire({
    //       icon: 'error',
    //       title: '',
    //       text: 'INGRESA TODOS LOS CAMPOS',
    //     });
    //   return
    //   }else {      
    //      this._almacenService.nuevoServicioCensur( this.data )
    //       .subscribe( (data:any) => {  
    //         if(data.ok) { 
    //           Swal.fire({
    //             icon: 'success',
    //             title: '',
    //             text: 'SE REGISTRO EXITOSAMENTE EL SERVICIO',
    //           });
    //           this._router.navigateByUrl('/lista/servicios');
    //         }else {
    //           Swal.fire({
    //             icon: 'error',
    //             title: '',
    //             text: 'INTENTALO MÁS TARDE',
    //           });

    //         }
    //       });
    // }
  }

  //agregamos un nuevo horario a los servicios 
  addSchedule(  horario : String ) {  
    this.getCounterAllSerivices();
    if( this.schedules.length == 0 ) {
      this.schedules.push( horario );
    }else {
      for( let i = 0; i <= this.schedules.length; i++ ) {
        if( this.schedules.length >= 1 && horario === "Todos los dias" ) {
          alert('No se puede agregar');
          return;
        }
        if( this.schedules[i] === "Todos los dias" ) {
          alert('No puedes agregar mas días');
          return
        }else if( horario != this.schedules[i] ) {
          this.schedules.push(horario);
          return; 
        }
      }
    }
  }

  //obtenemos el count de los serivicios registrados para sacar el ID consecutivo
  getCounterAllSerivices ( ){
    this._almacenService.getCounterAllDocumentsSerivices()
    .subscribe((data:any) => {
      // seteamos los servicios 
      if(data.ok) {
        this.counterAllService = data.data;
      }else{
        alert("Intenta mas tarde");
        this.counterAllService = 0
      }
    })
  }

  // generamos el ID de los servicios 
  generateAnId(value:any){
    const { nombre, tipoServicio, categoria, profesionalARealizar  } = value;
    const nombreCutted = nombre.slice( 0, 3 ).toUpperCase();
    const tipoServicioCutted = tipoServicio.slice(0,3).toUpperCase();
    const categoriaCutted = categoria.slice(0,3).toUpperCase();
    const profesionalARealizarCutted = profesionalARealizar.slice(0,3).toUpperCase();
    const idService = `${ nombreCutted}/${tipoServicioCutted}/${categoriaCutted}/${profesionalARealizarCutted}/${this.counterAllService}/${new Date().getMinutes()}/${new Date().getSeconds()} ` 
    value.idServicio = idService;
  } 

  //Seleccion de servicio
  SeleccionServicio(eve){
    this.data.tipoServicio = eve
  }

  SeleccionCategoria(eve){
    this.data.categoria = eve
  }
  
  SeleccionProfesional(eve){
    this.data.profesionalAREalizar = eve
  }
}