<div class="container-fluid">
    <h1 class="centerxd">HOJA DE SERVICIOS RECEPTOR</h1>

    <div class="card">
        <div class="row">
          <div class="col-4">NOMBRE COMPLETO: </div>
          <div class="col-4">CURP: </div>
          <div class="col-4">EDAD: </div>
        </div>
      
      <div class="row">
        <div class="col-4">TELEFONO: </div>
        <div class="col-4">GENERO: </div>
        <div class="col-4">ID: </div>
      </div>

      <div class="row" style="margin-bottom: 30px;">
        <div class="col-4">FECHA DE NACIMIENTO: </div>
        <div class="col-4">DOMICILIO: </div>
        <div class="col-4">C.P: </div>
      </div>
    </div>

    <div class="espaciador">
  <div class="row">
    <div class="col-md-12 col-lg-12">
        <div class="card">
            <div class="card-heder header text-center">
                <div class="text_header" >
                    <p>
                      DETALLE DEL PEDIDO
                    </p>
                </div>
                <div class="row">
                  <div class="col text_header" >
                    <p>No.</p>
                  </div>
                  <div class="col text_header">
                    <p>CANTIDAD</p>
                  </div>
                  <div class="col text_header">
                    <p>PRODUCTO</p>
                  </div>
                  <div class="col text_header">
                    <p>PRECIO UNITARIO</p>
                  </div>
                  <div class="col text_header">
                    <p>PRECIO ACUMULADO</p>
                  </div>
                </div>
            </div>

            <div class="card-body text-center">
                    <div class="row">
                        <div class="col">
                            <p>
                                01
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                              02
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                              CE
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                                $200.00
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                                $400.00
                            </p>
                        </div>
                    </div>
            </div>

            <div class="card-footer" >
                <div class="row" style="justify-content: end !important;">
                    <div class="text-footer" >
                        <p>
                            TOTAL: $0.00    
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</div>

<div class="espaciador">
<div class="row">
    <div class="col-md-12 col-lg-12">
        <div class="card">
            <div class="card-heder header text-center">
                <div class="text_header" >
                    <p>DETALLE DEL INTERCAMBIO</p>
                </div>
                <div class="row">
                    <div class="col text_header" >
                        <p>No.</p>
                    </div>
                    <div class="col text_header">
                        <p>CANTIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>PRODUCTO</p>
                    </div>
                    <div class="col text_header">
                        <p>PRECIO UNITARIO</p>
                    </div>
                    <div class="col text_header">
                        <p>PRECIO ACUMULADO</p>
                    </div>
                </div>
            </div>

            <div class="card-body text-center">
                    <div class="row">
                        <div class="col">
                            <p>
                                01
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                              02
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                              CE
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                                $200.00
                            </p>
                        </div>
                        <div class="col" >
                            <p>
                                $400.00
                            </p>
                        </div>
                    </div>
            </div>

          <div class="card-footer">
            <div class="row"style="justify-content: end !important;" >
              <div class="text-footer" >
                  <p>
                    TOTAL: $0.00
                  </p>
               </div>
            </div>
          </div>

        </div>
    </div>
</div>
</div>

</div>