import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

  constructor(private _http:HttpClient) { }

  // Obtener las sedes
  getSedes(){
    const url = `${URL}/ver/sedes`;
    return this._http.get(url);
  }

  agregarEmpleado(body: any){
    const url = `${URL}/personal/nuevo`;
    return this._http.post(url, body);
  }

}
