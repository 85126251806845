import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedidos-socios',
  templateUrl: './pedidos-socios.component.html',
  styleUrls: ['./pedidos-socios.component.css']
})
export class PedidosSociosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
