<div class="content">
    <form #form='ngForm'>
        <div class="row">
            <div class="col-md-12">
                <input type="text" class="form-control" placeholder="C.I.E. 10" name="cie10" (keyup)="obtenerDiagnosticoUno()" id="cie10" [(ngModel)]="buscarDiagnostico">
                <br>
                <div style="overflow-x: scroll; height: 5rem;">
                    <table>
                        <tr *ngFor="let item of diagnosticos">
                            <td class="pointer" (click)="agregarDiagnosticoUno( item.NOMBRE  )"> {{ item.NOMBRE }} </td>
                        </tr>
                    </table>
                </div>

                <div class="col-md-12">
                    <div class="card">
                        <ul *ngFor="let item of hojaEvolucion.diagnosticos">
                            <li>
                                {{ item.diagnostico }}
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <p> CANDIDATO A DONACIÓN </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="candidato_donacion" id="candidato_donacion" class="form-control" ngModel (blur)="validacion(form)">
                        <option value="SI" > SI </option>
                        <option value="NO" > NO </option>
                    </select>
                </div>
            </div>
        </div>


        <!-- <div class="row">
            <div class="col">
                <p> DIFERIDO </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="candidato_donacion" id="candidato_donacion" class="form-control" ngModel (blur)="formularioDiagnostico(form)">
                        <option value="SI" > SI </option>
                        <option value="NO">  NO  </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p> APTO </p>
            </div>
            <div class="col">
                <div class="col">
                    <select name="apto" id="apto" class="form-control" ngModel (blur)="formularioDiagnostico(form)">
                        <option value="SI" > SI </option>
                        <option value="NO">  NO  </option>
                    </select>
                </div>
            </div>
        </div> -->

        <div class="row" *ngIf="valid">
            <div class="col">
                <p>Tiempo de exclusion</p>
            </div>
            <div class="col">
                <input type="text" name="tiempo_exclusion" ngModel (blur)="formularioDiagnostico(form)">
            </div>
        </div>
        <!-- <div class="row" *ngIf="valid">
            <div class="col">
                <p>Fecha de termino de la exclusion</p>
            </div>
            <div class="col">
                <input type="date" name="fecha_exclusion" ngModel (blur)="formularioDiagnostico(form)">
            </div>
        </div> -->

        <div class="row" *ngIf="valid">
            <div class="col-md-12">
                <p> MOTIVO DE EXCLUSIÓN </p>
                <textarea name="motivo_exclusion" id="motivo_exclusion" cols="30" rows="10" class="form-control" ngModel (blur)="formularioDiagnostico(form)"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <p> OBSERVACIONES </p>
                <textarea name="observaciones_diagnostico" id="observaciones_diagnostico" cols="30" rows="10" class="form-control" ngModel (blur)="formularioDiagnostico(form)"></textarea>
            </div>
        </div>
    </form>
</div>