import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URL } from "src/app/config/conf";
import { proveedorModel } from "../proveedor-model/proveedor.model";

@Injectable({
    providedIn: 'root'
})

export class ProveedorService{

    private url = URL

    constructor( private http: HttpClient) {  }

    //Postear los proveedores
    crearProveedor( proveedor: proveedorModel ){
      return this.http.post(`${ this.url }/registro/proveedor`, proveedor);
    }
    
    //Obtener los proveedores
    getProveedor(){
     return this.http.get(`${ this.url }/ver/proveedores`);
    }

    obtenerProveedorId(id){
      return this.http.get(`${ this.url }/ver/proveedor/por/id/${id}`);
    }

    updateProveedorId(id, body){
      return this.http.put(`${ this.url }/actualizar/proveedor/${id}`,body);
    }

    eliminarProveedor(id){
      return this.http.get(`${ this.url }/desactivar/proveedor/por/id/${id}`);
    }
    
}