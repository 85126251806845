<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <h1 class="centerxd">HISTORICO DE ESTUDIOS</h1>
        </div>
        <div class="col-md-3">
            <a type="button" class="btn btn-secondary boton_contra2">NUEVO</a>
        </div>
    </div>

    <div class="row bitacora">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col text_header">
                            <p>No</p>
                        </div>
                        <div class="col text_header">
                            <p>CLAVE</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>QUIMICO</p>
                        </div>
                        <div class="col text_header">
                            <p>METODO</p>
                        </div>
                        <div class="col text_header">
                            <p>OBSERVACIONES</p>
                        </div>
                        <div class="col text_header">
                            
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="row">
                            <div class="col">
                                <p>
                                    01
                                </p> 
                            </div>
                            <div class="col">
                                <p>
                                    BDSSERO012022021
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    SEROLOGIA
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    12-02-2021
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    QFB. ALANA TORRES MILAN
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    MULTIPLES METODOS
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    NINGUNA OBSERVACION
                                </p>
                            </div>
                            <div class="col">
                                <label style="margin-left: 1rem;" class="btn btn-outline-success">
                                    <i class="fas fa-edit"></i>
                                </label>
                                <label class="btn btn-outline-danger" style="margin-left: 1rem;">
                                    <i class="fas fa-trash-alt"></i>
                                </label>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>