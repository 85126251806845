<div class="container-fluid">
    <section class="center">
        <div class="row">
            <div class="col-md-8">
                <h1 class="centerxd"> {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase}} {{ paciente.apellidoMaterno | uppercase }} </h1>
            </div>
            <div class="col-md-4">
                <p>
                    <span> {{fecha}} </span>
                </p>
                <p>
                    <span>
                        <label for="consultas">  Consultas: </label>  {{ paciente.consultas }} 
                    </span>
                </p>
                <p>
                    <button class="estudios" [routerLink]="['/estudio/por/paciente/', id]">Estudios</button>
                </p>
            </div>
        </div>
    </section>
    <section class="container-fluid">
        <div class="row">
            <div class="col-md-7">
                <app-select-servicios *ngIf="paquetes.length == 0"></app-select-servicios>
            </div>
    </div>
    </section>
    <br>

    <ficha-info-user [pacienteInfo]="paciente" [title]="false"></ficha-info-user>

    <h3 class="center color-text mt-5"> Tus paquetes </h3>

    <div class="card-columns">
        <div *ngFor="let item of paquetes">
            <div class="col" *ngIf="paquetes.length != 0">
                <div class="container">
                    <div class="card center" name="card" id="card" style="height: 24rem !important;">
                        <img class="card-img-top img" [src]="item.paquete.icon" alt="Card image cap" style="width: 260px;" [routerLink]="['/paquete-paciente', item._id]">
                        <div class="card-body">
                            <h5 class="card-title color-text"> {{ item.paquete.nombrePaquete }} </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="cursor: pointer;">
            <div class="card center" name="card" id="card" style="height: 24rem!important;" [routerLink]="['/solicitar/paquete/', id]">
                <img class="card-img-top img" src="../../../../assets/icons/serviciosIconos/add.svg" alt="Card image cap" style="width: 200px; margin-top: 2rem;">
                <h5 class="card-title color-text" style="margin-top: 2rem; cursor: pointer !important;"> AGREGAR PAQUETE</h5>
            </div>
        </div>

        <div class="container" style="cursor: pointer;">
            <div class="card center" name="card" id="card" style="height: 24rem!important;" [routerLink]="['/cargar/otros/estudios', id]">
                <img class="card-img-top img" src="../../../../assets/icons/nuevosEstudios.png" alt="Card image cap" style="width: 200px; margin-top: 2rem;">
                <h5 class="card-title color-text" style="margin-top: 2rem; text-transform: uppercase; cursor: pointer!important;"> Otros estudios</h5>
            </div>
        </div>
    </div>
</div>