import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import * as moment from 'moment';
import  jsPDF from  "jspdf" ;
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-paciente-estudios-lab',
  templateUrl: './paciente-estudios-lab.component.html',
  styleUrls: ['./paciente-estudios-lab.component.css']
})
export class PacienteEstudiosLabComponent implements OnInit {

  public servicio = '';

  constructor(private _route:ActivatedRoute) { }

  ngOnInit(): void {
    this.servicio = this._route.snapshot.paramMap.get('servicio');
  }

}
