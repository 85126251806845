import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-practicas-riesgo',
  templateUrl: './practicas-riesgo.component.html',
  styleUrls: ['./practicas-riesgo.component.css']
})
export class PracticasRiesgoComponent implements OnInit {

  @Output() riesgo = new EventEmitter<string>();
  formulario: FormGroup;
  public lugar = false;

  constructor() { }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      transfusiones_prev: new FormControl('NO'),
      pago_por_donar_sangre: new FormControl('NO'),
      uso_drogas: new FormControl('NO'),
      heterosecual_promiscuo: new FormControl('NO'),
      homoxesual: new FormControl('NO'),
      bisexual: new FormControl('NO'),
      pago_por_relaciones: new FormControl('NO'),
      contacto_con_hemofilicos: new FormControl('NO'),
      instituciones_pentales: new FormControl('NO'),
      acupunturas: new FormControl('NO'),
      tatuajes: new FormControl('NO'),
      enfermedades_itc: new FormControl('NO'),
    });
    this.riesgo.emit(this.formulario.value);
  }

  activarLugar(tipo){
    if(tipo == 'SI'){
      this.lugar = true;
    }else{
      this.lugar = false;
    }
  }

  formularioRiesgo(form: NgForm){
    this.riesgo.emit(form.form.value);
  }

}
