<div class="content">
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p> ¿HA CONVIVIDO CON ENFERMOS DE HEPATITIS? </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="enfermos_hepatitis" formControlName="enfermos_hepatitis"  (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="enfermos_hepatitis" formControlName="enfermos_hepatitis"  (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <p>¿ ALGUNA VEZ LE HAN HECHO DETECCIÓN DE ?</p>
        <div class="row">
            <div class="col">
                <p>V.I.H</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="vih_deteccion" formControlName="vih_deteccion" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="vih_deteccion" formControlName="vih_deteccion" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>HEPATITIS B</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="hepatitisb_deteccion" formControlName="hepatitisb_deteccion" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="hepatitisb_deteccion" formControlName="hepatitisb_deteccion" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>HEPATITIS C</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="hepatitisc_deteccion" formControlName="hepatitisc_deteccion" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="hepatitisc_deteccion" formControlName="hepatitisc_deteccion" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>¿USTED O SU PAREJA HAN TENIDO UNA ENFERMEDAD DE TRANSMISIÓN SEXUAL?</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="enfermedad_de_transmision" formControlName="enfermedad_de_transmision" (focus)="activarLugar('SI')" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="enfermedad_de_transmision" formControlName="enfermedad_de_transmision" (focus)="activarLugar('NO')" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="lugar">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="enfermedad_de_transmision" formControlName="enfermedad_de_transmision" ngModel (blur)="formularioAntecedentes(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ODINOFAGÍA (DOLOR AL DEGLUTIR)</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="odinofagia" formControlName="odinofagia" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="odinofagia" formControlName="odinofagia" (blur)="formularioAntecedentes(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>