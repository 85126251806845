<!-- <link rel="stylesheet" type="text/css" href="./resultados-ultrasonido.component.css" media="all" />
<link rel="stylesheet" type="text/css" href="./resultados-ultrasonido.component.css" media="print" />
<div class="col-lg-12">
    <div class="container">

        <div id="tablaData">
            <section class="row">
                <div class="col-md-12">
                    <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
        
                </div>
            </section>
         
            <div class="card">
                <h1 class="text-center">REPORTE RAYOS X</h1>
                <h3 class="text-center"> {{titulo}}</h3>
            </div>
        
            <br>
            <h5>INTERPRETACION</h5>
            <br>
        
          
            <div class="card">
        
                <div class="text-justify">
                    {{machoteEditado}}
                </div>
        
            </div>
            <br>
            <h5>DIAGNOSTICO</h5>
            <div class="card">
                <div class="text-justify">
                    {{diag}}
                </div>
            </div>
        
            <br>
            <h5>OBSERVACIÓNES</h5>
            <div class="card">
                <div class="text-justify">
                    {{ observa}}
                </div>
            </div>
            <br>
        
            <h5>IMAGENES</h5>
            <div class="row">
                <div class="col" *ngFor="let item of imagenes">
                    <div class="usg">
                        <img class="img-fluid  img-thumbnail" src="https://horizonte-backend.herokuapp.com/img/{{item}}">
                    </div>
        
                </div>
            </div>
            <br>
        
            <h5 class="text-center"> {{userXRAY}} </h5>
            <h5 class="text-center"> CED.PROF. 10156497</h5>
            <h5 class="text-center">________________________________</h5>
            <h6 class="text-center">NOMBRE Y CEDULA DEL ESPECIALISTA</h6>
        
        
        
        </div>
        
         
    </div>
</div>




<div class=" center">
    <button type="button" class="btn btn-outline-primary btn-block">Descargar PDF</button>
</div> -->



<!-- <link rel="stylesheet" type="text/css" href="./resultados-ultrasonido.component.css" media="all" />
<link rel="stylesheet" type="text/css" href="./resultados-ultrasonido.component.css" media="print" /> -->
<div class="col-lg-12">
    <div class="container">
        <div id="tablaData">
            <div class="col">
                <h1 class="centerxd" id="centerxd">HOJA DE REPORTE</h1>
            </div>
            <section class="row">
                <div class="col-md-12">
                    <ficha-info-user [pacienteInfo]="paciente">
                        <!--NONE-->
                    </ficha-info-user>
                </div>
            </section>
            <div class="sombra card">
                <section class="row">
                    <div class="col-md-12">
                        <h1 class="text-center fontColor">
                            REPORTE RAYOS X
                        </h1>
                        <h3 class="text-center fontColor">
                            {{titulo}}
                        </h3>
                    </div>
                </section>
                <br>
                <h5 class="fontColor">
                    INTERPRETACION
                </h5>
                <br>
                <div class="col-md-12">
                    <div class="text-justify">
                        {{machoteEditado}}
                    </div>
                </div>
                <br>
                <h5 class="fontColor">
                    DIAGNOSTICO
                </h5>
                <div class="col-md-12">
                    <div class="text-justify">
                        {{diag}}
                    </div>
                </div>
                <br>
                <h5 class="fontColor">
                    OBSERVACIÓNES
                </h5>
                <div class="col-md-12">
                    <div class="text-justify">
                        {{ observa}}
                    </div>
                </div>
                <br>
                <div class="hide-when-printing fontColor">
                    <h5>
                        IMAGENES
                    </h5>
                    <div class="row">
                        <div class="col-md-12" *ngFor="let item of imagenes">

                            <div class="usg">
                                <img class="img-fluid  img-thumbnail" src="http://192.168.0.2:3200/img/{{item}}">
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <h5 class="text-center">
                   DR. {{userXRAY}}
                </h5>
                <h5 class="text-center" style="font-size: 18px;">
                    CED.PROF. 10156497
                </h5>
                <h5 class="text-center" style="font-size: 18px;">
                    ESPECIALISTA:
                </h5>
                <h6 class="text-center">
                    NOMBRE Y CEDULA DEL ESPECIALISTA
                </h6>
                <div class="center forced-jump">
                    <button type="button" class="btn btn-outline-primary btn-block hide-when-printing buttonSVY" (click)="USGpdf()">
                        DESCARGAR PDF
                    </button>
                </div>
                <br>
            </div>
        </div>

        <br>
        <!--SVY-->
        <div class="forced-jump">
            <!--NONE-->
        </div>
        <div class="hide-display IQ">
            <div class="margin-top-div">
                <div class="ImageNx" style="width: 50%;display: inline-block;" *ngFor="let item of imagenes | slice:0:2;">
                    <img class="img-fluid img-thumbnail" src="http://horizonte-backend.herokuapp.com/img/{{item}}">
                </div>
            </div>
            <div>
                <div class="forced-jump-line" style="width: 50%;display: inline-block;" *ngFor="let item of imagenes | slice:2:4;">
                    <img class="img-fluid img-thumbnail" src="http://horizonte-backend.herokuapp.com/img/{{item}}">
                </div>
            </div>
            <div class="forced-jump">
                <!--NONE-->
            </div>
            <div class="margin-top-div">
                <div class="ImageNx" style="width: 50%;display: inline-block;" *ngFor="let item of imagenes | slice:4:6;">
                    <img class="img-fluid img-thumbnail" src="http://192.168.0.2:3200/img/{{item}}">
                </div>
            </div>
            <div>
                <div class="forced-jump-line" style="width: 50%;display: inline-block;" *ngFor="let item of imagenes | slice:6:8;">
                    <img class="img-fluid img-thumbnail" src="http://192.168.0.2:3200/img/{{item}}">
                </div>
            </div>
            <div class="forced-jump">
                <!--NONE-->
            </div>
            <div class="margin-top-div">
                <div class="ImageNx" style="width: 50%;display: inline-block;" *ngFor="let item of imagenes | slice:8:10;">
                    <img class="img-fluid img-thumbnail" src="http://192.168.0.2:3200/img/{{item}}">
                </div>
            </div>
            <div>
                <div class="forced-jump-line" style="width: 50%;display: inline-block;" *ngFor="let item of imagenes | slice:10:12;">
                    <img class="img-fluid img-thumbnail" src="http://192.168.0.2:3200/img/{{item}}">
                </div>
            </div>
        </div>
        <!--SVY-->
    </div>
</div>