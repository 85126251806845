<header class="noprint">
    <app-titulos [title]="'AGREGAR PRODUCTOS'"></app-titulos>
    <!-- <h2 class="ml-5 title">AGREGAR PRODUCTOS</h2> -->
</header>

<div class="container-fluid">
    <app-ficha-indentificaciosproductos-almacen  [dataFiProducto]="dataFi" [tipo]="'PRODUCTO'" [stock]="productos.length"></app-ficha-indentificaciosproductos-almacen>
</div>

<section class="mt-4 noprint" style="margin-bottom: 20px;">
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <div class="card">
                <app-productos-stock [proveedores] = "proveedores" (proveedor)="obtenerForm($event)"></app-productos-stock>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>No</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p> PRECIO </p>
                        </div>
                        <div class="col text_header">
                            <p>LOTE</p>
                        </div>
                        <div class="col text_header">
                            <p>LOTE UNITARIO</p>
                        </div>
                        <div class="col-md-3 text_header">
                            <p>PROVEEDOR</p>
                        </div>
                        <div class="col text_header">
                            <p>FACTURA</p>
                        </div>
                        <div class="col text_header">
                            <p>QR</p>
                        </div>
                        <div class="col text_header">
                            <p>ETIQUETA</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div  *ngIf="productos.length === 0"> 
                        <div class="row">
                            <div class="col-md-12">
                                <p class="text-center">
                                    No hay productos en Stock
                                </p>
                            </div>
                        </div>
                    </div>
                    <div  *ngFor="let product of productos| paginate:{id:'listaproductos',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalProductos}; let i = index">
                        <div class="row puntero">
                            <div class="col-md-1" style="text-align: center;">
                                <p>
                                    {{ i +1 }}
                                </p>
                                <span class="puntero"></span>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                  $  {{ product.precioVenta }} .00
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ product.lote }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{product.lote_unitario}}
                                </p>
                            </div>
                            <div class="col-md-3" style="text-align: center;">
                                <p>
                                    {{ product.proveedor }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ product.factura }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <img class="img-responsive" [src]="product.codeQr_img"  #imgElement/>
                            </div>
                            <div class="col" style="text-align: center;">
                                <div class="row">
                                    <div class="col">
                                        <p class="text-header-black puntero" [ngClass]="{'colorRedDownloaded':  product.downloaded== true }" (click)="imprimirEtiquetaIndividual(imgElement, product._id )">
                                            <i class="fas fa-download" style="font-size:36px"></i>  
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col" >
                                <div class="row">
                                    <p class="text-header-black"  [ngClass]="{'colorRedDownloaded':  product.downloaded== true }"   (click)="imprimirEtiquetaIndividual(imgElement, product._id )"> 
                                        <i class="fas fa-download" style="font-size:36px"></i>    
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listaproductos" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="row mt-4 noprint">
    <div class="offset-md-4 col-md-2">
        <button class="btn btn-product btn-block color-bg-blue" (click)="registrarProductoEnStock()"  [disabled]="btnValidate" > Agregar </button>
    </div>
    <div class="col-md-2">
        <button  class="btn btn-product btn-block color-bg-blue"   (click)="printImgQr()" >Descargar</button>
    </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>

<!-- <section class="mt-4 noprint">
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <div class="card">
                <app-productos-stock [proveedores] = "proveedores" (proveedor)="obtenerForm($event)"></app-productos-stock>
                <div class="row mt-2">
                    <div class="col-md-12">

                        <div class="row mb-2">
                            
                            <div class="col-md-2">
                                <p class="text-body color-text-black">
                                    COSTO
                                </p>                               
                            </div>
                            <div class="col-md-4">
                                <input type="number" min="0" name="precioReal"  [(ngModel)]="productoData.costoReal"  class="form-control"   />
                            </div>

                            <div class="col-md-2">
                                <p class="text-body color-text-black">
                                    PRECIO
                                </p>                               
                            </div>
                            <div class="col-md-4">
                                <input type="number" min="1" name="precioVenta" [(ngModel)]="productoData.precioVenta" class="form-control" />
                            </div>

                   
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row mb-2">
                            
                            <div class="col-md-2">
                                <p class="text-body color-text-black">
                                    LOTE
                                </p>

                            </div>
                            <div class="col-md-4">
                                <input type="text" name="lote"  [(ngModel)]="productoData.lote"  class="form-control"  />
                            </div>
                            <div class="col-md-2">
                                <p class="text-body">
                                    CANTIDAD
                                </p>

                            </div>
                            <div class="col-md-4">
                                <input type="number" name="cantidad" [(ngModel)]="productoData.cantidad"  min="1" class="form-control"  />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">

                        <div class="row mb-2">
                            <div class="col-md-2">
                                <p class="text-body">
                                    PROVEEDOR
                                </p>

                            </div>
                            <div class="col-md-4">
                                <select name="proveedor" [(ngModel)]="productoData.proveedor"  class="form-control">
                                    <option value=""  > Selecciona un proveedor </option>
                                    <option [value]="proveedor.razonSocial"  *ngFor="let proveedor of proveedores">  {{ proveedor.nombreEmpresa }} </option>
                                </select>
                                <input type="text"   />
                            </div>

                            <div class="col-md-2">
                                <p class="text-body color-text-black">
                                    FECHA DE CADUCIDAD
                                </p>                               
                            </div>
                            <div class="col-md-4">
                                <input type="date" name="fecha" [(ngModel)]="productoData.fecha_caducidad" class="form-control" (blur)="validateForm()" />
                            </div>

                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">

                            <div class="col-md-2">
                                <p class="text-body">
                                    FACTURA
                                </p>
                            </div>
                            <div class="col-md-4">
                                <input type="text" name="factura" [(ngModel)]="productoData.factura" class="form-control"  />
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <header class="color-bg-blue mt-2" >
                        <div class="row">
                            <div class="col-md-1">
                                <p class="text-header-white pl-3"> No </p>
                            </div>
                            <div class="col-md-2">
                                <p class="text-header-white"> ID </p>
                            </div>
                            <div class="col-md-1">
                                <p class="text-header-white"> LOTE </p>
                            </div>
                            <div class="col-md-2">
                                <p class="text-header-white"> LOTE UNITARIO </p>
                            </div>
                            <div class="col-md-2">
                                <p class="text-header-white"> PROVEEDOR </p>
                            </div>
                            <div class="col-md-1">
                                <p class="text-header-white"> FACTURA </p>
                            </div>
                            <div class="col-md-2">
                                <p class="text-header-white"> QR </p>
                            </div>
                            <div class="col-md-1">
                                <p class="text-header-white"> ETIQUETA </p>
                            </div>
                        </div>
                    </header>
                    <section>
                        <div  *ngIf="productos.length === 0" >
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <p  class="text-center" >
                                        No hay prpoductos en Stock
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="productos.length > 0" >
                            <div class="row"  *ngFor="let product of productos; let i = index"  >
                                <div class="col-md-1">
                                    <p class="text-header-black pl-3"> {{ i +1 }} </p>
                                </div>
                                <div class="col-md-2">
                                    <p class="text-header-black"> {{ product._id }} </p>
                                </div>
                                <div class="col-md-1">
                                    <p class="text-header-black"> {{ product.lote }} </p>
                                </div>
                                <div class="col-md-2">
                                    <p class="text-header-black"> {{product.lote_unitario}} </p>
                                </div>
                                <div class="col-md-2">
                                    <p class="text-header-black"> {{ product.proveedor }} </p>
                                </div>
                                <div class="col-md-1">
                                    <p class="text-header-black"> {{ product.factura }} </p>
                                </div>
                                <div class="col-md-2">
    
                                    <img class="img-responsive" [src]="product.codeQr_img"  #imgElement />
                                </div>

                                <div class="col-md-1 pointer" >
                                    <p class="text-header-black"  [ngClass]="{'colorRedDownloaded':  product.downloaded== true }"   (click)="imprimirEtiquetaIndividual(imgElement, product._id )"> 
                                        <i class="fas fa-download"></i>    
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section> -->