<!-- TODOS LOS HTML´'S ESTAN RESUMINDOS AQUÍ, POR LO TANTO SOLO CUENTAN CON UN FORMATO DE ESTRILO EN LA CARD 
Y EN LOS BOTONES, NOTA: SIMPLEMENTE NO SE PODRAN MODIFICAR, MÁS QUE LO MINIMO  -->
<div class="row">
    <div class="col-md-9">
        <div class="card" style="border-radius: 30px;">
            <div class="card-header header" style="border-top-left-radius: 24px;
            border-top-right-radius: 24px;">
                <div class="row">
                    <div class="col-md-3 text_header">
                        <p>ESTUDIO</p>
                    </div>
                    <div class="col text_header">
                        <p *ngIf="membresia != true">PRECIO</p>
                        <p *ngIf="membresia"> PRECIO MEMBRESÍA </p>
                    </div>
                    <div class="col text_header">
                        <p *ngIf="membresia != true">PRECIO URGENCIA</p>
                        <p *ngIf="membresia"> PRECIO MEMBRESÍA URGENCIA </p>
                    </div>
                    <div class="col text_header" *ngIf="servicios != 'laboratorio'">
                        <p *ngIf="membresia != true">PRECIO HOSPITALIZACIÓN</p>
                        <p *ngIf="membresia">PRECIO MEMBRESÍA HOSPITALIZACIÓN</p>
                    </div>
                    <!-- <div class="col text_header" *ngIf="membresia">
                        <p>PRECIO MEMBRESÍA HOSPITALIZACION URGENCIA</p>
                    </div> -->

                    <div class="col text_header" *ngIf="RoleUser == 'Admin' ">
                        ACCIONES
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body" style="background-color: #ffffff; border-radius: 0px 0px 30px 30px;" >
            <div *ngFor="let SiService of serviceSi | paginate:{id:'listaAmbulancia',
            itemsPerPage: 4 ,
            currentPage:pagina,
            totalItems:totalAmbulancia}">

                <div class="row">
                    <div class="col-md-3">
                        <label style="margin-right: 50px; margin-top: 14px; overflow-wrap: break-word;"> 
                            {{ SiService.ESTUDIO}}
                        </label>
                    </div>

                    <div class="col">
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO, SiService.PRECIO_MEMBRESIA, SiService )" class="cursor  btn btn-light precioPublicoOtros" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                        background-color: white;
                                        border: none;" *ngIf="membresia != true">
                            {{ SiService.PRECIO_PUBLICO | currency}}
                            <!-- <p (click)="agregarCarrito( $event, SiService )" *ngIf="membresia != true"> 
                                {{ SiService.PRECIO_PUBLICO | currency}}
                            </p> 
                            <p (click)="agregarCarrito( $event, SiService )" *ngIf="membresia"> 
                                {{ SiService.PRECIO_MEMBRESIA | currency}}
                            </p>  -->
                        </div>
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO, SiService.PRECIO_MEMBRESIA, SiService )" class="cursor  btn btn-light precioPublicoOtros" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                        background-color: white;
                                        border: none;" *ngIf="membresia">
                            {{ SiService.PRECIO_MEMBRESIA | currency}}
                            <!-- <p (click)="agregarCarrito( $event, SiService )" *ngIf="membresia != true"> 
                                {{ SiService.PRECIO_PUBLICO | currency}}
                            </p> 
                            <p (click)="agregarCarrito( $event, SiService )" *ngIf="membresia"> 
                                {{ SiService.PRECIO_MEMBRESIA | currency}}
                            </p>  -->
                        </div>
                    </div>

                    <div class="col">
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO_URGENCIA, SiService.PRECIO_MEMBRESIA_URGENCIA, SiService)" class="cursor btn btn-light precioPublicoUrgencia" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: white;
                                    border: none;" *ngIf="membresia != true">
                                    {{ SiService.PRECIO_PUBLICO_URGENCIA | currency }}
                            <!-- <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia != true">
                                {{ SiService.PRECIO_PUBLICO_URGENCIA | currency }}
                            </h6>
                            <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia">
                                {{ SiService.PRECIO_MEMBRESIA_URGENCIA | currency }}
                            </h6> -->
                        </div>
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO_URGENCIA, SiService.PRECIO_MEMBRESIA_URGENCIA, SiService)" class="cursor btn btn-light precioPublicoUrgencia" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: white;
                                    border: none;" *ngIf="membresia">
                                   {{ SiService.PRECIO_MEMBRESIA_URGENCIA | currency }} 
                            <!-- <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia != true">
                                {{ SiService.PRECIO_PUBLICO_URGENCIA | currency }}
                            </h6>
                            <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia">
                                {{ SiService.PRECIO_MEMBRESIA_URGENCIA | currency }}
                            </h6> -->
                        </div>
                    </div>

                    <div class="col" *ngIf="servicios != 'laboratorio'">
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO_HOSPITALIZACION, SiService.PRECIO_MEMBRESIA_HOSPITALIZACION, SiService  )" class="cursor btn btn-light precioPublicoHopi" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: white;
                                    border: none;" *ngIf="membresia != true">
                                    {{ SiService.PRECIO_PUBLICO_HOSPITALIZACION | currency}}
                            <!-- <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia != true">
                                {{ SiService.PRECIO_PUBLICO_HOSPITALIZACION | currency}}
                            </h6>
                            <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia">
                                {{ SiService.PRECIO_MEMBRESIA_HOSPITALIZACION | currency}}
                            </h6> -->
                        </div>
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO_HOSPITALIZACION, SiService.PRECIO_MEMBRESIA_HOSPITALIZACION, SiService  )" class="cursor btn btn-light precioPublicoHopi" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: white;
                                    border: none;" *ngIf="membresia">
                                    {{ SiService.PRECIO_MEMBRESIA_HOSPITALIZACION | currency}}
                            <!-- <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia != true">
                                {{ SiService.PRECIO_PUBLICO_HOSPITALIZACION | currency}}
                            </h6>
                            <h6 (click)="agregarCarrito( $event, SiService )" *ngIf="membresia">
                                {{ SiService.PRECIO_MEMBRESIA_HOSPITALIZACION | currency}}
                            </h6> -->
                        </div>
                    </div>

                    <!-- <div class="col" *ngIf="membresia">
                        <div (click)="alertcomparasion( $event, SiService.PRECIO_PUBLICO_HOSPITALIZACION, SiService.PRECIO_MEMBRESIA_HOSPITALIZACION, SiService  )" class="cursor btn btn-light precioHospiUrgen" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: white;
                                    border: none;">
                            {{ SiService.PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA |currency}}
                            <h6 (click)="agregarCarrito( $event, SiService )">
                                {{ SiService.PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA |currency}}
                            </h6>

                        </div>
                    </div> -->

                    <div class="col" *ngIf="RoleUser =='Admin'">
                        <div class="row">
                            <div class="col">
                                <label class="btn btn-outline-success" (click)="editarServicio(SiService._id)">
                                    <i class="fas fa-edit"></i>
                                </label>
                            </div>
                            <div class="col">
                                <label class="btn btn-outline-danger" (click)="delete(SiService._id)">
                                    <i class="fas fa-trash-alt"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="offset-md-2">
                <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card" style="margin: 10px; height: 33em; border-radius: 24px;">

            <div class="card-title" style="border-bottom-style: solid;">
                <h4 style="text-align: center;">COTIZACIÓN</h4>
            </div>

            <div class="card-body" style="overflow: auto; ">
                <div class="row" *ngFor="let pedido of carrito.items; let i=index">
                    <div class="col-md-8" *ngIf="pedido !== [];">
                        <p style="font-size: 10px; font-weight: 600; text-align: center;" *ngIf="membresia != true">
                            {{ pedido.nombreEstudio }} : {{ pedido.precioSin}}
                        </p>
                        <p style="font-size: 10px; font-weight: 600; text-align: center;" *ngIf="membresia">
                            {{ pedido.nombreEstudio }} : {{ pedido.precioCon}}
                        </p>
                    </div>
                    <div class="col-md-2">
                        <label class="btn" (click)="eliminar(i)">
                            <i class="fas fa-trash-alt" id="elimi"></i>
                        </label>
                        <!-- <button type="button" (click)="eliminar(i)" class="btn btn-link" style="color: red;">

                            Eliminar
                        </button> -->
                    </div>
                </div>
                <div *ngIf="validarCarrito()">
                    <p>No cuentas con servicios en el carrito</p>
                </div>
            </div>

            <div class="card-footer" style="border-top-style: solid;">
                <div class="row">
                    <div class="col-md-10">
                        <h5 style="font-size: 15px; font-weight: 800;" *ngIf="membresia != true">Total: &#36; {{carrito.totalSin}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <h5 style="font-size: 15px; font-weight: 800;">Total membresía: &#36; {{ carrito.totalCon}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro"> Ahorro: &#36; {{carrito.totalSin - carrito.totalCon}}</h5>
                    </div>
                </div>

                <!-- <button *ngIf="btnPago" type="button" class="btn btn-success btn-block btn-tarjeta" [routerLink]="['/pago/servicios']">
                    <i class="fas fa-hand-holding-usd"></i>
                        Pagar
                </button> -->
                <button *ngIf="btnPago" type="button" class="btn btn-success btn-block btn-tarjeta" (click)="onClick()">
                    <i class="fas fa-hand-holding-usd"></i>
                        Pagar
                </button>

                <button *ngIf="btnImpPago" type="button" class="btn btn-success btn-block btn-tarjeta" (click)="imprimirCotizacion(carrito)">
                    <i class="fas fa-hand-holding-usd"></i>
                        Imprimir cotización
                </button>

                <button class="btn btn-dark btn-tarjeta btn-block" [routerLink]="['/paciente']" routerLinkActive="router-link-active" *ngIf="RoleUser == 'Admin' ">
                    <i class="fas fa-user-plus"></i>
                        Nuevo
                </button>


                <button *ngIf="!btnPago" class="btn btn-block btn-info  btn-tarjeta" (click)="abrirInputCorreo()">
                    <i class="fas fa-share-alt"></i>
                        Enviar
                </button>
            </div>
        </div>
    </div>
</div>
<!-- <div role="alert" id="toastEmail" aria-live="assertive" aria-atomic="true" [ngClass]="show" class="toast" data-autohide="false">
    <div class="toast-header">


        <div class="from-group">
            <strong class="mr-auto">Email:</strong>

            <form (ngSubmit)="enviar()">
                <input type="email" class="form-control" name="email" [(ngModel)]="email" id="email" placeholder="Email">
                <button type="submit" class="btn btn-primary"> Enviar </button>
            </form>

        </div>



        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="cerrarModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
</div> -->