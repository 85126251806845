import { Component, OnInit } from '@angular/core';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-sedesbs',
  templateUrl: './sedesbs.component.html',
  styleUrls: ['./sedesbs.component.css']
})
export class SedesbsComponent implements OnInit {
  public sedes:any[] = [];
  public pagina = 0;
  
  public totalsedes:string;
  public totalAmbulancia: string;

  constructor(public _BancoServices: BancoService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.obtenerPacientes();
  }
  obtenerPacientes() {

    this.sedes = [];

    this._BancoServices.getSedesAll()
    .subscribe( (data: any) => {
      this.sedes = data.data;
      this.totalAmbulancia = data.data.results;
       /*if(data.ok){
        this.sedes = data.data.reverse();
        this.sedes = data.data;
        this.totalsedes = data.data.results;
        this.spinner.hide();
      }
*/
      if( data['message']   === 'No hay sedes' ) {
         Swal.fire({
              icon: 'success',
              title: '',
              text: 'NO SE ENCUENTRA NINGUNA SEDE',
            })
        return;
      }
      /* this._spinnerService.show(); */

    });
  }

}
