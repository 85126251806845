<div class="container-fluid">
    <section class="center">
        <!-- <div class="row">
            <div class="col-md-8">
                <h1 class="centerxd"> {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase}} {{ paciente.apellidoMaterno | uppercase }} </h1>
            </div>
            <div class="col-md-4">
                <p>
                    <span>
                        {{paciente.fechaRegistro}}
                    </span>
                </p>
                <p>
                    <span><label for="consultas">  Consultas: </label>  {{ paciente.consultas }} </span>
                </p>
            </div>
        </div> -->
        <app-titulos [data]="paciente2" [rol]="'recepcion'"></app-titulos>
    </section>

    <section class="container">
        <div class="row">
            <div class="col-9">
                <select name="servicio" class="form-control" id="serv" #servicio (change)="irAUnServicio( servicio.value  )">
                    <option   value="" selected> Selecciona un servicio  </option>
                    <option   value="ambulancia"> Ambulancia</option>
                    <option   value="consulta"  > Consulta general </option>
                    <option   value="consultaEspecialista">  Consulta con especialista </option>
                    <option   value="endoscopia"> Endoscopía</option>
                    <option   value="laboratorio"> Laboratorio</option>
                    <option   value="xray">     Rayos X </option>
                    <option   value="resonancia"> Resonancia</option>
                    <option   value="tomografias"> Tomografía</option>
                    <option   value="ultrasonido"> Ultrasonido</option>
                    <option   value="otros"> Servicios generales </option> 
                    <option   value="Donaciones"> Donaciones </option> 
                    <option   value="Transfusiones"> Transfusiones </option> 
                    <option   value="Productos"> Productos </option> 
                  </select>
            </div>
        </div>
    </section>
    <section class="container">
        <div class="row">
            <div class="col-9">
                <select name="lugar" class="form-control" id="lugar" #lugar *ngIf="lugar" (change)="actualizarLugar(lugar.value)">
                    <option   value="" selected> Selecciona una opción  </option>
                    <option   value="Paquete Control Prenatal"> Paquete Control Prenatal</option>
                    <option   value="Paquete Neonatal"  > Paquete Neonatal </option>
                    <option   value="Paquete Pediátrico">  Paquete Pediátrico </option>
                    <option   value="Servicio de la Membresía"> Servicio de la Membresía</option>
                    <option   value="Paquete Médico Laboral"> Paquete Médico Laboral</option>
                    <option   value="Paquete de Control Prenatal de Alto Riesgo"> Paquete de Control Prenatal de Alto Riesgo</option>
                    <option   value="Paquete Vida Plena"> Paquete Vida Plena</option>
                    <option   value="Ningun Paquete"> Ninguno </option>
                  </select>
            </div>
        </div>
    </section>
    <section>
        <!-- <app-ficha-info-censur [pacienteInfo]="paciente"></app-ficha-info-censur> -->
        <app-fichas-identificacion [data]="paciente2"></app-fichas-identificacion>
    </section>

    <div class="card-columns">
        <!-- <div *ngFor="let item of paquetes">
            <div class="col" *ngIf="paquetes.length != 0">
                <div class="container">
                    <div class="card center" name="card" id="card" style="
                    height: 24rem !important;">
                        <img class="card-img-top img" [src]="item.paquete.icon" alt="Card image cap" style="width: 260px;" [routerLink]="['/paquete-paciente', item._id]">
                        <div class="card-body">
                            <h5 class="card-title color-text"> {{ item.paquete.nombrePaquete }} </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 offset-3  mt-1  sin-paquetes" *ngIf="paciente.paquetes.length == 0">
        </div> -->
        <div class="container cardBanco" *ngIf="donaciones.length != 0">
            <div class="card center" name="card" id="cardSangre" style="height: 20rem!important;" (click)="pasar()">
                <img class="card-img-top img" src="../../../../../../assets/captura2.PNG" alt="Card image cap" style="width: 220px; margin-top: 2rem; cursor: pointer;">
                <h5 class="card-title color-text" style="margin-top: 1.5rem; cursor: pointer;"> BANCO DE SANGRE</h5>
            </div>
        </div>
        <!-- <div class="container cardAgregar">
            <div class="card center" name="card" id="cardAgregarP" style="height: 20rem!important;" [routerLink]="['/solicitar/paquete/', id]">
                <img class="card-img-top img" src="../../../../assets/icons/serviciosIconos/add.svg" alt="Card image cap" style="width: 200px; margin-top: 2rem;">
                <h5 class="card-title color-text" style="margin-top: 2rem;"> AGREGAR PAQUETE</h5>
            </div>
        </div> -->
    </div>
</div>