<div class="container">
    <section>
        <app-ficha-reacciones-derivadas [pacienteInfo]="paciente"></app-ficha-reacciones-derivadas>
    </section>

    <div class="card" id="historia" style="padding: 40px;">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <p>INICIO DE LA REACCIÓN</p>
                    </div>
                    <div class="col">
                        <input type="date" id="iniciodereaccion" name="iniciodereaccion" [(ngModel)]="data.iniciodereaccion">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <p>TIPO DE DONANTE:</p>
                    </div>
                    <div class="col">
                        <input type="text" name="tipodonante" id="tipo" [(ngModel)]="data.tipodonante">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" id="reacciones">
            <div class="col">
                <div class="form-group row">
                    <label class="col col-form-label"><span>PRESENTACIÓN DE REACCIONES:</span></label>
                    <div class="form-group" style="margin-left: 0px; margin-top: 5px;">
                        <div class="form-check form-check-inline">
                            <input type="radio" value="DURANTE LA DONACION" name="DURANTE LA DONACION" [(ngModel)]="data.presentacionreacciones">
                            <label class="form-check-label" style="margin-left: 5px; margin-top: 1px;"> DURANTE LA DONACION</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" value="POSTERIOR A LA DONACION" name="DURANTE LA DONACION" [(ngModel)]="data.presentacionreacciones">
                            <label class="form-check-label" style="margin-left: 5px; margin-top: 1px;">POSTERIOR A LA DONACION</label>
                        </div>
                    </div>
                </div>     
            </div>
            <div class="col">
                <div class="form-group row">
                    <label class="col col-form-label"><span>ANTECEDENTES DE REACCIONES:</span></label>
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input type="radio" value="SI" name="antecedentes" [(ngModel)]="data.antecedentesreacciones">
                            <label class="form-check-label" style="margin-left: 5px; margin-top: 1px;"> SI</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" value="NO" name="antecedentes" [(ngModel)]="data.antecedentesreacciones">
                            <label class="form-check-label" style="margin-left: 5px; margin-top: 1px;">NO</label>
                        </div>
                    </div>
                </div>        
            </div>
        </div>
        <span>CLASIFICACIONES DE LA GRAVEDAD DE LA RAD (DE ACUERDO A SIGNOS Y SÍNTOMAS PRESENTADOS DURANTE LA REACCIÓN)</span>
        <div role="tabpanel" class="row">
            <div class="col-6" id="indicadoress">
                <ul class="nav flex-column nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#reaccionVasovagal" aria-controls="#reaccionVasovagal"> 
                            REACCIÓN VASOVAGAL
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#reaccionVenopuncion" data-toggle="tab" aria-controls="#reaccionVenopuncion"> 
                            REACCIÓN POR VENOPUNCIÓN 
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#reaccionToxicidad" data-toggle="tab" aria-controls="#reaccionToxicidad"> 
                            REACCIÓN DE TOXICIDAD
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#reaccionAdversa" data-toggle="tab" aria-controls="#reaccionAdversa"> 
                            TIEMPO DE PRESENTACIÓN DE REACCIÓN ADVERSA
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <div class="tab-content">
                    <div role="tabpanel" class="tab-pane active" id="reaccionVasovagal">
                        <app-reaccion-vasovagal (reaccionesVasovagalleve)="reaccionesVasovagalleve($event)" 
                                                (reaccionesVasovagalmoderada)="reaccionesVasovagalmoderada($event)" 
                                                (reaccionesVasovagalsevera)="reaccionesVasovagalsevera($event)"
                                                (otrosSintomasLeve)="otrosSintomasLeveVaso($event)"
                                                (otrosSintomasModerada)="otrosSintomasModeradaVaso($event)"
                                                (otrosSintomasSevera)="otrosSintomasSeveraVaso($event)"></app-reaccion-vasovagal>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="reaccionVenopuncion">
                        <app-reaccion-venopuncion (reaccionesVenopuncionlleve)="reaccionesVenopuncionlleve($event)" 
                                                  (reaccionesVenopuncionmoderada)="reaccionesVenopuncionmoderada($event)" 
                                                  (reaccionesVenopuncionsevera)="reaccionesVenopuncionsevera($event)"
                                                  (otrosSintomasLeve)="otrosSintomasLeveVeno($event)"
                                                  (otrosSintomasModerada)="otroModeradoVeno($event)"
                                                  (otrosSintomasSevera)="otroSeveroVeno($event)"></app-reaccion-venopuncion>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="reaccionToxicidad">
                        <app-reaccion-toxicidad (reaccionesToxicidadlleve)="reaccionesToxicidadlleve($event)" 
                                                (reaccionesToxicidadmoderada)="reaccionesToxicidadmoderada($event)" 
                                                (reaccionesToxicidadsevera)="reaccionesToxicidadsevera($event)"
                                                (LabCalcio)="LabCalcio($event)"
                                                (LabMagnesio)="LabMagnesio($event)"
                                                (otrosSintomasModerada)="otrosSintomasModeradaToxi($event)"
                                                (otrosSintomasSevera)="otrosSintomasSeveraToxi($event)"></app-reaccion-toxicidad>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="reaccionAdversa">
                        <app-reaccion-adversa (tiempodepresentacion)="tiempodepresentacion($event)" (tratamiento)="tratamiento($event)"></app-reaccion-adversa>
                        <div class="boton">
                            <button class="principalButton" (click)="enviar()" > GUARDAR </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>