import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterProduc } from '../../../../../functions/almacen';

@Component({
  selector: 'app-lista-productos-almacen',
  templateUrl: './lista-productos-almacen.component.html',
  styleUrls: ['./lista-productos-almacen.component.css']
})
export class ListaProductosAlmacenComponent implements OnInit {

  public productos = [];
  public productos2=[];
  public idSelectedForDelete = "";
  public txtSearch = "";
  public pagina = 0;
  public totalProductos: string;

  constructor( private _almacenService: AlmacenService,
               private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.obetenerProductos();
  }


  obetenerProductos() {
    this.spinner.show()
    this._almacenService.obtenerProductosAlmacenBitacora().subscribe((data:any) => {
      this.totalProductos = data.data.results;
      this.setProductosAlmacen(data.data)
      this.spinner.hide()
    });
  }

  setProductosAlmacen(data:any) {
    this.productos = data;
    this.productos2 = data;
  }


  disabledProdcut( id: string){
    const messageAlertSection = document.querySelector('#section-message-alert');
    messageAlertSection.classList.remove('dispaly-none');
    messageAlertSection.classList.add('dispaly-block');
    this.idSelectedForDelete = id;
  }

  btnConfirmDelete() {
    this.spinner.show()
    const messageAlertSection = document.querySelector('#section-message-alert');
    this._almacenService.disabledAProduct( this.idSelectedForDelete )
    .subscribe((data:any) => {
      if(data.ok){
        setTimeout(() => {
          messageAlertSection.classList.remove('dispaly-block');
          messageAlertSection.classList.add('dispaly-none');
          this.idSelectedForDelete = "";
          this.obetenerProductos();
        }, 2000);
        this.spinner.hide()
      }
    });
  }

  filterChangeByName(event) {
    this.pagina = 0;
    if(event.target.value.length >= 3){
      this._almacenService.buscarMaterialProductos({nombre: event.target.value})
      .subscribe( (data: any) => this.productos = data.data);
    }else{
      this.productos = this.productos2;
    }
  }

  cancelBtnAlertMessage() {
    const messageAlertSection = document.querySelector('#section-message-alert');
    messageAlertSection.classList.remove('dispaly-block');
    messageAlertSection.classList.add('dispaly-none')
  }

}
