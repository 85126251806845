import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
@Component({
  selector: 'app-detalle-productos-almacen',
  templateUrl: './detalle-productos-almacen.component.html',
  styleUrls: ['./detalle-productos-almacen.component.css']
})
export class DetalleProductosAlmacenComponent implements OnInit {

  public detailProductId : any = "";
  public productInfo : any = "";
  public listProductVentas : Array<any> = [];
  public productoInfoPrecio : number = 0;
  public pagina : number =0;
  constructor( private activatedRoute: ActivatedRoute, private _almacenService: AlmacenService, ) { }

  ngOnInit(): void {
    this.detailProductId = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerProductoInfo();
    this.obtenerProductoVentas();
   
  }

  obtenerProductoVentas(){
    this._almacenService.obteneProductosPorId(this.detailProductId).subscribe((data:any)=> this.setDetailProduct(data.data))
  }
  obtenerProductoInfo(){
    this._almacenService.obtenerProductosAlmacenBitacora().subscribe((data:any)=>this.setInfoProduct(data.data));
  }

  setInfoProduct(data:Array<any>){
    this.productInfo = data.find((product)=>product._id === this.detailProductId)
  }

  setDetailProduct(data:Array<any>){
     this.listProductVentas = data;
     if(data.length === 0){
      this.productoInfoPrecio = 0;
     }else{
      this.productoInfoPrecio = data[0].precioVenta || 0;
     }
     
    //  let productDetail = data.map(product=>product._id===this.detailProductId);
    //  console.log(productDetail)
  }


}
