import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { USGService } from 'src/app/services/usg/usg.service';




@Component({
  selector: 'app-formulario-creador',
  templateUrl: './formulario-creador.component.html',
  styleUrls: ['./formulario-creador.component.css']
})
export class FormularioCreadorComponent implements OnInit {

  public id: string;

  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:'',
    _id:""
  };

  public title = '';

  public ultrasonidos: any [] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private _usgService: USGService,
  ) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    // this.ultrasonidos = this._usgService.getUltrasonidos();
    // console.log(this.ultrasonidos);
    
    
  }

  alertaMamalona(){
     Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE HA NOTIFICADO ALMEDICO TRATANTE',
            })
    /* alert('Se ha notificado al Médico tratante :) '); */
  }

 

}
