import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-medicamentos',
  templateUrl: './medicamentos.component.html',
  styleUrls: ['./medicamentos.component.css']
})
export class MedicamentosComponent implements OnInit {

  @Output() medicamentos = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  formularioMedicamentos(form: NgForm){
    this.medicamentos.emit(form.form.value);
  }

}
