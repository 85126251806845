import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';
import { NotaIngreso } from 'src/app/pages/Hospitalizacion/bitacora-hospitalizacion/hospitalizacion.interface';

@Injectable({
  providedIn: 'root'
})
export class HospitalizacionService {

  constructor(
    public _http:HttpClient
  ) {  }
 
  public url = URL;

  //? endpoints de  nota de ingreso

  getBitacoraHospitalizacion(){

    return this._http.get(`${URL}/ver/pacientes/hospitalizados`);
  }


  getBitacoraPorId(id:string){
    return this._http.get(`${URL}/hoja/ingreso/${id}`);
  }

  updateNotaIngreso( id: string, body: any ) {
    return this._http.put(`${URL}/actulizar/estado/hospitalizacion/${id}`, body);
  }

  updateNotaIndicaciones(id: string, body:any){
    return this._http.put(`${URL}/agregar/indicacion/${id}`, body);
  }

  createHojaIndicaciones(body:any){
    return this._http.post(`${URL}/agregar/hoja/indicaciones`, body);
  }

}
