import { Component, OnInit } from '@angular/core';
import { Cie10Service } from 'src/app/services/consultas/cie10.service';
import { NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-diagnostico-disponente',
  templateUrl: './diagnostico-disponente.component.html',
  styleUrls: ['./diagnostico-disponente.component.css']
})
export class DiagnosticoDisponenteComponent implements OnInit {

  public buscarDiagnostico: string;
  public diagnostico=[];
  public diagnosticos: [] = [];
  public valid = false;
  public hojaEvolucion = {
    diagnosticos : []
  }

  @Output() diagnostic = new EventEmitter<string>();

  constructor( public _Cie10Service: Cie10Service) { }

  ngOnInit(): void {
  }

  agregarDiagnosticoUno( item  ){
    let diagnostico  = { diagnostico: item}
    this.diagnostico.push( diagnostico );
    this.hojaEvolucion.diagnosticos.push(diagnostico);
    this.diagnosticos = [];
    this.buscarDiagnostico='';
  }
  
  obtenerDiagnosticoUno(){
    if(this.buscarDiagnostico.length > 3) {
      this._Cie10Service.getCiePorNombre(this.buscarDiagnostico).subscribe(
        (data:any) => {
          this.diagnosticos = data.data;
        });
      }else{
        this.diagnosticos=[]
      }
  }

  validacion(form: NgForm){
    if(form.form.value.candidato_donacion == 'NO'){
      this.valid = true;
    }else{
      this.valid = false;
    }
  }
    
  formularioDiagnostico(form: NgForm){
    form.form.value.cie10 = this.diagnostico
    this.diagnostic.emit(form.form.value);
  }
}
