import { Component, OnInit, Input } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute} from '@angular/router';
import { gaurdarCotizacion } from '../../../../functions/storage.funcion';
import { getCarritoStorage } from '../../../../functions/pacienteIntegrados';
import { FormBuilder } from '@angular/forms';
import  {  getDataStorage   } from '../../../../functions/storage.funcion';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import  Carrito  from '../../../../classes/carrito/carrito.class';

@Component({
  selector: 'app-quirofano',
  templateUrl: './quirofano.component.html',
  styleUrls: ['./quirofano.component.css']
})
export class QuirofanoComponent implements OnInit {

  // data de los servicios

  
  @Input() serviceSi: any [] = [];
  public ambulanciaSI: any [] = [];
  public totalAmbulancia: string;
  //forma: FormGroup;
  public buscar = {
    estudio:''
  }
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showAmbulanacia = false;
  @Input() showVista= true;
  public searching = false;
  public servicios ="";

  public membresia = false;
  public preciosMembresia=[];

  public RoleUser = "";

  public carritoMembresia = {
    items:[],
    total:0
  }

  public paciente:any;

  obtenerPacienteYMembresia(){  

    const usuarioMembresia = new PacienteStorage();
    this.paciente = usuarioMembresia.verPacienteConMembresia();

    if( this.paciente == null || this.paciente.membresiaHabilitada == false ) {
      this.verDatos();
    }else{ 
      this.membresia = usuarioMembresia.verMembresiaPaciente(); 
      this.verDatos();      
    }
  
  }

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  constructor(private _service: IntegradosService, private _router: Router, private activatedRoute: ActivatedRoute, private _fb: FormBuilder) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    /* console.log(this.serviceSi); */
    
    // console.log(this.servicios);
    this.obtenerRoleStorage();
    this.obtenerCarritoStorage();
    this.obtenerPacienteYMembresia();
  }

  obtenerCarrito (){
    
    this.carrito = getCarritoStorage();

    if ( this.carrito == null ){
        this.carrito = {
          totalSin: 0,
          totalCon: 0,
          items: []
        };
    }

  }

  obtenerRoleStorage(){
    this.RoleUser = getDataStorage().role;
    // console.log( this.RoleUser );
  }

  obtenerCarritoStorage(){
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  busquedaGeneral(){
    if(this.buscar.estudio.length >3){
      this._service.getAllDepartments(this.buscar)
      .subscribe((res:any) => {
        // console.log( res );
        if(res.data[0]!=0){
          this.ambulanciaSI = res.data[0];
          this.showAmbulanacia= true;
          this.showTableAmbulanacia = false;
        }else{
          this.serviceSi = res.data[1]; 
          this.searching = true;
          /* console.log(  this.serviceSi ); */ 
        }            
      })
    }
    if(this.buscar.estudio == ''){
      this.searching = false;
      this.showAmbulanacia= false;
      this.showTableAmbulanacia = true;
      this.obtenerCarritoStorage();
      this.verDatos();
    }
  }


  /* eliminar(index){
    //console.log('Pedido', pedido);
    let carrito = new Carrito();
    carrito.eliminar( index );

    this.obtenerCarritoStorage();
  } */

  verDatos(){
    this._service.getObtener(this.servicios).subscribe(
      (res: any) => {
          this.serviceSi = res.data;
          this.totalAmbulancia = res.data.results;
      },
    err => {
        /* console.log(<any>err); */
    }
  );   
  }

}
