<div class="container-fluid">
    <app-titulos [title]="'BITÁCORA DE LABORATORIOS'" [rol]="'pacientes'"></app-titulos>
    <!-- <h1 class="centerxd">BITÁCORA DE LABORATORIOS</h1> -->
    <!-- <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar"  class="form-control" id="busquedaPaciente" >
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>

                        <div class="col-md-3 text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p> CURP </p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>GÉNERO</p>
                        </div>
                        <div class="col text_header">
                            <p>LUGAR DE ORIGEN</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div  *ngFor="let item of bitacoraPacientes; let i = index" >
                        <div class="row" id="pointer" [routerLink]="['/hoja/servicios/laboratorio/disponentes/', item._id]">
                            <div class="col-md-1" style="text-align: left;">
                                <p>
                                    {{ i + 1 }}
                                </p>
                            </div>
                            <div class="col-md-3" style="text-align: center;">
                                <p>
                                    {{ item.paciente?.nombrePaciente | uppercase }} 
                                    {{ item.paciente?.apellidoPaterno | uppercase }}
                                    {{ item.paciente?.apellidoMaterno | uppercase }} 
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{ item.paciente?.curp | uppercase}}
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{item.paciente?.edad}} años
                                </p>
                            </div>
                            <div class="col-2" style="text-align: center;">
                                <p>
                                    {{item.paciente?.genero | uppercase}}
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{item.paciente?.lugardeorigen | uppercase}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>