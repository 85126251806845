<!-- NO SE PUEDE MODIFICAR EL NOMBRE DE PATOLOGÍA/NI DE NINGUNA OTRA -->
<div class="container-fluid">
    <!-- <h1 class="centerxd">LISTADO DE {{ servicios | uppercase }}</h1> -->
    <app-titulos [title]="titulo" [rol]="'servicio'"></app-titulos>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio">
                </div>
            </div>
            <div class="col-2" *ngIf="roleuser == 'Admin' ">
                <button type="button" class="btn btn-secondary principalButton" id="boton_contra2" [routerLink]="['/formulario', servicios]" routerLinkActive="router-link-active">NUEVO</button>
            </div>
        </div>

        <!-- ESTA ES LA TABLA DE LOS DEMAS SERVICIOS  -->
            <app-tabla-service *ngIf="showTableAmbulanacia" [serviceSi]="serviceSi" [membresia]="membresia" [RoleUser]="roleuser" [totalAmbulancia]="totalAmbulancia" [servicios]="servicios"></app-tabla-service>
        <!-- TERMINA LA TABLA DE LOS DEMAS SERVICIOS  -->
        <app-ambulancia *ngIf="showAmbulanacia" [ambulanciaSI]=ambulanciaSI [showAmbulanacia2]=false></app-ambulancia>
    </div>
</div>



<!-- <patologia-x-membresia *ngIf="paciente" [membresia]="membresia" [servicio]="servicios" [serviceSi]="serviceSi"></patologia-x-membresia> -->