import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-hoja-reporte-patologia',
  templateUrl: './hoja-reporte-patologia.component.html',
  styleUrls: ['./hoja-reporte-patologia.component.css']
})
export class HojaReportePatologiaComponent implements OnInit {

  public id = "";

  public obtenidosPatologia = {

    interpretacion:"",
    diagnostico:"",
    observaciones:"",
    idPedido:"",
    idPaciente:"",
    idEstudio:""

  }

  public estudio = {
    ESTUDIO:"",
    ELEMENTOS:[],
    name:""
  }


  constructor(
    private activatedRoute: ActivatedRoute, 
    private _integradosService: IntegradosService, 
    private  _patologiaService: PatologiaService,
    private _routers: Router
  ) { }

  ngOnInit(): void {


    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    this.obtenerServicioPorId();
    this.obtenerLocalStorageItems();
  }

  obtenerLocalStorageItems() {

    this.obtenidosPatologia.idPedido = localStorage.getItem('idPedido');
    this.obtenidosPatologia.idPaciente = localStorage.getItem('idPaciente');
    this.obtenidosPatologia.idEstudio = this.id;

  }

  obtenerServicioPorId() {
    this._integradosService.buscarServicio(this.id)
    .subscribe((data:any) => {
    
      if(data.ok) {
        this.setDataEstudio( data.data );
      }

    })
  }

  setDataEstudio(data) {
    this.estudio = data;
  }

  enviarReporte(){

    this.pintLoadingScreen();
    this._patologiaService.crerPatologiaRegresos( this.obtenidosPatologia )
    .subscribe((data:any) => {
      
      if(data.ok){

        Swal.fire('REPORTE GUARDADO', 'Se guardo el reporte', 'success');
        setTimeout(() => {
            this._routers.navigate(['/'])

        }, 2000);

        this.removeLoadingScreen();
      }
    });

  }

  pintLoadingScreen() {
    // funcion que pinta el loading en la pantalla
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
  }
  
  removeLoadingScreen() {
    // se quita la clase que pinta el loading en la pantalla, la clase esta en el css
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
  }

}
