import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 
@Component({
  selector: 'app-cloro-tinaco-cisterna',
  templateUrl: './cloro-tinaco-cisterna.component.html',
  styleUrls: ['./cloro-tinaco-cisterna.component.css']
})
export class CloroTinacoCisternaComponent implements OnInit {

  public radios ={
    r1:"",
    r2:"", 
    r3:"",
    r4:"",
    r5:"", 
    r6:"",
    r7:"",
    r8:"", 
    r9:"",
    r10:"",
    r11:"", 
    r12:"",
    r13:"",
    r14:"", 
    r15:"",
    r16:"",
    r17:"", 
    r18:"",
    r19:"",
    r20:"", 
    r21:"",
    r22:"",
    r23:"", 
    r24:"",
    r25:"",
    r26:"", 
    r27:"",
    r28:"",
    r29:"", 
    r30:"",
    r31:"",
    r32:"", 
    r33:"",
    r34:"",
    r35:"", 
    r36:"",
    r37:"",
    r38:"", 
    r39:"",
    r40:"",
    r41:"", 
    r42:"",
    r43:"",
    r44:"", 
    r45:"",
    r46:"",
    r47:"", 
    r48:"",
    r49:"",
    r50:"", 
    r51:"",
    r52:"",
    r53:"", 
    r54:"",
    r55:"",
    r56:"", 
    }//fin pucblic radios
    
    public areas={
      areas1:"",
    areas2:"", 
    areas3:"",
    areas4:"",
    areas5:"", 
    areas6:"",
    areas7:"",
    areas8:"", 
    areas9:"",
    areas10:"",
    areas11:"", 
    areas12:"",
    areas13:"",
    areas14:"", 
    areas15:"",
    areas16:"",
    areas17:"", 
    areas18:"",
    areas19:"",
    areas20:"", 
    areas21:"",
    areas22:"",
    areas23:"", 
    areas24:"",
    areas25:"",
    areas26:"", 
    areas27:"",
    areas28:"",
    areas29:"", 
    areas30:"",
    areas31:"",
    areas32:"", 
    areas33:"",
    areas34:"",
    areas35:"", 
    areas36:"",
    areas37:"",
    areas38:"", 
    areas39:"",
    areas40:"",
    areas41:"", 
    areas42:"",
    areas43:"",
    areas44:"", 
    areas45:"",
    areas46:"",
    areas47:"", 
    areas48:"",
    areas49:"",
    areas50:"", 
    areas51:"",
    areas52:"",
    areas53:"", 
    areas54:"",
    areas55:"",
    areas56:"", 
    }
    
    public habilitaV1 = false
    public habilitaV2 = false
    public habilitaV3 = false
    public habilitaV4 = false
    public habilitaV5 = false
    public habilitaV6 = false
    public habilitaV7 = false
    public habilitaAC = false
    public habilitaA1 = false
    public habilitaA2 = false
    public habilitaA3 = false
    public habilitaA4 = false
    public habilitaA5 = false
    public habilitaA6 = false
    public habilitaA7 = false
    public habilitaLC = false
    public habilitaL1 = false
    public habilitaL2 = false
    public habilitaL3 = false
    public habilitaL4 = false
    public habilitaL5 = false
    public habilitaL6 = false
    public habilitaL7 = false
    public habilitaDC = false
    public habilitaD1 = false
    public habilitaD2 = false
    public habilitaD3 = false
    public habilitaD4 = false
    public habilitaD5 = false
    public habilitaD6 = false
    public habilitaD7 = false
    public habilitaLLC = false
    public habilitaLL1= false
    public habilitaLL2 = false
    public habilitaLL3 = false
    public habilitaLL4 = false
    public habilitaLL5 = false
    public habilitaLL6 = false
    public habilitaLL7 = false
    public habilitaCC = false
    public habilitaC1 = false
    public habilitaC2 = false
    public habilitaC3 = false
    public habilitaC4 = false
    public habilitaC5 = false
    public habilitaC6 = false
    public habilitaC7 = false
    public habilitaMC = false
    public habilitaM1 = false
    public habilitaM2 = false
    public habilitaM3 = false
    public habilitaM4 = false
    public habilitaM5 = false
    public habilitaM6 = false
    public habilitaM7 = false
    
    public save =false
    
      constructor() { }
    
      ngOnInit(): void {
      }
     cambioRadio(){
     if (this.radios.r1 !=""){ this.habilitaV1 = true}
     if (this.radios.r2 !=""){this.habilitaV2 = true}
     if (this.radios.r3 !=""){ this.habilitaV3 = true}
     if (this.radios.r4 !=""){this.habilitaV4 = true}
     if (this.radios.r5 !=""){ this.habilitaV5 = true}
     if (this.radios.r6 !=""){this.habilitaV6 = true}
     if (this.radios.r7 !=""){this.habilitaV7 = true}
     if (this.radios.r8 !=""){this.habilitaAC = true}
    
     if (this.radios.r9 !=""){ this.habilitaA1 = true}
     if (this.radios.r10 !=""){this.habilitaA2 = true}
     if (this.radios.r11 !=""){ this.habilitaA3 = true}
     if (this.radios.r12 !=""){this.habilitaA4 = true}
     if (this.radios.r13 !=""){ this.habilitaA5 = true}
     if (this.radios.r14 !=""){this.habilitaA6 = true}
     if (this.radios.r15 !=""){this.habilitaA7 = true}
     if (this.radios.r16 !=""){this.habilitaLC = true}
     
     if (this.radios.r17 !=""){ this.habilitaL1 = true}
     if (this.radios.r18 !=""){this.habilitaL2 = true}
     if (this.radios.r19 !=""){ this.habilitaL3 = true}
     if (this.radios.r20 !=""){this.habilitaL4 = true}
     if (this.radios.r21 !=""){ this.habilitaL5 = true}
     if (this.radios.r22 !=""){this.habilitaL6 = true}
     if (this.radios.r23 !=""){this.habilitaL7 = true}
     if (this.radios.r24 !=""){this.habilitaDC = true}
    
     if (this.radios.r25 !=""){ this.habilitaD1 = true}
     if (this.radios.r26 !=""){this.habilitaD2 = true}
     if (this.radios.r27 !=""){ this.habilitaD3 = true}
     if (this.radios.r28 !=""){this.habilitaD4 = true}
     if (this.radios.r29 !=""){ this.habilitaD5 = true}
     if (this.radios.r30 !=""){this.habilitaD6 = true}
     if (this.radios.r31 !=""){this.habilitaD7 = true}
     if (this.radios.r32 !=""){this.habilitaLLC = true}
    
     if (this.radios.r33 !=""){ this.habilitaLL1 = true}
     if (this.radios.r34 !=""){this.habilitaLL2 = true}
     if (this.radios.r35 !=""){ this.habilitaLL3 = true}
     if (this.radios.r36 !=""){this.habilitaLL4 = true}
     if (this.radios.r37 !=""){ this.habilitaLL5 = true}
     if (this.radios.r38 !=""){this.habilitaLL6 = true}
     if (this.radios.r39 !=""){this.habilitaLL7 = true}
     if (this.radios.r40 !=""){this.habilitaCC = true}
     
     if (this.radios.r41 !=""){ this.habilitaC1 = true}
     if (this.radios.r42 !=""){this.habilitaC2 = true}
     if (this.radios.r43 !=""){ this.habilitaC3 = true}
     if (this.radios.r44 !=""){this.habilitaC4 = true}
     if (this.radios.r45 !=""){ this.habilitaC5 = true}
     if (this.radios.r46 !=""){this.habilitaC6 = true}
     if (this.radios.r47 !=""){this.habilitaC7 = true}
     if (this.radios.r48 !=""){this.habilitaMC = true}
     
     if (this.radios.r49 !=""){ this.habilitaM1 = true}
     if (this.radios.r50 !=""){this.habilitaM2 = true}
     if (this.radios.r51 !=""){ this.habilitaM3 = true}
     if (this.radios.r52 !=""){this.habilitaM4 = true}
     if (this.radios.r53 !=""){ this.habilitaM5 = true}
     if (this.radios.r54 !=""){this.habilitaM6 = true}
     if (this.radios.r55 !=""){this.habilitaM7 = true}
     if (this.radios.r56 !=""){this.save = true}
     }
    enviar(){
       Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS DATOS SE ENVIARON CON EXITO',
            })
          this.limpiarRadio()
    }
    
    limpiarRadio(){
    this.radios.r1="",
    this.radios.r2="", 
    this.radios.r3="",
    this.radios.r4="",
    this.radios.r5="", 
    this.radios.r6="",
    this.radios.r7="",
    this.radios.r8="", 
    this.radios.r9="",
    this.radios.r10="",
    this.radios.r11="", 
    this.radios.r12="",
    this.radios.r13="",
    this.radios.r14="", 
    this.radios.r15="",
    this.radios.r16="",
    this.radios.r17="", 
    this.radios.r18="",
    this.radios.r19="",
    this.radios.r20="", 
    this.radios.r21="",
    this.radios.r22="",
    this.radios.r23="", 
    this.radios.r24="",
    this.radios.r25="",
    this.radios.r26="", 
    this.radios.r27="",
    this.radios.r28="",
    this.radios.r29="", 
    this.radios.r30="",
    this.radios.r31="",
    this.radios.r32="", 
    this.radios.r33="",
    this.radios.r34="",
    this.radios.r35="", 
    this.radios.r36="",
    this.radios.r37="",
    this.radios.r38="", 
    this.radios.r39="",
    this.radios.r40="",
    this.radios.r41="", 
    this.radios.r42="",
    this.radios.r43="",
    this.radios.r44="", 
    this.radios.r45="",
    this.radios.r46="",
    this.radios.r47="", 
    this.radios.r48="",
    this.radios.r49="",
    this.radios.r50="", 
    this.radios.r51="",
    this.radios.r52="",
    this.radios.r53="", 
    this.radios.r54="",
    this.radios.r55="",
    this.radios.r56="",
    
     this.habilitaV1 = false
     this.habilitaV2 = false
     this.habilitaV3 = false
     this.habilitaV4 = false
     this.habilitaV5 = false
     this.habilitaV6 = false
     this.habilitaV7 = false
     this.habilitaAC = false
     this.habilitaA1 = false
     this.habilitaA2 = false
     this.habilitaA3 = false
     this.habilitaA4 = false
     this.habilitaA5 = false
     this.habilitaA6 = false
     this.habilitaA7 = false
     this.habilitaLC = false
     this.habilitaL1 = false
     this.habilitaL2 = false
     this.habilitaL3 = false
     this.habilitaL4 = false
     this.habilitaL5 = false
     this.habilitaL6 = false
     this.habilitaL7 = false
     this.habilitaDC = false
     this.habilitaD1 = false
     this.habilitaD2 = false
     this.habilitaD3 = false
     this.habilitaD4 = false
     this.habilitaD5 = false
     this.habilitaD6 = false
     this.habilitaD7 = false
     this.habilitaLLC = false
     this.habilitaLL1= false
     this.habilitaLL2 = false
     this.habilitaLL3 = false
     this.habilitaLL4 = false
     this.habilitaLL5 = false
     this.habilitaLL6 = false
     this.habilitaLL7 = false
     this.habilitaCC = false
     this.habilitaC1 = false
     this.habilitaC2 = false
     this.habilitaC3 = false
     this.habilitaC4 = false
     this.habilitaC5 = false
     this.habilitaC6 = false
     this.habilitaC7 = false
     this.habilitaMC = false
     this.habilitaM1 = false
     this.habilitaM2 = false
     this.habilitaM3 = false
     this.habilitaM4 = false
     this.habilitaM5 = false
     this.habilitaM6 = false
     this.habilitaM7 = false
    
     this.save =false
    
     this.areas.areas1="",
     this.areas.areas2="", 
     this.areas.areas3="",
     this.areas.areas4="",
     this.areas.areas5="", 
     this.areas.areas6="",
     this.areas.areas7="",
     this.areas.areas8="", 
     this.areas.areas9="",
     this.areas.areas10="",
     this.areas.areas11="", 
     this.areas.areas12="",
     this.areas.areas13="",
     this.areas.areas14="", 
     this.areas.areas15="",
     this.areas.areas16="",
     this.areas.areas17="", 
     this.areas.areas18="",
     this.areas.areas19="",
     this.areas.areas20="", 
     this.areas.areas21="",
     this.areas.areas22="",
     this.areas.areas23="", 
     this.areas.areas24="",
     this.areas.areas25="",
     this.areas.areas26="", 
     this.areas.areas27="",
     this.areas.areas28="",
     this.areas.areas29="", 
     this.areas.areas30="",
     this.areas.areas31="",
     this.areas.areas32="", 
     this.areas.areas33="",
     this.areas.areas34="",
     this.areas.areas35="", 
     this.areas.areas36="",
     this.areas.areas37="",
     this.areas.areas38="", 
     this.areas.areas39="",
     this.areas.areas40="",
     this.areas.areas41="", 
     this.areas.areas42="",
     this.areas.areas43="",
     this.areas.areas44="", 
     this.areas.areas45="",
     this.areas.areas46="",
     this.areas.areas47="", 
     this.areas.areas48="",
     this.areas.areas49="",
     this.areas.areas50="", 
     this.areas.areas51="",
     this.areas.areas52="",
     this.areas.areas53="", 
     this.areas.areas54="",
     this.areas.areas55="",
     this.areas.areas56=""
    
    }
    }
    