import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'spinner-perrucho',
  templateUrl: './spinner-perrucho.component.html',
  styleUrls: ['./spinner-perrucho.component.css']
})
export class SpinnerPerruchoComponent implements OnInit {
  
  public isLoading$ = this._SpinnerService.isLoading$;

  constructor( private _SpinnerService: SpinnerService) { }
  
  ngOnInit(): void {
  }

}
