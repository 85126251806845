import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ficha-info-hopitalizado',
  templateUrl: './ficha-info-hopitalizado.component.html',
  styleUrls: ['./ficha-info-hopitalizado.component.css']
})
export class FichaInfoHopitalizadoComponent implements OnInit {

  @Input() rol='';

  constructor() { }

  ngOnInit(): void {
  }

}
