import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder,  Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registro-productos-almacen',
  templateUrl: './registro-productos-almacen.component.html',
  styleUrls: ['./registro-productos-almacen.component.css']
})
export class RegistroProductosAlmacenComponent implements OnInit {

  // inicializacion de la variables
  public btnRegistro = false;
  public newFormProductos: any;
  public proveedoresCensur = [];
  public countOfProducts = 0;
  public newIdProducto ="";
  public medicamento = false;

  constructor(private formBuilder: FormBuilder,
              private _alamcenService: AlmacenService,
              private _router: Router,
              private _spinner: NgxSpinnerService){}
    // dependencias necesarias

  ngOnInit(): void {
    // mandamos a llamar el metodo que crea el formulario dinamico
    this.obtenerProveedoresCensur();
    this.creacionDeFormulario();
    this.getCountOfProducts();
  }

  activarMedicamento(){
    this.medicamento = this.newFormProductos.value.medicamento
  }

  getCountOfProducts() { 
    this._alamcenService.countProducts()
    .subscribe((data:any) => this.setTotalOfProducts(data.data));
  }

  setTotalOfProducts(data: number) {
    this.countOfProducts = data;
  }

  get valoresProveedor () {
    // obtenemos los controles que estan dentro del form Array
    return this.newFormProductos.get('proveedor') as FormArray;
  }


  agregarProveedor() {
    // metodo para crear el una nueva fila de controles
    const valoresProveedor = this.formBuilder.group({
      proveedor: '',
      costo: 0
    });
    
    this.valoresProveedor.push( valoresProveedor );
    // console.log("Agregar ejecutado")
  }

  obtenerProveedoresCensur() {
    this._alamcenService.obetenerProveedores()
      .subscribe( (data:any) => this.setProveedores(data.data));
  }

  setProveedores(data) {
    this.proveedoresCensur = data;
  }
  
  creacionDeFormulario() {
    
    // creacion del formualrio dinamico
    this.newFormProductos = this.formBuilder.group({
      nombre: ['', { validators: [Validators.required] }],
      tipo_producto: [''],
      nombre_comercial: ['', { validators: [Validators.required] }],
      idProducto: [ this.newIdProducto ],
      descripcion: [''],
      proveedor: this.formBuilder.array([]),
      laboratorio: [''],
      // precio:  ['', { validators: [Validators.required] }],
      cantidadMin: ['', { validators: [Validators.required] }],
      medicamento: [''],
      presentacion:[''],
      contenidoFrasco:[''],
      viaAdministracion:[''],
      // fechaCaducidad:[''],
      // lote:['']
    });

    this.agregarProveedor();
    // hacemos el push de los forms reactivos
  }

  validarFormulario() {
    // validmaos que el formualrio se haya llenado de forma correcta
    this.createAnId( this.newFormProductos.value );
  }

  removeValores( i: number ){
    if( this.valoresProveedor.length  > 1 ) {
      this.valoresProveedor['controls'].forEach((element, index) => {
        if (index == i) {
          this.valoresProveedor['controls'].splice(index, 1)
          this.valoresProveedor.value.splice(index, 1)
        }
      });
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'SE REQUIERE AGREGAR AL MENOS UN PROVEEDOR',
      });
    }
  }

  agregarNuevoProveedor(event){
    this.proveedoresCensur.push(event);
  }

  enviarFormualrio() {
    this.btnRegistro = true;
    this._spinner.show();
    // validamos el formulario
    if( this.newFormProductos.valid ){
      // bloqueamos el boton 
      // this.pintLoadingScreen();
      this.btnRegistro = true;
      // let valores;
      this.validarFormulario();
      this.newFormProductos.value.idProducto = this.newIdProducto
      this.newFormProductos.value.medicamento = this.medicamento
      //enviamos la peticion HTTP
      this._alamcenService.registroProductosAlmacen( this.newFormProductos.value )
      .subscribe( (data:any) => {
        // si la respuesta el true disparamos el alert 
        if(data.ok) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE REGISTRO EXITOSAMENTE EL PRODUCTO',
          });

          this._spinner.hide();
            // esperamos 2s para que redireccione al dash
          setTimeout(() => {
            this._router.navigateByUrl('/lista/productos/almacen');
          }, 2000)
        }
      });

    }else{
      this.btnRegistro = false;
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'ES NECESARIO COMPLETAR TODOS LOS CAMPOS',
      });
    }
  }

  // pintLoadingScreen() {
  //   // funcion que pinta el loading en la pantalla
  //   const sectionSpinner = document.querySelector('#sppiner-section');
  //   sectionSpinner.classList.add('display-block');
  // }

  // removeLoadingScreen() {
  //   // se quita la clase que pinta el loading en la pantalla, la clase esta en el css
  //   const sectionSpinner = document.querySelector('#sppiner-section');
  //   sectionSpinner.classList.remove('display-block');
  // }



  // funcion que se encraga de la creaicon de los ID de los producos
  createAnId(data: any){
    const { nombre_comercial, proveedor} = data;
    const nombreComercialCutted = nombre_comercial.slice( 0, 3 ).toUpperCase();
    const proveedorOne = proveedor[0].proveedor;
    const proveedorCutted = proveedorOne.slice( 0, 3 );

    const newIdOfProduct =  `${nombreComercialCutted}/${proveedorCutted}/${this.countOfProducts + 1}/${new Date().getMinutes()}/${new Date().getSeconds()}`;
    this.newIdProducto = newIdOfProduct;
    /* console.log( this.newIdProducto ); */
  }

}
