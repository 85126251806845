import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XRAYService } from 'src/app/services/Rayos-X/xray.service';

@Component({
  selector: 'app-entrega-rayosx',
  templateUrl: './entrega-rayosx.component.html',
  styleUrls: ['./entrega-rayosx.component.css']
})
export class EntregaRayosxComponent implements OnInit {

  public totalpaciente:string;
  public pagina = 0;

  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

  public id;

  public estudios={
    estudios: [{}],
    fechaDePedidoDeLosExamenes: "",
    idPaciente: "",
    prioridad: "",
    sede: "",
    _id :""
  }


  constructor(private activatedRoute: ActivatedRoute,
              private _xrayServices: XRAYService) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerXray();
  }

  grabaridPaciente(){
    localStorage.setItem('idPaciente',JSON.stringify(this.estudios.idPaciente));
    localStorage.setItem('idPedidoXray',JSON.stringify(this.estudios._id));
  }

  datosPaciente(paciente ){
    this.paciente=paciente
  }
  obtenerXray(){
    this._xrayServices.verPedidoXray(this.id).subscribe((data) => {
      this.estudios = data['data']
      this.totalpaciente=data['data'].results;
      this.datosPaciente(data['data']['idPaciente'])
      this.grabaridPaciente()
    })  
  }
}
