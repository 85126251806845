import { Component, OnInit } from '@angular/core';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-registrar-patologia',
  templateUrl: './registrar-patologia.component.html',
  styleUrls: ['./registrar-patologia.component.css']
})
export class RegistrarPatologiaComponent implements OnInit {

  constructor(
    private _PatologiaService: PatologiaService

  ) { }
  
  public btnValidacion= true;

  public patologiaData ={
    idPaciente:"",
    estudios: "",
    sede:"",
    medicoQueSolicito:"",
    fechaDePedidoDeLosExamenes:"",
    prioridad:"",
    status:"",
    idObtenido:"",
  }

  ngOnInit(): void {
  }

  validarForm(){
    if (this.patologiaData.estudios.length == 0) {

      alert('Ingresa el tipo de estudio de la endoscopia');

    }else if(this.patologiaData.sede.length == 0) {

      alert('Ingresa la sede de la endoscopia');

    }else if(this.patologiaData.medicoQueSolicito.length == 0) {

      alert('Ingresa el nombre del medico de la endoscopia');

    }else if(this.patologiaData.fechaDePedidoDeLosExamenes.length == 0) {

      alert('Ingresa la fecha de los examenes');

    }else if(this.patologiaData.prioridad.length == 0) {

      alert('Ingresa la prioridad de la endoscopia');

    }else if (this.patologiaData.status.length == 0) {

      alert('Ingresa el status de la endoscopia');

    }

    this.btnValidacion = false;
  }

  guardarPatologia(){
  this._PatologiaService.agregarPedidoPatologia(this.patologiaData)
    .subscribe((data:any) => {
      Swal.fire({
        title: 'LA ENDOSCOPIA SE CREO EXISTOSAMENTE',
        icon: 'success'
        }) 
    })
  }
}
