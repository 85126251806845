import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { URL } from 'src/app/config/conf';


@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  public url: string;

  
  constructor(private _http:HttpClient){
    this.url = URL;
  }

  actualizarCita(id:string, body) {
    let url = `${this.url}/agenda/update/${id}`
    return this._http.put(url, body )
  }
  CancelarCita(id:string, body) {
    let url = `${this.url}/agenda/cancelar/${id}`
    return this._http.put(url, body )
  }
  UpdateCitaID(id:string, body) {
    let url = `${this.url}/agenda/actualizarid/${id}`
    return this._http.put(url, body )
  }
  UpdateAgendar(id:string, body) {
    let url = `${this.url}/agenda/updateAgendar/${id}`
    return this._http.put(url, body )
  }
  agregarCita(body){
    const url = `${this.url}/agenda/agendar`;
    return this._http.post( url, body );
  }
  agregarServicio(body){
    const url = `${this.url}/agenda/agendar/servicios`;
    return this._http.post( url, body );
  }
  getAgendaSevicios(){
    const url = `${this.url}/agenda/servicios`;
   return this._http.get(url);
  }
  getAgenda(){
    const url = `${this.url}/agenda`;
   return this._http.get(url);
  }
 
  getAgendaId( id: string ) {
    const url = `${URL}/agenda/${id}`;
    return this._http.get( url );   
  }

  getAgendaIdPaciente( id: string ) {
    const url = `${URL}/agenda/paciente/${id}`;
    return this._http.get( url );   
  }
  getAgendaSerId( names: string ) {
    const url = `${URL}/servicio/${names}`;
    return  this._http.get( url );   
  }
 
  getServicios(){
    const url = `${this.url}/servicio`;
   return this._http.get(url);
  }

  obtenerAgendaPersonal(id){
    const url = `${this.url}/agenda/personal/${id}`;
    return this._http.get(url);
  }
}

