import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';

import { CardComponent } from './card/card.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChatComponent } from './chat/chat/chat.component';
import { FormularioServiciosComponent } from './servicios/formulario-servicios/formulario-servicios.component';
import { FormularioAmbulanciaComponent } from './servicios/formulario-ambulancia/formulario-ambulancia.component';
import { EndoscopiaComponent } from './servicios/serv/endoscopia/endoscopia.component';
import { AgendasComponent } from './servicios/serv/agendas/agendas.component';
import { AmbulanciaComponent } from './servicios/serv/ambulancia/ambulancia.component';
import { PatologiaXComponent } from './servicios/serv/patologia-x/patologia-x.component';
import { LabUltaTomoResComponent } from './servicios/serv/lab-ulta-tomo-res/lab-ulta-tomo-res.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { StepperComponent } from './registro/stepper/stepper.component';
import { TablasConsultasComponent } from './tablas/tablas-consultas/tablas-consultas.component';
import { TabHisroiaClinicaComponent } from './tabs/tab-hisroia-clinica/tab-hisroia-clinica.component';
import { FichaIdentificacionComponent } from './ficha-identificacion/ficha-identificacion.component';

import { HeaderRecetaComponent } from './header-receta/header-receta.component';
import { GraficasPediatriaComponent } from './graficas-pediatria/graficas-pediatria.component';
import { GraficasNinosComponent } from './graficas-ninos/graficas-ninos.component';
import { HistoriaClinicaComponent } from './hisotriaClinica/historia-clinica/historia-clinica.component';
import { PaquetesComponent } from './paquetes/paquetes.component';
import { RiesgoDeCaidaComponent } from './ficha-identificacion/riesgoCaida/riesgo-de-caida/riesgo-de-caida.component';
import { PrenatalComponent } from './paquetes/paciente-paquete/prenatal/prenatal.component';
import { NeonatalComponent } from './paquetes/paciente-paquete/neonatal/neonatal.component';
import { PediatricoComponent } from './paquetes/paciente-paquete/pediatrico/pediatrico.component';
import { MembresiaComponent } from './paquetes/paciente-paquete/membresia/membresia.component';
import { MedicoLaboralComponent } from './paquetes/paciente-paquete/medico-laboral/medico-laboral.component';
import { PrenatalRiesgoComponent } from './paquetes/paciente-paquete/prenatal-riesgo/prenatal-riesgo.component';
import { VidaPlenaComponent } from './paquetes/paciente-paquete/vida-plena/vida-plena.component';
import { FichaInfoUserComponent } from './ficha-identificacion/ficha-info-user/ficha-info-user.component';
import { GraficasSvComponent } from './graficas-sv/graficas-sv.component';
import { PagoServiciosConComponent } from './pagos/pagosCon/pago-servicios-con/pago-servicios-con.component';

import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TablaServiceComponent } from './servicios/serv/tabla-service/tabla-service.component';
import { StepperSocioComponent } from './registro-socio/stepper-socio/stepper-socio.component';
import { StepperSocioEditarComponent } from './registro-socio/stepper-socio-editar/stepper-socio-editar.component';
import { FichaInfoSocioComponent } from './ficha-identificacion/ficha-info-socio/ficha-info-socio.component';
import { UtilidadSocioServiciosComponent } from './utilidad-socio/utilidad-socio-servicios/utilidad-socio-servicios.component';
import { UtilidadSocioAmbulanciaComponent } from './utilidad-socio/utilidad-socio-ambulancia/utilidad-socio-ambulancia.component';
import { StepperSocioEditarAmbulanciaComponent } from './registro-socio/stepper-socio-editar-ambulancia/stepper-socio-editar-ambulancia.component';
import { PagosPaquetesComponent } from './paquetes/pagos-paquetes/pagos-paquetes.component';
import { PagosCensurComponent } from './pagos/pagos-censur/pagos-censur.component';
/* import { FichaInfoCensurComponent } from './ficha-identificacion/ficha-info-censur/ficha-info-censur.component'; */
import { RegistrarDisponenteComponent } from './registrar-disponente/registrar-disponente.component';
/* import { FichaEnfermeriaDisponenteComponent } from './ficha-enfermeria-disponente/ficha-enfermeria-disponente.component'; */
/* import { FichaInfoFlebotomiaComponent } from './ficha-identificacion/ficha-info-flebotomia/ficha-info-flebotomia.component'; */
/* import { FichaDoctorComponent } from './ficha-doctor/ficha-doctor.component'; */
import { IndicadoresGeograficosComponent } from './historia-clinica-disp/indicadores-geograficos/indicadores-geograficos.component';
import { AntecedentesComponent } from './historia-clinica-disp/antecedentes/antecedentes.component';
import { AntecedentesPersonalesComponent } from './historia-clinica-disp/antecedentes-personales/antecedentes-personales.component';
import { AntecedentesPatologicosComponent } from './historia-clinica-disp/antecedentes-patologicos/antecedentes-patologicos.component';
import { AntecedentesGinecoComponent } from './historia-clinica-disp/antecedentes-gineco/antecedentes-gineco.component';
import { PracticasRiesgoComponent } from './historia-clinica-disp/practicas-riesgo/practicas-riesgo.component';
import { ParejasSexualesComponent } from './historia-clinica-disp/parejas-sexuales/parejas-sexuales.component';
import { UltimoAnioComponent } from './historia-clinica-disp/ultimo-anio/ultimo-anio.component';
import { MedicamentosComponent } from './historia-clinica-disp/medicamentos/medicamentos.component';
import { ExploracionFisicaComponent } from './historia-clinica-disp/exploracion-fisica/exploracion-fisica.component';
import { DiagnosticoDisponenteComponent } from './historia-clinica-disp/diagnostico-disponente/diagnostico-disponente.component';
import { FichaReaccionesDerivadasComponent } from './ficha-reacciones-derivadas/ficha-reacciones-derivadas.component';
import { BiometriaLabDisponenteComponent } from './biometria-lab-disponente/biometria-lab-disponente.component';
import { ReaccionVasovagalComponent } from './reacciones-derivadas-disp/reaccion-vasovagal/reaccion-vasovagal.component';
import { ReaccionVenopuncionComponent } from './reacciones-derivadas-disp/reaccion-venopuncion/reaccion-venopuncion.component';
import { ReaccionToxicidadComponent } from './reacciones-derivadas-disp/reaccion-toxicidad/reaccion-toxicidad.component';
import { ReaccionAdversaComponent } from './reacciones-derivadas-disp/reaccion-adversa/reaccion-adversa.component';
/* import { FichaLaboratorioDisponenteComponent } from './ficha-laboratorio-disponente/ficha-laboratorio-disponente.component'; */
import { FichaIndentificaciosproductosAlmacenComponent } from './ficha-identificacion/almacen/ficha-indentificaciosproductos-almacen/ficha-indentificaciosproductos-almacen.component';
import { LaboratorioDispComponent } from './historia-clinica-disp/laboratorio-disp/laboratorio-disp.component';
import { SppinerComponent } from './ui/sppiner/sppiner.component';
import { FichasIdentificacionComponent } from './ficha-identificacion/ficha-info-bancoSangre/fichas-identificacion/fichas-identificacion.component';
import { TitulosComponent } from './ficha-identificacion/titulos/titulos.component';
import { EditablesComponent } from './editables/editables.component';
import { SpinnerPerruchoComponent } from './ui/spinner-perrucho/spinner-perrucho.component';
import { SignosVitalesComponent } from './signos-vitales/signos-vitales.component';
import { HistoriaClinicaHorizonteComponent } from './historia-clinica-horizonte/historia-clinica-horizonte/historia-clinica-horizonte.component';
import { AntecedentesHorizonteComponent } from './historia-clinica-horizonte/antecedentes-horizonte/antecedentes-horizonte.component';
import { SignosVitalesHorizonteComponent } from './historia-clinica-horizonte/signos-vitales-horizonte/signos-vitales-horizonte.component';
import { GinecoPorVisitaComponent } from './historia-clinica-horizonte/gineco-por-visita/gineco-por-visita.component';
import { MedicinaPrevComponent } from './historia-clinica-horizonte/medicina-prev/medicina-prev.component';
import { EsquemaVacunacionComponent } from './historia-clinica-horizonte/esquema-vacunacion/esquema-vacunacion.component';
import { NutricionComponent } from './historia-clinica-horizonte/nutricion/nutricion.component';
import { AntecedentesHFComponent } from './historia-clinica-horizonte/antecedentes-hf/antecedentes-hf.component';
import { AntecedentesPNPComponent } from './historia-clinica-horizonte/antecedentes-pnp/antecedentes-pnp.component';
import { AntecedentesPpComponent } from './historia-clinica-horizonte/antecedentes-pp/antecedentes-pp.component';
import { InterrogacionPAYSComponent } from './historia-clinica-horizonte/interrogacion-pays/interrogacion-pays.component';
import { AntecedentesGinecoObsComponent } from './historia-clinica-horizonte/antecedentes-gineco-obs/antecedentes-gineco-obs.component';
import { BitacoraResultadosPatologiaComponent } from './recepcion-regresos/bitacora-resultados-patologia/bitacora-resultados-patologia.component';
import { RegistroSedesComponent } from './registro-sedes/registro-sedes.component';
import { SelectServiciosComponent } from './select/select-servicios/select-servicios.component';
import { SelectMedicosComponent } from './select/select-medicos/select-medicos.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { HojaReporteUltrasonidosComponent } from './regresos/ultrasondios/hoja-reporte-ultrasonidos/hoja-reporte-ultrasonidos.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BabyandMomComponent } from './paquetes/paciente-paquete/babyand-mom/babyand-mom.component';
import { ActualizarPacienteComponent } from './actualizar-paciente/actualizar-paciente.component';
import { MetodosPagosComponent } from './metodos-pagos/metodos-pagos.component';
import { ProductosStockComponent } from './productos/productos-stock/productos-stock.component';
import { LabsRegresosComponent } from './regresos-service/labs-regresos/labs-regresos.component';
import { UsgRegresosComponent } from './regresos-service/usg-regresos/usg-regresos.component';
import { PrenatalHospiComponent } from './paquetes/paciente-paquete/prenatal-hospi/prenatal-hospi.component';
import { HojaReporteEndoscopiasComponent } from './regresos/endoscopias/hoja-reporte-endoscopias/hoja-reporte-endoscopias.component';
import { HojaReportePatologiasComponent } from './regresos/patologias/hoja-reporte-patologias/hoja-reporte-patologias.component';
import { HojaReporteRayosxComponent } from './regresos/rayos-x/hoja-reporte-rayosx/hoja-reporte-rayosx.component';
import { RayosxRegresosComponent } from './regresos-service/rayosx-regresos/rayosx-regresos.component';
import { EstudiosLbsPacienteComponent } from './regresos/estudios-lbs-paciente/estudios-lbs-paciente.component';
import { EstudiosUltrPacienteComponent } from './regresos/estudios-ultr-paciente/estudios-ultr-paciente.component';
import { FichaInfoHopitalizadoComponent } from './ficha-identificacion/ficha-info-hopitalizado/ficha-info-hopitalizado.component';
import { NavBarHorizonteComponent } from './nav-bar-horizonte/nav-bar-horizonte.component';
import { QuirofanoComponent } from './servicios/serv/quirofano/quirofano.component';
import { CalendarioPersonalComponent } from './calendario-personal/calendario-personal.component';
// import { FormularioCreadorComponent } from './servicios/creador-servicios/formulario-creador/formulario-creador.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  timeGridPlugin,
]);

@NgModule({
  declarations: [
    DashboardComponent,
    CardComponent,
    NavBarComponent,
    ChatComponent,
    FormularioServiciosComponent,
    FormularioAmbulanciaComponent,
    EndoscopiaComponent,
    AgendasComponent,
    AmbulanciaComponent,
    PatologiaXComponent,
    LabUltaTomoResComponent,
    StepperComponent,
    TablasConsultasComponent,
    TabHisroiaClinicaComponent,
    FichaIdentificacionComponent,
    GraficasPediatriaComponent,
    GraficasNinosComponent,
    HeaderRecetaComponent,
    HistoriaClinicaComponent,
    PaquetesComponent,
    RiesgoDeCaidaComponent,
    PrenatalComponent,
    NeonatalComponent,
    PediatricoComponent,
    MembresiaComponent,
    MedicoLaboralComponent,
    PrenatalRiesgoComponent,
    VidaPlenaComponent,
    FichaInfoUserComponent,
    GraficasSvComponent,
    LabUltaTomoResComponent,
    PagoServiciosConComponent,
    // FormularioCreadorComponent,
    TablaServiceComponent,
    StepperSocioComponent,
    StepperSocioEditarComponent,
    FichaInfoSocioComponent,
    UtilidadSocioServiciosComponent,
    UtilidadSocioAmbulanciaComponent,
    StepperSocioEditarAmbulanciaComponent,
    PagosPaquetesComponent,
    PagosCensurComponent,
    RegistrarDisponenteComponent,
    IndicadoresGeograficosComponent,
    AntecedentesComponent,
    AntecedentesPersonalesComponent,
    AntecedentesPatologicosComponent,
    AntecedentesGinecoComponent,
    PracticasRiesgoComponent,
    ParejasSexualesComponent,
    UltimoAnioComponent,
    MedicamentosComponent,
    ExploracionFisicaComponent,
    DiagnosticoDisponenteComponent,
    FichaReaccionesDerivadasComponent,
    BiometriaLabDisponenteComponent,
    ReaccionVasovagalComponent,
    ReaccionVenopuncionComponent,
    ReaccionToxicidadComponent,
    ReaccionAdversaComponent,
    FichaIndentificaciosproductosAlmacenComponent,
    LaboratorioDispComponent,
    SppinerComponent,
    FichasIdentificacionComponent,
    TitulosComponent,
    EditablesComponent,
    SpinnerPerruchoComponent,
    SignosVitalesComponent,
    HistoriaClinicaHorizonteComponent,
    AntecedentesHorizonteComponent,
    SignosVitalesHorizonteComponent,
    GinecoPorVisitaComponent,
    MedicinaPrevComponent,
    EsquemaVacunacionComponent,
    NutricionComponent,
    AntecedentesHFComponent,
    AntecedentesPNPComponent,
    AntecedentesPpComponent,
    InterrogacionPAYSComponent,
    AntecedentesGinecoObsComponent,
    BitacoraResultadosPatologiaComponent,
    RegistroSedesComponent,
    SelectServiciosComponent,
    SelectMedicosComponent,
    HojaReporteUltrasonidosComponent,
    BabyandMomComponent,
    ActualizarPacienteComponent,
    MetodosPagosComponent,
    ProductosStockComponent,
    LabsRegresosComponent,
    UsgRegresosComponent,
    PrenatalHospiComponent,
    HojaReporteEndoscopiasComponent,
    HojaReportePatologiasComponent,
    HojaReporteRayosxComponent,
    RayosxRegresosComponent,
    EstudiosLbsPacienteComponent,
    EstudiosUltrPacienteComponent,
    FichaInfoHopitalizadoComponent,
    NavBarHorizonteComponent,
    QuirofanoComponent,
    CalendarioPersonalComponent,
    // PagoServiciosSinComponent,

  ],
  schemas: [ 
    CUSTOM_ELEMENTS_SCHEMA
   ],
  exports: [
    CardComponent,
    NavBarComponent,
    ChatComponent,
    FormularioServiciosComponent,
    FormularioAmbulanciaComponent,
    EndoscopiaComponent,
    AgendasComponent,
    AmbulanciaComponent,
    PatologiaXComponent,
    LabUltaTomoResComponent,
    ReactiveFormsModule,
    StepperComponent,
    TablasConsultasComponent,
    TabHisroiaClinicaComponent,
    FichaIdentificacionComponent,
    GraficasNinosComponent,
    GraficasPediatriaComponent,
    GraficasSvComponent,
    HeaderRecetaComponent,
    HistoriaClinicaComponent,
    PaquetesComponent,
    RiesgoDeCaidaComponent,
    PrenatalComponent,
    NeonatalComponent,
    PediatricoComponent,
    MembresiaComponent,
    MedicoLaboralComponent,
    PrenatalRiesgoComponent,
    VidaPlenaComponent,
    AmbulanciaComponent,
    EndoscopiaComponent,
    PagoServiciosConComponent,
    FichaInfoUserComponent,
    TablaServiceComponent,
    StepperSocioComponent,
    StepperSocioEditarComponent,
    FichaInfoSocioComponent,
    UtilidadSocioServiciosComponent,
    UtilidadSocioAmbulanciaComponent,
    StepperSocioEditarAmbulanciaComponent,
    PagosPaquetesComponent,
    PagosCensurComponent,
    RegistrarDisponenteComponent,
    IndicadoresGeograficosComponent,
    AntecedentesComponent,
    AntecedentesPersonalesComponent,
    AntecedentesPatologicosComponent,
    AntecedentesGinecoComponent,
    PracticasRiesgoComponent,
    ParejasSexualesComponent,
    UltimoAnioComponent,
    MedicamentosComponent,
    ExploracionFisicaComponent,
    DiagnosticoDisponenteComponent,
    FichaReaccionesDerivadasComponent,
    BiometriaLabDisponenteComponent,
    ReaccionVasovagalComponent,
    ReaccionVenopuncionComponent,
    ReaccionToxicidadComponent,
    ReaccionAdversaComponent,
    FichaIndentificaciosproductosAlmacenComponent,
    LaboratorioDispComponent,
    SppinerComponent,
    FichasIdentificacionComponent,
    TitulosComponent,
    EditablesComponent,
    SpinnerPerruchoComponent,
    SignosVitalesComponent,
    HistoriaClinicaHorizonteComponent,
    AntecedentesGinecoObsComponent,
    BitacoraResultadosPatologiaComponent,
    RegistroSedesComponent,
    SelectServiciosComponent,
    SelectMedicosComponent,
    NgxSpinnerModule,
    HojaReporteUltrasonidosComponent,
    NgxSpinnerModule,
    BabyandMomComponent,
    ActualizarPacienteComponent,
    MetodosPagosComponent,
    ProductosStockComponent,
    LabsRegresosComponent,
    UsgRegresosComponent,
    PrenatalHospiComponent,
    HojaReporteEndoscopiasComponent,
    HojaReportePatologiasComponent,
    HojaReporteRayosxComponent,
    RayosxRegresosComponent,
    EstudiosLbsPacienteComponent,
    FichaInfoHopitalizadoComponent,
    NavBarHorizonteComponent,
    QuirofanoComponent,
    CalendarioPersonalComponent
    // FormularioCreadorComponent


  ],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatStepperModule,
    ChartsModule,
    NgxSpinnerModule,
    FullCalendarModule
    // HeaderRecetaComponent
  ],
  providers:[]
})
export class ComponentsModule { }
