import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class SocioService {

  public url: string;

  constructor(private _http:HttpClient) { 
    this.url = URL;
  }

  getObtener(id){
    return this._http.get(this.url +'/ver/utilidad/sede/'+id);
  }

  utilidad(body){  
    return this._http.post(this.url+'/utilidad/socio/agregar',body);
  }

  obtenerUtilidades(servicio,sede){
    return this._http.get(this.url + '/ver/utilidades/sede/'+servicio+'/'+sede);
  }

  updateUtilidad(id,body){
    return this._http.put(this.url + '/editar/utilidad/servicio/'+id,body);
  }

  eliminarUtilidad(id){
    return this._http.delete(this.url + '/eliminar/utilidad/servicio/'+id)
  }

  obtenerUtilidadServ(servicio,sede){
    return this._http.get(this.url + '/ver/servicio/utilidad/'+servicio+'/'+sede);
  }

  //ambulancia
  utilidadAmbulancia(body){  
    return this._http.post(this.url+'/utilidad/ambulancia/agregar',body);
  }

  updateUtilidadAmbulancia(id,body){
    return this._http.put(this.url + '/actualizar/utilidad/ambulancia/'+id,body);
  }

  obtenerUtilidadAmbulancia(servicio,sede){
    return this._http.get(this.url + '/ver/servicio/utilidad/ambulancia/'+servicio+'/'+sede);
  }

  obtenerUtilidadesAmbulancia(servicio,sede){
    return this._http.get(this.url + '/ver/utilidad/ambulancia/'+servicio+'/'+sede);
  }

  eliminarUtilidadAmbulancia(id){
    return this._http.delete(this.url + '/eliminar/utilidad/'+id)
  }

  updateRangoSede(sede,servicio,body){
    return this._http.put(this.url + '/actualizar/rango/'+sede+'/'+servicio,body);
  }

  updateRangoSedeAmbulancia(sede,body){
    return this._http.put(this.url + '/update/rango/ambulancia/'+sede,body);
  }

}
