<form #form='ngForm'>
    <mat-horizontal-stepper linear #stepper>
        <mat-step label="Datos generales">
            <div class="row">    
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <label for="nombreServicio"> Nombre del servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" maxlength="13" name="nombreServicio" ngModel id="nombreServicio" class="form-control">
                </div>

                <div class="col-md-2 offset-1">
                    <label for="idServicio"> ID del servicio </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="idServicio" ngModel id="idServicio" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="sede">  Sede </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="sede" ngModel id="sede" class="form-control">
                </div>
            </div>
        </mat-step>
        <mat-step label="Rango de utilidad">
            <div class="row">    
                <div class="col-md-2">
                    <label for="rangoUtilidad"> Rango de utilidad </label>
                </div>
                <div class="col-md-3">
                    <select name="rangoUtilidad" ngModel id="rangoUtilidad" class="form-control">
                    </select>
                </div>
            </div>
            
            <div class="row">    
                <div class="col-md-2">
                    <label for="precioPublico"> Precio publico </label>
                </div>

                <div class="col-md-3">
                    <input type="text" maxlength="13" name="precioPublico" ngModel id="precioPublico" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioMembresia"> Precio membresia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="precioMembresia" ngModel id="precioMembresia" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioUrgencia">  Precio urgencia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="precioUrgencia" ngModel id="precioUrgencia" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioUrgenciaMembresia"> Precio urgencia membresia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="precioUrgenciaMembresia" ngModel id="precioUrgenciaMembresia" class="form-control">
                </div>

                <div class="col-md-2">
                    <label for="precioHospitalizacion">  Precio hospitalización </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="precioHospitalizacion" ngModel id="precioHospitalizacion" class="form-control">
                </div>


                <div class="col-md-2 offset-1">
                    <label for="precioHopitalizacionMem"> Precio hospitalización membresia </label>
                </div>

                <div class="col-md-3">
                    <input type="text" name="precioHopitalizacionMem" ngModel id="precioHopitalizacionMem" class="form-control">
                </div>
                <!-- terminan los datos fiscales  -->
            </div>
        </mat-step>

        <mat-step label="Precio por utilidad">
            <div class="row">
                <div class="col-md-4">
                    <p id="utilidad1">Utilidad</p>
                </div>
                <div class="col-md-8">
                    <p id="utilidad2">Utilidad</p>
                </div>
            </div> 
            <div class="row">    
                <div class="col-md-2">
                    <label for="utilidadprecioPublico"> Precio público </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" maxlength="13" name="utilidadprecioPublico" ngModel id="utilidadprecioPublico" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="text" maxlength="13" name="utilidadprecioPublico2" ngModel id="utilidadprecioPublico2" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioMembresia"> Precio membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioMembresia" ngModel id="utilidadprecioMembresia" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioMembresia2" ngModel id="utilidadprecioMembresia2" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioUrgencia">  Precio urgencia </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioUrgencia" ngModel id="utilidadprecioUrgencia" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioUrgencia2" ngModel id="utilidadprecioUrgencia2" class="form-control">
                        </div>
                    </div> 
                </div>

                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioUrgenciaMembresia"> Precio urgencia membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioUrgenciaMembresia" ngModel id="utilidadprecioUrgenciaMembresia" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioUrgenciaMembresia2" ngModel id="utilidadprecioUrgenciaMembresia2" class="form-control">
                        </div>
                    </div>   
                </div>

                <div class="col-md-2">
                    <label for="utilidadprecioHospitalizacion">  Precio hospitalización </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioHospitalizacion" ngModel id="utilidadprecioHospitalizacion" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioHospitalizacion2" ngModel id="utilidadprecioHospitalizacion2" class="form-control">
                        </div>
                    </div>    
                </div>


                <div class="col-md-2 offset-1">
                    <label for="utilidadprecioHopitalizacionMem"> Precio hospitalización membresía </label>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioHopitalizacionMem" ngModel id="utilidadprecioHopitalizacionMem" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <input type="text" name="utilidadprecioHopitalizacionMem2" ngModel id="utilidadprecioHopitalizacionMem2" class="form-control">
                        </div>
                    </div>     
                </div>
                <!-- terminan los datos fiscales  -->
            </div>
            <div class="center">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
            </div>  
        </mat-step>

        <!-- <mat-step label="Agregar">
            <div class="center">
                <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
            </div>
        </mat-step> -->
    </mat-horizontal-stepper>
</form>
