<div class="row" class="ion-padding">
    <div class="col">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
    </div>
</div>

<!-- <section class="container-fluid">
    <div class="row">
        <div class="col-6">
            <select name="servicio" class="form-control" id="" #servicio (change)="irAUnServicio( servicio.value  )">
                <option   value="" selected> Selecciona un servicio  </option>
                <option   value="ambulancia"> Ambulancia</option>
                <option   value="consulta"  > Consulta general </option>
                <option   value="consultaEspecialista">  Consulta con especialista </option>
                <option   value="endoscopia"> Endoscopía</option>
                
                <option   value="laboratorio"> Laboratorio</option>
                
                <option   value="xray">     Rayos X </option>
                <option   value="resonancia"> Resonancia</option>
                <option   value="tomografias"> Tomografía</option>
                
                <option   value="ultrasonido"> Ultrasonido</option>
               
                <option   value="otros"> Servicios generales </option> 
              </select>
        </div>
    </div>
</section> -->
<br>
<section class="row" id="container">
        <div class="col">
            <div class="card" id="card">
                <div class="card-body">    
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="solicito">Doctor que solicito:</label>
                                <input type="text" name="solicito" id="solicito" [(ngModel)]="infoVenta.doctorQueSolicito" class="form-control" placeholder="Doctor que solicito...">
                            </div>
    
                        </div>
                    </div>
                    
                    <div class="row" id="metodos">
                        <div class="col">Método de pago</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <input type="radio" id="efectivo" name="efectivo" value="true" [(ngModel)]="infoVenta.efectivo">
                            <label for="efectivo">Pago en efectivo</label>
    
                            <div *ngIf="infoVenta.efectivo">
                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoEfectivo" id="montoEfectivo" [(ngModel)]="infoVenta.montoEfectivo" (blur)="calcularNuevoTotalEfectivo()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <input type="radio" id="transferencia" name="transferencia" value="true" [(ngModel)]="infoVenta.transferencia">
                            <label for="transferencia">Transferencia bancaria</label>
    
                            <div *ngIf="infoVenta.transferencia">
    
                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTranferencia" id="montoEfectivo" [(ngModel)]="infoVenta.montoTranferencia" (blur)="calcularMontoTransferencia()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <input type="radio" id="tarjetaDeCredito" (change)="agregarIva()" name="tarjetaDeCredito" value="true" [(ngModel)]="infoVenta.tarjetCredito">
    
                            <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                            <br>
                            <div *ngIf="infoVenta.tarjetCredito">
    
                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTranferencia" id="montoTranferencia" [(ngModel)]="infoVenta.montoTarjteCredito" (blur)="calcularMontoTarjetaCredito()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <input type="radio" id="tarjetaDeDebito" (change)="agregarIva()" name="tarjetaDeDebito" value="true" [(ngModel)]="infoVenta.tarjetaDebito">
    
                            <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                            <br>
                            <div *ngIf="infoVenta.tarjetaDebito">
    
                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjetaDebito" id="montoTarjetaDebito" [(ngModel)]="infoVenta.montoTarjetaDebito" (blur)="calcuarMontoTarjetaDebito()">
                            </div>
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col" id="boton">
                            <button class="boton" (click)="enviarPedido()" [disabled]="btnValidacion"> 
                                PAGAR
                            </button>
                        </div>
                        <!-- <div class="col">
                            <button class="btn btn-block btn-info btn-tarjeta ml-2" (click)="imprimirCotizacion()">
                                IMPRIMIR 
                            </button>
    
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col">
    
            <div class="card" id="card">
                <div class="card-header" id="cardheader">
                    <div class="row">
                        <div class="col"> Producto</div>
                        <div class="col" *ngIf="!pedido.compraConMembresia"> Precio público </div>
                        <div class="col" *ngIf="pedido.compraConMembresia"> Precio membresía</div>
                        <!-- <div class="col"></div> -->
                    </div>
                </div>
    
                <div class="card-body" style="overflow: auto;">
                    <div *ngFor="let item of pedido.estudios; let i = index">
                        <div class="row">
                            <div class="col">
                                {{ item.nombreEstudio }}
                            </div>
                            <div class="col" *ngIf="!pedido.compraConMembresia">
                                {{ item.precioSin }}
                            </div>
                            <div class="col" *ngIf="pedido.compraConMembresia">
                                {{ item.precioCon }}
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div *ngFor="let item of carrito.items; let i = index">
                        <div class="row">
                            <div class="col">
                                {{ item.nombreEstudio }}
                            </div>
                            <div class="col" *ngIf="!pedido.compraConMembresia">
                                {{ item.precioSin }}
                            </div>
                            <div class="col" *ngIf="pedido.compraConMembresia">
                                {{ item.precioCon }}
                            </div>
                            <!-- <div class="col">
                                <label class="btn btn-outline-danger" (click)="eliminar(i)">
                                    <i class="fas fa-trash-alt"></i>
                                </label>
                            </div> -->
                        </div>
                        <hr>
                    </div>
                </div>
                <div class="card-footer" id="cardfooter">
                    <div class="row">
                        <div class="col"> Anticipo </div>
                        <div class="col white"> {{pedido.montoAnticipo | currency}} </div>
                    </div>

                    <div class="row" *ngIf="pedido.compraConMembresia">
                        <div class="col totalApagar">Total membresía: </div>
                        <div class="col center totalApagar"> {{ totalCarritoMembresia | currency}}</div>
                    </div>
    
                    <div class="row" *ngIf="!pedido.compraConMembresia">
                        <div class="col totalApagar">Total publico: </div>
                        <div class="col center totalApagar"> {{ totalCarritoMembresia | currency}}</div>
                    </div>
                    <!-- <div class="row" *ngIf="!paciente.membresiaActiva">
                        <div class="col">Ahorro: </div>
                        <div class="col center"> {{ carrito.totalSin - carrito.totalCon | currency }}</div>
                    </div> -->
    
                </div>
            </div>
        </div>
</section>