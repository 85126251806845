import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 

import { EdicionService } from 'src/app/services/ultra/edicion.service';
@Component({
  selector: 'app-formulario-elementos',
  templateUrl: './formulario-elementos.component.html',
  styleUrls: ['./formulario-elementos.component.css']
})
export class FormularioElementosComponent implements OnInit {

  

  forma: FormGroup;
  public id;
  public elementos = {
    ELEMENTOS: '',

    ESTUDIO: ''
  };


  public ultrasonidos ={
    ESTUDIO:"",
    ELEMENTOS:[{machote:""}],
    _id:"",
machote:""
  }

  public contador =0;

  public machote:any;

  constructor(private  servicioUltra : EdicionService,private fb: FormBuilder,private _route: ActivatedRoute , private _routeservi : IntegradosService) { 
    
    this.machote = this.servicioUltra.obtenerArreglo();
    /* console.log(this.machote); */
    
    this.crearFormulario();
    
  }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerPedidosUltra();
    this.obtenerUSG();
    this.eliminarLocal()     ;

  }

  obtenerUSG(){
    this._routeservi.getServicioById(this.id)
    .subscribe((data:any) => {
      this.elementos = data['data'];
    })
 /* console.log(this.elementos) */
  }

  get grupos(){
    return this.forma.get('grupos') as FormArray;
  }
 eliminarLocal(){
  localStorage.removeItem('editado');
 }

  crearFormulario(){
    // this.machote=JSON.parse(localStorage.getItem('editado'))
    // console.log(this.machote);
    
    this.forma = this.fb.group
    ({
     grupos:this.fb.array([]),

    });
   
   this.agregarCampo()
      //  localStorage.removeItem("editado")

     }//fin metodo crearformulario()


     
  agregarCampo(){
    
    if(this.machote == "" || this.machote == undefined || this.machote == null || this.contador == 1   ){
      const elemento =  this.fb.group({
        machote: "",
        
      });
      this.grupos.push(elemento)
      /* console.log("entro 1"); */
    } else {

      const elemento =  this.fb.group({
        machote: this.machote,
      });
      this.grupos.push(elemento)
      /* console.log("entro 2"); */
      this.contador += 1;
    }
    
     }

     borrarCampo(i :  number){
       this.grupos.removeAt(i);
     }
   
       vista(){
         /* console.log(this.forma) */
         
       }


       setPeidoUltra(pedido){
        this.ultrasonidos =pedido;
       
        if (this.ultrasonidos.ELEMENTOS[0].machote == undefined)
        {
          /* console.log("no hay nada"); */  
           Swal.fire({
              icon: 'warning',
              title: '',
              text: 'DATOS INCOMPLETOS',
            })
       }else {this.machote = this.ultrasonidos.ELEMENTOS[0].machote;} 
         /* console.log(this.machote); */
         localStorage.setItem('editado',JSON.stringify( this.machote));
       // console.log(this.ultrasonidos.ELEMENTOS[0]);
         
         
        }
          obtenerPedidosUltra(){
           this._routeservi.buscarServicio (this.id)
           .subscribe((data) => this.setPeidoUltra(data ['data']))
               
          }


  enviarUSG( ) {
    this._routeservi.actualizarElemtos(this.id, this.forma.value)
    .subscribe((data:any) => {
      this.elementos = data['data'];
      /* alert('Se ha enviado MACHOTE de Estudio')
      console.log(data); */
       Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS DATOS SE ENVIARON CORRECTAMENTE',
            })      
    })
  }
}
