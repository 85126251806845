<div class="container-fluid">
    <div class="col">
        <!-- <h1 class="centerxd" id="centerxd">HOJA DE SERVICIOS</h1> -->
        <app-titulos [title]="'HOJA DE SERVICIOS'"></app-titulos>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTADO</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of pedido.estudios | paginate:{id:'listapacientes',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer" [routerLink]="['/final/obtenidos',item.idEstudio]" (click)="grabarLocalStorage()" *ngIf="item.estado !=='En espera'">
                            <div class="col">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col"  (click)="verEstudio(item.nombreEstudio)" >
                                <p>{{item.nombreEstudio}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.estado | uppercase}}</p>
                            </div>
                        </div>
                        <div class="row pointer"  *ngIf="item.estado ==='En espera'">
                            <div class="col">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col" (click)="grabarLocalStorage()"   >
                                <p >{{item.nombreEstudio}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.estado | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>







<!-- <div class="col-md-12 col-lg-12">
    <div class="card">
        <div class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p>No</p>
                </div>
                <div class="col text_header">
                    <p>Nombre de Estudio</p>
                </div>
                <div class="col text_header">
                    <p>Estado</p>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div *ngFor="let item of pedido.estudios | paginate:{id:'lista',
                itemsPerPage: 5 ,
                currentPage:pagina,
                totalItems:total}; let i=index">
                <div class="row" [routerLink]="['/final/obtenidos',item.idEstudio]" (click)="grabarLocalStorage()" *ngIf="item.estado !=='En espera'">
                    <div class="col" style="text-align: center;">
                        <p>{{ i +1 }}</p>
                    </div>
                    <div class="col" style="text-align: center;"  (click)="verEstudio(item.nombreEstudio)" >
                        <p>{{item.nombreEstudio}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.estado | uppercase}}</p>
                    </div>

                </div>
                <div class="row" (click)="grabarLocalStorage()" *ngIf="item.estado ==='En espera'">
                    <div class="col" style="text-align: center;">
                        <p>{{ i +1 }}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.nombreEstudio}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.estado | uppercase}}</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>
    </div>
</div> -->