import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { metodo } from '../../interfaces/metodos-pagos'; 

@Component({
  selector: 'app-metodos-pagos',
  templateUrl: './metodos-pagos.component.html',
  styleUrls: ['./metodos-pagos.component.css']
})
export class MetodosPagosComponent implements OnInit {

  public metodos:metodo = {} as metodo;
  @Output() metodosPago = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  efectivo(){
    if (this.metodos.efectivo == false) {
      this.metodos.montoEfectivo = 0;
    }
    this.metodosPago.emit(this.metodos);
  }

  transfer(){
    if (this.metodos.transferencia == false) {
      this.metodos.montoTranferencia = 0;
    }
    this.metodosPago.emit(this.metodos);
  }

  credito(){
    if (this.metodos.tarjetCredito == false) {
      this.metodos.montoTarjteCredito = 0;
      this.metodos.comisionCredito='';
    }
    this.metodosPago.emit(this.metodos);
  }

  debito(){
    if (this.metodos.tarjetaDebito == false) {
      this.metodos.montoTarjetaDebito = 0;
      this.metodos.comisionDebito='';
    }
    this.metodosPago.emit(this.metodos);
  }

  agregarEfectivo(event){
    this.metodos.montoEfectivo = parseInt(event.target.value)
    this.metodosPago.emit(this.metodos);
  }

  agregarTransfer(event){
    this.metodos.montoTranferencia = parseInt(event.target.value)
    this.metodosPago.emit(this.metodos);
  }

  agregarIvaCredito(event){
    this.metodos.montoTarjteCredito = parseInt(event.target.value)
    this.metodos.comisionCredito = this.agregarIva(this.metodos.montoTarjteCredito);
    this.metodosPago.emit(this.metodos);
  }

  agregarIvaDebito(event){
    this.metodos.montoTarjetaDebito = parseInt(event.target.value)
    this.metodos.comisionDebito = this.agregarIva(this.metodos.montoTarjetaDebito);
    this.metodosPago.emit(this.metodos);
  }

  agregarIva(pago:any){
    let iva = 0.0;
    if( this.metodos.tarjetCredito  ){
      iva = .028;
    }
    if( this.metodos.tarjetaDebito ){
      iva = .018;
    }
    let totalIva = (( pago * iva ));
    return totalIva.toFixed(2);
  }

}
