import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export class AlmacenService {

  constructor(  private _http:HttpClient    ) {
        
  } 


  //obtien los productos activos de la tabla de almacen y que tengan el campo productosFI

  obtenerProductosAlmacenBitacora (){
    
    const url = `${URL}/ver/todos/productos/almacen`;
    return this._http.get( url );

   }

   obtenerLosProductosYMateriales(body:any) {

    const url = `${URL}/buscar/nombre/productos/materiales`;
    return this._http.post( url, body );
    
   }
   
  // hace el registro de los productos de forma administrativa, osea solo la fI del producto es la que se llena
  registroProductosAlmacen( body: any ) {

    const url = `${URL}/agregar/productos/almacen`;
    return this._http.post( url, body );
  }

  //obtener productos que hay en el stock por el  id
  obteneProductosPorId( id: string ) {
    const url = `${URL}/ver/productos/almacen/por/id/${id}`;
    return this._http.get( url );   
  }

  // obtenemos los productos que estan en el stock
  obtenerTodosLosProductosAlmacenBS(){
    const url = `${URL}/obtener/todos/produtos/stock`;
    return this._http.get( url );
  }

  // agregamos los productos de forma individual al stock, se agregan uno * uno
  agregarStockProducto(body: any){
    const url = `${URL}/agregar/productos/almacen/stock`;
    return this._http.post( url, body );
  }

  // obtenemos los productos de la tabla admin de productos por el id
  obtenerProductosPorIdTablaAdmin( id: string ){

    const url = `${URL}/ver/producto/almacen/por/id/${id}`;
    return this._http.get( url );
  
  }

  //Actualizacion de productos
  CambiarProducto(id, body){
    let url = `${URL}/actualizar/productos/almacen/${id}`;
    return this._http.put( url,body );
  }

  // obtenemos todos los proveedores que estan registrados
  obetenerProveedores ( ) {
    
    const url = `${URL}/ver/proveedores`;
    return this._http.get( url );
  
  }
  //este servicio nos retorna el total de los prductos que hay registrarProductoEnStock

  countProducts() {
      const url = `${URL}/count/total/productos`;
      return this._http.get( url );
  }


  //este servicio cambie el estado de la etiqueta del producto
  changeStatusLabeldownloaded(id: String) {

    const url = `${URL}/actualizar/status/etiqueta/${id}`;
    return this._http.get( url )
  }

  //este servicio desahabilita un producto

  disabledAProduct( id: String ){
    const url = `${URL}/desactivar/producto/almacen/${id}`;
    return this._http.get( url);
  }

  // este servicio actualiza el costo de un pooducto por el ID

  updatePriceAProductById( id: String, body:any) {

    const url = `${URL}/actualizar/productos/costo/${id}`;
    return this._http.put(url, body);

  }


  /*
   *  Rutas de los serivicios
   * 
   */


  // creamos un nuevo servicio 

  nuevoServicioCensur( body: any ) {
    
    const url  = `${URL}/agregar/servicio/censur`;
    return this._http.post(url, body);
  
  }


  getCounterAllDocumentsSerivices () {

    const url = `${URL}/ver/folio/servicios`  
    return this._http.get(url);
  }


  getListadoMateriales(){
   const url = `${URL}/materiales/banco/sangre`
   return this._http.get(url);
  } 

  /*
   * Fin de las rutas de los serivicos 
   */

  /**
   * 
   * RUTAS DE MATERIAALES
   */


  addANewMaterial(body:any) {

    const url = `${URL}/agregar/nuevo/material/banco/sangre`;
    return this._http.post( url, body );
  }

  /**
   * 
   * OBTENER el servicio por el ID 
   */

  getServiceCensurById(id: String){

    const url = `${URL}/ver/servicio/banco/sangre/${id}`;
    return this._http.get( url)
  }

  getcountAllMaterial(token: String) {
    const url = `${URL}/count/all/materiales/${token}`
    return this._http.get( url );
  }

  buscarMaterialProductos(body){
    const url = `${URL}/buscar/nombre/productos/materiales`;
    return this._http.post( url, body);  
  }

  /* 
  *
  *  SECCION DE Seguimientos
  */

  obtenerSeguimentos(){

    const url = `${ URL }/ver/seguimientos/creados`;
    return this._http.get( url );
  }

  crearSeguimiento( body: any ) {
    const url = `${URL}/crear/nuevo/seguimiento`;
    return this._http.post( url, body );
  }

  //obtenemos la fid del producto
  getproductosAlmacenId(id: any ) {

    const url = `${ URL }/ver/producto/almacen/por/id/${id}`;
    return this._http.get( url );
  }

  /**
   *  SECCION GENERAL-PACIENTES 
   */
  obtenerPacientes(){
    const url = `${ URL }/pacientes`;
    return this._http.get(url);
  }

  /**
   * SECCION DE ENDOSCOPIA
   */
  postearEndoscopia( body: any ){
    const url = `${ URL }/agregar/pedido/endoscopia`;
    return this._http.post( url, body );
  }


  //actualiza el prodcuto pero solo en el stock
  actualizarProductosStock(  id:any, body: any ){
    const url = `${ URL }/actualizar/productos/stock/almacen/${id}`;
    return this._http.put( url, body );
  }

  //Aqui inician los servicios
  crearServicioDepartamento(body){
    const url = `${URL}/agregar/servicio/departamento`;
    return this._http.post( url, body );
  }

  obtenerFechasDisponibles(id){
    const url = `${ URL }/horario/servicios/${id}`;
    return this._http.get(url);
  }



}

