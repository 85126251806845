import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiciosService } from 'src/app/services/admin/servicios.service';



@Component({
  selector: 'app-hoja-servicios',
  templateUrl: './hoja-servicios.component.html',
  styleUrls: ['./hoja-servicios.component.css']
})
export class HojaServiciosComponent implements OnInit {

public id: string

public paciente  = {
  nombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  estadoPaciente: '',
  fechaNacimiento: '',
  telefono: '',
  edad: 0,
  genero: '',
  curp:'',
  callePaciente:'',
  cpPaciente:'',
  paisPaciente:'',
  idMedicinaPreventiva: '',
  idAntecedentesHeredoFam: '',
  idPaciente:'',
  _id:""
};
public name: string;
public estudios: Object;
public pagina = 0;
public totalpaciente:string;

constructor( private _router: Router,
  private activatedRoute: ActivatedRoute,
  private _servicesService: ServiciosService) { 
   
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.name = this.activatedRoute.snapshot.paramMap.get('nombreServicio')
    this.obtenerPedidoLab()
  }

  obtenerPedidoLab(){
    let id = {"id": this.id, "nameService": this.name}
    this._servicesService.getEstudiesByServices(id)
    .subscribe( (data) =>   {
      if(data['data'].length == 0){
        this._router.navigate(['/dashboard']);
      }else{
        this.estudios = data['data']
        this.totalpaciente = data['data'].results
        this.paciente = data ['data'][0]['idPaciente']
      }
      //this.estadoActualizado(data ['data']['estudios']);      
    });
  }

  navegar(item:any){
    /* console.log(item); */
    localStorage.setItem('idUltra',item.idServicio._id)
    this._router.navigate(['/hoja-reporte',item.nombreServicio ,item._id]);
  }

}
