import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecetasService } from 'src/app/services/recetas/recetas.service';

@Component({
  selector: 'app-rectas-detalle',
  templateUrl: './rectas-detalle.component.html',
  styleUrls: ['./rectas-detalle.component.css']
})
export class RectasDetalleComponent implements OnInit {

  public idReceta = "";
  public receta = {
    medicamentos: [],
    estudios:[],
    idPaciente:{
      nombrePaciente:"",
      apellidoPaterno:"",
      apellidoMaterno:"",
      edad:0,
      genero : "",
      telefono:""
    }
  };

  constructor(

    private _activatedRoute: ActivatedRoute, 
    private _recetaService : RecetasService
  
) { }

  
  ngOnInit(): void {
    this.obtenerIdReceta();
    this.obtenerReceta();
  }


  obtenerIdReceta(){
    this.idReceta = this._activatedRoute.snapshot.paramMap.get('id');
  }

  obtenerReceta() {
    this._recetaService.verRecetaPorId(this.idReceta )
    .subscribe( (data:any)  => {
      if( data.ok) {
          this.setRecetaInfo(data.data);
      }
    })

  }


  setRecetaInfo(data) {
    this.receta = data;
  }

}
