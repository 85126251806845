import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { IntegradosService } from '../../../services/servicios/integrados.service';
import { SocioService } from '../../../services/socio/socio.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-utilidad-socio-ambulancia',
  templateUrl: './utilidad-socio-ambulancia.component.html',
  styleUrls: ['./utilidad-socio-ambulancia.component.css']
})
export class UtilidadSocioAmbulanciaComponent implements OnInit {

  public servicios:string;
  public servicioSI: any [] = [];
  public totalAmbulancia: string;
  public pagina = 0;
  public sede;
  public porcentaje:any[]=[];
  public rango={
    rangoUtilidad:''
  }

  constructor(private _router: Router, private _service: IntegradosService, private activatedRoute: ActivatedRoute, public _sede:SocioService) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.verDatos();
    this.obtenerId(); 
    this.obtenerUtilidad();
  }

  verDatos(){
    this._service.getDestino(this.servicios).subscribe((res: any) => {
      if(res.ok){
        this.setDatos( res['data']  );
        this.totalAmbulancia = res.data.results;
      }
    },
    err => {
      console.log(<any>err);
    });       
  }

  Rango(valor){
    this.rango.rangoUtilidad=valor
    this._sede.updateRangoSedeAmbulancia(this.sede,this.rango).subscribe((resp:any)=>{
      if(resp.ok){
        /* console.log(resp); */
        this.obtenerUtilidad(); 
      }
    })
  }

  setDatos(datos){
    this.servicioSI = datos; 
    /* console.log(this.servicioSI); */    
  }

  obtenerId(){
    this.sede= localStorage.getItem('sede');
  }

  obtenerUtilidad(){   
    this._sede.obtenerUtilidadesAmbulancia(this.servicios,this.sede).subscribe((resp:any) =>{
      if(resp.ok){
        if(resp['data'].length == 0){
          this.porcentaje=[];
        }else{
          this.setDatosUtilidad(resp);
        } 
      }
    })    
  }

  setDatosUtilidad(resp){      
    this.porcentaje=[];
    if(resp['data'].length == 0){
      this.porcentaje=[];
    }else{
      for (const item of resp['data']) {
        this.porcentaje.push(item);        
      }
    }
  }

  socioUtilidad (idServ,precio): any{ 
    var valor = this.porcentaje.find(id => id.idServicio._id === idServ.toString());     
    if(valor != undefined){
      switch(valor.rangoUtilidad){
        case 'A':
          if(precio == "PRECIO_PUBLICO_DIA"){
            return valor.preciosRangoA[0].precio_publico_diaA;
          }else if(precio == "PRECIO_MEMBRESIA_DIA"){
            return valor.preciosRangoA[0].precio_membresia_diaA;
          }else if(precio == "PRECIO_PUBLICO_NOCHE"){
            return valor.preciosRangoA[0].precio_publico_nocheA;
          }else if(precio == "PRECIO_MEMBRESIA_NOCHE"){
            return valor.preciosRangoA[0].precio_membresia_nocheA;
          }else if(precio == "PRECIO_PUBLICO_REDONDO_DIA"){
            return valor.preciosRangoA[0].precio_publico_redondo_diaA;
          }else if(precio == "PRECIO_MEMBRESIA_REDONDO_DIA"){
            return valor.preciosRangoA[0].precio_membresia_redondo_diaA;
          }else if(precio == "PRECIO_PUBLICO_REDONDO_NOCHE"){
            return valor.preciosRangoA[0].precio_publico_redondo_nocheA;
          }else if(precio == "PRECIO_MEMBRESIA_REDONDO_NOCHE"){
            return valor.preciosRangoA[0].precio_membresia_redondo_nocheA;
          }
          break;
        case 'B':
          if(precio == "PRECIO_PUBLICO_DIA"){
            return valor.preciosRangoB[0].precio_publico_diaB;
          }else if(precio == "PRECIO_MEMBRESIA_DIA"){
            return valor.preciosRangoB[0].precio_membresia_diaB;
          }else if(precio == "PRECIO_PUBLICO_NOCHE"){
            return valor.preciosRangoB[0].precio_publico_nocheB;
          }else if(precio == "PRECIO_MEMBRESIA_NOCHE"){
            return valor.preciosRangoB[0].precio_membresia_nocheB;
          }else if(precio == "PRECIO_PUBLICO_REDONDO_DIA"){
            return valor.preciosRangoB[0].precio_publico_redondo_diaB;
          }else if(precio == "PRECIO_MEMBRESIA_REDONDO_DIA"){
            return valor.preciosRangoB[0].precio_membresia_redondo_diaB;
          }else if(precio == "PRECIO_PUBLICO_REDONDO_NOCHE"){
            return valor.preciosRangoB[0].precio_publico_redondo_nocheB;
          }else if(precio == "PRECIO_MEMBRESIA_REDONDO_NOCHE"){
            return valor.preciosRangoB[0].precio_membresia_redondo_nocheB;
          }
          break;
        case 'C':
          if(precio == "PRECIO_PUBLICO_DIA"){
            return valor.preciosRangoC[0].precio_publico_diaC;
          }else if(precio == "PRECIO_MEMBRESIA_DIA"){
            return valor.preciosRangoC[0].precio_membresia_diaC;
          }else if(precio == "PRECIO_PUBLICO_NOCHE"){
            return valor.preciosRangoC[0].precio_publico_nocheC;
          }else if(precio == "PRECIO_MEMBRESIA_NOCHE"){
            return valor.preciosRangoC[0].precio_membresia_nocheC;
          }else if(precio == "PRECIO_PUBLICO_REDONDO_DIA"){
            return valor.preciosRangoC[0].precio_publico_redondo_diaC;
          }else if(precio == "PRECIO_MEMBRESIA_REDONDO_DIA"){
            return valor.preciosRangoC[0].precio_membresia_redondo_diaC;
          }else if(precio == "PRECIO_PUBLICO_REDONDO_NOCHE"){
            return valor.preciosRangoC[0].precio_publico_redondo_nocheC;
          }else if(precio == "PRECIO_MEMBRESIA_REDONDO_NOCHE"){
            return valor.preciosRangoC[0].precio_membresia_redondo_nocheC;
          }
          break;
      }
    }else{
      return valor=0;
    }
  }

  precios(precio):any{
    let precioSinTrim  =  precio.replace('$', '');
    let precioSinComa = precioSinTrim.replace(',', '');
        // aca le quito la coma si es que trae
    let precioSinMembresiaNumber = parseFloat( precioSinComa );
    return(precioSinMembresiaNumber);
  }

  eliminar(idServ){
    const valor = this.porcentaje.find(id => id.idServicio._id === idServ.toString());
    if(valor == undefined){
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'NO ELIMINO NINGUNA UTILIDAD',
        })
    }else{
      this.alertEliminar(valor._id);
    }
  }

  alertEliminar(valor){
        Swal.fire({title: "¿Estas seguro de eliminar la utilidad?",
    text: "Una vez que se haya eliminado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._sede.eliminarUtilidadAmbulancia(valor).subscribe((resp:any) => {
          if(resp.ok){
          this.obtenerUtilidad();
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA UTILIDAD SE ELIMINO CORRECTAMENTE',
            })
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'LA UTILIDAD NO SE ELIMINO',
            })
            this._router.navigateByUrl('dash/'+this.sede+'/'+this.servicios);
      } 
    })
  }

  editar(id){
    Swal.fire({title: "¿Estas seguro que vas a editar la utilidad?",
    text: "Una vez que se haya editado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._router.navigateByUrl('editar/utilidad/'+ this.servicios+ '/' +id)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'NO SE EDITO NADA',
            })
            this._router.navigateByUrl('dash/'+this.sede+'/'+this.servicios);
      } 
    })
}
}