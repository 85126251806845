<div class="container-fluid animate bounceInLeft">
    <div>
        <ficha-info-user [pacienteInfo]="pacienteInfo"></ficha-info-user>
    </div>
    
    <div class="card" id="card">
        <div class="row">
            <div class="col-md-6">
                <div class="container-fluid">
                    <div class="btn mb-2 noprint mt-3 ">
                        <app-select-servicios></app-select-servicios>
                    </div>
                    <div class="btn mb-2 noprint">
                        <button class="btn btn primary mb2" class="btn btnPrincipalBS" data-toggle="modal" data-target="#exampleModal"> Agregar pago </button>
                    </div>
                </div>
            </div>

            <div class="col-md-4" style="overflow:auto; height:180px;">
                <div class="row mt-3" *ngFor="let item of carrito.items; let i=index">

                    <div class="col-md-10">
                        {{ item.nombreEstudio }}
                    </div>
                    <div class="col">
                        <label class="btn btn-outline-danger" (click)="eliminar(i,item)">
                            <i class="fas fa-trash-alt"></i>
                        </label>
                    </div>

                </div>

                <div class="row mt-4" *ngIf="carrito.items.length > 0">
                    <button class="btn btnPrincipalBS" (click)="redireccion()"> 
                        Pagar Servicios 
                    </button>
                </div>

            </div>

            <div class="col-md-2 noprint">
                <img [src]="item.paquete.icon " *ngFor="let item of paquete " class="img-fluid ">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ">
                <div class="container-fluid">
                    <div class="p-3 mb-2 bg-success text-white noprint">
                        Citas incluidas
                    </div>
                    <div class="container-fluid noprint">
                        <div class="row">
                            <div class="col-md-4" *ngFor="let item of citasIncluidas" style="margin-bottom: 20px;">
                                <div class="card" style="cursor: pointer;">
                                    <div class="card-body">
                                        <span class="badge" (click)="showMessageConsulta(item)">{{item.numeroVisitas}}</span>
                                        <p class="text-center " (click)="agregarCarrito('consultas',item.nombre,item.numeroVisitas)"><i class="fas fa-female mr-2 "></i>{{item.nombre}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 p-3 mb-2 bg-primary text-white noprint">
                        Estudios de laboratorio
                    </div>
                    <div class="container-fluid noprint">
                        <div class="row cardsExamen">
                            <div class="col-md-4" *ngFor="let item of laboratoriosIncluidas">
                                <div class="card" style="cursor: pointer; margin-bottom: 20px;">
                                    <div class="card-body">
                                        <span class="badge" (click)="showMessageLab(item)">{{item.numeroVisitas}}</span>
                                        <p class="text-center" (click)="agregarCarrito('laboratorio',item.nombre,item.numeroVisitas)"><i class="fas fa-female mr-2"></i> {{item.nombre}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 p-3 mb-2 bg-info text-white">
                        Otros
                    </div>
                    <div class="container-fluid">
                        <div class="row cardsExamen">
                            <div class="col-md-4" *ngFor="let item of otros">
                                <div class="card" style="cursor: pointer; margin-bottom: 20px;">
                                    <div class="card-body">
                                        <span class="badge" (click)="showMessageLab(item)">{{item.numeroVisitas}}</span>
                                        <p class="text-center" (click)="agregarCarrito('otros',item.nombre,item.numeroVisitas)"><i class="fas fa-female mr-2"></i> {{item.nombre}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 p3 mb-3">
                        <div class="">
                            <div class="card" style="width: auto !important;">
                                <div class="card-header header">
                                    <div class="">
                                        <div class="row">
                                            <div class="col text_header">
                                                <p>Fecha de pago</p>
                                            </div>
                                            <div class="col text_header">
                                                <p>semana de gestación</p>
                                            </div>
                                            <div class="col text_header">
                                                <p>Semanas de pagos</p>
                                            </div>
                                            <div class="col text_header">
                                                <p>Pago</p>
                                            </div>
                                            <div class="col text_header">
                                                <p>Acumulado</p>
                                            </div>
                                            <div class="col-md-2 text_header">
                                                <p>Vendedor</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row" *ngFor="let item of tabularpagos; let i = index">
                                        <div class="col">
                                            <p style="text-align: center;">{{item.fechaPago}}</p>
                                        </div>
                                        <div class="col">
                                            <p style="text-align: center;">{{item.gestacion}}</p>
                                        </div>
                                        <div class="col">
                                            <p style="text-align: center;">{{item.semana}}</p>
                                        </div>
                                        <div class="col">
                                            <p style="text-align: center;">{{item.pago}}</p>
                                        </div>
                                        <div class="col">
                                            <p style="text-align: center;">{{sumaAcumulado[i]}}</p>
                                        </div>
                                        <div class="col-md-2">
                                            <p style="text-align: center;">{{item.vendedor}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>EL restante del paquete es :</p>
                                        </div>
                                        <div class="col"></div>
                                        <div class="col">
                                            <h6 style="text-align: left;">
                                                {{totalpagos}}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">PAGO SEMANAL {{nombrePaquete}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <form> -->
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label> SEMANA DE PAGOS </mat-label>
                            <select name="semanapago" id="Paciente2" class="input-censur" [(ngModel)]="selectSemanaPago" disabled>
                                <option value="">Selecciona semana de pago</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label> Pago </mat-label>
                            <input matInput type="number" min="0" name="pago" class="input-censur" [(ngModel)]="pagoSemana" disabled>
                        </div>
                    </div>
                    <div class="container">
                        <div class="col-md-12">
                            <h5 style="margin-top: 15px;">
                                Método de pago
                            </h5>
                        </div>
                        <div class="col-md-12">
                            <div class="row mt-3">
                                <div class="col">
                                    <input type="checkbox" [(ngModel)]="infoVenta.efectivo">
                                    <label>Pago en efectivo</label>
                                </div>
                            </div>
                            <div class="row">    
                                <input type="number" *ngIf="infoVenta.efectivo" (keyup)="agregarEfectivo($event)">
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input type="checkbox" [(ngModel)]="infoVenta.transferencia">
                                    <label>Transferencia bancaria</label>
                                </div>
                            </div>
                            <div class="row">    
                                <input type="number" *ngIf="infoVenta.transferencia" (keyup)="agregarTransfer($event)">
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input type="checkbox" [(ngModel)]="infoVenta.tarjetCredito">
                                    <label>Tarjeta de Crédito {{comisionCredito}}</label>
                                </div>
                            </div>
                            <div class="row">    
                                <input type="number" *ngIf="infoVenta.tarjetCredito" (keyup)="agregarIvaCredito($event)">
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input type="checkbox" [(ngModel)]="infoVenta.tarjetaDebito">
                                    <label>Tarjeta de Débito {{comisionDebito}}</label>
                                </div>
                            </div>
                            <div class="row">    
                                <input type="number" *ngIf="infoVenta.tarjetaDebito" (keyup)="agregarIvaDebito($event)">
                            </div>
                        </div>
                    </div>
                <!-- </form> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="agregarPago()">Pagar</button>
            </div>
        </div>
    </div>
</div>

<!-- Spinner -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>