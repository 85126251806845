import { NgxSpinnerService } from 'ngx-spinner';
import { style } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProveedorService } from '../servicio-proveedor/proveedor.service';
import { FormBuilder, NgForm, Validators } from "@angular/forms";
import Swal from 'sweetalert2/dist/sweetalert2.js';;
import { proveedorModel } from '../proveedor-model/proveedor.model'

@Component({
  selector: 'app-registro-proveedores-bs',
  templateUrl: './registro-proveedores-bs.component.html',
  styleUrls: ['./registro-proveedores-bs.component.css']
})
export class RegistroProveedoresBsComponent implements OnInit {

  public btnRegistrar = false;
  // proveedor = new proveedorModel();
  public formProveedor: any;
  @Output() agregar = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private _spinner: NgxSpinnerService,
              private proveedorService: ProveedorService,) { }

  ngOnInit(): void {
    this.creacionDeFormulario();
  }

  creacionDeFormulario() {
    this.formProveedor = this.formBuilder.group({
      contacto: ['',{ validators: [Validators.required] }],
      correo: [''],
      descripcion: [''],
      diasDistribucion: ['',{ validators: [Validators.required] }],
      domicilio: ['',{ validators: [Validators.required] }],
      idProveedor: ['',{ disabled: true }],
      nombreEmpresa: ['',{ validators: [Validators.required] }],
      razonSocial: ['',{ validators: [Validators.required] }],
      rfcEmpresa: ['',{ validators: [Validators.required] }],
    });
  }

  agregarProveedor(){
    this._spinner.show();
    this.btnRegistrar = true;
    if (this.formProveedor.status == 'INVALID') {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'ES NECESARIO COMPLETAR LOS CAMPOS',
      });
      this.btnRegistrar = false;
      this._spinner.hide();
    }else { 
      this.proveedorService.crearProveedor(this.formProveedor.value).subscribe( resp=>{
        this.btnRegistrar = true;
        this.agregar.emit(resp['data']);
        Swal.fire({
          title: 'EL PROOVEEDOR SE CREO EXISTOSAMENTE',
          icon: 'success'
        }) 
        this.formProveedor.reset();
      })
    } 
  }

  generarIdProv(){
    const idprov = this.formProveedor.value.rfcEmpresa;
    let date: Date = new Date();
    let dia, mes, anio, hora, min;
     hora = date.getHours();
     min = date.getUTCSeconds();
     dia = date.getUTCDay();
     mes = date.getMonth();
     anio = date.getUTCFullYear();
    const idGenerado = `${idprov}/${anio}/${mes}/${dia}/${hora}/${min}`;
    this.formProveedor.patchValue({
      idProveedor: idGenerado,
    });
  }
}
