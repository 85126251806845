<div class="container">

    <h1 class="title-principal-bs"> ACTUALIZAR CONSULTAS </h1>

    <div class="card p-3">
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">NOMBRE: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">APELLIDO PATERNO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">APELLIDO MATERNO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ATENDIO ENFERMERA: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" >
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">DOCTOR : </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">FECHA DE INGRESO: </label>
                    <div class="col-sm-7">
                        <input type="date" class="form-control" >
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="mx-auto mt-5">
                <button type="submit" class="btnPrincipalBS">REGISTRAR</button>
            </div>
        </div>
    </div>
</div>
