import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HospitalizacionService } from 'src/app/services/hospitalizacion/hospitalizacion.service';
import { HojaDeIndicaciones, Hospitalizacion } from '../bitacora-hospitalizacion/hospitalizacion.interface';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-hoja-indicaciones',
  templateUrl: './hoja-indicaciones.component.html',
  styleUrls: ['./hoja-indicaciones.component.css']
})
export class HojaIndicacionesComponent implements OnInit {

  public hospitalizados: any;
  public id = "";
  
  public hojaDeIndicaciones ={
    status: "",
    enfermeria:"",
    dieta: [],
    soluciones: [],
    medicamentos: [],
    cuidados: [],
    sondas: [],
    monitoreos: [],
    transfuciones: [],
    servicios: [],
    vendajes: [],
    _id: "",
    enfermeraAtendio: "",
    idPaciente: "",
    medicoTratante: "",
    medicoIngreso: "",
    idConsulta: "",
    idIngreso: "",
    fechaOrigen: ""
  };

  public nombreUser ="";
  constructor(
    private _HospitalizacionService: HospitalizacionService,
    // private _Cie10Service: Cie10Service,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getIngresoPorId();
    this.nombreUser = JSON.parse(localStorage.getItem('usuario')).nombre || "";
  }

  getIngresoPorId(){
    this._HospitalizacionService.getBitacoraPorId( this.id )
    .subscribe( (data:any) =>{ 
      this.hospitalizados = data.data[0]
      console.log( this.hospitalizados );
      this.spinner.hide();
    });
  }


  agregarHojaDeIndicaciones(){
    delete this.hospitalizados._id;
    this._HospitalizacionService.updateNotaIndicaciones( this.id, this.hospitalizados )
    .subscribe( (data:any) => {
      console.log( data );
    })
  }

  createHojaIndicaciones(){
    
    this.hojaDeIndicaciones.idConsulta = this.hospitalizados.idConsulta;
    this.hojaDeIndicaciones.idPaciente = this.hospitalizados.idPaciente;
    this.hojaDeIndicaciones.enfermeraAtendio = this.hospitalizados.enfermeraAtendio;
    this.hojaDeIndicaciones.medicoIngreso = this.hospitalizados.medicoIngreso;
    this.hojaDeIndicaciones.medicoTratante = this.hospitalizados.medicoTratante;
    this.hojaDeIndicaciones.idIngreso = this.hospitalizados._id;
    
    delete this.hojaDeIndicaciones._id
    
    this._HospitalizacionService.createHojaIndicaciones( this.hojaDeIndicaciones )
    .subscribe((data:any )=>{

      if(data.ok){
        Swal.fire('Indicaciones agregadas', '', 'success')
      }
    });
  //   this._HospitalizacionService.createHojaIndicaciones( this.hojaDeIndicaciones)
  //   .subscribe( (data) => {console.log( data )});
  }

  addItem( events:any,event:any  ){
    if( event === 'dieta'  ){
      this.findElement( this.hojaDeIndicaciones.dieta, events );
    }else{
      console.log("No se pudo ingresar");
    }

    console.log( this.hojaDeIndicaciones );
  } 


  findElement( arr:any[], dieta: string ){
    
    const now = new Date();
    const current = now.getHours() + ':' + now.getMinutes();
    const date = now.getDay() + "/" + now.getMonth() +"/" + now.getFullYear();

     
    if( arr.length == 0  ){
      arr.push({ inidico: this.nombreUser,  dieta: dieta, hora: current, fechaIndicacion: date }); 
    }else {
    
      const existe =  arr.some( element =>  element.dieta == dieta);
      if(  existe ){
        Swal.fire('Ya ha sido agregado', '', 'error');
        return;
      }else{
        arr.push({ inidico: this.nombreUser,  dieta: dieta, hora: current, fechaIndicacion: date }); 
      }

    }
  }

  searchServicio(txtServicio: String){
      console.log( txtServicio );
  }

}
