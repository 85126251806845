import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
import { paquete } from '../../../interfaces/paquetes';

@Component({
  selector: 'app-paquete-id',
  templateUrl: './paquete-id.component.html',
  styleUrls: ['./paquete-id.component.css']
})
export class PaqueteIDComponent implements OnInit {

  public id: String;
  public paquete:paquete = {} as paquete;

  constructor(private _paqueteService: PaquetesService,
              public router: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id');
    this.obtenerPaquete();
  }

  obtenerPaquete( ){
    this._paqueteService.getPaqueById(this.id).subscribe( (data:any) => {
      this.paquete = data['paquete'];
    })
  }

}
