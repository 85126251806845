import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';


@Component({
  selector: 'app-bitacora-pacientes',
  templateUrl: './bitacora-pacientes.component.html',
  styleUrls: ['./bitacora-pacientes.component.css']
})
export class BitacoraPacientesComponent implements OnInit {

  public pacientes = [];
  public pagina = 0;
  public totalpacientes:string;

  constructor(
    private GeneralService: AlmacenService,
  ) { }

  ngOnInit(): void {
    this.obetenerPacientes();
  }

  obetenerPacientes(){
    this.GeneralService.obtenerPacientes()
    .subscribe((data:any) =>{
      this.pacientes= data.data;
      this.totalpacientes = data['data'].results;
    })
  }
  
  typeSearchPatient( searchPatient ){
    const pacienteFilter = [];
    if ( searchPatient.value.length >3 ) {
        const { value } = searchPatient;
        
        value.trim();

        this.pacientes.forEach( service => (service.nombre === value.toUpperCase() ? pacienteFilter.push(service): null));
        this.pacientes = pacienteFilter;
    }else{
      this.obetenerPacientes();
    }
  }

}
