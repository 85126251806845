<div class="container-fluid">

    <app-titulos
        [title]="'BITACORA DE RESULTADOS DE PATOLOGÍA'"
        [rol]="'patologia'"
    ></app-titulos>

    <div class="row my-3">
            <div class="col-8 my-2">
                <input type="text" class="form-control shadow-input-bs" placeholder="BUSCAR" name="filterPost" [(ngModel)]="filtroPatologia" (keyup)="typeSearchPathology()" >
            </div>
            <div class="col my-2 offset-md-2">
                <button class="btnPrincipalBS " type="submit">NUEVO</button>
            </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-md-1">
                        No.
                </div>
                <div class="col-md-3 center">
                        NOMBRE
                </div>

                <div class="col-md-1 center">
                        EDAD
                </div>
                <div class="col-md-1 center">
                        SEXO
                </div>

                <div class="col-md-3 center">
                    ESTUDIO
            </div>
        
                <div class="col-md-2 center">
                    PRIORIDAD
                </div>

                <div class="col-md-1 center">
                        SEDE
                </div>

            </div>
        </div>
        <div class="card-body">
                <div class="row"  *ngFor="let item of patologias; let i = index"  >
                
                    <div class="col-md-1">
                    <p>
                        {{ i +1 }}
                    </p>
                </div>
                <div class="col-md-3 pointer"  [routerLink]="['/hoja/servicio/patologia', item._id]" >
                    <p  class="center">
                        {{ item.idPaciente.apellidoPaterno }}                         {{ item.idPaciente.apellidoMaterno }}
                        {{ item.idPaciente.nombrePaciente }}
                    </p>
                </div>
                <div class="col-md-1">
                    <p class="center" >
                        {{ item.idPaciente.edad }}
                    </p>
                </div>
                <div class="col-md-1">
                    <p  class="center">
                        {{ item.idPaciente.genero }}
                    </p>
                </div>
                <div class="col-md-3">
                    <p  class="center">
                        {{ item.estudios.length }}
                    </p>
                </div>

                <div class="col-md-2">
                    <p class="center">
                        
                        {{ item.prioridad | uppercase }}
                    </p>
                </div>
                
                
                <div class="col-md-1">
                    <p class="center">
                        
                        {{ item.sede }}
                    </p>
                </div>
                
            </div>
        </div>
        <div class="card-footer">
            <div class="text-center">
                AQUI VA EL PAGINADOR
            </div>
        </div>
    </div>
</div>

<!-- 
    TODO: ENVIAR EL ID DEL ESTUDIO AL PEDIDO
 -->