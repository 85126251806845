<div class="container-fluid">
  <app-titulos
    [title]="'HOJA DE SERVICIOS'"
    [rol]="'endoscopias'"
  ></app-titulos>
  <ficha-info-user
    [pacienteInfo]="paciente"
    [title]="false"
    [rol]="'recepcion'"
  >
  </ficha-info-user>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="card">
        <div class="card-heder header">
          <div class="row">
            <div class="col text_header">
              <p>#</p>
            </div>

            <div class="col text_header">
              <p>FECHA</p>
            </div>
            <div class="col text_header">
              <p>NOMBRE</p>
            </div>
            <div class="col text_header">
              <p>EDAD</p>
            </div>
            <div class="col text_header">
              <p>SEXO</p>
            </div>
            <div class="col text_header">
              <p>SEDE</p>
            </div>
            <div class="col text_header">
              <p>ESTUDIO</p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div>
            <div class="row">
              <div class="col" style="text-align: center">
                <p>1<br /></p>
              </div>
              <div class="col" style="text-align: center">
                <p>
                  Nov 5,2021
                  <br />
                </p>
              </div>
              <div class="col" style="text-align: center">
                <p>SSSSS SSSSSSSS SSSSSSSSSS<br /></p>
              </div>
              <div class="col" style="text-align: center">
                <p>15<br /></p>
              </div>
              <div class="col" style="text-align: center">
                <p>H <br /></p>
              </div>
              <div class="col" style="text-align: center">
                <p>JHUL743D</p>
              </div>
              <div class="col" style="text-align: center">
                <p>ENDOSCOPIA DE ABDOMEN</p>
              </div>
            </div>
          </div>
        </div>
        <div class="offset-md-4">
          <pagination-controls class="my-pagination"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
