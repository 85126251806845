import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class RecetasService {

  // ;

    // public url = "http://localhost:3200";
    public url = URL;

  constructor(
    private _http: HttpClient
  ) { }

  verRecetasEmitidas(){

   const url = this.url + '/ver/recetas/con/estudios';
     return this._http.get(  url );
    
  }

  verRecetaPorId( id: string ){
    const url = this.url  + `/ver/receta/${id}`;
    return this._http.get( url);
  }

  verRecetaConConsulta(id){
    const url = `${this.url}/ver/consulta/receta/${id}`;
  
    return this._http.get( url );
  
  }

  verRecetasDR(medico:any) {
    
    const url = `${this.url}/ver/recetas/${medico}`;
    return this._http.get( url );
  
  }

}
