<div class="container-fluid">
    <!-- <h2 class="text-muted">Registro de Empleados</h2>
    <br> -->
    <app-titulos [title]="'REGISTRO DE EMPLEADOS'"></app-titulos>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card" style="border-radius: 30px;">
                <form [formGroup]="empleadoForm" (ngSubmit)="registrarEmpleado()">
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-6">
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Nombre Completo</label>
                                    <div class="col">
                                        <input type="text" class="form-control" formControlName="nombre">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Password</label>
                                    <div class="col">
                                        <input type="password" class="form-control" formControlName="password">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">RFC</label>
                                    <div class="col">
                                        <input type="text" class="form-control" formControlName="rfc" maxlength="13" oninput="this.value = this.value.toUpperCase()">
                                    </div>
                                </div>
                                <div *ngIf="rfc?.invalid && (rfc?.dirty || rfc?.touched)" class="alert alert-warning" role="alert">
                                    <span class="" *ngIf="rfc?.errors?.required">RFC Obligatorio</span>
                                    <span class="" *ngIf="(rfc?.dirty || rfc?.touched) && rfc?.invalid && rfc?.errors?.pattern">RFC Incorrecto</span>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Role</label>
                                    <div class="col">
                                        <input type="text" class="form-control" formControlName="role">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Turno</label>
                                    <div class="col">
                                        <input type="text" class="form-control" formControlName="turno">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">CURP</label>
                                    <div class="col">
                                        <input type="text" class="form-control" formControlName="curp" maxlength="18" oninput="this.value = this.value.toUpperCase()">
                                    </div>
                                </div>
                                <div *ngIf="curp?.invalid && (curp?.dirty || curp?.touched)" class="alert alert-warning" role="alert">
                                    <span class="" *ngIf="curp?.errors?.required">CURP Obligatorio</span>
                                    <span class="" *ngIf="(curp?.dirty || curp?.touched) && curp?.invalid && curp?.errors?.pattern">CURP Incorrecto</span>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Fecha de Nacimiento</label>
                                    <div class="col">
                                        <input type="date" class="form-control">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Sede</label>
                                    <div class="col">
                                        <select formControlName="sedeId" class="form-control">
                                            <option value="" selected>Selecciona el módulo</option>
                                            <option value="{{sede._id}}" *ngFor="let sede of sedes">
                                                {{ sede.encargado }} | {{ sede.nomenclatura }}
                                                
                                            </option>
                                            <!-- <option value="{{sede._id}}" *ngFor="let sede of sedes">
                                                {{ sede.clinica }} | {{ sede.codsede }}
                                                
                                            </option> -->
                                           
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="" class="col-sm-3 col-form-label">Imagen</label>
                                    <div class="col">
                                        <input type="file" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-4">
                                <button class="boton">AGREGAR</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
