import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { antecedentesPNP } from '../../../interfaces/historia-clinica';

@Component({
  selector: 'app-antecedentes-pnp',
  templateUrl: './antecedentes-pnp.component.html',
  styleUrls: ['./antecedentes-pnp.component.css']
})
export class AntecedentesPNPComponent implements OnInit {

  @Input() _id='';
  @Input() antecedentesPersonalesNoPatologicos:antecedentesPNP = {} as antecedentesPNP;

  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarPersonalesNoPatologicos(form: NgForm){
    this.antecedentesPersonalesNoPatologicos = form.value
    this.antecedentesPersonalesNoPatologicos.idPaciente = this._id;
    this._HistoriaClinicaService.agregarPersonalesNoPatologicos( this.antecedentesPersonalesNoPatologicos )
    .subscribe( ( data: any ) => {
        if( data['ok'] ){
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE AGREGARON LOS ANTECEDENTES PERSONALES NO PATOLOGICOS',
          })
        }
    })
  }

  actualizarNoPatologicos(form: NgForm){
    let id = this.antecedentesPersonalesNoPatologicos._id;
    this.antecedentesPersonalesNoPatologicos = form.value;
    this.antecedentesPersonalesNoPatologicos._id = id;
    this.antecedentesPersonalesNoPatologicos.idPaciente = this._id;
    this._HistoriaClinicaService.actualizarPersonalesNoPatologicosEnElPaciente(this.antecedentesPersonalesNoPatologicos._id, this.antecedentesPersonalesNoPatologicos)
    .subscribe( ( data:any ) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZARON LOS ANTECEDENTES PERSONALES NO PATOLOGICOS',
        })
      }
    });
  }
}
