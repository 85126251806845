import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-otros-estudios',
  templateUrl: './otros-estudios.component.html',
  styleUrls: ['./otros-estudios.component.css']
})
export class OtrosEstudiosComponent implements OnInit {

  public id = '';

  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    sede:""
  }
  public imagenes = [];
  public idVendedor;
  public deshabilitarBoton = false;
  public otrosEstudios:any[]=[];

  constructor(public _pacienteService : PacientesService, 
              private _route: ActivatedRoute,
              public _router: Router,
              public _servicios:IntegradosService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.idVendedor = JSON.parse(localStorage.getItem('usuario'))._id;
    this.obtenerPaciente();
  }

  obtenerPaciente(){
    this._pacienteService.getPacienteBtID(  this.id )
    .subscribe( (resp:any) => {
      this.setPaciente(resp.paciente)
    });
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
    this.obtenerOtrosEstudios(this.paciente._id);
  }

  obtenerOtrosEstudios(id){
    this.spinner.show();
    this._servicios.obtenerOtrosEstudiosPaciente(id).subscribe((resp:any)=>{
      if(resp.ok){
        // this.otrosEstudios = resp['data'];
        this.estudios(resp['data'])
      }
    })
  }

  estudios(datos){
    datos.forEach(element => {
      element.imageUrl.forEach(elemen => {
        this.otrosEstudios.push(elemen)
      });
    });
    this.spinner.hide();
  }

  //Archivos del DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[0].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  agregar(form:NgForm){
    this.spinner.show();
    this.deshabilitarBoton = true;
    if(form.form.status != 'INVALID'){
      if(this.imagenes.length >= 1){
        let fecha = moment().format('l');  
        let data = Object.assign(form.form.value,
                                 {imageUrl:this.imagenes},
                                 {vendedor:this.idVendedor},
                                 {idPaciente:this.paciente._id},
                                 {fechaCargaEsudio:fecha});
        try {
          this._servicios.agregarOtrosEstudios(data).subscribe((resp:any)=>{
            form.resetForm();
            this.imagenes=[];
            this.files=[];
            this.deshabilitarBoton = false;
            this.spinner.hide();
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDO CORRECTAMENTE EL ESTUDIO',
            })
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'NO SE GUARDO EL ESTUDIO',
          })
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'ES NECESARIO SUBIR AL MENOS UNA IMAGEN',
        })
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'ES NECESARIO LLENAR TODOS LOS CAMPOS',
      })
    }
  }
}
