<form #fSignosVitales id="signosVitalesH">
    <div class="container-fluid">
        <div class="card-header">
            <div class="row" id="cabecera">
                <div class="col pointer">
                    <p title="(m)">Talla</p>  
                </div>
                <div class="col pointer">
                    <p title="(kg)">Peso</p>
                </div>
                <div class="col pointer">
                    <p title="(P/T^2)">IMC</p>
                </div>
                <div class="col pointer">
                    <p title="(Sist/Dias)">TA</p>
                </div>
                <div class="col pointer">
                    <p title="(LPM)">F.C.</p>
                </div>
                <div class="col pointer">
                    <p title="(RPM)">F.R.</p>
                </div>
                <div class="col pointer">
                    <p title="°C">Temp</p>
                </div>
                <div class="col pointer">
                    <p title="(cm)">P.C.</p>
                </div>
                <div class="col pointer">
                    <p title="(cm)">P.A.</p>
                </div>
                <div class="col pointer">
                    <p title="(cm)">P.T.</p>
                </div>
                <div class="col pointer">
                    <p title="(SaO%)">SaO</p>
                </div>
                <div class="col pointer">
                    <p title=""> PaO </p>
                </div>
                <div class="col pointer">
                    <p title="mg/dl"> Glucosa</p>
                </div>
                <div class="col-md-2 pointer">
                    <p>Fecha</p>
                </div>
            </div>

        </div>

        <!-- TODO: QUEDAN PENDIENTES LOS SIGNOS DE TA, FC, FR -->

        <div class="card-body">
            <div *ngFor="let signos of historialSginos">
                <!-- <pre> {{  signos| json }}</pre> -->
                <div class="row" id="valores">
                    <div class="col">
                        <p>{{ signos.talla }}</p>
                    </div>

                    <div class="col">
                        <p>{{ signos.peso }}</p>
                    </div>

                    <div class="col">
                        <p>{{ signos.imc}}</p>
                    </div>

                    <div class="col">
                        <p>{{ signos.sistolica}}/{{signos.diastolica}}</p>
                    </div>
                    <div class="col">
                        <p>{{signos.fc}}</p>
                    </div>
                    <div class="col">
                        <p>{{signos.fr}}</p>
                    </div>
                    <div class="col">
                        <p>{{ signos.temp }}</p>
                    </div>
                    <div class="col">
                        <p>{{ signos.pc }}</p>
                    </div>
                    <div class="col">
                        <p>{{ signos.pa }}</p>
                    </div>
                    <div class="col">
                        <p>{{ signos.pt }}</p>
                    </div>
                    <div class="col">
                        <p>{{ signos.SaO }}</p>
                    </div>
                    <div class="col">
                        <p>{{signos.pao}}</p>
                    </div>
                    <div class="col">
                        <p>{{signos.glucosa}}</p>
                    </div>
                    <div class="col-md-2">
                        <p>{{ signos.fechaIngreso}}</p>
                    </div>
                </div>

            </div>

            <!-- <div class="card-body">
                <div *ngFor="let signos of historialSginos">

                    <div class="row  mt-1 ">
                        <div class="col-md-1">
                            {{ signos.talla }}
                        </div>

                        <div class="col-md-1">
                            {{ signos.peso }}
                        </div>
                        <div class="col-md-1">
                            {{ signos.imc}}
                        </div>

                        <div class="col-md-1">

                        </div>

                        <div class="col-md-1">

                        </div>
                        <div class="col-md-1">

                        </div>
                        <div class="col-md-1">
                            {{ signos.temp }}
                        </div>
                        <div class="col-md-1">
                            {{ signos.pc }}
                        </div>
                        <div class="col-md-1">
                            {{ signos.pa }}
                        </div>
                        <div class="col-md-1">
                            {{ signos.pt }}
                        </div>
                        <div class="col-md-1">
                            {{ signos.SaO }}
                        </div>
                        <div class="col-md-1">
                            {{ signos.fechaIngreso }}
                        </div>

                    </div>

                </div>
            </div> -->

        </div>
    </div>
</form>
