<div class="container-fluid">
    <app-titulos [title]="'HOJA DE CONSUMO AL DÍA'" [rol]="'pacientes'"></app-titulos>

    <ficha-info-user [pacienteInfo]="paciente" [title]="false" [rol]="'CONTABILIDAD'"></ficha-info-user>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col text_header">
                            <p>No</p>
                        </div>
                        <div class="col text_header">
                            <p>Producto o servicio</p>
                        </div>
                        <div class="col text_header">
                            <p>Precio</p>
                        </div>
                        <div class="col text_header">
                            <p>Fecha</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of pedidos.estudios; let i = index">
                        <div class="row puntero">
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ i +1 }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ item.nombreEstudio }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;" *ngIf="paciente.membresiaActiva == true" style="text-align: center;">
                                <p>
                                    {{item.precioCon}}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;" *ngIf="paciente.membresiaActiva == false" style="text-align: center;">
                                <p>
                                   {{item.precioSin}} 
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ pedidos.fecha | date:'dd/MM/yyyy' }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="col-md-12">
                        <p class="center"> TOTAL: $ {{ pedidos.totalCompra }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">HOJA DE CONSUMO AL DÍA</h1>
        
    </div>
</section> -->


<!-- <section class="container-fluid">

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <p style="color: #001672;"> <span class="font-bold"> Nombre: {{paciente.nombrePaciente}} {{paciente.apellidoPaterno}} {{paciente.apellidoMaterno}} </span> </p>
                </div>

                <div class="col-md-4">
                    <p style="color: #001672;">Edad: {{ paciente.edad }} años </p>
                </div>

                <div class="col-md-4">
                    <p style="color: #001672;">Género: {{ paciente.genero }} </p>
                </div>

            </div>

            <div class="row">

                <div class="col-md-4">
                    <p style="color: #001672;"> Teléfono: {{ paciente.telefono }} </p>
                </div>


                <div class="col-md-4">
                    <p style="color: #001672;">ID: {{ paciente._id }} </p>
                </div>


            </div>

            <div class="row">

                <div class="col-md-4">
                    <p style="color: #001672;">Fecha de nacimiento: {{paciente.fechaNacimientoPaciente}} </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">Lugar de naciemiento: {{ paciente.paisPaciente }} </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">Tipo de sangre: O+</p>
                </div>

            </div>
            <div class="row">

                <div class="col-md-4">
                    <p style="color: #001672;">Razon social: {{paciente.razonSocial1}} </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">RFC: {{ paciente.razonSocial1RFC }} </p>
                </div>
                <div class="col-md-4">
                    <p style="color: #001672;">Correo:{{paciente.correoRazonSocial1}}</p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-4">
                    <p style="color: #001672;">Factura:{{pedidos.factura}}</p>
                </div>

            </div>
        </div>
    </div>

</section>

<br>

<section class="container-fluid">
    <div class="card">
        <div class="card-header header">
            <div class="row">
                <div class="col-1 text_header">
                    <p>No</p>
                </div>
                <div class="col text_header">
                    <p>Producto o servicio</p>
                </div>
                <div class="col text_header">
                    <p>Precio</p>
                </div>
                <div class="col text_header">
                    <p> Fecha</p>
                </div>
            </div>
        </div>
        <div class="card-body">

            <div class="row" *ngFor="let item of pedidos.estudios; let i = index">

                <div class="col-1 text-center">
                    <p style="color: #001672;"> {{ i +1 }} </p>
                </div>
                <div class="col text-center">
                    <p style="color: #001672;"> {{ item.nombreEstudio }} </p>
                </div>
                <div class="col text-center" *ngIf="pedidos.compraConMembresia">
                    <p style="color: #001672;"> {{ item.precio | currency }} </p>
                </div>
                <div class="col text-center" *ngIf="!pedidos.compraConMembresia">
                    <p style="color: #001672;"> {{ item.precioSin | currency }} </p>
                </div>
                <div class="col text-center">
                    <p> {{ pedidos.fecha | date:'dd/MM/yyyy' }}</p>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <div class="col-md-12">
                <p class="center"> TOTAL: $ {{ pedidos.totalCompra }} </p>
            </div>
        </div>
    </div>
</section> -->