import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { getDataStorage } from 'src/app/functions/storage.funcion';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-tipar-cruzar',
  templateUrl: './tipar-cruzar.component.html',
  styleUrls: ['./tipar-cruzar.component.css']
})
export class TiparCruzarComponent implements OnInit {

  public paciente = {
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    _id:"",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    receptor:"",
    tipoDeSangre:"",
    sede:''
  }
  public gruposanguineo = {
    tiposangre: '',
    sede: '', 
    idbancodesangre:'',
    fechahoy: '',
  }
  public receptor = {
    idbancosangre:''
  }
 
  public pedido = {
    estudios:[{
      cantidad: 0,
      idProducto: '',
      precio: 0,
      producto: ''
    }],
    fecha: '',
    pedido: '',
    sede_banco: '',
    status: '',
    totalCompra: 0,
    vendedor: '',
    _id: ''
  }
  public setTiparyCruzar = {
      idlaboratorio:'',
      metodo:'IMPLEMEDANCIA ELÉCTRICA TIPO V',
      quimico:'Dr.ALBERTO ZAMORA PALMA',
      tecnico:'Pedro Paramo',
      cedula:'3279016',
      proceso:'FINALIZADO',
      pedido: ['Tipar y Cruzar'],
      obtenidos:[],
      idCensur:''
    }
  public data = {
    producto:'',
    idProducto:'',
    precio:0,
    idDonante:'',
    resultado:'',
    }
    public donadoresCompatibles = []
    public totalRowDonacines = []
    public productos = [];
    public donantes = [];
    public matriz = [];
    public product = [];
    public compatibles = [];
    public dataPedido = [];
    public idDonanteSelect: any
    public strDonanteSelect: any
    public usuario: any;
    public quimico: any;
    public selected: any;
    public idCensur:any;
    public arr = []
  
  constructor(private _bancoSangre: BancoService,
              private _router:Router) { }

  ngOnInit(): void {
    this.obtenerReceptor();
    this.getIdCensur();
    this.usuario= getDataStorage();
    this.quimico = this.usuario?.nombre;
  }

  obtenerReceptor(){
    this.paciente = JSON.parse(localStorage.getItem('receptor'))    
  }

  getIdCensur(){
    this.idCensur = localStorage.getItem('idCensur'); 
    this.getPedidoReceptor(this.idCensur);
    this.gruposanguineo.idbancodesangre = this.idCensur
  }

  getPedidoReceptor(id){
    this.receptor.idbancosangre = id;
    this._bancoSangre.getPedidoReceptor(this.receptor).subscribe((resp:any) =>{
      this.dataPedido = resp.data.pedido.estudios
      this.setPedido(resp.data)
    })
  }

  setPedido(pedido){
    this.pedido = pedido.pedido;
    const idpedido = pedido.pedido._id
    const paciente = pedido.paciente._id
    
    pedido.pedido.estudios.forEach((element) => {
      for (let i = 0; i < element.cantidad; i++) {
        this.productos.push(element)      
      }
    });
    this.productos.forEach((element, index) => {
      let nuevo = {
        idPedido:idpedido,
        idPaciente:paciente,
        cantidad: element.cantidad,
        idProducto: element.idProducto,
        precio: element.precio,
        producto: element.producto,
      }
      this.product.push(nuevo)    
    })
    console.log(this.product);
    
  }

  disponentes(grupo, producto, index){
    this.gruposanguineo.tiposangre = grupo
    this.gruposanguineo.sede = this.paciente.sede
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    this.gruposanguineo.fechahoy = date

    this._bancoSangre.postgruposanguineo(this.gruposanguineo).subscribe((resp:any) =>{
      this.donantes = [] 
      if (producto == resp.data[0].ce.idProducto) {
          this.donantesRow(resp.data[0].ce.donadores,producto)
          this.selected = resp.data[0].ce.idProducto +'_'+ index
      } else if(producto == resp.data[0].cp.idProducto) {
        this.donantesRow(resp.data[0].cp.donadores, producto)
        this.selected = resp.data[0].cp.idProducto +'_'+ index
      }else if(producto == resp.data[0].pl.idProducto, producto) {
        this.donantesRow(resp.data[0].pl.donadores, producto)
        this.selected = resp.data[0].pl.idProducto +'_'+ index
      }else if(producto == resp.data[0].pt.idProducto) {
        this.donantesRow(resp.data[0].pt.donadores, producto)
        this.selected = resp.data[0].pt.idProducto +'_'+ index
      }
    })
  }

  donantesRow(array, producto){
    if (this.totalRowDonacines.length != 0) {
      this.donantes = []
      let pivo = [] = array
          if(this.arr.length != 0){
           array = this.arr
          }
      this.totalRowDonacines.forEach((element)=> {
          if (element.idProducto == producto) {
            let valor = array.filter(ele =>ele.idDonante != element.idDonante)
            this.arr = valor
            this.donantes = valor
          } else {
            this.arr = []
            this.donantes = pivo
          }
      })
    } else {
      this.donantes = array
    }
  }

  donante(event){
    this.donantes.forEach(element => {
      if (element.idDonante == event) {
          this.idDonanteSelect = element.idDonante,
          this.strDonanteSelect = element.lote_interno + ' '+ element.nombreCompleto 
      }
    });
  }

  resultados(paciente, producto, pedido,data){

    //! SOLO FALTA MOSTRAR en el html cuando es compatile
    if (data == 'nocompatible') {
      (<HTMLInputElement>document.getElementById(paciente)).value='';
      (<HTMLInputElement>document.getElementById(producto)).value='';
      (<HTMLInputElement>document.getElementById(pedido)).value='';
      this.data = {
        resultado:data,
        idDonante:paciente.split('_')[0],
        idProducto: producto.split('_')[0],
        precio: 70,
        producto: 'Prueba tipar y cruzar'
      }
      let donadorSeleccionado = {
        idDonante: this.idDonanteSelect,
        idProducto: producto.split('_')[0],
        strDonante: this.strDonanteSelect,
        resultado: data 
      };
      this.totalRowDonacines.push(donadorSeleccionado)
      this.matriz.push(this.data)
    } else {
      for (let i = 0; i < this.dataPedido.length; i++) {
        if (this.dataPedido[i].idProducto == producto.split('_')[0] ) {
          this.data = {
            resultado:data,
            idDonante:this.idDonanteSelect,
            idProducto: this.dataPedido[i].idProducto,
            precio: this.dataPedido[i].precio,
            producto: this.dataPedido[i].producto
          }
        }
      }
      let tipe = {
        resultado:"compatible",
        idDonante:this.data.idDonante,
        idProducto: producto.split('_')[0],
        precio: 70,
        producto: 'Prueba tipar y cruzar'
      }
      let donadorSeleccionado = {
        row: producto,
        idDonante: this.idDonanteSelect,
        idProducto: producto.split('_')[0],
        strDonante: this.strDonanteSelect,
        resultado: data 
      };
      this.totalRowDonacines.push(donadorSeleccionado)
      this.donadoresCompatibles.push(donadorSeleccionado)
      this.matriz.push(tipe)  
      this.matriz.push(this.data)  
    }
    //console.log(this.matriz);
  }

  guardar(){
      if (this.paciente.tipoDeSangre == undefined) {       
        this.data = {
          resultado:'No compatible', 
          idDonante: this.paciente._id,
          idProducto: this.matriz[0]?.idProducto,
          precio: 40,
          producto: 'Grupo sanguineo y factor RH (anti D)'
        }
        this.matriz.push(this.data)
      }
      this.setTiparyCruzar.idCensur = this.idCensur
      this.setTiparyCruzar.obtenidos =this.matriz
      this.setTiparyCruzar.idlaboratorio = '********************'
      console.log(this.setTiparyCruzar);
      
      // this._bancoSangre.datosTiparyCruzar(this.setTiparyCruzar).subscribe((resp:any) =>{
      //   Swal.fire({
      //           icon: 'success',
      //           title: '',
      //           text: 'SE AGREGARON LOS PRODUCTOS',
      //   })
      //   this._router.navigateByUrl('/hojaservicios/receptor/'+this.pedido.pedido)
      // })
  }

}

/* disponentes(grupo: any, index:any){
  //console.log(this.productos)
  const array = []
  
  this.gruposanguineo.tiposangre = grupo
  this.gruposanguineo.sede = 'TLYC01'
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  this.gruposanguineo.fechahoy = date
  console.log(this.gruposanguineo.tiposangre, this.grupointer)
  console.log(this.donantes)
  if ( this.donantes.length > 0 && this.gruposanguineo.tiposangre == this.grupointer) {
    console.log('entro aqui')
    if (this.product[index].producto == "CONCENTRADO ERITROCITARIO") {
    this.hemoderivados = "CONCENTRADO ERITROCITARIO"
    this.getDonadores(this.ce, index,'ce')
    }else if (this.product[index].producto == "CONCENTRADO PLAQUETARIO") { 
    this.hemoderivados = "CONCENTRADO PLAQUETARIO"
    this.getDonadores(this.cp, index,'cp')
    }else if (this.product[index].producto == "PLASMA"){
    this.hemoderivados = "PLASMA "
    this.getDonadores(this.pl, index,'pl')
    }else if (this.product[index].producto == "PLAQUETOFERESIS") {
    this.hemoderivados = "PLAQUETOFERESIS"
    this.getDonadores(this.pt, index,'pt')
    } else {
    console.log('error')
    }
  } else {
  this._bancoSangre.postgruposanguineo(this.gruposanguineo).subscribe((resp:any) =>{
    this.donantes = resp.data
    console.log(this.donantes)
    const array = []
    this.ce = []
    this.cp = []
    this.pl = []
    this.pt = []
    this.donantes.forEach(element => { array.push(element) } )
    if (array.length > 0) {
      this.grupointer = array[0].tipoDeSangre
    }else{
      this.grupointer = ''
    }
    
    const piv = array.sort(this.sortByDate)
    const pivo = piv.reverse()
    for (let i = 0; i < pivo.length; i++) {
      //console.log(pivo[i])
      var ce = pivo[i].lote_interno.search(/CE/i)
      var cp = pivo[i].lote_interno.search(/CP/i)
      var pl = pivo[i].lote_interno.search(/PL/i)
      var pt = pivo[i].lote_interno.search(/PT/i)
      if (ce !== -1) {
        this.ce.push(pivo[i])
      } else if (cp !== -1) {
        this.cp.push(pivo[i])
      } else if (pl !== -1) {
        this.pl.push(pivo[i])
      } else if (pt !== -1) {
        this.pt.push(pivo[i])
      }
    }
    if (this.product[index].producto == "CONCENTRADO ERITROCITARIO") {
    this.hemoderivados = "CONCENTRADO ERITROCITARIO"
    this.getDonadores(this.ce, index,'ce')
    }else if (this.product[index].producto == "CONCENTRADO PLAQUETARIO") { 
    this.hemoderivados = "CONCENTRADO PLAQUETARIO"
    this.getDonadores(this.cp, index,'cp')
    }else if (this.product[index].producto == "PLASMA"){
    this.hemoderivados = "PLASMA "
    this.getDonadores(this.pl, index,'pl')
    }else if (this.product[index].producto == "PLAQUETOFERESIS") {
    this.hemoderivados = "PLAQUETOFERESIS"
    this.getDonadores(this.pt, index,'pt')
    } else {
    console.log('error')
    }
  })
  }
}  getDonadores(donadores: any, index:any, hemo: any){
  const array2 = []
  const arreglo = []
        for (let i = 0; i < donadores.length; i++) {
            const element = donadores[i]
            arreglo.push(element)
        }
          if (this.matriz.length == 0) {
            if (hemo == 'ce') {
              this.ce2[index]=arreglo
            } else if (hemo == 'cp') {
              this.cp2[index]=arreglo
            } else if (hemo == 'pl') {
              this.pl2[index]=arreglo
            } else if (hemo == 'pt') {
              this.pt2[index]=arreglo
            } 
          }else {
            if (hemo === 'ce') {
              console.log(this.matriz)
              this.matriz.forEach(data => {
              let index = arreglo.findIndex(element => element.lote_interno == data.idProductoC)
              arreglo.splice(index, 1)
              })
              console.log(arreglo);
              
              this.ce2[index]=arreglo
            } else if (hemo === 'cp') {
              this.matriz.forEach(data => {
              let index = arreglo.findIndex(element => element.lote_interno === data.idProductoC)
              arreglo.splice(index, 1)
              })
              this.cp2[index]=arreglo
            } else if (hemo === 'pl') {
              this.matriz.forEach(data => {
              let index = arreglo.findIndex(element => element.lote_interno == data.idProductoC)
              arreglo.splice(index, 1)
              })
              this.pl2[index]=arreglo
            } else if (hemo === 'pt') {
              console.log(this.matriz)
              this.matriz.forEach(data => {
              let index = arreglo.findIndex(element => element.lote_interno == data.idProductoC)
              arreglo.splice(index, 1)
              })
              this.pt2[index]=arreglo
            }
          }
}
datos(event,rest,id,nocompa,i){
  console.log(event);
  
  let data = {
    tipoSangreD:'',
    tipoSangreR:'',
    resultado:'',
    idDonante:'',
    idProductoC:'',
    precioVenta:'',
    producto:'',
  }
  let array = []
  const pivote2 = {
          tipoDeSangre: '',
          resultado:'',
          set: [],
  }
  for (const key in event.value) {
    if (event.value[key] != undefined) {
      array.push(event.value[key]);
    }
  }
  if (this.paciente.tipoDeSangre == undefined) {
    data = {
      tipoSangreD:'',
      tipoSangreR:'',
      resultado:array[0],
      idDonante:array[this.pivo+1].split('-')[0],
      idProductoC: this.paciente._id,
      precioVenta: '70',
      producto: 'Grupo sanguineo y factor RH (anti D)'
    }
  }
  data = {
    tipoSangreD:array[0],
    tipoSangreR:array[0],
    resultado:array[2],
    idDonante:array[this.pivo+1].split('-')[0],
    idProductoC: array[this.pivo+1].split('-')[1].split('_')[0],
    precioVenta: array[this.pivo+1].split('_')[1],
    producto: this.hemoderivados
  }
  if(event.value.resultado == 'nocompatible'){
    event.value.resultado = undefined;
    console.log(rest,id,nocompa);
    
    (<HTMLInputElement>document.getElementById(rest)).value='';
    (<HTMLInputElement>document.getElementById(id)).value='';
    (<HTMLInputElement>document.getElementById(nocompa)).value='';
    data = {
      tipoSangreD:array[0],
      tipoSangreR:array[0],
      resultado:array[2],
      idDonante:array[this.pivo+1].split('-')[0],
      idProductoC: array[this.pivo+1].split('-')[1].split('_')[0],
      precioVenta: '70',
      producto: 'Prueba tipar y cruzar'
    }
    this.matriz.push(data)
  } else{
      if (this.pivo == 0) {
        this.pivo = 2
      } else {
        this.pivo++
      }
      this.matriz.push(data)
  }

  if ( this.donantes.length > 0) {
    console.log(i);
    
    console.log('entro aqui')
    console.log(this.product);
    
    if (this.product[i].producto == "CONCENTRADO ERITROCITARIO") {
    this.hemoderivados = "CONCENTRADO ERITROCITARIO"
    this.getDonadores(this.ce, i,'ce')
    }else if (this.product[i].producto == "CONCENTRADO PLAQUETARIO") { 
    this.hemoderivados = "CONCENTRADO PLAQUETARIO"
    this.getDonadores(this.cp, i,'cp')
    }else if (this.product[i].producto == "PLASMA"){
    this.hemoderivados = "PLASMA "
    this.getDonadores(this.pl, i,'pl')
    }else if (this.product[i].producto == "PLAQUETOFERESIS") {
    this.hemoderivados = "PLAQUETOFERESIS"
    this.getDonadores(this.pt, i,'pt')
    } else {
    console.log('error')
    }
  }
  console.log(this.matriz);  
}


<div *ngFor="let item of product; let i = index">
<div class="row productos">
    <div class="col">
        {{item.producto}}
    </div>
    <div class="col" id="tipo">
        <select name="tipoDeSangre" required ngModel [id]="sangre+item.idProducto" class="form-control" (change)="disponentes($event.target.value,i)">
            <option value="Tipo" selected>Seleccionar una opción</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
        </select>
    </div>
    <div class="col">
        <select [name]="item.idProducto+'_'+i" required [(ngModel)]="item.idProducto" [id]="item.idProducto+i" class="form-control">
            <option value="" selected>Seleccionar una opción</option>
            <ng-container *ngIf=hemoderivados = "CONCENTRADO ERITROCITARIO" >
                <option [value]="item.idDonante+'-'+item.lote_interno+'_'+item.precioVenta" *ngFor="let item of ce2[i]; let j = index" >{{item.lote_interno}} {{item.nombrePaciente}} {{item.apellidoPaterno}} {{item.apellidoMaterno }}</option>
            </ng-container>
            <ng-container *ngIf=hemoderivados = "CONCENTRADO PLAQUETARIO">
                <option [value]="item.idDonante+'-'+item.lote_interno+'_'+item.precioVenta" *ngFor="let item of cp2[i]; let j = index" >{{item.lote_interno}} {{item.nombrePaciente}} {{item.apellidoPaterno}} {{item.apellidoMaterno }}</option>
            </ng-container>
            <ng-container *ngIf=hemoderivados = "PLASMA">
                <option [value]="item.idDonante+'-'+item.lote_interno+'_'+item.precioVenta" *ngFor="let item of pt2[i]; let j = index" >{{item.lote_interno}} {{item.nombrePaciente}} {{item.apellidoPaterno}} {{item.apellidoMaterno }}</option>
            </ng-container>
            <ng-container *ngIf=hemoderivados = "PLAQUETOFERESIS">
                <option [value]="item.idDonante+'-'+item.lote_interno+'_'+item.precioVenta" *ngFor="let item of pl2[i]; let j = index" >{{item.lote_interno}} {{item.nombrePaciente}} {{item.apellidoPaterno}} {{item.apellidoMaterno }}</option>
            </ng-container>                                
        </select>
    </div>
    <div class="col">
        <select name="resultado" required ngModel [id]="resul+item.idProducto+i" class="form-control" (change)="datos(form, item.idProducto+i, sangre+item.idProducto+i, resul+item.idProducto+i, i)">
            <option value="ninguno" selected>Seleccionar una opción</option>
            <option value="compatible">COMPATIBLE</option>
            <option value="nocompatible">NO COMPATIBLE</option>
        </select> 
    </div>
</div>
</div> */