import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estudios-ultr-paciente',
  templateUrl: './estudios-ultr-paciente.component.html',
  styleUrls: ['./estudios-ultr-paciente.component.css']
})
export class EstudiosUltrPacienteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
