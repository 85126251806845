<div class="container-fluid">
  <div class="titi">
    <h2 id="namesede" class="titulocam">{{namesede}}</h2>
    <h2 *ngIf="allserver == false" id="nameser" class="titulocam"> {{nameser}}</h2>
    <h2 *ngIf="allserver == true"  id="nameser" class="titulocam"> {{namedoc}}</h2>
  </div>
  <div class="card" id="agendaCard">
    <div class='demo-app'>
      <div class='demo-app-sidebar'>
        <div class='demo-app-sidebar-section'>
          <!-- <div *ngIf="ServicePag.length != 0">
            <h5 id="titulopaciente">CITAS PAGADAS</h5>

            <cdk-accordion class="example-accordion">
              <cdk-accordion-item *ngFor="let item1 of ServicePag | paginate:{
                id:'listServicesPag1',
                itemsPerPage: 3 ,
                currentPage:paginas,
                totalItems:ServicePag.length}; let index = index;" #accordionItem="cdkAccordionItem"
                class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                <div *ngIf=" item1.servicio!" class="example-accordion-item-header" (click)="accordionItem.toggle()">
                  {{ item1.name }}
                  <span class="example-accordion-item-description">
                    {{ accordionItem.expanded ? 'cerrar' : 'abrir' }}
                  </span>
                </div>
                <div class="example-accordion-item-body" role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index">


                  <cdk-accordion class="example-accordion">
                    <cdk-accordion-item *ngFor="let item2 of item1.servicio; let index = index;"
                      #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
                      [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                      [attr.aria-controls]="'accordion-body-' + index">
                      <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                        {{ item2.name }}
                        <span class="example-accordion-item-description">
                          {{ accordionItem.expanded ? 'cerrar' : 'abrir' }}
                        </span>
                      </div>
                      <div class="example-accordion-item-body" role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                        <div class="card-body" *ngFor="let item3 of item2.servicios">
                          <p>{{item3.idServicio.ESTUDIO}}</p>
                          <div class="form-check">
                            <input class="form-check-input" (click)="AsigHorarioUpdate(item3)" type="radio"
                              name="flexRadioDefault" id="{{item3._id}}">
                            <label class="form-check-label" for="{{item3._id}}">
                              Agendar
                            </label>
                          </div>
                        </div>
                      </div>
                    </cdk-accordion-item>
                  </cdk-accordion>

                </div>
              </cdk-accordion-item>
            </cdk-accordion>

            <pagination-controls *ngIf="ServicePag.length != 0" class="my-pagination" autoHide="true" nextLabel=""
              previousLabel="" id="listServicesPag1" maxSize="10" directionLinks="true" (pageChange)="paginas = $event">
            </pagination-controls>
            <!---->
          <!-- </div>
          <div class="nohay" *ngIf="ServicePag.length == 0">
            <p>NO HAY CITAS PAGADAS</p>
          </div> --> 

          <div *ngIf="DatesPac.length != 0">
            <div style="text-align: center;" >
              <h5 id="titulopaciente">CITAS PENDIENTES</h5>

            </div>

            {{DatesPac.paciente.nombrePaciente}} {{DatesPac.paciente.apellidoPaterno}}
            <cdk-accordion class="example-accordion">
              <cdk-accordion-item *ngFor="let item2 of Servicepac; let index = index;" #accordionItem="cdkAccordionItem"
                class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                  {{ item2.name }}
                  <span class="example-accordion-item-description">
                    {{ accordionItem.expanded ? 'cerrar' : 'abrir' }}
                  </span>
                </div>
                <div class="example-accordion-item-body" role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index">
                  <div class="card-body" *ngFor="let item3 of item2.servicios">
                    <p>{{item3.ESTUDIO}}</p>
                    <div class="form-check">
                      <input class="form-check-input" (click)="AsigHorario(item3)" type="radio" name="flexRadioDefault"
                        id="{{item3._id}}">
                      <label class="form-check-label" for="{{item3._id}}">
                        Agendar
                      </label>
                    </div>
                  </div>
                </div>
              </cdk-accordion-item>
            </cdk-accordion>

            <!---->
          </div>
          <div *ngIf="DatesPac.length == 0">
            <p>NO HAY CITAS PENDIENTES</p>
          </div>



        </div>



        <div class='demo-app-sidebar-section'>
        </div>
        <div class='demo-app-sidebar-section'>
          <div id="borderizq">
            <p style="font-weight: bold !important; color: black;">AGENDA DOCTORES</p>
            <p style="text-transform: uppercased;"> <b>{{DiaActual}}</b></p>
          </div>

          <mat-card *ngFor="let item of DoctoresHoyHours | paginate:{
            id:'listServicesPag2',
            itemsPerPage: 2 ,
            currentPage:paginas2,
            totalItems:DoctoresHoyHours.length}; let index = index;" class="example-card">
            <mat-card-subtitle>Dr(a).</mat-card-subtitle>
            <mat-card-title>{{item.idPersonal.NOMBRE}}</mat-card-title>
            <mat-card-content>
              <div class="ixzq">
                <img class="izz" src="../../../assets/images/doctoricono.png" alt="{{item.idPersonal.NOMBRE}}">
              </div>
              <div class="ptitle">
                <p>Servicio: <b>{{item.titulo}}</b>
                <p>Hora Entrada: <b> {{item.horaInicio}} </b></p>
                <p>Hora Salida: <b> {{item.horaFin}}</b></p>
              </div>

            </mat-card-content>
            <mat-card-footer>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
          </mat-card>
          <pagination-controls *ngIf="DoctoresHoyHours.length != 0" class="my-pagination" autoHide="true" nextLabel=""
            previousLabel="" id="listServicesPag2" maxSize="10" directionLinks="true" (pageChange)="paginas2 = $event">
          </pagination-controls>
        </div>


      </div>
      <div class='demo-app-main'>
        <!-- <div class="titi">
          <h2 id="namesede" class="titulocam">{{namesede}}</h2>
          <h2 id="nameser" class="titulocam"> {{nameser}}</h2>
        </div> -->


        <form [formGroup]="addEventForm3">
          <div class="form-group">

            <input formControlName="consultName" *ngIf="subser4 === true" #nombre
              (keyup)="buscarPacienteCitas(nombre.value)" type="text" name="buscarPacienteCitas"
              class="form-control  col-md-12 izq2" id="buscarPacienteCitas" placeholder="Buscar paciente...">


            <select id="SelecServ" *ngIf="subser4 === false" class="form-control col-md-2 izq"
              formControlName="consultaserv" (change)="GetAgendaCon($event)" name="consultaserv" id="consultaserv">
              <option value="" selected>Servicio</option>
              <option value="5fd3ebca08ccbb0017712f0d">Consulta General</option>
              <option *ngFor="let consulta of this.servi">
                {{consulta.name}}
              </option>

            </select>


            <select *ngIf="medicos!"  (change)="GetAgendaConDoctor()" formControlName="consultdoctor" class="form-control col-md-2 izq"name="Doctor" id="Doctor">
              <option value="" selected>Doctor</option>
              <option id="nomDoctor" id="selectedDoc"  *ngFor="let consulta of medicos" [value]="consulta._id"> {{
                consulta.nombre }}
            </select>




            <select *ngIf="subser3 == true" class="form-control col-md-3 izq" formControlName="Subserv"
              (change)="GetAgendaCon($event)" name="Subserv" id="Subserv">
              <option *ngFor="let consulta of this.Subservice2" value="{{consulta._id}}">
                {{consulta.ESTUDIO}}
              </option>
              <option selected value="{{addEventForm3.controls['Subserv'].value}}">

              </option>
            </select>



            <input *ngIf="subser4 === false" type="date" class="form-control col-sm-2 izq"
              (change)="GetAgendaCon($event)" formControlName="consultafec" name="consultafec" id="consultafec"
              placeholder="FECHA">


            <select *ngIf="subser4 === false" class="form-control col-md-3 izq" formControlName="consultasede"
              (change)="GetAgendaCon($event)" name="consultasede" id="consultasede">
              <option selected value="">Seleccione Sede</option>
              <option value="TLYC01">Sede - Tlayacapan</option>
              <option value="CTLA01">Sede - Cuautla</option>
              <option value="YCA01">Sede - YECAPIXTLA</option>
            </select>

          </div>
          <div class="p-2" style="display: flex;
              flex-direction: row ;">
            <div class="col-md-3">
              <div class="custom-control custom-switch">
                <input (change)="OpenModals()" type="checkbox" class="custom-control-input" formControlName="addservice"
                  id="addservice">
                <label class="custom-control-label" for="addservice">Agregar servicios</label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="custom-control custom-switch">
                <input (change)="OcultNameType()" [value]="subser4" type="checkbox" class="custom-control-input"
                  id="consultname">
                <label class="custom-control-label" for="consultname">buscar paciente</label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="custom-control custom-switch">
                <input (change)="AllHour()" [value]="AllHours" type="checkbox" class="custom-control-input"
                  id="AllHourss">
                <label class="custom-control-label" for="AllHourss">Todo el horario</label>
              </div>
            </div>
          </div>


        </form>

        <full-calendar *ngIf='calendarVisible' id="agenda" #Calendar [options]='calendarOptions'></full-calendar>
      </div>

    </div>
  </div>
</div>

<!--- INICIO MODEL ´PARA RESERVAR CITAAS-->
<div class="modal-dialog modal-xl">
  <ng-template id="modalAgenda" #modalContent let-close="close">
    <div id="modalAgenda">
      <div style="flex-direction: column;" class="modal-header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title w-100 text-center" *ngIf="addEventForm3.controls['addservice'].value === false">
          REGISTRAR CITA</h5>
        <h5 class="modal-title w-100 text-center" *ngIf="addEventForm3.controls['addservice'].value != false">
          REGISTRAR SERVICIO</h5>
        <h5 class="modal-title w-100 text-center" id="nameser"> {{nameser}}
        </h5>


      </div>

      <div *ngIf="Registro != true">
        <div id="contsearch" *ngIf="addEventForm3.controls['addservice'].value != true">

          <input #nombre (keyup)="buscarPaciente(nombre.value)" type="text" name="busquedaPaciente"
            class="form-control input-search" id="busquedaPaciente" placeholder="Buscar paciente...">
          <div class="row">
            <div class="pacietes-select">
              <table class="table-search">
                <tr *ngFor="let item of AllPacientes">
                  <td (click)="SelectPac( item )" class="puntero">
                    <strong *ngIf="AllPacientes.length!=0">
                      {{ item.nombrePaciente }} {{ item.apellidoPaterno }} {{ item.apellidoMaterno }}
                    </strong>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="form-group ">
            <form [formGroup]="addEventForm">
              <div class="custom-control custom-switch">
                <input (change)="NewUser()" type="checkbox" class="custom-control-input" id="NuevoUser"
                  formControlName="NuevoUser">
                <label class="custom-control-label" for="NuevoUser">Nuevo Paciente</label>
              </div>
            </form>
          </div>

          <div class="modal-body">
            <form [formGroup]="addEventForm" *ngIf="addEventForm.controls['User'].value != false">

              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Nombre Paciente</label>

                <div class="formcals col-12">
                  <input type="text" class="form-control" formControlName="nombrePaciente" name="nombrePaciente"
                    id="nombrePaciente" placeholder="Paciente">
                  <span
                    *ngIf="addEventForm.controls['nombrePaciente'].invalid && addEventForm.controls['nombrePaciente'].touched && addEventForm.controls['nombrePaciente'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Nombre Paciente es requerido</span>
                </div>

              </div>
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Apellido paterno</label>
                <div class="formcals col-12">

                  <input type="text" class="form-control" formControlName="apellidoPaterno" name="apellidoPaterno"
                    id="apellidoPaterno" placeholder="Apellido paterno">
                  <span
                    *ngIf="addEventForm.controls['apellidoPaterno'].invalid && addEventForm.controls['apellidoPaterno'].touched && addEventForm.controls['apellidoPaterno'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Apellido paterno es requerido</span>
                </div>
              </div>
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Apellido Materno</label>
                <div class="formcals col-12">
                  <input type="text" class="form-control" formControlName="apellidoMaterno" name="apellidoMaterno"
                    id="apellidoMaterno" placeholder="Apellido Materno">
                  <span
                    *ngIf="addEventForm.controls['apellidoMaterno'].invalid && addEventForm.controls['apellidoMaterno'].touched && addEventForm.controls['apellidoMaterno'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Apellido Materno es requerido</span>
                </div>
              </div>
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Edad</label>
                <div class="formcals col-12">

                  <input type="number" class="form-control" formControlName="edad" name="edad" id="edad"
                    placeholder="Edad">
                  <span
                    *ngIf="addEventForm.controls['edad'].invalid && addEventForm.controls['edad'].touched && addEventForm.controls['edad'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*La edad es requerido</span>
                </div>
              </div>
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Género</label>

                <div class="formcals col-12">

                  <select name="genero" class="form-control" formControlName="genero" id="genero">
                    <option class="selecGenero" Selected>SEXO PASCIENTE</option>
                    <option class="selecGenero" value="MASCULINO">MASCULINO</option>
                    <option class="selecGenero" value="FEMENINO">FEMENINO</option>
                  </select>
                  <span
                    *ngIf="addEventForm.controls['genero'].invalid && addEventForm.controls['genero'].touched && addEventForm.controls['genero'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*El genero es requerido</span>
                </div>
              </div>

              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Teléfono</label>
                <div class="formcals col-12">
                  <input type="number" class="form-control" formControlName="telefono" name="telefono" id="telefono"
                    placeholder="Telefono">
                  <span
                    *ngIf="addEventForm.controls['telefono'].invalid && addEventForm.controls['telefono'].touched && addEventForm.controls['telefono'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Telefono es requerido</span>
                </div>
              </div>
            </form>
            <form [formGroup]="addEventForm2" (ngSubmit)="onSubmit()">
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Doctor</label>
                <div class="formcals col-12">

                  <select [disable]="true" formControlName="Doctor" class="selectStyle form-control" name="Doctor"
                    id="Doctor">
                    <option selected>Seleccione un doctor</option>
                    <option id="nomDoctor" *ngFor="let consulta of medicos" [value]="consulta._id"> {{
                      consulta.nombre }}
                  </select>
                  <span
                    *ngIf="addEventForm2.controls['Doctor'].invalid && addEventForm2.controls['Doctor'].touched && addEventForm2.controls['Doctor'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*El doctor es requerido</span>
                </div>
              </div>
              <div *ngIf="this.addEventForm2.controls['addservice3'].value == false" class="form-group ">
                <label class="col-sm-2 col-form-label">Servicio</label>
                <div class="formcals col-12">
                  <select class="form-control col-sm-10" (change)="selectName()" formControlName="Servicios"
                    name="Servicios" id="Servicios">
                    <option selected>Tipo de servicio</option>
                    <option *ngFor="let consulta of Subservice2" value="{{consulta._id}}">
                      {{consulta.ESTUDIO}}
                    </option>
                  </select>
                  <span
                    *ngIf="addEventForm2.controls['Servicios'].invalid && addEventForm2.controls['Servicios'].touched && addEventForm2.controls['Servicios'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*El servicio es requerido</span>

                  <button *ngIf="this.addEventForm2.controls['addservice2'].value == true"
                    class="btn btn-outline-primary ms-2" id="dell2" type="button" (click)="agregarServicioExtra()">
                    Agregar
                  </button>
                </div>
              </div>
              <div id="selectServer" *ngIf="this.addEventForm2.controls['addservice3'].value == true">

                <input formControlName="Subservicios" (keyup)="buscarbyServer()" type="text" name="Subserv"
                  class="form-control col-sm-8" id="Subservicios" placeholder="Buscar Servicios...">
                <div class="row">
                  <div class="service-select">
                    <table class="table-search">
                      <tr *ngFor="let item of Subservice3">
                        <td class="puntero" (click)="SelectSer( item )">
                          <strong>
                            {{ item.ESTUDIO }}
                          </strong>
                      </tr>
                    </table>
                  </div>
                </div>

                <button *ngIf="this.addEventForm2.controls['addservice2'].value == true"
                  class="btn btn-outline-primary ms-2" id="dell" type="button" (click)="agregarServicioExtra()">
                  Agregar
                </button>


              </div>
              <div class="form-group">
                <div class="form-select col-sm-8">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" formControlName="addservice2" name="addservice2"
                      (change)="ocultAddser()" id="addservice2">
                    <label class="custom-control-label" for="addservice2">Agregar mas servicios</label>
                  </div>
                  <div class="custom-control custom-switch">
                    <input (change)="ocultAddser2()" formControlName="addservice3" type="checkbox"
                      class="custom-control-input" id="serviType">
                    <label class="custom-control-label" for="serviType">Nombre Servicio</label>
                  </div>
                </div>
              </div>
              <!--- 1401   Subservice-->
              <div class="input-group" [formGroup]="ServicosExtra">
                <div *ngIf="this.addEventForm2.controls['addservice2'].value == true">
                  <input hidden type="text" class="form-control col-sm-3" formControlName="Subservicios" value=""
                    name="Subservicios" id="Subservicios">


                </div>
              </div>
              <div *ngIf="this.addEventForm2.controls['addservice2'].value == true" class="col-12 listServ"
                formArrayName="servicioExt">
                <div class="input-group mb-3" *ngFor="let pendiente of servicioExtArr.controls; let i = index"
                  [formGroupName]="i">

                  <table id="tablecount">
                    <thead>
                      <th>No.</th>
                      <th>Fecha de la cita: </th>
                      <th>Horario: </th>
                      <th>Servicio: </th>
                      <th>Sede: </th>
                    </thead>
                    <tr>
                      <td><span class="input-group-text">{{i+1}}</span>
                        <button class="btn btn-outline-danger ms-2" style="line-height: 0.2;" type="button"
                          (click)="borrarServicioExtra(i,pendiente)">
                          Eliminar
                        </button>
                      </td>
                      <td><input class="sinbord" type="text" formControlName="FechaEnt" value="" name="FechaEnt"
                          id="FechaEnt"></td>
                      <td><input class="inputextra sinbord" type="text" formControlName="HoraEnt" value=""
                          name="HoraEnt" id="HoraEnt">
                        <p> - </p> <input class="inputextra sinbord" type="text" formControlName="HoraSal" value=""
                          name="HoraSal" id="HoraSal">
                      </td>
                      <td><input class="inputextra2 sinbord" type="text" formControlName="Subservicios" value=""
                          name="Subservicios" id="Subservicios"></td>
                      <td> <input type="text" class="sinbord" formControlName="sedecon" value="" name="sedecon"
                          id="sedecon">
                      </td>

                    </tr>
                  </table>
                </div>
              </div>
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Motivo</label>
                <div class="formcals col-12">
                  <input type="text" class="form-control" formControlName="Motivo" name="Motivo" id="Motivo">
                  <span
                    *ngIf="addEventForm2.controls['Motivo'].invalid && addEventForm2.controls['Motivo'].touched && addEventForm2.controls['Motivo'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Motivo es requerido</span>
                </div>
              </div>

              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Indicaciones:</label>
                <div class="formcals col-12">
                  <textarea name="Descripcion" class="form-control" formControlName="Descripcion" id="Descripcion"
                    cols="5" rows="5"></textarea>
                  <span
                    *ngIf="addEventForm2.controls['Descripcion'].invalid && addEventForm2.controls['Descripcion'].touched && addEventForm2.controls['Descripcion'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Las indicaciones son requeridas</span>
                </div>
              </div>
              <div class="form-group ">
                <label class="col-sm-2 col-form-label">Reservación</label>
                <div class="formcals col-12">
                  <select name="Reservacion"  (change)="holi()" class="form-control" formControlName="Reservacion" id="Reservacion">
                    <option value="" selected>Seleccione un metodo de reservación </option>
                    <option value="Presencial" selected>PresenciaPresenl</option>
                    <option value="Llamada" selected>Llamada </option>
                    <option value="Redes sociales" selected>Redes sociales </option>
                  </select>
                  <span
                    *ngIf="addEventForm2.controls['Reservacion'].invalid && addEventForm2.controls['Reservacion'].touched && addEventForm2.controls['Reservacion'].errors.required"
                    class="fw-light text-danger" style="font-size:12px;">*Tipo de reservación es requerido</span>
                </div>
              </div>

              <div class="modal-footer">
                <div *ngIf="DatesPaySelectUpdate == false">
                  <button class="btn-sea" type="submit" [disabled]="addEventForm2.invalid"
                  >
                  AGREGAR CITA
                </button>
                </div>
                <div *ngIf="DatesPaySelectUpdate == true">
                  <button class="btn-sea" type="button" (click)="updateForm()" >
                    ACTUALIZAR CITA
                  </button>
                </div>
               

              </div>
            </form>
          </div>
        </div>
        <div class="modal-body" *ngIf="addEventForm3.controls['addservice'].value != false">
          <form [formGroup]="addEventForm4" class="sed" (ngSubmit)="onSubmitSer()">

            <div class="form-group" *ngIf="addEventForm4.controls['fechainicioser'].value.length == 0">
              <div class="form-select days">
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" formControlName="lunes"
                    id="lunes">
                  <label class="custom-control-label" for="lunes">lunes</label>
                </div>
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" id="martes"
                    formControlName="martes">
                  <label class="custom-control-label" for="martes">martes</label>
                </div>
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" id="miercoles"
                    formControlName="miercoles">
                  <label class="custom-control-label" for="miercoles">Miercoles</label>
                </div>

              </div>
            </div>
            <div class="form-group " *ngIf="this.addEventForm4.controls['fechainicioser'].value.length == 0">
              <div class="form-select days ">
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" id="jueves"
                    formControlName="jueves">
                  <label class="custom-control-label" for="jueves">Jueves</label>
                </div>
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" id="viernes"
                    formControlName="viernes">
                  <label class="custom-control-label" for="viernes">Viernes</label>
                </div>
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" id="sabado"
                    formControlName="sabado">
                  <label class="custom-control-label" for="sabado">Sabado</label>
                </div>
                <div class="custom-control custom-switch">
                  <input (change)="SelectDays()" type="checkbox" class="custom-control-input" id="domingo"
                    formControlName="domingo">
                  <label class="custom-control-label" for="domingo">Domingo</label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 col-form-label">Selecciona el Doctor</label>
              <select formControlName="Doctor" class="selectStyle form-control" name="Doctor" id="Doctor">
                <option selected value="">Seleccione un doctor</option>
                <option *ngFor="let consulta of medicos" [value]="consulta._id"> {{ consulta.nombre }}
                </option>
              </select>
            </div>
            <div *ngIf="this.addEventForm4.controls['fechainicioser'].value != 0" class="form-group ">
              <label class="col-sm-4 col-form-label">Fecha Inicio</label>

              <input type="date" class="form-control col-sm-3" formControlName="fechainicioser" name="fechainicioser"
                id="fechainicioser">
            </div>
            <div *ngIf="this.addEventForm4.controls['fechainicioser'].value != 0" class="form-group ">
              <label class="col-sm-4 col-form-label">Fecha final</label>

              <input type="date" class="form-control col-sm-3" formControlName="fechafinser" name="fechafinser"
                id="fechafinser">
            </div>
            <div class="form-group ">
              <label class="col-sm-4 col-form-label">Hora Inicio</label>
              <input type="time" class="form-control col-sm-3" formControlName="horainicioser" name="horainicioser"
                id="horainicioser">
            </div>
            <div class="form-group ">
              <label class="col-sm-4 col-form-label">Hora Salida</label>
              <input type="time" class="form-control col-sm-3" formControlName="horafinser" name="horafinser"
                id="horafinser">
            </div>
            <div class="modal-footer">
              <button class="btn-sea" type="submit">
                AGREGAR CITA
              </button>
            </div>
          </form>

        </div>
      </div>
      <app-registrar-disponente *ngIf="Registro != false">

      </app-registrar-disponente>


    </div>

  </ng-template>
</div>
<!--- FIN MODEL PARA RESERVAR CITAAS-->
<!---INICIO MODEL ´PARA RESERVAR SERVICIOS-->
<ng-template #modalContent1 let-close="close">
  <div>
    <div class="modal-header">
      <h5 class="modal-title w-100 text-center">VER CITA </h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body2">
      <div id="accordion">
        <div class="card ">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link btnse" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne">
                Información personal
              </button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              <p>Nombre: <span>{{cita.paciente.nombrePaciente +' '+ cita.paciente.apellidoPaterno+' '+
                  cita.paciente.apellidoMaterno}}</span> </p>
              <p>Telefono: <span>{{cita.paciente.telefono}}</span></p>
              <p>Genero: <span>{{cita.paciente.genero}}</span></p>
              <p>Edad: <span>{{cita.paciente.edad}}</span></p>
            </div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed btnse" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                Información consulta
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <h5>Estudio: <span>{{cita.idServicio.ESTUDIO}}</span></h5>
              <p>Estado: <span>{{cita.status}}</span></p>
              <p>Motivos: <span>{{cita.motivo}}</span></p>
            </div>
          </div>
        </div>

      </div>
      <button *ngIf="terminaregistro != true " (click)="registro(cita.paciente._id)" type="button"
        class="btn btn-danger bd">Terminar
        Registro</button>
      <div style="display: contents;" *ngIf="terminaregistro != false">
        <p>PAGAR: </p>
        <button *ngIf="cita.status != 'PAGADO'" type="button" (click)="PaytoService(cita.paciente,'Hoy')"
          class="btn btn-success bd">HOY</button>
        <button *ngIf="cita.status != 'PAGADO'" type="button" (click)="PaytoService(cita.paciente,'Todo')"
          class="btn btn-success bd">TODO</button>
      </div>

      <button *ngIf="cita.status == 'PENDIENTE'" type="button" type="button" class="btn btn-info bd">ANTICIPIO</button>
      <button type="button" class="btn btn-danger bd">HOSPITAL</button>
      <input *ngIf="cita.status == 'PENDIENTE' || cita.status != 'PAGADO'" type="button" type="button" value="CANCELAR"
        (click)="CancelarDate(idDate)" class="btn btn-warning bd">
    </div>
  </div>


</ng-template>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
  <br>
  <p style="color: white"> Cargando... </p>
</ngx-spinner>