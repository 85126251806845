<div class="container-fluid">
    <h1 class="centerxd">BITACORA DE NO APTOS O DIFERIDOS</h1>
  
        <div class="row">
          <div class="col-md-12 col-lg-12">
              <div class="card">
                  <div class="card-heder header text-center">
                      <div class="row">
                          <div class="col-1 text_header" >
                              <p>No.</p>
                          </div>
                          <div class="col-2 text_header">
                              <p>NOMBRE</p>
                          </div>
                          <div class="col text_header">
                              <p>SEXO</p>
                          </div>
                          <div class="col text_header">
                              <p>EDAD</p>
                          </div>
                          <div class="col text_header">
                              <p>GRUPO Y RH</p>
                          </div>
                          <div class="col text_header">
                              <p>ESTATUS</p>
                          </div>
                          <div class="col text_header">
                              <p>PROCESO</p>
                          </div>
                          <div class="col-2 text_header">
                              <p>MOTIVO</p>
                          </div>
                          <div class="col-2 text_header">
                              <p>TIEMPO DE RECHAZO</p>
                          </div>
                      </div>
                  </div>
  
                  <div class="card-body text-center">
                          <div class="row">
                              <div class="col-1">
                                  <p class="nombre">
                                      01
                                  </p>
                              </div>
                              <div class="col-2" >
                                  <p class="nombre">
                                    PEDRO PARAMO 
                                  </p>
                              </div>
                              <div class="col" >
                                  <p class="nombre">
                                    M
                                  </p>
                              </div>
                              <div class="col" >
                                  <p class="nombre">
                                      48 AÑOS
                                  </p>
                              </div>
                              <div class="col" >
                                  <p class="nombre">
                                      O+
                                  </p>
                              </div>
                              <div class="col" >
                                <p class="nombre">
                                  DIFERIDO
                                </p>
                            </div>
                            <div class="col" >
                                <p class="nombre">
                                  RECEPCIÓN
                                </p>
                            </div>
                            <div class="col-2" >
                                <p class="nombre">
                                    TATUAJES RECIENTES
                                </p>
                            </div>
                            <div class="col-2" >
                                <p class="nombre">
                                    12 MESES
                                </p>
                            </div>
                          </div>
                  </div>
  
                  <div class="card-footer">
                      <div class="row" >
                        AQUI VA EL PAGINADOR
                      </div>
                  </div>
  
        </div>
      </div>
    </div>
  </div>    
  