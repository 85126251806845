<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE ESTUDIOS'"></app-titulos>
    <div class="container-fluid">
        <div class="col-md-12">
            <form>
                <div class="form-group">
                    <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral($event)">
                    <!--  (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio" -->
                </div>
            </form>

            <div class="row">
                <div class="col-md-12">
                    <div class="card" style="border-radius: 30px;">
                        <div class="card-header header" style="border-top-left-radius: 24px;
                        border-top-right-radius: 24px;">
                            <div class="row">
                                <div class="col-1 text_header">
                                    <p>No.</p>
                                </div>
                                <div class="col-7 text_header">
                                    <p>ESTUDIO</p>
                                </div>
                                <div class="col-4 text_header">
                                      <p>ACCIONES</p>
                                </div>
                            </div>
                        </div>

                        <div class="card-body" style="background-color: #ffffff; border-radius: 0px 0px 30px 30px;" >
                            <div *ngFor="let item of tablaEstudios | paginate:{id:'listaAmbulancia',
                            itemsPerPage: 10 ,
                            currentPage:pagina,
                            totalItems:totalAmbulancia} ;let i = index">
                                <div class="row puntero">
                                    <div class="col-1">
                                        <label style="margin-right: 50px; margin-top: 14px; overflow-wrap: break-word;"> 
                                            {{ i +1 }}
                                        </label>
                                    </div>
                
                                    <div class="col-7">
                                        <label style="margin-right: 50px; margin-top: 14px; overflow-wrap: break-word;"> 
                                            {{item.ESTUDIO}}
                                        </label>
                                    </div>
                                    <div class="col-4">
                                        <label class="btn btn-outline-success ml-2" (click)="agregar(item._id)">
                                            <i class="fas fa-plus-square"></i>
                                        </label>
                                        <label class="btn btn-outline-warning ml-2" (click)="editar(item._id)">
                                            <i class="fas fa-edit"></i>
                                        </label>
                                        <label class="btn btn-outline-danger ml-2" (click)="delete(item._id)">
                                            <i class="fas fa-trash-alt"></i>
                                        </label>
                                    </div>
                
                                </div>
                            </div>
                            <div class="offset-md-2">
                                <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>

<!-- <div class="col-md-12">
    <div class="card" style="border-radius: 30px;">
        <div class="card-header header" style="border-top-left-radius: 24px;
        border-top-right-radius: 24px;">
            <div class="row">
                <div class="col-md-2 text_header">
                    <p class="text-center">No.</p>
                </div>
                <div class="col-md-7 text_header">
                    <p class="text-center">NOMBRE DEL ESTUDIO</p>
                </div>
                <div class="col-md-3 text_header">
                    <p class="text-center">OBSERVACIONES</p>
                </div>                
            </div>
        </div>
    </div>
    
    <div class="card-body" style="background-color: #ffffff; border-radius: 0px,30px,30px, 0px" >
        <div *ngFor="let item of tablaEstudios; let i = index" [routerLink]="['/hoja-servicios', item._id]">
            <div class="row">
                <div class="col-md-2">
                    <p class="text-center">{{ i +1 }}</p>
                </div>
                <div class="col-md-7">
                    <p class="text-center">{{item.ESTUDIO}}</p>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-success btnSVY" [routerLink]="['/nuevo/estudio/laboratorio',item._id]">NUEVO</button>
                    <button type="button" class="btn btn-primary btnSVY" [routerLink]="['/editar/laboratorios', item._id]">EDITAR</button>
                </div>
            </div>
        </div>
        <div class="offset-md-3">
            <pagination-controls id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div> 
    </div>
</div> -->
<br>