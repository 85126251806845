import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-indentificaciosproductos-almacen',
  templateUrl: './ficha-indentificaciosproductos-almacen.component.html',
  styleUrls: ['./ficha-indentificaciosproductos-almacen.component.css']
})
export class FichaIndentificaciosproductosAlmacenComponent{

  @Input('dataFiProducto') dataFiProducto = {
    nombre:'',
    costo:"",
    estado:"",
    descripcion:"",
    tipo_producto:"",
    idProducto:"",
    nombre_comercial:"",
    proveedor: [],
    cantidadMin:0,
    laboratorio:""
  }
  @Input() stock = 0
  @Input() venta = 0
  @Input() otrosHospitales = 0
  @Input() cantidadMinima = 0
  @Input() tipo = 'PRODUCTO'

  constructor() { }

}
