<div class="container-md">
    <app-titulos
    [title]="'REGISTRAR ENDOSCOPIA'"
    [rol]="'endoscopia'"
    ></app-titulos>

    
    <div class="card">
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ESTUDIOS: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" name="estudios" [(ngModel)]="endoscopiaData.estudios" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">PRIORIDAD: </label>
                    <div class="col-sm-7">
                        <select class="form-control" [(ngModel)]="endoscopiaData.prioridad" name="prioridad">
                            <option value="1"> URGENTE </option>
                            <option value="2"> EN ESPERA </option>
                            <option value="3"> PROGRAMADO </option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">STATUS: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="status" name='status'[(ngModel)]="endoscopiaData.status" >
                    </div>
                </div>

                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">MEDICO QUE SOLICITO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="status" name='status'[(ngModel)]="endoscopiaData.medicoQueSolicito" >
                    </div>
                </div>/
            </div>

            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ID PACIENTE: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="idPaciente" readonly name="idpaciente" [(ngModel)]="endoscopiaData.idPaciente">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">SEDE: </label>
                    <div class="col-sm-7">
                        <select class="form-control" [(ngModel)]="endoscopiaData.sede" name="sede" >
                            <option selected >SELECCIONA UNA OPCION</option>
                            <option value="1"> TLYC01 </option>
                            <option value="2"> CUAUTLA </option>
                            <option value="3"> CUERNAVACA </option>
                          </select>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">FECHA DE EXAMENES: </label>
                    <div class="col-sm-7">
                        <input type="date" class="form-control" id="fechaExamenes" [(ngModel)]="endoscopiaData.fechaDePedidoDeLosExamenes"  >
                    </div>
                </div>

                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ID OBTENIDO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="idPaciente" readonly name="idpaciente" [(ngModel)]="endoscopiaData.idObtenido">
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">  
            <label class="form-label">VIDEO: </label>
            <input type="file" class="form-control" id="video" rows="2" name="video" [(ngModel)]="endoscopiaData.video" (blur)="validarForm()">
          </div>
    
        <div class="d-grid gap-2 col-2 mx-auto mt-3">
            <button type="submit" class="btn btn-primary" [disabled]="btnValidacion" (click)="guardarEndoscopia()">REGISTRAR</button>
        </div>
    </div>
</div>
    

