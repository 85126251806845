<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="'BITACORA DE HOSPITALIZACIÓN'"></app-titulos>
        </div>
        <div class="col">
            <button class="boton"> HISTORIAL DE HOSPITALIZADOS </button>
            <button class="principalButton"> IMPRIMIR BITACORA</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col text_header">
                            <p>Curp</p>
                        </div>
                        <div class="col text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col text_header">
                            <p>Servicio médico</p>
                        </div>
                        <div class="col text_header">
                            <p>Médico</p>
                        </div>
                        <div class="col text_header">
                            <p>Hora de cita</p>
                        </div>
                        <div class="col text_header">
                            <p>Número de expediente</p>
                        </div>
                    </div>
                </div>
                <div class="card-body mayus">
                    <div>
                        <div class="row pointer sombra" [routerLink]="['/nota/de/ingreso', hospitalizado._id]"  *ngFor="let hospitalizado of hospitalizados; let i = index"  >
                            <div class="col-md-1" style="text-align: center;">
                                <p> {{ i +1 }} </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ hospitalizado.idPaciente.apellidoPaterno }}
                                    {{ hospitalizado.idPaciente.apellidoMaterno }}
                                    {{ hospitalizado.idPaciente.nombrePaciente }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ hospitalizado.idPaciente.curp }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ hospitalizado.idPaciente.edad }}
                                </p>
                            </div>
                            <div class="col bold" style="text-align: center;">
                                <p>
                                    <strong>
                                    </strong>
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ hospitalizado.medicoTratante.NOMBRE }}
                                </p>
                            </div>
      
                            <div class="col" style="text-align: center;">
                                <p>{{ hospitalizado.horaIngreso }}</p>
                            </div>

                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ hospitalizado.idPaciente.numeroExpediente }}
                                </p>
                                <!-- <p *ngIf="listaEsperaP.paciente.numeroExpediente==undefined">
                                   Sin Expediente <br>
                                </p> -->
                            </div>
      

                        </div>
                    </div>
                </div>
                <!-- <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div> -->
            </div>
        </div>
    </div>
</div>