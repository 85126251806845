import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, NgForm, Validators } from "@angular/forms";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ProveedorService } from '../servicio-proveedor/proveedor.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-actualizar-proveedor',
  templateUrl: './actualizar-proveedor.component.html',
  styleUrls: ['./actualizar-proveedor.component.css']
})
export class ActualizarProveedorComponent implements OnInit {

  public id = '';
  public formProveedor: any;


  constructor(private _router: ActivatedRoute,
              private _proveedor:ProveedorService,
              private formBuilder: FormBuilder,
              private _spinner: NgxSpinnerService,
              private _routers: Router,) { }

  ngOnInit(): void {
    this.id = this._router.snapshot.paramMap.get('id');
    this.obtenerProveedor();
    this.creacionDeFormulario();
  }

  creacionDeFormulario() {
    this.formProveedor = this.formBuilder.group({
      contacto: ['',{ validators: [Validators.required] }],
      correo: [''],
      descripcion: [''],
      diasDistribucion: ['',{ validators: [Validators.required] }],
      domicilio: ['',{ validators: [Validators.required] }],
      idProveedor: ['',{ disabled: true }],
      nombreEmpresa: ['',{ validators: [Validators.required] }],
      razonSocial: ['',{ validators: [Validators.required] }],
      rfcEmpresa: ['',{ validators: [Validators.required] }],
    });
  }

  obtenerProveedor(){
    this._proveedor.obtenerProveedorId(this.id).subscribe((resp:any)=>{
      this.setProductoid(resp['data']);
    })
  }

  setProductoid(data:any){
    this.formProveedor.patchValue({
      contacto: data.contacto,
      correo: data.correo,
      descripcion: data.descripcion,
      diasDistribucion: data.diasDistribucion,
      domicilio: data.domicilio,
      idProveedor: data.idProveedor,
      nombreEmpresa: data.nombreEmpresa,
      razonSocial: data.razonSocial,
      rfcEmpresa: data.rfcEmpresa,
    });
    this.formProveedor.get('idProveedor').disable();
  }

  actualizarProveedor(){
    this._spinner.show();
    if(this.formProveedor.status != 'INVALID'){
      this._proveedor.updateProveedorId(this.id, this.formProveedor.value).subscribe((resp:any)=>{
        if(resp.ok){
          this._spinner.hide();
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE ACTUALIZO CORRECTAMENTE EL PROVEEDOR',
          });
          this._routers.navigateByUrl('/listado/proveedor');
        }
      })
    }else{
      this._spinner.hide();
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'NO SE REALIZO LA ACTUALIZACION DEL PROVEEDOR',
      });
    }
  }
}
