<div class="container-fluid">

    <app-titulos
        [title]="'CANCELAR TICKETS'"
        [rol]="'patologia'"
    ></app-titulos>

    <div class="row my-3">
            <div class="col-8 my-2">
            
                <input type="number" class="form-control shadow-input-bs" placeholder="BUSCAR" name="filterPost"  [(ngModel)]="filterName"  autofocus="autofocus"  (keyup)="filterByName()" />
            </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-md-1">
                        No.
                </div>
                <div class="col-md-2 center">
                        NOMBRE
                </div>

                <div class="col center">
                        FECHA
                </div>
                <div class="col center">
                        HORA
                </div>

                <div class="col center">
                    FOLIO
                </div>
            

                <div class="col center">
                    ESTADO

                </div>

                <div class="col center">
                    SEDE

                </div>

                <div class="col">
                    
                </div>

                <div class="col center">
                    
                    CANCELAR
                </div>

            </div>
        </div>

        <div class="card-body">
            <div class="row"  *ngFor="let item of filterPacientes| paginate:{id:'listapacientes',
            itemsPerPage: 7,
            currentPage:pagina,
            totalItems:totaltickets}; let i = index"  >
                
                <div class="col-md-1">
                    <p>
                        {{ i +1 }}
                    </p>
                </div>

                <div class="col-md-2 pointer"  >
                    <p  class="center">
                        {{  item.paciente.nombrePaciente  }}
                        {{  item.paciente.apellidoPaterno  }}
                        {{  item.paciente.apellidoMaterno  }}
                    </p>
                </div>

                <div class="col">
                    <p class="center" >
                        
                        {{  item.fecha | date  }}
                    
                    </p>
                </div>
                <div class="col">
                    <p  class="center">
                        {{  item.hora  }}
                    
                    </p>
                </div>
                <div class="col">
                    <p  class="center">
                        {{  item.folio  }}
                    
                    </p>
                </div>
                <div class="col">
                    <p  class="center">
                        {{  item.status  | uppercase }}
                    </p>
                </div>

                <div class="col">
                    <p  class="center">
                        {{  item.sede  | uppercase }}
                    </p>
                </div>

                <div class="col">
                    <input type="" name="ticketCanel" #f [value]="item.motivoCancelacio" class="input-censur"/> 
                </div>

                <div class="col text-center">
                     <i
                        class="fa fa-trash-alt colorRed pointer"
                        (click)="cancelTicket(item._id, f)"
                    >
                     </i>
                </div>

            </div>
        </div>
        
        <div class="card-footer">
            <div class="text-center">
                <div>
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>
<section class="display-none" id="sppiner-section" >
    <div class="row">
        <div class="sppiner-bg">
            <div class="spinner-stock">
                <div class="col-md-12">
                    <app-sppiner></app-sppiner>
                </div>
            </div>
    
        </div>
    </div>

</section>