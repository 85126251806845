
<form  autocomplete="off" #form="ngForm">

    <h1 class="text-center">CENTRO MEDICO HORIZONTE</h1>
    <h1 class="text-center">DEPARTAMENTO DE MANTENIMIENTO</h1>
    <h5 class="text-center">DETERMINACION DE CLORO RESIDUAL Y ANALISIS BACTERIOLOGICO</h5>
    <hr>
    <br>
    <br>

    <div class="form-group row">
        <label class=" col-form-label">MES:</label>
        <div class="col-3">
          <input class="form-control"
                 type="text"
                 name="mes"
                 placeholder="MES"
            >  
        </div>
        <label class=" col-form-label">AÑO:</label>
        <div class="col-2">
          <input class="form-control"
                 type="text"
                 name="AÑO"
                 placeholder="AÑO"
            >
        </div>        
    </div>
    <div class="form-group row">
        <label class=" col-form-label">SEMANA EPIDEMIOLOGICA DEL :</label>
        <div class="col-2">
          <input class="form-control"
                 type="text"
                 name="SEMANA"
                 placeholder=""
            >  
        </div>
        <label class=" col-form-label">AL:</label>
        <div class="col-2">
          <input class="form-control"
                 type="text"
                 name="A"
                 placeholder=""
            >
        </div>        
    </div>
    <br>
    <!--************************+ TABLA *********************-->
     <table class="table">
        <thead class="thead-dark">
          <tr>
    
            <th scope="col">ÁREA EM DONDE SE DETERMINA EL CLORO RESIDUAL</th>
            <th scope="col">NÚMERO DE LECTURA</th>
            <th scope="col">FECHA</th>
            <th scope="col">RESULTADO DE DETERMINACION DE CLORO (0.5-1.5MG/L)</th>
            <th scope="col">ANALISIS BACTERIOLOGICO</th>
            <th scope="col">OBSERVACIONES</th>
            <th scope="col">NOMBRE Y FIRMA DE QUIEN REALIZA LA ACTIVIDAD</th>
            
          </tr>
        </thead>

        <tbody>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN CISTERNA</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                     
            </tr>
            <tr>
                <th scope ="row"> DETERMINACION DE CLORO EN TINACO </th>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
         
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN TINACO 2</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
               
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN TINACO 3</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN TINACO 4</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
            </tr>

            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN TINACO 5</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN TINACO 6</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN TINACO 7</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
            </tr>

            <tr>
                <th scope="row">DETERMINACION DE CLORO EN QUIROFANO</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
         
            </tr>
            <tr>
                <th scope ="row"> DETERMINACION DE CLORO EN HOSPITALIZACION PISO </th>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
               
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN HOSPITALIZACION PISO 2</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN CONSULTA EXTERNA</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
            </tr>

            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN URGENCIAS</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN COCINA</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
            </tr>
            <tr>
                <th scope="row"> DETERMINACION DE CLORO EN OFICINAS EN CUERPO DE GOBIERNO</th>
                <td> <div class="md-form">
                <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                <label for="form7">DESCRIPCION</label>
              </div> </td>
                <td> </td>
                <td><div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div> </td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
                <td> <div class="md-form">
                    <textarea type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput ></textarea>
                    <label for="form7">DESCRIPCION</label>
                  </div></td>
            </tr>


        </tbody>
        <br>
        <br>
    </table>

    

</form>