
<div class="container d-flex justify-content-center" style="margin-top: 20px;">
    <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title text-center">Actualizar Contraseña</h5>

            <form [formGroup]="changePasswordForm" (ngSubmit)="confirmarPasswords()">
                <div class="form-group mt-4">
                    <label for="">Nueva Contraseña</label>
                    <input type="password" class="form-control" formControlName="nuevaPassword">
                </div>
                <div *ngIf="nuevaPassword?.invalid && (nuevaPassword?.dirty || nuevaPassword?.touched)" class="alert alert-warning" role="alert">
                    <span class="" *ngIf="nuevaPassword?.errors?.required">Contraseña Obligatoria</span>
                    <span class="" *ngIf="(nuevaPassword?.dirty || nuevaPassword?.touched) && nuevaPassword?.invalid && nuevaPassword?.errors?.pattern">Contraseña Incorrecta</span>
                    <div *ngIf="nuevaPassword?.errors?.minlength">
                        <span class="">Contraseña Debil</span>
                    </div>
                </div>
    
                <div class="form-group mt-4">
                    <label for="">Confirmar Nueva Contraseña</label>
                    <input type="password" class="form-control" formControlName="confirmarPassword">
                </div>
                <div *ngIf="confirmarPassword?.invalid && (confirmarPassword?.dirty || confirmarPassword?.touched)" class="alert alert-warning" role="alert">
                    <span class="" *ngIf="confirmarPassword?.errors?.required">Contraseña Obligatoria</span>
                </div>

                <small>*Almenos 8 caracteres de longitud</small><br>
                <small>*Mayúsculas y Minúsculas</small><br>
                <small>*Números</small>
    
                <div class="form-group mt-4 text-center">
                    <button class="btn btn-warning">Confirmar Contraseña</button>
                </div>
            </form>

          </div>
        </div>
    </div>
</div>
