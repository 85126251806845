<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="'ORDEN DE SERVICIO'"></app-titulos>
        </div>
        <div class="col">
            <h1 class="fecha" id="fecha"> {{ today | date }} </h1>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <input type="date" #fecha (change)="busquedaFecha(fecha)" [value]="today" [max]="today" name="busquedaporFecha" id="busquedaporFecha" class="form-control">
        </div>
    </div>

    <div class="row">

        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>USUARIO</p>
                        </div>
                        <div class="col text_header">
                            <p>SERVICIO</p>
                        </div>
                        <div class="col text_header">
                            <p>MÉTODO</p>
                        </div>
                        <div class="col text_header">
                            <p>TOTALES</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        
                        <div class="col text_header">
                            FOLIO
                        </div>

                        <div class="col text_header">
                            STATUS
                        </div>

                        <div class="col text_header"  *ngIf="busqueda.sede != 'TLYC01'" >
                            UTILIDAD
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of data; let i = index">
                        <div class="row pointer" [routerLink]="['/hoja/consumo/por/usuario', item._id]">
                            
                            
                            <div class="col-md-1">
                                <p> {{ i +1 }} </p>
                            </div>
                            <div class="col">
                                <p> {{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }} </p>
                            </div>
                            <div class="col">
                                <p> {{ item.estudios.length }} </p>
                            </div>
                            
                            <div class="col">

                                <p> {{metodo[i] }} </p>
                            </div>

                            <ng-container>
                                <div class="col">
                                    <p> {{ item.totalCompra | currency }} </p>
                                </div>
                                
                            </ng-container>

                            <div class="col">
                                <p> {{ item.sede }} </p>
                            </div>

                            <div class="col text-center">
                                <p> {{ item.folio }} </p>
                            </div>
                            
                            <div class="col text-center">
                                <p> {{ item.status | uppercase }} </p>
                                
                                <p  class="cancel-text" *ngIf="item.status == 'CANCELADO'" >
                                    Cancelado por...
                                    <strong>

                                        {{ item.motivoCancelacio }}
                                    </strong>
                                </p>
                            </div>

                            <div class="col text-center" *ngIf="busqueda.sede != 'TLYC01'">
                                <p> {{ item.ganancia }} </p>
                            </div>

                        </div>
                    </div>
                </div>
                
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>TOTAL: $ {{ totales }} . 00</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </div>

</div>