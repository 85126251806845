import { Component, OnInit } from '@angular/core';
import { IntegradosService, Scv } from '../../../services/servicios/integrados.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-servicios-dash',
  templateUrl: './servicios-dash.component.html',
  styleUrls: ['./servicios-dash.component.css']
})
export class ServiciosDashComponent implements OnInit {
  public pagina : number = 0;
  public totalservicios:number;
  servicio:Scv[]=[];
  
  constructor( private _serviciosService: IntegradosService, private _router:Router) {}

  ngOnInit(): void {
    this.servicio= this._serviciosService.getServicios();
    this.totalservicios = this.servicio.length;
    this.pagina = 0;
  }
}
