<div class="container animate bounceInLeft">
    <h2 class="text-center"></h2>

        <ficha-info-user [pacienteInfo]="pacienteInfo"></ficha-info-user>

    <div class="card" id="card">
        <div class="row">
            <div class="col-md-9">
                <button class="btn btn-secondary mb-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i class="fas fa-folder-plus"></i> Agregar consulta
                </button>
                  <!-- <button class="btn btn-danger mb-2">
                  <i class="fas fa-file-pdf"></i> Descargar PDF
                </button> -->
                <div class="btn mb-2">
                  <select name="servicio" class="form-control" id="" #servicio (change)="irAUnServicio( servicio.value  )">
                      <option   value="" selected> Selecciona un servicio  </option>
                      <option   value="ambulancia"> Ambulancia</option>
                      <option   value="consulta"  > Consulta General </option>
                      <option   value="consultaEspecialista">  Consulta con especialista </option>
                      <option   value="endoscopia"> Endoscopia</option>
                      <!-- <option   value="resultados"> Entraga de resultados </option>  -->
                      <!-- <option   value="farmacia">   Farmacia </option>  -->
                      <!-- <option   value="hospitalizacion"> Hospitalización</option>  -->
                      <option   value="laboratorio"> Laboratorio</option>
                      <option   value="quirofano">  Quirofano</option>
                      <option   value="xray">     Rayos x</option>
                      <option   value="resonancia"> Resonancia</option>
                      <option   value="tomografias"> Tomografias</option>
                      <!-- <option   value="TrabajoSocial" > Trabajo Social  </option> -->
                      <option   value="ultrasonido"> Ultrasonido</option>
                      <!-- <option   value="urgencias"> Urgencias</option>  -->
                      <option   value="otros"> Otros </option> 
                    </select>
              </div>
              
                  <div class="collapse mb-4" id="collapseExample">
                      <div class="card card-body">
                          <table class="table">
                              <tbody>
                                  <tr>
                                      <td>
                                          <select class="form-control" #select (change)="seleccion($event, select.value)" [(ngModel)]="consultas.tipo">
                                              <option value="" selected>Tipo de consulta o estudio...</option>
                                              <option value="visitas">Citas incluidas</option>
                                              <!-- <option value="laboratorio">Estudios de laboratorio</option>
                                              <option value="ultrasonido">Estudios de ultrasonido</option>
                                              <option value="rayosx">Rayos X</option> -->
                                        </select>
                                          <!-- <p class="text-danger">*No puedes agregar más consultas</p> -->
                                      </td>
                                      <td>
                                          <select class="form-control" #selectCon [(ngModel)]="consultas.consulta">
                                            <option value="" selected>Concepto...</option>
                                            <option [value]="item" *ngFor="let item of concepto">{{ item }}</option>
                                        </select>
                                      </td>
                                      <td>
                                          <select class="form-control" [(ngModel)]="consultas.medico">
                                            <option value="" selected>Medico...</option>
                                            <option [value]="medico.nombre" *ngFor="let medico of medicos">{{ medico.nombre }}</option>
                                        </select>
                                      </td>
                                      <!-- <td><input type="text" class="form-control" placeholder="Firma..." [(ngModel)]="consultas.firma"></td> -->
                                      <td>
                                          <button class="btn btn-warning" (click)="agregarConsulta()">Pagar</button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
            </div>
            <div class="col-md-3">
                <img [src]="item.paquete.icon" *ngFor="let item of paquete" class="img-fluid">
            </div>
        </div>
        <div class="row">   
                <div class="container-fluid">                
                    <div class="p-3 mb-2 bg-success text-white">
                        Citas incluidas
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="card" id="cardMG" style="cursor: pointer;" (click)="showMessageConsulta('Consulta de medicina general sin costo durante un año de Lunes a Domingo las 24 Horas')">
                                <div class="card-body">
                                    <span class="badge" id="badgeMG"></span>
                                    <p class="text-center"><i class="fas fa-female mr-2"></i> Consulta de medicina general sin costo durante un año de lunes a domingo las 24 Horas </p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <!-- <div class="mt-5 p-3 mb-2 bg-primary text-white">
                        Estudios de laboratorio
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card" id="BasicosObsCard" style="cursor: pointer;" (click)="showMessage('Estudios básicos')">
                                    <div class="card-body">
                                        <span class="badge" id="badegeBasicos"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Estudios básicos </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="card" style="cursor: pointer;" id="EspecialesCard" (click)="showMessage('Estudios especiales')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeEspeciales"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Estudios especiales </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="mt-5 p-3 mb-2 bg-info text-white">
                        Estudios de ultrasonido
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card" id="RECard" style="cursor: pointer;" (click)="showMessage('Realización de estudios')">
                                    <div class="card-body">
                                        <span class="badge" id="badegeRE"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Realización de estudios </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="card" style="cursor: pointer;" id="IECard" (click)="showMessage('Interpretación de estudios ')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeIE"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Interpretación de estudios  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="mt-5 p-3 mb-2 bg-info text-white">
                        Rayos X
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card" id="RPCard" style="cursor: pointer;" (click)="showMessage('Realización de placas')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeRP"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Realización de placas </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="card" style="cursor: pointer;" id="IPCard" (click)="showMessage('Interpretación de placas')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeIP"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Interpretación de placas  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div> 

        </div>
    </div>
</div>