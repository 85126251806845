import { Component, Input, OnInit } from '@angular/core';
import Carrito from '../../../../classes/carrito/carrito.class';
import CarritoHorizonte from 'src/app/classes/carrito-horizonte/horizonte-carrito';
import { eliminarStorage, getCarritoStorage } from '../../../../functions/pacienteIntegrados';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailService } from 'src/app/services/cotizacion/email.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Tickets from 'src/app/classes/tickets/ticket.class';
import { CarritoNuevo } from 'src/app/interfaces/carrito-horizonte-interface';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-tabla-service',
  templateUrl: './tabla-service.component.html',
  styleUrls: ['./tabla-service.component.css']
})
export class TablaServiceComponent implements OnInit {

  @Input() serviceSi: any[] = [];
  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };
 
  public carrito2: CarritoNuevo = {} as CarritoNuevo;
  @Input() membresia = false;
  @Input() RoleUser = "";
  @Input() public pagina = 0;
  @Input() servicios = "";
  @Input() totalAmbulancia: string;
  public show = 'hidden';
  public btnPago = false;
  public btnImpPago = false;
  public email = "";
  public sedePaciente = '';
  public dataSedePaciente = {
    idSede: '',
    name: '',
    idItem: ''
  }
  public idPedidoSede;

  constructor(private _service: IntegradosService,
    private _router: Router,
    private _emailService: EmailService,
    private _route: ActivatedRoute,

    ) { }

  ngOnInit(): void {
    this.obtenerCarritoStorage();
    this.obtenerPacienteYMembresia();
    this.obtenerSedePaciente();

  }

  obtenerSedePaciente() {
    this.sedePaciente = localStorage.getItem('sedePaciente');
    if (this.sedePaciente == '') {
      this.sedePaciente = ''
    }
  }

  obtenerPacienteYMembresia() {
    // se obtienen los precios con membresia 
    let usuario = JSON.parse(localStorage.getItem('paciente'));
    if (usuario != null) {
      this.btnPago = true;
      this.btnImpPago = false;
      this.verDatos();
      this.membresia = usuario.membresiaActiva;
    } else {
      this.btnPago = false;
      this.btnImpPago = true;
      this.verDatos();
    }
    /* if(this.idPedidoSede == null){
      this.btnPago = false;
      this.btnPagoSede = false;
      this.btnImpPago = true;
    }else{
      this.btnPago = false;
      this.btnPagoSede = true;
      this.btnImpPago = false;
    }  */
    /* console.log( this.btnPago ); */
  }

  onClick() {
    this.idPedidoSede = localStorage.getItem('idPedidoSede');
    if (this.idPedidoSede == null) {
      this._router.navigateByUrl('/pago/servicios');
    } else {
      this._router.navigateByUrl('/pedidos/detalle/' + this.idPedidoSede);
    }
  }


  obtenerCarrito() {
    this.carrito = getCarritoStorage();
    if (this.carrito == null) {
      this.carrito = {
        totalSin: 0,
        totalCon: 0,
        items: []
      };
    }
  }

  obtenerCarritoStorage() {
    /* const storageCarrito = new Carrito(); */
    const storageCarrito = new CarritoHorizonte();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }
  cerrarModal() {
    this.show = 'hidden'
  }

  abrirInputCorreo() {
    this.show = 'show';
  }

  enviar() {
    let cotizacion = {
      correo: this.email,
      carrito: this.carrito
    }
    this._emailService.envioEmail(cotizacion).subscribe((data: any) => {
      if (data.ok) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'LA COTIZACION SE ENVIO EXITOSAMENTE',
        })
        this.show = 'hidden';
      }
    })
  }

  imprimirCotizacion(carrito) {
    this.generarTicket(carrito);
  }

  generarTicket(carrito) {
    const tickets = new Tickets();
    tickets.imprimirCotizacion(carrito);
    eliminarStorage();
    this.obtenerCarrito();
  }

  agregarCarrito(precioSin, precioCon, item: any) {
    let carrito = new CarritoHorizonte();
    this.carrito2 = carrito.agregarItem(this.membresia, precioSin, precioCon, item);
    this.validarCarrito();
  }

  agregarCarritoSede(event, item: any, uti, rango) {
    // console.log(event);
    let carrito = new Carrito();
    this.carrito = carrito.agregarItemUtilidad(event, item, uti, rango);
    this.validarCarrito();
  }

  eliminar(index) {
    //console.log('Pedido', pedido);
    let carrito = new CarritoHorizonte();
    carrito.eliminar(index);
    this.obtenerCarritoStorage();
    this.validarCarrito();
  }

  validarCarrito() {
    this.obtenerCarritoStorage();
    if (this.carrito.items.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  async alertcomparasion(ev, precioPublico, precioMembresia, item2: any) {
    let precioSinTrim = precioPublico.replace('$', '');
    let precioSinComaPublico = precioSinTrim.replace(',', '');
    let precioMemTrim = precioMembresia.replace('$', '');
    let precioMemComaMembresia = precioMemTrim.replace(',', '');
    if (this.sedePaciente === null) {
      this.agregarCarrito(precioPublico, precioMembresia, item2);
    } else {
      this._service.getIdSedeNom(this.sedePaciente).subscribe((data: any) => {
        if (data.ok) {
          this.dataSedePaciente.idSede = data['data'][0]._id;
          this.dataSedePaciente.name = this.servicios;
          this.dataSedePaciente.idItem = item2._id;
          this._service.getItemSede(this.dataSedePaciente).subscribe((data: any) => {
            if (data.ok) {
              if (data['data'].length == 0) {
                this.agregarCarrito(precioPublico, precioMembresia, item2);
              } else {
                let uti = '';
                switch (data['data'][0].rangoUtilidad) {
                  case 'A':
                    if (this.membresia) {
                      uti = data['data'][0].preciosRangoA[0].porcentajePrecioPublicoA
                    } else {
                      uti = data['data'][0].preciosRangoA[0].porcentajePrecioMembresiaA
                    }
                    break;
                  case 'B':
                    if (this.membresia) {
                      uti = data['data'][0].preciosRangoB[0].porcentajePrecioPublicoB
                    } else {
                      uti = data['data'][0].preciosRangoB[0].porcentajePrecioMembresiaB
                    }
                    break;
                  case 'C':
                    if (this.membresia) {
                      uti = data['data'][0].preciosRangoC[0].porcentajePrecioPublicoC
                    } else {
                      uti = data['data'][0].preciosRangoC[0].porcentajePrecioMembresiaC
                    }
                    break;
                }
                this.agregarCarritoSede(ev, data['data'][0].idServicio, uti, data['data'][0].rangoUtilidad)
              }

            }
          })

        }
      })
      /* this._service.getItemSede(this.dataSedePaciente).subscribe((data:any)=>{
        if(data.ok){
          console.log(data);
          
        }
      }) */
    }
    /* this.agregarCarrito(ev, item2) */
    if (this.servicios === 'xray') {
      this.interpretacion(precioSinComaPublico, precioMemComaMembresia, item2);
    } else {
      Swal.fire({
        icon: 'success',
        title: 'CON MEMBRESIA AHORRAS',
        text: `$${precioSinComaPublico - precioMemComaMembresia}.00`,
      })
    }
  }

  async interpretacion(precioSinComaPublico, precioMemComaMembresia, item2) {
    const { value: accept } = await
      Swal.fire({
        title: 'Con membrecia ahorras: $' + (precioSinComaPublico - precioMemComaMembresia),
        input: 'checkbox',
        inputValue: 1,
        icon: "success",
        inputPlaceholder:
          'Agregar interpretacion, recuerda que tiene un costo extra',
        confirmButtonText:
          'Aceptar',
      });

    if (accept) {
      const item = {
        name: "xray",
        ESTUDIO: "INTERPRETACIÓN DE " + item2.ESTUDIO,
        PRECIO_PUBLICO: "$100",
        PRECIO_MEMBRESIA: "$100",
        _id: "0"
      }
      this.agregarCarrito(precioSinComaPublico, precioMemComaMembresia, item);
    }
  }

  verDatos() {
    if(this.servicios != 'agenda'){
      this._service.getObtener(this.servicios).subscribe(
        (res: any) => {
          this.serviceSi = res.data;
          // this.imprimirEstudios(this.serviceSi);
          this.totalAmbulancia = res.data.results;
        },
        err => {
          console.log(<any>err);
        });
    }
  }

  imprimirEstudios(data){
    console.log(data);
    this.imprimirPDF(data);
  }

  imprimirPDF(duplicados:any){
    const data : any =[]
    const doc : any = new jsPDF({ orientation: 'landscape'});
    doc.setTextColor(100);
    doc.setFont('courier')
    duplicados.forEach((element:any) => {
      var x =[
        element.ESTUDIO,
        element.PRECIO_PUBLICO,
        element.PRECIO_MEMBRESIA,
      ]
      data.push(x)
    });
    doc.autoTable({
      theme:'grid',
       styles: { halign: 'center',font :'courier',fontSize:18 , fillColor:[44, 62, 80]},
      columnStyles: {0:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     1:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     2:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     3:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     4:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     5:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     6:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] },
                     7:{halign: 'center',font :'courier',fontSize:9 ,fillColor: [248,248,255] }},
      head:[["ESTUDIO","PUBLICO","MEMBRESIA"]],
      body :data,
      startY:30
    });

    

     doc.output('dataurlnewwindow')
  }
 
  
  showAlert() {
    Swal.fire({
      title: "¿Estas seguro de contratar a este destino?",
      text: "El servicio de ambulancia solo será requerido para dicho destino, no puede haber cambios",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'INGRESA LOS DATOS PARA EL TRASLADO',
          })
          this._router.navigateByUrl('/hoja-fram');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this._router.navigateByUrl('serviciosInt/' + this.servicios);
        }

      })
  }


  editarServicio(id) {

    Swal.fire({
      title: "¿Estas seguro que vas a editar el servicio",
      text: "Una vez que se haya editado, no se podrá recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
      .then((result) => {
        if (result.value) {
          this._router.navigateByUrl('formularioeditarservice/' + id)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'NO SE EDITO NADA',
          })
          this._router.navigateByUrl('serviciosInt/' + this.servicios);
        }
      })
  }

  delete(id) {
    Swal.fire({
      title: "¿Estas seguro de eliminar el servicio?",
      text: "Una vez que se haya eliminado, no se podrá recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
      .then((result) => {
        if (result.value) {
          this._service.deleteService(id).subscribe((resp: any) => {
            if (resp.ok) {
              Swal.fire({
                icon: 'success',
                title: '',
                text: 'EL SERVICIO SE ELIMINO CORRECTAMENTE',
              })
              this.verDatos();
              this._router.navigateByUrl('serviciosInt/' + this.servicios);
            }
          })

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'EL SERVICIO NO SE ELIMINO',
          })
        }
      })
  }

}
