<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'LISTADO DE PEDIDOS DE OTRAS SEDES'"></app-titulos>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>GÉNERO</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTUDIOS</p>
                        </div>
                        <div class="col text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <div class="col text_header">
                            <p>STATUS</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let pedido of pedidos | paginate:{id:'listapacientes',
                    itemsPerPage: 4,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer puntero" (click)="detalle(pedido,pedido._id)">
                            <div class="col-md-1">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.paciente.nombrePaciente | uppercase }} {{ pedido.paciente.apellidoPaterno | uppercase }} {{ pedido.paciente.apellidoMaterno | uppercase }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.paciente.edad }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.paciente.genero | uppercase }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.estudios.length }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.fecha | date:'dd/MM/yyyy':'UTC' }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.sede }}</p>
                            </div>
                            <div class="col">
                                <p>{{ pedido.status }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>







<!-- <div class="row">
    <div class="col">
        <h1 class="text-principal">

            Listado de pedidos de otras sedes
        </h1>

    </div>
</div>


<section class="mt-3">
    <div class="card">
        <div class="card-header header">
            <div class="row">
                <div class="col text_header">
                    <p>
                        #
                    </p>
                </div>
                <div class="col text_header">
                    <p>
                        Nombre
                    </p>
                </div>
                <div class="col text_header">
                    <p>
                        Edad
                    </p>
                </div>
                <div class="col text_header">
                    <p>
                        Género
                    </p>
                </div>

                <div class="col text_header">
                    <p>
                        Estudios
                    </p>
                </div>

                <div class="col text_header">
                    <p>
                        Fecha
                    </p>
                </div>

                <div class="col text_header">
                    <p>
                        Sede
                    </p>
                </div>

                <div class="col text_header">
                    <p>
                        Status
                    </p>
                </div>


            </div>
        </div>

        <div class="card-body">
            <div class="row" *ngFor="let pedido of pedidos | paginate:{id:'listaAmbulancia',
            itemsPerPage: 3 ,
            currentPage:pagina,
            totalItems:totalAmbulancia}; let i = index" (click)="detalle(pedido,pedido._id)">
                <div class="col center">
                    <p class="color">
                        {{ i +1 }}
                    </p>
                </div>
                <div class="col center">
                    <p class="color  pointer">
                        {{ pedido.paciente.nombrePaciente | uppercase }} {{ pedido.paciente.apellidoPaterno | uppercase }} {{ pedido.paciente.apellidoMaterno | uppercase }}
                    </p>

                </div>
                <div class="col center">
                    <p class="color">
                        {{ pedido.paciente.edad }}
                    </p>
                </div>

                <div class="col center">
                    <p class="color">

                        {{ pedido.paciente.genero | uppercase }}
                    </p>
                </div>

                <div class="col center">
                    <p class="color">

                        {{ pedido.estudios.length }}
                    </p>
                </div>

                <div class="col center">
                    <p class="color">

                        {{ pedido.fecha | date }}
                    </p>
                </div>

                <div class="col center">
                    <p class="color">
                        {{ pedido.sede }}
                    </p>
                </div>
                <div class="col center">
                    <p class="color">
                        {{ pedido.status }}
                    </p>
                </div>
            </div>
            <div class="offset-md-4">
                <pagination-controls id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </div>
</section> -->