export default class  PacienteStorage {
    
    constructor() {   
    }

    public verMembresiaPaciente(){
        let membresia = JSON.parse(  localStorage.getItem('paciente')).membresiaHabilitada;
        return membresia;
    }

    // checa si existe un paciente en el localstorage
    public verPacienteConMembresia(){
        let pacienteMembresia = JSON.parse( localStorage.getItem('paciente'));
        return pacienteMembresia;
    }

    public setPacienteStorage(  infoPaciente  ){
      let pacienteStorage =  JSON.stringify( infoPaciente );
      localStorage.setItem('paciente', pacienteStorage);
      return true;
    }

    public removePacienteStorage(){
       return localStorage.removeItem('paciente');
    }

}