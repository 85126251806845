import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {  getDataStorage  } from '../../functions/storage.funcion';
import * as moment from 'moment';
import { paciente } from '../../interfaces/historia-clinica';

@Component({
  selector: 'app-signos-vitales',
  templateUrl: './signos-vitales.component.html',
  styleUrls: ['./signos-vitales.component.css']
})
export class SignosVitalesComponent implements OnInit {
  @Input() idConsulta : string;
  public fecha: string;
  public enfermeraAtendio='';
  @Input()  paciente: paciente = {} as paciente;
  public imc: number = 0;
  public btnSignosVitales = true;
  public talla: number =0;
  public peso: number=0;
  
  public infPaciente = {
    esquemaVacunacion:""
  }
  
  public signosVitales = {
    talla:0,
    peso:0,
    imc:0,
    fc: "",
    fr: "",
    temp: "",
    sistolica: "",
    diastolica: "",
    pc:    "",
    pa:"",
    pt:"",
    apgar: "",
    SaO: "",
    pao:"",
    glucosa:"",
    riesgoCaida: "",
    enfermeraAtendio:"",
    diagnosticoActual:"",
    observaciones: ""
  }
  
  constructor(private _HistoriaClinicaService: HistoriaClinicaService,
              private _consultaService: ConsultaService,
              private _WsLoginService : WsLoginService,
              private _router: Router) { }


  ngOnInit(): void {
    this.fecha = moment().format('l');
    this.signosVitales.enfermeraAtendio = getDataStorage().nombre;
  }

  setPeso(){
    this.signosVitales.peso = this.peso;
    this.signosVitales.talla = this.talla;
    this.signosVitales.imc = this.imc;
  }

  caida($event){
    this.signosVitales.riesgoCaida = $event;
  } 
  
  obtenerIMC(){
    this.imc = ((this.peso)/(this.talla * this.talla));
    let imcL = this.imc.toString();
    imcL.split(',', 2);
    let imcLn;
    imcLn = parseFloat(imcL).toFixed(2);
    this.imc = imcLn;
  }

  validarSignos( validar: number  ){
    if( validar <= 0 ){
      return true;
    }
  }

  messageAlert(message){
    Swal.fire({
      icon: 'success',
      title: '',
      text: 'NO SE ENCUENTRA NINGUN PACIENTE',
    })
    Swal.fire( 
      "PELIGRO",
        message, 
       "error",
       /* button:"Entendido" */
    );
    /* alert(message); */
  }

  validarStringsForm(validar: string){
    if(  validar == "" || validar == undefined ){
      return true;
    }
  }

  validacionFormSignos(){
    if( this.validarSignos( this.talla ) ){
      this.messageAlert('Completa la talla');
      return false;
    }else if(this.peso == 0){
      this.messageAlert('Completa el peso');
      return false;
    }else if( this.validarStringsForm( this.signosVitales.temp )  ){
      this.messageAlert('Completa la tempetura')
      return false;
    }
    this.btnSignosVitales =  false;
    return true;
  }

  ageragrSignos(){
    this.setPeso();
    this._HistoriaClinicaService.agregarSignosVitales(this.idConsulta,this.signosVitales)
    .subscribe(  (data:any) => {
      if(  data['ok']){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'LOS SIGNOS VITALES SE GUARDARON CORRECTAMENTE',
        })
        this.cambioDeEstadoConsultaEnfermeria();
        this._WsLoginService.notificarDoctor(data);
      }
    });
  }

  // cambioDeEstadoCosnulta(){
  //   let consultaestado = {
  //     status: 'Medico'
  //   }
  //   this._consultaService.cambiarEstadoConsulta(this.idConsulta, consultaestado)
  //   .subscribe( (data) => {
  //     if(data['ok']){
  //       this._router.navigateByUrl('/')
  //     }
  //   })
  // }

  // comente el cambio de estado de los mediucos

  cambioDeEstadoConsultaEnfermeria(){
    const estado = {
      status : 'Medico'
    }
    this._consultaService.cambiarEstadoConsulta( this.idConsulta, estado  )
    .subscribe( (data) => {
      if(data['ok']){
        this._router.navigateByUrl('/')
      }
    });
  }

}
