import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { getDataStorage } from 'src/app/functions/storage.funcion';

@Injectable({
  providedIn: 'root'
})
export class EnfermeriaGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      /* let usuarioRole = getDataStorage().role;

      if( usuarioRole == 'Enfermería' || usuarioRole == 'Admin' ){

        return true;
      }
      return false; */
      try {
        let usuarioRole = getDataStorage().role;
        if( usuarioRole == 'Enfermería' || usuarioRole == 'Admin' ){
  
          return true;
        }else{
          return false;
        } 
      } catch (error) {
        return true;
      }

  }
  
}
