import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Dates from 'src/app/classes/dates/date.class';
import { Pedidos } from 'src/app/classes/pedidos/pedidos';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { PageComponent } from '../../page/page.component';

@Component({
  selector: 'app-hoja-consumo-al-dia',
  templateUrl: './hoja-consumo-al-dia.component.html',
  styleUrls: ['./hoja-consumo-al-dia.component.css']
})
export class HojaConsumoAlDiaComponent implements OnInit {

  constructor(private _service: ServiciosService,
              private _route: ActivatedRoute) { }

  public id = "";

  public pedidos = {
    estudios:[],
    doctorQueSolicito: "",
    efectivo: "",
    fecha: "",
    hora: "",
    montoEfectivo: 0,
    montoTarjetaDebito: 0,
    montoTarjteCredito: 0,
    montoTranferencia: 0,
    factura:'',
    paciente: "",
    sede: "",
    tarjetCredito: "",
    tarjetaDebito: "",
    totalCompra:0,
    transferencia: "",
    vendedor: "",
    ventasOrigen:  "",    
    _id: "",
    compraConMembresia:false
  };

  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno:"",
    edad:0,
    genero:"",
    _id:"",
    telefono:"",
    fechaNacimientoPaciente:"",
    paisPaciente:"",
    razonSocial1: "",
    cpRazonSocial: "",
    razonSocial1RFC:"" ,
    razonSocial1Calle:"",
    razonSocial1Estado:"", 
    razonSocial1Municipio:"",
    correoRazonSocial1:"",
    membresiaActiva:false
  }



  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.getDetailSells();
  }


  public getDetailSells(){
    const dates = new  Dates();
    const date = dates.getDate();



    this._service.getSellsDetailByUser(this.id)
    .subscribe( (data:any) =>  {
      if(data.ok){
        this.setPaciente( data['data']['paciente'] );
        this.setPedidos( data['data']);
      }
    });
  }

  setPedidos(data){ 
    /* console.log( data); */
    this.pedidos = data;
    this.pedidos.fecha = data.fecha.split('T')[0];
    // console.log( this.pedidos );
  }

  setPaciente(paciente){
    // console.log( paciente );
    this.paciente = paciente;

  }

}
