<div class="row">
    <div class="col-md-8">
        <h1 class="centerxd" *ngIf="rol == 'recepcion'"> {{ data.nombrePaciente | uppercase }} {{ data.apellidoPaterno | uppercase}} {{ data.apellidoMaterno | uppercase }} </h1>
        <h1 class="centerxd" *ngIf="rol != 'recepcion'"> {{ title |uppercase }} </h1>
    </div>
    <div class="col-md-4" *ngIf="rol == 'recepcion'">
        <p>
            <span>
                {{data.fechaRegistro }}
            </span>
        </p>
        <p>
            <span><label for="consultas">  Consultas: </label>  {{ data.consultas }} </span>
        </p>
    </div>
</div>