import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PedidosSedesService } from 'src/app/services/pedidosSedes/pedidos-sedes.service';
import { PacientesService } from '../../../services/pacientes/pacientes.service';
import {  FichaInfo } from '../../../../app/classes/ficha-info-paciente';
import Dates from 'src/app/classes/dates/date.class';
import * as moment from 'moment';
import { eliminarTodoPedido, getDataStorage } from 'src/app/functions/storage.funcion';
import { CEDE } from 'src/app/classes/cedes/cedes.class';
import Tickets from 'src/app/classes/tickets/ticket.class';
import { PagoServiciosService } from '../../../services/pagos/pago-servicios.service';
import { USGService } from '../../../services/usg/usg.service';
import { XRAYService } from '../../../services/Rayos-X/xray.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Carrito from 'src/app/classes/carrito/carrito.class';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
 
@Component({
  selector: 'app-detalle-pedidos-sedes',
  templateUrl: './detalle-pedidos-sedes.component.html',
  styleUrls: ['./detalle-pedidos-sedes.component.css']
})
export class DetallePedidosSedesComponent implements OnInit {

  public id = "";
  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };
  public paciente={
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    id:"",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    membresiaActiva: '',
    _id:"",
    sede:""
  };
  public pedido={
    anticipo: 0,
    compraConMembresia: '',
    doctorQueSolicito: '',
    efectivo: '',
    estudios: [],
    fecha: '',
    hora: '',
    montoAnticipo: 0,
    montoEfectivo: 0,
    paciente: {
      _id: '',
      membresiaActiva:''
    },
    sede: '',
    status: '',
    totalCompra: 0,
    vendedor: '',
    _id: '',
    ganancias:''
  };
  public infoVenta = {  
    paciente:"",
    nombrePaciente:"",
    vendedor:"",
    fecha:"",
    hora:"",
    estudios:[],
    efectivo:false,
    doctorQueSolicito:"",
    transferencia: false,
    tarjetCredito:false,
    tarjetaDebito:false,
    montoEfectivo:0,
    montoTarjteCredito:0,
    montoTarjetaDebito:0,
    montoTranferencia: 0,
    sede:"",
    totalCompra:0,
    prioridad:"",
    compraConMembresia:true,
    montoAnticipo:0,
    status:'',
    idVenta:'',
    ganancia:''
  }

  public fecha = "";
  public totalConIva=0;
  public totalCarritoMembresia = 0;
  public btnValidacion=true;

  public pedidosLaboratorios = { 

    estudios:[],
    idPaciente:"",
    fecha:"",
    hora:"",
    medicoQueSolicita:"",
    sede:"",
    prioridad:"Programado",
    estado:""
  }

  public pedidosUltrasonido = {
    idPaciente:"", 
    estudios:[],
    fecha:"",
    hora:"",
    medicoQueSolicita:"",
    sede:"",
    prioridad:"Programado"
  }

  
  public pedidosRayox = {
    idPaciente:"", 
    estudios:[],
    fecha:"",
    hora:"",
    medicoQueSolicita:"",
    sede:"",
    prioridad:"Programado"
  }


  constructor(
    private _ultrasonidoService: USGService,
    private _pagoServicios: PagoServiciosService,
    private _pacienteService: PacientesService,
    private pedidosSedesService: PedidosSedesService,
    private activatedRoute: ActivatedRoute,
    private _router:Router,
    private _xrayService :XRAYService,
    private _servicios: ServiciosService
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerPedido();
    this.obtenerCarritoStorage();
    //console.log(this.paciente);  
  }

  obtenerCarritoStorage(){
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  obtenerPaciente(idPaciente){    
    this._pacienteService.getPacienteBtID(  idPaciente )
    .subscribe( (data:any) => {
      if(data.ok){
        /* this.setPaciente(data['paciente']); */
        this.setPaciente(data['paciente']);
        /* console.log(this.paciente); */  
      }
    });
  }

  obtenerPedido(){
    localStorage.setItem('idPedidoSede', this.id);
    this.pedidosSedesService.obtenerPedido( this.id )
    .subscribe( (data:any) => {
      if(data.ok){
        this.setPedido(data['data']);
      }
    })
  }

  irAUnServicio(  servicio ){
    localStorage.setItem('sedePaciente', this.pedido.sede);
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    this._router.navigate([ `/serviciosInt/${servicio}`]);
  }


  setPedido(pedido){
    this.pedido=pedido
    this.obtenerPaciente(this.pedido.paciente._id); 
    /* console.log(this.pedido.totalCompra - this.pedido.montoAnticipo); */
    this.obtenerCarrito(this.pedido.totalCompra - this.pedido.montoAnticipo); 
    this.infoVenta.doctorQueSolicito= this.pedido.doctorQueSolicito;      
  }

  setPaciente( paciente ){
    this.paciente = paciente;    
    localStorage.setItem('paciente', JSON.stringify(this.paciente));
    /* console.log(this.paciente); */
  }

  enviarPedido(){
    const est={
      status: 'Pagado'
    }
    //this.infoVenta.estudios = this.carrito.items;
    /* this.infoVenta.paciente = this.paciente.id; */
    this.setDatesVenta();
    /* this.carrito.items.forEach(element =>{
      this.infoVenta.estudios.push(element)
    }); */

    this.infoVenta.status = 'Pagado';
    this.infoVenta.sede = this.paciente.sede;

    //! hacer el modulo para que recepcion le de su ganancias al socio
    this.infoVenta.totalCompra = this.pedido.totalCompra - this.pedido.montoAnticipo

    //! TODO: hacer el calculo de la ganancia del socio por servicio
    this._pagoServicios.agregarPedido( this.infoVenta ).subscribe( async(data) => {
      if(  data['ok'] ){
          this.generarTicket(data['folio']);
          if(data['data']._id) {
            //seteamos los datos de los pedidos
            this.infoVenta.idVenta = await data['data']._id;
            this.setDatesPedidos();
          }
          this.pedidosSedesService.actualizarEstado(this.pedido._id, est).subscribe((data:any)=>{
          })
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'VENTA GENERADA EXITOSAMENTE',
          })
          this._router.navigateByUrl('/');  
          this.borrarLocal();
      }
    });
  
  }

  borrarLocal(){
    localStorage.removeItem('paciente')
    localStorage.removeItem('sedePaciente')
    localStorage.removeItem('carrito')
  }

  /* setDatesPedidos (){
    // this.pedidosLaboratorios.fecha = moment().format('l');
    const datesPedidoLab = new Dates();
        // configuracion de los pedidos de laboratorio
    this.pedidosLaboratorios.fecha = datesPedidoLab.getDate();;
    this.pedidosLaboratorios.hora = moment().format('LT');
    this.pedidosLaboratorios.medicoQueSolicita = this.infoVenta.doctorQueSolicito;
    this.pedidosLaboratorios.sede = this.pedido.sede;
    this.pedidosLaboratorios.idPaciente = this.paciente._id;
    if(this.pedidosLaboratorios.idPaciente == undefined){
      this.pedidosLaboratorios.idPaciente = this.paciente.id;
    }

    // configuracion de los pedidos de ultrasonido

    this.pedidosUltrasonido.idPaciente = this.paciente._id;
    if(this.pedidosUltrasonido.idPaciente == undefined){
      this.pedidosUltrasonido.idPaciente = this.paciente.id;
    }
    this.pedidosUltrasonido.fecha = datesPedidoLab.getDate();;
    this.pedidosUltrasonido.sede = this.pedido.sede;

    // configuracion de los pedidos de Rayos x

    this.pedidosRayox.idPaciente = this.paciente._id;
    if(this.pedidosRayox.idPaciente == undefined){
      this.pedidosRayox.idPaciente = this.paciente.id;
    }
    this.pedidosRayox.fecha = datesPedidoLab.getDate();;
    this.pedidosRayox.medicoQueSolicita = this.infoVenta.doctorQueSolicito;
    this.pedidosRayox.sede = this.pedido.sede;


  } */

  setDatesVenta(){
    const dates = new Dates();
    //this.infoVenta.totalCompra = this.carrito.totalSin;
    this.fecha = moment().format('l');    
    this.infoVenta.hora = moment().format('LT');
    this.infoVenta.vendedor = getDataStorage()._id;
    this.infoVenta.paciente = this.paciente._id;
    if(this.infoVenta.paciente == undefined){
      this.infoVenta.paciente = this.paciente.id;
    }
    this.infoVenta.sede = this.pedido.sede;
    this.infoVenta.prioridad = "Programado"
    this.infoVenta.fecha = dates.getDate();
    this.infoVenta.estudios = this.pedido.estudios;
    this.infoVenta.totalCompra = this.pedido.totalCompra;
    this.infoVenta.montoAnticipo = this.pedido.montoAnticipo;
    this.infoVenta.ganancia = this.pedido.ganancias
  }

  setDatesPedidos (){
    //creamos un nuevo json para todos los pedidos que se hagan conforme a la venta
    this.pedido.estudios.forEach(  (element) => {
       let jsonToPedidos = { ... this.infoVenta}
      //  console.log(element);
       
       Object.assign( jsonToPedidos, {idPaciente: this.infoVenta.paciente} );
      //comparamos que si la sede es diferenre de tlayacapan retorne otra respuesta 
      Object.assign( jsonToPedidos, {idServicio: element.idEstudio} );
      Object.assign( jsonToPedidos, {nombreServicio: element.name} );

      //eliminamos los datos que estan de mas
      delete jsonToPedidos.estudios
      delete jsonToPedidos.montoEfectivo
      delete jsonToPedidos.montoTarjetaDebito
      delete jsonToPedidos.montoTarjteCredito
      delete jsonToPedidos.montoTranferencia
      delete jsonToPedidos.efectivo
      delete jsonToPedidos.tarjetCredito
      delete jsonToPedidos.tarjetaDebito
      delete jsonToPedidos.transferencia
      delete jsonToPedidos.compraConMembresia
      delete jsonToPedidos.nombrePaciente
      delete jsonToPedidos.status
      //peticion que se lanza para los nuevo estudios
      this.requestPedidos(jsonToPedidos);
    });
    //pedios de los estudios 
  }
  
  requestPedidos( jsonPedidios ) {
    //peticion para los pedidos de los estudios 
    
    //peticion para crear los nuevo pedidos
    this._servicios.postANewPedidoNew( jsonPedidios )
    .subscribe( (data:any) => {
      if(data.ok){
        console.log(data)
      }  
    })
  }


  generarTicket(folio){
    const tickets = new Tickets();
    tickets.printTicket( this.paciente, this.pedido ,  this.infoVenta, folio );
  }

  agregarIva(){
    let iva = 0.0;
    if( this.infoVenta.tarjetCredito  ){
      iva = 1.9;
    }else if( this.infoVenta.tarjetaDebito ){
      iva = 2.5;
    }
    let totalIva = (( this.totalCarritoMembresia * iva ) / 100);
    this.totalConIva =  this.totalCarritoMembresia + totalIva;
    this.totalCarritoMembresia =  Math.round(this.totalConIva); 
  }

  calcularNuevoTotalEfectivo(){ 
    if(this.totalCarritoMembresia < this.infoVenta.montoEfectivo){
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'MONTO MAYOR',
        })
    }else{
        this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoEfectivo;      
        this.infoVenta.compraConMembresia = false;  
        this.validarBoton(this.totalCarritoMembresia);
    }    
  }

  calcuarMontoTarjetaDebito(){
    if(this.infoVenta.montoTarjetaDebito > this.totalCarritoMembresia){
            Swal.fire({
        icon: 'error',
        title: '',
        text: 'MONTO MAYOR',
        })
    }else{
      this.totalCarritoMembresia = this.totalCarritoMembresia  - this.infoVenta.montoTarjetaDebito;
      this.validarBoton(this.totalCarritoMembresia);
    }
  }
 
  calcularMontoTarjetaCredito(){
   /* console.log(this.totalCarritoMembresia); */
   
    if(this.infoVenta.montoTarjteCredito > this.totalCarritoMembresia){
            Swal.fire({
        icon: 'error',
        title: '',
        text: 'MONTO MAYOR',
        })
    }else{
      this.totalCarritoMembresia = this.totalCarritoMembresia  - this.infoVenta.montoTarjteCredito;      
      this.validarBoton(this.totalCarritoMembresia);
    } 
  }

  calcularMontoTransferencia(){
    if(this.infoVenta.montoTranferencia > this.totalCarritoMembresia){
            Swal.fire({
        icon: 'error',
        title: '',
        text: 'MONTO MAYOR',
        })
    }else{
      this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoTranferencia;
      this.validarBoton(this.totalCarritoMembresia);
    } 
  }

  validarBoton(valor){
    /* console.log(valor); */
        
    if( valor == 0  ){
      this.btnValidacion = false;
    }
  }

  obtenerCarrito(monto){
    if(this.pedido.paciente.membresiaActiva){
      this.totalCarritoMembresia= monto + this.carrito.totalCon;
      
    }else{
      this.totalCarritoMembresia= monto + this.carrito.totalSin;
      
    }
  }
}
