<div class="container-fluid">
    <div class="col">
        <app-titulos [title]="'HOJA DE SERVICIOS'"></app-titulos>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE DEL ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTADO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let ultras of estudios.estudios | paginate:{id:'listapacientes',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer" [routerLink]="['/resultado/servicio/rayosx/', ultras.idEstudio]">
                            <div class="col">
                                <p> {{ i +1 }} </p>
                            </div>
                            <div class="col">
                                <p> {{ultras.nombreEstudio}} </p>
                            </div>
                            <div class="col">
                                <p> {{estudios.prioridad | uppercase}}</p>
                            </div>
                            <div class="col">
                                <p>{{estudios.sede}} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- <h1 class="centerxd">HOJA SERVICIOS</h1>


<section class="row">
    <div class="col-md-12">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

    </div>
</section>

<br>

<div class="center">
    <section style="padding-left: 3.5rem; padding-right: 3.5rem;">
        <div class="card" name="card" id="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col text_header">
                        <p> # </p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre de Estudio </p>
                    </div>
                    <div class="col text_header">
                        <p>Estado</p>
                    </div>
                    <div class="col text_header">
                        <p>Sede</p>
                    </div>

                </div>
            </div>

            <div class="card-body">
                <div *ngFor="let ultras of estudios.estudios| paginate:{id:'listaAmbulancia',
                    itemsPerPage: 3 ,
                    currentPage:pagina,
                    totalItems:totalAmbulancia} ; let i = index">
                    <div class="row" [routerLink]="['/resultado/servicio/rayosx/', ultras.idEstudio]">

                        <div class="col color">
                            <p> {{ i +1 }} </p>
                        </div>

                        <div class="col color">
                            <p> {{ultras.nombreEstudio}} </p>
                        </div>

                        <div class="col color">
                            <p> {{estudios.prioridad}}</p>
                        </div>

                        <div class="col color">
                            <p>{{estudios.sede}} </p>
                        </div>



                    </div>
                </div>
            </div>
            <div class="offset-md-1">
                <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </section>
</div> -->