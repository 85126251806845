import { HistoriaClinicaService } from '../../../../services/historiaClinica/historia-clinica.service';
import { MedicamentosService } from '../../../../services/farmacia/medicamentos.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { getDataStorage } from '../../../../functions/storage.funcion'
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import { CEDE } from 'src/app/classes/cedes/cedes.class';
import Swal from 'sweetalert2/dist/sweetalert2.js';;




@Component({
  selector: 'app-receta',
  templateUrl: './receta.component.html',
  styleUrls: ['./receta.component.css']
})
export class RecetaComponent implements OnInit {

  public fecha: string;
  public hora: string;
  public id: string;

  public nombresMedicamentosDB: [];
  public salOSustanciaDB: [];


  public medico = {
    idMedico: '',
    nombre: '',
    cedulaProfesional: '',
    Especialidad: '',
    cedulaEsp:''
  }

  public estudios: [] = [];

  public buscarEstudiotxt = {
    estudio: ""
  }


  public medicamentoServiceDoctor = {
    nombreComercial: "",
    nombreDeSalOsustanciaActiva: "",
    presentacio: "",
    contenidoFrasco: "",
    viaDeAdministracion: ""
  }

  public recetaPDF = {
    height: 3.7,
    width: 1.5,
    whithEspacio: 3.4
  }

  public paciente = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    _id: '',
    edad: '',
    curp: '',
    registro: '',
    genero: '',
    medico: '',
    calle: '',
    municipio: '',
    talla: '',
    peso: '',
    imc: '',
    fc: '',
    fr: '',
    temp: '',
    pc: '',
    pa: '',
    pt: '',
    apgar: '',
    sao: '',
    alergias: '',
    callePaciente: "",
    membresiaActica: false
  }

  public paciente2 = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    sede:""
  }

  public busuqedaMedicamento = "";

  public medicamentos = [];


  public indicaciones = {
    medicmanento: '',
    indcacionesMedicamento: ''
  }

  public recetMedica = {

    medicamentos: [],
    estudios: [],
    otrasIndicaciones: "",
    idPaciente: "",
    idConsulta: "",
    fechaReceta: '',
    horareceta: '',
    medicoQueAtendio: '',
    idMedico: '',
    cedula: '',
    especialidad:'',
    cedulaEsp:'',
    firma: '',
    origen: 'TLY01',
    horaEgreso: '',
    prioridad: 'Programado'
  }


  constructor(
    private _route: ActivatedRoute,
    private _historiaClinicaService: HistoriaClinicaService,
    private _medicamentosService: MedicamentosService,
    public _consultaService: ConsultaService,
    public _router: Router,
    public _integradosService: IntegradosService
  ) { }

  ngOnInit(): void {
    this.fecha = moment().format('L');
    this.hora = moment().format('LT');

    // este id es de la tabla de consulta
    this.id = this._route.snapshot.paramMap.get('id');
    this.obetenerConsulta();
    // sacamos la informacion de la sesion 
    this.getMedicoData();
  }


  getMedicoData() {

    this.medico.nombre = getDataStorage().nombre
    this.medico.idMedico = getDataStorage()._id;
    this.medico.cedulaProfesional = getDataStorage().cedulaProfesional;
    this.medico.Especialidad = getDataStorage().Especialidad;
    this.medico.cedulaEsp = getDataStorage().cedulaEsp;
    
    /* console.log(this.medico,"aquyyy "); */
    
  }


  setRecetaDataMedico() {
    this.recetMedica.medicoQueAtendio = this.medico.nombre;
    this.recetMedica.idMedico = this.medico.idMedico;
    this.recetMedica.cedula = this.medico.cedulaProfesional;
    this.recetMedica.especialidad = this.medico.Especialidad;
    this.recetMedica.cedulaEsp = this.medico.cedulaEsp;
    this.recetMedica.fechaReceta = this.fecha;
    this.recetMedica.horaEgreso = moment().format('LT');
    this.recetMedica.origen = CEDE;
  }

  obetenerConsulta() {
    this._historiaClinicaService.obtenerConsultaPorElId(this.id)
      .subscribe((data: any) => {
        this.setPaciente(data['data']['paciente'])
        this.paciente.nombre = data['data'].paciente.nombrePaciente;
        this.paciente.apellidoPaterno = data['data'].paciente.apellidoPaterno;
        this.paciente.apellidoMaterno = data['data'].paciente.apellidoMaterno;
        this.paciente.registro = data['data']['paciente']['fechaRegistro'];
        this.paciente.calle = data['data'].paciente.callePaciente;
        this.paciente.edad = data['data']['paciente']['edad'];
        this.paciente.municipio = data['data']['paciente']['estadoPaciente'];
        this.paciente.genero = data['data']['paciente']['genero'];
        this.paciente.curp = data['data']['paciente']['curp'];
        this.paciente._id = data['data']['paciente']['_id'];
        this.paciente.membresiaActica = data['data']['paciente']['membresiaActiva'];
        this.paciente.callePaciente = data['data']['paciente']['callePaciente'];
        // this.paciente.registro = data['data'].paciente._id;
        // console.log( this.paciente );
      });
  }

  setPaciente(data){
    for (const key in this.paciente2) {
      if (data[key] == undefined) {
        this.paciente2[key] = ''
      }else{
        this.paciente2[key] = data[key]
      }
    }
  }


  buscarMedicamento() {


    if( this.busuqedaMedicamento.length == 0 ){
      this.medicamentos = [];
    }

    if (this.busuqedaMedicamento.length >= 4) {

      this._medicamentosService.obtenerMedicamentoPorNombre(this.busuqedaMedicamento)
        .subscribe((data) => {

          this.nombresMedicamentosDB = data['data'][0];
          this.salOSustanciaDB = data['data'][1];
          this.medicamentos = this.nombresMedicamentosDB.concat(this.salOSustanciaDB);


        });
    }
  }

  deleteItem(i:number){
    this.recetMedica.medicamentos.splice( i, 1 );
  } 

  validarMedicamento(){
  
    if( this.medicamentoServiceDoctor.contenidoFrasco == "" && this.medicamentoServiceDoctor.nombreComercial == "" && this.medicamentoServiceDoctor.nombreDeSalOsustanciaActiva == "" && this.medicamentoServiceDoctor.presentacio == "" && this.medicamentoServiceDoctor.viaDeAdministracion == "" ){
      
      Swal.fire('El medicamento esta incompleto', 'Completa los campos', 'error');
    }
  
  }

  agregarMedicamentosDesdeDoctor() {

    return this._medicamentosService.agragarmedicamentos(this.medicamentoServiceDoctor)
      .subscribe((data) => {
        // console.log(data);
       if (data['ok']) {

      Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS MEDICAMENTOS SE AGREGARON CORRECTAMENTE',
            })
          this.resetMedicamentos();
       
        } 
      });
  }


  public resetMedicamentos (){ 
    this.medicamentoServiceDoctor.contenidoFrasco = "";
    this.medicamentoServiceDoctor.nombreComercial = "";
    this.medicamentoServiceDoctor.nombreDeSalOsustanciaActiva = "";
    this.medicamentoServiceDoctor.presentacio = "";
    this.medicamentoServiceDoctor.viaDeAdministracion = "";
  }

  // seteamos los medicamentos que se obtiene de la api
  setMedicamentos(event: HTMLElement) {


    this.indicaciones.medicmanento = event.textContent;
    this.busuqedaMedicamento = this.indicaciones.medicmanento;
    this.medicamentos = [];


  }


  agregarEstudioItem(estudios :HTMLElement){
  }

  agregarMedicamentosEIndicaciones() {


    if (this.validarOtrasIndicaciones()) {
      let indicaciones = {
        medicamento: this.indicaciones.medicmanento,
        indicaciones: this.indicaciones.indcacionesMedicamento.toLocaleUpperCase(),
      }


      this.recetMedica.medicamentos.push(indicaciones);
      this.busuqedaMedicamento = "";
      this.resetearIndicaciones();

    } else {
      return;

    }
  }


  resetearIndicaciones() {
    this.indicaciones.medicmanento = "";
    this.indicaciones.indcacionesMedicamento = "";
    this.medicamentos = [];
  }

  medicamentosRE(medicamentos, indicaciones) {
    this.indicaciones.medicmanento = medicamentos,
      this.indicaciones.indcacionesMedicamento = indicaciones
  }

  setIDconsulta() {
    this.recetMedica.idConsulta = this.id;
  }

  setIdPaceinte() {
    this.recetMedica.idPaciente = this.paciente._id;
  }

  agregarReceta() {
    this.setIDconsulta();
    this.setIdPaceinte();
  }


  setIds() {
    this.recetMedica.idPaciente = this.paciente._id;
    this.recetMedica.idConsulta = this.id;
  }

  validarOtrasIndicaciones() {

    if (this.indicaciones.indcacionesMedicamento == '') {
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'COMPLETA LAS INDICACIONES MEDICAS',
            })
      /* alert('Completa las indicaciones del medicamnento'); */
      return false;
    }
    return true;
  }

  guardarReceta() {
    this.setIds(); 
    this.setRecetaDataMedico();
    // this.imprimirReverso();
    /* this.imprimirFrente(); */
    this._consultaService.agregarReceta(this.recetMedica)
      .subscribe(data => {
        if (data['ok']) {
          let estado = {
            status: 'Receta'
          }
          this._consultaService.cambiarEstadoConsulta(this.id, estado)
            .subscribe(data => {
              if (data['ok']) {
                // console.log(data);
                          Swal.fire({
                  icon: 'success',
                  title: '',
                  text: 'LA RECETA SE GUARDO CORRECTAMENTE',
                })
                /* alert('Receta guardada'); */
                this._router.navigateByUrl('/bitacora/medicina/general');
              }
            });
        }
      });
  }


  eliminarEstudio( id: number ){

    this.recetMedica.estudios.splice(id, 1);

  }

  // bisca los estudios en al base de datos
  buscarEstudio() {

    if( this.buscarEstudiotxt.estudio.length == 0 ){
        this.estudios = []
    }
    if (this.buscarEstudiotxt.estudio.length > 5) {
    

        this._integradosService.getAllDepartments( this.buscarEstudiotxt )
         .subscribe( data => {

          this.estudios = data['data'][1]

        });


    }
  }

  // agrega los estudio al json y a la interfaz
  agregarEstudio(estudio) {

    this.recetMedica.estudios.push(estudio);
    this.buscarEstudiotxt.estudio = "";
    this.estudios = [];

  }

  imprimirFrente() {

  /*HOJA POR EL FRENTE*/
    let sexo = "";
    if (this.paciente.genero == 'FEMENINO') {
      sexo = 'F';
    } else if (this.paciente.genero == 'MASCULINO') {
      sexo = 'M';
    }
    const doc: any = new jsPDF('p', 'cm', 'letter');
    doc.setFontSize(10);
    doc.text(12.3, 2.2, `${this.fecha.substring(0, 2)}`);
    doc.text(13.5, 2.2, `${this.fecha.substring(3, 5)}`);
    doc.text(14.5, 2.2, `${this.fecha.substring(8, 10)}`);
    doc.text(5.5, 3.0, `${this.paciente.nombre} ${this.paciente.apellidoPaterno} ${this.paciente.apellidoMaterno}`);
    doc.text(17.5, 3.0, `${sexo}`);
    doc.text(20, 3.0, `${this.paciente.edad}`);
    let a = 5.0;
    let b = 5.5;
    let controlPages = 1;
    this.recetMedica.medicamentos.forEach((medicamento: any) => {
      this.recetaPDF.height += 1;
      doc.text(0.5, a, (this.recetaPDF.width, this.recetaPDF.height, `${medicamento.medicamento}`));
      a = a + 1.3;
      this.recetaPDF.height += 1;
      doc.text(0.6, b, (this.recetaPDF.width, this.recetaPDF.height, `${medicamento.indicaciones.toUpperCase()}`));
      b = b + 1.3;
      controlPages = controlPages + 1;
      if (controlPages == 6) {
        doc.addPage();
        doc.text(12.3, 2.2, `${this.fecha.substring(0, 2)}`);
        doc.text(13.5, 2.2, `${this.fecha.substring(3, 5)}`);
        doc.text(14.5, 2.2, `${this.fecha.substring(8, 10)}`);
        doc.text(5.5, 3.0, `${this.paciente.nombre} ${this.paciente.apellidoPaterno} ${this.paciente.apellidoMaterno}`);
        doc.text(17.5, 3.0, `${sexo}`);
        doc.text(20, 3.0, `${this.paciente.edad}`);
        controlPages = 0;
        a = 5.0;
        b = 5.5;
      }
      doc.text("Cédula: " + this.recetMedica.cedula + "/" + this.recetMedica.cedulaEsp, 18, 12.6, 'right');
      doc.text("Doctor: " + this.recetMedica.medicoQueAtendio, 18, 13.2, 'right');
      doc.text("Especialidad: " + this.recetMedica.especialidad, 18, 13.8, 'right');
    });

    /*HOJA POR EL REVERSO*/
    doc.addPage();
    let controlPagesHR = 0;
    doc.text(0.5, 2, `${this.recetMedica.otrasIndicaciones}`);
    var y = 5.5;
    this.recetMedica.estudios.forEach((estudio, i) => {
      y += 1.3;
      doc.text(0.5, y, `${estudio.ESTUDIO}`);
      controlPagesHR = controlPagesHR + 1;
      if (controlPagesHR == 4) {
        doc.addPage();
        doc.text(0.5, 2, `${this.recetMedica.otrasIndicaciones}`);
        y = 5.5;
        controlPagesHR = 0;
      }
      // doc.text("Cédula: " + this.recetMedica.cedula, 18, 12.6, 'right');
      // doc.text("Doctor: " + this.recetMedica.medicoQueAtendio, 18, 13.2, 'right');
    });

    doc.save(this.paciente.nombre + " " + this.paciente.apellidoPaterno);

  }

  // imprimirReverso() {
    
  //   let controlPages = 0;
  //   const doc: any = new jsPDF('p', 'cm', 'letter');
  //   doc.setFontSize(11);
  //   doc.text(0.5, 2, `${this.recetMedica.otrasIndicaciones}`);
  //   var y = 5.5;
  //   this.recetMedica.estudios.forEach((estudio, i) => {
  //     y += 1.3;
  //     doc.text(0.5, y, `${estudio.ESTUDIO}`);
  //     controlPages = controlPages + 1;
  //     if (controlPages == 4) {
  //       console.log("Y QUEDA EN: " + y);
  //       doc.addPage();
  //       doc.text(0.5, 2, `${this.recetMedica.otrasIndicaciones}`);
  //       y = 5.5;
  //       controlPages = 0;
  //     }

  //     doc.text("Cédula: " + this.recetMedica.cedula, 18, 12.6, 'right');
  //     doc.text("Doctor: " + this.recetMedica.medicoQueAtendio, 18, 13.2, 'right');

  //   });
  //   doc.save(this.paciente.nombre + " " + this.paciente.apellidoPaterno + "" + "POR DETRAS");
  //   console.log("CEDULA DOCTOR: " + this.recetMedica.cedula);
  //   console.log("MEDICO: " + this.recetMedica.medicoQueAtendio);
  // }


  /*setRecetaDataMedico() {
    this.recetMedica.medicoQueAtendio = this.medico.nombre;
    this.recetMedica.idMedico = this.medico.idMedico;
    this.recetMedica.cedula = this.medico.cedulaProfesional;
    this.recetMedica.fechaReceta = this.fecha;
    this.recetMedica.horaEgreso = moment().format('LT');
    this.recetMedica.origen = CEDE;
  }*/


}
