import { Component, Input, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { EsquemaVacun } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-esquema-vacunacion',
  templateUrl: './esquema-vacunacion.component.html',
  styleUrls: ['./esquema-vacunacion.component.css']
})
export class EsquemaVacunacionComponent implements OnInit {

  @Input() _id='';
  @Input() esquemaVacuncaion:EsquemaVacun = {} as EsquemaVacun;
  
  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarVacunacionNinos(form: NgForm) {
    this.esquemaVacuncaion = form.value
    this.esquemaVacuncaion.idPaciente = this._id;
    this._HistoriaClinicaService.agregarEsquemaVacunacion( this.esquemaVacuncaion )
    .subscribe((data:any) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE GUARDARDO EL ESQUEMA DE VACUNACION',
        })
      }
    });
  }

  actualizarEsquemaVacunacion(form: NgForm){
    let id = this.esquemaVacuncaion._id
    this.esquemaVacuncaion = form.value
    this.esquemaVacuncaion._id = id;
    this.esquemaVacuncaion.idPaciente = this._id;
    this._HistoriaClinicaService.actualizarEV(this.esquemaVacuncaion._id, this.esquemaVacuncaion)
    .subscribe((data:any) => {
      if(data['ok']){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZO EL ESQUEMA DE VACUNACION',
        })
      }
    })
  }

}
