import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { antecedentesDisp, 
         antecedentesPersonales,
         antecedentesPatologicos,
         practicasdeRiesgo,
         ultimoAnio,
         medicamentos,
         fechaMayor} from '../../../../functions/bancoSangre';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'historia-clinica-disp',
  templateUrl: './historia-clinica.component.html',
  styleUrls: ['./historia-clinica.component.css']
})
export class HistoriaClinicaComponent implements OnInit {

  public paciente={
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
    religion:''
  }

  public signos={
    diastolica: 0,
    fecha: "",
    huellasMultiples: "",
    huellasRecientes: "",
    imc: 0,
    peso: 0,
    signosvitales: "",
    sistolica: 0,
    talla: 0,
    temp:0
  }

  public idCensur;

  public geograficos={};
  public antecendent={};
  public antecedentPersonales={};
  public antecedentPato={};
  public antecedetGineco={};
  public medicament={};
  public riesgos={};
  public parejasSex={};
  public ultimoAnio={};
  public fecha;
  public respuesta={
    diferido: [{
      fechafinal: "",
      fechainicio: "",
      motivo: "",
      tiempoDiferir: "",
    }],
    diferir: false,
    proceso: "",
    respuestas: 0
  }
  

  constructor(private activatedRoute:ActivatedRoute,
              private _banco:BancoService,
              private _router: Router) { }

  ngOnInit(): void {
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDisponente(this.idCensur);
  }

  getDisponente(id){
    this._banco.getDisponente(id).subscribe((resp:any)=>{
      /* this.paciente = resp.data.paciente */
      this.setPaciente(resp.data.paciente);
      this.signos = resp.data.signosvitales
    })
  }

  //LOADING
  pintLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
    /* console.log("toy funcionando weeeee"); */
    
  }

  removeLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
    /* console.log('dejo de funcionar'); */
    
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

  indicadores(event){
    this.geograficos = event;   
  }
  antecedentes(event){
    this.antecendent = event;
  }
  antecedentesPersonales(event){
    this.antecedentPersonales = event;
  }

  antecedentespato(event){
    this.antecedentPato = event;
  }
  antecedentesgineco(event){
    this.antecedetGineco = event;
  }
  riesgo(event){
    this.riesgos = event;
  }
  
  parejasSexuales(event){
    this.parejasSex = event;
  }
  
  ultimo(event){
    this.ultimoAnio = event;
  }
  
  medicamentos(event){
    this.medicament = event;
  }

  ageragrHistoria(){
    this.pintLoadingScreen();
    this.fecha = moment().format('l');
    let arreglo = [];
    let validacionAntecedentes,
        validacionAntecedentesPers,
        validacionAntecedentesPato,
        validacionPracticasRiego,
        validacionUltimoAnio,
        validacionGineco,
        validacionMedicamentos; 
    /////////validacion de indicadores geograficos////////////////
    /* if(this.validar(this.geograficos)){
      let validacionIndicadores = indicadoesGeo(this.fecha,this.geograficos);
      console.log(validacionIndicadores);
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa los indicadores geográficos',
      })
    } */
    /////////fin de la validacion de indicadores geograficos////////////////

    /////////validacion de antecedentes////////////////
    if(this.validar(this.antecendent)){
      validacionAntecedentes = antecedentesDisp(this.fecha, this.antecendent);
      if(validacionAntecedentes.diferir){
        arreglo.push(validacionAntecedentes.diferido[0]);
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa la seccion de antecedentes',
      })
    }
    /////////fin de la validacion de antecedentes////////////////

    /////////validacion de antecedentes personales////////////////
    if(this.validar(this.antecedentPersonales)){
      validacionAntecedentesPers = antecedentesPersonales(this.fecha, this.antecedentPersonales);
      if(validacionAntecedentesPers.diferir){
        arreglo.push(validacionAntecedentesPers.diferido[0]);
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa la seccion antecedentes personales',
      })
    }
    /////////fin de la validacion de antecedentes personales////////////////

    /////////validacion de antecedentes personales////////////////
    if(this.validar(this.antecedentPato)){
      validacionAntecedentesPato = antecedentesPatologicos(this.fecha, this.antecedentPato);
      if(validacionAntecedentesPato.diferir){
        arreglo.push(validacionAntecedentesPato.diferido[0]);
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa la seccion antecedentes personales',
      })
    }
    /////////fin de la validacion de antecedentes personales////////////////

    /* /////////validacion de antecedentes ginecoobstetricos////////////////
    console.log(this.paciente.genero);
    
    if(this.validar(this.antecedetGineco)){
      validacionGineco = antecedentesPatologicos(this.fecha, this.antecedetGineco);
      if(validacionGineco.diferir){
        arreglo.push(validacionGineco.diferido[0]);
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa la seccion antecedentes personales',
      })
    }
    /////////fin de la validacion de antecedentes ginecoobstetricos//////////////// */

    /////////validacion de practicas de riesgo////////////////
    if(this.validar(this.riesgos)){
      validacionPracticasRiego = practicasdeRiesgo(this.fecha, this.riesgos);
      if(validacionPracticasRiego.diferir){
        arreglo.push(validacionPracticasRiego.diferido[0]);
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa la seccion practicas de riesgo',
      })
    }
    /////////fin de la validacion de practicas de riesgo////////////////

    /////////validacion de ultimo año////////////////
    if(this.validar(this.ultimoAnio)){
      validacionUltimoAnio = ultimoAnio(this.fecha, this.ultimoAnio);
      if(validacionUltimoAnio.diferir){
        arreglo.push(validacionUltimoAnio.diferido[0]);
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Completa la seccion en el ultimo año',
      })
    }   
    /////////fin de la validacion de ultimo año////////////////

    /////////Validacion de los medicamentos////////////////////
    if(this.validar(this.medicament)){
      validacionMedicamentos = medicamentos(this.fecha, this.ultimoAnio);
      if(validacionMedicamentos.diferir){
        arreglo.push(validacionMedicamentos.diferido[0]);
      }
    }
    /////////Fin de validacion de los medicamentos////////////////////
    if(arreglo.length == 0){
      this.respuesta={
        diferido: [],
        diferir: false,
        proceso: "",
        respuestas: 0
      }
    }else{
      let buscarDiferido = fechaMayor(arreglo)
      let diferido = arreglo.find( element => element.motivo === buscarDiferido.motivo);
      this.respuesta = {
        respuestas:0,
        diferido: [],
        diferir: true,
        proceso: 'HISTORIA CLINICA'
      }
      this.respuesta.diferido.push(diferido)
    }
    
  }

  enviar(resp){
    let id = {
      idbancosangre:this.idCensur
    }
    let historia = Object.assign( id,
                                  this.geograficos, 
                                  this.antecendent, 
                                  this.antecedentPersonales, 
                                  this.antecedentPato,
                                  this.antecedetGineco,
                                  this.riesgos,
                                  this.parejasSex,
                                  this.ultimoAnio,
                                  this.medicament);
                                    
    if(resp.diferir){
      let fecha = new Date(resp.diferido[0].fechafinal);
        let dif ={
            idbanco:this.idCensur,
            proceso:resp.proceso, 
            estatus:'DIFERIDO',
            motivo:resp.diferido[0].motivo,
            rechazo:resp.diferido[0].tiempoDiferir,
            fechaRechazo:fecha
        }
        this._banco.diferirDisponente(dif).subscribe((resp:any)=>{
          /* console.log(resp); */
          this.removeLoadingScreen();
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'EL DISPONENTE SE DIFERIO CORRECTAMENTE',
          })
          this._router.navigate([ `/dashboard`]);
        })
    }else{
      this._banco.historiaClinica(historia).subscribe((resp:any)=>{
        if (resp.ok) {
          this.removeLoadingScreen();
          this._router.navigateByUrl('/hoja/evolucion/disponente/'+this.idCensur)
        }
      })
    }
  }

  validar(form){
    if(Object.keys(form).length != 0){
      return true;
    }else{
      return false;
    }
  }
}
