import { Component, OnInit } from '@angular/core';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-bitacora-consulta-disponentes',
  templateUrl: './bitacora-consulta-disponentes.component.html',
  styleUrls: ['./bitacora-consulta-disponentes.component.css']
})
export class BitacoraConsultaDisponentesComponent implements OnInit {

  public pacientes:any [] = [];

  constructor(private _banco:BancoService) { }

  ngOnInit(): void {
    this.getBitacora();
  }

  getBitacora(){
    this._banco.bitacoraDoctor().subscribe((resp:any)=>{
      this.pacientes = resp['data'];
      this.pacientes.reverse();
    })
  }

}
