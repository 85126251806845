<div class="content">
    <form #form='ngForm'>
        <div class="row">
            <div class="col">
                <p>¿HA TENIDO MAS DE DOS PAREJAS SEXUALES?</p>
            </div>
            <div class="col">
                <input type="text" name="parejas_sexuales" ngModel (blur)="formularioParejas(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>NUMERO EN EL ÚLTIMO AÑO</p>
            </div>
            <div class="col">
                <input type="text" name="ultimo_año_de_parejas" ngModel (blur)="formularioParejas(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>NUMERO EN LOS ÚLTIMOS 5 AÑOS</p>
            </div>
            <div class="col">
                <input type="text" name="ultimos_cinco_años_de_parejas" ngModel (blur)="formularioParejas(form)">
            </div>
        </div>
    </form>
</div>
