<div class="container pa">
  <input #nombre (keyup)="buscarPaciente(nombre.value)" type="text" name="busquedaPaciente"
  class="form-control input-search" id="busquedaPaciente" placeholder="Buscar paciente...">
  <div class="row">
    <div class="col-10">
      <table class="col-10">
        <tr *ngFor="let item of AllPacientes">
          <td class="puntero" (click)="SelectPac( item )">
            <strong>
              {{ item.nombrePaciente }} {{ item.apellidoPaterno }} {{ item.apellidoMaterno }}
            </strong>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
      <div class="col-md-8">
          <app-titulos [title]="'ACTUALIZAR PACIENTE'" [rol]="'Registro'"></app-titulos>
      </div>
  </div>
  <app-actualizar-paciente></app-actualizar-paciente>
</div>
  