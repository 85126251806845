<div class="container">
    <section>
        <app-titulos [title]="'HOJA DE EVOLUCIÓN'" [rol]="'doctor'"></app-titulos>
        <!-- <app-ficha-doctor [titulo]="'HOJA DE EVOLUCIÓN'" [pacienteInfo]="paciente"></app-ficha-doctor> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'doctor'"></app-fichas-identificacion>
    </section>

    <div class="card" id="historia">
        <div role="tabpanel" class="row">
            <div class="col-6" id="indicadoress">
                <ul class="nav flex-column nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#laboratorios" aria-controls="#laboratorios"> 
                            BIOMETRIA HEMATICA COMPLETA
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#exploracionFisica" aria-controls="#exploracionFisica"> 
                            EXPLORACIÓN FISICA 
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#diagnostico" data-toggle="tab" aria-controls="#diagnostico"> 
                            DIAGNÓSTICO 
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <div class="tab-content">
                    <div role="tabpanel" class="tab-pane active" id="laboratorios">
                        <app-laboratorio-disp (editLab)="editLab($event)"></app-laboratorio-disp>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="exploracionFisica">
                        <app-exploracion-fisica (exploracionFisica)="exploracionFisica($event)"></app-exploracion-fisica>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="diagnostico">
                        <app-diagnostico-disponente (diagnostic)="diagnostic($event)"></app-diagnostico-disponente>
                        <div class="boton">
                            <button class="principalButton" (click)="enviar()"> GUARDAR </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="display-none" id="sppiner-section">
        <div class="row">
            <div class="sppiner-bg">
                <div class="spinner-stock">
                    <div class="col-md-12">
                        <app-sppiner></app-sppiner>
                    </div>
                </div>

            </div>
        </div>
    </section>

</div>