import { Component, OnInit } from '@angular/core';
import {MedicamentosService} from '../../../services/farmacia/medicamentos.service'
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-listado-medicamentos',
  templateUrl: './listado-medicamentos.component.html',
  styleUrls: ['./listado-medicamentos.component.css']
  
})
export class ListadoMedicamentosComponent implements OnInit {

  public medicamentos: any [];
  public pagina = 0;
  public totalpaciente:string;
  public buscar;
  constructor( private _medicamentoService:MedicamentosService) { }

  ngOnInit(): void {
    this.listarMedicamentos()
    //this.typeSearchMedicamentos();
  }

  listarMedicamentos( ){
    this._medicamentoService.obtenermedicamento().subscribe(
      (data) => {
        this.medicamentos=data['data'];
        this.totalpaciente = data['data'].results;
      } 
      ) 
     }

  eliminarMedicamento( id ){
      //  console.log( id)
    Swal.fire({title: "¿Estas seguro de eliminar el medicamento?",
    text: "Una vez que se haya eliminado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
        .then((result) => {
      if (result.value) {
        this._medicamentoService.eliminarMedicamento(id).subscribe((resp:any) => {
          if(resp.ok){
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'EL SERVICIO SE ELIMINO CORRECTAMENTE',
            })
            this.listarMedicamentos()
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'EL MEDICAMENTO NO SE ELIMINO',
            })
        } 
    })

  }
 
        typeSearchMedicamentos(){
    
          if( this.buscar.length > 3 ){
            this._medicamentoService.agragarmedicamentos( this.medicamentos )
            .subscribe(  data => {
              this.medicamentos= data['data'];
              // console.log(this.medicamentos);
              
            });

          }else if(this.medicamentos){
            this.listarMedicamentos();
            this.focusSearchMedicamentos();
          }
      
        }

        focusSearchMedicamentos(){
          const input: HTMLInputElement = document.querySelector('#busquedaMedicamento');
          input.select();
        }

}