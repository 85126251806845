<div class="container-fluid" style="height: 40rem;">
    <h1 class="centerxd">LISTADO DE SERVICIO {{servicios | uppercase}}</h1>
    <div class="row">
        <div class="col-md-8">
            <!-- <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar servicio" class="form-control" style="max-width:100em">
            </div> -->
            <select name="sedes" id="sedes" class="form-control" #rango (change)="Rango(rango.value)">
                <option   value="" selected> Selecciona Rango</option>
                <option value="A"  name="rangoA">A</option>
                <option value="B"  name="rangoA">B</option>
                <option value="C"  name="rangoA">C</option>
            </select>
        </div>
        <div class="col-md-4">
        </div>
    </div>

    <div class="col-xs-12" id="tabla" style="overflow-x: scroll; border-radius: 30px;">
        <div class="card" style="border-radius: 30px;">
            <div class="card-heder header" style="width: 130%;">
                <div class="row">
                    <div class="col text_header">
                        <p>#</p>
                    </div>
                    <div class="col-md-1 text_header">
                        <p>DESTINO</p>
                    </div>
                    <div class="col text_header">
                        <p>PUBLICO DIA</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>MEMBRESÍA DIA</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>PUBLICO NOCHE</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>MEMBRESÍA NOCHE</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div> 
                    <div class="col text_header">
                        <p>PUBLICO REDONDO DIA</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>MEMBRESÍA REDONDO DIA</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div> 
                    <div class="col text_header">
                        <p>PUBLICO REDONDO NOCHE</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div> 
                    <div class="col text_header">
                        <p>MEMBRESÍA REDONDO NOCHE</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div> 
                    <div class="col text_header">
                        <p style="margin-right: 15px;">ACCIONES</p>
                    </div>
                </div>

            </div>

            <div class="card-body" style="background-color: #ffffff; width: 130%;">
                <div *ngFor="let item of servicioSI | paginate:{id:'listaAmbulancia',
                itemsPerPage: 7,
                currentPage:pagina,
                totalItems:totalAmbulancia}; let i=index">
                    <div class="row">
                        <div class="col">
                            <p>{{i+1}}</p>
                        </div>
                        <div class="col-md-1">
                            <p style="overflow-wrap: break-word;">{{item.DESTINO}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_PUBLICO_DIA}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO_DIA")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_PUBLICO_DIA) * socioUtilidad(item._id,"PRECIO_PUBLICO_DIA")) / 100 | currency}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_MEMBRESIA_DIA}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_MEMBRESIA_DIA")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_MEMBRESIA_DIA) * socioUtilidad(item._id,"PRECIO_MEMBRESIA_DIA")) / 100 | currency}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_PUBLICO_NOCHE}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO_NOCHE")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_PUBLICO_NOCHE) * socioUtilidad(item._id,"PRECIO_PUBLICO_NOCHE")) / 100 | currency}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_MEMBRESIA_NOCHE}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_MEMBRESIA_NOCHE")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_MEMBRESIA_NOCHE) * socioUtilidad(item._id,"PRECIO_MEMBRESIA_NOCHE")) / 100 | currency}}</p>
                        </div> 
                        <div class="col">
                            <p>{{item.PRECIO_PUBLICO_REDONDO_DIA}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO_REDONDO_DIA")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_PUBLICO_REDONDO_DIA) * socioUtilidad(item._id,"PRECIO_PUBLICO_REDONDO_DIA")) / 100 | currency}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_MEMBRESIA_REDONDO_DIA}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_MEMBRESIA_REDONDO_DIA")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_MEMBRESIA_REDONDO_DIA) * socioUtilidad(item._id,"PRECIO_MEMBRESIA_REDONDO_DIA")) / 100 | currency}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_PUBLICO_REDONDO_NOCHE}}</p>
                        </div>
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO_REDONDO_NOCHE")}}%</p>
                        </div>
                        <div class="col">
                            <p>{{(precios(item.PRECIO_PUBLICO_REDONDO_NOCHE) * socioUtilidad(item._id,"PRECIO_PUBLICO_REDONDO_NOCHE")) / 100 | currency}}</p>
                        </div>
                        <div class="col">
                            <p>{{item.PRECIO_MEMBRESIA_REDONDO_NOCHE}}</p>
                        </div> 
                        <div class="col">
                            <p>{{socioUtilidad(item._id,"PRECIO_MEMBRESIA_REDONDO_NOCHE")}}%</p>
                        </div> 
                        <div class="col">
                            <p>{{(precios(item.PRECIO_MEMBRESIA_REDONDO_NOCHE) * socioUtilidad(item._id,"PRECIO_MEMBRESIA_REDONDO_NOCHE")) / 100 | currency}}</p>
                        </div> 
                        <div class="col">
                            <div class="row">
                            <!--  <div class="col-xs-1" style="width:20px; height:20px"> -->
                                    <!-- <label class="btn btn-outline-success" (click)="editar()"> -->
                                        <i class="fas fa-edit" (click)="editar(item._id)" style="color: green;"></i>
                                    <!-- </label> -->
                                <!-- </div> -->
                                <!-- <div class="col-xs-1">
                                    <label class="btn btn-outline-danger" style="margin-left: 1rem;" (click)="eliminar()"> -->
                                        <i class="fas fa-trash-alt" (click)="eliminar(item._id)" style="color: red; margin-left: 10px;"></i>
                                    <!-- </label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event" style="width: 130%;"></pagination-controls>
                </div> 
            </div>    
        </div>
    </div>
</div>
