import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { PacientesService  } from '../../../services/pacientes/pacientes.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit {

  public consultas:any [] = [];
  public noSe=[];
  public pagina = 0;
  public totalpaciente:string;
  public imprimir = {
    indice: 0,
    fecha: '',
    nombre:  '',
    genero: '',
    edad: '',
    medico: '',
  }

  public listaEspera = [];
  public getPacienteSotageX = this.listaEspera;
  public filterPost = ''
  public filterNombre = '';
  public sede = "";

  constructor(private spinner: NgxSpinnerService,
    private _consultasService: ConsultaService,
  ) { }

  ngOnInit(): void {
    this.obtenerSede();
    this.obtenerConsultas();
    this.spinner.show();
  }

  obtenerSede() {
    this.sede = JSON.parse(localStorage.getItem('cede'));  

  }

  obtenerConsultas(){
    this._consultasService.verConsultasRecepcion(this.sede)
    .subscribe( (data:any) => {
      if(data.ok) {
        this.setconsultas( data['data']);
        this.totalpaciente = data['data'].results;
      }
    });
  }

  setconsultas( consultas ){
    consultas.forEach(element => {
      if(element.paciente != null){
        this.consultas.push(element)
      }
    });
    // this.consultas.reverse();
    this.spinner.hide();
    /* this.setImp(this.consultas); */
  }

  /* setImp(cons){    
    let i= 0;
    for (let element of cons) {  
      //console.log(element);
        
      this.imprimir.indice= i + 1;
      this.imprimir.fecha = element.fechaIngreso;
      this.imprimir.nombre = element.paciente.nombrePaciente;
      this.imprimir.genero = element.paciente.genero;
      this.imprimir.edad = element.paciente.edad;
      this.imprimir.medico = element.medicoTrante;
      //console.log(this.imprimir.indice);
      //console.log(this.imprimir);
      
      this.noSe.push(this.imprimir)
      this.imprimir = {
        indice: 0 ,
        fecha: '',
        nombre:  '',
        genero: '',
        edad: '',
        medico: '',
      }
      i++
    }    
  } */

  

  imp(){
    const url = "http://localhost:3200/descarga/pdf/historialPacientes";
    window.open(url, "_blank");
    // console.log(this.noSe);
    
    // let values: any;
    // values = this.noSe.map((elemento) => Object.values(elemento));
    
    // console.log(values);
    
    // const doc:any = new jsPDF();

    // doc.autoTable({
    //   head: [['#', 'Fecha', 'Nombre', 'Género', 'Edad', 'Médico Tratante']],
    //   body: values
    // })

    // doc.save('Historico_De_Pacientes.pdf')
  }

}
