import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import {  Router } from '@angular/router';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
@Component({
  selector: 'app-nuevo-estudio-lab',
  templateUrl: './nuevo-estudio-lab.component.html',
  styleUrls: ['./nuevo-estudio-lab.component.css']
})
export class NuevoEstudioLabComponent implements OnInit {

  
  ngOnInit(): void {
    this.creacioDeForm();
  }


  public  newValorDeReferencia:any;
  // public newFormValor:any;
  public formNewLab: any;
  public newFormValor:any;
  public btnAgregar = false;

  constructor(
    private formBuilder: FormBuilder,
    private _labService: LaboratorioService,
    private _banco:BancoService,
    private _router: Router
    ) {


     } 
    

     // fila
    get valoresDeReferencias() {
      return this.newFormValor.get('valores') as FormArray;
    }
  
    // input de valores de referencia
    get valoresDeHombreYMujer() {
     return this.newFormValor['controls'].valores['controls'] as FormArray;
    }

  creacioDeForm() {

    this.newFormValor =  this.formBuilder.group({ 
       nombreEstudio: ['', { validators: [Validators.required] }],
       tipoExamen: ['', {validators: [Validators.required]}],
       valores: this.formBuilder.array([]),
       observaciones: ['']
     });
     
     this.addValoreDeReferencia();
  }


  agregarValoresReferenciaD(index: number) {

    const masValores = this.formBuilder.group({
      valorReferenciaD : ''
    });
    // console.log(     )

    if(this.newFormValor['controls']['valores']['controls'][index]['controls']['valorDeReferencia'].length ) {
      this.newFormValor['controls']['valores']['controls'][index]['controls']['valorDeReferencia'].push(masValores);
    }

  }


  addValoreDeReferencia() {

    const valoresInput =  this.formBuilder.group({
      grupo:'',
      elemento: '',
      valorDeReferencia: this.formBuilder.array([
        this.formBuilder.group({
          valorReferenciaD: ''
        })
      ]),
      metodo: ''
    });

    this.valoresDeReferencias.push( valoresInput );
    
  }

  removeValores( i: number ){

    if( this.valoresDeReferencias.length  > 1 ) {
      this.valoresDeReferencias.removeAt(i);
    }
}

    //  creacion del formulario dinamico

    


    validatefields(){
      this.btnAgregar = false;
    }


  sendDataPost() {


    //this._labService.addANewEstudioCensur( this.newFormValor.value )
    this._banco.agregarnuevosestudios( this.newFormValor.value )
    .subscribe( (data:any) => {
      this.btnAgregar = true;

      if( data.ok ) {
        this._router.navigateByUrl('/');
      }

    })

  }

}
