import { Component, Input, OnInit } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute } from '@angular/router';
import { getCarritoStorage } from '../../../../functions/pacienteIntegrados';
import { FormBuilder } from '@angular/forms';
import { getDataStorage } from '../../../../functions/storage.funcion';
import PacienteStorage from 'src/app/classes/pacientes/pacientesStorage.class';
import Carrito from '../../../../classes/carrito/carrito.class';
import { AgendaService } from 'src/app/services/agenda/agenda.service';

@Component({
  selector: 'app-agendas',
  templateUrl: './agendas.component.html',
  styleUrls: ['./agendas.component.css']
})
export class AgendasComponent implements OnInit {

  @Input() serviceSi: any[] = [];
  public ambulanciaSI: any[] = [];
  public totalAmbulancia: string;
  //forma: FormGroup;
  public buscar = {
    estudio: ''
  }
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showAmbulanacia = false;
  @Input() showVista = true;
  public searching = false;
  public servicios = "";

  public membresia = false;
  public preciosMembresia = [];

  public RoleUser = "";
  public service: any[] = [];

  public carritoMembresia = {
    items: [],
    total: 0
  }
  public agenda: any = {
    _id: "",
    idPaciente: {
      sede: ""
    },
    idPersonal: "",

    agendo: {
      sede: ""
    },
    status: "",
    color: "",
    idServicio: {
      ESTUDIO: "",
      PRECIO_MEMBRESIA: "",
      PRECIO_MEMBRESIA_HOSPITALIZACION: "",
      PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA: "",
      PRECIO_MEMBRESIA_URGENCIA: "",
      PRECIO_PUBLICO: "",
      PRECIO_PUBLICO_HOSPITALIZACION: "",
      PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA: "",
      PRECIO_PUBLICO_URGENCIA: "",
      name: "",
    },
    motivo: "",
    title: "",
    start: "",
    end: "",
    fechaInicio: "",
    fechaFin: ""
  }
  public paciente: any;


  obtenerPacienteYMembresia() {
    const usuarioMembresia = new PacienteStorage();
    this.paciente = usuarioMembresia.verPacienteConMembresia();

    /* if (this.paciente == null || this.paciente.membresiaHabilitada == false) {
      this.getservice();
    } else {
      this.membresia = usuarioMembresia.verMembresiaPaciente();
      this.getservice();
    } */

  }

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };


  constructor(private _service: IntegradosService, private _router: Router, private activatedRoute: ActivatedRoute, private _fb: FormBuilder, private _AgendaService: AgendaService) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    /* console.log(this.serviceSi); */
    this.GetAgenda()
    // console.log(this.servicios);
    this.obtenerRoleStorage();
    this.obtenerCarritoStorage();
    //this.obtenerPacienteYMembresia();
  }


  obtenerCarrito() {

    this.carrito = getCarritoStorage();

    if (this.carrito == null) {
      this.carrito = {
        totalSin: 0,
        totalCon: 0,
        items: []
      };
    }

  }

  obtenerRoleStorage() {
    this.RoleUser = getDataStorage().role;
    // console.log( this.RoleUser );
  }

  obtenerCarritoStorage() {
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  busquedaGeneral() {
    if (this.buscar.estudio.length > 3) {
      this._service.getAllDepartments(this.buscar)
        .subscribe((res: any) => {
         this.serviceSi = []
          // console.log( res );
          if (res.data[0] != 0) {
            this.ambulanciaSI = res.data[0];
            this.showAmbulanacia = true;
            this.showTableAmbulanacia = false;
          } else {
            this.serviceSi = res.data[1];
            this.searching = true;
          }
        })
    }
    if (this.buscar.estudio == '') {
      this.searching = false;
      this.showAmbulanacia = false;
      this.showTableAmbulanacia = true;
      this.obtenerCarritoStorage();
    }
  }

  GetAgenda() {
    this._AgendaService.getAgenda()
      .subscribe((resp: any) => {
         this.agenda = resp['data']
         let usuario = JSON.parse(localStorage.getItem('paciente'))._id
         this.serviceSi = []
         this.agenda.forEach(element => {
           if (element.idPaciente._id == usuario && element.status == 'PENDIENTE') {
             this.serviceSi.push(element.idServicio)
            }  
          });
      })
  }

}
