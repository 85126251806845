import { Component, Input, OnInit } from '@angular/core';
import { FichaInfoCensur } from 'src/app/classes/ficha-info-paciente';

@Component({
  selector: 'app-titulos',
  templateUrl: './titulos.component.html',
  styleUrls: ['./titulos.component.css']
})
export class TitulosComponent implements OnInit {

  @Input() data:FichaInfoCensur;
  @Input() rol='';
  @Input() title=''

  constructor() { }

  ngOnInit(): void {
  }

}
