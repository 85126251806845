<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="titulo"></app-titulos>
        </div>
    </div>

    <div class="row" style="margin-bottom: 25px;">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>DESCARGAR</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                        <div class="row pointer puntero" *ngFor="let item of estudiosLab; let i = index">
                            <div class="col-md-1">
                                <p>{{ i + 1 }}</p>
                            </div>
                            <div class="col-md-2">
                                <p>{{ item.fechaPedido | date: 'dd/MM/yyyy':'UTC' }}</p>
                            </div>
                            <div class="col">
                                <p [routerLink]="['/final/obtenidos', item._id]">{{ paciente.nombrePaciente }} {{ paciente.apellidoPaterno }} {{ paciente.apellidoMaterno }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ paciente.edad }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ paciente.genero }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.sede }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.idServicio.ESTUDIO }}</p>
                            </div>
                            <div class="col">
                                <a>
                                    <i class="fa fa-print" id="ver" (click)="obtenerVAlores(item)"></i>
                                </a>
                                <!-- <a (click)="descargarEndos(item.resultados[0].obtenidos.imgs[0].base,item.resultados[0].obtenidos.imgs[0].name)" *ngIf="nombreServicio == 'endoscopia'">
                                    <i class="fa fa-print" id="ver"></i>
                                </a> -->
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="estudiosUsg.length != 0">
        <div class="col-md-12">
            <app-titulos [title]="titulo2"></app-titulos>
        </div>
    </div>

    <div class="row" *ngIf="estudiosUsg.length != 0" style="margin-bottom: 20px;">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTUDIO</p>
                        </div>
                        <div class="col text_header">
                            <p>DESCARGAR</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                        <div class="row pointer puntero" *ngFor="let item of estudiosUsg; let i = index">
                            <div class="col-md-1">
                                <p>{{ i + 1 }}</p>
                            </div>
                            <div class="col-md-2">
                                <p>{{ item.fechaPedido | date: 'dd/MM/yyyy':'UTC' }}</p>
                            </div>
                            <div class="col">
                                <p>{{ paciente.nombrePaciente }} {{ paciente.apellidoPaterno }} {{ paciente.apellidoMaterno }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ paciente.edad }}</p>
                            </div>
                            <div class="col-md-1">
                                <p>{{ paciente.genero }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.sede }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.idServicio.ESTUDIO }}</p>
                            </div>
                            <div class="col">
                                <a (click)="obtenerVAlores(item)" *ngIf="nombreServicio != 'endoscopia'">
                                    <i class="fa fa-print" id="ver"></i>
                                </a>
                                <!-- <a (click)="descargarEndos(item.resultados[0].obtenidos.imgs[0].base,item.resultados[0].obtenidos.imgs[0].name)" *ngIf="nombreServicio == 'endoscopia'">
                                    <i class="fa fa-print" id="ver"></i>
                                </a> -->
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>
