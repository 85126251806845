import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';import { ActivatedRoute } from '@angular/router';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';


@Component({
  selector: 'app-hoja-reporte-ultrasonidos',
  templateUrl: './hoja-reporte-ultrasonidos.component.html',
  styleUrls: ['./hoja-reporte-ultrasonidos.component.css']
})
export class HojaReporteUltrasonidosComponent implements OnInit {

  @Input() nameEstudie = "";
  @Output() dataDonanteEmit = new EventEmitter();

  public dataReportEstudies = {
    interpretacion: "",
    diagnostico:"",
    observaciones: "",
    realizo:"",
    cedula:"",
    especilidad:"",
    imgs:[]
  }

  public nombre :String
  public id = '';
  public imagenes = [];
  
  constructor(private _routeservi : IntegradosService,
              private _route: ActivatedRoute,
              private _servicios : ServiciosService) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('idUltra');
    this._servicios.title$.subscribe( ((title: String) => {
      this.nombre = title;
    })) 
    this.obtenerUSG();   
  }

  obtenerUSG(){
    this._routeservi.getServicioById(this.id)
    .subscribe((data:any) => {
      this.dataReportEstudies.interpretacion = data['data'].ELEMENTOS[0].machote
    })
  }

  //Archivos del DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[0].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }

  onRemove(event) {
    this.imagenes.splice(this.imagenes.indexOf(event), 1)
    this.files.splice(this.files.indexOf(event), 1);
  }

  // onSelect(event){
  //   /* console.log(event); */
  //   this.files.push(...event.addedFiles);
  //   this.dataReportEstudies.imgs.push( ...event.addedFiles );
  //   console.log( this.dataReportEstudies )
  
  //   /* console.log(this.files); */
  
     // const formData = new FormData();
     // this.files.forEach(img => {
       //   console.log(img);

     //     formData.append("name", img, img.name)
     // });
     
     
     //     for (var i = 0; i < this.files.length; i++) { 
    //       formData.append("name", this.files[i],this.files[i].name);
    //       console.log(formData);
    
    //     }
    // console.log(this.files);

    
    // }

    // onRemove(event){
      //   /* console.log(event); */
  //   this.files.splice(this.files.indexOf(event), 1);
  
  // }
  //Fin Archivos DROPZONE
  

  sendResultsData () {
    // seteo de los datos de los resultados 
    this.dataReportEstudies.imgs = this.imagenes
    if(JSON.parse(localStorage.getItem('usuario')).medico != null || JSON.parse(localStorage.getItem('usuario')).medico != undefined){
      this.dataReportEstudies.realizo = JSON.parse(localStorage.getItem('usuario')).medico
    }else{
      this.dataReportEstudies.realizo = JSON.parse(localStorage.getItem('usuario')).nombre  
    }
    this.dataReportEstudies.cedula = JSON.parse(localStorage.getItem('usuario')).cedulaProfesional
    this.dataReportEstudies.especilidad = JSON.parse(localStorage.getItem('usuario')).cedulaEsp
    // console.log( this.dataReportEstudies )
    //emit de los datos
    this.dataDonanteEmit.emit( this.dataReportEstudies);
    localStorage.removeItem('idUltra')
  }

  }
  