import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-habitaciones-pacientes',
  templateUrl: './habitaciones-pacientes.component.html',
  styleUrls: ['./habitaciones-pacientes.component.css']
})
export class HabitacionesPacientesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
