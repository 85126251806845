import { Component, OnInit } from '@angular/core';
import { IntegradosService, Ambulancia } from '../../../services/servicios/integrados.service';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-formulario-ambulancia',
  templateUrl: './formulario-ambulancia.component.html',
  styleUrls: ['./formulario-ambulancia.component.css']
})
export class FormularioAmbulanciaComponent implements OnInit {

  public ambulancia: Ambulancia;
  public  id:string;
  public status: string;
  public page_title: string;

  constructor(private _integradosService: IntegradosService, private _router: Router, private _route: ActivatedRoute) {
      this.ambulancia = new Ambulancia('','','','','','','','','');
      this.page_title =  "Nuevo Destino Servicio de Ambulancia";
  }

  ngOnInit():void{
    this.id = this._route.snapshot.paramMap.get('servicio');
    // console.log(this.id);
  }

  onSubmit(){
    this._integradosService.create(this.ambulancia,this.id).subscribe(
      res => {
        if(res.ok){
          this.status = 'ok';
          this.ambulancia = res.ambulancia;
          this._router.navigateByUrl('serviciosInt/ambulancia');
        }else{
          this.status = 'error';
        }
      },
      err => {
        /* console.log(err); */
        this.status = 'error'; 
      }
    )
  }
}
