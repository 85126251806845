<form action="">
    <div class="container">
        <h1 class="center">Reporte Ultrasonido</h1>

        <div class="col-md">
            <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

        </div>

        <div class="row center" *ngFor="let ultrasonido of ultrasonidos">
            <div class="card" style="width: 1200px;">
                <div class="form-group row">
                    <h4 class="col" style="margin-left: 1rem;">
                        Nombre del Estudio: {{ultrasonido.nombre}}
                    </h4>
                </div>
            </div>

            <div class="card mt-3">
                <!-- onkeyup="document.getElementById(this.id).value=document.getElementById(this.id).value.toUpperCase()" -->
                <textarea class="txtarea" name="" id="campo" cols="140" rows="10" autocapitalize="characters" autocomplete="on">
                   {{ultrasonido.machote}}
                </textarea>

            </div>

        </div>
        <!-- Fin USG Testicular -->

        <!-- inico Usg Mama -->
        <!-- <div class="row center" *ngFor="let ultrasonido of ultrasonidos">
            <div class="card" style="width: 1200px;">
                <div class="form-group row">
                    <h4 class="col" style="margin-left: 1rem;" *ngIf="ultrasonido.nombre == 'ULTRASONIDO DE MAMA'">
                        Nombre del Estudio: {{ultrasonido.nombre}}
                    </h4>
                </div>
            </div>

            <div class="card mt-3"> -->
        <!-- onkeyup="document.getElementById(this.id).value=document.getElementById(this.id).value.toUpperCase()" -->
        <!-- <textarea class="txtarea" name="" id="campo" cols="140" rows="10" autocapitalize="characters" autocomplete="on" *ngIf="ultrasonido.nombre == 'ULTRASONIDO DE MAMA'">
                   {{ultrasonido.machote}}
                </textarea>

            </div>

        </div> -->
        <!-- fin USG Mama -->
        <div class="center">
            <button class="btn btn-success mt-3" (click)="alertaMamalona()"> Guardar y Enviar Reporte</button>
        </div>

    </div>
</form>