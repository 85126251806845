import { Component, Input, OnInit } from '@angular/core';
import { EditableService } from 'src/app/services/editable/editable.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
@Component({
  selector: 'app-editables',
  templateUrl: './editables.component.html',
  styleUrls: ['./editables.component.css']
})
export class EditablesComponent implements OnInit {

  rowEstudioForm : FormGroup
  id;
  elementos = '';
  id2: any;

  constructor(private _editable: EditableService, 
              private _routers: Router, 
              private formBuilder: FormBuilder,
              private _route: ActivatedRoute, 
              private consumoIntegrado : IntegradosService,
              private _laboratorio: LaboratorioService) 
              { 
                //this.addValores(0)
              }
              
              ngOnInit(): void { 
                this.id = this._route.snapshot.paramMap.get('id');
                this.obtenerIdEstudio();
                this.rowEstudioForm = this.formBuilder.group({
                  nombre: [''],
                  idEstudio: [''],
                  tipo_de_examen: ['', {validators: [Validators.required]}],
                  observaciones: [''],
                  metodo: ['', {validators: [Validators.required]}],
                  valoresDeReferencia: this.formBuilder.array([]),
                })
  }
  obtenerIdEstudio(){
    this.consumoIntegrado.getServicioById(this.id)
    .subscribe((data:any) => {
     this.elementos=data['data'].ESTUDIO
   })
   let idUser = {'id': this.id }
   this.obtenerDatosEstudio(idUser)  
 }

  //todo regresa los grupos y elemento 
  rowValores(): FormArray {
    return this.rowEstudioForm.get('valoresDeReferencia') as FormArray
  }
  //* Agregar el un nuevo row de grupo y elemento
  newValores(): FormGroup{
    return this.formBuilder.group({
      grupo:'',
      elemento: '',
      unidad: '',
      valorDeReferencia: this.formBuilder.array([])
    })
  }
  //? agregar valores de referencia
  addValores(i: number){
        this.rowValores().push(this.newValores())
        this.addRowReferenciasValore(i)
  }
  //! borrar el row de los grupos y elemento
  dropRowEstudioValore(i){
    if (this.rowValores().length > 1) {
        this.rowValores().removeAt(i)
    } 
  }


  //todo regresa los valores de referencia
  rowValoresReferencia(i): FormArray {
    return this.rowValores().at(i).get('valorDeReferencia') as FormArray
  }
  //* Agregar el un nuevo row de grupo y elemento
  newRowReferenciasValore(): FormGroup{
      return this.formBuilder.group({
        valorReferenciaD: ''
      })
    }

  //?agregar el valores de referencia
  addRowReferenciasValore(i){
    this.rowValoresReferencia(i).push(this.newRowReferenciasValore())
  }

  //! borrar el valores de referencia
  dropRowReferenciasValore(i,x){
    if (this.rowValoresReferencia(i).length > 1) {
      this.rowValoresReferencia(i).removeAt(x)
    }
  }

  obtenerDatosEstudio(user){
    this._laboratorio.editarEstudio(user)
     .subscribe((data) => {
       this.id2 = data['data'][0]._id
       data['data'][0].valoresDeReferencia.forEach(i => {

        var valoresDeReferencia: FormGroup = this.newValores()
        this.rowValores().push(valoresDeReferencia)
        
        i.valorDeReferencia.forEach(b => {
          (valoresDeReferencia.get('valorDeReferencia') as FormArray).push(this.newRowReferenciasValore())
        });
      });
      
      this.rowEstudioForm.patchValue(data['data'][0])
    })

  }

  sendDataPost() {
      this._laboratorio.editablesEstudio(this.id2, this.rowEstudioForm.value)
      .subscribe( (data:any) => {
        Swal.fire({
                  icon: 'success',
                  title: '',
                  text: 'LOS DATOS SE ACTUALIZARON CORRECTAMENTE',
                }) 
        this._routers.navigate(['/historico-estudio'])
      }) 
    
  }

}
