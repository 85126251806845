import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reaccion-venopuncion',
  templateUrl: './reaccion-venopuncion.component.html',
  styleUrls: ['./reaccion-venopuncion.component.css']
})
export class ReaccionVenopuncionComponent implements OnInit {

  public reaccionVPL = []
  public reaccionVPM = []
  public reaccionVPS = []
  public value='';
  public value2 = '';
  public value3 = '';
  @Output() reaccionesVenopuncionlleve = new EventEmitter();
  @Output() reaccionesVenopuncionmoderada = new EventEmitter();
  @Output() reaccionesVenopuncionsevera = new EventEmitter();
  
  @Output() otrosSintomasLeve = new EventEmitter();
  @Output() otrosSintomasModerada = new EventEmitter();
  @Output() otrosSintomasSevera = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  VenopuncionLeve(event){
    this.reaccionVPL.push(event);
    this.reaccionesVenopuncionlleve.emit(this.reaccionVPL)
  }

  VenopuncionModerada(event){
    this.reaccionVPM.push(event);
    this.reaccionesVenopuncionmoderada.emit(this.reaccionVPM)
  }

  VenopuncionSevera(event){
    this.reaccionVPS.push(event);
    this.reaccionesVenopuncionsevera.emit(this.reaccionVPS)
  }

  otroleve(form: NgForm){
    this.value = form.form.value.otrosSintomasLeves
    this.otrosSintomasLeve.emit(this.value)
  }

  otroModerado(form:NgForm){
    this.value2 = form.form.value.otrosSintomasModerados
    this.otrosSintomasModerada.emit(this.value2)
  }

  otroSevero(form:NgForm){
    this.value3 = form.form.value.otrosSintomasSevero
    this.otrosSintomasSevera.emit(this.value3)
  }

}
