import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalogo-productos',
  templateUrl: './catalogo-productos.component.html',
  styleUrls: ['./catalogo-productos.component.css']
})
export class CatalogoProductosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
