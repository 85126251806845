<div class="container-fluid">
    <h1 h1 class="centerxd">HOJA DE SERVICIO</h1>
</div>

<section class="row">
    <div class="col-md-12">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
    </div>
</section>


<div class="center">
    <section style="padding-left: 3.5rem; padding-right: 3.5rem;">
        <div class="card" name="card" id="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col text_header">
                        <p> No. </p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre Estudio</p>
                    </div>
                    <div class="col text_header">
                        <p>Estado</p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div *ngFor="let ultras of estudios.estudios; let i = index">
                    <div class="row">

                        <div class="col color">
                            <p [routerLink]="['/hoja/reporte/ultrasonido', ultras.idEstudio]" (click)="grabarestudioUltra()"> {{ i +1 }} </p>
                        </div>

                        <div class="col color">
                            <p [routerLink]="['/hoja/reporte/ultrasonido', ultras.idEstudio]" (click)="grabarestudioUltra()"> {{ultras.nombreEstudio}} </p>
                        </div>

                        <div class="col color">
                            <p [routerLink]="['/hoja/reporte/ultrasonido', ultras.idEstudio]" (click)="grabarestudioUltra()"> {{estudios.prioridad}} </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>

<!--codigo viejo-->
<!--<div style="overflow-x: scroll; height: 13rem;">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-hover colors izq" id="bitacora">
                <thead class="center" style="background-color: #03318C;">
                    <tr style="color: white;">
                        <th scope="col"> No.</th>
                        <th scope="col"> Nombre de Estudio </th>
                        <th scope="col"> Estado </th>-->
<!-- <th scope="col"> Resultados </th> -->
<!--</tr>
                </thead>

                <tbody class="center" style="background-color: white;color: black;">
                    <tr *ngFor="let ultras of estudios.estudios; let i = index">
                        <th> {{i +1}} </th>
                        <th [routerLink]="['/hoja/reporte/ultrasonido', ultras.idEstudio]" (click)="grabarestudioUltra()"> {{ultras.nombreEstudio}} </th>
                        <th> {{estudios.prioridad}} </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>-->