<form #form='ngForm'>
    <br>
    <div class="container-fluid" style="margin-right: 40rem;">
        <div class="form-check form-check-inline ">
            <label for=" " style="margin-right: 1rem;">Dismenorrea (Incapacitante): </label>
            <input class="form-check-input" type="radio" name="dismenorrea" [(ngModel)]="antecedentesGinecoObstetricosHistoria.dismenorrea" id="dismeSi" value="Si ">
            <label class="form-check-label" for="dismeSi">Si</label>
            <input class="form-check-input" style="margin-left: 1rem;" type="radio" name="dismenorrea" [(ngModel)]="antecedentesGinecoObstetricosHistoria.dismenorrea" id="dismeNo" value="No ">
            <label class="form-check-label" for="dismeNo">No</label>

        </div>
        <br>
        <div class="form-check form-check-inline ">
            <label for=" " style="margin-right: 1rem;">Ciclos Regulares: </label>
            <input class="form-check-input" type="radio" name="ciclosRegulares" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ciclosRegulares" id="regularSi" value="Si">
            <label class="form-check-label" for="regularSi">Si</label>
            <input class="form-check-input" style="margin-left: 1rem;" type="radio" name="ciclosRegulares" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ciclosRegulares" id="regularNo" value="No">
            <label class="form-check-label" for="regularNo">No</label>
        </div>
        <br>
        <div class="form-check form-check-inline estilo">
            <label for=" " style="margin-right: 15rem;">IVSA:</label>
            <label class="form-check-label" for="ivsa">Años:</label>
            <input class="form-check-input resultados" style="margin-left: 0.4rem;" name="ivsaAnios" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ivsaAnios" type="number" id=" ">
        </div>
        <div class="form-check form-check-inline estilo">
            <label for="ritmo">Ritmo: </label>
            <input type="text" style="margin-left: 2.5rem;" name="ritmo" [(ngModel)]="antecedentesGinecoObstetricosHistoria.ritmo" class="form-control resultados" id="ritmo">

        </div>
        <div class="form-check form-check-inline">
            <label for=" " style="margin-top: 0.5rem;">Hipermenorrea: </label>
            <input class="form-check-input " style="margin-left: 1rem;" type="radio" id="hipermenorreaSi " name="hipermenorrea" [(ngModel)]="antecedentesGinecoObstetricosHistoria.hipermenorrea" value="Si">
            <label class="form-check-label " for="hipermenorreaSi ">Si</label>
            <input class="form-check-input " style="margin-left: 1rem;" type="radio" id="hipermenorreaNo " name="hipermenorrea" [(ngModel)]="antecedentesGinecoObstetricosHistoria.hipermenorrea" value="No">
            <label class="form-check-label " for="hipermenorreaNo ">No</label>
        </div>

        <div class="form-check form-check-inline estilo">
            <label for="citologia">Fecha Última citología (PAP): </label>
            <input type="date" name="fechaUltimaCitologia" [(ngModel)]="antecedentesGinecoObstetricosHistoria.fechaUltimaCitologia" class="form-control resultados">

        </div>

        <div class="form-check form-check-inline estilo">
            <label for="menstruacion">Fecha Última Menstruación: </label>
            <input type="date" name="fechaUltimaMenstruacion" [(ngModel)]="antecedentesGinecoObstetricosHistoria.fechaUltimaMenstruacion" class="form-control resultados">

        </div>
        <div class="">
            <button class="boton" (click)="agregarGinecoObstetricosHistoria(form)">Guardar</button>
            <button class="boton2" (click)="actualizarGinecoHistoria(form)">Actualizar</button>
        </div>
    </div>
</form>