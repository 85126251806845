import { Component, Input, OnInit } from '@angular/core';
import { CalendarOptions, EventInput, DateSelectArg, CalendarApi } from '@fullcalendar/angular'; 
import esLocale from "@fullcalendar/core/locales/es";
import moment from 'moment';
import { validarRole } from 'src/app/functions/validar';
import { AgendaService } from 'src/app/services/agenda/agenda.service';
import { AlmacenService } from '../../services/almacenBs/almacen.service'; 

@Component({
  selector: 'app-calendario-personal',
  templateUrl: './calendario-personal.component.html',
  styleUrls: ['./calendario-personal.component.css']
})
export class CalendarioPersonalComponent implements OnInit {

  @Input() id = '';
  @Input() events = [];
  @Input() role = '';
  public validar = false;
  calendarOptions: CalendarOptions = {
    titleFormat: {
      // will produce something like "Tuesday, September 18, 2018"
      month: "long",
      year: "numeric",
    },
    headerToolbar: {
      left: "prev,today,next",
      center: "title",
      right: "dayGridMonth,timeGridDay,timeGridWeek",
    },
    locale: esLocale,
    scrollTime: moment().format("HH:mm:ss"),
    weekends: true,
    allDaySlot: false,
    navLinks: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    expandRows: true,
    nowIndicator: true,
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "localeEs",
    },
    dayHeaderFormat: {
      weekday: "long",
      day: "numeric",
    },
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "localeEs",
    },
    fixedWeekCount: false,
    showNonCurrentDates: true,
    firstDay: 0,
    eventClick: this.handleEventClick.bind(this),
    eventDrop: this.Movactualizad.bind(this),
    eventResize: this.Movactualizad.bind(this),
    select: this.handleDateSelect.bind(this),
  };

  constructor(private _agenda:AgendaService) {}

  ngOnInit(): void {
    this.validar = validarRole(this.role)
    if(this.validar){
      this.calendarOptions.initialView = 'dayGridMonth'
    }else{
      this.calendarOptions.initialView = 'timeGridDay'
      setTimeout(() => {
        this.calendarOptions.events = this.events
      }, 1000);
      // this.calendarOptions.events = this.events
      // this.agendaPersonal();
    }
  }

  agendaPersonal(){
    console.log(this.events);
    
    // this._agenda.obtenerAgendaPersonal(this.id).subscribe(resp=>{
    //   resp['data'].forEach((elemen: any) => {
    //     let data = {
    //       a: elemen.fechaInicio,
    //       b: elemen.horaInicio,
    //       c: elemen.fechaFin,
    //       d: elemen.horaFin
    //     }
    //     let { inicio, fin } = this.OutDate(data),
    //       eventos = {
    //         id: elemen._id,
    //         title: elemen.idServicio.ESTUDIO.concat(': '+elemen.idPaciente.nombreCompletoPaciente),
    //         start: inicio,
    //         end: fin,
    //         color: elemen.color,
    //         display: 'background'
    //       };
    //       this.events.push(eventos)
    //     });
    //   this.calendarOptions.events = this.events
    // })
  }

  OutDate(data:any) {
    const { a, b, c, d } = data
    const valores = {
      inicio:'',
      fin:''
    }
    if (a != null && b != null) {
      valores.inicio = a.toString() + "T" + b.toString() + ":00";
      valores.fin = c.toString() + "T" + d.toString() + ":00";
      return valores;
    }
  }

  handleEventClick(event){
    console.log('entro1',event);
  }
  Movactualizad(event){
    console.log('entro2',event);
  }
  handleDateSelect(selectInfo: DateSelectArg) {
    if(this.validar){
      const calendarApi = selectInfo.view.calendar;
      calendarApi.unselect(); // clear date selection
      calendarApi.addEvent({
        title:'ghghg',
        start: selectInfo.startStr,
        end: selectInfo.endStr,
      });
    }
  }
}
