import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { USGService } from 'src/app/services/usg/usg.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { filterResultados } from 'src/app/classes/buscarServicos/buscarServicos';


@Component({
  selector: 'app-bitacora-ultrasonidos',
  templateUrl: './bitacora-ultrasonidos.component.html',
  styleUrls: ['./bitacora-ultrasonidos.component.css']
})
export class BitacoraUltrasonidosComponent implements OnInit {
  public consultas:any=[]
  public totalpaciente:string;
  public pagina = 0;
  public pedidosUSG = []

  constructor(  public _usgservicio : USGService,
                private spinner : NgxSpinnerService,
                private _servicios: ServiciosService,
                ) { }

  ngOnInit(): void {
    this.obtenerCosnultaUltra()
    // this.getBitacoraUSG()
  }
  
  obtenerCosnultaUltra(){
    this.spinner.show()
    this.consultas = [];
      this._servicios.getBitacoraLabs('ULTRASONIDO')
      .subscribe((data:any) => {
        if( data.ok ) {
          this.consultas = this.setRegresos( data.data )
          this.spinner.hide();
        }
      })
  }

  busquedaGeneral(event:any){
    // if (event.target.value == '') {
    //   this.consultas = this.pedidosUSG;
    // } else if (event.target.value.length >= 3){
    //   this.consultas = filterResultados(this.consultas, event.target.value)
    // }
    if (event.target.value == '') {
      
      this.obtenerCosnultaUltra();
    } else if (event.target.value.length >= 3){
      // this.subcription.add(
      this._servicios.obterPedidosFinalizadosPorNombrePaciente( {servicio: 'ultrasonido', nombre: event.target.value} )
        .subscribe( (data:any) => {
          if(data.ok) {
            this.consultas = data.data; 
          }
        })
      // )
      // this.pedidosLab = filterResultados(this.pedidosLab, event.target.value)
    }
  }

  setRegresos(ultrasonidos:any){
    ultrasonidos.forEach(element => {
      if (element.idPaciente != null) {
        this.consultas.push(element)
      }
    });
    var found ={};
    var resp = this.consultas.filter(function(element){
      return found.hasOwnProperty(element.idPaciente._id)? false : (found[element.idPaciente._id]=true);
    });
    this.pedidosUSG = resp;
    return resp.reverse();
  }

  // setDataUltras(data:any) {
  //   this.consultas = data.reverse();
  // }
  // getBitacoraUSG() {
  //   this._serviciosService.getBitacoraLabs('ULTRASONIDO')
  //   .subscribe(
  //     (data) => {
  //       this.pedidosUSG = data['data']
  //       console.log(this.pedidosUSG);
        
  //       if (data = 'ok') {
  //         this.spinner.hide()
  //       }
  //     }
      
  //   )
  // }
}
