import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { IntegradosService } from '../../../services/servicios/integrados.service';
import { SocioService } from '../../../services/socio/socio.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({ 
  selector: 'app-utilidad-socio-servicios',
  templateUrl: './utilidad-socio-servicios.component.html',
  styleUrls: ['./utilidad-socio-servicios.component.css']
})
export class UtilidadSocioServiciosComponent implements OnInit {

  public servicios:string;
  public servicioSI: any [] = [];
  public totalAmbulancia: string;
  public pagina = 0;
  public sede;
  public porcentaje:any[]=[];
  public rang={
    rangoUtilidad:''
  }

  constructor(private _router: Router, private _service: IntegradosService, private activatedRoute: ActivatedRoute, public _sede:SocioService) { }

  ngOnInit(): void {

    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.verDatos();
    this.obtenerId();   
    this.obtenerUtilidad();
  }

  verDatos(){
    if(this.servicios == 'ambulancia'){
      this._service.getDestino(this.servicios).subscribe(
        (res: any) => {
          this.setDatos( res['data']  );
        },
        err => {
          console.log(<any>err);
        }); 
    }else{
      this._service.getObtener(this.servicios)
      .subscribe(
        (res: any) => {

          this.setDatos( res['data']  );
          this.totalAmbulancia = res.data.results;          
        },
      err => {
          console.log(<any>err);
      });
    }
      
  }

  Rango(valor){
    /* console.log(valor); */
    this.rang.rangoUtilidad=valor;
    /* console.log(this.rang); */
    this._sede.updateRangoSede(this.sede,this.servicios,this.rang).subscribe((resp:any)=>{
      if(resp.ok){
        /* console.log(resp); */
        this.obtenerUtilidad();
      }
    })
  }

  obtenerUtilidad(){   
    this._sede.obtenerUtilidades(this.servicios,this.sede).subscribe((resp:any) =>{
      if(resp.ok){
        if(resp['data'].length == 0){
          this.porcentaje=[];
        }else{
          this.setDatosUtilidad(resp);
        } 
      }
    })    
  }

  setDatosUtilidad(resp){
    /* console.log(resp); */
    this.porcentaje=[];
    if(resp['data'].length == 0){
      this.porcentaje=[];
    }else{
      for (const item of resp['data']) {
        this.porcentaje.push(item);                
      }
    }   
  }

  socioUtilidad (idServ,precio): any{ 
    var valor = this.porcentaje.find(id => id.idServicio._id === idServ.toString()); 
    if(valor != undefined){
      switch(valor.rangoUtilidad){
        case 'A':
          if(precio == "PRECIO_PUBLICO"){
            return valor.preciosRangoA[0].porcentajePrecioPublicoA;
          }else if(precio == "PRECIO_MEMBRESIA"){
            return valor.preciosRangoA[0].porcentajePrecioMembresiaA;
          }else if(precio == "PRECIO_PUBLICO_URGENCIA"){
            return valor.preciosRangoA[0].porcentajePrecioPublicoUrgenciaA;
          }else if(precio == "PRECIO_MEMBRESIA_URGENCIA"){
            return valor.preciosRangoA[0].porcentajePrecioMembresiaUrgenciaA;
          }else if(precio == "PRECIO_PUBLICO_HOSPITALIZACION"){
            return valor.preciosRangoA[0].porcentajePrecioPublicoHospitalizacionA;
          }
          break;
        case 'B':
          if(precio == "PRECIO_PUBLICO"){
            return valor.preciosRangoB[0].porcentajePrecioPublicoB;
          }else if(precio == "PRECIO_MEMBRESIA"){
            return valor.preciosRangoB[0].porcentajePrecioMembresiaB;
          }else if(precio == "PRECIO_PUBLICO_URGENCIA"){
            return valor.preciosRangoB[0].porcentajePrecioPublicoUrgenciaB;
          }else if(precio == "PRECIO_MEMBRESIA_URGENCIA"){
            return valor.preciosRangoB[0].porcentajePrecioMembresiaUrgenciaB;
          }else if(precio == "PRECIO_PUBLICO_HOSPITALIZACION"){
            return valor.preciosRangoB[0].porcentajePrecioPublicoHospitalizacionB;
          }
          break;
        case 'C':
          if(precio == "PRECIO_PUBLICO"){
            return valor.preciosRangoC[0].porcentajePrecioPublicoC;
          }else if(precio == "PRECIO_MEMBRESIA"){
            return valor.preciosRangoC[0].porcentajePrecioMembresiaC;
          }else if(precio == "PRECIO_PUBLICO_URGENCIA"){
            return valor.preciosRangoC[0].porcentajePrecioPublicoUrgenciaC;
          }else if(precio == "PRECIO_MEMBRESIA_URGENCIA"){
            return valor.preciosRangoC[0].porcentajePrecioMembresiaUrgenciaC;
          }else if(precio == "PRECIO_PUBLICO_HOSPITALIZACION"){
            return valor.preciosRangoC[0].porcentajePrecioPublicoHospitalizacionC;
          }
          break;
      }
    }else{
      return valor=0;
    }
  }

  eliminar(idServ){    
    const valor = this.porcentaje.find(id => id.idServicio._id === idServ.toString());
    if(valor == undefined){
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'NO HAY NADA QUE ELIMINAR',
        })
    }else{
      this.alertEliminar(valor._id);
    }
  }

  setDatos(datos){
    this.servicioSI = datos;    
  }

  obtenerId(){
    this.sede= localStorage.getItem('sede');
  }

  alertEliminar(valor){
    Swal.fire({title: "¿Estas seguro de eliminar la utilidad?",
    text: "Una vez que se haya eliminado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._sede.eliminarUtilidad(valor).subscribe((resp:any) => {
          if(resp.ok){
          this.obtenerUtilidad();
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'LA UTILIDAD SE ELIMINO CORRECTAMENTE',
            })
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'LA UTILIDAD NO SE ELIMINO',
            })
            this._router.navigateByUrl('dash/'+this.sede+'/'+this.servicios);
      } 
    })
    
  }

  editar(id){
    Swal.fire({title: "¿Estas seguro de eliminar la utilidad?",
    text: "Una vez que se haya eliminado, no se podrá recuperar",
    icon: "warning",
    showCancelButton: true, 
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR'
    })
    .then((result) => {
      if (result.value) {
        this._router.navigateByUrl('editar/utilidad/'+ this.servicios+ '/' +id)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
              icon: 'error',
              title: '',
              text: 'NO SE EDITO NADA',
            })
            this._router.navigateByUrl('dash/'+this.sede+'/'+this.servicios);
      } 
    })
}
}