<!-- ESTE TEMPLATE ES DE LOS PAQUETES EN GENERAL -->
<div class="container animate bounceInLeft">
    <h2 class="text-center centerxd"> {{ paquete.nombrePaquete | uppercase}} </h2>
    <div class="card" id="card">
        <div class="row">
            <div class="col-md-9">    
                <ul class="mt-3">
                    <h3 class="centerxd"> Consultas Incluidas: </h3>
                    <li class="text-justify" *ngFor="let citas of paquete.consultas"> 
                    <i class="fas fa-check text-success"></i> {{ citas }} </li>
                </ul>

                <ul  class="mt-3" *ngIf="paquete.laboratorio">
                    <h3 class="centerxd"> Exames de laboratorio: </h3>
                    <li *ngFor="let citas of paquete.laboratorio"> 
                        <i class="fas fa-check text-success"></i> {{ citas }} 
                    </li>
                </ul>

                <ul  class="mt-3" *ngIf="paquete.ultrasonido">
                    <h3 class="centerxd"> Ultrasonidos: </h3>
                    <li *ngFor="let citas of paquete.ultrasonido"> 
                        <i class="fas fa-check text-success"></i> {{ citas }} 
                    </li>
                </ul>

                <ul class="mt-3" *ngIf="paquete.rayosX">
                    <h3 class="centerxd"> Rayos X: </h3>
                    <li *ngFor="let farmacia of paquete.farmacia"> 
                        <i class="fas fa-check text-success"></i> {{ farmacia }} 
                    </li>
                </ul>

                <ul class="mt-3" *ngIf="paquete.extras">
                    <h3 class="centerxd">Más beneficios: </h3>
                    <li *ngFor="let extra of paquete.extras">
                        <i class="fas fa-check text-success"></i> {{ extra }}
                    </li>
                </ul>
    
                <ul class="mt-3" *ngIf="paquete.descuentos">
                    <h3 class="centerxd"> Descuentos: </h3>
                    <li *ngFor="let descuentos of paquete.descuentos"> <i class="fas fa-check text-success"></i> {{ descuentos}} </li>
                </ul>
    
    
                <ul class="mt-3" *ngIf="paquete.farmacia">
                    <h3 class="centerxd"> Farmacia: </h3>
                    <li *ngFor="let farmacia of paquete.farmacia"> <i class="fas fa-check text-success"></i> {{ farmacia }} </li>
    
                </ul>  
    
            </div>
            <div class="col-md-3">
                <img [src]="paquete.icon" class="img-fluid">
    
                <h4 class="text-center mt-4"> Precio: ${{ paquete.costoTotal }}.00 </h4>
                <a class="btn btn-success btn-block text-white" routerLink="/paciente">  Adquirir  </a>
    
                <!-- <div *ngIf="paquete.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL'" class="center">
                    <a class="center btn btn-block btn-info" data-toggle="modal" data-target="#exampleModal">  Ver forma de pago </a>                      
                </div>
                <br>
                <div *ngIf="paquete.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL DE ALTO RIESGO'" class="center">
                    <a class="center" download="anexopaquete.pdf" target="_blank" href="http://tlayacapan.grupomedicohorizonte.com.mx/contratos/ANEXO_PAQUETE_DE_MATERNIDAD.pdf">  Descargar anexo </a>
                </div> -->
                <!-- <div class="alert alert-warning" role="alert" *ngIf="paquete.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL'">
                    <a class="center" [routerLink]="['/paquete/5e7a40531c9d440000a97610']">  Paquete Alto riesgo </a>
                </div> -->
    
            </div>
        </div>
    </div>
</div>