<div class="container-fluid mb-4">
    <app-titulos [title]="'ACTUALIZAR MATERIAL'"></app-titulos>
</div>

<div class="container-fluid">
    <div class="card cardPer container">
        <form [formGroup]="newFormProductos">
            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> NOMBRE:</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" name="nombre"    class="form-control shadow-input-bs" formControlName="nombre" />
                </div>
                <div class="col-md-2">
                    <label class="mayus" for="idProducto"> ID: </label>
                </div>
                <div class="col-md-4">
                    <input type="text"  name="idProducto" placeholder="2EK3j34R14U34U" id="idProducto" class="form-control shadow-input-bs" formControlName="idProducto"/>
                </div>
            </div>
            <div formArrayName="proveedor" *ngFor="let valor of valoresProveedor.controls; let j = index" style="margin-top: 20px;">
                <div  [formGroupName]="j">
                    <div class="row mb-3">
                        <div class="col-md-2">
                            <label class="mayus">PROVEEDOR</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control shadow-input-bs"  id="inputGroupSelect01"  formControlName="proveedor" >
                                <option [value]="proveedor.razonSocial"   *ngFor="let proveedor of proveedoresCensur" > {{ proveedor.nombreEmpresa }} </option>
                                <!-- <option value="Vic"></option> -->
                              </select>
                        </div>
                        <div class="col-md-2">
                            <label class="mayus">COSTO</label>
                        </div>
                        <div class="col-md-3">
                            <input type="number" min="0" class="form-control shadow-input-bs" name="" id=""  required   formControlName="costo" >
                        </div>
                        <div class="col-md-1">
                            <i class="fa fa-trash-alt colorRed" (click)="removeValores(j)"></i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ! Modal de los Proveedores -->
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Agregar Proveedor</h5> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <app-registro-proveedores-bs (agregar)="agregarNuevoProveedor($event)"></app-registro-proveedores-bs>
                  </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> FABRICANTE</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" placeholder="PFIZER" name="laboratorio" class="form-control shadow-input-bs" formControlName="laboratorio"/>
                </div>
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> CANTIDAD MINIMA</label>
                </div>
                <div class="col-md-4">
                    <input type="number" min="0" class="form-control shadow-input-bs" name="cantidadMin" id="" required    formControlName="cantidadMin">   
                </div>
            </div>
            <div class="row ml-1 mb-4">
                <!-- <button class="boton" (click)="agregarProveedor({proveedor:'', costo:0})">
                    Agregar proveedor
                </button> -->
                <div class="col-md-3">
                    <label class="proveedor" (click)="agregarProveedor({proveedor:'', costo:0})" >Agregar proveedor</label>
                </div>
                <div class="col-md-3">
                    <a class="proveedor" type="button" data-toggle="modal" data-target=".bd-example-modal-lg">Agregar NUEVO Proveedor</a>
                </div>
            </div>
            <div class="row ml-1">
                <div class="col-md-12">
                    <label class="mayus">descripción:</label>
                </div>
                <div class="col-md-12">
                    <textarea class="mb-3" name="descripcion" id="" cols="2" rows="3" formControlName="descripcion" ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 offset-md-5">    
                    <button  class="btn btnPrincipalBS" [disabled]="validBoton" (click)="actualizarProducto()" >
                        REGISTRAR
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
