import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import {USGService} from 'src/app/services/usg/usg.service'
@Component({
  selector: 'app-bitacoraultra',
  templateUrl: './bitacoraultra.component.html',
  styleUrls: ['./bitacoraultra.component.css']
}) 
export class BitacoraultraComponent implements OnInit {

  public consultas:any=[];
  public pagina = 0;
  public totalAmbulancia: string;
  public hoy = new Date();
  public fecha1;
  public fecha2;
  //nuevo 
  public noSe=[];
  public imprimir = {
    indice: 0,
    fecha: '',
    nombre: '',
    edad:  '',
    sexo: '',
    sede: '',
    estudio: '',
    estado: '',
  }
  
  constructor(   public _consultaService: ConsultaService, 
    public _usgservicio : USGService) { 
    }
    ngOnInit(): void {
      this.obtenerCosnultaUltra();        
    }
    
    
    
    obtenerCosnultaUltra(){  
    this._usgservicio.getUltrsonidoUSG()
    .subscribe( (data) =>   {
        this.setconsultas( data['data']);
        this.consultas = data['data'].reverse();
        /* this.setFecha(this.hoy.toISOString().split('T'),fe[0]) */
        //console.log(this.consultas);
        this.totalAmbulancia = data['data'].results;
    });
  }

  setFecha(valor){
    const fe=new Date(valor).toISOString().split('T');
    this.fecha1 = this.hoy.toISOString().split('T');
    this.fecha2 = fe[0];
    
    if(this.fecha1[0] == this.fecha2){      
      return true;
    }else{
      return false;
    }
  }

  setconsultas( consultas ){
    this.consultas = consultas;
    //console.log(this.consultas);
    this.setImp(this.consultas);
  }

  convertirFecha(valor){
    const fe = new Date(valor).toISOString().split('T');
    let fe2 = new Date(fe[0]).toDateString();

    /*let aux: any = [] = fe2.split(' ');
    let dia = parseInt(aux[2]);
    dia++;  opcion 1 :´v
    let fecha = aux[1]+" "+dia+" "+aux[3];
    //console.log(aux[1]+" "+aux[2]+" "+aux[3]);*/

    let aux: any = [] = fe2.split(' ');
    let fecha = aux[1]+" "+aux[2]+" "+aux[3];
    return fecha;
  }

  setImp(cons){    
    let i= 0;
    for (let element of cons) {  
      //console.log(element);
      this.imprimir.indice= i + 1;
      //this.imprimir.fecha = element.fechaDePedidoDeLosExamenes;
      this.imprimir.fecha = this.convertirFecha(element.fechaDePedidoDeLosExamenes);
      this.imprimir.nombre = element.idPaciente.nombrePaciente + ' ' +element.idPaciente.apellidoPaterno +' '+ element.idPaciente.apellidoMaterno;
      this.imprimir.edad = element.idPaciente.edad;
      this.imprimir.sexo = element.idPaciente.genero;
      this.imprimir.sede = element.sede;
      this.imprimir.estudio = element.estudios[0].nombreEstudio;
      this.imprimir.estado = element.prioridad;
      //console.log(this.imprimir.indice);
      //console.log(this.imprimir);
      this.noSe.push(this.imprimir)
      this.imprimir = {
        indice: 0 ,
        fecha: '',
        nombre:  '',
        edad: '',
        sexo: '',
        sede: '',
        estudio: '',
        estado: '',
      }
      i++
    }    
  }

  imp(){
    let values: any;
    values = this.noSe.map((elemento) => Object.values(elemento));
    
    /* console.log(values); */
    
    const doc:any = new jsPDF();
    doc.text(12, 9, "BITÁCORA DE ULTRASONIDO");
    doc.autoTable({
      head: [['#', 'Fecha', 'Nombre', 'Edad', 'Sexo', 'Sede', 'Estudio', 'Estado']],
      body: values
    })
    doc.save('Bitácora_De_Ultrasonido.pdf')
  }
 
}