import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class XRAYService {

    
    public  url = URL;

    constructor(  private _http:HttpClient    ) {
        /* console.log('Los Rayos X Están a punto de ser cargados :D'); */
        
    }


   

    getXray(){
        const url = `${this.url}/ver/pedidos/rayosX`;
        return this._http.get( url);
    }

    verPedidoXray (id){
        const url = `${this.url}/obtener/rayosX/pedido/${id}`;
        return this._http.get( url);
    }

    postPedidosXray(pedido){
        const url = `${this.url}/nuevo/pedido/rayosX`;
        return this._http.post( url, pedido );

    }

    // servicios de regresos

    enviarXrayRegreso(enviarXray){
        const url = `${this.url}/agregar/regresos/xray`;
        return this._http.post(url,enviarXray)
      
    }
    
    obtenerXrayRecepcionHC(id){
        const url = `${this.url}/ver/servicio/regreso/xray/${id}`;
        return this._http.get(url)
      }

      obtenerXrayRecepcion(id){
        const url = `${this.url}/ver/regreso/xray/recepcion/${id}`;
        return this._http.get(url)
      }

      uploadImage( body:FormData ) {

        const url = `https://api.cloudinary.com/v1_1/juanpatron1630/image/upload`;
        return this._http.post( url, body );
    }
    

}