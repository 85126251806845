<div class="container-fluid">
    <h1 class="centerxd">BITÁCORA DE ALMACÉN</h1>
    <!-- <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar"  class="form-control" id="busquedaPaciente" >
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-3">Producto
            <select class="form-control" required>
                <option>Selecciona un producto</option>
                <option>Hemoderivados</option>
                <option>Servicios</option>
                <option>Materiales</option>
              </select>
        </div>
        <div class="col-md-4">Fecha
            <input type="date"  required class="form-control">
        </div>
        <div class="col-md-4">
            <input class="principalButton" type="submit" value="IMPRIMIR">
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>

                        <div class="col-md-3 text_header">
                            <p>ID</p>
                        </div>
                        <div class="col text_header">
                            <p>DISPONENTE</p>
                        </div>
                        <div class="col text_header">
                            <p>PROCEDENCIA</p>
                        </div>
                        <div class="col text_header">
                            <p>TÍPO DE DONACIÓN</p>
                        </div>
                        <div class="col text_header">
                            <p>PRODUCTO</p>
                        </div>
                        <div class="col text_header">
                            <p>CADUCIDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>FECHA</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div>
                        <div class="row" id="pointer">
                            <div class="col-md-1" style="text-align: left;">
                                <p>
                                    1
                                </p>
                            </div>
                            <div class="col-md-3" style="text-align: center;">
                                <p>
                                   PEPRMS2034903MJRLS/01/04/CE
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    VICTOR MANUEL ARAGON CASTELLANOS
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    TLAYACAPAN
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    VOLUNTARIA
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    CONCENTRADOS ERITROCITARIOS
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    5 DÍAS
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    22/02/2021
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>