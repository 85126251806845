import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaquetesService } from '../../../services/paquetes/paquetes.service';

@Component({
  selector: 'app-paquete-paciente',
  templateUrl: './paquete-paciente.component.html',
  styleUrls: ['./paquete-paciente.component.css']
})
export class PaquetePacienteComponent implements OnInit {

  public id: String;
  nombre: String;
  paq:any;

  constructor(public _router: ActivatedRoute, public _paquete:PaquetesService) { }

  ngOnInit(): void {
    this.id = this._router.snapshot.paramMap.get('id');
    this.obtenerPaquete();
  }

  obtenerPaquete(){
    this._paquete.obtenerPaquete(this.id)
    .subscribe(  (data:any) =>{
      if(data.ok) {
        this.paq = data['paquetes']
        this.nombre= this.paq.paquete.nombrePaquete
      }
    });
  }

}
