import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class ServiciosAlmacenService {

  public url: string;

  constructor(private _http:HttpClient) { 
    this.url = URL;
  }

  obtenerServicios(){
    return this._http.get( `${ this.url }/ver/servicios/censur` );
  }

  obtenerServiciosDepartamento(){
    return this._http.get( `${ this.url }/horario/servicios` );
  }

}


