import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';


@Injectable({
  providedIn: 'root'
})
export class PedidosSedesService {

  
  constructor( private _http: HttpClient  ) { }


  obeterPedidos(){
    const url =  `${URL}/compras/sedes`;
    return this._http.get( url );
  }

  actualizarEstado(id,body){
    /* console.log(body); */
    
    const url =  `${URL}/actualizar/estado/compra/${id}`;
    return this._http.put( url, body);
  }

  obtenerPedido( id ){
    const url =  `${URL}/ver/pedido/sede/${id}`;
    return this._http.get( url );
  }

  ventas( ventas:any ) {

    const url =  `${URL}/nueva/compra/sede`;
    return this._http.post( url, ventas );
  }

}
