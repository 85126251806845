import { Component, OnInit, Input } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';

import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import { ServiciosService } from 'src/app/services/admin/servicios.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-regresos',
  templateUrl: './regresos.component.html',
  styleUrls: ['./regresos.component.css']
})
export class RegresosComponent implements OnInit {

  @Input() estudiosPendientes: string;

  public consultas: any = []

  public sexo = "";
  public id: string;
  public fecha: string;

  public pagina = 0;
  public totalpaciente:string;

  public search = "";
  public href: string = "";
  public titleBitacora = "";
  public serviciosName ="";

  constructor(

    private activatedRoute: ActivatedRoute,
    public _consultaService: ConsultaService,
    public _consultaPaciente: PacientesService,
    private _servicesService: ServiciosService,
    private router: Router,
    private spinner:NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.eliminarLocal();
    this.href = this.router.url; //sirve para ver la url y hacer el if de los titulos
    // console.log(this.router.url);
    this.titulosBitacoras();
    this.getPedidiosByService();
  }

  // Poner los titulos en la bitacora
  titulosBitacoras(){
    
    if(this.href == '/bitacora/pedidos/ultrasonido'){
      this.titleBitacora = 'BITÁCORA DE ULTRASONIDO'
    }else if (this.href == '/bitacora/pedidos/laboratorio') {
      this.titleBitacora = 'BITÁCORA DE LABORATORIO'
    }else if ( this.href == '/bitacora/pedidos/xray' ){
      this.titleBitacora = 'BITÁCORA DE RAYOS X'
    }else if(this.href == '/bitacora/pedidos/patologia') {
      this.titleBitacora = 'BITÁCORA DE PATOLOGÍA'
    }else if (this.href == '/bitacora/pedidos/endoscopia') {
      this.titleBitacora = 'BITÁCORA DE ENDOSCOPÍA'
    }else if (this.href == '/bitacora/pedidos/tomografia') {
      this.titleBitacora = 'BITÁCORA DE TOMOGRAFÍA'
    }

    this.serviciosName = this.activatedRoute.snapshot.paramMap.get('services');
  }

  getPedidiosByService() {
    this.spinner.show()
    this._servicesService.getPedidosByServices(this.serviciosName)
    .subscribe((data:any) => {
      if(data.ok) {
        this.setPedidosLab(data.data);
        this.spinner.hide();
      }
    });
  }

  setPedidosLab(data:any  ){  
    this.consultas = data.reverse();
  }

  eliminarLocal(){
    localStorage.removeItem('idPedido')
  }



  public indexTable = 0;
  receivedIndex(i: number) {
    this.indexTable = i;
  }

  printTable() {
    const doc: any = new jsPDF('l', 'cm', 'letter');
    let contador = 1;
    //doc.autoTable({ orientation: 'p', html: '#printTableThis' });
    //doc.fromHTML('#printTableThis');

    var columns = ["No.", "Fecha", "Nombre", "Edad", "Sexo", "Sede", "Estudio", "Prioridad"];
    var rows = [];

    this.consultas.forEach(element => {
      var x = [contador, element.fecha,
        element.idPaciente.nombrePaciente + element.idPaciente.apellidoPaterno + element.idPaciente.apellidoMaterno,
        element.idPaciente.edad, element.idPaciente.genero, element.sede, element.estudios[0].nombreEstudio,
        element.prioridad];

        rows.push(x);
        contador = contador + 1;
    });
    
    doc.autoTable(columns, rows, {startY: 1});
    doc.save(`Bitacora Consultas Externas.pdf`);

  }


}
