export const consultaService =  [
    {
    name: "Endoscopia",
    color: "#04A4DB"
},
{
    name: "Pediatría",
    color: "#f2709c"

},
{
    name: "Hospitalizacion"
},
{
    name: "Consulta General"
},
{
    name: "Consulta Especialidad"
},

{
    name: "Revision"
},

{
    name: "Cirugia"
},

{
    name: "Expulsion"
},
{
    name: "Tococirugia"
},


{
    name: "Estancia corta"
},

{
    name: "Retiro de puntos"
},
{
    name: "Curaciones"
}

,
{
    name: "Urgencias"
}
];