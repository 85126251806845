import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-antecedentes-gineco',
  templateUrl: './antecedentes-gineco.component.html',
  styleUrls: ['./antecedentes-gineco.component.css']
})
export class AntecedentesGinecoComponent implements OnInit {

  @Output() antecedentesgineco = new EventEmitter<string>();
  formulario: FormGroup;
  public abortos = false;
  public gestas = false;
  public partos = false;
  public cesareas = false;

  constructor() { }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      ultima_relacion: new FormControl('NO'),
      gestas: new FormControl('NO'),
      partos: new FormControl('NO'),
      cesareas: new FormControl('NO'),
      abortos: new FormControl('NO'),
      ultimo_parto: new FormControl(),
      ultimo_aborto: new FormControl(),
      isoinmunizacioes: new FormControl('NO'),
      globulina: new FormControl('NO'),
    });
    this.antecedentesgineco.emit(this.formulario.value);
  }

  activarGestas(tipo){
    if(tipo == 'SI'){
      this.gestas = true;
    }else{
      this.gestas = false;
    }
  }

  activarPartos(tipo){
    if(tipo == 'SI'){
      this.partos = true;
    }else{
      this.partos = false;
    }
  }

  activarCesareas(tipo){
    if(tipo == 'SI'){
      this.cesareas = true;
    }else{
      this.cesareas = false;
    }
  }

  activarAbortos(tipo){
    if(tipo == 'SI'){
      this.abortos = true;
    }else{
      this.abortos = false;
    }
  }


  formularioAntecedentesGineco(form: NgForm){
    this.antecedentesgineco.emit(form.form.value);
  }

}
