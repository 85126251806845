import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';;

@Component({
  selector: 'app-editar-seguimientos-almacen',
  templateUrl: './editar-seguimientos-almacen.component.html',
  styleUrls: ['./editar-seguimientos-almacen.component.css']
})
export class EditarSeguimientosAlmacenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
  guardar( forma: NgForm){

    if (forma.invalid) {
      Object.values( forma.controls ).forEach( control =>{
      control.markAsTouched();
    });
    Swal.fire({
      title: 'FAVOR DE LLENAR TODOS LOS CAMPOS',
      icon: 'error'
      }).then( forma => {
        forma.value
      }) 
      
    }else { 
    //  this.proveedorService.crearProveedor( this.proveedor ).
    //   subscribe( resp=>{
    //     this.btnRegistrar = true;

    //     this._router.navigateByUrl('/');
    //   })
      Swal.fire({
        title: 'EL SEGUIMIENTO SE CREO EXISTOSAMENTE',
        icon: 'success'
        }).then( forma => {
          forma.value
        }) 
    } 
  }

}
