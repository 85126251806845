<!-- <h1 class="centerxd"> RECETA MÉDICA </h1> -->
<div class="row">
    <div class="col-md-8">
        <app-titulos [title]="'RECETA MÉDICA'" [rol]="'doctor'"></app-titulos>
    </div>
</div>

<div class="row">
    <div class="col-md">
        <ficha-info-user [pacienteInfo]="paciente2" [title]="false"></ficha-info-user>
        <!-- <app-header-receta [paciente]="paciente"></app-header-receta> -->
    </div>
</div>
<br>

<div class="container-fluid">
    <div class="row">
        <!--tabs-->
        <div class="card container-fluid col-md-12">
            <div role="tabpanel">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link center active" data-toggle="tab" href="#dosificacion"> Posología </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link center" data-toggle="tab" href="#prescripcion"> Otras indicaciones </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link center" data-toggle="tab" href="#estudios"> Estudios </a>
                    </li>
                </ul>

                <div class="tab-content mt-5 mb-3">
                    <div role="tabpanel" class="tab-pane active" id="dosificacion">
                        <div class="container-fluid">
                            <div class="row">

                                <div class="col-md-6">
                                    <form>

                                        <h5 class="iz"> Medicamento </h5>

                                        <input type="text form-control" class="" (keyup)="buscarMedicamento()"
                                            [(ngModel)]="busuqedaMedicamento" name="medicamento"
                                            id="buquedaDeMedicamento" autocomplete="off">
                                        
                                        <div class="tablaMedicamentos" *ngIf="medicamentos.length > 0">
                                            <li class="selected pointer" *ngFor="let item of medicamentos" #tabla (click)="setMedicamentos( tabla )">
                                                <strong>
                                                    {{ item.nombreComercial}} {{ item.nombreDeSalOsustanciaActiva }} 
                                                    {{item.presentacio }} {{ item.viaDeAdministracion }} 
                                                    {{item.contenidoFrasco }}
                                                </strong>
                                            </li>
                                        </div>
                                            <!-- <table class="table-search">
                                                <tr *ngFor="let item of medicamentos">
                                                    <td class="puntero pointer"> 
                                                        <strong>
                                                            {{ item.nombreComercial}} {{ item.nombreDeSalOsustanciaActiva }} {{
                                                                item.presentacio }} {{ item.viaDeAdministracion }} {{
                                                                item.contenidoFrasco }}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </table> -->
                                        <!-- <div>
                                            <tr class="" *ngFor="let item of medicamentos">
                                                <td #tabla (click)="setMedicamentos( tabla )">
                                                    {{ item.nombreComercial}} {{ item.nombreDeSalOsustanciaActiva }} {{
                                                    item.presentacio }} {{ item.viaDeAdministracion }} {{
                                                    item.contenidoFrasco }}
                                                </td>
                                            </tr>
                                        </div> -->
                                        <h5 class="iz"> Indicaciones </h5>

                                        <textarea class="iz form-control text-upper"
                                            [(ngModel)]="indicaciones.indcacionesMedicamento" name="indicaciones" id=""
                                            cols="55" rows="3"></textarea>
                                        <button class="principalButton  btn-receta"
                                            (click)="agregarMedicamentosEIndicaciones()">
                                            Agregar

                                        </button>

                                        <!-- <button class="secondaryButton btn-receta"> Imprimir  </button> -->
                                        <p class="nuevoMedicamento"> ¿ No encuentras algún medicamento? </p> <a
                                            data-toggle="modal" data-target="#medicamentoModal" class="cursor"> Nuevo
                                        </a>


                                    </form>

                                </div>

                                <div class="col-md-6">

                                    <table id="receta" *ngFor="let item of recetMedica.medicamentos; let i = index">
                                        <tr class="first-medicine">
                                            <td>
                                                {{ item.medicamento }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ item.indicaciones }}
                                                <a class="text-danger btn-delete pointer" (click)="deleteItem(i)"> Eliminar </a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div role="tabpanel" class="tab-pane" id="prescripcion">

                        <div class="row">
                            <div class="col-md-3">
                                <h4>
                                    Receta
                                </h4>
                            </div>
                        </div>
                        <!-- INDICACIONES  -->
                        <div class="row">
                            <div class="col-md-12">

                                <textarea class="text-upper" name="form-control"
                                    [(ngModel)]="recetMedica.otrasIndicaciones" id="" cols="110" rows="8"></textarea>
                            </div>

                        </div>

                    </div>

                    <!-- ESTUDIOS  -->
                    <div role="tabpanel" class="tab-pane" id="estudios">
                        <h3> Estudios</h3>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text form-control" name="buscarEstudios" id="buscarEstudios"
                                    [(ngModel)]="buscarEstudiotxt.estudio" (keyup)="buscarEstudio()">
                            </div>
                            <div class="col-md-6">
                                <div class="tablaEstudios" *ngIf="estudios.length > 0">
                                    <li class="selected pointer" *ngFor="let estudio of estudios" (click)="agregarEstudio( estudio )">
                                        <strong>
                                            {{ estudio.ESTUDIO }}
                                        </strong>
                                    </li>
                                </div>
                                <!-- <div class="col-md-12">
                                    <tr class="list-estudios" *ngFor="let estudio of estudios">
                                        <td (click)="agregarEstudio( estudio )"> {{ estudio.ESTUDIO }} </td>
                                    </tr>
                                </div> -->
                            </div>
                            <div class="col-md-10 mt-2">
                                <div class="row" *ngFor="let item  of recetMedica.estudios; let i = index">
                                    <div class="col-md-10 mt-3">
                                        <li class="center"> {{ item.ESTUDIO }} </li>
                                    </div>
                                    <div class="col-md-2 mt-3">
                                        <a class="text-danger btn-delete pointer" (click)="eliminarEstudio(i)"> Eliminar </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 offset-md-4 mt-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Boton guardar-->
            <div class="row mb-2">
                <div class="col-md-4 offset-md-4">
                    <button class="principalButton" (click)="guardarReceta()"> Guardar </button>
                </div>
                <!-- <div class="col-md-2 offset-md-1">
                    <button class="secondaryButton" (click)="imprimirFrente()">  Imprimir  </button>
                </div> -->
            </div>

            <div class="row">
                <div class="col-md-2 offset-9">
                    <p class="strongText center"> Dr.{{medico.nombre}} </p>
                    <p class="strongText center">  {{medico.cedulaProfesional}} / {{medico.cedulaEsp}} </p>
                    <p class="strongText center"> Especialidad: {{medico.Especialidad}} </p>
                </div>
            </div>

        </div>

    </div>

    <div id="medicamentoModal" class="modal fade bd-example-modal-lg center" tabindex="-1" role="dialog"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title center">Registro Nuevo Medicamento</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <label for="nombreComercial">Nombre Comercial:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                        margin: -2rem 0 2rem 0;"
                                    [(ngModel)]="medicamentoServiceDoctor.nombreComercial">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="sustamciaActiva">Sustancia Activa:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                        margin: -2rem 0 2rem 0;"
                                    [(ngModel)]="medicamentoServiceDoctor.nombreDeSalOsustanciaActiva">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="presentacion">Presentación:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                        margin: -2rem 0 2rem 0;" [(ngModel)]="medicamentoServiceDoctor.presentacio">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="contenido">Contenido del frasco:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                        margin: -2rem 0 2rem 0;"
                                    [(ngModel)]="medicamentoServiceDoctor.contenidoFrasco">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="viaAdmon">Vía de Administración:</label>
                                <input type="text" class="inputStyle inModalS" style="margin: -2rem 0 2rem 0;"
                                    [(ngModel)]="medicamentoServiceDoctor.viaDeAdministracion">
                            </div>

                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class=" btn-danger" data-dismiss="modal">Cerrar</button>
                    <button type="button" class=" btn-primary"
                        (click)="agregarMedicamentosDesdeDoctor()">Guardar</button>
                </div>

            </div>
        </div>
    </div>
    <br>