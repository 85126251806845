<div class="container-fluid">
    <!-- <h1 class="centerxd" *ngIf="showVista">LISTADO DE ENDOSCOPÍA</h1> -->
    <app-titulos [title]="'LISTADO DE QUIROFANO'" [rol]="'quirofano'" *ngIf="showVista"></app-titulos>
    <div class="container-fluid">
        <div class="row" *ngIf="showVista">
            <div class="col-md-8">
                <form>
                    <div class="form-group">
                        <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio">
                        <!-- <button class="btn btn-primary" type="submit">
                            Buscar
                        </button> -->
                    </div>
                </form>
            </div>

            <div class="col-2">
                <button type="button" class="btn btn-secondary principalButton" id="boton_contra2" [routerLink]="['/formulario', servicios]" routerLinkActive="router-link-active" *ngIf="RoleUser == 'Admin' ">NUEVO</button>
            </div>
            <!-- <d
                iv class="col-2">
                <button type=" button " class="btn btn-primary separar" id="boton_contra1" [routerLink]="['/hoja-fram']" routerLinkActive="router-link-active">COTIZAR</button>
            </div> -->
            <app-tabla-service *ngIf="showTableAmbulanacia" [serviceSi]="serviceSi" [membresia]="membresia" [RoleUser]="RoleUser" [totalAmbulancia]="totalAmbulancia" [servicios]="servicios"></app-tabla-service>
            <!-- TERMINA LA TABLA DE LOS DEMAS SERVICIOS  -->
            <app-ambulancia *ngIf="showAmbulanacia" [ambulanciaSI]="ambulanciaSI" [showAmbulanacia2]="false"></app-ambulancia>
        </div>
    </div>
</div>
