import { Component, Input , OnInit } from '@angular/core';

@Component({
  selector: 'app-ficha-info-socio',
  templateUrl: './ficha-info-socio.component.html',
  styleUrls: ['./ficha-info-socio.component.css']
})
export class FichaInfoSocioComponent implements OnInit {

  public fecha:Date;
  @Input() sede= {
    encargado: "",
    localizacion: "",
    nomenclatura: "",
    rfc: "",
    _id: ""
  };

  constructor() { 
    this.fecha = new Date();
    this.fecha.getHours();
    this.fecha.getDay();
  }

  ngOnInit(): void {
  }

}
