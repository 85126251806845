import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {   getDataStorage } from '../../functions/storage.funcion'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedicosGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      
      try {
        let usuarioRole = getDataStorage().role;
        if( usuarioRole == 'Medico' || usuarioRole == 'Admin' ){
  
          return true;
        }else{
          return false;
        } 
      } catch (error) {
        
        return true;
      }
  }
  
}
