import jsPDF from "jspdf";
import * as moment from "moment";

export function generarPdfComponentes(objeto:any){
    const doc:any = new jsPDF( {
      unit: "cm",
      format: 'a4',
    });
    let imgLabs = '../../../../../assets/images/bs-1-hero.png';
    let fooLabs = '../../../../../assets/images/lineas-bs.png';
    let marca = '../../../../../assets/images/transfu.png';
    let fecha= moment().format('l');    
        
    doc.addImage(imgLabs, 'PNG', 14, 1.5, 5, 2);
    doc.addImage(marca, 'PNG', 1.5, 12, 17.5, 7);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(9);
    doc.setFont('helvetica')
    doc.setFont(undefined, 'bold')
    doc.text('ENVIO DE COMPONENTES SANGUÍNEOS', 10, 2.5, { maxWidth: 15, align: 'center' });
    doc.text('BANCO DE SANGRE CENSUR',10, 3,{ maxWidth: 15, align: 'center' });
    
    doc.text('BANCO DE SANGRE CENSUR',1, 4);
    doc.text('LUGAR Y FECHA: TLAYACAPAN', 12, 4);
    doc.text(fecha, 17.1, 4);

    doc.text('UNIDAD RECEPTORA', 10, 5, { maxWidth: 15, align: 'center' });

    doc.text('INSTITUTO/UNIDAD MEDICA RECEPTORA:',1, 5.8);
    doc.text('DOMICILIO:', 12, 5.8);

    doc.text('DATOS DE UNIDAD/COMPONENTE', 10, 7, { maxWidth: 15, align: 'center' });

    doc.text('NUMERO PROGRESIVO DE DONANTE/UNIDAD:',1, 8);
    doc.setFont(undefined, 'normal')
    doc.text(objeto.numeroProgresivoDonante,1, 8.4);
    doc.setFont(undefined, 'bold')
    doc.text('NOMBRE DEL DONANTE:', 12, 8);
    doc.setFont(undefined, 'normal')
    doc.text(objeto.nombreDonante.toUpperCase(), 12, 8.4);
    doc.setFont(undefined, 'bold')

    doc.text('HEMOCOMPONENTE: '+objeto.hemocomponente,1, 9);
    doc.text('GRUPO Y RH: '+objeto.grupoRh, 12, 9);

    doc.text('SEROLOGIA:',1, 10);
    doc.text('HIV',1, 10.5);
    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('NO',1, 11);
    doc.text('REACTIVO',1, 11.3);
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')
    doc.text('VHB',2.5, 10.5);
    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('NEGATIVO',2.5, 11);
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')
    doc.text('VHC',4, 10.5);
    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('NEGATIVO',4, 11);
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')
    doc.text('T.PALLIDIUM',5.5, 10.5);
    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('NEGATIVO',5.5, 11);
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')
    doc.text('T.CRUZI',8, 10.5);
    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('NEGATIVO',8, 11);
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')
    doc.text('BRUC',9.5, 10.5);
    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('NEGATIVO',9.5, 11);
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')

    doc.text('FECHA DE EXTRACCIÓN: '+objeto.fechaExtraccion, 12, 10);
    doc.text('FECHA DE CADUCIDAD: '+objeto.fechaCaducidad, 12, 11);

    doc.text('VOLUMEN DE UNIDAD: '+objeto.volumenUnidad,1, 12);
    doc.text('Hb/Hto: ' + objeto.hbHto, 12, 12);

    doc.text('FECHA Y HORA DE SALIDA: ',1, 13);
    doc.text('FECHA Y HORA DE RECEPCION:', 12, 13);

    doc.text('TEMPERATURA. DE SALIDA:',1, 14);
    doc.text('TEMP. DE RECEPCION:', 12, 14);

    doc.text('DATOS DE PERSONAL RESPONSABLE', 10, 15, { maxWidth: 15, align: 'center' });

    doc.text('NOMBRE Y FIRMA DE QUIEN PREPARO UNIDADES:',1, 16);
    doc.text('_______________________________________________',1, 16.5);
    doc.setFontSize(8);
    doc.text('Apellido paterno, materno y (Nombre(s)',1, 17);
    doc.setFontSize(9);

    doc.text('NOMBRE Y FIRMA DE QUIEN REALIZA ENVIO DE UNIDADES: ',1, 17.5);
    doc.text('_______________________________________________',1, 18);
    doc.setFontSize(8);
    doc.text('Apellido paterno, materno y (Nombre(s)',1, 18.5);
    doc.setFontSize(9);

    doc.text('NOMBRE Y FIRMA DE QUIEN RECIBE UNIDADES: ',1, 19);
    doc.text('_______________________________________________',1, 19.5);
    doc.setFontSize(8);
    doc.text('Apellido paterno, materno y (Nombre(s)',1, 20);
    doc.setFontSize(9);

    doc.text('OBSERVACIONES: ',1, 21);

    doc.setFontSize(7);
    doc.setFont(undefined, 'normal')
    doc.text('La Transfusión de algún componente sanguíneo lleva inherente un alto riesgo de complicaciones por la introduccion de un tejido extraño para el receptor, por lo que se pueden presentarse una serie de efectos adversos inmediatos o tardíos producidos por mecanismos inmunológicos o no inmunológidos (reacciones transfusionales), las cuales se pueden presentar entre 0.5 y 3 %, la responsabilidad en la noticifación del evento y tratamiento, pertenece al hospital que transfunda el hemocomponentes y equipo de colaboradores.',1, 24.7, { maxWidth: 18, align: 'justify' });
    doc.setFontSize(9);
    doc.setFont(undefined, 'bold')

    doc.text('_______________________________________',7, 26.5);
    doc.text('Nombre y Firma de familiar o paciente',7.6, 27);
        
    doc.addImage(fooLabs, 0, 27.8, 18, 1)
    window.open(doc.output('bloburl', '_blank'));
   //return doc.output('blob');
   // * linea que nos devulve archivo en tipo FILE
}