<form #form="ngForm" [formGroup]="productosStock" autocomplete="off">
    <div class="row mt-2 principal">
        <div class="col-md-12">
            <div class="row mb-2">
                <div class="col-md-2">
                    <p class="text-body color-text-black">
                        COSTO
                    </p>                               
                </div>
                <div class="col-md-4">
                    <input type="number" min="0" formControlName="costoReal" (change)="enviarDatos(form)"/>
                </div>
                <div class="col-md-2">
                    <p class="text-body color-text-black">
                        PRECIO
                    </p>                               
                </div>
                <div class="col-md-4">
                    <input type="number" min="1" formControlName="precioVenta" (change)="enviarDatos(form)"/>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row mb-2">
                <div class="col-md-2">
                    <p class="text-body color-text-black">
                        LOTE
                    </p>
                </div>
                <div class="col-md-4">
                    <input type="text" formControlName="lote" (change)="enviarDatos(form)"/>
                </div>
                <div class="col-md-2">
                    <p class="text-body">
                        CANTIDAD
                    </p>
                </div>
                <div class="col-md-4">
                    <input type="number" min="1" formControlName="cantidad" (change)="enviarDatos(form)"/>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="row mb-2">
                <div class="col-md-2">
                    <p class="text-body">
                        PROVEEDOR
                    </p>
                </div>
                <div class="col-md-4">
                    <select formControlName="proveedor" (change)="enviarDatos(form)">
                        <option value=""  > Selecciona un proveedor </option>
                        <option [value]="proveedor.razonSocial"  *ngFor="let proveedor of proveedores">  {{ proveedor.nombreEmpresa }} </option>
                    </select>
                </div>
                <div class="col-md-2">
                    <p class="text-body color-text-black">
                        FECHA DE CADUCIDAD
                    </p>                               
                </div>
                <div class="col-md-4">
                    <input type="date" formControlName="fecha_caducidad" (change)="enviarDatos(form)"/>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-2">
                    <p class="text-body">
                        FACTURA
                    </p>
                </div>
                <div class="col-md-4">
                    <input type="text" formControlName="factura"  (change)="enviarDatos(form)"/>
                </div>
            </div>
        </div>
    </div>
</form>