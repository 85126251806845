<div class="content">
    <form #form='ngForm'>
        <p> REACCIÓN POR VENOPUNCIÓN <span>(LEVE)</span> </p>
        <div>
            <input type="radio" value="HEMATOMA CIRCUNSCRITO AL SITIO DE PUNCIÓN (DIÁMETRO 1CM)" name="HEMATOMA CIRCUNSCRITO AL SITIO DE PUNCIÓN (DIÁMETRO 1CM)" ngModel (change)="VenopuncionLeve($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> HEMATOMA CIRCUNSCRITO AL SITIO DE PUNCIÓN (DIÁMETRO 1CM)</label>
        </div>
        <div>
            <input type="radio" value="INFLAMACIÓN CIRCUNSCRITA AL SITIO DE PUNCIÓN" name="INFLAMACIÓN CIRCUNSCRITA AL SITIO DE PUNCIÓN" ngModel (change)="VenopuncionLeve($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> INFLAMACIÓN CIRCUNSCRITA AL SITIO DE PUNCIÓN</label> 
        </div>
                
        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasLeves" id="otrosSintomasLeves" cols="30" rows="10" ngModel (blur)="otroleve(form)"></textarea>
        </div>

        <p> REACCIÓN POR VENOPUNCIÓN <span>(MODERADO)</span> </p>
        <div>    
            <input type="radio" value="HEMATOMA CON DIÁMETRO SUPERIOR A 1CM" name="HEMATOMA CON DIÁMETRO SUPERIOR A 1CM" ngModel (change)="VenopuncionModerada($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> HEMATOMA CON DIÁMETRO SUPERIOR A 1CM</label>
        </div>
        <div>
            <input type="radio" value="INFLAMACIÓN EXTENDIDA MÁS ALLÁ DE LA FOSA ANTECUBITAL" name="INFLAMACIÓN EXTENDIDA MÁS ALLÁ DE LA FOSA ANTECUBITAL" ngModel (change)="VenopuncionModerada($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> INFLAMACIÓN EXTENDIDA MÁS ALLÁ DE LA FOSA ANTECUBITAL</label> 
        </div>
        <div>
            <input type="radio" value="DERMATITIS" name="DERMATITIS" ngModel (change)="VenopuncionModerada($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DERMATITIS</label> 
        </div>
        
        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasModerados" id="otrosSintomasModerados" cols="30" rows="10" ngModel (blur)="otroModerado(form)"></textarea>
        </div>  

        <p> REACCIÓN POR VENOPUNCIÓN <span>(SEVERA)</span> </p>
        <div class="row">
            <div class="col">
                <div>
                    <input type="radio" value="CELULITIS" name="CELULITIS" ngModel (change)="VenopuncionSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CELULITIS</label>
                </div>
                <div>
                    <input type="radio" value="SÍNDROME COMPARTIMENTAL" name="SÍNDROME COMPARTIMENTAL" ngModel (change)="VenopuncionSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SÍNDROME COMPARTIMENTAL</label> 
                </div>
            </div>
            <div class="col">
                <div>
                    <input type="radio" value="CAUSALGIA" name="CAUSALGIA" ngModel (change)="VenopuncionSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CAUSALGIA</label>
                </div>
                <div>
                    <input type="radio" value="FÍSTULA ARTERIO-VENOSA" name="FÍSTULA ARTERIO-VENOSA" ngModel (change)="VenopuncionSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> FÍSTULA ARTERIO-VENOSA</label> 
                </div>
            </div>
        </div>

        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasSevero" id="otrosSintomasSevero" cols="30" rows="10" ngModel (blur)="otroSevero(form)"></textarea>
        </div>
    </form>
</div>