import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cargar-otros-estudios',
  templateUrl: './cargar-otros-estudios.component.html',
  styleUrls: ['./cargar-otros-estudios.component.css']
})
export class CargarOtrosEstudiosComponent implements OnInit {

  public id = '';
  public imagenes = [];
  public idVendedor;
  public deshabilitarBoton = false;
  public otrosEstudios:any[]=[];

  constructor(public _pacienteService : PacientesService, 
              private _route: ActivatedRoute,
              public _router: Router,
              public _servicios:IntegradosService,
              private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.idVendedor = JSON.parse(localStorage.getItem('usuario'))._id;
  }

  //Archivos del DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[0].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  agregar(form:NgForm){
    this.spinner.show();
    this.deshabilitarBoton = true;
    if(form.form.status != 'INVALID'){
      if(this.imagenes.length >= 1){
        let fecha = moment().format('l');  
        let data = Object.assign(form.form.value,
                                 {imageUrl:this.imagenes},
                                 {vendedor:this.idVendedor},
                                 {idPaciente:this.id},
                                 {fechaCargaEsudio:fecha});
        try {
          this._servicios.agregarOtrosEstudios(data).subscribe((resp:any)=>{
            form.resetForm();
            this.imagenes=[];
            this.files=[];
            this.deshabilitarBoton = false;
            this.spinner.hide();
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'SE GUARDO CORRECTAMENTE EL ESTUDIO',
            })
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'NO SE GUARDO EL ESTUDIO',
          })
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'ES NECESARIO SUBIR AL MENOS UNA IMAGEN',
        })
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'ES NECESARIO LLENAR TODOS LOS CAMPOS',
      })
    }
  }
}
