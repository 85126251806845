<div class="container-fluid">
    <form #form='ngForm'>
        <div class="row">
            <div class="col">
                <div>
                    <div class="form-row">
                        <div class="form-check form-check-inline ">
                            <p style="margin-top: 1rem;">¿Embarazada Actualmente?</p>
                            <input class="form-check-input" style="margin-left: 1rem;" type="radio" name="embarazoActual" [(ngModel)]="agregarGinecoObstetricosPorVisita.embarazoActual" id="eASi" value="Si">
                            <p class="form-check-label" for="eAsi">Si</p>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" [(ngModel)]="agregarGinecoObstetricosPorVisita.embarazoActual"  type="radio" name="embarazoActual" id="eASi" value="No">
                            <p class="form-check-label"  for="eANo">No</p>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-check form-check-inline ">
                            <p for=" " style="margin-top: 1rem;">¿Embarazada de Alto Riesgo?</p>
                            <input class="form-check-input" style="margin-left: 1rem;" type="radio" name="embarazoAltoRiesgo" [(ngModel)]="agregarGinecoObstetricosPorVisita.embarazoAltoRiesgo" id="alRSi" value="Si">
                            <p class="form-check-label"  for="alRSi">Si</p>
                        </div>
                        <div class="form-check form-check-inline ">
                            <input class="form-check-input" type="radio" name="embarazoAltoRiesgo" [(ngModel)]="agregarGinecoObstetricosPorVisita.embarazoAltoRiesgo" id="alRSi" value="No">
                            <p class="form-check-label" for="alRNo">No</p>
                        </div>
    
                    </div>
                    <div class="form-row" id="admin">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <p for=" " style="margin-top: 1rem;">Administración de: </p>
                                <input type="text" class="form-control antecedentes-inputs" name="fechaAdmonHierro" [(ngModel)]="agregarGinecoObstetricosPorVisita.fechaAdmonHierro" [value]="agregarGinecoObstetricosPorVisita.fechaAdmonHierro" placeholder="Hierro ">
                                <input type="text" class="form-control" name="administracionHierroAcidoF" [(ngModel)]="agregarGinecoObstetricosPorVisita.administracionHierroAcidoF" [value]="agregarGinecoObstetricosPorVisita.administracionHierroAcidoF"  placeholder="A. Folico">
                            </div>
                        </div>
                    </div>
                    <div class="form-row" id="fecha">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline">
                                <p for=" " style="margin-top: 1rem;">Fecha: </p>
                                <input type="date" name="fechaAdmonHierroAcidoF" [(ngModel)]="agregarGinecoObstetricosPorVisita.fechaAdmonHierroAcidoF" [value]="agregarGinecoObstetricosPorVisita.fechaAdmonHierroAcidoF"  class="form-control">
                                <input type="date" name="fechaAdmonAcido" [(ngModel)]="agregarGinecoObstetricosPorVisita.fechaAdmonAcido" [value]="agregarGinecoObstetricosPorVisita.fechaAdmonAcido" class="form-control">
                            </div>
                        </div>
                    </div>
    
                    <div class="form-row ">
    
                        <div class="form-check form-check-inline">
                            <p for=" " style="margin-top: 1rem;">¿Método anticonceptivo después del parto?</p>
                            <input class="form-check-input" style="margin-left: 5px;" type="radio" name="metodoAntiConceptivoPostParto" [(ngModel)]="agregarGinecoObstetricosPorVisita.metodoAntiConceptivoPostParto" id="metSi" value="Si">
                            <p class="form-check-label" for="metSi">Si</p>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="metodoAntiConceptivoPostParto" [(ngModel)]="agregarGinecoObstetricosPorVisita.metodoAntiConceptivoPostParto" id="metSi " value="No">
                            <p class="form-check-label " for="metNo ">No</p>
                        </div>
    
                    </div>
    
                    <div class="form-row" id="vidaSexual">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <p for=" " style="margin-top: 1rem;">Inicio de la vida sexual activa: </p>
                                <input type="text" name="incioVidaSexualActiva" [(ngModel)]="agregarGinecoObstetricosPorVisita.incioVidaSexualActiva" [value]="agregarGinecoObstetricosPorVisita.incioVidaSexualActiva" class="form-control antecedentes-inputs">
                            </div>
                        </div>
                    </div>
    
                    <div class="form-row" id="fechaUltimoP">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <p for=" " style="margin-top: 1rem;">Fecha del último parto: </p>
                                <input type="date" name="fechaUltimoParto" [(ngModel)]="agregarGinecoObstetricosPorVisita.fechaUltimoParto" [value]="agregarGinecoObstetricosPorVisita.fechaUltimoParto" class="form-control ">
                            </div>
                        </div>
                    </div>
    
                    <div class="form-row " id="crecimiento">
                        <div class="form-group col-md6" >
                            <div class="form-check form-check-inline ">
                                <p for=" " style="margin-top: 1rem;">Crecimiento de vellos pubicos: </p>
                                <input type="text" name="crecimientoBellosPubicos" [(ngModel)]="agregarGinecoObstetricosPorVisita.crecimientoBellosPubicos" [value]="agregarGinecoObstetricosPorVisita.crecimientoBellosPubicos" class="form-control ">
    
                            </div>
                        </div>
                    </div>
    
                    <div class="form-row" id="tipoMetodo">
                        <div class="form-group col-md6 ">
                            <div class="form-check form-check-inline ">
                                <p for=" " style="margin-top: 1rem;">Tipo de método de planif. </p>
                                <input type="text" name="tipoMetodoPlanFamiliar" [(ngModel)]="agregarGinecoObstetricosPorVisita.tipoMetodoPlanFamiliar" [value]="agregarGinecoObstetricosPorVisita.tipoMetodoPlanFamiliar" class="form-control ">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-row" id="gestas">
                    <div class="form-group col">
                        <p for="gesta">Gestas</p>
                        <input type="text" name="gestas" [(ngModel)]="agregarGinecoObstetricosPorVisita.gestas" [value]="agregarGinecoObstetricosPorVisita.gestas" class="form-control " id="">
                    </div>
                    <div class="form-group col">
                        <p for="partos">Partos</p>
                        <input type="text" name="partos" [(ngModel)]="agregarGinecoObstetricosPorVisita.partos" [value]="agregarGinecoObstetricosPorVisita.partos" class="form-control " id="">
                    </div>
                    <div class="form-group col">
                        <p for="cesareas">Cesáreas</p>
                        <input type="text" name="cesareas" [(ngModel)]="agregarGinecoObstetricosPorVisita.cesareas" [value]="agregarGinecoObstetricosPorVisita.cesareas" class="form-control " id="">
                    </div>
                    <div class="form-group col">
                        <p for="abortos">Abortos</p>
                        <input type="text" name="abortos" [(ngModel)]="agregarGinecoObstetricosPorVisita.abortos" [value]="agregarGinecoObstetricosPorVisita.abortos" class="form-control " id="">
                    </div>
                </div>
                <div class="form-row " id="papanicolao">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <p for=" ">Último Papanicolao: </p>
                            <input type="text" name="ultimoPapanicolao" [(ngModel)]="agregarGinecoObstetricosPorVisita.ultimoPapanicolao" [value]="agregarGinecoObstetricosPorVisita.ultimoPapanicolao" class="form-control antecedentes-inputs ">
                        </div>
                    </div>
                </div>
                <div class="form-row" id="colpo">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <p for=" ">Última Colposcopía: </p>
                            <input type="text" name="ultimaColposcopia" [(ngModel)]="agregarGinecoObstetricosPorVisita.ultimaColposcopia" class="form-control antecedentes-inputs ">
                        </div>
                    </div>
                </div>
                <div class="form-row " id="revision">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <p for=" ">Última revisión mamaria: </p>
                            <input type="text" name="ultimaRevisionMamaria" [(ngModel)]="agregarGinecoObstetricosPorVisita.ultimaRevisionMamaria" class="form-control antecedentes-inputs ">

                        </div>
                    </div>
                </div>
                <div class="form-row" id="nacidos">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <p for=" " style="margin-top: 1rem;">Nacidos: </p>
                            <input type="text" name="bebesNacidos" [(ngModel)]="agregarGinecoObstetricosPorVisita.bebesNacidos" class="form-control antecedentes-inputs " placeholder="Vivos ">
                            <input type="text" name="bebesMuertos" [(ngModel)]="agregarGinecoObstetricosPorVisita.bebesMuertos" class="form-control " placeholder="Muertos ">
                        </div>
                    </div>
                </div>
                <div class="form-row " id="primera">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <p for=" ">1era Menstruación: </p>
                            <input type="text" name="primeraMenstruacion" [(ngModel)]="agregarGinecoObstetricosPorVisita.primeraMenstruacion" class="form-control antecedentes-inputs ">

                        </div>
                    </div>
                </div>
                <div class="form-row ">
                    <div class="form-group col-md6 " id="pechos">
                        <div class="form-check form-check-inline ">
                            <p for=" ">Crecimiento de Pechos: </p>
                            <input type="text" name="crecimientoDePechos" [(ngModel)]="agregarGinecoObstetricosPorVisita.crecimientoDePechos" class="form-control antecedentes-inputs ">

                        </div>
                    </div>
                </div>
                <div class="form-row ">
                    <div class="form-group col-md6 " id="primerEmbarazo">
                        <div class="form-check form-check-inline ">
                            <p for=" ">Primer Embarazo: </p>
                            <input type="text" name="primerEmbarazo" [(ngModel)]="agregarGinecoObstetricosPorVisita.primerEmbarazo" class="form-control antecedentes-inputs">

                        </div>
                    </div>
                </div>
                <div class="form-row " id="fechas">
                    <div class="form-group col-md6 ">
                        <div class="form-check form-check-inline ">
                            <p for=" " style="margin-top: 1rem;">Fecha: </p>
                            <input type="date" name="fechaPrimerEmbarazo" [(ngModel)]="agregarGinecoObstetricosPorVisita.fechaPrimerEmbarazo" class="form-control ">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <button class="boton" (click)="agregarGinecoObstetricos(form)">Guardar</button>
                <button class="boton2" (click)="actualizarGinecoPorVisitalt(form)">Actualizar</button>
            </div>
        </div>
    </form>
</div>
