import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actualizar-consultas-enfermeria',
  templateUrl: './actualizar-consultas-enfermeria.component.html',
  styleUrls: ['./actualizar-consultas-enfermeria.component.css']
})
export class ActualizarConsultasEnfermeriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
