import { Component, OnInit, OnDestroy } from '@angular/core';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import { LoginService  }  from  '../../services/login.service';
import  PacienteStorage from '../../classes/pacientes/pacientesStorage.class';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
  
})
export class PageComponent implements OnInit {

  public usuario;
  public chatModal = false;

  
  constructor(
    public wsLogin:WsLoginService,
    public _loginService: LoginService
    // private WSLaboratorio: LaboratorioService
  ) { }


  ngOnInit() {
    this.getRole()
    this.wsLogin.mostarUsuario();
    // this.removePacienteStorage();
  }
  /* ngOnDestroy(){
    this._loginService.logout();
  } */

  removePacienteStorage(){
    const eliminarPacienteStorage  = new PacienteStorage();
    eliminarPacienteStorage.removePacienteStorage();
    /* window.location.reload(); */
  }

  getRole(){

    this.usuario =  JSON.parse (localStorage.getItem('usuario'));
    // console.log( this.usuario );
    if( this.usuario == null || this.usuario == undefined ){
        this._loginService.logout();
        /* window.location.reload(); */
        this.wsLogin.adios();
        return;
    }else {

      this.wsLogin.login( this.usuario );
      
    }
    // this.verPedido();
  }

  // verPedido(){
  //   // this.WSLaboratorio.verPedido();
  // }

  estadoChat(event ){

      // console.log( event.user.usuario  );
      this.chatModal = true;
  }

  abrirVentanaChat(event){
    // console.log(event);
    this.chatModal = true;

  }

  cerrarVentanaChat(event){

    this.chatModal = false;

  }

}
