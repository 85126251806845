import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControlName, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {  Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'formsede',
  templateUrl: './agregar-sede.component.html',
  styleUrls: ['./agregar-sede.component.css']
})
export class AgregarSedeComponent implements OnInit {


  formsede: FormGroup
  submitted = false;
  public asentamiento = [];

  public municipios = [];
  public estadocp = "";
  public codigoPostal = "";
  public zipCode = [];
  fechaNacimientoNgForm:any 
  datosfiscales = 'opcion_2'
  files: File[] = [];
  files2: File[] = [];
  nomImg: any[] = [];
  imagen = [];
  documentos = [];

  tamanoTotal: number = 0;
  tamanoArchivo: number = 0
  constructor(private formBuilder: FormBuilder,public _BancoService: BancoService, private _router: Router) {
    this.formsede= this.formBuilder.group({
      imagencli: [[], ],
      clinica: ['', [Validators.required]],
      razon :['', [Validators.required]],
      rfc:['', [Validators.required, Validators.minLength(12)]],
      responsable_sa:['', [Validators.required]],
      contacto:['', [Validators.required]],
      genero:['', [Validators.required]],
      fecha:['', [Validators.required]],
      telefono:['', [Validators.required,Validators.minLength(10)]],
      usuario:['', [Validators.required]],
      contrasena:['', [Validators.required]],
      correo:['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/)]],
      responsable_si:['', [Validators.required]],
      calle:['', [Validators.required]],
      no_exterior:['', [Validators.required]],
      postal:['', [Validators.required]],
      pais:['', [Validators.required]],
      estado:['', [Validators.required]],
      municipio:['', [Validators.required]],
      ref_1:['', ],
      ref_2:['', ],
      razon_em:['', [Validators.required]],
      rfc_em:['', [Validators.required]],
      postal_em:['', [Validators.required]],
      estado_em:['', [Validators.required]],
      municipio_em:['', [Validators.required]],
      calle_em:['', [Validators.required]],
      exterior_em:['', [Validators.required]],
      correo_em:['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/)]],
      archivos:[[],],
      codsede:['']

    });
  }

  

  onsavesede() {
    var codcli;

    this.formsede.value['archivos'] = this.documentos;

    codcli= this.formsede.value['clinica'].substring(0,4);

    this.formsede.value['imagencli'] = this.imagen;
    this.formsede.value['codsede'] = codcli;
    if (this.formsede.valid) {
      let form = Object.assign(this.formsede.value,{sede:this.formsede.value.codsede})
      /* this._BancoService.agregarsedes(form).subscribe(( data:any ) => {
      console.log(this.formsede.value)
      if(data.ok) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SEDE REGISTRADA CORRECTAMENTE',
          });
          /* console.log(data);
          this._router.navigateByUrl('/listado/sedes/banco/sangre')
        }
      }); */
    }else{
      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'COMPLETA EL REGISTRO',
      });
    }
  }
  fileToBase64 = (file:File):Promise<string> => {
    
    return new Promise<string> ((resolve,reject)=> {
          
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }
  onSelect2(event) {
    this.files2.push(...event.addedFiles);
    if (this.files2 && this.files2[0]) {
      for (let i = 0; i < this.files2.length; i++) {
        

       this.fileToBase64(this.files2[i])
        .then(result=>{
          const base64String = result
          this.documentos.push( {name:this.files2[i].name,base:base64String} );
        });         
      }
    }
  }
  onSelect(event) {
    this.files.push(...event.addedFiles);
    
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagen.push( {name:this.files[0].name, base: base64String} );
        });         
      }
    }
  }
  cleaninpt(){
    this.formsede.patchValue({
      razon_em:'',
      rfc_em:'',
      postal_em:'',
      estado_em:'',
      municipio_em:'',
      calle_em:'',
      exterior_em:'',
      correo_em:'',
    })

  
  }
  onRemove(event) {
    this.imagen.splice(this.imagen.indexOf(event), 1)
    this.files.splice(this.files.indexOf(event), 1);
  }
  onRemoves(event) {
    this.documentos.splice(this.documentos.indexOf(event), 1)
    this.files2.splice(this.files2.indexOf(event), 1);
  }
  ngOnInit(): void {
    
    
  }
  
  estado(event){
    var estado = event
    this.formsede.patchValue({
      entidadNacimiento: estado,
    })
  
  }
 
  
  cogidoPostal(event,event2){
    this.estadocp = event2
    this.codigoPostal = event
    this.municipios = []
    this.asentamiento = []
    this.estadocp = ''

    if (event2 == false) {
      let data = {
        estado: '',
        codigoPostal: event
      }
      this._BancoService.setCodigoPostal(data)
      .subscribe((resp:any) => { 
        this.estadocp = resp.data[0].cpEstado

        this.formsede.patchValue({
          estado: this.estadocp,
        })

        var result = resp.data.reduce(function (r, a) {
          r[a.cpMunicipio] = r[a.cpMunicipio] || [];
          r[a.cpMunicipio].push(a); 
          return r;
        },Object.create(null))
        for (const key in result) {
          if (result[key] != undefined) {
            this.municipios.push(key)
          }
        }

        resp.data.forEach(element => {
          this.asentamiento.push(element.cpAsentamiento)
        });

      })

    }

    if (event == false) {
      this.municipios = []
      this.asentamiento = []
      let data = {
        estado: event2,
        codigoPostal: ''
      }
      this._BancoService.setCodigoPostal(data)
      .subscribe((resp:any) => { 
        this.formsede.patchValue({
          estado: this.estadocp,
        })
        this.zipCode = resp.data
        var result = resp.data.reduce(function (r, a) {
          r[a.cpMunicipio] = r[a.cpMunicipio] || [];
          r[a.cpMunicipio].push(a); 
          return r;
        },Object.create(null))
        for (const key in result) {
          if (result[key] != undefined) {
            this.municipios.push(key)
          }
        }
      })
    }
  }
  pais(event){
    var pais = event
    this.formsede.patchValue({
      pais: pais,
    })
  }
  municipio(event){
    this.formsede.patchValue({
      municipio: event,
    })
  }
  municipio2(event){
    this.zipCode.forEach(element => {
      if (element.cpMunicipio == event) {
        this.asentamiento.push(element.cpAsentamiento)
      }
    });
    this.formsede.patchValue({
      calle: event,
    })
  }
  ciudad(event){
    this.formsede.patchValue({
      calle: event,
    })
  }
  ciudad2(event){
  this.zipCode.forEach(element => {
      if (element.cpAsentamiento == event) {
        this.formsede.patchValue({
          postal: element.codigoPostal
        })
      }
    });
    this.formsede.patchValue({
      calle: event,
    })
  }
  domicilioFiscal(event){
    this.datosfiscales = event
  }
  getErrores(campo: string) {
    return this.formsede.controls[campo].errors && this.formsede.controls[campo].touched  ;
  }
  
 
}
