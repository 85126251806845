<div class="container-fluid">

    <div class="row">

        <div class="col-md-3">
            <img src="../../../../../assets/images/bs-1-hero.png"  class="img-fluid img-left hide" />

        </div>

        <div class="col-md-9">
            <img src="../../../../../assets/images/lineas-bs.png"  class="img-fluid img-widt-100 mt-2 hide" />
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'Hoja de reporte'"> </app-titulos>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-md-12">
            <app-fichas-identificacion [data]="pedido.idPaciente" ></app-fichas-identificacion>
        </div>
    </div>

    <div class="card card-reporte">

        <div class="row">
            <div class="col-md-12">
                <h3 class="title-header text-center mb-4 mt-4" >
                    REPORTE DE PATOLOGIA
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h4 class="pl-3">
                    {{ pedido.estudios[0].nombreEstudio }}

                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-4">
                <h4 class="pl-3">
                    INTERPRETACION
                </h4>
                <p class="pl-5">
                    {{ pedido.idObtenido.interpretacion }}

                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 mt-4">
                <h4 class="pl-3">
                    OBSERVACIONES
                </h4>
                <p class="pl-5">
                    {{ pedido.idObtenido.observaciones }}

                </p>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 mt-4">
                <h4 class="pl-3">
                    DIAGNOSTICO
                </h4>
                <p class="pl-5">
                    {{ pedido.idObtenido.diagnostico }}

                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 offset-md-4 mb-4">
                <button class="btn-censur noprint" (click)="imprimirRegresos()">
                    IMPRIMIR
                </button>
            </div>
        </div>
    </div>
</div>


<div class="row img-footer">
    <div class="col-md-12">
        <img src="../../../../../assets/images/lineas-bs.png"  class="img-fluid img-widt-100 hide" />
    </div>
</div>

