export const filterServicios = (servicios = [], nombre) => {
    const servicosFilter = [];
    servicios.forEach((element:any)=>{
        let est = element.ESTUDIO.toLowerCase();
            if (est.includes(nombre.toLowerCase())) {
                servicosFilter.push(element)
            }  
        
    })
    return servicosFilter;
}

export const filterResultados = (pacientes = [], buscar) => {
    const pacientesFilter = [];
    
    pacientes.forEach((element:any)=>{
        let nombre = element.idPaciente.nombrePaciente.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        let apellidoP = element.idPaciente.apellidoPaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        let apellidoM = element.idPaciente.apellidoMaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        let nombreCompleto = nombre.concat(' ',apellidoP,' ', apellidoM).toLowerCase();
        if (nombreCompleto.includes(buscar.toLowerCase())) {
            pacientesFilter.push(element)
        }  
    })
    return pacientesFilter;
}