import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getDataStorage, getrolefunction } from 'src/app/functions/storage.funcion';

// servicios 
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-biometria-lab-disponente',
  templateUrl: './biometria-lab-disponente.component.html',
  styleUrls: ['./biometria-lab-disponente.component.css']
})
export class BiometriaLabDisponenteComponent implements OnInit {

  public idBanco = "";

  public dataGrupoYRh = [{
    grupo: '',
     elemento: '',
  }];
  
  public paciente={
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
    religion:''
  } 

  public valoresEstudios = {valoresDeReferencia:[{
    grupo: "",
    elemento: "",
    valorDeReferencia: [
      {
        valorReferenciaD: ""
      }
    ],
    "metodo": ""
  }]};



  public sendaDataPost = {
    obtenidos: [],
    metodo: "IMPLEMEDANCIA ELÉCTRICA TIPO V",
    quimico:"",
    tecnico:"",
    cedula:"kjfwekk455s45v",
    pedido: ['Biometria Hematica Completa'],
    idlaboratorio:"61291e62f1f91457f8a98ce7",
    idbancodesangre: "",
    proceso: 'FINALIZADO'
  }

   public sendaDataPostGRH = {
    grupo:"",
    metodo: "AGLUTINACIÓN EN TUBO",
    quimico:"",
    tecnico:"",
    cedula:"kjfwekk455s45v",
    pedido: ['Grupo y Factor RH'],
    idlaboratorio:"61326f058173002364db7c0f",
    idbancodesangre: "",
    idpaciente: "",
    proceso: 'FINALIZADO'
  }


  constructor(
    private _labService: LaboratorioService,
    private _route: ActivatedRoute, 
    private route: Router, 
    private _banco: BancoService
  ) { }

  ngOnInit(): void {
    // obtenemos el ID de la tabla de banco
    this.idBanco = this._route.snapshot.paramMap.get('id');
    this.getDataEstudios();
    this.getRole();
    this.getFIBiometria();
    this.getGrupoYRh();
  }

  //loading
  //LOADING
  pintLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
    /* console.log("toy funcionando weeeee"); */
    
  }

  removeLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
    /* console.log('dejo de funcionar'); */
    
  }

  //obtener los estuios por el id
  getEstudios(){
    this._banco.getEstudiosbyIdlLab('61291e62f1f91457f8a98ce7')
    .subscribe((data:any) => {
      console.log(data)
    } );
  }
  

// obtenemos la FI del paciente 
  getFIBiometria() {
    this._labService.getFichaIdentificacionBiometria(this.idBanco)
    .subscribe((data:any) => 
    {
      this.setFichaIdentificacionBiometria(data.data.paciente)
      this.getIdPaciente(data.data.paciente._id)
    }
    );
  }

  getIdPaciente( id ){
    this.sendaDataPostGRH.idpaciente = id
  }

  // seteamos los datos del paciente
  setFichaIdentificacionBiometria(data: any) {
    /* this.paciente = data; */
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

  // obtenemos el role del paciente
  public getRole() {
    const { nombre, cedula } = getDataStorage();

    this.sendaDataPost.tecnico = nombre;
    this.sendaDataPost.cedula = cedula;
  }

  // obtenemos la informacion de la biometria
  getDataEstudios( ) {
    this._labService
    .getEstudioById('61291e62f1f91457f8a98ce7')
    .subscribe((data:any) => this.setDataEstudios(data.data) ); 
  }

  getGrupoYRh() {
    this._labService.getEstudioById("61326f058173002364db7c0f")
    .subscribe((data:any) => this.setDataGrupoYRh(data.data.valoresDeReferencia));
  }

  setDataGrupoYRh( data: any) {
    this.dataGrupoYRh = data;
  }

  ngSubmitForm( f : NgForm ){
 
    this.sendaDataPost.obtenidos.push(f.value);
    this.sendaDataPost.idbancodesangre = this.idBanco;
    this.sendaDataPostGRH.idbancodesangre = this.idBanco
    this.pintLoadingScreen();
    
    if( f.valid ){
        if( this.sendaDataPostGRH.grupo.length == 0 ){
          
          alert("Selecciona el grupo");
          
            // TODO: AGREGAR LARUTA DE LOS DIFERIDOS

        }else {
          this.sendDataToSavebiometria();
          this.removeLoadingScreen();
        }

    }else {
      
      alert("Completa todos los campos")
    }
  }

  setDataEstudios(data:any) {
    this.valoresEstudios = data;
  }


  sendDataToSavebiometria() {

    this._labService.postBiometriaBanco( this.sendaDataPost )
    .subscribe( (data:any) => {
      /* console.log(data) */
      if (data.ok) {
            this._banco.postGrupoyRHBanco( this.sendaDataPostGRH )
        .subscribe( (data:any) => {
          /* console.log(data) */
          if(data.ok) {
              Swal.fire({
                icon: 'success',
                title: '',
                text: 'Los Resultados se enviaron al doctor para valoración',
              })
            this.route.navigate(['/dashboard']);
          }
        })
      }

    });
  }
  

}