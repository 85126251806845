<div class="container-fluid">
    <div class="row" *ngIf="estudiosPendientes == 'consultas'">
        <div class="col-md-8">
            <!-- <h1 class="centerxd">HISTORIAL DE CONSULTAS</h1> -->
            <app-titulos [title]="'HISTORIAL DE CONSULTAS'" [rol]="'historial'"></app-titulos>
        </div>
    
        <div class="col">
            <button class="boton_contra2" routerLink="/ver/estudios/pendientes/por/aprobar">VER ESTUDIOS</button>
            <!-- <a class="boton_contra2" routerLink="/ver/estudios/pendientes/por/aprobar"> VER ESTUDIOS </a> -->
        </div>
    </div>

    <div class="row" *ngIf="estudiosPendientes == 'estudios'">
        <div class="col-md-12">
            <app-titulos [title]="'ESTUDIOS SOLICITADOS'"></app-titulos>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12" *ngIf="estudiosPendientes == 'consultas'">
            <div class="card" style="border-radius: 30px; margin: 0px 25px">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p># </p>
                        </div>
                        <div class="col text_header">
                            <p>Fecha</p>
                        </div>
                        <div class="col text_header">
                            <p>Hora</p>
                        </div>
                        <div class="col text_header">
                            <p>Paciente</p>
                        </div>
                        <div class="col text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col text_header">
                            <p>Sexo</p>
                        </div>
                        <div class="col text_header">
                            <p>CURP</p>
                        </div>
    
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of consultas | paginate:{id:'lista',
                    itemsPerPage: 5,
                    currentPage:pagina,
                    totalItems:total}; let i = index">
                        <div class="row pointer">
                            <div class="col-md-1" style="text-align: center;">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p> {{ item.fechaIngreso }} </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.horaIngreso}}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno| uppercase }} {{ item.paciente.apellidoMaterno| uppercase }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.paciente.edad }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.paciente.genero | uppercase }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p> {{ item.paciente.curp }}</p>
                            </div>
                            <!-- <div class="col" style="text-align: center;">
                                {{ item.diagnosticoActual}} <br>
                            </div> -->
                            <!-- <div class="col">
                                <p></p>
                            </div> -->
                            <!-- <div class="col" style="text-align: center;">
                                {{ item.enfermeraAtendio }} <br>
                            </div> -->
                            <!-- <div class="col" style="text-align: center;">
                                {{ item.doctorAPasar }} <br>
                            </div>
                            <div class="col" style="text-align: center;">
                                {{ item.observaciones }} <br>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    
        <div class="col-md-12 col-lg-12" *ngIf="estudiosPendientes == 'estudios'">
            <div class="card" style="border-radius: 30px; margin: 0px 25px">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col text_header">
                            <p>Número</p>
                        </div>
                        <div class="col text_header">
                            <p>Fecha</p>
                        </div>
                        <div class="col text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col text_header">
                            <p>Sexo</p>
                        </div>
                        <div class="col text_header">
                            <p>Estudios</p>
                        </div>
                        <div class="col text_header">
                            <p>Origen</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of recetasConEstudios | paginate:{id:'lista',
                    itemsPerPage: 7,
                    currentPage:pagina,
                    totalItems:total}; let i=index">
                        <div class="row pointer" [routerLink]="['/estudios/pedidos/', item._id]">
                            <div class="col" style="text-align: center;">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.fechaReceta }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.idPaciente.nombrePaciente | uppercase }} {{ item.idPaciente.apellidoPaterno| uppercase }} {{ item.idPaciente.apellidoMaterno| uppercase }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.idPaciente.edad }} </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.idPaciente.genero | uppercase}}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.estudios.length }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>{{ item.origen }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior"  class="paginador" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>



<!-- <div class="row">
    <div class="col-md-12 col-lg-12" *ngIf="estudiosPendientes == 'consultas'">
        <table class="table table-hover colors izq" id="bitacora">
            <thead class="center" style="background-color: #03318C;">

                <tr style="color: white;">

                    <th scope="col"> Número</th>
                    <th scope="col"> Fecha </th>
                    <th scope="col ">Hora</th>
                    <th scope="col ">Nombre del Paciente</th>
                    <th scope="col ">Edad</th>
                    <th scope="col ">Sexo</th>
                    <th scope="col ">Diagnostico</th>
                    <th scope="col ">CURP</th>
                    <th scope="col"> Enfermera</th>
                    <th scope="col"> Médico</th>
                    <th scope="col"> Turno</th>
                    <th scope="col"> Observaciones </th>

                </tr>


            </thead>

            <tbody class="center" style="background-color: white;color: black;">

                <tr *ngFor="let item of consultas | paginate:{id:'lista',
                itemsPerPage: 9 ,
                currentPage:pagina,
                totalItems:total}; let i = index">
                    <td> {{ i +1 }} </td>
                    <td> {{ item.fechaIngreso }} </td>
                    <td> {{ item.horaIngreso}} </td>
                    <td> {{ item.paciente.nombrePaciente }} {{ item.paciente.apellidoPaterno }} {{ item.paciente.apellidoMaterno }} </td>
                    <td> {{ item.paciente.edad }} </td>
                    <td> {{ item.paciente.genero | titlecase }} </td>
                    <td> {{ item.diagnosticoActual}} </td>
                    <td> {{ item.paciente.curp }} </td>
                    <td> {{ item.enfermeraAtendio }} </td>
                    <td> {{ item.doctorAPasar }} </td>
                    <td></td>
                    <td> {{ item.observaciones }} </td>
                    <td> {{ item.notaDeLaEnfermera}}</td> 

                </tr>

            </tbody>
        </table>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div> 
    </div>


    <div class="col-md-12" *ngIf="estudiosPendientes == 'estudios'">

        <table class="table table-hover colors izq" id="bitacora">
            <thead style="background-color: #03318C;">

                <tr style="color: white;">
                    <th> Número </th>
                    <th>Fecha</th>
                    <th> Nombre </th>
                    <th> Edad </th>
                    <th> Sexo </th>
                    <th> Estudios </th>
                    <th> Origen </th>
                </tr>

            </thead>
            <tr *ngFor="let item of recetasConEstudios | paginate:{id:'lista',
            itemsPerPage: 10 ,
            currentPage:pagina,
            totalItems:total}; let i = index;">
                <td> {{ i +1 }} </td>
                <td> {{ item.fechaReceta }} </td>
                <td [routerLink]="['/estudios/pedidos/', item._id]"> {{ item.idPaciente.nombrePaciente }} {{ item.idPaciente.apellidoPaterno }} {{ item.idPaciente.apellidoMaterno }} </td>
                <td> {{ item.idPaciente.edad }} </td>
                <td> {{ item.idPaciente.genero }} </td>
                <td> {{ item.estudios.length +1 }} </td>
                <td> {{ item.origen }} </td>
            </tr>
        </table>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>

    </div>


</div> -->