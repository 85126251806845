import { Component, OnInit } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-historico-ultrasonido',
  templateUrl: './historico-ultrasonido.component.html',
  styleUrls: ['./historico-ultrasonido.component.css']
})

export class HistoricoUltrasonidoComponent implements OnInit {
  public usg;
  public ultrasonidos ={
    ESTUDIO:"",
    ELEMENTOS:[{machote:""}],
    _id:"",
    machote:""
  }
  public contador =0;
  public machote:any;
  public totalUlta:string;
  public pagina = 0;

  constructor(private _consumoServicioUSG: IntegradosService,
              private _routeservi : IntegradosService,
              private spinner: NgxSpinnerService) {/* localStorage.removeItem('editado'); */}

  ngOnInit(): void {
    this.spinner.show();
    this.obtenerDataUsg();
    // localStoragremoveIteme.('editado');
  }

  obtenerDataUsg(){
    this._consumoServicioUSG.getObtener('ultrasonido').subscribe((data:any) => {
      this.usg = data['data'];
      this.totalUlta = data['data'].results;
      this.spinner.hide();
      /* console.log( data); */
    })
  }

  setPeidoUltra(pedido){
    this.ultrasonidos =pedido;
    if (this.ultrasonidos.ELEMENTOS[0].machote == undefined){
      /* console.log("no hay nada"); */  
      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'INFORMACION INCOMPLETA',
      });
    }else {
      this.machote = this.ultrasonidos.ELEMENTOS[0].machote;
    } 
    /* console.log(this.machote); */
    localStorage.setItem('editado',JSON.stringify( this.machote));
    // console.log(this.ultrasonidos.ELEMENTOS[0]);
  }

  obtenerPedidosUltra(){
    this._routeservi.buscarServicio (this.usg._id)
    .subscribe((data) => this.setPeidoUltra(data ['data']))
  }

}
