import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { PacientesService } from '../../../services/pacientes/pacientes.service';
import { IdentificacionService } from '../../../services/consultas/identificacion.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { WsLoginService } from 'src/app/services/sockets/chat/ws-login.service';
import {  consultaService}  from '../../../classes/servicios/serviciosConsultas'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ConsultaService } from '../../../services/consultas/consulta/consulta.service';
import { filterNameByPaciente, buscarPaciente} from 'src/app/classes/helpers/filterNamePaciente';
import { NgxSpinnerService } from 'ngx-spinner';
moment.locale('es');

@Component({
  selector: 'app-consuta-gral',
  templateUrl: './consuta-gral.component.html',
  styleUrls: ['./consuta-gral.component.css']
})
export class ConsutaGralComponent implements OnInit {

  public nombrePaciente ="";
  public pacientes:[]=[];
  public idPaciente="";
  public fechaIngreso = moment().format('l');
  public horaIngreso = moment().format('LTS');
  public servicio = "";
  public consultorio = "";
  public consultasCount = {
    consultas: 0
  }
  public AllPacientes = [];
  public serviciosConsultasSelect = [];
  public PACIENTES_SERVICES=[];
  public paciente = {
    // los datos del paciente son solo representacionales
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno:"",
    genero:"",
    curp: "",
    id:"",
    fechaIngreso:this.fechaIngreso,
    horaIngreso: this.horaIngreso,
    enfermeraAtendio:  "" ,
    diagnosticoActual: "",
    notaDeLaEnfermera: "",
    consultorio:"",
    doctorAPasar:'',
    consultas: 0,
    servicio:'',
    motivoIngreso:'',
    notaRecepcion:'',
    turno:'Matutino',
    sede:''
  }
  public pacienteSeleccionado = {}
  public listaEspera:any [] = [];

  constructor(public _pacienteService: PacientesService,
              public _route: ActivatedRoute,
              public _wsLoginService: WsLoginService,
              public _consultasService: ConsultaService ,
              public _router:Router,
              private spinner: NgxSpinnerService){}

  ngOnInit(): void {
    // this.spinner.show();
    this.idPaciente =  this._route.snapshot.paramMap.get('id');
    // console.log(  this.idPaciente );
    this.obtenerConsultas();
    // this.obtenerPacientes()
    this.obtenerServiciosConsulta();
    this.pacienteSeleccionado = JSON.parse(localStorage.getItem('paciente'))
    if (this.pacienteSeleccionado != undefined) {
      this.seleccionarPaciente(this.pacienteSeleccionado)
    }
    // if( this.idPaciente == null ){

    //   return;
    // }else {

    //   this.obtenerPacientePorId();
      
    // }
  }

  // TODO: Se quito para probar la busqueda de pacientes por backend
  // obtenerPacientes() {
  //   this._pacienteService.getPacientesAll()
  //   .subscribe( (data:any) => {
  //     if(data.ok) {
  //       this.PACIENTES_SERVICES = data['data'];
  //       this.spinner.hide();
  //     }
  //   });
  // }

  obtenerPacientePorId(){
    this._pacienteService.getPacienteBtID(  this.idPaciente )
    .subscribe((data:any) => {
      // console.time()
      // console.log(data);
      // console.timeEnd()
      if(data.ok) {
        this.paciente.nombrePaciente = data['paciente']['nombrePaciente'];
        this.paciente.apellidoPaterno = data['paciente']['apellidoPaterno'];
        this.paciente.apellidoMaterno = data['paciente']['apellidoMaterno'];
        this.paciente.curp = data['paciente']['curp'];
        this.paciente.genero = data['paciente']['genero']
        this.paciente.id = data['paciente']['_id'];
      }
    });
  }

  obtenerServiciosConsulta (){
    //obtenemos los servicos de las consultas
    this.serviciosConsultasSelect = consultaService;
    this.serviciosConsultasSelect.sort(( a, b  ) => {
      // comparamos los items del array
      if(  a.name > b.name ) {
        //retornamos los alementos de acuerdoa a su posiocion
        return 1;
      }
      //retonamos los elemenros de la posicion inversa
      return -1
    });
  }

  seleccionarConsultprop( consultorio  ) {
    let selectConsultorio = document.getElementById('consultorioSelect');
    if( consultorio['value']   == "seleccionarConsultorio" ){
      Swal.fire('Error', 'Selecciona un consultorio', 'error');
      /* alert('Selecciona un consultorio'); */
      selectConsultorio.classList.add('is-invalid')
      return;
    }
    this.consultorio = consultorio;
  }

  seleccionarPaciente( item ) {
    this.paciente.nombrePaciente = item['nombrePaciente'];
    this.paciente.apellidoPaterno = item['apellidoPaterno'];
    this.paciente.apellidoMaterno = item['apellidoMaterno'];
    this.paciente.curp = item['curp'];
    this.paciente.genero = item['genero'];
    this.paciente.consultas = item['consultas'];
    this.paciente.id = item['_id'];
    this.AllPacientes = [];
  }

  buscarPaciente(  nombre: string ){
    if(nombre.length >= 3){
      this._pacienteService.buscarPacientesPorNombreBackend({nombre: nombre}).subscribe( (data: any) =>{
        this.AllPacientes = data.data
      });
    }else{
      this.AllPacientes = [];
    }
    // if( nombre.length != 0 ){
    //   if( nombre.length > 3) {
    //     const nameFilter = buscarPaciente( this.PACIENTES_SERVICES, nombre );
    //     nameFilter.reverse();
    //     this.AllPacientes = nameFilter;
    //     nombre = "";
    //   }
    // }else {
    //   this.AllPacientes = [];
    // }
  }

  actualizarConteoConsultas(){
    this.consultasCount.consultas = this.paciente.consultas = this.paciente.consultas + 1;
    this._consultasService.actualizarConteoConsultas(  this.paciente.id, this.consultasCount )
    .subscribe( data => { console.log(data) });
  }

  validarConsultaData() {
    if( this.paciente.servicio === "" ) {
      Swal.fire('Error', 'Selecciona un servicio', 'error');
      return false;
    }else if( this.paciente.doctorAPasar == "" ){ 
      Swal.fire('Error', 'Selecciona el médico', 'error');
      return false;
    }
    return true;
  }

  agregarSede(){
    if(localStorage.getItem('cede') != undefined){
      this.paciente.sede = JSON.parse(localStorage.getItem('cede'))
    }
  }

  enviar(){
    if( this.validarConsultaData() ){
      this.agregarSede();
      this._consultasService.agregarConsulta(  this.paciente )
      .subscribe((data) => {
        if( data['ok'] ){
          this._wsLoginService.enviarConsultas(  data['data']['_id'] );          
          Swal.fire('Exito', 'El paciente pasó a enfemería', 'success');
          this.actualizarConteoConsultas();
          this.PACIENTES_SERVICES = [];
          this._router.navigateByUrl('/consulta');
        }
      });
    } 
  }

  resetPaciente (){
    this.paciente.id = "";
    this.paciente.nombrePaciente = "";
    this.paciente.genero = "";
    this.paciente.apellidoPaterno = "";
    this.paciente.apellidoMaterno = "";
    // this.pa
  }

  obtenerConsultas(){
    this._consultasService.verConsultasPendientesEnfermeria()
    .subscribe( (data) =>{ 
      data['data'].forEach(element => {
        if(element.paciente != null){
          this.listaEspera.push(element)
        }
      });
    });
  }

}
