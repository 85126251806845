import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-endoscopias',
  templateUrl: './reporte-endoscopias.component.html',
  styleUrls: ['./reporte-endoscopias.component.css']
})
export class ReporteEndoscopiasComponent implements OnInit {

  public genero = "M";
  public nombre = "KKKKKKKKKGGGGGGGGGGKKK ";

  constructor() { }

  ngOnInit(): void {
  }

}
