import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LocationServiceService {

  constructor(public http: HttpClient) { }

  getCountries() {
    const URLCountries = "https://countriesnow.space/api/v0.1/countries";
    
    return this.http.get(URLCountries)
  }

  getLocalidades(id_estado: number, id_municipio: number) {

    const URLCODIGOPOSTAL = `https://sepomex.razektheone.com/colonias?id_estado=${id_estado}&id_mun=${id_municipio}`;
    // obtenemos las localidades por el Codigo postal
    //let tipo = '?type=simplified';
    //TOKEN
    //const token = "&token=530d89c8-109c-493c-b697-a0fb6a24c387";
    //let url = `${URLCODIGOPOSTAL}${codigo}${tipo}${token}`;

    return this.http.get(URLCODIGOPOSTAL)

  }

  getEstado(pais:String) {
    const url = "https://countriesnow.space/api/v0.1/countries/states";
    //TOKEN
    //const token = "?token=530d89c8-109c-493c-b697-a0fb6a24c387";
    //return this.http.get(url +  token);
    // return this.http.post(url,{country:pais}).pipe(map((country) => {
    //   return country['data'].states;
    // }));

  }


  getMunicipios(pais,municipio) {
    //obtiene el pais y municipio para poder extraer los municipios
    const url = "https://countriesnow.space/api/v0.1/countries/state/cities";
    //TOKEN
    //const token = "?token=530d89c8-109c-493c-b697-a0fb6a24c387";

    // return this.http.post(url,{country:pais,state:municipio}).pipe(map((municipio) => {
    //   return municipio['data'];
    // }));

  }
}
