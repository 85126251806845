import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class BancoService {
  public url: string;

  private resultadosdash:BS [] =[
    {
      nombre:"DOCUMENTOS MÉDICOS",
      url:"/documentos/receptor",
      estado:"EN ESPERA"
    },
    {
      nombre:"TIPAR Y CRUZAR",
      url:"/tiparcruzar",
      estado:"EN ESPERA"
    },
    {
      nombre:"CONSULTA DE HISTÓRICO",
      url:"/historico",
      estado:"EN ESPERA"
    },
    {
      nombre:"ENTREGA DE PRODUCTOS",
      url:"/productos/receptor",
      estado:"EN ESPERA"
    },
  ]
  private _ActivatedRoute: any;
  constructor(private _http:HttpClient){
    this.url = URL;
  }

/*   getDashServicio(){
    return this.resultadosdash;
  } */

  getOrdenPedido(){
    const url = `${this.url}/orden/receptores`;
    return this._http.get(  url );
  }

  getProductosAlmacen(){
    return this._http.get(this.url+'/productos/censur');
  }
  getDashServicio(body){
        const url = `${this.url}/procesos/orden/censur`;
        return this._http.post( url, body );
  }

  getPedidoReceptor(body){
    const url = `${this.url}/informacion/receptor`;
    return this._http.post( url, body );
  }
  

  getPedidoReceptorPagos(body){
    const url = `${this.url}/informacion/receptor/pedido`;
    return this._http.post( url, body );
  }
  agregarProducto(body){
    const url = `${this.url}/agregar/productodeorigen`;
    return this._http.put( url, body );
  }

  agregarLugar(body){
    const url = `${this.url}/agregar/lugardeorigen`;
    return this._http.put( url, body );
  }

  agregarIdCensur(body){
    const url = `${this.url}/bancodesangre`;
    return this._http.post( url, body );
  }

  agregarCuestionario(body){
    const url = `${this.url}/cuestionario/autoexclusion`;
    return this._http.post( url, body );
  }

  cambiarProceso(body){
    const url = `${this.url}/agregar/procesobancodesangre`;
    return this._http.put( url, body );
  }

  diferirDisponente(body){
    const url = `${this.url}/diferir/disponente`;
    return this._http.post( url, body );
  }

  getEnfermeria(){
    const url = `${this.url}/bitacora/enfermeria`;
    return this._http.get(  url );
  }

  getDisponente(id){
    return this._http.get(this.url+'/getdisponente/'+id);
  }
  getDiferidos(){
    return this._http.get(this.url+'/historico/disponentes/diferidos');
  }

  actualizarReligion(body){
    const url = `${this.url}/actualizar/religion`;
    return this._http.put( url, body );
  }

  actualizarTipoSangre(body){
    const url = `${this.url}/actualizar/tipoSangre/paciente`;
    return this._http.put( url, body );
  }

  agregarSignos(body){
    const url = `${this.url}/agregar/signosvitales`;
    return this._http.post( url, body );
  }

  bitacoraDoctor(){
    const url = `${this.url}/bitacora/doctor`;
    return this._http.get(  url );
  }

  historiaClinica(body){
    const url = `${this.url}/agregar/historiaclinica`;
    return this._http.post( url, body );
  }

  hojaEvolucion(body){
    const url = `${this.url}/agregar/hojadeevolucion`;
    return this._http.post( url, body );
  }

  pedirLaboratoriosDisp(body){
    /* console.log(body); */
    
    const url = `${this.url}/ordenar/laboratorios`;
    return this._http.post( url, body );
  }

  bitacoraLaboratorio(){
    const url = `${this.url}/bitacora/laboratorio`;
    return this._http.get(  url );
  }

  getHojaDeServicioLab(id){
    return this._http.get(this.url+'/hoja/servicio/laboratorio/'+id);
  }

  getProcesosLab(body){
    const url = `${this.url}/laboratorios/servicios/proceso`;
    return this._http.post(  url , body );
  }

  getEstudiosbyIdlLab(id){
     return this._http.get(this.url+'/obtener/estudio/banco/'+id);
  }

  agregarnuevosestudios(body: any) {
    const url = `${this.url}/nuevo/estudio/banco`;
    return this._http.post(url, body);
  }

  numerodeDonadores(){
    return this._http.get(this.url+'/disponentes/contar');
  }

  numeroDonacionesPaciente(body){
    const url = `${this.url}/getdisponente/visitas`;
    return this._http.post(url, body);
  }

  postGrupoyRHBanco(body: any) {

    const url = `${this.url}/analisis/grupo`;
    return this._http.post(url, body);
    
  }

  datosSerologia(body){
    const url = `${this.url}/analisis/serologia`;
    return this._http.post(url, body);
  }

  datosTiparyCruzar(body){
    const url = `${this.url}/analisis/tipeycruce`;
    return this._http.post(url, body);
  }

  getHistoricoLab(body){
    const url = `${this.url}/historico/banco/disponente`;
    return this._http.post(url, body);
  }
  postgetlaboratorios(body: any) {
    const url = `${this.url}/laboratorios/doctor`;
    return this._http.post(url, body);
  }
    postgruposanguineo(body: any) {
    const url = `${this.url}/disponentes/grupoyrh`;
    return this._http.post(url, body);
  }
  getLaboratoriosOrden(body: any) {
    const url = `${this.url}/pedido/laboratorio`;
    return this._http.post(url, body);
  }

  agregarCarrito(body){
    const url = `${this.url}/agergar/carrito/censur`;
    return this._http.post(url, body);
  }

  cambioEstadoCensur( id: string ){
    const url = `${this.url}/actulizar/estado/pedido/censur/${id}`;
    return this._http.put(url, {estado: 'reacciones transfucionales'});
  }
  historicoDonaciones(body){
    const url = `${this.url}/historico/banco/disponente`;
    return this._http.post(url, body);
  }
  validacionDonacion(body){
    const url = `${this.url}/bancos/usuario/nodonacion`;
    return this._http.post(url, body);
  }

  reaccionesDerivadas(body){
    const url = `${this.url}/agregar/reaccionesderivadas`;
    return this._http.post(url, body);
  }

  getHistoricoDonacionesPaciente(id:any){
    return this._http.get(this.url+'/gethistorico/'+id);
  }

  actualizarDocumentosReceptor(id:any,body){
    const url = `${this.url}/actualizar/receptor/documentos/`+id;
    return this._http.put( url, body );
  }

  getPacienteDisponente(id:any){
    const url = `${this.url}/getdisponente/por/paciente/${id}`;
    return this._http.get(  url );
  }

  agregarsedes(body){
    const url = `${this.url}/crear/sede/bs`;
    return this._http.post( url, body );
  }
  getSedesAll(){
  const url = `${this.url}/listado/sedes/banco/sangre`;
   return this._http.get( url);  

  }
  getSedesById(id: string) {

    const url =  `${URL}/detalle/sede/${id}`;
    return this._http.get( url );
  }
  setCodigoPostal( code: any  ){
    const uri = `${this.url}/codigo/postal`;
    return this._http.post( uri, code  );
  }
getSedes(){
    const url = `${URL}/ver/sedes`;
    return this._http.get(url);
  }
  getVenSede(id){
    const url = `${URL}/ver/sedes/${id}`;
    return this._http.get(url);
  }
  // servicio que sube los docuemtos de los receptores
  agregardocuemtosReceptorFirmados( body){
    const url = `${URL}/agregar/documentos/receptor`;
    return this._http.post(url, body);
  }

  verDetallePedidoCensursocio(id){
    const url = `${URL}/ver/pedidos/detalle/${id}`;
    return this._http.get(url);
  }

  getSalidasAlmacen () {
    const url = `${URL}/ver/productos/salidas`;
    return this._http.get(url);
  }
}

export interface BS{
  nombre: string;
  url: string;
  estado: string;
}
