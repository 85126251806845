import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ultimo-anio',
  templateUrl: './ultimo-anio.component.html',
  styleUrls: ['./ultimo-anio.component.css']
})
export class UltimoAnioComponent implements OnInit {

  @Output() ultimo = new EventEmitter<string>();
  formulario: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      tos_disnea: new FormControl('NO'),
      perdida_de_peso: new FormControl('NO'),
      diarrea_cronica: new FormControl('NO'),
      diaforesis_cronica: new FormControl('NO'),
      diaforesis_profusa: new FormControl('NO'),
      astenia: new FormControl('NO'),
      adenomegalias: new FormControl('NO'),
      herpes_mucocutaneo: new FormControl('NO'),
      fiebre_continua: new FormControl('NO'),
      odinofagia_diez_dias: new FormControl('NO'),
      sindrome_diarreico: new FormControl('NO'),
      isotretinoina_ultimo_mes: new FormControl('NO'),
      covid_ultimo_mes: new FormControl('NO'),
      medicamentos_ultima_semana: new FormControl('NO'),
      infecciones_agudas_ultima_semana: new FormControl('NO'),
      fiebre_ultimos_dos_dias: new FormControl('NO'),
      ejercicio_intenso_ultimos_dos_dias: new FormControl('NO'),
      ayuno_ultimos_dos_dias: new FormControl('NO'),
      vigilia_ultimos_dos_dias: new FormControl('NO'),
      ingesta_de_alcohol_dos_dias: new FormControl('NO'),
    });
    this.ultimo.emit(this.formulario.value);
  }

  formularioUltimo(form: NgForm){
    this.ultimo.emit(form.form.value);
  }

}
