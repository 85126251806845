export function farmacia(nombreFarmacia){
    const farma = [
        {
            nombre:"1 Vacuna BSG",
            _id: "",
            name: "farmacia",
            ESTUDIO: "VACUNA BSG",
            precioCon:"0",
            precioSin:"0",
        }
    ];

    let encontrado = farma.find((element) => element.nombre == nombreFarmacia);
    return encontrado;
}
export function hospital(nombreHospi){
    const hospi = [
        {
            nombre:"1 Hospitalización en habitación estándar",
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0",
        },
        {
            nombre:"1 Atención de Cesárea por especialidad en Ginecología y Obstetricia, Anestesista y Pediatra",
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0",
        },
        {
            nombre:"1 Consulta después de Parto o Cesárea con Médico General",
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 Medicamento y material de curación durante la hospitalización", 
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 Atención del parto o cesárea por especialista en Ginecología y obstetricia, anestesia y pediatría",
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 Atención del recién nacido durante el parto o cesárea por el especialista en pediatría",
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 Estancia del recién nacido en la habitación de la madre",
            _id: "",
            name: "hospitalizacion",
            ESTUDIO: "HOSPITALIZACION",
            precioCon:"0",
            precioSin:"0"
        }
    ];

    let encontrado = hospi.find((element) => element.nombre == nombreHospi);
    return encontrado;
}
export function cita(nombreCita){
    const citas = [
        {
            nombre:"9 Consultas programadas con Especialista en Ginecología y Obstetricia con un rastreo de ultrasonido por consulta",
            _id: "5fdd2b41a71eca0017a8e936",
            name: "consulta",
            ESTUDIO: "CONSULTA GINECOLOGIA",
            precioCon:"0",
            precioSin:"0",
        },
        {
            nombre:"1 Consulta con pediatría antes de su Parto a Cesárea",
            _id: "5fdd2bfba71eca0017a8e939",
            name: "consulta",
            ESTUDIO: "CONSULTA PEDIATRIA",
            precioCon:"0",
            precioSin:"0",
        },{
            nombre:"1 Consulta después de Parto o Cesárea con Médico General",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },
        {
            nombre:"Consulta después de Parto o Cesárea con Médico General",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"Cita abierta a Urgencias con Médico General",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },
        {
            nombre:"Cita abierta a urgencias desde la contratación del servicio hasta la atención del parto o cesárea por medicina general",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 Consulta después del parto o cesárea con medicina general",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 consulta con pediatría antes de su parto o cesárea",
            _id: "5fdd2bfba71eca0017a8e939",
            name: "consulta",
            ESTUDIO: "CONSULTA PEDIATRIA",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"6 Consultas con pediatría",
            _id: "5fdd2bfba71eca0017a8e939",
            name: "consulta",
            ESTUDIO: "CONSULTA PEDIATRIA",
            precioCon:"0",
            precioSin:"0"
        },
        {
            nombre:"1 Valoración de recién nacido por pediatría",
            _id: "5fdd2bfba71eca0017a8e939",
            name: "consulta",
            ESTUDIO: "CONSULTA PEDIATRIA",
            precioCon:"0",
            precioSin:"0"
        },
        {
            nombre:"7 Consultas con especialista en Ginecología y obstetricia",
            _id: "5fdd2b41a71eca0017a8e936",
            name: "consulta",
            ESTUDIO: "CONSULTA GINECOLOGIA",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"9 Consultas con especialista en Ginecología y obstetricia",
            _id: "5fdd2b41a71eca0017a8e936",
            name: "consulta",
            ESTUDIO: "CONSULTA GINECOLOGIA",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"3 Consultas con Nutrición",
            _id: "5fdd2a7fa71eca0017a8e930",
            name: "consulta",
            ESTUDIO: "CONSULTA NUTRICION",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"2 Consultas después del parto o Cesárea con Ginecología y Obstetricia.",
            _id: "5fdd2b41a71eca0017a8e936",
            name: "consulta",
            ESTUDIO: "CONSULTA GINECOLOGIA",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"12 Consultas con Especialista en Pediatría",
            _id: "5fdd2bfba71eca0017a8e939",
            name: "consulta",
            ESTUDIO: "CONSULTA PEDIATRIA",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"Cita abierta a Urgencias con Medico General las 24 Horas",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"8 Consultas programadas con Especialista MEDICINA INTERNA",
            _id: "5fdd29e5a71eca0017a8e92c",
            name: "consulta",
            ESTUDIO: "CONSULTA MEDICINA INTERNA",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"Cita abierta a urgencias desde la contratación del servicio por un año con Medicina General.",
            _id: "5fd3ebca08ccbb0017712f0d",
            name: "consulta",
            ESTUDIO: "CONSULTA DE MEDICINA GENERAL",
            precioCon:"0",
            precioSin:"0"
        },{
            nombre:"1 Certificado Médico Escolar",
            _id: "5fd3efec08ccbb0017712f11",
            name: "otros",
            ESTUDIO: "CERTIFICADO MEDICO ESCOLAR",
            precioCon:"0",
            precioSin:"0"
        }
    ];

    let encontrado = citas.find((element) => element.nombre == nombreCita);
    /* delete encontrado.nombre */
    return encontrado;
}

export function laboratorios(nombreLabo){
    const labs = [
        {
            nombre:'2 Biometrías Hemática Completas',
            name:'laboratorio',
            ESTUDIO: "BIOMETRIA HEMATICA COMPLETA",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd284b11cb0ea0017f57bd8'
        },{
            nombre:'1 Biometría Hemática Completa',
            name:'laboratorio',
            ESTUDIO: "BIOMETRIA HEMATICA COMPLETA",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd284b11cb0ea0017f57bd8'
        },
        {
            nombre:"1 QS 6 (Glucosa, Urea, Creatinina, Ácido Úrico, Colesterol, y Triglicéridos)",
            name:'laboratorio',
            ESTUDIO: "QUIMICA SANGUINEA 6",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2a4171cb0ea0017f57ddb'
        },
        {
            nombre:"8 Química Sanguínea de 3 elementos (Glucosa, Colesterol y Triglicéridos)",
            name:'laboratorio',
            ESTUDIO: "QUIMICA SANGUINEA 3 (GLUCOSA. COLESTEROL Y TRIGLICERIDOS)",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2a3ed1cb0ea0017f57dd8'
        },
        {
            nombre:"1 V.D.R.L.",
            name:'laboratorio',
            ESTUDIO: "V.D.R.L.",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd29b611cb0ea0017f57d51'
        },
        {
            nombre:"2 Exámenes Generales de Orina",
            name:'laboratorio',
            ESTUDIO: "EXAMEN GENERAL DE ORINA ",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd28c2f1cb0ea0017f57c58'
        },
        {
            nombre:"3 Exámenes Generales de Orina",
            name:'laboratorio',
            ESTUDIO: "EXAMEN GENERAL DE ORINA ",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd28c2f1cb0ea0017f57c58'
        },
        {
            nombre:"1 Exámenes Generales de Orina",
            name:'laboratorio',
            ESTUDIO: "EXAMEN GENERAL DE ORINA ",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd28c2f1cb0ea0017f57c58'
        },
        {
            nombre:"1 Grupo Sanguíneo",
            name:'laboratorio',
            ESTUDIO: "GRUPO Y FACTOR RH",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd292b31cb0ea0017f57c9e'
        },
        {
            nombre:"2 Grupos Sanguíneos (Ambos padres)",
            name:'laboratorio',
            ESTUDIO: "GRUPO Y FACTOR RH",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd292b31cb0ea0017f57c9e'
        },
        {
            nombre:"1 V.I.H.",
            name:'laboratorio',
            ESTUDIO: "H.I.V. (PRUEBA PRESUNTIVA)",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd293501cb0ea0017f57caa'
        },
        {
            nombre:"1 Curva de tolerancia",
            name:'laboratorio',
            ESTUDIO: "CURVA  DE   TOLERANCIA  A  LA  GLUCOSA  2 HRS",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2a0051cb0ea0017f57d7a'
        },
        {
            nombre:"1 Tiempos de coagulación",
            name:'laboratorio',
            ESTUDIO: "TIEMPOS DE COAGULACION (TP, TPT)",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd29a821cb0ea0017f57d3f'
        },
        {
            nombre:"1 Exudado vaginal",
            name:'laboratorio',
            ESTUDIO: "CULTIVO CERVICO VAGINAL",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd289731cb0ea0017f57c24'
        },
        {
            nombre:"1 Pruebas de funcionamiento hepático",
            name:'laboratorio',
            ESTUDIO: "PRUEBAS DE FUNCION HEPATICO (BT,BD,BI,TGO,TGP,ALK)",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2992b1cb0ea0017f57d1e'
        },
        {
            nombre:"1 Depuración de proteínas en orina de 24 horas",
            name:'laboratorio',
            ESTUDIO: "PROTEINAS EN ORINA DE 24 HRS.",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2984d1cb0ea0017f57d0f'
        },
        {
            nombre:"1 Tamiz ampliado (Dentro de los primeros 10 días de nacido)",
            name:'laboratorio',
            ESTUDIO: "TAMIZ METABOLICO AMPLIADO ",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2a4631cb0ea0017f57de0'
        },
        {
            nombre:"1 Triple",
            name:'laboratorio',
            ESTUDIO: "TRIPLE MARCADO CON INTERPRETACION GRAFICA",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2a4821cb0ea0017f57de2'
        },
        {
            nombre:"1 Perfil tiroideo",
            name:'laboratorio',
            ESTUDIO: "PERFIL TIROIDEO I",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2a3771cb0ea0017f57dcf'
        },
        {
            nombre:"1 Urocultivo",
            name:'laboratorio',
            ESTUDIO: "CULTIVO DE ORINA (UROCULTIVO)",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd28a2b1cb0ea0017f57c32'
        },
        {
            nombre:"1 Examen coprológico",
            name:'laboratorio',
            ESTUDIO: "COPROLOGICO (EXAMEN GENERAL DE HECES)",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd287ed1cb0ea0017f57c0d'
        },
        {
            nombre:"3 Ultrasonidos obstétricos convencionales",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO OBSTETRICO CONVENCIONAL",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd280031cb0ea0017f57b87'
        },
        {
            nombre:"1 Ultrasonido Estructural",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO OBSTETRICO ESTRUCTURAL DEL SEGUNDO TRIMESTRE ",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd280c41cb0ea0017f57b91'
        },{
            nombre:"2 Ultrasonidos obstétricos convencionales",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO OBSTETRICO CONVENCIONAL",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd280031cb0ea0017f57b87'
        },{
            nombre:"1 Hígado y Via Biliar",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO DE HIGADO Y VIAS BILIARES",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd27ce51cb0ea0017f57b5a'
        },{
            nombre:"4 Obstétricos",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO OBSTETRICO CONVENCIONAL",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd280031cb0ea0017f57b87'
        },{
            nombre:"1 Genético (11-13.6 SDG)",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO OBSTETRICO ESTRUCTURAL DEL PRIMER TRISMESTRE",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd2810b1cb0ea0017f57b96'
        },{
            nombre:"1 Estructural (18-24 SDG)",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO OBSTETRICO ESTRUCTURAL DEL SEGUNDO TRIMESTRE ",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd280c41cb0ea0017f57b91'
        },{
            nombre:"1 Perfil biofísico (Ultimo trimestre)",
            name:'ultrasonido',
            ESTUDIO: "ULTRASONIDO PERFIL BIOFISICO",
            precioCon:"0",
            precioSin:"0",
            _id:'602edde98a29f000156dbc2d'
        },{
            nombre:"1 Tele de Tórax.",
            name:'xray',
            ESTUDIO: "RADIOGRAFÍA TELE DE TÓRAX",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd4f80e8adaca00176bc5b8'
        },{
            nombre:"1 Electrocardiograma",
            name:'tomografia',
            ESTUDIO: "ELECTROCARDIOGRAMA",
            precioCon:"0",
            precioSin:"0",
            _id:'5fd7c35961c8830017d478b3'
        }
    ];

    let encontrado = labs.find((element) => element.nombre == nombreLabo);
    /* delete encontrado.nombreLab */
    return encontrado;
}
export function numeroVisitas(citasIncluidas,visitas){
    let contador = 0;
    let consulta = ""
    let fecha= ""
    let firma= ""
    let hora= ""
    let medico= ""
    let tipo= ""
    let newArray=[]
    if(citasIncluidas != undefined){
        citasIncluidas.forEach((element:any) => {
            visitas.forEach(elem => {
                if(elem.nombre == element){
                    contador+=1
                    consulta = elem.consulta
                    fecha= elem.fecha
                    firma= elem.firma
                    hora= elem.hora
                    medico= elem.medico
                    tipo= elem.tipo
                }
            });
            let name = element
            let valores = {
                nombre : name,
                consulta: consulta,
                fecha: fecha,
                firma: firma,
                hora: hora,
                medico: medico,
                tipo: tipo,
                numeroVisitas: contador
            }
            newArray.push(valores)
            contador = 0;
        });
    }
    return newArray;
}

export function validarConsultas(nombre,numeroCitas){
    let cortar = nombre.substr(0,2)
    if(parseInt(cortar) == numeroCitas){
        return false
    }else{
        return true
    }
}

export function generar(sem:any){
    let semanaGestacion=[];
    if(sem[0].gestacion == 0){
        for (let index = 1; index < 39; index++) {
            semanaGestacion.push(index);
        }
    }
    return semanaGestacion;   
}

export function generarSemanaPago(costo,semanaGes){
    let totalSemanas = 39
    let totalSemana = totalSemanas - semanaGes;
    let cantidadSemana = costo/totalSemana
    let regreso = []
    let semanapago = [];
    for (let index = 1 ; index <= totalSemana; index++) {
        semanapago.push(index)
    }
    regreso.push(Math.round(cantidadSemana))
    regreso.push(semanapago)
    regreso.push(totalSemana)
    return regreso;
}

export function generarSemanaDosPagos(costo,semanaPago){
    let totalSemanas = 2
    let totalSemana = semanaPago + 1;
    let cantidadSemana = costo/totalSemanas
    let regreso = []
    let semanapago = [];
    for (let index = 1 ; index <= totalSemana; index++) {
        semanapago.push(index)
    }
    regreso.push(Math.round(cantidadSemana))
    regreso.push(semanapago)
    regreso.push(totalSemana)
    return regreso;
}

export function acumulados(pagos){
    let array=[]
    for (let index = 0; index < pagos.length; index++) {
        if(index == 0){
            array.push(pagos[index].pago)
        }else{
            array.push(pagos[index].pago+array[index-1])
        }
    }
    return array
}

export function restante(acumulado,total){
    let index = acumulado.length - 1
    return total-acumulado[index]
}

export function agregarConsultaCarrito(citas:any,consultas:any){
    let encontrar = citas.findIndex(element => element.nombre == consultas.nombre);
    if (encontrar >= 0) {
        citas[encontrar].numeroVisitas = citas[encontrar].numeroVisitas + 1
    }
    return citas;
}

export function restarConsultas(citas:any,consulta:any){
    let encontrar = citas.findIndex(element => element.nombre == consulta.nombre)
    citas[encontrar].numeroVisitas = citas[encontrar].numeroVisitas - 1
    return citas;
}