<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'BITACORA DE RESULTADOS DE LABORATORIO'"></app-titulos>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <form>
                <div class="form-group">
                    <!-- (keyup)="busquedaGeneral($event)" -->
                    <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" [(ngModel)]="buscar">
                    <button (click)="buscarEstudio()" >buscar</button>
                </div>
            </form>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <!-- <div class="col text_header">
                            <p>FECHA</p>
                        </div> -->
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>SEXO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <!-- <div class="col text_header">
                            <p>ESTUDIO</p>
                        </div> -->
                    </div>
                </div>
                <div class="card-body">
                    <!-- <div *ngFor="let item of consultas | paginate:{id:'listapacientes',
                    itemsPerPage: 3,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer" [routerLink]="['/resultado-servicios', item._id]">
                            <div class="col-md-1">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.fecha}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.edad}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.genero | generos:true }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.sede}}</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{item.estudios[0].nombreEstudio}}</p>
                            </div>
                        </div>
                        [routerLink]="['/final/obtenidos', item._id]"
                    </div> -->
                        <div class="row pointer puntero" *ngFor="let item of pedidosLab| paginate:{id:'listapacientes',
                        itemsPerPage: 3,
                        currentPage:pagina,
                        totalItems:totalpaciente}; let i = index" [routerLink]="['/bitacora/pedidos/paciente/', item.idPaciente._id,'laboratorio']">
                            <div class="col-md-1">
                                <p>{{ i + 1 }}</p>
                            </div>
                            <!-- <div class="col">
                                <p>{{ item.fechaPedido | date: 'dd/MM/yyyy':'UTC' }}</p>
                            </div> -->
                            <div class="col">
                                <p>{{ item.idPaciente.nombrePaciente }} {{ item.idPaciente.apellidoPaterno }} {{ item.idPaciente.apellidoMaterno }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.idPaciente.edad }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.idPaciente.genero | generos:true }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.sede }}</p>
                            </div>
                            <!-- <div class="col">
                                <p>{{ item.idServicio.ESTUDIO }}</p>
                            </div> -->
                        </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>




<!-- <h1 class="centerxd">BITACORA DE RESULTADOS DE LABORATORIO</h1>

<div class="col-md offset-md-5">
    <button class="btn" style="color: aliceblue;" (click)="imp()">IMPRIMIR</button>

</div>

<div class="col-md-12 col-lg-12">
    <div class="card" style="border-radius: 30px; margin: 25px 0px 25px; font-weight: 800;">
        <div class="card-heder header">
            <div class="row">
                <div class="col-1 text_header">
                    <p>No</p>
                </div>
                <div class="col-1 text_header">
                    <p>Fecha</p>
                </div>
                <div class="col text_header">
                    <p>Nombre</p>
                </div>
                <div class="col-1 text_header">
                    <p>Edad</p>
                </div>
                <div class="col-1 text_header">
                    <p>Sexo</p>
                </div>
                <div class="col-1 text_header">
                    <p>Sede</p>
                </div>
                <div class="col text_header">
                    <p>Estudio</p>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div *ngFor="let item of consultas | paginate:{id:'listaAmbulancia',
            itemsPerPage: 5 ,
            currentPage:pagina,
            totalItems:totalAmbulancia}; let i = index">
                <div class="row" [routerLink]="['/resultado-servicios', item._id]">
                    <div class="col-1" style="text-align: center;">
                        <p>{{ i +1 }}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.fecha}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.idPaciente.edad}}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.idPaciente.genero | generos:true }}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.sede}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.estudios[0].nombreEstudio}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>
    </div>
</div> -->