import { Component, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { preRegistro } from '../../../../../functions/bancoSangre';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-autoexclusion',
  templateUrl: './autoexclusion.component.html',
  styleUrls: ['./autoexclusion.component.css']
})
export class AutoexclusionComponent implements OnInit {

  public fecha;
  public respuesta={
    diferido: [{
      fechafinal: "",
      fechainicio: "",
      motivo: "",
      tiempoDiferir: "",
    }],
    diferir: true,
    proceso: "",
    respuestas: 0
  }
  public idPaciente;
  public idCensur=[];

  constructor(private activatedRoute: ActivatedRoute,
              private _banco:BancoService,
              private _router: Router) { }

  ngSip = 'SI';
  ngNop = 'NO';
  preguntacero='SI';
  preguntauno='SI';
  preguntados='SI'

  ngOnInit(): void {
    this.idPaciente = this.activatedRoute.snapshot.paramMap.get('id');
  }

  //LOADING
  pintLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
    /* console.log("toy funcionando weeeee"); */
  }

  removeLoadingScreen() {
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.remove('display-block');
    /* console.log('dejo de funcionar'); */
  }
  
  autoexclusion(form: NgForm){
    /* console.log(form); */
    this.pintLoadingScreen()
    this.fecha = moment().format('l'); 
    let regreso = preRegistro(this.fecha, form.value);
    this.respuesta = regreso;
    let id ={
      idpaciente:this.idPaciente
    }
    this._banco.agregarIdCensur(id).subscribe((resp:any)=>{
      this.idCensur = resp.data
      if(resp == 'ok') {
        this.removeLoadingScreen();
      }
    })
  } 

  enviar(resp){
    /* console.log(resp);
    console.log(this.idCensur); */
    this.idCensur.reverse();
    /* console.log(this.idCensur); */
    if(resp.diferir){
      let fecha = new Date(resp.diferido[0].fechafinal);
      let dif ={
        idbanco:this.idCensur[0]._id,
        proceso:resp.proceso, 
        estatus:'DIFERIDO',
        motivo:resp.diferido[0].motivo,
        rechazo:resp.diferido[0].tiempoDiferir,
        fechaRechazo:fecha
      }
      // console.log(dif);
      this._banco.diferirDisponente(dif).subscribe((resp:any)=>{
        /* console.log(resp); */
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL DISPONENTE SE DIFERIO CORRECTAMENTE',
        })
        this._router.navigate([ `/dashboard`]);
      })
    }else{
      let id = {
        idbancosangre:this.idCensur[0]._id,
        proceso:'enfermeria'
      }
      let cuestionario = {
        idbancosangre:this.idCensur[0]._id,
        preguntacero:resp.cuestionario.preguntacero,
        preguntauno:resp.cuestionario.preguntauno,
        preguntados:resp.cuestionario.preguntados,
        preguntatres:resp.cuestionario.preguntatres,
        preguntacuatro:resp.cuestionario.preguntacuatro,
        preguntacinco:resp.cuestionario.preguntacinco,
        preguntaseis:resp.cuestionario.preguntaseis,
        preguntasiete:resp.cuestionario.preguntasiete,
        preguntaocho:resp.cuestionario.preguntaocho,
        preguntanueve:resp.cuestionario.preguntanueve,
        preguntadiez:resp.cuestionario.preguntadiez,
        preguntaonce:resp.cuestionario.preguntaonce,
        preguntadoce:resp.cuestionario.preguntadoce,
      }
      this._banco.agregarCuestionario(cuestionario).subscribe((resp:any)=>{
        //console.log(resp);
      })
      this.pintLoadingScreen();
      this._banco.cambiarProceso(id).subscribe((resp:any)=>{
        // console.log(resp);
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'EL DISPONENTE PASO A ENFERMERIA',
        })
        this._router.navigate([ `/dashboard`]);
      })
    }
  }

}
