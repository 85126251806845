import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-interrogacion-pays',
  templateUrl: './interrogacion-pays.component.html',
  styleUrls: ['./interrogacion-pays.component.css']
})
export class InterrogacionPAYSComponent implements OnInit {

  @Input() _id='';
  public hojaEvolucion = {
    motivoDeConsulta:'',
    evolucionDelPadecimiento:'',
    medicoTrante:'',
    diagnostico:[],
    plan:'',
    respiratorioCardiovascular:"",
    digestivo:"",
    endocrino:"",
    musculoEsqueletico:"",
    genitourinario:"",
    hematopoyeticoLinfatico:"",
    pielAnexos:"",
    neurologicoPsiquiatricos:"",
    piel:'',
    cabezaCuello:"",
    torax:"",
    abdomen:"",
    genitales:"",
    extremidades:"",
    sistemaNervioso:"",
    idPaciente: ""
  }

  constructor() { }

  ngOnInit(): void {
  }

}
