<div class="content">
    <form #form='ngForm'>
        <p> REACCIÓN DE TOXICIDAD <span>(LEVE)</span> </p>
        <div>
            <input type="radio" value="ANSIEDAD" name="ANSIEDAD" ngModel (change)="ToxicidadLeve($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ANSIEDAD </label>
        </div>
        <div>
            <input type="radio" value="FATIGA" name="FATIGA" ngModel (change)="ToxicidadLeve($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> FATIGA </label> 
        </div>
        <div>
            <input type="radio" value="PARESTESIAS PERIBUCALES O ACRAS" name="PARESTESIAS PERIBUCALES O ACRAS " ngModel (change)="ToxicidadLeve($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> PARESTESIAS PERIBUCALES O ACRAS </label> 
        </div>     

        <p>RESULTADOS DE LABORATORIOS:</p>
        <div class="row" style="margin-bottom: 10px;">
            <div class="col-md-3">
                <label for="">CALCIO</label>
            </div>
            <div class="col">
                <input type="text" id="calcio" name="calcio" ngModel (change)="LaboCalcio(form)">
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <label for="">MAGNESIO</label>
            </div>
            <div class="col">
                <input type="text" id="magnesio" name="magnesio" ngModel (change)="LaboMagnesio(form)">
            </div>
        </div>


        <p> REACCIÓN DE TOXICIDAD <span>(MODERADO)</span> </p>
        <div>    
            <input type="radio" value="ESPASMOS MUSCULARES" name="ESPASMOS MUSCULARES" ngModel (change)="ToxicidadModerada($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ESPASMOS MUSCULARES </label>
        </div>
        <div>
            <input type="radio" value="CHEVOSTEK" name="CHEVOSTEK" ngModel (change)="ToxicidadModerada($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CHEVOSTEK </label> 
        </div>
        <div>
            <input type="radio" value="TROUSSEAU" name="TROUSSEAU" ngModel (change)="ToxicidadModerada($event.target.value)">
            <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TROUSSEAU </label> 
        </div>
        
        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otroModerado" id="otroModerado" cols="30" rows="10" ngModel (blur)="otroModerado(form)"></textarea>
        </div>  

        <p> REACCIÓN DE TOXICIDAD <span>(SEVERA)</span> </p>
        <div class="row">
            <div class="col">
                <div>
                    <input type="radio" value="TETANIA" name="TETANIA" ngModel (change)="ToxicidadSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TETANIA </label>
                </div>
                <div>
                    <input type="radio" value="HIPOTENSIÓN" name="HIPOTENSIÓN" ngModel (change)="ToxicidadSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> HIPOTENSIÓN </label> 
                </div>
                <div>
                    <input type="radio" value="PROLONGACIÓN QT" name="PROLONGACIÓN QT" ngModel (change)="ToxicidadSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> PROLONGACIÓN QT </label> 
                </div>
            </div>
            <div class="col">
                <div>
                    <input type="radio" value="LARINGOESPASMO" name="LARINGOESPASMO" ngModel (change)="ToxicidadSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> LARINGOESPASMO </label>
                </div>
                <div>
                    <input type="radio" value="FALLA CARDÍACA" name="FALLA CARDÍACA" ngModel (change)="ToxicidadSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> FALLA CARDÍACA </label> 
                </div>
            </div>
        </div>

        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasSevera" id="otrosSintomasSevera" cols="30" rows="10" ngModel (blur)="otroSevero(form)"></textarea>
        </div>
    </form>
</div>
