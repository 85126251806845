<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <form action="" class="card cardPer card-paddin-title" enctype="multipart/form-data">
                <div class="row center">
                    <h3>{{nombre}}</h3>
                    <div class="col-md-12">
                        <h5 class="mt-4" >
                            INTERPRETACION
                        </h5>
                        <div>
                            <textarea  class="txtarea form-control textUSG container mayus" 
                                       name="interpretacion" 
                                       [(ngModel)]="dataReportEstudies.interpretacion"
                                       id="interpretacion" 
                                       cols="120" rows="10" 
                                       autocapitalize="characters" 
                                       autocomplete="on" style="text-align: justify;">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 center">
                        <h5 class="mt-4">
                            DIAGNOSTICO
                        </h5>
                    </div>
                    <div class="col-md-12">
                        <textarea  class="txtarea form-control textUSG container mayus" 
                                   name="diagnostico" 
                                   [(ngModel)]="dataReportEstudies.diagnostico"
                                   id="diagnostico" 
                                   cols="90" rows="2" 
                                   autocapitalize="characters" 
                                   autocomplete="on">
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 center">
                        <h5 class="mt-4">
                            IMAGENES
                        </h5>
                        <div class="card">
                            <ngx-dropzone name="fileUploadTosend" (change)="onSelect($event)">
                                <ngx-dropzone-label>
                                    <!-- <span class="material-icons">
                                        add
                                    </span> -->
                                    INSERTA LAS IMAGENES AQUÍ
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview [file]="f" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                    <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 center">
                        <h5 class="mt-4">
                            OBSERVACIÓNES:
                        </h5>
                    </div>
                    <div class="col-md-12" >
                        <textarea  class="txtarea form-control textUSG container mayus" 
                                   name="observaciones" 
                                   id="observaciones" 
                                   [(ngModel)]="dataReportEstudies.observaciones"
                                   cols="120" rows="2" 
                                   autocapitalize="characters" 
                                   autocomplete="on">
                        </textarea>
                    </div>   
                </div>
                <div class="row" style="display: flex;justify-content: center;">
                    <div>
                        <button class="boton_contra2"  (click)="sendResultsData()" >
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
