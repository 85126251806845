<div class="content">
    <form #form='ngForm'>
        <p> REACCIÓN VASOVAGAL <span>(LEVE)</span> </p>
        <div class="row">
            <div class="col">
                <div>
                    <input type="radio" value="PALIDEZ" name="PALIDEZ(LEVE)" ngModel [checked]="true" (change)="vasovagalLeve($event.target.value)" checked>
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> PALIDEZ</label>
                </div>
                <div>
                    <input type="radio" value="DEBILIDAD" name="DEBILIDAD(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DEBILIDAD</label> 
                </div>
                <div>
                    <input type="radio" value="SUDORACIÓN" name="SUDORACIÓN(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SUDORACIÓN</label> 
                </div>
                <div>
                    <input type="radio" value="NÁUSEAS" name="NÁUSEAS(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> NÁUSEAS</label> 
                </div>
                <div>
                    <input type="radio" value="VÓMITO" name="VÓMITO(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> VÓMITO</label> 
                </div>
                <div>
                    <input type="radio" value="VÉRTIGO" name="VÉRTIGO(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> VÉRTIGO</label> 
                </div>
                <div>
                    <input type="radio" value="VISIÓN BORROSA" name="VISIÓN BORROSA(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> VISIÓN BORROSA</label> 
                </div>
            </div>
            <div class="col">
                <div>
                    <input type="radio" value="ACITETRINA" name="ACITETRINA(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ACITETRINA</label>
                </div>
                <div>
                    <input type="radio" value="PARESTESIAS" name="PARESTESIAS(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> PARESTESIAS</label> 
                </div>
                <div>
                    <input type="radio" value="SOMNOLENCIA" name="SOMNOLENCIA(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SOMNOLENCIA</label> 
                </div>
                <div>
                    <input type="radio" value="CEFALEA" name="CEFALEA(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CEFALEA</label> 
                </div>
                <div>
                    <input type="radio" value="CIANOSIS" name="CIANOSIS(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CIANOSIS</label> 
                </div>
                <div>
                    <input type="radio" value="ESCALOFRÍOS" name="ESCALOFRÍOS(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> ESCALOFRÍOS</label> 
                </div>
                <div>
                    <input type="radio" value="DESCANSO T.A MEDIA" name="DESCANSO T.A MEDIA(LEVE)" ngModel (change)="vasovagalLeve($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DESCANSO T.A MEDIA </label> 
                </div>
            </div>
        </div>

        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasLeves" id="value" cols="30" rows="10" ngModel (blur)="otroleve(form)"></textarea>
        </div>


        <p> REACCIÓN VASOVAGAL <span>(MODERADO)</span> </p>
        <div class="row">
            <div class="col">
                <div>
                    <input type="radio" value="RIGIDEZ" name="RIGIDEZ(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> RIGIDEZ</label>
                </div>
                <div>
                    <input type="radio" value="TAQUICARDIA" name="TAQUICARDIA(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TAQUICARDIA</label> 
                </div>
                <div>
                    <input type="radio" value="CAFALEA INTENSA" name="CAFALEA INTENSA(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> CAFALEA INTENSA</label> 
                </div>
                <div>
                    <input type="radio" value="DESCANSO T.A. MEDIA" name="DESCANSO T.A. MEDIA(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DESCANSO T.A. MEDIA</label> 
                </div>
            </div>
            <div class="col">
                <div>
                    <input type="radio" value="TEMBLOR" name="TEMBLOR(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TEMBLOR</label>
                </div>
                <div>
                    <input type="radio" value="DISNEA" name="DISNEA(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DISNEA</label> 
                </div>
                <div>
                    <input type="radio" value="PÉRDIDA DE CONCIENCIA" name="PÉRDIDA DE CONCIENCIA(MODERADO)" ngModel (change)="vasovagalModerada($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> PÉRDIDA DE CONCIENCIA</label> 
                </div>
            </div>
        </div>

        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasModerados" id="otrosSintomasModerados" cols="30" rows="10" ngModel (blur)="otroModerado(form)"></textarea>
        </div>

        <p> REACCIÓN VASOVAGAL <span>(SEVERA)</span> </p>
        <div class="row">
            <div class="col">
                <div>
                    <input type="radio" value="CONVULCIONES" name="CONVULCIONES(SEVERA)" ngModel (change)="vasovagalSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;" > CONVULCIONES</label>
                </div>
                <div>
                    <input type="radio" value="RELAJACION DE ESFÍNTERES" name="RELAJACION DE ESFÍNTERES(SEVERA)" ngModel (change)="vasovagalSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> RELAJACION DE ESFÍNTERES</label> 
                </div>
                <div>
                    <input type="radio" value="DOLOR PRECORDIAL" name="DOLOR PRECORDIAL(SEVERA)" ngModel (change)="vasovagalSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DOLOR PRECORDIAL</label> 
                </div>
            </div>
            <div class="col">
                <div>
                    <input type="radio" value="PÉRDIDA DE CONCIENCIA" name="PÉRDIDA DE CONCIENCIA(SEVERA)" ngModel (change)="vasovagalSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> PÉRDIDA DE CONCIENCIA</label>
                </div>
                <div>
                    <input type="radio" value="DESCANSO T.A. MEDIA" name="DESCANSO T.A. MEDIA(SEVERA)" ngModel (change)="vasovagalSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> DESCANSO T.A. MEDIA</label> 
                </div>
                <div>
                    <input type="radio" value="TRAUMATISMO POR CAÍDA" name="TRAUMATISMO POR CAÍDA(SEVERA)" ngModel (change)="vasovagalSevera($event.target.value)">
                    <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> TRAUMATISMO POR CAÍDA</label> 
                </div>
            </div>
        </div>

        <div>
            <p>OTROS SÍNTOMAS</p>
            <textarea name="otrosSintomasSevero" id="otrosSintomasSevero" cols="30" rows="10" ngModel (blur)="otroSevero(form)"></textarea>
        </div>
    </form>
</div>
