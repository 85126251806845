import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterResultados } from 'src/app/classes/buscarServicos/buscarServicos';
import { ServiciosService } from 'src/app/services/admin/servicios.service';

@Component({
  selector: 'app-bitacora-rayosx',
  templateUrl: './bitacora-rayosx.component.html',
  styleUrls: ['./bitacora-rayosx.component.css']
})
export class BitacoraRayosxComponent implements OnInit {
  public consultas:any=[]
  public totalpaciente:string;
  public pagina = 0;
  public pedidosRayosX = []

  constructor(private spinner : NgxSpinnerService,
                private _servicios: ServiciosService) { }

  ngOnInit(): void {
    this.spinner.show()
    this.obtenerCosnultaUltra()
    // this.getBitacoraUSG()
  }
  
  obtenerCosnultaUltra(){
    this._servicios.getBitacoraLabs('XRAY')
    .subscribe((data:any) => {
      if( data.ok ) {
        this.consultas = this.setRegresos( data.data )
        this.spinner.hide();
      }
    })
  }

  busquedaGeneral(event:any){
    if (event.target.value == '') {
      this.consultas = this.pedidosRayosX;
    } else if (event.target.value.length >= 3){
      this.consultas = filterResultados(this.pedidosRayosX, event.target.value)
    }
  }

  setRegresos(rayosx:any){
    rayosx.forEach(element => {
      if (element.idPaciente != null) {
        this.consultas.push(element)
      }
    });
    var found ={};
    var resp = this.consultas.filter(function(element){
      return found.hasOwnProperty(element.idPaciente._id)? false : (found[element.idPaciente._id]=true);
    });
    this.pedidosRayosX = resp;
    return resp.reverse();
  }

  // setDataUltras(data:any) {
  //   let array:any[] = [];
  //   data.forEach(element => {
  //     if(element.idPaciente != null){
  //       array.push(element)
  //     }
  //   });
  //   this.consultas = array.reverse();
  // }
}
