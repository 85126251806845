import { Component, OnInit } from '@angular/core';
import { PatologiaService } from 'src/app/services/patologia/patologia.service';

@Component({
  selector: 'app-bitacora-resultados-patologia',
  templateUrl: './bitacora-resultados-patologia.component.html',
  styleUrls: ['./bitacora-resultados-patologia.component.css']
})
export class BitacoraResultadosPatologiaComponent implements OnInit {

  constructor(
    private _patologiaService: PatologiaService
  ) { }

  public patologias = [];

  ngOnInit(): void {
    this.obtenerPatologiasFinalizadas();
  }

  obtenerPatologiasFinalizadas() {
    this._patologiaService.verPedidosPatologia('FINALIZADO')
    .subscribe((data:any) => {
      
      if(data.ok) {
        this.setPatologias(data.data);        
      }
    })
  }

  setPatologias(data:any){
      this.patologias = data;
    }

}
