<div class="container-fluid">
  <app-titulos [title]="'LISTADO DE PRODUCTOS'"></app-titulos>

  <div class="row">
    <div class="col-md-9">
      <div class="form-group">
        <input
          type="text"
          name="txtSearch"
          autofocus="autofocus"
          [(ngModel)]="txtSearch"
          (keyup)="filterChangeByName($event)"
          placeholder="Buscar..."
          class="form-control shadow-input-bs"/>
      </div>
    </div>
    <div class="col-md-2">
      <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/agregar/productos/almacen']">NUEVO</a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="card">
        <div class="card-heder header">
          <div class="row">
            <div class="col-md-1 text_header">
              <p>No</p>
            </div>
            <div class="col text_header">
              <p>Nombre</p>
            </div>
            <div class="col text_header">
              <p>Nombre Comercial</p>
            </div>
            <div class="col text_header">
              <p>Proveedor</p>
            </div>
            <div class="col text_header">
              <p>Precio</p>
            </div>
            <div class="col text_header">
              <p>Acciones</p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div *ngFor="let producto of productos| paginate:{id:'lista',
                                                            itemsPerPage: 5,
                                                            currentPage:pagina,
                                                            totalItems:totalProductos}; let i = index">
            <div class="row puntero" [routerLink]="['/agregar/stock/productos/almacen',producto._id]">
              <div class="col-md-1" style="text-align: center;">
                <p>
                  {{ i + 1 }}
                </p>
                <span class="puntero"></span>
              </div>
              <div class="col" style="text-align: center;">
                <p>
                  {{ producto.nombre }}
                </p>
              </div>
              <div class="col" style="text-align: center;">
                <p>
                  {{ producto.nombre_comercial }}
                </p>
              </div>
              <div class="col" style="text-align: center;">
                <p>
                  {{ producto.proveedor[0].proveedor }}
                </p>
              </div>
              <div class="col" style="text-align: center;">
                <p>
                  {{ producto.proveedor[0].costo }}
                </p>
              </div>
              <div class="col" style="text-align: center;">
                <p>
                  <!-- <i class="fa fa-trash-alt colorRed"
                    (click)="disabledProdcut(producto._id)">
                  </i> -->
                  <i class="far fa-edit colorGreenEditIcon"
                    [routerLink]="['/editar/producto/almacen/', producto._id]">
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="offset-md-3">
          <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <p style="color: white"> Cargando... </p>
  </ngx-spinner>
</div>











<!-- <div class="container-fluid">
  <h1 class="centerxd title-principal-bs">LISTADO DE PRODUCTOS</h1>
  <div class="row">
    <div class="col-md-7 mt-4 mr-4 mb-4">
      <div class="form-group">
        <input
          type="text"
          name="txtSearch"
          autofocus="autofocus"
          [(ngModel)]="txtSearch"
          (keyup)="filterChangeByName()"
          placeholder="Buscar..."
          class="form-control shadow-input-bs"/>
      </div>
    </div>
    <div class="col-md-2 offset-md-2 mt-3 mb-4">
      <div
        class="btn btnPrincipalBS"
        [routerLink]="['/agregar/productos/almacen']"
      >
        Nuevo
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="card">
        <div class="card-heder header bg-color-blue-bs">
          <div class="row">
            <div
              class="col-md-1 text_header"
              style="text-align: left; margin-left: 15px"
            >
              <p>No.</p>
            </div>

            <div class="col-md-2 text_header">
              <p>Nombre</p>
            </div>
            <div class="col-md-2 text_header">
              <p>Nombre Comercial</p>
            </div>
            <div class="col text_header">
              <p>Descripción</p>
            </div>

            <div class="col text_header">
              <p>Existencia</p>
            </div>
            
            <div class="col text_header">
              <p>Proveedor</p>
            </div>

            <div class="col text_header">
              <p> Precio </p>
            </div>


            <div class="col text_header">
              <p>Acciones</p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div>
            
            <div
              class="row puntero pointer"
              id="pointer"
              *ngFor="let producto of productos| paginate:{id:'lista',
              itemsPerPage: 5,
              currentPage:pagina,
              totalItems:totalProductos}; let i = index"
            >
              <div class="col-md-1">
                <p>
                  {{ i + 1 }}
                </p>
              </div>
              <div
                class="col-md-2"
                style="text-align: center"
                [routerLink]="[
                  '/agregar/stock/productos/almacen',
                  producto._id
                ]"
              >
                <p>
                  {{ producto.nombre }}
                </p>
              </div>
              <div class="col-md-2" style="text-align: center">
                <p>
                  {{ producto.nombre_comercial }}
                </p>
              </div>
              <div class="col" style="text-align: center">
                <p>
                  {{ producto.descripcion }}
                </p>
              </div>
              <div class="col" style="text-align: center">
                <p>
                  {{ producto.existencia }}
                </p>
              </div>
              <div class="col" style="text-align: center">
                <p>
                  {{ producto.proveedor[0].proveedor }}
                </p>
              </div>
              
              <div class="col" style="text-align: center">
                <p>
                  {{ producto.proveedor[0].costo }}
                </p>
              </div>
              
              <div class="col" style="text-align: center">
                <p>
                  <i
                    class="fa fa-trash-alt colorRed"
                    (click)="disabledProdcut(producto._id)"
                  >
                  </i>
                  <i
                    class="far fa-edit colorGreenEditIcon"
                    [routerLink]="['/editar/producto/almacen/', producto._id]"
                  ></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="offset-md-4">
          <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
      </div>
      </div>
    </div>
  </div>
</div> -->

<section id="section-message-alert" class="dispaly-none">
  <div class="card card-message">
    <h2 class="center grayColor">
      <i class="fa fa-exclamation-circle"></i>
      DESEAS DESHABILITAR ESTE PRODUCTO
    </h2>
    <p class="center grayColor">NO SE PODRÁ REVERTIR</p>

    <div class="row ml-5">
      <div class="col-md-5">
        <button class="btnDangerRed" (click)="cancelBtnAlertMessage()">
          NO, CANCELAR
        </button>
      </div>
      <div class="col-md-5">
        <button class="btnSuccessGreen" (click)="btnConfirmDelete()">
          SI, ACEPTAR
        </button>
      </div>
    </div>
  </div>
</section>

<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
  <br>
  <p style="color: white"> Cargando... </p>
</ngx-spinner> -->
