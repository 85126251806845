<div class="container-fluid">
   <app-titulos [title]="'AGREGAR SEDES'" [rol]="'sedes'"></app-titulos>
</div>
<form [formGroup]="formsede" (ngSubmit)="onsavesede()">
   <mat-horizontal-stepper linear #stepper class="matho" style="background: WHITE;">
      <div class="card cardPer">
         <mat-step label="DATOS PERSONALES">
            <div class="container ">
               <div class="row-1">
                  <div class="alan" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
                     <ngx-dropzone-label>
                        <img src="../../../../../../assets/images/imagensede.png" alt="imagensede">
                     </ngx-dropzone-label>
                     <ngx-dropzone-image-preview class="imgPreview" ngProjectAs="ngx-dropzone-preview"
                        *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                     </ngx-dropzone-image-preview>
                  </div>
               </div>
               <div class="row">

                  <div class="col">
                     <label> CLINICA/HOSPITAL</label>
                  </div>
                  <div class="col">

                     <input formControlName="clinica" type="text" class="form-control">

                     <span *ngIf="getErrores('clinica')" class="form-text text-danger">Este campo es obligatorio</span>


                  </div>
                  <div class="col">
                     <label>RAZON SOCIAL</label>
                  </div>
                  <div class="col">
                     <select class="form-control custom-select" aria-label=".form-select-md example" name="razon"
                        id="razon" formControlName="razon">
                        <option selected>FISICA</option>
                        <option>MORAL</option>
                     </select>
                     <span *ngIf="getErrores('razon')" class="form-text text-danger">Selecciona el estado fiscal</span>

                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label> RFC</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" name="rfc" formControlName="rfc">
                     <span *ngIf="getErrores('rfc')" class="form-text text-danger">Este campo requiere mínimo 12
                        caracteres</span>
                  </div>
                  <div class="col">
                     <label> RESPONSABLE SANITARIO</label>
                  </div>
                  <div class="col"><input type="text" name="responsable_sa" class="form-control"
                        formControlName="responsable_sa">
                     <span *ngIf="getErrores('responsable_sa')" class="form-text text-danger">Este campo es
                        obligatorio</span>
                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>CONTACTO</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" formControlName="contacto">
                     <span *ngIf="getErrores('contacto')" class="form-text text-danger">Este campo es obligatorio</span>

                  </div>
                  <div class="col">
                     <label>GÉNERO</label>
                  </div>
                  <div class="col">
                     <select class="form-control custom-select" name="genero" id="genero" formControlName="genero">
                        <option value="HOMBRE">HOMBRE</option>
                        <option value="MUJER">MUJER</option>
                     </select>
                     <span *ngIf="getErrores('genero')" class="form-text text-danger">Selecciona el genero</span>

                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>FECHA</label>
                  </div>
                  <div class="col">
                     <input type="date" class="form-control" formControlName="fecha">
                     <span *ngIf="getErrores('fecha')" class="form-text text-danger">Selecciona la fecha</span>

                  </div>
                  <div class="col">
                     <label>TELEFONO</label>
                  </div>
                  <div class="col">
                     <input type="number" class="form-control" formControlName="telefono">
                     <span *ngIf="getErrores('telefono')" class="form-text text-danger">Minimo 10 digitos</span>

                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>USUARIO</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" formControlName="usuario">

                  </div>
                  <div class="col">
                     <label> CONTRASEÑA</label>
                  </div>
                  <div class="col">
                     <input type="password" class="form-control" formControlName="contrasena">

                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label> CORREO</label>
                  </div>
                  <div class="col">
                     <input type="email" class="form-control" formControlName="correo">
                     <span *ngIf="getErrores('correo')" class="form-text text-danger">Escriba un correo valido
                        ejemplo@correo.com </span>

                  </div>
                  <div class="col"><label> RESPONSABLE DEL SERVICIO INSTITUCIONAL</label></div>
                  <div class="col">
                     <input type="text" class="form-control" formControlName="responsable_si">

                  </div>
               </div>


            </div>

            <div class="Container">
               <h2>DIRECCIÓN</h2>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>CALLE</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" formControlName="calle">
                     <span *ngIf="getErrores('calle')" class="form-text text-danger">Este campo es obligatorio</span>

                  </div>
                  <div class="col">
                     <label>NUMERO EXTERIOR</label>
                  </div>
                  <div class="col">
                     <input type="number" class="form-control" formControlName="no_exterior">
                     <span *ngIf="getErrores('no_exterior')" class="form-text text-danger">Este campo es
                        obligatorio</span>

                  </div>
               </div>
            </div>



            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>CODIGO POSTAL</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" (blur)="cogidoPostal($event.target.value,false)"
                        formControlName="postal">

                  </div>
                  <div class="col">
                     <label>PAIS</label>
                  </div>
                  <div class="col">
                     <select formControlName="pais" name="pais" id="pais" class="form-control custom-select"
                        (blur)="pais($event.target.value)">
                        <option value="AF">Afganistán</option>
                        <option value="AL">Albania</option>
                        <option value="DE">Alemania</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antártida</option>
                        <option value="AG">Antigua y Barbuda</option>
                        <option value="AN">Antillas Holandesas</option>
                        <option value="SA">Arabia Saudí</option>
                        <option value="DZ">Argelia</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaiyán</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrein</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BE">Bélgica</option>
                        <option value="BZ">Belice</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermudas</option>
                        <option value="BY">Bielorrusia</option>
                        <option value="MM">Birmania</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia y Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brasil</option>
                        <option value="BN">Brunei</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="BT">Bután</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Camboya</option>
                        <option value="CM">Camerún</option>
                        <option value="CA">Canadá</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CY">Chipre</option>
                        <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comores</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo, República Democrática del</option>
                        <option value="KR">Corea</option>
                        <option value="KP">Corea del Norte</option>
                        <option value="CI">Costa de Marfíl</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croacia (Hrvatska)</option>
                        <option value="CU">Cuba</option>
                        <option value="DK">Dinamarca</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egipto</option>
                        <option value="SV">El Salvador</option>
                        <option value="AE">Emiratos Árabes Unidos</option>
                        <option value="ER">Eritrea</option>
                        <option value="SI">Eslovenia</option>
                        <option value="ES">España</option>
                        <option value="US">Estados Unidos</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Etiopía</option>
                        <option value="FJ">Fiji</option>
                        <option value="PH">Filipinas</option>
                        <option value="FI">Finlandia</option>
                        <option value="FR">Francia</option>
                        <option value="GA">Gabón</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GD">Granada</option>
                        <option value="GR">Grecia</option>
                        <option value="GL">Groenlandia</option>
                        <option value="GP">Guadalupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GY">Guayana</option>
                        <option value="GF">Guayana Francesa</option>
                        <option value="GN">Guinea</option>
                        <option value="GQ">Guinea Ecuatorial</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="HT">Haití</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungría</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IQ">Irak</option>
                        <option value="IR">Irán</option>
                        <option value="IE">Irlanda</option>
                        <option value="BV">Isla Bouvet</option>
                        <option value="CX">Isla de Christmas</option>
                        <option value="IS">Islandia</option>
                        <option value="KY">Islas Caimán</option>
                        <option value="CK">Islas Cook</option>
                        <option value="CC">Islas de Cocos o Keeling</option>
                        <option value="FO">Islas Faroe</option>
                        <option value="HM">Islas Heard y McDonald</option>
                        <option value="FK">Islas Malvinas</option>
                        <option value="MP">Islas Marianas del Norte</option>
                        <option value="MH">Islas Marshall</option>
                        <option value="UM">Islas menores de Estados Unidos</option>
                        <option value="PW">Islas Palau</option>
                        <option value="SB">Islas Salomón</option>
                        <option value="SJ">Islas Svalbard y Jan Mayen</option>
                        <option value="TK">Islas Tokelau</option>
                        <option value="TC">Islas Turks y Caicos</option>
                        <option value="VI">Islas Vírgenes (EEUU)</option>
                        <option value="VG">Islas Vírgenes (Reino Unido)</option>
                        <option value="WF">Islas Wallis y Futuna</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italia</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japón</option>
                        <option value="JO">Jordania</option>
                        <option value="KZ">Kazajistán</option>
                        <option value="KE">Kenia</option>
                        <option value="KG">Kirguizistán</option>
                        <option value="KI">Kiribati</option>
                        <option value="KW">Kuwait</option>
                        <option value="LA">Laos</option>
                        <option value="LS">Lesotho</option>
                        <option value="LV">Letonia</option>
                        <option value="LB">Líbano</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libia</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lituania</option>
                        <option value="LU">Luxemburgo</option>
                        <option value="MK">Macedonia, Ex-República Yugoslava de</option>
                        <option value="MG">Madagascar</option>
                        <option value="MY">Malasia</option>
                        <option value="MW">Malawi</option>
                        <option value="MV">Maldivas</option>
                        <option value="ML">Malí</option>
                        <option value="MT">Malta</option>
                        <option value="MA">Marruecos</option>
                        <option value="MQ">Martinica</option>
                        <option value="MU">Mauricio</option>
                        <option value="MR">Mauritania</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX" selected>México</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldavia</option>
                        <option value="MC">Mónaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="MS">Montserrat</option>
                        <option value="MZ">Mozambique</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Níger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk</option>
                        <option value="NO">Noruega</option>
                        <option value="NC">Nueva Caledonia</option>
                        <option value="NZ">Nueva Zelanda</option>
                        <option value="OM">Omán</option>
                        <option value="NL">Países Bajos</option>
                        <option value="PA">Panamá</option>
                        <option value="PG">Papúa Nueva Guinea</option>
                        <option value="PK">Paquistán</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Perú</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PF">Polinesia Francesa</option>
                        <option value="PL">Polonia</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="UK">Reino Unido</option>
                        <option value="CF">República Centroafricana</option>
                        <option value="CZ">República Checa</option>
                        <option value="ZA">República de Sudáfrica</option>
                        <option value="DO">República Dominicana</option>
                        <option value="SK">República Eslovaca</option>
                        <option value="RE">Reunión</option>
                        <option value="RW">Ruanda</option>
                        <option value="RO">Rumania</option>
                        <option value="RU">Rusia</option>
                        <option value="EH">Sahara Occidental</option>
                        <option value="KN">Saint Kitts y Nevis</option>
                        <option value="WS">Samoa</option>
                        <option value="AS">Samoa Americana</option>
                        <option value="SM">San Marino</option>
                        <option value="VC">San Vicente y Granadinas</option>
                        <option value="SH">Santa Helena</option>
                        <option value="LC">Santa Lucía</option>
                        <option value="ST">Santo Tomé y Príncipe</option>
                        <option value="SN">Senegal</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leona</option>
                        <option value="SG">Singapur</option>
                        <option value="SY">Siria</option>
                        <option value="SO">Somalia</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="PM">St Pierre y Miquelon</option>
                        <option value="SZ">Suazilandia</option>
                        <option value="SD">Sudán</option>
                        <option value="SE">Suecia</option>
                        <option value="CH">Suiza</option>
                        <option value="SR">Surinam</option>
                        <option value="TH">Tailandia</option>
                        <option value="TW">Taiwán</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TJ">Tayikistán</option>
                        <option value="TF">Territorios franceses del Sur</option>
                        <option value="TP">Timor Oriental</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad y Tobago</option>
                        <option value="TN">Túnez</option>
                        <option value="TM">Turkmenistán</option>
                        <option value="TR">Turquía</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UA">Ucrania</option>
                        <option value="UG">Uganda</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistán</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="YE">Yemen</option>
                        <option value="YU">Yugoslavia</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabue</option>
                     </select>
                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>ESTADO</label>
                  </div>
                  <div class="col">
                     <select formControlName="estado" name="estado" id="estado" class="form-control custom-select"
                        (blur)="cogidoPostal(false, $event.target.value)">
                        <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                        <option value="Aguascalientes">AGUASCALIENTES</option>
                        <option value="Baja California">BAJA CALIFORNIA</option>
                        <option value="Baja California Sur">BAJA CALIFORNIA SUR</option>
                        <option value="Campeche">CAMPECHE</option>
                        <option value="Chiapas">CHIAPAS</option>
                        <option value="Chihuahua">CHIHUAHUA</option>
                        <option value="Ciudad de Mexico">CDMX</option>
                        <option value="Coahuila de Zaragoza">COAHUILA</option>
                        <option value="Colima">COLIMA</option>
                        <option value="Durango">DURANGO</option>
                        <option value="Mexico">ESTADO DE MÉXICO</option>
                        <option value="Guanajuato">GUANAJUATO</option>
                        <option value="Guerrero">GUERRERO</option>
                        <option value="Hidalgo">HIDALGO</option>
                        <option value="Jalisco">JALISCO</option>
                        <option value="Michoacan de Ocampo">MICHOACÁN</option>
                        <option value="Morelos">MORELOS</option>
                        <option value="Nayarit">NAYARIT</option>
                        <option value="Nuevo Leon">NUEVO LÉON</option>
                        <option value="Oaxaca">OAXACA</option>
                        <option value="Puebla">PUEBLA</option>
                        <option value="Queretaro">QUERÉTARO</option>
                        <option value="Quintana Roo">QUINTANA ROO</option>
                        <option value="San Luis Potosi">SAN LUIS POTOSÍ</option>
                        <option value="Sinaloa">SINALOA</option>
                        <option value="Sonora">SONORA</option>
                        <option value="Tabasco">TABASCO</option>
                        <option value="Tamaulipas">TAMAULIPAS</option>
                        <option value="Tlaxcala">TLAXCALA</option>
                        <option value="Veracruz de Ignacio de la Llave">VERACRUZ</option>
                        <option value="Yucatan">YUCATÁN</option>
                        <option value="Zacatecas">ZACATECAS</option>
                     </select>
                  </div>
                  <div class="col">
                     <label>MUNICIPIO</label>
                  </div>
                  <div class="col">
                     <ng-container *ngIf="formsede.get('postal').value != '' && estadocp != 'false'">
                        <select formControlName="municipio" name="municipio" id="municipio"
                           class="form-control custom-select" (blur)="municipio($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option *ngFor="let item of municipios; index as i">{{item}}</option>
                        </select>
                     </ng-container>
                     <ng-container *ngIf="formsede.get('postal').value == ''">
                        <select name="municipio" id="municipio" class="form-control custom-select"
                           (blur)="municipio2($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option *ngFor="let item of municipios; index as i">{{item}}</option>
                        </select>
                     </ng-container>
                  </div>
               </div>
            </div>
            <div class="container first">
               <div class="row">
                  <div class="col">
                     <label>REFERENCIA 1</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" formControlName="ref_1">

                  </div>
                  <div class="col">
                     <label> REFERENCIA 2</label>
                  </div>
                  <div class="col">
                     <input type="text" class="form-control" formControlName="ref_2">

                  </div>
               </div>
            </div>
         </mat-step>
         <mat-step label="DATOS FISCALES">


            <div class="col">
               <h1> ¿TU DOMICILIO FISCAL ES EL MISMO QUE TU DOMICILIO ACTUAL?</h1>
               <label class="checkbox-inline">
                  <input type="radio" id="checkboxEnLinea1" name="opciones" value="opcion_1"
                     (change)="domicilioFiscal($event.target.value)"> Si
               </label>
               <label class="checkbox-inline">
                  <input type="radio" id="checkboxEnLinea2" name="opciones" value="opcion_2" (click)="cleaninpt()"> No
               </label>
            </div>
            <ng-container *ngIf="datosfiscales == 'opcion_1'">
               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label>RAZON SOCIAL</label>
                     </div>
                     <div class="col">
                        <input type="text" [ngModel]="formsede.get('razon').value" class="form-control"
                           formControlName="razon_em">

                     </div>
                     <div class="col">
                        <label> RFC </label>
                     </div>
                     <div class="col">
                        <input type="text" [ngModel]="formsede.get('rfc').value" class="form-control"
                           formControlName="rfc_em">

                     </div>
                  </div>
               </div>

               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label>CODIGO POSTAL</label>
                     </div>
                     <div class="col">
                        <input type="number" [ngModel]="formsede.get('postal').value" class="form-control"
                           formControlName="postal_em">

                     </div>
                     <div class="col">
                        <label> ESTADO</label>
                     </div>
                     <div class="col">
                        <input type="text" [ngModel]="formsede.get('estado').value" class="form-control"
                           formControlName="estado_em">

                     </div>
                  </div>
               </div>
               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label> MUNICIPIO</label>
                     </div>
                     <div class="col">
                        <input type="text" class="form-control" [ngModel]="formsede.get('municipio').value"
                           formControlName="municipio_em">

                     </div>
                     <div class="col">
                        <label> CALLE</label>
                     </div>
                     <div class="col">
                        <input type="text" class="form-control" [ngModel]="formsede.get('calle').value"
                           formControlName="calle_em">

                     </div>
                  </div>
               </div>

               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label> NUMERO EXTERIOR</label>
                     </div>
                     <div class="col">
                        <input type="number" class="form-control" [ngModel]="formsede.get('no_exterior').value"
                           formControlName="exterior_em">

                     </div>
                     <div class="col">
                        <label> CORREO</label>
                     </div>
                     <div class="col"><input type="email" class="form-control" [ngModel]="formsede.get('correo').value"
                           formControlName="correo_em">

                     </div>
                  </div>

               </div>
            </ng-container>
            <ng-container *ngIf="datosfiscales == 'opcion_2'">
               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label>RAZON SOCIAL</label>
                     </div>
                     <div class="col">
                        <input type="text" value="" class="form-control" formControlName="razon_em">
                        <span *ngIf="getErrores('razon_em')" class="form-text text-danger">Este campo es
                           obligatorio</span>

                     </div>
                     <div class="col">
                        <label> RFC </label>
                     </div>
                     <div class="col">
                        <input type="text" value="" class="form-control" formControlName="rfc_em">
                        <span *ngIf="getErrores('rfc_em')" class="form-text text-danger">Este campo son minimo 12
                           caracteres</span>
                     </div>
                  </div>
               </div>

               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label>CODIGO POSTAL</label>
                     </div>
                     <div class="col">
                        <input type="number" value="" class="form-control" formControlName="postal_em">
                        <span *ngIf="getErrores('postal_em')" class="form-text text-danger">Este campo es
                           obligatorio</span>
                     </div>
                     <div class="col">
                        <label> ESTADO</label>
                     </div>
                     <div class="col">
                        <select formControlName="estado_em" name="estado_em" id="estado_em"
                           class="form-control custom-select" (blur)="cogidoPostal(false, $event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option value="Aguascalientes">AGUASCALIENTES</option>
                           <option value="Baja California">BAJA CALIFORNIA</option>
                           <option value="Baja California Sur">BAJA CALIFORNIA SUR</option>
                           <option value="Campeche">CAMPECHE</option>
                           <option value="Chiapas">CHIAPAS</option>
                           <option value="Chihuahua">CHIHUAHUA</option>
                           <option value="Ciudad de Mexico">CDMX</option>
                           <option value="Coahuila de Zaragoza">COAHUILA</option>
                           <option value="Colima">COLIMA</option>
                           <option value="Durango">DURANGO</option>
                           <option value="Mexico">ESTADO DE MÉXICO</option>
                           <option value="Guanajuato">GUANAJUATO</option>
                           <option value="Guerrero">GUERRERO</option>
                           <option value="Hidalgo">HIDALGO</option>
                           <option value="Jalisco">JALISCO</option>
                           <option value="Michoacan de Ocampo">MICHOACÁN</option>
                           <option value="Morelos">MORELOS</option>
                           <option value="Nayarit">NAYARIT</option>
                           <option value="Nuevo Leon">NUEVO LÉON</option>
                           <option value="Oaxaca">OAXACA</option>
                           <option value="Puebla">PUEBLA</option>
                           <option value="Queretaro">QUERÉTARO</option>
                           <option value="Quintana Roo">QUINTANA ROO</option>
                           <option value="San Luis Potosi">SAN LUIS POTOSÍ</option>
                           <option value="Sinaloa">SINALOA</option>
                           <option value="Sonora">SONORA</option>
                           <option value="Tabasco">TABASCO</option>
                           <option value="Tamaulipas">TAMAULIPAS</option>
                           <option value="Tlaxcala">TLAXCALA</option>
                           <option value="Veracruz de Ignacio de la Llave">VERACRUZ</option>
                           <option value="Yucatan">YUCATÁN</option>
                           <option value="Zacatecas">ZACATECAS</option>
                        </select>
                        <span *ngIf="getErrores('estado_em')" class="form-text text-danger">Este campo es
                           obligatorio</span>
                     </div>
                  </div>

               </div>
               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label> MUNICIPIO</label>
                     </div>
                     <div class="col">

                        <ng-container *ngIf="formsede.get('postal').value != '' && estadocp != 'false'">
                           <select formControlName="municipio_em" name="estado" id="estado"
                              class="form-control custom-select" (blur)="municipio($event.target.value)">
                              <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                              <option *ngFor="let item of municipios; index as i">{{item}}</option>
                           </select>
                           <span *ngIf="getErrores('razon_em')" class="form-text text-danger">Este campo es
                              obligatorio</span>
                        </ng-container>
                        <ng-container *ngIf="formsede.get('postal').value == ''">
                           <select formControlName="municipio_em" name="estado" id="estado"
                              class="form-control custom-select" (blur)="municipio2($event.target.value)">
                              <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                              <option *ngFor="let item of municipios; index as i">{{item}}</option>
                           </select>
                           <span *ngIf="getErrores('razon_em')" class="form-text text-danger">Este campo es
                              obligatorio</span>
                        </ng-container>

                     </div>
                     <div class="col">
                        <label> CALLE</label>
                     </div>
                     <div class="col">
                        <input type="text" value="" class="form-control" formControlName="calle_em">
                        <span *ngIf="getErrores('calle_em')" class="form-text text-danger">Este campo es
                           obligatorio</span>
                     </div>
                  </div>
               </div>

               <div class="container first">
                  <div class="row">
                     <div class="col">
                        <label> NUMERO EXTERIOR</label>
                     </div>
                     <div class="col">
                        <input type="number" value="" class="form-control" formControlName="exterior_em">
                        <span *ngIf="getErrores('exterior_em')" class="form-text text-danger">Este campo es
                           obligatorio</span>
                     </div>
                     <div class="col">
                        <label> CORREO</label>
                     </div>
                     <div class="col"><input type="email" value="correo_em" class="form-control"
                           formControlName="correo_em">
                        <span *ngIf="getErrores('correo_em')" class="form-text text-danger">Este campo es
                           obligatorio</span>
                     </div>
                  </div>

               </div>
            </ng-container>
         </mat-step>
         <mat-step label="DOCUMENTOS">
            <div id="archiv">
               <a href="">consentimiento-donacion.pdf</a>
               <a href="">convenio-censur.pdf</a>
               <a href="">etiquetas-no-liberadas-donacion.pdf</a>
            </div>
            <div class="card">
                  <ngx-dropzone name="fileUploadTosend"  accept="application/pdf" (change)="onSelect2($event)">
                     <ngx-dropzone-label>
                        <!-- <span class="material-icons">
                           add
                       </span> -->
                        INSERTA LOS DOCUMENTOS AQUÍ
                     </ngx-dropzone-label>
                     <ngx-dropzone-preview [file]="f" *ngFor="let f of files2" [removable]="true"
                        (removed)="onRemoves(f)">
                        <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                     </ngx-dropzone-preview>
                  </ngx-dropzone>
            </div>

            <section class="row mt-4 noprint">
               <div class="offset-md-5 col-md-2">

                  <input class="principalButton" type="submit" value="REGISTRAR">
               </div>
            </section>
         </mat-step>


      </div>
   </mat-horizontal-stepper>


</form>
