<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'UTLIDAD SOCIOS'"></app-titulos>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mt-3">

            <select name="sedes" id="sedes" class="form-control shadow-input-bs"  #f  (change)="setSedesToSearch( f.value )" >
                
                <option [value]="busqueda.sede"  name="busquedaSede"  > Selecciona una sede </option>
                <option [value]="sede.nomenclatura"  name="busquedaSede"   *ngFor="let sede of sedes">  {{ sede.encargado }} </option>

            </select>
        </div>

    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
    
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col-md-3 text_header">
                            <p>USUARIO</p>
                        </div>
                        <div class="col-md-1 text_header">
                            <p>SERVICIO</p>
                        </div>
                        
                        <!--  <div class="col text_header">
                            <p>MÉTODO</p>
                        </div>


                        <div class="col text_header">
                            <p>TOTALES</p>
                        </div>

                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        
                    -->
                        <div class="col text_header">
                            FOLIO
                        </div>
    
                        <div class="col text_header">
                            STATUS
                        </div>
    
                        <div class="col text_header"  >
                            UTILIDAD
                        </div>
    
                    </div>
                </div>

                <div class="card-body">
                    <div *ngFor="let item of estudios; let i = index">
                        <div class="row pointer" [routerLink]="['/hoja/consumo/por/usuario', item._id]">
                            
                            
                            <div class="col-md-1">
                                <p> {{ i +1 }} </p>
                            </div>
                            <div class="col-md-3">
                                <p> {{ item.paciente.nombrePaciente | uppercase }} {{ item.paciente.apellidoPaterno | uppercase }} {{ item.paciente.apellidoMaterno | uppercase }} </p>
                            </div>
                            <div class="col-md-1">
                                <p> {{ item.estudios.length }} </p>
                            </div>
                            
                            <!--
                            <ng-container>
                                <div class="col">
                                    <p> {{ item.totalCompra | currency }} </p>
                                </div>
                                
                            </ng-container>

                        -->


                            <div class="col text-center">
                                <p> {{ item.folio }} </p>
                            </div>
                            
                            <div class="col text-center">
                                <p> {{ item.status | uppercase }} </p>
                            </div>

                            <div class="col text-center" *ngIf="busqueda.sede != 'TLYC01'">
                                <p> {{ item.ganancia }} </p>
                            </div>

                        </div>
                    </div>
                </div>
                
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>TOTAL: $ {{ totales }}  . 00</p>
                        </div>
                    </div>
                </div>
    
            </div>
            
        </div>
    </div>
</div>