import { Component, OnInit } from '@angular/core';
import { USGService } from 'src/app/services/usg/usg.service';
import { ActivatedRoute,Router } from '@angular/router';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
import { NgxDropzoneComponent } from 'ngx-dropzone';


@Component({
  selector: 'app-reporte-ultrasonido',
  templateUrl: './reporte-ultrasonido.component.html',
  styleUrls: ['./reporte-ultrasonido.component.css']
})
export class ReporteUltrasonidoComponent implements OnInit {



  public id_: string;
   //public fechas;
  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:'',
    _id:""
  };
  

  public respHttplink = [];

  public ultrasonidos ={
  
    ESTUDIO:"",
    ELEMENTOS:[{machote:""}],
    _id:"",
    machote:""
  
  }

  public usgObtenido={
    idPaciente:"",
    idPedido:"",
    idEstudio:"",
    machoteEdit:"",
    fecha:"",
    observaciones:"",
    diagnostico:"",
    img: [],
    usuario:""
  }
  public imageUrl;
  public estudio;
  public img;
  public machote:""
  public macho:""
  public diagnostico:"";
  public holiwis:any;
  public obs:"";
  public fechas:""
  public userStorage:{
    nombre:""
  }


  public activar = false;
  // public imageUrl = [];
  constructor(

    private activatedRoute: ActivatedRoute,
    private _usgService: USGService,
    private _integrateServicios : IntegradosService,
    private _routers : Router,
    private _usgRegresos: USGService
  ) { }

  ngOnInit(): void {

    this.id_ = this.activatedRoute.snapshot.paramMap.get('id');
    /* console.log(this.id_); */
    this.paciente = JSON.parse(localStorage.getItem('idPaciente'))

    // this.ultrasonidos = this._usgService.getUltrasonidos();
    // console.log(this.ultrasonidos);

    this.obtenerPedidosUltra();
    this.obtenerDatosLocalStorage()
  }

  //Archivos del DROPZONE
  files: File[] = [];

  onSelect(event){
    /* console.log(event); */
    this.files.push(...event.addedFiles);
 
    /* console.log(this.files); */
    
    // const formData = new FormData();
    // this.files.forEach(img => {
    //   console.log(img);

    //     formData.append("name", img, img.name)
    // });

    //     console.log(formData);
        
    
    //     for (var i = 0; i < this.files.length; i++) { 
    //       formData.append("name", this.files[i],this.files[i].name);
    //       console.log(formData);
          
    //     }
    // console.log(this.files);

    
  }

  onRemove(event){
    /* console.log(event); */
    this.files.splice(this.files.indexOf(event), 1);
    
  }
  //Fin Archivos DROPZONE


 setPeidoUltra(pedido){
 this.ultrasonidos =pedido;

 if (this.ultrasonidos.ELEMENTOS[0].machote == undefined){
  //  console.log("no hay nada");  
}else {this.holiwis = this.ultrasonidos.ELEMENTOS[0].machote;} 
   /* console.log(this.holiwis); */
// console.log(this.ultrasonidos.ELEMENTOS[0]);
  
  
 }
   obtenerPedidosUltra(){
    this._integrateServicios.buscarServicio (this.id_)
    .subscribe((data) => this.setPeidoUltra(data ['data']))
   }

  obtenerDatosLocalStorage(){
    this.estudio=JSON.parse(localStorage.getItem('ultrasonido'))
    this.ultrasonidos=JSON.parse(localStorage.getItem('idPedidoUltra'))
     this.paciente = JSON.parse(localStorage.getItem('idPaciente'));
     this.fechas = JSON.parse(localStorage.getItem('fechaUsg'))
     this.userStorage = JSON.parse(localStorage.getItem('usuario'))
    //  console.log(this.userStorage.nombre);
     
  }
  
  enviarReporteUsg(){
    /* console.log(this.holiwis,this.obs,this.diagnostico); */
    
    // if((this.diagnostico === undefined && this.obs === undefined && this.holiwis === undefined) || (this.diagnostico === undefined && this.holiwis != undefined))
    if((this.holiwis == undefined && this.diagnostico == undefined && this.obs == undefined) || (this.obs != undefined && this.diagnostico != undefined && this.holiwis == undefined) || (this.holiwis != undefined && this.diagnostico != undefined && this.obs == undefined) || (this.obs != undefined && this.diagnostico != undefined && this.holiwis == "")  || (this.obs == undefined && this.diagnostico == undefined && this.holiwis != undefined) || (this.obs == "" && this.diagnostico == "" && this.holiwis != undefined)) 
    {
      Swal.fire({
              icon: 'error',
              title: 'DATOS INCOMPLETOS',
              text: 'INFORMACION INCOMPLETA',
            })
     
    }else {
   
      this.pintLoadingScreen();
    
    this.usgObtenido.idPaciente = this.paciente._id
  this.usgObtenido.idEstudio = this.ultrasonidos._id
  this.usgObtenido.idPedido = this.estudio
  this.usgObtenido.diagnostico= this.diagnostico
  this.usgObtenido.machoteEdit = this.holiwis
  this.usgObtenido.observaciones = this.obs
  this.usgObtenido.fecha = this.fechas
  this.usgObtenido.usuario = this.userStorage.nombre

  


    const formData = new FormData();
    const files = new FormData();

  for (var i = 0; i < this.files.length; i++) { 

    formData.append("imageUrl", this.files[i]);
    files.append('file',  this.files[i] );
  }

  /*
    const formData = new FormData();
  for (var i = 0; i < this.files.length; i++) { 
      formData.append("imageUrl", this.files[i]);
  }
  
  */



  // formData.append('idPaciente', this.usgObtenido.idPaciente)
  // formData.append('idEstudio', this.usgObtenido.idEstudio)
  // formData.append('idPedido', this.usgObtenido.idPedido)
  // formData.append('diagnostico', this.usgObtenido.diagnostico)
  // formData.append('machoteEdit', this.usgObtenido.machoteEdit)
  // formData.append('observaciones', this.usgObtenido.observaciones)
  // formData.append('fecha', this.usgObtenido.fecha)
  // formData.append('usuario',this.usgObtenido.usuario);


    this.files.forEach( (file) => {
    
      //este  foreache itera todas las imagenes que se tienen cargadas

      const filesCloudinary = new FormData();

      filesCloudinary.append('upload_preset', 'zlducuhn');
      filesCloudinary.append('cloud_name', "juanpatron1630");
      filesCloudinary.append('file', file);
      // data necesaria para cloudinary
      
      this._usgRegresos.uploadImageUsg( filesCloudinary )
      .subscribe((data:any) => {
          
        // respuesta de cloudinary con las urls de cloudinary
        this.respHttplink.push( data.url );
        
      });

      this.usgObtenido.img = this.respHttplink;

      
    });


    setTimeout(() => {

      this._usgService.enviarUsgRegreso( this.usgObtenido )
        .subscribe((data :any)=>{
          if(data.ok){
            
            this.removeLoadingScreen();

            Swal.fire({
                    icon: 'success',
                    title: 'DATOS ENVIADOS',
                    text: 'INFORMACION ENVIADA CORRECTAMENTE',
                  })
        
          }

          this._routers.navigate(['/'])
          
        })
    }, 3500);


} 

  }


  pintLoadingScreen() {
    // funcion que pinta el loading en la pantalla
    const sectionSpinner = document.querySelector('#sppiner-section');
    sectionSpinner.classList.add('display-block');
}

removeLoadingScreen() {
  const sectionSpinner = document.querySelector('#sppiner-section');
  sectionSpinner.classList.remove('display-block');
  /* console.log('dejo de funcionar'); */
  
}


}


