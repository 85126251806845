<div class="container animate bounceInLeft">
    <h2 class="text-center"></h2>
    

        <ficha-info-user [pacienteInfo]="pacienteInfo"></ficha-info-user>

     
    <div class="card" id="card">
        <div class="">
            <div class="row">
                <div class="col-md-9">
                    <div class="container-fluid">
                        <button class="btn btn-secondary mb-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <i class="fas fa-folder-plus"></i> Agregar consulta
                        </button>
    

                        <div class="btn mb-2 noprint mt-3 ">

                            <app-select-servicios ></app-select-servicios>
                            
                        </div>
    
                        <!-- 
                    
                        -->

                        <p class="alert alert-danger" role="alert" *ngIf="diasRestantes <= 10">
                            Tú membresía vence en {{ diasRestantes }} días Renueva tu membresía
                        </p>
                        <div class="collapse mb-4" id="collapseExample">
                            <div class="card card-body">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <select class="form-control" #select (change)="seleccion($event, select.value)" [(ngModel)]="consultas.tipo">
                                                    <option value="" selected>Tipo de consulta o estudio...</option>
                                                    <option value="visitas" *ngIf="pacienteInfo.membresiaActiva">Citas incluidas</option>
                                                    <!-- <option value="ultrasonido" >Ultrasonido</option> 
                                                    <option value="laboratorio">Estudios de laboratorio</option>
                                                    <option value="xray">Rayos X</option> -->
                                                </select>
                                                <!-- <p class="text-danger">*No puedes agregar más consultas</p> -->
                                            </td>
                                            <td>
                                                <select class="form-control" #selectCon [(ngModel)]="consultas.consulta">
                                                  <option value="" selected>Concepto...</option>
                                                  <option [value]="item" *ngFor="let item of concepto">{{ item }}</option>
                                                </select>
                                            </td>
                                            <td>

                                                <app-select-medicos (MedicoSelected)="responseMedico($event.value)"></app-select-medicos>
                                            </td>
                                            <!-- <td><input type="text" class="form-control" placeholder="Firma..." [(ngModel)]="consultas.firma"></td> -->
                                            <td>
                                                <button class="btn btn-warning" (click)="agregarConsulta()">Agregar</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
            <div class="col-md-3">
                <img [src]="item.paquete.icon" *ngFor="let item of paquete" class="img-fluid">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                    <div class="p-3 mb-2 bg-success text-white">
                        Citas incluidas
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let item of citas; let i = index">
                                <div class="card border-success" style="cursor: pointer;" (click)="mostrarDatos(item.consulta, item.medico, '1')">
                                    <div class="card-body">
                                        <span class="badge badge-success">{{ i + 1 }}</span>
                                        <p class="text-center"><i class="fas fa-file-alt mr-2"></i> {{ item.fecha }} {{ item.hora }} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="mt-5 p-3 mb-2 bg-primary text-white">
                        Estudios de laboratorio
                    </div>
                    <div class="container-fluid">
                        <div class="row cardsExamen">
                            <div class="col-md-4">
                                <div class="card" id="cardBasicos" style="cursor: pointer;" (click)="showMessage( 'Estudios basicos')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeBasicos"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Estudios basicos </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="card" style="cursor: pointer;" id="especiales" (click)="showMessage('Estudios especiales')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeEspeciales"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Estudios especiales </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 p-3 mb-2 bg-primary text-white ">
                        Consultas
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card" id="cardGeneral" style="cursor: pointer;" (click)="showMessage( 'Consulta  de medicina general sin costo de lunes a domnigo las 24 Horas')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeGeneral"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Consulta de medicina general sin costo de lunes a domnigo las 24 Horas </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" id="cardNutricion" style="cursor: pointer;" (click)="showMessage( 'Nutrición')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeNutricion"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Nutrición </p>
                                        <p class="text-center">Hora: {{ nutricion.hora }} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" id="cardGinecologia" style="cursor: pointer;" (click)="showMessage( 'Ginecología')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeGinecologia"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Ginecología </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" id="cardPediatria" style="cursor: pointer;" (click)="showMessage( 'Pediatría')">
                                    <div class="card-body">
                                        <span class="badge" id="badgePediatria"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Pediatría </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" id="cardTrauma" style="cursor: pointer;" (click)="showMessage( 'Traumatología y ortopedi')">
                                    <div class="card-body">
                                        <span class="badge" id="badgeTrauma"></span>
                                        <p class="text-center"><i class="fas fa-female mr-2"></i> Traumatología y ortopedi </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
    </div>