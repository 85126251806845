<app-utilidad-socio-ambulancia *ngIf="servicio == 'ambulancia'"></app-utilidad-socio-ambulancia>
<app-utilidad-socio-servicios *ngIf="servicio != 'ambulancia'"></app-utilidad-socio-servicios>

<!-- <div class="container-fluid" style="height: 40rem;">
    <h1 class="centerxd">LISTADO DE SERVICIO {{servicios | uppercase}}</h1>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar servicio" class="form-control" style="max-width:100em">
            </div>
        </div>
    </div>

    <div class="col-xs-12" id="tabla">
        <div class="card" style="border-radius: 30px;">
            <div class="card-heder header">
                <div class="row">
                    <div class="col text_header">
                        <p>#</p>
                    </div>
                    <div class="col-md-1 text_header">
                        <p>SERVICIO O PRODUCTO</p>
                    </div>
                    <div class="col text_header">
                        <p>PRECIO PUBLICO</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>PRECIO MEMBRESÍA</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>PRECIO URGENCIAS</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div>
                    <div class="col text_header">
                        <p>PRECIO URGENCIAS MEMBRESÍA</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div> 
                    <div class="col text_header">
                        <p>PRECIO HOSPITALIZACION</p>
                    </div>
                    <div class="col text_header">
                        <p>UTILIDAD</p>
                    </div>
                    <div class="col text_header">
                        <p>MONTO</p>
                    </div> 
                    <div class="col-md-1 text_header">
                        <p>ACCIONES</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="card-body" style="background-color: #ffffff;">
            <div *ngFor="let item of servicioSI | paginate:{id:'listaAmbulancia',
            itemsPerPage: 5 ,
            currentPage:pagina,
            totalItems:totalAmbulancia}; let i=index">
                <div class="row">
                    <div class="col">
                        <p>{{i+1}}</p>
                    </div>
                    <div class="col-md-1">
                        <p style="overflow-wrap: break-word;">{{item.ESTUDIO}}</p>
                    </div>
                    <div class="col">
                        <p>{{item.PRECIO_PUBLICO | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO")}}%</p>
                    </div>
                    <div class="col">
                        <p>{{(item.PRECIO_PUBLICO * socioUtilidad(item._id,"PRECIO_PUBLICO")) / 100 | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{item.PRECIO_MEMBRESIA | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{socioUtilidad(item._id,"PRECIO_MEMBRESIA")}}%</p>
                    </div>
                    <div class="col">
                        <p>{{(item.PRECIO_MEMBRESIA * socioUtilidad(item._id,"PRECIO_MEMBRESIA")) / 100 | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{item.PRECIO_PUBLICO_URGENCIA | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO_URGENCIA")}}%</p>
                    </div>
                    <div class="col">
                        <p>{{(item.PRECIO_PUBLICO_URGENCIA * socioUtilidad(item._id,"PRECIO_PUBLICO_URGENCIA")) / 100 | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{item.PRECIO_MEMBRESIA_URGENCIA | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{socioUtilidad(item._id,"PRECIO_MEMBRESIA_URGENCIA")}}%</p>
                    </div>
                    <div class="col">
                        <p>{{(item.PRECIO_MEMBRESIA_URGENCIA * socioUtilidad(item._id,"PRECIO_MEMBRESIA_URGENCIA")) / 100 | currency}}</p>
                    </div> 
                    <div class="col">
                        <p>{{item.PRECIO_PUBLICO_HOSPITALIZACION | currency}}</p>
                    </div>
                    <div class="col">
                        <p>{{socioUtilidad(item._id,"PRECIO_PUBLICO_HOSPITALIZACION")}}%</p>
                    </div>
                    <div class="col">
                        <p>{{(item.PRECIO_PUBLICO_HOSPITALIZACION * socioUtilidad(item._id,"PRECIO_PUBLICO_HOSPITALIZACION")) / 100 | currency}}</p>
                    </div> 
                    <div class="col-md-1">
                        <div class="row">
                            <div class="col-xs-1">
                                <label class="btn btn-outline-success" (click)="editar(item._id)">
                                    <i class="fas fa-edit"></i>
                                </label>
                            </div>
                            <div class="col-xs-1">
                                <label class="btn btn-outline-danger" style="margin-left: 1rem;" (click)="eliminar(item._id)">
                                    <i class="fas fa-trash-alt"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>      
    </div>
</div> -->