import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-stock-productos-alamacen-bs',
  templateUrl: './stock-productos-alamacen-bs.component.html',
  styleUrls: ['./stock-productos-alamacen-bs.component.css']
})
export class StockProductosAlamacenBSComponent implements OnInit {

  public idFi = "";
  public productos = [];
  public btnValidate = true;
  // queda pendiente el nombre comercial, recuerdo que ese se iba a quitar
  public productoData = {
    // productosFI, es el id de la tabla administrativa
    productosFI: "",
    lote: "",
    proveedor: "",
    factura:"" ,
    lote_unitario:"" ,
    fecha_caducidad:"",
    cantidad: 0,
    costoReal:0,
    precioVenta:0,
  }
  public dataFi = {
    nombre:"",
    costo:"",
    estado:"",
    descripcion:"",
    nombre_comercial:"",
    proveedor: []
  }
  public proveedores = [];
  public totalProductos:string;
  public pagina = 0;

  constructor(private _almacenService: AlmacenService,
              private activatedRoute: ActivatedRoute,
              private _spinner: NgxSpinnerService){}

  ngOnInit(): void {
    this.idFi = this.activatedRoute.snapshot.paramMap.get('id');
    this.obetnerProductoFIAdmin();
    this.obtenerProductosAlmacen();
    this.obtenerProveedores();
  }

  //peticion de los proveedores
  obtenerProveedores() {
    this._almacenService.obetenerProveedores().subscribe((data:any) => this.setProveedores(data.data));
  }

//setamos todos los proveedores
  setProveedores(data){
      this.proveedores = data;
  }

  //peticion que obtiene todos los productos del stock
  obtenerProductosAlmacen() {
    this.productos = [];
    this._almacenService.obteneProductosPorId(this.idFi )
    .subscribe((data:any) => {
      this.setProductosAlmacen(data.data)
    });
  }

  setProductosAlmacen(data:any) {
    this.productos = data;
  }

  // validamos que el formulario no vaya vacio
  // validateForm() {
  //   if( this.productoData.lote.length == 0 ){
  //     alert("Ingresa el lote")
  //   }else if( this.productoData.cantidad == 0 ) {
  //     alert("Ingresa la cantidad");
  //   }else if( this.productoData.proveedor.length == 0 ) {
  //     alert("Ingresa el proveedor");
  //   }else if(this.productoData.factura.length == 0 ) {
  //     alert("Ingresa la Factura");
  //   }
  //   // habilitamos el boton
  //   this.btnValidate = false;
  // }

  obtenerForm(event){
    this.productoData = event.value;
    if(event.status == 'VALID'){
      this.btnValidate = false;
    }
  }

  // pintLoadingScreen() {
  //   // funcion que pinta el loading en la pantalla
  //   const sectionSpinner = document.querySelector('#sppiner-section');
  //   sectionSpinner.classList.add('display-block');
  // }

  // removeLoadingScreen() {
  //   // se quita la clase que pinta el loading en la pantalla, la clase esta en el css
  //   const sectionSpinner = document.querySelector('#sppiner-section');
  //   sectionSpinner.classList.remove('display-block');
  // }

  registrarProductoEnStock() {
    this._spinner.show();
    // se setea el id del producto
    this.productoData.productosFI = this.idFi;
    // this.pintLoadingScreen();
    this.btnValidate = true;
    this._almacenService.agregarStockProducto( this.productoData ).subscribe((data:any) => {
      if(data.ok){
        const newPrice = {
          proveedor: this.productoData.proveedor,
          costo: this.productoData.costoReal
        }
        this._almacenService.updatePriceAProductById( this.idFi, newPrice ).subscribe((data:any) =>{
          if(data.ok) {
            this.dataFi = data['data'];
            //  quitamos el loading del formulario
            this.obtenerProductosAlmacen();
            //setamos la data en null para poder agregar más productos
            // this.removeLoadingScreen();
            this.productoData.cantidad = 0;
            this.productoData.costoReal = 0;
            this.productoData.factura ="";
            this.productoData.fecha_caducidad = "";
            this.productoData.lote = "";
            this.productoData.lote_unitario = "";
            this.productoData.proveedor = "";
            this.productoData.precioVenta = 0;
            this._spinner.hide();
            this.btnValidate = true;
          }
        })
      }
    });
  }
  
  //peticion de la FI del producto
  obetnerProductoFIAdmin() {
    this._almacenService.obtenerProductosPorIdTablaAdmin(this.idFi)
      .subscribe( (data:any) => this.setDataFIProductosAdmin(data.data)); 
  }

  printImgQr() {
    //obtenemos el element que se va  a usar para imprimir el PDF
    const imgsQr = document.querySelectorAll('.img-responsive');
    const page = document.body.innerHTML;
    //pasamos todos los componentes del body a un stringify
    document.body.innerHTML = "";
    // dejamos vacio el HTML 
    imgsQr.forEach( img  => { 
      //iteramos todos los elementos  y les agregamos la clase img-to-print
      img.classList.add('img-to-print');
      document.body.append(img)
      //hacemos el append de los elementos con la clase
    });
    // lamamos el print
    window.print();
    imgsQr.forEach( img => {
      //removemos la clase 
      img.classList.remove('img-to-print')
    });
    document.body.innerHTML = page;
    //window.location.reload();
  } 

  setDataFIProductosAdmin(data:any) {
    this.dataFi = data;
    //seteamos la data de la peticion
  }


  imprimirEtiquetaIndividual( imgTag: HTMLElement, id: String ){
    // hacemos lo mismo de los pdfs pero con la data mas estatica
    this.changeStatusLabel( id );
    const page = document.body.innerHTML;
    imgTag.classList.add('img-to-print');
    document.body.innerHTML = "";
    document.body.appendChild( imgTag );
    window.print();
    imgTag.classList.remove('img-to-print');
    document.body.innerHTML = page;
    window.location.reload();

  }
  // hace el camcbio del estado de los docs
  changeStatusLabel(id: String) {
    this._almacenService.changeStatusLabeldownloaded( id )
    .subscribe((data:any) => console.log(""))
  }
  
}
