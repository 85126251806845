import { gaurdarCotizacionCensur, getDataCarritoCensur } from 'src/app/functions/storage.funcion';
import { getCarritoStorageCensur } from '../../../app/functions/pacienteIntegrados';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default class CarritoCensur {

    public carrito= {
        total: 0,
        items: [],
    };


    constructor(){
        /* this.obtenerSotorageCarrito(); */
    }

    public obtenerSotorageCarrito(){
        this.carrito = getCarritoStorageCensur();
        if ( this.carrito == null ){
            this.carrito = {
              total: 0,
              items: [],
            };
        }
        return this.carrito;
    }

    public agregarItem(item){

        const pedido = {
            producto: item.producto,
            precio:"",
            cantidad:1,
            idProducto: item.idProducto
        }

        pedido.precio = item.precio;
        this.cantidad(pedido.idProducto, pedido);
        let carritoString = JSON.stringify( this.carrito );
        gaurdarCotizacionCensur( carritoString );
        this.carrito = getDataCarritoCensur();
        this.alerta();
        return this.carrito;
    
    }

    public agregarItemAlmace( item ){

        const pedido = {
            producto: item.nombre,
            precio:"",
            cantidad:1,
            idProducto: item.idProducto
        }

        pedido.precio = item.precio;
        this.cantidad(pedido.idProducto, pedido);
        let carritoString = JSON.stringify( this.carrito );
        gaurdarCotizacionCensur( carritoString );
        this.carrito = getDataCarritoCensur();
        this.alerta();
        return this.carrito;
    
    
    }

    public cantidad(id, pedido){
        this.obtenerSotorageCarrito();
        if(this.carrito.items.length == 0){
            this.sumarTotal( pedido.precio);
            this.carrito.items.push( pedido );
        }else{
            const found = this.carrito.items.findIndex(element => element.idProducto == id);
            if(found >= 0){
                this.restarTotal(this.carrito.items[found].precio, this.carrito.items[found].cantidad);
                this.carrito.items[found].cantidad = this.carrito.items[found].cantidad + 1;
                this.sumarTotal((this.carrito.items[found].precio * this.carrito.items[found].cantidad));  
            }else{
                this.sumarTotal( pedido.precio);
                this.carrito.items.push( pedido );
            }
        }
    }

    public  sumarTotal( precio){
        this.carrito.total = this.carrito.total + precio;
    }

    public  restarTotal(precio, cantidad) {
        this.carrito.total = this.carrito.total - (precio * cantidad);
    }

    public restarUno(precio){
        this.carrito.total -= precio
    } 
    
    public agregarProductos(productos, total){
        productos.forEach(element => {
            this.carrito.items.push( element );
        });
        this.carrito.total = total;
        let carritoString = JSON.stringify( this.carrito );
        gaurdarCotizacionCensur( carritoString );
        this.carrito = getDataCarritoCensur();
        return this.carrito;
    }

    public  eliminar( id ) {
        this.carrito.items.forEach(  (item, index) => {
            if (index  === id ) {
                if(item.cantidad == 1){
                    this.carrito.items.splice( index, 1 )
                    this.restarTotal( item.precio, item.cantidad );
                }else{
                    item.cantidad -= 1;
                    this.restarUno (item.precio);
                }  
            }
        });
        let  carritoString = JSON.stringify( this.carrito  );
        gaurdarCotizacionCensur(  carritoString );
    }

    public alerta(){
        Swal.fire({
            icon: 'success',
            title: 'SE AGREGO CORRECTAMENTE EL PRODUCTO',
            text: 'NUEVO PRODUCTO',
          })
    }
}