import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, } from '@angular/forms';
//import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 
import { IntegradosService } from 'src/app/services/servicios/integrados.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-studio',
  templateUrl: './new-studio.component.html',
  styleUrls: ['./new-studio.component.css']
})
export class NewStudioComponent implements OnInit {
  forma: FormGroup; 

public id;
public elementos = '';

public pedido  = {
  ESTUDIO:"",
  ELEMENTOS: [{ referencia:[]}], 
    _id: "", 
    idPaciente:"", 
    fecha: "", 
    hora: ""
  };
  
  public  newValorDeReferencia:any;
  // public newFormValor:any;
  public formNewLab: any;
  public newFormValor:any;
  public btnAgregar = false;

  constructor(private _routers: Router,
     private formBuilder: FormBuilder, 
     private _laboratorio: LaboratorioService,
     private fb: FormBuilder, 
     private _route: ActivatedRoute,
     private consumoIntegrado : IntegradosService) { 
    /* console.log(this.crearFormulario()); */
    /* console.log(this.crearValoresRef()); */
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerIdEstudio();
    /* this.crearValoresRef(); */
  }

  ngOnInit(): void {
    this.creacioDeForm()
    /* this.obtenerEstudios(); */
  }

  creacioDeForm() {

    this.newFormValor =  this.formBuilder.group({ 
       nombreEstudio: [''],
       idEstudio: [''],
       tipoExamen: ['', {validators: [Validators.required]}],
       valores: this.formBuilder.array([]),
       observaciones: [''],
       metodo: ['', {validators: [Validators.required]}]
     });
     
     this.addValoreDeReferencia();
  }

  addValoreDeReferencia() {

    const valoresInput =  this.formBuilder.group({
      grupo:'',
      elemento: '',
      unidad: '',
      valorDeReferencia: this.formBuilder.array([
        this.formBuilder.group({
          valorReferenciaD: '',
        })
      ]),
    });

    this.valoresDeReferencias.push( valoresInput );
    
  }
  get valoresDeReferencias() {
      return this.newFormValor.get('valores') as FormArray;
  }

  get valoresDeHombreYMujer() {
    return this.newFormValor['controls'].valores['controls'] as FormArray;
   }

   agregarValoresReferenciaD(index: number) {

    const masValores = this.formBuilder.group({
      valorReferenciaD : ''
    });
    // console.log(     )

    //if(this.newFormValor['controls']['valores']['controls'][index]['controls']['valorDeReferencia'].length  < 2  ) {
      this.newFormValor['controls']['valores']['controls'][index]['controls']['valorDeReferencia'].push(masValores);
    //}

  }

  quitarValoresReferenciaD(index2: number, index: number) {
    if(  this.newFormValor['controls']['valores']['controls'][index2]['controls']['valorDeReferencia'].length  > 1 ) {
      this.newFormValor['controls']['valores']['controls'][index2]['controls']['valorDeReferencia'].removeAt(index);
    }

}

  removeValores( i: number ){
    /* console.log(i); */
    if( this.valoresDeReferencias.length  > 1 ) {
      this.valoresDeReferencias['controls'].forEach((element, index) => {
        if (index == i) {
          this.valoresDeReferencias['controls'].splice(index, 1)
        }
      });
    }
}

validatefields(){
  this.btnAgregar = false;
}

sendDataPost() {
  let data = Object.assign(this.newFormValor.value,{nombreEstudio:this.elementos},{idEstudio:this.id})
  this._laboratorio.agregarEstudio( data )
  //this._banco.agregarnuevosestudios( this.newFormValor.value )
  .subscribe( (data:any) => {
    this.btnAgregar = true;
     Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS DATOS SE ENVIARON CORRECTAMENTE',
            })
     this._routers.navigate(['/historico-estudio'])
  })

}

 
 
   
  get grupos(){
    return this.forma.get('grupos') as FormArray;
  }

  get referencia(){
    return this.forma.get('referencia') as FormArray;
  }
  // get referencia(){
  //   return this.forma.get('referencia') as FormArray;
  // }
  // get tipo(){
  //   return this.forma.get('tipo') as FormArray;
  // }

  

  /* agregarRefer2(){
    const valores =  this.fb.group({
      referencia:""
    });
    console.log(this.forma)
    this.grupos.controls['0'].controls['referencia'].push(valores)
  } */

  agregarRefer(index){
    const valores =  this.fb.group({
      referencia:""
    });
    /* console.log(this.forma) */
    this.grupos.controls[''+index].controls['referencia'].push(valores)
  }

  borrarCampo(i :  number){
    this.grupos.removeAt(i);
  }

    vista(){
      /* console.log(this.forma) */
    }

    obtenerIdEstudio(){
       this.consumoIntegrado.getServicioById(this.id)
       .subscribe((data:any) => {
        /* console.log(data); */ 
        this.elementos=data ['data'].ESTUDIO
      })
    }


    enviarSave(){
      /* console.log(this.forma.value) */
      this.consumoIntegrado.actualizarElemtos(this.id,this.forma.value)
      .subscribe ((data:any)=> {this.elementos =data ['data']
      /* console.log(data) */
    }) 
 Swal.fire({
              icon: 'success',
              title: '',
              text: 'LOS DATOS SE ENVIARON CORRECTAMENTE',
            })
     this._routers.navigate(['/historico-estudio'])

    }




    /* obtenerEstudios(){

      this.consumoIntegrado.buscarServicio( this.id)
      .subscribe((data) =>    this.setPedido(  data['data']  ))
    }
  
   setPedido(  pedido ){
    this.elementos = pedido;
    console.log(pedido )
   }  
 */
}
