<div class="container-fluid mb-4">
    <!-- <h1 class="ml-5 title-principal">ACTUALIZAR PRODUCTO</h1> -->
    <app-titulos [title]="'ACTUALIZAR PRODUCTO'"></app-titulos>
</div>

<div class="container-fluid">
    <div class="card cardPer container">
        <form [formGroup]="newFormProductos">
            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> NOMBRE:</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" name="nombre"    class="form-control shadow-input-bs" formControlName="nombre" />
                </div>
                <div class="col-md-2">
                    <label class="mayus" for="idProducto"> ID: </label>
                </div>
                <div class="col-md-4">
                    <input type="text"  name="idProducto" placeholder="2EK3j34R14U34U" id="idProducto" class="form-control shadow-input-bs" formControlName="idProducto"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-2">
                    <label class="mayus">TIPO DE PRODUCTO</label>
                </div>
                <div class="col-md-4">
                    <select class="form-control shadow-input-bs" required name="tipo_producto" formControlName="tipo_producto">
                            <option value=""> Selecciona el tipo de producto </option>    
                            <option value="I" >I</option>
                            <option value="II" >II</option>
                            <option value="III" >III</option>
                            <option value="IV" >IV</option>
                            <option value="V" > V </option>
                            <option value="VI" > VI </option>     
                    </select>   
                </div>
                <div class="col-md-2">
                    <label class="mayus">NOMBRE COMERCIAL</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control shadow-input-bs" name="nombre_comercial" id="nombre_comercial" placeholder="CONCETRADO ELICTROCITARIO" formControlName="nombre_comercial"/>
                </div>
            </div>
            <div class="row mt-4" *ngIf="medicamento">
                <div class="col-md-2">
                    <label class="mayus">PRESENTACION</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control shadow-input-bs" name="presentacion" id="" required formControlName="presentacion">   
                </div>
                <div class="col-md-2">
                    <label class="mayus">CONTENIDO DEL FRASCO</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control shadow-input-bs" name="contenidoFrasco" id="" required formControlName="contenidoFrasco">   
                </div>
            </div>
            <div class="row" *ngIf="medicamento">
                <div class="col-md-2">
                    <label class="mayus">VIA DE ADMINISTRACION</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control shadow-input-bs" name="viaAdministracion" id="" required    formControlName="viaAdministracion">   
                </div>
                <!-- <div class="col-md-2">
                    <h5> Fecha de caducidad:</h5>
                </div>
                <div class="col-md-4">
                    <input type="date" class="input" name="fechaCaducidad" id="" required  formControlName="fechaCaducidad">   
                </div> -->
            </div>
            <div formArrayName="proveedor" *ngFor="let valor of valoresProveedor.controls; let j = index" style="margin-top: 20px;">
                <div  [formGroupName]="j">
                    <div class="row mb-3">
                        <div class="col-md-2">
                            <label class="mayus">PROVEEDOR</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control shadow-input-bs"  id="inputGroupSelect01"  formControlName="proveedor" >
                                <option [value]="proveedor.razonSocial"   *ngFor="let proveedor of proveedoresCensur" > {{ proveedor.nombreEmpresa }} </option>
                                <!-- <option value="Vic"></option> -->
                              </select>
                        </div>
                        <div class="col-md-2">
                            <label class="mayus">COSTO</label>
                        </div>
                        <div class="col-md-3">
                            <input type="number" min="0" class="form-control shadow-input-bs" name="" id=""  required   formControlName="costo" >
                        </div>
                        <div class="col-md-1">
                            <i class="fa fa-trash-alt colorRed pointer" (click)="removeValores(j)"></i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ! Modal de los Proveedores -->
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Agregar Proveedor</h5> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <app-registro-proveedores-bs (agregar)="agregarNuevoProveedor($event)"></app-registro-proveedores-bs>
                  </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> LABORATORIO</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" placeholder="PFIZER" name="laboratorio" class="form-control shadow-input-bs" formControlName="laboratorio"/>
                </div>
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> CANTIDAD MINIMA</label>
                </div>
                <div class="col-md-4">
                    <input type="number" min="0" class="form-control shadow-input-bs" name="cantidadMin" id="" required    formControlName="cantidadMin">   
                </div>
                <!-- <div class="col-md-2">
                    <label class="mayus" for="precio"> Precio de venta: </label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="precio" placeholder="$200.00" name="precio" class="form-control shadow-input-bs" [(ngModel)]="productoData.precio" />
                </div> -->

            </div>
            <!-- <div class="row ml-1 mb-4">
                <label class="mayus pointer font-color-bs"  >AGREGAR OTRO PROVEEDOR...</label>
            </div> -->
            <div class="row ml-1 mb-4 mt-3">
                <!-- <button class="boton" (click)="agregarProveedor({proveedor:'', costo:0})">
                    Agregar proveedor
                </button> -->
                <div class="col-md-3">
                    <label class="proveedor" (click)="agregarProveedor({proveedor:'', costo:0})">Agregar proveedor</label>
                </div>
                <div class="col-md-3">
                    <a class="proveedor" type="button" data-toggle="modal" data-target=".bd-example-modal-lg">Agregar NUEVO Proveedor</a>
                </div>
                <!-- <h5 class="mayus pointer pointer-prove" (click)="agregarProveedor()" style="color: #00acd8;">agregar  proveedor</h5> -->
            </div>
            <div class="row ml-1">
                <div class="col-md-12">
                    <label class="mayus">descripción:</label>
                </div>
                <div class="col-md-12">
                    <textarea class="mb-3" name="descripcion" id="" cols="2" rows="3" formControlName="descripcion" ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 offset-md-5">    
                    <button  class="btn btnPrincipalBS" [disabled]="btnValidacion" (click)="actualizarProducto()">
                        GUARDAR
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- <div class="container-fluid">
    <div class="card cardPer container">
        <form [formGroup]="newFormProductos">
            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> NOMBRE:</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" name="nombre"    class="form-control shadow-input-bs" [(ngModel)]="productoData.nombre" />
                </div>
                <div class="col-md-2">
                    <label class="mayus" for="idProducto"> ID: </label>
                </div>
                <div class="col-md-4">
                    <input type="text"  disabled  name="idProducto" placeholder="2EK3j34R14U34U" id="idProducto" class="form-control shadow-input-bs" [(ngModel)]="productoData.idProducto" />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-2">
                    <label class="mayus">TIPO DE PRODUCTO</label>
                </div>
                <div class="col-md-4">
                    <select class="form-control shadow-input-bs" required name="tipo_producto" [(ngModel)]="productoData.tipo_producto">
                            <option value=""> Selecciona el tipo de producto </option>    
                            <option value="I" >I</option>
                            <option value="II" >II</option>
                            <option value="III" >III</option>
                            <option value="IV" >IV</option>
                            <option value="V" > V </option>
                            <option value="VI" > VI </option>     
                    </select>   
                </div>
                <div class="col-md-2">
                    <label class="mayus">NOMBRE COMERCIAL</label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control shadow-input-bs" name="nombre_comercial" id="nombre_comercial" placeholder="CONCETRADO ELICTROCITARIO" [(ngModel)]="productoData.nombre_comercial" />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-2">
                    <label class="mayus">PROVEEDOR</label>
                </div>
                <div class="col-md-4">
                    <select class="form-control shadow-input-bs" name="proveedor" required [(ngModel)]="productoData.proveedor" >
                        <option value="item.razonSocial"  *ngFor="let item of proveedoresCensur"  > {{item.nombreEmpresa }} </option>
                    </select> 
                </div>
               <div class="col-md-2">
                    <label class="mayus">COSTO</label>
               </div>
                <div class="col-md-4">
                    <input type="number" class="form-control shadow-input-bs" name="costo" [(ngModel)]="productoData.costo" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="mayus" for="nombre"> LABORATORIO</label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="nombre" placeholder="PFIZER" name="laboratorio" class="form-control shadow-input-bs"[(ngModel)]="productoData.laboratorio" />
                </div>

                <!-- <div class="col-md-2">
                    <label class="mayus" for="precio"> Precio de venta: </label>
                </div>
                <div class="col-md-4">
                    <input type="text" id="precio" placeholder="$200.00" name="precio" class="form-control shadow-input-bs" [(ngModel)]="productoData.precio" />
                </div>

            </div>
            <div class="row ml-1 mb-4">
                <label class="mayus pointer font-color-bs"  >AGREGAR OTRO PROVEEDOR...</label>
            </div>
            <div class="row ml-1">
                <div class="col-md-12">
                    <label class="mayus">descripción:</label>
                </div>
                <div class="col-md-12">
                    <textarea class="mb-3" name="descripcion" id="" cols="2" rows="3" [(ngModel)]="productoData.descripcion"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 offset-md-5">    
                    <button  class="btn btnPrincipalBS"  (click)="actualizarProducto()" >
                        REGISTRAR
                    </button>
                </div>
            </div>
        </form>
    </div>
</div> -->