import { Component, OnInit } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute} from '@angular/router'
// clases que se ocupan para ver la membresia si es que estan habilitada
import  PacienteStorage  from '../../../../classes/pacientes/pacientesStorage.class';
import Carrito  from '../../../../classes/carrito/carrito.class';
import { getrolefunction } from 'src/app/functions/storage.funcion';

@Component({
  selector: 'app-patologia-x',
  templateUrl: './patologia-x.component.html',
  styleUrls: ['./patologia-x.component.css']
})
export class PatologiaXComponent implements OnInit {

  // data de los servicios
  public serviceSi: any [] = [];
  public ambulanciaSI: any [] = [];
  public totalAmbulancia: string;
  public termino: string;
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showAmbulanacia = false;
  public searching = false;
  public servicios:string;
  public membresia : any;
  public titulo;

  public todosLosServicios = {
    ambulancia: [],
    endoscopia: [],
    laboratorios: [],
    message: '',
    ok: false,
    otrosServicio: [],
    patologia: [],
    rayosX: [],
    tomografia: [],
    ultrasonido: []
  };

  public roleuser;

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public buscar = {
    estudio:''
  };

  public paciente:any;


  constructor(private _service: IntegradosService, private _router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let title = 'LISTADO DE '
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.titulo = title.concat(this.servicios)
    this.obetenerMembresia();
    this.obtenerCarrito();
    this.getRoleUser();
  }

  obtenerCarrito(){
   const storageCarrito = new Carrito();
   this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  getRoleUser(){
    this.roleuser = getrolefunction();
  }

  obetenerMembresia(){

    const pacienteStorage = new PacienteStorage();
    this.paciente  = pacienteStorage.verPacienteConMembresia();

    if( this.paciente == null  || this.paciente == undefined){
      
      this.verDatos();
    }else {
      this.membresia = pacienteStorage.verMembresiaPaciente();
      this.verDatos();
    }

  }

  /* eliminar(idEstudio){
    let carrito = new Carrito();
    carrito.eliminar(  idEstudio );
    this.obtenerCarrito();

  } */

  busquedaGeneral(){
    if(this.buscar.estudio.length >3){
      this._service.getAllDepartments(this.buscar)
      .subscribe((res:any) => {
        // console.log( res );
        if(res.data[0]!=0){
          this.ambulanciaSI = res.data[0];
          this.showAmbulanacia= true;
          this.showTableAmbulanacia = false;
        }else{
          this.serviceSi = res.data[1]; 
          this.searching = true;
          /* console.log(  this.serviceSi ); */ 
        }            
      })
    }
    if(this.buscar.estudio == ''){
      this.searching = false;
      this.showAmbulanacia= false;
      this.showTableAmbulanacia = true;
      this.verDatos();
    }
  }
  // se obtiene la informacion del carrito

  /* agregarCarrito( event, item: any ) { 
        const carrito = new Carrito();
      this.carrito =  carrito.agregarItem(  event, item );
  } */




  verDatos(){
    this._service.getObtener(this.servicios).subscribe(
      (res: any) => {
          this.serviceSi = res.data;
          this.totalAmbulancia = res.data.results;
      },
      err => {
        /* console.log(<any>err); */
      }
    );   
  }

  /* alertcomparasion( ev, precioPublico, precioMembresia, item2:any ){

    const carritoAlert = new Carrito();
    const alertPrecio = carritoAlert.messageCompracion(ev, precioPublico, precioMembresia, item2 );
 
  } */

  /* showAlert(){
    swal({title: "Estas seguro de contratar a este destino?",
    text: "El servicio de ambulancia solo será requerido para dicho destino, no puede haber cambios",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancelar",
        value: null,
        visible: true,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "OK",
        value: true,
        visible: true,
        className: "",
        closeModal: true
      }
    },
    dangerMode: true,
  })
  .then((value) => {
    console.log( value );
    if (value) {
      swal("Vamos a llenar el papeleo!", {
        icon: "success",
      });
      this._router.navigateByUrl('/hoja-fram');
    } else if( value == null ) {
      swal("Tranquilo, Puedes intentar contratar algun otro destino!", {
        icon: "error",
      });
    }});

  }

  editarServicio(id){
    swal({title: "Estas seguro de Editar este servicio?",
    text: "Una vez que se haya editado el servicio, no se podrá recuperar",
    icon: "warning",
    buttons: [true, true],
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      this._router.navigateByUrl('formularioeditarservice/' +id)
    } else if (willDelete == null){
      swal("Tranquilo, el servicio sigue estando ahí..", {
        icon: "error",
      });
      this._router.navigateByUrl('serviciosInt/'+this.servicios);
    }});
  }
  
  delete(id) {
    swal(
      {title:"¿Estas seguro de eliminar este servicio?",text:"Una vez eliminado el servicio, no se podra recuperar",icon:"warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "",
          closeModal: true
        }
      },
      dangerMode: true,}
    ).then((willDelete) => {
      if (willDelete) {
        this._service.deleteService(id).subscribe(
          response => {
            //console.log("Registro Eliminado!", "Este registro no se podrá ver más", "error");
            swal("El servicio se ha eliminado correctamente",{icon:"success"});
            this.verDatos();
            this._router.navigateByUrl('serviciosInt/'+this.servicios);
          },
          error => {
            console.log(error);
          }
        );
      } else if (willDelete == null){
        swal("Tranquilo, el destino sigue estando ahí..", {
          icon: "error",
        });
      }});
  } */

}
