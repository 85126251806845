<div class="container-fluid">
    <h1 class="centerxd">BITÁCORA DE ENFERMERÍA DE FLEBOTOMÍA</h1>
    <!-- <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar"  class="form-control" id="busquedaPaciente" >
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header" style="text-align: left; margin-left: 15px;">
                            <p>No.</p>
                        </div>

                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col text_header">
                            <p>GÉNERO</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>TELÉFONO</p>
                        </div>
                        <div class="col text_header">
                            <p>ARCHIVOS</p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div>
                        <div class="row" id="pointer"   *ngFor="let paciente of pacientes; let i = index"  >
                            <div class="col-md-1" style="text-align: left;">
                                <p>
                                    {{  i  +1 }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;" [routerLink]="['/documentos/flebotomia/', paciente._id]" >
                                <p>
                                    {{ paciente.paciente.nombrePaciente }}
                                    {{ paciente.paciente.apellidoPaterno }}
                                    {{ paciente.paciente.apellidoMaterno }}
                                    
                                </p>
                            </div>
                            <div class="col-md-3" style="text-align: center;">
                                <p>

                                    {{ paciente.paciente.genero }}

                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    
                                    {{ paciente.paciente.edad }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    {{ paciente.paciente.telefono }}
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    0 DOCUMENTOS
                                    <!--    TODO: QUEDAN PENDIENTES LOS ARCHIVOS Y VÍ QUE EL POPULATE DE LABORATORIO NO SE HACE -->
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>