<div class="container" style="overflow-x: scroll; height: 19rem;">
    <form action="">
        <div class="row">
            <div class="col-md-3">

                <label for="respiratorio">Respiratorio/Cardiovascular</label>
            </div>

            <div class="col-md-9">
                <input type="text" name="respiraotriosCardiovasculares" [(ngModel)]="hojaEvolucion.respiratorioCardiovascular" class="form-control">

            </div>
        </div>
        <div class="row">


            <div class="col-md-3">

                <label for="digestivo">Digestivo</label>
            </div>

            <div class="col-md-8">
                <input type="text" class="form-control" name="digestivo" [(ngModel)]="hojaEvolucion.digestivo">

            </div>

            <div class="col-md-3">
                <label for="endocrino"> Endocrino </label>
            </div>

            <div class="col-md-8">
                <input type="text" class="form-control" name="endocrino" id="endocrino" [(ngModel)]="hojaEvolucion.endocrino">
            </div>

            <div class="col-md-3">

                <label for="musculo">Musculo - Esquelético</label>
            </div>

            <div class="col-md-8">

                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.musculoEsqueletico" name="musculoEsqueletico">
            </div>

            <div class="col-md-3">
                <label for="gitourinario">Gitourinario</label>

            </div>

            <div class="col-md-8">

                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.genitourinario" name="genitourinario">
            </div>
            <div class="col-md-3">
                <label for="hematopoyetico">Hematopoyético - Linfático</label>

            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.hematopoyeticoLinfatico" name="hematopoyeticoLinfatico">

            </div>
            <div class="col-md-3">
                <label for="piel">Piel y anexo</label>

            </div>

            <div class="col-md-8">
                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.pielAnexos" name="pielAnexos">

            </div>

            <div class="col-md-3">
                <label for="neurologico">Neurológico y Psiquiátrico</label>

            </div>

            <div class="col-md-8">
                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.neurologicoPsiquiatricos" name="neurologicoPsiquiatricos">

            </div>

            <!-- <button class="btn btn-primary" (click)="actulizarAparatosSistemas()" >
                Guardar
            </button> -->
        </div>

    </form>
</div>
