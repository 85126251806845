import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-detalle-materiales',
  templateUrl: './detalle-materiales.component.html',
  styleUrls: ['./detalle-materiales.component.css']
})
export class DetalleMaterialesComponent implements OnInit {

  public idFi = "";
  public materiales = [];
  public materialData = {
    productosFI: "",
    lote: "",
    proveedor: "",
    factura:"" ,
    lote_unitario:"" ,
    fecha_caducidad:"",
    cantidad: 0,
    costoReal:0,
    precioVenta:0,
  }
  public btnValidate = true;
  public dataFi = {
    nombre:"",
    costo:"",
    estado:"",
    descripcion:"",
    nombre_comercial:"",
    proveedor: []
  }
  public totalMateriales:string;
  public pagina = 0;
  public proveedores = [];

  constructor(private _almacenService: AlmacenService,
              private activatedRoute: ActivatedRoute,
              private _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.idFi = this.activatedRoute.snapshot.paramMap.get('id');
    this.obetnerProductoFIAdmin();
    this.obtenerProductosAlmacen();
    this.obtenerProveedores();
  }

  obetnerProductoFIAdmin() {
    this._almacenService.obtenerProductosPorIdTablaAdmin(this.idFi)
      .subscribe( (data:any) => this.setDataFIProductosAdmin(data.data)); 
  }

  setDataFIProductosAdmin(data:any) {
    this.dataFi = data;
    //seteamos la data de la peticion
  }

  obtenerProductosAlmacen() {
    this.materiales = [];
    this._almacenService.obteneProductosPorId(this.idFi )
    .subscribe((data:any) => {
      this.setMaterialesAlmacen(data.data)
    });
  }

  setMaterialesAlmacen(data:any) {
    this.materiales = data;
  }

  obtenerProveedores() {
    this._almacenService.obetenerProveedores().subscribe((data:any) => this.setProveedores(data.data));
  }

  //setamos todos los proveedores
  setProveedores(data){
      this.proveedores = data;
  }

  obtenerForm(event){
    this.materialData = event.value;
    if(event.status == 'VALID'){
      this.btnValidate = false;
    }
  }

  registrarMaterialesEnStock() {
    this._spinner.show();
    this.materialData.productosFI = this.idFi;
    this.btnValidate = true;
    this._almacenService.agregarStockProducto( this.materialData ).subscribe((data:any) => {
      if(data.ok){
        const newPrice = {
          proveedor: this.materialData.proveedor,
          costo: this.materialData.costoReal
        }
        this._almacenService.updatePriceAProductById( this.idFi, newPrice ).subscribe((data:any) =>{
          if(data.ok) {
            this.dataFi = data['data'];
            this.obtenerProductosAlmacen();
            this._spinner.hide();
          }
        })
      }
    });
  }

  printImgQr() {
    //obtenemos el element que se va  a usar para imprimir el PDF
    const imgsQr = document.querySelectorAll('.img-responsive');
    const page = document.body.innerHTML;
    //pasamos todos los componentes del body a un stringify
    document.body.innerHTML = "";
    // dejamos vacio el HTML 
    imgsQr.forEach( img  => { 
      //iteramos todos los elementos  y les agregamos la clase img-to-print
      img.classList.add('img-to-print');
      document.body.append(img)
      //hacemos el append de los elementos con la clase
    });
    // lamamos el print
    window.print();
    imgsQr.forEach( img => {
      //removemos la clase 
      img.classList.remove('img-to-print')
    });
    document.body.innerHTML = page;
    //window.location.reload();
  }

  imprimirEtiquetaIndividual( imgTag: HTMLElement, id: String ){
    // hacemos lo mismo de los pdfs pero con la data mas estatica
    this.changeStatusLabel( id );
    const page = document.body.innerHTML;
    imgTag.classList.add('img-to-print');
    document.body.innerHTML = "";
    document.body.appendChild( imgTag );
    window.print();
    imgTag.classList.remove('img-to-print');
    document.body.innerHTML = page;
    window.location.reload();

  }
  // hace el camcbio del estado de los docs
  changeStatusLabel(id: String) {
    this._almacenService.changeStatusLabeldownloaded( id )
    .subscribe((data:any) => console.log(""))
  }
}
