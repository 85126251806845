import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import Dates from 'src/app/classes/dates/date.class';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Tickets from '../../../classes/tickets/ticket.class'
import { PagoServiciosService } from '../../../services/pagos/pago-servicios.service';
import { eliminarStorage } from 'src/app/functions/pacienteIntegrados';
import { PedidosSedesService } from 'src/app/services/pedidosSedes/pedidos-sedes.service';
import { validateAnticipoLess, validateMontos } from 'src/app/classes/helpers/filterNamePaciente';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Paciente } from "../../../interfaces/pacientes.interface";
import { ventaPaquetes } from "../../../interfaces/paquetes";

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.css'],
})
export class SolicitudComponent implements OnInit {

  public maxDay : any;
  pdfFilePath = "";
  public paciente:Paciente = {} as Paciente
  public infoVenta2:ventaPaquetes={} as ventaPaquetes

  public  method = {
    metodo:"", 
    valid: false
  }

  public infoVenta = {
    idPaciente: "",
    atendio:"",
    fecha: "",
    hora:"",
    paquete:"",
    anticipo:0,
    sede:"",
    ganancia:0
  }

  public paquetesPacientes;

  public usuarioMaq:any;
  
  public paquetesDB= [{
      CitasIncluidas: [],
      costoTotal:0,
      examenesLaboratorio: [],
      icon: "",
      nombrePaquete: "",
      _id: ""
    }];


    
  public paqueteSelected = {
    nombrePaquete: "",
    CitasIncluidas: [],
    costoTotal:0,
    examenesLaboratorio: [],
    icon: "",
    _id: ""
  };  

  public anticipo = 0;
  public parentesco1: string;
  public parentesco2: string;
  public celular:string;
  public folio;
  
  public fecha = moment().format('l');
  public hora = moment().format('hh:mm');
  public id: string;
  membresiavali = 0;
  imagenes = [];

  constructor(private _pagoServicios:PagoServiciosService  ,
              public router: ActivatedRoute,
              public _router2: Router,
              public paquetesService: PaquetesService,
              public _pacientesServices: PacientesService,
              private _pedidosSedes :PedidosSedesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    // el id del usuario
    this.id = this.router.snapshot.paramMap.get('id');
    this.getPacieteByID( this.id );
    this.getUsuarioLocalStorage();
    this.getPaquetes();
    this.setDate();
    // this.spinner.show()
  }

  setDate( ){
    const today = new Dates();
    this.maxDay = today.getDate();
    this.infoVenta.fecha = this.maxDay;
  }

  getPacieteByID( id: string  ){
    this._pacientesServices.getPacienteBtID( id ).subscribe( (data:any) => {
      this.paciente = data.paciente;
    } );
  }

  // peticion para la solicitud
  getPaquetes(){
    this.paquetesService.getPaquetesSolicitud().subscribe(  (data: any ) => this.paquetesDB = data.paquetes);
    this.paquetesService.obtenerFolio().subscribe((data:any)=> { 
      this.folio = data.data
    })
  }

  // OBTENEMOS DEL LOCAL STORAGE EL NOMBRE DEL USUARIO QUIEN ATENDIO
  getUsuarioLocalStorage(){
    const idUser = JSON.parse( localStorage.getItem('usuario') )
    this.usuarioMaq = idUser._id;
  }

  // obtiene los paquetes para el select
  getPaquete( id: string  ){
    this.paquetesService.getPaqueById( id ).subscribe( (data )  => {
      this.paqueteSelected = data['paquete'];
      /* console.log(this.paqueteSelected); */
      // el anticipo varia de acuerdo al paquete
      if(this.paqueteSelected.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL') {
        this.membresiavali = 0;
        this.anticipo = 2500;
        this.pdfFilePath = "/assets/contracts/Contrato Paquete De Maternidad 17500.pdf";
      }else if( this.paqueteSelected.nombrePaquete === 'PAQUETE MÉDICO LABORAL') {
        this.membresiavali = 0;
        this.anticipo = 175;
        this.pdfFilePath = "/assets/contracts/Contrato Paquete Médico Laboral listo.pdf";
      }else if(this.paqueteSelected.nombrePaquete === 'SERVICIOS DE LA MEMBRESÍA'){
        this.membresiavali = 1;
        this.anticipo = 580;
        this.pdfFilePath = "http://tlayacapan.grupomedicohorizonte.com.mx/contratos/ANEXO_PAQUETE_DE_MATERNIDAD.pdf";
      } else if( this.paqueteSelected.nombrePaquete === 'PAQUETE NEONATAL (DE 0 A 12 MESES)' ){
        this.membresiavali = 0;
        this.anticipo = 2100;
        this.pdfFilePath = "/assets/contracts/Contrato Paquete Neonatal.pdf";
      }else if( this.paqueteSelected.nombrePaquete ===  "PAQUETE VIDA PLENA"  ){
        this.membresiavali = 0;
        this.anticipo= 2800;
        this.pdfFilePath = "/assets/contracts/Contrato Paquete Vida Plena.pdf";
      }else if(this.paqueteSelected.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL DE ALTO RIESGO'){
        this.membresiavali = 0;
        this.anticipo = 2500;
        this.pdfFilePath = "/assets/contracts/ANEXO PAQUETE DE MATERNIDAD alto riesgo.pdf";
      }else if(this.paqueteSelected.nombrePaquete === 'PAQUETE PEDIÁTRICO (A PARTIR DE LOS 12 MESES)'){
        this.membresiavali = 0;
        this.anticipo = 1400;
        this.pdfFilePath = "/assets/contracts/Contrato Paquete PEDIATRICO.pdf";
      }else if(this.paqueteSelected.nombrePaquete === 'PAQUETE BABY & MOM'){
        this.membresiavali = 0;
        this.anticipo = 2500;
        this.pdfFilePath = "/assets/contracts/CONTRATO DE PAQUETE BABY & MOM.pdf";
      }else if(this.paqueteSelected.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL HOSPITALARIO'){
        this.membresiavali = 0;
        this.anticipo = 2500;
        this.pdfFilePath = "/assets/contracts/Contrato Paquete De Maternidad hospitalario.pdf";
      }
      /* console.log(this.membresiavali); */
    });
  }

  setInfoVenta(){
    this.infoVenta.atendio = this.usuarioMaq;
    /* this.infoVenta.paciente = this.id; */
    this.infoVenta.idPaciente = this.id;
    this.infoVenta.paquete = this.paqueteSelected._id;
    this.infoVenta.hora = this.hora;
    this.infoVenta.anticipo = this.anticipo;
    this.infoVenta.sede = this.paciente.sede;
    /* console.log( this.infoVenta ); */
  }

  setInfoVenta2(){
    const idUser = JSON.parse( localStorage.getItem('usuario') )
    const dates = new Dates();
    this.infoVenta2.vendedor = this.usuarioMaq;
    this.infoVenta2.paciente = this.id;
    this.infoVenta2.paquete = this.paqueteSelected._id;
    this.infoVenta2.hora = this.hora; 
    this.infoVenta2.fecha = dates.getDate()
    this.usuarioMaq = idUser._id;
    if( this.paqueteSelected.nombrePaquete === 'SERVICIOS DE LA MEMBRESÍA' ){
      this.infoVenta2.ganancia = this.paqueteSelected.costoTotal;
      this.infoVenta2.ganancias = this.paqueteSelected.costoTotal;
    }else {
      this.infoVenta2.ganancia = (this.anticipo *8 )/100;
      this.infoVenta2.ganancias = (this.anticipo *8 )/100;
    }
  }
  
  actualizarEstadoMembresia(){
    const body = {
      membresiaActiva: true
    }
    this.paquetesService.actualizarEstadoMembresia( this.id, body  ).subscribe( data => {/* console.log(data) */}  );
  }

  agregarPaqueteAlUsuario(){
    this.spinner.show();
    const dates = new Dates();
    this.paquetesService.agregarPaquete(Object.assign(this.paqueteSelected,{
      pago:{
        gestacion:0,
        semana:0,
        pago: this.anticipo,
        acumulado:0,
        vendedor:JSON.parse(localStorage.getItem('usuario')).nombre,
        fechaPago:dates.getDate()
      },documentos:this.files}) , this.id ).subscribe(data => {
      if(  data['ok'] ){
        this.ventas(data['data'])
        this.spinner.hide();
        this._router2.navigateByUrl('/paciente/'+ this.id);
        this.actualizarEstadoMembresia();
      }
      //! TODO: Migrar todos los paquetes al carrito
    }); 
  } 
     
  crearTablaVenta(){
    this.paquetesService.setPaquete(this.infoVenta).subscribe( data => { console.log(data)  });
  }

  validarPaqueteSeeccionado(){
    if( this.paqueteSelected._id == ""){
      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'SELECCIONA UN PAQUETE',
      })
      return false;
    }else{
      return true;
    }
  }

  crearPdf(){
    if( this.paqueteSelected.nombrePaquete ===  "PAQUETE DE CONTROL PRENATAL"){
      this._router2.navigateByUrl('/contrato-maternidad');
    }else if( this.paqueteSelected.nombrePaquete === "PAQUETE MÉDICO LABORAL"  ){
      this._router2.navigateByUrl('/contrato-medico-laboral')
    }else if(  this.paqueteSelected.nombrePaquete === 'PAQUETE PEDIATRICO (APARTIR DE LOS 12 MESES)' ){
      this._router2.navigateByUrl('/contrato/pediatrico');
    }else if( this.paqueteSelected.nombrePaquete === 'PAQUETE VIDA PLENA'  ){
      this._router2.navigateByUrl('/contrato/vida/plena');
    }else if( this.paqueteSelected.nombrePaquete === 'PAQUETE NEONATAL (DE 0 12 MESES)' ){ 
      this._router2.navigateByUrl('/contrato/neonatal') 
    }else if( this.paqueteSelected.nombrePaquete === 'PAQUETE DE CONTROL PRENATAL DE ALTO RIESGO'){
      this._router2.navigateByUrl('/anexo/alto/riesgo');
    }
  }

  enviar( f: NgForm  ){
    var metodoPayment;
    if(!this.validarPaqueteSeeccionado() ){
      return
    }else {
      // validamos que el moto de los paos no sea mayor al del deposito
      metodoPayment = validateAnticipoLess( this.infoVenta2, this.anticipo )
      if(metodoPayment.valid ){
        this.setInfoVenta();
        this.setInfoVenta2();
        this.infoVenta2.sede = this.paciente.sede;
        if(metodoPayment.metodo == 'Efectivo' ){
          this.infoVenta2.totalCompra = this.infoVenta2.montoEfectivo
        }else if(metodoPayment.metodo == 'TarjetaCredito') {
          this.infoVenta2.totalCompra = this.infoVenta2.montoTarjteCredito
        }else if( metodoPayment.metodo == 'TarjetaDebito') {
          this.infoVenta2.totalCompra = this.infoVenta2.montoTarjteCredito
        }else if( metodoPayment.metodo == 'transferencia' ){
          this.infoVenta2.totalCompra = this.infoVenta2.montoTranferencia
        }
        this.agregarPaqueteAlUsuario(); 
      }else {
        Swal.fire('Revisa el concepto', '', 'error')
      }
    }
  }
      
  // DROPZONE
  files: File[] = [];
  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i]).then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[i].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    })
  }

  onRemove(event) {
    this.imagenes.splice(this.imagenes.indexOf(event), 1)
    this.files.splice(this.files.indexOf(event), 1);
  }

  ventas(data){
    if(data != 'Repetido') {
      if(this.infoVenta.sede != 'TLYC01'){
        // console.log( this.infoVenta2 )
        this._pedidosSedes.ventas( this.infoVenta2).subscribe((resp:any)=>{});
      }
      const tickets = new Tickets();
      const carrito={
        totalSin:this.anticipo,
        totalCon:this.anticipo,
        items:[{
          nombreEstudio: this.paqueteSelected.nombrePaquete,
          precioSin:this.anticipo,
          precioCon:this.anticipo,
          name:this.paqueteSelected.nombrePaquete
        }]
      }
      this.infoVenta2.totalCompra= this.infoVenta2.montoEfectivo ;
      this.infoVenta2.estudios= carrito.items;
      this._pagoServicios.agregarPedido( this.infoVenta2 ).subscribe( (data) => {
        if(  data['ok'] ){
          tickets.printTicketSinMembresia(this.paciente,carrito, this.infoVenta2, data['data'].folio);
          // se crea la tabla de las ventas 
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE AGREGO EL PAQUETE AL PACIENTE',
          })
          eliminarStorage();
        }
      });
    }else{
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'NO SE PUEDE AGREGAR UN PAQUETE REPETIDO',
      })
      eliminarStorage();
    }
  }      
}
  

