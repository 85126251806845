import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdicionService {


  public arreglo=[];
  public ultra:any;

  constructor() { 
    this.obtenerArreglo();
  }
  
  obtenerLocal(){
  return JSON.parse(localStorage.getItem('editado'))
}

obtenerArreglo(){
  this.ultra = this.obtenerLocal();
  return this.ultra;
}
setArreglo(item){
  /* console.log(item); */
  item.forEach(element => {
    this.arreglo.push(element); 
  });
  this.obtenerArreglo();
}
}