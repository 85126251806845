export function validarRole(role){
    if(role == 'Recepcion'){
        return true
    }else{
        return false
    }
}

export function eventsFechas(valores){
    const { nombre, horario, color } = valores;
    const array = [];
    horario.forEach(element => {
        let valor = {
            start:new Date(element.dia + "T" + element.horaInicio),
            end:new Date(element.dia + "T" + element.horafin),
            display: 'background',
            title: nombre,
            editable: false,
            color: color,
        }
        array.push(valor);
    });
    return array;
}