import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Antecedentes } from '../../../interfaces/historia-clinica';

@Component({
  selector: 'app-antecedentes-horizonte',
  templateUrl: './antecedentes-horizonte.component.html',
  styleUrls: ['./antecedentes-horizonte.component.css']
})
export class AntecedentesHorizonteComponent implements OnInit {

  @Input() _id='';
  @Input() edad;
  @Input() antecedentes:Antecedentes = {} as Antecedentes;
  
  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
    this.llenarFormulario();
  }

  llenarFormulario(){
    this.antecedentes.enfermedadesPielPersonal = 'NO'
    this.antecedentes.enfermedadesPielFecha = 'NO'
    this.antecedentes.enfermedadesPielFamiliares = 'NO'
    this.antecedentes.enfermedadesPielNotas = 'NO'
    this.antecedentes.desnutricionPersonal = 'NO'
    this.antecedentes.desnutricionFecha = 'NO'
    this.antecedentes.desnutricionFamiliares = 'NO'
    this.antecedentes.desnutricionNotas = 'NO'
    this.antecedentes.obesidadPersonal = 'NO'
    this.antecedentes.obesidadFecha = 'NO'
    this.antecedentes.obesidadFamiliares = 'NO'
    this.antecedentes.obesidadNotas = 'NO'
    this.antecedentes.defectosPostularesPersonal = 'NO'
    this.antecedentes.defectosPostularesFecha = 'NO'
    this.antecedentes.defectosPostularesFamiliares = 'NO'
    this.antecedentes.defectosPostularesNotas = 'NO'
    this.antecedentes.fracturasPersonal = 'NO'
    this.antecedentes.fracturasFecha = 'NO'
    this.antecedentes.fracturasFamiliares = 'NO'
    this.antecedentes.fracturasNotas = 'NO'
    this.antecedentes.hospitalizacionesPersonal = 'NO'
    this.antecedentes.hospitalizacionesFecha = 'NO'
    this.antecedentes.hospitalizacionesFamiliares = 'NO'
    this.antecedentes.hospitalizacionesNotas = 'NO'
    this.antecedentes.transfucionesPersonal = 'NO'
    this.antecedentes.transfucionesFecha = 'NO'
    this.antecedentes.transfucionesFamiliares = 'NO'
    this.antecedentes.transfucionesNotas = 'NO'
    this.antecedentes.cardiopatiasPersonal = 'NO'
    this.antecedentes.cardiopatiasFecha = 'NO'
    this.antecedentes.cardiopatiasFamiliares = 'NO'
    this.antecedentes.cardiopatiasNotas = 'NO'
    this.antecedentes.cirugiasPersonal = 'NO'
    this.antecedentes.cirugiasFecha = 'NO'
    this.antecedentes.cirugiasFamiliares = 'NO'
    this.antecedentes.cirugiasNotas = 'NO'
    this.antecedentes.cancerLeucemiaPersonal = 'NO'
    this.antecedentes.cancerLeucemiaFecha = 'NO'
    this.antecedentes.cancerLeucemiaFamiliares = 'NO'
    this.antecedentes.cancerLeucemiaNotas = 'NO'
    this.antecedentes.alergiasPersonal = 'NO'
    this.antecedentes.alergiasFecha = 'NO'
    this.antecedentes.alergiasFamiliares = 'NO'
    this.antecedentes.alergiasNotas = 'NO'
    this.antecedentes.vihPersonal = 'NO'
    this.antecedentes.vihFecha = 'NO'
    this.antecedentes.vihFamiliares = 'NO'
    this.antecedentes.vihNotas = 'NO'
    this.antecedentes.tabaquismoFecha ='NO'
    this.antecedentes.tabaquismoFamiliares ='NO'
    this.antecedentes.tabaquismoNotas ='NO'
    this.antecedentes.tabaquismoPersonal ='NO'
    this.antecedentes.diabetesPersonal = 'NO'
    this.antecedentes.diabetesFecha = 'NO'
    this.antecedentes.diabetesFamiliares = 'NO'
    this.antecedentes.diabetesNotas = 'NO'
    this.antecedentes.tuberculosisPersonal ='NO'
    this.antecedentes.tuberculosisFecha ='NO'
    this.antecedentes.tuberculosisFamiliares ='NO'
    this.antecedentes.tuberculosisNotas ='NO'
    this.antecedentes.alcoholismoPersonal ='NO'
    this.antecedentes.alcoholismoFecha ='NO'
    this.antecedentes.alcoholismoFamiliares ='NO'
    this.antecedentes.alcoholismoNotas ='NO'
    this.antecedentes.deportesPersonal ='NO'
    this.antecedentes.deportesFecha ='NO'
    this.antecedentes.deportesFamiliares ='NO'
    this.antecedentes.deportesNotas ='NO'
    this.antecedentes.idPaciente = 'NO'
    this.antecedentes.otrasEnfPersonales ='NO'
    this.antecedentes.otrasEnfFecha ='NO'
    this.antecedentes.otrasEnfFamiliares ='NO'
    this.antecedentes.otrasEnfNotas ='NO'
    this.antecedentes.enfermedadesDeLosSentidosPersonales  ='NO'
    this.antecedentes.enfermedadesDeLosSentidosFecha  ='NO'
    this.antecedentes.enfermedadesSentidosFamiliares  ='NO'
    this.antecedentes.enfermedadesSentidosNotas  ='NO'
    this.antecedentes.expoLaboralPersonales ='NO'
    this.antecedentes.expoLaboralFecha ='NO'
    this.antecedentes.expoLaboralFamiliares ='NO'
    this.antecedentes.expoLaboralNotas ='NO'
    this.antecedentes.postQuirurgicoPersonales ='NO'
    this.antecedentes.postQuirurgicoFecha ='NO'
    this.antecedentes.postQuirurgicoFamiliares ='NO'
    this.antecedentes.postQuirurgicoNotas ='NO'
  }

  agregarAntecedentes(form: NgForm){
    this.antecedentes= form.value
    this.antecedentes.idPaciente = this._id;
    this._HistoriaClinicaService.agregarHistoriaClinica(this.antecedentes)
    .subscribe(  (data:any) => { 
        if(data['ok']){
           Swal.fire({
            icon: 'success',
            title: '',
            text: 'SE AGREGARON LOS ANTECEDENTES',
          })
        }
    });
  }

  actualizarAntecedentes(form: NgForm){
    let id = this.antecedentes._id;
    this.antecedentes = form.value;
    this.antecedentes.idPaciente = this._id;
    this.antecedentes._id = id;
    this._HistoriaClinicaService.actualizarAntecedentesPaciente(this.antecedentes._id, this.antecedentes)
    .subscribe((data:any) => {
      if(data['ok']){ 
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZARON LOS ANTECEDENTES',
        })
      }
    })
  }

}
