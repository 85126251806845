import { Component, OnInit } from '@angular/core';
import { Personal } from 'src/app/interfaces/usuario.interface';
import {  ModulesService } from '../../services/modules/modules.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar-horizonte',
  templateUrl: './nav-bar-horizonte.component.html',
  styleUrls: ['./nav-bar-horizonte.component.css']
})
export class NavBarHorizonteComponent implements OnInit {
  public pagina = 0;
  public totalAmbulancia: string;
  public usuario: Personal;
  public modules:any[]=[];

  constructor(public router: Router,
              public modulesService: ModulesService){}

  ngOnInit(): void {
    this.getRoleLocalStorage();
  }

  getRoleLocalStorage(){
    // SETEA LOS DATOS DEL USURAIO EN EL LOCALSTORAGE
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    if( this.usuario == null || this.usuario == undefined){
      return;
    }else{
      this.getModuleByRole();
    }
  }

  getModuleByRole(){
    this.modulesService.getModules( this.usuario._id ).subscribe( (data:any)  => {
      //  OBTENEMOS TODOS LOS MODULOS DEL USUARIO
      this.modules = data['data'];
      this.totalAmbulancia = data.data.usuario;
    })
  }
}
