import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {

  constructor(private _http:HttpClient) { }

  // Obtener los usuarios
  getUsers(){
    const url = `${URL}/personal`;
    return this._http.get(url);
  }

  //Obtener los modulos
  getModulos(){
    const url = `${URL}/modules`;
    return this._http.get(url);
  }

  // Asignar un modulo a un usuario
  addModulo(body:any){
    const url = `${URL}/nuevo/usuario/modulo`;
    return this._http.post(url, body);
  }

}
