<div class="container-md">
    <!-- <h1 class="title-principal-bs">ACTUALIZAR PROVEEDOR</h1> -->
    <app-titulos [title]="'ACTUALIZAR PROVEEDOR'"></app-titulos>
    <div class="card">
        <form [formGroup]="formProveedor">
        <div class="row">
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">NOMBRE DE LA EMPRESA: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="nombreEmpresa" name="nombreEmpresa" formControlName="nombreEmpresa" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">RAZÓN SOCIAL: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="razonSocial" name='razonSocial' formControlName="razonSocial" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">CONTACTO: </label>
                    <div class="col-sm-7">
                        <input type="number" class="form-control shadow-input-bs  " id="contacto" min="0" name='contacto' formControlName="contacto" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">RFC: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="rfcEmpresa" name='rfcEmpresa' formControlName="rfcEmpresa" >
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">ID: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="idProveedor" formControlName="idProveedor">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">DOMICILIO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="domicilio" name='domicilio' formControlName="domicilio" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">CORREO: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="correo" pattern='[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$'  name='correo' formControlName="correo" >
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-5 col-form-label">DÍAS DE DISTRIBUCIÓN: </label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control shadow-input-bs" id="diasDistribucion"  name='diasDistribucion' formControlName="diasDistribucion" >
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">DESCRIPCIÓN</label>
            <textarea class="form-control" id="descripcion" rows="3"  name='descripcion' formControlName="descripcion" ></textarea>
        </div>
        <div class="d-grid gap-2 col-2 mx-auto mt-5">
            <button class="btn btn-primary" (click)="actualizarProveedor()">REGISTRAR</button>
        </div>
    </form>
    </div>
</div>
    