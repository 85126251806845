import { Component, OnInit } from '@angular/core';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { fileToBase64 } from 'src/app/functions/generalFunctions';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-documentos-receptor-firmados',
  templateUrl: './documentos-receptor-firmados.component.html',
  styleUrls: ['./documentos-receptor-firmados.component.css']
})
export class DocumentosReceptorFirmadosComponent implements OnInit {

  public pacienteInfo = {
    nombrePaciente:"",
    apellidoPaterno: "",
    genero:"",
    edad:0,
    telefono:"",
    curp:"",
    numeroExpediente:""
  };
  public imagenes = [];
  public pedidioCensur = "";
  public idCesnur = "";
  files: File[] = [];

  constructor(
    private _banco: BancoService,
    private _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getIdCesurLocalStorage();
    this.obtenerFiPaciente();
  }

  getIdCesurLocalStorage(){
    this.spinner.show();
    this.pedidioCensur = localStorage.getItem('pedido');
    this.idCesnur = localStorage.getItem('idCensur');
    
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       fileToBase64 (this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[i].name, base: base64String} );
        });         
      }
    }
  }


  obtenerFiPaciente(){
    this._banco.verDetallePedidoCensursocio(this.pedidioCensur)
    .subscribe( (data: any) => {
      if(data.ok){
        this.pacienteInfo = data.data;
        this.spinner.hide();
      }
    }); 
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.imagenes.splice(this.imagenes.indexOf(event),1)
  }

  agregarDocumentosReceptor(){
    this.spinner.show();
    this._banco.agregardocuemtosReceptorFirmados( { idCensur: this.idCesnur, documentos: this.imagenes } )
    .subscribe( (data:any)  => {
      if(data.ok) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'DOCUMENTOS AGREGADOS',
        });
        this.spinner.hide();
        this._router.navigateByUrl('/')
      }
    })
  }
}
