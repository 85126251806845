<div class="container-fluid">
    <!-- <p>asignar-modulo works!</p> -->
    <!-- <h2 class="text-muted mt-3">Asignación de Módulos</h2> -->
    <app-titulos [title]="'ASIGNACIÓN DE MÓDULOS'"></app-titulos>

    <div class="card">
        <div class="col-lg-12">
            <form [formGroup]="modulosForm" (ngSubmit)="asignarModuloUser()">
                <div class="row mt-5">
                    <div class="form-group col-6">
                        <div class="form-group">
                            <label for="">Usuarios</label>
                            <select class="form-control" id="" formControlName="id_user">
                                <option value="" selected>Selecciona usuario</option>
                                <option value={{user._id}} *ngFor="let user of usuarios">
                                    {{ user.nombre }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <div class="form-group">
                            <label for="">Módulos</label>
                            <select class="form-control" id="" formControlName="id_modulo">
                                <option value="" selected>Selecciona el módulo</option>
                                <option value={{modulo._id}} *ngFor="let modulo of modulos">
                                    {{ modulo?.nameModule }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-4">
                        <button class="boton">CONFIRMAR</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>