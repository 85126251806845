<div class="content">
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p>FECHA DE ÚLTIMA MENSTRUACIÓN</p>
            </div>
            <div class="col">
                <input type="date" id="relacion" name="ultima_relacion" formControlName="ultima_relacion" (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p> GESTAS </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="gestas" formControlName="gestas" (focus)="activarGestas('SI')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="gestas" formControlName="gestas" (focus)="activarGestas('NO')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="gestas">
            <div class="col">
                <p>CUANTOS</p>
            </div>
            <div class="col">
                <input type="text" id="relacion" name="gestas" formControlName="gestas" ngModel (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>PARTOS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="partos" formControlName="partos" (focus)="activarPartos('SI')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="partos" formControlName="partos" (focus)="activarPartos('NO')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="partos">
            <div class="col">
                <p>CUANTOS</p>
            </div>
            <div class="col">
                <input type="text" id="relacion" name="partos" formControlName="partos" ngModel (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>CESÁREAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="cesareas" formControlName="cesareas" (focus)="activarCesareas('SI')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="cesareas" formControlName="cesareas" (focus)="activarCesareas('NO')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="cesareas">
            <div class="col">
                <p>CUANTOS</p>
            </div>
            <div class="col">
                <input type="text" id="relacion" name="cesareas" formControlName="cesareas" ngModel (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ABORTOS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="abortos" formControlName="abortos" (focus)="activarAbortos('SI')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="abortos" formControlName="abortos" (focus)="activarAbortos('NO')" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="abortos">
            <div class="col">
                <p>CUANTOS</p>
            </div>
            <div class="col">
                <input type="text" id="relacion" name="abortos" formControlName="abortos" ngModel (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>FECHA DEL ÚLTIMO PARTO</p>
            </div>
            <div class="col">
                <input type="date" id="parto" name="ultimo_parto" formControlName="ultimo_parto" (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>FECHA DEL ÚLTIMO ABORTO</p>
            </div>
            <div class="col">
                <input type="date" id="aborto" name="ultimo_aborto" formControlName="ultimo_aborto" (blur)="formularioAntecedentesGineco(form)">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ISOINMUNIZACIONES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="isoinmunizacioes" formControlName="isoinmunizacioes" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="isoinmunizacioes" formControlName="isoinmunizacioes" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>GLOBULINA ANTI-D</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="globulina" formControlName="globulina" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="globulina" formControlName="globulina" (blur)="formularioAntecedentesGineco(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
