import { Component, Input, OnInit } from '@angular/core';
import { antecedentesPP } from '../../../interfaces/historia-clinica';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-antecedentes-pp',
  templateUrl: './antecedentes-pp.component.html',
  styleUrls: ['./antecedentes-pp.component.css']
})
export class AntecedentesPpComponent implements OnInit {

  @Input() _id='';
  @Input() antecedentesPersonalesPatologicos:antecedentesPP = {} as antecedentesPP;

  constructor(private _HistoriaClinicaService: HistoriaClinicaService) { }

  ngOnInit(): void {
  }

  agregarPersonalesPatologicos(form: NgForm){
    this.antecedentesPersonalesPatologicos = form.value;
    this.antecedentesPersonalesPatologicos.idPaciente = this._id;
    this._HistoriaClinicaService.agregarPersonalesPatologicos(this.antecedentesPersonalesPatologicos)
    .subscribe( ( data:any ) => {
      if(data['ok']){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE GUARDO LA HISTORIA CLINICA',
        })
      }
    });
  }

  actualizarPersonalesPatologicos(form: NgForm){
    let id = this.antecedentesPersonalesPatologicos._id;
    this.antecedentesPersonalesPatologicos = form.value;
    this.antecedentesPersonalesPatologicos._id = id;
    this.antecedentesPersonalesPatologicos.idPaciente = this._id;
    this._HistoriaClinicaService.actualizarPersonalesPatologicos(this.antecedentesPersonalesPatologicos._id, this.antecedentesPersonalesPatologicos)
    .subscribe((data:any) => {
      if(data['ok']) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'SE ACTUALIZO LA HISTORIA CLINICA',
        })
      }
    })
  }

}
