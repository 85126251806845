import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-laboratorio-disp',
  templateUrl: './laboratorio-disp.component.html',
  styleUrls: ['./laboratorio-disp.component.css']
})
export class LaboratorioDispComponent implements OnInit {

  cont: FormGroup;
  public idCensur;
  public labs={
    obtenidos: []
  };
  public valoresEstudios = {
    valoresDeReferencia:[{
    grupo: "",
    obtenido:"",
    elemento: "",
    valorDeReferencia: [
      {
        valorReferenciaD: ""
      }
    ],
    "metodo": ""
  }]};

  @Output() editLab = new EventEmitter();

  constructor(private activatedRoute:ActivatedRoute,
              private _banco: BancoService,
              private _labService: LaboratorioService,
              private spinner: NgxSpinnerService){}

  ngOnInit(): void {
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    let id = {
      idbancodesangre:this.idCensur
    }
    this.cont = new FormGroup({
      leucocitos0: new FormControl(),
      neutrofilos1: new FormControl(),
      linfocitos2: new FormControl(),
      monocitos3: new FormControl(),
      neutrofilos4: new FormControl(),
      linfocitos5: new FormControl(),
      monocitos6: new FormControl(),
      eritrocitos7: new FormControl(),
      hematocrito8: new FormControl(),
      hemoglobina9: new FormControl(),
      'ancho de distribucion eritrocitaria9': new FormControl(),
      Plaquetas10: new FormControl(),
      'volumen plaquetar medio11': new FormControl(),
      segmentados12: new FormControl(),
      linfocitos13: new FormControl(),
      monocitos14: new FormControl(),
      eosinoifos15: new FormControl(),
      basofilos16: new FormControl(),
      'en banda17': new FormControl(),
      mielocitos18: new FormControl(),
      metamiecocitos19: new FormControl(),
   });
   /* console.log(this.cont); */
   
    this.getDataEstudios(id);
    
  }

  getDataEstudios(id) {
    this.spinner.show();
    this._labService
    .getEstudioById('61291e62f1f91457f8a98ce7')
    .subscribe((data:any) => this.setDataEstudios(data.data,id) ); 
  }

  setDataEstudios(data:any,id) {
    this.valoresEstudios = data;
    this.spinner.hide();
    this.getLaboratorios(id);
  }

  getLaboratorios(id){
    this._banco.postgetlaboratorios(id).subscribe((resp:any)=>{
      this.setLaboratorios(resp['data']);
    })
  }

  setLaboratorios(resp){
    console.log(resp);
    
    let obt={};
    this.labs = resp.find( element => element.pedido[0] === 'Biometria Hematica Completa');
    obt = this.labs.obtenidos[0]
    this.valoresEstudios.valoresDeReferencia.forEach((element,index) => {
      for (const key in obt) {
        if(key == element.elemento+index){
          element['obtenido'] = obt[key]
        }
      }
    });
    /* this.editLab.emit(this.valoresEstudios.valoresDeReferencia); */
  }

  ngSubmitForm(f : NgForm){
    /* console.log(f.form.value); */
    
  }
}
