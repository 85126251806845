import { Component, OnInit } from '@angular/core';
import { IntegradosService } from '../../../../services/servicios/integrados.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { filterServicios } from 'src/app/classes/buscarServicos/buscarServicos';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-historico-estudios',
  templateUrl: './historico-estudios.component.html',
  styleUrls: ['./historico-estudios.component.css']
})
export class HistoricoEstudiosComponent implements OnInit {

  public tablaEstudios ;
  public totalAmbulancia: string;
  public pagina = 0;
  constructor( private consumoServicio : IntegradosService,
               private _router:Router,
               private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  
    this.obtnerDatos();
  }


  obtnerDatos(){
    this.spinner.show();
    this.consumoServicio.getObtener('laboratorio')
    .subscribe( (data:any) => {
      if(data.ok){
        this.tablaEstudios =data ['data'] 
        this.totalAmbulancia = this.tablaEstudios.results;
        this.spinner.hide();
      }
    })
  }

  busquedaGeneral(event:any){
    if (event.target.value == '') {
      this.obtnerDatos();
    } else if (event.target.value.length >= 3){
      this.tablaEstudios = filterServicios(this.tablaEstudios, event.target.value)
    }
  }

agregar(id){
  Swal.fire({title: "¿Estas seguro que vas a agregar los elementos al estudio de laboratorio",
  text: "Una vez que se haya editado, no se podrá recuperar",
  icon: "warning",
  showCancelButton: true, 
    confirmButtonText: 'ACEPTAR',
    cancelButtonText: 'CANCELAR'
  })
  .then((result) => {
    if (result.value) {
      this._router.navigateByUrl('/nuevo/estudio/laboratorio/' + id)
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire({
            icon: 'error',
            title: '',
            text: 'NO SE EDITO NADA',
          })
    }   
  })
}

editar(id){
  Swal.fire({title: "¿Estas seguro que vas a editar el estudio de laboratorio",
  text: "Una vez que se haya editado, no se podrá recuperar",
  icon: "warning",
  showCancelButton: true, 
    confirmButtonText: 'ACEPTAR',
    cancelButtonText: 'CANCELAR'
  })
  .then((result) => {
    if (result.value) {
      this._router.navigateByUrl('editar/laboratorios/' + id)
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire({
            icon: 'error',
            title: '',
            text: 'NO SE EDITO NADA',
          })
    }   
  })
}

delete(id) {
/*   Swal.fire({title: "¿Estas seguro de deshabilitar el servicio?",
text: "Una vez que se haya eliminado, no se podrá recuperar",
icon: "warning",
showCancelButton: true, 
confirmButtonText: 'ACEPTAR',
cancelButtonText: 'CANCELAR'
})
.then((result) => {
if (result.value) {
this._service.deleteService(id).subscribe((resp:any) => {
if(resp.ok){
  Swal.fire({
    icon: 'success',
    title: '',
    text: 'EL SERVICIO SE ELIMINO CORRECTAMENTE',
  })
  this.verDatos();
  this._router.navigateByUrl('serviciosInt/'+this.servicios);
}
})

} else if (result.dismiss === Swal.DismissReason.cancel) {
Swal.fire({
    icon: 'error',
    title: '',
    text: 'EL SERVICIO NO SE ELIMINO',
  })
} 
}) */
}

}
