import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditableService {
 
  public arreglo=[];
  public eleme:any;
 
  constructor() { 
    // this.obtenerArreglo();
  }

  obtenerLocal(){
    return JSON.parse(localStorage.getItem('elementoss'))
  }

  obtenerArreglo(){

    this.eleme = this.obtenerLocal(); 
    return this.eleme.ELEMENTOS;

  }

  setArreglo(item  =[]){

    item.forEach(element => {

      this.arreglo.push(element); 
    });
    this.obtenerArreglo();
  }
}
