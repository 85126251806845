<div class="container-fluid">
    <section class="center">
        <div class="row">
            <div class="col-md-8">
                <app-titulos [title]="'SOLICITUD DE PAQUETE'" [rol]="'pacientes'"></app-titulos>
                <!-- <h1 class="centerxd"> SOLICITUD DE PAQUETE </h1> -->
            </div>
            <!--<div class="col-auto">
                <h5 class="fecha"> {{fecha}} </h5>
            </div> -->
            <div class="col-md-2 offset-sm-2">
                <h5 class="fecha"> Folio : {{folio}} </h5>
            </div>
        </div>
    </section>
    <ficha-info-user [pacienteInfo]="paciente" [title]="false"></ficha-info-user>
    <section class="container-fluid">
        <div class="card" name="card" id="card">
            <div class="container2">
                <br>
                <form class="formulario" class="container" #f='ngForm' (ngSubmit)="enviar( f )">
                    <div class="row h-20">
                        <div class="col-md-6">
                            <div class="form-group">
                                <!--Cambios-->
                                <label for="inciioMembresia">  Listado de paquetes </label>
                                <select class="form-control" #paq (change)="getPaquete( paq.value )">
                                <option selected>SELECCIONA UN PAQUETE</option>
                                <option *ngFor="let paquete of paquetesDB"  [value]="paquete._id"  >
                                    {{ paquete.nombrePaquete }}
                                </option>         
                            </select>

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group" >
                                <label for="inciioMembresia">  Fecha de contratación </label>
                                <!-- max="{{infoVenta.fecha}}" -->
                                <input *ngIf="membresiavali !== 0" class="form-control" type="date" name="fechaInicioMembresia" [value]="maxDay" [(ngModel)]="infoVenta.fecha" [max]="maxDay">
                                <input *ngIf="membresiavali === 0" disabled class="form-control" type="date" name="fechaInicioMembresia" [value]="maxDay" [(ngModel)]="infoVenta.fecha" [max]="maxDay">
                               
                                <!-- <label for="">  {{ maxDay  | date :'MMM d, y'	}}</label> -->

                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="pdfFilePath != ''">
                            <div class="form-group" style="margin-top: 30px;">
                                <label></label>
                                <a [href]="pdfFilePath" class="btn btn-success" target="_blank" [download]>Imprimir contrato</a>
                            </div>
                        </div>
                    </div>

                    <h5 class="center" style="margin-top: 30px;"> DATOS ADICIONALES </h5>

                    <ngx-dropzone name="fileUploadTosend" (change)="onSelect($event)">
                        <ngx-dropzone-label>
                            <!-- <span class="material-icons">
                                add
                            </span> -->
                            INSERTA LOS DOCUMENTOS AQUÍ
                        </ngx-dropzone-label>
                        <ngx-dropzone-preview [file]="f" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                            <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>  

                    <br>

                   
                    <br>
                    <div class="container">  
                        <div class="row">
                            <div class="col-6">
                                <div class="row justify-content-center">
                                    <!-- <app-metodos-pagos></app-metodos-pagos> -->
                                </div>
                            </div>
                            <div class="col-6">
                                <h5 class="center servicio"> COSTO DEL SERVICIO </h5>
                                <div class="row justify-content-center">    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5 class="text-end" style="font-size: 1.2rem; font-weight: 800; color: rgb(0, 60, 128);">Total: &#36; {{paqueteSelected.costoTotal}} MX</h5>
                                            <h5 class="text-end " style="font-size: 1.2rem; font-weight: 800; color: green;"> Anticipo: &#36; {{anticipo}} MX</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>


                    <div class="row mt-3">
                        <div class="col">
                            <input type="radio" id="efectivo" name="efectivo" value="true" [(ngModel)]="infoVenta2.efectivo">
                            <label for="efectivo">Pago en efectivo</label>

                            <div *ngIf="infoVenta2.efectivo">
                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoEfectivo" id="montoEfectivo" [(ngModel)]="infoVenta2.montoEfectivo">
                            </div>
                        </div>

                        <div class="col">
                            <input type="radio" id="transferencia" name="transferencia" value="true" [(ngModel)]="infoVenta2.transferencia">
                            <label for="transferencia">Transferencia bancaria</label>

                            <div *ngIf="infoVenta2.transferencia">

                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTranferencia" id="montoTranferencia" [(ngModel)]="infoVenta2.montoTranferencia">
                            </div>
                        </div>

                        <div class="col">
                            <input type="radio" id="tarjetaDeCredito" name="tarjetaDeCredito" value="true" [(ngModel)]="infoVenta2.tarjetCredito">

                            <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                            <br>
                            <div *ngIf="infoVenta2.tarjetCredito">

                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjteCredito" id="montoTarjteCredito" [(ngModel)]="infoVenta2.montoTarjteCredito">
                            </div>
                        </div>

                        <div class="col">
                            <input type="radio" id="tarjetaDeDebito" name="tarjetaDeDebito" value="true" [(ngModel)]="infoVenta2.tarjetaDebito">

                            <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                            <br>
                            <div *ngIf="infoVenta2.tarjetaDebito">

                                <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjetaDebito" id="montoTarjetaDebito" [(ngModel)]="infoVenta2.montoTarjetaDebito">
                            </div>
                        </div>

                    </div>

<!--                     <div class="row">
                        <pdf-viewer [src]="pdfFilePath" [render-text]="true"
                        [original-size]="true" style="display: block; height: 400px"></pdf-viewer>
                    </div> -->
                    <br>
                    <!--fin cambios-->

                    <div class="center">
                        <div class="">
                            <input type="submit" class=" btn  principalButton" value="AÑADIR PAQUETE">
                        </div>

                    </div>
                </form>
            </div>

        </div>
    </section>
    <br>
</div>

<!-- Spinner -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>

<!-- <div class="card cardPer card-paddin-title container">
    <form class="formulario" class="container" #f='ngForm' (ngSubmit)="enviar( f )">



        <h5 class="center" style="margin-top: 30px;"> DATOS ADICIONALES </h5>

        <div class="row">

            <div class="col-md-9">
                <div class="form-group">
                    <label for="inciioMembresia">  Listado de paquetes </label>
                    <select class="form-control" #paq (change)="getPaquete( paq.value )">
                    <option selected>SELECCIONA UN PAQUETE</option>
                    <option *ngFor="let paquete of paquetesDB"  [value]="paquete._id"  >
                        {{ paquete.nombrePaquete }}
                    </option>         
                </select>

                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group" *ngIf="membresiavali !== 0">
                    <label for="inciioMembresia">  Inicio de la membresía </label>
                    <input class="form-control" type="date" name="fechaInicioMembresia" [value]="maxDay" [(ngModel)]="infoVenta.fecha" [max]="maxDay">

                </div>
            </div>
        </div>

        <br>

        <h5 class="center servicio"> COSTO DEL SERVICIO </h5>
        <br>
        <div class="row">
            <div class="col-md-3">
                <p> COSTO DEL PAQUETE </p>
            </div>
            <div class="col-md-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                    <input type="number" class="form-control  input-paquete" min="0" disabled name="costoToal" [value]="paqueteSelected.costoTotal">
                    <div class="input-group-append ">
                        <span class="input-group-text"> M.N. </span>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-3">
                <p> ANTICIPO </p>
            </div>
            <div class="col-md-3">

                <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                    <input type="number" class="form-control input-paquete" min="0" disabled name="anticipo" ngModel [value]="anticipo">
                    <div class="input-group-append ">
                        <span class="input-group-text"> M.N. </span>
                    </div>
                </div>

            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <input type="radio" id="efectivo" name="efectivo" value="true" [(ngModel)]="infoVenta2.efectivo">
                <label for="efectivo">Pago en efectivo</label>

                <div *ngIf="infoVenta2.efectivo">
                    <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoEfectivo" id="montoEfectivo" [(ngModel)]="infoVenta2.montoEfectivo">
                </div>
            </div>

            <div class="col">
                <input type="radio" id="transferencia" name="transferencia" value="true" [(ngModel)]="infoVenta2.transferencia">
                <label for="transferencia">Transferencia bancaria</label>

                <div *ngIf="infoVenta2.transferencia">

                    <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTranferencia" id="montoTranferencia" [(ngModel)]="infoVenta2.montoTranferencia">
                </div>
            </div>

            <div class="col">
                <input type="radio" id="tarjetaDeCredito" name="tarjetaDeCredito" value="true" [(ngModel)]="infoVenta2.tarjetCredito">

                <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                <br>
                <div *ngIf="infoVenta2.tarjetCredito">

                    <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjteCredito" id="montoTarjteCredito" [(ngModel)]="infoVenta2.montoTarjteCredito">
                </div>
            </div>

            <div class="col">
                <input type="radio" id="tarjetaDeDebito" name="tarjetaDeDebito" value="true" [(ngModel)]="infoVenta2.tarjetaDebito">

                <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                <br>
                <div *ngIf="infoVenta2.tarjetaDebito">

                    <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjetaDebito" id="montoTarjetaDebito" [(ngModel)]="infoVenta2.montoTarjetaDebito">
                </div>
            </div>

        </div>

        <div class="row">
            <pdf-viewer [src]="pdfFilePath" [original-size]="false" style="display: block;"></pdf-viewer>
        </div>
        <br>
        <div class="row">
            <div class="offset-md-5 col-md-2">
                <div class="form-group">
                    <input type="number" min="0" placeholder="FOLIO" class="form-control" name="folio" [(ngModel)]="folio">
                </div>
            </div>
        </div>
        <br>

        <div class="center">
            <div class="">
                <input type="submit" class="btn btn-block btn-success" value="AÑADIR PAQUETE">
            </div>

        </div>
    </form> 
</div> -->