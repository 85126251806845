import { Component, OnInit, Input } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta/consulta.service';
import { RecetasService } from 'src/app/services/recetas/recetas.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tablas-consultas',
  templateUrl: './tablas-consultas.component.html',
  styleUrls: ['./tablas-consultas.component.css']
})

export class TablasConsultasComponent implements OnInit {


  @Input() estudiosPendientes: string;

  public consultas:any[]=[];
  public recetasConEstudios:any[] =[];
  public pagina = 0;
  public total: string;
  public sede = "";
  
  constructor(private spinner: NgxSpinnerService,
    public _consultaService: ConsultaService,
    private _recetaService: RecetasService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.obtenerSede();
    if( this.estudiosPendientes == 'consultas'){
      this.obtenerCosnultas();

    }else if( this.estudiosPendientes == 'estudios'  ){
      this.obtenerRecetas();

    }

  }


  obtenerSede() {
    this.sede = JSON.parse(localStorage.getItem('cede'));  

  }

  obtenerCosnultas(){
    this._consultaService.verConsultasRecepcion(this.sede)
    .subscribe( (data:any) =>   {
      if( data.ok) {
        this.setConsultas(data['data'])
        this.total=data['data'].results;
        this.spinner.hide()
      }
    });
  }

  setConsultas(consultas:any){
    consultas.forEach(element => {
      if(element.paciente != null){
        this.consultas.push(element)
      }
    });
    // this.consultas.reverse()
  }


  obtenerRecetas(){
    this._recetaService.verRecetasEmitidas(  )
    .subscribe(  (data) => {
      this.setEstudios(data['data'])
      this.spinner.hide()
    });
  }

  setEstudios(estudios:any){
    estudios.forEach(element => {
      if(element.idPaciente != null){
        this.recetasConEstudios.push(element)
      }
    });
    // this.recetasConEstudios.reverse()
  }

}
