import {  LoginService  } from '../services/login.service';
import { Component, OnInit } from '@angular/core';
import {  Personal } from '../interfaces/usuario.interface'
import { Router  } from '@angular/router';
import {  NgForm  } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CEDE } from '../classes/cedes/cedes.class';
import { WsLoginService } from '../services/sockets/chat/ws-login.service';
import { OnesignalService } from '../onesignal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  private cede = '';
public folios = 500;
  constructor(
    private wsloginService: WsLoginService,
    public _loginService: LoginService,
    private router: Router,
    private onesignal: OnesignalService
  ) { 
    //  localStorage.setItem('folio',JSON.stringify(this.folios));
  }

  ngOnInit(): void {
    if( this.cede === null || this.cede == "" ){ 
      this.setCede();
    }
    this.verSede();
    
  }
  
  
  // validaciones de las sedes en localstorage
  setCede(){
    
    this.wsloginService.adios();
    localStorage.setItem('cede', JSON.stringify( CEDE ) );

  }

  verSede(){
    this.wsloginService.adios();
    this.cede = JSON.parse( localStorage.getItem('cede') );
    // console.log(  this.cede);
  }

  // esta funcio se llama para hacer el login con los datos del usuario

  ingresar( forma: NgForm ) {

    // console.log( forma )
    this._loginService.logout();
    this.wsloginService.adios();
    if( forma.invalid ){
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'LLENA TODOS LOS CAMPOS',
        })
      return false;
    }

   const nombrePersonal = forma.value.nombre.trim();
   const passwordPersonal = forma.value.password.trim();

    if( !nombrePersonal ){
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'EL NOMBRE NP ES VALIDO',
        })
      forma.reset();
      return
    }
    else if(!passwordPersonal){
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'LA CONTRASEÑA NO ES VALIDO',
        })

      forma.reset();
      return
    }

    let personal = new Personal( nombrePersonal, passwordPersonal );
    
    this._loginService.login( personal, this.cede )
    .subscribe( (correcto: any)=> {

      var user = ''
      if(!correcto){
        alert('entra');
      }else{
        localStorage.setItem('token', correcto.token);
        localStorage.setItem('usuario', JSON.stringify(correcto.personal) )
        user = correcto.personal.nombre
        this.onesignal.sendTags(user)
        this.router.navigate(['/dashboard'])
      }
    },
      error => {
          if( error['ok']  == false){
            forma.reset();
             Swal.fire({
              icon: 'error',
              title: '',
              text: 'EL NOMBRE O LA CONTRASEÑA SON INCORRECTOS',
            })
          }
      }
     )
    
  

  }


}
