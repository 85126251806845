<div class="container-fluid">
    <!-- <h1 class="centerxd">LISTADO DE {{ servicios | uppercase }}</h1> -->
    <app-titulos [title]="titulo" [rol]="'servicio'"></app-titulos>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio">
                    <!-- <button class="btn btn-primary" type="submit">
                            Buscar
                    </button> -->
                </div>
            </div>


            <div class="col-2">
                <button type="button" class="btn btn-secondary principalButton" id="boton_contra2" [routerLink]="['/formulario', servicios]" routerLinkActive="router-link-active" *ngIf="roleuser == 'Admin' ">NUEVO</button>
            </div>
        </div>

        <app-tabla-service *ngIf="showTableAmbulanacia" [serviceSi]="serviceSi" [membresia]="membresia" [RoleUser]="roleuser" [totalAmbulancia]="totalAmbulancia" [servicios]="servicios" [pagina]="pagina"></app-tabla-service>
        <!-- TERMINA LA TABLA DE LOS DEMAS SERVICIOS  -->
        <!-- <app-ambulancia *ngIf="showAmbulanacia" [ambulanciaSI]=ambulanciaSI [showAmbulanacia2]=false></app-ambulancia> -->
    </div>
</div>