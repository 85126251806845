import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class PatologiaService {

  
  public  url = URL;

  constructor(
    private _http:HttpClient
  ) { }


  agregarPedidoPatologia(body:any) {
    
    const url =  `${this.url}/agregar/pedido/patologia`;
    return this._http.post(url, body);
  }

  verPedidosPatologia(status: any) {

    const url =  `${this.url}/ver/pedidos/${status}`;
    return this._http.get(url); 
  }

  verPedidoPorId( id: string ) {
    
    const url =  `${this.url}/ver/pedido/${id}`;
    return this._http.get(url); 
  }

  crerPatologiaRegresos( body:any ) {
    const url =  `${this.url}/agregar/regresos/patologia`;
    return this._http.post(url, body);
  }

  obtenidosPatologiaID( id: any ){
    const url =  `${this.url}/ver/obtenidos/patologia/${id}`;
    return this._http.get(url); 
  }

}
