import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Cie10Service } from 'src/app/services/consultas/cie10.service';
import { HospitalizacionService } from 'src/app/services/hospitalizacion/hospitalizacion.service';
import { Hospitalizacion, NotaIngreso } from '../bitacora-hospitalizacion/hospitalizacion.interface';
import { CIE10 } from './notaIngreso.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-nota-ingreso',
  templateUrl: './nota-ingreso.component.html',
  styleUrls: ['./nota-ingreso.component.css']
})
export class NotaIngresoComponent implements OnInit {

  public hospitalizados: Hospitalizacion;
  public txtCie10 = "";
  public cie10: CIE10[];
  public id ="";

  constructor(
    private _HospitalizacionService: HospitalizacionService,
    private _Cie10Service: Cie10Service,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getIngresoPorId();
  }

  getIngresoPorId(){
    this._HospitalizacionService.getBitacoraPorId( this.id )
    .subscribe( (data:any) =>{ 
      this.hospitalizados = data.data[0]
      this.spinner.hide();
    });
  }

  getcie10PorNombre(){
    
    if( this.txtCie10.length >= 3 ) {
      this._Cie10Service.getCiePorNombre( this.txtCie10 )
      .subscribe( (data: any ) => {
        // console.log( data);
        this.cie10 = data.data;
      })
    }
  }

  addNewItem( nombre: String ){

    this.hospitalizados.diagnosticoInicial.push(nombre);
    this.txtCie10 = "";
    this.cie10 = [];
  }

  deleteItem(item: number){
    this.hospitalizados.diagnosticoInicial.splice(item,1);
  }

  updateNotaIngreso(){
    
    if( this.hospitalizados.diagnosticoInicial.length  >= 1){
      this.spinner.show();
      delete this.hospitalizados._id;
      this._HospitalizacionService.updateNotaIngreso( this.id, this.hospitalizados)
      .subscribe( (data:any ) =>{
        if(data.ok){
          this._router.navigate([ `/hoja/indicaciones`, this.id]);
        }
      });
    
    }else {
      Swal.fire(
        'No se pudo agregar',
        'Ingresa un Diagnostico',
        'error'
      );
    }
  }
}
