<div class="content">
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p> CARDIOPATÍAS </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="cardiopatias" formControlName="cardiopatias" (focus)="activarCardiopatias('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="cardiopatias" formControlName="cardiopatias" (focus)="activarCardiopatias('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="cardiopatias">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="cardiopatias" formControlName="cardiopatias" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>ENFERMEDADES RENALES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="efermedades_renales" formControlName="efermedades_renales" (focus)="activarEfermedades_renales('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="efermedades_renales" formControlName="efermedades_renales" (focus)="activarEfermedades_renales('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="efermedades_renales">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="efermedades_renales" formControlName="efermedades_renales" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>COAGULOPATIAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="coagulopatias" formControlName="coagulopatias" (focus)="activarCoagulopatias('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="coagulopatias" formControlName="coagulopatias" (focus)="activarCoagulopatias('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="coagulopatias">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="coagulopatias" formControlName="coagulopatias" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>CÁNCER</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="cancer" formControlName="cancer" (focus)="activarCancer('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="cancer" formControlName="cancer" (focus)="activarCancer('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="cancer">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="cancer" formControlName="cancer" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>NEOPLASÍA HEMATOLOGÍCA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="neoplasia_hematologica" formControlName="neoplasia_hematologica" (focus)="activarNeoplasia_hematologica('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="neoplasia_hematologica" formControlName="neoplasia_hematologica" (focus)="activarNeoplasia_hematologica('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="neoplasia_hematologica">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="neoplasia_hematologica" formControlName="neoplasia_hematologica" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>ANEMIA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="anemia" formControlName="anemia" (focus)="activarAnemia('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="anemia" formControlName="anemia" (focus)="activarAnemia('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="anemia">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="anemia" formControlName="anemia" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>INFECCIONES BACTERIOLÓGICAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="infecciones_bacteo" formControlName="infecciones_bacteo" (focus)="activarInfecciones_bacteo('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="infecciones_bacteo" formControlName="infecciones_bacteo" (focus)="activarInfecciones_bacteo('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="infecciones_bacteo">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="infecciones_bacteo" formControlName="infecciones_bacteo" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>LEPRA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="lepra" formControlName="lepra" (focus)="activarLepra('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="lepra" formControlName="lepra" (focus)="activarLepra('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="lepra">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="lepra" formControlName="lepra" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>PALUDISMO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="paludismo" formControlName="paludismo" (focus)="activarPaludismo('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="paludismo" formControlName="paludismo" (focus)="activarPaludismo('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="paludismo">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="paludismo" formControlName="paludismo" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>BRUCELOSIS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="brucelosis" formControlName="brucelosis" (focus)="activarBrucelosis('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="brucelosis" formControlName="brucelosis" (focus)="activarBrucelosis('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="brucelosis">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="brucelosis" formControlName="brucelosis" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>DIABETES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="diabetes" formControlName="diabetes" (focus)="activarDiabetes('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="diabetes" formControlName="diabetes" (focus)="activarDiabetes('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="diabetes">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="diabetes" formControlName="diabetes" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>HIPERTENSIÓN ARTERIAL</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="hipertension_arte" formControlName="hipertension_arte" (focus)="activarHipertension_arte('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="hipertension_arte" formControlName="hipertension_arte" (focus)="activarHipertension_arte('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="hipertension_arte">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="hipertension_arte" formControlName="hipertension_arte" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>TUBERCOLOSIS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="tuberculosis" formControlName="tuberculosis" (focus)="activarTuberculosis('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="tuberculosis" formControlName="tuberculosis" (focus)="activarTuberculosis('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tuberculosis">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="tuberculosis" formControlName="tuberculosis" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>EPILEPSIA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="epilepsia" formControlName="epilepsia" (focus)="activarEpilepsia('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="epilepsia" formControlName="epilepsia" (focus)="activarEpilepsia('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="epilepsia">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="epilepsia" formControlName="epilepsia" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>ICTERICIA/ACOLIA/COLURIA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="icteria" formControlName="icteria" (focus)="activarIcteria('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="icteria" formControlName="icteria" (focus)="activarIcteria('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="icteria">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="icteria" formControlName="icteria" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>TRANSTORNOS MENTALES/SX DEMENCIAL</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="transtormental" formControlName="transtormental" (focus)="activarTranstormental('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="transtormental" formControlName="transtormental" (focus)="activarTranstormental('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="transtormental">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="transtormental" formControlName="transtormental" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>TOXOPLASMOSIS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="toxoplasmosis" formControlName="toxoplasmosis" (focus)="activarToxoplasmosis('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="toxoplasmosis" formControlName="toxoplasmosis" (focus)="activarToxoplasmosis('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="toxoplasmosis">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="toxoplasmosis" formControlName="toxoplasmosis" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>TRANSPLANTES</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="transplantes" formControlName="transplantes" (focus)="activarTransplantes('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="transplantes" formControlName="transplantes" (focus)="activarTransplantes('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="transplantes">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="transplantes" formControlName="transplantes" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>COVID-19 (SARS-COV2)</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="covid" formControlName="covid" (focus)="activarCovid('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="covid" formControlName="covid" (focus)="activarCovid('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="covid">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="covid" formControlName="covid" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>OTRAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="otros_patologicos" formControlName="otros_patologicos" (focus)="activarOtros_patologicos('SI')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="otros_patologicos" formControlName="otros_patologicos" (focus)="activarOtros_patologicos('NO')" (blur)="formularioAntecedentesPato(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="otros_patologicos">
            <div class="col">
                <p>CUAL</p>
            </div>
            <div class="col">
                <input type="text" name="otros_patologicos" formControlName="otros_patologicos" ngModel (blur)="formularioAntecedentesPato(form)">
            </div>
        </div>

    </form>
</div>