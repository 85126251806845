import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/conf';
@Injectable({
  providedIn: 'root'
})
export class HistoriaClinicaService {


  public url = URL;

  constructor(
    public _http:HttpClient
  ) { }


  agregarHojaEvolucion(id, body){
  const url = `${this.url}/agregar/hoja/evolucion/modificado/${id}`;
    return this._http.put( url, body );
  }

  agregarHistoriaCLinicaPaciente(body){
    return this._http.post( `${this.url}/agregar/hoja/evolucion`, body);
  }

  agregarHistoriaClinica(historia){

    return this._http.post( `${this.url}/agregar/AgregarAntecedentes`, historia);
  }


  agregarIdModelPaciente(id, idAntecedentes){

  const uri = `${this.url}/agregar/antecedentes/${id}`;

    return this._http.put(uri, idAntecedentes)
  }


  // los antecedentes de normales del paciente
  obtenerAntecedentesDelPaciente(id){
    // console.log( id );
    return this._http.get(`${this.url}/ver/antecedentes/paciente/${id}`);
  }

  obtenerAntecedentes(id){
    return this._http.get(`${this.url}/ver/antecedentes/${id}`);
  }

  // obtener los signos viatales de un paciente en cosnultas anteriores

  obtenerHistroialSignosVitalesPaciente(id) {

    /* console.log(  id ); */

    let url =  `${this.url}/ver/signos/paciente/${id}`;
    return this._http.get( url );

  }

  agregarSignosVitales(id, body){
    console.table( { id, body })
    let url =  `${this.url}/agregar/signos/consulta/${id}`;
    return this._http.put( url, body );

  }

  obtenerConsultaPorElId(id){
    let url = `${this.url}/consulta/general/identificacion/${id}`;
    return this._http.get( url );
  }
//  Inicio Vacunacion
  agregarEsquemaVacunacion( esquema ){
    let url =  `${this.url}/agregar/esquema/vacunacion`;

    return this._http.post(   url, esquema  );

  }

  obtenerEV(id) {
    let url = `${this.url}/ver/esquema/vacunacion/${id}`;

    return this._http.get( url )
  }

  actualizarEV(id, body){
    let url = `${this.url}/actualizar/esquema/vacunacion/${id}`;
    return this._http.put(url, body);
  }

// Fin Vacunacion

// Inicio Nutricion
  agregarNutricion(nutricion) {
    let url = `${this.url}/agregar/AgregarNutricionNinos`;

    return this._http.post(url, nutricion)
  }
  obtenerNutricion(id){
    let url = `${this.url}/ver/nutricion/${id}`;

    return this._http.get( url );
  }
  actualizarNutricion(id, body){
    let url = `${this.url}/actualizar/nutricion/${id}`;
    return this._http.put(url, body)
  }
// Fin Nutricion

  ///Inicio Heredo Familiares
  agregarHeredoFamiliares( heredoFamiliares ) {
    let url =  `${this.url}/agregar/antecedentes/herodo/familiares`;

    return this._http.post ( url, heredoFamiliares );
  }

  actualizarHeredoFamiliaresEnElPaciente( id, body){
    
    let url = `${this.url}/actualizar/antecedentes/heredo/familiares/paciente/${id}`;
    return this._http.put(url, body)
  }


  obtenerHeredoFamiliares(id){

    /* console.log(  id ); */

    let url =  `${this.url}/ver/antecedentes/heredo/familiares/${id}`;
    return this._http.get( url );

  }
  ///Fin Heredo Familiares

  ///Inicio Antecedentes Personales No Patologicos
  agregarPersonalesNoPatologicos( personalesNoPatologicos ){
    let url = `${this.url}/agregar/antecedentes/no/patologicos`;
     return this._http.post( url, personalesNoPatologicos );

}
  actualizarHistoriaclinicaPorAparatos(idPaciente, idHistoria){

    // aca ta we
  let url =  `${this.url}/actualizar/historia/clinica/${idPaciente}`;
  return this._http.put(url, idHistoria);
  }

  actualizarPersonalesNoPatologicosEnElPaciente( id, body ){
  let url = `${this.url}/actualizar/antecedentes/no/patologicos/paciente/${id}`;

  return this._http.put(url, body);
  }
///Fin Antecedentes Personales No Patologicos

///Inicio Personales Patologicos
  agregarPersonalesPatologicos( personalesPatologicos ) {
  let url = `${this.url}/agregar/antecedentes/patologicos`;

  return this._http.post( url, personalesPatologicos);
  }

  actualizarPersonalesPatologicos(id, body) {
    let url = `${this.url}/actualizar/personales/patologicos/${id}`;

    return this._http.put(url, body)
  }
///Fin Personales Patologicos

  obtenerAntecedentePersonalePatologicos(id){
    let url = `${this.url}/ver/antecedentes/personales/patologicos/${id}`;
    return this._http.get( url );
  }

///Inicio GinecoObstetrcicos Historia Clinica
  agregarGinecoObstetricosHC( ginecoObsHC ) {
  let url = `${this.url}/agregar/gineco/obstetricos/historia`;

  return this._http.post( url, ginecoObsHC );
  }

  obtenerGinecoHistoria ( id ) {
    let url = `${this.url}/ver/antecedentes/gineco/historia/${id}`;

    return this._http.get ( url )
  }
  actualizarGinecoH(id, body){
    let url = `${this.url}/actualizar/gineco/historia/paciente/${id}`;

  return this._http.put( url, body);
  }
///Fin Gineco Obstetricos HC

// Agregar GinecoObstetricos por visita
  agregarGinecoObstetricosPorVisita ( ginecoPorVisita ) {
    let url = `${this.url}/agregar/antecedentes/gineco`;
      /* console.log(url); */
      
    return this._http.post( url, ginecoPorVisita );
  }

  obtenerGinecoPorVisita ( id ) {
    let url = `${this.url}/ver/antecedentes/gineco/visita/${id}`;

    return this._http.get( url );
  }

  actualizarGinecoPorVisita(id, body){
    let url = `${this.url}/actualizar/gineco/visita/paciente/${id}`

    return this._http.put(url, body);
  }


// fin GinecoObstetricos por visita

agregarAparatosYsistemas (aparatosYsistemas) {
  let url = `${this.url}/agregar/aparatos/sistemas`;
  /* console.log(url); */

  return this._http.post( url, aparatosYsistemas );
  
}

actualizarAparatosYsistemas( id, body) {
  let url = `${this.url}/actualizar/aparatos/sistemas/paciente/${id}`;
  return this._http.put( url, body )
}

//Medicina Preventiva

agregarMedicinaPreventiva ( medicinaPreventiva) {
  let url = `${this.url}/agregar/medicina/preventiva`;
  return this._http.post ( url, medicinaPreventiva );
}

actualizarMedicinaPreventivaEnElPaciente (id, body) {

let url = `${this.url}/actualizar/medicina/preventiva/${id}`;
    return this._http.put( url, body )
  }

obtenerMedicinaPreventiva(id){

  /* console.log( id ); */
  let url =  `${this.url}/ver/medicina/preventiva/${id}`;
  return this._http.get( url );
}

// Actualizar Data de antecedentes para el paciente:
// actualizarAntecedentesEnElPaciente( id, body ){


//   let url = `${this.url}/actualizar/antecedentes/${id}`;
  
//   return this._http.put(url, body);
// }

actualizarAntecedentesPaciente( id, body ){
  let url = `${this.url}/actualizar/antecedentes/${id}`;

  return this._http.put( url, body);
}


verAntecendetesNoPatoloogicos(  id  ){
  let url = `${this.url}/ver/antecedentes/no/patologicos/${id}`;
  return this._http.get( url );
}

// obtenemos las hojas de evoluicn anteriores

verHistorialDeEvoluciones(id) {
  const url = `${this.url}/ver/hoja/evolucion/${id}`;
  return this._http.get( url );
}

verHistorialRecetasMedicas(id){
  return this._http.get(this.url +'/ver/consultas/anteriores/paciente/'+id);
}

    
}
