<div class="container">
    <section>
        <app-titulos [title]="'HISTORIA CLÍNICA'" [rol]="'doctor'"></app-titulos>
        <!-- <app-ficha-doctor [titulo]="'HISTORIA CLÍNICA'" [pacienteInfo]="paciente"></app-ficha-doctor> -->
        <app-fichas-identificacion [data]="paciente" [rol]="'doctor'"></app-fichas-identificacion>
    </section>

    <h5> SIGNOS VITALES </h5>
    <div class="card" id="signos">
        <div class="row">
            <div class="col">
                <p>PESO: {{signos.peso}} (Kg)</p>
            </div>
            <div class="col">
                <p>TALLA: {{signos.talla}} (M)</p>
            </div>
            <div class="col">
                <p>IMC: {{signos.imc}} (kg/m2)</p>
            </div>
            <div class="col">
                <p>SISTOLICA: {{signos.sistolica}} (mmHg)</p>
            </div>
            <div class="col">
                <p>DIASTOLICA: {{signos.diastolica}} (mmHg)</p>
            </div>
            <div class="col">
                <p>TEMP: {{signos.temp}} (°C)</p>
            </div>
        </div>
    </div>

    <div class="card" id="historia">
        <div role="tabpanel" class="row">
            <div class="col-6" id="indicadoress">
                <ul class="nav flex-column nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#indicadoresGeograficos" aria-controls="#indicadoresGeograficos"> 
                            INDICADORES GEOGRÁFICOS 
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#antecedentes" data-toggle="tab" aria-controls="#antecedentes"> 
                            ANTECEDENTES 
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#antecedentesPersonales" data-toggle="tab" aria-controls="#antecedentesPersonales"> 
                            ANTECEDENTES PERSONALES 
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#antecedentesPatologicos" data-toggle="tab" aria-controls="#antecedentesPatologicos">
                            ANTECEDENTES PATOLÓGICOS
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="paciente.genero == 'FEMENINO' || paciente.genero == 'femenino'">
                        <a class="nav-link" href="#antecedentesGineco" data-toggle="tab" aria-controls="#antecedentesGineco">
                            ANTECEDENTES GINECOOBSTETRICOS
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#practicasRiesgo" data-toggle="tab" aria-controls="#practicasRiesgo">
                            PRÁCTICAS DE RIESGO
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#parejasSexuales" data-toggle="tab" aria-controls="#parejasSexuales">
                            PAREJAS SEXUALES
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#ultimoAnio" data-toggle="tab" aria-controls="#ultimoAnio">
                            EN EL ÚLTIMO AÑO
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#medicamentos" data-toggle="tab" aria-controls="#medicamentos">
                            MEDICAMENTOS
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <div class="tab-content">
                    <div role="tabpanel" class="tab-pane active" id="indicadoresGeograficos">
                        <app-indicadores-geograficos (indicadores)="indicadores($event)"></app-indicadores-geograficos>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="antecedentes">
                        <app-antecedentes (antecedentes)="antecedentes($event)"></app-antecedentes>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="antecedentesPersonales">
                        <app-antecedentes-personales (antecedentesPersonales)="antecedentesPersonales($event)"></app-antecedentes-personales>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="antecedentesPatologicos">
                        <app-antecedentes-patologicos (antecedentespato)="antecedentespato($event)"></app-antecedentes-patologicos>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="antecedentesGineco" *ngIf="paciente.genero == 'FEMENINO' || paciente.genero == 'femenino'">
                        <app-antecedentes-gineco (antecedentesgineco)="antecedentesgineco($event)"></app-antecedentes-gineco>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="practicasRiesgo">
                        <app-practicas-riesgo (riesgo)="riesgo($event)"></app-practicas-riesgo>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="parejasSexuales">
                        <app-parejas-sexuales (parejasSexuales)="parejasSexuales($event)"></app-parejas-sexuales>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="ultimoAnio">
                        <app-ultimo-anio (ultimo)="ultimo($event)"></app-ultimo-anio>
                    </div>
                    <div role="tabpanel" class="tab-pane" id="medicamentos">
                        <app-medicamentos (medicamentos)="medicamentos($event)"></app-medicamentos>
                        <div class="boton">
                            <button class="principalButton" data-toggle="modal" data-target="#preregistro" (click)="ageragrHistoria()"> Agregar </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="preregistro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    EL RESULTADO DE LA HISTORIA CLINICA
                    <!-- ES {{respuesta.respuestas}}/12 -->
                </div>
                <div class="modal-body">
                    <p *ngIf="respuesta.diferir == false" id="sipuede">EL DISPONENTE PUEDE CONTINUAR CON EL PROCESO</p>
                    <p *ngIf="respuesta.diferir == true" id="nopuede">EL DISPONENTE NO PUEDE CONTINUAR CON EL PROCESO</p>
                    <textarea *ngIf="respuesta.diferir == true" name="motivo" id="motivo" cols="30" rows="10" placeholder="COMENTARIOS">{{respuesta.diferido[0].motivo}}</textarea>
                    <div class="center">
                        <button class="principalButton" (click)="enviar(respuesta)" data-dismiss="modal"> ACEPTAR </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="display-none" id="sppiner-section">
        <div class="row">
            <div class="sppiner-bg">
                <div class="spinner-stock">
                    <div class="col-md-12">
                        <app-sppiner></app-sppiner>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>