import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';

@Component({
  selector: 'app-detalle-servicios',
  templateUrl: './detalle-servicios.component.html',
  styleUrls: ['./detalle-servicios.component.css']
})
export class DetalleServiciosComponent implements OnInit {
  
  public pagina:number;
  public idServicio = "";
  public servicio={
    nombreDeServicio:'',
    fechas:[],
    _id:''
  }
  public service = {
    categoria: "",
    costo: 0,
    descripcion: "",
    horario: [],
    nombre: "",
    participante: "",
    productosOMateriales: [],
    profesionalAREalizar: "",
    status: "",
    tipoServicio: "",
    idServicio:""
  }

  constructor(
    private _almacenService: AlmacenService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.idServicio = this.activatedRoute.snapshot.paramMap.get('id');
    // this.getServiceById();
    this.obtenerHorario();
  }
  
  obtenerHorario(){
    this._almacenService.obtenerFechasDisponibles(this.idServicio).subscribe(resp=>{
      console.log(resp);
      this.servicio = resp['data'][0]
    })
  }

  getServiceById(){
    this._almacenService.getServiceCensurById( this.idServicio )
    .subscribe((data:any) => {
      if(data.ok){
        this.setServiceById(data.data);
      }
    })
  }

  setServiceById(data:any){
    this.service = data[0];
  }

  printTable() {

    const tableToPrintHtml = document.querySelector('#tableServicesToPrint');
    const page = document.body.innerHTML;
    document.body.innerHTML  = "";
    document.body.append( tableToPrintHtml );
    window.print();

    tableToPrintHtml.classList.remove('img-to-print');
    document.body.innerHTML = page;
  }

}
