<div class="container-fluid">
    <app-titulos [title]="'REGISTRO DE PRODUCTOS'"></app-titulos>

    <div class="card cardPer">
        <h2 class="title-secondary">DATOS DEL PRODUCTO</h2>
        <form [formGroup]="newFormProductos">
            <div class="row mb-3">
                <div class="col-md-2">
                    <h5> Sustancia :</h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" name="nombre" id="" required formControlName="nombre">
                </div>
    
                <div class="col-md-2">
                    <h5>Id:</h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" [value]="newIdProducto"  name="idProducto" id=""  disabled  formControlName="idProducto" >
                </div>
            </div>
        
            <div class="row mb-3">
                <div class="col-md-2">
                    <h5>Tipo de producto:</h5>
                </div>
                <div class="col-md-4">
                    <select class="custom-select mayus" id="inputGroupSelect01"   formControlName="tipo_producto" >
                        <option selected>selecciona un producto...</option>
                        <option value="I"> I </option>
                        <option value="II"> II </option>
                        <option value="III"> III </option>
                        <option value="IV"> IV </option>
                        <option value="V"> V </option>
                        <option value="VI"> VI </option>
                      </select>
                </div>
                <div class="col-md-2">
                    <h5> Nombre comercial: </h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" name="" id="" required  formControlName="nombre_comercial" />
                </div>
            </div>

            <div class="row" *ngIf="medicamento">
                <div class="col-md-2">
                    <h5>Presentacion:</h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" name="presentacion" id="" required    formControlName="presentacion">   
                </div>
                <div class="col-md-2">
                    <h5> Contenido del frasco:</h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" name="contenidoFrasco" id="" required  formControlName="contenidoFrasco">   
                </div>
            </div>

            <div class="row" *ngIf="medicamento">
                <div class="col-md-2">
                    <h5>Via de administración:</h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" name="viaAdministracion" id="" required    formControlName="viaAdministracion">   
                </div>
                <!-- <div class="col-md-2">
                    <h5> Fecha de caducidad:</h5>
                </div>
                <div class="col-md-4">
                    <input type="date" class="input" name="fechaCaducidad" id="" required  formControlName="fechaCaducidad">   
                </div> -->
            </div>
            <div formArrayName="proveedor" *ngFor="let valor of valoresProveedor.controls; let j = index" >
                <div  [formGroupName]="j">
                    <div class="row mb-3">
                        <div class="col-md-2">
                            <h5>Proveedor:</h5>
                        </div>
                        <div class="col-md-4">
                            <select class="custom-select mayus"  id="inputGroupSelect01"  formControlName="proveedor" >
                                <option [value]="proveedor.razonSocial"   *ngFor="let proveedor of proveedoresCensur" > {{ proveedor.nombreEmpresa }} </option>
                                <!-- <option value="Vic"></option> -->
                              </select>
                        </div>
                        <div class="col-md-2">
                            <h5>Costo: </h5>
                        </div>
                        <div class="col-md-3">
                            <input type="number" min="0" class="input" name="" id=""  required   formControlName="costo" >
                        </div>
                        <div class="col-md-1">
                            <i class="fa fa-trash-alt colorRed pointer" (click)="removeValores(j)"></i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ! Modal de los Proveedores -->

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Agregar Proveedor</h5> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <app-registro-proveedores-bs (agregar)="agregarNuevoProveedor($event)"></app-registro-proveedores-bs>
                  </div>
                </div>
            </div>
     
            <div class="row">
                <div class="col-md-2">
                    <h5>Laboratorio:</h5>
                </div>
                <div class="col-md-4">
                    <input type="text" class="input" name="laboratorio" id="" required    formControlName="laboratorio">   
                </div>
                <div class="col-md-2">
                    <h5>Cantidad minima:</h5>
                </div>
                <div class="col-md-4">
                    <input type="number" min="0" class="input" name="cantidadMin" id="" required    formControlName="cantidadMin">   
                </div>
                <!-- <div class="col-md-2">
                    <h5> Precio venta:</h5>
                </div>
                <div class="col-md-4">
                    <input type="number" class="input" name="precio" id="" required  formControlName="precio">   
                </div> -->
            </div>

            <!-- <div class="row" style="margin-top: 10px;">
                <div class="col-md-2">
                    <h5>Cantidad minima:</h5>
                </div>
                <div class="col-md-4">
                    <input type="number" min="0" class="input" name="cantidadMin" id="" required    formControlName="cantidadMin">   
                </div>
                <div class="col-md-2" *ngIf="medicamento">
                    <h5>Lote:</h5>
                </div>
                <div class="col-md-4" *ngIf="medicamento">
                    <input type="number" min="0" class="input" name="lote" id="" required    formControlName="lote">   
                </div>
            </div> -->

            <div class="row" style="margin-top: 20px;">
                <div class="col-md-6">
                    <input type="checkbox" formControlName="medicamento" (change)="activarMedicamento()">
                    <label style="margin-left: 10px;">Medicamento</label>
                </div>
            </div>
        
            <div class="row ml-1 mb-4 mt-3">
                <!-- <button class="boton" (click)="agregarProveedor()">
                    Agregar proveedor
                </button> -->
                <div class="col-md-3">
                    <label class="proveedor" (click)="agregarProveedor()" >Agregar proveedor</label>
                </div>
                <div class="col-md-3">
                    <a class="proveedor" type="button" data-toggle="modal" data-target=".bd-example-modal-lg">Agregar NUEVO Proveedor</a>
                </div>
                <!-- <h5 class="mayus pointer pointer-prove" (click)="agregarProveedor()" style="color: #00acd8;">agregar  proveedor</h5> -->
            </div>
        
            <div class="row ml-1">
                <div class="col-md-12">
                    <h5>Descripción:</h5>
                    <textarea class="mb-3" name="" id="" cols="160" rows="3" formControlName="descripcion"  ></textarea>
                </div>
            </div>
        
            <div class="row">
                <div class="offset-md-5 col-md-3">
                    <button (click)="enviarFormualrio()" [disabled]="btnRegistro" class="boton pointer">REGISTRAR</button>
                </div>
            </div>
        </form>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>

<!-- <div class="card cardPer container">
    
    <h3 class="mayus mb-4 title-registro">Datos del Producto</h3>
    
    <form  [formGroup]="newFormProductos" >


        <div class="row mb-3">
            <div class="col-md-2">
                <h5 class="mayus"> Sustancia :</h5>
            </div>
            <div class="col-md-4">
                <input type="text"  name="nombre" id="" required formControlName="nombre">
            </div>

            <div class="col-md-2">
                <h5 class="mayus">id:</h5>
            </div>
            <div class="col-md-4">
                <input type="text" [value]="newIdProducto"  name="idProducto" id=""  disabled  formControlName="idProducto" >
            </div>
        </div>
    
        <div class="row mb-3">
            
            <div class="col-md-2">
                <h5 class="mayus">tipo de producto:</h5>
            </div>
            <div class="col-md-4">
                <select class="custom-select mayus" id="inputGroupSelect01" required   formControlName="tipo_producto" >
                    <option selected>selecciona un producto...</option>
                    <option value="I"> I </option>
                    <option value="II"> II </option>
                    <option value="III"> III </option>
                    <option value="IV"> IV </option>
                    <option value="V"> V </option>
                    <option value="VI"> VI </option>
                  </select>
            </div>
            <div class="col-md-2">
                <h5 class="mayus"> Nombre comercial: </h5>
            </div>
            
            <div class="col-md-4">
                <input type="text" name="" id="" required  formControlName="nombre_comercial" />
            </div>
        </div>
        
        <div formArrayName="proveedor" *ngFor="let valor of valoresProveedor.controls; let j = index" >
            <div  [formGroupName]="j">

                <div class="row mb-3">
                    <div class="col-md-2">
                        <h5 class="mayus">proveedor:</h5>
                    </div>
                    <div class="col-md-4">
                        <select class="custom-select mayus"  id="inputGroupSelect01"  formControlName="proveedor" >
                            
                            <option [value]="proveedor.razonSocial"   *ngFor="let proveedor of proveedoresCensur" > {{ proveedor.nombreEmpresa }} </option>
                            <option value="Vic"></option>
                          </select>
                    </div>
                    <div class="col-md-2">
                        <h5 class="mayus">Costo: </h5>
                    </div>
                    <div class="col-md-4">
                        <input type="number" name="" id=""  required   formControlName="costo" >
                    </div>
                </div>
            </div>
        </div>
   
    
        <div class="row">
            
            <div class="col-md-2">
                <h5 class="mayus">laboratorio:</h5>
            </div>
            <div class="col-md-4">
                <input type="text" name="laboratorio" id="" required    formControlName="laboratorio">   
            </div>
            
            <div class="col-md-2">
                <h5 class="mayus"> Precio venta:</h5>
            </div>
            <div class="col-md-4">
                <input type="text" name="precioVenta" id="" required  formControlName="precioVenta">   
            </div>
        

        </div>

        <div class="row">
            
            <div class="col-md-2">
                <h5 class="mayus">Cantidad Minima:</h5>
            </div>
            <div class="col-md-4">
                <input type="text" name="cantidadMin" id="" required    formControlName="cantidadMin">   
            </div>

        </div>
    
        <div class="row ml-1 mb-4 mt-3">
            <h5 class="mayus pointer pointer-prove" (click)="agregarProveedor()" style="color: #00acd8;">agregar  proveedor</h5>
        </div>
    
        <div class="row ml-1">
            <div class="col-md-12">
                <h5 class="mayus">descripción:</h5>
                <textarea class="mb-3" name="" id="" (focus)="validarFormulario()"  cols="160" rows="3" formControlName="descripcion"  ></textarea>
            </div>
        </div>
    
        <div class="row">
            <div class="offset-md-5 col-md-3">
    
                <button (click)="enviarFormualrio()" [disabled]="btnRegistro" class="btn mayus btn-censur" style="text-align: center!important; background-color: #00acd8; color: #fff;">registrar</button>

            </div>
    
        </div>
    
    </form>
</div> -->

<!-- <section class="display-none" id="sppiner-section" >
    <div class="row">
        <div class="sppiner-bg">
            <div class="spinner-stock">
                <div class="col-md-12">
                    <app-sppiner></app-sppiner>
                </div>
            </div>
    
        </div>
    </div>

</section> -->