import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { BancoService } from '../../../../../services/bancodeSangre/banco.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-hoja-servicio-receptor',
  templateUrl: './hoja-servicio-receptor.component.html',
  styleUrls: ['./hoja-servicio-receptor.component.css']
})
export class HojaServicioReceptorComponent implements OnInit {

  public servicios =[];
  public idCensur;
  public sede;
  public idcensur = {
    idcensur:''
  }
  public receptor = {
    idbancosangre:''
  }
  public pedido = {
    estudios:[{
      cantidad: 0,
      idProducto: '',
      precio: 0,
      producto: ''
    }],
    fecha: '',
    pedido: '',
    sede_banco: '',
    status: '',
    totalCompra: 0,
    vendedor: '',
    _id: ""
  }
  public carrito = {
    total: 0,
    items: []
  };

  public id='';
  public paciente = {
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    _id:"",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    receptor:"",
    tipoDeSangre:""
  }

  constructor(private _bancoSangre: BancoService,
              private activatedRoute: ActivatedRoute,
              private _paciente: PacientesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(){
    this.idCensur = this.activatedRoute.snapshot.paramMap.get('id');
    this.idcensur.idcensur = this.idCensur
    this.getServicios();
    this.setLocal();
    this.getPedidoReceptor();
    this.spinner.show();
  }

  setLocal(){
    localStorage.setItem('idCensur', this.idCensur)
  }

  getPedidoReceptor(){
    this.receptor.idbancosangre = this.idCensur;
    this._bancoSangre.getPedidoReceptor(this.receptor).subscribe((resp:any) =>{
      this.setPedido(resp.data);
      /* console.log(resp.data) */
    })
  }

  setPedido(pedido){
    this.pedido = pedido.pedido;
    localStorage.setItem('pedidoCensur',this.pedido._id);
    this.id= pedido.paciente._id;
    this.getPaciente(this.id);
  }

  getPaciente(id){
    this._paciente.getPacienteBtID(id).subscribe((resp:any) =>{
      this.setPaciente(resp.paciente);
      this.spinner.hide();
    })
  }
      
  setPaciente(paciente){
    this.paciente.nombrePaciente = paciente.nombrePaciente;
    this.paciente.apellidoPaterno = paciente.apellidoPaterno;
    this.paciente.apellidoMaterno = paciente.apellidoMaterno;
    this.paciente.curp = paciente.curp;
    this.paciente.edad = paciente.edad;
    this.paciente.genero = paciente.genero;
    this.paciente._id = paciente._id;
    this.paciente.callePaciente = paciente.callePaciente;
    this.paciente.paisPaciente = paciente.paisPaciente;
    this.paciente.estadoPaciente = paciente.estadoPaciente;
    this.paciente.fechaNacimientoPaciente = paciente.fechaNacimientoPaciente;
    this.paciente.telefono = paciente.telefono;
    this.paciente.receptor = paciente.receptor;
    this.paciente.tipoDeSangre = paciente.tipoDeSangre;
    localStorage.setItem('receptor', JSON.stringify(paciente));    
  }
  

  getServicios(){
    //this.servicios = this._bancoSangre.getDashServicio();
    this._bancoSangre.getDashServicio(this.idcensur).subscribe((resp:any) =>{
     this.servicios = resp['data'] 
     /* console.log(this.servicios) */
    })
    
  }

}