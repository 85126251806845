import { Component, OnInit } from '@angular/core';
import { IntegradosService, Scv } from '../../../services/servicios/integrados.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-entrega-dash',
  templateUrl: './entrega-dash.component.html',
  styleUrls: ['./entrega-dash.component.css']
})
export class EntregaDashComponent implements OnInit {
  public pagina : number = 0;
  public totalservicios:number;
  
  servicio:Scv[]=[];
  
  constructor( private _serviciosService: IntegradosService, private _router:Router) {}

  ngOnInit(): void {
    this.servicio= this._serviciosService.getServicios();
    /* console.log(this.servicio); */
    this.totalservicios = this.servicio.length;
    this.pagina = 0;
  }

}
