import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-antecedentes-patologicos',
  templateUrl: './antecedentes-patologicos.component.html',
  styleUrls: ['./antecedentes-patologicos.component.css']
})
export class AntecedentesPatologicosComponent implements OnInit {

  @Output() antecedentespato = new EventEmitter<string>();
  formulario: FormGroup;
  public cardiopatias= false;
  public efermedades_renales= false;
  public coagulopatias= false;
  public cancer= false;
  public neoplasia_hematologica= false;
  public anemia= false;
  public infecciones_bacteo= false;
  public lepra= false;
  public paludismo= false;
  public brucelosis= false;
  public diabetes= false;
  public hipertension_arte= false;
  public tuberculosis= false;
  public epilepsia= false;
  public icteria= false;
  public transtormental= false;
  public toxoplasmosis= false;
  public transplantes= false;
  public covid= false;
  public otros_patologicos= false;

  constructor() { }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      cardiopatias: new FormControl('NO'),
      efermedades_renales: new FormControl('NO'),
      coagulopatias: new FormControl('NO'),
      cancer: new FormControl('NO'),
      neoplasia_hematologica: new FormControl('NO'),
      anemia: new FormControl('NO'),
      infecciones_bacteo: new FormControl('NO'),
      lepra: new FormControl('NO'),
      paludismo: new FormControl('NO'),
      brucelosis: new FormControl('NO'),
      diabetes: new FormControl('NO'),
      hipertension_arte: new FormControl('NO'),
      tuberculosis: new FormControl('NO'),
      epilepsia: new FormControl('NO'),
      icteria: new FormControl('NO'),
      transtormental: new FormControl('NO'),
      toxoplasmosis: new FormControl('NO'),
      transplantes: new FormControl('NO'),
      covid: new FormControl('NO'),
      otros_patologicos: new FormControl('NO'),
    });
    this.antecedentespato.emit(this.formulario.value);
  }

  activarCardiopatias(tipo){
    if(tipo == 'SI'){
      this.cardiopatias = true;
    }else{
      this.cardiopatias = false;
    }
  }

  activarEfermedades_renales(tipo){
    if(tipo == 'SI'){
      this.efermedades_renales = true;
    }else{
      this.efermedades_renales = false;
    }
  }

  activarCoagulopatias(tipo){
    if(tipo == 'SI'){
      this.coagulopatias = true;
    }else{
      this.coagulopatias = false;
    }
  }

  activarCancer(tipo){
    if(tipo == 'SI'){
      this.cancer = true;
    }else{
      this.cancer = false;
    }
  }

  activarNeoplasia_hematologica(tipo){
    if(tipo == 'SI'){
      this.neoplasia_hematologica = true;
    }else{
      this.neoplasia_hematologica = false;
    }
  }

  activarAnemia(tipo){
    if(tipo == 'SI'){
      this.anemia = true;
    }else{
      this.anemia = false;
    }
  }

  activarInfecciones_bacteo(tipo){
    if(tipo == 'SI'){
      this.infecciones_bacteo = true;
    }else{
      this.infecciones_bacteo = false;
    }
  }

  activarLepra(tipo){
    if(tipo == 'SI'){
      this.lepra = true;
    }else{
      this.lepra = false;
    }
  }

  activarPaludismo(tipo){
    if(tipo == 'SI'){
      this.paludismo = true;
    }else{
      this.paludismo = false;
    }
  }

  activarBrucelosis(tipo){
    if(tipo == 'SI'){
      this.brucelosis = true;
    }else{
      this.brucelosis = false;
    }
  }

  activarDiabetes(tipo){
    if(tipo == 'SI'){
      this.diabetes = true;
    }else{
      this.diabetes = false;
    }
  }

  activarHipertension_arte(tipo){
    if(tipo == 'SI'){
      this.hipertension_arte = true;
    }else{
      this.hipertension_arte = false;
    }
  }

  activarTuberculosis(tipo){
    if(tipo == 'SI'){
      this.tuberculosis = true;
    }else{
      this.tuberculosis = false;
    }
  }

  activarEpilepsia(tipo){
    if(tipo == 'SI'){
      this.epilepsia = true;
    }else{
      this.epilepsia = false;
    }
  }

  activarIcteria(tipo){
    if(tipo == 'SI'){
      this.icteria = true;
    }else{
      this.icteria = false;
    }
  }

  activarTranstormental(tipo){
    if(tipo == 'SI'){
      this.transtormental = true;
    }else{
      this.transtormental = false;
    }
  }

  activarToxoplasmosis(tipo){
    if(tipo == 'SI'){
      this.toxoplasmosis = true;
    }else{
      this.toxoplasmosis = false;
    }
  }

  activarTransplantes(tipo){
    if(tipo == 'SI'){
      this.transplantes = true;
    }else{
      this.transplantes = false;
    }
  }

  activarCovid(tipo){
    if(tipo == 'SI'){
      this.covid = true;
    }else{
      this.covid = false;
    }
  }

  activarOtros_patologicos(tipo){
    if(tipo == 'SI'){
      this.otros_patologicos = true;
    }else{
      this.otros_patologicos = false;
    }
  }

  formularioAntecedentesPato(form: NgForm){
    this.antecedentespato.emit(form.form.value);
  }

}
