import { numeroALetras  } from '../../functions/generalFunctions';
import jsPDF from 'jspdf';
import { getDataStorage, getrolefunction } from 'src/app/functions/storage.funcion';
import Dates from '../dates/date.class';
import { getSessionName } from '../helpers/filterNamePaciente';



export default class Tickets {

    public IVaCDEl16=0;
    public totalIva=0
    constructor() {       
    }
    public IVaDEl16 = 0;
    public positionYPDF = 55;
    public totalLetra = "";
    public atendio = "";

    calcularIva( carrito, membresia  ){
      if(membresia){
        this.IVaDEl16 = ((carrito.totalCon  * 16 ) / 100);
      }else {
        this.IVaDEl16 = ((carrito.totalSin  * 16 ) / 100);
      }
    }

    Iva( total ){
      this.IVaDEl16 = ((total  * 16 ) / 100);
    }

    calcularIvaPedido( pedido){
      this.IVaDEl16 = ((pedido.totalCompra  * 16 ) / 100);
    }

    printTicket( paciente, pedido:any, infoVenta:any, folio ){
      let restanteCredito= 0;
      let restanteDebito= 0;
      let x=0;
      let y=0;

      this.atendio = getDataStorage().nombre;
  
      const doc: any = new jsPDF();
  
      doc.setFontSize(8);
      // REvisar estas funciones
      doc.text( 2 , 15,`No ticke: ${folio}         Fecha: ${infoVenta.fecha}`);
      doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
      //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
      doc.text( 20, 25, `Gracias ${ paciente.nombrePaciente } ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}` );
      doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
      doc.text(2,35,`           Estudio                                   Costo                `);
      doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
    
      pedido.estudios.forEach(  item => {
        if( paciente.membresiaActiva ){
          doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precioCon}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
        }else {
          doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precioSin}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
          this.positionYPDF += 5;
        }
      });

      this.calcularIvaPedido(pedido);
      doc.text(15, this.positionYPDF+=10, `Anticipo:                               $ ${(pedido.montoAnticipo).toFixed(2)} MX` );
      doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:              $ ${(infoVenta.totalCompra - pedido.montoAnticipo - this.IVaDEl16).toFixed(2)} MX` );
      doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${(this.IVaDEl16).toFixed(2)} MX`  );
      /* if(infoVenta.efectivo){
        infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.mon;
     }
     if(infoVenta.transferencia){
        infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoTranferencia;
     } */
      if(infoVenta.tarjetCredito){
        y=(infoVenta.totalCompra * .036)
        restanteCredito =(infoVenta.totalCompra+(infoVenta.totalCompra * .036))-infoVenta.montoTarjteCredito
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${(infoVenta.totalCompra * .036).toFixed(2)} MX`  );
      }else{
        restanteCredito = infoVenta.totalCompra
      }
      if(infoVenta.tarjetaDebito){
        x=(restanteCredito * .036)
        restanteDebito=((restanteCredito * .036))-infoVenta.montoTarjetaDebito
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${(restanteCredito * .036).toFixed(2)} MX`  );
      }else{
        restanteDebito=infoVenta.totalCompra
      }
      this.totalLetra = numeroALetras( infoVenta.totalCompra+x+y-pedido.montoAnticipo, 'Pesos mexicanos' );
      doc.text( 15, this.positionYPDF+=5,  `El Total con I.V.A:                  $ ${(infoVenta.totalCompra+x+y-pedido.montoAnticipo).toFixed(2)} MX`  );
      doc.text(5, this.positionYPDF+=6, `Total con letra : `);
      doc.text(2, this.positionYPDF+=5, `${this.totalLetra}` );
      doc.text(25, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
      doc.text( 25, this.positionYPDF+=9, "Gracias por su Compra");
      doc.text( 8, this.positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
      this.positionYPDF +=15;
      doc.save('TICKET');
      this.positionYPDF = 100;
    }

    printTicketSinMembresia( paciente, carrito:any, infoVenta:any, folio:any ){
        var hoy = new Date();
        var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
        let restanteCredito= 0;
        let restanteDebito= 0;
        let x=0;
        let y=0;
        
        this.atendio = getDataStorage().nombre;
        const dates =  new Dates()
        const doc: any = new jsPDF();
        doc.setFontSize(8);
        // REvisar estas funciones
        doc.text( 2 , 15,`No ticket:${folio}          Fecha: ${infoVenta.fecha = dates.getDate()} Hora: ${hora}`);
        doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
        //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
        doc.text( 20, 25, `Gracias ${ paciente.nombrePaciente } ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}` );
        doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
        doc.text(2,35,`           Estudio                                   Costo                `);
        doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
      
        carrito['items'].forEach(  item => {
          if( paciente.membresiaActiva ){
            doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
            doc.text(5,  this.positionYPDF+5,`$ ${item.precioCon}.00 MX` );
            doc.text(3, this.positionYPDF += 15, ``)
          }else {
            doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
            doc.text(5,  this.positionYPDF+5,`$ ${item.precioSin}.00 MX` );
            doc.text(3, this.positionYPDF += 15, ``)
            this.positionYPDF += 5;
          }
        });

        this.calcularIva(  carrito, paciente.membresiaActiva );
     
        if( paciente.membresiaActiva ){
          doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:               $ ${(carrito.totalCon - this.IVaDEl16).toFixed(2)} MX` );

        }else {
          doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:                 $ ${(carrito.totalSin - this.IVaDEl16).toFixed(2)} MX` );

        }
  
        doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${(this.IVaDEl16).toFixed(2)} MX`  );

        if(infoVenta.efectivo){
            infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoEfectivo;
        }
        if(infoVenta.transferencia){
            infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoTranferencia;
        }
        if(infoVenta.tarjetCredito){
          y=(infoVenta.totalCompra * .036)
          restanteCredito =(infoVenta.totalCompra+(infoVenta.totalCompra * .036))-infoVenta.montoTarjteCredito
          doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${(infoVenta.totalCompra * .036).toFixed(2)} MX`  );
        }else{
          restanteCredito = infoVenta.totalCompra
        }
        if(infoVenta.tarjetaDebito){
          x=(restanteCredito * .036)
          restanteDebito=((restanteCredito * .036)-infoVenta.montoTarjetaDebito)
          doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de debito:              $ ${(restanteCredito * .036).toFixed(2)} MX`  );
        }else{
          restanteDebito=infoVenta.totalCompra
        }
  
        if( paciente.membresiaActiva  ){
          doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                  $ ${(carrito.totalCon+x+y).toFixed(2)} MX`  );
        }else {
          doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                  $ ${(carrito.totalSin+x+y).toFixed(2)} MX`  );
        }
        if( paciente.membresiaActiva ){
          this.totalLetra = numeroALetras( carrito.totalCon+x+y , 'Pesos mexicanos' );
        }else{
          this.totalLetra = numeroALetras( carrito.totalSin+x+y, 'Pesos mexicanos' );
        }
        doc.text(5, this.positionYPDF+=6, `Total con letra : `);
        doc.text(2, this.positionYPDF+=5, `${this.totalLetra}` );
        doc.text(25, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
        doc.text( 25, this.positionYPDF+=9, "Gracias por su Compra");
        doc.text( 8, this.positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
        this.positionYPDF +=15;
        doc.save('TICKET');
        this.positionYPDF = 100;
    }

    printTicketPagosPaquete( paciente, carrito:any, comisionDebito:any , comisionCredito:any ,infoVenta:any, folio:any ){
      var hoy = new Date();
      var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();      
      this.atendio = getDataStorage().nombre;
      var debitoCom = 0;
      var creditoCom = 0;
      const dates =  new Dates()
      const doc: any = new jsPDF();
      doc.setFontSize(8);
      // REvisar estas funciones
      doc.text( 2 , 15,`No ticket:${folio}          Fecha: ${infoVenta.fecha = dates.getDate()} Hora: ${hora}`);
      doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
      //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
      doc.text( 20, 25, `Gracias ${ paciente.nombrePaciente } ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}` );
      doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
      doc.text(2,35,`           Estudio                                   Costo                `);
      doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
    
      carrito['items'].forEach(  item => {
        if( paciente.membresiaActiva ){
          doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precioCon}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
        }else {
          doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precioSin}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
          this.positionYPDF += 5;
        }
      });

      this.calcularIva(  carrito, paciente.membresiaActiva );
   
      if( paciente.membresiaActiva ){
        doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:               $ ${(carrito.totalCon - this.IVaDEl16).toFixed(2)} MX` );

      }else {
        doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:                 $ ${(carrito.totalSin - this.IVaDEl16).toFixed(2)} MX` );

      }

      doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${(this.IVaDEl16).toFixed(2)} MX`  );

      /* if(infoVenta.efectivo){
          infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoEfectivo;
      }
      if(infoVenta.transferencia){
          infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoTranferencia;
      } */
      if(infoVenta.tarjetCredito){
        creditoCom = parseFloat(comisionCredito);
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${comisionCredito} MX`  );
      }
      if(infoVenta.tarjetaDebito){
        debitoCom = parseFloat(comisionDebito);
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de debito:              $ ${comisionDebito} MX`  );
      }
      let total = infoVenta.totalCompra+creditoCom+debitoCom
      doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                  $ ${total} MX`  );
      
      this.totalLetra = numeroALetras( total , 'Pesos mexicanos' );
      
      doc.text(5, this.positionYPDF+=6, `Total con letra : `);
      doc.text(2, this.positionYPDF+=5, `${this.totalLetra}` );
      doc.text(25, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
      doc.text( 25, this.positionYPDF+=9, "Gracias por su Compra");
      doc.text( 8, this.positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
      this.positionYPDF +=15;
      doc.save('TICKET');
      this.positionYPDF = 100;
    }

    calcularIvaCotizacion( pedido){
      this.IVaCDEl16 = ((pedido  * 16 ) / 100);
    }
    // sin membresia 
    imprimirCotizacion( carrito ){
      const totalLetra = numeroALetras( carrito.totalSin, 'Pesos mexicanos' );
    
      const doc: any = new jsPDF();
      doc.setFontSize(8);
        // REvisar estas funciones
        doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
        //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
        doc.text( 20, 25, `Cotización horizonte` );
        doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
        doc.text(2,35,`           Estudio                                   Costo                `);
        doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
      
        this.calcularIvaCotizacion(carrito.totalSin);
    
      //  doc.addImage( ImagenTicketHorizonter, 'JPEG', 30, 5, 70, 50 );
      doc.setFontSize(10);
      carrito.items.forEach(  item => {
        doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
        doc.text(5,  this.positionYPDF+5,`$ ${item.precioSin}.00 MX` );
        doc.text(3, this.positionYPDF += 15, ``)
        this.positionYPDF += 5;
      });
      // this.calcularIva();
      /* doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:               $ ${(carrito.totalSin - this.IVaCDEl16).toFixed(2)} MX` );
      doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${(this.IVaCDEl16).toFixed(2)} MX`  ); */
      /* doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                   $ ${(carrito.totalSin).toFixed(2)} MX`  ); */
      doc.text( 15, this.positionYPDF+=10,  `El total:                                $ ${(carrito.totalSin).toFixed(2)} MX`  );
      doc.text( 15, this.positionYPDF+=5,  `El total con membresía:      $ ${(carrito.totalCon).toFixed(2)} MX`  );
      doc.text( 15, this.positionYPDF+=5,  `TE AHORRARIAS:              $ ${(carrito.totalSin).toFixed(2)-(carrito.totalCon).toFixed(2)} MX`  );

      doc.text(5, this.positionYPDF+=6, `Total con letra : `);
      doc.text(2, this.positionYPDF+=5, `${totalLetra}` );
      doc.text(50, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
      doc.text(50, this.positionYPDF+=12, "Gracias por su visita");
      doc.save('COTIZACION');
      this.positionYPDF = 100;
    }

    printTicketCansur(paciente, carrito:any, venta:any, folio:any){

      let x=0;
      let y=0;
      let restanteCredito= 0;
      let restanteDebito= 0;
      var hoy = new Date();
      var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
      const dates =  new Dates()
      const doc: any = new jsPDF();
  
      doc.setFontSize(8);
      // REvisar estas funciones
      doc.text( 2 , 15,`No ticke: ${folio}         Fecha: ${venta.fecha = dates.getDate()} Hora: ${hora}`);
      doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
      //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
      doc.text( 20, 25, `Gracias ${ venta.quienRecibe }` );
      doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
      doc.text(2,35,`           Producto                                   Costo                `);
      doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
    
      venta.product.forEach(  item => {
          doc.text(3, this.positionYPDF,`${item.producto}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precio}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
      });

      this.calcularIvaCensur(venta.total);
      doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:              $ ${(venta.total - this.totalIva).toFixed(2)} MX` );
      doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${(this.totalIva).toFixed(2)} MX`  );
      /* if(infoVenta.efectivo){
        infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.mon;
     }
     if(infoVenta.transferencia){
        infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoTranferencia;
     } */
      if(venta.TarjetaCredito){
        y=(venta.total * .036)
        restanteCredito =(venta.total+(venta.total * .036))-venta.montoCredito
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${(venta.total * .036).toFixed(2)} MX`  );
      }else{
        restanteCredito = venta.total
      }
      if(venta.TarjetaDebito){
        x=(restanteCredito * .036)
        restanteDebito=((restanteCredito * .036))-venta.montoDebito
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${(restanteCredito * .036).toFixed(2)} MX`  );
      }else{
        restanteDebito=venta.total
      }
      this.totalLetra = numeroALetras( venta.total+x+y, 'Pesos mexicanos' );
      doc.text( 15, this.positionYPDF+=5,  `El Total con I.V.A:                  $ ${(venta.total+x+y).toFixed(2)} MX`  );
      doc.text(5, this.positionYPDF+=6, `Total con letra : `);
      doc.text(2, this.positionYPDF+=5, `${this.totalLetra}` );
      doc.text(25, this.positionYPDF+=10,  `Atendío: ${venta.vendedor}`);
      doc.text( 25, this.positionYPDF+=9, "Gracias por su Compra");
      doc.text( 8, this.positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
      this.positionYPDF +=15;
      doc.save('TICKET');
      this.positionYPDF = 100;
    }

    calcularIvaCensur(pedido){
      this.totalIva = ((pedido  * 16 ) / 100);
      
    }

    generarTicket( paciente, carrito:any, infoVenta:any, folio:any ){
      var hoy = new Date();
      var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
      let restanteCredito= 0;
      let restanteDebito= 0;
      let x=0;
      let y=0;
      
      this.atendio = getDataStorage().nombre;
      const dates =  new Dates()
      const doc: any = new jsPDF();
      doc.setFontSize(8);
      // REvisar estas funciones
      doc.text( 2 , 15,`No ticket:${folio}          Fecha: ${infoVenta.fecha = dates.getDate()} Hora: ${hora}`);
      doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
      //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
      doc.text( 20, 25, `Gracias ${ paciente.nombrePaciente } ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}` );
      doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
      doc.text(2,35,`           Estudio                                   Costo                `);
      doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
    
      carrito.forEach(  item => {
        if( paciente.membresiaActiva ){
          doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precioCon}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
        }else {
          doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
          doc.text(5,  this.positionYPDF+5,`$ ${item.precioSin}.00 MX` );
          doc.text(3, this.positionYPDF += 15, ``)
          this.positionYPDF += 5;
        }
      });

      this.Iva(infoVenta.totalCompra);
   
      // if( paciente.membresiaActiva ){
      doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:               $ ${(infoVenta.totalCompra - this.IVaDEl16).toFixed(2)} MX` );

      // }else {
      //   doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:                 $ ${(carrito.totalSin - this.IVaDEl16).toFixed(2)} MX` );

      // }

      doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${(this.IVaDEl16).toFixed(2)} MX`  );

      // if(infoVenta.efectivo){
      //     infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoEfectivo;
      // }
      // if(infoVenta.transferencia){
      //     infoVenta.totalCompra = infoVenta.totalCompra - infoVenta.montoTranferencia;
      // }
      if(infoVenta.tarjetCredito){
        y=(infoVenta.totalCompra * .036)
        restanteCredito =(infoVenta.totalCompra+(infoVenta.totalCompra * .036))-infoVenta.montoTarjteCredito
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de credito:              $ ${(infoVenta.totalCompra * .036).toFixed(2)} MX`  );
      }else{
        restanteCredito = infoVenta.totalCompra
      }
      if(infoVenta.tarjetaDebito){
        x=(restanteCredito * .036)
        restanteDebito=((restanteCredito * .036)-infoVenta.montoTarjetaDebito)
        doc.text( 15, this.positionYPDF+=5,  `Iva tarjeta de debito:              $ ${(restanteCredito * .036).toFixed(2)} MX`  );
      }else{
        restanteDebito=infoVenta.totalCompra
      }

      if( paciente.membresiaActiva  ){
        doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                  $ ${(infoVenta.totalCompra+x+y).toFixed(2)} MX`  );
      }else {
        doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                  $ ${(infoVenta.totalCompra+x+y).toFixed(2)} MX`  );
      }
      if( paciente.membresiaActiva ){
        this.totalLetra = numeroALetras( infoVenta.totalCompra+x+y , 'Pesos mexicanos' );
      }else{
        this.totalLetra = numeroALetras( infoVenta.totalCompra+x+y, 'Pesos mexicanos' );
      }
      doc.text(5, this.positionYPDF+=6, `Total con letra : `);
      doc.text(2, this.positionYPDF+=5, `${this.totalLetra}` );
      doc.text(25, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
      doc.text( 25, this.positionYPDF+=9, "Gracias por su Compra");
      doc.text( 8, this.positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
      this.positionYPDF +=15;
      doc.save('TICKET');
      this.positionYPDF = 100;
  }

  }

//Tickets para socios para pagar la utilidad
  export const  printTicketSocios = (  items = [], total = 0,  fecha, socio  ) => {


    const nombre = getSessionName();

    var hoy = new Date();
    var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
    
    let positionYPDF = 30;

    const doc: any = new jsPDF();

    doc.setFontSize(10);
    // REvisar estas funciones
    doc.text( 2 , 15,`            Fecha: ${fecha}   Hora: ${hora } `);
    doc.text( 2 , 20,`              Medico asociado: ${socio} `);



    items.forEach((element) => {

      doc.text( 5, positionYPDF,`${element.paciente.nombrePaciente} ${element.paciente.apellidoPaterno} ${element.paciente.apellidoMaterno}`);
      doc.text( 85, positionYPDF,  `${element.ganancia}`)
      positionYPDF += 5.5;

    });

    doc.text(10, positionYPDF+=10,  `Atendío: ${nombre}`);
    doc.text(50, positionYPDF,  `Total: $ ${total} `);
    doc.text( 8, positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
    doc.text( 25, positionYPDF+=9, "Gracias por su Compra");
    
    doc.save( `${socio}` );
  }

  
