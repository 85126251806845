<form #fNutricion #form='ngForm'>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <span>LISTADO</span>
            </div>
            <div class="col-md-2">
                <span>PREVIENE</span>
            </div>
            <div class="col">
                <span>MES 1</span>
            </div>
            <div class="col">
                <span>MES 2</span>
            </div>
            <div class="col">
                <span>MES 3</span>
            </div>
            <div class="col">
                <span>MES 4</span>
            </div>
            <div class="col">
                <span>MES 5</span>
            </div>
            <div class="col">
                <span>MES 6</span>
            </div>
            <div class="col">
                <span>NOTAS</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p>Alimentate Sanamente</p>
            </div>
            <div class="col-md-2">
                <p>Desnutrición</p>
            </div>
            <div class="col">
                <input type="text" name="alimentarSanamenteNinosMesUno" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesUno" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="alimentarSanamenteNinosMesDos" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesDos" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="alimentarSanamenteNinosMesTres" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesTres" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="alimentarSanamenteNinosMesCuatro" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesCuatro" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="alimentarSanamenteNinosMesCinco" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesCinco" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="alimentarSanamenteNinosMesSeis" [(ngModel)]="nutricionNinos.alimentarSanamenteNinosMesSeis" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="notasMesUno" [(ngModel)]="nutricionNinos.notasMesUno" placeholder="" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p>Desparacitación Intestinal</p>
            </div>
            <div class="col-md-2">
                <p>Parasitosis Intestinales</p>
            </div>
            <div class="col">
                <input type="text" name="desparacitacionIntestinalNinosMesUno" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesUno" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="desparacitacionIntestinalNinosMesDos" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesDos" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="desparacitacionIntestinalNinosMesTres" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesTres" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="desparacitacionIntestinalNinosMesCuatro" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesCuatro" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="desparacitacionIntestinalNinosMesCinco" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesCinco" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="desparacitacionIntestinalNinosMesSeis" [(ngModel)]="nutricionNinos.desparacitacionIntestinalNinosMesSeis" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="notasMesDos" [(ngModel)]="nutricionNinos.notasMesDos" placeholder="" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p>Vitamina A</p>
            </div>
            <div class="col-md-2">
                <p>Infecciones Respiratorias y Diarreas</p>
            </div>
            <div class="col">
                <input type="text" name="vitaminaANinosMesUno" [(ngModel)]="nutricionNinos.vitaminaANinosMesUno" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="vitaminaANinosMesDos" [(ngModel)]="nutricionNinos.vitaminaANinosMesDos" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="vitaminaANinosMesTres" [(ngModel)]="nutricionNinos.vitaminaANinosMesTres" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="vitaminaANinosMesCuatro" [(ngModel)]="nutricionNinos.vitaminaANinosMesCuatro" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="vitaminaANinosMesCinco" [(ngModel)]="nutricionNinos.vitaminaANinosMesCinco" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="vitaminaANinosMesSeis" [(ngModel)]="nutricionNinos.vitaminaANinosMesSeis" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="notasMesTres" [(ngModel)]="nutricionNinos.notasMesTres" placeholder="" class="form-control">
            </div>

        </div>
        <div class="row">
            <div class="col-md-2">
                <p>Hierro NCBP</p>
            </div>
            <div class="col-md-2">
                <p>Anemia</p>
            </div>
            <div class="col">
                <input type="text" name="hierroNCBPNinosMesUno" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesUno" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroNCBPNinosMesDos" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesDos" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroNCBPNinosMesTres" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesTres" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroNCBPNinosMesCuatro" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesCuatro" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroNCBPNinosMesCinco" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesCinco" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroNCBPNinosMesSeis" [(ngModel)]="nutricionNinos.hierroNCBPNinosMesSeis" placeholder="" class="form-control">
            </div>
            <div class="col"> 
                <input type="text" name="notasMesCuatro" [(ngModel)]="nutricionNinos.notasMesCuatro" placeholder="" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p>Hierro PP</p>
            </div>
            <div class="col-md-2">
                <p>Anemia</p>
            </div>
            <div class="col">
                <input type="text" name="hierroPPNinosMesUno" [(ngModel)]="nutricionNinos.hierroPPNinosMesUno" placeholder="" class="form-control ">
            </div>
            <div class="col">
                <input type="text" name="hierroPPNinosMesDos" [(ngModel)]="nutricionNinos.hierroPPNinosMesDos" placeholder="" class="form-control ">
            </div>
            <div class="col">
                <input type="text" name="hierroPPNinosMesTres" [(ngModel)]="nutricionNinos.hierroPPNinosMesTres" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroPPNinosMesCuatro" [(ngModel)]="nutricionNinos.hierroPPNinosMesCuatro" placeholder="" class="form-control">
            </div>
            <div class="col"> 
                <input type="text" name="hierroPPNinosMesCinco" [(ngModel)]="nutricionNinos.hierroPPNinosMesCinco" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="hierroPPNinosMesSeis" [(ngModel)]="nutricionNinos.hierroPPNinosMesSeis" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="notasMesCinco" [(ngModel)]="nutricionNinos.notasMesCinco" placeholder="" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p>Otros</p>
            </div>
            <div class="col-md-2">
                <p>Otras Enfermedades</p>
            </div>
            <div class="col">
                <input type="text" name="otrosNinosMesUno" [(ngModel)]="nutricionNinos.otrosNinosMesUno" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="otrosNinosMesDos" [(ngModel)]="nutricionNinos.otrosNinosMesDos" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="otrosNinosMesTres" [(ngModel)]="nutricionNinos.otrosNinosMesTres" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="otrosNinosMesCuatro" [(ngModel)]="nutricionNinos.otrosNinosMesCuatro" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="otrosNinosMesCinco" [(ngModel)]="nutricionNinos.otrosNinosMesCinco" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="otrosNinosMesSeis" [(ngModel)]="nutricionNinos.otrosNinosMesSeis" placeholder="" class="form-control">
            </div>
            <div class="col">
                <input type="text" name="notasMesSeis" [(ngModel)]="nutricionNinos.notasMesSeis" placeholder="" class="form-control">
            </div>
        </div>
    </div>
    <div class="" >
        <button class="boton" (click)="agregarNutricionN(form)">Guardar</button>
        <button class="boton2" (click)="actualizarNutricionN(form)">Actualizar</button>
    </div>
</form>
