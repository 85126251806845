import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analisis-bacteorologico',
  templateUrl: './analisis-bacteorologico.component.html',
  styleUrls: ['./analisis-bacteorologico.component.css']
})
export class AnalisisBacteorologicoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


