<form #fHeredoFamiliares #form='ngForm'>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <span>Diabetes</span>
                <input name="diabetes" [(ngModel)]="antecedentesHeredoFamiliares.diabetes" class="form-control " type="text " id="ahDiabetes">
            </div>
            <div class="col">
                <span>Cáncer</span>
                <input name="cancer" [(ngModel)]="antecedentesHeredoFamiliares.cancer" class="form-control " type="text " id="ahCancer">
            </div>
            <div class="col">
                <span>Cardiopatias</span>
                <input name="cardiopatias" [(ngModel)]="antecedentesHeredoFamiliares.cardiopatias" class="form-control " type="text " id="ahCardiopatias">
            </div>
            <div class="col">
                <span>Malformaciones</span>
                <input name="malformaciones" [(ngModel)]="antecedentesHeredoFamiliares.malformaciones" class="form-control " type="text " id="ahMalformaciones">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span>Hipertensión</span>
                <input name="hipertension" [(ngModel)]="antecedentesHeredoFamiliares.hipertension" class="form-control " type="text " id="ahHipertension">
            </div>
            <div class="col">
                <span>Tipo</span>
                <input name="hipertensionTipo" [(ngModel)]="antecedentesHeredoFamiliares.hipertensionTipo" class="form-control" type="text " id="ahHipertensionTipo">
            </div>
            <div class="col">
                <span>Nefropatías</span>
                <input name="nefropatias" [(ngModel)]="antecedentesHeredoFamiliares.nefropatias" class="form-control" type="text " id="ahNefropatias">
            </div>
            <div class="col">
                <span>Tipo</span>
                <input name="nefropatiasTipo" [(ngModel)]="antecedentesHeredoFamiliares.nefropatiasTipo" class="form-control" type="text " id="ahNefropatiasTipo">
            </div>
        </div>
        <div class="">
            <button class="boton" (click)="agregarAntecedentesHeredoFamimiares(form)">Guardar</button>
            <button class="boton2" (click)="actualizarHeredoFamiliares(form)">Actualizar</button>
        </div>
        <!-- <div class="row" >
            <div class="col">
                <button (click)="agregarAntecedentesHeredoFamimiares()" class="secondaryButton" style="margin-top: 10px;">
                Guardar
            </button>
                
            <div class="btn btn-success" (click)="actualizarHeredoFamiliares()">Actualizar</div>
            </div>
        </div> -->
    </div>
</form>
