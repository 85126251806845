import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http'


@Injectable({
  providedIn: 'root'
})
export class FlebotomiaService {

  constructor(
    private _http: HttpClient,
  ) { }

  getPacientesBitacora(){
    const url = `${URL}/bitacora/flebotomia`;
    return this._http.get(url);
  }

  getFlebotomiaById(id) {

    const url =  `${URL}/flebotomia/${id}`;
    return this._http.get( url );
  }
  
  postFlebotomia( body:any) {
    
    /* console.log(body); */

    const url =  `${URL}/flebotomia`;
    return this._http.post( url, body );
  }

  postDocsFlebotomia ( files ) {
    
    const url = `${URL}/cargar/documentos/flebotomia`;
    return this._http.post( url, files);
  }

  postDiferirDisponente( body:any ) {

    const url = `${URL}/diferir/disponente`;
    return this._http.post( url, body );
    
  }

  actualizacionCambioDeEstado( body: any ) {

    const url = `${URL}/agregar/procesobancodesangre`;
    return this._http.put(url, body);

  }

}
