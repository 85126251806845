<div class="container">
    <app-titulos
    [title]="'HOJA DE REPORTE DE PATOLOGIA'"
    [rol]="'patologia'"
    ></app-titulos>
    <ficha-info-user 
    [pacienteInfo]="paciente"
    title="false"
    [rol]="'patologia'"
    ></ficha-info-user>
    

    <div class="card my-4">
        <div class="card-header">
            <p class="titulo center">REPORTE ULTRASONIDO</p>
        </div>
        <div class="card-subtitle my-2 center">
            ULTRASONIDO DE ABDOMEN INTEGRAL (INCLUYE: ABDOMEN COMPLETO Y PELVICO)/HOMBRE.
        </div>
        <div class="card-subtitle">
            INTERPRETACION
        </div>
        <div class="card-body center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa repellat libero at nihil? Recusandae totam, harum dolor iste facilis soluta ipsa, laudantium non, optio vitae laborum molestias eaque quisquam voluptate.
            Ab, hic voluptatum. Similique, quasi iusto excepturi sed incidunt voluptate vero dolor numquam omnis alias molestias mollitia reprehenderit sit explicabo consequuntur praesentium odio natus quo quos enim rem eligendi dignissimos!
            Eos eum commodi laudantium repellendus explicabo, modi delectus laboriosam culpa obcaecati ipsam quam provident? Nemo corporis voluptatem temporibus nesciunt, cupiditate incidunt magni minus rem ad, commodi et quia exercitationem accusamus.
            Consectetur cumque quis eligendi eveniet voluptate assumenda hic molestiae sit aspernatur illum, nobis nemo quisquam eius neque tenetur totam dignissimos. Mollitia praesentium adipisci magnam doloribus eaque asperiores error expedita placeat?
            Commodi esse magnam fugit velit nulla. Eos optio exercitationem est distinctio iste dolorum temporibus culpa voluptates molestias, blanditiis hic enim officiis et doloribus explicabo fugiat nobis facere delectus placeat eius!
            Unde quae aut ad, a nam aperiam in optio accusamus quis laboriosam, illum laborum fugiat molestias. Hic saepe mollitia, distinctio sed pariatur, magnam quia adipisci dicta maxime qui vel non.
            Dolore, unde nisi. Eos exercitationem iure vitae culpa delectus voluptate eius! Molestiae minima quaerat eos dicta eligendi blanditiis neque odio quis harum mollitia, suscipit saepe nemo asperiores ex aspernatur tempore.
            Earum molestiae nulla voluptatum fugit nostrum laborum unde est ea velit, debitis maiores, totam illum inventore vitae accusantium reprehenderit, veniam aliquid possimus! Inventore, mollitia cumque? Ratione ex aut impedit iure?
            Pariatur, quaerat quis. Ut deserunt ex tenetur reiciendis quod nobis voluptas! Sequi illo repudiandae laborum nesciunt similique! In nihil maxime labore delectus quaerat culpa laborum accusantium iusto necessitatibus, animi sunt?
            Facere tempore sint aperiam obcaecati voluptate eligendi neque dolore libero earum mollitia asperiores eveniet vitae aspernatur consequatur minus, quidem dolorem. Neque veniam, porro accusantium nulla error odit quae sed corrupti!
        </div>
    </div>
</div>
