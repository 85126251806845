import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';

@Component({
  selector: 'app-historico-disponente-bs',
  templateUrl: './historico-disponente-bs.component.html',
  styleUrls: ['./historico-disponente-bs.component.css']
})
export class HistoricoDisponenteBSComponent implements OnInit {

  public paciente={
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
  }
  public donaciones:any[]=[];
  public idCensur = {
    idpaciente:''
  }
  constructor(public _route:ActivatedRoute,
              public _banco:BancoService) { }

  ngOnInit(): void {
    this.idCensur.idpaciente = this._route.snapshot.paramMap.get('id');
    this.getDisponente(this.idCensur.idpaciente);
    this.obtenerHistorico();
  }

  obtenerHistorico(){
    this._banco.historicoDonaciones(this.idCensur).subscribe((resp:any)=>{
      this.donaciones=resp['data']
    })
  }
  getDisponente(id){
    this._banco.getDisponente(id).subscribe((resp:any)=>{
      this.setPaciente(resp.data.paciente);
    })
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
  }

}
