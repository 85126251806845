<!-- SE ANEXO EL PLACEHOLDER AL IGUAL QUE EL KEYUP PARA HACER LA BUSQUEDA 
    DE MEDICAMENTOS QUE SE ENCUENTRAN DADOS DE ALTA, DE ESTA FORMA PERMITIRÁ SABER LOS 
EXISTENTES Y NO EXISTENTES-->

<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE MEDICAMENTOS'"></app-titulos>

    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filtrerPost" placeholder="Buscar" class="form-control" id="busquedaMedicamento" [(ngModel)]="buscar" keyup="typeSearchMedicamentos()">
            </div>
        </div>
        <div class="col-md-2">
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>Nombre Comercial</p>
                        </div>
                        <div class="col text_header">
                            <p>Sal o Sustancia Activa</p>
                        </div>
                        <div class="col text_header">
                            <p>Presentación</p>
                        </div>
                        <div class="col text_header">
                            <p>Contenido</p>
                        </div>
                        <div class="col text_header">
                            <p>Vía de Administración</p>
                        </div>
                        <div class="col text_header">
                            <p>Folio</p>
                        </div>
                        <div class="col text_header">
                            <p>Fecha de caducidad</p>
                        </div>
                        <div class="col text_header">
                            <p>Laboratorio</p>
                        </div>
                        <div class="col text_header">
                            <p>Eliminado</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of medicamentos | paginate:{id:'listapacientes',
                    itemsPerPage: 5,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row">
                            <div class="col-md-1">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col">
                                <p style="overflow-wrap: break-word;">{{ item.nombreComercial }}</p>
                            </div>
                            <div class="col">
                                <p style="overflow-wrap: break-word;">{{ item.nombreDeSalOsustanciaActiva }}</p>
                            </div>
                            <div class="col">
                                <p style="overflow-wrap: break-word;">{{ item.presentacio }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.contenidoFrasco }}</p>
                            </div>
                            <div class="col">
                                {{ item.viaDeAdministracion }}
                            </div>
                            <div class="col">
                                <p>{{ item.lote }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.fechaCaducidad }}</p>
                            </div>
                            <div class="col">
                                <p>{{ item.laboratorio }}</p>
                            </div>
                            <div class="col">
                                <label class="btn">
                                    <i class="fas fa-trash-alt" id="elimi"></i>
                                </label>
                                <!-- <button class=" btn btn-danger btn-block" (click)="eliminarMedicamento(item._id)">Eliminar</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- <h1 class="centerxd">LISTADO DE MEDICAMENTOS</h1>

<br>

<div style="margin-left: 5%;" class="col-md-9">
    <input type="text" name="filtrerPost" placeholder="Buscar" class="form-control" id="busquedaMedicamento" [(ngModel)]="buscar" keyup="typeSearchMedicamentos()" style="border-radius: 20px;">
</div>


<div style="margin-top: 2%;" class="col-xs-12" id="tabla">
    <div class="card" style="border-radius: 30px;">
        <div class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p> # </p>
                </div>
                <div class="col text_header">
                    <p>Nombre Comercial</p>
                </div>
                <div class="col text_header">
                    <p>Sal o Sustancia Activa</p>
                </div>
                <div class="col text_header">
                    <p>Presentación</p>
                </div>
                <div class="col text_header">
                    <p>Contenido</p>
                </div>
                <div class="col text_header">
                    <p>Vía de Administración</p>
                </div>
                <div class="col text_header">
                    <p>Folio</p>
                </div>
                <div class="col text_header">
                    <p>Fecha de caducidad</p>
                </div>
                <div class="col text_header">
                    <p>Laboratorio</p>
                </div>
                <div class="col text_header">
                    <p>Eliminado</p>
                </div>
            </div>
        </div>



        <div class="card-body">
            <div class="row" *ngFor="let item of medicamentos | paginate:{id:'lista',
            itemsPerPage: 5 ,
            currentPage:pagina,
            totalItems:total}; let i = index">
                <div class="col">
                    <p>{{ i +1 }}</p>
                </div>
                <div class="col">
                    <p style="overflow-wrap: break-word;">{{ item.nombreComercial }}</p>
                </div>
                <div class="col">
                    <p style="overflow-wrap: break-word;">{{ item.nombreDeSalOsustanciaActiva }}</p>
                </div>
                <div class="col">
                    <p style="overflow-wrap: break-word;">{{ item.presentacio }}</p>
                </div>
                <div class="col">
                    <p>{{ item.contenidoFrasco }}</p>
                </div>
                <div class="col">
                    {{ item.viaDeAdministracion }}
                </div>
                <div class="col">
                    <p>{{ item.lote }}</p>
                </div>
                <div class="col">
                    <p>{{ item.fechaCaducidad }}</p>
                </div>
                <div class="col">
                    <p>{{ item.laboratorio }}</p>
                </div>
                <div class="col">
                    <button class=" btn btn-danger btn-block" (click)="eliminarMedicamento(item._id)">Eliminar</button>
                </div>
            </div>
        </div>

        <div class="offset-md-3">
            <pagination-controls id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>

    </div>
</div> -->