import { Component, OnInit } from '@angular/core';
import { AlmacenService } from 'src/app/services/almacenBs/almacen.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { filterProduc } from 'src/app/functions/almacen';

@Component({
  selector: 'app-lista-materiales',
  templateUrl: './lista-materiales.component.html',
  styleUrls: ['./lista-materiales.component.css']
})

export class ListaMaterialesComponent implements OnInit {

  constructor(private servicioAlmace: AlmacenService,
              private _spinner: NgxSpinnerService) { }

  public servicios = [];
  public servicios2= [];
  public pagina = 0;
  public totalMateriales:String;

  ngOnInit(): void {
    this._spinner.show();
    this.obtenerServicios();
  }

  filterChangeByName(event) {
    this.pagina = 0;
    if(event.target.value.length >= 3){
      this.servicioAlmace.buscarMaterialProductos({nombre: event.target.value})
      .subscribe( (data: any) => this.servicios = data.data);
    }else{
      this.servicios = this.servicios2;
    }
  }

  obtenerServicios(){
    this.servicioAlmace.getListadoMateriales().subscribe((data:any) => {
      this.servicios = data.data;
      this.servicios2 = data.data;
      this._spinner.hide();
    });
  }
}
