import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BancoService } from 'src/app/services/bancodeSangre/banco.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-ver-disponente',
  templateUrl: './ver-disponente.component.html',
  styleUrls: ['./ver-disponente.component.css']
})
export class VerDisponenteComponent implements OnInit {

  public id;
   
  public paciente = {
      nombrePaciente:"",
      apellidoPaterno:"",
      apellidoMaterno: "",
      curp:"",
      telefono:0,
      consultas: 0,
      _id:"",
      fechaRegistro:Date,
      genero:"",
      direccion: {
        estadoPaciente:"",
        callePaciente:"",
        paisPaciente:"",
        cp:""
      },
      contactoEmergencia1Nombre:"",
      contactoEmergencia1Edad:"",
      correo: "",
      cp:"",
      edad:"",
      paquetes : [],
      membresiaActiva:false,
      receptor: "",
      tipoDeSangre: "",
      disponente: "",
  }
  public paquetes: any [] = [];
  public lugar = false;
  public paciente2 = {
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    edad: '',
    genero: '',
    _id:'',
    callePaciente: '',
    fechaNacimientoPaciente:'',
    estadoPaciente: '',
    paisPaciente: '',
    telefono: '',
    receptor: '',
    tipoDeSangre: '',
    disponente: '',
    fechaRegistro:Date,
    consultas: 0,
  }
  public donaciones:any[]=[];
  public idCensur = {
    idpaciente:''
  }

  constructor(private activatedRoute: ActivatedRoute,
              private _router:Router,
              private _pacienteService: PacientesService,
              private _banco:BancoService) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerPaciente();
    this.paquete(this.id);
    this.obtenerHistorico();
  }

  obtenerPaciente(){
    this._pacienteService.getPacienteBtID(this.id).subscribe( (data:any) => {
      this.paciente = data.paciente;
      this.setPaciente2(data.paciente);
      this.guardarLocalStorage();
    });
  }

  obtenerHistorico(){
    this.idCensur.idpaciente=this.id;
    this._banco.historicoDonaciones(this.idCensur).subscribe((resp:any)=>{
      this.donaciones=resp['data']
    })
  }

  setPaciente2(data){
    for (const key in this.paciente2) {
      if (data[key] == undefined) {
        this.paciente2[key] = ''
      }else{
        this.paciente2[key] = data[key]
      }
    }
  }

  guardarLocalStorage(){
    if(  localStorage.getItem('paciente') ){
      localStorage.removeItem('paciente');
    }
    if(  this.paciente.paquetes.length >= 1 || this.paciente.membresiaActiva == true ){
        this.paciente.membresiaActiva = true;
    }
    localStorage.setItem('paciente', JSON.stringify(this.paciente));
  }

  paquete(id){
    this._pacienteService.getPaquetePaciente(id).subscribe(
      (data: any) => {
          this.paquetes = data['data'];
      },
      err => {
        console.log(<any>err);
    });  
  }

  irAUnServicio(  servicio ){
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    let resp ={
      idpaciente: this.paciente._id,
      disponente:servicio
    }
    if(servicio == 'Donaciones'){
      this.lugar = true;
      this._banco.agregarProducto(resp).subscribe((resp:any)=>{
      })
      
    }else{
      this._router.navigate([ `/serviciosInt/${servicio}`]);
    }
  }

  actualizarLugar(lugar){
    let resp2 ={
      idpaciente: this.paciente._id,
      lugarorigen:lugar
    }
    this._banco.validacionDonacion(resp2).subscribe((resp:any)=>{
      console.log(resp);
      
      if (resp.data.proceso == 'FINALIZADO') {
        Swal.fire({
          icon: 'error',
          title: 'NO APTO',
          text: 'EL DISPONENTE NO ES APTO PARA UNA DONACION',
        })
      }
      if (resp.data.proceso == 'NUEVO' || resp.data.proceso == 'DISPONIBLE') {
        this._banco.agregarLugar(resp2).subscribe((resp:any)=>{
          this._router.navigate([ `/autoexclusion/${this.paciente._id}`]);
        })
      }
    })  
  }

  pasar(){
    this._router.navigate([ `/historico/disponente/bs/${this.id}`]);
  }

}
