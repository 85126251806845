<div class="card">
  <form 
   [formGroup]='registroForm' 
   #registroNgform='ngForm'>
   <mat-horizontal-stepper style="border-radius: 15px;" linear #stepper>
       <mat-step label="DATOS GENERALES">
           <div class="container">
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">NOMBRE (S)</label>
                       <input type="text" class="form-control" name="nombrePaciente" id="validationCustom01"  [formControlName]="'nombrePaciente'">
                       <div class="invalid-feedback" *ngIf="registroForm.get('nombrePaciente').hasError('required')">
                         EL nombre es requerido.
                       </div>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">APELLIDO PATERNO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'apellidoPaterno'" required>
                       <div class="invalid-feedback">
                         EL Apellido paterno es requerido.
                       </div>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">APELLIDO MATERNO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'apellidoMaterno'">
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">FECHA DE NACIMIENTO</label>
                       <input type="date" class="form-control" id="validationCustom01" [formControlName]="'fechaNacimientoPaciente'" (blur)="edad($event.target.value)">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="nombre">EDAD</label>
                       <div class="input-group">

                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'edad'"  ([ngModel]) = "registroForm.get('edad')">
                           <div class="input-group-prepend">
                               <span class="input-group-text" id="inputGroupPrepend3">AÑOS</span>
                             </div>
                       </div>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">GÉNERO</label>
                       <select name="genero" required id="genero" class="form-control custom-select" (blur)="genero($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option value="FEMENINO">  FEMENINO </option>
                           <option value="MASCULINO"> MASCULINO </option>
                       </select>
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">LUGAR DE ORIGEN</label>
                       <input type="text" class="form-control" id="validationCustom01"  [formControlName]="'lugarOrigen'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">ESTADO DE ORIGEN</label>
                       <select name="genero" required  id="genero" class="form-control custom-select" (blur)="estado($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option value="Aguascalientes">AGUASCALIENTES</option>
                           <option value="Baja California">BAJA CALIFORNIA</option>
                           <option value="Baja California Sur">BAJA CALIFORNIA SUR</option>
                           <option value="Campeche">CAMPECHE</option>
                           <option value="Chiapas">CHIAPAS</option>
                           <option value="Chihuahua">CHIHUAHUA</option>
                           <option value="Ciudad de Mexico">CDMX</option>
                           <option value="Coahuila de Zaragoza">COAHUILA</option>
                           <option value="Colima">COLIMA</option>
                           <option value="Durango">DURANGO</option>
                           <option value="Mexico">ESTADO DE MÉXICO</option>
                           <option value="Guanajuato">GUANAJUATO</option>
                           <option value="Guerrero">GUERRERO</option>
                           <option value="Hidalgo">HIDALGO</option>
                           <option value="Jalisco">JALISCO</option>
                           <option value="Michoacan de Ocampo">MICHOACÁN</option>
                           <option value="Morelos">MORELOS</option>
                           <option value="Nayarit">NAYARIT</option>
                           <option value="Nuevo Leon">NUEVO LÉON</option>
                           <option value="Oaxaca">OAXACA</option>
                           <option value="Puebla">PUEBLA</option>
                           <option value="Queretaro">QUERÉTARO</option>
                           <option value="Quintana Roo">QUINTANA ROO</option>
                           <option value="San Luis Potosi">SAN LUIS POTOSÍ</option>
                           <option value="Sinaloa">SINALOA</option>
                           <option value="Sonora">SONORA</option>
                           <option value="Tabasco">TABASCO</option>
                           <option value="Tamaulipas">TAMAULIPAS</option>
                           <option value="Tlaxcala">TLAXCALA</option>
                           <option value="Veracruz de Ignacio de la Llave">VERACRUZ</option>
                           <option value="Yucatan">YUCATÁN</option>
                           <option value="Zacatecas">ZACATECAS</option>
                       </select>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">CURP</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'curp'"  ([ngModel]) = "registroForm.get('curp')">
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">TELÉFONO</label>
                       <input type="number" class="form-control" id="validationCustom01" [formControlName]="'telefono'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">ESTADO CIVIL</label>
                       <select name="genero" required  id="genero" class="form-control custom-select" (blur)="estadoCivil($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                               <ng-container *ngIf="registroForm.get('genero').value == 'MASCULINO'">
                                   <option value="SOLTERO">SOLTERO</option>
                                   <option value="CASADO">CASADO</option>
                                   <option value="DIVORCIADO">DIVORCIADO</option>
                                   <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                   <option value="VIUDO">VIUDO</option>
                                   <option value="CONCUBINATO">CONCUBINATO</option>
                                   <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                               </ng-container>
                               <ng-container *ngIf="registroForm.get('genero').value == 'FEMENINO'">   
                                       <option value="SOLTERO">SOLTERA</option>
                                       <option value="CASADO">CASADA</option>
                                       <option value="DIVORCIADO">DIVORCIADA</option>
                                       <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                       <option value="VIUDO">VIUDA</option>
                                       <option value="CONCUBINATO">CONCUBINATO</option>
                                       <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                               </ng-container>
                       </select>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">NÚMERO DE EXPEDIENTE</label>
                       <input type="number" class="form-control" id="validationCustom01" [formControlName]="'numeroExpediente'" ([ngModel]) = "registroForm.get('numeroExpediente')">
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-12 p-2">
                       <label for="apellido 2">CORREO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'correoPaciente'">
                   </div>
               </div>
           </div>
       </mat-step>
       <mat-step label="DIRECCIÓN PERSONAL">
           <div class="container">
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">CALLE</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'callePaciente'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">NÚMERO INTERIOR</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'numeroIntPaciente'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">NÚMERO EXTERIOR</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'numeroPaciente'">
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">PAÍS</label>
                       <select name="paisActual" id="paisActual" class="form-control custom-select" (blur)="pais($event.target.value)">
                           <option value="AF">Afganistán</option>
                           <option value="AL">Albania</option>
                           <option value="DE">Alemania</option>
                           <option value="AD">Andorra</option>
                           <option value="AO">Angola</option>
                           <option value="AI">Anguilla</option>
                           <option value="AQ">Antártida</option>
                           <option value="AG">Antigua y Barbuda</option>
                           <option value="AN">Antillas Holandesas</option>
                           <option value="SA">Arabia Saudí</option>
                           <option value="DZ">Argelia</option>
                           <option value="AR">Argentina</option>
                           <option value="AM">Armenia</option>
                           <option value="AW">Aruba</option>
                           <option value="AU">Australia</option>
                           <option value="AT">Austria</option>
                           <option value="AZ">Azerbaiyán</option>
                           <option value="BS">Bahamas</option>
                           <option value="BH">Bahrein</option>
                           <option value="BD">Bangladesh</option>
                           <option value="BB">Barbados</option>
                           <option value="BE">Bélgica</option>
                           <option value="BZ">Belice</option>
                           <option value="BJ">Benin</option>
                           <option value="BM">Bermudas</option>
                           <option value="BY">Bielorrusia</option>
                           <option value="MM">Birmania</option>
                           <option value="BO">Bolivia</option>
                           <option value="BA">Bosnia y Herzegovina</option>
                           <option value="BW">Botswana</option>
                           <option value="BR">Brasil</option>
                           <option value="BN">Brunei</option>
                           <option value="BG">Bulgaria</option>
                           <option value="BF">Burkina Faso</option>
                           <option value="BI">Burundi</option>
                           <option value="BT">Bután</option>
                           <option value="CV">Cabo Verde</option>
                           <option value="KH">Camboya</option>
                           <option value="CM">Camerún</option>
                           <option value="CA">Canadá</option>
                           <option value="TD">Chad</option>
                           <option value="CL">Chile</option>
                           <option value="CN">China</option>
                           <option value="CY">Chipre</option>
                           <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
                           <option value="CO">Colombia</option>
                           <option value="KM">Comores</option>
                           <option value="CG">Congo</option>
                           <option value="CD">Congo, República Democrática del</option>
                           <option value="KR">Corea</option>
                           <option value="KP">Corea del Norte</option>
                           <option value="CI">Costa de Marfíl</option>
                           <option value="CR">Costa Rica</option>
                           <option value="HR">Croacia (Hrvatska)</option>
                           <option value="CU">Cuba</option>
                           <option value="DK">Dinamarca</option>
                           <option value="DJ">Djibouti</option>
                           <option value="DM">Dominica</option>
                           <option value="EC">Ecuador</option>
                           <option value="EG">Egipto</option>
                           <option value="SV">El Salvador</option>
                           <option value="AE">Emiratos Árabes Unidos</option>
                           <option value="ER">Eritrea</option>
                           <option value="SI">Eslovenia</option>
                           <option value="ES">España</option>
                           <option value="US">Estados Unidos</option>
                           <option value="EE">Estonia</option>
                           <option value="ET">Etiopía</option>
                           <option value="FJ">Fiji</option>
                           <option value="PH">Filipinas</option>
                           <option value="FI">Finlandia</option>
                           <option value="FR">Francia</option>
                           <option value="GA">Gabón</option>
                           <option value="GM">Gambia</option>
                           <option value="GE">Georgia</option>
                           <option value="GH">Ghana</option>
                           <option value="GI">Gibraltar</option>
                           <option value="GD">Granada</option>
                           <option value="GR">Grecia</option>
                           <option value="GL">Groenlandia</option>
                           <option value="GP">Guadalupe</option>
                           <option value="GU">Guam</option>
                           <option value="GT">Guatemala</option>
                           <option value="GY">Guayana</option>
                           <option value="GF">Guayana Francesa</option>
                           <option value="GN">Guinea</option>
                           <option value="GQ">Guinea Ecuatorial</option>
                           <option value="GW">Guinea-Bissau</option>
                           <option value="HT">Haití</option>
                           <option value="HN">Honduras</option>
                           <option value="HU">Hungría</option>
                           <option value="IN">India</option>
                           <option value="ID">Indonesia</option>
                           <option value="IQ">Irak</option>
                           <option value="IR">Irán</option>
                           <option value="IE">Irlanda</option>
                           <option value="BV">Isla Bouvet</option>
                           <option value="CX">Isla de Christmas</option>
                           <option value="IS">Islandia</option>
                           <option value="KY">Islas Caimán</option>
                           <option value="CK">Islas Cook</option>
                           <option value="CC">Islas de Cocos o Keeling</option>
                           <option value="FO">Islas Faroe</option>
                           <option value="HM">Islas Heard y McDonald</option>
                           <option value="FK">Islas Malvinas</option>
                           <option value="MP">Islas Marianas del Norte</option>
                           <option value="MH">Islas Marshall</option>
                           <option value="UM">Islas menores de Estados Unidos</option>
                           <option value="PW">Islas Palau</option>
                           <option value="SB">Islas Salomón</option>
                           <option value="SJ">Islas Svalbard y Jan Mayen</option>
                           <option value="TK">Islas Tokelau</option>
                           <option value="TC">Islas Turks y Caicos</option>
                           <option value="VI">Islas Vírgenes (EEUU)</option>
                           <option value="VG">Islas Vírgenes (Reino Unido)</option>
                           <option value="WF">Islas Wallis y Futuna</option>
                           <option value="IL">Israel</option>
                           <option value="IT">Italia</option>
                           <option value="JM">Jamaica</option>
                           <option value="JP">Japón</option>
                           <option value="JO">Jordania</option>
                           <option value="KZ">Kazajistán</option>
                           <option value="KE">Kenia</option>
                           <option value="KG">Kirguizistán</option>
                           <option value="KI">Kiribati</option>
                           <option value="KW">Kuwait</option>
                           <option value="LA">Laos</option>
                           <option value="LS">Lesotho</option>
                           <option value="LV">Letonia</option>
                           <option value="LB">Líbano</option>
                           <option value="LR">Liberia</option>
                           <option value="LY">Libia</option>
                           <option value="LI">Liechtenstein</option>
                           <option value="LT">Lituania</option>
                           <option value="LU">Luxemburgo</option>
                           <option value="MK">Macedonia, Ex-República Yugoslava de</option>
                           <option value="MG">Madagascar</option>
                           <option value="MY">Malasia</option>
                           <option value="MW">Malawi</option>
                           <option value="MV">Maldivas</option>
                           <option value="ML">Malí</option>
                           <option value="MT">Malta</option>
                           <option value="MA">Marruecos</option>
                           <option value="MQ">Martinica</option>
                           <option value="MU">Mauricio</option>
                           <option value="MR">Mauritania</option>
                           <option value="YT">Mayotte</option>
                           <option selected value="MX">México</option>
                           <option value="FM">Micronesia</option>
                           <option value="MD">Moldavia</option>
                           <option value="MC">Mónaco</option>
                           <option value="MN">Mongolia</option>
                           <option value="MS">Montserrat</option>
                           <option value="MZ">Mozambique</option>
                           <option value="NA">Namibia</option>
                           <option value="NR">Nauru</option>
                           <option value="NP">Nepal</option>
                           <option value="NI">Nicaragua</option>
                           <option value="NE">Níger</option>
                           <option value="NG">Nigeria</option>
                           <option value="NU">Niue</option>
                           <option value="NF">Norfolk</option>
                           <option value="NO">Noruega</option>
                           <option value="NC">Nueva Caledonia</option>
                           <option value="NZ">Nueva Zelanda</option>
                           <option value="OM">Omán</option>
                           <option value="NL">Países Bajos</option>
                           <option value="PA">Panamá</option>
                           <option value="PG">Papúa Nueva Guinea</option>
                           <option value="PK">Paquistán</option>
                           <option value="PY">Paraguay</option>
                           <option value="PE">Perú</option>
                           <option value="PN">Pitcairn</option>
                           <option value="PF">Polinesia Francesa</option>
                           <option value="PL">Polonia</option>
                           <option value="PT">Portugal</option>
                           <option value="PR">Puerto Rico</option>
                           <option value="QA">Qatar</option>
                           <option value="UK">Reino Unido</option>
                           <option value="CF">República Centroafricana</option>
                           <option value="CZ">República Checa</option>
                           <option value="ZA">República de Sudáfrica</option>
                           <option value="DO">República Dominicana</option>
                           <option value="SK">República Eslovaca</option>
                           <option value="RE">Reunión</option>
                           <option value="RW">Ruanda</option>
                           <option value="RO">Rumania</option>
                           <option value="RU">Rusia</option>
                           <option value="EH">Sahara Occidental</option>
                           <option value="KN">Saint Kitts y Nevis</option>
                           <option value="WS">Samoa</option>
                           <option value="AS">Samoa Americana</option>
                           <option value="SM">San Marino</option>
                           <option value="VC">San Vicente y Granadinas</option>
                           <option value="SH">Santa Helena</option>
                           <option value="LC">Santa Lucía</option>
                           <option value="ST">Santo Tomé y Príncipe</option>
                           <option value="SN">Senegal</option>
                           <option value="SC">Seychelles</option>
                           <option value="SL">Sierra Leona</option>
                           <option value="SG">Singapur</option>
                           <option value="SY">Siria</option>
                           <option value="SO">Somalia</option>
                           <option value="LK">Sri Lanka</option>
                           <option value="PM">St Pierre y Miquelon</option>
                           <option value="SZ">Suazilandia</option>
                           <option value="SD">Sudán</option>
                           <option value="SE">Suecia</option>
                           <option value="CH">Suiza</option>
                           <option value="SR">Surinam</option>
                           <option value="TH">Tailandia</option>
                           <option value="TW">Taiwán</option>
                           <option value="TZ">Tanzania</option>
                           <option value="TJ">Tayikistán</option>
                           <option value="TF">Territorios franceses del Sur</option>
                           <option value="TP">Timor Oriental</option>
                           <option value="TG">Togo</option>
                           <option value="TO">Tonga</option>
                           <option value="TT">Trinidad y Tobago</option>
                           <option value="TN">Túnez</option>
                           <option value="TM">Turkmenistán</option>
                           <option value="TR">Turquía</option>
                           <option value="TV">Tuvalu</option>
                           <option value="UA">Ucrania</option>
                           <option value="UG">Uganda</option>
                           <option value="UY">Uruguay</option>
                           <option value="UZ">Uzbekistán</option>
                           <option value="VU">Vanuatu</option>
                           <option value="VE">Venezuela</option>
                           <option value="VN">Vietnam</option>
                           <option value="YE">Yemen</option>
                           <option value="YU">Yugoslavia</option>
                           <option value="ZM">Zambia</option>
                           <option value="ZW">Zimbabue</option>
                       </select>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">CÓDIGO POSTAL</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'cpPaciente'" ([ngModel]) = "registroForm.get('cpPaciente')" (blur)="cogidoPostal($event.target.value,false)">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">ESTADO</label>
                       <ng-container *ngIf="registroForm.get('cpPaciente').value != '' && estadocp != 'false'" >
                           <select name="estado" id="estado" class="form-control custom-select">
                               <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                               <option>{{estadocp}}</option>
                           </select>
                       </ng-container>
                       <ng-container *ngIf="registroForm.get('cpPaciente').value == ''">
                           <select name="estado" id="estado" class="form-control custom-select" (blur)="cogidoPostal(false, $event.target.value)">
                               <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                               <option value="Aguascalientes">AGUASCALIENTES</option>
                               <option value="Baja California">BAJA CALIFORNIA</option>
                               <option value="Baja California Sur">BAJA CALIFORNIA SUR</option>
                               <option value="Campeche">CAMPECHE</option>
                               <option value="Chiapas">CHIAPAS</option>
                               <option value="Chihuahua">CHIHUAHUA</option>
                               <option value="Ciudad de Mexico">CDMX</option>
                               <option value="Coahuila de Zaragoza">COAHUILA</option>
                               <option value="Colima">COLIMA</option>
                               <option value="Durango">DURANGO</option>
                               <option value="Mexico">ESTADO DE MÉXICO</option>
                               <option value="Guanajuato">GUANAJUATO</option>
                               <option value="Guerrero">GUERRERO</option>
                               <option value="Hidalgo">HIDALGO</option>
                               <option value="Jalisco">JALISCO</option>
                               <option value="Michoacan de Ocampo">MICHOACÁN</option>
                               <option value="Morelos">MORELOS</option>
                               <option value="Nayarit">NAYARIT</option>
                               <option value="Nuevo Leon">NUEVO LÉON</option>
                               <option value="Oaxaca">OAXACA</option>
                               <option value="Puebla">PUEBLA</option>
                               <option value="Queretaro">QUERÉTARO</option>
                               <option value="Quintana Roo">QUINTANA ROO</option>
                               <option value="San Luis Potosi">SAN LUIS POTOSÍ</option>
                               <option value="Sinaloa">SINALOA</option>
                               <option value="Sonora">SONORA</option>
                               <option value="Tabasco">TABASCO</option>
                               <option value="Tamaulipas">TAMAULIPAS</option>
                               <option value="Tlaxcala">TLAXCALA</option>
                               <option value="Veracruz de Ignacio de la Llave">VERACRUZ</option>
                               <option value="Yucatan">YUCATÁN</option>
                               <option value="Zacatecas">ZACATECAS</option>
                           </select>
                       </ng-container>                       
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">MUNICIPIO</label>
                       <ng-container *ngIf="registroForm.get('cpPaciente').value != '' && estadocp != 'false'">
                           <select name="estado" id="estado" class="form-control custom-select" (blur)="municipio($event.target.value)">
                               <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                               <option *ngFor="let item of municipios; index as i">{{item}}</option>
                           </select>
                       </ng-container>
                       <ng-container *ngIf="registroForm.get('cpPaciente').value == ''">
                           <select name="estado" id="estado" class="form-control custom-select" (blur)="municipio2($event.target.value)">
                               <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                               <option *ngFor="let item of municipios; index as i">{{item}}</option>
                           </select>
                       </ng-container>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="nombre">CIUDAD</label>
                       <ng-container *ngIf="registroForm.get('cpPaciente').value != '' && estadocp != 'false'">
                           <select name="estado" id="estado" class="form-control custom-select" (blur)="ciudad($event.target.value)">
                               <option value="" selected>SELECCIONA UNA OPCIÓN</option> 
                               <option *ngFor="let item of asentamiento; index as i">{{item}}</option>
                           </select>
                       </ng-container>
                       <ng-container *ngIf="registroForm.get('cpPaciente').value == ''">
                           <select name="estado" id="estado" class="form-control custom-select" (blur)="ciudad2($event.target.value)">
                               <option value="" selected>SELECCIONA UNA OPCIÓN</option> 
                               <option *ngFor="let item of asentamiento; index as i">{{item}}</option>
                           </select>
                       </ng-container>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">REFERENCIA</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'referenciaPaciente'">
                   </div>
               </div>
           </div>
       </mat-step>
       <mat-step label="DATOS FISCALES">
           <div class="container">
               <div class="row">
                   <div class="col-md-6 p-2">
                       <label>¿TU DOMICILIO FISCAL ES EL MISMO QUE TU DOMICILIO ACTUAL?</label>
                   </div>
                   <div class="col-md-1 p-2">
                       <div class="form-check form-check-inline">
                           <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="si" (change)="domicilioFiscal($event.target.value)">
                           <label class="form-check-label" for="inlineRadio1">SI</label>
                         </div>
                   </div>
                   <div class="col-md-1 p-2">
                         <div class="form-check form-check-inline">
                           <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="no" (change)="domicilioFiscal($event.target.value)">
                           <label class="form-check-label" for="inlineRadio2">NO</label>
                         </div>
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">RAZÓN SOCIAL</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">RFC</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1RFC'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">CORREO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'correoRazonSocial1'">
                   </div>
               </div>
               <div class="row">
                   <ng-container *ngIf="datosfiscales == 'si'">
                       <div class="col-md-4 p-2">
                           <label for="nombre">CALLE</label>
                           <input [ngModel] = "registroForm.get('callePaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Calle'" >
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="apellido 1">NÚMERO EXTERIOR</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumExt'" [ngModel] = "registroForm.get('numeroPaciente').value">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="apellido 2">NÚMERO INTERIOR</label>
                           <input [ngModel] = "registroForm.get('numeroIntPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumInt'">
                       </div>
                   </ng-container>
                   <ng-container *ngIf="datosfiscales == 'no'">
                       <div class="col-md-4 p-2">
                           <label for="nombre">CALLE</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Calle'" >
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="apellido 1">NÚMERO EXTERIOR</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumExt'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="apellido 2">NÚMERO INTERIOR</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumInt'">
                       </div>
                   </ng-container>
               </div>
               <div class="row">
                   <ng-container *ngIf="datosfiscales == 'si'">
                       <div class="col-md-4 p-2">
                           <label for="apellido 2">PAÍS</label>
                           <input [ngModel] = "registroForm.get('paisActual').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocialPais'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="nombre">CÓDIGO POSTAL</label>
                           <input [ngModel] = "registroForm.get('cpPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'cpRazonSocial'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="apellido 1">ESTADO</label>
                           <input [ngModel] = "registroForm.get('estadoPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Estado'">
                       </div>
                   </ng-container>
                   <ng-container *ngIf="datosfiscales == 'no'">
                       <div class="col-md-4 p-2">
                           <label for="apellido 2">PAÍS</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocialPais'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="nombre">CÓDIGO POSTAL</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'cpRazonSocial'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="apellido 1">ESTADO</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Estado'">
                       </div>
                   </ng-container>
               </div>
               <div class="row">
                   <ng-container *ngIf="datosfiscales == 'si'">
                       <div class="col-md-4 p-2">
                           <label for="apellido 2">MUNICIPIO</label>
                           <input [ngModel] = "registroForm.get('municipioPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Municipio'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="nombre">CIUDAD</label>
                           <input [ngModel] = "registroForm.get('coloniaPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1colonia'">
                       </div>
                   </ng-container>
                   <ng-container *ngIf="datosfiscales == 'no'">
                       <div class="col-md-4 p-2">
                           <label for="apellido 2">MUNICIPIO</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Municipio'">
                       </div>
                       <div class="col-md-4 p-2">
                           <label for="nombre">CIUDAD</label>
                           <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1colonia'">
                       </div>
                   </ng-container>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">TELÉFONO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonsocial1Telefono'">
                   </div>
               </div>
           </div>
       </mat-step>
       <mat-step label="CONTACTO EMERGENCIA">
           <div class="container">
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">NOMBRE (S)</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Nombre'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">APELLIDO PATERNO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1ApellidoPaterno'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">APELLIDO MATERNO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1ApellidoMaterno'">
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="nombre">EDAD</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Edad'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">TELÉFONO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Telefono'">
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 2">PARENTESCO</label>
                       <select name="contactoEmergencia1Parentesco" class="form-control custom-select" id="contactoEmergencia1Parentesco" (blur)="parentesco($event.target.value)" >
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option  value="PADRE">  PADRES   </option>
                           <option value="ABUELOS">   ABUELOS </option>
                           <option value="HIJOS"> HIJOS </option>
                           <option value="HERMANOS">  HERMANOS </option>
                           <option value="PRIMOS">   PRIMOS </option>
                           <option value="CONYUGUE">  CÓNYUGUE </option>
                           <option value="OTROS">  OTROS </option>
                       </select>
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">GÉNERO</label>
                       <select name="genero" required  id="genero" class="form-control custom-select" (blur)="generoContacto($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <option value="FEMENINO">  FEMENINO </option>
                           <option value="MASCULINO"> MASCULINO </option>
                       </select>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">ESTADO CIVIL</label>
                       <select name="genero" required id="genero" class="form-control custom-select" (blur)="estadoCivilContacto($event.target.value)">
                           <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                           <ng-container *ngIf="registroForm.get('contactoEmergencia1Genero').value == 'MASCULINO'">
                               <option value="SOLTERO">SOLTERO</option>
                               <option value="CASADO">CASADO</option>
                               <option value="DIVORCIADO">DIVORCIADO</option>
                               <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                               <option value="VIUDO">VIUDO</option>
                               <option value="CONCUBINATO">CONCUBINATO</option>
                               <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                           </ng-container>
                           <ng-container *ngIf="registroForm.get('contactoEmergencia1Genero').value == 'FEMENINO'">   
                                   <option value="SOLTERO">SOLTERA</option>
                                   <option value="CASADO">CASADA</option>
                                   <option value="DIVORCIADO">DIVORCIADA</option>
                                   <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                   <option value="VIUDO">VIUDA</option>
                                   <option value="CONCUBINATO">CONCUBINATO</option>
                                   <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                           </ng-container>
                       </select>
                   </div>
                   <div class="col-md-4 p-2">
                       <label for="apellido 1">CORREO</label>
                       <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Correo'">
                   </div>
               </div>
               <div class="row">
                   <div class="col p-2">
                       <div class="center">
                           <button mat-button matStepperNext class="principalButton" [disabled]="validar" (click)="nuevoDisponente()" > Agregar </button>
                       </div>
                   </div>
               </div>
           </div>
       </mat-step>
   </mat-horizontal-stepper>
  </form> 
</div>