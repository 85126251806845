<div class="content">
    <p class="titulo">EN LOS ÚLTIMOS 6 MESES</p>
    <form #form='ngForm' [formGroup]="formulario">
        <div class="row">
            <div class="col">
                <p> TOS/ DISNEA PERSISTENTE </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="tos_disnea" formControlName="tos_disnea" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="tos_disnea" formControlName="tos_disnea" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>PERDIDA DE PESO > 10% </p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="perdida_de_peso" formControlName="perdida_de_peso" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="perdida_de_peso" formControlName="perdida_de_peso" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>DIARREA CRÓNICA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="diarrea_cronica" formControlName="diarrea_cronica" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="diarrea_cronica" formControlName="diarrea_cronica" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>DIAFORESIS CRÓNICA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="diaforesis_cronica" formControlName="diaforesis_cronica" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="diaforesis_cronica" formControlName="diaforesis_cronica" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>DIAFORESIS PROFUSA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="diaforesis_profusa" formControlName="diaforesis_profusa" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="diaforesis_profusa" formControlName="diaforesis_profusa" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ASTENIA/ADINAMIA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="astenia" formControlName="astenia" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="astenia" formControlName="astenia" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ADENOMEGALIAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="adenomegalias" formControlName="adenomegalias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="adenomegalias" formControlName="adenomegalias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>HERPES MUCOCUTÁNEA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="herpes_mucocutaneo" formControlName="herpes_mucocutaneo" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="herpes_mucocutaneo" formControlName="herpes_mucocutaneo" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>FIEBRE CONTINUA > 10 DÍAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="fiebre_continua" formControlName="fiebre_continua" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="fiebre_continua" formControlName="fiebre_continua" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ODINOGAFÍA > 10 DÍAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="odinofagia_diez_dias" formControlName="odinofagia_diez_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="odinofagia_diez_dias" formControlName="odinofagia_diez_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <p class="titulo">EN EL ÚLTIMO MES</p>
        <div class="row">
            <div class="col">
                <p>SÍNDROME DIARREICO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="sindrome_diarreico" formControlName="sindrome_diarreico" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="sindrome_diarreico" formControlName="sindrome_diarreico" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>ISOTRETINOINA</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="isotretinoina_ultimo_mes" formControlName="isotretinoina_ultimo_mes" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="isotretinoina_ultimo_mes" formControlName="isotretinoina_ultimo_mes" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>COVID-19, CASO SOSPECHOSO O CONFIRMADO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="covid_ultimo_mes" formControlName="covid_ultimo_mes" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="covid_ultimo_mes" formControlName="covid_ultimo_mes" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <p class="titulo">ÚLTIMA SEMANA</p>
        <div class="row">
            <div class="col">
                <p>TOMA MEDICAMENTOS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="medicamentos_ultima_semana" formControlName="medicamentos_ultima_semana" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="medicamentos_ultima_semana" formControlName="medicamentos_ultima_semana" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>INFECCIONES AGUDAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="infecciones_agudas_ultima_semana" formControlName="infecciones_agudas_ultima_semana" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="infecciones_agudas_ultima_semana" formControlName="infecciones_agudas_ultima_semana" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <p class="titulo">EN LAS ÚLTIMAS 48 HORAS</p>
        <div class="row">
            <div class="col">
                <p>FIEBRE Y/O ESCALOFRÍO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="fiebre_ultimos_dos_dias" formControlName="fiebre_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="fiebre_ultimos_dos_dias" formControlName="fiebre_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>EJERCICIO INTENSO/TRAUMATISMO</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="ejercicio_intenso_ultimos_dos_dias" formControlName="ejercicio_intenso_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="ejercicio_intenso_ultimos_dos_dias" formControlName="ejercicio_intenso_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>AYUNO DE MÁS DE 12 HORAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="ayuno_ultimos_dos_dias" formControlName="ayuno_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="ayuno_ultimos_dos_dias" formControlName="ayuno_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>VIGILIA DE MÁS DE 16 HORAS</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="vigilia_ultimos_dos_dias" formControlName="vigilia_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="vigilia_ultimos_dos_dias" formControlName="vigilia_ultimos_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>INGESTA DE ALCOHOL</p>
            </div>
            <div class="col">
                <div class="form-group" id="diferir">
                    <div class="form-check form-check-inline">
                        <input type="radio" value="SI" name="ingesta_de_alcohol_dos_dias" formControlName="ingesta_de_alcohol_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;"> SI</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input type="radio" value="NO" name="ingesta_de_alcohol_dos_dias" formControlName="ingesta_de_alcohol_dos_dias" (blur)="formularioUltimo(form)">
                        <label class="form-check-label" style="margin-left: 10px; margin-top: 2px;">NO</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>