<div class="container-fluid">
    <app-titulos [title]="'AGREGAR OTROS ESTUDIOS'" [rol]="'pacientes'"></app-titulos>

    <!-- <ficha-info-user [pacienteInfo]="paciente" [title]="false"></ficha-info-user> -->

    <div class="card">
        <form #form='ngForm'>
            <div class="row">
                <div class="col">
                    <select class="custom-select custom-select-lg mb-2" ngModel id="tipoEstudio" name="tipoEstudio" required>
                        <option value="" selected>Selecciona el tipo de Estudio</option>
                        <option value="LABORATORIO">LABORATORIO</option>
                        <option value="ENDOSCOPIA">ENDOSCOPIA</option>
                        <option value="ULTRASONIDO">ULTRASONIDO</option>
                        <option value="RAYOSX">RAYOS X</option>
                        <option value="TOMOGRAFIA">TOMOGRAFIA</option>
                        <option value="OTROS ESTUDIOS">OTROS ESTUDIOS</option>
                        <option value="NOTAS Y RESUMENES">NOTAS Y RESUMENES</option>
                        <option value="RECETAS">RECETAS</option>
                    </select>
                </div>
                <div class="col">
                    <input class="form-control custom-select-lg mb-2" ngModel type="date" name="fechaEstudios" id="fechaEstudios" required>
                </div>
            </div>
        
            <div class="col mt-3">
                <div class="card">
                    <ngx-dropzone name="fileUploadTosend" (change)="onSelect($event)">
                        <ngx-dropzone-label>
                            INSERTA LOS DOCUMENTOS AQUÍ
                        </ngx-dropzone-label>
                        <ngx-dropzone-preview [file]="f" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                            <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                </div>
            </div>
            <div class="col text-center">
                <button class="boton" [disabled]="deshabilitarBoton" (click)="agregar(form)">Guardar</button>
            </div>
        </form>
    </div>    
</div>