import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-signos-vitales-horizonte',
  templateUrl: './signos-vitales-horizonte.component.html',
  styleUrls: ['./signos-vitales-horizonte.component.css']
})
export class SignosVitalesHorizonteComponent implements OnInit {
  @Input() _id='';
  @Input() historialSginos :any[];

  constructor() { }

  ngOnInit(): void {
  }
}
