<form #fPersonalesPatologico #form='ngForm'>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col estilos">
                <h5>Enfermedades de la Infancia</h5>
                <div class="col">
                    <input type="text" name="enfermedadesInfancia" [(ngModel)]="antecedentesPersonalesPatologicos.enfermedadesInfancia" class="form-control">
                </div>
            </div>
            <div class="col estilos">
                <h5 for="">Secuelas</h5>
                <input type="text " name="enfermedadesInfanciaSecuelas" [(ngModel)]="antecedentesPersonalesPatologicos.enfermedadesInfanciaSecuelas" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="form-group col ">
                <h5 for=" ">Hospitalizaciones previas</h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input " type="radio" name="hospitalizacionesPrevias" [(ngModel)]="antecedentesPersonalesPatologicos.hospitalizacionesPrevias" id="hospPreviSi " value="Si">
                    <label class="form-check-label " for="hospPreviSi " style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input " type="radio" name="hospitalizacionesPrevias" [(ngModel)]="antecedentesPersonalesPatologicos.hospitalizacionesPrevias" id="hospPreviNo " value="No">
                    <label class="form-check-label " for="hospPreviNo " style="margin-right: 1rem;">No</label>
                </div>

            </div>
            <div class="col estilos">
                <h5 for=" ">Especificaciones</h5>
                <input type="text " name="hospitalizacionesPreviasEsp" [(ngModel)]="antecedentesPersonalesPatologicos.hospitalizacionesPreviasEsp" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <h5 for=" ">Transfusiones Previas: </h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input" name="transfucionesPrevias" [(ngModel)]="antecedentesPersonalesPatologicos.transfucionesPrevias" type="radio" id="transfucionesSi " value="Si">
                    <label class="form-check-label " for="transfucionesSi " style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input " name="transfucionesPrevias" [(ngModel)]="antecedentesPersonalesPatologicos.transfucionesPrevias" type="radio" id="transfucionesNo " value="No">
                    <label class="form-check-label " for="transfucionesNo " style="margin-right: 1rem;">No</label>
                </div>
            </div>
            <div class="col estilos">
                <h5 for=" ">Especificaciones: </h5>
                <input type="text " name="transfucionesPreviasEsp" [(ngModel)]="antecedentesPersonalesPatologicos.transfucionesPreviasEsp" class="form-control ">
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <h5 for=" ">Antecedentes Quirurgicos: </h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input " name="antecedentesQuirurgicos" type="radio" id="cirugiasSi" [(ngModel)]="antecedentesPersonalesPatologicos.antecedentesQuirurgicos" value="Si">
                    <label class="form-check-label " for="cirugiasSi " style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input " name="antecedentesQuirurgicos" type="radio" id="cirugiasNo" [(ngModel)]="antecedentesPersonalesPatologicos.antecedentesQuirurgicos" value="No">
                    <label class="form-check-label " for="cirugiasNo " style="margin-right: 1rem;">No</label>
                </div>
            </div>

            <div class="col estilos">
                <h5 for=" ">Especificaciones: </h5>
                <input type="text " name="antecedentesQuirurgicosEsp" [(ngModel)]="antecedentesPersonalesPatologicos.antecedentesQuirurgicosEsp" class="form-control ">
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <h5 for="">Fracturas: </h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input" name="fractutas" [(ngModel)]="antecedentesPersonalesPatologicos.fractutas" type="radio" id="fracturasSi" value="Si">
                    <label class="form-check-label " for="fracturasSi " style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input" name="fractutas" [(ngModel)]="antecedentesPersonalesPatologicos.fractutas" type="radio" id="fracturasNo" value="No">
                    <label class="form-check-label " for="fracturasNo " style="margin-right: 1rem;">No</label>
                </div>
            </div>

            <div class="col estilos">
                <h5>Especificaciones: </h5>
                <input type="text " name="fracturasEsp" [(ngModel)]="antecedentesPersonalesPatologicos.fracturasEsp" class="form-control ">
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <h5 for=" ">Otras Enfermedades: </h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input " name="otrasEnfermedades" [(ngModel)]="antecedentesPersonalesPatologicos.otrasEnfermedades" type="radio" id="otrasEnfSi " value="Si">
                    <label class="form-check-label " for="otrasEnfSi " style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input" name="otrasEnfermedades" [(ngModel)]="antecedentesPersonalesPatologicos.otrasEnfermedades" type="radio" id="otrasEnfNo " value="No">
                    <label class="form-check-label " for="otrasEnfNo " style="margin-right: 1rem;">No</label>
                </div>
            </div>


            <div class="col estilos">
                <h5 for=" ">Especificaciones: </h5>
                <input type="text " name="otrasEnfermedadesEsp" [(ngModel)]="antecedentesPersonalesPatologicos.otrasEnfermedadesEsp" class="form-control ">
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <h5 for=" ">Medicamentos: </h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input" name="medicamentosActuales" [(ngModel)]="antecedentesPersonalesPatologicos.medicamentosActuales" type="radio" id="medicamentosActualesSi" value="Si">
                    <label class="form-check-label" for="medicamentosActualesSi" style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input" type="radio" name="medicamentosActuales" [(ngModel)]="antecedentesPersonalesPatologicos.medicamentosActuales" id="medicamentosActualesNo" value="No">
                    <label class="form-check-label" for="medicamentosActualesNo" style="margin-right: 1rem;">No</label>
                </div>
            </div>
            <div class="col estilos">
                <h5 for="medicamentosActualesEsp">Medicamentos: </h5>
                <input type="text" name="medicamentosActualesEsp" [(ngModel)]="antecedentesPersonalesPatologicos.medicamentosActualesEsp" class="form-control ">
            </div>


        </div>
        <div class="row">
            <div class="form-group col">
                <h5 for=" ">Accidentes/Traumatismo: </h5>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input " type="radio" id="traumatismoSi" name="accidentes" [(ngModel)]="antecedentesPersonalesPatologicos.accidentes" value="Si">
                    <label class="form-check-label " for="traumatismoSi " style="margin-right: 1rem;">Si</label>
                    <input class="form-check-input " type="radio" id="traumatismoNo" name="accidentes" [(ngModel)]="antecedentesPersonalesPatologicos.accidentes" value="No">
                    <label class="form-check-label " for="traumatismoNo " style="margin-right: 1rem;">No</label>
                </div>
            </div>
            <div class="col estilos">
                <h5 for=" ">Especificaciones: </h5>
                <input type="text " name="accidentesEsp" [(ngModel)]="antecedentesPersonalesPatologicos.accidentesEsp" class="form-control ">
            </div>
        </div>
        <div class="row">
            <button class="boton" (click)="agregarPersonalesPatologicos(form)">Guardar</button>
            <button class="boton2" (click)="actualizarPersonalesPatologicos(form)">Actualizar</button>
        </div>
    </div>
</form>
