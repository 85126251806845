import { BrowserModule } from '@angular/platform-browser';
import  { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {  LoginModule } from './login/login.module'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';


import { APP_ROUTES } from './app.routes';
import { ComponentsModule } from './components/components.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageModule } from './pages/page.module';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntegradosService } from './services/servicios/integrados.service';
import { USGService } from './services/usg/usg.service';

//DorpZone :D
import { NgxDropzoneModule } from 'ngx-dropzone';
//Loading
import { NgxSpinnerModule } from "ngx-spinner";
import { NgStepperComponent } from 'angular-ng-stepper';
import { SpinnerInterceptor } from './components/ui/interceptorSpinner/spinner.interceptor';
import { FarmaciaComponent } from './pages/farmacia/farmacia/farmacia.component';

/* import { BucarPacienteLabPipe } from './pipes/buscarPacienteLab/bucar-paciente-lab.pipe'; */
// import { GenerosPipe } from './pipes/generos.pipe';
/*
import { AgendaComponent } from './././pages/agenda/agenda.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import  dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
// IMPORTS DE CALENDAR 


FullCalendarModule.registerPlugins([
  listPlugin,
  timeGridPlugin,
  interactionPlugin,
  dayGridPlugin
])
*/




@NgModule({
  declarations: [
    AppComponent,
    FarmaciaComponent,
   // AgendaComponent
    // BuscarPacientePipe,
    // GenerosPipe
  ],
  imports: [
    //FullCalendarModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    PageModule,
    ComponentsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxPaginationModule,
    ChartsModule,
    BrowserAnimationsModule,
    APP_ROUTES,
    ComponentsModule,
    NgxDropzoneModule,
    NgxSpinnerModule
    // ModalModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    ReactiveFormsModule,
    NgStepperComponent,
    NgxSpinnerModule,
  ],
  providers: [
    IntegradosService,
    ReactiveFormsModule,
    USGService,
    {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    //{ provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
