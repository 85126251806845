<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <app-titulos [title]="titleBitacora"> </app-titulos>
        </div>
        <div class="col">
            <button class="boton" (click)="printTable()">IMPRIMIR</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card">
                <div class="card-heder header mayus">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p>#</p>
                        </div>
                        <div class="col text_header">
                            <p>FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p>NOMBRE</p>
                        </div>
                        <div class="col text_header">
                            <p>EDAD</p>
                        </div>
                        <div class="col text_header">
                            <p>GÉNERO</p>
                        </div>
                        <div class="col text_header">
                            <p>SEDE</p>
                        </div>
                        <div class="col text_header">
                            <p>ESTUDIOS</p>
                        </div>
                        <div class="col text_header">
                            <p>PRIORIDAD</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let item of consultas | paginate:{id:'listapacientes',
                    itemsPerPage: 4,
                    currentPage:pagina,
                    totalItems:totalpaciente} ; let i = index">
                        <div class="row pointer puntero" [routerLink]="['/hoja-servicios', item.nombreServicio ,item.idVenta._id]">

                            <div class="col-md-1">
                                <p>{{ i +1 }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.fechaPedido | date :'dd/MM/yyyy': 'UTC' }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.nombrePaciente | uppercase}} {{item.idPaciente.apellidoPaterno| uppercase}} {{item.idPaciente.apellidoMaterno| uppercase}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.edad}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.idPaciente.genero | generos:true }}</p>
                            </div>
                            <div class="col">
                                <p>{{item.sede}}</p>
                            </div>
                            <div class="col">
                                <p>{{item.countServices}}</p>
                            </div>
                            <div class="col">
                                <p *ngIf="item.urgencia != true"  > PROGRAMADO </p>
                                <p *ngIf="item.urgencia == true"  > URGENTE </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="my-pagination" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
        <br>
        <p style="color: white"> Cargando... </p>
    </ngx-spinner>
</div>


<!-- <div class="col-md-12">
    <h1 class="centerxd">BITÁCORA DE LABORATORIO</h1>
    <button class="principalButton otherSVY" (click)="printTable()">
        Imprimir
    </button>
    <br>
</div>
<br>

<div class="col-md-12" id="printTableThis" class="card">
    <div class="card" style="border-radius: 30px;">
        <div class="card-header header" style="border-top-left-radius: 24px;
        border-top-right-radius: 24px;">
            <div class="row">

                <div class="col-1 text_header">
                    <p>No.</p>
                </div>
                <div class="col-1 text_header">
                    <p>Fecha</p>
                </div>
                <div class="col text_header">
                    <p>Nombre</p>
                </div>
                <div class="col-1 text_header">
                    <p>Edad</p>
                </div>
                <div class="col-1 text_header">
                    <p>sexo</p>
                </div>
                <div class="col-1 text_header">
                    <p>Sede</p>
                </div>
                <div class="col text_header">
                    <p>Estudio</p>
                </div>
                <div class="col text_header">
                    <p>Prioridad</p>
                </div>

            </div>

        </div>
    </div>
    <div class="card-body" style="background-color: #ffffff; border-radius: 30px;">
        <div *ngFor="let item of consultas | paginate:{id:'listaAmbulancia',
        itemsPerPage: 4 ,
        currentPage:pagina,
        totalItems:totalAmbulancia}; let i = index" [routerLink]="['/hoja-servicios', item._id]">

            <div class="row">
                <div class="col-1">
                    <p class="text-center">{{ i +1 }}</p>
                </div>
                <div class="col-1">
                    <p class="text-center">{{item.fecha}}</p>
                </div>
                <div class="col">
                    <p class="text-center">{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}
                    </p>
                </div>
                <div class="col-1">
                    <p class="text-center">{{item.idPaciente.edad}}</p>
                </div>
                <div class="col-1">
                    <p class="text-center">{{item.idPaciente.genero | generos:true }}</p>
                </div>
                <div class="col-1">
                    <p class="text-center">{{item.sede}}</p>
                </div>
                <div class="col">
                    <p class="text-center">{{item.estudios[0].nombreEstudio}}</p>
                </div>
                <div class="col">
                    <p class="text-center">{{item.prioridad}}</p>
                </div>
            </div>
        </div>
        <div class="offset-md-3">
            <pagination-controls id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event">
            </pagination-controls>
        </div>
    </div>
</div> -->
<!--
    TODO:
    DR.GOMEZ TRAUMA
    
    CUAUTLA 
    2 GINCEOS 

    COMPUS DE GINECO, USG Y PEDIATRIA
-->