<!-- 
    maqueta del reporte de lab
 -->
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <app-titulos [title]="'REPORTE DE RESULTADOS'"></app-titulos>
        </div>
    </div>

    <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>
    
    <section *ngIf="servicioNameUrl == 'LABORATORIO'"  >
        <div class="card cardPer card-paddin-title container">
            <div class="row">
                <div class="title-card">
                    <h2 class="mt-3 font-color">{{nombre}}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="title-card">
                        <h6 class="mt-1 font-color">{{examen}}</h6>
                    </div>
                </div>
            </div>
            <div class="row letras"> 
                <div class="col">
                    <h6>Toma</h6>
                    <input class="form-control" name="toma" type="datetime-local" [(ngModel)]="resultado.toma">
                </div>
                <div class="col">
                    <h6>Liberación</h6>
                    <input class="form-control" name="liberacion" type="datetime-local" [(ngModel)]="resultado.liberacion">
                </div>
                <div class="col">
                    <h6>Tipo de muestra</h6>
                    <select name="genero" required id="genero" class="form-control custom-select" (blur)="tipoMuestra($event.target.value)">
                        <option value="ASCITIS">ASCITIS</option>
                        <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                        <option value="ABSCESO">ABSCESO</option>
                        <option value="AMIBA EN FRESCO">AMIBA EN FRESCO</option>
                        <option value="ARTERIAL">ARTERIAL</option>
                        <option value="CULTIVOS ANAEROBIOS">CULTIVOS ANAEROBIOS</option>
                        <option value="CULTIVOS VARIOS">CULTIVOS VARIOS</option>
                        <option value="DERRAME PLEURAL">DERRAME PLEURAL</option>
                        <option value="EXUDADO FARINGEO">EXUDADO FARINGEO</option>
                        <option value="EXUDADO NASOFARINGEO">EXUDADO NASOFARINGEO</option>
                        <option value="EXUDADO GENITAL">EXUDADO GENITAL</option>
                        <option value="EXUDADO OTROS">EXUDADO OTROS</option>
                        <option value="GASOMETRIA ARTERIAL">GASOMETRIA ARTERIAL</option>
                        <option value="GASOMETRIA VENOSA">GASOMETRIA VENOSA</option>
                        <option value="HISOPADO NASOFARINGEO">HISOPADO NASOFARINGEO</option>
                        <option value="PLASMA CITRATADO">PLASMA CITRATADO</option>
                        <option value="ORINA OCASIONAL">ORINA OCASIONAL</option>
                        <option value="ORINA 24 HORAS">ORINA 24 HORAS</option>
                        <option value="SECRECION DE HERIDA">SECRECION DE HERIDA</option>
                        <option value="RASPADO UNGUEAL">RASPADO UNGUEAL</option>
                        <option value="RASPADO PERIANAL">RASPADO PERIANAL</option>
                        <option value="MOCO NASAL">MOCO NASAL</option>
                        <option value="MOCO FECAL">MOCO FECAL</option>
                        <option value="LIQUIDO SEMINAL">LIQUIDO SEMINAL</option>
                        <option value="LIQUIDO CEFALORRAQUIDEO">LIQUIDO CEFALORRAQUIDEO</option>
                        <option value="HECES">HECES</option>
                        <option value="PLASMA CON EDTA">PLASMA CON EDTA</option>
                        <option value="PLASMA CON HEPARINA">PLASMA CON HEPARINA</option>
                        <option value="SANGRE TOTAL CON EDTA">SANGRE TOTAL CON EDTA</option>
                        <option value="SUERO">SUERO</option>
                        <option value="SANGRE TOTAL">SANGRE TOTAL</option>
                        <option value="LIQUIDO ARTICULAR">LIQUIDO ARTICULAR</option>
                        <option value="LIQUIDO PERICARDICO">LIQUIDO PERICARDICO</option>
                        <option value="OTROS">OTROS</option>
                    </select>
                </div>
            </div>
            <div class="row letras title-card mt-3 mb-3">
                <div class="col-3">
                    <h3>grupos</h3>
                </div>
                <div class="col-2">
                    <h3>elementos</h3>
                </div>
                <div class="col-2">
                    <h3>Resultados</h3>
                </div>
                <div class="col-2">
                    <h3>Unidades</h3>
                </div>
                <div class="col-3">
                    <h3>Referencia</h3>
                </div>
            </div>
            <form #f='ngForm'>
                <div *ngFor="let estudio of valoresRef; let i = index">
                    <!-- container de todos los rows -->
                    <div class="container">
                        <div class="row letras mt-3 mb-3">
                            <div class="col-3">
                                <h6 style="overflow-wrap:break-word">{{ estudio.grupo }}</h6>
                            </div>
                            <div class="col-2">
                                <h6 style="overflow-wrap:break-word">{{ estudio.elemento }}</h6>
                            </div>
                            <div class="col-2">
                                <input class="form-control" [name]="estudio.elemento + i" required ngModel type="text" />
                            </div>
                            <div class="col-2">
                                <h6>{{ estudio.unidad }}</h6>
                            </div>
                            <div class="col-3">
                                <div class="row" *ngFor="let valoresRef of estudio.valorDeReferencia">
                                    <div class="col">
                                        <h6> {{ valoresRef.valorReferenciaD }} </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row letras">
                <div class="col">
                    <h6>OBSERVACIONES</h6>
                    <textarea name="OBSERVACIONES" [(ngModel)]="resultado.observaciones" id="" cols="5" ngModel class="form-control custom-select"></textarea>
                </div>
            </div>
            <div class="row letras mt-2">
                <h3>{{metodo}}</h3>
                <span style="color: #000000">*Un diagnóstico clÍnico lo debe realizar un médico certificado y un resultado aislado en ocasiones es insuficiente para establecerlo</span>
            </div>
            <div class="center mt-4">
                <button class="btn-censur" style="margin-right: 15px;" (click)="enviar( f )">
                    LIBERAR
                </button>
                <button class="btn-censur" (click)="sendObtenidos( f )">
                    VISUALIZAR
                </button>
            </div>
            <div class="center row mt-5 letras">
                <div class="col">
                    <h3> Liberación: Dr.ALBERTO ZAMORA PALMA </h3>
                    <h3>cédula: 3279016 </h3>
                </div>
                <div class="col">
                    <h3>Realizo: {{ quimico }} </h3>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- 
    maqueta de los resultados de los demas servicios
 -->
<section  *ngIf="servicioNameUrl == 'ULTRASONIDO'"  class="container-fluid">
    <article class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <app-hoja-reporte-ultrasonidos (dataDonanteEmit)="getReportEstudies($event)" [nameEstudie]="nombre" ></app-hoja-reporte-ultrasonidos>
            </div>
        </div>
    </article>
</section>

<section  *ngIf="servicioNameUrl == 'ENDOSCOPIA'"  class="container-fluid">
    <article class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <app-hoja-reporte-endoscopias (dataDonanteEmit)="getReportEstudies($event)" [nameEstudie]="nombre"></app-hoja-reporte-endoscopias>
            </div>
        </div>
    </article>
</section>

<section  *ngIf="servicioNameUrl == 'PATOLOGIA'"  class="container-fluid">
    <article class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <app-hoja-reporte-patologias (dataDonanteEmit)="getReportEstudies($event)" [nameEstudie]="nombre"></app-hoja-reporte-patologias>
            </div>
        </div>
    </article>
</section>

<section  *ngIf="servicioNameUrl == 'XRAY'"  class="container-fluid">
    <article class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <app-hoja-reporte-rayosx (dataDonanteEmit)="getReportEstudies($event)" [nameEstudie]="nombre"></app-hoja-reporte-rayosx>
            </div>
        </div>
    </article>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#00bbf1" type="ball-atom" [fullScreen]="true">
    <br>
    <p style="color: white"> Cargando... </p>
</ngx-spinner>
<!-- 
    Se muestra cuando los regresos son diferentes de lab
 -->